import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, IOSSwitch } from 'components';
import { CustomComponentsByCategory, FoodComponent } from 'lib/interfaces';
import { compact, uniq, uniqBy } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

import { CustomMealCategory, FoodComponentType } from '@calo/types';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, Grid, Stack, Typography } from '@mui/material';

import ComponentCard from './ComponentCard';
import ComponentItem from './ComponentItem';
import styles from './styles';

interface CustomizeMealCardProps {
  components: FoodComponent[];
  customFoodComponents: FoodComponent[];
  selectedRemovedComponentIdList: string[];
  setCustomFoodComponents: (value: FoodComponent[]) => void;
  selectedSwappedComponent: { oldId: string; newId: string }[];
  setSelectedSwappedComponent: (value: { oldId: string; newId: string }[]) => void;
  setSelectedRemovedComponentIdList: (value: string[]) => void;
  isPreBuiltMeal: boolean;
  selectedComponent: FoodComponent | undefined;
  setSelectedComponent: (value: FoodComponent | undefined) => void;
  allCustomFoodComponents: CustomComponentsByCategory;
  caloKidsComponents: FoodComponent[];
  isLoading?: boolean;
  applyAlwaysForMeal: boolean;
  setApplyAlwaysForMeal: (value: boolean) => void;
}

const CustomizeMealCard = ({
  customFoodComponents,
  applyAlwaysForMeal,
  setApplyAlwaysForMeal,
  setCustomFoodComponents,
  setSelectedRemovedComponentIdList,
  selectedRemovedComponentIdList,
  selectedSwappedComponent,
  setSelectedSwappedComponent,
  isPreBuiltMeal,
  selectedComponent,
  setSelectedComponent,
  allCustomFoodComponents,
  caloKidsComponents,
  components,
  isLoading
}: CustomizeMealCardProps) => {
  const [foodComponentsData, setFoodComponentsData] = useState<FoodComponent[] | undefined>(undefined);
  const [selectedComponentIndex, setSelectedComponentIndex] = useState<number>(0);

  useEffect(() => {
    const updatedComponents = [...customFoodComponents];
    setCustomFoodComponents(uniqBy(updatedComponents, 'name.en'));
  }, [selectedSwappedComponent, selectedComponent, selectedRemovedComponentIdList]);

  useEffect(() => {
    if (!selectedSwappedComponent) return;
    const swappedComponentIds = selectedSwappedComponent.map((comp) => comp.oldId);
    const updatedComponents = components.map((item) => {
      if (swappedComponentIds.includes(item.id) && !selectedRemovedComponentIdList.includes(item.id)) {
        const swappedAction = selectedSwappedComponent.find((comp) => comp.oldId === item.id);
        const customComp =
          caloKidsComponents.length > 0
            ? caloKidsComponents.find((comp) => comp.id === swappedAction?.newId)
            : allCustomFoodComponents.meal.find((custom) => custom.id === swappedAction?.newId);
        return customComp;
      } else {
        return item;
      }
    });
    setFoodComponentsData(compact(updatedComponents));
  }, [selectedSwappedComponent, selectedRemovedComponentIdList, allCustomFoodComponents, caloKidsComponents]);

  const handleRemove = (componentId: string) => {
    if (selectedRemovedComponentIdList.includes(componentId)) {
      setSelectedRemovedComponentIdList(uniq(selectedRemovedComponentIdList.filter((id) => id !== componentId)));
    } else {
      setSelectedRemovedComponentIdList(uniq([...selectedRemovedComponentIdList, componentId]));
    }
  };

  const handleRemoveComponents = useCallback(
    (componentId: string) => {
      const originalComponents = components.map((component) => component.id);
      const selectedComponent = components[selectedComponentIndex];
      const swappedAction = selectedSwappedComponent.find(
        (com) => componentId === com.newId && com.oldId === selectedComponent?.id
      );

      if (selectedComponent?.id !== componentId && swappedAction) {
        const updatedSwappedComponents = selectedSwappedComponent.filter(
          (item) => item.newId !== swappedAction.newId && selectedComponent?.id === swappedAction.oldId
        );

        handleRemove(swappedAction.oldId);
        setSelectedSwappedComponent(updatedSwappedComponents);
        const customData = components.find((component) => component.id === swappedAction.oldId);
        setSelectedComponent(customData);
      } else if (originalComponents?.includes(componentId) && selectedComponent?.id === componentId) {
        if (selectedSwappedComponent.some((component) => component.oldId === componentId)) {
          const updatedSwappedComponents = selectedSwappedComponent.filter((item) => item.oldId !== componentId);
          setSelectedSwappedComponent(updatedSwappedComponents);

          const customData = components.find(
            (component, index) => component.id === componentId && index === selectedComponentIndex
          );
          setSelectedComponent(customData);
        }

        handleRemove(componentId);
      }
    },
    [selectedSwappedComponent, selectedComponentIndex, selectedRemovedComponentIdList]
  );

  const handleSwapComponent = useCallback(
    (oldId: string, newId: string, isCaloKids?: boolean) => {
      const currentComponentData = components[selectedComponentIndex];
      const existingSwapped = selectedSwappedComponent.some(
        (swapped) => swapped.oldId === oldId && swapped.newId === newId && currentComponentData?.id === oldId
      );
      const undoSwap = selectedSwappedComponent.some(
        (swapped) => swapped.oldId === newId && swapped.newId === oldId && currentComponentData?.id === swapped.oldId
      );
      const swapSwapped = selectedSwappedComponent.some(
        (swapped) => swapped.newId === oldId && currentComponentData?.id !== newId
      );
      const swapOriginalDiff = selectedSwappedComponent.some(
        (swapped) => swapped.newId === oldId && currentComponentData?.id !== oldId
      );
      const originalRemoved = selectedRemovedComponentIdList.includes(oldId) && currentComponentData?.id === oldId;
      let updatedList = [...selectedSwappedComponent];
      let selectedSwapComponent: FoodComponent | undefined = currentComponentData;

      if (!existingSwapped && !undoSwap && !swapSwapped && !originalRemoved) {
        updatedList.push({ oldId, newId });
        selectedSwapComponent = isCaloKids
          ? caloKidsComponents.find((comp) => comp.id === newId)
          : allCustomFoodComponents.meal.find((sauce) => sauce.id === newId)!;
      } else if (undoSwap && !swapSwapped) {
        updatedList = updatedList.filter((swapped) => swapped.oldId !== newId && swapped.newId !== oldId);
        selectedSwapComponent = components.find((component) => component.id === newId)!;
      } else if (swapSwapped && !swapOriginalDiff && existingSwapped) {
        updatedList = updatedList.map((swapped) =>
          swapped.newId === oldId && swapped.oldId === currentComponentData?.id
            ? { oldId: currentComponentData?.id, newId }
            : swapped
        );
        selectedSwapComponent = isCaloKids
          ? caloKidsComponents.find((comp) => comp.id === newId)
          : allCustomFoodComponents.meal.find((sauce) => sauce.id === newId)!;
      } else if (swapOriginalDiff) {
        updatedList = updatedList.map((swapped) =>
          swapped.newId === oldId && currentComponentData?.id !== newId && currentComponentData?.id === swapped.oldId
            ? { oldId: currentComponentData?.id, newId }
            : swapped
        );
        selectedSwapComponent = isCaloKids
          ? caloKidsComponents.find((comp) => comp.id === newId)
          : allCustomFoodComponents.meal.find((component) => component.id === newId)!;
      } else {
        if (originalRemoved) {
          setSelectedRemovedComponentIdList(selectedRemovedComponentIdList.filter((id) => id !== oldId));
        }
        selectedSwapComponent = isCaloKids
          ? caloKidsComponents.find((comp) => comp.id === newId)
          : allCustomFoodComponents.meal.find((sauce) => sauce.id === newId)!;
        updatedList.push({ oldId, newId });
      }
      setSelectedSwappedComponent(updatedList);
      setSelectedComponent(selectedSwapComponent);
    },
    [selectedSwappedComponent, selectedComponent, selectedRemovedComponentIdList]
  );

  const handleOriginalComponent = (originalComponentId: string) => {
    if (selectedComponent?.id === originalComponentId && selectedRemovedComponentIdList.includes(originalComponentId)) {
      handleRemoveComponents(originalComponentId);
    } else if (selectedComponent?.id !== originalComponentId) {
      handleSwapComponent(selectedComponent!.id, originalComponentId);
    }
  };

  return (
    <>
      {isLoading ? (
        <CaloLoader />
      ) : (
        <>
          <Box sx={{ ...styles.mainContainer }}>
            <Grid container sx={{ ...styles.mainGrid }}>
              {(foodComponentsData ?? []).map((component, index) => (
                <ComponentItem
                  key={component.id}
                  component={component}
                  index={index}
                  foodComponents={components ?? []}
                  selectedComponent={selectedComponent}
                  selectedComponentIndex={selectedComponentIndex}
                  selectedRemovedComponentIdList={selectedRemovedComponentIdList}
                  setSelectedComponent={setSelectedComponent}
                  setSelectedComponentIndex={setSelectedComponentIndex}
                />
              ))}
            </Grid>
          </Box>
          {selectedComponent && (
            <Box sx={{ ...styles.customComponentContainer }}>
              <Stack>
                <Typography sx={{ ...styles.changeText }}>Change</Typography>
              </Stack>
              <Grid container sx={{ ...styles.mainGrid }}>
                <Grid item>
                  <Stack onClick={() => handleRemoveComponents(selectedComponent.id)} style={{ width: 120 }}>
                    <Stack style={{ width: 110, position: 'relative', ...styles.removeComponent }}>
                      <RemoveCircleOutlineIcon
                        sx={{
                          ...styles.removeIcon,
                          color: selectedRemovedComponentIdList.includes(selectedComponent?.id)
                            ? caloTheme.palette.red
                            : caloTheme.palette.neutral600
                        }}
                      />
                    </Stack>
                    <Stack
                      sx={{
                        ...styles.removeComponentStack
                      }}
                    >
                      <Typography
                        sx={{
                          ...styles.componentNameText
                        }}
                      >
                        Remove Component
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                {components && (
                  <Grid item>
                    <Stack
                      onClick={() => handleOriginalComponent(components[selectedComponentIndex].id)}
                      style={{ width: 120, marginTop: '-14px' }}
                    >
                      <ComponentCard
                        radioCheck={true}
                        foodComponents={components ?? []}
                        selectedComponent={selectedComponent}
                        selectedComponentIndex={selectedComponentIndex}
                        selectedRemovedComponentIdList={selectedRemovedComponentIdList}
                        component={components[selectedComponentIndex]}
                      />
                    </Stack>
                  </Grid>
                )}
                {caloKidsComponents
                  .filter((component) => component.name?.en !== components[selectedComponentIndex].name?.en)
                  // to be fixed here
                  .filter((component) => {
                    const caloKidsSection = (component.sections ?? []).find(
                      (section) => section.category === CustomMealCategory.caloKids
                    );
                    return (
                      caloKidsSection?.tag ===
                      (components[selectedComponentIndex]?.sections ?? []).find(
                        (section) => section.category === CustomMealCategory.caloKids
                      )?.tag
                    );
                  })
                  .map((kidsComp, index) => {
                    return (
                      <Grid item key={index}>
                        <Box
                          key={kidsComp.id}
                          onClick={() => handleSwapComponent(selectedComponent.id, kidsComp.id, true)}
                          style={{
                            marginTop: '-12px',
                            ...styles.componentCard
                          }}
                        >
                          <ComponentCard
                            radioCheck={true}
                            foodComponents={components}
                            selectedComponent={selectedComponent}
                            selectedComponentIndex={selectedComponentIndex}
                            selectedRemovedComponentIdList={selectedRemovedComponentIdList}
                            component={kidsComp}
                          />
                        </Box>
                      </Grid>
                    );
                  })}
                {customFoodComponents &&
                  ((selectedComponent?.sections ?? []).find((section) => section.category === CustomMealCategory.meal)?.tag ===
                    FoodComponentType.sauce ||
                    isPreBuiltMeal) &&
                  customFoodComponents
                    ?.filter((component) => component.name?.en !== components[selectedComponentIndex].name?.en)
                    .map((customComp, index) => (
                      <Grid item key={index}>
                        <Box
                          key={customComp.id}
                          onClick={() => handleSwapComponent(selectedComponent.id, customComp.id)}
                          style={{
                            marginTop: '-12px',
                            ...styles.componentCard
                          }}
                        >
                          <ComponentCard
                            radioCheck={true}
                            foodComponents={components}
                            selectedComponent={selectedComponent}
                            selectedComponentIndex={selectedComponentIndex}
                            selectedRemovedComponentIdList={selectedRemovedComponentIdList}
                            component={customComp}
                          />
                        </Box>
                      </Grid>
                    ))}
              </Grid>
            </Box>
          )}
          <Box>
            <Stack
              sx={{
                backgroundColor: caloTheme.palette.grey[50],
                padding: 1,
                borderRadius: 2,
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Typography sx={{ fontFamily: caloTheme.typography.body1, fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>
                Save changes for the future
              </Typography>
              <IOSSwitch onChange={() => setApplyAlwaysForMeal(!applyAlwaysForMeal)} checked={applyAlwaysForMeal} />
            </Stack>
          </Box>
        </>
      )}
    </>
  );
};
export default CustomizeMealCard;
