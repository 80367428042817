import { Food } from '@calo/types';
import { Box, Button, Stack, Typography } from '@mui/material';
import { deleteAddonMenu, deleteFoodMenu } from 'actions/delivery';
import mutation from 'actions/mutation';
import { caloTheme } from 'assets/images/theme/calo';
import Popup from 'components/Popup';
import { Delivery, MenuFood } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import { forwardRef } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation } from 'react-query';

interface ConfirmDeleteMealPopup {
  delivery: Delivery;
  selectedDeleteFood?: Food;
  handleClose: () => void;
  setSelectedDeleteFood: React.Dispatch<React.SetStateAction<Food | undefined>>;
  refetchDelivery: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<unknown, unknown>>;
}

const ConfirmDeleteMealPopup = forwardRef(
  (
    { delivery, selectedDeleteFood, handleClose, setSelectedDeleteFood, refetchDelivery }: ConfirmDeleteMealPopup,
    confirmModalRef
  ) => {
    const { mutateAsync: deleteAddonMutation } = useMutation(deleteAddonMenu);
    const { mutateAsync: deleteFoodMutation } = useMutation(deleteFoodMenu);

    const deleteMenuFood = async (food: MenuFood) => {
      await deleteFoodMutation(
        {
          id: delivery.id,
          foodId: food.id
        },
        {
          onSuccess: (data) => {
            const query = queryClient.getQueryData(['deliveries', delivery.id]) as any;
            const deliveryUpdated = { ...query, food: data.food, cost: data.cost };
            mutation(['deliveries', delivery.id], deliveryUpdated);
            handleClose();
            setSelectedDeleteFood(undefined);
            refetchDelivery();
          }
        }
      );
    };

    const deleteMenuAddon = async (addon: MenuFood) => {
      await deleteAddonMutation(
        {
          id: delivery.id,
          addonId: addon.id
        },
        {
          onSuccess: (data) => {
            const query = queryClient.getQueryData(['deliveries', delivery.id]) as any;
            const deliveryUpdated = { ...query, addons: data.addons, cost: data.cost };
            mutation(['deliveries', delivery.id], deliveryUpdated);
            handleClose();
            setSelectedDeleteFood(undefined);
            refetchDelivery();
          }
        }
      );
    };

    return (
      <Popup ref={confirmModalRef} title={'Confirm Delete Meal'} onClose={handleClose}>
        <Box>
          <Stack>
            <Typography
              display={'flex'}
              flexDirection={'row'}
              sx={{
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                color: caloTheme.palette.neutral900
              }}
            >
              {selectedDeleteFood?.name.en}
            </Typography>
          </Stack>
          <Stack display={'felx'} flexDirection={'row'} justifyContent={'center'} sx={{ mt: 3 }}>
            <Button
              aria-label="addAddressButton"
              sx={{
                width: 'auto',
                height: '45px',
                mx: 2,
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                borderRadius: '8px',
                color: caloTheme.palette.neutral600,
                '&:hover': {
                  backgroundColor: caloTheme.palette.neutral100,
                  borderColor: caloTheme.palette.neutral100
                }
              }}
              onClick={() => {
                handleClose();
                setSelectedDeleteFood(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              aria-label="confirm-remove-meal"
              sx={{
                width: 'auto',
                height: '45px',
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                borderRadius: '8px',
                boxShadow: 'none',
                padding: '14px 20px 14px 20px',
                backgroundColor: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary500,
                color: 'white',
                '&:hover': {
                  color: 'white',
                  backgroundColor: caloTheme.palette.primary600,
                  borderColor: caloTheme.palette.primary600
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  marginTop: 2,
                  width: '75%',
                  mx: 1
                }
              }}
              onClick={() =>
                delivery!.addons?.find((addon) => addon.id === selectedDeleteFood!.id)
                  ? deleteMenuAddon(selectedDeleteFood!)
                  : deleteMenuFood(selectedDeleteFood!)
              }
            >
              Confirm
            </Button>
          </Stack>
        </Box>
      </Popup>
    );
  }
);

export default ConfirmDeleteMealPopup;
