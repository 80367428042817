import { createSubscription } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CreateSubscriptionReq, Subscription } from '@calo/dashboard-types';
import { Box, Card, Stack, Typography } from '@mui/material';

import SubscriptionForm from './SubscriptionForm';

const NewSubscription = (subscription: Subscription) => {
  const history = useHistory();
  const { mutateAsync: createMutation } = useMutation(createSubscription);

  const handleCreateSubscription = async (values: CreateSubscriptionReq) => {
    try {
      const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);
      const ns = await createMutation({ ...values, phoneNumber: phoneNumber!.number as string });
      history.replace(Routes.subscription.replace(':id', ns.id));
    } catch (error: any) {
      if (error.message.includes('409')) {
        toast('Subscription already exists', { type: 'error', autoClose: 2000 });
      } else {
        toast('error', { type: 'error', autoClose: 2000 });
      }
    }
  };
  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          border: 'none',
          mb: 2,
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          display={'flex'}
          flexDirection="row"
          justifyContent={'space-between'}
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
          width="100%"
        >
          <Stack>
            <Typography
              variant="h6"
              sx={{
                mt: 'auto',
                fontWeight: 600,
                fontSize: '33px',
                lineHeight: '40px',
                color: caloTheme.palette.neutral900,
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              Subscriptions
            </Typography>
          </Stack>
        </Box>
      </Card>
      <Card
        variant="outlined"
        sx={{
          mb: 2,
          width: 'full',
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Stack padding={2}>
          <Typography
            sx={{
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '19px',
              lineHeight: '23px',
              color: caloTheme.palette.neutral900
            }}
          >
            Create Subscription
          </Typography>
        </Stack>
        <Stack>
          <SubscriptionForm onSubmit={handleCreateSubscription} subscription={subscription} />
        </Stack>
      </Card>
    </>
  );
};
export default NewSubscription;
