import { Permission, RatingFilters } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';
import { Icon as IconifyIcons } from '@iconify/react';
import { Box, Card, IconButton, Stack, Typography } from '@mui/material';
import { exportFeedBack, getListWithParams, toggleUISettings } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Pagination } from 'components';
import ExportButton from 'components/ExportButton';
import FeedbackTable from 'components/FeedbackTable';
import { getAccessibleCountries, getKitchenOptions } from 'lib/helpers';
import history from 'lib/history';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { FeedBackData } from 'lib/interfaces';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import Settings from './Settings';

interface PaginatedFeedback {
  data: FeedBackData[];
  meta: {
    limit: number;
    total: number;
  };
}

const Feedback = () => {
  const [page, setPage] = useState(0);
  const roles = useUserRoles();
  const location = useLocation() as any;
  const searchParams = new URLSearchParams(location.search);
  const userKitchens: Kitchen[] = useUserKitchens();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState<RatingFilters>({
    foodId: location.state?.id,
    brand: Brand.CALO,
    country: getAccessibleCountries(userKitchens)[0],
    kitchen: userKitchens && getKitchenOptions(userKitchens, getAccessibleCountries(userKitchens)[0])[0].value,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data, isLoading } = useQuery<any, Error, PaginatedFeedback>(
    [
      'ratings',
      {
        page,
        filters: {
          ...filters,
          foodName: filters.foodName && filters.foodName!.length > 0 ? filters.foodName : undefined,
          country: filters.country,
          brand: filters.brand
        }
      }
    ],
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  useEffect(() => {
    if (location.state && location?.state.id) {
      setFilters({ ...filters, foodId: location.state?.id });
      const state = { ...location.state };
      delete state.id;
      history.replace(location.pathname);
    }
  }, []);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: 2,
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          display={'flex'}
          flexDirection="row"
          justifyContent={'space-between'}
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
          width="100%"
        >
          <Stack width={'50%'} display={'flex'} flexDirection={'row'} alignItems="start" justifyContent={'space-between'}>
            <Typography
              variant="h3"
              sx={{
                mt: 'auto',
                fontWeight: 600,
                fontSize: '33px',
                lineHeight: '40px',
                color: caloTheme.palette.neutral900,
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              Customer Feedback - {filters.kitchen}
            </Typography>
          </Stack>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems="end"
            sx={{
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
                flexDirection: 'column'
              }
            }}
          >
            <span className="mr-4">
              <IconButton>
                <IconifyIcons
                  icon="fluent:filter-16-filled"
                  color={caloTheme.palette.neutral900}
                  onClick={() => dispatch(toggleUISettings())}
                />
              </IconButton>
              <ExportButton
                isDisabled={(data && data.data.length === 0) || isLoading || !roles.includes(Permission.EXPORT_RATING_LIST)}
                isLoading={isLoading}
                onClick={() => exportFeedBack(filters)}
              />
            </span>
          </Stack>
        </Box>
      </Card>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <>
          <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px'
              }}
            >
              Feedback List
            </Typography>

            {isLoading ? (
              <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                <CaloLoader />
              </Stack>
            ) : (
              <FeedbackTable data={data?.data || []} />
            )}
          </Box>
          {data && (
            <Pagination
              isHidden={data.data.length === 0}
              limit={data.meta.limit}
              total={data.meta.total}
              page={page}
              onChange={setPage}
            />
          )}
          <Settings onFilter={setFilters} filters={filters} />
        </>
      </Card>
    </>
  );
};

export default Feedback;
