import { CouponUsedByFilters } from '@calo/dashboard-types';
import { toggleUISettings } from 'actions';
import { Input, SideDrawer } from 'components';
import { AppState } from 'lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';

interface SettingsProps {
  onFilter: (values: CouponUsedByFilters) => any;
  filters: CouponUsedByFilters;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Input
          label="Phone Number"
          value={filters.phoneNumber}
          onChange={(e) => onFilter({ ...filters, phoneNumber: e.target.value.length > 0 ? e.target.value : undefined })}
          debounce
        />
      </section>
    </SideDrawer>
  );
};

export default Settings;
