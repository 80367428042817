import { Box, styled, Table, TableCell, tableCellClasses, ThemeProvider, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { theme } from 'lib/componentStyles';
import { StatsFoodComponent } from 'lib/interfaces';
import { useState } from 'react';
import ComponentsBody from './ComponentsBody';
import TableHeader from './TableHeader';

interface MainTableProps {
  component: StatsFoodComponent;
}

export default function MainTable({ component }: MainTableProps) {
  const componentMealStats = component?.keyedFood ? Object.values(component.keyedFood) : [];
  const [switchUnitDrawer, setSwitchUnitDrawer] = useState(false);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '19px',
      textTransform: 'capitalize'
    }
  }));

  return (
    <Table sx={{ marginY: '4px', minHeight: '120px', overflow: 'auto', width: '100%' }}>
      <ThemeProvider theme={theme}>
        <Box display={'flex'} flexDirection={'row'}>
          <Typography
            sx={{
              color: caloTheme.palette.neutral900,
              fontSize: '12px',
              lineHeight: '14px',
              mx: '6px',
              mt: switchUnitDrawer ? '4px' : '12px',
              fontFamily: 'Roboto',
              fontWeight: 600
            }}
          >
            {switchUnitDrawer ? 'All Units' : 'gram/ml'}
          </Typography>
        </Box>
      </ThemeProvider>
      <TableHeader component={component} switchUnitDrawer={switchUnitDrawer} setSwitchUnitDrawer={setSwitchUnitDrawer} />
      {componentMealStats.length > 0 ? (
        <ComponentsBody component={component} switchUnit={switchUnitDrawer} />
      ) : (
        <StyledTableCell style={{ border: 0 }} colSpan={12}>
          <Typography
            sx={{
              mt: 3,
              textAlign: 'center',
              width: 'full',
              fontSize: '24px',
              color: caloTheme.palette.neutral400
            }}
          >
            No Meals
          </Typography>
        </StyledTableCell>
      )}
    </Table>
  );
}
