import { caloTheme } from 'assets/images/theme/calo';

import { AddonAddedAsType } from '@calo/dashboard-types';
import { Button, TableCell, TableRow } from '@mui/material';

interface AddMealAddonProps {
  addonModalRef: any;
  setSelectedAddType: React.Dispatch<React.SetStateAction<AddonAddedAsType>>;
  setFetchMenu: (value: boolean) => void;
}

const AddMealAddonRow = ({ addonModalRef, setSelectedAddType, setFetchMenu }: AddMealAddonProps) => {
  return (
    <TableRow>
      <TableCell colSpan={7} style={{ width: '100%', textAlign: 'center' }}>
        <Button
          variant="contained"
          sx={{
            textAlign: 'center',
            mr: 2,
            height: '45px',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '17px',
            borderRadius: '8px',
            boxShadow: 'none',
            textTransform: 'none',
            color: caloTheme.palette.white,
            backgroundColor: caloTheme.palette.primary500,
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: caloTheme.palette.primary600,
              color: caloTheme.palette.white
            },
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              justifyItems: 'center',
              margin: 'auto',
              marginTop: 4,
              width: 'auto'
            }
          }}
          onClick={() => {
            setFetchMenu(true);
            setSelectedAddType(AddonAddedAsType.meal);
            addonModalRef.current?.open();
          }}
        >
          Add Meal
        </Button>
        <Button
          variant="contained"
          sx={{
            textAlign: 'center',
            height: '45px',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '17px',
            borderRadius: '8px',
            boxShadow: 'none',
            textTransform: 'none',
            color: caloTheme.palette.white,
            backgroundColor: caloTheme.palette.primary500,
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: caloTheme.palette.primary600,
              color: caloTheme.palette.white
            },
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              justifyItems: 'center',
              margin: 'auto',
              marginTop: 4,
              width: 'auto'
            }
          }}
          onClick={() => {
            setSelectedAddType(AddonAddedAsType.addon);
            addonModalRef.current?.open();
          }}
        >
          Add Addon
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default AddMealAddonRow;
