import { useMemo } from 'react';

import { getImageUploadLink, getListWithParams } from 'actions';
import { PackageElementsPicker } from 'components/index';
import ImageUploaderMUI from 'components/MUI/ImageUploaderMUI';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { FormikErrors, FormikTouched } from 'formik';
import { ImageType, PackageType } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { FoodPackageElement, UpdateFoodPackageReq } from 'lib/interfaces';
import { keyBy } from 'lodash-es';
import { useQuery } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import { Card, MenuItem, Stack, TextField, Typography } from '@mui/material';

interface PackageInformationCardProps {
  foodPackage: FoodPackageElement;
  values: UpdateFoodPackageReq;
  errors: FormikErrors<UpdateFoodPackageReq>;
  touched: FormikTouched<UpdateFoodPackageReq>;
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<UpdateFoodPackageReq>>;
  setValues: (values: UpdateFoodPackageReq) => void;
}

const PackageInformationCard = ({
  foodPackage,
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  setValues
}: PackageInformationCardProps) => {
  const userRoles = useUserRoles();
  const { data: packageElementList } = useQuery<any, Error, { data: FoodPackageElement[] }>(
    [
      'food-package-elements',
      {
        limit: 1000,
        filters: { country: foodPackage.country, brand: foodPackage.brand, kitchen: foodPackage.kitchen }
      }
    ],
    getListWithParams,
    {
      suspense: true
    }
  );

  const handleElementsChange = (rows: FoodPackageElement[]) => {
    setValues({
      ...values,
      elements: rows
    });
  };
  const packageElements = packageElementList?.data || [];
  const keyed = useMemo(() => keyBy(packageElements, 'id'), []);

  return (
    <InputThemeProvider>
      <Card
        variant="outlined"
        sx={{
          marginTop: '16px',
          border: 'none',
          borderRadius: '16px',
          py: '20px',
          px: '12px',
          zIndex: 100,
          display: 'flex',
          flexDirection: 'column',
          gap: '20px'
        }}
      >
        <Typography variant="h5" sx={{ fontSize: '19px', fontWeight: 600 }}>
          Information
        </Typography>
        <Stack display="flex" flexDirection="row" gap="13px" sx={{ width: '100%' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ maxWidth: '340px', width: '20%', maxHeight: '320px' }}
          >
            <ImageUploaderMUI
              key={foodPackage.id}
              uploadLink={() => getImageUploadLink(`food-package/${foodPackage.id}`)}
              image={`${process.env.REACT_APP_BUCKET_URL}/food-package/${foodPackage.id}/square@1x.jpg`}
              disabled={userRoles.includes(Permission.UPLOAD_IMAGE)}
              id={foodPackage.id}
              imageType={ImageType.PACKAGE}
            />
          </Stack>
          <Stack sx={{ display: 'flex', flexDirection: 'column', gap: '13px', width: '100%' }}>
            <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '13px', width: '100%', px: 2 }}>
              <Stack sx={{ width: '50%' }}>
                <TextField
                  label="Name"
                  name="name"
                  id="name"
                  placeholder="Enter Name"
                  value={values.name}
                  sx={{ width: '100%' }}
                  disabled={!userRoles.includes(Permission.UPDATE_FOOD_PACKAGE)}
                  onChange={(data) => setFieldValue('name', data.target.value)}
                  onBlur={handleBlur}
                  error={touched.name && !!errors.name}
                />
              </Stack>
              <TextField
                select
                label="Type*"
                value={values.type}
                name="type"
                placeholder="Enter Type"
                sx={{ width: '50%' }}
                disabled={!userRoles.includes(Permission.UPDATE_FOOD_PACKAGE)}
                onChange={(data) => setFieldValue('type', data.target.value)}
                onBlur={handleBlur}
                error={touched.type && !!errors.type}
              >
                {Object.values(PackageType).map((type) => (
                  <MenuItem value={type}>{type}</MenuItem>
                ))}
              </TextField>
            </Stack>
            <PackageElementsPicker
              onChange={handleElementsChange}
              value={values.elements || []}
              keyed={keyed}
              list={packageElements}
              isDisabled={!userRoles.includes(Permission.UPDATE_FOOD_PACKAGE)}
            />
          </Stack>
        </Stack>
      </Card>
    </InputThemeProvider>
  );
};

export default PackageInformationCard;
