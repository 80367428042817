import { differenceInDays, endOfWeek, format, parseISO, startOfWeek } from 'date-fns';
import { FoodComponent, IngredientHeaderItem } from 'lib/interfaces';
import { orderBy, uniqBy } from 'lodash-es';

export const filterAndSortUsedOnMenu = (foodComponent?: FoodComponent) => {
  return uniqBy(
    orderBy(foodComponent?.usedOnMenu || [], ['date', 'week'], ['asc'])?.filter((r) => {
      if (r.date) {
        return differenceInDays(parseISO(r.date), parseISO(format(+Date.now(), 'yyyy-MM-dd'))) >= 0;
      } else {
        const weekNumber = r.week!.toString().split('#');
        const date = new Date(+weekNumber[0], 0, 1 + +weekNumber[1] * 7);
        const weekDate = date;
        weekDate.setDate(date.getDate() - 7);
        return (
          differenceInDays(parseISO(format(startOfWeek(weekDate), 'yyyy-MM-dd')), parseISO(format(+Date.now(), 'yyyy-MM-dd'))) &&
          differenceInDays(+endOfWeek(weekDate), Date.now()) >= 0
        );
      }
    }),
    'date'
  );
};

export const findNumberOfIngredientHeaders = (structuredIngredients: IngredientHeaderItem[]) => {
  return structuredIngredients.reduce((acc, structuredIngredient) => {
    return structuredIngredient.type === 'header' ? acc + 1 : acc;
  }, 0);
};

export const isNewValidHeader = (newHeader: string | null | undefined, currentHeader: string | null | undefined) => {
  return newHeader !== currentHeader && newHeader !== null && newHeader !== undefined;
};
