import { getListWithParams, quickSearch } from 'actions';
import { createSpecialReq } from 'actions/specialRequest';
import { caloTheme } from 'assets/images/theme/calo';
import { AttachFile, Autocomplete, ModalRef } from 'components';
import Popup from 'components/Popup';
import SpecialRequestChipsRenderer from 'components/SpecialRequestChipsRenderer';
import { format } from 'date-fns/fp';
import { customStylesComplaints } from 'lib/componentStyles';
import { handleUploadImage } from 'lib/helpers';
import { useUserData } from 'lib/hooks';
import { AddedGiftMealData, PaginatedDeliveries, SpecialRequestState } from 'lib/interfaces';
import { compact, groupBy, map, upperFirst } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { CreateGiftReq, GiftItemType } from '@calo/dashboard-types';
import { Brand, Country, DeliveryStatus, Kitchen } from '@calo/types';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, MenuItem, Stack, TextField, Typography } from '@mui/material';

import DatePicker from '../DatePicker';
import AddMealsPopup from './AddMealsPopup';
import styles from './styles';

interface CreateSpecialRequestProps {
  refetchRequestList: any;
}

const CreateSpecialRequest = ({ refetchRequestList }: CreateSpecialRequestProps) => {
  const creator = useUserData();

  const [user, setUser] = useState<any>();
  const addMealsRef = useRef<ModalRef>(null);
  const [progress, setProgress] = useState(0);
  const [comment, setComment] = useState<string>('');
  const attachmentMethodImageRef = useRef<ModalRef>();
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingCreateReq, setLoadingCreateReq] = useState(false);
  const [uuIDCreate, setUuIDCreate] = useState<string>('');
  const [displayImage, setDisplayImage] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const { mutateAsync: createMutation } = useMutation(createSpecialReq);
  const [selectedMeals, setSelectedMeals] = useState<AddedGiftMealData[]>([]);
  const [createSpecialRequest, setCreateSpecialRequest] = useState<SpecialRequestState>({
    user: undefined,
    delivery: undefined,
    comment: '',
    attachment: '',
    requests: []
  });

  const handleDeleteChip = (selectedType: any) => {
    setCreateSpecialRequest({
      ...createSpecialRequest,
      requests: createSpecialRequest.requests.filter((request) => request !== selectedType)
    });
  };

  const handleConfirmAddMeals = () => {
    if (selectedMeals.length === 0) {
      return;
    }
    setCreateSpecialRequest({
      ...createSpecialRequest,
      requests: [...createSpecialRequest.requests, ...selectedMeals]
    });
    setSelectedMeals([]);
    addMealsRef.current?.close();
  };

  const handleCloseAddMealsPopup = () => {
    setSelectedMeals([]);
    addMealsRef.current?.close();
  };

  const { data } = useQuery<any, Error, PaginatedDeliveries>(
    [
      'deliveries',
      {
        page: 0,
        filters: {
          driverId: '',
          day: {
            lte: selectedDate,
            gte: selectedDate
          },
          status: [DeliveryStatus.paymentRequired, DeliveryStatus.upcoming],
          skipped: false,
          brand: user?.brand,
          country: user?.country,
          phoneNumber: user?.phoneNumber
        }
      }
    ],
    getListWithParams,
    {
      keepPreviousData: false,
      enabled: !!user && !!selectedDate,
      onSuccess: (data) => {
        const index = data.data.findIndex((delivery) => delivery.day === selectedDate);
        if (index >= 0) {
          setUser({
            ...user!,
            kitchen: data.data[index]!.kitchen || Kitchen.BH1,
            country: data.data[index]!.country,
            driver: data.data[index]!.driver,
            deliveryTime: data.data[index]!.time,
            deliveryId: data.data[index]!.id
          });
        } else {
          setUser({
            ...user!,
            kitchen: data.data[0]?.kitchen || Kitchen.BH1,
            country: data.data[0]?.country,
            driver: data.data[0]?.driver,
            deliveryTime: data.data[0]?.time,
            deliveryId: ''
          });
          toast(`No delivery for the selected customer on ${selectedDate}`, { type: 'error', autoClose: 2000 });
        }
      }
    }
  );

  const handleClear = () => {
    setUser(undefined);
    setComment('');
    setSelectedDate(null);
    setDisplayImage([]);
    setCreateSpecialRequest({
      user: undefined,
      delivery: undefined,
      comment: '',
      attachment: '',
      requests: []
    });
  };

  useEffect(() => {
    if (user) {
      setUuIDCreate(uuid());
    }
    if (data && user?.deliveryId && data.data[0]?.id === user.deliveryId) {
      setLoadingCreateReq(false);
    }
  }, [user, data]);

  useEffect(() => {
    if (!selectedDate && user?.deliveryId) {
      setUser((prevUser: any) => ({
        ...prevUser,
        deliveryId: undefined
      }));
    }
  }, [selectedDate, user?.deliveryId]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    handleUploadImage({
      disabled: !user,
      files,
      setDisplayImage,
      setLoadingImage,
      setProgress,
      urlFile: 'gifts',
      uuIDCreate: user.deliveryId
    });
  };

  const handleCreateRequest = async () => {
    setLoadingCreateReq(true);
    const createValues: CreateGiftReq = {
      kitchen: user!.kitchen,
      items: createSpecialRequest.requests || [],
      userId: user!.id,
      deliveryId: user.deliveryId,
      date: selectedDate!,
      comment: comment.length > 0 ? comment : '',
      attachments: compact(displayImage.map((link) => link.split('/')[2])) || [],
      deliveryTime: user!.deliveryTime,
      userName: user.name,
      phoneNumber: user!.phoneNumber,
      createdBy: {
        name: creator.name,
        email: creator.email,
        id: creator.id
      }
    };
    const complaintsReq = await createMutation(createValues);
    if (complaintsReq) {
      handleClear();
      refetchRequestList();
    }
    setLoadingCreateReq(false);
  };

  const handleChooseRequest = (data: any) => {
    if (data.target.value === GiftItemType.meal) {
      addMealsRef.current?.open();
    } else {
      setCreateSpecialRequest({
        ...createSpecialRequest,
        requests: [
          ...createSpecialRequest.requests,
          {
            type: data.target.value,
            amount: 1
          }
        ]
      });
    }
  };

  return (
    <>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width="100%">
        <Typography sx={{ ...styles.cardTitle }}> Special Request</Typography>
      </Box>

      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        sx={{
          paddingY: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: { flexDirection: 'column', width: '100%' }
        }}
      >
        <Stack
          width={'50%'}
          sx={{
            mr: 1,
            display: 'flex',
            minWidth: '45%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column',
              width: '100%',
              mb: 2
            }
          }}
        >
          <Autocomplete
            onSearch={(text) =>
              quickSearch({
                limit: 10,
                query: text,
                page: 1
              })
            }
            showSelectedVal
            transformer={(data: any[]) =>
              map(groupBy(data, 'type'), (group, key) => {
                if (key === 'subscription') {
                  const transformedGroup = group
                    .map((row: any) => {
                      if (row.type === 'subscription') {
                        return {
                          value: row.id,
                          type: row.type,
                          data: row,
                          phoneNumber: row.phoneNumber.replace('+', ''),
                          name: row.name,
                          label: `${row.name}, ${row.phoneNumber}, ${row.email}, ${row.brand ? row.brand : Brand.CALO}, ${row.country || Country.BH}, ${row.kitchen || Kitchen.BH1}`
                        };
                      }
                      return null;
                    })
                    .filter(Boolean);
                  return {
                    label: key,
                    options: transformedGroup
                  };
                }
                return null;
              }).filter(Boolean)
            }
            values={user ? user.label : ''}
            customStyles={customStylesComplaints}
            placeHolder={true}
            placeHolderValue={user ? user.label : 'Search User'}
            onPick={(data: any) =>
              data === undefined
                ? console.log('data is undefined')
                : (setUser({
                    ...user,
                    name: data.name,
                    id: data.value,
                    email: data.data.email,
                    phoneNumber: data.phoneNumber,
                    country: data.country,
                    brand: data.brand || Brand.CALO,
                    label: data.label,
                    kitchen: data.data.kitchen || Kitchen.BH1,
                    deliveryTime: data.data.deliveryTime,
                    driver: data.data.driver
                  }),
                  setCreateSpecialRequest({ ...createSpecialRequest, user: data.data }))
            }
          />
        </Stack>
        <Stack
          width={'35%'}
          sx={{
            mx: 1,
            height: '52px',
            minWidth: '35%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column',
              width: '100%',
              mb: 2,
              mx: 0
            }
          }}
        >
          <TextField
            select
            name="request"
            sx={{ width: '100%', zIndex: 0, height: '51px' }}
            label="Request"
            placeholder="Select Request Type..."
            disabled={!user}
            value={createSpecialRequest.requests}
            onChange={(data: any) => handleChooseRequest(data)}
            InputProps={{
              inputProps: {
                style: { borderRadius: 8, height: '51px' }
              },
              placeholder: 'Select Request Type...',
              style: { borderRadius: 8, height: '51px' }
            }}
            InputLabelProps={{
              style: { height: '51px' },
              shrink: true
            }}
          >
            {Object.entries(GiftItemType).map(([key, value]) => (
              <MenuItem
                key={key}
                value={value}
                disabled={createSpecialRequest.requests.map((r) => r.type).find((t) => t === value && t !== GiftItemType.meal)}
                sx={{ textTransform: 'normal' }}
              >
                {upperFirst(value)}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack
          sx={{
            width: '20%',
            height: '52px',
            minWidth: '18%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column',
              width: '100%',
              mb: 2,
              mx: 0
            }
          }}
        >
          <DatePicker
            width={'full'}
            disabled={!user}
            key={uuIDCreate}
            label={'Select Day'}
            disablePastDates
            disableLockupTime
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            onChange={(endDate: any) => {
              setLoadingCreateReq(true);
              setUser({ ...user, deliveryId: undefined });
              setSelectedDate(endDate === null ? null : format('yyyy-MM-dd')(endDate as Date));
            }}
          />
        </Stack>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingY: 2 }}>
        <Stack width={'100%'}>
          <TextField
            label="Comment"
            multiline
            minRows={1}
            sx={{ zIndex: 0, borderRadius: '8px' }}
            name="comment"
            value={comment}
            placeholder="Add Comment"
            onChange={(data: any) => setComment(data.target.value)}
            InputProps={{
              style: { borderRadius: 8, zIndex: 0 }
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <SpecialRequestChipsRenderer key={uuid()} items={createSpecialRequest.requests} onDelete={handleDeleteChip} />
        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '50%' }}>
          <LoadingButton
            loading={loadingImage && progress !== 100}
            variant="outlined"
            sx={{ ...styles.attachFileButton }}
            disabled={
              !user || user.deliveryId?.length === 0 || (loadingImage && progress !== 100) || loadingCreateReq || !selectedDate
            }
            onClick={() => displayImage.length > 0 && attachmentMethodImageRef.current?.open()}
            startIcon={
              loadingImage ? (
                'loading'
              ) : displayImage.length > 0 ? (
                <></>
              ) : (
                <AttachFileIcon style={{ transform: 'rotate(45deg)' }} />
              )
            }
          >
            {displayImage.length === 0 ? (
              <>
                <input
                  accept="image/*"
                  type="file"
                  multiple
                  size={2}
                  className="file-input fas fa-paperclip mt-2 cursor-pointer float-right "
                  key={uuid()}
                  onChange={handleFileChange}
                />
                {loadingImage ? '' : 'Attach Files'}
              </>
            ) : (
              <>{loadingImage ? '' : 'View Attached File'}</>
            )}
          </LoadingButton>

          <LoadingButton
            variant="contained"
            sx={{ ...styles.createRequestButton }}
            onClick={() => handleCreateRequest()}
            disabled={
              !user ||
              user.deliveryId?.length === 0 ||
              (loadingImage && progress !== 100) ||
              !selectedDate ||
              loadingCreateReq ||
              createSpecialRequest.requests.length === 0
            }
          >
            Create Request
          </LoadingButton>
        </Stack>
      </Box>

      <Popup
        fullWidth
        title="Add"
        maxWidth="laptop"
        ref={addMealsRef}
        onClose={handleCloseAddMealsPopup}
        info={
          <>
            <CloseIcon width={24} height={24} onClick={handleCloseAddMealsPopup} sx={{ cursor: 'pointer' }} />
          </>
        }
      >
        <AddMealsPopup
          selectedMeals={selectedMeals}
          setSelectedMeals={setSelectedMeals}
          handleClosePopup={handleCloseAddMealsPopup}
          handleConfirmAddMeals={handleConfirmAddMeals}
          brand={createSpecialRequest.user?.brand || Brand.CALO}
          kitchen={createSpecialRequest.user?.kitchen || Kitchen.BH1}
        />
      </Popup>

      <Popup
        maxWidth="lg"
        fullWidth
        title="Attached File"
        ref={attachmentMethodImageRef}
        onClose={() => attachmentMethodImageRef.current?.close()}
        info={
          <Button disabled={loadingImage}>
            <input
              accept="image/*"
              type="file"
              multiple
              size={2}
              className="file-input fas fa-paperclip mt-2 cursor-pointer float-right "
              key={uuid()}
              onChange={handleFileChange}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                mt: 1,
                cursor: 'pointer',
                lineHeight: '19px',
                color: caloTheme.palette.neutral900
              }}
            >
              {loadingImage ? 'Loading...' : '+ Add File'}
            </Typography>
          </Button>
        }
      >
        <AttachFile
          viewType="create"
          key={uuIDCreate}
          urlFile={'gifts'}
          uuIDCreate={user?.deliveryId}
          displayImage={displayImage}
          setDisplayImage={setDisplayImage}
        />
      </Popup>
    </>
  );
};
export default CreateSpecialRequest;
