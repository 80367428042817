import { useRef } from 'react';

import { addPerksEmployee } from 'actions';
import { toggleUISettings } from 'actions/ui';
import { caloTheme } from 'assets/images/theme/calo';
import ExportButton from 'components/ExportButton';
import Popup from 'components/Popup';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { PerksStatus } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { EmployeePerks, EmployeePerksInfoReq, PerksFilters } from 'lib/interfaces';
import { isEmpty } from 'lodash';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { Permission } from '@calo/dashboard-types';
import { Icon as Iconify } from '@iconify/react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Card, IconButton, Stack, Typography } from '@mui/material';

import { nextPerkMonth, nextPerkYear } from '../Settings/helpers';
import AddEmployeeModal from './AddEmployeeModal';
import { prepareForExport } from './Export';

interface PackageElementHeaderCardProps {
  filters: PerksFilters;
  employeePerks: EmployeePerks;
  isDisabled?: boolean;
  setEmployeePerks: (employeePerks: EmployeePerks) => void;
  isLoading?: boolean;
}

const EmployeePerksHeaderCard = ({
  filters,
  employeePerks,
  isDisabled,
  setEmployeePerks,
  isLoading
}: PackageElementHeaderCardProps) => {
  const userRoles = useUserRoles();
  const dispatch = useDispatch();
  const { mutateAsync: addEmployeeMutation } = useMutation(addPerksEmployee);
  const addEmployeeModalRef = useRef(null);
  const handleAddingEmployee = async (employees: EmployeePerksInfoReq[]) => {
    await addEmployeeMutation(
      {
        country: filters.country,
        month: filters.month,
        year: filters.year,
        employees
      },
      {
        onSuccess: (data) => {
          (addEmployeeModalRef.current as any)?.close();
          setEmployeePerks(data.data);
        }
      }
    );
  };
  const onExport = async () => {
    if (!employeePerks.employees.length) {
      return;
    }
    const workbook = new ExcelJS.Workbook();
    prepareForExport(workbook, employeePerks);
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, `employee-perks-report-${filters.country}-${filters.year}-${filters.month}` + fileExtension);
  };
  const isAddDisabled =
    isDisabled ||
    isLoading ||
    employeePerks.status === PerksStatus.done ||
    filters.year !== nextPerkYear ||
    filters.month !== nextPerkMonth;

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        border: 'none',
        padding: 2,
        paddingX: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '16px'
      }}
    >
      <Typography sx={{ fontSize: '32px', fontWeight: 600 }}>Employee Perks</Typography>
      <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
        <IconButton sx={{ cursor: 'pointer' }} onClick={() => dispatch(toggleUISettings())}>
          <Iconify icon="cil:list-filter" width={24} color={caloTheme.palette.neutral900} />
        </IconButton>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          disabled={!userRoles.includes(Permission.UPDATE_EMPLOYEE_PERKS) || Boolean(isAddDisabled)}
          onClick={() => (addEmployeeModalRef.current as any)?.open()}
          sx={{
            borderColor: caloTheme.palette.neutral900,
            borderRadius: '8px',
            color: caloTheme.palette.neutral900,
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 600,
            px: '16px',
            ':hover': {
              borderColor: caloTheme.palette.neutral900,
              color: caloTheme.palette.neutral900
            }
          }}
        >
          Add
        </Button>
        <ExportButton
          isDisabled={!userRoles.includes(Permission.EXPORT_EMPLOYEE_PERKS) || isEmpty(employeePerks.employees)}
          isLoading={false}
          onClick={onExport}
        />
        <Popup ref={addEmployeeModalRef} title="Add Employee" fullWidth>
          <AddEmployeeModal
            handleAddingEmployee={handleAddingEmployee}
            country={filters.country}
            employeePerksInfo={employeePerks.employees ?? []}
          />
        </Popup>
      </Stack>
    </Card>
  );
};

export default EmployeePerksHeaderCard;
