import { Brand, Country, FoodMenuLabelsTags, Kitchen } from '@calo/types';
import { postRequest } from 'actions';
import { getLabelToAdd } from 'lib/helpers';
import { Food } from 'lib/interfaces';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

interface useAddingTopRatedMealsProps {
  country: Country;
  kitchen: Kitchen;
  brand: Brand;
  menuDate: string;
  clicked: boolean;
}

const DEFAULT_LIMIT = 200;

const useAddingTopRatedMeals = ({ country, kitchen, brand, menuDate, clicked = false }: useAddingTopRatedMealsProps) => {
  const [page, setPage] = useState<number>(0);
  const [allTopRatedMeals, setAllTopRatedMeals] = useState<Food[]>([]);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState<boolean>(false);

  const { isLoading, refetch } = useQuery<any, Error, { data: Food[]; meta: any }>(
    [
      'food/list',
      {
        filters: {
          name: undefined,
          country,
          brand,
          kitchen,
          isDeleted: false
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page,
        limit: DEFAULT_LIMIT
      }
    ],
    postRequest,
    {
      keepPreviousData: true,
      enabled: clicked && !isFetchingNextPage,
      onSuccess: (data) => {
        if (data?.data) {
          const validTopRatedMeals = data.data.filter((meal) => {
            if (meal.menuLabels) {
              const today = new Date(menuDate).getTime();
              const hasTopRatedLabel = meal.menuLabels.some((menuLabel) => menuLabel.label === FoodMenuLabelsTags.TOP_RATED);
              return getLabelToAdd(today, meal.menuLabels) && hasTopRatedLabel;
            }
            return false;
          });
          setAllTopRatedMeals((prevMeals) => [...prevMeals, ...validTopRatedMeals]);
          if (data?.meta && page < Math.ceil(data.meta.total / DEFAULT_LIMIT) - 1) {
            setPage((prevPage) => prevPage + 1);
            setIsFetchingNextPage(true);
          } else {
            setIsFetchingNextPage(false);
          }
        }
      }
    }
  );

  useEffect(() => {
    if (page > 0 && clicked) {
      refetch();
    }
  }, [page, clicked, refetch]);

  useEffect(() => {
    setPage(0);
    setAllTopRatedMeals([]);
  }, [country, kitchen, brand]);

  return {
    isLoadingTopRated: isLoading || isFetchingNextPage,
    topRatedMeals: allTopRatedMeals
  };
};

export default useAddingTopRatedMeals;
