import { Box, Button, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import CaloLoader from 'components/CaloLoader';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import { Routes } from 'lib/enums';
import { Link as RouterLink } from 'react-router-dom';

type DeleteConfirmationPopupProps = {
  type: string;
  deletionRef: React.RefObject<ModalRef>;
  typeUsage: any[];
  isLoading: boolean;
  onClose: () => void;
  onDelete: () => void;
};

const DeleteConfirmationPopup = ({
  type,
  typeUsage,
  isLoading,
  onClose,
  onDelete,
  deletionRef
}: DeleteConfirmationPopupProps) => {
  const routeLink = type === 'ingredient' ? Routes.foodComponent : Routes.food;

  return (
    <Popup maxWidth="sm" ref={deletionRef} onClose={onClose} fullWidth>
      <Box>
        <Stack spacing={4}>
          {isLoading ? (
            <CaloLoader />
          ) : typeUsage && typeUsage.length > 0 ? (
            <>
              <Typography sx={styles.titleText}>{`Cannot delete ${type} until it is removed from`}</Typography>
              <Stack sx={{ paddingX: 3, maxHeight: '240px', overflowY: 'auto' }}>
                <List>
                  {typeUsage.map((typeUsageItem) => (
                    <ListItem
                      key={typeUsageItem.id}
                      sx={{
                        paddingLeft: 1,
                        display: 'list-item',
                        listStyleType: 'disc'
                      }}
                    >
                      <ListItemText
                        primary={
                          <RouterLink
                            to={`${routeLink.replace(':id', typeUsageItem.id)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            {typeUsageItem.name}
                          </RouterLink>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </>
          ) : (
            <Typography sx={styles.titleText}>{`Are you sure you want to delete this ${type} ?`}</Typography>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            {typeUsage && typeUsage.length > 0 ? (
              <Button variant="outlined" sx={styles.outlinedButton} onClick={onClose} disabled={isLoading}>
                Done
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  onClick={onClose}
                  sx={styles.outlinedButton}
                  disabled={isLoading}
                  data-test="cancel_delete_ingredient"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  data-test="confirm_delete_meal_size"
                  onClick={onDelete}
                  sx={styles.containedButton}
                  disabled={isLoading}
                >
                  Delete
                </Button>
              </>
            )}
          </Box>
        </Stack>
      </Box>
    </Popup>
  );
};

export default DeleteConfirmationPopup;

const styles = {
  titleText: { fontSize: '32px', fontWeight: 600, lineHeight: '48px', textAlign: 'center' },
  outlinedButton: {
    px: '16px',
    mr: 4,
    borderWidth: 0,
    fontWeight: 600,
    fontSize: '16px',
    textTransform: 'none',
    color: caloTheme.palette.neutral900,
    ':hover': {
      borderWidth: 0,
      color: caloTheme.palette.neutral900
    }
  },
  containedButton: {
    color: 'white',
    fontWeight: 600,
    fontSize: '19px',
    boxShadow: 'none',
    lineHeight: '28px',
    borderRadius: '8px',
    textAlign: 'center',
    textTransform: 'none',
    borderColor: caloTheme.palette.primary500,
    backgroundColor: caloTheme.palette.primary500,
    '&:hover': {
      color: 'white',
      boxShadow: 'none',
      backgroundColor: caloTheme.palette.primary600,
      borderColor: caloTheme.palette.primary600
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      marginTop: 4,
      width: 'auto',
      margin: 'auto',
      justifyItems: 'center'
    }
  }
};
