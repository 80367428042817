import { Brand, RetailBranch } from '@calo/types';
import {
  Box,
  Button,
  Card,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { getListWithParams } from 'actions/index';
import { updateRetailOrderStatus } from 'actions/retail';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { Routes } from 'lib/enums';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { generatePath } from 'react-router-dom';
import notificationSound from '../../../assets/audio/notification-sound.mp3';
import { PickUpStatus } from '../types';
import Settings from './Settings';

const audio = new Audio(notificationSound);
audio.loop = true; // Ensure the audio loops when played

const RetailOrderMenu = ({ history }: any) => {
  const searchParams = new URLSearchParams(location.search);
  const componentRef = useRef<HTMLDivElement>(null);

  const [filters, setFilters] = useState({
    brand: Brand.CALO,
    branch: RetailBranch.SEEF,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const [showNotificationCard, setShowNotificationCard] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  const [soundTest, setSoundTest] = useState(false);
  const [needReEnableAudio, setNeedReEnableAudio] = useState(false);

  useEffect(() => {
    const path = generatePath(Routes.retailOrderList, { brand: filters.brand, branch: filters.branch });
    history.push(path);
    searchParams.set('filters', JSON.stringify(filters));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  }, [filters.branch]); // Update when the branch changes

  const handleUserInteraction = () => {
    setIsUserInteracted(true);
    setSoundTest(true);
    setNeedReEnableAudio(false);

    // Play the notification sound for the sound test
    audio
      .play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch((error) => {
        console.error('Error playing sound:', error);
      });
  };

  const startPlayingSound = () => {
    if (!isPlaying) {
      audio
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error('Error playing sound:', error);
          setShowNotificationCard(true); // Show the interaction popup again
        });
    }
  };

  const stopPlayingSound = () => {
    if (isPlaying) {
      audio.pause();
      audio.currentTime = 0;
      setIsPlaying(false);
    }
  };

  const handleServiceWorkerMessage = (event: any) => {
    if (event.data.action === 'new-retail-order') {
      startPlayingSound();
      setShowNotificationCard(true);
      refetch();
    }
  };

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js';
    script1.defer = true;
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.OneSignalDeferred = window.OneSignalDeferred || [];
      window.OneSignalInitialized = window.OneSignalInitialized || false;
      OneSignalDeferred.push(async function(OneSignal) {
        const filters = ${JSON.stringify(filters)};
        if (!window.OneSignalInitialized) {
          await OneSignal.init({
            appId: '${process.env.REACT_APP_ONE_SIGNAL_APP_ID}'
          });
          window.OneSignalInitialized = true;
        }
        OneSignal.User.addTags({ ['retail-staff-' + filters.branch]: 'true' });
      });
    `;
    document.body.appendChild(script2);

    // Attach the message listener
    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
      navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
    };
  }, [filters.branch]);

  const {
    data: orderList,
    isLoading,
    refetch,
    isFetching
  } = useQuery<any, Error, any[]>(['/retail/orders', { branch: filters.branch }], getListWithParams, {
    suspense: false,
    onSuccess: () => {
      searchParams.set('filters', JSON.stringify(filters));
      history.push({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    }
  });

  const handleChangeStatus = async (pickUpId: string, status: PickUpStatus) => {
    await updateRetailOrderStatus(pickUpId, status);
    await refetch();
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  const handleSoundTestConfirmation = () => {
    stopPlayingSound();
    setSoundTest(false);
  };

  return (
    <div className="retail-order-menu" ref={componentRef}>
      {isUserInteracted ? (
        soundTest ? (
          <Card
            variant="outlined"
            sx={{
              position: 'fixed',
              top: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1000,
              padding: 2,
              backgroundColor: caloTheme.palette.success.main,
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '80%',
              maxWidth: '600px',
              textAlign: 'center'
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              This is a sound test. Please confirm if you can hear the notification sound.
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              onClick={handleSoundTestConfirmation}
              sx={{ color: 'white' }}
            >
              Yes, I can hear it
            </Button>
          </Card>
        ) : needReEnableAudio ? (
          <Card
            variant="outlined"
            sx={{
              position: 'fixed',
              top: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1000,
              padding: 2,
              backgroundColor: caloTheme.palette.warning.main,
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '80%',
              maxWidth: '600px',
              textAlign: 'center'
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              To continue receiving sound notifications, please tap the button below to re-enable audio.
            </Typography>
            <Button color="secondary" variant="contained" size="large" onClick={handleUserInteraction} sx={{ color: 'white' }}>
              Re-enable Sound Notifications
            </Button>
          </Card>
        ) : (
          <>
            {showNotificationCard && (
              <Card
                variant="outlined"
                sx={{
                  position: 'fixed',
                  top: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 1000,
                  padding: 2,
                  backgroundColor: caloTheme.palette.primary.main,
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '80%',
                  maxWidth: '600px'
                }}
              >
                <Typography variant="h6">New order received</Typography>
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => {
                    stopPlayingSound();
                    setShowNotificationCard(false);
                  }}
                  sx={{ color: 'white' }}
                >
                  Acknowledge
                </Button>
              </Card>
            )}
            <Card
              variant="outlined"
              sx={{
                border: 'none',
                borderRadius: '8px',
                paddingBottom: '4px',
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  flexDirection: 'column'
                }
              }}
            >
              <Box
                display={'flex'}
                flexDirection="row"
                justifyContent={'space-between'}
                sx={{
                  padding: 2,
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    flexDirection: 'column'
                  }
                }}
                width="100%"
              >
                <Stack width={'50%'} display={'flex'} flexDirection={'row'} alignItems="start" justifyContent={'space-between'}>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: 'auto',
                      fontWeight: 600,
                      fontSize: '33px',
                      lineHeight: '40px',
                      color: caloTheme.palette.neutral900,
                      fontFamily: caloTheme.typography.fontFamily
                    }}
                  >
                    Retail Orders / {filters.branch}
                  </Typography>
                </Stack>
              </Box>
              <Settings onFilter={setFilters} filters={filters} />
              <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
                {isLoading || isFetching ? (
                  <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                    <CaloLoader />
                  </Stack>
                ) : orderList && orderList.length > 0 ? (
                  <Table
                    sx={{
                      marginY: '4px',
                      minHeight: '120px',
                      overflow: 'auto',
                      width: '100%',
                      [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                        flexDirection: 'column'
                      }
                    }}
                  >
                    <TableHead
                      style={{ borderRadius: '8px' }}
                      sx={{
                        backgroundColor: caloTheme.palette.neutral50,
                        color: 'black',
                        flexWrap: 0,
                        justifyContent: 'space-between',
                        width: '100%',
                        borderRadius: '8px'
                      }}
                    >
                      <TableRow
                        sx={{
                          backgroundColor: caloTheme.palette.neutral50,
                          color: 'black',
                          width: '100%',
                          flexWrap: 0,
                          justifyContent: 'space-between'
                        }}
                      >
                        <StyledTableCell>Order</StyledTableCell>
                        <StyledTableCell>Customer Name</StyledTableCell>
                        <StyledTableCell>Phone Number</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Created At</StyledTableCell>
                        <StyledTableCell>Pickup Time</StyledTableCell>
                        <StyledTableCell>Items</StyledTableCell>
                        <StyledTableCell>Modifiers</StyledTableCell>
                        <StyledTableCell>Total</StyledTableCell>
                        <StyledTableCell>Notes</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[...orderList].reverse().map((order, index) => (
                        <TableRow key={index}>
                          <StyledTableCell>{order.orderId}</StyledTableCell>
                          <StyledTableCell>{order.user.name}</StyledTableCell>
                          <StyledTableCell>{order.user.phoneNumber}</StyledTableCell>
                          <StyledTableCell>
                            <Select
                              value={order.pickUp.status}
                              onChange={(e) => handleChangeStatus(order.pickUp.id, e.target.value as PickUpStatus)}
                              disabled={isFetching || isLoading}
                            >
                              {Object.values(PickUpStatus).map((status) => (
                                <MenuItem key={status} value={status}>
                                  {status}
                                </MenuItem>
                              ))}
                            </Select>
                          </StyledTableCell>
                          <StyledTableCell>
                            {new Date(order.createdAt).toLocaleDateString()} at {new Date(order.createdAt).toLocaleTimeString()}
                          </StyledTableCell>
                          <StyledTableCell>
                            {new Date(order.pickUp.pickUpAt).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit'
                            })}
                          </StyledTableCell>
                          <StyledTableCell>
                            {order.items.map((item: any) => (
                              <div key={item.id}>
                                <strong style={{ fontWeight: 'bold', fontSize: '1.1em' }}>{item.quantity} x</strong>{' '}
                                {String(item.name.en)}
                              </div>
                            ))}
                          </StyledTableCell>
                          <StyledTableCell>
                            {order.items.map((item: any) => (
                              <div key={item.id}>
                                {item.notes && (
                                  <div>
                                    <div>{String(item.name.en)}:</div>
                                    {item.notes.split('\n').map((note: string, idx: number) => (
                                      <div key={idx}>{note}</div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))}
                          </StyledTableCell>
                          <StyledTableCell>
                            {order.items.reduce((total: number, item: any) => total + item.quantity, 0)}
                          </StyledTableCell>
                          <StyledTableCell>{order.notes}</StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography variant="h6" sx={{ textAlign: 'center', marginY: 2 }}>
                    No orders found.
                  </Typography>
                )}
              </Box>
            </Card>
          </>
        )
      ) : (
        <Card
          variant="outlined"
          sx={{
            position: 'fixed',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            padding: 2,
            backgroundColor: caloTheme.palette.primary.main,
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '80%',
            maxWidth: '600px',
            textAlign: 'center'
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            To receive sound notifications for new orders, please tap the button below.
          </Typography>
          <Button color="secondary" variant="contained" size="large" onClick={handleUserInteraction} sx={{ color: 'white' }}>
            Enable Sound Notifications
          </Button>
        </Card>
      )}
    </div>
  );
};

export default RetailOrderMenu;
