import { Subscription } from '@calo/dashboard-types';
import { Brand, Country, FoodType, HealthConditionNote, HealthConditions, Kitchen } from '@calo/types';
import { Box, Card, Stack, Typography } from '@mui/material';
import { getList, getListWithParams, getRecord, getRecordWithParams, quickSearch } from 'actions';
import { Autocomplete } from 'components';
import { format } from 'date-fns/fp';
import { customStyles2 } from 'lib/componentStyles';
import { Delivery, MealPlanFoodState, Menu, PaginatedDeliveries } from 'lib/interfaces';
import { groupBy, map, orderBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import DeliveriesCard from './DeliveriesCard';
import FoodRestrictions from './FoodRestrictions';
import HealthConditionNotes from './HealthConditionNotes';
import UserInformation from './UserInformation';
import UserPlanDetails from './UserPlanDetails';
import styles from './styles';

const Nutritionist = () => {
  const today = new Date(Date.now());
  const [selectedDate, setSelectedDate] = useState<Date | null>(today);
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [selectedDelivery, setSelectedDelivery] = useState<Delivery>();
  const [selectedDeliveryFood, setSelectedDeliveryFood] = useState<MealPlanFoodState>({
    breakfast: [],
    lunch: [],
    snack: [],
    caloKids: [],
    dessert: [],
    salad: [],
    juice: [],
    coffee: [],
    gifts: []
  });
  const [allCustomerNotes, setAllCustomerNotes] = useState<HealthConditionNote[]>([]);
  const [healthConditionsData, setHealthConditionData] = useState<HealthConditions[]>([]);
  const [allergicIngredients, setAllergicIngredients] = useState<any[]>([]);
  const [favMealIds, setFavMealIds] = useState<string[]>([]);

  const { data: del, isLoading } = useQuery<any, Error, PaginatedDeliveries>(
    [
      `subscriptions/${selectedCustomer?.value}/deliveries`,
      { page: 0, limit: 31, day: format('yyyy-MM-dd')(selectedDate ?? today) }
    ],
    getListWithParams,
    {
      suspense: false,
      keepPreviousData: false,
      enabled: !!selectedCustomer || !!selectedDate
    }
  );
  const subscriptionDeliveries = del as PaginatedDeliveries;

  const { data: subscriptionData } = useQuery(['subscriptions', selectedCustomer?.value], getRecord, {
    suspense: false,
    enabled: !!selectedCustomer
  });
  const subscription = subscriptionData as Subscription & {
    remainingDays: number;
    expectedLastDeliveryDay: string;
    ratings: any[];
    magicPrice?: boolean;
  };

  const {
    data: selDel,
    refetch: DelRefetch,
    isLoading: delLoading
  } = useQuery(['deliveries', selectedDelivery?.id], getRecord, {
    suspense: false,
    enabled: !!selectedDelivery,
    onSuccess: (data: Delivery) => {
      setSelectedDelivery(data);
    }
  });

  const {
    data: foodPref,
    isLoading: foodPrefLoading,
    refetch: foodPrefRef
  } = useQuery<any, Error, any>([`food-preferences/${subscription?.id}`], getList, {
    suspense: false,
    enabled: !!subscription,
    keepPreviousData: true,
    onSuccess: (data) => {
      setAllCustomerNotes(data?.healthConditions?.notes || []);
      setHealthConditionData(data?.healthConditions?.healthConditions || []);
      setAllergicIngredients(data?.ingredientsToAvoid || []);
      setFavMealIds(data?.favouriteFood || []);
    }
  });

  useEffect(() => {
    setAllCustomerNotes([]);
    setHealthConditionData([]);
    setAllergicIngredients([]);
    setFavMealIds([]);
  }, [selectedCustomer]);

  useEffect(() => {
    if (selDel) {
      const initialFoodState: MealPlanFoodState = {
        breakfast: [],
        lunch: [],
        snack: [],
        juice: [],
        dessert: [],
        caloKids: [],
        salad: [],
        coffee: [],
        gifts: []
      };
      const giftedMeals =
        selDel.giftedItems && selDel.giftedItems.meal
          ? selDel.giftedItems.meal.map((meal) => ({ ...meal, isGiftedItem: true }))
          : [];

      const combinedMeals = [...(selDel.addons ?? []), ...(selDel.food ?? [])];
      const newFoodState = combinedMeals?.reduce((acc: MealPlanFoodState, meal, index) => {
        if (meal.type)
          for (const type of meal.type) {
            if (type === FoodType.dinner) {
              continue;
            } else if (type === FoodType.lunch) {
              acc.lunch.push({ ...meal, positionIndex: index });
            } else {
              const category = FoodType[type];
              if (category && typeof category === 'string') {
                acc[category] = acc[category] || [];
                acc[category].push({ ...meal, positionIndex: index });
              }
            }
          }
        return acc;
      }, initialFoodState);
      for (const [index, meal] of giftedMeals.entries()) {
        newFoodState.gifts.push({ ...meal, isGiftedItem: meal.isGiftedItem, positionIndex: index } as any);
      }
      setSelectedDeliveryFood(newFoodState);
    }
  }, [selDel]);

  const { data, isLoading: MenuLoading } = useQuery(
    [
      '/menu',
      selectedDelivery?.day,
      {
        brand: Brand.CALO,
        kitchen: selectedDelivery?.kitchen || Kitchen.BH1,
        userId: selectedDelivery?.userId
      }
    ],
    getRecordWithParams,
    {
      retry: false,
      enabled: !!selectedDelivery
    }
  );
  const menu = data as Menu;

  return (
    <>
      <Card variant="outlined" sx={{ ...styles.AutoCompleteCard }}>
        <Box display={'flex'} flexDirection={'column'}>
          <Stack style={{ paddingBottom: '12px' }}>
            <Typography style={{ ...styles.chooseCustomerText }}>Choose Customer</Typography>
          </Stack>
          <Stack style={{ paddingBottom: '4px' }}>
            <Autocomplete
              onSearch={(text) =>
                quickSearch({
                  limit: 10,
                  query: text,
                  page: 1
                })
              }
              transformer={(data: any[]) =>
                map(groupBy(data, 'type'), (group, key) => ({
                  label: key,
                  options: group.map(
                    (row: any) =>
                      row.type === 'subscription' && {
                        value: row.id,
                        type: row.type,
                        data: row,
                        phoneNumber: row.phoneNumber.replace('+', ''),
                        name: row.name,
                        label: `${row.name}, ${row.phoneNumber}, ${row.email}, ${row.brand ? row.brand : Brand.CALO}, ${row.country || Country.BH}, ${row.kitchen || Kitchen.BH1}`
                      }
                  )
                }))
              }
              customStyles={customStyles2}
              placeHolder={true}
              placeHolderValue={selectedCustomer ? selectedCustomer.label : 'Search'}
              onPick={(data) => (data === undefined ? setSelectedCustomer(undefined) : setSelectedCustomer(data))}
            />
          </Stack>
        </Box>
      </Card>
      {subscription ? (
        <Box display={'flex'} flexDirection={'row'} style={{ paddingTop: '12px', justifyContent: 'space-between' }}>
          <Stack display={'flex'} flexDirection={'column'} width={'30%'} style={{ marginBottom: 12 }}>
            {subscription && (
              <>
                <Card variant="outlined" sx={{ ...styles.cards, mt: '1px' }}>
                  <UserPlanDetails selectedCustomer={subscription} />
                </Card>
                <Card variant="outlined" sx={{ ...styles.cards }}>
                  <UserInformation selectedCustomer={subscription} />
                </Card>
                <Card variant="outlined" sx={{ ...styles.cards }}>
                  <FoodRestrictions
                    foodPref={foodPref}
                    refetch={DelRefetch}
                    favMealIds={favMealIds}
                    selectedCustomer={subscription}
                    customerNotes={allCustomerNotes}
                    healthConditionsData={healthConditionsData}
                    setHealthConditionData={setHealthConditionData}
                    allergicIngredients={allergicIngredients}
                    setAllergicIngredients={setAllergicIngredients}
                  />
                </Card>
                <Card variant="outlined" sx={{ ...styles.cards }}>
                  <HealthConditionNotes
                    foodPref={foodPref}
                    favMealIds={favMealIds}
                    selectedCustomer={subscription}
                    allCustomerNotes={orderBy(allCustomerNotes, ['createdAt'], ['desc'])}
                    setAllCustomerNotes={setAllCustomerNotes}
                    ingredientsToAvoid={allergicIngredients}
                    healthConditions={healthConditionsData}
                  />
                </Card>
              </>
            )}
          </Stack>
          <Stack display={'flex'} flexDirection={'column'} width={'68%'}>
            <Card variant="outlined" sx={{ ...styles.customerPlanCard }}>
              <DeliveriesCard
                menu={menu}
                foodPref={foodPref}
                favMealIds={favMealIds}
                selectedDelivery={selDel}
                foodPrefRef={foodPrefRef}
                menuLoading={MenuLoading}
                selectedDate={selectedDate}
                setFavMealIds={setFavMealIds}
                delRefetch={() => DelRefetch()}
                selectedCustomer={subscription}
                setSelectedDate={setSelectedDate}
                setSelectedDelivery={setSelectedDelivery}
                selectedDeliveryFood={selectedDeliveryFood}
                subscriptionDeliveries={subscriptionDeliveries}
                setSelectedDeliveryFood={setSelectedDeliveryFood}
                deliveryLoading={isLoading || delLoading || foodPrefLoading}
              />
            </Card>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ flexDirection: 'row', m: 'auto' }}>
          <Typography sx={{ ...styles.noUserText }}>Select Customer</Typography>
        </Box>
      )}
    </>
  );
};
export default Nutritionist;
