import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Box, IconButton, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';

interface TablePaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (newPage: number) => void;
}

const TablePaginationRow = ({ count, page, rowsPerPage, onPageChange }: TablePaginationProps) => {
  const handleFirstPageButtonClick = () => {
    onPageChange(0);
  };

  const handleBackButtonClick = () => {
    onPageChange(page - 1);
  };

  const handleNextButtonClick = () => {
    onPageChange(page + 1);
  };

  const handleLastPageButtonClick = () => {
    onPageChange(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', flexShrink: 0, ml: 2.5, p: 1 }}>
      <IconButton onClick={() => handleFirstPageButtonClick()} disabled={page === 0} aria-label="first page">
        {caloTheme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={() => handleBackButtonClick()} disabled={page === 0} aria-label="previous page">
        {caloTheme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <Typography sx={{ mt: 1, fontSize: '14px', flexShrink: 0 }}>
        {page + 1} of {Math.max(0, Math.ceil(count / rowsPerPage))}
      </Typography>
      <IconButton
        onClick={() => handleNextButtonClick()}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {caloTheme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={() => handleLastPageButtonClick()}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {caloTheme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
      <Typography sx={{ flexShrink: 0, fontSize: '14px', mt: '10px' }}>Total: {count}</Typography>
    </Box>
  );
};
export default TablePaginationRow;
