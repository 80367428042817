import { CreateMenuReq } from '@calo/dashboard-types';
import { Brand, Country, Kitchen } from '@calo/types';
import { format } from 'date-fns/fp';
import { useFormik } from 'formik';
import { mealTracking } from 'lib/helpers';

export default (onSubmit: (values: CreateMenuReq) => Promise<void>) =>
  useFormik<CreateMenuReq>({
    enableReinitialize: true,
    initialValues: {
      food: [],
      day: format('yyyy-MM-dd')(Date.now()),
      country: Country.BH,
      brand: Brand.CALO,
      kitchen: Kitchen.BH1,
      draft: mealTracking as any
    },

    validate: (values: CreateMenuReq) => {
      const errors: any = {};
      if (Object.values(values.food).length === 0) {
        errors.food = true;
      }

      if (!values.day) {
        errors.day = true;
      }
      if (!values.country) {
        errors.country = true;
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
