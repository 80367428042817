import { Permission, User, UserGroup } from '@calo/dashboard-types';
import { getList } from 'actions';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import queryClient from 'lib/queryClient';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

interface UserRowProps {
  user: User;
}

const UserRow = ({ user }: UserRowProps) => {
  const roles = useUserRoles();
  const { data } = useQuery<any, Error, { data: UserGroup[] }>('user-permissions', getList, {
    suspense: true,
    retry: false,
    onSuccess: (data) => {
      for (const row of data?.data || []) {
        queryClient.setQueryData(['user-permissions', row.id], row);
      }
    }
  });
  const permissionsAndRoles = data?.data ?? [];

  return (
    <tr>
      <td>
        {roles.includes(Permission.VIEW_USER) ? (
          <Link to={Routes.user.replace(':id', user.id)}>{user.name}</Link>
        ) : (
          `${user.name}`
        )}
      </td>
      <td>{user.group && <p>{user.group && permissionsAndRoles.find((r) => r.id === user.group)?.groupName}</p>}</td>
      <td>{user.phoneNumber && <p>{user.phoneNumber}</p>}</td>
      <td>{user.email && <p>{user.email}</p>}</td>
      <td>{user.country && <p>{user.country}</p>}</td>
      <td>{user.country && <p>{(user.kitchen || []).join(';')}</p>}</td>
    </tr>
  );
};

export default UserRow;
