import { useState } from 'react';

import { getListWithParams } from 'actions';
import { CaloLoader } from 'components';
import { resolveCountryFromKitchen } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { FoodPackageFilters } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';

import PackagesHeaderCard from './PackagesHeaderCard';
import PackagesListCard from './PackagesListCard';
import Settings from './Settings';

const PackagesList = () => {
  const history = useHistory();
  const userKitchen = useUserKitchens();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState<FoodPackageFilters>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data, isLoading } = useQuery<any, Error, { data: any[] }>(
    ['food-packages', { limit: 1000, filters }],
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );
  const packagesList = data?.data as any[];
  const isDisabled = false;

  return (
    <>
      <PackagesHeaderCard filters={filters} history={history} packages={packagesList} isDisabled={isDisabled} />
      {isLoading ? <CaloLoader /> : <PackagesListCard packaging={packagesList} isLoading={isLoading} />}
      <Settings onFilter={setFilters} filters={filters} />
    </>
  );
};

export default PackagesList;
