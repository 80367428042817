import { useRef, useState } from 'react';

import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components';
import CupsSizesMUI from 'components/MUI/CupsSizesMUI';
import { FormikErrors } from 'formik';
import { BaseOmit, FoodComponent } from 'lib/interfaces';

import { CupSizes } from '@calo/dashboard-types';
import AddIcon from '@mui/icons-material/Add';
import { Card, IconButton, Stack, Typography } from '@mui/material';

import AddCupSizeModal from 'components/AddCupSizeModal';
import { FormOperation } from 'lib/enums';
import { getComponentPermission } from 'lib/helpers/permissionUtils';
import { useUserRoles } from 'lib/hooks';

interface CupsListCardProps {
  prototype?: boolean;
  operation: FormOperation;
  values: Omit<FoodComponent, BaseOmit>;
  isDisabled?: boolean;
  setValues: (
    values: React.SetStateAction<Omit<FoodComponent, BaseOmit>>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
}

const CupsListCard = ({ values, isDisabled, prototype, operation, setValues, setFieldValue }: CupsListCardProps) => {
  const addCupsRef = useRef<ModalRef>();
  const roles = useUserRoles();

  const [cups, setCups] = useState<CupSizes>({ size: undefined!, value: undefined! });

  const permission = getComponentPermission(roles, operation, prototype);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          marginTop: '16px',
          border: 'none',
          borderRadius: '16px',
          py: '18px',
          overflow: 'auto'
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: '18px',
            marginBottom: '16px',
            color: caloTheme.palette.neutral900
          }}
        >
          <Typography sx={{ fontSize: '19px', fontWeight: 600 }}>Add Cups</Typography>
          <IconButton data-test="addCupsButton" onClick={() => addCupsRef.current?.open()} disabled={isDisabled} color="inherit">
            <AddIcon />
          </IconButton>
        </Stack>
        <CupsSizesMUI
          values={values}
          addCupsRef={addCupsRef}
          isDisabled={!!isDisabled || !permission}
          setValues={(s) => setValues(s)}
          setCups={(e: any) => setCups({ size: e.type, value: e.value })}
        />
      </Card>
      <AddCupSizeModal
        addCupsRef={addCupsRef}
        cups={cups}
        setCups={(e) => setCups(e)}
        setFieldValue={setFieldValue}
        values={values}
      />
    </>
  );
};

export default CupsListCard;
