import { toggleUISettings } from 'actions/ui';
import { caloTheme } from 'assets/images/theme/calo';
import { useDispatch } from 'react-redux';

import { DeliveryFilters } from '@calo/dashboard-types';
import { Icon as Iconify } from '@iconify/react';
import { Card, IconButton, Stack, Typography } from '@mui/material';
import { exportCoolerBags } from 'actions/coolerBags';
import ExportButton from 'components/ExportButton';
import { CoolerBagStats } from '../CoolerBags';

interface CoolerBagsHeaderCardProps {
  filters: Partial<DeliveryFilters>;
  isDisabled?: boolean;
  isLoading?: boolean;
  coolerBagsStats?: CoolerBagStats;
}

const CoolerBagsHeaderCard = ({ filters, isLoading }: CoolerBagsHeaderCardProps) => {
  const dispatch = useDispatch();

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        border: 'none',
        padding: 2,
        paddingX: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '16px'
      }}
    >
      <Typography sx={{ fontSize: '32px', fontWeight: 600 }}>Cooler Bags</Typography>
      <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
        <IconButton sx={{ cursor: 'pointer' }} onClick={() => dispatch(toggleUISettings())}>
          <Iconify icon="cil:list-filter" width={24} color={caloTheme.palette.neutral900} />
        </IconButton>
        <ExportButton isLoading={isLoading} onClick={() => exportCoolerBags(filters)} />
      </Stack>
    </Card>
  );
};

export default CoolerBagsHeaderCard;
