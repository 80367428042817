import { useState } from 'react';
import { generatePath } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import { uniqBy } from 'lodash-es';

import { Permission } from '@calo/dashboard-types';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Card, Stack, Typography } from '@mui/material';

import { CaloLoader, Icon, MenuTable } from 'components';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { DayMenuProps } from '../types';
import Container from './Container';
import { styles } from './styles';

const DayMenuCard = ({ filters, history, isLoading, selectedMenu }: DayMenuProps) => {
  const userRoles = useUserRoles();
  const [closedCards, setClosedCards] = useState<string[]>([]);

  const handleDayClick = (date: string) => {
    const path = generatePath(Routes.menu, { brand: filters.brand, kitchen: filters.kitchen, id: date });
    return history.push(path);
  };

  if (isLoading) {
    return <CaloLoader />;
  }

  if (!selectedMenu) {
    return (
      <Container>
        <Typography sx={styles.noMenuText}>No Menu For this Day</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Card
        variant="outlined"
        sx={[
          styles.card,
          {
            height: closedCards.includes(selectedMenu.id) ? '8vh' : 'auto'
          }
        ]}
      >
        <Box sx={styles.header}>
          <Stack sx={styles.headerInfo}>
            <Typography
              sx={[styles.text, { marginRight: 4 }]}
            >{`${format('EEEE dd/MM/yyyy')(parseISO(selectedMenu.id))}`}</Typography>
            <Typography sx={styles.text}>{`${uniqBy(selectedMenu.food, 'name.en').length} Meals`}</Typography>
          </Stack>
          <Stack sx={styles.headerInfo}>
            {userRoles.includes(Permission.UPDATE_MENU) && userRoles.includes(Permission.VIEW_MENU) && (
              <Icon name="editPen2" style={styles.editIcon} size={6} onClick={() => handleDayClick(selectedMenu.id)} />
            )}
            {closedCards.includes(selectedMenu.id) ? (
              <ExpandMoreIcon
                onClick={() => setClosedCards(closedCards.filter((id) => id !== selectedMenu.id))}
                sx={styles.toggleIcon}
                fontSize="large"
              />
            ) : (
              <KeyboardArrowUpIcon
                onClick={() => setClosedCards([...closedCards, selectedMenu.id])}
                sx={styles.toggleIcon}
                fontSize="large"
              />
            )}
          </Stack>
        </Box>
        <Box sx={styles.menuTable}>
          <MenuTable
            key={selectedMenu.id}
            selectedDate={selectedMenu.id}
            isEditable={false}
            kitchen={selectedMenu?.kitchen}
            brand={selectedMenu?.brand}
            foodList={selectedMenu?.food}
            foodLabel={selectedMenu?.tags}
            setReplaceFood={() => null}
            draftTrack={undefined}
          />
        </Box>
      </Card>
    </Container>
  );
};

export default DayMenuCard;
