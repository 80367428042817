import { getListWithParams } from 'actions';
import Disclaimer from 'components/Disclaimer';
import { MealCreationInformation } from 'components/FoodShared';
import CostInformationCard from 'components/MealCostInformationCard/CostInformationCard';
import {
  Components,
  Information,
  Ingredients,
  NutritionalInformation,
  PackagingInformation,
  PortioningNotes
} from 'components/Sections';
import { checkCountryCurrency } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { FoodComponent, FoodComponentWithQuantity } from 'lib/interfaces';
import { keyBy, round, sumBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { CreateFoodReq, MenuFoodComponent, Permission } from '@calo/dashboard-types';
import { DietType, FoodDietType } from '@calo/types';
import { Stack } from '@mui/material';

import { findAutoCalculatedMacros, findAutoCalculatedMicros } from 'views/Food/helpers';
import MealCreationHeader from './MealCreationHeader/MealCreationHeader';
import useFoodForm from './useFoodForm';

interface FoodFormProps {
  onSubmit: (value: Omit<CreateFoodReq, 'id'>) => Promise<void>;
  isPreBuildCustom: boolean;
}

const FoodForm = ({ onSubmit, isPreBuildCustom }: FoodFormProps) => {
  const roles = useUserRoles();

  const isDisabled = !roles.includes(Permission.CREATE_PROTOTYPE_FOOD);

  const [name, setName] = useState('');
  const [packageCost, setPackageCost] = useState(0);

  const { handleSubmit, values, handleChange, handleBlur, isSubmitting, isValid, dirty, setFieldValue, setValues, errors } =
    useFoodForm(onSubmit, isPreBuildCustom);

  const { data: foodComponentSearchList, isLoading: isLoadingFoodComponent } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        filters: { withChildComponents: true, name, country: values.country, brand: values.brand, kitchen: values.kitchen },
        limit: 50
      }
    ],
    getListWithParams,
    {
      enabled: !!name && roles.includes(Permission.VIEW_FOOD_COMPONENTS_LIST)
    }
  );

  const { data: prototypeComponentSearchResult } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components/prototype',
      {
        limit: 50,
        filters: {
          withChildComponents: true,
          name,
          country: values.country,
          brand: values.brand,
          kitchen: values.kitchen
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!name && roles.includes(Permission.VIEW_PROTOTYPE_COMPONENT_LIST),
      keepPreviousData: true
    }
  );

  const keyedSelectedComponents = keyBy(values.components, 'id');
  const childComponents = (values.components ?? []).flatMap(
    (comp) => comp.childComponents ?? []
  ) as any as FoodComponentWithQuantity[];
  const keyedChildComponents = keyBy(childComponents, 'id');

  useEffect(() => {
    setValues({
      ...values,
      size: 'M',
      tags: isPreBuildCustom ? [FoodDietType.preBuiltCustom] : [DietType.balanced]
    });
  }, [values.brand]);

  useEffect(() => {
    setValues({
      ...values,
      components: [],
      ingredients: []
    });
  }, [values.brand, values.country]);

  useEffect(() => {
    const ingredients = (values.components ?? []).flatMap((comp) => comp.ingredients);
    const childIngredients = (values.components ?? []).flatMap((comp) =>
      (comp.childComponents ?? []).flatMap((childComp: any) => childComp.ingredients)
    );
    setFieldValue('ingredients', [...ingredients, ...childIngredients]);
  }, [values.components]);

  const handleComponentsChange = async (componentRows: MenuFoodComponent[]) => {
    const newComponentRows = componentRows.map((r) => (isNaN(r.quantity) ? { ...r, id: r.id, quantity: 0 } : r));

    setValues({
      ...values,
      components: newComponentRows,
      macros: values.withManualMacros ? values.macros : findAutoCalculatedMacros(newComponentRows),
      micronutrients: values.withManualMacros ? values.micronutrients : findAutoCalculatedMicros(newComponentRows)
    });
  };

  const getTotalCost = () => {
    const componentCost = values.components?.map(
      (r) =>
        ([...(values.components ?? []), ...(prototypeComponentSearchResult?.data || [])].find((k) => k.id === r.id)?.cost || 0) *
        r.quantity
    );
    const cC = round(
      sumBy(componentCost, (fk) => fk || 0),
      3
    );
    return (packageCost + cC).toFixed(3);
  };

  useEffect(() => {
    setFieldValue('currency', checkCountryCurrency(values.country));
  }, [values.country]);

  const handleAutoCalculate = (value: boolean) => {
    if (value) {
      setValues({
        ...values,
        withManualMacros: value
      });
    } else {
      setValues({
        ...values,
        macros: findAutoCalculatedMacros(values.components ?? []),
        micronutrients: findAutoCalculatedMicros(values.components ?? []),
        withManualMacros: value
      });
    }
  };

  const isValidMeal = () => {
    if ((values.name?.ar && values.name?.ar.length > 50) || values.type?.length === 0 || !isValid) {
      return false;
    }
    return true;
  };

  return (
    <Stack flexDirection={'column'} justifyContent={'space-between'} spacing={2}>
      <MealCreationHeader
        values={values}
        setValues={setValues}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        disableSubmit={!dirty || !isValid || isSubmitting || isDisabled || !isValidMeal()}
      />
      <Disclaimer type="meal" sx={{ top: '130px' }} />

      <MealCreationInformation values={values} setFieldValue={setFieldValue} />

      <Components
        ref={null}
        name={name}
        values={values}
        setName={setName}
        isDisabled={isDisabled}
        setFieldValue={setFieldValue}
        isLoading={isLoadingFoodComponent}
        handleComponentsChange={handleComponentsChange}
        foodComponents={[...(foodComponentSearchList?.data || []), ...(prototypeComponentSearchResult?.data || [])]}
      />
      <Ingredients
        ref={null}
        ingredients={values.ingredients ?? []}
        isLoading={false}
        components={(values.components as any) ?? []}
      />
      <PackagingInformation
        ref={null}
        roles={roles}
        values={values}
        isDisabled={isDisabled}
        food={values as any}
        setFieldValue={setFieldValue}
        setPackageCost={(r) => setPackageCost(r)}
      />

      <NutritionalInformation
        ref={null}
        values={values}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        handleAutoCalculate={handleAutoCalculate}
        displayMessageForMacrosLimits={undefined}
        microNutrientsAuto={values.withManualMacros || false}
      />
      <CostInformationCard
        values={values}
        setFieldValue={setFieldValue}
        getTotalCost={getTotalCost}
        packagingCost={packageCost}
        allComponents={keyedSelectedComponents as any}
        childComponent={keyedChildComponents}
        errors={errors}
      />

      <Information
        values={values}
        errors={errors}
        allSizesFood={[]}
        isDisabled={isDisabled}
        food={values as any}
        setFieldValue={setFieldValue}
      />

      <PortioningNotes ref={null} values={values} errors={errors} isDisabled={isDisabled} handleChange={handleChange} />
    </Stack>
  );
};

export default FoodForm;
