import { GiftItemType } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Box, Button, Divider, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { updateSpecialRequest } from 'actions/specialRequest';
import { caloTheme } from 'assets/images/theme/calo';
import { AttachFile, ModalRef } from 'components';
import Popup from 'components/Popup';
import SpecialRequestChipsRenderer from 'components/SpecialRequestChipsRenderer';
import { format } from 'date-fns/fp';
import { handleUploadImage } from 'lib/helpers';
import { compact, upperFirst } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';
import AddMealsPopup from '../CreateSpecialRequest/AddMealsPopup';
import DatePicker from '../DatePicker';
import styles from './styles';
interface EditRequestPopupProps {
  refetchList: any;
  selectedRequest: any;
  editRequestRef: React.MutableRefObject<ModalRef | undefined>;
}

const EditRequestPopup = ({ selectedRequest, refetchList, editRequestRef }: EditRequestPopupProps) => {
  const attachmentMethodImageRef = useRef<ModalRef>();
  const [loadingImage, setLoadingImage] = useState(false);
  const [showMealSection, setShowMealSection] = useState(false);
  const [displayImage, setDisplayImage] = useState<string[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const [updatedRequest, setUpdatedRequest] = useState<any>(selectedRequest || []);
  const [progress, setProgress] = useState(0);
  const { mutateAsync: updateMutation } = useMutation(updateSpecialRequest);

  const handleDeleteChip = (selectedType: any) => {
    setUpdatedRequest((prev: any) => ({
      ...prev,
      items: prev.items.filter((request: any) => request !== selectedType)
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    handleUploadImage({
      disabled: !updatedRequest,
      files,
      setDisplayImage,
      setLoadingImage,
      setProgress,
      urlFile: 'gifts',
      uuIDCreate: updatedRequest!.id.split('#')[1]
    });
  };

  const handleAddingMeals = (newAddedMeal: any) => {
    setUpdatedRequest({
      ...updatedRequest,
      items: [...updatedRequest.items, ...newAddedMeal]
    });
  };

  const handleUpdateRequest = async () => {
    setLoadingRequest(true);
    const updatedValues = {
      deliveryTime: updatedRequest.deliveryTime,
      comment: updatedRequest.comment,
      attachments: compact(displayImage.map((link) => (link.includes('gifts') ? link.split('/')[2] : link))) || [],
      items: updatedRequest.items,
      userName: updatedRequest.userName,
      phoneNumber: updatedRequest.phoneNumber
    };

    const { data, status } = await updateMutation({
      ...updatedValues,
      id: `${updatedRequest.date}_${updatedRequest.deliveryId}`,
      kitchen: updatedRequest.kitchen
    });

    if (status >= 200 && status <= 299 && data) {
      editRequestRef.current?.close();
      setUpdatedRequest([]);
    }
    refetchList();
    setLoadingRequest(false);
  };

  useEffect(() => {
    if (updatedRequest) {
      setDisplayImage(updatedRequest.attachments);
    } else {
      setDisplayImage([]);
    }
  }, [updatedRequest]);

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} sx={{ ...styles.mainBox }} width="100%">
      <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{ width: '100%', mx: 1 }}>
        <Stack width={'33%'} sx={{ mr: 1, display: 'flex' }}>
          <TextField
            name="user"
            label="User"
            disabled={true}
            value={updatedRequest.userName}
            InputProps={{
              inputProps: { style: { borderRadius: 8 } },
              style: { borderRadius: 8, height: '48px' }
            }}
          ></TextField>
        </Stack>
        <Stack width={'50%'} sx={{ mx: 1 }}>
          <TextField
            select
            name="request"
            label="Request"
            disabled={!updatedRequest.userName}
            value={updatedRequest.items}
            onChange={(data: any) => {
              if (data.target.value === GiftItemType.meal) {
                setShowMealSection(true);
              } else {
                setUpdatedRequest({
                  ...updatedRequest,
                  items: [
                    ...updatedRequest.items,
                    {
                      type: data.target.value,
                      amount: 1
                    }
                  ]
                });
              }
            }}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8, height: '48px' } }}
          >
            {Object.entries(GiftItemType).map(([key, value]) => (
              <MenuItem
                key={key}
                value={value}
                disabled={updatedRequest?.items?.map((r: any) => r.type).find((t: any) => t === value && t !== GiftItemType.meal)}
                sx={{ textTransform: 'normal' }}
              >
                {upperFirst(value)}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack sx={{ mx: 1, width: '33%', height: '48px' }}>
          <DatePicker
            disabled={true}
            label={'Select Day'}
            selectedDate={updatedRequest.date}
            onChange={(endDate: any) =>
              setUpdatedRequest({
                ...updatedRequest,
                date: endDate === null ? '' : format('yyyy-MM-dd')(endDate as Date)
              })
            }
            setSelectedDate={(e: any) => setUpdatedRequest({ ...updatedRequest, date: e.target.value })}
          />
        </Stack>
      </Stack>
      {showMealSection && (
        <Box sx={{ width: '100%', mt: 1 }}>
          <Divider sx={{ mt: 1 }} />
          <Stack sx={{ width: '100%', mt: 1, mb: '4px', px: 1 }}>
            <Typography sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '20px', color: caloTheme.palette.black }}>
              Add Meal
            </Typography>
          </Stack>
          <Stack sx={{ width: '100%', mx: 1, pr: 1, pb: '4px' }}>
            <AddMealsPopup
              addMealsSection="update"
              selectedMeals={[]}
              setSelectedMeals={handleAddingMeals}
              handleConfirmAddMeals={() => null}
              handleClosePopup={() => setShowMealSection(false)}
              brand={Brand.CALO}
              kitchen={updatedRequest.kitchen || Kitchen.BH1}
            />
          </Stack>
          <Divider />
        </Box>
      )}
      <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{ width: '100%', my: 3, mr: 2, ml: 1 }}>
        <TextField
          label="Comment"
          sx={{ width: '100%' }}
          multiline
          minRows={1}
          name="comment"
          value={updatedRequest.comment}
          placeholder="Add Comment"
          onChange={(data: any) => setUpdatedRequest({ ...updatedRequest, comment: data.target.value })}
          inputProps={{
            inputProps: { style: { borderRadius: 8, width: '100%' } },
            style: { borderRadius: 8, width: '100%' }
          }}
        />
      </Stack>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <SpecialRequestChipsRenderer key={uuid()} items={updatedRequest.items} onDelete={handleDeleteChip} />
        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '55%' }}>
          <Button
            variant="outlined"
            sx={{ ...styles.attachButton }}
            disabled={!updatedRequest.userName}
            onClick={() => displayImage?.length > 0 && attachmentMethodImageRef.current?.open()}
            startIcon={
              loadingImage ? (
                'loading'
              ) : displayImage?.length > 0 ? (
                <></>
              ) : (
                <AttachFileIcon style={{ transform: 'rotate(45deg)' }} />
              )
            }
          >
            {displayImage?.length === 0 ? (
              <>
                <input
                  accept="image/*"
                  type="file"
                  multiple
                  size={2}
                  className="file-input fas fa-paperclip mt-2 cursor-pointer float-right "
                  key={uuid()}
                  onChange={handleFileChange}
                />
                {loadingImage ? '' : 'Attach File'}
              </>
            ) : (
              <>{loadingImage ? '' : 'View Attached File'}</>
            )}
          </Button>

          <Button
            variant="contained"
            sx={{ ...styles.updateButton }}
            onClick={() => handleUpdateRequest()}
            disabled={
              !updatedRequest.userName ||
              (loadingImage && progress !== 100) ||
              loadingRequest ||
              updatedRequest.items.length === 0
            }
          >
            Update Request
          </Button>
        </Stack>
      </Box>
      <Popup
        maxWidth="lg"
        fullWidth
        title="Attached File"
        ref={attachmentMethodImageRef}
        onClose={() => attachmentMethodImageRef.current?.close()}
        info={
          <Button disabled={loadingImage}>
            <input
              accept="image/*"
              type="file"
              multiple
              size={2}
              className="file-input fas fa-paperclip mt-2 cursor-pointer float-right "
              key={uuid()}
              onChange={handleFileChange}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                mt: 1,
                cursor: 'pointer',
                lineHeight: '19px',
                color: caloTheme.palette.neutral900
              }}
            >
              {loadingImage ? 'Loading...' : '+ Add File'}
            </Typography>
          </Button>
        }
      >
        <AttachFile
          viewType="view"
          urlFile={'gifts'}
          displayImage={displayImage}
          setDisplayImage={setDisplayImage}
          key={updatedRequest!.id?.split('#')[1]}
          uuIDCreate={updatedRequest!.id?.split('#')[1]}
        />
      </Popup>
    </Box>
  );
};
export default EditRequestPopup;
