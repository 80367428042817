import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';

import { setUICouponSettingsVisible, setUIKitchenSettingsVisible, setUISettingsVisible, setViewMode } from 'actions/ui';
import { Action, ViewMode } from 'lib/enums';
import { AppState } from 'lib/interfaces';

export default ({ getState }: MiddlewareAPI<Dispatch, AppState>) =>
  (next: Dispatch) =>
  (action: AnyAction) => {
    const state = getState();
    if (action.type === Action.UI_TOGGLE_VIEW_MODE) {
      return state.ui.viewMode === ViewMode.map ? next(setViewMode(ViewMode.list)) : next(setViewMode(ViewMode.map));
    }
    if (action.type === Action.UI_TOGGLE_SETTINGS) {
      return state.ui.settings ? next(setUISettingsVisible(false)) : next(setUISettingsVisible(true));
    }
    if (action.type === Action.UI_TOGGLE_KITCHEN_SETTINGS) {
      return state.ui.kitchenSettings ? next(setUIKitchenSettingsVisible(false)) : next(setUIKitchenSettingsVisible(true));
    }
    if (action.type === Action.UI_TOGGLE_COUPON_SETTINGS) {
      return state.ui.couponSettings ? next(setUICouponSettingsVisible(false)) : next(setUICouponSettingsVisible(true));
    }
    return next(action);
  };
