import { KitchenLogsFilters } from '@calo/dashboard-types';
import { Brand } from '@calo/types';
import { getListWithParams } from 'actions';
import { CaloLoader, Icon, Pagination } from 'components';
import { KitchenLogType } from 'lib/enums';
import { getAccessibleCountries, getKitchenOptions } from 'lib/helpers';
import history from 'lib/history';
import { useUserKitchens } from 'lib/hooks';
import { FoodLogInterface } from 'lib/interfaces';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import FoodLogRow from './FoodLogRow';
import FoodType from './FoodType';
import Settings from './Settings';

interface PaginatedFoodLogs {
  data: FoodLogInterface[];
  meta: {
    limit: number;
    total: number;
  };
}

const FoodLogs = () => {
  const [page, setPage] = useState(0);
  const userKitchens = useUserKitchens();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [selectedType, setSelectedType] = useState<KitchenLogType>(KitchenLogType.food);
  const [filters, setFilters] = useState<KitchenLogsFilters>({
    country: getAccessibleCountries(userKitchens)[0],
    kitchen: userKitchens && getKitchenOptions(userKitchens, getAccessibleCountries(userKitchens)[0])[0].value,
    brand: Brand.CALO,
    type: selectedType,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data, isLoading } = useQuery<any, Error, PaginatedFoodLogs>(
    [
      'kitchen-logs',
      {
        page,
        filters: {
          date: filters.date,
          type: filters.type,
          targetId: filters.targetId,
          brand: filters.brand,
          country: filters.country ? filters.country : undefined,
          kitchen: filters.kitchen
        },
        limit: 25
      }
    ],
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  const kitchenLogs = data;

  useEffect(() => {
    setPage(0);
  }, [filters]);

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Kitchen Food Logs / {filters.country ? filters.country : 'All'} </li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              {filters.brand && filters.brand === Brand.MEALO ? (
                <Icon name="mealo" size={28} className="w-auto -mb-24" />
              ) : (
                <Icon name="calo" size={8} className="w-32 -mb-4" />
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="my-4 ">
        <FoodType
          selectedType={filters.type || selectedType}
          setSelectedType={(t) => {
            setFilters({ ...filters, type: t, targetId: undefined });
            setSelectedType(t);
          }}
        />
      </section>
      <section>
        {isLoading ? (
          <CaloLoader />
        ) : (
          <>
            <div className="card has-table has-table-container-upper-radius">
              <div className="card-content">
                <div className="table-container overflow-y-auto">
                  <table className="table is-fullwidth is-striped is-hoverable is-sortable">
                    <thead>
                      <tr className="bg-black">
                        <th style={{ color: 'white' }}>Date</th>
                        <th style={{ color: 'white' }}>Country</th>
                        <th style={{ color: 'white' }}>Kitchen</th>
                        <th style={{ color: 'white' }}>Actor</th>
                        <th style={{ color: 'white' }}>Action type</th>
                        <th style={{ color: 'white' }}>Field</th>
                        <th style={{ color: 'white' }}>Old Value</th>
                        <th style={{ color: 'white' }}>New Value</th>
                        <th style={{ color: 'white' }}>Link</th>
                      </tr>
                    </thead>
                    {kitchenLogs && kitchenLogs.data.length === 0 ? (
                      <span className="absolute w-full text-4xl mt-4 text-center font-bold text-gray-400">NO FOOD LOGS</span>
                    ) : (
                      <tbody>{kitchenLogs?.data.map((log) => <FoodLogRow key={log.id} log={log} />)}</tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
        <Settings
          onFilter={setFilters}
          filters={filters}
          selectedType={filters.type || selectedType}
          page={page}
          setPage={setPage}
        />
        {data && (
          <Pagination
            isHidden={data.data.length === 0 || data.meta.total < 25}
            limit={25}
            total={data.meta.total}
            page={page}
            onChange={setPage}
          />
        )}
      </section>
    </>
  );
};

export default FoodLogs;
