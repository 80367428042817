import { CouponRule, Permission } from '@calo/dashboard-types';
import { useUserRoles } from 'lib/hooks';
interface CouponRulesProps {
  values: any;
  addRulesRef: any;
  rulesValues: CouponRule[];
  setValues: (values: any) => void;
  setRules: (value: CouponRule) => void;
}

const CouponRules = ({ rulesValues, values, setValues, addRulesRef, setRules }: CouponRulesProps) => {
  const deleteRule = (rule: CouponRule) => {
    const i = rulesValues.indexOf(rule);
    values.rules.splice(i, 1);
    setValues({
      ...values
    });
  };

  const editRule = (rule: any) => {
    addRulesRef.current?.open();
    setRules({ type: rule.type, value: rule.value });
    const i = rulesValues.indexOf(rule);
    values.rules.splice(i, 1);
    setValues({
      ...values
    });
  };
  const roles = useUserRoles();

  return (
    <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth ">
      <thead>
        <tr>
          <th>Type</th>
          <th>Value</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {rulesValues.map((k, index) => (
          <tr key={index}>
            <td>{k.type}</td>
            <td>{typeof k.value === 'object' ? (k.value as any).join(', ') : k.value}</td>
            <td>
              {roles.includes(Permission.UPDATE_COUPON) && (
                <>
                  <i className="fas fa-trash mr-4 " onClick={() => deleteRule(k)} />
                  <i className="fas fa-edit mr-4 " onClick={() => editRule(k)} />
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default CouponRules;
