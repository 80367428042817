import { GroupGiftExportType } from '@calo/dashboard-types';
import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';

export const validateSubscribers = async (values: any) => {
  const { data, status } = await client.post('/group-gifts/validate/subscriptions', {
    subscriptionIds: values.subscriptionIds,
    date: values.date,
    country: values.country,
    brand: values.brand
  });
  ToastSuccessMsgs({ status, action: 'validated successfully' });
  return data;
};

export const validateAllergens = async (values: any) => {
  const { data, status } = await client.post('/group-gifts/validate/allergens', {
    subscriptionIds: values.subscriptionIds,
    foodId: values.foodId
  });
  ToastSuccessMsgs({ status, action: 'allergic checked successfully' });
  return data;
};

export const createGiftRecord = async (values: any) => {
  const { data, status } = await client.post('group-gifts', {
    gifts: values.gifts,
    food: values.food,
    date: values.date,
    country: values.country,
    brand: values.brand,
    kitchen: values.kitchen
  });
  ToastSuccessMsgs({ status, action: 'created Successfully' });
  return data;
};

export const createGiftRecordWithAutoSelectSubscriptions = async (values: any) => {
  const { data, status } = await client.post('group-gifts-auto', {
    food: values.food,
    addons: values.addons,
    date: values.date,
    expectedLastDeliveryDate: values.expectedLastDeliveryDate,
    country: values.country,
    brand: values.brand,
    kitchen: values.kitchen
  });
  ToastSuccessMsgs({ status, action: 'created Successfully' });
  return data;
};

export const applyGiftRecord = async (id: string) => {
  const [date, checkID] = id.split('#');
  const { data, status } = await client.post(`/group-gifts/${date}/${checkID}/apply`);
  ToastSuccessMsgs({ status, action: 'released Successfully' });
  return data;
};

export const autoApplyGiftRecord = async (id: string) => {
  const [date, checkID] = id.split('#');
  const { data, status } = await client.post(`/group-gifts-auto/${date}/${checkID}/apply`);
  ToastSuccessMsgs({ status, action: 'released Successfully' });
  return data;
};

export const downloadGiftSubscriptionsLists = async (values: { id: string; type: GroupGiftExportType }) => {
  const [date, checkID] = values.id.split('#');
  const { data } = await client.get(`/group-gifts/${date}/${checkID}/${values.type}/export`);

  return data;
};

export const deleteSubscriberItem = async (values: { userId: string; id: string }) => {
  const [date, checkID] = values.id.split('#');
  const { data, status } = await client.delete(`/group-gift-items/${date}/${checkID}/${values.userId}`);
  ToastSuccessMsgs({ status, action: 'deleted Successfully' });
  return data;
};
