import { sortBy } from 'lodash-es';

import { Permission } from '@calo/dashboard-types';

import { DriverMetrics } from '@calo/driver-types';
import { Card } from 'components';
import { useUserRoles } from 'lib/hooks';
import { DriverMetricDelivery } from 'lib/interfaces';
import DeliveryRow from './DeliveryRow';

interface DeliveriesPanelProps {
  deliveries: DriverMetricDelivery[];
  driverMetric: DriverMetrics;
}

const DeliveriesCard = ({ deliveries, driverMetric }: DeliveriesPanelProps) => {
  const roles = useUserRoles();
  const sortedDeliveries = sortBy(deliveries, 'priority');
  const getReason = (id: string) => {
    return driverMetric.routePlan[id] ? driverMetric.routePlan[id].reasonForNotFollowPriority : undefined;
  };

  return (
    <Card title="Deliveries">
      <div className="card has-table has-table-container-upper-radius">
        <div className="card-content">
          <div className="table-container">
            <table className="table w-full is-striped">
              <thead>
                <tr className="bg-black">
                  <th style={{ color: 'white' }}>Name</th>
                  <th style={{ color: 'white' }}>Address</th>
                  <th style={{ color: 'white' }}>Note</th>
                  <th style={{ color: 'white' }}>Driver Note</th>
                  <th style={{ color: 'white' }}>Priority</th>
                  <th style={{ color: 'white', minWidth: '150px' }}>ETA</th>
                  <th style={{ color: 'white', minWidth: '50px' }}>DeliveredAt</th>
                  <th style={{ color: 'white', minWidth: '100px' }}>Custom Time</th>
                  <th style={{ color: 'white', minWidth: '300px' }}>Delivery Action</th>
                  <th style={{ color: 'white' }}>Diff</th>
                  <th style={{ color: 'white', minWidth: '250px', textAlign: 'center' }}>Delivery Instructions</th>
                </tr>
              </thead>
              <tbody>
                {sortedDeliveries.map((delivery, index) => (
                  <DeliveryRow
                    key={delivery.id}
                    delivery={delivery}
                    haveRoleToUpdateCustomTime={roles.includes(Permission.UPDATE_SUBSCRIPTION_CUSTOM_DELIVERY_TIME)}
                    reasonForNotFollowPriority={getReason(delivery.id)}
                    previousLocation={
                      index === 0
                        ? // @ts-ignore
                          driverMetric.startingPosition
                        : {
                            lat: sortedDeliveries[index - 1].deliveryAddress.lat,
                            lng: sortedDeliveries[index - 1].deliveryAddress.lng
                          }
                    }
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default DeliveriesCard;
