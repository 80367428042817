import { FoodType } from '@calo/types';
import { Box, Button as MUIButton, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Food, MenuFood } from 'lib/interfaces';
import React from 'react';
import FoodDietTypeTags from '../FoodDietTypeTags';
import Icon from '../Icon';
import { styles } from './styles';

interface FoodItemCardProps {
  food: Food;
  isLast: boolean;
  lastFoodElementRef: React.RefObject<HTMLDivElement>;
  handleAction: (food: Food) => void;
  handleCalculateMealCost: (food: Food) => number;
  isLoading: boolean;
  replaceFood: { open: boolean; name: string; data?: MenuFood };
}

const FoodItemCard: React.FC<FoodItemCardProps> = ({
  food,
  isLast,
  lastFoodElementRef,
  handleAction,
  handleCalculateMealCost,
  isLoading,
  replaceFood
}) => {
  return (
    <Box key={food.id} sx={styles.replaceListContainer}>
      <Stack width="100%" display="flex" flexDirection="column" ref={isLast ? lastFoodElementRef : null}>
        <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
          <Stack
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="h6" sx={styles.mealNameText}>
              {`${food.name.en}`}
            </Typography>
            <Typography
              sx={{
                ...styles.mealCostText,
                color: handleCalculateMealCost(food) > 26 ? caloTheme.palette.red : caloTheme.palette.black
              }}
              variant="h6"
            >
              {handleCalculateMealCost(food)}%
            </Typography>
            <Box display="flex" flexDirection="row" sx={{ width: '15%' }}>
              <Icon name="star" size={4} />
              <Typography sx={{ ml: '6px', mt: '-2px' }}>
                {!!food.totalRating && !!food.numberOfRatings ? (food.totalRating / food.numberOfRatings).toFixed(2) : 0}(
                {food.numberOfRatings ? food.numberOfRatings : 0})
              </Typography>
            </Box>
            <Typography variant="h6" sx={styles.mealTypeStyle}>
              {food.type.map((r) => r !== FoodType.dinner && (r.includes(FoodType.lunch) ? 'Lunch & Dinner' : r))}
            </Typography>
            <Box sx={{ ...styles.foodTagsStyle, display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', width: '20%' }}>
              {food.tags.map((tag) => (
                <FoodDietTypeTags key={tag} tag={tag} />
              ))}
            </Box>
          </Stack>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ mt: 2 }}>
          {food && (
            <Stack sx={{ ...styles.stackTagOption, flexWrap: 'wrap' }}>
              {food.foodTags?.map((tag) =>
                tag.value?.map((tagOption) => (
                  <Typography key={tagOption} sx={styles.searchTermStyle}>
                    {tagOption}
                  </Typography>
                ))
              )}
            </Stack>
          )}
          {food.usedOnMenu && food.usedOnMenu.length > 0 && (
            <Stack sx={styles.lastUsedContainer}>
              <Typography variant="h1" sx={styles.lastUsedText}>
                Last Used: {food.usedOnMenu[food.usedOnMenu.length - 1].date}
              </Typography>
            </Stack>
          )}
        </Box>
      </Stack>
      <Box sx={{ textAlign: 'right', my: 'auto' }}>
        <MUIButton variant="outlined" sx={styles.addButton} disabled={isLoading} onClick={() => handleAction(food)}>
          {replaceFood.open ? 'Select' : 'Add'}
        </MUIButton>
      </Box>
    </Box>
  );
};

export default FoodItemCard;
