import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  card: {
    mb: 2,
    width: 'full',
    border: 'none',
    borderRadius: '8px',
    paddingBottom: '4px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  boxHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    ml: 2,
    mt: 3
  },
  typography: {
    fontFamily: caloTheme.typography.fontFamily,
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    mr: 3
  },
  boxContent: {
    ml: 2,
    mt: 4,
    minHeight: '20rem'
  },
  listItem: {
    paddingLeft: 1,
    display: 'list-item',
    listStyleType: 'disc'
  },
  dialogTitle: {
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '38px'
  },
  stackPadding: {
    paddingX: 4
  },
  button: {
    px: '16px',
    mr: 4,
    borderWidth: 0,
    fontWeight: 600,
    fontSize: '16px',
    textTransform: 'none',
    color: caloTheme.palette.primary500,
    ':hover': {
      borderWidth: 0,
      color: caloTheme.palette.primary500
    }
  }
};
