import { CreateMenuReq } from '@calo/dashboard-types';
import { Country, Kitchen } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import { Box, Card, MenuItem, Stack, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components';
import Popup from 'components/Popup';
import { parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import { FormikErrors } from 'formik';
import { getAccessibleCountries, getKitchenOptions } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { PopulateMenuReq } from 'lib/interfaces';
import { startCase } from 'lodash';
import React, { useRef, useState } from 'react';
import ConfirmPopupMenu from '../ConfirmPopupMenu';

interface FilterCriteriaCardProps {
  values: CreateMenuReq;
  populateMenuData: PopulateMenuReq[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<CreateMenuReq>>;
  setPopulateMenuData: React.Dispatch<React.SetStateAction<PopulateMenuReq[]>>;
}

const FilterCriteriaCard = ({ values, populateMenuData, setPopulateMenuData, setFieldValue }: FilterCriteriaCardProps) => {
  const confirmModalRef = useRef<ModalRef>();
  const userKitchens: Kitchen[] = useUserKitchens();
  const [infoChanges, setInfoChanges] = useState({ value: '', field: '' });

  const handleChangesPopup = (field: string, data: any) => {
    if (values.food.length > 0 || populateMenuData.length > 0) {
      setInfoChanges({ value: data, field: field });
      confirmModalRef.current?.open();
    } else {
      setFieldValue(field, data);
    }
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          mb: 2,
          width: 'full',
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          component="form"
          sx={{
            width: 'full',
            mt: 2,
            '& .MuiTextField-root': { m: 2, width: '30%', justifyContent: 'space-between' },
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'row',
              width: '100%',
              '& .MuiTextField-root': { my: 2, mx: 1, width: '96%' }
            }
          }}
        >
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            sx={{
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                flexDirection: 'column',
                ml: 1
              }
            }}
          >
            <TextField
              select
              type="text"
              data-test="menu-new-country-select"
              label="Country"
              value={values.country}
              placeholder="Select Country"
              id="menu-new-country-select"
              onChange={(data: any) => {
                handleChangesPopup('country', data.target.value);
              }}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            >
              {getAccessibleCountries(userKitchens).map((country) => (
                <MenuItem key={country} value={country}>
                  {startCase(Country[country])}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              type="text"
              data-test="menu-new-kitchen-select"
              label="Kitchen"
              value={values.kitchen}
              placeholder="Select Kitchen"
              onChange={(data: any) => handleChangesPopup('kitchen', data.target.value)}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            >
              {getKitchenOptions(userKitchens, values.country)
                .filter((kitchen) => kitchen.label !== 'Any')
                .map((kitchen) => (
                  <MenuItem key={kitchen.value} value={kitchen.value}>
                    {kitchen.label}
                  </MenuItem>
                ))}
            </TextField>
            <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px' }}>
              <DesktopDatePicker
                label="Date"
                value={parseISO(values.day)}
                minDate={new Date()}
                inputFormat="dd/MM/yyyy"
                onChange={(date: any) => setFieldValue('day', format('yyyy-MM-dd')(date as Date))}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Stack>
        </Box>
      </Card>
      <Popup ref={confirmModalRef} title="Confirm details" onClose={() => confirmModalRef.current?.close()}>
        <ConfirmPopupMenu
          infoChanges={infoChanges}
          setFieldValue={setFieldValue}
          confirmModalRef={confirmModalRef}
          setPopulateMenuData={setPopulateMenuData}
        />
      </Popup>
    </>
  );
};

export default FilterCriteriaCard;
