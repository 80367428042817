import { Icon } from '@iconify/react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import React, { useEffect, useRef } from 'react';
import styles from './styles';

interface ConfirmationPopupProps {
  isOpen: boolean;
  title: string;
  content: string | JSX.Element[];
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  isOpen,
  title,
  content,
  onConfirm,
  onCancel,
  confirmText = 'Confirm',
  cancelText = 'Cancel'
}) => {
  const confirmationRef = useRef<ModalRef>();

  useEffect(() => {
    if (isOpen) {
      confirmationRef.current?.open();
    } else {
      confirmationRef.current?.close();
    }
  }, [isOpen]);

  return (
    <Popup fullWidth ref={confirmationRef} maxWidth={'md'} onClose={onCancel}>
      <Box sx={styles.container}>
        <Stack sx={styles.container}>
          <Stack sx={styles.headerStack}>
            <Typography sx={styles.titleText}>{title}</Typography>
            <IconButton onClick={onCancel}>
              <Icon icon="carbon:close" />
            </IconButton>
          </Stack>
          <Typography sx={styles.contentText}>{content}</Typography>
        </Stack>
        <Stack sx={styles.rawStack}>
          <Button sx={styles.cancelButtonPopup} onClick={onCancel}>
            {cancelText}
          </Button>
          <Button sx={styles.confirmButtonPopup} onClick={onConfirm} color="primary" autoFocus>
            {confirmText}
          </Button>
        </Stack>
      </Box>
    </Popup>
  );
};

export default ConfirmationPopup;
