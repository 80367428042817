import { SxProps, Theme } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

const commonTextStyle: SxProps<Theme> | undefined = {
  fontFamily: caloTheme.typography.fontFamily,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '17px',
  textTransform: 'capitalize'
};

export const styles: { [key: string]: SxProps<Theme> | undefined } = {
  mainContainer: {
    my: 2
  },
  typeText: {
    width: '260px',
    mr: 2
  },
  datePickerStyle: {
    width: '50%',
    marginRight: '16px',
    borderRadius: 12
  },
  searchTermStyle: {
    backgroundColor: caloTheme.palette.secondaryBlue100,
    mr: 1,
    textTransform: 'capitalize',
    color: caloTheme.palette.secondaryBlue700,
    borderRadius: '37px',
    padding: '4px 12px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    gap: '8px',
    mb: 1
  },
  addButton: {
    ml: 4,
    textTransform: 'capitalize',
    width: '92px',
    height: '48px',
    lineHeight: '21px',
    fontWeight: 400,
    fontSize: '18px',
    borderRadius: '8px',
    borderColor: caloTheme.palette.primary500,
    color: caloTheme.palette.primary500,
    '&:hover': {
      color: caloTheme.palette.primary600,
      borderColor: caloTheme.palette.primary600
    }
  },
  noMealResultStyle: {
    ...commonTextStyle,
    color: caloTheme.palette.neutral500,
    fontSize: '23px',
    fontWeight: 600,
    lineHeight: '28px',
    textAlign: 'center'
  },
  filterButtonStyle: {
    width: '262px',
    height: '51px',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
    backgroundColor: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: caloTheme.palette.primary600,
      borderColor: caloTheme.palette.primary600
    }
  },
  lastUsedContainer: {
    width: '20%',
    display: 'flex',
    flexDirection: 'row'
  },
  lastUsedText: {
    ...commonTextStyle
  },
  stackTagOption: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    mb: '4px'
  },
  autoCompleteField: {
    width: '99%',
    mr: 2,
    mt: 1,
    mb: 1,
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
      mb: 1
    }
  },
  replaceContainer: {
    padding: 1,
    overflow: 'auto',
    minWidth: '800px',
    borderRadius: '8px',
    maxHeight: '480px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      minWidth: '100%',
      maxHeight: '100%'
    }
  },
  replaceListContainer: {
    width: '100%',
    display: 'flex',
    padding: 1,
    mb: 1,
    minHeight: '130px',
    borderBottomWidth: 2,
    borderBottomColor: caloTheme.palette.neutral100,
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      minWidth: '100%',
      maxHeight: '70vh'
    }
  },
  mealTypeStyle: {
    ...commonTextStyle,
    width: '15%',
    textTransform: 'capitalize'
  },
  foodTagsStyle: {
    ...commonTextStyle,
    justifyContent: 'end',
    lineHeight: '23px',
    display: 'flex',
    flexDirection: 'column',
    mt: '-4px'
  },
  mealNameText: {
    ...commonTextStyle,
    fontFamily: caloTheme.typography.fontFamily,
    fontWeight: 600,
    fontSize: '19px',
    lineHeight: '23px',
    width: '40%'
  },
  mealCostText: {
    fontFamily: caloTheme.typography.fontFamily,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    width: '15%'
  }
};
