import { ShiftActionType } from '@calo/dashboard-types';
import { DriverMetrics } from '@calo/driver-types';
import { format } from 'date-fns/fp';

export const getAction = (row: DriverMetrics, type: ShiftActionType) => {
  if (row.driverActions) {
    const index = row.driverActions.findIndex((a) => a.type === type);
    return index === -1 ? '--:--' : format('hh:mm aaa')(Date.parse(row.driverActions[index].time));
  }
  return '--:--';
};

export const getAverageDeliveryTime = (row: DriverMetrics) => {
  const millisec = getShiftDurationMillisec(row);
  if (millisec && row.deliveredDeliveries) {
    const avg = millisec / row.deliveredDeliveries;
    return avg;
  }
  return null;
};

export const getShiftDurationMillisec = (row: DriverMetrics) => {
  const startInd = row.driverActions?.findIndex((a) => a.type === 'STARTED_DELIVERING');
  const endInd = row.driverActions?.findIndex((a) => a.type === 'FINISHED_SHIFT');
  if (row.driverActions && startInd !== -1 && endInd !== -1) {
    const startTime = Date.parse(row.driverActions[startInd].time);
    const endTime = Date.parse(row.driverActions[endInd].time);
    return endTime - startTime;
  }
  return null;
};

export const formatTime = (millisec: number | null) => {
  if (millisec) {
    const rawSec = millisec / 1000;
    const h = Math.floor(rawSec / 3600);
    const formH = h > 9 ? h : `0${h}`;
    const m = Math.round((rawSec - h * 3600) / 60);
    const formM = m > 9 ? m : `0${m}`;
    return `${formH}:${formM}`;
  }
  return '--:--';
};

export const getFormatedTimeInMinutes = (millisec: number | null) => {
  if (millisec) {
    const m = Math.round(millisec / 1000 / 60);
    return `${m} min`;
  }
  return '--';
};

export const differentInMinutesForDates = (firstDate?: string, secondDate?: string) => {
  if (firstDate && secondDate) {
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);
    const diffTime: number = date2.getTime() - date1.getTime();
    return getFormatedTimeInMinutes(diffTime);
  }
  return '--';
};

export const compareWithAverage = (row: DriverMetrics, totalAvgDeliveryTime: number) => {
  const avg = getAverageDeliveryTime(row);
  if (avg && totalAvgDeliveryTime >= Math.floor(avg / 100 / 60) / 10) {
    return false;
  }
  return true;
};
