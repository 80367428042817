import { useFormik } from 'formik';

import { UpdateMenuReq } from '@calo/dashboard-types';

import { Menu } from 'lib/interfaces';

export default (menu: Menu, onSubmit: (values: UpdateMenuReq) => Promise<void>) =>
  useFormik<UpdateMenuReq>({
    enableReinitialize: true,
    initialValues: {
      day: menu.id,
      country: menu.country,
      food: menu?.food.map((f) => f.id) || [],
      tags: menu?.tags as any,
      brand: menu.brand,
      kitchen: menu.kitchen
    },

    validate: (values: UpdateMenuReq) => {
      const errors: any = {};
      if (values.food && values.food.length === 0) {
        errors.food = true;
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
