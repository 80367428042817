import { SxProps, Theme } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

type Styles = {
  [key: string]: SxProps<Theme>;
};

const styles: Styles = {
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    px: 2,
    mb: 1,
    zIndex: 0,
    marginBottom: 2
  },
  sectionHeader: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: caloTheme.palette.neutral900,
    fontFamily: caloTheme.typography.fontFamily,
    marginBottom: 2
  },
  subHeader: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    color: caloTheme.palette.neutral900,
    fontFamily: caloTheme.typography.fontFamily
  },
  headerNote: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px'
  },
  createAffiliateContainer: {
    mr: 2,
    display: 'flex',
    marginLeft: 'auto',
    height: '51px',
    fontWeight: 600,
    lineHeight: '23px',
    fontSize: '14px',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    boxShadow: 'none',
    color: caloTheme.palette.white,
    backgroundColor: caloTheme.palette.primary500,
    '&:hover': {
      boxShadow: 'none',
      color: caloTheme.palette.white,
      backgroundColor: caloTheme.palette.primary600
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      display: 'flex',
      justifyItems: 'center',
      m: 'auto',
      mb: 2,
      mt: '-4px',
      width: 'auto'
    }
  }
};

export default styles;
