import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  listItem: {
    paddingLeft: 1,
    display: 'list-item',
    listStyleType: 'disc'
  },
  dialogTitle: {
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '38px'
  },
  stackPadding: {
    paddingX: 4
  },
  button: {
    px: '16px',
    mr: 4,
    borderWidth: 0,
    fontWeight: 600,
    fontSize: '16px',
    textTransform: 'none',
    color: caloTheme.palette.primary500,
    ':hover': {
      borderWidth: 0,
      color: caloTheme.palette.primary500
    }
  }
};
