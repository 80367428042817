import { caloTheme } from 'assets/images/theme/calo';

import { CupSizes } from '@calo/dashboard-types';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface CupSizesProps {
  values: any;
  addCupsRef: any;
  isDisabled: boolean;
  setValues: (values: any) => void;
  setCups: (value: CupSizes) => any;
}

const CupSizesMUI = ({ isDisabled, values, setValues, addCupsRef, setCups }: CupSizesProps) => {
  const deleteCup = (rule: any) => {
    const i = values.cups?.findIndex((e: any) => e === rule);
    values.cups?.splice(i, 1);
    setValues({
      ...values
    });
  };

  const editCup = (rule: CupSizes) => {
    addCupsRef.current?.open();
    setCups({ size: rule.size, value: rule.value });
    const i = values.cups.indexOf(rule);
    values.cups.splice(i, 1);
  };

  const isDeleteButtonActive = !isDisabled;

  return (
    <Paper sx={{ width: '96%', m: 2, mt: 0, boxShadow: 'none' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: caloTheme.palette.neutral900, fontWeight: 600, width: '40%' }}>Size</TableCell>
              <TableCell style={{ color: caloTheme.palette.neutral900, fontWeight: 600 }}>Value</TableCell>
              <TableCell sx={{ textAlign: 'end !important' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isNaN(values.cups) &&
              values.cups?.map((k: CupSizes, index: any) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      fontSize: '16px',
                      fontWeight: 600,
                      py: '4px !important',
                      border: '1px solid' + caloTheme.palette.neutral100,
                      borderRightWidth: 0,
                      borderLeftWidth: 0
                    }}
                  >
                    {k.size}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '16px',
                      fontWeight: 600,
                      py: '4px !important',
                      border: '1px solid' + caloTheme.palette.neutral100,
                      borderRightWidth: 0,
                      borderLeftWidth: 0
                    }}
                  >
                    {k.value}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'end !important',
                      py: '4px !important',
                      border: '1px solid' + caloTheme.palette.neutral100,
                      borderRightWidth: 0,
                      borderLeftWidth: 0
                    }}
                  >
                    {!isDisabled && (
                      <Stack
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '12px',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <IconButton
                          data-test={`cupsDeleteButton#${index}`}
                          disabled={!isDeleteButtonActive}
                          onClick={() => deleteCup(k)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                        <IconButton
                          data-test={`cupsEditButton#${index}`}
                          disabled={!isDeleteButtonActive}
                          onClick={() => editCup(k)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Stack>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CupSizesMUI;
