import { UpdateRetailMenuReq } from '@calo/dashboard-types';
import { Brand, RetailBranch } from '@calo/types';
import { useFormik } from 'formik';

export default (retailMenu: any, brand: Brand, branch: RetailBranch, onSubmit: (values: UpdateRetailMenuReq) => Promise<void>) =>
  useFormik<UpdateRetailMenuReq>({
    enableReinitialize: true,
    initialValues: {
      brand: brand,
      branch: branch,
      menuId: retailMenu.id,
      food:
        retailMenu?.food.map((f: any) => ({
          id: f.id,
          priority: +f.priority || 1,
          retailPrice: f.retail.retailPrice,
          retailTagList: f.retail.retailTagList,
          foodModifiers: f.retail.foodModifiers,
          disabled: !!f.retail.disabled,
          swapAvailable: !!f.retail.swapAvailable
        })) || []
    },

    validate: (values: UpdateRetailMenuReq) => {
      const errors: any = {};

      if (values.food.length === 0) {
        errors.food = true;
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
