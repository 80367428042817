import { startCase } from 'lodash';

import { CustomComponentSection, CustomMealCategory, FoodComponentType } from '@calo/types';
import { Icon } from '@iconify/react';
import { Checkbox, FormControlLabel, IconButton, MenuItem, TableCell, TableRow, TextField } from '@mui/material';

import { InputMUI } from '../..';
import { caloTheme } from '../../../assets/images/theme/calo';
import { handleCategoryText } from './helpers';

interface CustomSectionRowProps {
  section: CustomComponentSection;
  isValid: boolean;
  isDisabled?: boolean;
  updateQuantity: (category: CustomMealCategory, quantity: number) => void;
  removeSection: (category: CustomMealCategory) => void;
  toggleSectionAvailability: (category: CustomMealCategory) => void;
  selectTag: (category: CustomMealCategory, tag: FoodComponentType) => void;
}

const CustomSectionRow = ({
  section,
  isValid,
  isDisabled,
  selectTag,
  updateQuantity,
  removeSection,
  toggleSectionAvailability
}: CustomSectionRowProps) => {
  return (
    <TableRow sx={{ borderBottom: '1px solid ' + caloTheme.palette.neutral100 }}>
      <TableCell sx={{ padding: '8px' }}>{startCase(handleCategoryText(section.category))}</TableCell>
      <TableCell sx={{ padding: '8px' }}>
        <InputMUI
          data-test={`sectionQuantityInput#${section.category}`}
          type="number"
          value={section.quantity}
          onChange={(e) => updateQuantity(section.category, Number(e.target.value))}
          min={0}
          step="any"
          debounce
          disabled={isDisabled}
          error={!isValid}
          sx={{
            width: '90%'
          }}
          inputProps={{
            style: {
              height: '46px',
              fontSize: '16px'
            }
          }}
        />
      </TableCell>
      <TableCell sx={{ padding: '8px' }}>
        <TextField
          data-test={`sectionTagInput#${section.category}`}
          select
          label="Tag"
          value={section.tag ? section.tag : ''}
          SelectProps={{
            multiple: false,
            value: section.tag ? section.tag : '',
            onChange: (e) => selectTag(section.category, e.target.value as FoodComponentType),
            sx: {
              '.MuiSelect-select': {
                padding: '12px',
                alignContent: 'center'
              }
            }
          }}
          sx={{ my: 0, width: '60%' }}
          disabled={isDisabled}
        >
          {Object.values(FoodComponentType).map((prepOrder) => (
            <MenuItem key={prepOrder} value={prepOrder}>
              {prepOrder}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell sx={{ padding: '8px', paddingLeft: 0 }}>
        <FormControlLabel
          control={
            <Checkbox
              data-test={`availabilityCheckbox#${section.category}`}
              name="availability"
              checked={section.availability}
              onChange={() => toggleSectionAvailability(section.category)}
              disabled={isDisabled}
            />
          }
          label=""
        />
      </TableCell>
      <TableCell sx={{ padding: '8px' }}>
        <IconButton data-test={`removeSectionButton#${section.category}`} onClick={() => removeSection(section.category)}>
          <Icon icon="ph:trash" width={18} height={18} color="black" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default CustomSectionRow;
