import { PickersDay, PickersDayProps, pickersDayClasses } from '@mui/x-date-pickers';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns/fp';
export interface DeliveryDayStatusProps {
  skipped: string[];
  delivered: string[];
  cancelled: string[];
  pending: string[];
  paused: string[];
}
const handleRenderDay = (day: Date, DayComponentProps: PickersDayProps<Date>, deliveryDayStatus: DeliveryDayStatusProps) => {
  const today = format('yyyy-MM-dd')(new Date());
  const calenderDay = format('yyyy-MM-dd')(day);
  if (calenderDay === today) {
    return (
      <PickersDay
        style={{
          color: 'white',
          backgroundColor: caloTheme.palette.primary500,
          borderColor: DayComponentProps.tabIndex === 0 ? caloTheme.palette.primary900 : caloTheme.palette.primary500
        }}
        sx={{
          [`&&.${pickersDayClasses.selected}`]: {
            border: 1,
            color: 'white',
            backgroundColor: caloTheme.palette.primary500,
            borderColor: caloTheme.palette.primary900
          }
        }}
        {...DayComponentProps}
      />
    );
  } else if (deliveryDayStatus.skipped.includes(calenderDay)) {
    return (
      <PickersDay
        sx={{
          backgroundColor: caloTheme.palette.neutral700,
          textDecoration: 'line-through',
          color: 'black',
          ':hover': { color: 'black', backgroundColor: caloTheme.palette.neutral700 },
          [`&&.${pickersDayClasses.selected}`]: {
            border: 1,
            color: 'black',
            backgroundColor: caloTheme.palette.neutral700,
            borderColor: caloTheme.palette.primary600
          }
        }}
        {...DayComponentProps}
      />
    );
  } else if (deliveryDayStatus.delivered.includes(calenderDay)) {
    return (
      <PickersDay
        sx={{
          backgroundColor: caloTheme.palette.primary100,
          color: 'black',
          ':hover': { color: 'black', backgroundColor: caloTheme.palette.primary100 },
          [`&&.${pickersDayClasses.selected}`]: {
            border: 1,
            color: 'black',
            backgroundColor: caloTheme.palette.primary100,
            borderColor: caloTheme.palette.primary600
          }
        }}
        {...DayComponentProps}
      />
    );
  } else if (deliveryDayStatus.cancelled.includes(calenderDay)) {
    return (
      <PickersDay
        sx={{
          backgroundColor: caloTheme.palette.secondaryRed100,
          color: 'red',
          ':hover': { color: 'red', backgroundColor: caloTheme.palette.secondaryRed100 },
          [`&&.${pickersDayClasses.selected}`]: {
            border: 1,
            color: 'red',
            backgroundColor: caloTheme.palette.secondaryRed100,
            borderColor: caloTheme.palette.primary600
          }
        }}
        {...DayComponentProps}
      />
    );
  } else if (deliveryDayStatus.paused.includes(calenderDay)) {
    return (
      <PickersDay
        sx={{
          backgroundColor: caloTheme.palette.secondaryPurple100,
          color: 'black',
          ':hover': { color: 'black', backgroundColor: caloTheme.palette.secondaryPurple100 },
          [`&&.${pickersDayClasses.selected}`]: {
            border: 1,
            color: 'black',
            backgroundColor: caloTheme.palette.secondaryPurple100,
            borderColor: caloTheme.palette.primary600
          }
        }}
        {...DayComponentProps}
      />
    );
  } else if (deliveryDayStatus.pending.includes(calenderDay)) {
    return (
      <PickersDay
        sx={{
          backgroundColor: caloTheme.palette.secondaryYellow100,
          color: 'black',
          ':hover': { color: 'black', backgroundColor: caloTheme.palette.secondaryYellow100 },
          [`&&.${pickersDayClasses.selected}`]: {
            border: 1,
            color: 'black',
            backgroundColor: caloTheme.palette.secondaryYellow100,
            borderColor: caloTheme.palette.primary600
          }
        }}
        {...DayComponentProps}
      />
    );
  } else {
    return (
      <PickersDay
        sx={{
          [`&&.${pickersDayClasses.selected}`]: {
            backgroundColor: '#0000',
            color: 'black',
            borderColor: caloTheme.palette.primary600,
            borderStyle: 'dashed',
            border: 'none'
          }
        }}
        {...DayComponentProps}
      />
    );
  }
};
export default handleRenderDay;
