import React from 'react';
import { Button, Modal, ModalRef } from '../';

interface ConfirmDeleteModalProps {
  confirmModalRef: React.MutableRefObject<ModalRef | undefined>;
  selected: any;
  setSelected: (value: any) => void;
  handleDelete: (step: any) => void;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({ confirmModalRef, selected, setSelected, handleDelete }) => {
  const handleDeleteStep = () => {
    handleDelete(selected);
    confirmModalRef.current?.close();
  };

  const handleCancel = () => {
    setSelected('');
    confirmModalRef.current?.close();
  };

  return (
    <Modal ref={confirmModalRef} onClose={() => confirmModalRef.current?.close()}>
      <section className="section is-title-bar label -my-8 ">
        <p className="label uppercase text-center">Are you sure you want to delete step</p>
      </section>
      <div className="ml-4">
        <label>Are you sure you want to delete the step as well as the image attached?</label>
      </div>
      <section>
        <div className="flex justify-between mx-auto -mb-6 mt-6 w-1/2">
          <Button primary content="Delete" onClick={handleDeleteStep} />
          <Button content="Cancel" className="mr-2" onClick={handleCancel} />
        </div>
      </section>
    </Modal>
  );
};

export default ConfirmDeleteModal;
