import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

interface CheckboxProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  error?: boolean;
  customStyles?: any;
}

const Checkbox = ({ label, error: _error, customStyles, ...rest }: CheckboxProps) => (
  <div className="field">
    <div className="control">
      <label className="checkbox label">
        <input {...rest} type="checkbox" style={customStyles} /> {label}
      </label>
    </div>
  </div>
);

export default Checkbox;
