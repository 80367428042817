import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

interface ModalContentCardProps {
  children: ReactNode;
}

const StyledContent = styled(Box)({
  flexGrow: 1,
  display: 'block',
  paddingTop: '16px',
  overflow: 'hidden',
  backgroundColor: '#FAFAFA',
  height: '100%',
  borderBottomRightRadius: '8px',
  borderBottomLeftRadius: '8px'
});

const ModalContentCard = ({ children }: ModalContentCardProps) => {
  return <StyledContent>{children}</StyledContent>;
};

export default ModalContentCard;
