import { ComponentService } from '@calo/services';
import { Dictionary, FoodComponentType } from '@calo/types';
import { FoodComponent, FoodComponentWithQuantity, Ingredient } from 'lib/interfaces';
import { keyBy, orderBy } from 'lodash';

export const getOrderedComponents = (components: any[], allComponents: Dictionary<FoodComponent>) => {
  let availableComponents = [...components];
  const orderedComponents: any[] = [];
  const orderByNames = [
    FoodComponentType.base,
    FoodComponentType.side,
    FoodComponentType.protein,
    FoodComponentType.topping,
    FoodComponentType.sauce
  ];

  for (const foodComponentType of orderByNames) {
    const filtered = availableComponents.filter((comp) =>
      (allComponents[comp.id]?.sections ?? []).map((section) => section.tag).includes(foodComponentType)
    );
    orderedComponents.push(...filtered);
    availableComponents = availableComponents.filter(
      (comp) =>
        !allComponents[comp.id] ||
        !(allComponents[comp.id]?.sections ?? []).map((section) => section.tag).includes(foodComponentType)
    );
  }
  orderedComponents.push(...availableComponents);
  return orderedComponents;
};

const calculateFractionWeight = (component: FoodComponent, quantity: number, calculatedWeight: number): number => {
  const totalWeight =
    ComponentService.calculateTotalComponentAbsoluteWeight(
      component,
      component.ingredients,
      keyBy(component.childComponents, 'id')
    ) || 1;
  const fraction = quantity / totalWeight;
  return calculatedWeight * fraction;
};

export const processComponentIngredients = (components: FoodComponentWithQuantity[]): any[] => {
  const ingredients: { [id: string]: Ingredient } = {};

  for (const component of components) {
    const totalComponentWeight =
      ComponentService.calculateTotalComponentAbsoluteWeight(
        component,
        component.ingredients,
        keyBy(component.childComponents, 'id')
      ) || 1;

    // component weight in meal
    const calculatedComponentWeight = ComponentService.calculateComponentWeight(
      component.cups ?? [],
      component.measurementUnit,
      component.weight ?? 0,
      component.quantity ?? 0
    );

    for (const ingredient of component.ingredients) {
      const calculatedIngredientWeight = calculateFractionWeight(component, ingredient.quantity ?? 0, calculatedComponentWeight);
      if (ingredients[ingredient.id]) {
        ingredients[ingredient.id].quantity = ingredients[ingredient.id].quantity ?? 0 + calculatedIngredientWeight;
      } else {
        ingredients[ingredient.id] = { ...ingredient, quantity: calculatedIngredientWeight };
      }
    }

    for (const childComponent of (component.childComponents as any as FoodComponentWithQuantity[]) ?? []) {
      // component weight in parent component
      const calculatedChildComponentWeight = ComponentService.calculateComponentWeight(
        childComponent.cups ?? [],
        childComponent.measurementUnit,
        childComponent.weight ?? 0,
        childComponent.quantity ?? 0
      );

      for (const ingredient of childComponent.ingredients) {
        const ingredientWeightInChild = calculateFractionWeight(
          childComponent,
          ingredient.quantity ?? 0,
          calculatedChildComponentWeight
        );
        const adjustedIngredientWeight = (ingredientWeightInChild / totalComponentWeight) * calculatedComponentWeight;
        if (ingredients[ingredient.id]) {
          ingredients[ingredient.id].quantity = (ingredients[ingredient.id].quantity ?? 0) + adjustedIngredientWeight;
        } else {
          ingredients[ingredient.id] = { ...ingredient, quantity: adjustedIngredientWeight };
        }
      }
    }
  }

  return orderBy(Object.values(ingredients), ['quantity'], ['desc']);
};
