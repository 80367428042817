import ExcelJS from 'exceljs';
import { Food, FoodComponent } from 'lib/interfaces';
import { range, round } from 'lodash';
import {
  addCellBorderToFilledCells,
  addCellBorders,
  addEmptyRow,
  addLabelValueRow,
  addTableHeaderRow,
  clearRows,
  leftBorderStylesForCell,
  removeRowBorders,
  topBorderStylesForCell
} from './excelUtils';

const createComponentsWorksheet = (workbook: ExcelJS.Workbook, selectedFood: Food, selectedFoodComponents: FoodComponent[]) => {
  const worksheet = workbook.addWorksheet('components');

  const colCount = 10;

  worksheet.getColumn(2).width = 20;
  worksheet.getColumn(3).width = 20;
  worksheet.getColumn(4).width = 10;
  worksheet.getColumn(5).width = 10;
  worksheet.getColumn(6).width = 10;
  worksheet.getColumn(7).width = 12;
  worksheet.getColumn(8).width = 12;
  worksheet.getColumn(9).width = 15;

  let rowIndex = 1;
  let i = 0;

  for (const component of selectedFoodComponents) {
    rowIndex = clearRows(worksheet, rowIndex, 4, colCount);
    if (i !== 0) {
      worksheet.getRow(rowIndex - 4).getCell(colCount - 1).border = topBorderStylesForCell;
    }
    addLabelValueRow(worksheet, rowIndex, colCount, 'KITCHEN', selectedFood.kitchen);
    rowIndex++;
    addLabelValueRow(worksheet, rowIndex, colCount, 'COMPONENT NAME', component.name.en);
    rowIndex++;
    addEmptyRow(worksheet, rowIndex, colCount);
    rowIndex++;
    const values = ['', '', 'INGREDIENTS', 'PREPPED WEIGHT', 'WASTE FACTOR', 'RAW WEIGHT', 'UNIT', 'UNIT COST', 'TOTAL COST', ''];
    addTableHeaderRow(worksheet, rowIndex, values);
    rowIndex++;
    rowIndex = addIngredientsRowsForComponent(worksheet, rowIndex, colCount, component);

    i++;
  }
};

const addIngredientsRowsForComponent = (
  worksheet: ExcelJS.Worksheet,
  rowIndex: number,
  colCount: number,
  component: FoodComponent
) => {
  let totalCost = 0;
  for (const ingredient of component.ingredients ?? []) {
    const ingredientRow = worksheet.getRow(rowIndex);
    ingredientRow.height = 15;
    ingredientRow.alignment = { horizontal: 'center' };
    const ingredientWeight = (ingredient.quantity ?? 0) * (ingredient.weight ?? 0);
    const ingredientWeightBeforeCooking = ingredientWeight * (ingredient.wastage ?? 1);
    const ingredientCost = (ingredient.quantity ?? 0) * (ingredient.cost ?? 0) * (ingredient.wastage ?? 1);
    ingredientRow.values = [
      '',
      '',
      ingredient.name.en,
      ingredientWeight,
      ingredient.wastage ?? 1,
      ingredientWeightBeforeCooking,
      ingredient.measurementUnit,
      ingredient.cost,
      ingredientCost,
      ''
    ];
    removeRowBorders(ingredientRow);
    addCellBorderToFilledCells(ingredientRow, colCount);
    totalCost += ingredientCost;
    rowIndex++;
  }
  const totalCostRow = worksheet.getRow(rowIndex);
  totalCostRow.height = 20;
  totalCostRow.values = ['', '', '', '', '', '', 'TOTAL COST', '', totalCost, ''];
  removeRowBorders(totalCostRow);
  totalCostRow.eachCell((cell, colNumber) => {
    if (range(3, 3 + colCount - 3).includes(colNumber)) {
      cell.border = topBorderStylesForCell;
    }

    if (colNumber === colCount - 1) {
      addCellBorders(cell);
    }

    if (colNumber === colCount) {
      cell.border = leftBorderStylesForCell;
    }
  });
  rowIndex++;

  const constPerGramRow = worksheet.getRow(rowIndex);
  constPerGramRow.height = 20;

  constPerGramRow.values = [
    '',
    '',
    '',
    '',
    '',
    '',
    'COST PER g',
    '',
    round((component.purchasingCost ?? 0) / (component.weight ?? 1), 5),
    ''
  ];
  removeRowBorders(constPerGramRow);
  constPerGramRow.eachCell((cell, colNumber) => {
    if (colNumber === colCount - 1) {
      addCellBorders(cell);
    }

    if (colNumber === colCount) {
      cell.border = leftBorderStylesForCell;
    }
  });
  rowIndex++;
  return rowIndex;
};

export default createComponentsWorksheet;
