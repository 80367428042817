import client from 'lib/client';
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

interface UseReferralTransactionsProps {
  subscriptionId: string;
}

const useReferralTransactions = (props: UseReferralTransactionsProps) => {
  const { subscriptionId } = props;

  const { data: transactionsData, isLoading: isFetchingTransactions } = useInfiniteQuery<{
    data: any[];
    paginationKey?: string;
  }>(
    [`referrals/${subscriptionId}/transactions`],
    async ({ pageParam, queryKey }) => {
      const { data } = await client.get(queryKey[0] as string, {
        ...(pageParam &&
          ({
            paginationKey: pageParam
          } as any))
      });
      return data;
    },
    {
      getNextPageParam: (data) => data.paginationKey
    }
  );

  const transactionsList = useMemo(
    () =>
      (transactionsData?.pages || []).reduce<any[]>((res, r) => {
        res = [...res, ...r.data];
        return res;
      }, []),
    [transactionsData]
  );

  return {
    data: transactionsData,
    isLoading: isFetchingTransactions,
    transactionsList
  };
};

export default useReferralTransactions;
