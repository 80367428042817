import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { dayMappings } from 'lib/enums';
import { memo } from 'react';
import styles from './styles';

interface WeekDayToggleButtonsProps {
  selectedDay: number;
  handleDaysChange: (newDayIndex: number) => void;
}

const WeekDayToggleButtons = memo(({ selectedDay, handleDaysChange }: WeekDayToggleButtonsProps) => (
  <ToggleButtonGroup
    exclusive
    value={selectedDay}
    onChange={(event, value) => value !== null && handleDaysChange(value)}
    style={{ width: '100%', justifyContent: 'space-between' }}
  >
    {Object.values(dayMappings).map((shortDay, index) => (
      <ToggleButton
        key={index}
        value={index}
        aria-label={shortDay}
        style={{
          ...styles.toggleStyle,
          backgroundColor: selectedDay === index ? caloTheme.palette.primary500 : 'white'
        }}
      >
        <Typography
          sx={{
            ...styles.shortDayText,
            color: selectedDay === index ? 'white' : caloTheme.palette.neutral900
          }}
        >
          {shortDay}
        </Typography>
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
));

export default WeekDayToggleButtons;
