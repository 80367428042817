import { DriverCreateReq, DriverStatus } from '@calo/dashboard-types';
import { Country, Kitchen } from '@calo/types';
import { useFormik } from 'formik';
import { generatePassword } from 'lib/helpers';

export default (onSubmit: (values: Omit<DriverCreateReq, 'id'>) => any) =>
  useFormik<Omit<DriverCreateReq, 'id'>>({
    initialValues: {
      name: '',
      phoneNumber: '',
      email: '',
      address: '',
      password: generatePassword(),
      country: Country.BH,
      status: DriverStatus.active,
      kitchen: Kitchen.BH1
    },
    validate: (values: Omit<DriverCreateReq, 'id'>) => {
      const errors: any = {};
      if (!values.name) {
        errors.name = true;
      }

      if (!values.email) {
        errors.email = true;
      }

      // const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);
      // if (!phoneNumber || (phoneNumber && !phoneNumber.isValid())) {
      //   errors.phoneNumber = true;
      // }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
