import { ComponentService } from '@calo/services';
import { Macros, Micronutrients } from '@calo/types';
import { round, sumBy } from 'lodash-es';
import { toast } from 'react-toastify';
import { FoodComponentWithQuantity, Ingredient } from '../interfaces';

export const calculateMacrosFromIngredients = (
  ingredients: Ingredient[],
  childComponentsWithQuantity: FoodComponentWithQuantity[],
  cookedRawFactor: number
) => {
  let totalChildComponentsWeight = 0;
  let totalMicronutrients: Micronutrients = {
    addedSugar: 0,
    cholesterol: 0,
    saturatedFats: 0,
    sodium: 0,
    totalSugar: 0,
    transFats: 0
  };
  let totalMacros: Macros = { cal: 0, carbs: 0, fat: 0, protein: 0, fiber: 0 };

  const totalIngredientsWeight = sumBy(ingredients, (ing) => (ing.quantity || 0) * (ing.weight || 0)) ?? 0;

  for (const ing of ingredients) {
    if (!ing.macros) {
      return showMissingIngredientToast(ing.name.en);
    }

    totalMacros = addMacrosOrMicros(ing.macros, totalMacros, ing.quantity);
    totalMicronutrients = addMacrosOrMicros(ing.micronutrients || {}, totalMicronutrients, ing.quantity);
  }

  for (const child of childComponentsWithQuantity) {
    if (!child) {
      return;
    }
    if (!child.macros) {
      return showMissingComponentToast(child.name.en);
    }

    const childWeight = round(
      ComponentService.calculateComponentWeight(child.cups!, child.measurementUnit, child.weight ?? 0, child.quantity),
      6
    );

    totalChildComponentsWeight += childWeight;
    totalMacros = addMacrosOrMicros(child.macros, totalMacros, childWeight);
    totalMicronutrients = addMacrosOrMicros(child.micronutrients || {}, totalMicronutrients, childWeight);
  }

  const totalQuantity = totalIngredientsWeight + totalChildComponentsWeight;

  let calculatedMacros = calculateMacros(totalMacros, totalQuantity, cookedRawFactor);
  const calculatedMicronutrients = calculateMacros(totalMicronutrients, totalQuantity, cookedRawFactor);
  calculatedMacros = {
    ...calculatedMacros,
    cal: (calculatedMacros?.fat || 0) * 9 + (calculatedMacros?.carbs || 0) * 4 + (calculatedMacros?.protein || 0) * 4
  };
  return {
    macros: roundMacrosToFourDecimals(calculatedMacros),
    micronutrients: roundMacrosToFourDecimals(calculatedMicronutrients)
  };
};

const addMacrosOrMicros = (valuesToAdd: Macros | Micronutrients, oldValues: Macros | Micronutrients, weight = 1) => {
  const newValues: any = {};
  for (const [key, _] of Object.entries(oldValues)) {
    newValues[key] = (oldValues[key] || 0) + (valuesToAdd[key] || 0) * weight;
  }
  return newValues;
};

const calculateMacros = (totalMacrosOrMicros: Macros | Micronutrients, totalQuantity: number, cookedRawFactor: number) => {
  const newValues: any = {};
  for (const [key, value] of Object.entries(totalMacrosOrMicros)) {
    newValues[key] = ((value || 0) / (totalQuantity || 1)) * (cookedRawFactor || 1);
  }
  return newValues;
};

const roundMacrosToFourDecimals = (oldValue: Macros | Micronutrients) => {
  const newValue: any = {};
  for (const [key, value] of Object.entries(oldValue)) {
    newValue[key] = Math.round(value * 10000) / 10000;
  }
  return newValue;
};

const showMissingIngredientToast = (name: string) => {
  toast(`Ingredient ${name} is missing macros`, {
    type: 'error',
    autoClose: 2000
  });
};

const showMissingComponentToast = (name: string) => {
  toast(`Component ${name} is missing macros`, {
    type: 'error',
    autoClose: 2000
  });
};
