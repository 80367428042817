import { forwardRef } from 'react';

import { FormikErrors } from 'formik';

import { UpdateFoodReq } from '@calo/dashboard-types';
import { Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { UpdateFoodReqWithIndex } from 'lib/interfaces';
import { FoodNutritionalForm } from '../MUI';

interface NutritionalInformationProps {
  values: UpdateFoodReq;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors: FormikErrors<UpdateFoodReqWithIndex>;
  handleBlur: (e: React.ChangeEvent<any>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  microNutrientsAuto: boolean;
  handleAutoCalculate: (v: boolean) => void;
  displayMessageForMacrosLimits?: (macrosProp: 'carbs' | 'cal' | 'protein' | 'fat') => string;
}

const NutritionalInformation = forwardRef<HTMLDivElement, NutritionalInformationProps>(
  (
    {
      values,
      setFieldValue,
      errors,
      handleBlur,
      handleChange,
      microNutrientsAuto,
      handleAutoCalculate,
      displayMessageForMacrosLimits
    }: NutritionalInformationProps,
    ref
  ) => {
    return (
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        ref={ref}
        sx={{ backgroundColor: caloTheme.palette.white, borderRadius: '16px' }}
      >
        <Typography
          sx={{
            m: 2,
            color: caloTheme.palette.neutral900,
            textTransform: 'capitalize',
            fontSize: '19px',
            fontWeight: 600,
            lineHeight: '23px'
          }}
        >
          Nutritional Information
        </Typography>
        <FoodNutritionalForm
          errors={errors}
          macros={values.macros}
          micronutrients={values.micronutrients}
          formType="update"
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          microNutrientsAuto={microNutrientsAuto}
          setMicroNutrientsAuto={handleAutoCalculate}
          displayMessageForMacrosLimits={displayMessageForMacrosLimits}
        />
      </Stack>
    );
  }
);

export default NutritionalInformation;
