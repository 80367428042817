import React, { useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import { getWeek } from 'date-fns';
import { endOfWeek, getYear, isBefore, parseISO } from 'date-fns/fp';
import { flatten, groupBy, intersection, sumBy } from 'lodash-es';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation, useQuery } from 'react-query';

import { AddonAddedAsType, Permission } from '@calo/dashboard-types';
import { MacrosService } from '@calo/services';
import { ActivityLevel, Brand, CustomMealCategory, DietType, Food, FoodType, Localized } from '@calo/types';

import {
  Button as MUIButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {
  addAddonFood,
  createCustomFood,
  deleteAddonMenu,
  deleteFoodMenu,
  getListWithParams,
  getRecordWithParams,
  replaceFood,
  updateCustomFood
} from 'actions';
import mutation from 'actions/mutation';
import { Button, CaloLoader, ConfirmationModal, Modal, ModalRef } from 'components';
import FoodDietTypeTags from 'components/FoodDietTypeTags';
import { getIngredientsToAvoid } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { Delivery, FoodWithPosition, Menu, MenuFood } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import AddonsPickerModal from './AddonsPickerModal';
import CustomFoodModal from './CustomFoodModal';
import FoodRow from './FoodRow';
import MenuFoodPicker from './MenuFoodPicker';
import RegularMealTable from './RegularMealTable';

interface MenuTabProps {
  delivery: Delivery;
  menuModalRef: React.MutableRefObject<ModalRef | undefined>;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
}

const MenuTab = ({ delivery, menuModalRef, refetch }: MenuTabProps) => {
  const [selected, setSelected] = useState<FoodWithPosition | null>();
  const [customFood, setCustomFood] = useState<any | null>();
  const [addonType, setAddonType] = useState(FoodType.coffee);
  const [selectedAddon, setSelectedAddon] = useState<MenuFood | null>(null);
  const [selectedDeleteFood, setSelectedDeleteFood] = useState<Food>();
  const [allMenuFood, setAllMenufood] = useState<MenuFood[]>();
  const [customMealAction, setCustomMealAction] = useState<string>('');
  const [swapMeal, setSwapMeal] = useState<boolean>(false);
  const [isGifted, setIsGifted] = useState<boolean>(false);
  const [selectedAddType, setSelectedAddType] = useState<AddonAddedAsType>(AddonAddedAsType.addon);

  const roles = useUserRoles();

  const foodModalRef = useRef<ModalRef>();
  const addonModalRef = useRef<ModalRef>();
  const confirmModalRef = useRef<ModalRef>();
  const customFoodModalRef = useRef<ModalRef>();

  const week = getWeek(new Date(delivery.day));
  const year = getYear(endOfWeek(new Date(delivery.day)));

  const { data } = useQuery(
    [
      '/menu',
      delivery.day,
      { brand: delivery.brand ? delivery.brand : Brand.CALO, kitchen: delivery.kitchen, userId: delivery.userId }
    ],
    getRecordWithParams,
    {
      retry: false
    }
  );
  const menu = data as Menu;

  const { data: customFoodCom, isLoading } = useQuery<any, Error, { data: any[] }>(
    [
      'food-components/custom-food',
      {
        filters: {
          kitchen: delivery.kitchen ? delivery.kitchen : `${delivery.country || 'BH'}001`,
          brand: delivery.brand ? delivery.brand : Brand.CALO
        }
      }
    ],
    getListWithParams,
    {
      enabled: roles.includes(Permission.VIEW_FOOD_COMPONENTS_CUSTOM_FOOD) && swapMeal,
      onSuccess: (data) => {
        for (const row of data?.data || []) {
          queryClient.setQueryData(['food-components/custom-food', row.id], row);
        }
      }
    }
  );

  const customFoodComponent = customFoodCom as any;

  const { data: userCustomMeals, refetch: customMealsRefetch } = useQuery<any, Error, { data: any[] }>(
    ['food/custom', { subscriptionId: delivery.userId, deliveryId: delivery.id }],
    getListWithParams,
    {
      enabled: roles.includes(Permission.VIEW_FOOD_COMPONENTS_CUSTOM_FOOD)
    }
  );

  const userCustomMeals2 = useMemo(() => userCustomMeals?.data.map((customFood) => customFood), [userCustomMeals?.data]);

  const { mutateAsync: replaceMutation } = useMutation(replaceFood);
  const { mutateAsync: deleteFoodMutation } = useMutation(deleteFoodMenu);
  const { mutateAsync: deleteAddonMutation } = useMutation(deleteAddonMenu);
  const { mutateAsync: updateCustomFoodMutation } = useMutation(updateCustomFood);
  const { mutateAsync: createCustomFoodMutation } = useMutation(createCustomFood);
  const { mutateAsync: addAddonMutation } = useMutation(addAddonFood);

  const menuFood = useMemo(() => {
    if (!selected) {
      return [];
    }

    let food = (menu?.food || []).filter((f) => intersection(f.type, selected.type).length > 0);

    if (isBefore(Date.now())(parseISO(delivery.day))) {
      food = food.filter((f) => (f.tags || []).includes(delivery.dietType || DietType.balanced));
    }
    setAllMenufood(food);

    return Object.values(groupBy(food, 'name.en')).map((r) => r[0]);
  }, [menu, delivery, selected]);

  const handleReplace = async (food: any) => {
    if (selected) {
      await replaceMutation(
        {
          id: delivery.id,
          sourceId: selected.id,
          targetId: food.size === 'C' ? (food.id === 'food' ? food.sk : food.id) : food.id || food.foodId,
          positionIndex: selected.positionIndex
        },
        {
          onSuccess: () => {
            foodModalRef.current?.close();
            refetch();
          }
        }
      );
    }
  };

  const handleAddAddonRequest = async (mealAdded: Food) => {
    if (!mealAdded) return;
    await addAddonMutation(
      { foodType: addonType, foodId: mealAdded.id, id: delivery.id, isGifted: isGifted, addedAs: selectedAddType },
      {
        onSuccess: (data) => {
          const query = queryClient.getQueryData(['deliveries', delivery.id]) as any;
          const deliveryUpdated = { ...query, addons: data.addons, cost: data.cost };
          mutation(['deliveries', delivery.id], deliveryUpdated);
          setSelectedAddon(null);
          setIsGifted(false);
          refetch();
          addonModalRef.current?.close();
        }
      }
    );
  };

  const deleteMenuFood = async (food: MenuFood) => {
    await deleteFoodMutation(
      {
        id: delivery.id,
        foodId: food.id
      },
      {
        onSuccess: (data) => {
          const query = queryClient.getQueryData(['deliveries', delivery.id]) as any;
          const deliveryUpdated = { ...query, food: data.food, cost: data.cost };
          mutation(['deliveries', delivery.id], deliveryUpdated);
          setSelectedDeleteFood(undefined);
        }
      }
    );
  };

  const deleteMenuAddon = async (addon: MenuFood) => {
    await deleteAddonMutation(
      {
        id: delivery.id,
        addonId: addon.id
      },
      {
        onSuccess: (data) => {
          const query = queryClient.getQueryData(['deliveries', delivery.id]) as any;
          const deliveryUpdated = { ...query, addons: data.addons, cost: data.cost };
          mutation(['deliveries', delivery.id], deliveryUpdated);
          setSelectedDeleteFood(undefined);
        }
      }
    );
  };

  const handleUpdateCustomFood = async (x: any, customName: Localized) => {
    const customComponents = flatten(Object.values(x)).map((r: any) => ({ id: r.value }));
    await updateCustomFoodMutation(
      {
        deliveryId: delivery.id,
        foodId: customFood.id === 'food' ? customFood.sk : customFood.id,
        name: customName,
        components: customComponents.map((r) => r.id)
      },
      {
        onSuccess: () => {
          customFoodModalRef.current?.close();
          setCustomFood(null);
          customMealsRefetch();
        }
      }
    );
  };

  const handleCreateCustomFood = async (components: any, customName: Localized) => {
    const customComponents = flatten(Object.values(components)).map((r: any) => ({ id: r.value }));
    await createCustomFoodMutation(
      {
        subscriptionId: delivery.userId,
        deliveryId: delivery.id,
        name: customName,
        foodComponentIds: customComponents.map((r) => r.id),
        category: CustomMealCategory.meal
      },
      {
        onSuccess: (data) => {
          customFoodModalRef.current?.close();
          handleReplace(data);
          mutation(['food/custom', { subscriptionId: delivery.userId, deliveryId: delivery.id }], data);
          customMealsRefetch();
        }
      }
    );
  };

  const handleCustomMeal = (componentData: any, customName: Localized) => {
    if (customMealAction === 'update') {
      handleUpdateCustomFood(componentData, customName);
    } else if (customMealAction === 'create') {
      handleCreateCustomFood(componentData, customName);
    }
  };

  useEffect(() => {
    if (selected) {
      foodModalRef.current?.open();
    } else {
      foodModalRef.current?.close();
    }
  }, [selected, customFood]);

  useEffect(() => {
    if (customFood && customMealAction === 'update') {
      customFoodModalRef.current?.open();
    }
  }, [customFood]);

  useEffect(() => {
    setIsGifted(false);
  }, [selectedAddType]);

  const deliveryMacros =
    delivery.macros ||
    MacrosService.getMacrosBag(
      MacrosService.getCal(delivery.macrosData!),
      delivery.plan.dietType || DietType.balanced,
      delivery.macrosData?.weight ?? 0,
      delivery.macrosData?.activityLevel ?? ActivityLevel.level1
    );

  const giftedMeals =
    delivery.giftedItems && delivery.giftedItems.meal
      ? delivery.giftedItems.meal.map((meal) => ({ ...meal, isGiftedItem: true }))
      : [];
  console.log(giftedMeals);
  return (
    <section>
      <div className="card">
        <header className="card-header">
          <p className="card-header-title bg-black text-white">Menu</p>
        </header>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Portion</TableCell>
              <TableCell>Type</TableCell>
              <TableCell style={{ width: '5rem' }}>Cal</TableCell>
              <TableCell style={{ width: '5rem' }}>Protein</TableCell>
              <TableCell style={{ width: '5rem' }}>Carbs</TableCell>
              <TableCell style={{ width: '5rem' }}>Fat</TableCell>
              <TableCell>Tags</TableCell>
              <TableCell>
                {roles.includes(Permission.CREATE_ADDONS_DELIVERY_MENU) && (
                  <span className="pr-1">
                    <Button icon="fas fa-plus ml-1" onClick={() => addonModalRef.current?.open()} />
                  </span>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableFooter>
            <TableRow>
              <TableCell></TableCell>
              <TableCell colSpan={3}>Total</TableCell>
              <TableCell>{sumBy(delivery.food, (food) => food.macros?.cal || 0)}</TableCell>
              <TableCell>{sumBy(delivery.food, (food) => food.macros?.protein || 0)}</TableCell>
              <TableCell>{sumBy(delivery.food, (food) => food.macros?.carbs || 0)}</TableCell>
              <TableCell>{sumBy(delivery.food, (food) => food.macros?.fat || 0)}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Target macros</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{deliveryMacros.cal}</TableCell>
              <TableCell>
                {deliveryMacros.protein.min} - {deliveryMacros.protein.max}
              </TableCell>
              <TableCell>
                {deliveryMacros.carbs.min} - {deliveryMacros.carbs.max}
              </TableCell>
              <TableCell>
                {deliveryMacros.fat.min} - {deliveryMacros.fat.max}
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableFooter>
          <TableBody>
            {delivery.food &&
              delivery.food.map((food, i) => (
                <FoodRow
                  key={`${food.id}-${i}`}
                  item={{ ...food, positionIndex: i } as FoodWithPosition}
                  delivery={delivery}
                  removeFromList={(val) => {
                    setSelectedDeleteFood(val);
                    confirmModalRef.current?.open();
                  }}
                  selectRow={setSelected}
                  setCustomMeal={(e) => setCustomFood(e)}
                  setSwapMeal={(v) => setSwapMeal(v)}
                />
              ))}
            {delivery.addons &&
              delivery.addons.map((addon, i) => (
                <FoodRow
                  key={`${addon.id}-${i}`}
                  delivery={delivery}
                  item={{ ...addon, positionIndex: i } as FoodWithPosition}
                  removeFromList={deleteMenuAddon}
                  selectRow={setSelected}
                  disableEditButton
                  setSwapMeal={setSwapMeal}
                />
              ))}
            {giftedMeals &&
              giftedMeals.map((gifted, i) => (
                <FoodRow
                  key={`${gifted.id}-${i}`}
                  delivery={delivery}
                  item={{ ...(gifted as any), positionIndex: i } as FoodWithPosition}
                  removeFromList={deleteMenuAddon}
                  selectRow={setSelected}
                  disableEditButton
                  setSwapMeal={setSwapMeal}
                />
              ))}
          </TableBody>
        </Table>

        <Modal
          ref={foodModalRef}
          onClose={() => {
            setSelected(null);
            setCustomFood(null);
            foodModalRef.current?.close();
          }}
        >
          <section className="section is-title-bar label -m-8 w-full">
            <span className="uppercase font-bold text-xl">Regular Meals</span>
          </section>
          <table className="is-fullwidth border-separate">
            <thead>
              <tr className="bg-white">
                <th></th>
                <th></th>
                <th className="w-32"></th>
                <th className="w-32"></th>
                <th className="w-24"></th>
              </tr>
            </thead>
            <tbody>
              <tr key={selected?.id} className="bg-gray-100 py-2">
                <td className="w-1/6">
                  <span
                    className="flex flex-row w-full"
                    style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '19px', lineHeight: '23px' }}
                  >
                    <p className="ml-4 my-4">{selected?.name?.en}</p>
                  </span>
                </td>
                <td className="py-4">
                  <div className="w-11/12 my-auto">
                    {getIngredientsToAvoid(selected, delivery).map((i: any) =>
                      i.isAlergen ? <span className="text-red-500"> {i.name}, </span> : <span> {i.name}, </span>
                    )}
                  </div>
                </td>
                <td>
                  <span className={`flex flex-row py-4 -ml-4`}>
                    {selected?.tags?.map((r: any) => <FoodDietTypeTags key={r} tag={r} />)}
                  </span>
                </td>
                <td>
                  <p className="my-4 font-bold">{selected?.size}</p>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <section>
            {menuFood.length > 0 ? (
              <RegularMealTable
                allMenuFood={allMenuFood || []}
                currency={delivery.currency}
                delivery={delivery}
                handleReplace={handleReplace}
                menuFood={menuFood}
                selected={selected!}
              />
            ) : (
              <div>
                {' '}
                <p className="text-xl text-center font-bold">No meals to swap with</p>
              </div>
            )}
          </section>
          {delivery.brand !== Brand.MEALO &&
            (selected?.type?.includes(FoodType.lunch) || selected?.type?.includes(FoodType.dinner)) && (
              <>
                <section className="section is-title-bar label -m-8 mt-2">
                  <div className="level">
                    <div className="level-left">
                      <div className="level-item">
                        <span className="uppercase font-bold text-xl -mb-4">Custom Meals</span>
                      </div>
                    </div>
                    <div className="level-right">
                      <div className="level-item">
                        {roles.includes(Permission.CREATE_USER_CUSTOM_FOOD) && (
                          <MUIButton
                            disabled={true}
                            variant="contained"
                            aria-label="show-meal-macros"
                            sx={{
                              height: '45px',
                              fontWeight: 600,
                              fontSize: '14px',
                              lineHeight: '17px',
                              borderRadius: '8px',
                              textTransform: 'none'
                            }}
                            onClick={() => {
                              setCustomMealAction('create');
                              setCustomFood(null);
                              customFoodModalRef.current?.open();
                            }}
                          >
                            Create Custom Meal
                          </MUIButton>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  {userCustomMeals2?.length === 0 ? (
                    <div>
                      {' '}
                      <p className="flex justify-center font-bold mt-4"> No custom meal available</p>
                    </div>
                  ) : isLoading ? (
                    <CaloLoader />
                  ) : (
                    <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
                      <Typography sx={{ fontWeight: 600 }}>Use new deliveries page for Custom Meals</Typography>
                      {/* <table className="table is-sortable is-fullwidth">
                        <thead>
                          <tr className="bg-white sticky top-0 z-50">
                            <th>Meal</th>
                            <th>Component</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isLoading &&
                            userCustomMeals2 &&
                            userCustomMeals2.map((r: any) => (
                              <CustomMealPicker
                                key={r.id}
                                delivery={delivery}
                                customMeal={r}
                                onPick={handleReplace}
                                food={selected!}
                                setCustomFood={(v) => setCustomFood(v)}
                                setCustomMealAction={setCustomMealAction}
                                customFoodComponent={customFoodComponent?.data}
                              />
                            ))}
                        </tbody>
                      </table> */}
                    </Stack>
                  )}
                </section>
              </>
            )}
        </Modal>

        <Modal
          ref={menuModalRef}
          onClose={() => {
            menuModalRef.current?.close();
            setCustomFood(null);
          }}
        >
          <MenuFoodPicker
            menu={menu}
            gifted={false}
            delivery={delivery}
            isGifted={isGifted}
            setIsGifted={setIsGifted}
            setMealType={setAddonType}
            menuModalRef={menuModalRef}
            refetchDelivery={refetch}
            deliveryBalance={delivery.balance}
          />
        </Modal>
        <Modal
          ref={addonModalRef}
          onClose={() => {
            addonModalRef.current?.close();
            setSelectedAddon(null);
          }}
        >
          <section className="section is-title-bar label -m-8">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <span className="uppercase font-bold text-xl -mb-4">
                    {selectedAddType === AddonAddedAsType.addon ? 'Add new addon' : 'Add new meal'}
                  </span>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  {roles.includes(Permission.CREATE_ADDONS_DELIVERY_MENU) && (
                    <Button
                      content="Addon"
                      onClick={() => {
                        setSelectedAddType(AddonAddedAsType.addon);
                      }}
                      className={cx('-mb-4 w-32 mr-2', {
                        'bg-green-500 text-white border border-green-500 hover:text-white hover:border-green-400 focus:text-white focus:border-green-500 hover:bg-green-500':
                          selectedAddType === AddonAddedAsType.addon,
                        'bg-gray-200 text-gray-500': selectedAddType !== AddonAddedAsType.addon
                      })}
                    />
                  )}
                  {roles.includes(Permission.ADD_FOOD_DELIVERY_MENU) && (
                    <Button
                      content="Meal"
                      onClick={() => {
                        setSelectedAddType(AddonAddedAsType.meal);
                      }}
                      className={cx('-mb-4 w-32', {
                        'bg-green-500 text-white border border-green-500 hover:text-white hover:border-green-400 focus:text-white focus:border-green-500 hover:bg-green-500':
                          selectedAddType === AddonAddedAsType.meal,
                        'bg-gray-200 text-gray-500': selectedAddType !== AddonAddedAsType.meal
                      })}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
          {selectedAddType === AddonAddedAsType.addon ? (
            <AddonsPickerModal
              year={year}
              week={week}
              isGifted={isGifted}
              setIsGifted={setIsGifted}
              addonModalRef={addonModalRef}
              addonType={addonType}
              delivery={delivery}
              country={delivery.country}
              handleAddAddonRequest={(v: Food) => handleAddAddonRequest(v)}
              selectedAddon={selectedAddon}
              setAddonType={setAddonType}
              setSelectedAddon={setSelectedAddon}
            />
          ) : (
            <MenuFoodPicker
              menu={menu}
              gifted={true}
              delivery={delivery}
              isGifted={isGifted}
              refetchDelivery={refetch}
              setIsGifted={setIsGifted}
              setMealType={setAddonType}
              menuModalRef={addonModalRef}
              deliveryBalance={delivery.balance}
              handleAddMealRequest={(v) => handleAddAddonRequest(v)}
            />
          )}
        </Modal>

        {roles.includes(Permission.VIEW_FOOD_COMPONENTS_CUSTOM_FOOD) && (
          <Modal
            ref={customFoodModalRef}
            onClose={() => {
              customFoodModalRef.current?.close();
              setCustomFood(null);
              setCustomMealAction('');
            }}
            isNarrow
          >
            <CustomFoodModal
              isLoading={isLoading}
              customFood={customFood || []}
              customFoodComponent={customFoodComponent?.data}
              handleUpdateCustomFood={handleCustomMeal}
              customFoodAction={customMealAction}
            />
          </Modal>
        )}

        <ConfirmationModal ref={confirmModalRef} values={selectedDeleteFood} action={deleteMenuFood}>
          <div className="mt-4 mb-4 ml-4">
            <span className="flex">
              <label className="label"> Deleting meal: </label>
              <p className="ml-2">{selectedDeleteFood?.name.en}</p>
            </span>
          </div>
        </ConfirmationModal>
      </div>
    </section>
  );
};

export default MenuTab;
