import { PurchaseCostService } from '@calo/services';
import { format } from 'date-fns/fp';
import { max } from 'lodash-es';
import { FoodComponent, FoodComponentWithQuantity, Ingredient } from '../interfaces';

export const handlePurchasingCost = (ingredient: Ingredient) => {
  return (ingredient?.cost || 0) * (ingredient?.quantity || 0) * (ingredient?.wastage || 0);
};

export const calculatePurchasingCost = (
  ingredients: Ingredient[],
  childComponentsWithQuantity: FoodComponentWithQuantity[],
  cookedRawFactor: number
) => {
  return PurchaseCostService.calculateFCPurchasingCostPerGram({
    ingredients,
    childComponents: childComponentsWithQuantity,
    cookedRawFactor
  });
};
export const getLastUpdatedPurchasingCostDate = (foodComponent: FoodComponent[]) =>
  max(
    foodComponent.map((c) =>
      max(c.ingredients.map((i) => (i.costLastUpdated ? format('dd-MM-yyyy')(new Date(i.costLastUpdated)) : '---')))
    )
  );
