import { exportFoodPackage } from 'actions';
import { toggleUISettings } from 'actions/ui';
import { caloTheme } from 'assets/images/theme/calo';
import ExportButton from 'components/ExportButton';
import { History } from 'history';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { FoodPackage } from 'lib/interfaces';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';

import { FoodPackageFilters, Permission } from '@calo/dashboard-types';
import { Icon as Iconify } from '@iconify/react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Card, IconButton, Stack, Typography } from '@mui/material';

interface PackagesHeaderCardProps {
  filters: FoodPackageFilters;
  history: History<unknown>;
  packages: FoodPackage[];
  isDisabled?: boolean;
}

const PackagesHeaderCard = ({ filters, history, packages, isDisabled }: PackagesHeaderCardProps) => {
  const userRoles = useUserRoles();
  const dispatch = useDispatch();

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        border: 'none',
        padding: 2,
        paddingX: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '16px'
      }}
    >
      <Typography sx={{ fontSize: '32px', fontWeight: 600 }}>
        Packaging - {filters.country} / {filters.kitchen}
      </Typography>
      <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
        <IconButton sx={{ cursor: 'pointer' }} onClick={() => dispatch(toggleUISettings())}>
          <Iconify icon="cil:list-filter" width={24} color={caloTheme.palette.neutral900} />
        </IconButton>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          disabled={!userRoles.includes(Permission.CREATE_FOOD_PACKAGE) || Boolean(isDisabled)}
          onClick={() => history.push(Routes.newPackage)}
          sx={{
            borderColor: caloTheme.palette.neutral900,
            borderRadius: '8px',
            color: caloTheme.palette.neutral900,
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 600,
            px: '16px',
            ':hover': {
              borderColor: caloTheme.palette.neutral900,
              color: caloTheme.palette.neutral900
            }
          }}
        >
          Add
        </Button>
        <ExportButton
          isDisabled={!Permission.EXPORT_FOOD_PACKAGE || isEmpty(packages)}
          isLoading={false}
          onClick={() => exportFoodPackage(filters)}
        />
      </Stack>
    </Card>
  );
};

export default PackagesHeaderCard;
