import { useState } from 'react';

import cx from 'classnames';
import { addHours, format } from 'date-fns/fp';

import { RouteItemActionType, RouteItemActionTypeMessage } from '@calo/driver-types';
import Tooltip from '@mui/material/Tooltip';

import { LatLng } from '@calo/dashboard-types';
import { updateSubscriptionCustomDeliveryTime } from 'actions';
import Icon from 'components/Icon';
import TimePicker from 'components/TimePicker';
import { DriverMetricDelivery } from 'lib/interfaces';
import { AddressService } from 'services';
import { differentInMinutesForDates } from '../../../utils';
import DeliveryInstructionsPopup from './DeliveryInstructionsPopup/DeliveryInstructionsPopup';

interface DeliveryRowProps {
  delivery: DriverMetricDelivery;
  previousLocation: LatLng;
  reasonForNotFollowPriority?: string;
  haveRoleToUpdateCustomTime?: boolean;
}

const DeliveryRow = ({
  delivery,
  reasonForNotFollowPriority,
  haveRoleToUpdateCustomTime,
  previousLocation
}: DeliveryRowProps) => {
  const getCustomDeliveryTime = (customDeliveryTime?: string) => {
    const initTime = customDeliveryTime || delivery.customDeliveryTime;
    if (initTime) {
      const list = initTime.split(':');
      const time = new Date();
      time.setHours(parseInt(list[0]));
      time.setMinutes(parseInt(list[1]));
      return time;
    }
    return null;
  };

  const updateCustomDeliveryTime = async (time: string) => {
    if (time !== 'NaN:NaN') {
      await updateSubscriptionCustomDeliveryTime(delivery.userId, time, delivery.deliveryAddress.id);
      setCustomDeliveryTime(getCustomDeliveryTime(time));
    }
  };

  const [customDeliveryTime, setCustomDeliveryTime] = useState(getCustomDeliveryTime());
  const filteredActions =
    delivery.actions && delivery.actions.length > 0
      ? delivery.actions?.filter((a) => a.type !== RouteItemActionType.DRIVERS_REQUESTING_DELIVERY_LOCATION_UPDATES)
      : [];

  return (
    <>
      <tr key={delivery.id} className={cx('text-black', { 'text-red-500': reasonForNotFollowPriority })}>
        <td>{delivery.name}</td>
        <Tooltip title={reasonForNotFollowPriority} placement="top" arrow>
          <td>
            <span>{AddressService.display(delivery.deliveryAddress)}</span>
            <a
              href={`https://www.google.com/maps/dir/${previousLocation?.lat},${previousLocation?.lng}/${delivery.deliveryAddress?.lat},${delivery.deliveryAddress?.lng}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="direction" size={4} className="ml-2" />
            </a>
          </td>
        </Tooltip>
        <td>{delivery.deliveryAddress.notes || ''}</td>
        <td>{delivery.deliveryAddress.driverNote || ''}</td>
        <td>{delivery.priority}</td>
        <td>
          {delivery.toBeDeliveredAt ? (
            <>
              {format('hh:mm a')(new Date(delivery.toBeDeliveredAt))}{' '}
              {delivery.groupBufferTime && delivery.groupBufferTime > 0 && (
                <label
                  className="label"
                  style={{
                    borderRadius: 3,
                    color: 'white',
                    padding: '2px',
                    fontSize: 12,
                    backgroundColor: '#888888',
                    display: 'inline-flex'
                  }}
                >
                  +{delivery.groupBufferTime}m
                </label>
              )}
            </>
          ) : (
            '--:--'
          )}
        </td>
        <td>{delivery.deliveredAt ? format('hh:mm a')(new Date(delivery.deliveredAt)) : '--:--'}</td>
        <td>
          {haveRoleToUpdateCustomTime ? (
            <TimePicker customDeliveryTime={customDeliveryTime} updateCustomDeliveryTime={updateCustomDeliveryTime} />
          ) : customDeliveryTime ? (
            `${format('hh:mm a')(customDeliveryTime)}-${format('hh:mm a')(addHours(1)(customDeliveryTime))}`
          ) : (
            '--:--'
          )}
        </td>
        <td>
          {filteredActions && filteredActions.length > 0 && RouteItemActionTypeMessage[filteredActions[0].type] ? (
            <div>
              {RouteItemActionTypeMessage[filteredActions[0].type]}
              <br />
              {format('hh:mm a')(new Date(filteredActions[0].createdAt))}
            </div>
          ) : (
            '---'
          )}
        </td>
        <td>
          {differentInMinutesForDates(
            customDeliveryTime ? customDeliveryTime.toISOString() : delivery.toBeDeliveredAt,
            delivery.noCustomerOnSpotTime || delivery.deliveredAt
          )}
        </td>
        <td style={{ textAlign: 'center' }}>
          {delivery.deliveryAddress.deliveryInstructions ? (
            <DeliveryInstructionsPopup instructions={delivery.deliveryAddress.deliveryInstructions} />
          ) : (
            '-'
          )}
        </td>
      </tr>
    </>
  );
};
export default DeliveryRow;
