import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { getImageUploadLink } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Icon, ImageUploader, ModalRef } from 'components';
import Popup from 'components/Popup';
import { useRef, useState } from 'react';
import ImagePopup from './ImagePopup';

interface AttachingFilePopupProps {
  componentMethodImageRef: React.MutableRefObject<ModalRef | undefined>;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loadingImage: boolean;
  selectedComplaint: any;
  displayImage: string[];
  progress: number;
  setDisplayImage: (value: string[]) => void;
}

const AttachingFilePopup = ({
  setDisplayImage,
  progress,
  displayImage,
  selectedComplaint,
  componentMethodImageRef,
  handleFileChange,
  loadingImage
}: AttachingFilePopupProps) => {
  const [imageUrlLink, setImageUrlLink] = useState<string | undefined>();
  const viewImageRef = useRef<ModalRef>();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleEnlargeImage = (image: string, ImageIndex: number) => {
    setImageUrlLink(image);
    setCurrentIndex(ImageIndex);
    viewImageRef.current?.open();
  };

  return (
    <>
      <Popup
        maxWidth="lg"
        fullWidth
        title="Attached Files"
        ref={componentMethodImageRef}
        onClose={() => componentMethodImageRef.current?.close()}
        info={
          <>
            <Button>
              <input
                accept="image/*"
                type="file"
                multiple
                size={2}
                className="file-input fas fa-paperclip mt-2 cursor-pointer float-right "
                onChange={(e) => handleFileChange(e)}
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '16px',
                  mt: 1,
                  cursor: 'pointer',
                  lineHeight: '19px',
                  color: caloTheme.palette.neutral900
                }}
              >
                + Add File
              </Typography>
            </Button>
          </>
        }
        subTitle={
          <>
            <Stack display={'flex'} flexDirection={'row'} justifyContent={'end'}>
              <div className="file-label">
                <input
                  className="file-input cursor-pointer fas fa-pen"
                  accept="image/*"
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                />
              </div>
            </Stack>
          </>
        }
      >
        {loadingImage ? (
          <CaloLoader />
        ) : (
          <>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} key={selectedComplaint?.id}>
              <Grid container spacing={5}>
                {displayImage?.map((image: string, index) => (
                  <Grid item key={image}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box display={'flex'} flexDirection={'column'} justifyContent={'start'}>
                          <Stack
                            key={image}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'start'}
                            onClick={() => handleEnlargeImage(image, index)}
                          >
                            <ImageUploader
                              key={selectedComplaint?.id}
                              uploadLink={() =>
                                getImageUploadLink(`complaints/${selectedComplaint?.id.split('#')[1]}/${image}`, ' ')
                              }
                              image={`${process.env.REACT_APP_BUCKET_URL}/complaints/${selectedComplaint?.id.split('#')[1]}/${image}`}
                              disabled={false}
                              width={120}
                              maxHeight={44}
                            />
                          </Stack>
                          <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ width: '100%' }}>
                            <Typography sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
                              Attachment {index + 1}
                            </Typography>
                          </Stack>
                          <Stack
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'center'}
                            sx={{ width: '100%', marginTop: 1 }}
                          >
                            <Icon
                              name="trash"
                              size={6}
                              style={{ color: caloTheme.palette.neutral100 }}
                              onClick={() => {
                                setDisplayImage(displayImage.filter((imageId) => imageId !== image));
                              }}
                              className="mx-4 cursor-pointer"
                            />
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box>
              <Stack
                sx={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  display: 'flex',
                  width: '100%',
                  marginTop: 2
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    width: '156px',
                    color: caloTheme.palette.primary500,
                    fontFamily: 'Roboto',
                    textAlign: 'center',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '23px',
                    textTransform: 'capitalize'
                  }}
                  disabled={progress !== 100}
                  onClick={() => {
                    componentMethodImageRef.current?.close();
                  }}
                >
                  Done
                </Button>
              </Stack>
            </Box>
          </>
        )}
      </Popup>
      <ImagePopup
        imageUrl={imageUrlLink}
        mainRef={viewImageRef}
        displayImage={displayImage}
        selectedComplaintId={selectedComplaint?.id}
        setImageUrlLink={setImageUrlLink}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </>
  );
};
export default AttachingFilePopup;
