import { ChangeEvent, DetailedHTMLProps, InputHTMLAttributes, useEffect, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { TextField } from '@mui/material';

interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  error?: boolean;
  debounce?: boolean;
  hidden?: boolean;
  placeholder?: string;
  textId?: string;
  disabled?: boolean;
  onSubmit?: (v: any) => void;
  inputProps?: any;
  sx?: any;
  multiline?: boolean;
  rows?: number;
  type?: string;
  min?: number;
  max?: number;
  step?: any;
  InputLabelProps?: any;
}

const Input = ({
  sx,
  inputProps,
  onSubmit,
  disabled,
  label,
  debounce,
  error,
  onChange,
  placeholder,
  textId,
  value,
  multiline = false,
  rows = 1,
  type,
  min,
  max,
  step,
  InputLabelProps,
  name,
  ...rest
}: InputProps) => {
  const [localValue, setLocalValue] = useState(value);

  const debounced = useDebouncedCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
      onSubmit && onSubmit(e.target.value);
    }
  }, 600);

  const handleOnchange = (e: ChangeEvent<HTMLInputElement>) => {
    const callback = debounced;
    setLocalValue(e.target.value);
    if (callback) {
      callback(e);
    }
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <TextField
      sx={sx}
      id={textId}
      label={label}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      value={debounce ? localValue : value}
      onChange={handleOnchange}
      InputProps={inputProps}
      multiline={multiline}
      rows={rows}
      error={error}
      type={type}
      inputProps={{
        min: min,
        max: max,
        step: step
      }}
      InputLabelProps={InputLabelProps}
      data-test={rest['data-test']}
    />
  );
};

export default Input;
