import { Permission } from '@calo/dashboard-types';
import { FoodType } from '@calo/types';

import { CaloLoader } from 'components';
import { OperationType } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import ExtraMealRow from './ExtraMealRow';

interface ExtraMealListProps {
  extraMeals: any[];
  selectedDate: string;
  selectedCustomer: any;
  deliveryExist: boolean;
  loadingDelivery: boolean;
  loadingExtraMeals: boolean;
  selectedOperation: OperationType;
}

const ExtraMealList = ({
  extraMeals,
  selectedDate,
  deliveryExist,
  loadingDelivery,
  loadingExtraMeals,
  selectedCustomer,
  selectedOperation
}: ExtraMealListProps) => {
  const roles = useUserRoles();

  if (selectedOperation === OperationType.other) {
    return (
      <div className="flex flex-column w-2/5 mx-4">
        <span className="flex flex-row w-full justify-between mb-4">
          <p className="capitalize font-roboto text-lg">Meals</p>
          <p className="capitalize font-roboto text-lg ml-32">Tags</p>
          <p className="capitalize font-roboto text-lg">Extra on hand</p>
        </span>
        <div className="flex justify-center my-16">
          <p className="text-2xl font-bold text-gray-400">NO MEALS</p>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-column w-2/5 mx-4">
      <span className="flex flex-row w-full justify-between mb-4">
        <p className="capitalize font-roboto text-lg">Meals</p>
        <p className="capitalize font-roboto text-lg ml-32">Tags</p>
        <p className="capitalize font-roboto text-lg">Extra on hand</p>
      </span>
      {roles.includes(Permission.LIST_EXTRA_MEALS) &&
        (loadingDelivery || loadingExtraMeals ? (
          <div className="flex justify-center my-16">
            <CaloLoader />
          </div>
        ) : !deliveryExist &&
          selectedCustomer &&
          selectedDate.length > 0 &&
          selectedOperation !== OperationType.manualDelivery ? (
          <div className="flex justify-center my-16">
            <p className="text-2xl font-bold text-gray-400">{`No Delivery on ${selectedDate} for ${selectedCustomer.name}`}</p>
          </div>
        ) : extraMeals && extraMeals.length === 0 && selectedDate && selectedCustomer ? (
          <div className="flex justify-center my-16">
            <p className="text-2xl font-bold text-gray-400">{`No Extra Meals for ${selectedDate}`}</p>
          </div>
        ) : selectedDate.length === 0 || !selectedCustomer ? (
          <div className="flex justify-center my-16">
            <p className="text-2xl font-bold text-gray-400">Select Date and Customer to See the Meals</p>
          </div>
        ) : (
          <div className="overflow-y-auto max-h-600px">
            {Object.values([FoodType.breakfast, FoodType.lunch, FoodType.snack]).map((type) => (
              <ExtraMealRow
                foodList={
                  extraMeals?.filter((r: any) =>
                    type === FoodType.lunch ? r.type.includes(type) && r.type.includes(FoodType.dinner) : r.type.includes(type)
                  ) || []
                }
                type={type}
                key={type}
              />
            ))}
          </div>
        ))}
    </div>
  );
};
export default ExtraMealList;
