import cairoBold from 'assets/fonts/cairo-bold';
import cairoRegular from 'assets/fonts/cairo-regular';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import printJS from 'print-js';

const getTitle = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const printPDF = (title: string, records: any[], titleFontSize?: number) => {
  const doc = new jsPDF();
  doc.addFileToVFS('Cairo-Bold.ttf', cairoBold);
  doc.addFont('Cairo-Bold.ttf', 'Cairo', 'bold');
  doc.addFileToVFS('Cairo-Regular.ttf', cairoRegular);
  doc.addFont('Cairo-Regular.ttf', 'Cairo', 'normal');

  doc.setFont('Cairo', 'bold');
  doc.setFontSize(titleFontSize ?? 23);
  const splitTitle = doc.splitTextToSize(title, 180);
  const height = doc.getTextDimensions(splitTitle).h;
  doc.text(splitTitle, 13, 10);
  const startY = (height || 4) + 10;

  const body = records.map((r) => {
    const values = Object.values(r);
    return values;
  });
  const header = Object.keys(records[0]).map((v) => getTitle(v));
  autoTable(doc, {
    head: [header],
    body: body as any,
    theme: 'grid',
    startY
  });

  const data = doc.output('blob');
  const blobUrl = URL.createObjectURL(data);
  printJS(blobUrl);
};
