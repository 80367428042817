import client from 'lib/client';
import { useQuery } from 'react-query';

interface UseReferralSummaryProps {
  subscriptionId: string;
}

interface GetReferralSummary {
  summary: {
    balance: number;
    totalEarned: number;
    totalReferred: number;
    share: {
      link: string;
      code: string;
    };
  };
}

const useReferralSummary = (props: UseReferralSummaryProps): GetReferralSummary => {
  const { subscriptionId } = props;

  const { data: summary } = useQuery<{
    balance: number;
    totalEarned: number;
    totalReferred: number;
    share: {
      link: string;
      code: string;
    };
  }>({
    queryFn: () => client.get(`referrals/${subscriptionId}`).then((response) => response.data),
    queryKey: ['referralSummary']
  });

  return {
    summary: summary!
  };
};

export default useReferralSummary;
