import { format } from 'date-fns/fp';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Alert,
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses
} from '@mui/material';
import { alpha } from '@mui/system';

import { Permission } from '@calo/dashboard-types';
import { getTransactionHistory, syncSubscriptionBalanceWithActual } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { SubscriptionTransactionHistoryItem } from 'lib/interfaces';
import { useMutation } from 'react-query';

interface TransactionHistoryProps {
  userId: string;
  balance: number;
}

const TransactionHistoryPage = ({ userId, balance }: TransactionHistoryProps) => {
  const roles = useUserRoles();
  const history = useHistory();
  const [transactions, setTransactionList] = useState<SubscriptionTransactionHistoryItem[]>([]);
  const [paymentBalance, setPaymentBalance] = useState(0);
  const [balanceLoaded, setBalanceLoaded] = useState(false);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      width: '100%',
      flexShrink: 0,
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px',
      variant: 'caption',
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    },
    [`&.${tableCellClasses.body}`]: {
      width: '100%',
      flexShrink: 0,
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      variant: 'caption',
      lineHeight: '14px',
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    }
  }));

  useEffect(() => {
    const getData = async () => {
      const data = await getTransactionHistory(userId);
      setPaymentBalance(data.balance);
      setBalanceLoaded(true);
      if (data.transactions) {
        setTransactionList(data.transactions);
      }
    };
    getData();
  }, [userId]);

  const { mutateAsync: createMutation } = useMutation(syncSubscriptionBalanceWithActual);
  const syncBalance = async () => {
    await createMutation(userId);
    history.go(0);
  };

  return (
    <>
      {balanceLoaded && balance !== paymentBalance ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 2,
            mx: 'auto'
          }}
        >
          <Alert color="error" severity="error" variant="standard">
            Subscription Balance & Actual Balance don't match!
          </Alert>
        </Box>
      ) : (
        ''
      )}

      {balanceLoaded && balance !== paymentBalance && roles.includes(Permission.SYNC_SUBSCRIPTION_BALANCE) && (
        <Button onClick={() => syncBalance()}>Sync Balance</Button>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mt: 2,
          mx: 'auto',
          width: '100%'
        }}
      >
        <Stack
          sx={{
            width: '25%',
            marginRight: 1,
            display: 'flex',
            minWidth: '64px',
            textAlign: 'left',
            borderRadius: '8px',
            flexDirection: 'row',
            padding: '16px 16px 10px 16px',
            justifyContent: 'space-between',
            color: caloTheme.palette.neutral900,
            border: '1px solid ' + caloTheme.palette.neutral100
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '19px',
              textAlign: 'left',
              marginRight: '4px',
              lineHeight: '23px',
              letterSpacing: '-0.02em',
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            Subscription Balance
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '23px',
              lineHeight: '24px',
              marginRight: '6px',
              letterSpacing: '-0.02em',
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            {`${balance}`}
          </Typography>
        </Stack>

        <Stack
          sx={{
            width: '25%',
            marginRight: 1,
            display: 'flex',
            minWidth: '64px',
            textAlign: 'left',
            borderRadius: '8px',
            flexDirection: 'row',
            padding: '16px 16px 10px 16px',
            justifyContent: 'space-between',
            color: caloTheme.palette.neutral900,
            border: '1px solid ' + caloTheme.palette.neutral100
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '19px',
              textAlign: 'left',
              marginRight: '4px',
              lineHeight: '23px',
              letterSpacing: '-0.02em',
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            Actual Balance
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '23px',
              lineHeight: '24px',
              marginRight: '6px',
              letterSpacing: '-0.02em',
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            {`${paymentBalance}`}
          </Typography>
        </Stack>
      </Box>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          mx: 'auto',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: '100%',
            mx: 1
          }
        }}
      >
        <Table
          sx={{
            marginY: '4px',
            minHeight: '120px',
            tableLayout: 'fixed',
            overflow: 'auto',
            width: '100%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
        >
          <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, width: '100%', borderRadius: '8px' }}>
            <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Actor</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Delivery</StyledTableCell>
              <StyledTableCell>Debit</StyledTableCell>
              <StyledTableCell>Credit</StyledTableCell>
              <StyledTableCell>Balance</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((row) => (
              <>
                <TableRow key={row.id}>
                  <StyledTableCell>{row.createdAt && format('Pp')(new Date(row.createdAt))}</StyledTableCell>
                  <StyledTableCell>{row.createdBy}</StyledTableCell>
                  <StyledTableCell>{row.source}</StyledTableCell>
                  <StyledTableCell>
                    {row.deliveryId ? (
                      <Typography
                        onClick={() => window.open(Routes.delivery.replace(':id', row.deliveryId as string), '_blank')}
                        sx={{
                          ml: 0,
                          fontWeight: 600,
                          cursor: 'pointer'
                        }}
                      >
                        <Icon name={'greenTruck'} size={8} color="green" />
                      </Typography>
                    ) : (
                      ''
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      background:
                        row.amountWithoutVAT && row.type === 'debit' ? alpha(caloTheme.palette.secondaryRed100, 0.3) : ''
                    }}
                  >
                    {row.type === 'debit' ? `- ${row.amountWithoutVAT || 0}` : ''}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      background: row.amountWithoutVAT && row.type === 'credit' ? alpha(caloTheme.palette.primary100, 0.8) : ''
                    }}
                  >
                    {row.type === 'credit' ? `+ ${row.amountWithoutVAT || 0}` : ''}
                  </StyledTableCell>
                  <StyledTableCell>{row.balance}</StyledTableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default TransactionHistoryPage;
