import { getUnpaidDeliveries, postSyncRemainingDays } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import { getUnpaidDeliveriesProps } from 'lib/interfaces';
import { useState } from 'react';

import { Subscription } from '@calo/types';
import { DeliveryDining } from '@mui/icons-material';
import { Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

import styles from '../DeliveriesCalender/styles';

interface StatisticsCardsProps {
  subscription: Subscription & { remainingDays: number; expectedLastDeliveryDay: string; ratings: any[] };
  refetchSubscription: () => void;
}

const StatisticsCards = ({ refetchSubscription, subscription }: StatisticsCardsProps) => {
  const [unpaidDeliveryCount, setUnpaidDeliveryCount] = useState<getUnpaidDeliveriesProps>({
    loading: false,
    loaded: false,
    count: 0,
    totalUnpaidDeliveriesCost: 0,
    unpaidDeliveries: []
  });

  const [syncDeliveriesState, setSyncDeliveriesState] = useState<boolean>(false);

  const fetchUnpaidDeliveries = async () => {
    setUnpaidDeliveryCount({ ...unpaidDeliveryCount, loading: true });

    try {
      const {
        count,
        totalUnpaidDeliveriesCost,
        unpaidDeliveries: unpaidDeliveriesItems
      } = await getUnpaidDeliveries(subscription.id);
      setUnpaidDeliveryCount({
        loading: false,
        loaded: true,
        count,
        totalUnpaidDeliveriesCost,
        unpaidDeliveries: unpaidDeliveriesItems
      });
    } catch {
      setUnpaidDeliveryCount({ ...unpaidDeliveryCount, loading: false });
    }
  };

  const syncRemainingDays = async () => {
    setSyncDeliveriesState(true);
    try {
      const { remainingDays } = await postSyncRemainingDays(subscription.id);
      refetchSubscription();
      subscription.remainingDays = remainingDays;
      setSyncDeliveriesState(false);
    } catch {
      setSyncDeliveriesState(false);
    }
  };

  return (
    <Stack sx={{ mt: 2 }}>
      <Stack sx={styles.infoStack}>
        <Typography variant={'caption'} sx={styles.infoText}>
          Remaining Days
          <Button
            variant="outlined"
            aria-label="Sync Remaining Days"
            disabled={syncDeliveriesState}
            sx={styles.actionButton}
            onClick={syncRemainingDays}
          >
            {syncDeliveriesState ? 'Syncing...' : 'Sync'}
          </Button>
        </Typography>
        <Typography variant="h5" sx={styles.resultText}>
          {`${subscription.remainingDays} days` || '----'}
        </Typography>
      </Stack>

      <Stack sx={styles.infoStack}>
        <Typography variant={'caption'} sx={styles.infoText}>
          Cost Per Day
        </Typography>
        <Typography variant="h5" sx={styles.resultText}>
          {`${subscription.plan.pricePerDay} ${subscription.currency}` || '---'}
        </Typography>
      </Stack>

      <Stack sx={styles.infoStack}>
        <Typography variant={'caption'} sx={styles.infoText}>
          Unpaid Deliveries
        </Typography>
        <Typography variant="h5" sx={styles.resultText}>
          {!unpaidDeliveryCount.loaded && (
            <Button
              variant="outlined"
              aria-label="Get Unpaid Deliveries"
              disabled={unpaidDeliveryCount.loading}
              sx={styles.actionButton}
              onClick={fetchUnpaidDeliveries}
            >
              {unpaidDeliveryCount.loading ? 'Fetching...' : 'Fetch'}
            </Button>
          )}

          {unpaidDeliveryCount.loaded && (
            <>
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ListItemText
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: caloTheme.typography.fontFamily
                        }}
                        primary={`${unpaidDeliveryCount.count}`}
                      />
                    </ListItemIcon>
                    <ListItemText primary={`${unpaidDeliveryCount.count > 1 ? 'Deliveries' : 'Delivery'}`} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ListItemText
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: caloTheme.typography.fontFamily
                        }}
                        primary={`${unpaidDeliveryCount.totalUnpaidDeliveriesCost}`}
                      />
                    </ListItemIcon>
                    <ListItemText primary={`Total Cost`} />
                  </ListItemButton>
                </ListItem>
              </List>
              {unpaidDeliveryCount.count > 9 && (
                <>
                  <Divider />
                  <List>
                    {unpaidDeliveryCount.unpaidDeliveries.map((delivery) => (
                      <ListItem disablePadding key={delivery.id}>
                        <ListItemButton>
                          <ListItemIcon>
                            <DeliveryDining />
                          </ListItemIcon>
                          <ListItemText
                            primary={delivery.day}
                            onClick={() => window.open(Routes.delivery.replace(':id', delivery.id), '_blank')}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </>
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default StatisticsCards;
