import { CognitoUser } from '@aws-amplify/auth';

export const selectUserRoles = (user: CognitoUser): any => {
  const permissions = user.getSignInUserSession()?.getIdToken().payload['custom:permissions'].split('|');
  return permissions;
};
export const selectUserKitchens = (user: CognitoUser): any => {
  const kitchen = user.getSignInUserSession()?.getIdToken().payload['custom:kitchen']?.split(';');
  return kitchen;
};

export const selectUserCountry = (user: CognitoUser): any => {
  const country = user.getSignInUserSession()?.getIdToken().payload['custom:country']?.split(';');
  return country;
};

export const selectUserEmail = (user: CognitoUser): any => {
  const email = user.getSignInUserSession()?.getIdToken().payload['email'];
  return email;
};

export const selectUserData = (user: CognitoUser): any => {
  const email = user.getSignInUserSession()?.getIdToken().payload['email'];
  const name = user.getSignInUserSession()?.getIdToken().payload['name'];
  const id = user.getSignInUserSession()?.getIdToken().payload['sub'];
  return { email, name, id };
};
