// CustomPaperContent.tsx
import { Box, Button, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { eachWeekOfInterval, endOfMonth, getWeek, startOfMonth } from 'date-fns';
import React from 'react';

interface CustomPaperContentProps {
  selectedDate: Date;
  selectedWeek: number;
  setSelectedWeek: (week: number) => void;
  children: React.ReactNode;
}

const PaperContent: React.FC<CustomPaperContentProps> = ({ selectedDate, selectedWeek, setSelectedWeek, children }) => {
  const startOfMonthDate = startOfMonth(selectedDate);
  const endOfMonthDate = endOfMonth(selectedDate);
  const weeksInMonth = eachWeekOfInterval({ start: startOfMonthDate, end: endOfMonthDate }, { weekStartsOn: 0 });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '20px',
          alignItems: 'center',
          marginLeft: '10px',
          gap: '3px'
        }}
      >
        <Typography sx={{ marginBottom: '48px' }}>Week</Typography>
        {weeksInMonth.map((weekStartDate) => {
          const week = getWeek(weekStartDate);
          return (
            <Button
              key={weekStartDate.toString()}
              onClick={() => setSelectedWeek(week)}
              sx={{
                fontSize: '13px',
                fontWeight: 600,
                color: week === selectedWeek ? 'white' : 'black',
                minWidth: '35px',
                borderRadius: '20px',
                backgroundColor: week === selectedWeek ? caloTheme.palette.primary500 : 'white',
                ':hover': { color: 'white', backgroundColor: caloTheme.palette.primary500 }
              }}
            >
              {week}
            </Button>
          );
        })}
      </Stack>
      {children}
    </Box>
  );
};

export default PaperContent;
