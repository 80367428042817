import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  card: {
    width: 'full',
    mb: '14px',
    border: 'none',
    borderRadius: '8px',
    paddingBottom: '4px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  cardHeaderBox: {
    display: 'flex',
    padding: 2,
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  mainStack: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column',
      textAlign: 'center'
    }
  },
  titleText: {
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column',
      textAlign: 'center'
    },
    fontSize: '28px',
    lineHeight: '32px',
    fontWeight: 500,
    fontFamily: caloTheme.typography.fontFamily,
    color: caloTheme.palette.neutral900
  }
};

export default styles;
