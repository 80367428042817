import { createCustomFoodReq } from '@calo/dashboard-types';
import { CustomMealCategory } from '@calo/types';
import { useFormik } from 'formik';
import { MenuFood } from 'lib/interfaces';

const useCreateCustomizeFoodForm = (food: MenuFood, subscriptionId: string, deliveryId: string) => {
  const formik = useFormik<createCustomFoodReq>({
    initialValues: getCreateCustomizeFoodFormInitialValues(food, subscriptionId, deliveryId),

    validate: () => {
      const errors: any = {};

      return errors;
    },
    onSubmit: async () => {}
  });

  return formik;
};

export const getCreateCustomizeFoodFormInitialValues = (food: MenuFood, subscriptionId: string, deliveryId: string) => {
  return {
    name: { en: `${food?.name?.en}-custom`, ar: `${food?.name?.ar}-custom` },
    description: { en: `${food?.name?.en}-custom`, ar: `${food?.name?.ar}-custom` },

    category: CustomMealCategory.meal,
    foodComponentIds: (food.components ?? []).map((comp) => comp.id),
    subscriptionId: subscriptionId,
    deliveryId: deliveryId,
    components: (food?.components ?? []).map((comp) => {
      return {
        id: comp.id,
        quantity: comp.quantity,
        quantityConstraints: comp['quantityConstraints']
      };
    })
  };
};

export default useCreateCustomizeFoodForm;
