import { DeliveryInstruction } from '@calo/types';
import CallIcon from '@mui/icons-material/Call';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { caloTheme } from 'assets/images/theme/calo';
import { DeliveryInstructionsLabels } from 'lib/enums';
import React, { useState } from 'react';

interface DeliveryInstructionsPopupProps {
  instructions: string[];
}

const DeliveryInstructionsPopup: React.FC<DeliveryInstructionsPopupProps> = ({ instructions }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const icons = {
    [DeliveryInstruction.CALL_ME_WHEN_YOU_REACH]: <CallIcon style={{ fontSize: '16px' }} color="action" />,
    [DeliveryInstruction.LEAVE_AT_RECEPTION]: '🛎️',
    [DeliveryInstruction.LEAVE_AT_THE_DOOR]: '🚪',
    [DeliveryInstruction.RING_MY_DOORBELL]: '🛎️'
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'mouse-over-popover' : undefined;

  return (
    <div>
      <div>
        <span style={{ marginRight: '7px' }}>{icons[instructions[0] as DeliveryInstruction]}</span>
        {DeliveryInstructionsLabels[instructions[0] as DeliveryInstruction]}
      </div>
      <Typography
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ cursor: 'pointer', display: 'inline-block' }}
      >
        {instructions.length > 1 && (
          <div
            style={{
              backgroundColor: caloTheme.palette.primary100,
              marginLeft: '5px',
              borderRadius: '30px',
              width: '70px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            {`${instructions.length - 1} more`}
          </div>
        )}
      </Typography>
      <Popover
        id={id}
        sx={{ pointerEvents: 'none', marginTop: '20px' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ paddingTop: 2, pl: 2, fontWeight: 'bold', fontSize: '16px' }}>All instructions</Typography>
        <Typography sx={{ px: 2, paddingBottom: 2 }}>
          {instructions.map((instruction, index) => (
            <div key={index} style={{ paddingTop: '10px' }}>
              <span style={{ marginRight: '10px' }}>{icons[instruction as DeliveryInstruction]}</span>
              {DeliveryInstructionsLabels[instruction as DeliveryInstruction]}
            </div>
          ))}
        </Typography>
      </Popover>
    </div>
  );
};

export default DeliveryInstructionsPopup;
