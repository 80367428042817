import SelectMUI from 'components/MUI/SelectMUI';
import { FormikErrors } from 'formik';
import { foodInformationFormMultiSelectCustomStyles } from 'lib/componentStyles';
import { startCase } from 'lodash';

import { CustomMealCategory, FoodComponentType } from '@calo/types';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack, Typography } from '@mui/material';

import { ModalRef } from '../..';
import { caloTheme } from '../../../assets/images/theme/calo';
import { BaseOmit, FoodComponent } from '../../../lib/interfaces';
import Popup from '../../Popup';
import { handleCategoryText } from './helpers';

interface AddSectionPopup {
  isDisabled?: boolean;
  addSectionRef: React.MutableRefObject<ModalRef | undefined>;
  values: Omit<FoodComponent, BaseOmit>;
  selectedCategories: CustomMealCategory[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<CustomMealCategory[]>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
  handleClosePopup: () => void;
}

const AddSectionPopup = ({
  isDisabled,
  addSectionRef,
  values,
  selectedCategories,
  setFieldValue,
  setSelectedCategories,
  handleClosePopup
}: AddSectionPopup) => {
  const confirmSectionsHandler = () => {
    if (selectedCategories.length === 0) {
      return;
    }
    const existingCategories = values.sections?.map((section) => section.category) || [];

    const newSections = [...(values.sections ?? [])];
    for (const section of selectedCategories) {
      if (!existingCategories.includes(section)) {
        newSections.push({
          category: section,
          quantity: 0,
          availability: false,
          tag: FoodComponentType.base
        });
      }
    }

    setFieldValue('sections', newSections);
    handleClosePopup();
  };

  return (
    <Popup maxWidth="sm" ref={addSectionRef} onClose={handleClosePopup} fullWidth>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}
      >
        <Typography sx={{ fontSize: '23px', fontWeight: 600 }}>Add Category</Typography>
        <IconButton data-test="closeButton" onClick={handleClosePopup}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack sx={{ marginTop: '-20px', marginBottom: 2 }}>
        <SelectMUI
          isMulti
          label="Section Name"
          value={selectedCategories}
          data-test="component-section-tags"
          onChange={(data: any) => {
            setSelectedCategories(data.map((option) => option.value));
          }}
          options={Object.entries(CustomMealCategory).map(([key, value]) => ({
            value: key,
            label: startCase(handleCategoryText(value))
          }))}
          isDisabled={isDisabled}
          customStyles={foodInformationFormMultiSelectCustomStyles()}
        />
      </Stack>
      <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '50%', alignSelf: 'center' }}>
        <Button
          variant="text"
          onClick={handleClosePopup}
          sx={{
            width: '40%',
            textTransform: 'none',
            fontSize: '20px',
            fontWeight: 600,
            color: caloTheme.palette.neutral600,
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
        >
          Cancel
        </Button>
        <Button
          data-test="sectionTagsConfirmButton"
          variant="contained"
          onClick={confirmSectionsHandler}
          sx={{
            width: '40%',
            textTransform: 'none',
            fontSize: '20px',
            fontWeight: 600,
            color: 'white',
            borderRadius: '8px',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none'
            }
          }}
        >
          Confirm
        </Button>
      </Stack>
    </Popup>
  );
};

export default AddSectionPopup;
