import { DriverStatus, Permission } from '@calo/dashboard-types';
import { Country, Kitchen } from '@calo/types';
import { deleteDriver, resetDriverPassword, updateDriver } from 'actions';
import mutation from 'actions/mutation';
import cx from 'classnames';
import { Button, Icon, Input, Modal, ModalRef, PhoneNumber, Select2 } from 'components';
import { generatePassword } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import queryClient from 'lib/queryClient';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';

interface DriversRowProps {
  driver: any;
  country: Country;
  kitchen: Kitchen;
}

const DriversRow = ({ driver, country, kitchen }: DriversRowProps) => {
  const editDriverRef = useRef<ModalRef>();
  const [selected, setSelected] = useState<boolean>(false);
  const resetModalRef = useRef<ModalRef>();
  const [newPassword, setNewPassword] = useState<string>('');

  const { mutateAsync: updateMutation } = useMutation(updateDriver);
  const { mutateAsync: deleteMutation } = useMutation(deleteDriver);
  const roles = useUserRoles();

  const [singleDriverData, setSingleDriverData] = useState<any>({
    name: driver.name,
    phoneNumber: driver.phoneNumber,
    email: driver.email,
    address: driver.address,
    country: driver.country,
    kitchen: driver.kitchen,
    status: driver.status
  });

  const handleChangePassword = (data: string) => {
    setNewPassword(data);
  };

  const onSubmit = async (val: any) => {
    const id = driver.id;
    await updateMutation(
      { id: id, ...val },
      {
        onSuccess: (data) => {
          const query = queryClient.getQueryData(['drivers', country, kitchen]) as any;
          const index = query?.pages[0]?.data.findIndex((i: any) => i.id === driver.id);
          query?.pages[0]?.data.splice(index, 1, data);
          mutation(['drivers', country, kitchen], {
            ...query,
            pages: [{ ...query?.pages, data: query?.pages[0]?.data }]
          });
          const stats = queryClient.getQueryData(['stats/drivers', { country, kitchen }]) as any;
          mutation(
            ['stats/drivers', { country, kitchen }],
            country === data.country && kitchen === data.kitchen
              ? {
                  active: data.status === DriverStatus.active ? stats.active + 1 : stats.active - 1,
                  inactive: stats.inactive
                }
              : {
                  active: data.status === DriverStatus.active ? stats.active - 1 : stats.active,
                  inactive: stats.inactive
                }
          );
        },
        onSettled: () => {
          setSelected(false);
        }
      }
    );
  };

  const handleDelete = async (driver: any) => {
    await deleteMutation(driver, {
      onSuccess: () => {
        const query = queryClient.getQueryData(['drivers', country]) as any;
        const index = query.pages[0].data.findIndex((i: any) => i.id === driver.id);
        query.pages[0].data.splice(index, 1);
        mutation(['drivers', country], { ...query, pages: [{ ...query.pages, data: query.pages[0].data }] });
        const stats = queryClient.getQueryData(['stats/drivers', { country: country }]) as any;
        mutation(['stats/drivers', { country: country }], {
          active: driver.status === DriverStatus.active ? stats.active - 1 : stats.active,
          inactive: stats.inactive
        });
      },
      onSettled: () => {
        setSelected(false);
      }
    });
  };

  useEffect(() => {
    if (selected) {
      editDriverRef.current?.open();
    } else {
      editDriverRef.current?.close();
    }
  }, [selected]);

  const handleResetPassword = async (driverId: string, newPassword: string) => {
    await navigator.clipboard.writeText(newPassword);
    await resetDriverPassword({ driverId, newPassword });
    resetModalRef.current?.close();
    setNewPassword('');
  };

  useEffect(() => {
    const tempPass = generatePassword();
    setNewPassword(tempPass);
  }, []);

  return (
    <>
      <tr className="my-4">
        <td className="border-t-2 border-b-2 border-l-2 rounded-l py-4 pl-5">{singleDriverData.name}</td>
        <td className="border-t-2 border-b-2 py-4">
          <PhoneNumber>{singleDriverData.phoneNumber}</PhoneNumber>
        </td>
        <td className="border-t-2 border-b-2 py-4">{singleDriverData.email}</td>
        <td className="border-t-2 border-b-2 py-4">{singleDriverData.address}</td>
        <td className="border-t-2 border-b-2 py-4">{singleDriverData.country}</td>
        <td className="border-t-2 border-b-2 py-4">{singleDriverData.kitchen}</td>
        <td className="border-t-2 border-b-2 py-4">
          {singleDriverData.status === 'active' ? (
            <div className="rounded-full h-4 w-4 flex ml-4 bg-green-600 "></div>
          ) : (
            <div className="rounded-full h-4 w-4 flex ml-4 bg-red-600"></div>
          )}
        </td>
        <td className={'border-t-2 border-b-2 border-r-2 rounded-r py-4'}>
          <span className="flex flex-row -mt-2">
            <i className=" mt-1 fas fa-edit mr-4 cursor-pointer " onClick={() => setSelected(!selected)} />
            {roles.includes(Permission.DELETE_DRIVER) && (
              <i className="cursor-pointer" onClick={() => handleDelete(driver)}>
                {' '}
                <Icon name="delete" size={6} />{' '}
              </i>
            )}
          </span>
        </td>
      </tr>
      <Modal ref={editDriverRef} onClose={() => setSelected(false)}>
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h3 className="text-xl text-black pb-4">Edit</h3>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              {roles.includes(Permission.RESET_DRIVER_PASSWORD) && (
                <Button warning content="Reset password" className="float-right" onClick={() => resetModalRef.current?.open()} />
              )}
            </div>
          </div>
        </div>
        <div>
          <Input
            label="Name"
            name="name"
            value={singleDriverData.name}
            onChange={(e) => setSingleDriverData({ ...singleDriverData, name: e.target.value })}
          />
          <Input
            label="Phone number"
            name="phoneNumber"
            value={singleDriverData.phoneNumber}
            type="text"
            pattern="[0-9]*"
            onChange={(e) => setSingleDriverData({ ...singleDriverData, phoneNumber: e.target.value })}
          />
          <Input
            label="Email"
            name="email"
            value={singleDriverData.email}
            onChange={(e) => setSingleDriverData({ ...singleDriverData, email: e.target.value })}
          />

          <Input
            label="Address"
            name="address"
            value={singleDriverData.address}
            onChange={(e) => setSingleDriverData({ ...singleDriverData, address: e.target.value })}
          />
          <Select2
            label="Country"
            name="country"
            value={singleDriverData.country}
            options={Object.values(Country).map((c) => ({
              label: c,
              value: c
            }))}
            onChange={(e) =>
              setSingleDriverData({
                ...singleDriverData,
                country: e.target.value as Country,
                kitchen: Object.values(Kitchen).find((r) => r.includes(e.target.value) && !r.includes('000'))
              })
            }
          />
          <Select2
            label="Kitchen"
            value={singleDriverData.kitchen}
            onChange={(e) =>
              setSingleDriverData({
                ...singleDriverData,
                kitchen: e.target.value as Kitchen
              })
            }
            options={Object.values(Kitchen)
              .filter((r) => r.includes(singleDriverData.country) && !r.includes('000'))
              .map((c) => ({
                value: c,
                label: c
              }))}
            disabled={Object.values(Kitchen).filter((r) => r.includes(singleDriverData.country)).length === 0}
          />
        </div>
        <div className="mt-2">
          <span className="text-black">
            <h3 className="pb-2">Duty</h3>
          </span>
          <div>
            <label
              className={cx('cursor-pointer inline-block w-12 p-1 rounded-full', {
                'toggle-left bg-green-500': singleDriverData.status === DriverStatus.active,
                'toggle-right bg-red-500': singleDriverData.status === DriverStatus.inactive
              })}
            >
              <input
                type="checkbox"
                className="hidden"
                checked={singleDriverData.status === DriverStatus.active}
                onChange={() =>
                  setSingleDriverData({
                    ...singleDriverData,
                    status: singleDriverData.status === DriverStatus.active ? DriverStatus.inactive : DriverStatus.active
                  })
                }
              />
              <div
                className={cx('h-5 w-5 rounded-full bg-white transition-all right-0', {
                  'ml-5': singleDriverData.status === DriverStatus.active
                })}
              ></div>
            </label>
          </div>
        </div>
        <div className="flex flex-row pt-2 justify-center">
          <Button
            content="Cancel"
            className="cursor-pointer text-white bg-black mx-2 w-32  mt-2 border border-black hover:text-white hover:border-black  focus:text-white focus:border-green-500"
            onClick={() => {
              setSelected(false);
              setSingleDriverData({
                name: driver.name,
                phoneNumber: driver.phoneNumber,
                email: driver.email,
                address: driver.address,
                country: driver.country,
                status: driver.status
              });
            }}
          />
          <Button
            content="Save"
            className=" cursor-pointer text-white bg-green-500  mt-2 w-32 border border-green-500 hover:text-white hover:border-green-500  focus:text-white focus:border-green-500"
            onClick={() => onSubmit(singleDriverData)}
          />
        </div>
      </Modal>
      <Modal
        ref={resetModalRef}
        onClose={() => {
          resetModalRef.current?.close();
        }}
      >
        <section className=" section is-title-bar label -m-8">
          <p className="text-center">Reset password</p>
        </section>
        <div className="flex flex-col">
          <Input label="Temp password" value={newPassword} onChange={(data: any) => handleChangePassword(data.target.value)} />
          {newPassword.length < 8 && <p className="text-red-400 text-xs mb-1">Password should be at least 8 characters </p>}
          <Button
            warning
            content="Copy & Reset"
            disabled={newPassword.length < 8}
            onClick={() => handleResetPassword(driver.id, newPassword)}
          />
        </div>
      </Modal>
    </>
  );
};

export default DriversRow;
