import { useFormik } from 'formik';
import { UpdateFoodPackageElementReq } from 'lib/interfaces';
import { omit } from 'lodash';

export default (packages: any, onSubmit: (values: UpdateFoodPackageElementReq) => Promise<void>) =>
  useFormik<UpdateFoodPackageElementReq>({
    initialValues: {
      ...(omit(packages, ['id', 'updatedAt', 'createdAt']) as any)
    },
    validate: (values: UpdateFoodPackageElementReq) => {
      const errors: any = {};
      if (!values.name) {
        errors.name = true;
      }

      return errors;
    },
    onSubmit: async (values) => {
      console.log(values);
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
