import axios from 'axios';
import { compact } from 'lodash';
import { useMutation } from 'react-query';

import { Brand, Country } from '@calo/types';

import { GroupGiftExportType } from '@calo/dashboard-types';
import { downloadGiftSubscriptionsLists } from 'actions';
import { Button, ModalRef, Pagination } from 'components';
import { MenuFood } from 'lib/interfaces';
import { useEffect, useState } from 'react';
import { ShowDataModal } from '../GiftModal';

export interface ReadyForReleaseProps {
  giftListData: any[];
  page: number;
  total: number;
  setPage: (value: number) => void;
  setselectedMeals: (value: MenuFood[]) => void;
  setselectedAddons: (value: any[]) => void;
  handleReleaseGift: (id: string, auto: boolean) => void;
  showDataModalRef: React.MutableRefObject<ModalRef | undefined>;
}

const ReadyForRelease = ({ setPage, page, total, handleReleaseGift, giftListData, showDataModalRef }: ReadyForReleaseProps) => {
  const [giftID, setGiftID] = useState<string>('');
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [selectedDataRecord, setSelectedDataRecord] = useState({
    selectedDate: '',
    selectedCountry: Country.BH,
    selectedBrand: Brand.CALO,
    selectedMeals: [],
    selectedAddons: [],
    giftID: '',
    dataType: ''
  });

  const [dataType, setDataType] = useState<string>('');

  const { mutateAsync: downloadGiftSubscriptionsListsMutation } = useMutation(downloadGiftSubscriptionsLists);

  const handleDownload = async (id: string, type: GroupGiftExportType) => {
    await downloadGiftSubscriptionsListsMutation(
      { id, type },
      {
        onSuccess: (data) => {
          axios({
            url: data.url,
            method: 'GET',
            responseType: 'blob' // important
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${type}.csv`);
            document.body.appendChild(link);
            link.click();
          });
        }
      }
    );
  };

  useEffect(() => {
    if (selectedDataRecord.giftID.length > 0) {
      showDataModalRef.current?.open();
    } else {
      showDataModalRef.current?.close();
    }
  }, [selectedDataRecord]);

  return (
    <>
      <div className="card has-table has-table-container-upper-radius mt-3">
        <div className="card-content">
          <div className="table-container overflow-y-auto">
            <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
              <thead>
                <tr className="bg-black">
                  <th style={{ color: 'white' }} className=" w-48">
                    Subscribers
                  </th>
                  <th style={{ color: 'white' }} className=" w-48">
                    Meals
                  </th>
                  <th style={{ color: 'white' }} className=" w-48">
                    Addons
                  </th>
                  <th style={{ color: 'white' }} className=" w-48">
                    Date
                  </th>
                  <th style={{ color: 'white' }} className=" w-48">
                    Cost (per subscriber)
                  </th>
                  <th style={{ color: 'white' }} className=" w-48">
                    Total Cost
                  </th>
                  <th style={{ color: 'white' }} className=" w-48"></th>
                  <th style={{ color: 'white' }} className=" w-48"></th>
                </tr>
              </thead>
              <tbody>
                {giftListData &&
                  compact(giftListData)?.map((data: any) => (
                    <tr key={data.id}>
                      {/* subscribers */}
                      <td>
                        <span className="flex flex-row">
                          <span>{`🧍 * ${data.stats?.count}`}</span>
                          <span className="ml-2 cursor-pointer w-32">
                            <p
                              className="border-none text-blue-500"
                              onClick={() => {
                                setDataType('subscribers');
                                setSelectedDataRecord({
                                  selectedDate: data.date,
                                  selectedCountry: data.country,
                                  selectedBrand: data.brand,
                                  selectedMeals: data.food,
                                  selectedAddons: data.addons,
                                  giftID: data.id,
                                  dataType: 'subscribers'
                                });
                                setTotalRecords(data.stats.count);
                                setGiftID(data.id);
                              }}
                            >
                              See More
                            </p>
                          </span>
                        </span>
                      </td>
                      {/* Meals */}
                      <td>
                        <span className="flex flex-row">
                          <span>{`🌮 * ${data.food?.length ? data.food.length : '0'}`}</span>
                          <span className="ml-2 cursor-pointer">
                            <p
                              className="border-none text-blue-500"
                              onClick={() => {
                                setDataType('meals');
                                setSelectedDataRecord({
                                  selectedDate: data.date,
                                  selectedCountry: data.country,
                                  selectedBrand: data.brand,
                                  selectedMeals: data.food,
                                  selectedAddons: data.addons,
                                  giftID: data.id,
                                  dataType: 'meals'
                                });
                              }}
                            >
                              See More
                            </p>
                          </span>
                        </span>
                      </td>
                      {/* Addons */}
                      <td>
                        <span className="flex flex-row">
                          <span>{`🌮 * ${data.addons ? data.addons.length : '0'}`}</span>
                          <span className="ml-2 cursor-pointer">
                            <p
                              className="border-none text-blue-500"
                              onClick={() => {
                                setDataType('addons');
                                setSelectedDataRecord({
                                  selectedDate: data.date,
                                  selectedCountry: data.country,
                                  selectedBrand: data.brand,
                                  selectedMeals: data.food,
                                  selectedAddons: data.addons,
                                  giftID: data.id,
                                  dataType: 'addons'
                                });
                              }}
                            >
                              See More
                            </p>
                          </span>
                        </span>
                      </td>
                      {/* Date */}
                      <td>{data.date}</td>
                      {/* Allergies and Gifts download buttons */}
                      <td>{data.cost ? data.cost.toFixed(3) : '--'}</td>
                      <td>{data.cost ? (data.cost * data.stats.count).toFixed(3) : '--'}</td>
                      <td>
                        {data.expectedLastDeliveryDate && (
                          <>
                            <Button
                              outlined
                              content="Allergies"
                              className="text-green-500 border border-green-500 hover:border-green-500 hover:text-green-500 hover:shadow-lg"
                              onClick={() => handleDownload(data.id, GroupGiftExportType.allergies)}
                            />
                            <Button
                              outlined
                              content="Gifts"
                              className="text-green-500 border border-green-500 hover:border-green-500 hover:text-green-500 hover:shadow-lg"
                              onClick={() => handleDownload(data.id, GroupGiftExportType.gifts)}
                            />
                          </>
                        )}
                      </td>
                      {/* Action */}
                      <td>
                        <span className="flex flex-row float-right">
                          {data.status === 'pending' ? (
                            <span className="button cursor-default is-outlined text-red-500 border border-red-500 hover:border-red-500 hover:text-red-500">
                              Pending
                            </span>
                          ) : (
                            <Button
                              outlined
                              content="Release"
                              className="text-green-500 border border-green-500 hover:border-green-500 hover:text-green-500 hover:shadow-lg"
                              onClick={() => handleReleaseGift(data.id, data.expectedLastDeliveryDate ? true : false)}
                            />
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination limit={10} total={total} page={page} onChange={setPage} />
      </div>
      <ShowDataModal
        giftID={giftID}
        totalRecords={totalRecords}
        listType={'draft'}
        dataType={dataType}
        setGiftID={setGiftID}
        showDataModalRef={showDataModalRef}
        selectedDate={selectedDataRecord.selectedDate}
        meals={selectedDataRecord.selectedMeals}
        addons={selectedDataRecord.selectedAddons}
        selectedCountryBrand={{
          brand: selectedDataRecord.selectedBrand!,
          country: selectedDataRecord.selectedCountry!
        }}
      />
    </>
  );
};
export default ReadyForRelease;
