import { CustomMealCategory, Dictionary, FoodComponentType, Localized, Macros } from '@calo/types';
import { Stack } from '@mui/material';
import { ComponentCountByCustomMealType } from 'lib/enums';
import { ComponentLabelValue, ComponentsByTag, FoodComponent } from 'lib/interfaces';
import { useState } from 'react';
import { MacrosCard, MealNameCard, SelectedComponentCard } from '../Shared';
import ComponentCard from './ComponentCard';
import MealDetailsBox from './ComponentCard/MealDetailsBox';
import ComponentTagsList from './ComponentTagsList';

interface InitialFromProps {
  customMealCategory: CustomMealCategory;
  customFoodName: Localized;
  totalMacros: Macros;
  selectedTag: FoodComponentType;
  selectedComponents: ComponentsByTag;
  keyedComponents: Dictionary<FoodComponent>;
  componentsByCategory: FoodComponent[];
  setSelectedComponents: React.Dispatch<React.SetStateAction<ComponentsByTag>>;
  handleNameChange: (data: string) => void;
  setSelectedTag: React.Dispatch<React.SetStateAction<FoodComponentType>>;
}

const InitialForm = ({
  customFoodName,
  customMealCategory,
  componentsByCategory,
  selectedTag,
  totalMacros,
  selectedComponents,
  keyedComponents,
  setSelectedComponents,
  handleNameChange,
  setSelectedTag
}: InitialFromProps) => {
  const [selectedComponentInfoId, setSelectedComponentInfoId] = useState<string | null>(null);

  const componentsBySelectedTag = componentsByCategory?.filter((comp) =>
    (comp.sections ?? []).some((section) => section.category === customMealCategory && section.tag === selectedTag)
  );

  const removeSelectedComponent = (id: string, tag: FoodComponentType) => {
    const index = selectedComponents[tag].findIndex((comp) => comp.value === id);
    const newTagComponents = [...selectedComponents[tag]];
    newTagComponents.splice(index, 1);

    setSelectedComponents((prev) => ({
      ...prev,
      [tag]: newTagComponents
    }));
  };

  const selectComponent = (id: string, tag: FoodComponentType) => {
    setSelectedComponents((prev) => ({
      ...prev,
      [tag]: [...prev[tag], { label: keyedComponents[id].name.en, value: id }]
    }));
  };

  return (
    <Stack sx={{ width: '100%', position: 'relative', overflow: 'visible' }}>
      <MealNameCard customFoodName={customFoodName} handleNameChange={handleNameChange} />
      <Stack
        id="selected-components"
        sx={{
          flexDirection: 'row',
          gap: '20px',
          overflowX: 'scroll',
          scrollbarWidth: 'none', // For Firefox
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        {Object.values(selectedComponents)
          .flat()
          .map((component: ComponentLabelValue, index) => (
            <SelectedComponentCard
              key={`${component.value}#${index}`}
              component={keyedComponents[component.value]}
              customMealCategory={customMealCategory}
              removeComponent={removeSelectedComponent}
            />
          ))}
      </Stack>
      <MacrosCard macros={totalMacros} />
      <ComponentTagsList
        selectedComponents={selectedComponents}
        customMealCategory={customMealCategory}
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
      />
      <Stack sx={{ flexDirection: 'row', flexWrap: 'wrap', gap: '2px', marginTop: '8px' }}>
        {componentsBySelectedTag?.map((comp) => (
          <ComponentCard
            key={comp.id}
            component={comp}
            selectedTag={selectedTag}
            setSelectedComponentInfo={setSelectedComponentInfoId}
            maxAllowedCompsForTag={ComponentCountByCustomMealType[customMealCategory][selectedTag]}
            numOfSelectedComponentsByTag={selectedComponents[selectedTag].length}
            selectedCount={selectedComponents[selectedTag]?.filter((selectedComp) => selectedComp.value === comp.id).length}
            selectComponent={selectComponent}
          />
        ))}
      </Stack>
      {selectedComponentInfoId && (
        <MealDetailsBox
          component={keyedComponents[selectedComponentInfoId]}
          closeHandler={() => setSelectedComponentInfoId(null)}
        />
      )}
    </Stack>
  );
};

export default InitialForm;
