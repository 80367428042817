import { useMemo, useState } from 'react';

import { FoodStatsFilters } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';
import { Box, Card, Stack } from '@mui/material';
import { getListWithParams } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { format } from 'date-fns/fp';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { resolveCountryFromKitchen } from 'lib';
import history from 'lib/history';
import { useUserKitchens } from 'lib/hooks';
import { StatsFoodComponent } from 'lib/interfaces';
import { flatten, sortBy } from 'lodash-es';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import SharedStatsHeader from '../SharedStatsHeader';
import TableStatsHeaderSection from '../TableStatsHeaderSection';
import { printPDF } from '../utils';
import KitchenComponentStatsTable from './KitchenComponentStatsTable';
import MealsDrawer from './MealsDrawer';
import Settings from './Settings';
import {
  calcualteSubComponentCookedWeight,
  calculateRemovedCookedWeight,
  calculateSubComponentRawWeight,
  calculateTotalCookedWeight,
  calculateTotalRawWeight,
  getMeasurementUnit,
  groupedAllFoodComponents,
  prepareForExport
} from './helpers';

const KitchenComponentStats = () => {
  const location = useLocation();
  const userKitchen = useUserKitchens();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const [switchUnit, setSwitchUnit] = useState<boolean>(false);
  const [showDetailedView, setShowDetailedView] = useState(false);
  const [drawerComponent, setDrawerComponent] = useState<StatsFoodComponent | undefined>();
  const [filtersCheck, setFiltersCheck] = useState({ subscriptions: true, businesses: false, charity: false });
  const [selectedCustomSize, setSelectedCustomSize] = useState<string | undefined>(undefined);

  const [filters, setFilters] = useState<FoodStatsFilters>({
    day: {
      gte: format('yyyy-MM-dd')(Date.now()),
      lte: format('yyyy-MM-dd')(Date.now())
    },
    brand: Brand.CALO,
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data: statsData, isLoading } = useQuery<
    any,
    Error,
    {
      data: StatsFoodComponent[];
      charity: StatsFoodComponent[];
      business: StatsFoodComponent[];
    }
  >(['/stats/kitchen-food', filters], getListWithParams, {
    onSuccess: () => {
      searchParams.set('filters', JSON.stringify(filters));
      history.push({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    }
  });

  const filteredStatsData: StatsFoodComponent[] = useMemo(() => {
    const data: StatsFoodComponent[][] = [];
    if (filtersCheck.subscriptions) {
      data.push(statsData ? statsData?.data : []);
    }
    if (filtersCheck.businesses) {
      data.push(statsData ? statsData?.business : []);
    }
    if (filtersCheck.charity) {
      data.push(statsData ? statsData?.charity : []);
    }

    return flatten(data);
  }, [filtersCheck, statsData]);

  const summedComponentData = useMemo(() => {
    return Object.values(groupedAllFoodComponents(filteredStatsData)) as StatsFoodComponent[];
  }, [filteredStatsData, selectedCustomSize]);

  const filteredComponentData = useMemo(() => {
    if (!selectedCustomSize) {
      return summedComponentData;
    }

    return summedComponentData
      .filter((component) => Object.values(component.keyedFood).some((food) => food.size === selectedCustomSize))
      .map((component) => {
        const matchingFood = Object.values(component.keyedFood).find((food) => food.size === selectedCustomSize);

        if (matchingFood) {
          return {
            ...component,
            stats: {
              quantity: matchingFood.quantity,
              cookedWeight: matchingFood.weight,
              rawWeight: Math.ceil((component.cookedRawFactor ?? 1) * (matchingFood.weight - (matchingFood.removedWeight || 0))),
              cookedSpecialRequestsWeight: 0,
              rawSpecialRequestsWeight: 0,
              specialRequestsQuantity: 0,
              childQuantity: 0,
              childWeight: 0,
              removedQuantity: matchingFood.removedQuantity,
              removedCookedWeight: matchingFood.removedWeight,
              removedRawWeight: matchingFood.removedWeight
            }
          } as StatsFoodComponent;
        }
        return component;
      });
  }, [selectedCustomSize, summedComponentData]);

  const onExport = async () => {
    if (!filteredComponentData) {
      return;
    }
    const workbook = new ExcelJS.Workbook();

    prepareForExport(workbook, filteredComponentData, filters, showDetailedView);

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, 'component-stats' + fileExtension);
  };

  const handleOnPrint = () => {
    const records = sortBy(filteredComponentData, 'name.en').map((row) => {
      const startSection = {
        name: row.name.en,
        kitchen: row.kitchen
      };
      let middleSection = {};
      const endSection = {
        totalRawWeight: calculateTotalRawWeight(row),
        totalCookedWeight: calculateTotalCookedWeight(switchUnit, row),
        measurementUnit: getMeasurementUnit(switchUnit, row)
      };
      if (showDetailedView) {
        middleSection = {
          rawWeight: Math.ceil(row.stats.rawWeight - row.stats.rawSpecialRequestsWeight),
          cookedWeight: switchUnit
            ? row.stats.quantity
            : Math.ceil(row.stats.cookedWeight - row.stats.cookedSpecialRequestsWeight),
          specialRequestsRawWeight: Math.ceil(row.stats.rawSpecialRequestsWeight),
          specialRequestsCookedWeight: switchUnit
            ? row.stats.specialRequestsQuantity
            : Math.ceil(row.stats.cookedSpecialRequestsWeight),
          subComponentRawWeight: calculateSubComponentRawWeight(switchUnit, row),
          subComponentCookedWeight: calcualteSubComponentCookedWeight(switchUnit, row),
          removedRawWeight: Math.ceil(row.stats.removedRawWeight),
          removedCookedWeight: calculateRemovedCookedWeight(switchUnit, row)
        };
      }
      return {
        ...startSection,
        ...middleSection,
        ...endSection
      };
    });
    printPDF(`Component stats ${filters.day.gte}-${filters.day.lte} Section: ${filters.station ?? 'All'}`, records, 18);
  };

  const getAllCustomSizes = (components: StatsFoodComponent[]): string[] => {
    const customSizes: string[] = [];
    for (const component of components) {
      for (const [key, food] of Object.entries(component.keyedFood)) {
        if (key.includes('_custom') && !customSizes.includes(food.size)) {
          customSizes.push(food.size);
        }
      }
    }
    return customSizes;
  };

  const customSizes = useMemo(() => getAllCustomSizes(summedComponentData), [summedComponentData]);

  return (
    <>
      <SharedStatsHeader
        title={'Component Stats'}
        isLoading={isLoading}
        onExport={onExport}
        isDisabled={filteredComponentData.length === 0 || isLoading}
        onPrint={handleOnPrint}
      />
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: '14px',
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
          <TableStatsHeaderSection
            filtersCheck={filtersCheck}
            setFiltersCheck={setFiltersCheck}
            title="Food Components"
            setSwitchUnit={setSwitchUnit}
            switchUnit={switchUnit}
            showToggleSwitch={true}
            showDetailedView={showDetailedView}
            setShowDetailedView={setShowDetailedView}
          />
          {isLoading ? (
            <Stack sx={{ width: '100%', justifyContent: 'center' }}>
              <CaloLoader />
            </Stack>
          ) : (
            <KitchenComponentStatsTable
              switchUnit={switchUnit}
              selectedCustomSize={selectedCustomSize}
              setIsDrawerOpen={setIsDrawerOpen}
              showDetailedView={showDetailedView}
              setDrawerComponent={setDrawerComponent}
              filteredComponentData={filteredComponentData}
            />
          )}
        </Box>
      </Card>
      <Settings
        filters={filters}
        onFilter={setFilters}
        customSizes={customSizes}
        selectedCustomSize={selectedCustomSize}
        setSelectedCustomSize={setSelectedCustomSize}
      />
      <MealsDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        component={
          drawerComponent && {
            ...drawerComponent,
            keyedFood: Object.fromEntries(
              Object.entries(drawerComponent?.keyedFood).filter(([_key, food]) =>
                selectedCustomSize ? food.size === selectedCustomSize : food
              )
            )
          }
        }
        filters={filters}
      />
    </>
  );
};
export default KitchenComponentStats;
