import { SyncFoodComponentReq } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { FormikErrors } from 'formik';

import CloseIcon from '@mui/icons-material/Close';
import { caloTheme } from 'assets/images/theme/calo';
import CheckAttributes from './CheckAttributes';
import KitchensDropdown from './KitchensDropdown';
import SelectedKitchensList from './SelectedKitchensList';

interface SelectSyncOptionsProps {
  values: SyncFoodComponentReq;
  isLoading: boolean;
  currentKitchen: Kitchen;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<SyncFoodComponentReq>>;
  closePopup: () => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

const SelectSyncOptions = ({
  values,
  currentKitchen,
  isLoading,
  handleSubmit,
  setFieldValue,
  closePopup
}: SelectSyncOptionsProps) => {
  const selectKitchenHandler = (kitchen: Kitchen) => {
    if (!values.kitchens.includes(kitchen)) {
      setFieldValue('kitchens', [...values.kitchens, kitchen]);
    }
  };

  const removeKitchenHandler = (kitchen: Kitchen) => {
    setFieldValue(
      'kitchens',
      values.kitchens.filter((k) => k !== kitchen)
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', py: '14px' }}>
      <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: '10px' }}>
        <Typography variant="h5" sx={{ fontSize: '28px', fontWeight: 600 }}>
          Update Component Across MPs
        </Typography>
        <IconButton onClick={closePopup}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <CheckAttributes values={values} setFieldValue={setFieldValue} />
      <KitchensDropdown values={values} currentKitchen={currentKitchen} selectKitchen={selectKitchenHandler} />
      <SelectedKitchensList values={values} removeKitchen={removeKitchenHandler} />
      <Button
        variant="outlined"
        disabled={isLoading || values.kitchens.length === 0 || values.attributes.length === 0}
        onClick={() => handleSubmit()}
        sx={{
          color: 'white',
          backgroundColor: caloTheme.palette.primary500,
          borderColor: caloTheme.palette.primary500,
          borderRadius: '8px',
          width: '290px',
          alignSelf: 'center',
          textTransform: 'none',
          marginTop: '20px',
          fontSize: '20px',
          fontWeight: 600,
          '&:hover': {
            color: 'white',
            backgroundColor: caloTheme.palette.primary500,
            borderColor: caloTheme.palette.primary500
          },
          '&:disabled': {
            color: 'white',
            backgroundColor: caloTheme.palette.primary300,
            borderColor: caloTheme.palette.primary300
          }
        }}
      >
        Check Component Status
      </Button>
    </Box>
  );
};

export default SelectSyncOptions;
