import { useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack, TableCell, Typography } from '@mui/material';

import Input from '../../Input';

interface HeaderRowProps {
  row: {
    type: 'header';
    header?: string;
  };
  index: number;
  saveHeaderHandler: (index: number, newName?: string) => void;
  deleteHeaderHandler: (header?: string) => void;
  isDisabled?: boolean;
}

const HeaderRow = ({ row, index, saveHeaderHandler, deleteHeaderHandler, isDisabled }: HeaderRowProps) => {
  const [header, setHeader] = useState(row.header);
  const [editHeader, setEditHeader] = useState(false);

  const nameChangeHandler = (e: any) => {
    setHeader(e.target.value);
  };

  const saveHeader = () => {
    saveHeaderHandler(index, header);
    setEditHeader(false);
  };

  const deleteHeader = () => {
    deleteHeaderHandler(row.header);
  };

  return (
    <TableCell colSpan={12} sx={{ backgroundColor: '#F7FBF9', borderRadius: '8px', py: 0 }}>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          py: '2px'
        }}
      >
        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
          {editHeader ? (
            <Input className="mt-1" onChange={nameChangeHandler} value={header} />
          ) : (
            <Typography sx={{ marginY: 'auto', fontSize: '18px', fontWeight: 400 }}>{header}</Typography>
          )}
          {editHeader ? (
            <IconButton disabled={isDisabled} onClick={saveHeader}>
              <CheckIcon fontSize="medium" />
            </IconButton>
          ) : (
            <IconButton disabled={isDisabled} onClick={() => setEditHeader(true)}>
              <EditIcon fontSize="medium" />
            </IconButton>
          )}
        </Stack>
        <IconButton disabled={isDisabled} onClick={deleteHeader}>
          <DeleteOutlineIcon />
        </IconButton>
      </Stack>
    </TableCell>
  );
};

export default HeaderRow;
