import { addDays } from 'date-fns/fp';
import { FormikBag, FormikProps, withFormik } from 'formik';

import { PermissionService } from '@calo/services';
import DateFnsAdapter from '@date-io/date-fns';
import { Box, Button, Stack, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { caloTheme } from 'assets/images/theme/calo';
import { SubscriptionPUValues } from 'lib/interfaces';

interface PauseSubscriptionFormProps {
  onSubmit: (values: SubscriptionPUValues) => any;
  pausedAt?: string;
  resumeAt?: string;
  deliveryDays: number[];
}

const PauseSubscriptionForm = ({
  values,
  submitForm,
  handleSubmit,
  setFieldValue,
  isValid,
  dirty,
  deliveryDays
}: PauseSubscriptionFormProps & FormikProps<SubscriptionPUValues>) => (
  <form onSubmit={handleSubmit}>
    <Box display={'flex'} flexDirection={'column'} m={3}>
      <Stack mb={2}>
        <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px' }}>
          <DesktopDatePicker
            label="Pause"
            value={values.startDate || null}
            inputFormat="dd/MM/yyyy"
            minDate={PermissionService.getMinActionDate(deliveryDays, Date.now())}
            onChange={(date) => setFieldValue('startDate', date)}
            renderInput={(params) => <TextField {...params} data-test="PauseDatePickerInput" />}
          />
        </LocalizationProvider>
      </Stack>
      <Stack mb={2}>
        <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px' }}>
          <DesktopDatePicker
            label="Resume"
            value={values.endDate || null}
            disabled={!values.startDate}
            inputFormat="dd/MM/yyyy"
            minDate={addDays(1)(PermissionService.getMinActionDate(deliveryDays, Date.now()))}
            onChange={(endDate) => setFieldValue('endDate', endDate)}
            renderInput={(params) => <TextField {...params} data-test="ResumeDatePickerInput" />}
          />
        </LocalizationProvider>
      </Stack>
    </Box>
    <Box width={'full'} display={'flex'} flexDirection={'row'}>
      <Button
        data-test="confirmButton"
        variant="contained"
        fullWidth
        disabled={!dirty || !isValid}
        aria-label="confirmPauseSubscription"
        sx={{
          height: '51px',
          lineHeight: '17px',
          fontWeight: 600,
          fontSize: '14px',
          borderRadius: '8px',
          borderColor: caloTheme.palette.primary500,
          backgroundColor: caloTheme.palette.primary500,
          color: 'White',
          '&:hover': {
            backgroundColor: caloTheme.palette.primary600,
            borderColor: caloTheme.palette.primary600
          }
        }}
        onClick={submitForm}
      >
        Confirm
      </Button>
    </Box>
  </form>
);

export default withFormik<PauseSubscriptionFormProps, SubscriptionPUValues>({
  validate: (values: SubscriptionPUValues) => {
    const errors: any = {};
    if (values.endDate && values.endDate <= values.startDate) {
      errors.endDate = true;
    }
    return errors;
  },
  handleSubmit: async (values, { props }: FormikBag<PauseSubscriptionFormProps, SubscriptionPUValues>) => {
    try {
      await props.onSubmit(values);
    } catch (error) {
      console.log(error);
    }
  }
})(PauseSubscriptionForm);
