import React from 'react';
import { Box, List, ListItem, Fade } from '@mui/material';
import { styled } from '@mui/system';
import PhoneCardComponent from '../PhoneCard';

interface PhoneCardComponentProps {
  id: string;
  name: string;
  tags: string[];
  macros: {
    cal: number;
    carbs: number;
    fat: number;
    protein: number;
    fiber: number;
  };
}
interface PhoneCardListProps {
  cards: PhoneCardComponentProps[];
}

const StyledList = styled(List)({
  padding: 0,
  width: '100%',
  height: '100%'
});

const StyledListItem = styled(ListItem)({
  padding: '12px 0'
});

const PhoneCardList = ({ cards }: PhoneCardListProps) => {
  return (
    <Box>
      <StyledList>
        {cards.map((card, index) => (
          <StyledListItem key={index} disablePadding>
            <Fade in={true}>
              <div style={{ width: '100%' }}>
                <PhoneCardComponent {...card} />
              </div>
            </Fade>
          </StyledListItem>
        ))}
      </StyledList>
    </Box>
  );
};

export default PhoneCardList;
