import { LinkedComponent } from '@calo/dashboard-types';
import { ComponentService } from '@calo/services';
import { Dictionary, Nutrition } from '@calo/types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { MacrosOffset } from 'lib/enums';
import { FoodComponent } from 'lib/interfaces';
import { round, sumBy } from 'lodash-es';

import CustomizedFoodComponentRow from './CustomizedFoodComponentRow';

interface CustomizedFoodTableProps {
  isChild: boolean;
  isDisabled: boolean;
  selectedComponents: LinkedComponent[];
  allComponents: Dictionary<FoodComponent>;
  onChange: (rows: LinkedComponent[]) => void;
  isPreBuildCustom?: boolean;
}

const calculateMacrosPercentage = (totalWeight: number, totalCalories: number, offset: number) => {
  return round(((totalWeight * offset) / totalCalories) * 100, 1);
};

const CustomizedFoodTable = (props: CustomizedFoodTableProps) => {
  const { isDisabled, isChild, selectedComponents, allComponents, onChange, isPreBuildCustom } = props;

  const handleSumMacrosData = (allComponents: Dictionary<FoodComponent>, macrosType: Nutrition) => {
    return round(
      sumBy(selectedComponents, (fc) => {
        const component = allComponents[fc.id];
        if (!component) return 0;
        const componentWeight = ComponentService.calculateComponentWeight(
          component.cups,
          component.measurementUnit,
          component.weight ?? 1,
          fc?.quantity || 0
        );
        return isChild
          ? ((component?.macros[macrosType] || 0) / (component?.weight || 1)) * componentWeight
          : (component?.macros[macrosType] || 0) * componentWeight;
      })
    );
  };

  const totalCalories = handleSumMacrosData(allComponents, 'cal' as any);
  const totalProteinWeight = handleSumMacrosData(allComponents, Nutrition.protein);
  const totalCarbsWeight = handleSumMacrosData(allComponents, Nutrition.carbs);
  const totalFatWeight = handleSumMacrosData(allComponents, Nutrition.fat);

  const proteinPercentage = calculateMacrosPercentage(totalProteinWeight, totalCalories, MacrosOffset.protein);
  const carbsPercentage = calculateMacrosPercentage(totalCarbsWeight, totalCalories, MacrosOffset.carbs);
  const fatPercentage = calculateMacrosPercentage(totalFatWeight, totalCalories, MacrosOffset.fat);

  return (
    <Paper sx={{ width: '98%', m: 2, mt: 0, boxShadow: 'none' }}>
      <TableContainer>
        <Table sx={{ width: '100%' }}>
          <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, border: 0 }}>
            <TableRow>
              <TableCell
                colSpan={2}
                sx={{
                  borderRadius: '8px 0 0 8px',
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  minWidth: '250px'
                }}
              >
                Component Name
              </TableCell>
              {isPreBuildCustom && (
                <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '150px' }}>
                  Times Portioned
                </TableCell>
              )}
              <TableCell />
              <TableCell
                sx={{
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  minWidth: isPreBuildCustom ? '100px' : '150px'
                }}
              >
                Quantity
              </TableCell>
              <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '100px' }}>
                Unit
              </TableCell>
              <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '100px' }}>
                Weight
              </TableCell>
              <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '100px' }}>
                Calories
              </TableCell>
              <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '100px' }}>
                Proteins
              </TableCell>
              <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '100px' }}>
                Carbs
              </TableCell>
              <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '100px' }}>
                Fats
              </TableCell>
              <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '100px' }}>
                Fibers
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedComponents.map((row, index) => (
              <TableRow key={row.id}>
                <CustomizedFoodComponentRow
                  isDisabled={isDisabled}
                  childComp={isChild}
                  selectedComponents={selectedComponents}
                  onChange={onChange}
                  allComponents={allComponents}
                  component={row}
                  isPreBuildCustom={isPreBuildCustom}
                  index={index}
                />
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                colSpan={isPreBuildCustom ? 7 : 6}
                sx={{ borderBottom: 0, pb: 1, borderTop: '1px solid ' + caloTheme.palette.neutral100 }}
              >
                {' '}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: 0,
                  pb: 1,
                  fontSize: '16px',
                  borderTop: '1px solid ' + caloTheme.palette.neutral100,
                  color: caloTheme.palette.black
                }}
              >
                {totalCalories}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: 0,
                  pb: 1,
                  fontSize: '16px',
                  borderTop: '1px solid ' + caloTheme.palette.neutral100,
                  color: caloTheme.palette.black
                }}
              >
                {totalProteinWeight}
                {!isChild && Boolean(proteinPercentage) && `(${proteinPercentage}%)`}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: 0,
                  pb: 1,
                  fontSize: '16px',
                  borderTop: '1px solid ' + caloTheme.palette.neutral100,
                  color: caloTheme.palette.black
                }}
              >
                {totalCarbsWeight}
                {!isChild && Boolean(carbsPercentage) && `(${carbsPercentage}%)`}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: 0,
                  pb: 1,
                  fontSize: '16px',
                  borderTop: '1px solid ' + caloTheme.palette.neutral100,
                  color: caloTheme.palette.black
                }}
              >
                {totalFatWeight}
                {!isChild && Boolean(fatPercentage) && `(${fatPercentage}%)`}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: 0,
                  pb: 1,
                  fontSize: '16px',
                  borderTop: '1px solid ' + caloTheme.palette.neutral100,
                  color: caloTheme.palette.black
                }}
              >
                {handleSumMacrosData(allComponents, Nutrition.fiber)}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CustomizedFoodTable;
