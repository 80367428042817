import { exportSubscriptions, getListWithParams, toggleUISettings } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Icon, Pagination, TablePagination } from 'components';
import { useDocumentMedia } from 'hooks';
import { theme } from 'lib/componentStyles';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { PaginatedSubscriptions } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import { Permission, SubscriptionFilters } from '@calo/dashboard-types';
import { Brand, SubscriptionStatus } from '@calo/types';
import { Box, Button, Card, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

import Settings from '../Settings';
import SubscriptionListMobile from '../SubscriptionListMobile';
import SubscriptionRow from '../SubscriptionRow';

type SubscriptionListProps = RouteComponentProps;

const SubscriptionList2 = ({ history }: SubscriptionListProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const roles = useUserRoles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [oldView, setOldView] = useState<boolean>(false);
  const { isMobile, isTablet } = useDocumentMedia();
  const [filters, setFilters] = useState<SubscriptionFilters>({
    status: [SubscriptionStatus.ongoing],
    autoRenew: undefined,
    brand: Brand.CALO,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data, isLoading } = useQuery<any, Error, PaginatedSubscriptions>(
    ['subscriptions', { page, filters }],
    getListWithParams,
    {
      onSuccess: (data) => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
        for (const sub of data?.data || []) {
          queryClient.setQueryData(['subscriptions', sub.id], sub);
        }
      }
    }
  );

  useEffect(() => {
    setPage(0);
  }, [filters]);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: 2,
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          display={'flex'}
          flexDirection="row"
          justifyContent={'space-between'}
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
          width="100%"
        >
          <Stack width={'50%'} display={'flex'} flexDirection={'row'} alignItems="start" justifyContent={'space-between'}>
            <Typography
              variant="h3"
              sx={{
                mt: 'auto',
                fontWeight: 600,
                fontSize: '33px',
                lineHeight: '40px',
                color: caloTheme.palette.neutral900,
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              Subscriptions
            </Typography>
          </Stack>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems="end"
            sx={{
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
                flexDirection: 'column'
              }
            }}
          >
            <ThemeProvider theme={theme}>
              <Box sx={{ marginX: 2 }}>
                <Switch
                  onChange={() => {
                    setOldView(!oldView);
                  }}
                  sx={{ color: 'white' }}
                  checked={oldView}
                />
              </Box>
            </ThemeProvider>
            <Button
              aria-label="filter-subscription-list"
              sx={{
                cursor: 'pointer',
                my: 'auto',
                '&:hover': {
                  backgroundColor: 'white',
                  borderColor: 'none'
                },
                color: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary500,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  justifyItems: 'center',
                  margin: 'auto',
                  marginTop: 4,
                  width: 'auto'
                }
              }}
              onClick={() => dispatch(toggleUISettings())}
            >
              {<Icon name="filter" size={6} className="w-10 h-18" />}
            </Button>
            <Button
              variant="outlined"
              disabled={(data && data.data.length === 0) || isLoading}
              aria-label="Download-subscription-list"
              sx={{
                textTransform: 'none',
                height: '45px',
                ml: 2,
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                borderRadius: '8px',
                padding: '14px 20px 14px 20px',
                color: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary500,
                '&:hover': {
                  backgroundColor: caloTheme.palette.primary100,
                  borderColor: caloTheme.palette.primary500
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  justifyItems: 'center',
                  margin: 'auto',
                  marginTop: 4,
                  width: 'auto'
                }
              }}
              onClick={() => exportSubscriptions(filters)}
              startIcon={
                <Icon
                  name="importFile"
                  size={6}
                  style={{
                    stroke:
                      (data && data.data.length === 0) || isLoading ? caloTheme.palette.neutral300 : caloTheme.palette.primary500
                  }}
                />
              }
            >
              Download
            </Button>
            {roles.includes(Permission.CREATE_SUBSCRIPTION) && (
              <Button
                data-test="createSubscriptionButton"
                variant="outlined"
                aria-label="create-subscription-list"
                sx={{
                  textTransform: 'none',
                  height: '45px',
                  ml: 2,
                  lineHeight: '17px',
                  fontWeight: 600,
                  fontSize: '14px',
                  borderRadius: '8px',
                  padding: '14px 20px 14px 20px',
                  color: caloTheme.palette.primary500,
                  borderColor: caloTheme.palette.primary500,
                  '&:hover': {
                    backgroundColor: caloTheme.palette.primary100,
                    borderColor: caloTheme.palette.primary500
                  },
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    justifyItems: 'center',
                    margin: 'auto',
                    marginTop: 4,
                    width: 'auto'
                  }
                }}
                onClick={() => history.push(Routes.newSubscription)}
                startIcon={'+'}
              >
                Create Subscription
              </Button>
            )}
          </Stack>
        </Box>
      </Card>
      {oldView ? (
        history.push(Routes.subscriptions)
      ) : (
        <Card
          variant="outlined"
          sx={{
            border: 'none',
            borderRadius: '8px',
            paddingBottom: '4px',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
        >
          {isTablet || isMobile ? (
            <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
              <Typography
                variant="h6"
                sx={{
                  pb: 2,
                  fontFamily: caloTheme.typography.fontFamily,
                  fontWeight: 600,
                  lineHeight: '23px'
                }}
              >
                Subscription List
              </Typography>
              {isLoading ? (
                <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                  <CaloLoader />
                </Stack>
              ) : (
                <>
                  {!data || data?.data.length === 0 ? (
                    <span className="absolute w-11/12 text-2xl mt-6 text-center font-bold text-gray-400 ">NO SUBSCRIPTION</span>
                  ) : (
                    <>
                      {data.data.map((subscription) => (
                        <SubscriptionListMobile subscription={subscription} />
                      ))}
                      {data && (
                        <TablePagination
                          page={page}
                          rowsPerPage={50}
                          count={(data && data.meta.total) || 0}
                          key="subscription-list-row"
                          onPageChange={(value) => setPage(value)}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
          ) : (
            <>
              <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
                <Typography
                  variant="h6"
                  sx={{
                    pb: 2,
                    fontFamily: caloTheme.typography.fontFamily,
                    fontWeight: 600,
                    lineHeight: '23px'
                  }}
                >
                  Subscription List
                </Typography>

                {isLoading ? (
                  <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                    <CaloLoader />
                  </Stack>
                ) : (
                  <>
                    <Table
                      sx={{
                        marginY: '4px',
                        minHeight: '120px',
                        overflow: 'auto',
                        width: '100%',
                        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                          flexDirection: 'column'
                        }
                      }}
                    >
                      <TableHead
                        style={{ borderRadius: '8px' }}
                        sx={{
                          backgroundColor: caloTheme.palette.neutral50,
                          color: 'black',
                          flexWrap: 0,
                          justifyContent: 'space-between',
                          width: '100%',
                          borderRadius: '8px'
                        }}
                      >
                        <TableRow
                          sx={{
                            backgroundColor: caloTheme.palette.neutral50,
                            color: 'black',
                            width: '100%',
                            flexWrap: 0,
                            justifyContent: 'space-between'
                          }}
                        >
                          <StyledTableCell style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', width: '64px' }}>
                            Name
                          </StyledTableCell>
                          <StyledTableCell>Phone</StyledTableCell>
                          <StyledTableCell>Email</StyledTableCell>
                          <StyledTableCell>Balance</StyledTableCell>
                          <StyledTableCell>Payment Balance</StyledTableCell>
                          <StyledTableCell>First Day</StyledTableCell>
                          <StyledTableCell>Last Day</StyledTableCell>
                          <StyledTableCell>Frequency</StyledTableCell>
                          <StyledTableCell style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}>
                            Kitchen
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {data && data.data.length === 0 ? (
                        <span className="absolute w-11/12 text-2xl mt-6 text-center font-bold text-gray-400 ">
                          NO SUBSCRIPTION
                        </span>
                      ) : (
                        <TableBody>
                          {data &&
                            data.data.map((subscription) => (
                              <SubscriptionRow key={subscription.id} subscription={subscription} />
                            ))}
                        </TableBody>
                      )}
                    </Table>
                  </>
                )}
              </Box>
              {data && (
                <Pagination
                  isHidden={data.data.length === 0}
                  limit={data.meta.limit}
                  total={data.meta.total}
                  page={page}
                  onChange={setPage}
                />
              )}
              <Settings onFilter={setFilters} filters={filters} />
            </>
          )}
        </Card>
      )}
    </>
  );
};
export default SubscriptionList2;
