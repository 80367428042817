import { Subscription } from '@calo/dashboard-types';
import { DeliveryTime, Kitchen } from '@calo/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { format, setDay } from 'date-fns/fp';
import { startCase } from 'lodash-es';
import { useState } from 'react';
import styles from './styles';

interface UserPlanDetailsProps {
  selectedCustomer: Subscription & {
    remainingDays: number;
    expectedLastDeliveryDay: string;
    ratings: any[];
    magicPrice?: boolean;
  };
}

const UserPlanDetails = ({ selectedCustomer }: UserPlanDetailsProps) => {
  const [headerUserPlanOpen, setHederUserPlanOpen] = useState<boolean>(false);

  const handleRenderText = (type: string, text: string) => {
    return (
      <Stack sx={{ my: '8px', ml: '1px' }}>
        <Typography variant="caption" sx={{ ...styles.typeText }} display={headerUserPlanOpen ? 'flex' : 'none'}>
          {type}
        </Typography>
        <Typography variant="caption" sx={{ ...styles.valueText }} display={headerUserPlanOpen ? 'flex' : 'none'}>
          {text}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'space-between'}
        onClick={() => setHederUserPlanOpen(!headerUserPlanOpen)}
      >
        <Typography style={{ ...styles.titleText }}>User Plan</Typography>
        {headerUserPlanOpen ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
      </Box>
      <Box style={{ marginTop: '4px', display: headerUserPlanOpen ? 'flex' : 'none', flexDirection: 'column' }}>
        <Stack>
          <Typography sx={{ ...styles.subInfoText }} display={headerUserPlanOpen ? 'flex' : 'none'}>
            Subscription Details
          </Typography>
        </Stack>
        {handleRenderText('Package', selectedCustomer?.plan.id || '')}
        {handleRenderText('Diet', selectedCustomer?.plan?.dietType || '')}
        {handleRenderText('Frequency', selectedCustomer?.plan.frequency || '----')}
        {handleRenderText(
          'Delivery Time',
          selectedCustomer?.deliveryTime === DeliveryTime.evening
            ? `${startCase(selectedCustomer?.deliveryTime)} (6-10 PM) `
            : selectedCustomer?.kitchen === Kitchen.GB1
              ? `${startCase(selectedCustomer?.deliveryTime)} (2-6 AM)`
              : `${startCase(selectedCustomer?.deliveryTime)} (7-11 AM)` || '---'
        )}
        {handleRenderText('Cycle Starting on', format('dd/MM/yyyy')(new Date(selectedCustomer?.cycleStartedAt)) || '----')}
        {handleRenderText('First Day', format('dd/MM/yyyy')(new Date(selectedCustomer?.startedAt)) || '----')}
        {handleRenderText(
          'Last Delivery Date',
          format('dd/MM/yyyy')(new Date(selectedCustomer?.expectedLastDeliveryDay)) || '----'
        )}
        {handleRenderText(
          'Expected Last Delivery Date',
          format('dd/MM/yyyy')(new Date(selectedCustomer?.expectedLastDeliveryDay)) || ''
        )}
        <Divider
          sx={{
            strokeWidth: '1px',
            color: caloTheme.palette.neutral100,
            height: '2px',
            borderWidth: 0,
            alignSelf: 'stretch'
          }}
        />
        <Stack>
          <Typography sx={{ ...styles.subInfoText }} display={headerUserPlanOpen ? 'flex' : 'none'}>
            Delivery Days
          </Typography>
          <Stack sx={{ ...styles.deliveryDaysStack }}>
            {selectedCustomer.deliveryDays.map((day: any) => (
              <Box sx={{ ...styles.deliveryDaysBox }}>
                <Grid sx={{ ...styles.grid }}>
                  <Typography sx={{ ...styles.gridText }}>
                    <p className="mx-2">{format('EEE')(setDay(day)(Date.now()))}</p>
                  </Typography>
                </Grid>
              </Box>
            ))}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
export default UserPlanDetails;
