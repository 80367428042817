import { Map } from '@calo/dashboard-types';
import { AddressType, Country, DeliveryAddress, DeliveryTime, Kitchen, NewDeliveryAddress } from '@calo/types';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { Alert, AlertTitle, Box, Button as NewButton } from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { compact, debounce, flatten, isNull, omit, pick, uniq } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { ActionMeta, InputActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import { toast } from 'react-toastify';

import { getRecord } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Input } from 'components';
import { AddressViewMode } from 'lib/enums';
import { findPolygon, getCenteredCountry } from 'lib/helpers';
import { AddressService } from 'services';
import Button from '../Button';
import TextArea from '../TextArea';
import AddressTypeRow from './AddressTypeRow';
import useAddressPickerForm from './useAddressPickerForm';

interface AddressPickerProps {
  label: string;
  country: Country;
  onCancel: () => void;
  onDelete?: () => any;
  viewMode?: AddressViewMode;
  onPick: (values: NewDeliveryAddress) => any;
  deliveryAddress?: Partial<DeliveryAddress> | null;
  kitchen: Kitchen;
  time: DeliveryTime;
}

const AddressPicker = ({ label, country, viewMode, onPick, deliveryAddress }: AddressPickerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [linkLocation, setLinkLocation] = useState<string>('');
  const [mode, setMode] = useState(viewMode || AddressViewMode.map);
  const [options, setOptions] = useState<any[]>([]);
  const [markerInDeliveryZone, setMarkerInDeliveryZone] = useState<boolean>(true);
  const [polys, setPolys] = useState<any>([]);

  useQuery([`${country}/map/default/kitchen/all`], getRecord, {
    suspense: false,
    retry: false,
    onError: console.error,
    onSuccess: (data) => {
      const maps = data as Map[];
      const concatenatedData = maps.map((obj) => {
        const morningValue = obj.morning || [];
        const eveningValue = obj.evening || [];
        return compact(uniq([...morningValue, ...eveningValue]));
      });
      setPolys(() => concatenatedData);
    }
  });

  const handleSearch = useRef(
    debounce((text: string, { action }: InputActionMeta) => {
      if (action === 'input-change') {
        setIsLoading(true);
        AddressService.autocompleteAddress(text, {})
          .then((data) => setOptions(data))
          .finally(() => setIsLoading(false));
      }
    }, 600)
  );

  const handleMapClick = async (e: google.maps.MapMouseEvent) => {
    setValues({
      ...values,
      lat: e.latLng!.lat(),
      lng: e.latLng!.lng()
    });
    setMarkerInDeliveryZone(!isNull(findPolygon(e.latLng!.lat(), e.latLng!.lng(), flatten(polys))));
  };

  const onMarkerSet = async () => {
    const address = await AddressService.getAddress(values.lat!, values.lng!);
    if (address.country === country) {
      setValues({
        ...pick(values, ['lat', 'lng', 'type']),
        ...(address as NewDeliveryAddress),
        type: values.type as any
      });
      setMode(AddressViewMode.form);
    } else {
      toast(`the location is not in ${country}`, { type: 'error', autoClose: 2000 });
    }
  };

  const { values, isSubmitting, dirty, isValid, handleChange, handleBlur, setValues, submitForm, setFieldValue } =
    useAddressPickerForm(onPick, omit(deliveryAddress || {}, ['id', 'createdAt', 'updatedAt']) as NewDeliveryAddress);

  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${values.lat},${values.lng}`;

  const handleSelect = async ({ value }: OnChangeValue<any, false>, { action }: ActionMeta<any>) => {
    if (action === 'select-option') {
      AddressService.getPlace(value).then((place) => {
        setValues({
          ...values,
          placeId: value,
          lat: place.geometry!.location!.lat(),
          lng: place.geometry!.location!.lng()
        });
      });
    }
  };

  useEffect(() => {
    if (linkLocation) {
      const url = linkLocation.split(',');
      const lat = +url[0];
      const lng = +url[1];
      setValues({
        ...values,
        lat: lat,
        lng: lng
      });
      setMarkerInDeliveryZone(!isNull(findPolygon(lat, lng, flatten(polys))));
    }
  }, [linkLocation]);

  useEffect(() => {
    if (country) {
      let toUpdate: any = {
        country
      };
      if (values.country !== country || !values.lat || !values.lng) {
        toUpdate = {
          ...toUpdate,
          lat: getCenteredCountry(country).lat,
          lng: getCenteredCountry(country).lng
        };
      }
      setValues({
        ...values,
        ...toUpdate
      });
    }
  }, [country]);

  const copyGoogleMapsLinkToClipboard = () => {
    navigator.clipboard.writeText(googleMapsLink);
  };

  if (mode === AddressViewMode.map) {
    return (
      <>
        <section className="section is-title-bar -mx-8 -mt-10">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <p className="uppercase -mb-4 font-roboto text-4xl">{label}</p>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <p className="uppercase -mb-4 font-roboto text-4xl">1/2</p>
              </div>
            </div>
          </div>
        </section>
        <div className="flex-grow flex-shrink-0 flex items-center mt-4">
          <div className="w-full">
            <Input
              label="Coordinates"
              className="rounded-lg"
              onChange={(data) => setLinkLocation(data.target.value)}
              placeholder="31.9719, 35.8492"
            />
            <Select
              value={{
                value: values.placeId,
                label: AddressService.display(values as NewDeliveryAddress)
              }}
              onChange={handleSelect}
              // inputValue={values.address}
              onInputChange={handleSearch.current as any}
              // cacheOptions={true}
              options={options}
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              placeholder="Search"
              isLoading={isLoading}
            />
          </div>
        </div>
        {isLoading ? (
          <CaloLoader />
        ) : (
          <Box
            sx={{
              border: markerInDeliveryZone ? 0 : 3,
              borderColor: markerInDeliveryZone ? caloTheme.palette.primary500 : caloTheme.palette.red,
              mt: 1
            }}
          >
            {!markerInDeliveryZone && (
              <Alert severity="error">
                <strong>Location Outside Delivery Area</strong>
              </Alert>
            )}
            <GoogleMap
              mapContainerClassName="w-full h-454"
              center={{ lat: values.lat!, lng: values.lng! }}
              zoom={13}
              options={{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
              }}
              onClick={handleMapClick}
            >
              <Marker position={{ lat: values.lat!, lng: values.lng! }} draggable onDragEnd={handleMapClick} />
            </GoogleMap>
          </Box>
        )}
        <Button
          className="mt-4"
          fluid
          primary
          content="Confirm Pin Location"
          disabled={!values.lat || !values.lng || !markerInDeliveryZone}
          onClick={onMarkerSet}
        />
      </>
    );
  }

  return (
    <>
      <section className="section is-title-bar -mx-8 -mt-10">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="uppercase -mb-4 font-roboto text-4xl">{label}</p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <p className="uppercase -mb-4 font-roboto text-4xl">2/2</p>
            </div>
          </div>
        </div>
      </section>
      <div className="py-8 overflow-y-auto">
        <div className="flex-col mb-4">
          <img
            className="w-full mx-auto rounded-lg"
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${values.lat},${values.lng}
            &zoom=11&size=600x150&maptype=roadmap
            &markers=color:green|${values.lat},${values.lng}
            &key=AIzaSyBRXQIB947Dfvs_384Q5K4eR04mt4K-zQQ`}
            alt=""
          />
          <Button
            className="flex mt-4 w-full mx-auto rounded"
            primary
            outlined
            content="Edit Location Pin"
            onClick={() => setMode(AddressViewMode.map)}
          />
        </div>

        <div className="flex-col">
          <div className="mb-4">
            <Alert severity="info">
              <AlertTitle>Coordinates</AlertTitle>
              Lat: {values.lat}, Lng: {values.lng}
              <br />
              <a href={googleMapsLink} target="_blank" rel="noreferrer">
                Google Maps Link
              </a>
              <NewButton onClick={() => copyGoogleMapsLinkToClipboard()}>
                <CopyAllIcon />
              </NewButton>
            </Alert>
          </div>
        </div>

        <div className="flex-col">
          <div className="flex flex-row justify-center mb-4 cursor-pointer">
            <AddressTypeRow
              type={AddressType.home}
              onSelect={(t) => setFieldValue('type', t)}
              selected={values.type === AddressType.home}
              name="Home"
            />
            <span className="w-full ml-2">
              <AddressTypeRow
                type={AddressType.office}
                onSelect={(t) => setFieldValue('type', t)}
                selected={values.type === AddressType.office}
                name="Office"
              />
            </span>
          </div>
          <div className="flex flex-row justify-center mb-4">
            <span className="w-full ml-2 rounded-xl">
              {values.type === AddressType.home ? (
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.apartment}
                  type="text"
                  name="apartment"
                  label="Apartment"
                />
              ) : (
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.office}
                  type="text"
                  name="office"
                  label="Office"
                />
              )}
            </span>
            <span className="w-full ml-2 rounded-xl">
              <Input onChange={handleChange} onBlur={handleBlur} value={values.building} name="building" label="Building" />
            </span>
          </div>
          <div className="flex flex-row justify-center mb-4">
            <span className="w-full ml-2 rounded-xl">
              <Input onChange={handleChange} onBlur={handleBlur} value={values.street} name="street" label="Street" />
            </span>
            <span className="w-full ml-2 rounded-xl">
              <Input onChange={handleChange} onBlur={handleBlur} value={values.district} name="district" label="Area" />
            </span>
          </div>
          <div className="flex flex-row justify-center mb-4 ml-2">
            <span className="w-full">
              <TextArea
                rows={1}
                label="Notes"
                placeholder="Notes"
                name="notes"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.notes}
              />
            </span>
          </div>

          <Button
            fluid
            primary
            content="Save Address"
            className="mx-auto"
            disabled={!dirty || !isValid || isSubmitting}
            onClick={submitForm}
          />
        </div>
      </div>
    </>
  );
};

export default AddressPicker;
