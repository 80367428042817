export const foodListSelection = {
  meta: {
    limit: true,
    total: true
  },
  data: {
    country: true,
    kitchen: true,
    id: true,
    name: {
      en: true
    },
    foodTags: {
      key: true,
      value: true
    },
    slug: true,
    size: true,
    tags: true,
    type: true,
    totalRating: true,
    averageRating: true,
    numberOfRatings: true,
    menuLabels: {
      label: true,
      startDay: true,
      endDay: true
    },
    lastUsedOnMenu: true,
    usedOnMenu: {
      date: true,
      type: true,
      week: true
    },
    components: {
      count: true,
      id: true,
      quantity: true,
      name: {
        ar: true,
        en: true
      },
      macros: {
        cal: true,
        carbs: true,
        fat: true,
        protein: true,
        fiber: true
      },
      cost: true
    },
    purchasingCost: true,
    metadata: true
  }
};
