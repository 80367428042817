import { format, parseISO } from 'date-fns/fp';

import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { Log } from 'lib/interfaces';

interface LogsCardRowMobileProps {
  row: Log;
}
const LogsCardRowMobile = ({ row }: LogsCardRowMobileProps) => {
  //check
  return (
    <Card key={row.id} sx={{ width: 'full', mb: 2 }}>
      <CardHeader
        title={
          <Stack sx={{ width: 'full', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
            <Typography>{row.actor}</Typography>
            <Typography>{format('dd/MM/yyyy hh:mm a')(parseISO(row.createdAt))}</Typography>
          </Stack>
        }
      />
      <CardContent>
        <Stack sx={{ color: caloTheme.palette.neutral900, my: 1, width: 'full' }}>
          Action
          <Typography sx={{ my: 1, width: 'full' }}>{row.message}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
export default LogsCardRowMobile;
