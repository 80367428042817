import { useEffect, useMemo, useRef, useState } from 'react';

import { parseISO } from 'date-fns';
import { flatten, sortBy, startCase } from 'lodash-es';
import DateTime from 'react-datetime-picker';
import { useQuery } from 'react-query';
import AsyncSelect from 'react-select/async';

import { CodeChannel, CodeChannelCategory, CouponMode, CouponRule, CouponRuleType, CreateCouponReq } from '@calo/dashboard-types';
import { Country, CouponType, Currency, PlanType, SubscriptionFrequency } from '@calo/types';

import { getListWithParams } from 'actions';
import { Button, Card, Input, Modal, Select } from 'components';
import { ModalRef } from 'components/Modal/types';
import {
  checkCountryCurrency,
  handleSearch,
  handleValidPhoneNumber,
  hasValidCharactersForLanguage,
  isValidEmail
} from 'lib/helpers';
import AddRulesModal from '../AddRulesModel';
import CouponRules from '../CouponRules';
import CreditCardList from '../CreditCardList';
import ExclusiveList from '../ExclusiveList';
import useCouponForm from './useCouponForm';

interface CouponFormProps {
  onSubmit: (value: Omit<CreateCouponReq, 'id'>) => Promise<void>;
}

const CouponForm = ({ onSubmit }: CouponFormProps) => {
  const addRulesRef = useRef<ModalRef>();
  const addExclusiveMemberRef = useRef<ModalRef>();
  const addCreditCardListRef = useRef<ModalRef>();
  const [rules, setRules] = useState<CouponRule>({
    type: undefined!,
    value: undefined!
  });
  const [exclusiveMember, setExclusiveMember] = useState<boolean>(false);
  const [frequency, setFrequency] = useState<CouponRule>({
    type: CouponRuleType.SUBSCRIPTION_FREQUENCY,
    value: '' as any
  });
  const [exList, setExList] = useState<any>({
    type: undefined!,
    value: [] as any[]
  });
  const [newMember, setNewMember] = useState<any>('');
  const [newCreditCard, setNewCreditCard] = useState<any>();
  const [exType, setExType] = useState<string>('Add List');
  const [planType, setPlanType] = useState<CouponRule>({
    type: CouponRuleType.SUBSCRIPTION_PLAN,
    value: Object.values([
      PlanType.full,
      PlanType.skipBreakfast,
      PlanType.skipDinner,
      PlanType.businessLunch,
      PlanType.custom
    ]) as any
  });
  const [areMandatoryRulesSet, setAreMandatoryRulesSet] = useState<boolean>(false);
  const [searchName, setSearchName] = useState<string>('');
  const [langAlert, setLangAlert] = useState({ AR: false, EN: false });
  const [countryRuleData, setCountryRuleData] = useState<any>(Country.BH);

  const { handleSubmit, values, handleChange, handleBlur, isSubmitting, isValid, dirty, setFieldValue, setValues } =
    useCouponForm(onSubmit);

  const { data } = useQuery<any, Error, { data: any[] }>(
    ['subscriptions/search', { query: searchName, page: 0 }],
    getListWithParams,
    {
      suspense: false
    }
  );

  const subscriptionsName = data?.data;

  const options = useMemo(
    () =>
      sortBy(subscriptionsName, (f) => `${f.name}`).map((user: any) => ({
        value: user.id,
        name: user.name,
        label: `${user.name}, ${user.phoneNumber}, ${user.email}`
      })),
    [subscriptionsName]
  );

  const filterOptions = (inputValue: string) => options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));

  const loadOptions = (inputValue: any, callback: any) => {
    callback(filterOptions(inputValue));
  };

  const handleSubmitValues = () => {
    frequency.value && values.rules.push(frequency);
    Object.values(planType.value).length < 5 && values.rules.push(planType);
    exList.type && exList.value.length > 0 && values.rules.push(exList);
    if (exType === 'Payment Card' && values.hint) {
      values.hint.en?.length > 0 && setFieldValue('hint.en', values.hint.en);
      values.hint.ar?.length > 0 && setFieldValue('hint.ar', values.hint.ar);
    }
    setFieldValue('mode', exType === 'Payment Card' ? CouponMode.CREDIT : CouponMode.DISCOUNT);
    handleSubmit();
  };

  const handleNewMember = (x: any) => {
    setExList({ ...exList, value: flatten([...exList.value, x]) });
    addExclusiveMemberRef.current?.close();
    setNewMember(undefined);
  };

  const handleNewCreditCard = (x: any) => {
    setExList({ ...exList, value: [...exList.value, x] });
    addCreditCardListRef.current?.close();
    setNewCreditCard(undefined);
  };

  const handleRemoveCreditCard = (userValue: string) => {
    const index = flatten(exList.value).indexOf(userValue);
    exList.value.splice(index, 1);
    setExList({ ...exList, value: flatten(exList.value) });
  };

  const handleRemoveUser = (userValue: string) => {
    const index = flatten(exList.value).indexOf(userValue);
    exList.value.splice(index, 1);
    setExList({ ...exList, value: flatten(exList.value) });
  };

  useEffect(() => {
    !exclusiveMember && setExType('Add List');
  }, [exclusiveMember]);

  useEffect(() => {
    const countryRule = values.rules.find((r) => r.type === CouponRuleType.USER_COUNTRY);
    if (countryRule) {
      setCountryRuleData(countryRule.value);
      setFieldValue('currency', checkCountryCurrency(countryRule.value as Country));
    }
  }, [values.rules]);

  const handleCharacters = (letter: string, lang: string) => {
    switch (lang) {
      case 'AR':
        hasValidCharactersForLanguage(letter, lang)
          ? setLangAlert({ ...langAlert, AR: false })
          : setLangAlert({ ...langAlert, AR: true });
        return setFieldValue('hint.ar', hasValidCharactersForLanguage(letter, 'AR') ? letter : '');
      case 'EN':
        hasValidCharactersForLanguage(letter, lang)
          ? setLangAlert({ ...langAlert, EN: false })
          : setLangAlert({ ...langAlert, EN: true });
        return setFieldValue('hint.en', hasValidCharactersForLanguage(letter, 'EN') ? letter : '');
    }
  };

  return (
    <div className=" p-6 pt-12">
      <Input
        label="Code"
        value={values.code}
        name="code"
        onChange={(e) => setFieldValue('code', e.target.value.replace(/ /g, '').toUpperCase())}
        onBlur={handleBlur}
      />
      <div className="field">
        <label className="label">Expire At</label>
        <div className="control is-clearfix">
          <DateTime
            className="w-1/6"
            onChange={(date: Date) => setFieldValue('expiresAt', date && date.toISOString())}
            value={values.expiresAt ? parseISO(values.expiresAt) : undefined}
            renderNumbers={true}
          />
        </div>
      </div>
      <Card title="Add Rules" className="my-2">
        <div className="float-right -mt-8">
          <Button icon="fas fa-plus" onClick={() => addRulesRef.current?.open()} />
        </div>

        <CouponRules
          rulesValues={values.rules.filter(
            (r) =>
              r.type !== CouponRuleType.SUBSCRIPTION_PLAN &&
              r.type !== CouponRuleType.SUBSCRIPTION_FREQUENCY &&
              r.type !== CouponRuleType.EMAIL_EXISTS &&
              r.type !== CouponRuleType.PHONE_EXISTS &&
              r.type !== CouponRuleType.EMAIL_DOMAIN &&
              r.type !== CouponRuleType.CREDIT_CARD_PREFIX
          )}
          values={values}
          setValues={(s) => setValues(s)}
          addRulesRef={addRulesRef}
          setRules={(e: any) => setRules({ type: e.type, value: e.value })}
        />
        {!values.rules.find((r) => r.type === CouponRuleType.USER_ACTION) && (
          <p className="text-red-500 text-xs">User action rule is needed</p>
        )}
      </Card>

      <label className="b-checkbox checkbox">
        <input
          type="checkbox"
          value="lorem"
          checked={exclusiveMember}
          onChange={() => {
            setExclusiveMember(!exclusiveMember);
            !exclusiveMember && setExType('Add List');
          }}
        />
        <span className="check is-primary"> Rules for Exclusive Members </span>
      </label>

      {exclusiveMember && (
        <div>
          <Select
            value={exType}
            onChange={(data: any) => {
              setExType(data.value);
              setExList({ ...exList, value: undefined });
            }}
            options={[
              {
                label: 'Add List',
                value: 'Add List'
              },
              {
                label: 'Email domain',
                value: 'Email domain'
              },
              {
                label: 'Payment Card',
                value: 'Payment Card'
              }
            ]}
          />
          {exType === 'Add List' && (
            <ExclusiveList
              exList={exList}
              addExclusiveMemberRef={addExclusiveMemberRef}
              setExList={setExList}
              handleRemoveUser={handleRemoveUser}
            />
          )}

          {exType === 'Email domain' && (
            <div className="mb-4">
              <Input
                label="Domain"
                type="email"
                placeholder="@calo.app"
                value={exList.value}
                onChange={(data) =>
                  setExList({
                    type: CouponRuleType.EMAIL_DOMAIN,
                    value: data.target.value
                  })
                }
              />
            </div>
          )}

          {exType === 'Payment Card' && (
            <CreditCardList
              exList={exList}
              setExList={(v) => setExList(v)}
              addCreditCardListRef={addCreditCardListRef}
              handleRemoveUser={handleRemoveCreditCard}
            />
          )}
        </div>
      )}

      {exType === 'Payment Card' && (
        <div className="flex w-full">
          <span className="w-1/2">
            <label className="flex label"> Hint Text EN</label>
            <span>
              Use
              <input
                name="hint.en"
                value={values.hint?.en}
                onChange={(data: any) => handleCharacters(data.target.value, 'EN')}
                onBlur={handleBlur}
                className={values.hint?.en ? 'w-1/2 border-b-2 border-blue-300' : ' border-b-2 border-blue-300'}
                maxLength={35}
              />
              to get your bonus credits
            </span>
            {langAlert.EN && <p className="text-red-400 text-xs my-2"> letters should be in english only</p>}
            {values.hint?.en?.trim().length === 0 && <p className="text-red-400 text-xs my-2"> Hint can not be empty</p>}
            <div className="flex mb-4">
              <span className="fas fa-info-circle flex">
                <p className="text-gray-300 text-xs ml-2"> Use ex: NBB credit card to get your bonus credits </p>
              </span>
            </div>
          </span>
          <span className="w-1/2">
            <label className="label flex flex-row">Hint Text AR</label>
            <span>
              {' '}
              استخدم
              <input
                maxLength={35}
                name="hint.ar"
                value={values.hint?.ar}
                onChange={(data: any) => handleCharacters(data.target.value, 'AR')}
                className={values.hint?.ar ? 'w-1/2 border-b-2 border-blue-300' : ' border-b-2 border-blue-300'}
              />
              لتحصل على رصيد إضافي كمكافأة{' '}
            </span>
            {langAlert.AR && <p className="text-red-400 text-xs mb-2"> letters should be in arabic only</p>}
            {values.hint?.ar?.trim().length === 0 && <p className="text-red-400 text-xs my-2"> Hint can not be empty</p>}
            <div className="flex mb-4">
              <span className="fas fa-info-circle flex">
                <p className="text-gray-300 text-xs ml-2"> استخدم على سبيل المثال: بطاقة ائتمان لتحصل على رصيد إضافي كمكافأة </p>
              </span>
            </div>
          </span>
        </div>
      )}

      {exType === 'Payment Card' ? (
        <Select
          label="Type"
          value={values.type}
          onChange={(data: any) => setFieldValue('type', data.value)}
          options={[
            {
              value: CouponType.FIXED,
              label: 'Extra Credit Amount'
            },
            {
              value: CouponType.PERCENTAGE,
              label: 'Extra Credit Percentage'
            }
          ]}
        />
      ) : (
        <Select
          label="Type"
          value={values.type}
          onChange={(data: any) => setFieldValue('type', data.value)}
          options={
            values.rules.some((r) => r.type === CouponRuleType.USER_COUNTRY)
              ? Object.values(CouponType).map((t) => ({
                  value: t,
                  label: CouponType[t]
                }))
              : [{ value: CouponType.PERCENTAGE, label: CouponType.PERCENTAGE }]
          }
        />
      )}

      <Select
        label="Frequency"
        value={frequency.value}
        isMulti={true}
        onChange={(data: any) =>
          setFrequency({
            type: CouponRuleType.SUBSCRIPTION_FREQUENCY,
            value: data.map((val) => val.value) as any
          })
        }
        options={Object.values(SubscriptionFrequency).map((t) => ({
          value: t,
          label: t
        }))}
      />

      <Select
        label="Channel"
        value={values.channel}
        className="w-96"
        onChange={(data: any) => setFieldValue('channel', data.value)}
        options={Object.values(CodeChannel).map((channel) => ({
          value: channel,
          label: startCase(channel)
        }))}
      />
      <Select
        label="Channel Category (Optional)"
        value={values.channelCategory}
        className="w-96"
        onChange={(data: any) => setFieldValue('channelCategory', data.value)}
        options={Object.values(CodeChannelCategory).map((channelCategory) => ({
          value: channelCategory,
          label: startCase(channelCategory)
        }))}
      />
      <Select
        isMulti
        label="Meal package"
        value={planType.value}
        onChange={(data: any) =>
          setPlanType({
            ...planType,
            value: data.map((val) => val.value) as any
          })
        }
        options={Object.values(PlanType).map((plan) => ({
          value: plan,
          label: startCase(plan)
        }))}
      />

      <Select
        label="Currency"
        value={values.currency}
        onChange={(data: any) => setFieldValue('currency', data.value)}
        options={Object.values(Currency).map((m) => ({
          value: m,
          label: Currency[m]
        }))}
        isDisabled={!!flatten(values.rules).find((r) => r.type === CouponRuleType.USER_COUNTRY)}
      />

      <Input label="Amount" value={values.amount} name="amount" onChange={handleChange} onBlur={handleBlur} type="number" />

      <label className="label"> Assigned to</label>
      <div className="mb-4">
        <AsyncSelect
          loadOptions={loadOptions}
          onInputChange={(data: string, action: any) => handleSearch({ text: data, action, name: setSearchName })}
          onChange={(data: any) => {
            setSearchName(data.name);
            setFieldValue('assignee', data.value);
          }}
        />
      </div>
      <Button
        className="mx-auto"
        type="submit"
        primary
        loading={isSubmitting}
        disabled={
          !dirty ||
          !isValid ||
          isSubmitting ||
          !areMandatoryRulesSet ||
          Object.values(planType.value).length === 0 ||
          (exType === 'Payment Card' && !(values.hint?.en?.trim().length !== 0 && values.hint?.ar?.trim().length !== 0))
        }
        content="Save"
        onClick={() => handleSubmitValues()}
      />

      <AddRulesModal
        addRulesRef={addRulesRef}
        rules={rules}
        setRules={(e) => setRules(e)}
        setFieldValue={setFieldValue}
        values={values}
        setActionRules={(e) => setAreMandatoryRulesSet(e)}
      />

      <Modal
        ref={addExclusiveMemberRef}
        onClose={() => {
          addExclusiveMemberRef.current?.close();
          setNewMember(undefined);
        }}
      >
        <div className="flex flex-col">
          <Input
            label={exList.type === CouponRuleType.PHONE_EXISTS ? 'Add phone Number' : 'Add an Email'}
            value={newMember ? newMember : ''}
            placeholder={
              CouponRuleType.PHONE_EXISTS ? (countryRuleData === Country.BH ? '+973XXXXXXXX' : '+966XXXXXXXXX') : 'Abcde@calo.app'
            }
            pattern={exList.type === CouponRuleType.PHONE_EXISTS ? '[0-9]*' : 'text'}
            onChange={(data: any) => setNewMember(data.target.value)}
            onBlur={handleBlur}
            error={
              exList.type === CouponRuleType.PHONE_EXISTS
                ? !handleValidPhoneNumber(newMember ? newMember : '', countryRuleData) && newMember?.length >= 1
                : !isValidEmail(newMember) && newMember?.length > 1
            }
          />
          {exList.type === CouponRuleType.EMAIL_EXISTS
            ? !isValidEmail(newMember) &&
              newMember?.length > 1 && (
                <p className="text-red-400 text-xs -mt-2">Email format is not correct, a good format is Abcde@calo.app</p>
              )
            : !handleValidPhoneNumber(newMember ? newMember : '', countryRuleData) &&
              newMember?.length >= 1 && (
                <p className="text-red-400 text-xs -mt-2">
                  Phone number format is not correct, a good format is{' '}
                  {countryRuleData === Country.BH ? '+973XXXXXXXX' : '+966XXXXXXXXX'}
                </p>
              )}
          <Button
            warning
            content="Add"
            disabled={
              !newMember || exList.value?.includes(newMember) || exList.type === CouponRuleType.PHONE_EXISTS
                ? !handleValidPhoneNumber(newMember ? newMember : '', countryRuleData)
                : !isValidEmail(newMember)
            }
            onClick={() => handleNewMember(newMember)}
          />
        </div>
      </Modal>

      <Modal
        ref={addCreditCardListRef}
        onClose={() => {
          setNewCreditCard(undefined);
          addRulesRef.current?.close();
        }}
      >
        <div className="flex flex-col">
          <Input
            label="Add card digits"
            value={newCreditCard}
            onChange={(data: any) => setNewCreditCard(data.target.value)}
            onBlur={handleBlur}
            type="number"
          />
          {exList.value?.includes(newCreditCard) && (
            <p className="text-red-500 text-xs mb-2"> Digits already exist in the list</p>
          )}

          <Button
            warning
            content="Add"
            onClick={() => handleNewCreditCard(newCreditCard)}
            disabled={newCreditCard?.length !== 6 || exList.value.includes(newCreditCard)}
          />
        </div>
      </Modal>
    </div>
  );
};
export default CouponForm;
