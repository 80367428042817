import { Affiliate, AffiliateTransactionType } from '@calo/dashboard-types';
import { Box, Button, Card, Divider, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { addMonths, format, isSameMonth, startOfMonth } from 'date-fns/fp';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { Currency } from '@calo/types';
import { payAffiliate } from 'actions/affiliates';
import { caloTheme } from 'assets/images/theme/calo';
import { Button as CaloButton, CaloLoader } from 'components';
import ExcelJS, { Style } from 'exceljs';
import saveAs from 'file-saver';
import { convertCurrency } from 'lib';
import { useUserRoles } from 'lib/hooks';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import AffiliateTransactionRow from './AffiliateTransactionRow/AffiliateTransactionRow';
import useAffiliateTransactions from './useAffiliateTransactions';

interface AffiliateTransactionsListProps {
  affiliates: Affiliate[];
}

const AffiliateTransactionsList = ({ affiliates }: AffiliateTransactionsListProps) => {
  const roles = useUserRoles();
  const { mutateAsync: payAffiliateHook } = useMutation(payAffiliate);
  const { id: affiliateId } = useParams<{ id: string }>();

  const affiliate = affiliates.find((affiliate) => affiliate.id === affiliateId);

  const { transactions, transactionsLoading, hasNextPage, fetchNextPage, refetchAffiliate } = useAffiliateTransactions({
    affiliateId
  });

  const onExport = useCallback(async () => {
    if (transactions.length === 0) {
      toast(`No Transactions Available`, { type: 'error', autoClose: 3000 });
      return;
    }
    const style: Partial<Style> = {
      border: {
        top: { style: 'thick', color: { argb: 'D3D3D3' } },
        left: { style: 'thick', color: { argb: 'D3D3D3' } },
        bottom: { style: 'thick', color: { argb: 'D3D3D3' } },
        right: { style: 'thick', color: { argb: 'D3D3D3' } }
      },
      alignment: {
        horizontal: 'center',
        vertical: 'middle'
      }
    };

    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet(`${affiliate?.name}-AffiliatePayout`, {
      pageSetup: { fitToPage: true, orientation: 'portrait' }
    });

    worksheet.columns = [
      { header: 'Date', width: 24, key: 'date', style: style },
      { header: 'Currency', width: 24, key: 'currency', style: style },
      { header: 'Subscription Price', width: 24, key: 'subPrice', style: style },
      { header: 'Commission', width: 24, key: 'commission', style: style },
      { header: 'Total Commission', width: 24, key: 'totalCommission', style: style },
      { header: "Affiliate's Currency", width: 24, key: 'affiliateCurrency', style: style }
    ];

    let totalPayout = 0.0;
    const affiliateCurrency = affiliate?.currency;
    let comissionInAffiliateCurrency: number;
    const transactionsSortedAscendingly = [...transactions].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    for (const transaction of transactionsSortedAscendingly) {
      comissionInAffiliateCurrency = transaction.commissionInOriginalCurrency ?? 0;
      if (transaction.originalCurrency !== affiliateCurrency) {
        comissionInAffiliateCurrency = convertCurrency(
          comissionInAffiliateCurrency,
          transaction.originalCurrency ?? Currency.BHD,
          affiliateCurrency
        );
      }

      totalPayout += comissionInAffiliateCurrency;
      worksheet.addRow(
        {
          date: transaction.date && format('dd/MM/yyyy HH:MM a')(new Date(transaction.date)),
          currency: transaction.originalCurrency,
          subPrice: (transaction.commissionInOriginalCurrency ?? 0) / ((affiliate?.commissionPercentage ?? 0) * 0.01),
          commission: transaction.commissionInOriginalCurrency,
          totalCommission: totalPayout,
          affiliateCurrency: affiliateCurrency
        },
        ''
      );
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, `${affiliate?.name}_${affiliate?.compensationScheme}` + fileExtension);
  }, [affiliate, transactions]);

  if (!affiliate) {
    return <></>;
  }

  const nextPaymentDate = format('dd/MM/yyyy')(startOfMonth(addMonths(1)(new Date())));
  let earnings = 0;

  for (const transaction of transactions) {
    if (transaction.type === AffiliateTransactionType.redeemed) {
      if (isSameMonth(new Date())(new Date(transaction.date))) {
        const commissionInOriginalCurrency = transaction.commissionInOriginalCurrency;
        const originalCurrency = transaction.originalCurrency;
        if (commissionInOriginalCurrency && originalCurrency) {
          earnings += convertCurrency(commissionInOriginalCurrency, originalCurrency, affiliate?.currency);
        }
      } else {
        break;
      }
    }
  }

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  const handlePayAffiliate = async (transactionId: string) => {
    await payAffiliateHook({
      affiliateId,
      transactionId
    });
    await refetchAffiliate();
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <>
          <Box
            display={'flex'}
            flexDirection="row"
            justifyContent={'space-between'}
            sx={{
              padding: 2,
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                flexDirection: 'column'
              }
            }}
            width="100%"
          >
            <Stack width={'50%'} display={'flex'} flexDirection={'row'} alignItems="start" justifyContent={'space-between'}>
              <Typography
                variant="h3"
                sx={{
                  mt: 'auto',
                  fontWeight: 600,
                  fontSize: '33px',
                  lineHeight: '40px',
                  color: caloTheme.palette.neutral900,
                  fontFamily: caloTheme.typography.fontFamily
                }}
              >
                {affiliate?.name}
              </Typography>
            </Stack>
            <CaloButton onClick={onExport} icon="fas fa-file-export" />
          </Box>
          <Box
            overflow="auto"
            width="100%"
            sx={{
              padding: 2,
              display: 'flex'
            }}
          >
            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px',
                marginX: 2
              }}
            >
              <Typography component={'span'}>Coupon Users:</Typography> {affiliate?.totalUsersAttracted}
            </Typography>

            <Divider orientation="vertical" flexItem />

            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px',
                marginX: 2
              }}
            >
              <Typography component={'span'}>Earnings this month: </Typography>
              {earnings} {affiliate.currency}
            </Typography>

            <Divider orientation="vertical" flexItem />

            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px',
                marginX: 2
              }}
            >
              <Typography component={'span'}>Next Payment:</Typography> {nextPaymentDate ?? 0}
            </Typography>

            <Divider orientation="vertical" flexItem />

            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px',
                marginX: 2
              }}
            >
              <Typography component={'span'}>Email: </Typography>
              {affiliate?.email}
            </Typography>

            <Divider orientation="vertical" flexItem />

            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px',
                marginX: 2
              }}
            >
              <Typography component={'span'}>Phone number: </Typography>
              {affiliate?.phoneNumber}
            </Typography>

            <Divider orientation="vertical" flexItem />

            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px',
                marginX: 2
              }}
            >
              <Typography component={'span'}>Country: </Typography>
              {affiliate?.country}
            </Typography>

            <Divider orientation="vertical" flexItem />

            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px',
                marginX: 2
              }}
            >
              <Typography component={'span'}>Code: </Typography>
              {affiliate?.codeName} ({affiliate?.discountPercentage}%)
            </Typography>
          </Box>

          <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
            {transactionsLoading ? (
              <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                <CaloLoader />
              </Stack>
            ) : (
              <>
                <Table
                  sx={{
                    marginY: '4px',
                    minHeight: '120px',
                    overflow: 'auto',
                    width: '100%',
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      flexDirection: 'column'
                    }
                  }}
                >
                  <TableHead
                    style={{ borderRadius: '8px' }}
                    sx={{
                      backgroundColor: caloTheme.palette.neutral50,
                      color: 'black',
                      flexWrap: 0,
                      justifyContent: 'space-between',
                      width: '100%',
                      borderRadius: '8px'
                    }}
                  >
                    <TableRow
                      sx={{
                        backgroundColor: caloTheme.palette.neutral50,
                        color: 'black',
                        width: '100%',
                        flexWrap: 0,
                        justifyContent: 'space-between'
                      }}
                    >
                      <StyledTableCell>Compensation Type</StyledTableCell>
                      <StyledTableCell style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', width: '64px' }}>
                        Transaction Type
                      </StyledTableCell>
                      <StyledTableCell>User</StyledTableCell>
                      <StyledTableCell>Transaction Date</StyledTableCell>
                      <StyledTableCell>Commission ({affiliate?.commissionPercentage}%)</StyledTableCell>
                      <StyledTableCell>Country</StyledTableCell>
                      <StyledTableCell>Payout Amount</StyledTableCell>
                      <StyledTableCell>Transaction status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {transactions?.length === 0 ? (
                    <span className="absolute w-11/12 text-2xl mt-6 text-center font-bold text-gray-400 ">NO TRANSACTIONS!</span>
                  ) : (
                    <TableBody>
                      {transactions?.map((transaction) => (
                        <AffiliateTransactionRow
                          affiliateTransaction={transaction}
                          affiliateCurrency={affiliate.currency}
                          key={transaction.id}
                          roles={roles}
                          handlePayAffiliate={handlePayAffiliate}
                        />
                      ))}
                    </TableBody>
                  )}
                </Table>
              </>
            )}
          </Box>
          {hasNextPage && (
            <Box display={'flex'} justifyContent="center">
              <Button onClick={() => fetchNextPage()}>Load Older Transactions</Button>
            </Box>
          )}
        </>
      </Card>
    </>
  );
};
export default AffiliateTransactionsList;
