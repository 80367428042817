import { parseISO } from 'date-fns';

import { Subscription } from '@calo/types';
import { Box, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';

interface SubscriptionDatesPlanProps {
  setSelectedDate: (value: Date) => void;
  values: Subscription & { remainingDays: number; expectedLastDeliveryDay: string };
}

const SubscriptionDatesPlan = ({ values, setSelectedDate }: SubscriptionDatesPlanProps) => {
  //check
  return (
    <Box
      sx={{
        display: 'flex',
        direction: 'column',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        sx={{
          ml: 2,
          marginY: 2,
          width: '25%',
          gridColumn: 4,
          height: '85px',
          cursor: 'pointer',
          backgroundColor: caloTheme.palette.primary50,
          color: caloTheme.palette.primary500,
          display: 'flex',
          direction: 'column',
          borderRadius: '12px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'auto'
          }
        }}
        onClick={() => values.cycleStartedAt && setSelectedDate(parseISO(values.cycleStartedAt))}
      >
        <Typography
          sx={{
            m: 1,
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
            mb: 2,
            ml: 2
          }}
        >
          Cycle Starting On
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            fontSize: '19px',
            lineHeight: '23px'
          }}
        >
          {values.cycleStartedAt || '----'}
        </Typography>
      </Stack>
      <Stack
        sx={{
          width: '25%',
          height: '85px',
          ml: 2,
          marginY: 2,
          cursor: 'pointer',
          backgroundColor: caloTheme.palette.secondaryYellow50,
          color: caloTheme.palette.secondaryYellow600,
          display: 'flex',
          direction: 'column',
          borderRadius: '12px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'auto'
          }
        }}
        onClick={() => values.startedAt && setSelectedDate(parseISO(values.startedAt))}
      >
        <Typography
          sx={{
            m: 1,
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
            mb: 2,
            ml: 2
          }}
        >
          First Day
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            fontSize: '19px',
            lineHeight: '23px'
          }}
        >
          {values.startedAt || '----'}
        </Typography>
      </Stack>
      <Stack
        sx={{
          ml: 2,
          marginY: 2,
          width: '25%',
          height: '85px',
          display: 'flex',
          cursor: 'pointer',
          direction: 'column',
          backgroundColor: caloTheme.palette.secondaryBlue50,
          color: caloTheme.palette.secondaryBlue500,
          borderRadius: '12px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'auto'
          }
        }}
        onClick={() => values.expectedLastDeliveryDay && setSelectedDate(parseISO(values.expectedLastDeliveryDay))}
      >
        <Typography
          sx={{
            m: 1,
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
            mb: 2,
            ml: 2
          }}
        >
          Last Delivery Day
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            fontSize: '19px',
            lineHeight: '23px'
          }}
        >
          {values.expectedLastDeliveryDay || '----'}
        </Typography>
      </Stack>
      <Stack
        sx={{
          mx: 2,
          marginY: 2,
          width: '25%',
          height: '85px',
          display: 'flex',
          cursor: 'pointer',
          direction: 'column',
          color: caloTheme.palette.secondaryPurple500,
          backgroundColor: caloTheme.palette.secondaryPurple50,
          borderRadius: '12px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'auto'
          }
        }}
        onClick={() => values.expectedLastDeliveryDay && setSelectedDate(parseISO(values.expectedLastDeliveryDay))}
      >
        <Typography
          sx={{
            m: 1,
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
            mb: 2,
            ml: 2
          }}
        >
          Expected Last Delivery Day
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            fontSize: '19px',
            lineHeight: '23px'
          }}
        >
          {values.expectedLastDeliveryDay || '----'}
        </Typography>
      </Stack>
    </Box>
  );
};
export default SubscriptionDatesPlan;
