import { Permission } from '@calo/dashboard-types';
import { Icon as Iconify } from '@iconify/react';
import { Button, IconButton, TableCell, TableRow, Tooltip, styled, tableCellClasses } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon, ModalRef } from 'components';
import { format, parseISO } from 'date-fns/fp';
import { ComplaintReason } from 'lib/enums';
import { complaintsReasonsHelper } from 'lib/helpers/complaintsReasons';
import { useUserRoles } from 'lib/hooks';
import { startCase } from 'lodash-es';
import React, { useRef } from 'react';
import { CommentViewPopup } from '../ComplaintsPopUps';
import AttachmentViewPopup from '../ComplaintsPopUps/AttachmentViewPopup';
interface ComplaintListRowProps {
  complaintData: any;
  setSelectedComplaint: React.Dispatch<any>;
  setDisplayImage: React.Dispatch<React.SetStateAction<string[]>>;
  attachmentRef: React.MutableRefObject<ModalRef | undefined>;
  editComplaintRef: React.MutableRefObject<ModalRef | undefined>;
  selectedComplaint: any;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  displayImage: string[];
}

const ComplaintListRow = ({
  displayImage,
  attachmentRef,
  handleFileChange,
  selectedComplaint,
  editComplaintRef,
  setDisplayImage,
  setSelectedComplaint,
  complaintData
}: ComplaintListRowProps) => {
  const roles = useUserRoles();
  const commentRef = useRef<ModalRef>();

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      justifyContent: 'center'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'center'
    }
  }));

  const handleComplaintsStatus = (status: string) => {
    switch (status) {
      case 'approved': {
        return (
          <IconButton
            style={{
              backgroundColor: caloTheme.palette.primary100,
              cursor: 'auto'
            }}
          >
            <Iconify
              icon="ic:baseline-check"
              style={{
                color: caloTheme.palette.primary700
              }}
            />
          </IconButton>
        );
      }
      case 'rejected': {
        return (
          <IconButton
            style={{
              backgroundColor: caloTheme.palette.secondaryRed100,
              cursor: 'auto'
            }}
          >
            <Iconify
              icon="ph:x-bold"
              style={{
                color: caloTheme.palette.red
              }}
            />
          </IconButton>
        );
      }
      case 'review': {
        return (
          <IconButton
            style={{
              backgroundColor: caloTheme.palette.secondaryYellow100,
              cursor: 'auto'
            }}
          >
            <Iconify
              icon="ep:loading"
              style={{
                color: caloTheme.palette.secondaryYellow800
              }}
            />
          </IconButton>
        );
      }
      default: {
        return (
          <Button
            variant="text"
            startIcon={<Icon name={'edit'} size={6} />}
            sx={{
              color: caloTheme.palette.neutral900,
              fontFamily: 'Roboto',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '19px'
            }}
            disabled={!roles.includes(Permission.UPDATE_CUSTOMER_COMPLAINT)}
            onClick={() => {
              setSelectedComplaint(complaintData);
              setDisplayImage(complaintData?.attachments);
              editComplaintRef.current?.open();
            }}
          >
            Edit
          </Button>
        );
      }
    }
  };

  const getLabel = (complaintReason: ComplaintReason, complaintType: string): string | undefined => {
    const options = complaintsReasonsHelper.getOptions();
    const complaintOptions =
      options[Object.entries(ComplaintReason).find(([key]) => key === complaintReason)![1] as ComplaintReason];
    if (complaintOptions) {
      const selectedOption = complaintOptions.find((option) => option.value === complaintType);
      return selectedOption?.label;
    }
    return undefined;
  };
  return (
    <>
      <TableRow>
        <StyledTableCell sx={{ textTransform: 'capitalize' }}>{complaintData.user.name}</StyledTableCell>
        <StyledTableCell>
          {Object.entries(ComplaintReason).find(([key]) => key === complaintData.category)
            ? Object.entries(ComplaintReason).find(([key]) => key === complaintData.category)![1]
            : ''}
        </StyledTableCell>
        <StyledTableCell>{getLabel(complaintData.category, complaintData.type)}</StyledTableCell>
        <StyledTableCell sx={{ textTransform: 'capitalize' }}>{startCase(complaintData.meal?.name)}</StyledTableCell>
        <StyledTableCell>{`${startCase(complaintData.createdBy.name)} on ${format('dd-MM-yyyy h:mm a')(parseISO(complaintData.createdAt))}`}</StyledTableCell>
        <StyledTableCell>
          {complaintData.resolvedBy && complaintData.resolvedAt
            ? `${startCase(complaintData.resolvedBy.name)} on ${format('dd-MM-yyyy h:mm a')(parseISO(complaintData.resolvedAt))}`
            : '---'}
        </StyledTableCell>
        <StyledTableCell sx={{ textAlign: 'center' }}>{complaintData.date}</StyledTableCell>
        <StyledTableCell>{startCase(complaintData.deliveryTime)}</StyledTableCell>
        <StyledTableCell>{startCase(complaintData?.driver?.name)}</StyledTableCell>
        <StyledTableCell>{complaintData?.operationsId}</StyledTableCell>
        <StyledTableCell style={{ textAlign: 'center' }}>
          <Button
            variant="text"
            sx={{
              width: '48px',
              color: caloTheme.palette.neutral900,
              fontFamily: 'Roboto',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '19px',
              height: '36px',
              textTransform: 'capitalize',
              textAlign: 'center',
              backgroundColor: caloTheme.palette.neutral50,
              borderRadius: 4
            }}
            onClick={() => {
              setSelectedComplaint(complaintData);
              commentRef.current?.open();
            }}
            disabled={!complaintData.comments || complaintData.comments[complaintData.comments?.length - 1]?.text?.length === 0}
          >
            <Iconify icon="octicon:comment-24" />
          </Button>
        </StyledTableCell>
        <StyledTableCell style={{ textAlign: 'center' }}>
          <Button
            variant="text"
            disabled={!complaintData.attachments.length}
            sx={{
              color: caloTheme.palette.neutral900,
              fontFamily: 'Roboto',
              textAlign: 'center',
              fontWeight: 600,
              fontSize: '16px',
              width: '48px',
              lineHeight: '19px',
              textTransform: 'capitalize',
              backgroundColor: caloTheme.palette.neutral50,
              height: '36px',
              borderRadius: 4
            }}
            onClick={() => {
              setSelectedComplaint(complaintData);
              setDisplayImage(complaintData?.attachments);
              attachmentRef.current?.open();
            }}
          >
            <Iconify icon="mingcute:attachment-line" rotate={1} />
          </Button>
        </StyledTableCell>
        <StyledTableCell style={{ textAlign: 'center' }}>
          <Tooltip title={complaintData.status === 'pending' ? '' : startCase(complaintData.status)} arrow>
            {handleComplaintsStatus(complaintData.status)}
          </Tooltip>
        </StyledTableCell>
      </TableRow>
      <AttachmentViewPopup
        viewType="view"
        attachmentRef={attachmentRef}
        handleFileChange={handleFileChange}
        selectedComplaint={selectedComplaint}
        setSelectedComplaint={setSelectedComplaint}
        displayImage={displayImage}
      />

      <CommentViewPopup
        commentRef={commentRef}
        selectedComplaint={selectedComplaint}
        setSelectedComplaint={setSelectedComplaint}
      />
    </>
  );
};
export default ComplaintListRow;
