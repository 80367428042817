import { useMemo } from 'react';

import { toggleUISettings } from 'actions';
import { Select2, SideDrawer } from 'components';
import { getAccessibleCountries } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { AppState, PerksFilters } from 'lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';

import { Country } from '@calo/types';

import { currentMonth, currentYear, getListOfMonthNames, getYearsArray } from './helpers';

interface SettingsProps {
  onFilter: (values: PerksFilters) => any;
  filters: PerksFilters;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));
  const dispatch = useDispatch();
  const userKitchens: string[] = useUserKitchens();
  const years = getYearsArray(2024);

  const availableMonths = useMemo(() => {
    const allMonths = getListOfMonthNames();
    if (filters.year === currentYear) {
      return allMonths.slice(0, currentMonth + 1);
    }
    if (filters.year > currentYear) {
      return [];
    }
    return allMonths;
  }, [filters.year]);

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Country"
          value={filters.country}
          onChange={(data) =>
            onFilter({
              ...filters,
              country: data.target.value as Country
            })
          }
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select2
          label="Month"
          value={filters.month}
          onChange={(data) => onFilter({ ...filters, month: +data.target.value })}
          options={availableMonths.map((m, i) => ({
            value: i + 1,
            label: m
          }))}
        />
        <Select2
          label="Year"
          value={filters.year}
          onChange={(data) => onFilter({ ...filters, year: +data.target.value, month: 1 })}
          options={years.map((y) => ({
            value: y,
            label: y as unknown as string
          }))}
        />
      </section>
    </SideDrawer>
  );
};

export default Settings;
