import { useEffect, useState } from 'react';

import { format } from 'date-fns/fp';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { DeliveryFilters, Permission } from '@calo/dashboard-types';
import { Brand, DeliveryStatus, Kitchen } from '@calo/types';

import { exportDeliveries, getListWithParams } from 'actions';
import { Button, CaloLoader, Pagination } from 'components';
import { resolveCountryFromKitchen } from 'lib/helpers';
import history from 'lib/history';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { Delivery } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import DeliveryRow from '../DeliveryRow';
import Settings from '../Settings';
import DeliveryStats from './DeliveryStats';

interface PaginatedDeliveries {
  data: Delivery[];
  meta: {
    limit: number;
    total: number;
  };
}

const Deliveries = () => {
  const roles = useUserRoles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userKitchen = useUserKitchens();
  const [selected, setSelected] = useState<Delivery[]>([]);
  const [page, setPage] = useState(0);

  const [filters, setFilters] = useState<DeliveryFilters>({
    driverId: '',
    day: {
      lte: format('yyyy-MM-dd')(Date.now()),
      gte: format('yyyy-MM-dd')(Date.now())
    },
    status: [DeliveryStatus.upcoming, DeliveryStatus.paymentRequired],
    skipped: false,
    brand: Brand.CALO,
    country: resolveCountryFromKitchen((userKitchen && userKitchen && userKitchen[0]) || Kitchen.BH1),
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data: driversList, isLoading: driversLoading } = useQuery(
    ['/map/drivers', { country: filters.country }],
    getListWithParams,
    {
      retry: false,
      onError: console.error
    }
  );

  const { data, isLoading } = useQuery<any, Error, PaginatedDeliveries>(['deliveries', { page, filters }], getListWithParams, {
    keepPreviousData: false,
    onSuccess: (data) => {
      searchParams.set('filters', JSON.stringify(filters));
      history.push({
        pathname: location.pathname,
        search: searchParams.toString()
      });
      for (const row of data?.data || []) {
        queryClient.setQueryData(['deliveries', row.id], row);
      }
    }
  });

  const toggleDelivery = (delivery: Delivery) => {
    if (selected.includes(delivery)) {
      setSelected(selected.filter((del) => del.id !== delivery.id));
    } else {
      setSelected([...selected, delivery]);
    }
  };

  useEffect(() => {
    setPage(0);
  }, [filters]);

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  Deliveries {filters.country} / {filters.brand ? filters.brand : 'All'} / {filters.kitchen}{' '}
                </li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              {roles.includes(Permission.EXPORT_DELIVERY_LIST) && (
                <span className="mr-4">
                  <Button onClick={() => exportDeliveries(filters)} icon="fas fa-file-export" />
                </span>
              )}
            </div>
          </div>
        </div>
      </section>
      <section>
        <DeliveryStats filters={filters} total={data?.meta?.total || 0} />
      </section>
      <section>
        {isLoading ? (
          <CaloLoader />
        ) : (
          <div className="card has-table has-table-container-upper-radius">
            <div className="card-content">
              <div className="table-container">
                <table className="table is-striped is-hoverable is-sortable is-fullwidth">
                  <thead>
                    <tr className="bg-black">
                      <th style={{ color: 'white' }}>User</th>
                      <th style={{ color: 'white' }}>Phone</th>
                      <th style={{ color: 'white' }}>Day</th>
                      <th style={{ color: 'white' }}>Pending Amount</th>
                      <th style={{ color: 'white' }}>Menu</th>
                      <th style={{ color: 'white' }}>Address</th>
                      <th style={{ color: 'white' }}>Notes</th>
                      <th style={{ color: 'white' }}>Country</th>
                      <th style={{ color: 'white' }}>Kitchen</th>
                      <th style={{ color: 'white' }}>Currency</th>
                      <th style={{ color: 'white' }}>Driver</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  {data && data.data.length === 0 ? (
                    <span className="absolute w-full text-4xl mt-4 text-center font-bold text-gray-400">NO DELIVERIES</span>
                  ) : (
                    <tbody>
                      {data &&
                        data.data.map((delivery) => (
                          <DeliveryRow
                            selected={selected.includes(delivery)}
                            onToggle={toggleDelivery}
                            key={delivery.id}
                            delivery={delivery}
                          />
                        ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        )}
        {data?.data && (
          <Pagination
            isHidden={data.data.length === 0 || data.meta.total < 50}
            limit={data.meta.limit}
            total={data.meta.total}
            page={page}
            onChange={setPage}
          />
        )}
      </section>
      <Settings
        onFilter={(r) => setFilters(r)}
        filters={filters}
        page={page}
        //@ts-ignore
        drivers={driversLoading ? [] : driversList?.data || []}
      />
    </>
  );
};

export default Deliveries;
