import { HealthConditions, Kitchen } from '@calo/types';

export const healthConditionsIngredientLists = {
  [HealthConditions.PREGNANCY_BREASTFEEDING]: {
    [Kitchen.BH1]: [
      'blackTea', // Black Tea
      'coffee', // Coffee Instant 3in1
      '945afbe7-94ed-45e7-96fe-1ff21f36c5bd', // Coffee Arabic
      'a52f334b-04f5-4c8a-b9e7-6eb945672757', // Coffee Powder
      '3f14b2c8-0d29-4e23-adfb-63f39048c8d1', // Cold Brew Concentrated
      'turkeyBacon', // Turkey Bacon
      'turkeyBreast', // Turkey Breast
      'cb2f62f0-128d-4beb-bd63-8d3011faac22', // Beef Bacon
      'chili', // Chilli
      'wasabi', // Wasabi Paste
      'cayenne', // Cayenne Pepper
      'teriyakiSauce', // Teriyaki Sauce
      'fc014e48-1060-469c-92a4-49b2f1bff177', // Poblano Pepper
      'chiliFlakes', // Chilli Flakes
      '7b0716b0-3f05-4f08-a806-399623c5a99d', // Gochujang Paste
      'sriracha', // Sriracha
      'jalapeno', // Jalapeno Fresh
      'f55fba33-18cd-4a54-a15d-c416c1e3ebdd' // Chili Green Fresh
    ],
    [Kitchen.SA1]: [
      '24c8405a-e86f-4267-99a9-56ac639a2a18', // Black Tea
      'df095f78-3612-41bf-843a-40b08372b214', // Coffee Instant 3in1
      'ea5ca20a-d8e8-42f7-8c2f-1c4b2bd66fca', // Coffee Arabic
      'ab577431-b5de-4ffe-be24-fa2d7d12cef7', // Coffee Powder
      'f18342ab-3392-4595-b780-155da631987b', // Cold Brew Concentrated
      'a6995060-71b6-418b-bb61-63fd9548dc78', // Turkey Bacon
      '31f8b0c9-d609-4c10-859c-1cc3c0959b15', // Turkey Breast
      '923898e5-00b1-4dc2-8050-185f3b4750ab', // Beef Bacon
      // Chilli
      '0a70c4a1-fddb-40ae-bfde-4a2d5ff4176b', // Wasabi Paste
      'b80fd9af-d4c5-494a-a29b-b64662dace03', // Cayenne Pepper
      'da746683-90a7-4dc2-a220-f9bb56af6ff2', // Teriyaki Sauce
      '9f87d953-4749-428c-8f8e-611e537b8757', // Poblano Pepper
      '12365687-59b9-49b5-b6fe-2f6725c74c72', // Chilli Flakes
      'f04c01c0-3608-46cd-a2e3-454e3b177b44', // Gochujang Paste
      '4178140b-657e-4af4-9579-ae477267de90', // Sriracha
      'b5802e8e-c3f8-47c4-9b25-6b6a79973733', // Jalapeno Fresh
      '5355e811-5639-4464-bd3a-65dfb30f41e9' // Chili Green Fresh
    ],
    [Kitchen.SA2]: [
      'aaf51cde-9178-46cf-9ae1-e619baf0c62a', // Black Tea
      '6d69070f-ce13-4fe9-b8a1-adb850f654e4', // Coffee Instant 3in1
      'cc748883-23e7-4aca-af04-0964ac8643b1', // Coffee Arabic
      'aaa25d62-4e4d-488a-8ba8-15579e34bee4', // Coffee Powder
      'a86f70d6-9f90-4607-b171-4887b22379c6', // Cold Brew Concentrated
      '280245c8-59d2-48bd-9c3e-2a217a4f4acc', // Turkey Bacon
      'e73a98b4-ded8-42eb-827d-2a7ccf843d10', // Turkey Breast
      '5b17f6c9-6182-40e0-ab95-f2166dc75041', // Beef Bacon
      // Chilli
      'ef4d6a68-693c-4569-bd1f-6e6d524b935b', // Wasabi Paste
      'cb4b6c94-f6a1-4343-8683-4e46b03db5d0', // Cayenne Pepper
      '9c9d1b59-cc5b-4283-a232-a8e487c7d739', // Teriyaki Sauce
      'b53ab9b4-c6e4-452e-93d6-1805dd334c3d', // Poblano Pepper
      'baeb20fc-9abd-4657-af70-0e3f36454524', // Chilli Flakes
      'd0d23a8c-0c56-4886-9f19-c421cbe74112', // Gochujang Paste
      '9439bc38-60ba-4fb5-aa0c-5175198bffa3', // Sriracha
      '774b074c-7d3b-4a3c-a7d9-0d8495971aae', // Jalapeno Fresh
      '02d982b1-943a-4bea-92aa-63805417876d'
    ],
    [Kitchen.AE1]: [
      'c88651ac-2491-4f5e-b124-b5276e7a4924', // Black Tea
      '7cc469d3-25e9-41d3-8cc3-f65f48387ea6', // Coffee Instant 3in1
      '0bf7144a-84e2-43ec-a708-794702844729', // Coffee Arabic
      '62b5e2f8-e793-40ed-8b33-d296e397d903', // Coffee Powder
      // Cold Brew Concentrated
      'fa2446bd-9842-4e18-ae62-5168605706c6', // Turkey Bacon
      '47aa38b1-4b32-41d9-9d35-85742c356d00', // Turkey Breast
      '6f8f036f-ea58-486b-a45e-de1fc6a1a181', // Beef Bacon
      '5b786170-3efa-4de3-9dff-e51734f08de9', // Chilli
      'd4ce8233-8786-4214-9418-fef77bbf8fb4', // Wasabi Paste
      '5e464a50-4714-4c76-8c7d-02070191b861', // Cayenne Pepper
      'e3a94ad6-8179-4c14-8600-fa785ef5c079', // Teriyaki Sauce
      'df250be0-515b-4ec3-8300-bbd919e6d1e2', // Poblano Pepper
      'cdfdbe92-9a28-4f97-9aea-179691f2141d', // Chilli Flakes
      '72fae7c7-0f40-4dae-a8bb-4f637e7d1c00', // Gochujang Paste
      'ad73c8fe-c763-43c6-8c76-0223af7f56ce', // Sriracha
      'c7a0fafc-faa8-4d20-afcc-13e4c70efd9c', // Jalapeno Fresh
      '428a3e59-8007-4f1e-8294-7bf05a1e28e2' // Chili Green Fresh
    ],
    [Kitchen.KW1]: [
      '02ff6e94-a731-406b-90bf-8936e79905be', // Black Tea
      '6e48b41e-2f08-4d74-ba46-363dcde031a2', // Coffee Instant 3in1
      'a0cfc97b-4ec6-448d-9f59-fa58df322602', // Coffee Arabic
      '9174fcbe-a02f-45c1-bf28-3c78f25c0efd', // Coffee Powder
      // Cold Brew Concentrated
      '39357d33-06e7-4a5c-96db-927060ab6c90', // Turkey Bacon
      'bb879766-ed59-421f-a193-72134a8fb0aa', // Turkey Breast
      '885b5bdc-b7d1-467e-99c6-ca21be3399a6', // Beef Bacon
      // Chilli
      '413e5769-46a8-4165-86fd-91c82ff60864', // Wasabi Paste
      'd2b77032-2098-48f7-9588-1c220045dd1f', // Cayenne Pepper
      'c0bfdfdf-0a89-4016-923f-073f13bf3cbe', // Teriyaki Sauce
      '2bf29376-2393-4809-ae73-ec65cf5a3135', // Poblano Pepper
      '8122af2f-7c75-4ade-9c2c-fd9fb7636f08', // Chilli Flakes
      '7194c6a0-a945-4601-bf7c-71eaf59ff5b7', // Gochujang Paste
      '8b3618a8-b2fe-4336-ac20-64cfc9cc9b52', // Sriracha
      '1bb41fbc-0767-40e9-8d6f-eee30c66105e', // Jalapeno Fresh
      '117babf9-d3fd-412b-87b7-89782b606bc5' // Chili Green Fresh
    ],
    [Kitchen.OM1]: [],
    [Kitchen.QA1]: [
      'fbace38b-f789-4e5d-966c-6b296b2493d6', // Black Tea
      'b54ff57f-b0fe-47f3-939e-384c1b1d0908', // Coffee Instant 3in1
      '6fd4c151-6c22-475a-ac50-0080535cc719', // Coffee Arabic
      '6754c945-1de3-4e6c-a784-df8cba881123', // Coffee Powder
      // Cold Brew Concentrated
      '8f469b0c-c9d7-42f2-a8f1-bc27e4547ae1', // Turkey Bacon
      'fe4660d7-99a2-44ae-9f35-69f7dea12841', // Turkey Breast
      '92ec0229-df80-4426-8fa4-e7a5ab19c1b2', // Beef Bacon
      // Chilli
      '0a7a806d-37c7-4e0f-aa42-1bc89243f4cb', // Wasabi Paste
      '3aea4495-c1f8-4cbd-b9a3-bab8cf36a03c', // Cayenne Pepper
      'bc399b2a-50db-4aa8-a9c2-ceefacb8139b', // Teriyaki Sauce
      '5f246ef7-c4a2-4c2b-8052-ae3d7f63068e', // Poblano Pepper
      '45284ada-ddc9-4476-8856-2f68e3edb82e', // Chilli Flakes
      '76e8c3b4-553f-444b-a393-b93aaa70753d', // Gochujang Paste
      '15e1efac-ca7c-412e-84ca-e587b4375eca', // Sriracha
      '9ab5ee73-946a-43ab-ab9a-e463f8639f49', // Jalapeno Fresh
      'e7051797-810e-49d6-845d-73181a024546' // Chili Green Fresh
    ]
  },
  [HealthConditions.HYPERTENSION]: {
    [Kitchen.BH1]: [
      'turkeyBacon', // Turkey Bacon
      'turkeyBreast', // Turkey Breast
      'd5f6396a-a3f6-4065-95f5-8182fed642fe', // Beef Tenderloin
      '9fa7033e-39e6-4976-9528-2e8a802a0293', // Beef Minced
      '5f4ee3be-ff9d-46d7-bef9-50fc42c5574e', // Beef Fat
      'cb2f62f0-128d-4beb-bd63-8d3011faac22', // Beef Bacon
      '3b0e58aa-8164-4088-835c-a16af292c10f', // Beef Brisket
      'b7868d27-4c6a-43ba-9110-0f045395f2e9', // Lamb Fat
      'lamb', // Lamb Leg
      'cottageCheese', // Cottage Cheese
      'lowFatWhippedCream', // Low Fat Whipped Cream
      'fatFreeCreamCheese', // Fat Free Cream Cheese
      'goatCheese', // Goat Cheese
      'greekYogurt', // Greek Yogurt
      '22a1678d-0ed4-407c-a053-b77edb8bf5fe', // Mascarpone
      'yogurt', // Yogurt Full Fat
      'creamCheese', // Cream Cheese
      'lowFatMozzarella', // Mozzarella
      'labneh', // Labneh
      'milk', // Milk Full Fat
      '7df90cb3-42a3-4edd-a5c0-a156437b33b5' // Kefir Milk - Fermendiet
    ],
    [Kitchen.SA1]: [
      'a6995060-71b6-418b-bb61-63fd9548dc78', // Turkey Bacon
      '31f8b0c9-d609-4c10-859c-1cc3c0959b15', // Turkey Breast
      '6de1797c-f813-4b9e-a5b9-9331daabf834', // Beef Tenderloin
      'c1a29231-e2ee-40ce-b669-234af292324b', // Beef Minced
      '8ad7bc47-8d7f-4969-acf6-526640fbfe33', // Beef Fat
      '923898e5-00b1-4dc2-8050-185f3b4750ab', // Beef Bacon
      'ec3660a0-29f4-4fe9-8bfb-ee1aece6fd99', // Beef Brisket
      'd4a52592-86c0-4a91-8011-305efd9932aa', // Lamb Fat
      '82580488-d5ec-41da-928c-8183efa4206b', // Lamb Leg
      '24599f8a-17d6-42c6-9b96-ff4cfd5c4bae', // Cottage Cheese
      '92597d84-92c7-40f5-893f-ae507b6fa582', // Low Fat Whipped Cream
      '15cde19c-ceab-4e4c-9e70-dd08d6fd827c', // Fat Free Cream Cheese
      '0e374d8d-c0cf-4257-92d4-153580ff68de', // Goat Cheese
      '83e489b0-976b-48ee-b417-bc83d246bc61', // Greek Yogurt
      '28505d5c-42ec-4221-a047-fd941c32a355', // Mascarpone
      '49b23c66-1632-48e3-8a92-d06414313841', // Yogurt Full Fat
      '241186bd-496f-448a-86d7-9d0c91c9d11b', // Cream Cheese
      '0841e420-d897-40e2-bd4c-fa7103487a8b', // Mozzarella
      '067f42db-35c4-4ce8-ad31-66e3bade3135', // Labneh
      '77c43647-9bc4-4d5f-99dd-384cfc50fbd0' // Milk Full Fat
      // Kefir Milk - Fermendiet
    ],
    [Kitchen.SA2]: [
      '280245c8-59d2-48bd-9c3e-2a217a4f4acc', // Turkey Bacon
      'e73a98b4-ded8-42eb-827d-2a7ccf843d10', // Turkey Breast
      'f0f4d590-5d37-4622-b652-4dce58ed6b9c', // Beef Tenderloin
      '571654f7-6e6e-4d3a-b725-49c692ec5c23', // Beef Minced
      '73f4b63c-ef83-45eb-8427-8cdb3999db2e', // Beef Fat
      '5b17f6c9-6182-40e0-ab95-f2166dc75041', // Beef Bacon
      'b5861c55-c866-4545-9121-e306e8d9d7f2', // Beef Brisket
      '6de3ff5a-facd-4238-ae5f-ec17e7a69789', // Lamb Fat
      '09789bd7-5394-4a4e-9e02-67de1063da08', // Lamb Leg
      '5d10b08f-c83f-49b2-a098-5b540aa2b744', // Cottage Cheese
      '636ed64d-8b82-4d8d-a0f4-ab662395a0d3', // Low Fat Whipped Cream
      'cf32f12f-f849-423d-ba85-b1236b225c7b', // Fat Free Cream Cheese
      '785c7f7f-85bf-449e-b799-2f1e9f217fcc', // Goat Cheese
      'ff3621b1-099c-4c42-90a8-e50d36b4655b', // Greek Yogurt
      'eb076a7d-8b18-43c9-80ba-99879860f00e', // Mascarpone
      '4e2451f9-2baf-4f75-835a-bdc986e678ec', // Yogurt Full Fat
      'b95b5eb4-8c2b-44f0-b18a-6567f0c186ae', // Cream Cheese
      '78e376d8-216f-436e-b49e-e05e28b8bf3b', // Mozzarella
      '43ba7ccd-0ad3-469b-b2f4-7b6b7d529505', // Labneh
      'c8e99b6b-a17d-4ff9-914b-3a3fdb538cd9' // Milk Full Fat
      // Kefir Milk - Fermendiet (ID not provided)
    ],
    [Kitchen.AE1]: [
      'fa2446bd-9842-4e18-ae62-5168605706c6', // Turkey Bacon
      '47aa38b1-4b32-41d9-9d35-85742c356d00', // Turkey Breast
      'e7695e9f-8cf9-49f5-9ad7-2b2d230d2502', // Beef Tenderloin
      '345de98e-2fbe-4167-8958-029387f0ed13', // Beef Minced
      '919a59a0-48da-45c8-9d46-6922275583e8', // Beef Fat
      '6f8f036f-ea58-486b-a45e-de1fc6a1a181', // Beef Bacon
      '259f96df-6c82-47b1-ad3a-245a1a3546a6', // Beef Brisket
      '8b69f6fe-2a08-40c9-b4a6-66912dfbdea0', // Lamb Fat
      '486d4368-235e-4f2f-96ac-e5e7d3a459fb', // Lamb Leg
      'b4b9df10-d978-42bf-ad95-e10861d778ee', // Cottage Cheese
      '4749c55b-f568-4106-b9e6-70bc49d6d37e', // Low Fat Whipped Cream
      'eb0f92d7-2cee-4872-8c34-6092daacbd66', // Fat Free Cream Cheese
      '4edfcde6-52d3-42ec-98bf-21227a43a941', // Goat Cheese
      '57e3ed89-711e-4ce7-8fd3-102fcc1decfc', // Greek Yogurt
      '6b4032f8-57d5-49e7-bbbd-1740aea956ef', // Mascarpone
      '74650dc0-bfab-41e5-ac47-675b790c679e', // Yogurt Full Fat
      'd325ad9b-e345-400d-bcc5-c05bccac1f37', // Cream Cheese
      '5efa4813-b42d-417a-a7dc-06914cd7a826', // Mozzarella
      '46985777-256a-4640-a48d-58f93fb670cf', // Labneh
      '0d103d94-6b41-4eeb-99b9-8dfe5c1e1d81' // Milk Full Fat
      // Kefir Milk - Fermendiet
    ],
    [Kitchen.KW1]: [
      '39357d33-06e7-4a5c-96db-927060ab6c90', // Turkey Bacon
      'bb879766-ed59-421f-a193-72134a8fb0aa', // Turkey Breast
      'd3bc778f-fcd5-4653-ba6b-fbdc216c562f', // Beef Tenderloin
      'c1d4bf7b-c1d1-4ff8-9f58-f18ddef63209', // Beef Minced
      'd2c97b2e-903f-4315-ba0c-30bd44346940', // Beef Fat
      '885b5bdc-b7d1-467e-99c6-ca21be3399a6', // Beef Bacon
      'ea84f9bc-2469-4935-8d9d-6199d00e2ac1', // Beef Brisket
      'a0af5859-9e94-44ff-bf1c-980927492ff6', // Lamb Fat
      'fda53f7c-a97e-427f-a208-3f00a7bae715', // Lamb Leg
      '7e75f865-b0a9-4aa8-8992-5dc991884b43', // Cottage Cheese
      '358cef66-0efb-4c66-bb8b-2bffa2d6f1bb', // Low Fat Whipped Cream
      '7528e3ef-4eea-4fd2-89e0-daa5650a001e', // Fat Free Cream Cheese
      'ca3f84c8-dacc-451b-b0a4-9eb57d657ab5', // Goat Cheese
      // Greek Yogurt
      'e5be4c6e-536c-459a-99aa-cf99dce1e08b', // Mascarpone
      // Yogurt Full Fat
      '86255147-0fcf-4a59-aa9c-585c03173e23', // Cream Cheese
      '1e12e803-8f8a-4039-b67c-e12602fd9c44', // Mozzarella
      'fc6f0ee0-58b6-47b6-92f9-d25f7afcdf7f', // Labneh
      '440ea63f-4756-44e7-a260-900590486ddc', // Milk Full Fat
      '#N/A' // Kefir Milk - Fermendiet
    ],
    [Kitchen.OM1]: [],
    [Kitchen.QA1]: [
      '8f469b0c-c9d7-42f2-a8f1-bc27e4547ae1', // Turkey Bacon
      'fe4660d7-99a2-44ae-9f35-69f7dea12841', // Turkey Breast
      '204a26d6-184c-4375-83b5-da8182683a0f', // Beef Tenderloin
      '89ffa231-d780-4b38-ae0a-2e3af2798ee2', // Beef Minced
      '0e606e25-6a7b-44b9-9054-c2263e91f278', // Beef Fat
      '92ec0229-df80-4426-8fa4-e7a5ab19c1b2', // Beef Bacon
      '15b76fbe-4f03-4572-a3db-c5f016cb2347', // Beef Brisket
      // Lamb Fat
      '5ad24ea9-cc5a-4eb8-8e4a-7c2b4d619aa6', // Lamb Leg
      '6e8dd25d-ffdd-4655-ab58-dc21c297edd0', // Cottage Cheese
      '5b10d4ca-b88a-458e-960b-1872bdf91168', // Low Fat Whipped Cream
      '29a0993d-2dde-4b6e-aa82-e1064bb2a294', // Fat Free Cream Cheese
      '47b61d6a-2e46-40bb-9af1-aa5b2f75c266', // Goat Cheese
      '3ddbe021-00ef-4eac-b33d-8d42153ed19f', // Greek Yogurt
      '86b3c84e-18d0-47e2-a336-8e0f0d091bfc', // Mascarpone
      '294c5f58-8b09-4759-a767-01a4896780a9', // Yogurt Full Fat
      '26f1b059-ba44-4fd7-ae8c-645b6031227e', // Cream Cheese
      '5d60d9cc-ed57-4c5e-85ee-787337addbb4', // Mozzarella
      '8898bf74-f0fe-4bb6-ac46-22ea52625527', // Labneh
      '7464c2ac-2488-4ebd-a897-bb82024ab950' // Milk Full Fat
      // Kefir Milk - Fermendiet
    ]
  },
  [HealthConditions.HIGH_CHOLESTEROL]: {
    [Kitchen.BH1]: [
      'turkeyBacon', // Turkey Bacon
      'turkeyBreast', // Turkey Breast
      'cb2f62f0-128d-4beb-bd63-8d3011faac22', // Beef Bacon
      'd5f6396a-a3f6-4065-95f5-8182fed642fe', // Beef Tenderloin
      '9fa7033e-39e6-4976-9528-2e8a802a0293', // Beef Minced
      '5f4ee3be-ff9d-46d7-bef9-50fc42c5574e', // Beef Fat
      '3b0e58aa-8164-4088-835c-a16af292c10f', // Beef Brisket
      'b7868d27-4c6a-43ba-9110-0f045395f2e9', // Lamb Fat
      'lamb', // Lamb Leg
      '7c1c5c78-c59c-4b23-a411-67710c6c3ab2', // Salmon Frozen
      'shrimp', // Shrimp Frozen 30/40
      'eggWhite', // Egg White Fresh
      'a4a56bd6-467b-4df7-ba12-989092fd59ee', // Egg Yolk Fresh
      '943f8b19-b4f9-48ad-97ce-179168e54a60', // Frozen Egg Yolk
      // Egg Yolk
      '87d759ed-0f5f-4a29-b7ad-5a583ab24fd9', // Frozen Egg White
      '32b84716-02e0-4250-a1fd-c59f4cd4cec4' // Frozen Egg Whole
      // Whole Fresh Egg
    ],
    [Kitchen.SA1]: [
      'a6995060-71b6-418b-bb61-63fd9548dc78', // Turkey Bacon
      '31f8b0c9-d609-4c10-859c-1cc3c0959b15', // Turkey Breast
      '923898e5-00b1-4dc2-8050-185f3b4750ab', // Beef Bacon
      '6de1797c-f813-4b9e-a5b9-9331daabf834', // Beef Tenderloin
      'c1a29231-e2ee-40ce-b669-234af292324b', // Beef Minced
      '8ad7bc47-8d7f-4969-acf6-526640fbfe33', // Beef Fat
      'ec3660a0-29f4-4fe9-8bfb-ee1aece6fd99', // Beef Brisket
      'd4a52592-86c0-4a91-8011-305efd9932aa', // Lamb Fat
      '82580488-d5ec-41da-928c-8183efa4206b', // Lamb Leg
      '2859aa6a-c68d-4f5e-86d3-fe103c62bf16', // Salmon Frozen
      '0c982b93-d2e0-4a07-b0c1-f1883502bdb7', // Shrimp Frozen 30 / 40
      // Egg White Fresh
      // Egg Yolk Fresh
      '890c9808-13a9-4d4e-8f84-44402d47bb1e', // Frozen Egg Yolk
      '1ef436bd-ae86-4db4-83e6-0bbf2f521ecf', // Egg Yolk
      '70e17f7c-3dae-4e64-8700-b01814c4b34d', // Frozen Egg White
      '6e9016d0-3c4f-4b30-9667-7cc3698b9ccf' // Frozen Egg Whole
      // Whole Fresh Egg
    ],
    [Kitchen.SA2]: [
      '280245c8-59d2-48bd-9c3e-2a217a4f4acc', // Turkey Bacon
      'e73a98b4-ded8-42eb-827d-2a7ccf843d10', // Turkey Breast
      '5b17f6c9-6182-40e0-ab95-f2166dc75041', // Beef Bacon
      'f0f4d590-5d37-4622-b652-4dce58ed6b9c', // Beef Tenderloin
      '571654f7-6e6e-4d3a-b725-49c692ec5c23', // Beef Minced
      '73f4b63c-ef83-45eb-8427-8cdb3999db2e', // Beef Fat
      'b5861c55-c866-4545-9121-e306e8d9d7f2', // Beef Brisket
      '6de3ff5a-facd-4238-ae5f-ec17e7a69789', // Lamb Fat
      '09789bd7-5394-4a4e-9e02-67de1063da08', // Lamb Leg
      '54369670-b1ad-4a19-abfa-b1b36328b54c', // Salmon Frozen
      '0bc699b5-3cc3-4ae5-958e-d0b9d7260f27', // Shrimp Frozen 30/40
      // Egg White Fresh
      // Egg Yolk Fresh
      '028faaa0-baac-49df-bc34-4ff660be16f7', // Frozen Egg Yolk
      '08086f58-ef2a-4441-af12-bc34008c8c28', // Egg Yolk
      '10cac4a4-7168-4da5-97dc-e17b49931326', // Frozen Egg White
      '58e44b44-953f-477a-bad2-b9594f1dda44' // Frozen Egg Whole
      // Whole Fresh Egg
    ],
    [Kitchen.AE1]: [
      'fa2446bd-9842-4e18-ae62-5168605706c6', // Turkey Bacon
      '47aa38b1-4b32-41d9-9d35-85742c356d00', // Turkey Breast
      '6f8f036f-ea58-486b-a45e-de1fc6a1a181', // Beef Bacon
      'e7695e9f-8cf9-49f5-9ad7-2b2d230d2502', // Beef Tenderloin
      '345de98e-2fbe-4167-8958-029387f0ed13', // Beef Minced
      '919a59a0-48da-45c8-9d46-6922275583e8', // Beef Fat
      '259f96df-6c82-47b1-ad3a-245a1a3546a6', // Beef Brisket
      '8b69f6fe-2a08-40c9-b4a6-66912dfbdea0', // Lamb Fat
      '486d4368-235e-4f2f-96ac-e5e7d3a459fb', // Lamb Leg
      '011a2d67-25a6-4617-aedc-4e98f2faf2fe', // Salmon Frozen
      '66cb2ad2-a1db-4633-9ea1-dcbc0cb5a9d7', // Shrimp Frozen 30/40
      // Egg White Fresh
      'cbc30ce0-2c02-46a9-8032-735da49b8b00', // Egg Yolk Fresh
      '61f0e1ce-5f15-4ce0-ae33-10e2f59231cd', // Frozen Egg Yolk
      // Egg Yolk
      '042a37b7-2d9c-4fb8-b9de-ec44071b5176', // Frozen Egg White
      'c75a5cb5-3ae7-4593-baf7-0fd4cfc73342' // Frozen Egg Whole
      // Whole Fresh Egg
    ],
    [Kitchen.KW1]: [
      '39357d33-06e7-4a5c-96db-927060ab6c90', // Turkey Bacon
      'bb879766-ed59-421f-a193-72134a8fb0aa', // Turkey Breast
      '885b5bdc-b7d1-467e-99c6-ca21be3399a6', // Beef Bacon
      'd3bc778f-fcd5-4653-ba6b-fbdc216c562f', // Beef Tenderloin
      'c1d4bf7b-c1d1-4ff8-9f58-f18ddef63209', // Beef Minced
      'd2c97b2e-903f-4315-ba0c-30bd44346940', // Beef Fat
      'ea84f9bc-2469-4935-8d9d-6199d00e2ac1', // Beef Brisket
      'a0af5859-9e94-44ff-bf1c-980927492ff6', // Lamb Fat
      'fda53f7c-a97e-427f-a208-3f00a7bae715', // Lamb Leg
      'fd1df9a0-0fc4-45e3-86b1-58aafde27a27', // Salmon Frozen
      '6a0f286e-bfad-41a2-a57a-bc19739e25b5', // Shrimp Frozen 30/40
      '28790499-dd58-4791-b8c2-3cf73488dd9f', // Egg White Fresh
      'c72ee32b-8496-4cc8-b09f-c67e99dcc31e', // Egg Yolk Fresh
      'f41fde10-0084-45ac-b5ee-8b9e38884a2d', // Frozen Egg Yolk
      // Egg Yolk
      '08308f44-e752-4ad1-bab3-c044adb227fa', // Frozen Egg White
      'e7e92b40-1f78-4060-9185-13715573be71', // Frozen Egg Whole
      '#N/A' // Whole Fresh Egg
    ],
    [Kitchen.OM1]: [],
    [Kitchen.QA1]: [
      '8f469b0c-c9d7-42f2-a8f1-bc27e4547ae1', // Turkey Bacon
      'fe4660d7-99a2-44ae-9f35-69f7dea12841', // Turkey Breast
      '92ec0229-df80-4426-8fa4-e7a5ab19c1b2', // Beef Bacon
      '204a26d6-184c-4375-83b5-da8182683a0f', // Beef Tenderloin
      '89ffa231-d780-4b38-ae0a-2e3af2798ee2', // Beef Minced
      '0e606e25-6a7b-44b9-9054-c2263e91f278', // Beef Fat
      '15b76fbe-4f03-4572-a3db-c5f016cb2347', // Beef Brisket
      // Lamb Fat
      '5ad24ea9-cc5a-4eb8-8e4a-7c2b4d619aa6', // Lamb Leg
      '257efd1a-b125-4da0-a1c2-075ffe1931e6', // Salmon Frozen
      '482e553e-302e-40be-b8bd-3722cf0bd5f2', // Shrimp Frozen 30/40
      // Egg White Fresh
      // Egg Yolk Fresh
      '0d0b2ab2-f795-465b-98f1-d4ad444a774c', // Frozen Egg Yolk
      '5a714b6e-d7f7-47be-9d17-add576fd6bbc', // Egg Yolk
      '0850a7ae-2d7a-4bc7-a091-0aed0972aa0a', // Frozen Egg White
      '8dd6589a-0158-480d-a7af-b03d59c4b04c' // Frozen Egg Whole
      // Whole Fresh Egg
    ]
  },
  [HealthConditions.GERD]: {
    [Kitchen.BH1]: [
      'chili', // Chilli
      'wasabi', // Wasabi Paste
      'cayenne', // Cayenne Pepper
      'teriyakiSauce', // Teriyaki Sauce
      'fc014e48-1060-469c-92a4-49b2f1bff177', // Poblano Pepper
      'chiliFlakes', // Chilli Flakes
      '7b0716b0-3f05-4f08-a806-399623c5a99d', // Gochujang Paste
      'sriracha', // Sriracha
      'jalapeno', // Jalapeno Fresh
      'f55fba33-18cd-4a54-a15d-c416c1e3ebdd', // Chili Green Fresh
      'tomato', // Tomato Sauce
      '896354a9-112a-4a03-9ac1-559950866f1a', // Tomato Fresh
      'fa23e3c4-44ca-44f4-9e7f-fbed701513ef', // Tomato Peeled Can
      '05a5efd7-462c-441a-ad34-ec353d098c3b', // Lemon Zest
      'limeZest', // Lime Zest
      '59d9b4da-0a91-47b9-bf38-80aa83bd73b0', // Arabic Lemon pickles
      'lemon', // Lemon
      '2090707f-9015-47d5-a031-c7c5af496d41', // PP Sunrise Cleanse
      'lime', // Lime Fresh
      // Lemon Fresh
      // PP On Guard
      'e3bb4653-e3d1-4911-be85-004a6ea223e9', // Onion Brown
      '0c42de10-e0ac-4b42-92f4-4037ae9bcaf2', // Onion Red
      '96bebe63-d4fb-48c2-9c67-f053024f39ad', // Onion Powder
      '73b22779-2629-4ca6-9d53-131128e3efd7' // Onion Red Peeled
    ],
    [Kitchen.SA1]: [
      // Chilli
      '0a70c4a1-fddb-40ae-bfde-4a2d5ff4176b', // Wasabi Paste
      'b80fd9af-d4c5-494a-a29b-b64662dace03', // Cayenne Pepper
      'da746683-90a7-4dc2-a220-f9bb56af6ff2', // Teriyaki Sauce
      '9f87d953-4749-428c-8f8e-611e537b8757', // Poblano Pepper
      '12365687-59b9-49b5-b6fe-2f6725c74c72', // Chilli Flakes
      'f04c01c0-3608-46cd-a2e3-454e3b177b44', // Gochujang Paste
      '4178140b-657e-4af4-9579-ae477267de90', // Sriracha
      'b5802e8e-c3f8-47c4-9b25-6b6a79973733', // Jalapeno Fresh
      '5355e811-5639-4464-bd3a-65dfb30f41e9', // Chili Green Fresh
      'f4f1d683-c892-4621-8518-e37c64a26a65', // Tomato Sauce
      '230a9922-1e05-4510-bd20-f82c35899838', // Tomato Fresh
      '1511d397-163a-4215-8e6e-99990323a41c', // Tomato Peeled Can
      'c463fb2e-6684-4a60-ab20-48706fdde6fc', // Lemon Zest
      '9dbdec50-abec-4e92-b46a-6587c9e092bc', // Lime Zest
      '0090e808-7eee-446d-aa8e-d245e26e2559', // Arabic Lemon pickles
      '5be307fd-2a6e-47c7-8ab3-174dc48a31c9', // Lemon
      'b3e2023e-a78a-4a31-8eaa-6a3ebf7531c7', // PP Sunrise Cleanse
      'a9fed1dc-f6c3-4e1e-b184-627c288c816f', // Lime Fresh
      // Lemon Fresh
      // Lime Fresh
      // PP On Guard
      '827f7f30-65bc-44c9-a4a4-73c7b2434619', // Onion Brown
      'a97214f8-472b-46b5-99f6-bd9cde0d8114', // Onion Red
      'bad7b356-9259-41b4-baeb-799877d3f70d', // Onion Powder
      '7302475f-2288-41ab-a33d-52c2f92b654d' // Onion Red Peeled
    ],
    [Kitchen.SA2]: [
      // Chilli
      'ef4d6a68-693c-4569-bd1f-6e6d524b935b', // Wasabi Paste
      'cb4b6c94-f6a1-4343-8683-4e46b03db5d0', // Cayenne Pepper
      '9c9d1b59-cc5b-4283-a232-a8e487c7d739', // Teriyaki Sauce
      'b53ab9b4-c6e4-452e-93d6-1805dd334c3d', // Poblano Pepper
      'baeb20fc-9abd-4657-af70-0e3f36454524', // Chilli Flakes
      'd0d23a8c-0c56-4886-9f19-c421cbe74112', // Gochujang Paste
      '9439bc38-60ba-4fb5-aa0c-5175198bffa3', // Sriracha
      '774b074c-7d3b-4a3c-a7d9-0d8495971aae', // Jalapeno Fresh
      '02d982b1-943a-4bea-92aa-63805417876d', // Chili Green Fresh
      '401c8f93-80d6-4867-94df-aaf3cf50f94c', // Tomato Sauce
      '6001062f-5995-40c4-9b4f-3e358db0a3d2', // Tomato Fresh
      '3c48bbf3-5c94-4ff0-9533-96f45289c47e', // Tomato Peeled Can
      'a32cd116-e4fa-47d0-a6a5-e18eb9bd8e87', // Lemon Zest
      '75457859-f049-48ac-bda5-3743d3e4c35a', // Lime Zest
      'a67925dd-f360-4644-84d8-1c3e4eaf33cf', // Arabic Lemon pickles
      '2603c74e-c361-4936-ab96-eba735fa5d7d', // Lemon
      'ff30df98-d10f-482d-bf2b-f71b3e5c1acd', // PP Sunrise Cleanse
      '74845117-18bb-42b4-88cd-85aaff3e2f80', // Lime Fresh
      // Lemon Fresh
      // PP On Guard
      '304b7806-218b-461c-8b36-e48ec731823d', // Onion Brown
      '1ed7c3cc-7466-4b45-8cc4-3ebb604e4384', // Onion Red
      '8e34bad3-39d7-4d89-87dc-4f8427365487', // Onion Powder
      'bde6fd89-bdd3-4c22-9549-cd5b40f68e8a' // Onion Red Peeled
    ],
    [Kitchen.AE1]: [
      '5b786170-3efa-4de3-9dff-e51734f08de9', // Chilli
      'd4ce8233-8786-4214-9418-fef77bbf8fb4', // Wasabi Paste
      '5e464a50-4714-4c76-8c7d-02070191b861', // Cayenne Pepper
      'e3a94ad6-8179-4c14-8600-fa785ef5c079', // Teriyaki Sauce
      'df250be0-515b-4ec3-8300-bbd919e6d1e2', // Poblano Pepper
      'cdfdbe92-9a28-4f97-9aea-179691f2141d', // Chilli Flakes
      '72fae7c7-0f40-4dae-a8bb-4f637e7d1c00', // Gochujang Paste
      'ad73c8fe-c763-43c6-8c76-0223af7f56ce', // Sriracha
      'c7a0fafc-faa8-4d20-afcc-13e4c70efd9c', // Jalapeno Fresh
      '428a3e59-8007-4f1e-8294-7bf05a1e28e2', // Chili Green Fresh
      '8df5e126-7a35-4fa4-9358-484dcd4ebd86', // Tomato Sauce
      'e4456330-62b5-4293-8e98-8c74ab49b98c', // Tomato Fresh
      '97f1bcef-4d5e-4d24-a9ad-99c86d02d17c', // Tomato Peeled Can
      '7c22e585-e2e9-4efc-bf0b-3e92ba4068d3', // Lemon Zest
      '9276c65a-1e6e-4bc2-b482-2ecce3210e33', // Lime Zest
      'e53e05c0-edb3-4fef-8745-7b60e5a6d012', // Arabic Lemon pickles
      'a370eee3-6c38-42f3-aff6-617cfc33a2f6', // Lemon
      'b80ec435-09ee-465c-8852-5e65d3c356be', // PP Sunrise Cleanse
      '5f1b42bf-3627-44b6-b0d9-e9f7d01b224d', // Lime Fresh
      // Lemon Fresh
      // Lime Fresh
      // PP On Guard
      'd0cf9ab6-4684-46ac-9003-a469242187ec', // Onion Brown
      'f4a2b5c5-f8df-433f-a752-fdf2816472be', // Onion Red
      '5758b737-1e95-4cf6-8550-bb7ac00fa29d', // Onion Powder
      '679445ef-9ea0-4471-8217-31f789a39844' // Onion Red Peeled
    ],
    [Kitchen.KW1]: [
      // Chilli
      '413e5769-46a8-4165-86fd-91c82ff60864', // Wasabi Paste
      'd2b77032-2098-48f7-9588-1c220045dd1f', // Cayenne Pepper
      'c0bfdfdf-0a89-4016-923f-073f13bf3cbe', // Teriyaki Sauce
      '2bf29376-2393-4809-ae73-ec65cf5a3135', // Poblano Pepper
      '8122af2f-7c75-4ade-9c2c-fd9fb7636f08', // Chilli Flakes
      '7194c6a0-a945-4601-bf7c-71eaf59ff5b7', // Gochujang Paste
      '8b3618a8-b2fe-4336-ac20-64cfc9cc9b52', // Sriracha
      '1bb41fbc-0767-40e9-8d6f-eee30c66105e', // Jalapeno Fresh
      '117babf9-d3fd-412b-87b7-89782b606bc5', // Chili Green Fresh
      // Tomato Sauce
      'a554ecfa-d3f2-488e-97e6-f2e54f459797', // Tomato Fresh
      'c603dbdc-227a-4913-9ab2-38ffa0580bdb', // Tomato Peeled Can
      'a16202f6-f1ef-432a-9fe8-ca4bad746281', // Lemon Zest
      '137c1fd5-924e-42f6-91ef-31a589642f43', // Lime Zest
      '020d7752-9af4-4be2-ace1-bb971aa226d4', // Arabic Lemon pickles
      'aebede5c-f164-4cc1-b40e-2b3eb7446b0c', // Lemon
      '08e9df15-44eb-4304-a584-cac7a90fe793', // PP Sunrise Cleanse
      'cd2e59ff-6f05-4e06-81a2-b98316089214', // Lime Fresh
      // Lemon Fresh
      'cd2e59ff-6f05-4e06-81a2-b98316089214', // Lime Fresh (duplicate in the list)
      // PP On Guard
      'd92f8abe-e010-4127-bf85-dbed28bca80c', // Onion Brown
      'e97dd6d6-bc7e-47e1-968d-5fee367d4e50', // Onion Red
      '18d11e13-ce15-4743-9bd2-031cba555526', // Onion Powder
      'b507ed1b-ef31-49a6-9f86-b8c533dbe51d' // Onion Red Peeled
    ],
    [Kitchen.OM1]: [],
    [Kitchen.QA1]: [
      // Chilli
      '0a7a806d-37c7-4e0f-aa42-1bc89243f4cb', // Wasabi Paste
      '3aea4495-c1f8-4cbd-b9a3-bab8cf36a03c', // Cayenne Pepper
      'bc399b2a-50db-4aa8-a9c2-ceefacb8139b', // Teriyaki Sauce
      '5f246ef7-c4a2-4c2b-8052-ae3d7f63068e', // Poblano Pepper
      '45284ada-ddc9-4476-8856-2f68e3edb82e', // Chilli Flakes
      '76e8c3b4-553f-444b-a393-b93aaa70753d', // Gochujang Paste
      '15e1efac-ca7c-412e-84ca-e587b4375eca', // Sriracha
      '9ab5ee73-946a-43ab-ab9a-e463f8639f49', // Jalapeno Fresh
      'e7051797-810e-49d6-845d-73181a024546', // Chili Green Fresh
      'eed9336e-4201-400d-b277-1135029c31e6', // Tomato Sauce
      '0da35e17-5404-4843-8511-fa9b7963cc78', // Tomato Fresh
      'e0ea41bf-bcd7-420f-b727-c7dbfd1c11a8', // Tomato Peeled Can
      'c6dfa415-1134-48fd-8a0d-15d481166376', // Lemon Zest
      'd954a4c2-0852-4b34-a285-bfdd1c0de2e7', // Lime Zest
      '57a627b8-fa12-4922-8824-7c01e58328d3', // Arabic Lemon pickles
      'f16a7d9a-3ac4-48da-b010-d0bdeef3e837', // Lemon
      'aba4231a-0569-4bad-b53f-e747766d7fbd', // PP Sunrise Cleanse
      'e2b01175-4acc-4fce-85cc-d3327c15fec1', // Lime Fresh
      // Lemon Fresh
      // PP On Guard
      'c1196637-1a38-4888-a1c6-d0885eb457fc', // Onion Brown
      '5ec4deba-25a2-4e53-b2ba-b827620f41a3', // Onion Red
      '3bafaa1e-2544-44d1-b9a8-0e98f1f54ccd', // Onion Powder
      '0ba81d30-cc0a-4e83-8cc4-493e0c6859c0' // Onion Red Peeled
    ]
  },
  [HealthConditions.IBS]: {
    [Kitchen.BH1]: [
      'chili', // Chilli
      'wasabi', // Wasabi Paste
      'cayenne', // Cayenne Pepper
      'teriyakiSauce', // Teriyaki Sauce
      'fc014e48-1060-469c-92a4-49b2f1bff177', // Poblano Pepper
      'chiliFlakes', // Chilli Flakes
      '7b0716b0-3f05-4f08-a806-399623c5a99d', // Gochujang Paste
      'sriracha', // Sriracha
      'jalapeno', // Jalapeno Fresh
      'f55fba33-18cd-4a54-a15d-c416c1e3ebdd', // Chili Green Fresh
      'cottageCheese', // Cottage Cheese
      'lowFatWhippedCream', // Low Fat Whipped Cream
      'fatFreeCreamCheese', // Fat Free Cream Cheese
      'goatCheese', // Goat Cheese
      'greekYogurt', // Greek Yogurt
      '22a1678d-0ed4-407c-a053-b77edb8bf5fe', // Mascarpone
      'yogurt', // Yogurt Full Fat
      'creamCheese', // Cream Cheese
      'lowFatMozzarella', // Mozzarella
      'labneh', // Labneh
      'milk', // Milk Full Fat
      '7df90cb3-42a3-4edd-a5c0-a156437b33b5', // Kefir Milk - Fermendiet
      'wheatFlour', // Wheat Flour
      'wheatTortilla', // Wheat Tortilla 6.5" or 8"
      'f875bcc0-71e0-4df2-a448-9b802446128e', // Rye Flour
      '0427b203-a960-4d28-9210-d304fcaaacbc', // Bread Flour Molini
      'b66239b2-4681-4d5a-ac74-ff69ea505b5a', // Brown Bread Flour
      'fd363a34-98e6-4a7d-81e2-d777049c8dbb', // Flour Tortilla 6 inch
      'adb97878-d5b5-4151-8051-0e84f78d75f0', // Brown Jumbo Bread - Sliced
      'dfb7fb82-7116-4b4c-a03d-902399a6e556', // White Jumbo Bread - Sliced
      'ee38abfe-cf84-4ed6-9f3e-218e10860045', // Multigrain Rye Sourdough - Sliced
      '5e59633f-e9b2-426c-9966-1a320c580bac', // Rye Sourdough - Sliced
      'wholeWheatFlour', // Whole Wheat Flour
      'breadcrumbs', // Breadcrumbs
      '9a0d6ae5-ffff-4d53-941a-9ed2d7c435aa', // Lentils Red
      'chickpeas', // Chickpeas Can
      '8b282008-df52-4ec8-be31-3a7645a509f3', // Cabbage White
      'cabbage', // Cabbage Red
      'cauliflower', // Cauliflower
      'soySauce' // Soy Sauce
    ],
    [Kitchen.SA1]: [
      // Chilli
      '0a70c4a1-fddb-40ae-bfde-4a2d5ff4176b', // Wasabi Paste
      'b80fd9af-d4c5-494a-a29b-b64662dace03', // Cayenne Pepper
      'da746683-90a7-4dc2-a220-f9bb56af6ff2', // Teriyaki Sauce
      '9f87d953-4749-428c-8f8e-611e537b8757', // Poblano Pepper
      '12365687-59b9-49b5-b6fe-2f6725c74c72', // Chilli Flakes
      'f04c01c0-3608-46cd-a2e3-454e3b177b44', // Gochujang Paste
      '4178140b-657e-4af4-9579-ae477267de90', // Sriracha
      'b5802e8e-c3f8-47c4-9b25-6b6a79973733', // Jalapeno Fresh
      '5355e811-5639-4464-bd3a-65dfb30f41e9', // Chili Green Fresh
      '24599f8a-17d6-42c6-9b96-ff4cfd5c4bae', // Cottage Cheese
      '92597d84-92c7-40f5-893f-ae507b6fa582', // Low Fat Whipped Cream
      '15cde19c-ceab-4e4c-9e70-dd08d6fd827c', // Fat Free Cream Cheese
      '0e374d8d-c0cf-4257-92d4-153580ff68de', // Goat Cheese
      '83e489b0-976b-48ee-b417-bc83d246bc61', // Greek Yogurt
      '28505d5c-42ec-4221-a047-fd941c32a355', // Mascarpone
      '49b23c66-1632-48e3-8a92-d06414313841', // Yogurt Full Fat
      '241186bd-496f-448a-86d7-9d0c91c9d11b', // Cream Cheese
      '0841e420-d897-40e2-bd4c-fa7103487a8b', // Mozzarella
      '067f42db-35c4-4ce8-ad31-66e3bade3135', // Labneh
      '77c43647-9bc4-4d5f-99dd-384cfc50fbd0', // Milk Full Fat
      // Kefir Milk - Fermendiet
      '2206d238-63ee-4fd3-9d94-5177be2da75d', // Wheat Flour
      '97ea1788-e3d9-4e5f-b595-8eefe7a37468', // Wheat Tortilla 6.5" or 8"
      '3b24316b-795c-4073-b3a3-c69a69f16f37', // Rye Flour
      '7e7abf9a-0b2b-44dc-99a9-d80db9aaee36', // Bread Flour Molini
      '3967eff5-44c5-457f-8d6a-fd816c93894a', // Brown Bread Flour
      'ae649cfe-4260-47b4-ad4e-7c8f0c522a3b', // Flour Tortilla 6 inch
      '692e1afa-0063-4d29-a2f3-47aacb0a8412', // Brown Jumbo Bread - Sliced
      '8a8bc4a6-0026-4482-a757-7ff993d6c1bd', // White Jumbo Bread - Sliced
      // Multigrain Rye Sourdough - Sliced
      // Rye Sourdough - Sliced
      '4aba49bf-bc0b-4d83-9645-e96f290b3182', // Whole Wheat Flour
      '5148f6dd-2133-44f3-b618-96a62c1a0efb', // Breadcrumbs
      '9fa44af1-924d-4c24-9b47-831057ab90e9', // Lentils Red
      'd3df63fc-e863-490a-8b67-825a89353e28', // Chickpeas Can
      '50616e73-4efb-4ba5-84b7-cbda5037efa4', // Cabbage White
      'b4eca8fc-f760-498b-833e-dda092638c7c', // Cabbage Red
      'd8a6d23c-9593-4979-8fc0-f3e96aefb2fd', // Cauliflower
      'c8061e16-382e-458e-9a87-661be14b4ed3' // Soy Sauce
    ],
    [Kitchen.SA2]: [
      '9439bc38-60ba-4fb5-aa0c-5175198bffa3', // Sriracha
      'ef4d6a68-693c-4569-bd1f-6e6d524b935b', // Wasabi Paste
      'cb4b6c94-f6a1-4343-8683-4e46b03db5d0', // Cayenne Pepper
      '9c9d1b59-cc5b-4283-a232-a8e487c7d739', // Teriyaki Sauce
      'b53ab9b4-c6e4-452e-93d6-1805dd334c3d', // Poblano Pepper
      'baeb20fc-9abd-4657-af70-0e3f36454524', // Chilli Flakes
      'd0d23a8c-0c56-4886-9f19-c421cbe74112', // Gochujang Paste
      '9439bc38-60ba-4fb5-aa0c-5175198bffa3', // Sriracha
      '774b074c-7d3b-4a3c-a7d9-0d8495971aae', // Jalapeno Fresh
      '02d982b1-943a-4bea-92aa-63805417876d', // Chili Green Fresh
      'baeb20fc-9abd-4657-af70-0e3f36454524', // Chilli Flakes
      'd0d23a8c-0c56-4886-9f19-c421cbe74112', // Gochujang Paste
      '9439bc38-60ba-4fb5-aa0c-5175198bffa3', // Sriracha
      '774b074c-7d3b-4a3c-a7d9-0d8495971aae', // Jalapeno Fresh
      '02d982b1-943a-4bea-92aa-63805417876d', // Chili Green Fresh
      'e73a98b4-ded8-42eb-827d-2a7ccf843d10', // Turkey Breast
      '636ed64d-8b82-4d8d-a0f4-ab662395a0d3', // Low Fat Whipped Cream
      'cf32f12f-f849-423d-ba85-b1236b225c7b', // Fat Free Cream Cheese
      '785c7f7f-85bf-449e-b799-2f1e9f217fcc', // Goat Cheese
      'ff3621b1-099c-4c42-90a8-e50d36b4655b', // Greek Yogurt
      'eb076a7d-8b18-43c9-80ba-99879860f00e', // Mascarpone
      '4e2451f9-2baf-4f75-835a-bdc986e678ec', // Yogurt Full Fat
      'b95b5eb4-8c2b-44f0-b18a-6567f0c186ae', // Cream Cheese
      '78e376d8-216f-436e-b49e-e05e28b8bf3b', // Mozzarella
      '43ba7ccd-0ad3-469b-b2f4-7b6b7d529505', // Labneh
      'c8e99b6b-a17d-4ff9-914b-3a3fdb538cd9', // Milk Full Fat
      // Kefir Milk - Fermendiet (ID not provided)
      '85243d6c-2889-400b-b9c3-3c79068d2f2e', // Wheat Flour
      '2bd266a1-4730-41c7-bd88-b103beff1861', // Wheat Tortilla 6.5" or 8"
      'e7e00814-5931-4463-a779-9ffa6424cbb2', // Rye Flour
      '544b10fb-1595-4fb3-9853-9e20c8b34124', // Bread Flour Molini
      '0bbacd40-5f89-4fbc-88bf-c006c6906619', // Brown Bread Flour
      '93bc7737-7a36-497c-a442-bc7052c9212f', // Flour Tortilla 6 inch
      '479b9b58-6281-4fb4-b73f-071ffeca37dd', // Brown Jumbo Bread - Sliced
      'bfd356a2-3bad-4912-997c-8c7aac6174b4', // White Jumbo Bread - Sliced
      // Multigrain Rye Sourdough - Sliced (ID not provided)
      // Rye Sourdough - Sliced (ID not provided)
      'eaa49600-5bec-4994-bc96-540cf47183b2', // Whole Wheat Flour
      'd8e5e4c9-8f30-4b28-8385-08594f7db334', // Breadcrumbs
      'ec19f0d1-a314-4872-8b0c-07781b5b4404', // Lentils Red
      '3c2f6b2e-67ca-4465-853a-135561cf0fa7', // Chickpeas Can
      '1221d01a-3174-4ce8-b0cf-350df1cdb96f', // Cabbage White
      'e0bb11db-4cb5-4028-83c6-9127ace88ab4', // Cabbage Red
      '948ca429-7918-409e-a87d-7bdc25ad55c9', // Cauliflower
      'd90faca6-f05a-4a7a-b5fd-645a08df39bb' // Soy Sauce
    ],
    [Kitchen.AE1]: [
      '5b786170-3efa-4de3-9dff-e51734f08de9', // Chilli
      'd4ce8233-8786-4214-9418-fef77bbf8fb4', // Wasabi Paste
      '5e464a50-4714-4c76-8c7d-02070191b861', // Cayenne Pepper
      'e3a94ad6-8179-4c14-8600-fa785ef5c079', // Teriyaki Sauce
      'df250be0-515b-4ec3-8300-bbd919e6d1e2', // Poblano Pepper
      'cdfdbe92-9a28-4f97-9aea-179691f2141d', // Chilli Flakes
      '72fae7c7-0f40-4dae-a8bb-4f637e7d1c00', // Gochujang Paste
      'ad73c8fe-c763-43c6-8c76-0223af7f56ce', // Sriracha
      'c7a0fafc-faa8-4d20-afcc-13e4c70efd9c', // Jalapeno Fresh
      '428a3e59-8007-4f1e-8294-7bf05a1e28e2', // Chili Green Fresh
      'b4b9df10-d978-42bf-ad95-e10861d778ee', // Cottage Cheese
      '4749c55b-f568-4106-b9e6-70bc49d6d37e', // Low Fat Whipped Cream
      'eb0f92d7-2cee-4872-8c34-6092daacbd66', // Fat Free Cream Cheese
      '4edfcde6-52d3-42ec-98bf-21227a43a941', // Goat Cheese
      '57e3ed89-711e-4ce7-8fd3-102fcc1decfc', // Greek Yogurt
      '6b4032f8-57d5-49e7-bbbd-1740aea956ef', // Mascarpone
      '74650dc0-bfab-41e5-ac47-675b790c679e', // Yogurt Full Fat
      'd325ad9b-e345-400d-bcc5-c05bccac1f37', // Cream Cheese
      '5efa4813-b42d-417a-a7dc-06914cd7a826', // Mozzarella
      '46985777-256a-4640-a48d-58f93fb670cf', // Labneh
      '0d103d94-6b41-4eeb-99b9-8dfe5c1e1d81', // Milk Full Fat
      // Kefir Milk - Fermendiet
      '32162d93-2b8e-40e6-b1fd-2a1724ba0bd6', // Wheat Flour
      '0b3b4991-dd91-4ad1-add4-68a869779901', // Wheat Tortilla 6.5" or 8"
      'bc644366-3104-4d0f-9de4-e3742565633b', // Rye Flour
      '364d823d-44ee-47d9-bf69-cce9f5a17278', // Bread Flour Molini
      'ae2056cd-5963-4a36-aa2c-079e310a1bf1', // Brown Bread Flour
      'b549bc7b-e69a-40c8-95c0-935e9a4824c9', // Flour Tortilla 6 inch
      '60da5162-2671-47ea-909e-88cacd301280', // Brown Jumbo Bread - Sliced
      '988683b0-149a-410e-90c1-6a3be0d16677', // White Jumbo Bread - Sliced
      // Multigrain Rye Sourdough - Sliced
      // Rye Sourdough - Sliced
      '88752982-0cbe-4c91-bdcc-0509294c4ba3', // Whole Wheat Flour
      'c67da99b-a948-4769-989e-5dede76a6e78', // Breadcrumbs
      'd689f2d6-6215-4d2f-8bc0-1314a54fef7f', // Lentils Red
      '8f5bf801-c266-49ae-a71a-b191e7cc3de5', // Chickpeas Can
      'b669a793-ee90-4010-a91b-1541eeac976b', // Cabbage White
      'ac366895-9dba-4b3d-8817-fc774c69fb25', // Cabbage Red
      '80e45f7c-5037-46c0-959a-0ccf47a29bc5', // Cauliflower
      'cd6c5b47-01de-4308-8823-c246fae6ab0e' // Soy Sauce
    ],
    [Kitchen.KW1]: [
      // Chilli
      '413e5769-46a8-4165-86fd-91c82ff60864', // Wasabi Paste
      'd2b77032-2098-48f7-9588-1c220045dd1f', // Cayenne Pepper
      'c0bfdfdf-0a89-4016-923f-073f13bf3cbe', // Teriyaki Sauce
      '2bf29376-2393-4809-ae73-ec65cf5a3135', // Poblano Pepper
      '8122af2f-7c75-4ade-9c2c-fd9fb7636f08', // Chilli Flakes
      '7194c6a0-a945-4601-bf7c-71eaf59ff5b7', // Gochujang Paste
      '8b3618a8-b2fe-4336-ac20-64cfc9cc9b52', // Sriracha
      '1bb41fbc-0767-40e9-8d6f-eee30c66105e', // Jalapeno Fresh
      '117babf9-d3fd-412b-87b7-89782b606bc5', // Chili Green Fresh
      '7e75f865-b0a9-4aa8-8992-5dc991884b43', // Cottage Cheese
      '358cef66-0efb-4c66-bb8b-2bffa2d6f1bb', // Low Fat Whipped Cream
      '7528e3ef-4eea-4fd2-89e0-daa5650a001e', // Fat Free Cream Cheese
      'ca3f84c8-dacc-451b-b0a4-9eb57d657ab5', // Goat Cheese
      // Greek Yogurt
      'e5be4c6e-536c-459a-99aa-cf99dce1e08b', // Mascarpone
      // Yogurt Full Fat
      '86255147-0fcf-4a59-aa9c-585c03173e23', // Cream Cheese
      '1e12e803-8f8a-4039-b67c-e12602fd9c44', // Mozzarella
      'fc6f0ee0-58b6-47b6-92f9-d25f7afcdf7f', // Labneh
      '440ea63f-4756-44e7-a260-900590486ddc', // Milk Full Fat
      // Kefir Milk - Fermendiet
      'fc4ff837-846e-426f-b8a8-25cc8cf581ce', // Wheat Flour
      '639f9346-0d5f-4f1b-b2ce-f5d572b5100d', // Wheat Tortilla 6.5" or 8"
      '2038d241-950e-4335-b2ae-7172a7e3efb2', // Rye Flour
      '5360744c-cb3d-468c-be53-2fbe63e40abd', // Bread Flour Molini
      'f8a4f084-af84-435e-8a53-aa925a9e2326', // Brown Bread Flour
      '5bf17c65-8134-4fa6-a81b-1584cc7ab15b', // Flour Tortilla 6 inch
      '8f0716f2-ac69-4864-ae2e-a24e860dfdd6', // Brown Jumbo Bread - Sliced
      '0a3eaa94-13cb-4052-a554-3d0908845799', // White Jumbo Bread - Sliced
      // Multigrain Rye Sourdough - Sliced
      // Rye Sourdough - Sliced
      'e7a26bd1-f6e3-4624-83d2-90705dce9984', // Whole Wheat Flour
      '701fc545-b74c-4c4b-926b-ca50aa4f80e1', // Breadcrumbs
      '80e34590-b6cc-422c-b11b-24bcb656a2d1', // Lentils Red
      '2233cffe-cfeb-49d0-adc5-0bd785c1ad19', // Chickpeas Can
      '3dee3acc-0fe7-4652-a8f4-664c39740cc0', // Cabbage White
      '3bef4ae3-85cb-481c-9a53-970d2d33989c', // Cabbage Red
      'f027b667-5e43-473f-8677-962a714e2dab', // Cauliflower
      'ef837e41-4ca6-4a43-9727-418c2a13c551' // Soy Sauce
    ],
    [Kitchen.OM1]: [],
    [Kitchen.QA1]: [
      // "Chilli",
      '0a7a806d-37c7-4e0f-aa42-1bc89243f4cb', // Wasabi Paste
      '3aea4495-c1f8-4cbd-b9a3-bab8cf36a03c', // Cayenne Pepper
      'bc399b2a-50db-4aa8-a9c2-ceefacb8139b', // Teriyaki Sauce
      '5f246ef7-c4a2-4c2b-8052-ae3d7f63068e', // Poblano Pepper
      '45284ada-ddc9-4476-8856-2f68e3edb82e', // Chilli Flakes
      '76e8c3b4-553f-444b-a393-b93aaa70753d', // Gochujang Paste
      '15e1efac-ca7c-412e-84ca-e587b4375eca', // Sriracha
      '9ab5ee73-946a-43ab-ab9a-e463f8639f49', // Jalapeno Fresh
      'e7051797-810e-49d6-845d-73181a024546', // Chili Green Fresh
      // "Cottage Cheese",
      // "Low Fat Whipped Cream",
      // "Fat Free Cream Cheese",
      // "Goat Cheese",
      // "Greek Yogurt",
      // "Mascarpone",
      // "Yogurt Full Fat",
      // "Cream Cheese",
      // "Mozzarella",
      // "Labneh",
      // "Milk Full Fat",
      // "Kefir Milk - Fermendiet",
      '4237528d-98e6-4567-855c-5e9971ba010c', // Wheat Flour
      'b6806fbb-7702-49aa-ac3d-e2dcf7adaa32', // Wheat Tortilla 6.5" or 8"
      '602feb96-b8d0-47ad-897b-59a7e6a377e9', // Rye Flour
      'f3879343-0c2a-466f-8eae-0a1d7deded6f', // Bread Flour Molini
      '09b7fd39-736d-4926-9728-b33a36975f60', // Brown Bread Flour
      '322e6434-e431-4d88-ba58-b8e2dcdc7e37', // Flour Tortilla 6 inch
      // "Brown Jumbo Bread - Sliced",
      // "White Jumbo Bread - Sliced",
      // "Multigrain Rye Sourdough - Sliced",
      // "Rye Sourdough - Sliced",
      'f8d8f14d-f1da-48f0-92be-d91461714409', // Whole Wheat Flour
      '55bbcefd-e726-4464-859a-14d51118d433', // Breadcrumbs
      'e5138d27-28f6-4988-bdcb-edd1b6c4b3c5', // Lentils Red
      'e01227ac-8b85-469a-90a0-094af255d949', // Chickpeas Can
      'eabf0190-80fb-4aba-9d91-fb3e19395ef3', // Cabbage White
      'ebfe9e31-0667-402e-af35-28eff8f2fb76', // Cabbage Red
      '66f94395-ecd3-49e2-a230-2b706245d121', // Cauliflower
      'b404d691-ba74-431e-98ca-dc9264bec681' // Soy Sauce
    ]
  },
  [HealthConditions.THYROID]: {
    [Kitchen.BH1]: [
      '2c5dd3c0-cff9-4d0e-bd03-eefb935df613', // Soya Bean Wrappers Goma
      '157b04e7-f641-4f10-811c-e9565fb8bbce', // Tofu Firm
      'soyMilk', // Soy Milk
      'soySauce', // Soy Sauce
      'walnutOil', // Walnut Oil
      'walnuts' // Walnuts
    ],
    [Kitchen.SA1]: [
      '911a9576-b0b3-45b1-954c-e1734c01475e', // Soya Bean Wrappers Goma
      '36698bc5-4efb-44fd-bfdc-42a330b3d20e', // Tofu Firm
      '62a13f75-65bb-44ee-8f1a-bc1b830364a6', // Soy Milk
      'c8061e16-382e-458e-9a87-661be14b4ed3', // Soy Sauce(Note: Repeated entry)
      '4e047691-56e6-47a1-9cc7-980f1c4d4e2c', // Walnut Oil
      '82750bd3-653c-4b0f-b3a5-48a7655fe3bb' // Walnuts
    ],
    [Kitchen.SA2]: [
      '9af6e559-0137-4272-b70d-6afac1665b11', // Soya Bean Wrappers Goma
      '8689b6e9-cb97-4460-acdd-53a6be55ebb6', // Tofu Firm
      'b167c830-7aad-4bce-9c4e-8ae07a6a3b42', // Soy Milk
      'd90faca6-f05a-4a7a-b5fd-645a08df39bb', // Soy Sauce
      'e63509bb-aabc-4d06-91c6-60d34ee9e1eb', // Walnut Oil
      '29ab1b26-3e8c-475b-a3ea-99c60ef8370d' // Walnuts
    ],
    [Kitchen.AE1]: [
      '87613c24-a17f-4fba-bcbf-eae7d22dd9dc', // Soya Bean Wrappers Goma
      '584c363a-7f01-4a7f-831f-bbb0fc14e4ba', // Tofu Firm
      '039857ae-d052-47ec-afd1-f44ba6494648', // Soy Milk
      'cd6c5b47-01de-4308-8823-c246fae6ab0e', // Soy Sauce
      '670cef58-0523-4c0a-b20a-a866be15c264', // Walnut Oil
      'f1637ff7-8841-45d5-a9f6-d268846d4dd2' // Walnuts
    ],
    [Kitchen.KW1]: [
      'e4f9b9a2-ab11-460e-85ad-f062481c5243', // Soya Bean Wrappers Goma
      '6f6de2e3-610a-47d6-af64-1abb392fab61', // Tofu Firm
      '6b9ea78d-6cf6-45ea-9c85-2a167391f93c', // Soy Milk
      'ef837e41-4ca6-4a43-9727-418c2a13c551', // Soy Sauce
      '7978eea2-e63e-4463-88bd-e8fedfe0ef07', // Walnut Oil
      '0dad4de0-e8a4-4caf-b70d-62c249a244be' // Walnuts
    ],
    [Kitchen.OM1]: [],
    [Kitchen.QA1]: [
      '494879e1-13c9-42de-b992-e63185441c97', // Soya Bean Wrappers Goma
      '78b8dd59-277c-4cf7-9768-ebd72e256248', // Tofu Firm
      'ab675da9-1ae5-4c35-ae96-2b9fc6b73327', // Soy Milk
      'b404d691-ba74-431e-98ca-dc9264bec681', // Soy Sauce
      '993d9a66-fc0a-4256-8415-97ac030008c3', // Walnut Oil
      '066da1d9-3ddb-4f75-a4cd-c3428862c9f4' // Walnuts
    ]
  },
  [HealthConditions.HIGH_URIC_ACID]: {
    [Kitchen.BH1]: [
      'turkeyBacon', // Turkey Bacon
      'turkeyBreast', // Turkey Breast
      'cb2f62f0-128d-4beb-bd63-8d3011faac22', // Beef Bacon
      'd5f6396a-a3f6-4065-95f5-8182fed642fe', // Beef Tenderloin
      '9fa7033e-39e6-4976-9528-2e8a802a0293', // Beef Minced
      '5f4ee3be-ff9d-46d7-bef9-50fc42c5574e', // Beef Fat
      '3b0e58aa-8164-4088-835c-a16af292c10f', // Beef Brisket
      'b7868d27-4c6a-43ba-9110-0f045395f2e9', // Lamb Fat
      'lamb', // Lamb Leg
      '7c1c5c78-c59c-4b23-a411-67710c6c3ab2', // Salmon Frozen
      'shrimp', // Shrimp Frozen 30/40
      '7c1c5c78-c59c-4b23-a411-67710c6c3ab2', // Salmon Frozen (Duplicate)
      '7c1c5c78-c59c-4b23-a411-67710c6c3ab2', // Salmon Fresh (Duplicate)
      '4b055ec0-2a16-4d15-8675-be6ef390be89' // Tuna Loin
    ],
    [Kitchen.SA1]: [
      'a6995060-71b6-418b-bb61-63fd9548dc78', // Turkey Bacon
      '31f8b0c9-d609-4c10-859c-1cc3c0959b15', // Turkey Breast
      '923898e5-00b1-4dc2-8050-185f3b4750ab', // Beef Bacon
      '6de1797c-f813-4b9e-a5b9-9331daabf834', // Beef Tenderloin
      'c1a29231-e2ee-40ce-b669-234af292324b', // Beef Minced
      '8ad7bc47-8d7f-4969-acf6-526640fbfe33', // Beef Fat
      'ec3660a0-29f4-4fe9-8bfb-ee1aece6fd99', // Beef Brisket
      'd4a52592-86c0-4a91-8011-305efd9932aa', // Lamb Fat
      '82580488-d5ec-41da-928c-8183efa4206b', // Lamb Leg
      '2859aa6a-c68d-4f5e-86d3-fe103c62bf16', // Salmon Frozen
      '0c982b93-d2e0-4a07-b0c1-f1883502bdb7', // Shrimp Frozen 30/40
      '5b94f911-0316-4c38-b3ad-16b2e356a4b8' // Salmon Fresh
      // Tuna Loin
    ],
    [Kitchen.SA2]: [
      '280245c8-59d2-48bd-9c3e-2a217a4f4acc', // Turkey Bacon
      'e73a98b4-ded8-42eb-827d-2a7ccf843d10', // Turkey Breast
      '5b17f6c9-6182-40e0-ab95-f2166dc75041', // Beef Bacon
      'f0f4d590-5d37-4622-b652-4dce58ed6b9c', // Beef Tenderloin
      '571654f7-6e6e-4d3a-b725-49c692ec5c23', // Beef Minced
      '73f4b63c-ef83-45eb-8427-8cdb3999db2e', // Beef Fat
      'b5861c55-c866-4545-9121-e306e8d9d7f2', // Beef Brisket
      '6de3ff5a-facd-4238-ae5f-ec17e7a69789', // Lamb Fat
      '09789bd7-5394-4a4e-9e02-67de1063da08', // Lamb Leg
      '54369670-b1ad-4a19-abfa-b1b36328b54c', // Salmon Frozen
      '0bc699b5-3cc3-4ae5-958e-d0b9d7260f27', // Shrimp Frozen 30/40
      '4b24c8c6-fb2e-4a30-8cd4-ff6bbd556843' // Salmon Fresh
      // Tuna Loin (ID not provided)
    ],
    [Kitchen.AE1]: [
      'fa2446bd-9842-4e18-ae62-5168605706c6', // Turkey Bacon
      '47aa38b1-4b32-41d9-9d35-85742c356d00', // Turkey Breast
      '6f8f036f-ea58-486b-a45e-de1fc6a1a181', // Beef Bacon
      'e7695e9f-8cf9-49f5-9ad7-2b2d230d2502', // Beef Tenderloin
      '345de98e-2fbe-4167-8958-029387f0ed13', // Beef Minced
      '919a59a0-48da-45c8-9d46-6922275583e8', // Beef Fat
      '259f96df-6c82-47b1-ad3a-245a1a3546a6', // Beef Brisket
      '8b69f6fe-2a08-40c9-b4a6-66912dfbdea0', // Lamb Fat
      '486d4368-235e-4f2f-96ac-e5e7d3a459fb', // Lamb Leg
      '011a2d67-25a6-4617-aedc-4e98f2faf2fe', // Salmon Frozen
      '66cb2ad2-a1db-4633-9ea1-dcbc0cb5a9d7', // Shrimp Frozen 30/40
      '9fe83d3b-d16a-4564-9410-da50c3133bad', // Salmon Fresh
      '3148c52c-06fb-429f-8122-ffd8b14ee9b5' // Tuna Loin
    ],
    [Kitchen.KW1]: [
      '39357d33-06e7-4a5c-96db-927060ab6c90', // Turkey Bacon
      'bb879766-ed59-421f-a193-72134a8fb0aa', // Turkey Breast
      '885b5bdc-b7d1-467e-99c6-ca21be3399a6', // Beef Bacon
      'd3bc778f-fcd5-4653-ba6b-fbdc216c562f', // Beef Tenderloin
      'c1d4bf7b-c1d1-4ff8-9f58-f18ddef63209', // Beef Minced
      'd2c97b2e-903f-4315-ba0c-30bd44346940', // Beef Fat
      'ea84f9bc-2469-4935-8d9d-6199d00e2ac1', // Beef Brisket
      'a0af5859-9e94-44ff-bf1c-980927492ff6', // Lamb Fat
      'fda53f7c-a97e-427f-a208-3f00a7bae715', // Lamb Leg
      'fd1df9a0-0fc4-45e3-86b1-58aafde27a27', // Salmon Frozen
      '6a0f286e-bfad-41a2-a57a-bc19739e25b5', // Shrimp Frozen 30/40
      // Salmon Fresh
      // Salmon Fresh (duplicate in the list)
      '6aa7abbb-5bd7-4f8f-8ae2-51969f72540f' // Tuna Loin
    ],
    [Kitchen.OM1]: [],
    [Kitchen.QA1]: [
      '8f469b0c-c9d7-42f2-a8f1-bc27e4547ae1', // Turkey Bacon
      'fe4660d7-99a2-44ae-9f35-69f7dea12841', // Turkey Breast
      '92ec0229-df80-4426-8fa4-e7a5ab19c1b2', // Beef Bacon
      '204a26d6-184c-4375-83b5-da8182683a0f', // Beef Tenderloin
      '89ffa231-d780-4b38-ae0a-2e3af2798ee2', // Beef Minced
      '0e606e25-6a7b-44b9-9054-c2263e91f278', // Beef Fat
      '15b76fbe-4f03-4572-a3db-c5f016cb2347', // Beef Brisket
      // "Lamb Fat",
      '5ad24ea9-cc5a-4eb8-8e4a-7c2b4d619aa6', // Lamb Leg
      '257efd1a-b125-4da0-a1c2-075ffe1931e6', // Salmon Frozen
      '482e553e-302e-40be-b8bd-3722cf0bd5f2', // Shrimp Frozen 30/40
      // "Salmon Frozen",
      '4e8895c8-30f4-4de8-ac44-34c77d0b743f' // Salmon Fresh
      // "Tuna Loin",
    ]
  },
  [HealthConditions.ANEMIA]: {
    [Kitchen.BH1]: [
      'blackTea', // Black Tea
      'coffee', // Coffee Instant 3in1
      '945afbe7-94ed-45e7-96fe-1ff21f36c5bd', // Coffee Arabic
      'a52f334b-04f5-4c8a-b9e7-6eb945672757', // Coffee Powder
      '3f14b2c8-0d29-4e23-adfb-63f39048c8d1', // Cold Brew Concentrated
      'cottageCheese', // Cottage Cheese
      'lowFatWhippedCream', // Low Fat Whipped Cream
      'fatFreeCreamCheese', // Fat Free Cream Cheese
      'goatCheese', // Goat Cheese
      'greekYogurt', // Greek Yogurt
      '22a1678d-0ed4-407c-a053-b77edb8bf5fe', // Mascarpone
      'yogurt', // Yogurt Full Fat
      'creamCheese', // Cream Cheese
      'lowFatMozzarella', // Mozzarella
      'labneh', // Labneh
      'milk', // Milk Full Fat
      '7df90cb3-42a3-4edd-a5c0-a156437b33b5' // Kefir Milk - Fermendiet
    ],
    [Kitchen.SA1]: [
      '24c8405a-e86f-4267-99a9-56ac639a2a18', // Black Tea
      'df095f78-3612-41bf-843a-40b08372b214', // Coffee Instant 3in1
      'ea5ca20a-d8e8-42f7-8c2f-1c4b2bd66fca', // Coffee Arabic
      'ab577431-b5de-4ffe-be24-fa2d7d12cef7', // Coffee Powder
      'f18342ab-3392-4595-b780-155da631987b', // Cold Brew Concentrated
      '24599f8a-17d6-42c6-9b96-ff4cfd5c4bae', // Cottage Cheese
      '92597d84-92c7-40f5-893f-ae507b6fa582', // Low Fat Whipped Cream
      '15cde19c-ceab-4e4c-9e70-dd08d6fd827c', // Fat Free Cream Cheese
      '0e374d8d-c0cf-4257-92d4-153580ff68de', // Goat Cheese
      '83e489b0-976b-48ee-b417-bc83d246bc61', // Greek Yogurt
      '28505d5c-42ec-4221-a047-fd941c32a355', // Mascarpone
      '49b23c66-1632-48e3-8a92-d06414313841', // Yogurt Full Fat
      '241186bd-496f-448a-86d7-9d0c91c9d11b', // Cream Cheese
      '0841e420-d897-40e2-bd4c-fa7103487a8b', // Mozzarella
      '067f42db-35c4-4ce8-ad31-66e3bade3135', // Labneh
      '77c43647-9bc4-4d5f-99dd-384cfc50fbd0' // Milk Full Fat
      // Kefir Milk - Fermendiet
    ],
    [Kitchen.SA2]: [
      'aaf51cde-9178-46cf-9ae1-e619baf0c62a', // Black Tea
      '6d69070f-ce13-4fe9-b8a1-adb850f654e4', // Coffee Instant 3in1
      'cc748883-23e7-4aca-af04-0964ac8643b1', // Coffee Arabic
      'aaa25d62-4e4d-488a-8ba8-15579e34bee4', // Coffee Powder
      'a86f70d6-9f90-4607-b171-4887b22379c6', // Cold Brew Concentrated
      '5d10b08f-c83f-49b2-a098-5b540aa2b744', // Cottage Cheese
      '636ed64d-8b82-4d8d-a0f4-ab662395a0d3', // Low Fat Whipped Cream
      'cf32f12f-f849-423d-ba85-b1236b225c7b', // Fat Free Cream Cheese
      '785c7f7f-85bf-449e-b799-2f1e9f217fcc', // Goat Cheese
      'ff3621b1-099c-4c42-90a8-e50d36b4655b', // Greek Yogurt
      'eb076a7d-8b18-43c9-80ba-99879860f00e', // Mascarpone
      '4e2451f9-2baf-4f75-835a-bdc986e678ec', // Yogurt Full Fat
      'b95b5eb4-8c2b-44f0-b18a-6567f0c186ae', // Cream Cheese
      '78e376d8-216f-436e-b49e-e05e28b8bf3b', // Mozzarella
      '43ba7ccd-0ad3-469b-b2f4-7b6b7d529505', // Labneh
      'c8e99b6b-a17d-4ff9-914b-3a3fdb538cd9' // Milk Full Fat
      // Kefir Milk - Fermendiet (ID not provided)
    ],
    [Kitchen.AE1]: [
      'c88651ac-2491-4f5e-b124-b5276e7a4924', // Black Tea
      '7cc469d3-25e9-41d3-8cc3-f65f48387ea6', // Coffee Instant 3in1
      '0bf7144a-84e2-43ec-a708-794702844729', // Coffee Arabic
      '62b5e2f8-e793-40ed-8b33-d296e397d903', // Coffee Powder
      // Cold Brew Concentrated
      'b4b9df10-d978-42bf-ad95-e10861d778ee', // Cottage Cheese
      '4749c55b-f568-4106-b9e6-70bc49d6d37e', // Low Fat Whipped Cream
      'eb0f92d7-2cee-4872-8c34-6092daacbd66', // Fat Free Cream Cheese
      '4edfcde6-52d3-42ec-98bf-21227a43a941', // Goat Cheese
      '57e3ed89-711e-4ce7-8fd3-102fcc1decfc', // Greek Yogurt
      '6b4032f8-57d5-49e7-bbbd-1740aea956ef', // Mascarpone
      '74650dc0-bfab-41e5-ac47-675b790c679e', // Yogurt Full Fat
      'd325ad9b-e345-400d-bcc5-c05bccac1f37', // Cream Cheese
      '5efa4813-b42d-417a-a7dc-06914cd7a826', // Mozzarella
      '46985777-256a-4640-a48d-58f93fb670cf', // Labneh
      '0d103d94-6b41-4eeb-99b9-8dfe5c1e1d81' // Milk Full Fat
      // Kefir Milk - Fermendiet
    ],
    [Kitchen.KW1]: [
      '02ff6e94-a731-406b-90bf-8936e79905be', // Black Tea
      '6e48b41e-2f08-4d74-ba46-363dcde031a2', // Coffee Instant 3in1
      'a0cfc97b-4ec6-448d-9f59-fa58df322602', // Coffee Arabic
      '9174fcbe-a02f-45c1-bf28-3c78f25c0efd', // Coffee Powder
      // Cold Brew Concentrated
      '7e75f865-b0a9-4aa8-8992-5dc991884b43', // Cottage Cheese
      '358cef66-0efb-4c66-bb8b-2bffa2d6f1bb', // Low Fat Whipped Cream
      '7528e3ef-4eea-4fd2-89e0-daa5650a001e', // Fat Free Cream Cheese
      'ca3f84c8-dacc-451b-b0a4-9eb57d657ab5', // Goat Cheese
      // Greek Yogurt
      'e5be4c6e-536c-459a-99aa-cf99dce1e08b', // Mascarpone
      // Yogurt Full Fat
      '86255147-0fcf-4a59-aa9c-585c03173e23', // Cream Cheese
      '1e12e803-8f8a-4039-b67c-e12602fd9c44', // Mozzarella
      'fc6f0ee0-58b6-47b6-92f9-d25f7afcdf7f', // Labneh
      '440ea63f-4756-44e7-a260-900590486ddc' // Milk Full Fat
      // Kefir Milk - Fermendiet
    ],
    [Kitchen.OM1]: [],
    [Kitchen.QA1]: [
      'fbace38b-f789-4e5d-966c-6b296b2493d6', // Black Tea
      'b54ff57f-b0fe-47f3-939e-384c1b1d0908', // Coffee Instant 3in1
      '6fd4c151-6c22-475a-ac50-0080535cc719', // Coffee Arabic
      '6754c945-1de3-4e6c-a784-df8cba881123' // Coffee Powder
      // "Cold Brew Concentrated",
      // "Cottage Cheese",
      // "Low Fat Whipped Cream",
      // "Fat Free Cream Cheese",
      // "Goat Cheese",
      // "Greek Yogurt",
      // "Mascarpone",
      // "Yogurt Full Fat",
      // "Cream Cheese",
      // "Mozzarella",
      // "Labneh",
      // "Milk Full Fat",
      // "Kefir Milk - Fermendiet",
    ]
  },
  [HealthConditions.G6PD]: {
    [Kitchen.BH1]: [
      '9a0d6ae5-ffff-4d53-941a-9ed2d7c435aa', // Lentils Red
      'chickpeas' // Chickpeas Can
    ],
    [Kitchen.SA1]: [
      '9fa44af1-924d-4c24-9b47-831057ab90e9', // Lentils Red
      'd3df63fc-e863-490a-8b67-825a89353e28' // Chickpeas Can
    ],
    [Kitchen.SA2]: [
      'ec19f0d1-a314-4872-8b0c-07781b5b4404', // Lentils Red
      '3c2f6b2e-67ca-4465-853a-135561cf0fa7' // Chickpeas Can
    ],
    [Kitchen.AE1]: [
      'd689f2d6-6215-4d2f-8bc0-1314a54fef7f', // Lentils Red
      '8f5bf801-c266-49ae-a71a-b191e7cc3de5' // Chickpeas Can
    ],
    [Kitchen.KW1]: [
      '80e34590-b6cc-422c-b11b-24bcb656a2d1', // Lentils Red
      '2233cffe-cfeb-49d0-adc5-0bd785c1ad19' // Chickpeas Can
    ],
    [Kitchen.OM1]: [],
    [Kitchen.QA1]: [
      'e5138d27-28f6-4988-bdcb-edd1b6c4b3c5', //"Lentils Red"
      'e01227ac-8b85-469a-90a0-094af255d949' //"Chickpeas Can"
    ]
  }
};
