import { FoodMenuLabelsTags } from '@calo/dashboard-types';
import DateFnsAdapter from '@date-io/date-fns';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { caloTheme } from 'assets/images/theme/calo';
import { isWithinInterval, parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import { startCase } from 'lodash-es';
import { useState } from 'react';

interface DateRangePopupProps {
  selectedMenuTag: FoodMenuLabelsTags | undefined;
  onClose: (event: any) => void;
  handleAddingFoodMenuTags: (value: any) => void;
  selectedDateRange: { startDate: string | null; endDate: string | null };
  setSelectedDateRange: (value: { startDate: string | null; endDate: string | null }) => void;
  menuLabels: { startDay: string; endDay: string }[];
}

const DateRangePopup = ({
  handleAddingFoodMenuTags,
  setSelectedDateRange,
  onClose,
  selectedDateRange,
  selectedMenuTag,
  menuLabels
}: DateRangePopupProps) => {
  const [openStartDatePicker, setOpenStartDatePicker] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);

  const isDateDisabled = (date: Date) => {
    return menuLabels.some((label) =>
      isWithinInterval(date, {
        start: parseISO(label.startDay),
        end: parseISO(label.endDay)
      })
    );
  };

  return (
    <Box sx={{ flexDirection: 'column' }}>
      <Stack>
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '32px',
            letterSpacing: '-0.15px'
          }}
        >
          {`Select the time range for tagging this meal as ${startCase(selectedMenuTag === FoodMenuLabelsTags.UPGRADED ? 'Improved' : selectedMenuTag)}`}
        </Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', my: 3 }}>
        <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ width: '100%' }}>
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            label={'Start Date'}
            minDate={new Date()}
            open={openStartDatePicker}
            onOpen={() => setOpenStartDatePicker(true)}
            onClose={() => setOpenStartDatePicker(false)}
            value={selectedDateRange.startDate ? parseISO(selectedDateRange.startDate) : null}
            onChange={(date: Date | null) => {
              setSelectedDateRange({
                ...selectedDateRange,
                startDate: date ? format('yyyy-MM-dd')(date) : null
              });
            }}
            shouldDisableDate={isDateDisabled}
            InputProps={{ style: { borderRadius: '8px' } }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{
                  width: '100%',
                  borderRadius: 12,
                  marginRight: 21
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: '8px' }
                }}
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ width: '100%' }}>
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            label={'End Date'}
            disabled={!selectedDateRange.startDate}
            minDate={selectedDateRange.startDate ? parseISO(selectedDateRange.startDate) : undefined}
            value={selectedDateRange.endDate ? parseISO(selectedDateRange.endDate) : null}
            open={openEndDatePicker}
            onOpen={() => setOpenEndDatePicker(true)}
            onClose={() => setOpenEndDatePicker(false)}
            shouldDisableDate={isDateDisabled}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{
                  width: '100%',
                  borderRadius: 12
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: '8px' }
                }}
              />
            )}
            onChange={(date: Date | null) => {
              setSelectedDateRange({
                ...selectedDateRange,
                endDate: date ? format('yyyy-MM-dd')(date) : null
              });
            }}
          />
        </LocalizationProvider>
      </Stack>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'end' }}>
        <Button
          aria-label="new-menu-label-date-cancel"
          sx={{
            lineHeight: '17px',
            fontWeight: 600,
            fontSize: '14px',
            borderRadius: '8px',
            mr: 2,
            color: caloTheme.palette.primary.light,
            '&:hover': {
              color: caloTheme.palette.primary.light
            }
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          aria-label="filter-subscription-list"
          sx={{
            boxShadow: 'none',
            cursor: 'pointer',
            my: 'auto',
            '&:hover': {
              boxShadow: 'none',
              color: caloTheme.palette.white
            },
            color: caloTheme.palette.white,
            borderColor: caloTheme.palette.primary500,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              justifyItems: 'center',
              margin: 'auto',
              marginTop: 4,
              width: 'auto'
            }
          }}
          disabled={!selectedDateRange.startDate || !selectedDateRange.endDate}
          onClick={() => handleAddingFoodMenuTags(selectedDateRange)}
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
};

export default DateRangePopup;
