import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  exportButton: {
    textTransform: 'none',
    height: '45px',
    ml: 2,
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
    padding: '14px 20px 14px 20px',
    color: caloTheme.palette.neutral900,
    borderColor: caloTheme.palette.neutral900,
    '&:hover': {
      backgroundColor: caloTheme.palette.neutral100,
      borderColor: caloTheme.palette.neutral900
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      justifyItems: 'center',
      margin: 'auto',
      marginTop: 4,
      width: 'auto'
    }
  }
};

export default styles;
