import { CreateCouponReq } from '@calo/dashboard-types';
import { createCoupon } from 'actions/coupons';
import { Routes } from 'lib/enums';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import CouponForm from './CouponForm';

const NewCoupon = () => {
  const { mutateAsync: createMutation } = useMutation(createCoupon);

  const history = useHistory();

  const handleNewCoupon = async (values: Omit<CreateCouponReq, 'id'>) => {
    const coupon = await createMutation(values);
    history.replace(Routes.coupon.replace(':id', coupon.id));
  };

  return (
    <div className=" overflow-visible ">
      <CouponForm onSubmit={handleNewCoupon} />
    </div>
  );
};
export default NewCoupon;
