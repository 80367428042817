import { Permission, UpdateSubscriptionTrackersReq } from '@calo/dashboard-types';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { updateProgressTrackers } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import { useUserRoles } from 'lib/hooks';
import { isEqual } from 'lodash-es';
import { useState } from 'react';
import { useMutation } from 'react-query';
import useTrackersForm from './useTrackersForm';

interface TrackersFormProps {
  trackers: {
    water: number;
    weight: number;
    steps: number;
  };
  subscriptionId: string;
  date: string;
}

const TrackersForm = ({ trackers, subscriptionId, date }: TrackersFormProps) => {
  const roles = useUserRoles();
  const [trackersValue, setTrackersValue] = useState<{
    water: number;
    steps: number;
    weight: number;
  }>(trackers);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { mutateAsync: updateMutation } = useMutation(updateProgressTrackers);

  const onSubmit = async (data: UpdateSubscriptionTrackersReq) => {
    await updateMutation({
      subscriptionId,
      date,
      ...data
    });
  };

  const { values, handleBlur, setFieldValue, setValues, initialValues, isValid, errors } = useTrackersForm(
    trackersValue,
    onSubmit
  );

  const handleEditing = (value: boolean) => {
    if (isValid && !isEqual(values, initialValues)) {
      onSubmit({ ...values });
      setTrackersValue({
        water: +values.water,
        steps: +values.steps,
        weight: +values.weight
      });
    }
    setIsEditing(value);
  };

  const handleReset = () => {
    setValues(initialValues);
    setIsEditing(false);
  };

  return (
    <>
      <Box
        sx={{
          padding: 2,
          backgroundColor: caloTheme.palette.neutral50,
          borderRadius: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'auto',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column'
          }
        }}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '13px',
            lineHeight: '23px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Trackers
        </Typography>
        {isEditing ? (
          <Stack sx={{ justifyContent: 'end', flexDirection: 'row' }}>
            <CheckIcon sx={{ marginRight: 2 }} style={{ cursor: 'pointer' }} onClick={() => handleEditing(!isEditing)} />
            <ClearIcon style={{ cursor: 'pointer' }} onClick={handleReset} />
          </Stack>
        ) : (
          <Icon
            onClick={() => handleEditing(!isEditing)}
            name="edit2"
            size={6}
            style={{ cursor: 'pointer', width: '26px', height: '26px' }}
          />
        )}
      </Box>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          width: 'full',
          '& .MuiTextField-root': { my: 2 },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'full',
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTextField-root': {
              my: 2,
              mx: 1,
              width: '100%',
              justifyContent: 'space-between'
            }
          }
        }}
      >
        <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ gap: '16px' }}>
          <Stack display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
            <TextField
              required
              name="water"
              label="Water Intake (Cups) / 2 L (8 Cups)"
              placeholder="Enter Water Intake"
              id="exact-water"
              type="number"
              value={values.water}
              sx={{
                width: '100%'
              }}
              disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION_DAY_PROGRESS) || !isEditing}
              onBlur={handleBlur}
              onChange={(data) => setFieldValue('water', data.target.value)}
              inputProps={{
                inputProps: { style: { borderRadius: 8, width: '100%' } },
                style: { borderRadius: 8, width: '100%' },
                min: 0
              }}
            />
            {errors.water && <p className="text-red-300">Value must not be less that 0</p>}
          </Stack>
          <Stack display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
            <TextField
              required
              name="weight"
              label="Today’s Weight"
              placeholder="Enter Weight"
              id="exact-weight"
              type="number"
              value={values.weight}
              sx={{
                width: '100%'
              }}
              disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION_DAY_PROGRESS) || !isEditing}
              onBlur={handleBlur}
              onChange={(data) => setFieldValue('weight', data.target.value)}
              inputProps={{
                inputProps: { style: { borderRadius: 8, width: '100%' } },
                style: { borderRadius: 8, width: '100%' },
                min: 0
              }}
            />
            {errors.weight && <p className="text-red-300">Value must not be less that 0</p>}
          </Stack>
          <Stack display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
            <TextField
              required
              name="steps"
              type="number"
              label="Steps"
              placeholder="Enter Walked Steps"
              id="exact-steps"
              value={values.steps}
              sx={{
                width: '100%'
              }}
              disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION_DAY_PROGRESS) || !isEditing}
              onBlur={handleBlur}
              onChange={(data) => setFieldValue('steps', data.target.value)}
              inputProps={{
                inputProps: { style: { borderRadius: 8, width: '100%' } },
                style: { borderRadius: 8, width: '100%' },
                min: 0
              }}
            />
            {errors.steps && <p className="text-red-300">Value must not be less that 0</p>}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default TrackersForm;
