import cx from 'classnames';

import { KitchenLogType } from 'lib/enums';

interface FoodTypeProps {
  selectedType: string;
  setSelectedType: (value: KitchenLogType) => void;
}

const FoodType = ({ selectedType, setSelectedType }: FoodTypeProps) => (
  <div className="level">
    <div className="level-left">
      <div
        className={cx('cursor-pointer flex opacity-80 rounded-2xl mr-8 w-52 h-28 border-l-8 border-white hover:border-red-500', {
          'border-l-8 border-red-500 opacity-100': selectedType === KitchenLogType.food
        })}
      >
        <div className="card tile rounded-2xl" onClick={() => setSelectedType(KitchenLogType.food)}>
          <div className="card-content self-center">
            <h1 className="subtitle is-spaced">Meal</h1>
          </div>
        </div>
      </div>
      <div
        className={cx(
          'cursor-pointer flex opacity-80 rounded-2xl mr-8 w-52 h-28 border-l-8 border-white hover:border-green-500',
          {
            'border-l-8 border-green-500 opacity-100': selectedType === KitchenLogType.foodComponent
          }
        )}
      >
        <div
          className="card tile rounded-2xl"
          onClick={() => {
            setSelectedType(KitchenLogType.foodComponent);
          }}
        >
          <div className="card-content self-center">
            <h1 className="subtitle is-spaced">Component</h1>
          </div>
        </div>
      </div>
      <div
        className={cx('cursor-pointer flex opacity-80 rounded-2xl mr-8 w-52 h-28 border-l-8 border-white hover:border-blue-500', {
          'border-l-8 border-blue-500 opacity-100': selectedType === KitchenLogType.ingredient
        })}
      >
        <div className="card tile rounded-2xl" onClick={() => setSelectedType(KitchenLogType.ingredient)}>
          <div className="card-content self-center">
            <h1 className="subtitle is-spaced ">Ingredient</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default FoodType;
