import { Country, Kitchen } from '@calo/types';
import { Button, Input, Select } from 'components';
import { SupplyChainUser } from 'lib/interfaces';
import { toLower } from 'lodash';
import useUserForm from './useUserForm';
interface UserFormProps {
  onSubmit: (value: Omit<SupplyChainUser, 'id'>) => Promise<void>;
  user: SupplyChainUser;
}

const UserForm = ({ onSubmit, user }: UserFormProps) => {
  const { handleSubmit, values, handleChange, handleBlur, isSubmitting, isValid, dirty, setFieldValue } = useUserForm(
    user,
    onSubmit
  );

  return (
    <form onSubmit={handleSubmit}>
      <Input label="Name" value={values.name} name="name" onChange={handleChange} onBlur={handleBlur} />
      <Input label="Phone number" value={values.phoneNumber} name="phoneNumber" onChange={handleChange} onBlur={handleBlur} />
      <Input
        label="Email"
        value={values.email}
        name="email"
        onChange={(data: any) => setFieldValue('email', toLower(data.target.value))}
        onBlur={handleBlur}
      />
      <Select
        label="Country"
        value={values.country}
        onChange={(data: any) => setFieldValue('country', data.value)}
        options={Object.values(Country).map((c) => ({
          value: c,
          label: Country[c]
        }))}
      />
      <Select
        label="Kitchens"
        value={values.kitchen}
        isMulti
        onChange={(data: any) =>
          setFieldValue(
            'kitchen',
            data.map((row) => row.value)
          )
        }
        options={Object.values(Kitchen)
          .filter((kitchen) => !kitchen.includes('000'))
          .map((c) => ({
            value: c,
            label: c
          }))}
        isDisabled={Object.values(Kitchen).filter((r) => r.includes(values.country!)).length === 0}
      />
      <div>
        <Button type="submit" primary loading={isSubmitting} disabled={!dirty || !isValid || isSubmitting} content="Save" />
      </div>
    </form>
  );
};
export default UserForm;
