import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import { getRecord } from 'actions';
import { AppState } from 'lib/interfaces';
import { capitalize } from 'lodash-es';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

interface DriverFormProps {
  value: string;
  selectedDay: string;
  onChange: (id: string) => any;
  setDriversData: (value: any) => void;
  setLastDriverChanged: (value: { day: string; name: string; id: string }) => void;
}
interface User {
  id: string;
  name: string;
  phoneNumber: string;
}

const DriverForm = ({ value, onChange, selectedDay, setDriversData, setLastDriverChanged }: DriverFormProps) => {
  const users = useSelector((state: AppState) => state.user.keyedList) ?? {};
  useQuery([`/drivers/${value}`], getRecord, {
    enabled: Boolean(users && !users[value]),
    retry: false
  });

  useEffect(() => {
    if (users) {
      setDriversData(users);
    }
  }, [users]);

  const handleOnChange = (event: any, newValue: User | null) => {
    onChange(newValue ? newValue.id : '');
    if (newValue) {
      setLastDriverChanged({ name: newValue.name, day: selectedDay, id: newValue.id });
    }
  };

  return (
    <Stack sx={{ mx: '1px' }} key={users[value]}>
      <Box sx={{ mt: 2 }}>
        <Autocomplete
          value={users ? users[value] : null}
          onChange={handleOnChange}
          options={Object.values(users || {})}
          getOptionLabel={(option) => `${option.name} (${option.phoneNumber})`}
          renderInput={(params) => (
            <TextField
              key={selectedDay}
              {...params}
              label={`Driver for ${capitalize(selectedDay?.toLowerCase())}`}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              sx={{
                width: '100%',
                '& .MuiInputBase-input': {
                  borderRadius: '8px',
                  padding: '12px 14px'
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px'
                }
              }}
            />
          )}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option?.id}>
              {`${option.name} (${option.phoneNumber})`}
            </Box>
          )}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          noOptionsText="No drivers found"
        />
      </Box>
    </Stack>
  );
};

export default DriverForm;
