import { ListGiftsFilters } from '@calo/dashboard-types';
import styled from '@emotion/styled';
import { Icon as IconifyIcons } from '@iconify/react';
import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses
} from '@mui/material';
import { exportSpecialRequest } from 'actions/specialRequest';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Pagination } from 'components';
import ExportButton from 'components/ExportButton';
import { format } from 'date-fns/fp';
import { PaginatedSpecialRequest } from 'hooks/useSpecialRequestList';
import { SpecialRequest } from 'lib/interfaces';
import { useState } from 'react';
import SpecialRequestFilters from '../SpecialRequestFilters';
import SpecialRequestListRow from './SpecialRequestListRow';

interface SpecialRequestListProps {
  data: PaginatedSpecialRequest | undefined;
  page: number;
  refetchSpecialList: any;
  filters: ListGiftsFilters;
  specialRequestList: SpecialRequest[];
  requestLoadingList: boolean;
  setPage: (value: number) => void;
  setFilters: React.Dispatch<React.SetStateAction<ListGiftsFilters>>;
}
const SpecialRequestList = ({
  requestLoadingList,
  data,
  specialRequestList,
  page,
  setPage,
  filters,
  setFilters,
  refetchSpecialList
}: SpecialRequestListProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpenFilter = Boolean(anchorEl);
  const [loadingExport, setLoadingExport] = useState(false);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      justifyContent: 'center',
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '14px',
      color: caloTheme.palette.neutral900
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'center'
    }
  }));

  const handleExportList = () => {
    try {
      setLoadingExport(true);
      exportSpecialRequest(filters).then(() => {
        setLoadingExport(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingExport(false);
    }
  };
  return (
    <Box sx={{ my: 1 }}>
      <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <Typography sx={{ fontWeight: 600, fontSize: '19px', lineHeight: '23px', textTransform: 'capitalize', my: 'auto' }}>
          {`Request list - ${filters.kitchen}`}
        </Typography>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Stack>
            <IconButton>
              <IconifyIcons
                icon="fluent:filter-16-filled"
                color={caloTheme.palette.neutral900}
                onClick={(event: any) => setAnchorEl(event.currentTarget)}
              />
            </IconButton>
            <SpecialRequestFilters
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              isOpenFilter={isOpenFilter}
              filters={filters}
              setFilters={setFilters}
            />
          </Stack>
          <ExportButton
            isDisabled={specialRequestList?.length === 0 || loadingExport}
            isLoading={loadingExport}
            onClick={handleExportList}
          />
        </Stack>
      </Stack>
      <Stack sx={{ my: 2 }}>
        <Table
          sx={{
            marginY: '4px',
            minHeight: '120px',
            overflow: 'auto',
            width: '100%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
        >
          <TableHead
            style={{ borderRadius: '8px' }}
            sx={{
              backgroundColor: caloTheme.palette.neutral50,
              color: 'black',
              flexWrap: 0,
              justifyContent: 'space-between',
              width: '100%',
              borderRadius: '8px'
            }}
          >
            <TableRow
              sx={{
                backgroundColor: caloTheme.palette.neutral50,
                color: 'black',
                width: '100%',
                flexWrap: 0,
                justifyContent: 'space-between'
              }}
            >
              <StyledTableCell style={{ width: '25%', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
                User
              </StyledTableCell>
              <StyledTableCell style={{ width: '10%' }}>Requested By</StyledTableCell>
              <StyledTableCell style={{ width: '10%' }}>Request Date</StyledTableCell>
              <StyledTableCell style={{ width: '10%' }}>Delivery Date</StyledTableCell>
              <StyledTableCell style={{ width: '30%' }}>Request Type</StyledTableCell>
              <StyledTableCell style={{ width: '5%' }}>Comment</StyledTableCell>
              <StyledTableCell style={{ width: '5%' }}>Attachment</StyledTableCell>
              <StyledTableCell style={{ width: '5%', borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}>
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {requestLoadingList ? (
            <StyledTableCell colSpan={8}>
              <CaloLoader />
            </StyledTableCell>
          ) : specialRequestList?.length === 0 ? (
            <StyledTableCell style={{ border: 0 }} colSpan={8}>
              <Typography
                sx={{
                  mt: 3,
                  textAlign: 'center',
                  width: 'full',
                  fontSize: '24px',
                  color: caloTheme.palette.neutral400
                }}
              >
                {`No Requests for ${format('dd/MM/yyyy')(new Date(filters.date!.gte))} - ${format('dd/MM/yyyy')(new Date(filters.date!.lte))}`}
              </Typography>
            </StyledTableCell>
          ) : (
            <TableBody>
              {specialRequestList &&
                specialRequestList?.map((row: any) => (
                  <SpecialRequestListRow request={row} key={row.id} refetchList={refetchSpecialList} />
                ))}
            </TableBody>
          )}
        </Table>
        {data && <Pagination limit={data.meta?.limit || 50} page={page} onChange={setPage} total={data.data?.length} />}
      </Stack>
    </Box>
  );
};
export default SpecialRequestList;
