import { RatingFilters } from '@calo/dashboard-types';
import { Box, Card, Stack, Typography } from '@mui/material';
import { getListWithParams } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Pagination } from 'components';
import FeedbackTable from 'components/FeedbackTable';
import { FoodFeedbackPages } from 'lib/enums';
import { useState } from 'react';
import { useQuery } from 'react-query';
import FoodFeedbackTab from '../FoodFeedbackTab';

interface PaginatedFeedback {
  data: any[];
  meta: {
    limit: number;
    total: number;
  };
}

interface FeedbackCardProps {
  filters: RatingFilters;
  selectedPage: FoodFeedbackPages;
  setSelectedPage: (value: FoodFeedbackPages) => void;
}
const FeedbackCard = ({ filters, selectedPage, setSelectedPage }: FeedbackCardProps) => {
  const [page, setPage] = useState(0);

  const { data, isLoading } = useQuery<any, Error, PaginatedFeedback>(['ratings', { page, filters }], getListWithParams, {
    suspense: false
  });

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <>
          <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px'
              }}
            >
              Feedback List
            </Typography>
            <FoodFeedbackTab selectedPage={selectedPage} setSelectedPage={(value) => setSelectedPage(value)} />
            {isLoading ? (
              <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                <CaloLoader />
              </Stack>
            ) : (
              <FeedbackTable data={data?.data || []} />
            )}
          </Box>
          {data && (
            <Pagination
              isHidden={data.data.length === 0}
              limit={data.meta.limit}
              total={data.meta.total}
              page={page}
              onChange={setPage}
            />
          )}
        </>
      </Card>
    </>
  );
};
export default FeedbackCard;
