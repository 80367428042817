import { Reward } from 'lib/interfaces';

interface RewardRowMobileProps {
  row: Reward;
}

const PointsTransactionRowMobile = (_: RewardRowMobileProps) => {
  return <></>;
};

export default PointsTransactionRowMobile;
