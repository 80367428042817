import { Range } from '@calo/dashboard-types';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import Input from '../Input';

interface RangeInputProps
  extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'value' | 'onChange'> {
  onChange: (value: Range | any) => void;
  value?: Range | any;
  label?: string;
}

const RangeInput = ({ label, onChange, value, ...rest }: RangeInputProps) => (
  <div>
    {label && <label className="label capitalize">{label}</label>}
    <div className="tabs">
      <Input
        label="Min"
        onChange={(e) =>
          onChange({
            lte: '0',
            ...value,
            gte: e.target.value
          })
        }
        value={value?.gte || 0}
        type="number"
        {...rest}
      />
      <Input
        label="Max"
        onChange={(e) =>
          onChange({
            gte: '0',
            ...value,
            lte: e.target.value
          })
        }
        value={value?.lte || 0}
        type="number"
        {...rest}
      />
    </div>
  </div>
);
export default RangeInput;
