import { forwardRef, ReactNode, useImperativeHandle, useState } from 'react';

import cx from 'classnames';
import RModal from 'react-modal';

interface ModalProps {
  children: ReactNode;
  onClose?: () => void;
  className?: string;
  isNarrow?: boolean;
}

export interface ModalRef {
  open(): void;
  close(): void;
}

const Modal = forwardRef(({ children, onClose, isNarrow }: ModalProps, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    },
    close: () => {
      setIsOpen(false);
    }
  }));

  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  return (
    <RModal
      className={cx(
        'p-8 flex flex-col bg-white  absolute left-0 right-0 bottom-0 sm:bottom-auto mx-auto top-0 sm:top-6/12 sm:-translate-y-1/2 sm:rounded-lg transform',
        {
          'w-6/12 md:w-6/12 lg:w-6/12 xl:w-4/12 sm:w-1/2': isNarrow,
          'w-10/12 sm:w-1/2 md:w-10/12 lg:w-10/12 xl:w-10/12': !isNarrow
        }
      )}
      overlayClassName="fixed left-0 bottom-0 right-0 top-0 bg-dim z-50"
      style={{
        overlay: {
          zIndex: 1110
        }
      }}
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      {children}
    </RModal>
  );
});

export default Modal;
