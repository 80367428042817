import Auth, { CognitoUser } from '@aws-amplify/auth';

import { selectUserCountry, selectUserData, selectUserKitchens, selectUserRoles } from './selectors';

let user: CognitoUser | null = null;
let userPromise: Promise<CognitoUser> | null = null;

export const useCurrentUser = () => {
  if (user) {
    return user;
  }

  if (!userPromise) {
    userPromise = Auth.currentAuthenticatedUser();
    userPromise.then((u) => (user = u));
  }

  throw userPromise;
};

export const useUserRoles = () => {
  const user = useCurrentUser();
  return selectUserRoles(user);
};

export const useUserKitchens = () => {
  const user = useCurrentUser();
  return selectUserKitchens(user);
};

export const useUserCountry = () => {
  const user = useCurrentUser();
  return selectUserCountry(user);
};

export const useUserData = () => {
  const user = useCurrentUser();
  return selectUserData(user);
};
