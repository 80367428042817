import { Brand, Country } from '@calo/types';
import { Button, CaloLoader, Input, ModalRef } from 'components';
import { groupBy } from 'lodash';
import React, { useMemo } from 'react';
import AddMealRow from './AddAddonRow';

interface AddAddonModalProps {
  addonsMenu: any;
  page: number;
  loadingAddonsMeals: boolean;
  selectedAddons: any[];
  setPage: (v: number) => void;
  filterName: string | undefined;
  autoSelectSubscribers: boolean | undefined;
  setFilterName: (value: string) => void;
  setSelectedAddons: (value: any[]) => void;
  selectedCountryBrand: { brand: Brand; country: Country };
  addAddonModalRef: React.MutableRefObject<ModalRef | undefined>;
}
const AddAddonTable = ({
  addonsMenu,
  loadingAddonsMeals,
  addAddonModalRef,
  filterName,
  setFilterName,
  selectedAddons,
  setSelectedAddons,
  autoSelectSubscribers
}: AddAddonModalProps) => {
  const menuFood = useMemo(() => {
    if (!addonsMenu) {
      return [];
    }
    return Object.values(groupBy(addonsMenu?.food, 'name.en')).map((r) => r[0]);
  }, [addonsMenu]);

  const filterByName = filterName
    ? menuFood.filter((meal) => meal.name.en.toLowerCase().includes(filterName.toLowerCase()))
    : menuFood;

  return (
    <section className="section">
      <div className="mb-4">
        <Input label="Name" value={filterName} onChange={(e) => setFilterName(e.target.value)} debounce />
      </div>
      <div>
        {loadingAddonsMeals ? (
          <CaloLoader />
        ) : (
          <div className="card has-table has-table-container-upper-radius">
            <div className="card-content">
              <div className="table-container overflow-y-auto" style={{ maxHeight: '26rem' }}>
                <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                  <thead>
                    <tr className="bg-black sticky top-0 z-50">
                      <th style={{ color: 'white' }} className=" w-64">
                        Name
                      </th>
                      <th style={{ color: 'white' }} className=" w-48">
                        Size
                      </th>
                      <th style={{ color: 'white' }} className=" w-48">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterByName &&
                      filterByName?.map((food: any) => (
                        <AddMealRow
                          key={food.id}
                          food={food}
                          allAddonMenuFood={addonsMenu.food}
                          selectedAddons={selectedAddons}
                          onPick={(v: any) => setSelectedAddons(v)}
                          autoSelectSubscribers={autoSelectSubscribers}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center">
        <Button
          primary
          content="Done"
          className="-mb-6 mt-6"
          disabled={selectedAddons.length === 0}
          onClick={() => addAddonModalRef.current?.close()}
        />
      </div>
    </section>
  );
};

export default AddAddonTable;
