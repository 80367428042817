import { aiServiceClient } from 'lib/client';
import { Province } from 'lib/enums';
import { ProvinceSchedule } from 'views/AiDashboard/types';

export const getLogisticsSchedule = async (province: Province) => {
  const res = await aiServiceClient.get(`/provinces/${province}/schedule`);
  const data = res.data;
  const schedule = data.schedule;
  return schedule;
};

export const updateLogisticsSchedule = async (province: Province, newSchedule: ProvinceSchedule) => {
  const res = await aiServiceClient.put(`/provinces/${province}/schedule`, {
    newSchedule
  });
  return res;
};

export interface PromptData {
  [name: string]: string;
}
export const getAiPrompts = async () => {
  const res = await aiServiceClient.get(`/prompts`);
  const data = res.data;
  return data as PromptData;
};

export const updateAiPrompts = async (promptData: PromptData) => {
  const res = await aiServiceClient.put(`/prompts`, promptData);
  const data = res.data;
  return data as PromptData;
};
