import { Subscription } from '@calo/dashboard-types';
import { Box, Button, Table, TableBody, TableCell, TableRow } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { Delivery } from 'lib/interfaces';

interface DeliveryPaymentProps {
  subscription: Subscription;
  delivery: Delivery;
  onApplyPayment: () => Promise<void> | void;
}

const DeliveryPayment = ({ onApplyPayment, subscription, delivery }: DeliveryPaymentProps) => (
  <Box display={'table'} sx={{ mx: 2 }}>
    <Table
      sx={{
        marginY: '4px',
        minHeight: '120px',
        tableLayout: 'fixed',
        overflow: 'auto',
        width: '100%',
        mx: 2,
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column'
        }
      }}
    >
      <TableBody>
        <TableRow style={{ textAlign: 'justify' }}>
          <TableCell sx={{ fontWeight: 600, fontSize: '16px' }}>Amount</TableCell>
          <TableCell sx={{ fontWeight: 400, fontSize: '16px' }} style={{ textAlign: 'center' }}>
            {delivery.cost - delivery.paidAmount} {delivery.currency}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell sx={{ fontWeight: 600, fontSize: '16px' }}>Current Balance</TableCell>
          <TableCell sx={{ fontWeight: 400, fontSize: '16px' }} style={{ textAlign: 'center' }}>
            {subscription.balance[subscription.currency]} {delivery.currency}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ fontWeight: 600, fontSize: '16px' }}>New Balance</TableCell>
          <TableCell sx={{ fontWeight: 400, fontSize: '16px', textAlign: 'center' }} style={{ textAlign: 'center' }}>
            {(subscription.balance[subscription.currency] - (delivery.cost - delivery.paidAmount)).toFixed(3)} {delivery.currency}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    <Button
      variant="contained"
      aria-label="create-delivery"
      fullWidth
      sx={{
        color: 'white',
        fontWeight: 600,
        fontSize: '19px',
        boxShadow: 'none',
        lineHeight: '23px',
        my: 2,
        mx: 1,
        borderRadius: '8px',
        textAlign: 'center',
        fontFamily: caloTheme.typography.fontFamily,
        borderColor: caloTheme.palette.primary500,
        backgroundColor: caloTheme.palette.primary500,
        '&:hover': {
          color: 'white',
          boxShadow: 'none',
          backgroundColor: caloTheme.palette.primary600,
          borderColor: caloTheme.palette.primary600
        },
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          mt: 2
        }
      }}
      onClick={onApplyPayment}
    >
      Confirm
    </Button>
  </Box>
);
export default DeliveryPayment;
