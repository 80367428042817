import { RefundReason } from '@calo/types';
import { SelectOptionString } from 'lib/interfaces';

export const refundReasonsHelper = {
  getOptions: () => {
    const options: SelectOptionString[] = [];
    for (const [key, value] of Object.entries(RefundReason)) {
      options.push({ label: value?.replace(/_/g, ' '), value: key });
    }
    options.push({ value: 'Other', label: 'Other' });

    return options;
  },
  getObject: () => {
    const refundReasonsObj: any = {};
    for (const [key, value] of Object.entries(RefundReason)) {
      refundReasonsObj[key] = value.replace(/_/g, ' ').toLowerCase();
    }
    return refundReasonsObj;
  },
  getSubReasonsOptions: () => ({
    [RefundReason.ALLERGIC_CONDITIONS]: [
      { value: 'Gluten', label: 'Gluten' },
      { value: 'Dairy', label: 'Dairy' },
      { value: 'Nuts', label: 'Nuts' },
      { value: 'Spicy', label: 'Spicy' },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.DOUBLE_CHARGE]: [
      { value: 'From TAP', label: 'From TAP' },
      {
        value: 'Renewed from the app after getting renewed',
        label: 'Renewed from the app after getting renewed'
      },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.BUG]: [
      { value: 'Dashboard bug', label: 'Dashboard bug' },
      { value: 'App bug', label: 'App bug' },
      { value: 'Payment bug', label: 'Payment bug' },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.FOOD_POISONING]: [
      { value: 'Food poisoning', label: 'Food poisoning' },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.FOREIGN_OBJECT]: [
      { value: 'Found a hair', label: 'Found a hair' },
      { value: 'Found a bug', label: 'Found a bug' },
      { value: 'Found a thread', label: 'Found a thread' },
      {
        value: 'Found an identified object',
        label: 'Found an identified object'
      },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.LEAVING_THE_COUNTRY]: [
      { value: 'Leaving the country', label: 'Leaving the country' },
      {
        value: 'Going for a different city',
        label: 'Going for a different city'
      },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.MEDICAL_CONDITION]: [
      {
        value: 'Meals are not suitable for the customer condition',
        label: 'Meals are not suitable for the customer condition'
      },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.PARTIAL_REFUND]: [
      {
        value: 'Wants a different package',
        label: 'Wants a different package'
      },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.PERSONAL_PREFERENCE]: [
      { value: 'Food preference', label: 'Food preference' },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.PERSONAL_REASONS]: [
      { value: 'Personal reasons', label: 'Personal reasons' },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.QUALITY_OF_THE_MEALS]: [
      {
        value: 'Quality of the meal/snack',
        label: 'Quality of the meal/snack'
      },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.UNAVAILABLE_DIETARY_PLANS]: [
      { value: 'Wanted a vegan plan', label: 'Wanted a vegan plan' },
      { value: 'Wanted a Keto plan', label: 'Wanted a Keto plan' },
      {
        value: 'Wanted a dairy/gluten free plan',
        label: 'Wanted a dairy/gluten free plan'
      },
      {
        value: 'Wanted a high protein plan',
        label: 'Wanted a high protein plan'
      },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.UNINTENTIONAL_RENEWAL]: [
      { value: 'Auto-renew was on', label: 'Auto-renew was on' },
      {
        value: 'Tapped on resume while CC is saved',
        label: 'Tapped on resume while CC is saved'
      },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.UNPLEASED_WITH_THE_DELIVERY]: [
      {
        value: 'Delivery time is not suitable',
        label: 'Delivery time is not suitable'
      },
      { value: 'Issue with the driver', label: 'Issue with the driver' },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.UNABLE_TO_CONTROL_MACROS_OR_CALORIES]: [
      { value: 'Not enough protein', label: 'Not enough protein' },
      { value: 'Fat is too high', label: 'Fat is too high' },
      { value: 'Carb is too high', label: 'Carb is too high' },
      {
        value: 'Not enough calories in the package',
        label: 'Not enough calories in the package'
      },
      { value: 'Other', label: 'Other' }
    ],
    [RefundReason.DISPATCH_ERROR]: [
      {
        value: 'Received a wrong meal/snack',
        label: 'Received a wrong meal/snack'
      },
      { value: 'Missing meal/snack', label: 'Missing meal/snack' },
      { value: 'Meal was not sealed', label: 'Meal was not sealed' },
      {
        value: 'Sauce container was not sealed',
        label: 'Sauce container was not sealed'
      },
      { value: 'Other', label: 'Other' }
    ]
  })
};
