import { Macros } from '@calo/types';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { macrosConfigs } from '../../../../../views/SubscriptionV2/ExactSubscription2/DeliveriesCard/DeliveriesMeals/DeliveriesMealTable/shared/DeliveryMealAction/SwapMealPopUp/helpers';

interface MacrosCardProps {
  sx?: SxProps<Theme>;
  macros: Macros;
}

const MacrosCard = ({ sx, macros }: MacrosCardProps) => {
  return (
    <Stack
      id="macros"
      sx={{
        backgroundColor: '#FBFBFB',
        flexDirection: 'row',
        borderRadius: '8px',
        px: '6px',
        py: '8px',
        justifyContent: 'space-around',
        ...sx
      }}
    >
      <Stack sx={{ gap: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>Kcal</Typography>
        <Typography sx={{ fontSize: '16px', fontWeight: 900 }}>{macros.cal ?? 0}</Typography>
      </Stack>
      {Object.entries(macrosConfigs).map(([macrosType, config]) => (
        <Stack key={macrosType} sx={{ gap: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Stack sx={{ flexDirection: 'row', gap: '4px' }}>
            <Typography
              sx={{ width: '8px', height: '8px', backgroundColor: config.color, borderRadius: '4px', marginTop: '4px' }}
            />
            <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>{config.label}</Typography>
          </Stack>
          <Typography sx={{ fontSize: '16px', fontWeight: 900 }}>{macros[macrosType]}g</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default MacrosCard;
