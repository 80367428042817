interface ObjectFromEnum {
  key: string;
  value: string;
}

export const getObjectFromEnum = (enumToConvert: any) => {
  return Object.entries(enumToConvert)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({ key, value })) as ObjectFromEnum[];
};
