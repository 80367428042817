import { Subscription } from '@calo/types';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { unskipDelivery } from 'actions/delivery';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns/fp';
import { useMutation } from 'react-query';

interface UnskipDeliveryCardProps {
  selectedDate: Date;
  deliveryId: string;
  subscription: Subscription & { remainingDays: number; expectedLastDeliveryDay: string; ratings: any[] };
  refetch: () => void;
}

const UnskipDeliveryCard = ({ selectedDate, subscription, deliveryId, refetch }: UnskipDeliveryCardProps) => {
  const { mutateAsync: unskipMutation } = useMutation(unskipDelivery);

  return (
    <Card
      variant="outlined"
      sx={{
        width: '100%',
        border: 'none',
        borderRadius: '8px',
        marginTop: '8px',
        height: 'auto'
      }}
    >
      <Box
        sx={{
          padding: 2,
          mt: '15%',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'auto',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column'
          }
        }}
        display={'flex'}
        flexDirection={'column'}
      >
        <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'}>
          <Typography
            variant="h5"
            sx={{
              fontFamily: caloTheme.typography.fontFamily,
              fontSize: '23px',
              mb: 4,
              fontWeight: 600,
              lineHeight: '28px',
              textAlign: 'center'
            }}
          >
            {`${subscription.name}'s delivery for ${format('dd/MM/yyyy')(selectedDate)} was skipped`}
          </Typography>
        </Stack>
        <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'}>
          <Button
            variant="contained"
            aria-label="create-delivery"
            sx={{
              width: '199px',
              height: '60px',
              color: 'white',
              fontWeight: 600,
              fontSize: '19px',
              boxShadow: 'none',
              lineHeight: '28px',
              borderRadius: '8px',
              textAlign: 'center',
              fontFamily: caloTheme.typography.fontFamily,
              borderColor: caloTheme.palette.primary500,
              backgroundColor: caloTheme.palette.primary500,
              '&:hover': {
                color: 'white',
                boxShadow: 'none',
                backgroundColor: caloTheme.palette.primary600,
                borderColor: caloTheme.palette.primary600
              },
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                marginTop: 4,
                width: 'auto',
                margin: 'auto',
                justifyItems: 'center'
              }
            }}
            onClick={() => {
              unskipMutation(deliveryId);
              refetch();
            }}
          >
            Unskip
          </Button>
        </Stack>
      </Box>
    </Card>
  );
};

export default UnskipDeliveryCard;
