import { DeliveryTicketType } from '@calo/dashboard-types';
import { getListWithParams } from 'actions/index';
import { useQuery } from 'react-query';

const useDeliveryTickets = ({ selectedKitchen, selectedDateReq }) => {
  const {
    data: sentRequestData,
    refetch: refetchSentReq,
    isLoading
  } = useQuery<any, Error, any[]>(
    [
      'delivery-tickets',
      {
        filters: { type: DeliveryTicketType.MEAL, kitchen: selectedKitchen, date: { lte: selectedDateReq, gte: selectedDateReq } }
      }
    ],
    getListWithParams,
    {
      suspense: false
    }
  );

  const {
    data: sentManualRequestData,
    refetch: refetchSentManualReq,
    isLoading: loadingSentManualReq
  } = useQuery<any, Error, any[]>(
    [
      'delivery-tickets',
      {
        filters: {
          type: DeliveryTicketType.MANUAL,
          kitchen: selectedKitchen,
          date: { lte: selectedDateReq, gte: selectedDateReq }
        }
      }
    ],
    getListWithParams,
    {
      suspense: false
    }
  );

  const {
    data: sentOtherRequestData,
    refetch: refetchSentOtherReq,
    isLoading: loadingSentOtherlReq
  } = useQuery<any, Error, any[]>(
    [
      'delivery-tickets',
      {
        filters: {
          type: DeliveryTicketType.OTHER,
          kitchen: selectedKitchen,
          date: { lte: selectedDateReq, gte: selectedDateReq }
        }
      }
    ],
    getListWithParams,
    {
      suspense: false
    }
  );

  const refetchAll = async () => {
    await refetchSentReq();
    await refetchSentManualReq();
    await refetchSentOtherReq();
  };

  const allData = [...(sentRequestData || []), ...(sentManualRequestData || []), ...(sentOtherRequestData || [])];

  return {
    isLoading,
    loadingSentManualReq,
    loadingSentOtherlReq,
    allData,
    refetchAll
  };
};

export default useDeliveryTickets;
