import { useMemo, useState } from 'react';

import { getListWithParams } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { PackageElementsPicker } from 'components/index';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { FormikErrors, FormikTouched } from 'formik';
import { PackageType } from 'lib/enums';
import { getAccessibleCountries, getKitchenOptions, hasValidCharactersForLanguage } from 'lib/helpers';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { CreateFoodPackageReq, FoodPackageElement } from 'lib/interfaces';
import { keyBy } from 'lodash-es';
import { useQuery } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import { Card, FormHelperText, MenuItem, Stack, TextField, Typography } from '@mui/material';

interface PackageInformationCardProps {
  values: CreateFoodPackageReq;
  errors: FormikErrors<CreateFoodPackageReq>;
  touched: FormikTouched<CreateFoodPackageReq>;
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<CreateFoodPackageReq>>;
  setValues: (values: CreateFoodPackageReq) => void;
}

const PackageInformationCard = ({
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  setValues
}: PackageInformationCardProps) => {
  const [langAlert, setLangAlert] = useState({ AR: false, EN: false, value: '' });

  const userRoles = useUserRoles();
  const { data: packageElementList } = useQuery<any, Error, { data: FoodPackageElement[] }>(
    [
      'food-package-elements',
      {
        limit: 1000,
        filters: { country: values.country, brand: values.brand, kitchen: values.kitchen }
      }
    ],
    getListWithParams
  );

  const handleNameFieldChange = (field: string, data: string, lang: 'AR' | 'EN') => {
    setFieldValue(field, data);
    const hasValidCharacters = hasValidCharactersForLanguage(data, lang, false) || data === '';
    setLangAlert({ ...langAlert, [`${lang}`]: !hasValidCharacters, value: field });
  };
  const handleElementsChange = (rows: FoodPackageElement[]) => {
    setValues({
      ...values,
      elements: rows
    });
  };
  const packageElements = packageElementList?.data || [];
  const keyed = useMemo(() => keyBy(packageElements, 'id'), [values.country, values.brand, values.kitchen, packageElements]);
  const userKitchens: Kitchen[] = useUserKitchens();

  return (
    <InputThemeProvider>
      <Card
        variant="outlined"
        sx={{
          marginTop: '16px',
          border: 'none',
          borderRadius: '16px',
          py: '20px',
          px: '12px',
          zIndex: 100,
          display: 'flex',
          flexDirection: 'column',
          gap: '20px'
        }}
      >
        <Typography variant="h5" sx={{ fontSize: '19px', fontWeight: 600 }}>
          Information
        </Typography>
        <Stack sx={{ display: 'flex', flexDirection: 'column', gap: '13px', width: '100%' }}>
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '13px', width: '100%', px: 2 }}>
            <Stack sx={{ width: '50%' }}>
              <TextField
                label="Name"
                name="name"
                id="name"
                placeholder="Enter Name"
                value={values.name}
                sx={{ width: '100%' }}
                disabled={!userRoles.includes(Permission.CREATE_FOOD_PACKAGE)}
                onChange={(data) => handleNameFieldChange('name', data.target.value, 'EN')}
                onBlur={handleBlur}
                error={touched.name && !!errors.name}
              />
              <FormHelperText id="name.en-error" sx={{ color: caloTheme.palette.red }}>
                {langAlert.EN && langAlert.value === 'name' && 'letters should be in English only'}
              </FormHelperText>
            </Stack>
            <TextField
              select
              label="Type*"
              value={values.type}
              name="type"
              placeholder="Enter Type"
              sx={{ width: '50%' }}
              disabled={!userRoles.includes(Permission.CREATE_FOOD_PACKAGE)}
              onChange={(data) => setFieldValue('type', data.target.value)}
              onBlur={handleBlur}
              error={touched.type && !!errors.type}
            >
              {Object.values(PackageType).map((type) => (
                <MenuItem value={type}>{type}</MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '13px', width: '100%', px: 2 }}>
            <TextField
              select
              label="Country*"
              value={values.country}
              name="country"
              placeholder="Enter Country"
              sx={{ width: '50%' }}
              disabled={!userRoles.includes(Permission.CREATE_FOOD_PACKAGE)}
              onChange={(data) => setFieldValue('country', data.target.value)}
              onBlur={handleBlur}
              error={touched.country && !!errors.country}
            >
              {getAccessibleCountries(userKitchens).map((country) => (
                <MenuItem value={country}>{country}</MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Kitchen*"
              value={values.kitchen}
              name="kitchen"
              placeholder="Enter Kitchen"
              sx={{ width: '50%' }}
              disabled={!userRoles.includes(Permission.CREATE_FOOD_PACKAGE)}
              onChange={(data) => setFieldValue('kitchen', data.target.value)}
              onBlur={handleBlur}
              error={touched.kitchen && !!errors.kitchen}
            >
              {getKitchenOptions(userKitchens, values.country)
                .filter((option) => option.label !== 'Any')
                .map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </TextField>
          </Stack>
          <PackageElementsPicker
            onChange={handleElementsChange}
            value={values.elements || []}
            keyed={keyed}
            list={packageElements}
            isDisabled={!userRoles.includes(Permission.CREATE_FOOD_PACKAGE)}
          />
        </Stack>
      </Card>
    </InputThemeProvider>
  );
};

export default PackageInformationCard;
