import { Country, Kitchen } from '@calo/types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { toggleUISettings } from 'actions';
import { Select2, SideDrawer } from 'components';
import { format, parseISO } from 'date-fns/fp';
import { getAccessibleCountries, getKitchenOptions } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';

interface SettingsProps {
  onFilter: (values: any) => any;
  filters: any;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <div className="field">
          <Select2
            label="Country"
            value={filters.country}
            onChange={(data) =>
              onFilter({
                ...filters,
                country: data.target.value as Country,
                kitchen: data.target.value ? userKitchens.find((k) => k.includes(data.target.value)) : ('' as Kitchen)
              })
            }
            options={getAccessibleCountries(userKitchens).map((c) => ({
              value: c,
              label: Country[c]
            }))}
          />
          <Select2
            label="Kitchen"
            value={filters.kitchen}
            onChange={(data) => onFilter({ ...filters, kitchen: data.target.value as Kitchen })}
            options={getKitchenOptions(userKitchens, filters.country)}
            disabled={Object.values(Kitchen).filter((r) => r.includes(filters.country!)).length === 0 || !filters.country}
          />
          <label className="label">Day</label>
          <div className="control is-clearfix">
            <DateRangePicker
              clearIcon={null}
              onChange={(dates: any) =>
                onFilter({
                  ...filters,
                  day: {
                    gte: format('yyyy-MM-dd')(dates[0]),
                    lte: format('yyyy-MM-dd')(dates[1])
                  }
                })
              }
              value={[parseISO(filters.day.gte), parseISO(filters.day.lte)]}
            />
          </div>
        </div>
      </section>
    </SideDrawer>
  );
};

export default Settings;
