import { FoodDietType, FoodType } from '@calo/types';
import { ArabicFoodTags, ArabicFoodType } from 'lib/helpers';
import { capitalize, compact, flatten, orderBy, uniq, uniqBy } from 'lodash-es';
import { toast } from 'react-toastify';

export const copyToClipBoard = async (extraMealsList: any[], lang: string) => {
  const foodListData = extraMealsList?.map((food) =>
    food.sizes
      .filter((f: any) => f.size !== 'C')
      .map((fs: any) => ({
        id: food.id,
        foodSize: fs.size,
        allergic: food.allergic,
        nameEn: food.name.en,
        nameAr: food.name.ar,
        type: food.type,
        tags: food.tags,
        extraMeals: fs.extraMeals || 0
      }))
  );
  const orderedSizes = uniqBy(
    orderBy(
      flatten(foodListData),
      (f) => f.tags.length === 1 && (f.tags.includes(FoodDietType.lowCarb) || f.tags.includes(FoodDietType.highProtein)),
      ['asc']
    ).filter((m) => m.extraMeals && m.extraMeals !== 0),
    'id'
  );

  const mapingList: any = [];
  Object.values(FoodType).map(
    (r) =>
      r !== FoodType.dinner &&
      mapingList.push({
        key: lang === 'En' ? `\n ${r === FoodType.lunch ? 'Lunch & Dinner' : capitalize(r)}: \n` : `\n ${ArabicFoodType(r)}: \n`,
        meals:
          lang === 'En'
            ? compact(
                uniq(
                  flatten(orderedSizes).map((meal) =>
                    meal.type.includes(r) ? `• ${meal.nameEn} - ${meal.tags} ${meal.allergic ? '- Allergic' : ''} \n` : ''
                  )
                )
              )
            : compact(
                uniq(
                  flatten(orderedSizes).map((meal) =>
                    meal.type.includes(r)
                      ? `• ${meal.nameAr || meal.nameEn} - ${meal.tags.map((tag: FoodDietType) => ArabicFoodTags(tag))} ${meal.allergic ? 'حساسية' : ''} \n`
                      : ''
                  )
                )
              )
      })
  );

  const mealText = compact(
    mapingList.map(
      (foodList: any) => flatten(foodList.meals).length !== 0 && `${foodList.key}${foodList.meals?.map((r: any) => r).join('')}`
    )
  ).join('');

  try {
    if (mealText.length === 0) {
      toast('No Meals available', { type: 'error', autoClose: 2000 });
    } else {
      await navigator.clipboard.writeText(`${mealText}`);
      toast(lang === 'En' ? 'Extra Meals list copied' : 'Extra Meals list copied in Arabic', {
        type: 'success',
        autoClose: 2000
      });
    }
  } catch {
    toast('Failed to copy!', { type: 'error', autoClose: 2000 });
  }
};
