export const currentYear = new Date().getFullYear();
export const currentMonth = new Date().getMonth() + 1;
export const getListOfMonthNames = () => {
  const formatter = new Intl.DateTimeFormat('en-US', { month: 'long' });
  return Array.from({ length: 12 }, (_, i) => formatter.format(new Date(currentYear, i)));
};
export const getYearsArray = (startYear: number) => {
  const years: number[] = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }
  return years;
};
export const currentDate = new Date();
currentDate.setMonth(currentDate.getMonth() + 1);
export const nextPerkMonth = currentDate.getMonth() + 1;
export const nextPerkYear = currentDate.getFullYear();
