import { Permission, UserGroup } from '@calo/dashboard-types';
import cx from 'classnames';
import { PermissionsInheritance } from 'lib';
import { uniq } from 'lodash-es';
import { useState } from 'react';

interface PermissionsRowProps {
  row: any;
  allRoles: UserGroup[];
  selectedRole: UserGroup | null;
  setSelectedRole: (value: any) => void;
  handleSave: () => void;
}

const checker = (inheritancePermissions: any, newPermissions: any) => {
  const val = inheritancePermissions?.every((r: Permission) => newPermissions.includes(r));
  return val;
};

const PermissionsRow = ({ row, allRoles, selectedRole, setSelectedRole, handleSave }: PermissionsRowProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleSelect = (permission: Permission, selected: boolean) => {
    if (!selectedRole) {
      return;
    }

    let newPermissions = [...selectedRole.permissions];
    // console.log(selected, permission, newPermissions, 'check');
    if (selected) {
      if (PermissionsInheritance[permission]) {
        newPermissions.push(...(PermissionsInheritance[permission] || []));
      }
      newPermissions = uniq(newPermissions);
      setSelectedRole({
        ...selectedRole,
        permissions: newPermissions
      });
    } else {
      newPermissions = newPermissions.filter((r) => r !== permission);
      newPermissions.map((p) => {
        if (!checker(PermissionsInheritance[p], newPermissions)) {
          newPermissions = newPermissions.filter((r) => r !== p);
        }
      });
      setSelectedRole({
        ...selectedRole,
        permissions: newPermissions
      });
    }
  };

  return (
    <>
      <tr className="text-black bg-blue-50" onClick={() => setIsOpen(!isOpen)}>
        <td colSpan={allRoles.length + 1}>
          <div className="sticky left-0 z-40  py-2 px-1  w-32">
            <i className={cx('fas pr-1 pt-1 text-black', { 'fa-angle-up': isOpen, 'fa-angle-down': !isOpen })}> </i>
            {row[0]}
          </div>
        </td>
      </tr>
      {row[1].map((permission: any) => (
        <tr key={permission.value} className={cx('w-full', { hidden: !isOpen, 'table-row': isOpen })}>
          <td className="sticky left-0 z-40 bg-white">
            <div>{permission.name}</div>
          </td>
          {allRoles.map((role, index) => (
            <td key={`${role.id}-${index}`}>
              <div onClick={() => selectedRole?.id !== role.id && handleSave()}>
                {(role.groupName === 'Admin' || role.groupName === 'admin') && row[0] === 'PERMISSIONS' ? (
                  <label className="b-checkbox checkbox">
                    <input
                      type="checkbox"
                      disabled={true}
                      value={permission.value}
                      checked={role.groupName === 'Admin' || role.groupName === 'admin'}
                    />
                    <span className="check is-primary"></span>
                  </label>
                ) : (
                  <label className="b-checkbox checkbox w-10">
                    <input
                      key={role.groupName}
                      type="checkbox"
                      disabled={row[0] === 'PERMISSIONS' || selectedRole?.id !== role.id}
                      value={`${permission.value}-${role.id}`}
                      onChange={(e) => handleSelect(permission.value, e.target.checked)}
                      checked={
                        (selectedRole?.permissions.includes(permission.value) && selectedRole?.id === role.id) ||
                        (role.permissions.includes(permission.value) && selectedRole?.id !== role.id)
                      }
                    />
                    <span className="check is-primary"></span>
                  </label>
                )}
              </div>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};
export default PermissionsRow;
