import { Permission } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';
import AddIcon from '@mui/icons-material/Add';
import { Button, Paper, Stack, Table, TableBody, TableContainer, TableHead, Typography } from '@mui/material';
import { getListWithParams } from 'actions/index';
import { caloTheme } from 'assets/images/theme/calo';
import CaloLoader from 'components/CaloLoader';
import { ModalRef } from 'components/Modal';
import { Routes } from 'lib/enums';
import { resolveCountryFromKitchen } from 'lib/helpers';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { IngredientType, IngredientsQuery } from 'lib/interfaces';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import IngredientCommentsPopup from 'views/ChefPlayground/Shared/IngredientCommentsPopup';
import TableHeaderRow from 'views/ChefPlayground/Shared/TableHeaderRow';
import IngredientRow from './IngredientRow';
import Settings from './Settings';

const IngredientList = () => {
  const roles = useUserRoles();
  const location = useLocation();
  const history = useHistory();
  const userKitchen = useUserKitchens();
  const commentsPopupRef = useRef<ModalRef>();
  const searchParams = new URLSearchParams(location.search);

  const [page, setPage] = useState<number>(0);

  const [ingredientList, setIngredientList] = useState<IngredientType[]>([]);
  const [selectedIngredient, setSelectedIngredient] = useState<IngredientType | undefined>();

  const [filters, setFilters] = useState<any>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  useEffect(() => {
    setPage(0);
  }, [filters]);

  const { data, isLoading } = useQuery<any, Error, IngredientsQuery>(
    [
      'ingredients/prototype',
      {
        page,
        filters: {
          ...filters,
          name: filters.name ? filters.name : undefined,
          ingredientId: filters.ingredientId ? filters.ingredientId : undefined
        },
        limit: 50
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: roles.includes(Permission.VIEW_PROTOTYPE_INGREDIENT_LIST),
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  useEffect(() => {
    if (!data?.data) {
      return;
    }
    setIngredientList(data.data as IngredientType[]);
  }, [data]);

  const closeCommentsPopup = () => {
    commentsPopupRef.current?.close();
  };

  const commentClickHandler = (ingredient: IngredientType) => {
    setSelectedIngredient(ingredient);
    commentsPopupRef.current?.open();
  };

  const addCommentSuccessHandler = (data: any) => {
    setSelectedIngredient(data);
    setIngredientList((prev) => {
      const componentIndex = prev.findIndex((ingredient) => ingredient.id === data.id);
      return [...prev.slice(0, componentIndex), data, ...prev.slice(componentIndex + 1)];
    });
  };

  return (
    <>
      <Stack sx={{ mb: 2, flexDirection: 'row', backgroundColor: 'white', borderRadius: '16px', px: '12px', py: '16px' }}>
        <Typography
          variant="h3"
          sx={{
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column',
              textAlign: 'center'
            },
            textAlign: 'left',
            fontSize: '24px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Chef's Playground
        </Typography>
      </Stack>
      {isLoading ? (
        <CaloLoader />
      ) : (
        <>
          <Stack sx={{ backgroundColor: 'white', borderRadius: '16px', px: '12px', py: '16px' }}>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>Ingredients</Typography>
              <Button
                startIcon={<AddIcon fontSize="small" />}
                onClick={() => history.push(Routes.playgroundNewIngredient)}
                disabled={!roles.includes(Permission.CREATE_PROTOTYPE_INGREDIENT)}
                sx={{
                  textTransform: 'none',
                  color: caloTheme.palette.neutral900,
                  fontSize: '16px'
                }}
              >
                Add
              </Button>
            </Stack>
            <Paper sx={{ width: '100%', mt: 1, boxShadow: 'none' }}>
              <TableContainer>
                <Table sx={{ width: '100%' }}>
                  <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, border: 0 }}>
                    <TableHeaderRow type="ingredient" />
                  </TableHead>
                  <TableBody>
                    {ingredientList.map((ingredient) => (
                      <IngredientRow key={ingredient.id} ingredient={ingredient} commentClickHandler={commentClickHandler} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Stack>
          <IngredientCommentsPopup
            selectedIngredient={selectedIngredient}
            ref={commentsPopupRef}
            closeCommentsPopup={closeCommentsPopup}
            successHandler={addCommentSuccessHandler}
          />
        </>
      )}
      <Settings onFilter={setFilters} filters={filters} />
    </>
  );
};

export default IngredientList;
