import { DeliveryTicketStatus, DeliveryTicketType } from '@calo/dashboard-types';
import cx from 'classnames';

const handleRequestAction = (req: any) => {
  if (req.data.type === DeliveryTicketType.ADDRESS) {
    if (req.data.addressTicketData.newAddress && req.data.addressTicketData.newDeliveryTime) {
      return 'Location & Shift Change';
    } else if (req.data.addressTicketData.newDeliveryTime && !req.data.addressTicketData.newAddress) {
      return 'Shift Change';
    } else {
      return 'Location Change';
    }
  } else {
    return 'Cancel Change';
  }
};
interface AddressChangeRequestProps {
  req: any;
}

const AddressChangeRequest = ({ req }: AddressChangeRequestProps) => {
  return (
    <div
      key={req.id}
      className={cx('rounded-lg min-h-24 mb-4 border h-auto w-full', {
        'border-red-500': req.status === DeliveryTicketStatus.REJECTED,
        'border-green': req.status === DeliveryTicketStatus.APPROVED
      })}
    >
      <span className="flex flex-column m-2">
        <span className="flex w-full justify-between">
          <p className="float-left font-bold uppercase">{req.data.type === 'address' ? 'Logistics change' : 'Cancel change'}</p>
          <p className="float-right">{handleRequestAction(req)}</p>
        </span>
        <span className="flex w-full justify-between">
          <p className="float-left font-bold mr-2">+{req.data.phoneNumber}</p>
          <p className="flex flex-row float-right">
            Invoice Number:<p className="font-bold ml-2">{req.data.deliveryShortId}</p>
          </p>
        </span>
        <span className="flex w-full justify-end">
          CX Agent:<p className="font-bold ml-2">{req.createdBy.name ?? ''}</p>
        </span>
        {req.status !== DeliveryTicketStatus.PENDING && (
          <span className="flex flex-row justify-end">
            <p
              className={cx('float-right mt-2 capitalize', {
                'text-green': req.status === DeliveryTicketStatus.APPROVED,
                'text-red-500': req.status === DeliveryTicketStatus.REJECTED
              })}
            >
              {req.status}
            </p>
          </span>
        )}
      </span>
    </div>
  );
};
export default AddressChangeRequest;
