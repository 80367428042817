import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Route, Switch } from 'react-router-dom';
import ExactKDSUser from './ExactKDSUser';
import KDSUserList from './KDSUserList';
import NewKDSUser from './NewKDSUser';

const KDSUsers = () => {
  const roles = useUserRoles();

  return (
    <Switch>
      {roles.includes(Permission.VIEW_KDS_USER) && (
        <Route key={Routes.kdsUser} exact path={Routes.kdsUser} component={ExactKDSUser} />
      )}
      {roles.includes(Permission.CREATE_KDS_USER) && (
        <Route key={Routes.newKdsUser} exact path={Routes.newKdsUser} component={NewKDSUser} />
      )}

      {roles.includes(Permission.VIEW_KDS_USER_LIST) && (
        <Route key={Routes.kdsUsersList} exact path={Routes.kdsUsersList} component={KDSUserList} />
      )}
    </Switch>
  );
};

export default KDSUsers;
