import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  cardContainer: {
    width: 'full',
    mb: 2,
    border: 'none',
    borderRadius: '8px',
    paddingBottom: '4px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 2,
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  textTitle: {
    fontWeight: 400,
    fontSize: '33px',
    lineHeight: '40px',
    color: caloTheme.palette.neutral900,
    fontFamily: caloTheme.typography.fontFamily
  },
  createCard: {
    width: 'full',
    mb: 2,
    overflow: 'visible',
    border: 'none',
    borderRadius: '8px',
    paddingBottom: '4px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  listTitle: {
    my: 'auto',
    fontFamily: caloTheme.typography.fontFamily,
    fontWeight: 600,
    lineHeight: '23px'
  },
  filterButton: {
    cursor: 'pointer',
    my: 'auto',
    '&:hover': {
      backgroundColor: 'white',
      borderColor: 'none'
    },
    color: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      justifyItems: 'center',
      margin: 'auto',
      marginTop: 4,
      width: 'auto'
    }
  },
  exportButton: {
    display: 'none',
    textTransform: 'none',
    height: '45px',
    ml: 2,
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
    padding: '14px 20px 14px 20px',
    color: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    '&:hover': {
      backgroundColor: caloTheme.palette.primary100,
      borderColor: caloTheme.palette.primary500
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      justifyItems: 'center',
      margin: 'auto',
      marginTop: 4,
      width: 'auto'
    }
  },
  tableStyle: {
    marginY: '4px',
    minHeight: '120px',
    overflow: 'auto',
    width: '100%',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  text: {
    mt: 2,
    justifyContent: 'center',
    color: caloTheme.palette.neutral400,
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '18px'
  }
};

export default styles;
