import { Route, Switch } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';

import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { SubscriptionUsersList } from './SubscriptionUsersList';

const SubscriptionUsers = () => {
  const roles = useUserRoles();
  return (
    <Switch>
      {roles.includes(Permission.LIST_APP_COGNITO_USERS) && (
        <Route key={Routes.subscriptionUsers} exact path={Routes.subscriptionUsers} component={SubscriptionUsersList} />
      )}
    </Switch>
  );
};

export default SubscriptionUsers;
