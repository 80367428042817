import ExcelJS, { Column, Style } from 'exceljs';
import saveAs from 'file-saver';

import { FoodStatsFilters } from '@calo/dashboard-types';

import { ComponentFoodSubStats, StatsFoodComponent } from 'lib/interfaces';
import { ceil } from 'lodash';

const columnsStyle: Partial<Style> = {
  border: {
    top: { style: 'thin', color: { argb: 'D3D3D3' } },
    left: { style: 'thin', color: { argb: 'D3D3D3' } },
    bottom: { style: 'thin', color: { argb: 'D3D3D3' } },
    right: { style: 'thin', color: { argb: 'D3D3D3' } }
  },
  alignment: {
    vertical: 'middle',
    horizontal: 'left'
  }
};

const columns: Array<Partial<Column>> = [
  { width: 30, key: 'componentName', style: columnsStyle },
  { width: 30, key: 'rawWeight', style: columnsStyle },
  { width: 30, key: 'cookedWeight', style: columnsStyle },
  { width: 20, key: 'quantity', style: columnsStyle },
  { width: 20, key: 'quantityAfterPrep', style: columnsStyle },
  { width: 20, key: 'removedQuantity', style: columnsStyle }
];

interface ComponentsDrawerProps {
  component: StatsFoodComponent;
  filters: FoodStatsFilters;
  componentFoodStats: ComponentFoodSubStats[];
}

const onExport = async ({ component, componentFoodStats, filters }: ComponentsDrawerProps) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('component-food-stats', {
    pageSetup: { fitToPage: true, orientation: 'portrait' }
  });
  worksheet.columns = columns;
  worksheet.mergeCells('A1', 'F1');
  worksheet.getCell('A1').value = `${filters.day.gte} - ${filters.day.lte}`;
  worksheet.getCell('A1').font = { color: { argb: '0000' }, bold: true, size: 18 };
  worksheet.getCell('A1').alignment = {
    vertical: 'middle',
    horizontal: 'center'
  };
  worksheet.getRow(2).values = [
    'Component Name',
    'Measurement Unit',
    'Raw Weight',
    'Cooked Weight',
    'Child Weight',
    'Removed Raw Weight',
    'Removed Cooked Weight'
  ];
  worksheet.getRow(2).font = { color: { argb: '0000' }, bold: true };
  worksheet.getRow(3).values = [
    component.name.en,
    'Gram',
    component.stats.rawWeight,
    component.stats.cookedWeight,
    component.stats.childWeight,
    component.stats.removedRawWeight,
    component.stats.removedCookedWeight
  ];
  worksheet.getRow(4);
  worksheet.getRow(5).values = ['Meal Name', 'Size', 'No. of Meals', 'Cooked Weight', 'Removed Cooked Weight'];
  worksheet.getRow(5).font = { color: { argb: '0000' }, bold: true };

  let rowIndex = 6;

  for (const row of componentFoodStats) {
    worksheet.getRow(rowIndex).values = [
      `${row.name.en} ${row.components ? '(Child)' : ''}`,
      row.size,
      row.mealCount,
      ceil(row.weight, 1),
      row.removedWeight ?? 0
    ];
    rowIndex++;
  }

  worksheet.getRow(rowIndex++).values = [];

  const buffer = await workbook.xlsx.writeBuffer();
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const fileExtension = '.xlsx';
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, 'component-meals-stats' + fileExtension);
};

export default onExport;
