import ExcelJS, { Column, Style } from 'exceljs';
import { EmployeePerks, EmployeePerksInfo } from 'lib/interfaces';
import { orderBy, startCase } from 'lodash-es';

export const getRowData = (row: EmployeePerksInfo) => {
  const removedBalance = Math.max(row.amount - (row.usedBalance ?? NaN), 0);
  return {
    id: row.id,
    name: startCase(row.name),
    amount: row.amount,
    currency: row.currency,
    status: row.status,
    balance: row.balance,
    addedBalance: row.amount,
    removedBalance: removedBalance,
    finalBalance: (row.balance ?? 0) - removedBalance + row.amount,
    usedBalance: row.usedBalance ?? 0
  };
};

const border: Partial<Style> = {
  font: { size: 12 },
  border: {
    top: { style: 'thin', color: { argb: 'D3D3D3' } },
    left: { style: 'thin', color: { argb: 'D3D3D3' } },
    bottom: { style: 'thin', color: { argb: 'D3D3D3' } },
    right: { style: 'thin', color: { argb: 'D3D3D3' } }
  }
};

export const columns: Array<Partial<Column>> = [
  { header: 'ID', width: 35, key: 'id', style: border },
  { header: 'Name', width: 25, key: 'name', style: border },
  { header: 'Amount', width: 10, key: 'amount', style: border },
  { header: 'Currency', width: 10, key: 'currency', style: border },
  { header: 'Status', width: 8, key: 'status', style: border },
  { header: 'Reason', width: 10, key: 'reason', style: border },
  { header: 'Balance', width: 10, key: 'balance', style: border },
  { header: 'Added Balance', width: 15, key: 'addedBalance', style: border },
  { header: 'Removed Balance', width: 18, key: 'removedBalance', style: border },
  { header: 'Final Balance', width: 15, key: 'finalBalance', style: border },
  { header: 'Used Balance', width: 15, key: 'usedBalance', style: border }
];

export const prepareForExport = (workbook: ExcelJS.Workbook, employeePerks: EmployeePerks) => {
  const employeePerksList = employeePerks.employees;
  const sortedEmployeePerksList = orderBy(employeePerksList, (employee) => employee.name.toLowerCase(), 'asc');
  const ws = workbook.addWorksheet();

  ws.getRow(1).values = [
    'ID',
    'Name',
    'Amount',
    'Currency',
    'Status',
    'Reason',
    'Balance',
    'Added Balance',
    'Removed Balance',
    'Final Balance',
    'Used Balance'
  ];

  ws.getRow(1).eachCell((cell) => {
    cell.style = {
      ...border,
      font: { bold: true, size: 12 },
      alignment: { horizontal: 'center' }
    };
  });
  ws.columns = columns;
  ws.addRows(sortedEmployeePerksList.map(getRowData));
};
