import { Button, Input, Modal } from 'components';
import { BaseOmit, FoodComponent } from 'lib/interfaces';

import { CupSizes } from '@calo/dashboard-types';

interface AddCupSizeModalProps {
  addCupsRef: any;
  cups: CupSizes;
  setCups: (value: CupSizes) => void;
  setFieldValue: any;
  values: Omit<FoodComponent, BaseOmit>;
}

const AddCupSizeModal = ({ addCupsRef, cups, setCups, setFieldValue, values }: AddCupSizeModalProps) => {
  const createRules = () => {
    if (cups.value && cups.size) {
      setFieldValue('cups', [cups, ...(Array.isArray(values.cups) ? values.cups : [])]);
      addCupsRef.current?.close();
      setCups({ size: undefined!, value: undefined! });
    }
  };

  return (
    <div>
      <Modal ref={addCupsRef}>
        <div className="flex flex-col">
          <Input
            data-test="cupsSizeInput"
            label="Cup Size"
            onChange={(data: any) => setCups({ ...cups, size: +data.target.value })}
            name="cups.size"
            type="number"
            min={0}
            step="0.25"
          />
          <Input
            data-test="cupsValueInput"
            label="Cup value"
            onChange={(data: any) => setCups({ ...cups, value: +data.target.value })}
            name="cups.value"
            type="number"
            min={0}
            step="any"
          />
          <Button data-test="cupsConfirmButton" warning content="Confirm" onClick={() => createRules()} />
        </div>
      </Modal>
    </div>
  );
};

export default AddCupSizeModal;
