import { CouponCategory, Permission } from '@calo/dashboard-types';
import { CouponType } from '@calo/types';
import cx from 'classnames';
import { format } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import { checkExpireDate } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { Link } from 'react-router-dom';

interface CouponRowProps {
  coupon: any;
  selected: CouponCategory;
}

const CouponRow = ({ coupon, selected }: CouponRowProps) => {
  const roles = useUserRoles();

  return (
    <tr className=" h-12  m-auto">
      <td>
        {roles.includes(Permission.VIEW_COUPON) && coupon.category === CouponCategory.promo ? (
          <Link to={Routes.coupon.replace(':id', coupon.id)}>{coupon.code}</Link>
        ) : (
          `${coupon.category === CouponCategory.promo ? coupon.code : coupon.id}`
        )}
      </td>

      <td>
        {coupon.type === CouponType.FIXED ? (
          <p>{`${coupon.amount ? coupon.amount : 0} ${coupon.currency}`}</p>
        ) : (
          <p>{`${coupon.amount}%`}</p>
        )}
      </td>

      <td>
        {coupon.expiresAt ? (
          selected === CouponCategory.promo ? (
            <div
              className={cx(' border rounded-lg w-28 h-7 text-white text-center ', {
                'bg-green-500': checkExpireDate(coupon.expiresAt!),
                'bg-red-500': !checkExpireDate(coupon.expiresAt!)
              })}
            >
              <span>{checkExpireDate(coupon.expiresAt!) ? 'Active' : 'Expired'}</span>
            </div>
          ) : (
            <div className={cx(' border rounded-lg w-28 h-7 text-white text-center bg-green-500 ', {})}>
              <span className="mx-auto">Redeemed</span>
            </div>
          )
        ) : (
          <div className={cx(' border rounded-lg w-28 h-7 text-white text-center bg-green-500 ', {})}>
            <span className="mx-auto">Active</span>
          </div>
        )}
      </td>
      {selected === CouponCategory.promo && (
        <>
          <td>{coupon.createdAt && <p>{format('dd-MM-yyyy')(Date.parse(coupon.createdAt))}</p>}</td>
          <td>
            {coupon.expiresAt ? (
              <span>
                <p>{format('dd-MM-yyyy')(Date.parse(coupon.expiresAt))}</p>
                <p className="text-gray-500 text-sm">{format('hh:mm a')(Date.parse(coupon.expiresAt))}</p>
              </span>
            ) : (
              <span>
                <p className="text-gray-500 text-sm">----</p>
              </span>
            )}
          </td>
        </>
      )}
      <td>
        <p>{coupon.currency}</p>
      </td>

      <td>
        <p>{coupon.usedTimes}</p>
      </td>
    </tr>
  );
};

export default CouponRow;
