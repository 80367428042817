import { useEffect } from 'react';

import { FormikErrors } from 'formik';
import { pick } from 'lodash-es';

import { MeasurementUnit } from '@calo/dashboard-types';
import { Card, MenuItem, Stack, TextField, Typography } from '@mui/material';

import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { InputValueRestrictionType } from 'lib/enums';
import { calculateCalories, isNumberInputValid } from 'lib/helpers';
import { IngredientFormData } from 'lib/interfaces';

interface MeasurementGuideAndMacrosCardProps {
  values: IngredientFormData;
  errors: FormikErrors<IngredientFormData>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<IngredientFormData>> | Promise<any>;
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
  handleChange: { (e: React.ChangeEvent<any>): void };
  disabled?: boolean;
}

const MeasurementGuideAndMacrosCard = ({
  values,
  errors,
  disabled,
  setFieldValue,
  handleChange,
  handleBlur
}: MeasurementGuideAndMacrosCardProps) => {
  useEffect(() => {
    setFieldValue('macros.cal', calculateCalories(values.macros));
  }, [values.macros?.carbs, values.macros?.fat, values.macros?.protein]);

  useEffect(() => {
    const calValue = calculateCalories(values.macros);
    if (values.macros?.cal !== calValue) {
      setFieldValue('macros.cal', calValue);
    }
  }, []);

  useEffect(() => {
    if (values.measurementUnit === MeasurementUnit.g) {
      setFieldValue('weight', 1);
    }
  }, [values.measurementUnit]);

  const handleChangeForNumRestrictions = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    if (isNumberInputValid(value, InputValueRestrictionType.macros)) {
      setFieldValue(`${name}`, Number(value));
    }
  };

  return (
    <InputThemeProvider>
      <Card
        variant="outlined"
        sx={{
          marginTop: '16px',
          border: 'none',
          borderRadius: '16px',
          px: '12px',
          py: '20px'
        }}
      >
        <Typography variant="h5" sx={{ fontSize: '19px', fontWeight: 600, marginBottom: '20px' }}>
          Measurement Guide
        </Typography>
        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginBottom: 2 }}>
          <TextField
            select
            label="Measurement Unit"
            value={values.measurementUnit}
            placeholder="Select Unit"
            sx={{ width: '100%' }}
            disabled={Boolean(disabled)}
            onChange={(data: any) => setFieldValue('measurementUnit', data.target.value)}
            error={!!errors.measurementUnit}
          >
            {Object.values(pick(MeasurementUnit, ['g', 'ml', 'piece'])).map((unit) => (
              <MenuItem key={unit} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Weight (in grams)*"
            value={values.weight}
            name="weight"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            sx={{ width: '100%', zIndex: 0 }}
            disabled={Boolean(disabled) || values.measurementUnit === MeasurementUnit.g}
            error={!!errors.wastage}
          />
          <TextField
            label="Wastage*"
            value={values.wastage}
            name="wastage"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            sx={{ width: '100%', zIndex: 0 }}
            disabled={Boolean(disabled)}
            error={!!errors.wastage}
          />
        </Stack>
        <Typography variant="h5" sx={{ fontSize: '19px', fontWeight: 600, marginBottom: '20px' }}>
          Macros as per measurement unit
        </Typography>
        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2 }}>
          <TextField
            label="Cal*"
            value={values.macros?.cal}
            name="macros.cal"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            sx={{ width: '100%', zIndex: 0 }}
            disabled
            error={!!(errors.macros as any)?.cal}
          />
          <TextField
            label="Protein*"
            value={values.macros?.protein}
            name="macros.protein"
            onChange={handleChangeForNumRestrictions}
            onBlur={handleBlur}
            type="number"
            sx={{ width: '100%', zIndex: 0 }}
            disabled={Boolean(disabled)}
            error={!!(errors.macros as any)?.protein}
          />
          <TextField
            label="Carbs*"
            value={values.macros?.carbs}
            name="macros.carbs"
            onChange={handleChangeForNumRestrictions}
            type="number"
            onBlur={handleBlur}
            sx={{ width: '100%', zIndex: 0 }}
            disabled={Boolean(disabled)}
            error={!!(errors.macros as any)?.carbs}
          />
          <TextField
            label="Fat*"
            value={values.macros?.fat}
            name="macros.fat"
            onChange={handleChangeForNumRestrictions}
            onBlur={handleBlur}
            type="number"
            sx={{ width: '100%', zIndex: 0 }}
            disabled={Boolean(disabled)}
            error={!!(errors.macros as any)?.fat}
            inputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          />
          <TextField
            label="Fiber"
            value={values.macros?.fiber}
            name="macros.fiber"
            onChange={handleChangeForNumRestrictions}
            onBlur={handleBlur}
            type="number"
            sx={{ width: '100%', zIndex: 0 }}
            disabled={Boolean(disabled)}
            inputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          />
        </Stack>
        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 3, gap: 2 }}>
          <TextField
            label="Saturated Fats"
            value={values.micronutrients?.saturatedFats}
            name="micronutrients.saturatedFats"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            disabled={Boolean(disabled)}
            sx={{ width: '100%', zIndex: 0 }}
            error={!!(errors.micronutrients as any)?.saturatedFats}
          />
          <TextField
            label="Trans Fats"
            value={values.micronutrients?.transFats}
            name="micronutrients.transFats"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            disabled={Boolean(disabled)}
            sx={{ width: '100%', zIndex: 0 }}
            error={!!(errors.micronutrients as any)?.transFats}
          />
          <TextField
            type="number"
            label="Sodium"
            onBlur={handleBlur}
            onChange={handleChange}
            name="micronutrients.sodium"
            sx={{ width: '100%', zIndex: 0 }}
            value={values.micronutrients?.sodium}
            error={!!(errors.micronutrients as any)?.sodium}
            disabled={Boolean(disabled)}
          />
          <TextField
            label="Cholesterol"
            value={values.micronutrients?.cholesterol}
            name="micronutrients.cholesterol"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            disabled={Boolean(disabled)}
            sx={{ width: '100%', zIndex: 0 }}
            error={!!(errors.micronutrients as any)?.cholesterol}
          />
          <TextField
            type="number"
            onBlur={handleBlur}
            label="Added sugar"
            name="micronutrients.addedSugar"
            onChange={handleChange}
            sx={{ width: '100%', zIndex: 0 }}
            value={values.micronutrients?.addedSugar}
            error={!!(errors.micronutrients as any)?.addedSugar}
            disabled={Boolean(disabled)}
          />
          <TextField
            type="number"
            onBlur={handleBlur}
            label="Total sugar"
            name="micronutrients.totalSugar"
            onChange={handleChange}
            sx={{ width: '100%', zIndex: 0 }}
            value={values.micronutrients?.totalSugar}
            error={!!(errors.micronutrients as any)?.totalSugar}
            disabled={Boolean(disabled)}
          />
        </Stack>
      </Card>
    </InputThemeProvider>
  );
};

export default MeasurementGuideAndMacrosCard;
