import { Subscription } from '@calo/types';

import { Modal } from 'components';
import { MenuFood } from 'lib/interfaces';
import AddMealTable from '../AddMealTable';

export interface AddMealModalProps {
  menu: any;
  page: number;
  addMealModalRef: any;
  LoadingMeals: boolean;
  selectedMeals: MenuFood[];
  selectedCountryBrand: any;
  filterName: string | undefined;
  autoSelectSubscribers: boolean | undefined;
  setPage: (value: number) => void;
  selectedSubscribers: Subscription[];
  setFilterName: (value: string) => void;
  setSelectedMeals: (value: MenuFood[]) => void;
  setSelectedSubscribers: (value: Subscription[]) => void;
}

const AddMealModal = ({
  page,
  addMealModalRef,
  menu,
  selectedCountryBrand,
  setPage,
  filterName,
  LoadingMeals,
  setSelectedMeals,
  selectedMeals,
  selectedSubscribers,
  setSelectedSubscribers,
  autoSelectSubscribers,
  setFilterName
}: AddMealModalProps) => (
  <Modal
    ref={addMealModalRef}
    onClose={() => {
      addMealModalRef.current?.close();
    }}
  >
    <>
      <section className="section is-title-bar -my-8">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="uppercase font-roboto text-2xl">Choose Meal</p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <p className="uppercase font-roboto text-2xl">
                {selectedCountryBrand.country}-{selectedCountryBrand.brand}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <AddMealTable
          menu={menu}
          page={page}
          setPage={setPage}
          filterName={filterName}
          loadingMeals={LoadingMeals}
          setFilterName={setFilterName}
          addMealModalRef={addMealModalRef}
          setSelectedMeals={setSelectedMeals}
          selectedMeals={selectedMeals || []}
          selectedSubscribers={selectedSubscribers!}
          setselectedSubscribers={setSelectedSubscribers}
          selectedCountryBrand={{ country: selectedCountryBrand.country!, brand: selectedCountryBrand.brand! }}
          autoSelectSubscribers={autoSelectSubscribers}
        />
      </section>
    </>
  </Modal>
);
export default AddMealModal;
