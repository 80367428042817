import { DeliveryTicketStatus, RatingFilters } from '@calo/dashboard-types';
import { Icon as Iconify } from '@iconify/react';
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses
} from '@mui/material';
import { getImageUploadLink, getListWithParams } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, ImageUploader, ModalRef, Pagination } from 'components';
import Popup from 'components/Popup';
import { FoodFeedbackPages } from 'lib/enums';
import { startCase } from 'lodash-es';
import { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import FoodFeedbackTab from '../FoodFeedbackTab';

interface ComplaintsCardProps {
  filters: RatingFilters;
  selectedPage: FoodFeedbackPages;
  setSelectedPage: (value: FoodFeedbackPages) => void;
}
const ComplaintsCard = ({ filters, selectedPage, setSelectedPage }: ComplaintsCardProps) => {
  const [page, setPage] = useState(0);
  const [selectedComplaint, setSelectedComplaint] = useState<any | undefined>();
  const commentRef = useRef<ModalRef>();
  const attachmentRef = useRef<ModalRef>();
  const [displayImage, setDisplayImage] = useState<string[]>([]);
  const { data, isLoading } = useQuery<any, Error, any>(
    [
      'complaints',
      {
        page,
        filters: {
          foodIds: filters.foodIds,
          kitchen: filters.kitchen,
          date: filters.createdAt,
          status: [DeliveryTicketStatus.APPROVED, DeliveryTicketStatus.PENDING]
        },
        limit: 50
      }
    ],
    getListWithParams,
    {
      suspense: false
    }
  );

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      justifyContent: 'center'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'center'
    }
  }));

  return (
    <>
      <Popup
        maxWidth="lg"
        fullWidth
        title="Comment"
        ref={commentRef}
        onClose={() => {
          commentRef.current?.close();
        }}
      >
        <Box>
          <Stack
            sx={{
              flexDirection: 'row',
              display: 'flex',
              width: '100%',
              marginX: 2
            }}
          >
            <Typography
              sx={{
                color: caloTheme.palette.neutral900,
                fontFamily: 'Roboto',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '20px'
              }}
            >
              {selectedComplaint && selectedComplaint.comments
                ? selectedComplaint.comments[selectedComplaint?.comments.length - 1].text
                : ''}
            </Typography>
          </Stack>
          <Stack
            sx={{
              justifyContent: 'center',
              flexDirection: 'row',
              display: 'flex',
              width: '100%',
              marginTop: 2
            }}
          >
            <Button
              variant="text"
              sx={{
                width: '156px',
                color: caloTheme.palette.primary500,
                fontFamily: 'Roboto',
                textAlign: 'center',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '23px',
                textTransform: 'capitalize'
              }}
              onClick={() => {
                commentRef.current?.close();
                setSelectedComplaint(undefined);
              }}
            >
              Done
            </Button>
          </Stack>
        </Box>
      </Popup>

      <Popup
        maxWidth="lg"
        fullWidth
        title="Attachment"
        ref={attachmentRef}
        onClose={() => {
          attachmentRef.current?.close();
        }}
      >
        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} key={selectedComplaint?.id}>
          <Grid container spacing={5}>
            {displayImage?.map((image: string, index) => (
              <Grid item key={image}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'start'}>
                      <Stack key={image} display={'flex'} flexDirection={'column'} justifyContent={'start'}>
                        <ImageUploader
                          key={selectedComplaint?.id}
                          uploadLink={() => getImageUploadLink(`complaints/${selectedComplaint?.id.split('#')[1]}/${image}`)}
                          image={`${process.env.REACT_APP_BUCKET_URL}/complaints/${selectedComplaint?.id.split('#')[1]}/${image}`}
                          disabled={false}
                          width={120}
                          maxHeight={44}
                        />
                      </Stack>
                      <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ width: '100%' }}>
                        <Typography sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
                          Attachment {index + 1}
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Stack
          sx={{
            justifyContent: 'center',
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
            marginTop: 2
          }}
        >
          <Button
            variant="text"
            sx={{
              width: '156px',
              color: caloTheme.palette.primary500,
              fontFamily: 'Roboto',
              textAlign: 'center',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '23px',
              textTransform: 'capitalize'
            }}
            onClick={() => {
              attachmentRef.current?.close();
              setSelectedComplaint(undefined);
            }}
          >
            Done
          </Button>
        </Stack>
      </Popup>

      <Card
        variant="outlined"
        sx={{
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <>
          <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px'
              }}
            >
              Feedback List
            </Typography>
            <FoodFeedbackTab selectedPage={selectedPage} setSelectedPage={(value) => setSelectedPage(value)} />
            {isLoading ? (
              <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                <CaloLoader />
              </Stack>
            ) : (
              <Table
                sx={{
                  marginY: '4px',
                  minHeight: '120px',
                  overflow: 'auto',
                  width: '100%',
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    flexDirection: 'column'
                  }
                }}
              >
                <TableHead
                  sx={{
                    backgroundColor: caloTheme.palette.neutral50,
                    color: 'black',
                    flexWrap: 0,
                    justifyContent: 'space-between',
                    width: '100%',
                    borderRadius: '8px'
                  }}
                >
                  <TableRow
                    sx={{
                      backgroundColor: caloTheme.palette.neutral50,
                      color: 'black',
                      width: '100%',
                      flexWrap: 0,
                      justifyContent: 'space-between'
                    }}
                  >
                    <StyledTableCell>Meal</StyledTableCell>
                    <StyledTableCell>User</StyledTableCell>
                    <StyledTableCell>Complaint Type</StyledTableCell>
                    <StyledTableCell>Complaint</StyledTableCell>
                    <StyledTableCell>Delivery Day</StyledTableCell>
                    <StyledTableCell>Attachment</StyledTableCell>
                    <StyledTableCell>Comment</StyledTableCell>
                  </TableRow>
                </TableHead>
                {data && data.data.length === 0 ? (
                  <span className="absolute w-11/12 text-2xl mt-6 text-center font-bold text-gray-400 ">NO COMPLAINTS</span>
                ) : (
                  <TableBody>
                    {data?.data?.map((complaint: any) => (
                      <TableRow hover>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{
                            textOverflow: 'ellipsis'
                          }}
                        >
                          <Typography>{complaint.meal.name}</Typography>
                        </StyledTableCell>

                        <StyledTableCell className="w-64">
                          <Typography>{complaint.user.name}</Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          <Typography>{startCase(complaint.category.toLowerCase())}</Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          <Typography>{startCase(complaint.type.toLowerCase())}</Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          <Typography>{complaint.date}</Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          <Button
                            variant="text"
                            disabled={!complaint.attachment}
                            sx={{
                              width: '45px',
                              color: caloTheme.palette.neutral900,
                              fontFamily: 'Roboto',
                              fontWeight: 600,
                              fontSize: '16px',
                              lineHeight: '19px',
                              height: '45px',
                              textTransform: 'capitalize',
                              textAlign: 'center',
                              backgroundColor: caloTheme.palette.neutral50,
                              borderRadius: 2
                            }}
                            onClick={() => {
                              setSelectedComplaint(complaint);
                              setDisplayImage(complaint?.attachments);
                              attachmentRef.current?.open();
                            }}
                          >
                            <Iconify icon="mingcute:attachment-line" rotate={1} />
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            variant="text"
                            sx={{
                              width: '45px',
                              color: caloTheme.palette.neutral900,
                              fontFamily: 'Roboto',
                              fontWeight: 600,
                              fontSize: '16px',
                              lineHeight: '19px',
                              height: '45px',
                              textTransform: 'capitalize',
                              textAlign: 'center',
                              backgroundColor: caloTheme.palette.neutral50,
                              borderRadius: 2
                            }}
                            disabled={
                              !complaint.comments || complaint.comments[complaint.comments?.length - 1]?.text?.length === 0
                            }
                            onClick={() => {
                              setSelectedComplaint(complaint);
                              commentRef.current?.open();
                            }}
                          >
                            <Iconify icon="octicon:comment-24" />
                          </Button>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            )}
          </Box>
          {data && (
            <Pagination
              isHidden={data.data.length === 0}
              limit={data.meta.limit}
              total={data.meta.total}
              page={page}
              onChange={setPage}
            />
          )}
        </>
      </Card>
    </>
  );
};
export default ComplaintsCard;
