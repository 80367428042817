import { CustomMealCategory, FoodComponentType } from '@calo/types';
import { Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { handleTagBarText } from 'components/FoodComponentShared/CustomSectionCard/helpers';
import { ComponentCountByCustomMealType } from 'lib/enums';
import { ComponentsByTag } from 'lib/interfaces';

interface ComponentTagsListProps {
  customMealCategory: CustomMealCategory;
  selectedTag: FoodComponentType;
  selectedComponents: ComponentsByTag;
  setSelectedTag: React.Dispatch<React.SetStateAction<FoodComponentType>>;
}

const ComponentTagsList = ({ selectedTag, selectedComponents, customMealCategory, setSelectedTag }: ComponentTagsListProps) => {
  return (
    <Stack id="components-tags-list" sx={{ py: '4px', flexDirection: 'row', marginTop: '14px' }}>
      {Object.values(FoodComponentType).map((tag) => (
        <Stack
          key={tag}
          onClick={() => setSelectedTag(tag)}
          sx={{
            cursor: 'pointer',
            width: '20%',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottomWidth: '1px',
            ...(selectedTag === tag && { borderColor: caloTheme.palette.primary500 }),
            py: '4px'
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600
            }}
          >
            {handleTagBarText(tag, customMealCategory)} ({selectedComponents[tag].length}/
            {ComponentCountByCustomMealType[customMealCategory][tag]})
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default ComponentTagsList;
