import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Route, Switch } from 'react-router-dom';
import DriversList from './DriversList';

const Coupons = () => {
  const roles = useUserRoles();
  return (
    <Switch>
      {roles.includes(Permission.VIEW_DRIVER_LIST) && [
        <Route key={Routes.drivers} exact path={Routes.drivers} component={DriversList} />
      ]}
    </Switch>
  );
};

export default Coupons;
