import { CouponRuleType } from '@calo/dashboard-types';
import cx from 'classnames';
import { Button } from 'components';
import { flatten } from 'lodash';
import { useEffect } from 'react';

interface CreditCardListProps {
  exList: any;
  addCreditCardListRef: any;
  setExList: (value: any) => void;
  handleRemoveUser: (value: any) => void;
}

const CreditCardList = ({ exList, addCreditCardListRef, setExList, handleRemoveUser }: CreditCardListProps) => {
  useEffect(() => {
    setExList({ type: CouponRuleType.CREDIT_CARD_PREFIX, value: exList.value || [] });
  }, []);

  return (
    <>
      <div className="flex mb-4">
        <span className="fas fa-info-circle flex">
          <p className="text-gray-300 text-xs ml-2"> Based on Card's initial 6 digits </p>
        </span>
      </div>
      <label> Exclusive Coupon for:</label>
      <div className="overflow-y-auto h-64 w-auto mb-4 border-2">
        {flatten(exList.value).map((f: any, index) => (
          <div className={cx('right-0 my-3 mx-2 border border-gray-200 bg-gray-200 shadow-lg rounded-md')} key={index}>
            <span>
              <i className="fas fa-times ml-2 cursor-pointer" onClick={() => handleRemoveUser(f)} />
            </span>
            <span className="text-black mx-2" key={index}>
              {f}
            </span>
          </div>
        ))}
      </div>

      <div className="flex mb-4">
        <Button className="mx-2" type="submit" primary content="Add" onClick={() => addCreditCardListRef.current?.open()} />
      </div>
    </>
  );
};

export default CreditCardList;
