import { Button, Select2 } from 'components';
import { MenuFood } from 'lib/interfaces';
import { compact, flatten, groupBy, uniqBy } from 'lodash';
import { useState } from 'react';

interface AddAddonRowProps {
  food: any;
  allAddonMenuFood: any[];
  selectedAddons: any[];
  autoSelectSubscribers: boolean | undefined;
  onPick: (food: any[]) => void;
}

const AddAddonRow = ({ allAddonMenuFood, food, onPick, selectedAddons, autoSelectSubscribers }: AddAddonRowProps) => {
  const [selectedSize, setSelectedSize] = useState<any>({ size: food.size, foodId: food.id, foodName: food.name.en });
  const sameMealSize = flatten(Object.values(groupBy(allAddonMenuFood, 'name.en')));

  const handleSizeChange = (size: string, allSizes: MenuFood[]) => {
    const selectedMealData = flatten(compact(allSizes.filter((r) => r.size === size)));
    setSelectedSize({
      size: selectedMealData[0].size,
      foodId: selectedMealData[0].id,
      foodName: selectedMealData[0].name.en
    });
  };

  const handleFoodSize = (foodName: MenuFood) => {
    const allSizes = compact(sameMealSize.map((r) => r.name.en === foodName.name.en && r));

    return (
      <Select2
        value={selectedSize?.size}
        name="size"
        disabled={!!selectedAddons.find((r) => r.id === selectedSize.foodId)}
        onChange={(data: any) => handleSizeChange(data.target.value, allSizes)}
        options={Object.values(allSizes).map((meal) => ({
          value: meal.size,
          label: meal.size
        }))}
      />
    );
  };

  const handleSelectMeal = (food: MenuFood, selectedSize: any) => {
    if (selectedSize.foodName === food.name.en && selectedSize.size !== food.size) {
      const selectedMealSize = allAddonMenuFood.find((meal) => meal.id === selectedSize.foodId);
      onPick(uniqBy([...selectedAddons, selectedMealSize!], 'id'));
    } else {
      onPick(uniqBy([...selectedAddons, food], 'id'));
    }
  };

  const handleRemoveMeal = (food: MenuFood, selectedSize: any) => {
    if (selectedSize.foodName === food.name.en && selectedSize.size !== food.size) {
      const selectedMealSize = allAddonMenuFood.find((meal) => meal.id === selectedSize.foodId);
      onPick(
        uniqBy(
          selectedAddons.filter((r) => r !== selectedMealSize!),
          'id'
        )
      );
    } else {
      onPick(
        uniqBy(
          selectedAddons.filter((meal) => meal !== food),
          'id'
        )
      );
    }
  };

  const handleButton = (food: any) => {
    if (autoSelectSubscribers) {
      if (selectedAddons.includes(food)) {
        return (
          <Button
            className="w-24  border-2 border-red-500 bg-red-50 text-red-500 outline:none hover:text-red-500 hover:border-red-500 focus:border-red-500 focus:text-red-500"
            content="Remove"
            onClick={() => handleRemoveMeal(food, selectedSize)}
          />
        );
      } else {
        return (
          <Button
            className="w-24  border border-green-500 text-green-500 outline:none hover:text-green-500 hover:border-green-500 focus:border-green-500 focus:text-green-500"
            content={'Add'}
            onClick={() => handleSelectMeal(food, selectedSize)}
          />
        );
      }
    } else {
      if (selectedAddons.includes(food)) {
        return (
          <Button
            className="w-24  border-2 border-red-500 bg-red-50 text-red-500 outline:none hover:text-red-500 hover:border-red-500 focus:border-red-500 focus:text-red-500"
            content="Remove"
            onClick={() => handleRemoveMeal(food, selectedSize)}
          />
        );
      } else {
        return (
          <Button
            className="w-24  border border-green-500 text-green-500 outline:none hover:text-green-500 hover:border-green-500 focus:border-green-500 focus:text-green-500"
            content={'Add'}
            onClick={() => handleSelectMeal(food, selectedSize)}
          />
        );
      }
    }
  };
  return (
    <>
      <tr key={food.id}>
        <td>
          <span>{food.name.en}</span>
        </td>
        <td>{handleFoodSize(food)}</td>
        <td>{handleButton(food)}</td>
      </tr>
    </>
  );
};
export default AddAddonRow;
