import {
  Box,
  Button,
  Card,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useMemo } from 'react';
import { useInfiniteQuery, useMutation } from 'react-query';

import { Subscription } from '@calo/types';
import { syncBalance } from 'actions/loyalty';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { useDocumentMedia } from 'hooks';
import { loyaltyClient } from 'lib/client';
import { loyaltyTransactionLogStatus } from 'lib/enums';
import { resolveLoyaltyTransactionLogStatus } from 'lib/helpers';
import { LoyaltyTransaction, PaginatedLoyaltyTransactions } from 'lib/interfaces';
import PointsTransactionRow from './PointsTransactionRow';
import PointsTransactionRowMobile from './PointsTransactionRowMobile';

interface TransactionsLogsProps {
  subscription: Subscription & {
    remainingDays: number;
    expectedLastDeliveryDay: string;
    ratings: any[];
    magicPrice?: boolean;
  };
}
const TransactionsLogs = ({ subscription }: TransactionsLogsProps) => {
  const { isMobile, isTablet } = useDocumentMedia();
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<PaginatedLoyaltyTransactions>(
    `dashboard/transactions/${subscription.id}`,
    async ({ queryKey, pageParam }) => {
      const { data } = await loyaltyClient.get(queryKey.join('/'), {
        params: {
          limit: 1000,
          ...(pageParam && {
            cursor: pageParam
          })
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => data.meta.cursor
    }
  );

  const { mutateAsync: syncBalanceMutation } = useMutation(syncBalance);

  const list = useMemo(
    () =>
      (data?.pages || []).reduce<LoyaltyTransaction[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [data]
  );

  const actualBalance = list.reduce((balance, curr) => {
    const itemType = resolveLoyaltyTransactionLogStatus(curr.type);
    if ([loyaltyTransactionLogStatus.added, loyaltyTransactionLogStatus.added_by_calo].includes(itemType!)) {
      balance += curr.points;
    } else if ([loyaltyTransactionLogStatus.redeemed, loyaltyTransactionLogStatus.expired].includes(itemType!)) {
      balance -= curr.points;
    }
    return balance;
  }, 0);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      flexShrink: 0,
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px',
      variant: 'caption',
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    },
    [`&.${tableCellClasses.body}`]: {
      flexShrink: 0,
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      variant: 'caption',
      lineHeight: '14px',
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    }
  }));
  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: '100%',
          border: 'none',
          borderRadius: '8px',
          marginTop: '14px'
        }}
      >
        <Box
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column'
            }
          }}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '19px',
              lineHeight: '23px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600
            }}
          >
            Logs
          </Typography>
        </Box>
        {!hasNextPage && !isLoading && (
          <Box
            sx={{
              padding: 2,
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: 'auto',
                display: 'flex',
                textAlign: 'center',
                flexDirection: 'column'
              }
            }}
            display={'flex'}
            flexDirection={'row'}
            // justifyContent={'space-between'}
          >
            <Stack
              sx={{
                width: '25%',
                marginRight: 1,
                display: 'flex',
                minWidth: '64px',
                textAlign: 'left',
                borderRadius: '8px',
                flexDirection: 'row',
                padding: '16px 16px 10px 16px',
                justifyContent: 'space-between',
                color: caloTheme.palette.neutral900,
                border: '1px solid ' + caloTheme.palette.neutral100
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '19px',
                  textAlign: 'left',
                  marginRight: '4px',
                  lineHeight: '23px',
                  letterSpacing: '-0.02em',
                  fontFamily: caloTheme.typography.fontFamily
                }}
              >
                Actual Balance
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '23px',
                  lineHeight: '24px',
                  marginRight: '6px',
                  letterSpacing: '-0.02em',
                  fontFamily: caloTheme.typography.fontFamily
                }}
              >
                {`${actualBalance}`}
              </Typography>
            </Stack>
            <Button onClick={() => syncBalanceMutation(subscription.id)}>Sync</Button>
          </Box>
        )}
        <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
          {isTablet || isMobile ? (
            <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
              {isLoading ? (
                <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                  <CaloLoader />
                </Stack>
              ) : (
                <>
                  {list.map((row) => (
                    <PointsTransactionRowMobile key={row.id} row={row} />
                  ))}
                </>
              )}
            </Box>
          ) : (
            <>
              {isLoading ? (
                <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                  <CaloLoader />
                </Stack>
              ) : (
                <>
                  {list.length === 0 ? (
                    <Typography
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{
                        justifyContent: 'center',
                        color: caloTheme.palette.neutral400,
                        fontSize: '33px',
                        fontWeight: 400,
                        lineHeight: '24px'
                      }}
                    >
                      No Logs Data
                    </Typography>
                  ) : (
                    <Table
                      sx={{
                        marginY: '4px',
                        minHeight: '120px',
                        tableLayout: 'fixed',
                        overflow: 'auto',
                        width: '100%',
                        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                          flexDirection: 'column'
                        }
                      }}
                    >
                      <TableHead
                        sx={{
                          backgroundColor: caloTheme.palette.neutral50,
                          width: '100%',
                          borderRadius: '8px'
                        }}
                      >
                        <TableRow>
                          <StyledTableCell>Time</StyledTableCell>
                          <StyledTableCell>Source</StyledTableCell>
                          <StyledTableCell>Reason</StyledTableCell>
                          <StyledTableCell>Status</StyledTableCell>
                          <StyledTableCell>Amount</StyledTableCell>
                          <StyledTableCell>Points</StyledTableCell>
                          <StyledTableCell>Actor</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {list.map((row) => (
                          <>
                            <PointsTransactionRow row={row} key={row.id} currency={subscription.currency} />
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </>
              )}
            </>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              mb: 2,
              mt: '-4px',
              width: '100%',
              display: 'flex',
              justifyItems: 'center'
            }
          }}
        >
          {hasNextPage && (
            <Button
              variant="text"
              aria-label="Remove Suspension"
              sx={{
                display: 'flex',
                mb: 1,
                mx: 'auto',
                height: '45px',
                fontWeight: 600,
                lineHeight: '17px',
                fontSize: '14px',
                borderRadius: '8px',
                padding: '14px 20px 14px 20px',
                color: caloTheme.palette.primary500,
                '&:hover': {
                  color: caloTheme.palette.primary600
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  display: 'flex',
                  justifyItems: 'center',
                  m: 'auto',
                  mb: 2,
                  mt: '-4px',
                  width: 'auto'
                }
              }}
              onClick={() => fetchNextPage()}
            >
              Show More
            </Button>
          )}
        </Box>
      </Card>
    </>
  );
};

export default TransactionsLogs;
