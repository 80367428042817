import { TableBody } from '@mui/material';

import { IngredientComponentStats, IngredientStats } from 'lib/interfaces';
import ComponentRow from './ComponentRow';

interface ComponentsBodyProps {
  ingredient: IngredientStats;
  switchUnit: boolean;
}

export default function ComponentsBody({ ingredient, switchUnit }: ComponentsBodyProps) {
  const ingredientComponentStats: IngredientComponentStats[] = ingredient?.keyedComponents
    ? Object.values(ingredient?.keyedComponents)
    : [];

  return (
    <TableBody>
      {ingredientComponentStats.map((component) => (
        <>
          {component.neededIngredientFromParent > 0 && (
            <ComponentRow component={component} switchUnit={switchUnit} ingredient={ingredient} />
          )}
          {component.neededIngredientFromChild > 0 && (
            <ComponentRow
              component={component}
              switchUnit={switchUnit}
              ingredient={ingredient}
              isChild={true}
              onlyChild={component.neededIngredientFromParent === 0}
            />
          )}
        </>
      ))}
    </TableBody>
  );
}
