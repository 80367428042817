import { CreateComplaintReq, ListComplaintsFilters, UpdateComplaintReq } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';

import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';

export const createComplaints = async (values: CreateComplaintReq) => {
  const { data, status } = await client.post('/complaints', values);
  ToastSuccessMsgs({ status, action: 'Created successfully' });
  return data;
};

export const updateComplaints = async (payload: UpdateComplaintReq & { id: string; kitchen: Kitchen }) => {
  const { id, kitchen, ...rest } = payload;
  const { data, status } = await client.put(`/complaints/${kitchen}/id/${id}`, rest);
  ToastSuccessMsgs({ status, action: 'Updated successfully' });
  return data;
};

export const exportComplaints = async (filters: ListComplaintsFilters) => {
  const { data, status } = await client.get('complaints/export', {
    params: filters
  });
  ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
  return data;
};
