import { User } from '@calo/dashboard-types';
import { useFormik } from 'formik';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { omit } from 'lodash-es';

export default (user: User, onSubmit: (values: Omit<User, 'id'>) => Promise<void> | void) =>
  useFormik<Omit<User, 'id'>>({
    initialValues: {
      ...(omit(user, ['id']) as User)
    },
    validate: (values: Omit<User, 'id'>) => {
      const errors: Partial<Record<keyof Omit<User, 'id'>, boolean>> = {};

      if (!values.name) {
        errors.name = true;
      }

      const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);
      if (!phoneNumber || (phoneNumber && !phoneNumber.isValid())) {
        errors.phoneNumber = true;
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
