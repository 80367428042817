import { camelCase, startCase } from 'lodash-es';

import { MacrosPreferencePriority } from '@calo/dashboard-types';
import { MacrosPreset, MacrosType } from '@calo/types';
import { Box, Checkbox, MenuItem, Stack, TextField, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { FormData } from './useMacrosForm';

interface CustomMacrosFormProps {
  values: FormData;
  hasPermission: boolean;
  isEditing: boolean;
  handleBlur: (e: any) => void;
  handleCalChange: (cal: number) => void;
  handleMacroEdit: (macrosValue: number, type: string) => void;
  handleMacrosType: (macrosType: MacrosType) => void;
  handleMacrosPresetChange: (macrosPreset: MacrosPreset, cal: number) => void;
  handleMacrosPreferencePriorityChange: <K extends keyof MacrosPreferencePriority>(macro: K, value: boolean) => void;
}

const CustomMacrosForm = ({
  values,
  hasPermission,
  isEditing,
  handleBlur,
  handleCalChange,
  handleMacroEdit,
  handleMacrosType,
  handleMacrosPresetChange: _,
  handleMacrosPreferencePriorityChange
}: CustomMacrosFormProps) => {
  const handleCaloriesChange = (data) => {
    const newValue = Math.floor(+data.target.value);
    newValue >= 0 && handleCalChange(newValue);
  };
  const handleDataField = <K extends keyof MacrosPreferencePriority>({
    name,
    value,
    label,
    onChange
  }: {
    name: K;
    label?: string;
    value: number;
    onChange: any;
  }) => {
    return (
      <Stack display="flex" flexDirection={'column'}>
        <Typography
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
          id={`filled-${camelCase(name)}-macro-helper-text`}
        >
          {startCase(label ?? name)}
          <Checkbox
            onChange={(event) => handleMacrosPreferencePriorityChange(name, event.target.checked)}
            disabled={!hasPermission || !isEditing}
            checked={!!values.macrosPreferencePriority?.[name]}
            name={`check-${camelCase(name)}-preference-priority`}
          />
        </Typography>
        {values.macrosPreferencePriority?.[name] && (
          <TextField
            label="Amount"
            name={name}
            value={value}
            placeholder={`Enter ${name}`}
            id={`exact-subscription-macrosType-${camelCase(name)}`}
            onChange={onChange}
            disabled={!hasPermission || values.macrosType === MacrosType.recommended || !isEditing}
          />
        )}
      </Stack>
    );
  };

  const handleMacroDataField = <K extends keyof Omit<MacrosPreferencePriority, 'cal'>>({
    name,
    macrosType
  }: {
    name: K;
    macrosType: { min: number; max: number };
  }) => {
    const averageMacros = Math.round((macrosType.max + macrosType.min) / 2);
    const onChange = (data) => {
      const newValue = Math.floor(+data.target.value);
      if (isNaN(newValue) || newValue < 0) return;
      const min = Math.max(newValue - 10, 0);
      const max = 2 * newValue - min;
      handleMacroEdit(min, `macros.${name}.min`);
      handleMacroEdit(max, `macros.${name}.max`);
    };
    return handleDataField({ name, value: averageMacros, onChange });
  };

  const handleSelectDataField = ({
    name,
    list,
    onChange,
    value
  }: {
    name: string;
    list: { value: string; label: string }[];
    onChange: (data: any) => void;
    value: any;
  }) => {
    return (
      <Stack display="flex" flexDirection={'column'}>
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '12px',
            lineHeight: '14px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          {name}
        </Typography>
        <TextField
          select
          type="text"
          onBlur={handleBlur}
          name={camelCase(name)}
          size="small"
          value={value}
          placeholder={`Select ${name}`}
          id={`exact-subscription-${camelCase(name)}`}
          margin="none"
          disabled={!hasPermission || !isEditing}
          onChange={onChange}
          InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
        >
          {list.map((macrosType: { value: string; label: string }) => (
            <MenuItem key={macrosType.value} value={macrosType.value}>
              {startCase(macrosType.label)}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    );
  };

  return (
    <Box
      component="form"
      autoComplete="off"
      sx={{
        width: 'full',
        mx: 2,
        '& .MuiTextField-root': { my: 1.5 },
        '& .MuiStack-root': { mt: 1 }
      }}
    >
      {handleSelectDataField({
        name: 'Macros Source',
        value: values.macrosType,
        list: [
          { value: MacrosType.recommended, label: 'Recommended' },
          { value: MacrosType.custom, label: 'Custom' },
          { value: MacrosType.athlete, label: 'Athlete' }
        ],
        onChange: (data: any) => handleMacrosType(data.target.value)
      })}

      {handleDataField({
        name: 'cal',
        label: 'Calories',
        value: values.macros!.cal,
        onChange: handleCaloriesChange
      })}

      {handleMacroDataField({ name: 'protein', macrosType: values.macros!.protein })}
      {handleMacroDataField({ name: 'carbs', macrosType: values.macros!.carbs })}
      {handleMacroDataField({ name: 'fat', macrosType: values.macros!.fat })}
    </Box>
  );
};
export default CustomMacrosForm;
