import { Permission } from '@calo/dashboard-types';
import { Kitchen, Subscription } from '@calo/types';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Card,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses
} from '@mui/material';
import { Stack, styled } from '@mui/system';
import { getListWithParams } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { useDocumentMedia } from 'hooks';
import client from 'lib/client';
import { useUserRoles } from 'lib/hooks';
import { useMemo, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import ComplaintUserCardRow from './ComplaintUserCardRow';
import FeedbackCardRow from './FeedbackCardRow';
import FeedbackCardRowMobile from './FeedbackCardRowMobile';

interface PaginatedFeedback {
  data: any[];
  meta: {
    limit: number;
    total: number;
  };
}

interface FeedbackCardProps {
  subscription: Subscription & { remainingDays: number; expectedLastDeliveryDay: string; ratings: any[] };
}

const FeedbackCard = ({ subscription }: FeedbackCardProps) => {
  const roles = useUserRoles();
  const [limit, setLimit] = useState(10);
  const [selectedFilter, setSelectedFilter] = useState<string>('Meal Feedback');
  const [typeFilterAnchorEl, setTypeFilterAnchorEl] = useState<null | HTMLElement>(null);
  const isTypeFilterMenuOpened = Boolean(typeFilterAnchorEl);

  const { isMobile, isTablet } = useDocumentMedia();

  const { data: feedback, isLoading } = useQuery<any, Error, PaginatedFeedback>(
    ['ratings', { page: 0, limit: limit, filters: { userId: subscription.id } }],
    getListWithParams,
    {
      suspense: false,
      enabled: !!roles.includes(Permission.VIEW_RATING_LIST)
    }
  );

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      flexShrink: 0,
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px',
      variant: 'caption',
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    },
    [`&.${tableCellClasses.body}`]: {
      flexShrink: 0,
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      variant: 'caption',
      lineHeight: '14px',
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    }
  }));
  const { data, isLoading: complaintLoading } = useInfiniteQuery<{ data: any[]; meta: { total: number } }>(
    ['/complaints'],
    async ({ pageParam, queryKey }) => {
      const { data } = await client.get(queryKey[0] as string, {
        params: {
          page: pageParam || 0,
          limit: 10,
          filters: {
            kitchen: subscription.kitchen || Kitchen.BH1,
            userId: subscription.id
          }
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => {
        const complaintsLength = data?.data?.length || 0;
        return data.meta?.total && complaintsLength < data.meta?.total ? Math.floor(complaintsLength / 10) : undefined;
      }
    }
  );

  const complaints: any[] = useMemo(
    () =>
      (data?.pages || []).reduce<any[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [data]
  );

  return (
    <>
      <Card variant="outlined" sx={{ width: '100%', border: 'none', borderRadius: '8px' }}>
        <Box
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column'
            }
          }}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Typography
            sx={{
              textAlign: 'left',
              mt: 2,
              fontSize: '19px',
              lineHeight: '23px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600
            }}
          >
            Feedback
          </Typography>
          <LoadingButton
            loading={false}
            id="delivery-action-button"
            aria-haspopup="true"
            disableElevation
            onClick={(event) => {
              !typeFilterAnchorEl && setTypeFilterAnchorEl(event.currentTarget);
            }}
            variant="outlined"
            aria-label="meal-actions"
            sx={{
              height: '45px',
              mb: '-16px',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '17px',
              borderRadius: '8px',
              textTransform: 'none',
              color: caloTheme.palette.black,
              borderColor: caloTheme.palette.neutral900,
              '&:hover': {
                borderColor: caloTheme.palette.neutral900,
                backgroundColor: caloTheme.palette.neutral100,
                color: caloTheme.palette.black
              },
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                justifyItems: 'center',
                margin: 'auto',
                marginTop: 1,
                width: 'auto'
              }
            }}
            endIcon={<ArrowDownIcon />}
          >
            {selectedFilter}
          </LoadingButton>
          <Menu
            sx={{ width: '100%' }}
            anchorEl={typeFilterAnchorEl}
            open={isTypeFilterMenuOpened}
            onClose={() => setTypeFilterAnchorEl(null)}
          >
            <MenuItem
              sx={{ fontWeight: 600, py: 1, mr: 2, width: '100%' }}
              onClick={() => {
                setSelectedFilter('Meal Feedback');
                setTypeFilterAnchorEl(null);
              }}
            >
              <Box>Meal Feedback</Box>
            </MenuItem>
            <MenuItem
              sx={{ fontWeight: 600, py: 1, mr: 2, width: '100%' }}
              onClick={() => {
                setSelectedFilter('Complaints');
                setTypeFilterAnchorEl(null);
              }}
            >
              <Box>Complaints</Box>
            </MenuItem>
          </Menu>
        </Box>
        <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
          {isTablet || isMobile ? (
            <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
              {isLoading ? (
                <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                  <CaloLoader />
                </Stack>
              ) : (
                <>
                  {feedback?.data.length === 0 || !feedback ? (
                    <Typography
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{
                        justifyContent: 'center',
                        color: caloTheme.palette.neutral400,
                        fontSize: '33px',
                        fontWeight: 400,
                        lineHeight: '24px'
                      }}
                    >
                      No Feedback
                    </Typography>
                  ) : (
                    <Table
                      sx={{
                        marginY: '4px',
                        minHeight: '120px',
                        tableLayout: 'fixed',
                        overflow: 'auto',
                        width: '100%'
                      }}
                    >
                      <TableBody></TableBody>
                      {feedback.data.map((row) => (
                        <FeedbackCardRowMobile key={row.id} row={row} />
                      ))}
                    </Table>
                  )}
                </>
              )}
            </Box>
          ) : (
            <>
              {isLoading || complaintLoading ? (
                <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                  <CaloLoader />
                </Stack>
              ) : (
                <>
                  {(selectedFilter === 'Meal Feedback' && feedback?.data.length === 0) || !feedback ? (
                    <Typography
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{
                        justifyContent: 'center',
                        color: caloTheme.palette.neutral400,
                        fontSize: '33px',
                        fontWeight: 400,
                        lineHeight: '24px'
                      }}
                    >
                      No Feedback
                    </Typography>
                  ) : selectedFilter === 'Complaints' && complaints && complaints?.length === 0 ? (
                    <Typography
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{
                        justifyContent: 'center',
                        color: caloTheme.palette.neutral400,
                        fontSize: '33px',
                        fontWeight: 400,
                        lineHeight: '24px'
                      }}
                    >
                      No Complaints
                    </Typography>
                  ) : (
                    <Table
                      sx={{
                        marginY: '4px',
                        minHeight: '120px',
                        tableLayout: 'fixed',
                        overflow: 'auto',
                        width: '100%'
                      }}
                    >
                      <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, width: '100%', borderRadius: '8px' }}>
                        {selectedFilter === 'Meal Feedback' ? (
                          <TableRow>
                            <StyledTableCell sx={{ width: '70%' }}>Meal Name</StyledTableCell>
                            <StyledTableCell sx={{ width: '15%' }}>Rating</StyledTableCell>
                            <StyledTableCell sx={{ width: '15%' }}>Review</StyledTableCell>
                            <StyledTableCell sx={{ width: '15%' }}>Date</StyledTableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <StyledTableCell style={{ width: '17%' }}>Meal Name</StyledTableCell>
                            <StyledTableCell style={{ width: '17%' }}>Complaint Type</StyledTableCell>
                            <StyledTableCell style={{ width: '17%' }}>Complaint</StyledTableCell>
                            <StyledTableCell style={{ width: '15%', textAlign: 'center' }}>Comment</StyledTableCell>
                            <StyledTableCell style={{ width: '15%', textAlign: 'center' }}>Attachments</StyledTableCell>
                            <StyledTableCell style={{ width: '10%', textAlign: 'center' }}>Date</StyledTableCell>
                          </TableRow>
                        )}
                      </TableHead>
                      <TableBody>
                        {selectedFilter === 'Meal Feedback'
                          ? feedback?.data.map((row) => <FeedbackCardRow key={row.id} row={row} />)
                          : complaints?.map((complaints: any) => <ComplaintUserCardRow key={complaints.id} row={complaints} />)}
                      </TableBody>
                    </Table>
                  )}
                </>
              )}
            </>
          )}
        </Box>

        <Box
          sx={{
            width: 'full',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              display: 'flex',
              justifyItems: 'center',
              m: 'auto',
              mb: 2,
              mt: '-4px',
              width: 'auto'
            }
          }}
        >
          {(selectedFilter === 'Meal Feedback'
            ? feedback && feedback.meta.total > limit
            : complaints && complaints?.length > limit) && (
            <Button
              variant="text"
              sx={{
                display: 'flex',
                mx: 'auto',
                height: '45px',
                fontWeight: 600,
                lineHeight: '17px',
                fontSize: '14px',
                borderRadius: '8px',
                padding: '14px 20px 14px 20px',
                color: caloTheme.palette.primary500,
                '&:hover': {
                  color: caloTheme.palette.primary600
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  display: 'flex',
                  justifyItems: 'center',
                  m: 'auto',
                  mb: 2,
                  mt: '-4px',
                  width: 'auto'
                }
              }}
              onClick={() => setLimit(limit + 10)}
            >
              Show More
            </Button>
          )}
        </Box>
      </Card>
    </>
  );
};
export default FeedbackCard;
