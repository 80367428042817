import { Route, Switch } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';

import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';

import AffiliateTransactionsList from './AffilateTransactionsPage/AffiliateTransactionsList';
import AffiliateProgramHomePage from './AffiliateProgramHomePage/AffiliateProgramHomePage';
import useAffiliates from './AffiliateProgramHomePage/AffiliatesList/useAffiliates';

const AffiliateProgram = () => {
  const roles = useUserRoles();
  const { affiliatesLoading, affiliates, refetchAffiliates } = useAffiliates();

  return (
    <Switch>
      {roles.includes(Permission.VIEW_AFFILIATE_PROFILE) && (
        <Route key={Routes.affiliateProfilesList} exact path={Routes.affiliateProfilesList}>
          <AffiliateProgramHomePage
            affiliates={affiliates}
            affiliatesLoading={affiliatesLoading}
            refetchAffiliates={refetchAffiliates}
          />
        </Route>
      )}
      ,
      {roles.includes(Permission.VIEW_AFFILIATE_PROFILE || Permission.PAY_AFFILIATE_COMMISSION) && affiliates.length > 0 && (
        <Route key={Routes.affiliateTransactionsList} exact path={Routes.affiliateTransactionsList}>
          <AffiliateTransactionsList affiliates={affiliates} />
        </Route>
      )}
    </Switch>
  );
};

export default AffiliateProgram;
