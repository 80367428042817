import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import {
  CreateFoodPackageElementReq,
  CreateFoodPackageReq,
  UpdateFoodPackageElementReq,
  UpdateFoodPackageReq
} from 'lib/interfaces';

import { FoodPackageElementFilters, FoodPackageFilters } from '@calo/dashboard-types';

import mutation from './mutation';

export const createFoodPackage = async (attr: CreateFoodPackageReq) => {
  const { data, status } = await client.post('food-packages', attr);
  ToastSuccessMsgs({ status, action: 'New Food package created' });
  mutation(['food-packages', data.id], data);
  return data;
};

export const updateFoodPackage = async (payload: UpdateFoodPackageReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`food-packages/${id}`, rest);
  ToastSuccessMsgs({ status, action: 'Food package updated successfully' });
  mutation(['food-packages', id], data);
};

export const deleteFoodPackage = async (id: string) => {
  await client.delete(`food-packages/${id}`);
};

export const createFoodPackageElement = async (attr: CreateFoodPackageElementReq) => {
  const { data, status } = await client.post('food-package-elements', attr);
  ToastSuccessMsgs({ status, action: 'New package element created successfully' });
  mutation(['food-package-elements', data.id], data);
  return data;
};

export const updateFoodPackageElement = async (payload: UpdateFoodPackageElementReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`food-package-elements/${id}`, rest);
  ToastSuccessMsgs({ status, action: 'Package element updated successfully' });
  mutation(['food-package-elements', id], data);
  return data;
};

export const deleteFoodPackageElment = async (id: string) => {
  await client.delete(`food-package-elements/${id}`);
};

export const exportFoodPackageElement = async (filters: FoodPackageElementFilters) => {
  const { status } = await client.get('/food-package-elements/export', { params: { filters } });
  ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
};

export const exportFoodPackage = async (filters: FoodPackageFilters) => {
  const { status } = await client.get('/food-packages/export', { params: { filters } });
  ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
};
