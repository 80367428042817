import { SyncFoodComponentReq } from '@calo/dashboard-types';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { SyncFoodComponentMPErrors } from 'lib/interfaces';
import KitchenValidation from './KitchenValidation';

interface ValidationResultProps {
  validationData: SyncFoodComponentMPErrors[];
  formValues: SyncFoodComponentReq;
  isLoading: boolean;
  handleClosePopup: () => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

const ValidationResult = ({ handleClosePopup, handleSubmit, validationData, formValues, isLoading }: ValidationResultProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', py: '14px', height: '100%' }}>
      <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: '10px' }}>
        <Typography variant="h5" sx={{ fontSize: '28px', fontWeight: 600 }}>
          Component Status
        </Typography>
        <IconButton onClick={handleClosePopup}>
          <CloseIcon />
        </IconButton>
      </Stack>
      {validationData &&
        formValues.kitchens.map((kitchen) => (
          <KitchenValidation
            key={kitchen}
            kitchen={kitchen}
            validationData={validationData.find((res) => res.kitchen === kitchen)?.errors}
          />
        ))}
      <Stack
        sx={{
          width: '60%',
          display: 'flex',
          marginTop: 'auto',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: validationData.length === 0 ? 'space-between' : 'center'
        }}
      >
        <Button
          variant="outlined"
          onClick={handleClosePopup}
          disabled={isLoading}
          sx={{
            textTransform: 'none',
            color: 'white',
            fontWeight: 600,
            borderRadius: '8px',
            width: '30%',
            fontSize: '20px',
            backgroundColor: caloTheme.palette.primary500,
            borderColor: caloTheme.palette.primary500,
            '&:hover': {
              color: 'white',
              backgroundColor: caloTheme.palette.primary500,
              borderColor: caloTheme.palette.primary500
            },
            '&:disabled': {
              color: 'white',
              backgroundColor: caloTheme.palette.primary300,
              borderColor: caloTheme.palette.primary300
            }
          }}
        >
          Cancel
        </Button>
        {validationData.length === 0 && (
          <Button
            variant="outlined"
            onClick={() => handleSubmit()}
            disabled={isLoading}
            sx={{
              textTransform: 'none',
              color: 'white',
              fontWeight: 600,
              borderRadius: '8px',
              width: '30%',
              fontSize: '20px',
              backgroundColor: caloTheme.palette.primary500,
              borderColor: caloTheme.palette.primary500,
              '&:hover': {
                color: 'white',
                backgroundColor: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary500
              },
              '&:disabled': {
                color: 'white',
                backgroundColor: caloTheme.palette.primary300,
                borderColor: caloTheme.palette.primary300
              }
            }}
          >
            Proceed
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default ValidationResult;
