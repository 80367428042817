import { SyncFoodComponentReq } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import { Button, Stack } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { getKitchenShortCut } from 'lib/helpers';

interface SelectedKitchensListProps {
  values: SyncFoodComponentReq;
  removeKitchen: (kitchen: Kitchen) => void;
}

const SelectedKitchensList = ({ values, removeKitchen }: SelectedKitchensListProps) => {
  return (
    <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '8px', flexWrap: 'wrap', mt: '18px', mx: '8px' }}>
      {values.kitchens.map((kitchen) => (
        <Button
          key={kitchen}
          sx={{
            borderRadius: '16px',
            px: '16px',
            fontWeight: 600,
            color: caloTheme.palette.primary700,
            backgroundColor: caloTheme.palette.primary100
          }}
          endIcon={<HighlightOffSharpIcon />}
          onClick={() => removeKitchen(kitchen)}
        >
          {getKitchenShortCut(kitchen)}
        </Button>
      ))}
    </Stack>
  );
};

export default SelectedKitchensList;
