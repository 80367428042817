import { DeliveryAddress } from '@calo/types';

import { updateDelivery } from 'actions';
import { Button } from 'components';
import { Delivery } from 'lib/interfaces';
import { useMutation } from 'react-query';
import { AddressService } from 'services';

interface AddressTabProps {
  delivery: Delivery & { deliveryAddresses: DeliveryAddress[] };
}

const AddressTab = ({ delivery }: AddressTabProps) => {
  const { mutateAsync: updateMutation } = useMutation(updateDelivery);

  const handleChangeAddress = async (deliveryAddress: DeliveryAddress) =>
    updateMutation({
      id: delivery.id,
      deliveryAddressId: deliveryAddress.id
    });

  return (
    <div className="card has-table has-table-container-upper-radius ml-4 flex-1 m-2">
      <div className="card-content">
        <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
          <thead>
            <tr>
              <th>Address</th>
              <th>Notes</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {delivery.deliveryAddresses &&
              delivery.deliveryAddresses.map((deliveryAddress) => (
                <tr key={deliveryAddress.id}>
                  <td>{AddressService.display(deliveryAddress)}</td>
                  <td>{deliveryAddress.notes}</td>
                  <td>
                    <Button
                      onClick={() => handleChangeAddress(deliveryAddress)}
                      icon="fas fa-check "
                      disabled={deliveryAddress.id === delivery.deliveryAddress.id ? true : false}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddressTab;
