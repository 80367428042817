import { RefundFilters } from '@calo/dashboard-types';
import { Country } from '@calo/types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { toggleUISettings } from 'actions';
import { Select2, SideDrawer } from 'components';
import { getAccessibleCountries } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';
interface SettingsProps {
  onFilter: (values: any) => void;
  filters: RefundFilters;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <div className="field">
          <Select2
            label="Country"
            value={filters.country}
            onChange={(e: any) => onFilter({ ...filters, country: e.target.value as Country })}
            options={getAccessibleCountries(userKitchens).map((c) => ({
              value: c,
              label: Country[c]
            }))}
          />
          <label className="label">Day</label>
          <div className="control is-clearfix">
            <DateRangePicker
              onChange={(dates: any) => {
                onFilter({
                  ...filters,
                  day: {
                    gte: Date.parse(dates ? dates[0] : undefined),
                    lte: Date.parse(dates ? dates[1] : undefined)
                  }
                });
              }}
              value={[filters!.day?.gte, filters!.day?.lte]}
            />
          </div>
        </div>
      </section>
    </SideDrawer>
  );
};

export default Settings;
