import { Link } from 'react-router-dom';

import { TableCell, TableRow, Typography } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import { Affiliate, Permission } from '@calo/dashboard-types';

import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';

interface AffiliateProfileRowProps {
  affiliateProfile: Affiliate;
}

const AffiliateRow = ({ affiliateProfile }: AffiliateProfileRowProps) => {
  const roles = useUserRoles();

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      color: caloTheme.palette.neutral900,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none'
    }
  }));

  const formattedDate = format('yyyy-MM-dd')(new Date(affiliateProfile.dateJoined));

  return (
    <TableRow key={affiliateProfile.id} hover>
      <StyledTableCell component="th" scope="row">
        <Typography
          component="span"
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'inline-block',
            fontFamily: caloTheme.typography.fontFamily
          }}
        >
          {roles.includes(Permission.VIEW_AFFILIATE_PROFILE) || roles.includes(Permission.PAY_AFFILIATE_COMMISSION) ? (
            <Link to={Routes.affiliateTransactionsList.replace(':id', affiliateProfile.id)}>{affiliateProfile.name}</Link>
          ) : (
            affiliateProfile.name
          )}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>{formattedDate}</StyledTableCell>
      <StyledTableCell>{affiliateProfile.codeName}</StyledTableCell>
      <StyledTableCell> {affiliateProfile.createdBy ? affiliateProfile.createdBy : ''} </StyledTableCell>
      <StyledTableCell>{affiliateProfile.country}</StyledTableCell>
    </TableRow>
  );
};
export default AffiliateRow;
