import { ShiftActionType } from '@calo/dashboard-types';
import { DriverMetrics, RouteItemActionType } from '@calo/driver-types';
import { getList } from 'actions';
import { Button, CaloLoader } from 'components';
import { addHours, format } from 'date-fns/fp';
import ExcelJS, { Style } from 'exceljs';
import { saveAs } from 'file-saver';
import { Routes } from 'lib/enums';
import { DriverMetricDelivery } from 'lib/interfaces';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useParams } from 'react-router-dom';
import { AddressService } from 'services';
import { differentInMinutesForDates, getAction, getAverageDeliveryTime, getFormatedTimeInMinutes } from '../utils';
import DeliveriesCard from './DeliveriesCard';

const ExactDriverMetric = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation() as any;

  let driverMetric: DriverMetrics = location.state?.driverMetric;

  const { data: driverMetricData } = useQuery<any, Error, DriverMetrics>([`/route-plans/${id}`], getList, {
    suspense: true
  });

  driverMetric = driverMetricData!;

  const { data, isLoading } = useQuery<any, Error, { data: DriverMetricDelivery[] }>(
    [`/route-plans/${driverMetric.id}/deliveries`],
    getList,
    {
      suspense: true
    }
  );
  const onExport = useCallback(async () => {
    if (!data) {
      return;
    }
    const border: Partial<Style> = {
      border: {
        top: { style: 'medium', color: { argb: 'D3D3D3' } },
        left: { style: 'medium', color: { argb: 'D3D3D3' } },
        bottom: { style: 'medium', color: { argb: 'D3D3D3' } },
        right: { style: 'medium', color: { argb: 'D3D3D3' } }
      }
    };

    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('driver-metrics', {
      pageSetup: { fitToPage: true, orientation: 'portrait' }
    });

    worksheet.columns = [
      { header: 'Name', width: 24, key: 'name', style: border },
      { header: 'Address', width: 60, key: 'address', style: border },
      { header: 'Notes', width: 60, key: 'notes', style: border },
      { header: 'Driver Note', width: 60, key: 'driverNote', style: border },
      { header: 'Priority', width: 24, key: 'priority', style: border },
      { header: 'ETA', width: 15, key: 'eta', style: border },
      { header: 'DeliveredAt', width: 15, key: 'deliveredAt', style: border },
      { header: 'Custom Time', width: 15, key: 'customDeliveryTime', style: border },
      { header: 'Did not pick up time', width: 15, key: 'noCustomerOnSpotTime', style: border },
      { header: 'Diff (min)', width: 12, key: 'diff', style: border },
      { header: 'deliveryAction', width: 15, key: 'DeliveryAction', style: border },
      { header: 'deliveryActionTime', width: 15, key: 'DeliveryActionTime', style: border },
      { header: 'deliveryInstructions', width: 60, key: 'deliveryInstructions', style: border }
    ];

    for (const delivery of data.data || []) {
      const filteredActions =
        delivery.actions && delivery.actions.length > 0
          ? delivery.actions?.filter((a) => a.type !== RouteItemActionType.DRIVERS_REQUESTING_DELIVERY_LOCATION_UPDATES)
          : [];

      worksheet.addRow(
        {
          name: delivery.name || '',
          address: AddressService.display(delivery.deliveryAddress).replaceAll(',', ''),
          notes: delivery.deliveryAddress.notes || '',
          driverNote: delivery.deliveryAddress.driverNote || '',
          priority: delivery.priority || '',
          eta: delivery.toBeDeliveredAt ? format('hh:mm a')(new Date(delivery.toBeDeliveredAt)) : '--:--',
          groupBufferTime: delivery.groupBufferTime ? delivery.groupBufferTime : 'N/A',
          deliveredAt: delivery.deliveredAt ? format('hh:mm a')(new Date(delivery.deliveredAt)) : '--:--',
          customDeliveryTime: delivery.customDeliveryTime
            ? `${format('hh:mm a')(new Date(delivery.customDeliveryTime))}-${format('hh:mm a')(addHours(1)(new Date(delivery.customDeliveryTime)))}`
            : '',
          noCustomerOnSpotTime: delivery.noCustomerOnSpotTime
            ? format('hh:mm a')(new Date(delivery.noCustomerOnSpotTime))
            : '--:--',
          DeliveryAction: filteredActions && filteredActions.length > 0 ? filteredActions[0].type : '',
          DeliveryActionTime:
            filteredActions && filteredActions.length > 0 ? format('hh:mm a')(new Date(filteredActions[0].createdAt)) : '--:--',
          diff:
            differentInMinutesForDates(delivery.toBeDeliveredAt, delivery.noCustomerOnSpotTime || delivery.deliveredAt) || '--',
          deliveryInstructions:
            delivery.deliveryAddress.deliveryInstructions && delivery.deliveryAddress.deliveryInstructions.length > 0
              ? delivery.deliveryAddress.deliveryInstructions.join(',')
              : '-'
        },
        ''
      );
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, `driver-metrics-${driverMetric.driver.driverName}-${driverMetric.day}` + fileExtension);
  }, [data]);

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  <Link to={Routes.driversMetric}>Drivers Metrics</Link>
                </li>
                <li>
                  {driverMetric.day} - {driverMetric.driver.driverName} - {driverMetric.time}
                </li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <Button onClick={onExport} icon="fas fa-file-export" />
            </div>
          </div>
        </div>
      </section>
      <div>
        {isLoading ? (
          <CaloLoader />
        ) : (
          <>
            <div className="flex-row m-3">
              <div className="w-60 text-white p-3 rounded shadow relative mr-10" style={{ backgroundColor: '#28B17B' }}>
                <p className="text-xl mb-2">Started shift:</p>
                <p className="text-2xl">{getAction(driverMetric, 'STARTED_SHIFT' as ShiftActionType)}</p>
              </div>
              <div className="w-60 text-white p-3 rounded shadow relative mr-10" style={{ backgroundColor: '#28B17B' }}>
                <p className="text-xl mb-2">Started delivering:</p>
                <p className="text-2xl">{getAction(driverMetric, 'STARTED_DELIVERING' as ShiftActionType)}</p>
              </div>
              <div className="w-60 text-white p-3 rounded shadow relative mr-10" style={{ backgroundColor: '#28B17B' }}>
                <p className="text-xl mb-2">Finished shift:</p>
                <p className="text-2xl">{getAction(driverMetric, 'FINISHED_SHIFT' as ShiftActionType)}</p>
              </div>
              <div className="w-60 text-white p-3 rounded shadow relative mr-10" style={{ backgroundColor: '#28B17B' }}>
                <p className="text-xl mb-2">Total deliveries:</p>
                <p className="text-2xl">{driverMetric.totalDeliveries}</p>
              </div>
              <div className="w-60 text-white p-3 rounded shadow relative mr-10" style={{ backgroundColor: '#28B17B' }}>
                <p className="text-xl mb-2">Delivered deliveries:</p>
                <p className="text-2xl">{driverMetric.deliveredDeliveries}</p>
              </div>
              <div className="w-60 text-white p-3 rounded shadow relative mr-10" style={{ backgroundColor: '#28B17B' }}>
                <p className="text-xl mb-2">Average delivery time:</p>
                <p className="text-2xl">{getFormatedTimeInMinutes(getAverageDeliveryTime(driverMetric))}</p>
              </div>
            </div>
            <DeliveriesCard deliveries={data?.data || []} driverMetric={driverMetric} />
          </>
        )}
      </div>
    </>
  );
};

export default ExactDriverMetric;
