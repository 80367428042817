import { Button, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

const RemoveButton = ({ handleClick }: { handleClick: () => void }) => {
  return (
    <Button
      variant="text"
      onClick={handleClick}
      sx={{
        position: 'absolute',
        backgroundColor: caloTheme.palette.alert50,
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        minWidth: '24px',
        borderRadius: '12px',
        right: '1px',
        top: '1px',
        ':hover': {
          backgroundColor: caloTheme.palette.alert50
        }
      }}
    >
      <Typography
        sx={{
          minWidth: '14px',
          right: '1px',
          top: '1px',
          borderRadius: '3px',
          width: '22px',
          height: '1px',
          backgroundColor: caloTheme.palette.red500
        }}
      ></Typography>
    </Button>
  );
};

export default RemoveButton;
