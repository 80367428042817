import { useFormik } from 'formik';

import { UpdateSubscriptionReq } from '@calo/dashboard-types';

export default (subscription: UpdateSubscriptionReq, onSubmit: (values: UpdateSubscriptionReq) => any) =>
  useFormik<UpdateSubscriptionReq>({
    enableReinitialize: true,
    initialValues: {
      name: subscription.name,
      email: subscription.email,
      paymentMethod: subscription.paymentMethod,
      macrosData: subscription.macrosData
    },
    validate: (values: UpdateSubscriptionReq) => {
      const errors: any = {};

      if (!values.name) {
        errors.name = true;
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
