import { createFoodPackageElement } from 'actions';
import { Routes } from 'lib/enums';
import { CreateFoodPackageElementReq } from 'lib/interfaces';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import PackageElementHeaderCard from './PackageElementHeaderCard';
import PackageElementInformation from './PackageElementInformationCard';
import useNewPackageElementForm from './useNewPackageElementForm';

const NewPackageElement = () => {
  const history = useHistory();
  const { mutateAsync: createMutation } = useMutation(createFoodPackageElement);

  const handleNewPackageElement = async (values: CreateFoodPackageElementReq) => {
    const foodPackageElement = await createMutation(values);
    history.replace(Routes.packageElement.replace(':id', foodPackageElement.id));
  };

  const { handleSubmit, values, handleBlur, isSubmitting, isValid, dirty, setFieldValue, errors, touched, handleChange } =
    useNewPackageElementForm(handleNewPackageElement);

  return (
    <>
      <PackageElementHeaderCard dirty={dirty} isValid={isValid} isSubmitting={isSubmitting} handleSubmit={handleSubmit} />
      <PackageElementInformation
        errors={errors}
        values={values}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />
    </>
  );
};

export default NewPackageElement;
