import { Link } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';

import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { KDSUser } from 'lib/interfaces';

interface KDSUserRowProps {
  user: KDSUser;
}

const KDSUserRow = ({ user }: KDSUserRowProps) => {
  const roles = useUserRoles();
  return (
    <tr>
      <td>
        {roles.includes(Permission.VIEW_KDS_USER) ? (
          <Link to={Routes.kdsUser.replace(':id', user.id)}>{user.name}</Link>
        ) : (
          `${user.name}`
        )}
      </td>
      <td>
        <p>{user.phoneNumber}</p>
      </td>
      <td>
        <p>{user.email}</p>
      </td>
      <td>
        <p>{user.country}</p>
      </td>
      <td>
        <p>{user.kitchen.join(';')}</p>
      </td>
    </tr>
  );
};

export default KDSUserRow;
