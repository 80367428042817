import { useEffect, useRef, useState } from 'react';

import { debounce } from 'lodash-es';
import { ActionMeta, InputActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';

import Select from '../Select';

interface AutocompleteProps {
  onSearch: (text: string) => Promise<any[]>;
  onPick: (values: OnChangeValue<any, false>) => void;
  transformer: (data: any[]) => OnChangeValue<any, false>[];
  values?: any;
  label?: string;
  types?: string[];
  customStyles?: any;
  placeHolder: boolean;
  placeHolderValue?: string;
  showSelectedVal?: boolean;
  menuPortalTarget?: HTMLElement;
}

const Autocomplete = ({
  showSelectedVal = false,
  placeHolderValue,
  customStyles,
  placeHolder,
  onPick,
  label,
  values = [],
  onSearch,
  transformer,
  menuPortalTarget
}: AutocompleteProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [val, setVal] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState<any>('Search');
  const [selectedValue, setSelectedValue] = useState<any>(values);

  const handleSelect = async (data: OnChangeValue<any, false>, { action }: ActionMeta<any>) => {
    if (action === 'select-option' || action === 'remove-value') {
      placeHolder && setValue(data.label);
      setSelectedValue(data);
      onPick(data || {});
      setVal('');
      setOptions([]);
    }
  };

  const handleSearch = useRef(
    debounce((text: string, { action }: InputActionMeta) => {
      if (text.length === 0) {
        setValue('Search');
        placeHolder && onPick(undefined);
        setOptions([]);
      } else if (action === 'input-change') {
        setIsLoading(true);
        onSearch(text)
          .then((data) => setOptions(data))
          .finally(() => setIsLoading(false));
      }
    }, 600)
  );

  const onSearchText = (text: string, { action }: InputActionMeta) => {
    if ((!selectedValue && text.length > 0) || action === 'input-change') {
      setVal(text);
      handleSearch.current(text, { action, prevInputValue: '' });
    }
  };

  useEffect(() => {
    if (values.length > 0) {
      setSelectedValue(values);
    }
  }, [values]);

  return (
    <Select
      label={label}
      value={selectedValue}
      isLoading={isLoading}
      onChange={handleSelect}
      customStyles={customStyles}
      onInputChange={onSearchText}
      options={transformer(options)}
      placeholder={placeHolder ? placeHolderValue : 'Search'}
      inputValue={showSelectedVal ? (selectedValue ? selectedValue.label : val) : val}
      menuPortalTarget={menuPortalTarget}
    />
  );
};

export default Autocomplete;
