import { Permission, SupplyChainUserCreateReq } from '@calo/dashboard-types';
import { createSupplyChainUser } from 'actions/supplyChainUsers';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import UserForm from './UserForm';

const NewSupplyChainUser = () => {
  const { mutateAsync: createMutation } = useMutation(createSupplyChainUser);
  const roles = useUserRoles();
  const history = useHistory();

  const handleNewSupplyChainUser = async (values: Omit<SupplyChainUserCreateReq, 'id'>) => {
    const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);
    const supplyChainUser = await createMutation({ ...values, phoneNumber: phoneNumber?.number as string });
    roles.includes(Permission.VIEW_SUPPLY_CHAIN_USER) &&
      history.replace(Routes.supplyChainUser.replace(':id', supplyChainUser.id));
  };
  return <UserForm onSubmit={handleNewSupplyChainUser} />;
};
export default NewSupplyChainUser;
