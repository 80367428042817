import { Permission } from '@calo/dashboard-types';
import { Route, Switch } from 'react-router-dom';

import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import DeliveryList from './DeliveryList';
import ExactDelivery from './ExactDelivery';

const Deliveries = () => {
  const roles = useUserRoles();
  return (
    <Switch>
      {roles.includes(Permission.VIEW_DELIVERY_LIST) && <Route exact path={Routes.deliveries} component={DeliveryList} />}
      {roles.includes(Permission.VIEW_DELIVERY) && <Route exact path={Routes.delivery} component={ExactDelivery} />}
    </Switch>
  );
};

export default Deliveries;
