import React, { ReactNode } from 'react';
import cx from 'classnames';

interface CardProps {
  children: ReactNode;
  title: string;
  actions?: ReactNode[];
  className?: string;
}

const Card = ({ title, children, actions, className }: CardProps) => (
  <div className={cx('w-full p-4 bg-white shadow-md rounded-md', className)}>
    <div className="flex flex-row justify-between">
      <h1 className="text-lg font-semibold text-gray-800">{title}</h1>
      <div>{actions}</div>
    </div>

    <div>{children}</div>
  </div>
);

export default Card;
