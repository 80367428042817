import { Card, Stack, TextField, Typography } from '@mui/material';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { format, isValid, parseISO } from 'date-fns';
import { IngredientType } from 'lib/interfaces';

interface PrecoroInformationCardProps {
  handleChange: { (e: React.ChangeEvent<any>): void };
  ingredient: IngredientType;
}

const PrecoroInformationCard = ({ ingredient, handleChange }: PrecoroInformationCardProps) => {
  const parsedDate = parseISO(ingredient.order?.date || '');

  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: '16px',
        border: 'none',
        borderRadius: '16px',
        px: '12px',
        py: '20px'
      }}
    >
      <Typography variant="h5" sx={{ fontSize: '19px', fontWeight: 600 }}>
        Additional Information from Precoro
      </Typography>
      <InputThemeProvider>
        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginTop: '24px' }}>
          <TextField
            label="Last Purchase Date (Precoro)"
            value={ingredient.order && isValid(parsedDate) ? format(new Date(ingredient.order?.date), 'dd/MM/yyyy') : '--'}
            name="lastPurchaseDate"
            sx={{ width: '100%' }}
            onChange={handleChange}
            disabled
          />
          <TextField
            label="Quantity Purchased"
            value={ingredient.order?.quantity ?? '--'}
            name="quantityPurchased"
            sx={{ width: '100%' }}
            onChange={handleChange}
            type="number"
            disabled
          />
          <TextField
            label="Price for the Quantity Purchased"
            value={ingredient.order?.value ?? '--'}
            name="priceForQuantityPurchased"
            sx={{ width: '100%' }}
            onChange={handleChange}
            type="number"
            disabled
          />
          <TextField
            label="Unit of Purchase"
            value={ingredient.order?.unit ?? '--'}
            name="unit"
            sx={{ width: '100%' }}
            onChange={handleChange}
            disabled
          />
          <TextField
            label="Conversion Factor"
            value={ingredient.order?.conversionFactor ?? '--'}
            name="conversionFactor"
            sx={{ width: '100%' }}
            onChange={handleChange}
            disabled
          />
        </Stack>
      </InputThemeProvider>
    </Card>
  );
};

export default PrecoroInformationCard;
