import { Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import styles from './styles';

interface TrackedMacrosProps {
  macros: {
    cal: number;
    protein: number;
    carbs: number;
    fat: number;
  };
}

const TrackedMacros = ({ macros: { cal, carbs, protein, fat } }: TrackedMacrosProps) => {
  return (
    <>
      <Typography sx={{ textAlign: 'left', ...styles.trackedCaloriesText }}>Tracked Macros</Typography>
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ gap: '10px' }}>
        <Typography
          variant="h1"
          sx={{
            borderRadius: '8px',
            textAlign: 'center',
            px: '16px',
            py: '12px',
            width: '25%',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '17px',
            fontFamily: caloTheme.typography.fontFamily,
            backgroundColor: caloTheme.palette.primary100,
            color: caloTheme.palette.primary500
          }}
        >
          Calories: {cal}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            borderRadius: '8px',
            textAlign: 'center',
            px: '16px',
            py: '12px',
            fontWeight: 600,
            background: caloTheme.palette.secondaryBlue100,
            color: caloTheme.palette.secondaryBlue500,
            fontSize: '14px',
            lineHeight: '17px',
            fontFamily: caloTheme.typography.fontFamily,
            width: '25%'
          }}
        >
          Protien: {protein} g
        </Typography>
        <Typography
          variant="h1"
          sx={{
            borderRadius: '8px',
            textAlign: 'center',
            px: '16px',
            py: '12px',
            fontWeight: 600,
            background: caloTheme.palette.secondaryPurple100,
            color: caloTheme.palette.secondaryPurple500,
            fontSize: '14px',
            lineHeight: '17px',
            fontFamily: caloTheme.typography.fontFamily,
            width: '25%'
          }}
        >
          Carbs: {carbs} g
        </Typography>
        <Typography
          variant="h1"
          sx={{
            borderRadius: '8px',
            textAlign: 'center',
            px: '16px',
            py: '12px',
            fontWeight: 600,
            background: caloTheme.palette.secondaryYellow100,
            color: caloTheme.palette.secondaryYellow500,
            fontSize: '14px',
            lineHeight: '17px',
            fontFamily: caloTheme.typography.fontFamily,
            width: '25%'
          }}
        >
          Fat: {fat} g
        </Typography>
      </Stack>
    </>
  );
};

export default TrackedMacros;
