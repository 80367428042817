import { BaseFoodTags, FoodTags, FoodTagsKeys, SnackTypeTags } from '@calo/dashboard-types';
import { FoodType } from '@calo/types';
import { MenuFood } from 'lib/interfaces';
import { sortBy } from 'lodash-es';

const mealTypeSortOrder: { [key: string]: string[] } = {
  lunch: [BaseFoodTags.salad, BaseFoodTags.sandwich, BaseFoodTags.pasta, BaseFoodTags.rice],
  dinner: [BaseFoodTags.salad, BaseFoodTags.sandwich, BaseFoodTags.pasta, BaseFoodTags.rice],
  snack: [SnackTypeTags.soup, SnackTypeTags.minisalad],
  breakfast: [BaseFoodTags.sandwich, BaseFoodTags.egg, BaseFoodTags.oats, BaseFoodTags.parfait, BaseFoodTags.pancake],
  default: [BaseFoodTags.salad, BaseFoodTags.sandwich, BaseFoodTags.pasta, BaseFoodTags.rice]
};

const getSortOrder = (type: string | string[]): string[] => {
  if (Array.isArray(type)) {
    return type.flatMap((t) => mealTypeSortOrder[t] || []);
  }
  return mealTypeSortOrder[type] || mealTypeSortOrder.default;
};

const findTagValues = (tags: FoodTags, key: string): string[] => {
  return tags.find((tag) => tag.key === key)?.value || [];
};

const sortMealsByTag = (meals: MenuFood[], tagKey: string, typeOrder: string[]): MenuFood[] => {
  return sortBy(meals, (meal) => {
    const tagValues = findTagValues(meal.foodTags ?? [], tagKey);
    const firstTagValue = tagValues[0];
    return typeOrder.includes(firstTagValue) ? typeOrder.indexOf(firstTagValue) : typeOrder.length;
  });
};

export const sortMeals = (meals: MenuFood[]): MenuFood[] => {
  const mealsWithBaseTag = meals.filter((meal) => findTagValues(meal.foodTags ?? [], FoodTagsKeys.base).length > 0);
  const mealsWithSnackTag = meals.filter(
    (meal) => meal.type.includes(FoodType.snack) && findTagValues(meal.foodTags ?? [], FoodTagsKeys.snacktype).length > 0
  );
  const mealsWithoutBaseTag = meals.filter((meal) => findTagValues(meal.foodTags ?? [], FoodTagsKeys.base).length === 0);

  const sortedMealsWithBaseTag = sortMealsByTag(
    mealsWithBaseTag,
    FoodTagsKeys.base,
    mealsWithBaseTag.flatMap((meal) => getSortOrder(meal.type))
  );
  const sortedMealsWithSnackTag = sortMealsByTag(mealsWithSnackTag, FoodTagsKeys.snacktype, getSortOrder(FoodType.snack));
  return [...sortedMealsWithBaseTag, ...sortedMealsWithSnackTag, ...mealsWithoutBaseTag];
};
