import { Route, Switch } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';

import CaloLoader from 'components/CaloLoader';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Suspense } from 'react';
import ExactFood from './ExactFood';
import ExactFoodComponent from './ExactFoodComponent';
import ExactIngredient from './ExactIngredient';
import Feedback from './Feedback';
import FoodComponentList from './FoodComponentList';
import FoodFeedback from './FoodFeedback';
import FoodList from './FoodList';
import Ingredients from './Ingredients';
import Logs from './Logs';
import NewFood from './NewFood';
import NewFoodComponent from './NewFoodComponent';
import NewIngredient from './NewIngredient';
import RedirctFoodID from './RedirctFoodID';

const Food = () => {
  const roles = useUserRoles();

  return (
    <Suspense fallback={<CaloLoader />}>
      <Switch>
        {roles.includes(Permission.VIEW_FOOD_LIST) && [
          <Route key={Routes.foodList} exact path={Routes.foodList} component={FoodList} />
        ]}
        {roles.includes(Permission.VIEW_FOOD_COMPONENTS_LIST) && [
          <Route key={Routes.foodComponentList} exact path={Routes.foodComponentList} component={FoodComponentList} />
        ]}
        {roles.includes(Permission.VIEW_INGREDIENT_LIST) && [
          <Route key={Routes.ingredients} exact path={Routes.ingredients} component={Ingredients} />
        ]}

        {roles.includes(Permission.CREATE_FOOD) && [
          <Route key={Routes.newFood} exact path={Routes.newFood} component={NewFood} />
        ]}
        {roles.includes(Permission.CREATE_FOOD_COMPONENTS) && [
          <Route key={Routes.newFoodComponent} exact path={Routes.newFoodComponent} component={NewFoodComponent} />
        ]}
        {roles.includes(Permission.CREATE_INGREDIENT) && [
          <Route key={Routes.newIngredient} exact path={Routes.newIngredient} component={NewIngredient} />
        ]}

        {roles.includes(Permission.VIEW_FOOD_LIST_BY_SLUG) && [
          <Route key={Routes.foodSlug} path={Routes.foodSlug} component={ExactFood} />
        ]}

        {roles.includes(Permission.VIEW_FOOD_COMPONENTS) && [
          <Route key={Routes.foodComponent} path={Routes.foodComponent} component={ExactFoodComponent} />
        ]}
        {roles.includes(Permission.VIEW_INGREDIENT) && [
          <Route key={Routes.ingredient} path={Routes.ingredient} component={ExactIngredient} />
        ]}
        {roles.includes(Permission.VIEW_RATING_LIST) && [
          <Route key={Routes.feedback} path={Routes.feedback} component={Feedback} />
        ]}
        {roles.includes(Permission.VIEW_RATING_LIST) && [
          <Route key={Routes.foodFeedback} path={Routes.foodFeedback} component={FoodFeedback} />
        ]}
        {roles.includes(Permission.VIEW_KITCHEN_LOGS_LIST) &&
          roles.includes(Permission.LIST_CUSTOMER_COMPLAINT) && [
            <Route key={Routes.foodLogs} path={Routes.foodLogs} component={Logs} />
          ]}
        {roles.includes(Permission.VIEW_FOOD_LIST_BY_SLUG) && [
          <Route key={Routes.food} path={Routes.food} component={RedirctFoodID} />
        ]}
      </Switch>
    </Suspense>
  );
};

export default Food;
