import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  containerStyles: {
    marginTop: '-35px',
    paddingBottom: 1
  },
  headerStackStyles: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  backButtonStackStyles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column',
      textAlign: 'center',
      width: '100%'
    }
  },
  backButtonIconContainerStyles: {
    marginLeft: '30px'
  },
  backButtonLinkStyles: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px'
  },
  arrowBackIconStyles: {
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto 0'
  },
  backButtonTextStyles: {
    fontSize: '16px',
    fontWeight: 600
  },
  headerTextStyles: {
    fontWeight: 400,
    fontSize: '16px',
    border: 'none',
    color: '#000000',
    textTransform: 'none',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column',
      textAlign: 'center',
      width: '100%',
      mt: 1
    }
  },
  createHeaderStyles: {
    fontSize: '20px',
    fontWeight: 600
  },
  dividerStyles: {
    marginX: 'auto',
    marginTop: '20px',
    marginBottom: '12px',
    width: '100%',
    height: '1px',
    backgroundColor: caloTheme.palette.neutral100
  },
  mainContainerStyles: {
    marginBottom: 2,
    paddingTop: 0,
    top: 0
  },
  mainStackStyles: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 3
  },
  actionButtonStackStyles: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px'
  },
  buttonOutlined: {
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    borderRadius: '8px',
    boxShadow: 'none',
    color: caloTheme.palette.neutral900,
    borderColor: caloTheme.palette.neutral900,
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: caloTheme.palette.neutral900,
      backgroundColor: caloTheme.palette.white
    }
  },
  buttonPrimary: {
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    borderRadius: '8px',
    boxShadow: 'none',
    color: caloTheme.palette.white,
    borderColor: caloTheme.palette.primary500,
    backgroundColor: caloTheme.palette.primary500,
    '&:hover': {
      borderColor: caloTheme.palette.primary500,
      backgroundColor: caloTheme.palette.primary700
    }
  },
  deleteButtonStyles: {
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    borderRadius: '8px',
    boxShadow: 'none',
    color: caloTheme.palette.neutral900,
    borderColor: caloTheme.palette.neutral900,
    '&:hover': {
      borderColor: caloTheme.palette.neutral900,
      backgroundColor: caloTheme.palette.neutral900,
      color: caloTheme.palette.white,
      boxShadow: 'none'
    }
  },
  updateButtonStyles: {
    textTransform: 'none',
    borderRadius: '8px',
    borderColor: caloTheme.palette.primary500,
    color: caloTheme.palette.primary500,
    fontSize: '16px',
    fontWeight: 600,
    '&:hover': {
      borderColor: caloTheme.palette.primary500,
      color: caloTheme.palette.primary500
    }
  },
  saveButtonStyles: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    borderRadius: '8px',
    backgroundColor: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    boxShadow: 'none',
    color: 'white',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: caloTheme.palette.primary600,
      borderColor: caloTheme.palette.primary600
    }
  }
};
