import { forwardRef } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { deleteFood } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import Popup from 'components/Popup';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { Food } from 'lib/interfaces';
import { toast } from 'react-toastify';

interface DeleteSizeModalProps {
  close: () => void;
  selectedFood: Food;
  foodWithAllSizes: Food[];
  isMealUsedInMenu: boolean;
  setSelectedFood: (food: Food) => void;
  setFoodWithAllSizes: (food: Food[]) => void;
}

const DeleteSizeModal = forwardRef(
  (
    { selectedFood, foodWithAllSizes, isMealUsedInMenu, setSelectedFood, setFoodWithAllSizes, close }: DeleteSizeModalProps,
    ref
  ) => {
    const handleDeleteSize = async () => {
      if (selectedFood) {
        await deleteFood(selectedFood.id);
        const existingFood = foodWithAllSizes.filter((f) => f.id !== selectedFood.id);
        if (existingFood.length > 0) {
          setFoodWithAllSizes(existingFood);
          setSelectedFood(existingFood[0]);
        } else {
          history.push(Routes.foodList);
        }
        close();
      }
    };

    const handleDeleteMeal = async () => {
      await Promise.all(foodWithAllSizes.map((food) => deleteFood(food.id)));
      history.push(Routes.foodList);
      toast('Meal deleted successfully', { type: 'success', autoClose: 2000 });
      close();
    };

    return (
      <Popup ref={ref}>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" mb={4}>
          <Stack sx={{ justifyContent: 'center', mx: 'auto', textAlign: 'center', mt: 2 }}>
            <Typography variant="h4" fontWeight="bold" sx={{ fontSize: '34px', fontWeight: 600, lineHeight: '48px' }}>
              Are you sure you want to delete meal size ?
            </Typography>
          </Stack>
          <IconButton onClick={() => close()} sx={{ ml: 4, width: '1.5rem', height: 'auto', border: 'none' }}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Tooltip placement={'top'} arrow title={isMealUsedInMenu ? "meal is used on the menu can't delete it" : ''}>
            <span>
              <Button
                variant="outlined"
                onClick={handleDeleteMeal}
                disabled={isMealUsedInMenu}
                sx={styles.entireMealButton}
                data-test="confirm_delete_meal"
              >
                Entire Meal
              </Button>
            </span>
          </Tooltip>
          <Button
            variant="contained"
            color="success"
            data-test="confirm_delete_meal_size"
            onClick={handleDeleteSize}
            sx={styles.deleteSizeButton}
          >
            Meal Size
          </Button>
        </Box>
      </Popup>
    );
  }
);
export default DeleteSizeModal;

const styles = {
  deleteSizeButton: {
    color: 'white',
    fontWeight: 600,
    fontSize: '19px',
    boxShadow: 'none',
    lineHeight: '28px',
    borderRadius: '8px',
    textAlign: 'center',
    textTransform: 'none',
    borderColor: caloTheme.palette.primary500,
    backgroundColor: caloTheme.palette.primary500,
    '&:hover': {
      color: 'white',
      boxShadow: 'none',
      backgroundColor: caloTheme.palette.primary600,
      borderColor: caloTheme.palette.primary600
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      marginTop: 4,
      width: 'auto',
      margin: 'auto',
      justifyItems: 'center'
    }
  },
  entireMealButton: {
    px: '16px',
    mr: 4,
    borderWidth: 0,
    fontWeight: 600,
    fontSize: '16px',
    textTransform: 'none',
    color: caloTheme.palette.neutral900,
    ':hover': {
      borderWidth: 0,
      color: caloTheme.palette.neutral900
    }
  }
};
