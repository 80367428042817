import { caloTheme } from 'assets/images/theme/calo';
import { Delivery } from 'lib/interfaces';
import handleRenderDay, { DeliveryDayStatusProps } from 'lib/renderDayStyle';
import { startCase, uniq } from 'lodash-es';
import React, { useEffect, useState } from 'react';

import DateFnsAdapter from '@date-io/date-fns';
import { ExpandMore as ExpandMoreIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';

import styles from './styles';

interface DeliveriesCalenderProps {
  selectedDate: Date;
  allDeliveries: Delivery[];
  setSelectedDate: (value: Date) => void;
}

const Indexes = [
  { status: 'delivered', color: caloTheme.palette.primary100 },
  { status: 'pending', color: caloTheme.palette.secondaryYellow100 },
  { status: 'skip', color: caloTheme.palette.neutral700 },
  { status: 'cancel', color: caloTheme.palette.secondaryRed100 },
  { status: 'paused', color: caloTheme.palette.secondaryPurple100 }
];

const StyledCircle = styled(Paper)(({ color }) => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
  backgroundColor: color
}));

const DeliveriesCalender: React.FC<DeliveriesCalenderProps> = ({ allDeliveries, selectedDate, setSelectedDate }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [deliveryDayStatus, setDeliveryDayStatus] = useState<DeliveryDayStatusProps>({
    skipped: uniq([]),
    delivered: uniq([]),
    cancelled: uniq([]),
    pending: uniq([]),
    paused: uniq([])
  });

  useEffect(() => {
    const updatedStatus: DeliveryDayStatusProps = {
      skipped: [],
      delivered: [],
      cancelled: [],
      pending: [],
      paused: []
    };

    for (const del of allDeliveries) {
      const day = del.day.split('T')[0];
      if (del.skipped) updatedStatus.skipped.push(day);
      if (del.deliveredAt) updatedStatus.delivered.push(day);
      if (del.status === 'cancelled') updatedStatus.cancelled.push(day);
      if (!del.deliveredAt) updatedStatus.pending.push(day);
      if (del.status === 'paused') updatedStatus.paused.push(day);
    }

    setDeliveryDayStatus(updatedStatus);
  }, [allDeliveries]);

  return (
    <>
      <Stack sx={styles.mainStack}>
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
          <StaticDatePicker
            value={selectedDate}
            displayStaticWrapperAs="desktop"
            onChange={(newValue) => {
              if (newValue) {
                setSelectedDate(newValue);
              }
            }}
            renderInput={(params) => <TextField {...params} />}
            dayOfWeekFormatter={(day) => `${day}`}
            renderDay={(day, _value, DayComponentProps) => handleRenderDay(day, DayComponentProps, deliveryDayStatus)}
            onMonthChange={(month) => setSelectedDate(month)}
          />
        </LocalizationProvider>
      </Stack>

      <Card variant="outlined" sx={styles.indexCard}>
        <CardHeader
          title="Index"
          action={
            <IconButton onClick={() => setIsOpen(!isOpen)} aria-label="show more">
              {isOpen ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
            </IconButton>
          }
        />
        <CardContent>
          <Collapse in={isOpen}>
            <Grid container spacing={5}>
              {Indexes.map((index, idx) => (
                <Grid item key={idx}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                      <StyledCircle color={index.color} />
                      <Typography variant={'caption'} sx={styles.indexText}>
                        {startCase(index.status)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Collapse>
        </CardContent>
      </Card>
    </>
  );
};

export default DeliveriesCalender;
