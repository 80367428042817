import { ListGiftsFilters, Permission } from '@calo/dashboard-types';
import { getListWithParams } from 'actions';
import { useUserRoles } from 'lib/hooks';
import { SpecialRequest } from 'lib/interfaces';
import { useState } from 'react';
import { useQuery } from 'react-query';

export interface PaginatedSpecialRequest {
  data: SpecialRequest[];
  meta: {
    limit: number;
    total: number;
  };
}

interface useSpecialRequestListProps {
  page: number;
  filters: ListGiftsFilters;
}
const useSpecialRequestList = ({ filters, page }: useSpecialRequestListProps) => {
  const roles = useUserRoles();

  const [specialRequest, setSpecialRequest] = useState<any[]>([]);

  const { data, refetch, isLoading } = useQuery<any, Error, PaginatedSpecialRequest>(
    [
      'gifts',
      {
        page,
        limit: 50,
        filters: {
          ...filters
        }
      }
    ],
    getListWithParams,
    {
      suspense: false,
      keepPreviousData: false,
      enabled: !!roles.includes(Permission.VIEW_RATING_LIST),
      onSuccess: (data) => {
        if (data.data) {
          setSpecialRequest(data.data);
        }
      }
    }
  );

  return {
    requestLoadingList: isLoading,
    specialRequest,
    data,
    refetch: refetch
  };
};

export default useSpecialRequestList;
