import { useEffect, useRef, useState } from 'react';

import { parseISO } from 'date-fns';
import { flatten, flattenDepth, startCase } from 'lodash-es';
import DateTime from 'react-datetime-picker';
import { useDispatch, useSelector } from 'react-redux';

import { CodeChannel, Coupon, CouponMode, CouponRule, CouponRuleType, Permission } from '@calo/dashboard-types';
import { Country, CouponType, Currency, PlanType, SubscriptionFrequency } from '@calo/types';

import { toggleUICouponSettings } from 'actions';
import { Button, Card, Input, Modal, ModalRef, Select, SideDrawer } from 'components';
import { checkCountryCurrency, handleValidPhoneNumber, hasValidCharactersForLanguage, isValidEmail } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import AddRulesModal from '../AddRulesModel';
import CouponRules from '../CouponRules';
import CreditCardList from '../CreditCardList';
import ExclusiveList from '../ExclusiveList';
import useCouponForm from './useCouponForm';

interface CouponFormProps {
  onSubmit: (value: Omit<Coupon, 'id'>) => Promise<void>;
  coupon: Coupon;
  frequency: CouponRule;
  planType: CouponRule;
  channel: CodeChannel;
  exList: any;
  exclusiveMember: boolean;
  emailDomain: CouponRule;
  exType: string;
  creditCardH: any;
  setEmailDomain: (value: CouponRule) => void;
  setExclusiveMember: (values: boolean) => void;
  setFrequency: (values: CouponRule) => void;
  setPlanType: (values: CouponRule) => void;
  setChannel: (values: CodeChannel) => void;
  setExList: (values: any) => void;
  setExType: (value: string) => void;
  setCreditCardH: (value: CouponRule) => void;
}

const CouponForm = ({
  creditCardH,
  setCreditCardH,
  exType,
  setExType,
  onSubmit,
  coupon,
  frequency,
  setFrequency,
  emailDomain,
  setEmailDomain,
  planType,
  setPlanType,
  exList,
  setExList,
  setExclusiveMember,
  exclusiveMember
}: CouponFormProps) => {
  const addRulesRef = useRef<ModalRef>();
  const addCreditCardListRef = useRef<ModalRef>();
  const [langAlert, setLangAlert] = useState({ AR: false, EN: false });
  const [countryRuleData, setCountryRuleData] = useState<any>(Country.BH);

  const [rules, setRules] = useState<CouponRule>({
    type: undefined!,
    value: undefined!
  });
  const [areMandatoryRulesSet, setAreMandatoryRulesSet] = useState<boolean>(false);
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.couponSettings
  }));
  const dispatch = useDispatch();
  const roles = useUserRoles();
  const addExclusiveMemberRef = useRef<ModalRef>();
  const [newMember, setNewMember] = useState<any>();
  const [newCreditCard, setNewCreditCard] = useState<any>();

  const { handleSubmit, values, handleChange, handleBlur, isSubmitting, isValid, setFieldValue, setValues } = useCouponForm(
    coupon,
    onSubmit
  );

  useEffect(() => {
    if (values.rules.find((r) => r.type === CouponRuleType.EMAIL_DOMAIN)) {
      setExType('Email domain');
    } else if (values.rules.find((r) => r.type === CouponRuleType.EMAIL_EXISTS || r.type === CouponRuleType.PHONE_EXISTS)) {
      setExType('Add List');
    } else if (values.rules.find((r) => r.type === CouponRuleType.CREDIT_CARD_PREFIX)) {
      setExType('Payment Card');
    } else {
      setExType('');
    }
  }, []);

  const handleNewMember = (x: any) => {
    setExList({ ...exList, value: flatten([...exList.value, x]) });
    addExclusiveMemberRef.current?.close();
  };

  const handleRemoveUser = (userValue: string) => {
    const index = flatten(exList.value).findIndex((i: any) => i.value === userValue);
    exList.value.splice(index, 1);
    setExList({ ...exList, value: flattenDepth(exList.value) });
  };

  const handleNewCreditCard = (x: any) => {
    setCreditCardH({
      ...creditCardH,
      value: flatten([...creditCardH.value, x])
    });
    addCreditCardListRef.current?.close();
    setNewCreditCard(undefined);
  };

  const handleRemoveCreditCard = (userValue: string) => {
    const index = flatten(creditCardH.value).indexOf(userValue);
    creditCardH.value.splice(index, 1);
    setCreditCardH({ ...exList, value: flatten(creditCardH.value) });
  };

  useEffect(() => {
    !exclusiveMember && setExList({ type: undefined, value: [] });
    !exclusiveMember && setEmailDomain({ type: undefined, value: undefined } as any);
    !exclusiveMember && setCreditCardH({ type: undefined, value: [] } as any);
  }, [exclusiveMember]);

  useEffect(() => {
    if (!exclusiveMember && exType === 'Payment Card') {
      setExType('Add List');
      setFieldValue('type', undefined);
    }
  }, [exclusiveMember]);

  useEffect(() => {
    if (exType === 'Payment Card') {
      setFieldValue('mode', CouponMode.CREDIT);
    } else {
      setFieldValue('mode', CouponMode.DISCOUNT);
    }
  }, [exType]);

  useEffect(() => {
    const countryRule = values.rules.find((r) => r.type === CouponRuleType.USER_COUNTRY);
    if (countryRule) {
      setCountryRuleData(countryRule.value);
      setFieldValue('currency', checkCountryCurrency(countryRule.value as Country));
    }
  }, [values.rules]);

  const handleCharacters = (letter: string, lang: string) => {
    switch (lang) {
      case 'AR':
        hasValidCharactersForLanguage(letter, lang)
          ? setLangAlert({ ...langAlert, AR: false })
          : setLangAlert({ ...langAlert, AR: true });
        return setFieldValue('hint.ar', hasValidCharactersForLanguage(letter, 'AR') ? letter : values.hint?.ar);
      case 'EN':
        hasValidCharactersForLanguage(letter, lang)
          ? setLangAlert({ ...langAlert, EN: false })
          : setLangAlert({ ...langAlert, EN: true });
        return setFieldValue('hint.en', hasValidCharactersForLanguage(letter, 'EN') ? letter : values.hint?.en);
    }
  };

  return (
    <SideDrawer
      visible={settingsVisible}
      onClose={() => dispatch(toggleUICouponSettings())}
      className={settingsVisible ? 'w-auto' : ''}
    >
      <div className="w-auto">
        <label className="label text-xl pt-8 px-4">Coupon Info</label>
        <div className=" flex flex-row pt-4 px-8">
          <div>
            <Input
              label="Amount"
              value={values.amount}
              name="amount"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!roles.includes(Permission.UPDATE_COUPON)}
            />

            {exType === 'Payment Card' && (
              <div className="w-full">
                <label className="flex label"> Hint Text EN</label>
                <span>
                  Use
                  <input
                    name="hint.en"
                    value={values.hint?.en}
                    onChange={(data: any) => handleCharacters(data.target.value, 'EN')}
                    onBlur={handleBlur}
                    className={values.hint?.en ? 'w-1/2 border-b-2 border-blue-300' : ' border-b-2 border-blue-300'}
                    maxLength={35}
                  />
                  to get your bonus credits
                </span>
                {langAlert.EN && <p className="text-red-400 text-xs my-2"> letters should be in english only</p>}
                {values.hint?.en?.trim().length === 0 && <p className="text-red-400 text-xs my-2"> Hint can not be empty</p>}
                <label className="mt-3 label flex flex-row">Hint Text AR</label>
                <span>
                  {' '}
                  استخدم
                  <input
                    maxLength={35}
                    name="hint.ar"
                    value={values.hint?.ar}
                    onChange={(data: any) => handleCharacters(data.target.value, 'AR')}
                    className={values.hint?.ar ? 'w-1/2 border-b-2 border-blue-300' : ' border-b-2 border-blue-300'}
                  />
                  لتحصل على رصيد إضافي كمكافأة{' '}
                </span>
                {langAlert.AR && <p className="text-red-400 text-xs my-2"> letters should be in arabic only</p>}
                {values.hint?.ar?.trim().length === 0 && <p className="text-red-400 text-xs my-2"> Hint can not be empty</p>}
              </div>
            )}

            {exType === 'Payment Card' ? (
              <Select
                label="Type"
                value={values.type}
                isDisabled={!roles.includes(Permission.UPDATE_COUPON)}
                onChange={(data: any) => setFieldValue('type', data.value)}
                options={[
                  {
                    value: CouponType.FIXED,
                    label: 'Extra Credit Amount'
                  },
                  {
                    value: CouponType.PERCENTAGE,
                    label: 'Extra Credit Percentage'
                  }
                ]}
              />
            ) : (
              <Select
                label="Type"
                value={values.type}
                onChange={(data: any) => setFieldValue('type', data.value)}
                isDisabled={!roles.includes(Permission.UPDATE_COUPON)}
                options={
                  values.rules.some((r) => r.type === CouponRuleType.USER_COUNTRY)
                    ? Object.values(CouponType).map((t) => ({
                        value: t,
                        label: CouponType[t]
                      }))
                    : [
                        {
                          value: CouponType.PERCENTAGE,
                          label: CouponType.PERCENTAGE
                        }
                      ]
                }
              />
            )}
            <Select
              label="Currency"
              value={values.currency}
              isDisabled={
                !roles.includes(Permission.UPDATE_COUPON) ||
                !!flatten(values.rules).find((r) => r.type === CouponRuleType.USER_COUNTRY)
              }
              onChange={(data: any) => setFieldValue('currency', data.value)}
              options={Object.values(Currency).map((t) => ({
                value: t,
                label: Currency[t]
              }))}
            />
            <Select
              label="Frequency"
              isMulti
              value={frequency?.value}
              onChange={(data: any) =>
                setFrequency({
                  type: CouponRuleType.SUBSCRIPTION_FREQUENCY,
                  value: data.map((val) => val.value) as any
                })
              }
              options={Object.values(SubscriptionFrequency).map((t) => ({
                value: t,
                label: t
              }))}
            />
            <Select
              label="Channel"
              value={values.channel}
              className="w-96"
              onChange={(data: any) => setFieldValue('channel', data.value)}
              options={Object.values(CodeChannel).map((channel) => ({
                value: channel,
                label: startCase(channel)
              }))}
            />
            <Select
              isMulti
              label="Meal package"
              value={planType?.value}
              className="w-96"
              onChange={(data: any) =>
                setPlanType({
                  ...planType,
                  value: data.map((val) => val.value) as any
                })
              }
              options={Object.values(PlanType).map((plan) => ({
                value: plan,
                label: startCase(plan)
              }))}
            />
            <div className="field">
              <label className="label">Expire At</label>
              <div className="control is-clearfix">
                <DateTime
                  className="w-1/6"
                  onChange={(date: Date) => setFieldValue('expiresAt', date && date.toISOString())}
                  value={values.expiresAt ? parseISO(values.expiresAt) : undefined}
                  disabled={!roles.includes(Permission.UPDATE_COUPON)}
                />
              </div>
            </div>

            <Card title="Update Rules" className="my-2">
              {roles.includes(Permission.UPDATE_COUPON) && (
                <div className="float-right -mt-8">
                  <Button icon="fas fa-plus" onClick={() => addRulesRef.current?.open()} />
                </div>
              )}
              <CouponRules
                rulesValues={values.rules.filter(
                  (r) =>
                    r.type !== CouponRuleType.SUBSCRIPTION_PLAN &&
                    r.type !== CouponRuleType.SUBSCRIPTION_FREQUENCY &&
                    r.type !== CouponRuleType.EMAIL_EXISTS &&
                    r.type !== CouponRuleType.PHONE_EXISTS &&
                    r.type !== CouponRuleType.EMAIL_DOMAIN &&
                    r.type !== CouponRuleType.CREDIT_CARD_PREFIX
                )}
                values={values}
                setValues={(s) => setValues(s)}
                addRulesRef={addRulesRef}
                setRules={(e: any) => setRules({ type: e.type, value: e.value })}
              />
              {!values.rules.find((r) => r.type === CouponRuleType.USER_ACTION) && (
                <p className="text-red-500 text-xs">User action rule is needed</p>
              )}
            </Card>

            <label className="b-checkbox checkbox">
              <input
                type="checkbox"
                value="lorem"
                checked={exclusiveMember}
                onChange={() => setExclusiveMember(!exclusiveMember)}
              />
              <span className="check is-primary"> Rules for Exclusive Members </span>
            </label>

            {exclusiveMember && (
              <div>
                <Select
                  value={exType}
                  onChange={(data: any) => setExType(data.value)}
                  options={[
                    {
                      label: 'Add List',
                      value: 'Add List'
                    },
                    {
                      label: 'Email domain',
                      value: 'Email domain'
                    },
                    {
                      label: 'Payment Card',
                      value: 'Payment Card'
                    }
                  ]}
                />
                {exType === 'Add List' && (
                  <ExclusiveList
                    exList={exList}
                    setExList={setExList}
                    handleRemoveUser={handleRemoveUser}
                    addExclusiveMemberRef={addExclusiveMemberRef}
                  />
                )}
                {exType === 'Email Domain' && (
                  <div>
                    <Input
                      label="Domain"
                      // @ts-ignore
                      value={emailDomain?.value}
                      onChange={(data) => {
                        setEmailDomain({
                          type: CouponRuleType.EMAIL_DOMAIN,
                          value: data.target.value
                        });
                      }}
                    />
                  </div>
                )}

                {exType === 'Payment Card' && (
                  <CreditCardList
                    exList={creditCardH!}
                    setExList={(v) => setCreditCardH(v)}
                    addCreditCardListRef={addCreditCardListRef}
                    handleRemoveUser={handleRemoveCreditCard}
                  />
                )}
              </div>
            )}

            <AddRulesModal
              rules={rules}
              values={values}
              addRulesRef={addRulesRef}
              setRules={(e) => setRules(e)}
              setFieldValue={setFieldValue}
              setActionRules={(e) => setAreMandatoryRulesSet(e)}
            />
            {roles.includes(Permission.UPDATE_COUPON) && (
              <Button
                type="submit"
                className="float-right my-4"
                primary
                loading={isSubmitting}
                disabled={
                  !isValid ||
                  isSubmitting ||
                  !areMandatoryRulesSet ||
                  Object.values(planType.value).length === 0 ||
                  values.type === undefined ||
                  (exType === 'Payment Card' && !(values.hint?.en?.trim().length !== 0 && values.hint?.ar?.trim().length !== 0))
                }
                content="Save"
                onClick={() => {
                  setFieldValue('mode', exType === 'Payment Card' ? CouponMode.CREDIT : CouponMode.DISCOUNT);
                  handleSubmit();
                  dispatch(toggleUICouponSettings());
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Modal ref={addExclusiveMemberRef}>
        <div className="flex flex-col">
          <Input
            value={newMember}
            onBlur={handleBlur}
            placeholder={
              CouponRuleType.PHONE_EXISTS ? (countryRuleData === Country.BH ? '+973XXXXXXXX' : '+966XXXXXXXXX') : 'Abcde@calo.app'
            }
            onChange={(data: any) => setNewMember(data.target.value)}
            label={exList.type === CouponRuleType.PHONE_EXISTS ? 'Add phone Number' : 'Add an Email'}
            error={
              exList.type === CouponRuleType.PHONE_EXISTS
                ? !handleValidPhoneNumber(newMember ? newMember : '', countryRuleData) && newMember?.length >= 1
                : !isValidEmail(newMember) && newMember?.length > 1
            }
          />
          {exList.type === CouponRuleType.EMAIL_EXISTS
            ? !isValidEmail(newMember) &&
              newMember?.length > 1 && (
                <p className="text-red-400 text-xs -mt-2">Email format is not correct, a good format is Abcde@calo.app</p>
              )
            : !handleValidPhoneNumber(newMember ? newMember : '', countryRuleData) &&
              newMember?.length >= 1 && (
                <p className="text-red-400 text-xs -mt-2">
                  Phone number format is not correct, a good format is{' '}
                  {countryRuleData === Country.BH ? '+973XXXXXXXX' : '+966XXXXXXXXX'}
                </p>
              )}
          <Button
            warning
            content="Add"
            onClick={() => handleNewMember(newMember)}
            disabled={
              !newMember || exList.type === CouponRuleType.PHONE_EXISTS
                ? !handleValidPhoneNumber(newMember ? newMember : '', countryRuleData)
                : !isValidEmail(newMember)
            }
          />
        </div>
      </Modal>
      <Modal ref={addCreditCardListRef}>
        <div className="flex flex-col">
          <Input
            label="Add card digits"
            value={newCreditCard}
            onChange={(data: any) => setNewCreditCard(data.target.value)}
            onBlur={handleBlur}
            type="number"
          />
          {exList.value.includes(newCreditCard) && <p className="text-red-500 text-xs mb-2"> Digits already exist in the list</p>}
          <Button
            warning
            content="Add"
            onClick={() => handleNewCreditCard(newCreditCard)}
            disabled={newCreditCard?.length !== 6 || exList.value.includes(newCreditCard)}
          />{' '}
        </div>
      </Modal>
    </SideDrawer>
  );
};
export default CouponForm;
