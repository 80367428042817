import { ListGiftsFilters } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Menu,
  Radio,
  Stack,
  Typography
} from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns/fp';
import { isNull } from 'lodash-es';
import { useEffect, useState } from 'react';
import DatePicker from '../DatePicker';
import styles from './styles';
interface SpecialRequestFiltersProps {
  anchorEl: null;
  isOpenFilter: boolean;
  filters: ListGiftsFilters;
  setAnchorEl: React.Dispatch<React.SetStateAction<null>>;
  setFilters: React.Dispatch<React.SetStateAction<ListGiftsFilters>>;
}

const SpecialRequestFilters = ({ isOpenFilter, anchorEl, setAnchorEl, filters, setFilters }: SpecialRequestFiltersProps) => {
  const [preApplyFilters, setPreApplyFilters] = useState<ListGiftsFilters>({
    date: {
      lte: filters.date!.lte,
      gte: filters.date!.gte
    },
    kitchen: filters.kitchen
  });

  const handleFilterChange = (event: any) => {
    const itemType = event.target.name;
    setPreApplyFilters({
      ...preApplyFilters,
      kitchen: itemType
    });
  };

  const handleApplyFilters = () => {
    setFilters({
      date: {
        gte: preApplyFilters.date!.gte,
        lte: preApplyFilters.date!.lte
      },
      kitchen: preApplyFilters.kitchen
    });
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!isOpenFilter) {
      setPreApplyFilters(filters);
    }
  }, [isOpenFilter]);

  return (
    <Menu
      open={isOpenFilter}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        'aria-labelledby': 'export-button'
      }}
      sx={{
        marginTop: '-7%',
        borderRadius: '8px',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          marginTop: '-14%'
        }
      }}
    >
      <Box display={'flex'} flexDirection={'column'} style={{ borderRadius: 12, padding: '16px' }}>
        <Stack display={'flex'} sx={{ justifyContent: 'start', ml: 1, my: 1 }}>
          <Typography sx={{ ...styles.titleText }}>Filters</Typography>
        </Stack>
        <Stack display={'flex'} flexDirection={'row'} width={'100%'}>
          <Stack display={'flex'} sx={{ ...styles.datePickerStack }}>
            <DatePicker
              label={'Start Date'}
              clearIndicator={false}
              selectedDate={preApplyFilters.date!.gte ? preApplyFilters.date!.gte : null}
              setSelectedDate={(e: any) =>
                setPreApplyFilters({
                  ...preApplyFilters,
                  date: {
                    ...preApplyFilters.date!,
                    gte: format('yyyy-MM-dd')(isNull(e) ? Date.now() : (e as Date))
                  }
                })
              }
              onChange={(date: any) =>
                setPreApplyFilters({
                  ...preApplyFilters,
                  date: {
                    ...preApplyFilters.date!,
                    gte: format('yyyy-MM-dd')(date as Date)
                  }
                })
              }
              width={'182px'}
              disabled={false}
              maxDate={preApplyFilters.date!.lte ? new Date(preApplyFilters.date!.lte) : undefined}
            />
          </Stack>
          <Stack display={'flex'} sx={{ ...styles.datePickerStack, px: '8px' }}>
            <DatePicker
              label={'End Date'}
              clearIndicator={false}
              selectedDate={preApplyFilters.date!.lte ? preApplyFilters.date!.lte : null}
              setSelectedDate={(e: any) =>
                setPreApplyFilters({
                  ...preApplyFilters,
                  date: {
                    ...preApplyFilters.date!,
                    lte: format('yyyy-MM-dd')(isNull(e) ? Date.now() : (e as Date))
                  }
                })
              }
              onChange={(date: any) =>
                setPreApplyFilters({
                  ...preApplyFilters,
                  date: {
                    ...preApplyFilters.date!,
                    lte: format('yyyy-MM-dd')(date as Date)
                  }
                })
              }
              width={'182px'}
              disabled={false}
            />
          </Stack>
        </Stack>
        <Divider sx={{ color: caloTheme.palette.neutral50, mx: 1, mt: '12px', display: 'flex' }} />
        <Stack display={'flex'} flexDirection={'row'} sx={{ ...styles.typeStack }}>
          <Accordion sx={{ borderWidth: 0, width: '100%' }} variant="outlined">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ width: '100%', mt: '-4px' }}>
              <Typography sx={{ ...styles.typeText }}>Kitchen</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
              {Object.values(Kitchen)
                .filter((kitchenData) => !kitchenData.includes('000'))
                .map((kitchen) => (
                  <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      sx={{ ...styles.leftTextType }}
                      control={
                        <Radio
                          name={kitchen}
                          checked={preApplyFilters.kitchen === kitchen}
                          onChange={(event) => handleFilterChange(event)}
                        />
                      }
                      label={kitchen}
                    />
                  </Stack>
                ))}
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Divider sx={{ color: caloTheme.palette.neutral50, m: 1, mt: '-8px', display: 'flex' }} />
        <Button variant="outlined" sx={{ ...styles.applyFilterButton }} onClick={handleApplyFilters}>
          Apply Filters
        </Button>
      </Box>
    </Menu>
  );
};
export default SpecialRequestFilters;
