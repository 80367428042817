import { Country, Currency, Kitchen } from '@calo/types';
import { FormikErrors } from 'formik';
import { uniq } from 'lodash-es';

interface SelectCountryProps {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<Omit<any, 'id'>>>;
  value: string;
  kitchens: Kitchen[];
}

export const selectCountry = ({ kitchens, setFieldValue, value }: SelectCountryProps) => {
  setFieldValue('country', value);
  setFieldValue(
    'kitchen',
    kitchens.find((k) => k.includes(value))
  );
};

export const resolveCountry = (country?: Country) => {
  switch (country) {
    case Country.BH:
      return 'Bahrain';
    case Country.SA:
      return 'Saudi Arabia';
    case Country.AE:
      return 'United Arab Emirates';
    case Country.KW:
      return 'Kuwait';
    case Country.OM:
      return 'Oman';
    case Country.QA:
      return 'Qatar';
    case Country.GB:
      return 'United Kingdom';
    default:
      return '';
  }
};

export const checkCountryCurrency = (country: Country) => {
  switch (country) {
    case Country.BH:
      return Currency.BHD;
    case Country.SA:
      return Currency.SAR;
    case Country.AE:
      return Currency.AED;
    case Country.KW:
      return Currency.KWD;
    case Country.QA:
      return Currency.QAR;
    case Country.OM:
      return Currency.OMR;
    case Country.GB:
      return Currency.GBP;
    default:
      return Currency.BHD;
  }
};

export const getCenteredCountry = (country: Country) => {
  switch (country) {
    case Country.BH:
      return { lat: 26.222917, lng: 50.580958 };
    case Country.SA:
      return { lat: 24.7136, lng: 46.6753 };
    case Country.KW:
      return { lat: 29.3117, lng: 47.4818 };
    case Country.OM:
      return { lat: 21.4735, lng: 55.9754 };
    case Country.QA:
      return { lat: 25.286188, lng: 51.534774 };
    case Country.AE:
      return { lat: 25.2048, lng: 55.2708 };
    case Country.GB:
      return { lat: 51.507405, lng: -0.12861 };
    default:
      return { lat: 26.222917, lng: 50.580958 };
  }
};

export const resolveCountryFromKitchen = (kitchen: Kitchen) => {
  switch (kitchen) {
    case Kitchen.BH0:
    case Kitchen.BH1: {
      return Country.BH;
    }
    case Kitchen.AE0:
    case Kitchen.AE1: {
      return Country.AE;
    }
    case Kitchen.SA0:
    case Kitchen.SA1:
    case Kitchen.SA2: {
      return Country.SA;
    }
    case Kitchen.KW0:
    case Kitchen.KW1: {
      return Country.KW;
    }
    case Kitchen.QA0:
    case Kitchen.QA1: {
      return Country.QA;
    }
    case Kitchen.GB0:
    case Kitchen.GB1: {
      return Country.GB;
    }
    default: {
      return Country.BH;
    }
  }
};

export const getAccessibleCountries = (kitchens: string[]) => {
  return uniq(kitchens.map((k) => resolveCountryFromKitchen(k as Kitchen)));
};
