import { Macros, Micronutrients } from '@calo/types';
import { Box, Typography } from '@mui/material';

import { Modal, ModalRef } from '../';

interface ModalProps {
  calculatedMacrosRef: React.MutableRefObject<ModalRef | undefined>;
  calculatedMacrosFromIngredients?: Macros;
  calculatedMicronutrientsFromIngredients?: Micronutrients;
}

const FoodComponentCalculatedMacrosModal = ({
  calculatedMacrosRef,
  calculatedMacrosFromIngredients,
  calculatedMicronutrientsFromIngredients
}: ModalProps) => (
  <Modal
    isNarrow
    ref={calculatedMacrosRef}
    onClose={() => {
      calculatedMacrosRef.current?.close();
    }}
  >
    <section className="section is-title-bar -mt-8">
      <p className="font-bold uppercase text-center">Calculated Nutritional Values</p>
    </section>
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} margin={2}>
      <Typography variant="h6">Cal: {calculatedMacrosFromIngredients?.cal || 0}</Typography>
      <Typography variant="h6">Protein: {calculatedMacrosFromIngredients?.protein || 0}</Typography>
      <Typography variant="h6">Carbs: {calculatedMacrosFromIngredients?.carbs || 0}</Typography>
    </Box>
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-around'} margin={2}>
      <Typography variant="h6">Fats: {calculatedMacrosFromIngredients?.fat || 0}</Typography>
      <Typography variant="h6">Fiber: {calculatedMacrosFromIngredients?.fiber || 0}</Typography>
    </Box>
    <hr style={{ height: 0.5, backgroundColor: 'lightgray' }} />
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} margin={2}>
      <Typography variant="h6">Saturated Fats: {calculatedMicronutrientsFromIngredients?.saturatedFats || 0}</Typography>
      <Typography variant="h6">Trans Fats: {calculatedMicronutrientsFromIngredients?.transFats || 0}</Typography>
      <Typography variant="h6">Sodium: {calculatedMicronutrientsFromIngredients?.sodium || 0}</Typography>
    </Box>
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} margin={2}>
      <Typography variant="h6">Cholesterol: {calculatedMicronutrientsFromIngredients?.cholesterol || 0}</Typography>
      <Typography variant="h6">Added Sugar: {calculatedMicronutrientsFromIngredients?.addedSugar || 0}</Typography>
      <Typography variant="h6">Total Sugar: {calculatedMicronutrientsFromIngredients?.totalSugar || 0}</Typography>
    </Box>
  </Modal>
);

export default FoodComponentCalculatedMacrosModal;
