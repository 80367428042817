import { useState } from 'react';

import cx from 'classnames';
import { compact, flatten, groupBy } from 'lodash';

import { Currency } from '@calo/types';

import { Button, Select2 } from 'components';
import FoodDietTypeTags from 'components/FoodDietTypeTags';
import { getIngredientsToAvoid } from 'lib/helpers';
import { Delivery, FoodWithPosition, MenuFood } from 'lib/interfaces';

interface FoodPickerProps {
  food: FoodWithPosition;
  delivery: Delivery;
  currency: Currency;
  foodList: MenuFood;
  allMenuFood: MenuFood[];
  onPick: (food: MenuFood) => Promise<void>;
}

const FoodPicker = ({ allMenuFood, food, onPick, foodList, delivery }: FoodPickerProps) => {
  const [selectedSize, setSelectedSize] = useState<any>({
    size: foodList.size,
    foodId: foodList.id,
    foodName: foodList.name.en
  });
  const sameMealSize = flatten(Object.values(groupBy(allMenuFood, 'name.en')));

  const handleSizeChange = (size: string, allSizes: MenuFood[]) => {
    const selectedMealData = flatten(compact(allSizes.filter((r) => r.size === size)));
    setSelectedSize({
      size: selectedMealData[0].size,
      foodId: selectedMealData[0].id,
      foodName: selectedMealData[0].name.en
    });
  };

  const handleFoodSize = (foodName: MenuFood) => {
    const allSizes = compact(sameMealSize.map((r) => r.name.en === foodName.name.en && r));
    return (
      <Select2
        value={selectedSize.size}
        name="size"
        className="w-20 mt-2"
        onChange={(data: any) => handleSizeChange(data.target.value, allSizes)}
        options={Object.values(allSizes).map((meal) => ({
          value: meal.size,
          label: meal.size
        }))}
      />
    );
  };

  const handleSelectMeal = (foodList: any, selectedSize: any) => {
    if (selectedSize.foodName === foodList.name.en && selectedSize.size !== foodList.size) {
      onPick(selectedSize);
    } else {
      onPick(foodList);
    }
  };

  return (
    <tr key={foodList.id} className="bg-gray-100 py-4">
      <td className="py-4 w-1/6">
        <span
          className="flex flex-row w-full"
          style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '19px', lineHeight: '23px' }}
        >
          <p className="justify-center ml-4 my-4">{foodList.name.en}</p>
        </span>
      </td>
      <td className="py-4">
        <div className="w-11/12">
          {getIngredientsToAvoid(foodList, delivery).map((i: any) =>
            i.isAlergen ? <span className="text-red-500"> {i.name}, </span> : <span> {i.name}, </span>
          )}
        </div>
      </td>
      <td className="py-4">
        <span className={`flex flex-row py-2`}>{foodList.tags?.map((r: any) => <FoodDietTypeTags key={r} tag={r} />)}</span>
      </td>
      <td className="py-4">
        <span className={`flex flex-row `}>{handleFoodSize(foodList)}</span>
      </td>
      <td className="py-4">
        <Button
          content={food.name.en === foodList.name.en && food.size === selectedSize.size ? 'Selected' : 'Select'}
          outlined
          style={{ fontWeight: 600 }}
          onClick={() =>
            food.name.en === foodList.name.en && food.size === selectedSize.size ? null : handleSelectMeal(foodList, selectedSize)
          }
          className={cx('w-20 mt-2 border-green-500 focus:border-green-500 border-2 rounded-lg bg-gray-100 hover:shadow-lg', {
            'cursor-not-allowed bg-green-500 text-white hover:text-white focus:text-white':
              food.name.en === foodList.name.en && food.size === selectedSize.size,
            'border-green-500 text-green-500 hover:text-green-500 hover:border-green-500 focus:text-green-500':
              food.name.en !== foodList.name.en,
            'border-green-500 text-green-500 hover:text-green-500  hover:border-green-500 focus:text-green-500':
              food.name.en === foodList.name.en && food.size !== selectedSize.size
          })}
        />
      </td>
    </tr>
  );
};
export default FoodPicker;
