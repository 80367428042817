import { useFormik } from 'formik';
import { UpdateAddonsMenuReq } from '@calo/dashboard-types';

export default (addons: any, onSubmit: (values: UpdateAddonsMenuReq) => Promise<void>) =>
  useFormik<UpdateAddonsMenuReq>({
    enableReinitialize: true,
    initialValues: {
      food:
        addons?.food.map((f: any) => ({
          id: f.id,
          priority: +f.priority
        })) || []
    },

    validate: (values: UpdateAddonsMenuReq) => {
      const errors: any = {};

      if (values.food.length === 0) {
        errors.food = true;
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        console.log(values);
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
