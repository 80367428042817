import DOMPurify from 'dompurify';

export const cleanHtml = (str: string): string => {
  const clean = DOMPurify.sanitize(str, {
    ALLOWED_TAGS: ['p', 'table', 'colgroup', 'col', 'tbody', 'tr', 'td'],
    ALLOWED_ATTR: ['border']
  });
  return clean;
};

export const encodeHtmlSymbols = (str: string): string => {
  const html = containsHTML(str) ? str : decodeHtmlSymbols(str);
  const cleanedHtml = cleanHtml(html);

  const encodedHtmlSymbols = cleanedHtml
    .replace(/&(?!(amp|apos|lt|gt|quot|#39|#x2F|nbsp);)/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/\//g, '&#x2F;');

  return encodedHtmlSymbols;
};

export const decodeHtmlSymbols = (str: string): string => {
  const symbols = [
    ['amp', '&'],
    ['apos', "'"],
    ['lt', '<'],
    ['gt', '>'],
    ['quot', '"'],
    ['#39', "'"],
    ['#x2F', '/'],
    ['nbsp', ' ']
  ];

  let previousStr;
  do {
    previousStr = str;
    for (const [symbol, char] of symbols) {
      const regex = new RegExp(`&${symbol};`, 'g');
      str = str.replace(regex, char);
    }
  } while (previousStr !== str);

  return str;
};

export const removeHtml = (str: string): string => {
  const clean = DOMPurify.sanitize(str, {
    ALLOWED_TAGS: [],
    ALLOWED_ATTR: []
  });

  return clean;
};

export const removeDecodedHtmlTags = (str: string): string => {
  const decodedHtml = decodeHtmlSymbols(str);
  const cleanedHtml = removeHtml(decodedHtml);
  return cleanedHtml;
};

export const containsHTML = (str: string) => {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;
  return htmlRegex.test(str);
};
