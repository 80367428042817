import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  mainBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '97%',
    alignItems: 'center',
    py: 1,
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
      flexDirection: 'column'
    }
  },
  titleText: {
    color: caloTheme.palette.neutral900,
    fontSize: '23px',
    lineHeight: '28px',
    fontWeight: 600,
    mx: 1,
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
      flexDirection: 'column',
      justifyContent: 'start',
      width: '100%'
    }
  },
  countryKitchenText: {
    color: caloTheme.palette.neutral900,
    fontSize: '19px',
    lineHeight: '23px',
    fontWeight: 600,
    my: 'auto',
    mr: 1,
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 600
    }
  },
  dateText: {
    color: caloTheme.palette.neutral900,
    fontSize: '19px',
    lineHeight: '23px',
    fontWeight: 600,
    mt: '4px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 600
    }
  },
  mainButtonStack: {
    width: '50%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%'
    }
  },
  buttonStack: {
    flexDirection: 'row',
    display: 'flex',
    backgroundColor: caloTheme.palette.neutral50,
    borderRadius: '12px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '50%'
    }
  },
  zoneButton: {
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    gap: '10px',
    marginLeft: '-4px',
    borderWidth: '0px',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: caloTheme.palette.primary500,
      backgroundColor: caloTheme.palette.primary500,
      color: caloTheme.palette.white,
      boxShadow: 'none'
    }
  }
};

export default styles;
