import { Permission, ShiftActionType } from '@calo/dashboard-types';
import { DriverMetrics } from '@calo/driver-types';
import { Button, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import cx from 'classnames';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Link } from 'react-router-dom';
import { formatTime, getAction, getAverageDeliveryTime, getFormatedTimeInMinutes } from '../utils';

interface Props {
  day: string;
  rows: DriverMetrics[];
  handleStartShift: (value: DriverMetrics) => void;
  getShiftDurationMillisec: (row: DriverMetrics) => number | null;
  totalAvgDeliveryTime: number;
}

const DriversMetricRow = ({ day, rows, getShiftDurationMillisec, totalAvgDeliveryTime, handleStartShift }: Props) => {
  const roles = useUserRoles();

  const getShiftDuration = (row: DriverMetrics) => {
    const millisec = getShiftDurationMillisec(row);
    return millisec ? formatTime(millisec) : '--:--';
  };

  const compareWithAverage = (row: DriverMetrics) => {
    const avg = getAverageDeliveryTime(row);
    if (avg && totalAvgDeliveryTime >= Math.floor(avg / 100 / 60) / 10) {
      return false;
    }
    return true;
  };

  return (
    <>
      <tr>
        <td className=" text-xl py-4 font-medium" colSpan={11}>
          {day}
        </td>
      </tr>
      {rows.map((row) => (
        <tr key={`${row.id}${row.day}`} className="pb-2">
          <td className="border-t-2 border-b-2 border-l-2 rounded-l py-4 pl-5">
            {roles.includes(Permission.VIEW_DRIVER_METRIC) ? (
              <Link
                to={{
                  pathname: Routes.driverMetric.replace(':id', row.id),
                  state: {
                    driverMetric: row
                  }
                }}
              >
                {row.driver.driverName}
              </Link>
            ) : (
              row.driver.driverName
            )}
          </td>
          <td className="border-t-2 border-b-2 py-4">{row.totalDeliveries}</td>
          <td className="border-t-2 border-b-2 py-4">{getAction(row, 'STARTED_SHIFT' as ShiftActionType)}</td>
          <td className="border-t-2 border-b-2 py-4">{getAction(row, 'STARTED_DELIVERING' as ShiftActionType)}</td>
          <td className="border-t-2 border-b-2 py-4">{getAction(row, 'FINISHED_SHIFT' as ShiftActionType)}</td>
          <td className="border-t-2 border-b-2 py-4">{getShiftDuration(row)}</td>
          <td className="border-t-2 border-b-2 py-4">{row.deliveredDeliveries}</td>
          <td className="border-t-2 border-b-2 py-4">{row.totalDeliveries! - row.deliveredDeliveries!}</td>
          <td className={cx('border-t-2 border-b-2 py-4', { 'text-red-500': compareWithAverage(row) })}>
            {getFormatedTimeInMinutes(getAverageDeliveryTime(row))}
          </td>
          <td className={'border-t-2 border-b-2 py-4'}>
            {roles.includes(Permission.UPDATE_DRIVER_METRIC) &&
              (row.canStartShift ? (
                <Typography
                  sx={{
                    lineHeight: '17px',
                    fontWeight: 600,
                    fontSize: '14px'
                  }}
                >
                  Allowed
                </Typography>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    width: 'auto',
                    height: '36px',
                    lineHeight: '17px',
                    fontWeight: 600,
                    fontSize: '14px',
                    borderRadius: '8px',
                    borderColor: caloTheme.palette.primary500,
                    backgroundColor: caloTheme.palette.primary500,
                    color: 'White',
                    '&:hover': {
                      backgroundColor: caloTheme.palette.primary600,
                      borderColor: caloTheme.palette.primary600
                    }
                  }}
                  onClick={() => handleStartShift(row)}
                >
                  Allow
                </Button>
              ))}
          </td>
        </tr>
      ))}
    </>
  );
};

export default DriversMetricRow;
