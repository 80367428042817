import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { generatePath, RouteComponentProps, useLocation } from 'react-router';

import { Permission } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';

import { getListWithParams } from 'actions';
import { Button, CaloLoader } from 'components';
import { resolveCountryFromKitchen } from 'lib';
import { Routes } from 'lib/enums';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import AddonsSubscriptionRow from './AddonsSubscriptionRow';
import Settings from './Settings';

type AddonsListProps = RouteComponentProps;

const AddonsSubscriptionList = ({ history }: AddonsListProps) => {
  const roles = useUserRoles();
  const userKitchen = useUserKitchens();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  useEffect(() => {
    const path = generatePath(Routes.addonsSubscriptionList, { brand: filters.brand, country: filters.country });
    history.push(path);
  }, [filters.country]);

  const { data: addonsSubscriptionList, isLoading } = useQuery<any, Error, any>(
    ['addons-subscription-menu', { brand: filters.brand, kitchen: filters.kitchen }],
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  Add-ons / {filters.country} / {filters.kitchen}
                </li>
              </ul>
            </div>
          </div>
          {(roles.includes(Permission.CREATE_ADDONS_SUBSCRIPTION) || roles.includes(Permission.UPDATE_ADDONS_SUBSCRIPTION)) && (
            <div className="level-right">
              <div className="level-item">
                <Button
                  icon="fas fa-pencil"
                  onClick={() => {
                    console.log(addonsSubscriptionList);
                    history.push(
                      Routes.addonsSubscription
                        .replace(':country', filters.country)
                        .replace(':brand', filters.brand)
                        .replace(':kitchen', filters.kitchen)
                    );
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </section>
      <Settings onFilter={setFilters} filters={filters} />
      {isLoading ? (
        <CaloLoader />
      ) : addonsSubscriptionList ? (
        <div className="card has-table has-table-container-upper-radius">
          <div className="card-content">
            {addonsSubscriptionList && <AddonsSubscriptionRow key={addonsSubscriptionList.id} addons={addonsSubscriptionList} />}
          </div>
        </div>
      ) : (
        <span className="flex w-full text-3xl mt-4 justify-center font-bold text-gray-400">NO ADDONS SUBSCRIPTION</span>
      )}
    </>
  );
};

export default AddonsSubscriptionList;
