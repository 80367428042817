import { getRecord, updateFoodPackage } from 'actions';
import { UpdateFoodPackageReq } from 'lib/interfaces';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import PackageHeaderCard from './PackageHeaderCard';
import PackageInformationCard from './PackageInformationCard';
import usePackageForm from './usePackageForm';

const ExactFoodPackage = () => {
  const { id } = useParams<{ id: string }>();
  const { mutateAsync: updateMutation } = useMutation(updateFoodPackage);

  const { data } = useQuery(['food-packages', id], getRecord, {
    suspense: true
  });
  const foodPackage = data as any;

  const handleUpdatePackages = async (values: UpdateFoodPackageReq) => {
    await updateMutation({ id, ...values });
    console.log(values);
  };

  const { handleSubmit, values, handleBlur, isSubmitting, isValid, dirty, setFieldValue, setValues, errors, touched } =
    usePackageForm(foodPackage, handleUpdatePackages);

  return (
    <>
      <PackageHeaderCard
        dirty={dirty}
        isValid={isValid}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        foodPackage={foodPackage}
      />
      <PackageInformationCard
        errors={errors}
        values={values}
        touched={touched}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        foodPackage={foodPackage}
        setValues={setValues}
      />
    </>
  );
};

export default ExactFoodPackage;
