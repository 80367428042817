import { UpdateMenuReq } from '@calo/dashboard-types';
import { Button, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Stack } from '@mui/material';
import { getListWithParams } from 'actions/index';
import { updateMenu } from 'actions/menu';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import { CaloLoader } from 'components/index';
import { format, getWeek, getYear } from 'date-fns';
import { MenuPresentationType } from 'lib/enums';
import { findStartAndEndDateForWeek } from 'lib/helpers';
import { Menu } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import { isEqual, uniqBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router-dom';
import DailyMenuCard from './DailyMenuCard';
import MenuActionsCard from './MenuActionsCard';
import MenuHeaderCard from './MenuHeaderCard';
import { useMenuFilters, useWeeklyMenu } from './MenuHooks';
import Settings from './Settings';
import WeeklyInfoCard from './WeeklyInfoCard';
import PhoneMenuPreview from './WeeklyInfoCard/PhoneMenuPreview';
import WeeklyMenuListCards from './WeeklyMenuListCards';
import { styles } from './styles';

type MenuListProps = RouteComponentProps;

const MenuList = ({ history }: MenuListProps) => {
  const deletedFoodRef = useRef<ModalRef>();

  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [filters, setFilters] = useMenuFilters();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isAddMealsPopupOpen, setIsAddMealsPopupOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState<number>(getYear(new Date()));
  const [selectedDay, setSelectedDay] = useState(format(Date.now(), 'yyyy-MM-dd'));
  const [menuPresentation, setMenuPresentation] = useState(MenuPresentationType.daily);
  const [deletedFoodNames, setDeletedFoodNames] = useState<string>('');
  const [selectedWeek, setSelectedWeek] = useState<number>(getWeek(new Date(), { weekStartsOn: 0 }));
  const {
    weeklyMenuList,
    isWeeklyMenuLoading,
    isWeeklyMenuChanged,
    setIsWeeklyMenuChanged,
    foodList,
    foodIds,
    originalWeeklyMenuData,
    setOriginalWeeklyMenuData,
    weeklyAverageCost,
    setWeeklyAverageCost,
    setWeeklyMenuList
  } = useWeeklyMenu(filters, selectedWeek, selectedYear);

  const updateWeeklyMenuList = (newMenuList: Menu[]) => {
    const filteredMenuList = newMenuList.map((menu) => ({
      ...menu,
      food: menu.food.filter((food) => !food.deletedAt) // Exclude deleted food
    }));

    const menusWithDeletedFood = newMenuList.filter((menu) => menu.food?.some((food) => food.deletedAt));
    if (menusWithDeletedFood.length > 0) {
      const updatedNames = uniqBy(
        menusWithDeletedFood.flatMap((menu) => menu.food.filter((food) => food.deletedAt)),
        (food) => food.name.en
      )
        .map((food) => food.name.en)
        .join(', ');
      setDeletedFoodNames(updatedNames);
      deletedFoodRef.current?.open();
    }
    setWeeklyMenuList(filteredMenuList);
  };

  const openPhoneModal = () => {
    setIsPhoneModalOpen(true);
  };

  const closePhoneModal = () => {
    setIsPhoneModalOpen(false);
  };

  const { data: menuList, isLoading } = useQuery<any, Error, Menu[]>(
    [
      'menu',
      {
        brand: filters.brand,
        kitchen: filters.kitchen || undefined
      }
    ],
    getListWithParams,
    {
      suspense: true,
      onSuccess: (data) => {
        for (const row of data || []) {
          queryClient.setQueryData(['menu', row.id], row);
        }
      }
    }
  );

  const handleMenuUpdate = (updatedMenu: Menu) => {
    setIsWeeklyMenuChanged(true);
    const updatedWeeklyMenuList = weeklyMenuList?.map((menu) => (menu.id === updatedMenu.id ? updatedMenu : menu));
    queryClient.setQueryData(
      [
        'menu',
        {
          brand: filters.brand,
          kitchen: filters.kitchen || undefined,
          date: {
            gte: format(findStartAndEndDateForWeek(selectedWeek, selectedYear).startOfWeekDate, 'yyyy-MM-dd'),
            lte: format(findStartAndEndDateForWeek(selectedWeek, selectedYear).endOfWeekDate, 'yyyy-MM-dd')
          }
        }
      ],
      updatedWeeklyMenuList!
    );
  };

  const { mutateAsync: updateMutation } = useMutation(updateMenu);

  const onSubmit = async (values: Menu) => {
    const updatedMenu: UpdateMenuReq = {
      day: values.id,
      food: values.food?.map((food) => food.id) || [],
      country: values.country,
      tags: (values.tags as any) || [],
      brand: values.brand,
      kitchen: values.kitchen,
      generatedAt: values.generatedAt || undefined
    };
    await updateMutation(
      { ...updatedMenu, id: values.id, mBrand: values.brand, mKitchen: values.kitchen, draft: values.draft as any },
      {
        onSuccess: (data) => {
          const updatedOriginals = originalWeeklyMenuData.map((menu) => (menu.id === data.id ? data : menu));
          setOriginalWeeklyMenuData(updatedOriginals);
        }
      }
    );
  };

  const handleUpdateWeeklyMenus = async () => {
    if (isWeeklyMenuChanged && weeklyMenuList) {
      try {
        const updatePromises: Promise<void>[] = weeklyMenuList.reduce<Promise<void>[]>((promises, menu) => {
          const originalMenu = originalWeeklyMenuData.find((original) => original.id === menu.id);
          if (
            !isEqual(
              menu.food?.map((f) => f.id),
              originalMenu?.food?.map((f) => f.id)
            )
          ) {
            promises.push(onSubmit(menu));
          }
          return promises;
        }, []);
        await Promise.all(updatePromises);
        setIsEditable(false);
        setIsWeeklyMenuChanged(false);
      } catch (error) {
        console.error('Failed to update weekly menus:', error);
      }
    }
    setIsEditable(false);
    setIsWeeklyMenuChanged(false);
  };

  useEffect(() => {
    setIsDuplicated(false);
    setIsEditable(false);
  }, [selectedWeek, selectedYear]);

  return (
    <>
      <MenuHeaderCard
        filters={filters}
        selectedDay={selectedDay}
        menuPresentation={menuPresentation}
        isWeeklyMenuChanged={isWeeklyMenuChanged}
        setMenuPresentation={setMenuPresentation}
      />
      <MenuActionsCard
        filters={filters}
        foodIds={foodIds}
        history={history}
        menuList={menuList}
        foodList={foodList}
        selectedDay={selectedDay}
        selectedWeek={selectedWeek}
        isDuplicated={isDuplicated}
        setSelectedDay={setSelectedDay}
        weeklyMenuList={weeklyMenuList}
        setIsDuplicated={setIsDuplicated}
        setSelectedWeek={setSelectedWeek}
        setSelectedYear={setSelectedYear}
        menuPresentation={menuPresentation}
        weeklyAverageCost={weeklyAverageCost}
        isFoodListLoading={isWeeklyMenuLoading}
        isWeeklyMenuChanged={isWeeklyMenuChanged}
        setWeeklyAverageCost={setWeeklyAverageCost}
        isLoading={isLoading || isWeeklyMenuLoading}
        updateWeeklyMenuList={updateWeeklyMenuList}
      />
      {menuPresentation === MenuPresentationType.daily && (
        <DailyMenuCard
          filters={filters}
          isLoading={isLoading}
          history={history}
          selectedMenu={menuList?.find((menu) => menu.id === selectedDay)}
        />
      )}
      {menuPresentation === MenuPresentationType.weekly && (
        <>
          {isWeeklyMenuLoading ? (
            <CaloLoader />
          ) : (
            <>
              <WeeklyInfoCard
                isEditable={isEditable}
                setSearchTerm={setSearchTerm}
                setIsEditable={setIsEditable}
                isDuplicated={isDuplicated}
                weeklyAverageCost={weeklyAverageCost}
                setIsWeeklyMenuChanged={setIsWeeklyMenuChanged}
                handleUpdateWeeklyMenus={handleUpdateWeeklyMenus}
                openAddMealsPopup={() => setIsAddMealsPopupOpen(true)}
                openPhoneModal={openPhoneModal}
              />
              <WeeklyMenuListCards
                filters={filters}
                foodIds={foodIds}
                foodList={foodList}
                isEditable={isEditable}
                searchTerm={searchTerm}
                selectedWeek={selectedWeek}
                selectedYear={selectedYear}
                onMenuUpdate={handleMenuUpdate}
                isLoading={isWeeklyMenuLoading}
                weeklyMenuList={weeklyMenuList}
                isAddMealsPopupOpen={isAddMealsPopupOpen}
                setWeeklyAverageCost={setWeeklyAverageCost}
                setIsAddMealsPopupOpen={setIsAddMealsPopupOpen}
                openAddMealsPopup={() => setIsAddMealsPopupOpen(true)}
              />
              <PhoneMenuPreview weeklyMenuList={weeklyMenuList} open={isPhoneModalOpen} closePhoneModal={closePhoneModal} />
              <Popup maxWidth="sm" fullWidth closeIcon onClose={() => deletedFoodRef.current?.close()} ref={deletedFoodRef}>
                <DialogTitle sx={styles.dialogTitle}>
                  The following food have been deleted and will be removed from the menu
                </DialogTitle>
                <DialogContent>
                  <Stack sx={styles.stackPadding}>
                    <List>
                      {deletedFoodNames.split(', ').map((name, index) => (
                        <ListItem key={index} sx={styles.listItem}>
                          <ListItemText primary={name} />
                        </ListItem>
                      ))}
                    </List>
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Button sx={styles.button} onClick={() => deletedFoodRef.current?.close()} disabled={isLoading}>
                    Done
                  </Button>
                </DialogActions>
              </Popup>
            </>
          )}
        </>
      )}
      <Settings onFilter={setFilters} filters={filters} />
    </>
  );
};

export default MenuList;
