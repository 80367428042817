import { FormikErrors } from 'formik';

import { IngredientCategory } from '@calo/types';
import { Card, Typography } from '@mui/material';

import SelectMUI from 'components/MUI/SelectMUI';
import { foodInformationFormMultiSelectCustomStyles } from 'lib/componentStyles';
import { IngredientFormData } from 'lib/interfaces';

interface AllergenCategoryCardProps {
  values: IngredientFormData;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<IngredientFormData>> | Promise<void>;
  isDisabled?: boolean;
}

const AllergenCategoryCard = ({ values, setFieldValue, isDisabled }: AllergenCategoryCardProps) => {
  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: '16px',
        border: 'none',
        borderRadius: '16px',
        px: '12px',
        py: '20px'
      }}
    >
      <Typography variant="h5" sx={{ fontSize: '19px', fontWeight: 600 }}>
        Allergen Category
      </Typography>
      <SelectMUI
        isDisabled={isDisabled}
        label="Category"
        value={values.category}
        isMulti
        onChange={(data: any) =>
          setFieldValue(
            'category',
            (data as any[]).map((row) => row.value)
          )
        }
        options={Object.values(IngredientCategory).map((k) => ({
          value: k,
          label: IngredientCategory[k]
        }))}
        customStyles={foodInformationFormMultiSelectCustomStyles()}
      />
    </Card>
  );
};

export default AllergenCategoryCard;
