import { Brand, Country, RetailBranch } from '@calo/types';

export interface RetailFilter {
  country: Country;
  brand: Brand;
  branch: RetailBranch;
}

export enum PickUpStatus {
  CREATED = 'CREATED',
  READY_FOR_PICK_UP = 'READY_FOR_PICK_UP',
  PICKED_UP = 'PICKED_UP'
}
