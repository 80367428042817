import React, { ReactNode } from 'react';
import { Modal, Fade, alpha } from '@mui/material';
import { styled } from '@mui/system';
import ModalContainer from './ModalContainer';

interface PhoneModalProps {
  open: boolean;
  onClose: () => void;
  header: ReactNode;
  headerTitle: string;
  body: ReactNode;
}

const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '15vw',
  justifyContent: 'center',
  '& .MuiBackdrop-root': {
    backgroundColor: alpha('#212121', 0.5)
  }
}));

const PhoneModal = ({ open, onClose, header, body, headerTitle }: PhoneModalProps) => {
  return (
    <StyledModal open={open} onClose={onClose} closeAfterTransition>
      <Fade in={open}>
        <div>
          <ModalContainer header={header} body={body} headerTitle={headerTitle} onClose={onClose} />
        </div>
      </Fade>
    </StyledModal>
  );
};

export default PhoneModal;
