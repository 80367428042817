import { Button, Icon, Modal } from 'components';
import { Routes } from 'lib/enums';
import history from 'lib/history';

interface TicketModalProps {
  ticketModalRef: any;
  selectedTicket: any;
  oldLocAddress: string | undefined;
  newLocAddress: string | undefined;
  handleUpdateTicket: (value: any) => void;
}

const TicketModal = ({ ticketModalRef, selectedTicket, oldLocAddress, handleUpdateTicket, newLocAddress }: TicketModalProps) => (
  <Modal
    ref={ticketModalRef}
    onClose={() => {
      ticketModalRef.current?.close();
      history.push(Routes.tickets);
    }}
  >
    <div className="section -m-10">
      <section className="section is-title-bar label -my-8 ">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p>Confirm updating details</p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <i
                className="fas fa-times ml-2 cursor-pointer"
                onClick={() => {
                  ticketModalRef.current?.close();
                  history.push(Routes.tickets);
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="card">
        <div className="card-content">
          <span className="flex ">
            <span className="mx-auto ml-8">{`Name: ${selectedTicket && selectedTicket.data.customer.name}`}</span>
            <span className="mx-auto">{`Name: ${selectedTicket && selectedTicket.createdBy.name}`} </span>
          </span>
          <span className="flex">
            <span className="mx-auto mt-4 ml-8">Phone: {selectedTicket && selectedTicket.data.customer.phoneNumber}</span>
            <span className="m-auto mt-4">Phone: {selectedTicket && selectedTicket.createdBy.phoneNumber} </span>
          </span>
        </div>
      </div>

      <div className="card">
        <div className="card-content">
          <span className="flex">
            <span className="w-1/2 flex">
              <p className="label"> Old marker: </p>
              <span className="ml-auto">
                {' '}
                <Icon
                  name="linkTo"
                  size={4}
                  onClick={() =>
                    window.open(
                      `https://maps.google.com/?q=${+selectedTicket?.data.oldLocation.lat}, ${+selectedTicket?.data.oldLocation.lng}`
                    )
                  }
                />
              </span>
            </span>
            <span className="w-1/2 flex">
              <p className="label ml-8"> New marker: </p>
              <span className="ml-auto">
                {' '}
                <Icon
                  name="linkTo"
                  size={4}
                  onClick={() =>
                    window.open(
                      `https://maps.google.com/?q=${+selectedTicket?.data.newLocation.lat}, ${+selectedTicket?.data.newLocation.lng}`
                    )
                  }
                />
              </span>
            </span>
          </span>
          <span className="flex w-full">
            <span className="w-1/2 break-all mr-8">{oldLocAddress}</span>
            <span className="w-1/2 break-all ml-8">{newLocAddress}</span>
          </span>
        </div>
      </div>
      <section>
        <div className="float-right -mb-6 mt-6">
          <Button
            content="Rejected"
            className="mr-2 text-red-500 border-red-500 hover:border-red-500 hover:text-red-500 "
            onClick={() => handleUpdateTicket({ id: selectedTicket.id, status: 'rejected' })}
          />
          <Button content="Confirm" warning onClick={() => handleUpdateTicket({ id: selectedTicket.id, status: 'approved' })} />
        </div>
      </section>
    </div>
  </Modal>
);
export default TicketModal;
