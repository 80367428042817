import { Coupon } from '@calo/dashboard-types';
import { useFormik } from 'formik';
import { omit } from 'lodash-es';

export default (coupon: Coupon, onSubmit: (values: Omit<Coupon, 'id'>) => any) =>
  useFormik<Omit<Coupon, 'id'>>({
    initialValues: {
      ...(omit(coupon, ['id', 'updatedAt', 'createdAt']) as Coupon)
    },
    validate: (_: Omit<Coupon, 'id'>) => {
      const errors: any = {};

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
