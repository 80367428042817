import { createCustomFoodReq, UpdateDeliveryFoodComponents } from '@calo/dashboard-types';
import { Localized } from '@calo/types';
import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';

export const updateCustomFood = async (
  payload: UpdateDeliveryFoodComponents & { deliveryId: string; foodId: string; description?: Localized; metadata?: string }
) => {
  const { deliveryId, foodId, ...rest } = payload;
  const { data, status } = await client.put(`/deliveries/${deliveryId}/food/${foodId}/components`, {
    components: rest.components,
    name: rest.name,
    componentsWithQuantity: rest.componentsWithQuantity,
    ...(rest.description && { description: rest.description }),
    ...(rest.metadata && { metadata: rest.metadata })
  });
  ToastSuccessMsgs({ status, action: 'Custom meal updated' });
  return data;
};

export const createCustomFood = async (payload: createCustomFoodReq & { deliveryId: string }) => {
  const { deliveryId, ...rest } = payload;
  const { data, status } = await client.post('food/custom', {
    subscriptionId: rest.subscriptionId,
    deliveryId: deliveryId,
    name: rest.name,
    foodComponentIds: rest.foodComponentIds,
    category: rest.category,
    ...(rest.type && { type: rest.type }),
    ...(rest.components && { components: rest.components }),
    ...(rest.description && { description: rest.description }),
    ...(rest.metadata && { metadata: rest.metadata })
  });
  ToastSuccessMsgs({ status, action: 'Custom meal created' });
  return data;
};
