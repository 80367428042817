import { DriverStatus, Permission } from '@calo/dashboard-types';
import { Country, Kitchen } from '@calo/types';
import { createDriver, getListWithParams } from 'actions';
import mutation from 'actions/mutation';
import cx from 'classnames';
import { Button, CaloLoader, Icon, Input, Modal, ModalRef, Select } from 'components';
import client from 'lib/client';
import {
  generatePassword,
  getAccessibleCountries,
  getKitchenOptions,
  handleValidPhoneNumber,
  isValidEmail,
  selectCountry
} from 'lib/helpers';
import history from 'lib/history';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import queryClient from 'lib/queryClient';
import { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import DriversRow from './DriversRow';
import Settings from './Settings';
import useDriverForm from './useDriverForm';

const DriversList = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState<any>({
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });
  const newDriverRef = useRef<ModalRef>();
  const [selected, setSelected] = useState<boolean>(false);
  const roles = useUserRoles();
  const userKitchens: Kitchen[] = useUserKitchens();

  const { data: stats } = useQuery<any, Error, any>(
    ['stats/drivers', { country: filters.country, kitchen: filters.kitchen }],
    getListWithParams,
    {
      suspense: false
    }
  );
  const statsData = stats as { active: number; inactive: number };

  const { mutateAsync: createMutation } = useMutation(createDriver);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery<{
    data: any[];
    meta: any;
  }>(
    ['drivers', filters],
    async ({ pageParam, queryKey }) => {
      const { data } = await client.get(queryKey[0] as string, {
        params: {
          ...(pageParam && {
            cursor: pageParam
          }),
          country: filters.country,
          kitchen: filters.kitchen
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => data.meta?.cursor,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  const DriversData = useMemo(
    () =>
      (data?.pages || []).reduce<any[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [data, data?.pages[0].data]
  );

  const getFilteredData = () => {
    return filters.country ? DriversData.filter((data) => data.country === filters.country) || [] : DriversData || [];
  };

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [data, filters.country]);

  const onSubmit = async (values: any) => {
    try {
      await createMutation(values, {
        onSuccess: (data) => {
          mutation(['stats/drivers', { country: filters.country, kitchen: filters.kitchen }], {
            active: values.status === DriverStatus.active ? statsData.active + 1 : statsData.active,
            inactive: statsData.inactive
          });
          const query = queryClient.getQueryData(['drivers', filters.country, filters.kitchen]) as any;
          const index = query?.pages[0]?.data?.length;
          query?.pages[0]?.data?.splice(index, 0, data);
          mutation(['drivers', filters.country, filters.kitchen], {
            ...query,
            pages: [{ ...query?.pages, data: query?.pages[0]?.data }]
          });
        },
        onSettled: () => {
          setSelected(false);
        }
      });
    } catch (error: any) {
      if (error.message.includes('409')) {
        toast('a driver with the same email already exists', { type: 'error', autoClose: 2000 });
      } else {
        toast('error', { type: 'error', autoClose: 2000 });
      }
    }
  };

  const { values, handleBlur, setFieldValue, setValues, dirty, isValid, isSubmitting } = useDriverForm(onSubmit);

  useEffect(() => {
    if (selected) {
      newDriverRef.current?.open();
    } else {
      newDriverRef.current?.close();
      setValues({
        name: '',
        phoneNumber: '',
        email: '',
        address: '',
        password: generatePassword(),
        country: Country.BH,
        status: DriverStatus.active,
        kitchen: Kitchen.BH1
      });
    }
  }, [selected]);

  return (
    <InfiniteScroll
      dataLength={DriversData.length || 0}
      next={fetchNextPage}
      hasMore={!!hasNextPage}
      loader={isLoading}
      scrollableTarget="scrollable"
      height={isLoading ? '110%' : undefined}
    >
      <div>
        <section className="section is-title-bar">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <ul>
                  <li>
                    Drivers / {filters.country ? filters.country : 'All'} / {filters.kitchen && filters.kitchen}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {roles.includes(Permission.VIEW_DRIVER_STATS) && (
          <section className="py-6">
            <div className="flex-row">
              <div className="h-32 text-white p-4 rounded shadow mr-16 bg-green-500">
                <p className="text-2xl mb-5">Total Active Drivers</p>
                <p className="text-3xl">{statsData?.active}</p>
              </div>
              <div className="-ml-28  mt-20 -mb-16 pl-1">
                <span>
                  <Icon name="driver-user" size={28} />
                </span>
              </div>
            </div>
          </section>
        )}
        <div>
          <div className="level-right -mt-10 pb-2">
            <div className="level-item">
              <Button
                content="+Add"
                onClick={() => setSelected(!selected)}
                className="cursor-pointer bg-green-500 text-white border border-green-500 w-24 hover:text-white hover:border-green-500 hover:shadow focus:text-white focus:border-green-500"
              />
            </div>
          </div>
        </div>
        <section>
          {isLoading ? (
            <CaloLoader />
          ) : (
            <>
              <table className="w-full h-full border-separate">
                <thead>
                  <tr className="bg-black pb-32">
                    <th className="text-white rounded-l py-4 pl-5 ">Name</th>
                    <th className="text-white py-4">Phone Number</th>
                    <th className="text-white py-4">Email</th>
                    <th className="text-white py-4">Address</th>
                    <th className="text-white py-4">Country</th>
                    <th className="text-white py-4">Kitchen</th>
                    <th className="text-white py-4">Status</th>
                    <th className="text-white rounded-r py-4">Edit</th>
                  </tr>
                </thead>
                {DriversData && DriversData.length === 0 && !isLoading ? (
                  <span className="absolute w-full text-4xl mt-4 text-center font-bold text-gray-400">NO DRIVERS</span>
                ) : (
                  <tbody>
                    {getFilteredData()?.map((driver: any) => (
                      <DriversRow key={driver.id} driver={driver} country={filters.country} kitchen={filters.kitchen} />
                    ))}
                  </tbody>
                )}
                {getFilteredData().length > 25 && (
                  <tfoot>
                    <tr className="bg-black pb-32">
                      <th className="text-white rounded-l py-4 pl-5 ">Name</th>
                      <th className="text-white py-4">Phone Number</th>
                      <th className="text-white py-4">Email</th>
                      <th className="text-white py-4">Address</th>
                      <th className="text-white py-4">Country</th>
                      <th className="text-white py-4">Kitchen</th>
                      <th className="text-white py-4">Status</th>
                      <th className="text-white rounded-r py-4">Edit</th>
                    </tr>
                  </tfoot>
                )}
              </table>
            </>
          )}
          <Settings onFilter={setFilters} filters={filters} />
          {!!hasNextPage && (
            <div className="flex justify-center pb-3">
              <Button onClick={() => fetchNextPage()} content="Load more" primary loading={isFetchingNextPage} />
            </div>
          )}
        </section>
        <Modal ref={newDriverRef} onClose={() => setSelected(false)}>
          <div>
            <h3 className="text-xl text-black pb-4">Add</h3>
          </div>
          <div>
            <Input
              label="Name"
              name="name"
              value={values.name}
              onChange={(e) => setFieldValue('name', e.target.value)}
              onBlur={handleBlur}
            />
            <span>
              <Input
                label="Phone number"
                name="phoneNumber"
                value={values.phoneNumber}
                type="text"
                pattern="[0-9]*"
                onChange={(e) => setFieldValue('phoneNumber', e.target.value)}
                onBlur={handleBlur}
                placeholder="+9733XXXXXXX"
                error={!handleValidPhoneNumber(values.phoneNumber, values.country) && values.phoneNumber.length > 1}
              />
              {!handleValidPhoneNumber(values.phoneNumber, values.country) && values.phoneNumber.length > 1 && (
                <p className="text-red-400 text-xs -mt-2">Phone number should be in a valid format</p>
              )}
            </span>

            <span>
              <Input
                label="Email"
                name="email"
                value={values.email}
                onChange={(e) => setFieldValue('email', e.target.value)}
                onBlur={handleBlur}
                error={!isValidEmail(values.email) && values.email.length > 1}
              />
              {!isValidEmail(values.email) && values.email.length > 1 && (
                <p className="text-red-400 text-xs -mt-2">Email should be in a valid format</p>
              )}
            </span>

            <Select
              label="Country"
              value={values.country}
              name="country"
              onChange={(data: any) =>
                selectCountry({
                  value: data.value,
                  kitchens: userKitchens,
                  setFieldValue
                })
              }
              options={getAccessibleCountries(userKitchens).map((country) => ({
                value: country,
                label: country
              }))}
            />
            <Select
              label="Kitchen"
              value={values.kitchen}
              onChange={(data: any) => setFieldValue('kitchen', data.value)}
              options={getKitchenOptions(userKitchens, values.country)}
              isDisabled={Object.values(Kitchen).filter((r) => r.includes(values.country!)).length === 0}
            />
            <Input label="Temporary password" name="password" value={values.password} disabled />
            <Input
              label="Address"
              name="address"
              value={values.address}
              onChange={(e) => setFieldValue('address', e.target.value)}
              onBlur={handleBlur}
            />
          </div>
          <label className="text-black text-md mt-2">Duty</label>
          <div className="mt-2">
            <label
              className={cx(' cursor-pointer inline-block w-12 p-1 rounded-full', {
                'toggle-left bg-green-500': values.status === DriverStatus.active,
                'toggle-right bg-red-500': values.status === DriverStatus.inactive
              })}
            >
              <input
                type="checkbox"
                className="hidden"
                checked={values.status === DriverStatus.inactive ? false : true}
                onChange={() =>
                  setFieldValue('status', values.status === DriverStatus.inactive ? DriverStatus.active : DriverStatus.inactive)
                }
              />
              <div
                className={cx('h-5 w-5 rounded-full bg-white transition-all right-0', {
                  'ml-5': values.status === DriverStatus.active
                })}
              ></div>
            </label>
          </div>
          <div>
            <Button
              content="Add"
              onClick={() => onSubmit(values)}
              disabled={
                !dirty ||
                !isValid ||
                isSubmitting ||
                !(handleValidPhoneNumber(values.phoneNumber, values.country) && isValidEmail(values.email))
              }
              loading={isSubmitting}
              primary
              className={cx(
                'cursor-pointer text-white bg-green-500 w-32 float-right mt-2 border border-green-500 hover:text-white hover:border-green-500  focus:text-white focus:border-green-500',
                {}
              )}
            />
          </div>
        </Modal>
      </div>
    </InfiniteScroll>
  );
};

export default DriversList;
