import { useRef, useState } from 'react';

import { addSubscriptionAddress, deleteSubscriptionAddress, updateSubscriptionAddress } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { AddressPickerMUI, Icon, ModalRef } from 'components';
import Popup from 'components/Popup';
import { AddressViewMode } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { isNil, omitBy } from 'lodash-es';
import { useMutation } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import { AddressService } from '@calo/services';
import { AddressType, DeliveryAddress, DeliveryInstruction, DeliveryTime, NewDeliveryAddress, Subscription } from '@calo/types';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

interface DeliveryAddressCardProps {
  subscription: Subscription;
}

const filterInstructions = (addressType: AddressType, deliveryInstructions: DeliveryInstruction[]) => {
  if (addressType === AddressType.home) {
    return deliveryInstructions.filter((instruction) => instruction !== DeliveryInstruction.LEAVE_AT_RECEPTION);
  } else {
    return deliveryInstructions.filter(
      (instruction) => ![DeliveryInstruction.LEAVE_AT_THE_DOOR, DeliveryInstruction.RING_MY_DOORBELL].includes(instruction)
    );
  }
};

const DeliveryAddressCard = ({ subscription }: DeliveryAddressCardProps) => {
  const roles = useUserRoles();
  const [selectedAddress, setSelectedAddress] = useState<Partial<DeliveryAddress> | null>();
  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutateAsync: updateAddressMutation } = useMutation(updateSubscriptionAddress);
  const { mutateAsync: addAddressMutation } = useMutation(addSubscriptionAddress);
  const { mutateAsync: deleteAddressMutation } = useMutation(deleteSubscriptionAddress);

  const addressDialogRef = useRef<ModalRef>();
  const driverNotesPopUpRef = useRef<ModalRef>();

  const deliveryPreferencesValues = Object.values(subscription.deliveryPreferences || {});
  const isAllDaysUsingSameAddress =
    deliveryPreferencesValues.length &&
    deliveryPreferencesValues.every((dp) => dp.deliveryAddressId === deliveryPreferencesValues[0].deliveryAddressId)
      ? true
      : deliveryPreferencesValues.length === 0;
  const isDeliveryAddressUsed = (id: string) => {
    if (subscription.deliveryPreferences && deliveryPreferencesValues.some((dp) => dp.deliveryAddressId === id)) {
      return true;
    }
    return false;
  };

  const handleNewAddress = async (deliveryAddress: NewDeliveryAddress) => {
    await addAddressMutation({
      ...(omitBy(deliveryAddress, isNil) as NewDeliveryAddress),
      id: subscription.id
    });

    setSelectedAddress(null);
    addressDialogRef.current?.close();
  };

  const handleUpdateAddress = async (id: string, deliveryAddress: Partial<NewDeliveryAddress>) => {
    if (deliveryAddress.type && deliveryAddress.deliveryInstructions) {
      deliveryAddress.deliveryInstructions = filterInstructions(deliveryAddress.type, deliveryAddress.deliveryInstructions);
    }

    await updateAddressMutation({
      ...(omitBy(deliveryAddress, isNil) as NewDeliveryAddress),
      id: subscription.id,
      daid: id
    });
    setSelectedAddress(null);
    addressDialogRef.current?.close();
  };

  const deleteAddress = (id: string) =>
    deleteAddressMutation({
      id: subscription.id,
      daid: id
    });

  const StyledTableCellHeader = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    }
  }));

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.root}`]: {
      '&:first-child': {
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px'
      },
      '&:last-child': {
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px'
      }
    }
  }));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          direction: 'row',
          width: 'full',
          justifyContent: 'space-between',
          margin: 2,
          padding: '12px',
          backgroundColor: caloTheme.palette.neutral50,
          borderRadius: 2
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: '19px',
            lineHeight: '23px',
            my: 'auto',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Delivery Addresses
        </Typography>
        {isEditing ? (
          <CheckIcon style={{ cursor: 'pointer', padding: 1 }} onClick={() => setIsEditing(!isEditing)} />
        ) : (
          <Icon
            onClick={() => setIsEditing(!isEditing)}
            name="edit2"
            size={6}
            style={{ cursor: 'pointer', width: '26px', height: '26px' }}
          />
        )}
      </Box>
      <Box sx={{ width: '100%', justifyContent: 'center' }}>
        <TableContainer>
          <Table sx={{ width: '97%', marginX: 'auto', mt: '4px', mb: '8px' }}>
            <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, color: 'black' }}>
              <TableRow>
                <StyledTableCellHeader style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
                  Address
                </StyledTableCellHeader>
                <StyledTableCellHeader align="right">Notes</StyledTableCellHeader>
                <StyledTableCellHeader align="right">Driver Notes</StyledTableCellHeader>
                <StyledTableCellHeader align="right"></StyledTableCellHeader>
                <StyledTableCellHeader align="right"></StyledTableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: 0 }}>
              {subscription.deliveryAddresses.map((deliveryAddress) => (
                <>
                  <TableRow
                    key={`${deliveryAddress.lat} - ${deliveryAddress.lng}`}
                    sx={{
                      borderTopWidth: '4px',
                      borderBottomWidth: '2px solid',
                      '&:first-child': { borderTopWidth: '4px', borderTopColor: 'white' },
                      backgroundColor: deliveryAddress.deletedAt
                        ? caloTheme.palette.alert50
                        : (subscription.deliveryPreferences &&
                              isAllDaysUsingSameAddress &&
                              subscription.deliveryPreferences[0]?.deliveryAddressId === deliveryAddress.id) ||
                            deliveryAddress.default
                          ? `${caloTheme.palette.primary200} !important`
                          : caloTheme.palette.white,
                      borderTopColor: 'white',
                      borderTop: '1px solid' + caloTheme.palette.neutral100,
                      borderBottomColor: caloTheme.palette.neutral100
                    }}
                  >
                    <StyledTableCell>
                      {AddressService.display(deliveryAddress)},
                      {roles.includes(Permission.VIEW_DEBUG_OPTIONS) && deliveryAddress.kitchen}
                      {deliveryAddress.deletedAt && <b> [Deleted] </b>}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {deliveryAddress.notes ? (
                        <Icon
                          name={'notes'}
                          className="cursor-pointer"
                          size={6}
                          onClick={() => {
                            setSelectedAddress(deliveryAddress);
                            setIsNoteOpen(!isNoteOpen);
                          }}
                        />
                      ) : (
                        '---'
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right" key={`${deliveryAddress.lat}`}>
                      {deliveryAddress.driverNote ? (
                        <Icon
                          name={'notes'}
                          className="cursor-pointer"
                          size={6}
                          onClick={() => {
                            setSelectedAddress(deliveryAddress);
                            driverNotesPopUpRef.current?.open();
                          }}
                        />
                      ) : (
                        '---'
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {!deliveryAddress.deletedAt && isEditing && (
                        <Icon
                          name={'edit'}
                          size={5}
                          className="cursor-pointer"
                          onClick={() => {
                            setSelectedAddress(deliveryAddress);
                            addressDialogRef.current?.open();
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {!deliveryAddress.deletedAt && isEditing && (
                        <>
                          {!(deliveryAddress.default || isDeliveryAddressUsed(deliveryAddress.id)) && (
                            <Icon
                              name={'trash'}
                              size={5}
                              className="cursor-pointer"
                              onClick={() => deleteAddress(deliveryAddress.id)}
                            />
                          )}
                        </>
                      )}
                    </StyledTableCell>
                  </TableRow>
                  {isNoteOpen && selectedAddress?.id === deliveryAddress.id && (
                    <TableRow key={deliveryAddress.id}>
                      <StyledTableCell
                        colSpan={6}
                        sx={{
                          familyFont: caloTheme.typography.fontFamily,
                          fontWeight: 400,
                          fontSize: '12px',
                          lineHeight: '14px',
                          color: caloTheme.palette.neutral900,
                          backgroundColor: caloTheme.palette.secondaryBlue50
                        }}
                      >
                        {selectedAddress?.notes}
                      </StyledTableCell>
                    </TableRow>
                  )}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {roles.includes(Permission.CREATE_SUBSCRIPTION_ADDRESS) && (
          <Box sx={{ marginY: 1, textAlign: 'center' }}>
            <Button
              variant="outlined"
              aria-label="add-address-button"
              sx={{
                margin: '4px',
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                borderRadius: '8px',
                padding: '12px 20px 12px 20px',
                borderColor: caloTheme.palette.primary500,
                color: caloTheme.palette.primary500,
                '&:hover': {
                  borderColor: caloTheme.palette.primary600
                }
              }}
              startIcon={'+'}
              disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
              onClick={() => {
                setSelectedAddress({});
                addressDialogRef.current?.open();
              }}
            >
              Add Address
            </Button>
          </Box>
        )}
      </Box>

      <Popup fullWidth ref={addressDialogRef} onClose={() => addressDialogRef.current?.close()}>
        <AddressPickerMUI
          onCancel={() => setSelectedAddress(null)}
          country={subscription.country}
          time={subscription.deliveryTime || DeliveryTime.morning}
          label={selectedAddress?.id ? 'Update Address' : 'Create New Address'}
          {...(selectedAddress?.id
            ? {
                onPick: (da) => handleUpdateAddress(selectedAddress.id!, da),
                deliveryAddress: selectedAddress,
                viewMode: AddressViewMode.form
              }
            : {
                onPick: handleNewAddress,
                viewMode: AddressViewMode.map
              })}
        />
      </Popup>

      <Popup title="Driver Note" ref={driverNotesPopUpRef} onClose={() => driverNotesPopUpRef.current?.close()}>
        <Stack display={'flex'} flexDirection={'row'}>
          <Typography
            display={'flex'}
            flexDirection={'row'}
            sx={{
              lineHeight: '17px',
              fontWeight: 600,
              fontSize: '14px',
              color: caloTheme.palette.neutral900
            }}
          >
            {selectedAddress?.driverNote}
          </Typography>
        </Stack>
      </Popup>
    </>
  );
};
export default DeliveryAddressCard;
