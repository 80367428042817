import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, Card, Stack, Tooltip } from '@mui/material';
import { getWeek, getYear } from 'date-fns';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { CreateMenuReq, MenuDraft, Permission } from '@calo/dashboard-types';
import CircularProgress from '@mui/material/CircularProgress';
import { createMenu } from 'actions/menu';
import { format } from 'date-fns/fp';
import useDuplicateMenu from 'hooks/useDuplicateMenu';
import { MenuPresentationType, Routes } from 'lib/enums';
import { findStartAndEndDateForWeek, getWeekDates } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { flatten } from 'lodash-es';
import { useMutation } from 'react-query';
import { findDailyAverageCostPerMenu, findMenusWithFoodCost } from '../helpers';
import { MenuActionsCardProps } from '../types';
import CalenderDialog from './CalenderDialog';
import ConfirmDialog from './ConfirmDialog';
import DailyMenuDatePicker from './DailyMenuDatePicker';
import { generateMenuSheets } from './ExportUtils';
import WeeklyMenusDatePicker from './WeeklyMenusDatePicker';
import { styles } from './styles';

const MenuActionsCard = ({
  filters,
  history,
  menuList,
  weeklyMenuList,
  foodList,
  isDuplicated,
  setIsDuplicated,
  weeklyAverageCost,
  isLoading,
  setSelectedYear,
  isFoodListLoading,
  isWeeklyMenuChanged,
  updateWeeklyMenuList,
  ...props
}: MenuActionsCardProps) => {
  const userRoles = useUserRoles();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { mutateAsync: createMutation } = useMutation(createMenu);
  const [duplicatedWeek, setDuplicatedWeek] = useState<number | null>(null);
  const [duplicatedYear, setDuplicatedYear] = useState<number | null>(null);
  const [selectedWeek, setSelectedWeek] = useState(getWeek(new Date(), { weekStartsOn: 0 }));
  const [selectedDYear, setSelectedDYear] = useState<number>(getYear(new Date()));

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { startOfWeekDate, endOfWeekDate } = findStartAndEndDateForWeek(selectedWeek);

  const {
    duplicateWeeklyMenu,
    isLoading: loadingDuplicateMenu,
    setIsLoading
  } = useDuplicateMenu(
    props.menuPresentation === MenuPresentationType.weekly ? getWeekDates(props.selectedWeek, selectedDYear) : [],
    filters,
    updateWeeklyMenuList
  );

  const exportMenuHandler = async () => {
    if (!weeklyMenuList) {
      return;
    }
    const workbook = new ExcelJS.Workbook();
    const menusWithFoodCostObject = findMenusWithFoodCost(foodList, weeklyMenuList, filters.kitchen);
    generateMenuSheets(weeklyMenuList, foodList, filters.kitchen, weeklyAverageCost, workbook, true, menusWithFoodCostObject);

    for (const menu of weeklyMenuList) {
      const dailyAverageCost = findDailyAverageCostPerMenu(menusWithFoodCostObject, menu.id);
      generateMenuSheets([menu], foodList, filters.kitchen, dailyAverageCost, workbook);
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'weekly-menu-stats.xlsx');
  };

  const handleDuplicateClick = () => {
    setIsCalendarOpen(true);
  };

  const handleCalendarDone = (weekNumber: number, year: number) => {
    setIsCalendarOpen(false);
    setDuplicatedWeek(weekNumber);
    setDuplicatedYear(year);
    setIsConfirmOpen(true);
  };

  const handleConfirmYes = async () => {
    setIsConfirmOpen(false);
    setIsDuplicated(true);
    if (props.menuPresentation === MenuPresentationType.weekly) {
      if (duplicatedWeek !== null && duplicatedYear !== null) {
        await duplicateWeeklyMenu(duplicatedWeek, duplicatedYear);
      } else {
        return;
      }
    }
  };

  const onSaveDuplicateMenus = async ({ day, food, country, tags, brand, kitchen, draft }: CreateMenuReq) => {
    await createMutation({
      day,
      food: flatten(Object.values(food)),
      country: country,
      tags: tags,
      brand: brand,
      kitchen: kitchen,
      draft: draft as any
    });
  };

  const doesWeekHaveMenus = (weeklyMenuList ?? []).length > 0;

  const handleSaveDuplicateMenus = async () => {
    if (props.menuPresentation === MenuPresentationType.weekly && props.selectedWeek !== null && selectedDYear !== null) {
      const weekDates = getWeekDates(props.selectedWeek, selectedDYear);
      setIsLoading(true);
      try {
        for (const [i, menu] of (weeklyMenuList ?? []).entries()) {
          const newDay = format('yyyy-MM-dd')(weekDates[i % 7]);
          const createMenuReq: CreateMenuReq = {
            day: newDay,
            food: menu.food.map((food) => food.id),
            country: menu.country,
            tags: menu.tags as any,
            brand: menu.brand,
            kitchen: menu.kitchen,
            draft: menu.draft as unknown as MenuDraft
          };
          try {
            await onSaveDuplicateMenus(createMenuReq);
          } catch (error) {
            console.error(`Error saving menu for day ${newDay}:`, error);
            toast.error(`Failed to create menu for: ${newDay}`);
          }
        }
        setIsDuplicated(false);
      } catch (error) {
        console.error('Error during duplication process:', error);
      } finally {
        setIsLoading(false);
        setIsDuplicated(false);
      }
    }
  };

  return (
    <Card variant="outlined" sx={styles.cardContainer}>
      <Box display="flex" flexDirection="row" sx={styles.mainContainer}>
        <Stack display="flex" flexDirection="row" sx={styles.container}>
          {userRoles.includes(Permission.CREATE_MENU) && props.menuPresentation === MenuPresentationType.daily && (
            <Button
              variant="contained"
              data-test="menu-exact-save-button"
              sx={styles.createButton}
              onClick={() =>
                history.push(Routes.newMenu, {
                  country: filters.country,
                  brand: filters.brand,
                  kitchen: filters.kitchen
                })
              }
              disabled={isLoading || isFoodListLoading || isWeeklyMenuChanged}
            >
              + Add
            </Button>
          )}
        </Stack>
        <Stack sx={styles.dateContainer}>
          <Stack sx={styles.dateStack}>
            {props.menuPresentation === MenuPresentationType.daily && (
              <DailyMenuDatePicker menuList={menuList} selectedDay={props.selectedDay} setSelectedDay={props.setSelectedDay} />
            )}
            {props.menuPresentation === MenuPresentationType.weekly && (
              <>
                <Tooltip
                  arrow
                  placement="top"
                  title={doesWeekHaveMenus && !isDuplicated ? "Can't duplicate a week that already has menu" : ''}
                >
                  <span style={{ display: 'inline-flex', width: 'auto' }}>
                    <Button
                      variant="outlined"
                      onClick={isDuplicated ? handleSaveDuplicateMenus : handleDuplicateClick}
                      sx={[isDuplicated || loadingDuplicateMenu ? styles.saveButton : styles.downloadButton]}
                      disabled={isLoading || isFoodListLoading || (doesWeekHaveMenus && !isDuplicated) || loadingDuplicateMenu}
                    >
                      {loadingDuplicateMenu ? (
                        <CircularProgress size={24} sx={{ color: 'inherit' }} />
                      ) : isDuplicated ? (
                        'Save'
                      ) : (
                        'Duplicate'
                      )}
                    </Button>
                  </span>
                </Tooltip>
                <Button
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  onClick={exportMenuHandler}
                  disabled={isLoading || isFoodListLoading || isWeeklyMenuChanged}
                  sx={styles.downloadButton}
                >
                  Download
                </Button>
                <WeeklyMenusDatePicker
                  filters={filters}
                  selectedWeek={props.selectedWeek}
                  setSelectedYear={(r) => {
                    setSelectedDYear(r);
                    setSelectedYear(r);
                  }}
                  setSelectedWeek={props.setSelectedWeek}
                  isWeeklyMenuChanged={isWeeklyMenuChanged}
                />
              </>
            )}
          </Stack>
        </Stack>
      </Box>

      <CalenderDialog
        isOpen={isCalendarOpen}
        onDone={handleCalendarDone}
        selectedDate={selectedDate}
        selectedWeek={selectedWeek}
        setSelectedDate={setSelectedDate}
        setSelectedWeek={setSelectedWeek}
        onClose={() => setIsCalendarOpen(false)}
        isWeeklyMenuChanged={isWeeklyMenuChanged}
      />

      <ConfirmDialog
        isOpen={isConfirmOpen}
        onConfirm={handleConfirmYes}
        onClose={() => setIsConfirmOpen(false)}
        selectedWeek={duplicatedWeek || selectedWeek}
        endWeek={format('yyyy-MM-dd')(endOfWeekDate)}
        startWeek={format('yyyy-MM-dd')(startOfWeekDate)}
      />
    </Card>
  );
};

export default MenuActionsCard;
