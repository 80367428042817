import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { SupplyChainUser } from 'lib/interfaces';
import { Link } from 'react-router-dom';

interface SupplyChainUserRowProps {
  user: SupplyChainUser;
}

const SupplyChainUserRow = ({ user }: SupplyChainUserRowProps) => {
  const roles = useUserRoles();
  return (
    <tr>
      <td>
        {roles.includes(Permission.VIEW_SUPPLY_CHAIN_USER) ? (
          <Link to={Routes.supplyChainUser.replace(':id', user.id)}>{user.name}</Link>
        ) : (
          `${user.name}`
        )}
      </td>
      <td>
        <p>{user.phoneNumber}</p>
      </td>
      <td>
        <p>{user.email}</p>
      </td>
      <td>
        <p>{user.country}</p>
      </td>
      <td>
        <p>{user.kitchen.join(', ')}</p>
      </td>
    </tr>
  );
};

export default SupplyChainUserRow;
