import { Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { useFormik } from 'formik';

export default (subscription: Subscription, onSubmit: (values: UpdateSubscriptionReq) => any) =>
  useFormik<UpdateSubscriptionReq>({
    enableReinitialize: true,
    initialValues: {
      macrosData: subscription.macrosData
    },

    validate: (_: UpdateSubscriptionReq) => {
      const errors: any = {};

      // if (!values.name) {
      //   errors.name = true;
      // }

      // const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, 'BH');

      // if (!phoneNumber || (phoneNumber && !phoneNumber.isValid())) {
      //   errors.phoneNumber = true;
      // }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
