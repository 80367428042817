import { MapFilters, Permission } from '@calo/dashboard-types';
import { Country, DeliveryTime, Kitchen } from '@calo/types';
import { Box, Button, Stack, Typography } from '@mui/material';
import { getListWithParams, setUsers, toggleUISettings } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import Icon from 'components/Icon';
import { format, parseISO } from 'date-fns/fp';
import client from 'lib/client';
import { Routes } from 'lib/enums';
import { getAccessibleCountries, getCountryFromLocalStorage } from 'lib/helpers';
import { getKitchenOptions } from 'lib/helpers/kitchenUtils';
import history from 'lib/history';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { Delivery, DeliveryPlan } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import { startCase } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import KitchenMapView from './KitchenMapView/KitchenMapView';
import MapView from './MapView';
import Settings from './Settings';
import styles from './styles';

const DeliveryPlanner = () => {
  const [day, setDay] = useState<string>(format('yyyy-MM-dd')(Date.now()));
  const [kitchenMap, setKitchenMap] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const dispatch = useDispatch();

  const roles = useUserRoles();
  const location = useLocation();
  const userKitchens: Kitchen[] = useUserKitchens();
  const searchParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState<MapFilters>({
    deliveryTime: DeliveryTime.morning,
    country: getCountryFromLocalStorage() || getAccessibleCountries(userKitchens)[0],
    kitchen: userKitchens && getKitchenOptions(userKitchens, getAccessibleCountries(userKitchens)[0])[0].value,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  useQuery(['/map/drivers', { country: filters.country }], getListWithParams, {
    retry: false,
    onError: console.error,
    onSuccess(data: any) {
      if (data && data.data) {
        dispatch(setUsers(data.data));
      }
    }
  });

  useEffect(() => {
    if (filters.kitchen) {
      history.push(Routes.deliveryPlanner.replace(':kitchen', filters.kitchen));
    }
  }, [filters.kitchen]);

  useEffect(() => {
    setDelivereis([]);
    setIsDataLoading(true);
  }, [filters, day]);

  const requestFilters = useMemo(
    () => ({
      ...filters,
      day
    }),
    [filters, day]
  );

  const [deliveries, setDelivereis] = useState<Delivery[]>([]);

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<{ data: any[]; meta: any }>(
    [`${filters.country}/map/${requestFilters.day}/deliveries`, requestFilters],
    async ({ pageParam, queryKey }) => {
      const { data } = await client.get(queryKey[0] as string, {
        params: {
          page: pageParam || 0,
          limit: 200,
          filters: queryKey[1]
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => data.meta?.nextPage
    }
  );

  const rawData = useMemo(
    () =>
      (data?.pages || []).reduce<Delivery[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [data, data?.pages[0].data]
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    } else {
      setIsDataLoading(false);
    }
  }, [data]);

  useEffect(() => {
    for (const row of rawData || []) {
      queryClient.setQueryData(['deliveries', row.id], row);
    }
    setDelivereis(rawData);
  }, [rawData]);

  const [deliveryPlans, setDeliveryPlans] = useState<DeliveryPlan[]>([]);

  useQuery<any, Error, { data: DeliveryPlan[] }>(
    [
      `${filters.country}/route-plans`,
      {
        day: { gte: requestFilters.day, lte: requestFilters.day },
        time: filters.deliveryTime,
        country: filters.country,
        kitchen: filters.kitchen
      }
    ],
    getListWithParams,
    {
      onSuccess: (data) => {
        if (data.data) {
          for (const row of data.data || []) {
            queryClient.setQueryData(['routes', row.id], row);
          }
          setDeliveryPlans(data.data);
        }
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  const updateDeliveries = (id: string, canceled: boolean) => {
    const data = queryClient.getQueryData(['deliveries', id]) as Delivery;
    const i = deliveries.findIndex((e) => e.id === id);
    const newData = [...deliveries];
    if (canceled) {
      newData.splice(i, 1);
    } else {
      newData[i] = data;
    }
    setDelivereis(newData);
  };

  return (
    <>
      <div className="bg-white">
        {isDataLoading && (
          <div className="flex bg-red-500 w-full items-center justify-center p-3 rounded">
            <p className="font-bold text-2xl text-white">Data is loading. Please wait</p>
          </div>
        )}
        <Box sx={styles.mainBox}>
          <Stack flexDirection={'row'} sx={{ paddingY: 2, my: 'auto' }}>
            <Typography sx={styles.titleText}>{kitchenMap ? 'Kitchen Zones' : 'Delivery Planner'}</Typography>
            <Typography sx={styles.countryKitchenText}>{`(${requestFilters.country}/${requestFilters.kitchen})`}</Typography>
            {!kitchenMap && (
              <Typography sx={styles.dateText}>
                {`${format('dd/MM/yyyy')(parseISO(requestFilters.day))} - ${startCase(requestFilters.deliveryTime)}`}
              </Typography>
            )}
          </Stack>
          <Stack sx={styles.mainButtonStack}>
            <Stack sx={styles.buttonStack}>
              <Button
                variant={'contained'}
                sx={[
                  styles.plannerButton,
                  {
                    zIndex: kitchenMap ? 0 : 1,
                    color: kitchenMap ? caloTheme.palette.neutral600 : caloTheme.palette.white,
                    backgroundColor: kitchenMap ? caloTheme.palette.neutral50 : caloTheme.palette.primary500
                  }
                ]}
                disabled={!roles.includes(Permission.UPDATE_KITCHEN)}
                onClick={() => (kitchenMap ? setKitchenMap(!kitchenMap) : null)}
              >
                Delivery Planner
              </Button>
              <Button
                variant={'contained'}
                sx={[
                  styles.zoneButton,
                  {
                    zIndex: kitchenMap ? 1 : 0,
                    color: kitchenMap ? caloTheme.palette.white : caloTheme.palette.neutral600,
                    backgroundColor: kitchenMap ? caloTheme.palette.primary500 : caloTheme.palette.neutral50
                  }
                ]}
                disabled={!roles.includes(Permission.UPDATE_KITCHEN)}
                onClick={() => (kitchenMap ? null : setKitchenMap(!kitchenMap))}
              >
                Kitchen Zones
              </Button>
            </Stack>
            <Stack justifyContent={'end'} sx={{ cursor: 'pointer', mr: '-12px' }}>
              <Button
                aria-label="filter-subscription-list"
                sx={{
                  cursor: 'pointer',
                  my: 'auto',
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    justifyItems: 'end',
                    width: '50%'
                  }
                }}
                onClick={() => dispatch(toggleUISettings())}
              >
                {<Icon name="filter" size={6} className="w-12 h-18" />}
              </Button>
            </Stack>
          </Stack>
        </Box>
        <Settings day={day} setDay={setDay} filters={filters} onFilter={setFilters} kitchenMap={kitchenMap} />
      </div>
      {roles.includes(Permission.VIEW_MAP) && (
        <div className="flex flex-1 mt-0">
          {kitchenMap ? (
            <KitchenMapView
              day={requestFilters.day}
              time={filters.deliveryTime!}
              country={filters.country as Country}
              kitchen={filters.kitchen as Kitchen}
            />
          ) : (
            <MapView
              day={requestFilters.day}
              deliveries={deliveries}
              deliveryPlans={deliveryPlans}
              time={filters.deliveryTime!}
              updateDeliveries={updateDeliveries}
              country={filters.country as Country}
              kitchen={filters.kitchen as Kitchen}
            />
          )}
        </div>
      )}
    </>
  );
};

export default DeliveryPlanner;
