import { ReactNode } from 'react';

import cx from 'classnames';

interface TagProps {
  children: ReactNode;
  className?: string;
  green?: boolean;
  blue?: boolean;
  blueBerry?: boolean;
  purple?: boolean;
  red?: boolean;
  orange?: boolean;
  yellow?: boolean;
  lightGreen?: boolean;
  style?: any;
  black?: boolean;
}

const Tag = ({
  children,
  className,
  blueBerry,
  lightGreen,
  green,
  blue,
  purple,
  red,
  yellow,
  orange,
  style,
  black
}: TagProps) => (
  <div
    className={cx(
      'border py-1 px-2 text-sm text-white rounded-md capitalize',
      {
        'bg-cGreen': green,
        'bg-blue-500': blue,
        'bg-purple-500': purple,
        'bg-red-500': red,
        'bg-blueBerry': blueBerry,
        'bg-yellow-500': yellow,
        'bg-green-300': lightGreen,
        'bg-yellow-600': orange,
        'bg-black': black
      },
      className
    )}
    style={style}
  >
    {children}
  </div>
);
export default Tag;
