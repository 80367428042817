import { Country, Kitchen } from '@calo/types';

export const getKitchenShortCut = (kitchen: Kitchen | string) => {
  if (!kitchen.includes('000')) {
    return kitchen.replaceAll('0', '');
  }
};

export const getKitchenOptions = (userKitchens: string[], country: string) => {
  const kitchenOptions = userKitchens
    .filter((r) => r.includes(country || Country.BH))
    .map((c) => ({
      value: c,
      label: c
    }));
  if (kitchenOptions.length > 1) {
    kitchenOptions.unshift({ label: 'Any', value: '' });
  }
  return kitchenOptions;
};

export const getCenteredKitchen = (kitchen: Kitchen) => {
  switch (kitchen) {
    case Kitchen.BH1:
      return { lat: 26.222917, lng: 50.580958 };
    case Kitchen.SA1:
      return { lat: 24.7136, lng: 46.6753 };
    case Kitchen.SA2:
      return { lat: 21.552549, lng: 39.195173 };
    case Kitchen.KW1:
      return { lat: 29.3117, lng: 47.4818 };
    case Kitchen.QA1:
      return { lat: 25.3548, lng: 51.1839 };
    case Kitchen.AE1:
      return { lat: 25.2048, lng: 55.2708 };
    case Kitchen.OM1:
      return { lat: 23.5710244, lng: 58.1976688 };
    case Kitchen.GB1:
      return { lat: 51.5074, lng: -0.13 };
    default:
      return { lat: 26.222917, lng: 50.580958 };
  }
};

export const formatKitchenText = (kitchen: Kitchen | undefined) => {
  return kitchen ? kitchen.slice(0, 2) + ' ' + kitchen.slice(2) : '';
};
