import { Permission, UpdateKitchenReq } from '@calo/dashboard-types';
import { Country } from '@calo/types';
import { toggleUIKitchenSettings } from 'actions';
import { Button, Input, Select, SideDrawer } from 'components';
import { FormikBag, FormikProps, withFormik } from 'formik';
import { AppState, Kitchen } from 'lib/interfaces';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface KitchenFormProps {
  kitchen: Kitchen;
  onSubmit: (values: UpdateKitchenReq) => void;
  roles: string[];
}

const KitchenForm = ({
  isSubmitting,
  dirty,
  isValid,
  handleSubmit,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  roles,
  kitchen
}: KitchenFormProps & FormikProps<UpdateKitchenReq>) => {
  const [newEmail, setNewEmail] = useState('');
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.kitchenSettings
  }));

  const isPreLaunchKitchen = (kitchen?.id || '').slice(2, 5) === '000';

  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUIKitchenSettings())}>
      <section className="section">
        <span className="flex pb-8 pt-2">
          <p className="text-black py-4 text-xl">Current capacity: {values.capacity}</p>
        </span>
        <form onSubmit={handleSubmit}>
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.capacity}
            type="number"
            min={1}
            name="capacity"
            label="Capacity"
            disabled={!roles.includes(Permission.UPDATE_KITCHEN) || isPreLaunchKitchen}
          />
          {roles.includes(Permission.UPDATE_KITCHEN) && (
            <>
              <div>
                <Select
                  label="Country"
                  value={values.country}
                  name="country"
                  options={Object.values(Country).map((c) => ({ value: Country[c], label: c }))}
                  onChange={(data: any) => setFieldValue('country', data.value)}
                />
                <Input
                  value={newEmail}
                  placeholder="Enter email"
                  label="Email"
                  name="emails"
                  onChange={(data) => setNewEmail(data.target.value)}
                />
                <Button
                  disabled={!newEmail}
                  primary
                  content="Add email"
                  className="mt-4 mx-4 float-right"
                  onClick={() => {
                    setFieldValue('emails', values.emails ? [...values.emails, newEmail] : [newEmail]);
                    setNewEmail('');
                  }}
                />
              </div>
              <div className="mt-24">
                <ul className="mr-5">
                  {values.emails &&
                    values.emails.map((email, index) => (
                      <li key={index} className="flex-row items-center justify-between bg-gray-200 rounded p-1 mb-1 h-7">
                        <p>{email}</p>
                        <i
                          className="fas fa-trash mr-4 last:mr-0"
                          onClick={() =>
                            setFieldValue('emails', values.emails ? values.emails.filter((e) => e !== email) : undefined)
                          }
                        />
                      </li>
                    ))}
                </ul>
              </div>
              <div className="mt-8 float-right mx-5">
                <Button
                  type="submit"
                  primary
                  loading={isSubmitting}
                  disabled={!dirty || !isValid || isSubmitting}
                  content="Save"
                />
              </div>
            </>
          )}
        </form>
      </section>
    </SideDrawer>
  );
};

export default withFormik({
  mapPropsToValues: ({ kitchen }: KitchenFormProps) => ({
    capacity: kitchen.capacity,
    country: kitchen.country,
    emails: kitchen.emails,
    bounds: kitchen.bounds
  }),
  // @ts-ignore // types issue`
  handleSubmit: async (values, { setSubmitting, props }: FormikBag<KitchenFormProps, UpdateKitchenReq>) => {
    try {
      console.log(values);
      await props.onSubmit(values);
    } catch {
      setSubmitting(false);
    }
  }
  // @ts-ignore // types issue`
})(KitchenForm);
