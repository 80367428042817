import { format, parseISO } from 'date-fns/fp';
import { startCase } from 'lodash-es';

import { Card, CardContent, CardHeader, Stack, styled, Typography, typographyClasses } from '@mui/material';

import { PaymentTransaction, TransactionHistorySource } from '@calo/types';

import { caloTheme } from 'assets/images/theme/calo';

interface TransactionCardRowMobileProps {
  row: PaymentTransaction;
}

const TransactionHistorySourceMap = {
  [TransactionHistorySource.PAYMENT]: 'Payment',
  [TransactionHistorySource.REFUND]: 'Refund',
  [TransactionHistorySource.MANUAL_ADDITION]: 'Manual Addition',
  [TransactionHistorySource.MANUAL_DEDUCTION]: 'Manual Deduction'
};

const TransactionCardRowMobile = ({ row }: TransactionCardRowMobileProps) => {
  const StyledTypo = styled(Typography)(() => ({
    [`&.${typographyClasses.root}`]: {
      px: 4,
      fontWeight: 400,
      fontSize: '12px',
      fontHeight: '14px',
      variant: 'caption',
      fontFamily: caloTheme.typography.fontFamily
    }
  }));
  return (
    <Card key={row.id} sx={{ width: 'full', mb: 2 }}>
      <CardHeader
        title={
          <Stack sx={{ width: 'full', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
            <StyledTypo>{row.vat ? row.amount - row.vat : row.amount}</StyledTypo>

            <StyledTypo>{row.vat || 0} VAT</StyledTypo>

            <StyledTypo>{format('Pp')(parseISO(row.createdAt))}</StyledTypo>
          </Stack>
        }
      />
      <CardContent>
        <Stack sx={{ color: caloTheme.palette.neutral900, my: 1, width: 'full', display: 'flex', flexDirection: 'column' }}>
          <StyledTypo sx={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
            Source:
            <StyledTypo sx={{ textAlign: 'right', width: '100%' }}>{TransactionHistorySourceMap[row.source] || '---'}</StyledTypo>
          </StyledTypo>

          <StyledTypo sx={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
            Action:
            <StyledTypo sx={{ textAlign: 'right', width: '100%', textTransform: 'capitalize' }}>
              {startCase(row.action) || '---'}
            </StyledTypo>
          </StyledTypo>

          {row.notes && (
            <StyledTypo sx={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
              Note:
              <StyledTypo sx={{ textAlign: 'right', width: '100%' }}>{startCase(row.notes || '---')}</StyledTypo>
            </StyledTypo>
          )}

          <StyledTypo sx={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
            Action:
            <StyledTypo sx={{ textAlign: 'right', width: '100%', textTransform: 'capitalize' }}>
              {startCase(row.paymentProvider) || '---'}
            </StyledTypo>
          </StyledTypo>

          <StyledTypo sx={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
            Status:
            <StyledTypo sx={{ textAlign: 'right', width: '100%', textTransform: 'capitalize' }}>
              {startCase(row.status) || '---'}
            </StyledTypo>
          </StyledTypo>

          <StyledTypo sx={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
            Balance:
            <StyledTypo sx={{ textAlign: 'right', width: '100%', textTransform: 'capitalize' }}>
              {row.balance || '---'}
            </StyledTypo>
          </StyledTypo>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TransactionCardRowMobile;
