import { FoodType, Kitchen } from '@calo/types';
import { MenuCategory, MenuPopulateType } from 'lib/enums';
import { handleMealCost } from 'lib/helpers';
import { Food, Menu, MenuFood } from 'lib/interfaces';
import { chain, sortBy, uniqBy } from 'lodash';

interface CategoryConfig {
  foodTypes: FoodType[];
  tag?: string;
  operationType: 'noFoodTags' | 'rotational' | 'fixed';
  categoryName: MenuCategory;
}

export interface MenusWithFoodCost {
  [date: string]: { [name: string]: number };
}

const categoryConfigs: CategoryConfig[] = [
  {
    foodTypes: [FoodType.breakfast],
    tag: 'balanced',
    operationType: 'rotational',
    categoryName: MenuCategory.breakfastBalancedRotational
  },
  { foodTypes: [FoodType.breakfast], tag: 'balanced', operationType: 'fixed', categoryName: MenuCategory.breakfastBalancedFixed },
  {
    foodTypes: [FoodType.breakfast],
    tag: 'low-carb',
    operationType: 'rotational',
    categoryName: MenuCategory.breakfastLowCarbRotational
  },
  { foodTypes: [FoodType.breakfast], tag: 'low-carb', operationType: 'fixed', categoryName: MenuCategory.breakfastLowCarbFixed },
  {
    foodTypes: [FoodType.breakfast],
    tag: 'high-protein',
    operationType: 'noFoodTags',
    categoryName: MenuCategory.breakfastHighProtein
  },
  {
    foodTypes: [FoodType.breakfast],
    tag: 'vegetarian',
    operationType: 'noFoodTags',
    categoryName: MenuCategory.breakfastVegetarian
  },
  {
    foodTypes: [FoodType.breakfast],
    tag: 'weight-loss',
    operationType: 'noFoodTags',
    categoryName: MenuCategory.breakfastWeightLoss
  },
  { foodTypes: [FoodType.breakfast], tag: 'gourmet', operationType: 'noFoodTags', categoryName: MenuCategory.breakfastGourmet },

  {
    foodTypes: [FoodType.lunch, FoodType.dinner],
    tag: 'balanced',
    operationType: 'rotational',
    categoryName: MenuCategory.lunchAndDinnerBalancedRotational
  },
  {
    foodTypes: [FoodType.lunch, FoodType.dinner],
    tag: 'balanced',
    operationType: 'fixed',
    categoryName: MenuCategory.lunchAndDinnerBalancedFixed
  },
  {
    foodTypes: [FoodType.lunch, FoodType.dinner],
    tag: 'low-carb',
    operationType: 'rotational',
    categoryName: MenuCategory.lunchAndDinnerLowCarbRotational
  },
  {
    foodTypes: [FoodType.lunch, FoodType.dinner],
    tag: 'low-carb',
    operationType: 'fixed',
    categoryName: MenuCategory.lunchAndDinnerLowCarbFixed
  },
  {
    foodTypes: [FoodType.lunch, FoodType.dinner],
    tag: 'high-protein',
    operationType: 'noFoodTags',
    categoryName: MenuCategory.lunchAndDinnerHighProtein
  },
  {
    foodTypes: [FoodType.lunch, FoodType.dinner],
    tag: 'vegetarian',
    operationType: 'noFoodTags',
    categoryName: MenuCategory.lunchAndDinnerVegetarian
  },
  {
    foodTypes: [FoodType.lunch, FoodType.dinner],
    tag: 'gourmet',
    operationType: 'noFoodTags',
    categoryName: MenuCategory.lunchAndDinnerGourmet
  },
  {
    foodTypes: [FoodType.lunch, FoodType.dinner],
    tag: 'weight-loss',
    operationType: 'noFoodTags',
    categoryName: MenuCategory.lunchAndDinnerWeightLoss
  },

  {
    foodTypes: [FoodType.snack],
    tag: 'balanced',
    operationType: 'rotational',
    categoryName: MenuCategory.snackBalancedRotational
  },
  { foodTypes: [FoodType.snack], tag: 'balanced', operationType: 'fixed', categoryName: MenuCategory.snackBalancedFixed },
  {
    foodTypes: [FoodType.snack],
    tag: 'low-carb',
    operationType: 'rotational',
    categoryName: MenuCategory.snackLowCarbRotational
  },
  { foodTypes: [FoodType.snack], tag: 'low-carb', operationType: 'fixed', categoryName: MenuCategory.snackLowCarbFixed },
  { foodTypes: [FoodType.snack], tag: 'high-protein', operationType: 'noFoodTags', categoryName: MenuCategory.snackHighProtein },
  { foodTypes: [FoodType.snack], tag: 'gourmet', operationType: 'noFoodTags', categoryName: MenuCategory.snackGourmet },

  { foodTypes: [FoodType.caloKids], operationType: 'noFoodTags', categoryName: MenuCategory.caloKids },
  { foodTypes: [FoodType.dessert], operationType: 'noFoodTags', categoryName: MenuCategory.dessert },
  { foodTypes: [FoodType.salad], operationType: 'noFoodTags', categoryName: MenuCategory.salad }
];

export const filterMenus = (weeklyMenuList: Menu[] | undefined) =>
  categoryConfigs.map((config) => ({
    category: config.categoryName,
    filteredMenuList: filterMenusByCategory(weeklyMenuList, config)
  }));

const filterMenusByCategory = (weeklyMenuList: Menu[] | undefined, categoryConfig: CategoryConfig) => {
  const { operationType, foodTypes, tag } = categoryConfig;

  return (weeklyMenuList || []).map((menu) => {
    switch (operationType) {
      case 'rotational':
        return {
          ...menu,
          food: uniqBy(menu.food, 'name.en').filter(
            (food) =>
              foodTypes.some((type) => food.type.includes(type)) && !isFoodTopRated(food) && (!tag || food.tags.includes(tag))
          )
        };
      case 'fixed':
        return {
          ...menu,
          food: uniqBy(menu.food, 'name.en').filter(
            (food) =>
              foodTypes.some((type) => food.type.includes(type)) && isFoodTopRated(food) && (!tag || food.tags.includes(tag))
          )
        };
      case 'noFoodTags':
        return {
          ...menu,
          food: uniqBy(menu.food, 'name.en').filter(
            (food) => foodTypes.some((type) => food.type.includes(type)) && (!tag || food.tags.includes(tag))
          )
        };
      default:
        return menu;
    }
  });
};

const isFoodTopRated = (food: MenuFood) => {
  return food.foodTags?.some((foodTag) => foodTag.key === 'rating' && foodTag.value.includes('top' as any));
};

export const resolveFoodTypeFromCategory = (category: MenuCategory) => {
  if (category.includes('Breakfast')) {
    return MenuPopulateType.breakfast;
  } else if (category.includes('Lunch')) {
    return MenuPopulateType.lunchAndDinner;
  } else if (category.includes('Calo Kids')) {
    return MenuPopulateType.caloKids;
  } else if (category.includes('Dessert')) {
    return MenuPopulateType.dessert;
  } else if (category.includes('Salad')) {
    return MenuPopulateType.salad;
  } else {
    return MenuPopulateType.snacks;
  }
};

export const findMenusWithFoodCost = (foodList: Food[], weeklyMenuList: Menu[] | undefined, kitchen: Kitchen) => {
  const menusWithFoodCost: MenusWithFoodCost = {};

  for (const menu of weeklyMenuList || []) {
    const foodWithCost: { [foodName: string]: number } = {};
    const mappedFoodList: Food[] = [];
    for (const foodItem of menu.food) {
      const existingFood = foodList.find((food) => food.id === foodItem.id);
      if (existingFood) {
        mappedFoodList.push(existingFood);
      }
    }

    const uniqFoodList = uniqBy(mappedFoodList, 'name.en');
    const sortedFoodList = sortBy(mappedFoodList, (food) => `${food.name.en}-${food.size}`);

    for (const food of uniqFoodList) {
      try {
        const foodCost = handleMealCost(food.name.en || '', sortedFoodList as any, kitchen);
        foodWithCost[food.name.en] = foodCost;
      } catch (error) {
        console.log('error', error);
      }
    }

    if (Object.keys(foodWithCost).length > 0) {
      menusWithFoodCost[menu.id] = foodWithCost;
    }
  }
  return menusWithFoodCost;
};

export const findOccurrencesOfSameFood = (filteredMenuList: Menu[]) => {
  return chain(filteredMenuList).flatMap('food').countBy('name.en').value();
};

export const findDailyAverageCostPerMenu = (menusWithFoodCostObject: MenusWithFoodCost, menuDay: string) => {
  const allValues = Object.values(menusWithFoodCostObject[menuDay] || {});
  const sum = allValues.reduce((accum, currentValue) => accum + currentValue, 0);
  return sum / allValues.length;
};
