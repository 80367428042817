import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Card, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import ComponentCostInfo from 'components/ComponentCostInfo';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { format } from 'date-fns';
import { FormikErrors } from 'formik';
import { FormOperation } from 'lib/enums';
import { IngredientFormData, IngredientOperationValues } from 'lib/interfaces';
import { round } from 'lodash-es';
import { useState } from 'react';

type CostCardProps = {
  disabled?: boolean;
  values: IngredientFormData;
  errors: FormikErrors<IngredientFormData>;
  handleChange: { (e: React.ChangeEvent<any>): void };
} & IngredientOperationValues;

const calculateCostCalculation = (order, ingCost, supplyWeight = 1) => {
  if (!order) return '';
  const totalQuantity = order.totalQuantity ?? 1;
  const itemReceived = totalQuantity / (order.conversionFactor ?? 1);
  const avgPrice = order.isBakedAndPieces ? ingCost * supplyWeight : ingCost * 1000 * supplyWeight;
  const totalValue = round(avgPrice * totalQuantity, 3);
  const response = `This is the cost per unit of this ingredient (${order.convertedUnit}). It is fetched from Precoro directly and converted using the following calculations:
      ,Total Quantity Calculation:, totalQuantity = itemReceived * conversion Factor, 
      totalQuantity = ${itemReceived} * ${order.conversionFactor ?? 1} = ${totalQuantity},-,
      Average Price Calculation:, averagePrice = totalValue / total quantity, 
      averagePrice = ${totalValue} / ${totalQuantity} = ${avgPrice.toFixed(4)},-,
      Cost Calculation:, if the purchase category is baked and pieces we keep the cost as the average price; if not we divide it by 1000.,
      cost = ${order.isBakedAndPieces ? avgPrice.toFixed(6) : (avgPrice / 1000).toFixed(6)}${supplyWeight === 1 ? '' : ','}
      ${
        supplyWeight === 1
          ? ''
          : `This ingredient comes in pieces from precoro but on dashboard the measurement unit is g so we need to divide by the weight of the piece.,
      final cost = ${order.isBakedAndPieces ? avgPrice.toFixed(6) : (avgPrice / 1000).toFixed(6)} / ${supplyWeight} = ${ingCost}`
      }
      `;
  return response;
};

const CostCard = ({ values, errors, disabled, handleChange, ...props }: CostCardProps) => {
  const [showCostInfo, setShowCostInfo] = useState(false);

  const costInfoText = () => {
    if (props.operation === FormOperation.update) {
      const metadata = props.ingredient.metadata;
      const supplyWeight = metadata ? JSON.parse(metadata)?.supplyWeight : 1;
      return calculateCostCalculation(props.ingredient.order, props.ingredient.cost, supplyWeight || 1);
    }
    return '';
  };

  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: '16px',
        border: 'none',
        borderRadius: '16px',
        px: '12px',
        py: '20px'
      }}
    >
      <InputThemeProvider>
        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '12px', marginBottom: '20px' }}>
          <Typography variant="h5" sx={{ fontSize: '19px', fontWeight: 600 }}>
            Cost
          </Typography>
        </Stack>
        <TextField
          label={`Cost (per measurement unit)`}
          value={values.cost}
          name="cost"
          sx={{ width: '100%' }}
          onChange={handleChange}
          type="number"
          disabled={disabled}
          error={!!errors.cost}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowCostInfo(true)}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {showCostInfo && (
          <ComponentCostInfo
            costInfo={costInfoText()}
            marginTop="8px"
            marginBottom="0px"
            handleClose={() => {
              setShowCostInfo(false);
            }}
          />
        )}
        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginTop: '24px' }}>
          <TextField
            label="Last Updated Date (Precoro)"
            value={
              props.operation === FormOperation.update && props.ingredient.costLastUpdated
                ? format(new Date(props.ingredient.costLastUpdated), 'dd/MM/yyyy')
                : '--'
            }
            name="lastUpdatedDate"
            sx={{ width: '100%' }}
            onChange={handleChange}
            disabled
          />
          <TextField
            label="Unit"
            value={props.operation === FormOperation.update ? props.ingredient.order?.convertedUnit : '--'}
            name="unit"
            sx={{ width: '100%' }}
            onChange={handleChange}
            disabled
          />
        </Stack>
      </InputThemeProvider>
    </Card>
  );
};

export default CostCard;
