import { getListWithParams, postRequest } from 'actions';
import { format } from 'date-fns';
import { findStartAndEndDateForWeek } from 'lib/helpers';
import { Food, Menu } from 'lib/interfaces';
import { chunk } from 'lodash';
import { uniq } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { MenuListFilters } from '../types';
import { foodListSelection } from './selection';

export const useWeeklyMenu = (filters: MenuListFilters, selectedWeek: number, selectedYear: number) => {
  const [isWeeklyMenuChanged, setIsWeeklyMenuChanged] = useState<boolean>(false);
  const [originalWeeklyMenuData, setOriginalWeeklyMenuData] = useState<Menu[]>([]);
  const [chunkIndex, setChunkIndex] = useState<number>(0);
  const [foodList, setFoodList] = useState<Food[]>([]);
  const [weeklyAverageCost, setWeeklyAverageCost] = useState<number>(0);
  const [weeklyMenuList, setWeeklyMenuList] = useState<Menu[]>([]);

  const { startOfWeekDate, endOfWeekDate } = findStartAndEndDateForWeek(selectedWeek, selectedYear);

  const { data: weeklyMenu, isLoading: isWeeklyMenuLoading } = useQuery<any, Error, Menu[]>(
    [
      'menu',
      {
        brand: filters.brand,
        kitchen: filters.kitchen || undefined,
        date: { gte: format(startOfWeekDate, 'yyyy-MM-dd'), lte: format(endOfWeekDate, 'yyyy-MM-dd') }
      }
    ],
    getListWithParams,
    {
      onSuccess: (data) => {
        setWeeklyMenuList(data || []);
        if (data && originalWeeklyMenuData.length === 0) {
          setOriginalWeeklyMenuData(data);
        }
      }
    }
  );

  const foodIds = useMemo(() => {
    if (!weeklyMenu) {
      return [];
    }
    return uniq(weeklyMenu.flatMap((menu) => menu.food.map((food) => food.id)));
  }, [weeklyMenu]);

  const chunkedData = useMemo(() => chunk(foodIds, 400), [foodIds]);

  useQuery<any, Error, any>(
    [
      'food/list',
      {
        filters: { country: filters.country, brand: filters.brand, kitchen: filters.kitchen, ids: chunkedData[chunkIndex] },
        sort: { orderBy: 'name', orderMode: 'asc' },
        page: 0,
        limit: chunkedData[chunkIndex]?.length ?? 100,
        selection: foodListSelection
      }
    ],
    postRequest,
    {
      enabled: chunkedData.length > 0 && chunkIndex <= chunkedData.length - 1,
      onSuccess: (data) => {
        if (data && data.data) {
          setFoodList((prevData) => [...prevData, ...data.data]);
          if (chunkIndex < chunkedData.length - 1) {
            setChunkIndex(chunkIndex + 1);
          }
        }
      },
      onError: () => {
        toast('Finding food cost failed', { type: 'error', autoClose: 2000 });
      }
    }
  );

  useEffect(() => {
    if (isWeeklyMenuLoading) {
      setFoodList([]);
      setChunkIndex(0);
    }
  }, [isWeeklyMenuLoading]);

  const updateWeeklyMenuList = (newMenuList: Menu[]) => {
    setWeeklyMenuList(newMenuList);
  };

  return {
    weeklyMenuList,
    isWeeklyMenuLoading,
    isWeeklyMenuChanged,
    setIsWeeklyMenuChanged,
    foodList,
    foodIds,
    setWeeklyMenuList,
    originalWeeklyMenuData,
    setOriginalWeeklyMenuData,
    weeklyAverageCost,
    setWeeklyAverageCost,
    updateWeeklyMenuList
  };
};
