import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  card: {
    width: 'full',
    border: 'none',
    marginBottom: '16px',
    borderRadius: '8px',
    paddingBottom: '4px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    width: '100%'
  },
  stack: {
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  typography: {
    marginTop: 'auto',
    fontWeight: 600,
    fontSize: '23px',
    lineHeight: '28px',
    border: 'none',
    fontFamily: caloTheme.typography.fontFamily,
    '&:hover': {
      border: 'none',
      fontFamily: caloTheme.typography.fontFamily
    }
  },
  actionStack: {
    width: '50%',
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'row',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: '16px'
    }
  },
  menuButton: {
    position: 'relative',
    height: '51px',
    lineHeight: '17px',
    fontWeight: 600,
    borderRadius: '8px',
    fontSize: '18px',
    textTransform: 'none',
    boxShadow: 'none',
    borderColor: caloTheme.palette.primary500,
    color: caloTheme.palette.primary500,
    marginRight: '16px',
    '&:hover': {
      boxShadow: 'none',
      borderColor: caloTheme.palette.primary600
    }
  },
  menuActionsBox: {
    position: 'absolute',
    top: '180px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 100,
    marginLeft: '-30px',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'
  },
  menuActionButton: {
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: 600,
    width: '100%',
    justifyContent: 'start',
    color: caloTheme.palette.neutral900,
    '&:hover': {
      boxShadow: 'none',
      borderColor: caloTheme.palette.primary600
    }
  },
  saveButton: {
    width: '120px',
    height: '51px',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
    backgroundColor: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    boxShadow: 'none',
    color: 'white',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: caloTheme.palette.primary600,
      borderColor: caloTheme.palette.primary600
    }
  }
};
