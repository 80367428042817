import { useFormik } from 'formik';
import { SyncFoodComponentReq } from '@calo/dashboard-types';

export default (onSubmit: (values: SyncFoodComponentReq) => any) =>
  useFormik<SyncFoodComponentReq>({
    initialValues: {
      validate: true,
      kitchens: [],
      attributes: []
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
