import React from 'react';

import cx from 'classnames';

interface SelectOption {
  label: string;
  value: any;
}

export interface SelectProps extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  label?: string;
  options: SelectOption[];
  className?: string;
}

const Select2 = ({ label, options, className, ...rest }: SelectProps) => (
  <div className="field ">
    {label && <label className="label">{label}</label>}
    <div className="control">
      <div
        className={cx(
          'select',
          {
            'w-full': !className
          },
          className
        )}
      >
        <select {...rest} data-test={rest['data-test']} className="w-full">
          {options.map((option, i) => (
            <option data-test={`${rest['data-test']}#${i}`} key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  </div>
);

export default Select2;
