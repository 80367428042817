import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

interface MealPlanOption {
  icon: string;
  label: string;
}

interface PhoneMealPlanOptionsProps {
  options: MealPlanOption[];
  selectedPlan: string;
  handlePlanSelect: (plan: string) => void;
}

const OptionsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  height: '84px',
  userSelect: 'none'
});

const OptionItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  width: '96px',
  height: '84px',
  padding: '0 8px',
  cursor: 'pointer'
});

const IconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ isSelected: boolean }>(({ isSelected }) => ({
  backgroundColor: 'white',
  width: '56px',
  height: '56px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: isSelected ? `2px solid #24A170` : '1px solid #E0E0E0',
  marginBottom: '4px'
}));

const OptionLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ isSelected: boolean }>(({ isSelected }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  textAlign: 'center',
  fontWeight: isSelected ? 700 : 400,
  color: isSelected ? '#24A170' : '#616161',
  maxWidth: '80px'
}));

const PhoneMealPlanOptions = ({ options, selectedPlan, handlePlanSelect }: PhoneMealPlanOptionsProps) => {
  return (
    <OptionsContainer>
      {options.map((option, index) => (
        <OptionItem key={index} onClick={() => handlePlanSelect(option.label)}>
          <IconWrapper isSelected={option.label === selectedPlan}>
            <img src={option.icon} alt={option.label} width="24" height="24" />
          </IconWrapper>
          <OptionLabel isSelected={option.label === selectedPlan}>{option.label}</OptionLabel>
        </OptionItem>
      ))}
    </OptionsContainer>
  );
};

export default PhoneMealPlanOptions;
