import { FoodPrototypeAction } from '@calo/dashboard-types';
import AddIcon from '@mui/icons-material/Add';
import { Button, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import Pagination from 'components/Pagination';
import { orderBy } from 'lodash-es';
import { useState } from 'react';
import ActivityLogItem from './ActivityLogItem';

interface ActivityLogCardProps {
  type: 'meal' | 'component' | 'ingredient';
  actionLogs: FoodPrototypeAction[];
  addCommentClickHandler: () => void;
}

const ActivityLogCard = ({ type, actionLogs, addCommentClickHandler }: ActivityLogCardProps) => {
  const [page, setPage] = useState(0);

  const sortedLogs = orderBy(actionLogs, ['createdAt'], ['desc']);

  return (
    <Stack
      sx={{
        flexDirection: 'column',
        backgroundColor: caloTheme.palette.white,
        borderRadius: '16px',
        padding: 2,
        marginTop: '16px'
      }}
    >
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            color: caloTheme.palette.neutral900,
            textTransform: 'capitalize',
            fontSize: '19px',
            fontWeight: 600,
            lineHeight: '23px'
          }}
        >
          Activity Log
        </Typography>
        <Button
          variant="text"
          startIcon={<AddIcon />}
          onClick={addCommentClickHandler}
          sx={{
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 600,
            color: caloTheme.palette.neutral900
          }}
        >
          Add Comment
        </Button>
      </Stack>
      <Stack sx={{ gap: '24px', px: '12px', marginTop: '24px' }}>
        {sortedLogs.slice(page, page + 5).map((action) => (
          <ActivityLogItem action={action} type={type} />
        ))}
      </Stack>
      <Pagination isHidden={actionLogs.length <= 5} limit={5} total={actionLogs.length} page={page} onChange={setPage} />
    </Stack>
  );
};

export default ActivityLogCard;
