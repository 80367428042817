import { Permission } from '@calo/dashboard-types';
import { WeekDay } from '@calo/types';
import { Box, Button, Divider, List, ListItem, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { DriverPicker, IOSSwitch } from 'components';
import { WeekDayIndex } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { PolygonDriverChange } from 'lib/interfaces';
import { Suspense, useEffect, useState } from 'react';
import ColorSelector from './ColorSelector';
import ConfirmationPopup from './ConfirmationPopup';
import WeekDayToggleButtons from './WeekDayToggleButtons';
import styles from './styles';

interface AreaFormProps {
  closePopUp: () => void;
  polygonRow: PolygonRowState;
  onChange: (polygonRow: PolygonRowState) => void;
  onDelete: (polygonRow: PolygonRowState) => void;
}

const AreaForm = ({ polygonRow, onDelete, onChange }: AreaFormProps) => {
  const roles = useUserRoles();
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const [selectedDay, setSelectedDay] = useState<number>(new Date().getDay());
  const [selectedColor, setSelectedColor] = useState<string>(polygonRow.color);
  const [isSoftLaunchEnabled, setIsSoftLaunchEnabled] = useState<boolean>(polygonRow.isSoftLaunchEnabled);
  const [checkedSaveAllDays, setCheckedSaveAllDays] = useState<boolean>(false);
  const [driversData, setDriversData] = useState<any[]>([]);
  const [lastDriverChanged, setLastDriverChanged] = useState({ name: '', id: '', day: '' });

  const [driversPerWeek, setDriversPerWeek] = useState(
    polygonRow.drivers?.length ? polygonRow.drivers : Array.from({ length: 7 }).fill('')
  );

  useEffect(() => {
    const updatedDrivers = driversPerWeek?.slice();
    while (updatedDrivers?.length < 7) updatedDrivers?.push('');
    setDriversPerWeek(updatedDrivers);
    setSelectedColor(polygonRow.color);
    setIsSoftLaunchEnabled(polygonRow.isSoftLaunchEnabled);
  }, [polygonRow, polygonRow.polygon]);

  const handleDriverChange = (driverId: string) => {
    const updatedDrivers = driversPerWeek?.map((driver, index) => (index === selectedDay ? driverId : driver));
    setDriversPerWeek(updatedDrivers);
  };

  const handleDaysChange = (newDayIndex: number) => {
    setSelectedDay(newDayIndex);
  };

  const handleApply = () => {
    onChange({
      ...polygonRow,
      drivers: driversPerWeek as any,
      color: selectedColor,
      isSoftLaunchEnabled
    });
    setIsConfirmationPopupOpen(false);
  };

  const isAnyDayMissingDriver = () => {
    return driversPerWeek?.some((driver) => driver === '');
  };

  const checkForChanges = (): { day: string; driverId: string }[] => {
    const changedDays: { day: string; driverId: string }[] = [];
    for (const [index, driver] of driversPerWeek.entries()) {
      if (polygonRow.drivers[index] !== driver) {
        changedDays.push({ day: WeekDayIndex[index], driverId: driver as string });
      }
    }
    return changedDays;
  };

  const handleSummaryChangesText = (changedDays: { day: string; driverId: string }[]) => {
    const driversPerDay: PolygonDriverChange[] = [];
    for (const [index, changedData] of changedDays.entries()) {
      const existingEntry = driversPerDay.find((entry) => entry.day === WeekDayIndex[changedData.day]);
      if (!existingEntry) {
        driversPerDay.push({
          day: changedData.day,
          oldDriver: polygonRow.drivers[index] ? driversData[polygonRow.drivers[index]]?.name : '',
          newDriver: driversData[changedData.driverId]?.name
        });
      }
    }
    return [
      <Stack>
        <Typography sx={styles.summaryText}>Changes made:</Typography>
        <List>
          {driversPerDay.map((data, index) => (
            <ListItem key={index}>
              <Typography sx={styles.summaryText}>
                {'\u2022'} {`${data.newDriver} will deliver on ${data.day}`}
              </Typography>
            </ListItem>
          ))}
        </List>
        <Typography sx={styles.summaryText}>Are you sure you want to make the changes?</Typography>
      </Stack>
    ];
  };

  const handleApplyForAllDays = () => {
    if (lastDriverChanged.id) {
      const updatedDrivers = driversPerWeek?.map(() => lastDriverChanged.id);
      setDriversPerWeek(updatedDrivers);
      setCheckedSaveAllDays(true);
    } else {
      if (driversPerWeek?.[selectedDay]) {
        const updatedDrivers = driversPerWeek?.map(() => driversPerWeek?.[selectedDay]);
        setDriversPerWeek(updatedDrivers);
        setCheckedSaveAllDays(true);
      }
    }
  };

  const handleDelete = () => {
    onDelete(polygonRow);
    setIsDeletePopupOpen(false);
  };

  useEffect(() => {
    if (checkedSaveAllDays) {
      handleApplyForAllDays();
    }
  }, [lastDriverChanged]);

  return (
    <Box width={'100%'}>
      {roles.includes(Permission.GET_DRIVER_USERS) && (
        <Box style={{ flexDirection: 'column' }}>
          <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{ mb: 1 }}>
            <Typography
              sx={{
                lineHeight: '20px',
                fontSize: '16px',
                fontWeight: 600,
                fontFamily: 'Roboto',
                color: caloTheme.palette.neutral900
              }}
            >
              Assigned Driver
            </Typography>
          </Stack>
          <WeekDayToggleButtons selectedDay={selectedDay} handleDaysChange={handleDaysChange} />
          <Suspense fallback={null}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
              <Stack sx={{ width: '75%' }}>
                <DriverPicker
                  onChange={handleDriverChange}
                  setDriversData={setDriversData}
                  value={driversPerWeek?.[selectedDay] as any}
                  setLastDriverChanged={setLastDriverChanged}
                  selectedDay={Object.values(WeekDay)[selectedDay]}
                />
              </Stack>
              <Stack sx={{ width: '20%', marginLeft: 2 }}>
                <Typography
                  sx={{
                    color: caloTheme.palette.neutral900,
                    fontSize: '12px',
                    lineHeight: '14px',
                    fontWeight: 400,
                    marginBottom: 1
                  }}
                >
                  Apply to all days
                </Typography>
                <IOSSwitch
                  onChange={() => {
                    handleApplyForAllDays();
                  }}
                  sx={{ color: 'white' }}
                  checked={checkedSaveAllDays}
                />
              </Stack>
            </Stack>
          </Suspense>
          <Divider style={styles.divider} />
          <Box sx={{ mx: '5px' }}>
            <Typography sx={{ fontWeight: 600, lineHeight: '16px', fontSize: '14px' }}>Zone </Typography>
            <ColorSelector onSelectColor={setSelectedColor} selectedColor={selectedColor} />
          </Box>

          <Divider sx={styles.divider} />
          <Box>
            <Stack
              direction="row"
              alignItems="start"
              sx={{ backgroundColor: caloTheme.palette.neutral50, borderRadius: '12px', p: '4px', width: '240px' }}
            >
              <Button
                variant={'contained'}
                disabled={polygonRow.isSoftLaunchEnabled === false || polygonRow.isSoftLaunchEnabled === undefined}
                sx={[
                  styles.softLaunchButton,
                  {
                    color: isSoftLaunchEnabled ? caloTheme.palette.white : caloTheme.palette.neutral400,
                    backgroundColor: isSoftLaunchEnabled ? caloTheme.palette.primary500 : caloTheme.palette.neutral50,
                    zIndex: isSoftLaunchEnabled ? 1 : 0
                  }
                ]}
                onClick={() => setIsSoftLaunchEnabled(true)}
              >
                Soft Launch
              </Button>
              <Button
                variant={'contained'}
                sx={[
                  styles.fullLaunchButton,
                  {
                    color: isSoftLaunchEnabled ? caloTheme.palette.neutral600 : caloTheme.palette.white,
                    backgroundColor:
                      isSoftLaunchEnabled === false || isSoftLaunchEnabled === undefined
                        ? caloTheme.palette.primary500
                        : caloTheme.palette.neutral50,
                    zIndex: isSoftLaunchEnabled ? 0 : 1
                  }
                ]}
                onClick={() => setIsSoftLaunchEnabled(false)}
              >
                Full Launch
              </Button>
            </Stack>
          </Box>
          {isSoftLaunchEnabled === false && (polygonRow.isSoftLaunchEnabled || polygonRow.isSoftLaunchEnabled === undefined) && (
            <Typography sx={styles.warningText}>Cannot move from full launch to Soft Launch after saving</Typography>
          )}

          <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 2 }}>
            <Button variant="text" sx={styles.deleteButton} onClick={() => setIsDeletePopupOpen(true)}>
              Delete
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => setIsConfirmationPopupOpen(true)}
              sx={styles.applyButton}
              disabled={isAnyDayMissingDriver()}
            >
              Apply
            </Button>
          </Stack>

          <ConfirmationPopup
            isOpen={isConfirmationPopupOpen}
            title={
              checkedSaveAllDays
                ? 'Changes will be applied to all days of the week'
                : `Changes will be applied to ${checkForChanges().length} day${checkForChanges().length === 1 ? '' : 's'}`
            }
            content={
              checkedSaveAllDays
                ? `The changes will be applied to all days of the week. Would you like to proceed ?`
                : handleSummaryChangesText(checkForChanges())
            }
            onConfirm={handleApply}
            onCancel={() => setIsConfirmationPopupOpen(false)}
            confirmText="Confirm"
            cancelText="Cancel"
          />

          <ConfirmationPopup
            cancelText="Cancel"
            confirmText="Delete"
            title="Confirm Deletion"
            onConfirm={handleDelete}
            isOpen={isDeletePopupOpen}
            onCancel={() => setIsDeletePopupOpen(false)}
            content="Are you sure you want to delete this polygon?"
          />
        </Box>
      )}
    </Box>
  );
};
export default AreaForm;
