import { Country } from '@calo/types';
import parsePhoneNumberFromString from 'libphonenumber-js';

export const isValidEmail = (email: string) => {
  const re =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const handleValidPhoneNumber = (number: string, country: Country) => {
  const phoneNumber = parsePhoneNumberFromString(number, country);
  if (phoneNumber && phoneNumber.isValid() && phoneNumber.country === country) {
    return /^[+](?:.*\d)?$/.test(number);
  }
};

export const hasValidCharactersForLanguage = (name: string, lang: 'AR' | 'EN', allowSpecialChar?: boolean) => {
  if (lang === 'AR') {
    name = name.replace(/[^\u0621-\u064A\u0660-\u0669\u0020-\u007E\0-9\s]/g, '');
  }

  let regex;
  switch (lang) {
    case 'AR':
      regex = allowSpecialChar
        ? /^[\u0621-\u064A\u0660-\u0669\0-9\s$&+,:;=?@#|'<>.^*()%!-]*$/
        : /^[\u0621-\u064A\u0660-\u0669\0-9\s]*$/;
      break;
    case 'EN':
      regex = allowSpecialChar ? /^[a-zA-Z0-9\s$&+,:;=?@#|'<>.^*()%!-]*$/ : /^[a-zA-Z0-9\s]*$/;
      break;
    default:
      return false;
  }
  return regex.test(name);
};
