import { Card, Stack, Typography } from '@mui/material';
import { MenuCategory, MenuPopulateType } from 'lib/enums';
import { Menu } from 'lib/interfaces';
import { startsWith } from 'lodash-es';
import { findOccurrencesOfSameFood } from '../../helpers';
import MealListPerDayBox from './MealListPerDay';
import { styles } from './styles';

interface MenuListCardProps {
  searchTerm: string;
  filteredMenuList: Menu[];
  isEditable: boolean;
  handleUpdateMenu: any;
  setSelectedMenu: (value: Menu) => void;
  categoryName: MenuCategory;
  searchResultRefs: React.MutableRefObject<HTMLDivElement[]>;
  setReplaceFood: (value: { open: boolean; name: string; menuId: string }) => void;
  menusWithFoodCostObject: {
    [date: string]: {
      [name: string]: number;
    };
  };
}

const MenuListCard = ({
  isEditable,
  searchTerm,
  categoryName,
  setReplaceFood,
  setSelectedMenu,
  handleUpdateMenu,
  searchResultRefs,
  filteredMenuList,
  menusWithFoodCostObject
}: MenuListCardProps) => {
  const foodOccurrences: { [foodName: string]: number } = findOccurrencesOfSameFood(filteredMenuList);
  const recurringFoodNames = Object.keys(foodOccurrences).filter((foodName) => foodOccurrences[foodName] > 1);

  const formatCategoryName = (category: MenuCategory): string => {
    const mealType = Object.values(MenuPopulateType).find((type) => startsWith(category, type));
    return mealType && mealType !== (category as string) ? `${mealType} - ${category.slice(mealType.length).trim()}` : category;
  };

  return (
    <Card variant="outlined" sx={styles.card}>
      <Typography sx={styles.categoryTypography}>{formatCategoryName(categoryName)}</Typography>
      <Stack sx={styles.menuStack}>
        {filteredMenuList?.map((menu) => (
          <MealListPerDayBox
            menu={menu}
            key={menu.id}
            searchTerm={searchTerm}
            isEditable={isEditable}
            setReplaceFood={setReplaceFood}
            setSelectedMenu={setSelectedMenu}
            handleUpdateMenu={handleUpdateMenu}
            searchResultRefs={searchResultRefs}
            recurringFoodNames={recurringFoodNames}
            menuWithFoodCost={menusWithFoodCostObject[menu.id]}
          />
        ))}
      </Stack>
    </Card>
  );
};

export default MenuListCard;
