import { pickersDayClasses } from '@mui/x-date-pickers';
import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  cardContainer: {
    width: 'full',
    border: 'none',
    mb: 2,
    borderRadius: '8px',
    paddingBottom: '4px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: caloTheme.palette.grey[500]
  },
  mainContainer: {
    padding: 2,
    width: '100%',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center'
    }
  },
  popupContainer: { width: '100%', justifyContent: 'center' },
  popupTitle: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px'
  },
  popupDesc: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.15px',
    color: caloTheme.palette.black
  },
  container: {
    width: '50%',
    m: 'auto',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  createButton: {
    width: '120px',
    height: '51px',
    fontWeight: 600,
    fontSize: '20px',
    borderRadius: '8px',
    textTransform: 'none',
    backgroundColor: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    boxShadow: 'none',
    color: 'white',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: caloTheme.palette.primary600,
      borderColor: caloTheme.palette.primary600
    }
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    justifyContent: 'end',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  dateStack: {
    my: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    gap: '18px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  downloadButton: {
    color: caloTheme.palette.neutral900,
    borderColor: caloTheme.palette.neutral900,
    borderRadius: '8px',
    fontSize: '20px',
    fontWeight: 600,
    textTransform: 'none',
    ':hover': {
      color: caloTheme.palette.neutral900,
      borderColor: caloTheme.palette.neutral900
    }
  },
  buttons: {
    display: 'flex',
    width: '160px',
    marginBottom: '10px',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    boxShadow: 'none',
    gap: '8px',
    borderRadius: '8px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: caloTheme.palette.primary500,
      boxShadow: 'none',
      height: '40px'
    }
  },
  pickerStyle: {
    color: 'black',
    backgroundColor: caloTheme.palette.primary100,
    ':hover': { color: 'black', backgroundColor: caloTheme.palette.neutral100 },
    [`&&.${pickersDayClasses.selected}`]: {
      border: 1,
      color: 'black',
      backgroundColor: caloTheme.palette.neutral100,
      borderColor: caloTheme.palette.primary600
    }
  },
  dateInputStyle: {
    mr: 2,
    width: '20%',
    minWidth: '240px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      mt: 6,
      width: '100%',
      display: 'flex',
      borderRadius: '8px',
      paddingLeft: '30px',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  saveButton: {
    color: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    borderRadius: '8px',
    fontSize: '20px',
    fontWeight: 600,
    textTransform: 'none',
    width: '120px',
    ':hover': {
      color: caloTheme.palette.primary500,
      borderColor: caloTheme.palette.primary500
    }
  }
};
