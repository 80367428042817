import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  mainBox: {
    padding: 2,
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      width: 'auto',
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'column'
    },
    gap: '14px'
  }
};

export default styles;
