import React from 'react';
import { Chip, Box } from '@mui/material';
import { styled } from '@mui/system';

interface CustomChipToggleGroupProps {
  label1: string;
  label2: string;
  selectedValue: 'breakfast' | 'lunchDinner';
  onChange: (value: 'breakfast' | 'lunchDinner') => void;
}

const StyledChip = styled(Chip)(({ theme }) => ({
  width: '152.5px',
  height: '32px',
  borderRadius: '8px',
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '18px',
  letter: '0.16px',
  padding: '4px',
  '&.MuiChip-filled': {
    backgroundColor: '#1C7E57',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  '&.MuiChip-outlined': {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  '& .MuiTouchRipple-root': {
    display: 'none'
  }
}));

const ChipContainer = styled(Box)({
  display: 'flex',
  backgroundColor: '#EEEEEE',
  borderRadius: '8px',
  padding: '8px 16px 8px 16px',
  justifyContent: 'center',
  width: '100%'
});

const ParentChipContainer = styled(Box)({
  display: 'flex',
  backgroundColor: 'transparent',
  padding: '8px 8px 0px 8px',
  justifyContent: 'center',
  width: '100%'
});

const CustomChipToggleGroup = ({ label1, label2, selectedValue, onChange }: CustomChipToggleGroupProps) => {
  const handleClick = (clickedValue: 'breakfast' | 'lunchDinner') => {
    onChange(clickedValue);
  };

  return (
    <ParentChipContainer>
      <ChipContainer>
        <StyledChip
          label={label1}
          onClick={() => handleClick('breakfast')}
          color="primary"
          variant={selectedValue === 'breakfast' ? 'filled' : 'outlined'}
        />
        <StyledChip
          label={label2}
          onClick={() => handleClick('lunchDinner')}
          color="primary"
          variant={selectedValue === 'lunchDinner' ? 'filled' : 'outlined'}
        />
      </ChipContainer>
    </ParentChipContainer>
  );
};

export default CustomChipToggleGroup;
