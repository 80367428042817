import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { AffiliateTransaction, GetAffiliateTransactionsRes } from '@calo/dashboard-types';

import client from 'lib/client';
interface useAffiliateTransactionsProps {
  affiliateId: string;
}

const useAffiliateTransactions = ({ affiliateId }: useAffiliateTransactionsProps) => {
  const { data, hasNextPage, fetchNextPage, refetch, isLoading } = useInfiniteQuery<GetAffiliateTransactionsRes>(
    `/v1/affiliates/${affiliateId}/transactions`,
    async ({ pageParam, queryKey }) => {
      const { data } = await client.get(queryKey.join('/'), {
        params: {
          ...(pageParam && {
            cursor: pageParam
          })
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => data.meta.cursor
    }
  );

  const transactions = useMemo(
    () =>
      (data?.pages || []).reduce<AffiliateTransaction[]>((res, r) => {
        res = [...res, ...(r.transactions || [])];
        return res;
      }, []),
    [data]
  );

  return {
    transactions,
    transactionsLoading: isLoading,
    refetchAffiliate: refetch,
    hasNextPage,
    fetchNextPage
  };
};

export default useAffiliateTransactions;
