import { Permission } from '@calo/dashboard-types';
import { Box, Button, TableCell, TableRow, debounce } from '@mui/material';
import { updateDriverMetricReq } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { useUserRoles } from 'lib/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { DriverCoolerBagStats } from 'views/CoolerBags/CoolerBags';

interface CoolerBagsListRowProps {
  driverStat: DriverCoolerBagStats;
  refetchTotals: () => void;
}

const CoolerBagsListRow = ({ driverStat, refetchTotals }: CoolerBagsListRowProps) => {
  const roles = useUserRoles();

  const [damagedBags, setDamagedBags] = useState(driverStat.damagedCoolerBags ?? 0);

  const { mutateAsync: updateMutation } = useMutation(updateDriverMetricReq);

  const isInitialMount = useRef(true);

  const debouncedUpdate = useCallback(
    debounce(async (newDamagedBags) => {
      await updateMutation({
        id: driverStat.routePlanId as string,
        damagedCoolerBags: newDamagedBags
      });
    }, 2000),
    []
  );

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      debouncedUpdate(damagedBags);
    }
  }, [damagedBags, debouncedUpdate]);

  const handleIncrement = () => {
    setDamagedBags((prev) => prev + 1);
  };

  const handleDecrement = () => {
    setDamagedBags((prev) => (prev > 0 ? prev - 1 : 0));
  };

  useEffect(() => {
    refetchTotals();
  }, [damagedBags]);

  const isDisabled = damagedBags === driverStat.totalCoolerBagsReturned;
  return (
    <TableRow
      key={driverStat.driverId}
      sx={{
        borderBottom: 'none'
      }}
    >
      <TableCell
        sx={{
          fontSize: '14px',
          paddingY: 1,
          borderBottom: 'none'
        }}
      >
        {driverStat.driverName || 'N/A'}
      </TableCell>
      <TableCell
        sx={{
          fontSize: '14px',
          paddingY: 1,
          paddingLeft: 2,
          borderBottom: 'none'
        }}
      >
        {driverStat.deliveriesWithCoolerBag ?? 0}
      </TableCell>
      <TableCell
        sx={{
          fontSize: '14px',
          paddingY: 1,
          paddingLeft: 4,
          borderBottom: 'none'
        }}
      >
        {driverStat.totalCoolerBagsReturned ?? 0}
      </TableCell>
      <TableCell
        sx={{
          fontSize: '14px',
          paddingY: 1,
          paddingLeft: -3,
          borderBottom: 'none'
        }}
      >
        <Box display="flex" alignItems="center" mx={-2.9}>
          <Button
            onClick={handleDecrement}
            variant="contained"
            size="small"
            sx={{
              minWidth: '30px',
              minHeight: '30px',
              borderRadius: '50%',
              backgroundColor: caloTheme.palette.primary100,
              color: caloTheme.palette.primary500,
              fontWeight: 600,
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: caloTheme.palette.primary100
              }
            }}
            disabled={!roles.includes(Permission.UPDATE_DRIVER_METRIC) || isDisabled}
          >
            -
          </Button>
          <Box mx={3}>{damagedBags}</Box>
          <Button
            onClick={handleIncrement}
            variant="contained"
            size="small"
            sx={{
              minWidth: '30px',
              minHeight: '30px',
              borderRadius: '50%',
              backgroundColor: caloTheme.palette.primary100,
              color: caloTheme.palette.primary500,
              fontWeight: 600,
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: caloTheme.palette.primary100
              }
            }}
            disabled={!roles.includes(Permission.UPDATE_DRIVER_METRIC) || isDisabled}
          >
            +
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default CoolerBagsListRow;
