import { Brand, Country, Kitchen } from '@calo/types';
import { useFormik } from 'formik';
import { PackageType } from 'lib/enums';
import { CreateFoodPackageReq } from 'lib/interfaces';

export default (onSubmit: (values: CreateFoodPackageReq) => Promise<void>) =>
  useFormik<CreateFoodPackageReq>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      type: PackageType.regular,
      cost: 0,
      elements: [],
      country: Country.BH,
      brand: Brand.CALO,
      kitchen: Kitchen.BH1
    },

    validate: (values: CreateFoodPackageReq) => {
      const errors: any = {};

      if (!values.name) {
        errors.name = true;
      }

      if (!values.kitchen) {
        errors.kitchen = true;
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
