import { CountryCode } from 'libphonenumber-js';

export interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

export const countries: readonly CountryType[] = [
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '+971'
  },
  { code: 'BH', label: 'Bahrain', phone: '+973' },
  { code: 'SA', label: 'Saudi Arabia', phone: '+966' },
  { code: 'QA', label: 'Qatar', phone: '+974' },
  { code: 'OM', label: 'Oman', phone: '+968' },
  { code: 'KW', label: 'Kuwait', phone: '+965' },
  { code: 'GB', label: 'United Kingdom', phone: '+44' }
];

export const countryCodes: CountryCode[] = countries.map((country) => country.code as CountryCode);
