import { createSubscriptionDelivery } from 'actions/delivery';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import { format } from 'date-fns/fp';
import { Delivery } from 'lib/interfaces';
import { useRef } from 'react';
import { useMutation } from 'react-query';

import { CreateDeliveryReq } from '@calo/dashboard-types';
import { Subscription } from '@calo/types';
import { Box, Button, Card, Stack, Typography } from '@mui/material';

import NewDeliveryForm from './NewDeliveryForm';

interface CreateDeliveryCardProps {
  selectedDate: Date;
  subscription: Subscription & { remainingDays: number; expectedLastDeliveryDay: string; ratings: any[] };
  setSelectedDelivery: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSubscriptionDeliveries: React.Dispatch<React.SetStateAction<Delivery[]>>;
}

const CreateDeliveryCard = ({
  selectedDate,
  subscription,
  setSelectedDelivery,
  setSubscriptionDeliveries
}: CreateDeliveryCardProps) => {
  const newDeliveryModalRef = useRef<ModalRef>();

  const { mutateAsync: createMutation } = useMutation(createSubscriptionDelivery);

  const handleNewDelivery = async (values: CreateDeliveryReq) => {
    await createMutation(
      {
        ...values,
        id: subscription.id
      },
      {
        onSuccess: (data) => {
          setSelectedDelivery(data.id);
          setSubscriptionDeliveries((prev) => [...(prev || []), data]);
        }
      }
    );
    newDeliveryModalRef.current?.close();
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: '100%',
          border: 'none',
          borderRadius: '8px',
          marginTop: '8px',
          height: 'auto'
        }}
      >
        <Box
          sx={{
            padding: 2,
            mt: '15%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column'
            }
          }}
          display={'flex'}
          flexDirection={'column'}
        >
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: caloTheme.typography.fontFamily,
                fontSize: '23px',
                mb: 4,
                fontWeight: 600,
                lineHeight: '28px',
                textAlign: 'center'
              }}
            >
              {`No delivery for ${format('dd/MM/yyyy')(selectedDate)}`}
            </Typography>
          </Stack>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'}>
            <Button
              variant="contained"
              aria-label="create-delivery"
              sx={{
                width: '199px',
                height: '60px',
                color: 'white',
                fontWeight: 600,
                fontSize: '19px',
                boxShadow: 'none',
                lineHeight: '28px',
                borderRadius: '8px',
                textAlign: 'center',
                fontFamily: caloTheme.typography.fontFamily,
                borderColor: caloTheme.palette.primary500,
                backgroundColor: caloTheme.palette.primary500,
                '&:hover': {
                  color: 'white',
                  boxShadow: 'none',
                  backgroundColor: caloTheme.palette.primary600,
                  borderColor: caloTheme.palette.primary600
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  marginTop: 4,
                  width: 'auto',
                  margin: 'auto',
                  justifyItems: 'center'
                }
              }}
              onClick={() => newDeliveryModalRef.current?.open()}
            >
              Create Delivery
            </Button>
          </Stack>
        </Box>
      </Card>
      <Popup title="Create Delivery" ref={newDeliveryModalRef} onClose={() => newDeliveryModalRef.current?.close()}>
        <NewDeliveryForm
          key={subscription.id}
          subscription={subscription}
          selectedDate={selectedDate}
          onSubmit={handleNewDelivery}
        />
      </Popup>
    </>
  );
};

export default CreateDeliveryCard;
