import { CreateMenuReq, Permission } from '@calo/dashboard-types';
import { Brand, Country, Kitchen, MenuTagValue } from '@calo/types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Card, CircularProgress, Link, Stack, Typography } from '@mui/material';
import { FormikErrors } from 'formik';
import { flatten, unionBy, uniq, uniqBy } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { ModalRef, TrackMealsPopup } from 'components';
import AddMealsByFilterPopup from 'components/AddMealsByFilterPopup';
import Popup from 'components/Popup';
import { useFoodListByFilters } from 'hooks';
import useAddingTopRatedMeals from 'hooks/useAddingTopRatedMeals';
import { resolveCountry, updateFoodTags } from 'lib';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Food, MenuDraft, PopulateMenuReq } from 'lib/interfaces';
import DuplicateMenu from './DuplicateMenu';
import { styles } from './styles';

interface ActionsCardProps {
  populateMenuData: PopulateMenuReq[];
  country: Country;
  brand: Brand;
  kitchen: Kitchen;
  cardOpen: string;
  selectedDate: string;
  values: CreateMenuReq;
  selectedFoods: Food[];
  replaceFood: { open: boolean; name: string };
  mealDraftOptions: MenuDraft;
  setMealDraftOptions: React.Dispatch<React.SetStateAction<MenuDraft>>;
  handleFoodIds: () => string[] | undefined;
  onSubmit: (submitData: CreateMenuReq) => Promise<void>;
  setReplaceFood: React.Dispatch<React.SetStateAction<{ open: boolean; name: string }>>;
  setSelectedFoods: React.Dispatch<React.SetStateAction<Food[]>>;
  handleFoodChanges: (food: Food[], tags: any) => void;
  setPopulateMenuData: React.Dispatch<React.SetStateAction<PopulateMenuReq[]>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<CreateMenuReq>>;
  duplicateMenuDate: Date;
  refetchDuplicateMenu: any;
  setDuplicateMenuDate: React.Dispatch<React.SetStateAction<Date>>;
}

const ActionsCard = ({
  duplicateMenuDate,
  setDuplicateMenuDate,
  refetchDuplicateMenu,
  country,
  brand,
  kitchen,
  cardOpen,
  populateMenuData,
  values,
  selectedFoods,
  replaceFood,
  mealDraftOptions,
  setMealDraftOptions,
  handleFoodIds,
  onSubmit,
  setReplaceFood,
  setSelectedFoods,
  handleFoodChanges,
  setFieldValue
}: ActionsCardProps) => {
  const roles = useUserRoles();
  const openMenuActionsRef = useRef<HTMLButtonElement | null>(null);
  const menuActionsRef = useRef<HTMLDivElement | null>(null);
  const addMealsByFiltersRef = useRef<ModalRef>();
  const trackMealsRef = useRef<ModalRef>();
  const includedSizes = ['XS', 'S', 'M', 'L'];
  const [topRatedClicked, setTopRatedClicked] = useState(false);
  const [filtersOn, setFiltersOn] = useState<boolean>(false);
  const [showMenuActions, setShowMenuActions] = useState(false);
  const [selectedMenuFilters, setSelectedMenuFilters] = useState({
    type: '',
    plan: '',
    protein: '',
    taste: '',
    sandwich: undefined,
    lastUsed: '',
    base: '',
    category: '',
    mealName: ''
  });

  useEffect(() => {
    if (replaceFood.open && replaceFood.name.length > 0) {
      addMealsByFiltersRef.current?.open();
    } else {
      addMealsByFiltersRef.current?.close();
    }
  }, [replaceFood]);

  const handleOutsideClick = (e: any) => {
    if (
      showMenuActions &&
      menuActionsRef.current &&
      !menuActionsRef.current.contains(e.target as Node) &&
      openMenuActionsRef.current !== e.target
    ) {
      setShowMenuActions(false);
    }
  };

  useEffect(() => {
    if (showMenuActions) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showMenuActions]);

  const { topRatedMeals, isLoadingTopRated } = useAddingTopRatedMeals({
    country,
    brand,
    kitchen,
    menuDate: values.day,
    clicked: topRatedClicked
  });
  const { foodListFiltersData, foodFiltersLoading, hasNextPage, fetchNextPage, remove, isLoadingFetchNextPage } =
    useFoodListByFilters({
      selectedMenuFilters,
      country,
      kitchen,
      brand,
      filtersOn
    });

  const handleAddTopRatedMeals = async () => {
    if (isLoadingTopRated) {
      return;
    }
    if (topRatedMeals && topRatedMeals.some((food) => includedSizes.includes(food.size))) {
      const allFood = topRatedMeals.filter((food) => includedSizes.includes(food.size));
      const tags = uniqBy(
        allFood.map((f) => ({ foodId: f.id, value: [MenuTagValue.TOP_RATED] })),
        'foodId'
      );
      handleFoodChanges(allFood as any, flatten(tags));
      toast('Top rated meals successfully added!', { type: 'success', autoClose: 2000 });
      setTopRatedClicked(false);
    } else {
      toast(`No top rated meals available for ${values.country}-${values.kitchen}`, { type: 'error', autoClose: 2000 });
      setTopRatedClicked(false);
    }
  };

  useEffect(() => {
    if (topRatedClicked && topRatedMeals && !isLoadingTopRated) {
      handleAddTopRatedMeals();
    }
  }, [topRatedClicked, topRatedMeals, isLoadingTopRated]);

  const handleReplaceFood = (selectedFood: Food[]) => {
    if (populateMenuData.length > 0) {
      const index = populateMenuData.findIndex((data) => data.day === cardOpen);
      if (index >= 0) {
        const selectedMenuReplaced = {
          ...populateMenuData[index],
          food: uniqBy(
            [...selectedFood, ...populateMenuData[index].food.filter((food) => food.name.en !== replaceFood.name)],
            'id'
          )
        };
        populateMenuData.splice(index, 1, selectedMenuReplaced);
        handleClosePopup();
      }
    } else {
      const menuDay = new Date(values.day).getTime();
      const replacedFoodSize = selectedFoods.filter((food) => food.name.en === replaceFood.name);
      setSelectedFoods(selectedFoods.filter((foodData) => !replacedFoodSize.map((r) => r.id).includes(foodData.id)));
      const updatedTags = updateFoodTags(selectedFood, values.tags, menuDay);
      const allFood = uniq([...selectedFood.map((f) => f.id), ...(values.food as string[])]);
      const allTags = uniq([...updatedTags, ...(values.tags ?? [])]);
      setSelectedFoods((old) => unionBy(old, selectedFood, 'id'));
      setFieldValue('food', allFood);
      setFieldValue('tags', allTags);
      handleClosePopup();
    }
  };

  const handleClosePopup = () => {
    remove();
    addMealsByFiltersRef.current?.close();
    setSelectedMenuFilters({
      type: '',
      plan: '',
      protein: '',
      taste: '',
      sandwich: undefined,
      lastUsed: '',
      base: '',
      category: '',
      mealName: ''
    });
    setFiltersOn(false);
    setReplaceFood({ open: false, name: '' });
  };

  return (
    <>
      <Card variant="outlined" sx={styles.card}>
        <Box sx={styles.box}>
          <Stack sx={styles.stack}>
            <Typography variant="h5" sx={styles.typography}>
              <Link href={Routes.menuList.replace(':country', country)} style={{ textDecoration: 'none' }}>
                Menu {country}
              </Link>{' '}
              / New
            </Typography>
          </Stack>
          <Stack sx={styles.actionStack}>
            <DuplicateMenu
              kitchen={kitchen}
              duplicateMenuDate={duplicateMenuDate}
              setDuplicateMenuDate={setDuplicateMenuDate}
              refetchDuplicateMenu={refetchDuplicateMenu}
              selectedFoods={selectedFoods}
              handleClosePopup={handleClosePopup}
            />
            <Stack>
              <Button
                ref={openMenuActionsRef}
                variant="outlined"
                endIcon={showMenuActions ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                onClick={() => setShowMenuActions((prev) => !prev)}
                sx={styles.menuButton}
              >
                Menu Actions
              </Button>
              {showMenuActions && (
                <Box ref={menuActionsRef} sx={styles.menuActionsBox}>
                  {roles.includes(Permission.DESIGN_MENU) && (
                    <Button
                      aria-label="new-subscription-cancel"
                      sx={styles.menuActionButton}
                      disabled={populateMenuData?.length > 0 && cardOpen?.length === 0}
                      onClick={() => trackMealsRef.current?.open()}
                    >
                      Track Meals
                    </Button>
                  )}
                  <Button
                    aria-label="new-subscription-cancel"
                    sx={styles.menuActionButton}
                    disabled={populateMenuData?.length > 0 && cardOpen?.length === 0}
                    onClick={() => addMealsByFiltersRef.current?.open()}
                  >
                    Add Meals By Filters
                  </Button>
                  <Button
                    aria-label="add-top-rated-meals"
                    sx={styles.menuActionButton}
                    onClick={() => setTopRatedClicked(true)}
                    endIcon={isLoadingTopRated ? <CircularProgress size={20} color="inherit" /> : null}
                  >
                    Add Top Rated meals
                  </Button>
                </Box>
              )}
            </Stack>
            <Button
              variant="contained"
              data-test="menu-new-save-button"
              sx={styles.saveButton}
              onClick={() =>
                onSubmit({
                  day: values.day,
                  food: values.food,
                  country: values.country,
                  tags: values.tags,
                  brand: values.brand,
                  kitchen: values.kitchen
                })
              }
            >
              Save
            </Button>
          </Stack>
        </Box>
      </Card>
      <Popup
        maxWidth="lg"
        title={replaceFood.open ? `Swap Meal ${replaceFood.name}` : 'Create Menu'}
        ref={addMealsByFiltersRef}
        onClose={handleClosePopup}
        info={`${values.brand?.toUpperCase()}-${resolveCountry(values?.country).toUpperCase()}-${values?.kitchen?.toUpperCase()}`}
      >
        <AddMealsByFilterPopup
          values={values}
          filtersOn={filtersOn}
          replaceFood={replaceFood}
          setFiltersOn={setFiltersOn}
          isLoading={foodFiltersLoading}
          foodIds={handleFoodIds() || []}
          handleClosePopup={handleClosePopup}
          handleReplaceFood={handleReplaceFood}
          selectedMenuFilters={selectedMenuFilters}
          addMealsByFiltersRef={addMealsByFiltersRef}
          setSelectedMenuFilters={setSelectedMenuFilters}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage || false}
          isFetchingNextPage={isLoadingFetchNextPage}
          handleFoodChanges={(food, tags) => handleFoodChanges(food, tags)}
          foodListFilters={foodListFiltersData || []}
        />
      </Popup>
      <Popup maxWidth="xl" fullWidth title="Track Meals" ref={trackMealsRef} onClose={() => trackMealsRef.current?.close()}>
        <Box sx={{ flexDirection: 'column', width: '100%' }}>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <TrackMealsPopup mealOptions={mealDraftOptions} setOptions={setMealDraftOptions} trackMealsRef={trackMealsRef} />
          </Stack>
        </Box>
      </Popup>
    </>
  );
};

export default ActionsCard;
