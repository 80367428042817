import { Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { useFormik } from 'formik';

export default (subscription: Subscription, onSubmit: (values: UpdateSubscriptionReq) => any) => {
  return useFormik<UpdateSubscriptionReq>({
    enableReinitialize: true,
    initialValues: {
      macros: subscription.macros,
      macrosType: subscription.macrosType,
      macrosPreferences: subscription.macrosPreferences,
      macrosData: subscription.macrosData,
      name: subscription.name,
      email: subscription.email,
      dayAllowance: subscription.dayAllowance,
      autoRenew: subscription.autoRenew,
      dietType: subscription.dietType,
      deliveryTime: subscription.deliveryTime,
      paymentMethod: subscription.paymentMethod
    },

    validate: (_: UpdateSubscriptionReq) => {
      const errors: any = {};

      // if (!values.name) {
      //   errors.name = true;
      // }

      // const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, 'BH');

      // if (!phoneNumber || (phoneNumber && !phoneNumber.isValid())) {
      //   errors.phoneNumber = true;
      // }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
};
