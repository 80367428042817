import { format as formatDate } from 'date-fns';

export const getCustomDeliveryRange = (time: string) => {
  const isoDateFrom = new Date();
  const isoDateTo = new Date();
  if (time.toUpperCase().includes('PM') || time.toUpperCase().includes('AM')) {
    // eslint-disable-next-line prefer-const
    let [hour, minute] = time.split(':').map((val) => parseInt(val));
    if (time.includes('PM') && hour < 12) {
      hour += 12;
    }
    isoDateFrom.setHours(hour);
    isoDateFrom.setMinutes(minute);
    isoDateTo.setHours(hour + 1);
    isoDateTo.setMinutes(minute);
    const timeFrom = formatDate(isoDateFrom, 'hh:mm a');
    const timeTo = formatDate(isoDateTo, 'hh:mm a');
    return [timeFrom, timeTo];
  } else {
    const [hours, minutes] = time.split(':');
    isoDateFrom.setHours(+hours);
    isoDateFrom.setMinutes(+minutes);
    isoDateTo.setHours(+hours + 1);
    isoDateTo.setMinutes(+minutes);
    const timeFrom = formatDate(isoDateFrom, 'hh:mm a');
    const timeTo = formatDate(isoDateTo, 'hh:mm a');
    return [timeFrom, timeTo];
  }
};
