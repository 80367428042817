import { format, parseISO } from 'date-fns/fp';
import { useDispatch, useSelector } from 'react-redux';

import { SubscriptionFilters } from '@calo/dashboard-types';
import {
  Country,
  Currency,
  DietType,
  Kitchen,
  PaymentMethod,
  PlanType,
  SubscriptionFrequency,
  SubscriptionStatus,
  SubscriptionTier
} from '@calo/types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { toggleUISettings } from 'actions';
import { Input, RangeInput, Select, Select2, SideDrawer } from 'components';
import { getAccessibleCountries, getKitchenOptions } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';

interface SettingsProps {
  onFilter: (values: SubscriptionFilters) => any;
  filters: SubscriptionFilters;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Country"
          value={filters.country || getAccessibleCountries(userKitchens)[0]}
          onChange={(e) =>
            onFilter({
              ...filters,
              country: e.target.value as Country,
              kitchen: userKitchens.find((k) => k.includes(e.target.value)) as Kitchen
            })
          }
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(e: any) => onFilter({ ...filters, kitchen: e.target.value as Kitchen })}
          options={getKitchenOptions(userKitchens, filters.country || getAccessibleCountries(userKitchens)[0])}
          disabled={filters.country && Object.values(Kitchen).filter((r) => r.includes(filters.country!)).length === 0}
        />
        <Input label="Name" value={filters.name} onChange={(e) => onFilter({ ...filters, name: e.target.value })} debounce />
        <Input
          label="Phone Number"
          value={filters.phoneNumber}
          onChange={(e) => onFilter({ ...filters, phoneNumber: e.target.value })}
          debounce
        />
        <Input label="Email" value={filters.email} onChange={(e) => onFilter({ ...filters, email: e.target.value })} debounce />
        <Select2
          label="Frequency"
          value={filters.frequency}
          onChange={(e) => onFilter({ ...filters, frequency: e.target.value as SubscriptionFrequency })}
          options={[
            {
              value: '',
              label: 'Any'
            },
            ...Object.values(SubscriptionFrequency).map((type) => ({
              value: type,
              label: type
            }))
          ]}
        />
        <Select
          label="Payment Method"
          isMulti
          value={filters.paymentMethod}
          onChange={(data: any) => onFilter({ ...filters, paymentMethod: (data || []).map((row) => row.value) })}
          options={[PaymentMethod.cash, PaymentMethod.cc, PaymentMethod.benefit].map((type) => ({
            value: type,
            label: type
          }))}
        />

        <Select
          label="Status"
          isMulti
          value={filters.status}
          onChange={(data: any) => onFilter({ ...filters, status: (data || []).map((row) => row.value) })}
          options={[
            SubscriptionStatus.ongoing,
            SubscriptionStatus.paused,
            SubscriptionStatus.cancelled,
            SubscriptionStatus.suspended
          ].map((type) => ({
            value: type,
            label: type
          }))}
        />

        <Select
          label="Plan"
          isMulti
          onChange={(data: any) => onFilter({ ...filters, planType: (data || []).map((row) => row.value) })}
          value={filters.planType}
          options={[
            {
              value: PlanType.businessLunch,
              label: 'Business Lunch'
            },
            {
              value: PlanType.custom,
              label: 'Custom'
            },
            {
              value: PlanType.full,
              label: 'Full'
            },
            {
              value: PlanType.skipBreakfast,
              label: 'Skip Breakfast'
            },
            {
              value: PlanType.skipDinner,
              label: 'Skip Dinner'
            },
            {
              value: PlanType.dessert,
              label: 'Dessert'
            },
            {
              value: PlanType.futoor,
              label: 'Futoor'
            },
            {
              value: PlanType.ramadan,
              label: 'Ramadan'
            },
            {
              value: PlanType.weightLossChallenge,
              label: 'Weight Loss Challenge'
            }
          ]}
        />
        <Select
          label="Diet Type"
          isMulti
          onChange={(data: any) => onFilter({ ...filters, dietType: (data || []).map((row) => row.value) })}
          value={filters.dietType}
          options={[
            {
              value: DietType.balanced,
              label: 'Balanced'
            },
            {
              value: DietType.lowCarb,
              label: 'Low-carb'
            },
            {
              value: DietType.highProtein,
              label: 'High Protein'
            },
            {
              value: DietType.vegetarian,
              label: 'Vegetarian'
            },
            {
              value: DietType.weightLoss,
              label: 'Weight Loss'
            },
            {
              value: DietType.customMacros,
              label: 'Custom Macros'
            }
          ]}
        />
        <Select
          label="Tier"
          onChange={(e: any) => onFilter({ ...filters, tier: e.value })}
          value={filters.tier}
          options={[
            {
              value: SubscriptionTier.personalized,
              label: 'Personalized'
            },
            {
              value: SubscriptionTier.standard,
              label: 'Standard'
            }
          ]}
        />
        <Select
          label="Auto Renew"
          onChange={(e: any) => onFilter({ ...filters, autoRenew: e.value })}
          value={filters.autoRenew}
          options={[
            {
              value: undefined,
              label: 'Any'
            },
            {
              value: true,
              label: 'Auto on'
            },
            {
              value: false,
              label: 'Auto off'
            }
          ]}
        />

        <Select2
          label="Currency"
          value={filters.currency}
          onChange={(e) => onFilter({ ...filters, currency: e.target.value as Currency })}
          options={[
            {
              value: '',
              label: 'Any'
            },
            ...Object.values(Currency).map((currency) => ({
              value: currency,
              label: currency
            }))
          ]}
        />
        <div className="field">
          <label className="label">Last delivered day</label>
          <div className="control is-clearfix">
            <DateRangePicker
              calendarType={'US'}
              onChange={(dates: any) =>
                onFilter({
                  ...filters,
                  lastDeliveredDate: dates
                    ? {
                        gte: format('yyyy-MM-dd')(dates[0]),
                        lte: format('yyyy-MM-dd')(dates[1])
                      }
                    : undefined
                })
              }
              value={
                filters.lastDeliveredDate
                  ? [
                      filters.lastDeliveredDate!.gte ? parseISO(filters.lastDeliveredDate!.gte) : undefined,
                      filters.lastDeliveredDate!.lte ? parseISO(filters.lastDeliveredDate!.lte) : undefined
                    ]
                  : undefined
              }
            />
          </div>
        </div>
        <RangeInput
          label="Balance"
          onChange={(balance) => onFilter({ ...filters, balance })}
          value={filters.balance}
          disabled={!filters.currency}
        />
        <RangeInput
          label="Pending Amount"
          onChange={(pendingAmount) => onFilter({ ...filters, pendingAmount })}
          value={filters.pendingAmount}
          disabled={!filters.currency}
        />
      </section>
    </SideDrawer>
  );
};

export default Settings;
