import DateFnsAdapter from '@date-io/date-fns';
import { Icon as Iconify } from '@iconify/react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { isToday, isTomorrow } from 'date-fns';
import { useState } from 'react';

interface DatePickerProps {
  disabled?: boolean;
  label: string;
  selectedDate: string | null;
  setSelectedDate: React.Dispatch<React.SetStateAction<string | null>>;
  onChange: any;
  width?: string;
  clearIndicator?: boolean;
  disableFutureDates?: boolean;
  disablePastDates?: boolean;
  disableLockupTime?: boolean;
  maxDate?: any;
}

const disableTodayAndTomorrow = (date: Date) => {
  return isToday(date) || isTomorrow(date);
};

const DatePicker = ({
  disablePastDates = false,
  disableFutureDates = false,
  clearIndicator = true,
  width = '100%',
  disabled = false,
  maxDate = undefined,
  setSelectedDate,
  selectedDate,
  label,
  onChange,
  disableLockupTime = false
}: DatePickerProps) => {
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter}>
      <DesktopDatePicker
        inputFormat="dd/MM/yyyy"
        label={label}
        value={selectedDate}
        open={datePickerOpen}
        maxDate={maxDate}
        disablePast={disablePastDates}
        disableFuture={disableFutureDates}
        shouldDisableDate={disableLockupTime ? disableTodayAndTomorrow : undefined}
        onClose={() => setDatePickerOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: {
                width: width,
                minWidth: width,
                height: '51px',
                borderRadius: 8
              },
              endAdornment: (
                <>
                  {
                    <IconButton size="small" onClick={() => setDatePickerOpen(true)} disabled={disabled}>
                      <Iconify icon="uit:calender" />
                    </IconButton>
                  }
                  {selectedDate && !disabled && clearIndicator && (
                    <IconButton
                      size="small"
                      style={{ width: '12px', marginTop: '2px', marginLeft: '4px' }}
                      onClick={() => {
                        setSelectedDate(null);
                        setDatePickerOpen(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </>
              )
            }}
          />
        )}
        disabled={disabled}
        onChange={onChange}
        InputProps={{
          style: { height: '51px', borderRadius: '8px' }
        }}
      />
    </LocalizationProvider>
  );
};
export default DatePicker;
