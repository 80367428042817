import { flatten, startCase, uniq } from 'lodash';
import { useQuery } from 'react-query';

import { Brand, Country, FoodType } from '@calo/types';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';

import { getRecordWithParams } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components';
import { Delivery, Menu, MenuFood } from 'lib/interfaces';

interface AddonsPickerPopupProps {
  year: number;
  week: number;
  country: Country;
  isGifted: boolean;
  delivery: Delivery;
  selectedAddon: MenuFood | null;
  addonType: FoodType;
  setIsGifted: (value: boolean) => void;
  setAddonType: React.Dispatch<React.SetStateAction<FoodType>>;
  setSelectedAddon: React.Dispatch<React.SetStateAction<MenuFood | null>>;
  handleAddAddonRequest: (mealAdded: MenuFood | null) => Promise<void>;
  addonModalRef: React.MutableRefObject<ModalRef | undefined>;
}

const AddonsPickerPopup = ({
  addonType,
  delivery,
  year,
  week,
  setAddonType,
  selectedAddon,
  handleAddAddonRequest,
  addonModalRef,
  setSelectedAddon
}: AddonsPickerPopupProps) => {
  const { data: addons } = useQuery(
    [
      `addons-menu/year/${year}/week/${week}`,
      {
        brand: delivery.brand || Brand.CALO,
        kitchen: delivery.kitchen
      }
    ],
    getRecordWithParams,
    {
      retry: false
    }
  );

  const addonsMenu = addons as Menu;
  const addonsTypes = uniq(flatten(addonsMenu?.food?.map((r: any) => r.type)));

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        <FormControl
          sx={{
            my: 1,
            minWidth: '20%',
            display: 'flex',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'full'
            }
          }}
        >
          <InputLabel id="exact-subscription-package">Type</InputLabel>
          <Select
            label="Type"
            value={addonType}
            sx={{
              borderRadius: '8px',
              width: 'auto'
            }}
            onChange={(data: any) => {
              setAddonType(data.target.value);
              setSelectedAddon(null);
            }}
          >
            {addonsTypes
              .filter((r) => r !== FoodType.dinner)
              .map((t) =>
                t === FoodType.lunch
                  ? {
                      value: t,
                      label: 'Lunch & Dinner'
                    }
                  : {
                      value: t,
                      label: t
                    }
              )
              .map((p) => (
                <MenuItem key={p.value} value={p.value}>
                  {p.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            my: 1,
            minWidth: '20%',
            display: 'flex',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'full'
            }
          }}
        >
          <InputLabel id="exact-subscription-package">Addon</InputLabel>
          <Select
            label="Addon"
            value={selectedAddon?.id}
            sx={{
              borderRadius: '8px',
              width: '100%'
            }}
          >
            {addonsMenu
              ? addonsMenu.food
                  .filter((f) => f.type.includes(addonType))
                  .map((f) => ({
                    value: f,
                    label: f.name.en
                  }))
                  .map((p) => (
                    <MenuItem key={p.value.id} value={p.value.id} onClick={() => setSelectedAddon(p.value)}>
                      {startCase(p.label)}
                    </MenuItem>
                  ))
              : []}
          </Select>
        </FormControl>
      </Box>

      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        {/* <Stack sx={{ mt: 2 }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isGifted}
                  onChange={() => setIsGifted(!isGifted)}
                  onClick={() => setIsGifted(!isGifted)}
                  sx={{
                    color: caloTheme.palette.neutral300,
                    '&.Mui-checked': {
                      color: caloTheme.palette.primary500
                    }
                  }}
                />
              }
              label="Add meal as a gift - For free"
            />
          </FormGroup>
        </Stack> */}
        <Stack display={'flex'} flexDirection={'row'} sx={{ mt: 2, ml: 2 }}>
          <Button
            variant="contained"
            aria-label="select-addons"
            disabled={!selectedAddon}
            sx={{
              width: '96px',
              height: '42px',
              color: caloTheme.palette.neutral300,
              fontWeight: 600,
              fontSize: '19px',
              mr: 1,
              boxShadow: 'none',
              lineHeight: '23px',
              borderRadius: '8px',
              textAlign: 'center',
              fontFamily: caloTheme.typography.fontFamily,
              borderColor: caloTheme.palette.neutral100,
              backgroundColor: caloTheme.palette.neutral100,
              '&:hover': {
                color: caloTheme.palette.neutral600,
                boxShadow: 'none',
                backgroundColor: caloTheme.palette.neutral200,
                borderColor: caloTheme.palette.neutral200
              },
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                marginTop: 4,
                width: 'auto',
                margin: 'auto',
                justifyItems: 'center'
              }
            }}
            onClick={() => {
              addonModalRef.current?.close();
              setSelectedAddon(null);
            }}
          >
            cancel
          </Button>
          <Button
            variant="contained"
            aria-label="create-delivery"
            sx={{
              width: '96px',
              height: '42px',
              color: 'white',
              fontWeight: 600,
              fontSize: '19px',
              boxShadow: 'none',
              lineHeight: '23px',
              borderRadius: '8px',
              textAlign: 'center',
              fontFamily: caloTheme.typography.fontFamily,
              borderColor: caloTheme.palette.primary500,
              backgroundColor: caloTheme.palette.primary500,
              '&:hover': {
                color: 'white',
                boxShadow: 'none',
                backgroundColor: caloTheme.palette.primary600,
                borderColor: caloTheme.palette.primary600
              },
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                marginTop: 4,
                width: 'auto',
                margin: 'auto',
                justifyItems: 'center'
              }
            }}
            disabled={selectedAddon === null}
            onClick={() => handleAddAddonRequest(selectedAddon)}
          >
            ADD
          </Button>
        </Stack>
      </Box>
    </>
  );
};
export default AddonsPickerPopup;
