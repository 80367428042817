import {
  BatchUpdateFoodListReq,
  CreateFoodReq,
  FoodFilters,
  PrototypeAction,
  RatingFilters,
  SortingFood,
  UpdatePrototypeActionsReq
} from '@calo/dashboard-types';

import client from 'lib/client';
import { ToastSuccessMsgs, getPrototypeActionSuccessMessage } from 'lib/helpers';

export const createFood = async (values: Omit<CreateFoodReq, 'id'>) => {
  const { data, status } = await client.post('/food', values);
  ToastSuccessMsgs({ status, action: 'Food created successfully' });
  return data;
};

export const createPrototypeFood = async (values: Omit<CreateFoodReq, 'id'>) => {
  const { data, status } = await client.post('/food/prototype', values);
  ToastSuccessMsgs({ status, action: 'Food created successfully' });
  return data;
};

export const batchUpdate = async (payload: BatchUpdateFoodListReq) => {
  const { data, status } = await client.put('/food', payload);
  ToastSuccessMsgs({ status, action: 'Food updated successfully' });
  return data;
};

export const batchUpdatePrototypeFood = async (payload: BatchUpdateFoodListReq) => {
  const { data, status } = await client.put('/food/prototype', payload);
  ToastSuccessMsgs({ status, action: 'Food updated successfully' });
  return data;
};

export const addPrototypeFoodAction = async ({ id, ...rest }: UpdatePrototypeActionsReq & { id: string }) => {
  const { data, status } = await client.put(`/food/prototype/${id}/add-action`, rest);
  const message = getPrototypeActionSuccessMessage(rest.action, 'Food');
  ToastSuccessMsgs({ status, action: message });
  return data;
};

export const approvePrototypeFood = async (id: string) => {
  const { data, status } = await client.put(`/food/prototype/${id}/approve`, { action: PrototypeAction.approve });
  const message = getPrototypeActionSuccessMessage(PrototypeAction.approve, 'Food');
  ToastSuccessMsgs({ status, action: message });
  return data;
};

export const deleteFood = async (id: string) => {
  const { status } = await client.delete(`/food/${id}`);
  ToastSuccessMsgs({ status, action: 'Food deleted successfully' });
};

export const deletePrototypeFood = async (id: string) => {
  const { status } = await client.delete(`/food/prototype/${id}`);
  ToastSuccessMsgs({ status, action: 'Food deleted successfully' });
};

export const deleteCustomFood = async ({ id, userId }: { id: string; userId: string }) => {
  const { status } = await client.delete(`/food/${id}/custom`, { params: { userId } });
  ToastSuccessMsgs({ status, action: 'Food deleted successfully' });
};

export const getPackage = async () => {
  const { data: packageList } = await client.get('food-packages', { params: { limit: 1000 } });
  return packageList;
};

export const exportFood = async (sort: SortingFood, filters: FoodFilters) => {
  const { status } = await client.get('food/export', { params: { sort, filters } });
  ToastSuccessMsgs({ status, action: 'The export file will be sent to your email shortly' });
};

export const exportFeedBack = async (filters: RatingFilters) => {
  const { status } = await client.get('ratings/export', { params: { filters } });
  ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
};

export const selectedFoodData = async (foodID: string) => {
  const { data } = await client.get(`food/${foodID}`);
  return data;
};
