import { Box } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

const Container = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        minHeight: '20rem',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }
      }}
    >
      {children}
    </Box>
  );
};

export default Container;
