import { FoodDietType, FoodType } from '@calo/types';

export const ArabicFoodType = (foodType: FoodType) => {
  switch (foodType) {
    case FoodType.breakfast:
      return 'فطور';
    case FoodType.lunch:
      return 'غداء/عشاء';
    case FoodType.coffee:
      return 'قهوة';
    case FoodType.dessert:
      return 'الحلويات';
    case FoodType.caloKids:
      return 'كالو للأطفال';
    case FoodType.juice:
      return 'عصير';
    case FoodType.snack:
      return 'سناك';
    default:
      return foodType;
  }
};

export const ArabicFoodTags = (foodTags: FoodDietType) => {
  switch (foodTags) {
    case FoodDietType.balanced:
      return 'متوازنة';
    case FoodDietType.gourmet:
      return 'وجبات الشيف الخاصة';
    case FoodDietType.lowCarb:
      return 'لو-كارب';
    case FoodDietType.newMealClub:
      return 'نادي الوجبات الجديدة';
    case FoodDietType.vegetarian:
      return 'نباتية';
    case FoodDietType.worldCup:
      return 'وجبات كاس العالم';
    case FoodDietType.highProtein:
      return 'بروتين عالي';

    default:
      return foodTags;
  }
};
