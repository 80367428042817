import { AddToWallet, User } from '@calo/dashboard-types';
import cx from 'classnames';
import { Food } from 'lib/interfaces';
import { ReactNode, forwardRef, useImperativeHandle, useState } from 'react';
import RModal from 'react-modal';
import { Button } from '..';

interface ConfirmationModalProps {
  children: ReactNode;
  values: Food | AddToWallet | User | any;
  action: (value: Food | any) => Promise<void> | any;
  onClose?: () => void;
  className?: string;
}
export interface ModalRef {
  open(): void;
  close(): void;
}

const ConfirmationModal = forwardRef(({ values, children, action, className, onClose }: ConfirmationModalProps, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    },
    close: () => {
      setIsOpen(false);
    }
  }));

  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  return (
    <RModal
      className={cx(
        'p-8 flex flex-col bg-white  absolute left-0 right-0 bottom-0 sm:bottom-auto mx-auto top-0 sm:top-6/12 sm:-translate-y-1/2 sm:rounded-lg transform',
        {
          className: className,
          'lg:w-5/12 xl:w-3/12': !className
        }
      )}
      overlayClassName="fixed left-0 bottom-0 right-0 top-0 bg-dim z-50"
      style={{
        overlay: {
          zIndex: 1110
        }
      }}
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      <div className="section -m-10">
        <section className="section is-title-bar label -my-8 ">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <p>Confirm details</p>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <i className="fas fa-times ml-2 cursor-pointer" onClick={() => setIsOpen(false)} />
              </div>
            </div>
          </div>
        </section>
        <div className="ml-4">{children}</div>
        <section>
          <div className="float-right -mb-6 mt-6">
            <Button
              content="Cancel"
              className="mr-2"
              onClick={() => {
                setIsOpen(false);
              }}
            />
            <Button
              content="Confirm"
              warning
              onClick={() => {
                action(values);
                setIsOpen(false);
              }}
            />
          </div>
        </section>
      </div>
    </RModal>
  );
});
export default ConfirmationModal;
