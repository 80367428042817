import { UpdateSubscriptionTrackersReq } from '@calo/dashboard-types';

import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';

export const updateProgressTrackers = async (
  payload: UpdateSubscriptionTrackersReq & { subscriptionId: string; date: string }
) => {
  const { subscriptionId, date, steps, water, weight } = payload;
  const { status } = await client.put(`/progress/${subscriptionId}/date/${date}`, {
    steps: +steps,
    water: +water,
    weight: +weight
  });
  ToastSuccessMsgs({ status, action: 'Progress trackers updated' });
};
