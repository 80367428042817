import { CreateCouponReq, UpdateCouponReq } from '@calo/dashboard-types';

import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import mutation from './mutation';

export const createCoupon = async (values: CreateCouponReq) => {
  const { data, status } = await client.post('/coupons', values);
  mutation('coupons', data);
  ToastSuccessMsgs({ status, action: 'Create successfully' });
  return data;
};

export const updateCoupon = async (payload: UpdateCouponReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`/coupons/${id}`, rest);
  mutation(['coupons', id], data);
  ToastSuccessMsgs({ status, action: 'Updated successfully' });
};

export const deleteCoupon = async (id: string) => {
  const { status } = await client.delete(`/coupons/${id}`);
  ToastSuccessMsgs({ status, action: 'Coupon deleted successfully' });
};
