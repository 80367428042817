import { Permission } from '@calo/dashboard-types';
import { FoodComponentType, Localized } from '@calo/types';
import { Button, CaloLoader, Select } from 'components';
import Input from 'components/Input';
import { selectStyles } from 'lib/componentStyles';
import { useUserRoles } from 'lib/hooks';
import { MenuFood } from 'lib/interfaces';
import { flatten, sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

interface FoodPickerProps {
  customFood: any;
  isLoading: boolean;
  customFoodAction: string;
  customFoodComponent: MenuFood[];
  handleUpdateCustomFood: (value: MenuFood, customName: Localized) => void;
}

const CustomFoodModal = ({
  isLoading,
  customFoodAction,
  customFood,
  handleUpdateCustomFood,
  customFoodComponent
}: FoodPickerProps) => {
  const roles = useUserRoles();
  const [customFoodComponents, setCustomFoodComponents] = useState<any>({
    base: [],
    protein: [],
    topping: [],
    sauce: [],
    side: []
  });

  const [customFoodName, setCustomFoodName] = useState<Localized>({ en: '', ar: '' });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const sidesComponents = customFoodComponent
    ?.filter((f: any) => f.tags.includes(FoodComponentType.side))
    .map((r: any) => ({ value: r.id, label: typeof r.name === 'string' ? r.name : r.name.en }));

  const options = useMemo(
    () =>
      sortBy(sidesComponents, (f) => `${f.label}`).map((sides: any) => ({
        value: sides.value,
        label: sides.label
      })),
    [sidesComponents]
  );

  const handleCount = (type: FoodComponentType) => {
    if (type === FoodComponentType.side) {
      try {
        const sidesCount = customFoodComponents?.side.length;
        return sidesCount;
      } catch {
        return 0;
      }
    } else {
      let counts = 0;
      if (customFoodComponents[type][0]) {
        counts = customFoodComponents[type]?.length;
      }
      return counts;
    }
  };

  const handleRemoveSides = (sideId: any) => {
    const i = customFoodComponents.side.indexOf(sideId);
    customFoodComponents.side.splice(i, 1);
    setCustomFoodComponents({ ...customFoodComponents, side: customFoodComponents.side });
  };

  const handleSideComponent = (comp: any) => {
    const count = customFood?.components.find((r: any) => r.id === comp.value);
    for (let i = 1; i <= count.count; i++) {
      customFoodComponents['side'].push({ value: comp.value, label: comp.label });
    }
    return customFoodComponents['side'];
  };

  const getComponents = () => {
    const fC = customFoodComponent
      ?.filter((cus: any) => customFood?.components?.find((r: any) => r.id === cus.id))
      .map((k: any) => ({ value: k.id, label: typeof k.name === 'string' ? k.name : k.name.en, tags: k.tags }));
    fC?.map((k: any) =>
      k.tags[0] === FoodComponentType.side
        ? handleSideComponent(k)
        : customFoodComponents[k.tags[0]].push({ value: k.value, label: k.label })
    );
  };
  useEffect(() => {
    getComponents();
  }, [customFood]);

  useEffect(() => {
    if (customFood.name) {
      setCustomFoodName({ en: customFood.name.en, ar: customFood.name.en });
    }
    setIsSubmitting(false);
  }, []);

  const handleNameChange = (data: string) => {
    const customName = data;
    if (customName.length <= 32) {
      setCustomFoodName({ en: data, ar: data });
    }
  };

  return (
    <>
      {isLoading ? (
        <div>
          <CaloLoader />
        </div>
      ) : (
        <>
          <section className="section is-title-bar label -m-8">
            {customFoodAction === 'update' ? (
              <p className="uppercase text-xl">Update Custom Meal</p>
            ) : (
              <p className="uppercase text-xl">Create Custom Meal</p>
            )}
          </section>
          <div className="flex flex-col w-full">
            <span className="mb-4">
              <Input
                disabled={!roles.includes(Permission.UPDATE_DELIVERY_CUSTOM_FOOD)}
                label="Meal Name"
                value={customFoodName.en}
                onChange={(data: any) => handleNameChange(data.target.value)}
              />
              {customFoodName.en.length >= 32 && (
                <span>
                  <p className="text-sm text-red-300"> Maximum characters is 32</p>
                </span>
              )}
            </span>
            <table>
              <thead className="justify-between">
                <tr className="justify-between flex flex-row w-full">
                  <th className="w-1/2">{`Base (${handleCount(FoodComponentType.base)}/1)`}</th>
                  <th className="w-1/2">{`Protein (${handleCount(FoodComponentType.protein)}/1)`}</th>
                  <th className="w-1/2">{`Sides (${handleCount(FoodComponentType.side)}/3)`}</th>
                  <th className="w-1/2">{`Toppings (${handleCount(FoodComponentType.topping)}/1)`}</th>
                  <th className="w-1/2">{`Sauce (${handleCount(FoodComponentType.sauce)}/1)`}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="flex flex-row justify-between">
                  {Object.values(FoodComponentType).map((type) => (
                    <td key={type} className="w-1/2">
                      {type === FoodComponentType.side
                        ? customFoodComponents[type]?.map(
                            (r: any, index: number) =>
                              customFoodComponents[type][index]?.label && (
                                <span key={index} className="flex flex-row">
                                  <span
                                    className="border-1 my-2 bg-gray-100 text-green-400 text-sm rounded-lg -ml-4"
                                    style={{ color: '#57AE7F' }}
                                  >
                                    <p className="px-2 font-bold">
                                      {r.label}
                                      {
                                        <i
                                          className="float-right mt-1 fas fa-times text-md ml-2  cursor-pointer"
                                          style={{ color: '#57AE7F' }}
                                          onClick={() => handleRemoveSides(r)}
                                        />
                                      }
                                    </p>
                                  </span>
                                </span>
                              )
                          )
                        : customFoodComponents[type][0]?.label && (
                            <span className="flex flex-row">
                              <span
                                className="border-1 my-2 bg-gray-100 text-green-400 text-sm rounded-lg"
                                style={{ color: '#57AE7F' }}
                              >
                                <p className="px-2 font-bold">
                                  {customFoodComponents[type][0].label}
                                  {
                                    <i
                                      className="float-right mt-1 fas fa-times text-md ml-2 cursor-pointerr"
                                      style={{ color: '#57AE7F' }}
                                      onClick={() => setCustomFoodComponents({ ...customFoodComponents, [type]: [] })}
                                    />
                                  }
                                </p>
                              </span>
                            </span>
                          )}
                    </td>
                  ))}
                </tr>
                <tr className="flex flex-row justify-between">
                  {Object.values(FoodComponentType).map((type) => (
                    <td className="w-full" key={Math.random()}>
                      {type !== FoodComponentType.side && customFoodComponents[type].length === 0 && (
                        <Select
                          value={customFoodComponents[type][0]?.value}
                          placeholder="Select"
                          className="w-1/2 mt-2"
                          customStyles={selectStyles}
                          options={customFoodComponent
                            ?.filter((f: any) => f.tags.includes(type))
                            .map((r: any) => ({ value: r.id, label: typeof r.name === 'string' ? r.name : r.name.en }))}
                          onChange={(data: any) =>
                            setCustomFoodComponents({
                              ...customFoodComponents,
                              [type]: [{ value: data.value, label: data.label }]
                            })
                          }
                        />
                      )}
                      {type === FoodComponentType.side && handleCount(FoodComponentType.side) < 3 && (
                        <Select
                          placeholder="Select"
                          options={options}
                          customStyles={selectStyles}
                          className="w-1/2 mt-2 -ml-4"
                          onChange={(data: any) =>
                            setCustomFoodComponents({
                              ...customFoodComponents,
                              side: [
                                ...customFoodComponents.side,
                                (customFoodComponents.side[handleCount(FoodComponentType.side) - 1] = {
                                  value: data.value,
                                  label: data.label
                                })
                              ]
                            })
                          }
                        />
                      )}
                    </td>
                  ))}
                </tr>

                <div className=" w-full mt-8">
                  <span className="relative mt-2 float-right">
                    <Button
                      content="Save"
                      primary
                      disabled={
                        isSubmitting ||
                        customFoodName.en.length === 0 ||
                        flatten(Object.values(customFoodComponents)).map((r: any) => ({ id: r.value })).length === 0
                      }
                      className="float-right"
                      onClick={() => {
                        setIsSubmitting(true);
                        handleUpdateCustomFood(customFoodComponents, customFoodName);
                      }}
                    />
                  </span>
                </div>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};
export default CustomFoodModal;
