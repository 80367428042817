import { useEffect, useRef, useState } from 'react';

import { format as formatFns } from 'date-fns';
import { format, parseISO } from 'date-fns/fp';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ListComplaintsFilters, Permission, UpdateComplaintReq } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import { Box, Button, Card, Stack, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';

import { exportComplaints, getListWithParams, toggleUISettings } from 'actions';
import { updateComplaints } from 'actions/complaints';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Button as CompButton, Icon, ModalRef, Pagination } from 'components';
import { useComplaints } from 'hooks';
import { handleUploadImage } from 'lib';
import { StyledTableCell } from 'lib/componentStyles';
import { useUserRoles } from 'lib/hooks';
import { PaginatedDeliveries } from 'lib/interfaces';
import ComplaintListRow from '../ComplaintListRow';
import AttachingFilePopup from '../ComplaintsPopUps/AttachingFilesPopup';
import EditComplaintPopup from '../ComplaintsPopUps/EditComplaintPopup';
import Settings from '../Settings';
import CreateComplaint, { UserComplaints } from './CreateComplaint';
import styles from './styles';

const ComplaintsList = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [page, setPage] = useState(0);

  const attachmentRef = useRef<ModalRef>();
  const editComplaintRef = useRef<ModalRef>();
  const componentMethodImageRef = useRef<ModalRef>();
  const [loadingImage, setLoadingImage] = useState(false);
  const searchParams = new URLSearchParams(location.search);

  const [availableMeals, setAvailableMeals] = useState<any[]>([]);
  const { mutateAsync: updateMutation } = useMutation(updateComplaints);

  const [user, setUser] = useState<UserComplaints | undefined>(undefined);
  const [selectedComplaint, setSelectedComplaint] = useState<any | undefined>(undefined);

  const [filters, setFilters] = useState<ListComplaintsFilters>({
    kitchen: Kitchen.BH1,
    date: {
      gte: formatFns(Date.now(), 'yyyy-MM-dd'),
      lte: formatFns(Date.now(), 'yyyy-MM-dd')
    },
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });
  const roles = useUserRoles();

  useQuery<any, Error, PaginatedDeliveries>(
    [
      'deliveries',
      {
        page: 0,
        filters: {
          day: {
            lte: selectedComplaint?.date,
            gte: selectedComplaint?.date
          },
          skipped: false,
          kitchen: selectedComplaint?.kitchen,
          phoneNumber: selectedComplaint?.user?.phoneNumber.replace('+', '')
        }
      }
    ],
    getListWithParams,
    {
      keepPreviousData: false,
      enabled: !!selectedComplaint,
      onSuccess: (data: any) => {
        const index = data.data.findIndex((delivery: any) => delivery.userId === selectedComplaint!.user.userId);
        if (index >= 0) {
          setAvailableMeals([
            ...(data.data[index].food || []),
            ...(data.data[index].addons || []),
            ...(data.data[index].giftedItems?.meal || [])
          ]);
        } else {
          toast(`No delivery for the selected customer on ${selectedComplaint!.date}`, { type: 'error', autoClose: 2000 });
        }
      }
    }
  );

  useEffect(() => {
    if (!selectedComplaint) {
      setAvailableMeals([]);
    }
  }, [selectedComplaint]);

  const { complaints, complaintsLoading, data, refetchComplaints } = useComplaints({ filters, page, setPage });

  const [displayImage, setDisplayImage] = useState<string[]>([]);
  const [progress, setProgress] = useState(0);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    handleUploadImage({
      disabled: !selectedComplaint,
      files,
      setDisplayImage,
      setLoadingImage,
      urlFile: 'complaints',
      setProgress,
      uuIDCreate: selectedComplaint!.id.split('#')[1]
    });
  };

  const handleUpdateData = async (data: any) => {
    const updatedValues: UpdateComplaintReq = {
      category: data!.category,
      type: data!.type,
      comments: data.comments?.length > 0 ? data.comments : undefined,
      attachment: !!data.attachment,
      attachments: displayImage,
      meal: data.meal
    };
    const complaintsReq = await updateMutation({ ...updatedValues, id: data.id.replace('#', '_'), kitchen: data.kitchen });
    refetchComplaints();
    if (complaintsReq) {
      setSelectedComplaint(undefined);
    }
  };

  return (
    <>
      <Card variant="outlined" sx={styles.cardContainer}>
        <Box sx={styles.container} width="100%">
          <Stack width={'50%'} display={'flex'} flexDirection={'row'} alignItems="start" justifyContent={'space-between'}>
            <Typography variant="h3" sx={styles.textTitle}>
              Complaints
            </Typography>
          </Stack>
        </Box>
      </Card>

      {roles.includes(Permission.CREATE_CUSTOMER_COMPLAINT) && (
        <Card variant="outlined" sx={styles.createCard}>
          <CreateComplaint refetchComplaintList={() => refetchComplaints()} setUser={setUser} user={user} />
        </Card>
      )}

      {roles.includes(Permission.LIST_CUSTOMER_COMPLAINT) && (
        <>
          <Card variant="outlined" sx={styles.cardContainer}>
            <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
              <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', pb: 2 }}>
                <Typography variant="h6" sx={styles.listTitle}>
                  Complaints raised on - {filters.date && format('dd/MM/yyyy')(parseISO(filters.date.gte))} -{' '}
                  {filters.date && format('dd/MM/yyyy')(parseISO(filters.date.lte))}
                </Typography>
                <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                  {roles.includes(Permission.EXPORT_COMPLAINT_LIST) && (
                    <span className="mr-4">
                      <CompButton onClick={() => exportComplaints(filters)} icon="fas fa-file-export" />
                    </span>
                  )}
                  <Button
                    aria-label="filter-subscription-list"
                    sx={styles.filterButton}
                    onClick={() => dispatch(toggleUISettings())}
                  >
                    {<Icon name="filter" size={6} className="w-10 h-18" />}
                  </Button>

                  <Button
                    variant="outlined"
                    disabled={(complaints && complaints.data.length === 0) || complaintsLoading}
                    aria-label="Download-subscription-list"
                    sx={styles.exportButton}
                    onClick={() => exportComplaints(filters)}
                    startIcon={
                      <Icon
                        name="importFile"
                        size={6}
                        style={{
                          stroke:
                            (complaints && complaints.data.length === 0) || complaintsLoading
                              ? caloTheme.palette.neutral300
                              : caloTheme.palette.primary500
                        }}
                      />
                    }
                  >
                    Download
                  </Button>
                </Stack>
              </Stack>
              <>
                <Table sx={styles.tableStyle}>
                  <TableHead
                    style={{ borderRadius: '8px' }}
                    sx={{
                      backgroundColor: caloTheme.palette.neutral50,
                      color: 'black',
                      flexWrap: 0,
                      justifyContent: 'space-between',
                      width: '100%',
                      borderRadius: '8px'
                    }}
                  >
                    <TableRow
                      sx={{
                        backgroundColor: caloTheme.palette.neutral50,
                        color: 'black',
                        width: '100%',
                        flexWrap: 0,
                        justifyContent: 'space-between'
                      }}
                    >
                      <StyledTableCell style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', width: '15%' }}>
                        User
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }}>Complaint Type</StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }}>Complaint</StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }}>Meal</StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }}>Reported By</StyledTableCell>
                      <StyledTableCell style={{ width: '10%' }}>Resolved By</StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }}>Delivery day</StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }}>Delivery Time</StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }}>Driver</StyledTableCell>
                      <StyledTableCell style={{ width: '15%' }}>Complaint ID</StyledTableCell>
                      <StyledTableCell style={{ width: '5%', textAlign: 'center' }}>Comment</StyledTableCell>
                      <StyledTableCell style={{ width: '5%', textAlign: 'center' }}>Attachment</StyledTableCell>
                      <StyledTableCell
                        style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px', width: '8%', textAlign: 'center' }}
                      >
                        Status
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {complaintsLoading ? (
                      <TableRow>
                        <StyledTableCell colSpan={13} style={{ textAlign: 'center' }}>
                          <CaloLoader />
                        </StyledTableCell>
                      </TableRow>
                    ) : complaints && complaints.data.length === 0 ? (
                      <TableRow>
                        <StyledTableCell colSpan={11} style={{ textAlign: 'center' }}>
                          <Typography sx={styles.text}>No Complaints</Typography>
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      complaints?.data.map((data: any) => (
                        <ComplaintListRow
                          key={data.id}
                          complaintData={data}
                          setSelectedComplaint={setSelectedComplaint}
                          setDisplayImage={setDisplayImage}
                          attachmentRef={attachmentRef}
                          editComplaintRef={editComplaintRef}
                          selectedComplaint={selectedComplaint}
                          handleFileChange={handleFileChange}
                          displayImage={displayImage}
                        />
                      ))
                    )}
                  </TableBody>
                </Table>
                {data?.data && (
                  <Pagination
                    limit={15}
                    page={page}
                    onChange={setPage}
                    total={data.meta.total}
                    isHidden={data.meta.total === 0 || data.meta.total <= 10}
                  />
                )}
              </>
            </Box>
          </Card>
          <Settings onFilter={setFilters} filters={filters} />
        </>
      )}

      <AttachingFilePopup
        progress={progress}
        loadingImage={loadingImage}
        displayImage={displayImage}
        setDisplayImage={setDisplayImage}
        handleFileChange={handleFileChange}
        componentMethodImageRef={componentMethodImageRef}
        selectedComplaint={selectedComplaint}
      />

      <EditComplaintPopup
        availableMeals={availableMeals}
        editComplaintRef={editComplaintRef}
        handleFileChange={handleFileChange}
        handleUpdateData={handleUpdateData}
        selectedComplaint={selectedComplaint}
        setSelectedComplaint={setSelectedComplaint}
        componentMethodImageRef={componentMethodImageRef}
      />
    </>
  );
};
export default ComplaintsList;
