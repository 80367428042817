import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Route, Switch } from 'react-router-dom';
import OperationsChangePage from './OperationsChangePage';

const OperationsChange = () => {
  const roles = useUserRoles();

  return (
    <Switch>
      {roles.includes(Permission.LIST_DELIVERY_TICKET) && (
        <Route key="meal-swap" exact path={Routes.operationsChanges} component={OperationsChangePage} />
      )}
    </Switch>
  );
};

export default OperationsChange;
