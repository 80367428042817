import { FoodComponentStation, FoodStatsFilters, MeasurementUnit } from '@calo/dashboard-types';
import { format } from 'date-fns/fp';
import ExcelJS, { Style } from 'exceljs';
import { ComponentFoodSubStats, StatsFoodComponent } from 'lib/interfaces';
import { Dictionary, has, keyBy, orderBy, sortBy } from 'lodash';
import { getTotalChildComponentQuantity } from './MealsDrawer/helpers';

export const getMeasurementUnit = (switchUnit: boolean, row: StatsFoodComponent) =>
  switchUnit ? row.measurementUnit : row.measurementUnit === MeasurementUnit.ml ? MeasurementUnit.ml : MeasurementUnit.g;

export const calculateRemovedCookedWeight = (switchUnit: boolean, row: StatsFoodComponent) =>
  switchUnit ? Math.round((row.stats.removedQuantity + +Number.EPSILON) * 100) / 100 : Math.ceil(row.stats.removedCookedWeight);

export const calcualteSubComponentCookedWeight = (switchUnit: boolean, row: StatsFoodComponent) =>
  switchUnit ? Math.round(getTotalChildComponentQuantity(row)) : Math.ceil(row.stats.childWeight);

export const calculateTotalRawWeight = (row: StatsFoodComponent) =>
  Math.round(
    (Math.ceil(row.stats.rawWeight - row.stats.removedRawWeight) +
      row.stats.childWeight * (row.cookedRawFactor ?? 1) +
      +Number.EPSILON) *
      100
  ) / 100;

export const calculateTotalCookedWeight = (switchUnit: boolean, row: StatsFoodComponent) =>
  switchUnit
    ? row.stats.quantity - row.stats.removedQuantity
    : Math.ceil(row.stats.cookedWeight - row.stats.removedCookedWeight) +
      (switchUnit ? Math.round(getTotalChildComponentQuantity(row)) : Math.ceil(row.stats.childWeight));

export const calculateSubComponentRawWeight = (switchUnit: boolean, row: StatsFoodComponent) =>
  switchUnit ? Math.round(getTotalChildComponentQuantity(row)) : Math.ceil(row.stats.childWeight * (row.cookedRawFactor ?? 1));

export const groupedAllFoodComponents = (selectedStatsData: StatsFoodComponent[]) => {
  const groupedData: Dictionary<StatsFoodComponent> = {};
  for (const item of selectedStatsData) {
    if (groupedData[item.id]) {
      groupedData[item.id] = {
        ...item,
        keyedFood: groupKeyedMeals(item, groupedData[item.id]),
        stats: {
          ...item.stats,
          quantity: groupedData[item.id].stats.quantity + item.stats.quantity,
          cookedWeight: groupedData[item.id].stats.cookedWeight + item.stats.cookedWeight,
          rawWeight: groupedData[item.id].stats.rawWeight + item.stats.rawWeight,
          cookedSpecialRequestsWeight:
            groupedData[item.id].stats.cookedSpecialRequestsWeight + item.stats.cookedSpecialRequestsWeight,
          specialRequestsQuantity: groupedData[item.id].stats.specialRequestsQuantity + item.stats.specialRequestsQuantity,
          rawSpecialRequestsWeight: groupedData[item.id].stats.rawSpecialRequestsWeight + item.stats.rawSpecialRequestsWeight,
          childQuantity: groupedData[item.id].stats.childQuantity + item.stats.childQuantity,
          childWeight: groupedData[item.id].stats.childWeight + item.stats.childWeight,
          removedQuantity: groupedData[item.id].stats.removedQuantity + item.stats.removedQuantity,
          removedCookedWeight: groupedData[item.id].stats.removedCookedWeight + item.stats.removedCookedWeight,
          removedRawWeight: groupedData[item.id].stats.removedRawWeight + item.stats.removedRawWeight
        }
      };
    } else {
      groupedData[item.id] = item;
    }
  }

  return groupedData;
};

const groupKeyedMeals = (compStats: StatsFoodComponent, groupedComp: StatsFoodComponent) => {
  const newKeyedMeals: Dictionary<ComponentFoodSubStats> = { ...groupedComp.keyedFood };
  for (const [mealId, componentMealStats] of Object.entries(compStats.keyedFood)) {
    if (!newKeyedMeals[mealId]) {
      newKeyedMeals[mealId] = {
        id: componentMealStats.id,
        name: componentMealStats.name,
        mealCount: componentMealStats.mealCount,
        size: componentMealStats.size,
        weight: 0,
        quantity: 0,
        specialRequestsQuantity: 0,
        category: componentMealStats.category
      };
    }

    const current = newKeyedMeals[mealId];
    newKeyedMeals[mealId] = {
      ...newKeyedMeals[mealId],
      weight: current.weight + componentMealStats.weight,
      quantity: current.quantity + componentMealStats.quantity,
      specialRequestsQuantity: current.specialRequestsQuantity + componentMealStats.specialRequestsQuantity
    };

    if (componentMealStats.components) {
      newKeyedMeals[mealId].components = [...(newKeyedMeals[mealId].components || []), ...componentMealStats.components];
    }

    if (componentMealStats.removedQuantity) {
      newKeyedMeals[mealId].removedQuantity = (newKeyedMeals[mealId].removedQuantity ?? 0) + componentMealStats.removedQuantity;
    }

    if (componentMealStats.removedWeight) {
      newKeyedMeals[mealId].removedWeight = (newKeyedMeals[mealId].removedWeight ?? 0) + componentMealStats.removedWeight;
    }
  }

  const keyedMealList = Object.values(newKeyedMeals);

  const sortedList = orderBy(keyedMealList, [(item) => has(item, 'components'), 'name.en'], ['asc', 'asc']);

  return keyBy(sortedList, 'id');
};

export const getRowData = (row: StatsFoodComponent, isDetailedView: boolean) => {
  let rowData: any = {
    name: row.name.en,
    brand: row.brand,
    unit: row.measurementUnit === MeasurementUnit.ml ? MeasurementUnit.ml : MeasurementUnit.g,
    totalRawWeight:
      Math.round((row.stats.rawWeight - row.stats.removedRawWeight + row.stats.childWeight + +Number.EPSILON) * 100) / 100,
    totalCookedWeight: row.stats.cookedWeight + row.stats.removedCookedWeight + Math.ceil(row.stats.childWeight),
    cookedWMU: (row.stats.quantity - row.stats.removedQuantity).toString(),
    measurementUnit: row.measurementUnit
  };
  if (isDetailedView) {
    rowData = {
      ...rowData,
      weight: (row.stats.rawWeight - row.stats.rawSpecialRequestsWeight).toString(),
      cookedWeight: (row.stats.cookedWeight - row.stats.cookedSpecialRequestsWeight).toString(),
      rawSpecialRequestsWeight: row.stats.rawSpecialRequestsWeight.toString(),
      cookedSpecialRequestsWeight: row.stats.cookedSpecialRequestsWeight.toString(),
      childWeightRaw: Math.ceil(row.stats.childWeight * (row.cookedRawFactor ?? 1)).toString(),
      childWeight: Math.ceil(row.stats.childWeight).toString(),
      removedRawWeight: Math.ceil(row.stats.removedRawWeight),
      removedCookedWeight: Math.ceil(row.stats.removedCookedWeight)
    };
  }
  return rowData;
};

const border: Partial<Style> = {
  font: { size: 12 },
  border: {
    top: { style: 'thin', color: { argb: 'D3D3D3' } },
    left: { style: 'thin', color: { argb: 'D3D3D3' } },
    bottom: { style: 'thin', color: { argb: 'D3D3D3' } },
    right: { style: 'thin', color: { argb: 'D3D3D3' } }
  }
};

const detailedViewHeaderColumns = [
  { header: 'Weight', width: 10, key: 'weight', style: border },
  { header: 'Cooked weight', width: 20, key: 'cookedWeight', style: border },
  { header: 'Special Requests Weight', width: 10, key: 'rawSpecialRequestsWeight', style: border },
  { header: 'Special Requests Cooked weight', width: 20, key: 'cookedSpecialRequestsWeight', style: border },
  { header: 'Sub-component cooked weight', width: 20, key: 'childWeight', style: border },
  { header: 'Sub-component raw weight', width: 20, key: 'childWeightRaw', style: border },
  { header: 'Removed Raw Weight', width: 20, key: 'removedRawWeight', style: border },
  { header: 'Removed Cooked Weight', width: 20, key: 'removedCookedWeight', style: border }
];

export const columns = (isDetailedView: boolean) => [
  { width: 2, style: border },
  { header: 'Name', width: 25, key: 'name', style: border },
  { header: 'Brand', width: 8, key: 'brand', style: border },
  ...(isDetailedView ? detailedViewHeaderColumns : []),
  { header: 'Unit', width: 8, key: 'unit', style: border },
  { header: 'Total Raw Weight', width: 20, key: 'totalRawWeight', style: border },
  { header: 'Total Cooked Weight', width: 20, key: 'totalCookedWeight', style: border },
  { header: 'Cooked weight in measurement unit', width: 20, key: 'cookedWMU', style: border },
  { header: 'Measurement Unit', width: 12, key: 'measurementUnit', style: border },
  { header: '*', width: 2, key: '*', style: border },
  { header: '_', width: 2, key: '_', style: border },
  { header: 'C', width: 2, key: 'C', style: border },
  { header: 'D', width: 2, key: 'D', style: border }
];

const defaultColumns = [' ', 'Name', 'Brand'];
const endDefaultColumns = [
  'Unit',
  'Total Raw Weight',
  'Total Cooked Weight',
  'Cooked weight in measurement unit',
  'Measurement Unit',
  '*',
  '_',
  'C',
  'D',
  ''
];

const detailedViewDColumns = [
  'Raw Weight',
  'Cooked weight',
  'Special Requests Weight',
  'Special Requests Cooked Weight',
  'Sub-component raw weight',
  'Sub-component cooked weight',
  'Removed Raw weight',
  'Removed cooked weight'
];

export const prepareForExport = (
  workbook: ExcelJS.Workbook,
  summedComponentData: StatsFoodComponent[],
  filters: FoodStatsFilters,
  isDetailedView: boolean
) => {
  const dData = sortBy(summedComponentData, 'name.en').map((food) => food);
  const ws = workbook.addWorksheet('all-stats', {
    pageSetup: { fitToWidth: 1, fitToHeight: 0, pageOrder: 'overThenDown', orientation: 'portrait' }
  });
  const footer = '&C &15 All Stations Page (&P) of (&N)';
  ws.headerFooter.oddFooter = ws.headerFooter.evenFooter = footer;

  ws.mergeCells('A1', isDetailedView ? 'R1' : 'L1');
  ws.mergeCells('A2', isDetailedView ? 'R2' : 'L2');
  ws.mergeCells('A3', isDetailedView ? 'R3' : 'L3');
  ws.getRow(4).values = [...defaultColumns, ...(isDetailedView ? detailedViewDColumns : []), ...endDefaultColumns];
  ws.columns = columns(isDetailedView);
  ws.addRows(dData.map((d) => getRowData(d, isDetailedView)));
  ws.getCell('A1').value = 'All Stations';
  ws.getCell('A2').value = `production date: ${filters.day.gte} - ${filters.day.lte}`;
  ws.getCell('A3').value = `export date: ${format('dd-MM-yyyy')(Date.now())} - ${format('hh:mm aa')(Date.now())}`;
  ws.getCell('A1').font = { color: { argb: '0000' }, bold: true, size: 18 };
  ws.getCell('A1').alignment = {
    vertical: 'middle',
    horizontal: 'center'
  };
  ws.getCell('A2').font = { color: { argb: '0000' }, bold: true, size: 18 };
  ws.getCell('A2').alignment = {
    vertical: 'middle',
    horizontal: 'center'
  };
  ws.getCell('A3').font = { color: { argb: '0000' }, bold: true, size: 18 };
  ws.getCell('A3').alignment = {
    vertical: 'middle',
    horizontal: 'center'
  };
  for (const [key, value] of Object.entries(FoodComponentStation)) {
    const rows = sortBy(summedComponentData, 'name.en').filter((r) => r.cookingStation?.includes(key as FoodComponentStation));
    if (rows.length > 0) {
      const ws = workbook.addWorksheet(`${value}-stats`, {
        pageSetup: { fitToWidth: 1, fitToHeight: 0, pageOrder: 'overThenDown', orientation: 'portrait' }
      });
      const footer = `&C &15 ${value}-station Page (&P) of (&N)`;
      ws.headerFooter.oddFooter = ws.headerFooter.evenFooter = footer;
      ws.mergeCells('A1', isDetailedView ? 'R1' : 'L1');
      ws.mergeCells('A2', isDetailedView ? 'R2' : 'L2');
      ws.mergeCells('A3', isDetailedView ? 'R3' : 'L3');
      ws.getRow(4).values = [...defaultColumns, ...(isDetailedView ? detailedViewDColumns : []), ...endDefaultColumns];
      ws.columns = columns(isDetailedView);
      ws.addRows(rows.map((d) => getRowData(d, isDetailedView)));
      ws.getCell('A1').value = `${value}`;
      ws.getCell('A2').value = `production date: ${filters.day.gte} - ${filters.day.lte}`;
      ws.getCell('A3').value = `export day: ${format('dd-MM-yyyy')(Date.now())} - ${format('hh:mm aa')(Date.now())}`;
      ws.getCell('A1').font = { color: { argb: '0000' }, bold: true, size: 18 };
      ws.getCell('A1').alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      ws.getCell('A2').font = { color: { argb: '0000' }, bold: true, size: 18 };
      ws.getCell('A2').alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      ws.getCell('A3').font = { color: { argb: '0000' }, bold: true, size: 18 };
      ws.getCell('A3').alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
    }
  }
};
