import { Country, DeliveryTime, Kitchen } from '@calo/types';
import { GoogleMap } from '@react-google-maps/api';
import { getCenteredCountry } from 'lib/helpers';
import { Suspense, useEffect, useReducer, useState } from 'react';
import PolygonContext from '../MapView/PolygonContext';
import PolygonManager from '../MapView/PolygonManager';
import polygonReducer from '../MapView/polygonReducer';
import KitchenPolygonManager from './KitchenPolygonManager';
import KitchenPolygonReducer from './kitchenPolygonReducer';

interface KitchenMapViewProps {
  country: Country;
  day: string;
  kitchen: Kitchen;
  time: DeliveryTime;
}

const KitchenMapView = ({ country, day, time, kitchen }: KitchenMapViewProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [getCenter, setGetCenter] = useState<any>();
  const [polygonState, dispatch] = useReducer(KitchenPolygonReducer, { polygons: [] });
  const [polygonStateDelivery, dispatchDelivery] = useReducer(polygonReducer, { polygons: [] });

  useEffect(() => {
    setGetCenter(getCenteredCountry(country));
  }, [country]);

  const handleMapClick = () => {
    dispatch!({
      type: 'select',
      payload: null
    });
  };

  return (
    <div className="flex flex-1 flex-grow">
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMap
          mapContainerClassName="w-full h-full relative"
          center={getCenter}
          zoom={country === 'SA' ? 10 : 11}
          options={{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
          }}
          onClick={handleMapClick}
        >
          <PolygonContext.Provider value={dispatchDelivery}>
            <Suspense fallback={null}>
              <PolygonManager
                closePopUp={handleMapClick}
                day={day}
                isAreaView={true}
                time={time}
                polygonState={polygonStateDelivery}
                isEditing={false}
                setIsEditing={setIsEditing}
                country={country}
                kitchenZones={true}
                kitchen={kitchen}
              />
            </Suspense>
          </PolygonContext.Provider>

          <PolygonContext.Provider value={dispatch}>
            <Suspense fallback={null}>
              <KitchenPolygonManager
                kitchenID={kitchen}
                addressView={false}
                closePopUp={handleMapClick}
                polygonState={polygonState}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                country={country}
              />
            </Suspense>
          </PolygonContext.Provider>
          <Suspense fallback={null}></Suspense>
        </GoogleMap>
      </div>
    </div>
  );
};

export default KitchenMapView;
