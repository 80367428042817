import { format, parseISO } from 'date-fns/fp';

import { Permission } from '@calo/dashboard-types';
import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';

import { selectedFoodData } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';

interface FeedbackCardRowMobileProps {
  row: any;
}

const handleChangeURL = async (foodID: string) => {
  const selectedFeedBackFood = await selectedFoodData(foodID);
  return history.replace(Routes.foodSlug.replace(':slug', selectedFeedBackFood.slug));
};

const FeedbackCardRowMobile = ({ row }: FeedbackCardRowMobileProps) => {
  const roles = useUserRoles();

  return (
    <Card key={row.id} sx={{ width: 'full', mb: 2 }}>
      <CardHeader
        title={
          <Stack sx={{ width: 'full', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
            <Typography>
              {roles.includes(Permission.VIEW_FOOD_LIST_BY_SLUG) ? (
                <a onClick={() => handleChangeURL(row.food.id)}>{row.food.name.en}</a>
              ) : (
                `${row.food.name.en}`
              )}
            </Typography>
            <Typography>{row.rating}</Typography>
          </Stack>
        }
      />
      <CardContent>
        <Stack sx={{ color: caloTheme.palette.neutral900, my: 1, width: 'full' }}>
          Review
          <Typography sx={{ my: 1, width: 'full' }}>{row.review}</Typography>
        </Stack>
        <Stack sx={{ color: caloTheme.palette.neutral900, my: 1, width: 'full' }}>
          Created At
          <Typography sx={{ my: 1, width: 'full' }}>{row.createdAt && format('dd-MM-yyyy')(parseISO(row.createdAt))}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
export default FeedbackCardRowMobile;
