import { caloTheme } from 'assets/images/theme/calo';
const styles = {
  addNewSectionBtn: {
    textTransform: 'none',
    height: '38px',
    ml: 2,
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
    padding: '14px 20px 14px 20px',
    color: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    '&:hover': {
      backgroundColor: caloTheme.palette.primary100,
      borderColor: caloTheme.palette.primary500
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      justifyItems: 'center',
      margin: 'auto',
      marginTop: 4,
      width: 'auto'
    }
  },
  trashBtn: {
    textAlign: 'center',
    height: '38px',
    borderRadius: '8px',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: caloTheme.palette.secondaryPink300
    }
  }
};
export default styles;
