import { Subscription } from '@calo/dashboard-types';
import { updatePaymentDelivery } from 'actions';
import mutation from 'actions/mutation';
import { Delivery } from 'lib/interfaces';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

export default () => {
  const { mutateAsync: updateMutation } = useMutation(updatePaymentDelivery);

  return (delivery: Delivery, subscription: Subscription) =>
    updateMutation(
      {
        id: delivery.id
      },
      {
        onSuccess: (data) => {
          const paidAmount = data.paidAmount - delivery.paidAmount;
          mutation(['subscriptions', subscription.id], { balance: subscription.balance[subscription.currency] - paidAmount });
          toast('paid', { type: 'success', autoClose: 2000 });
        }
      }
    );
};
