import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import { BusinessOrderFilters, UpdateAccountSubscriptionReq } from 'lib/interfaces';
import mutation from './mutation';

export const createBusinessAccountSubscription = async (values: any) => {
  const { data, status } = await client.post('business/users', values);
  mutation(['business/users', data.id], data);
  ToastSuccessMsgs({ status, action: 'New User created' });
  return data;
};

export const updateBusinessAccount = async (payload: { data: UpdateAccountSubscriptionReq; accId: string }) => {
  const { accId, ...rest } = payload;
  const { data, status } = await client.put(`business/users/${accId}`, rest.data);
  mutation(['business/users', accId], data);
  ToastSuccessMsgs({ status, action: 'Updated successfully' });
  return data;
};

export const createBusinessAccountDelivery = async (ny: any) => {
  const { data, status } = await client.post(`business/deliveries`, ny.values);
  ToastSuccessMsgs({ status, action: 'Order created' });
  return data;
};

export const deleteBusinessAccountDelivery = async (id: string) => {
  const { status } = await client.delete(`business/deliveries/${id}`);
  ToastSuccessMsgs({ status, action: 'Order deleted' });
};

export const updateBusinessAccountDelivery = async (payload: { data: any; ddId: string }) => {
  const { ddId, ...rest } = payload;
  const { data, status } = await client.put(`business/deliveries/${ddId}`, rest.data);
  mutation(['business/deliveries', data.id], data);
  ToastSuccessMsgs({ status, action: 'Updated successfully ' });
  return data;
};

export const exportAccounts = async (filters: any) => {
  const { data, status } = await client.get('business/users/export', { params: filters });
  ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
  return data;
};

export const updateBussinessAddress = async (payload: any & { id: string; daid: string }) => {
  const { id: _id, daid, ...rest } = payload;
  const { data } = await client.put(`/business/delivery-address/${daid}`, rest);
  return data;
};

export const exportBusinessOrders = async (filters: BusinessOrderFilters) => {
  const { data, status } = await client.get('business/deliveries/export', { params: filters });
  ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
  return data;
};
