import { useEffect, useState } from 'react';

import cx from 'classnames';
import { format, parseISO } from 'date-fns/fp';
import { uniq } from 'lodash-es';
import DatePicker from 'react-datepicker';

import { DeliveryTicketType } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';

import { CaloLoader, Icon, Select } from 'components';
import { selectStyles } from 'lib/componentStyles';
import { useUserKitchens } from 'lib/hooks';

const handleMealRequestSent = (req: any) => {
  if (req.data.type === DeliveryTicketType.MEAL) {
    const swaps = req.data.mealTicketData.map(
      (item) =>
        `${item.oldFood.name?.en || '--'} (${item.oldFood.size}) -> ${item.newFood.name?.en || '--'} (${item.newFood.size})`
    );
    return swaps.join('\n');
  } else if (req.data.type === DeliveryTicketType.MANUAL) {
    return req.data.manualTicketData?.foods.map((meal: any) => (
      <p key={`${meal.id}-${req.datacreatedAt}`}>
        {meal.name.en} ({meal.size})
      </p>
    ));
  } else {
    return <p>-</p>;
  }
};
interface RequestHistoryTableProps {
  isLoading: boolean;
  selectedDateReq: string;
  selectedKitchen: Kitchen;
  sentRequestData: any[];
  setSelectedDateReq: (value: string) => void;
  setSelectedKitchen: (value: Kitchen) => void;
}

const RequestHistoryTable = ({
  isLoading,
  selectedDateReq,
  sentRequestData,
  selectedKitchen,
  setSelectedKitchen,
  setSelectedDateReq
}: RequestHistoryTableProps) => {
  const [CXAgent, setCXAgent] = useState<string[]>([]);
  const [selectedCXAgent, setSelectedCXAgent] = useState<string | undefined>(undefined);

  const userKitchens: string[] = useUserKitchens();

  useEffect(() => {
    setCXAgent([]);
    setSelectedCXAgent(undefined);
    sentRequestData?.map((req) => setCXAgent((old) => uniq([...old, req?.createdBy.name])));
  }, [selectedKitchen, sentRequestData]);

  const handleFilteredList = () => {
    return selectedCXAgent ? sentRequestData.filter((r) => r.createdBy.name === selectedCXAgent) || [] : sentRequestData;
  };

  return (
    <>
      <section className="section is-title-bar my-6 flex flex-row gap-2">
        <p className="uppercase font-roboto" style={{ fontSize: '28px', width: '40%' }}>
          Requests Sent
        </p>
        <div className="flex flex-row -mt-8 -mb-6 w-2/3 items-center justify-end gap-2">
          <div className="w-2/6">
            <Select
              label="Kitchen"
              value={selectedKitchen}
              className="w-28 mr-2"
              onChange={(data: any) => setSelectedKitchen(data.value as Kitchen)}
              options={userKitchens.map((type) => ({
                value: type,
                label: type
              }))}
              customStyles={selectStyles}
            />
          </div>
          <div className="w-2/6">
            <Select
              label="CX Agent"
              value={selectedCXAgent}
              onChange={(data: any) => setSelectedCXAgent(data.value)}
              options={CXAgent.map((type) => ({
                value: type,
                label: type
              }))}
              customStyles={selectStyles}
            />
          </div>
          <div className="w-2/5">
            <DatePicker
              selected={parseISO(selectedDateReq)}
              onChange={(date: any) => setSelectedDateReq(format('yyyy-MM-dd')(date))}
              customInput={
                <span className="outline-none border-2 mt-8 border-cGreen rounded-lg text-cGreen flex justify-between">
                  <label className="w-6/12 mx-2 my-1.5">{format('dd/MM/yyyy')(parseISO(selectedDateReq))}</label>
                  <Icon name="calenderIcon" size={7} className="px-1 mt-1.5 ml-2" />
                </span>
              }
            />
          </div>
        </div>
      </section>
      <span className={' flex flex-row mb-2 py-2 font-bold w-full'}>
        <p className="text-md w-1/4 text-center m-auto">Action</p>
        <p className="text-md w-1/6 text-center m-auto">Country</p>
        <p className="text-md w-1/6 text-center m-auto">CX Agent</p>
        <p className="text-md w-1/4 text-center m-auto">Date</p>
        <p className="text-md w-1/4 text-center m-auto">Phone Number</p>
        <p className="text-md w-1/2 text-center mx-auto my-auto">Meals</p>
      </span>
      <div className="mb-4 -mt-2 h-64 max-h-64 overflow-y-auto w-full">
        {isLoading ? (
          <div>
            <CaloLoader />
          </div>
        ) : sentRequestData && sentRequestData?.length !== 0 ? (
          handleFilteredList().map((req) => (
            <span
              key={`${req.id}-${req.data.type}-${req.createdAt}`}
              className={cx('flex flex-row mb-2 h-auto py-2 font-bold w-full', {
                'bg-gray-50 text-black': req.status === 'pending',
                'bg-green-50 text-black': req.status === 'approved',
                'bg-gray-50 text-gray-400': req.status === 'rejected'
              })}
            >
              <p className="text-md w-1/4 text-center m-auto">
                {req.data.type === DeliveryTicketType.MEAL
                  ? 'Meal Swap'
                  : req.data.type === DeliveryTicketType.MANUAL
                    ? 'Manual Delivery'
                    : 'Other'}
              </p>
              <p className="text-md w-1/6 text-center m-auto border-gray-500 border-l-2">{req.country}</p>
              <p className="text-md w-1/6 text-center m-auto border-l-2 border-r-2 border-gray-500">{req.createdBy.name}</p>
              <p className="text-md w-1/4 text-center m-auto border-r-2 border-gray-500">{req.date}</p>
              <p className="text-md w-1/4 text-center m-auto border-r-2 border-gray-500">{req.data.phoneNumber || '--'}</p>
              <p className="text-md w-1/2 text-center mx-auto my-auto">{handleMealRequestSent(req)}</p>
            </span>
          ))
        ) : (
          <div className="flex justify-center my-4">
            <p className="text-2xl font-bold text-gray-400">No Requests for the Selected Kitchen</p>
          </div>
        )}
      </div>
    </>
  );
};

export default RequestHistoryTable;
