import { HeatingInstruction } from '@calo/types';

export const resolveHeatingInstruction = (heatingInstruction: HeatingInstruction) => {
  switch (heatingInstruction) {
    case HeatingInstruction.HEATING_WITH_SAUCE:
      return 'Heating with Sauce';
    case HeatingInstruction.HEATING_NO_SAUCE:
      return 'Heating no Sauce';
    case HeatingInstruction.COLD:
      return 'Cold';
    case HeatingInstruction.HEATING_SANDWICH:
      return 'Heating Sandwich';
    case HeatingInstruction.HEATING_SNACK:
      return 'Heating Snack';
    case HeatingInstruction.NONE:
      return 'None';
    default:
      return '';
  }
};
