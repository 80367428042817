import { compact, uniq } from 'lodash-es';
import { Food } from '../interfaces';

export const resolveSize = (size?: string) => {
  if (!size) {
    return 'None';
  }
  switch (size) {
    case 'S':
      return 'Small';
    case 'M':
      return 'Medium';
    case 'L':
      return 'Large';
    case 'XS':
      return 'X Small';
    case 'XL':
      return 'X Large';
    default:
      return size;
  }
};

export const getAvailableMealSizes = (mealSizes: string[], allSizesFood?: Food[]) => {
  const sizes = uniq(mealSizes);
  const usedSizes = allSizesFood ? allSizesFood.map((f) => f.size) : [];
  return sizes.filter((s) => !usedSizes.includes(s));
};

export const orderMealsBySize = (food: Food[]) => {
  if (!food) return [];
  const orderedSize = ['XS', 'S', 'M', 'L', 'XL'];
  const foodsWithExistingSize: Food[] = [];
  const foodsWithOtherSizes: Food[] = [];
  for (const size of orderedSize) {
    foodsWithExistingSize.push(...food.filter((f) => f.size === size));
  }
  for (const f of food) {
    const index = orderedSize.indexOf(f.size);
    if (index === -1) {
      foodsWithOtherSizes.push(f);
    }
  }
  return compact([...foodsWithExistingSize, ...foodsWithOtherSizes]);
};
