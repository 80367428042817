import { Icon as Iconify } from '@iconify/react';
import { History } from 'history';
import { isEmpty } from 'lodash';

import { FoodComponentFilters, FoodFilters, IngredientsFilters } from '@calo/dashboard-types';
import AddIcon from '@mui/icons-material/Add';
import { Button, Card, IconButton, Stack, Typography } from '@mui/material';
import { toggleUISettings } from 'actions/ui';
import { caloTheme } from 'assets/images/theme/calo';
import ExportButton from 'components/ExportButton';
import { Food, FoodComponent, Ingredient } from 'lib/interfaces';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getAddRoute, handleExport } from './helper';
import { styles } from './style';

interface SharedListHeaderCardProps {
  filters: IngredientsFilters | FoodComponentFilters | FoodFilters;
  history: History<unknown>;
  list: Ingredient[] | FoodComponent[] | Food[];
  isDisabled?: boolean;
  listType: 'food' | 'Components' | 'Ingredients';
}

const SharedListHeaderCard = ({ listType, filters, history, list, isDisabled }: SharedListHeaderCardProps) => {
  const addRoute = useMemo(() => getAddRoute(listType), [listType]);
  const dispatch = useDispatch();

  const handleAddClick = () => {
    if (addRoute) {
      history.push(addRoute);
    }
  };

  const handleExportClick = () => {
    handleExport(listType, filters);
  };
  return (
    <Card variant="outlined" sx={styles.card}>
      <Typography sx={styles.text}>
        {listType} - {filters.country} / {filters.kitchen}
      </Typography>
      <Stack sx={styles.stack}>
        <IconButton sx={{ cursor: 'pointer', px: '12px' }} onClick={() => dispatch(toggleUISettings())}>
          <Iconify icon="cil:list-filter" width={24} color={caloTheme.palette.neutral900} />
        </IconButton>
        <Button
          variant="outlined"
          sx={styles.button}
          startIcon={<AddIcon />}
          disabled={Boolean(isDisabled)}
          onClick={handleAddClick}
        >
          Add
        </Button>
        <ExportButton isLoading={false} isDisabled={isEmpty(list)} onClick={handleExportClick} />
      </Stack>
    </Card>
  );
};

export default SharedListHeaderCard;
