import { CreateFoodReq, FoodMenuLabelsTags, UpdateFoodReq } from '@calo/dashboard-types';
import { IngredientCategory } from '@calo/types';
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { foodInformationFormSingleSelectCustomStyles } from 'lib/componentStyles';
import { getExcludedFoodTags, getLabelStyles } from 'lib/helpers';
import { BaseOmit } from 'lib/interfaces';
import { startCase } from 'lodash-es';
import React, { useMemo } from 'react';
import SelectMUI from '../SelectMUI';
import { styles } from './styles';

interface MenuLabelSelectorProps {
  selectedMenuTag: any;
  menuLabels: any[];
  isDisabled: boolean;
  setSelectedMenuTag: (tag: any) => void;
  foodMenuDateRangeRef: React.RefObject<any>;
  handleRemoveMenuTag: (index: number) => void;
  setFieldValue: (field: string, value: any) => void;
  values: Omit<CreateFoodReq, 'id'> | Omit<UpdateFoodReq, BaseOmit>;
}

const MenuLabelSelector: React.FC<MenuLabelSelectorProps> = ({
  values,
  selectedMenuTag,
  menuLabels,
  isDisabled,
  foodMenuDateRangeRef,
  setSelectedMenuTag,
  setFieldValue,
  handleRemoveMenuTag
}) => {
  const automatedFoodTags = useMemo(
    () => getExcludedFoodTags(values, [IngredientCategory.dairy, IngredientCategory.gluten]),
    [values.ingredients]
  );

  const handleSelectChange = (data: any) => {
    if (data.value) {
      setSelectedMenuTag(data.value);
      foodMenuDateRangeRef.current?.open();
    } else {
      setSelectedMenuTag(data.value);
      setFieldValue('menuLabels', []);
    }
  };

  return (
    <Stack direction="row" justifyContent="space-evenly" alignItems="baseline" spacing={2}>
      <Box sx={{ width: '100%' }}>
        <SelectMUI
          label="Labels"
          value={selectedMenuTag}
          data-test="exact-meal-foodTags-rating"
          onChange={handleSelectChange}
          options={[
            { label: 'None', value: undefined as any },
            ...Object.values(FoodMenuLabelsTags).map((menuTag) => ({
              value: menuTag,
              label: menuTag === FoodMenuLabelsTags.UPGRADED ? 'Improved' : startCase(FoodMenuLabelsTags[menuTag].toLowerCase())
            }))
          ]}
          isDisabled={isDisabled}
          customStyles={foodInformationFormSingleSelectCustomStyles}
        />
        <Stack sx={{ flexDirection: 'row', gap: '12px', marginTop: '16px' }}>
          {menuLabels.map((value, index) => {
            const { backgroundColor, color } = getLabelStyles(value?.startDay, value?.endDay);
            return (
              <Box key={value.label} sx={[styles.menuLabelBox, { backgroundColor }]}>
                <Typography sx={[styles.menuLabelTypography, { color }]}>
                  {`${startCase(value.label === FoodMenuLabelsTags.UPGRADED ? 'Improved' : value.label.toLowerCase())} ${format(parseISO(value.startDay), 'dd/MM/yyyy')} - ${format(parseISO(value.endDay), 'dd/MM/yyyy')}`}
                </Typography>
                <CloseIcon width={'24px'} sx={styles.removeMenuTags} onClick={() => handleRemoveMenuTag(index)} />
              </Box>
            );
          })}
          {automatedFoodTags.map((tag) => (
            <Typography key={tag} sx={styles.menuLabelTypography}>
              {startCase(tag)}
            </Typography>
          ))}
        </Stack>
        {/* <Box sx={{ width: '20%' }}>
          <SelectMUI
            label="Flavors Profile"
            value={flavorsProfile}
            data-test="exact-meal-foodTags-flavorsProfile"
            onChange={(data: any) => {
              setFlavorsProfile(data.value);
              setFieldValue(
                'foodTags',
                compact([
                  ...((values.foodTags as any) || []).filter((k: any) => k.key !== FoodTagsKeys.flavorsProfile),
                  ...(data.value ? [{ key: FoodTagsKeys.flavorsProfile, value: data.value }] : [])
                ])
              );
            }}
            options={[
              {
                label: 'None',
                value: undefined
              },
              ...Object.values(FlavorsProfile).map((flavorsProfile) => ({
                value: flavorsProfile,
                label: startCase(lowerCase(flavorsProfile))
              }))
            ]}
            isDisabled={isDisabled}
            customStyles={foodInformationFormSingleSelectCustomStyles}
          />
        </Box>
        <Box sx={{ width: '20%' }}>
          <SelectMUI
            label="Hint Flavors"
            value={hintFlavors}
            data-test="exact-meal-foodTags-hintFlavors"
            onChange={(data: any) => {
              setHintFlavors(data.value);
              setFieldValue(
                'foodTags',
                compact([
                  ...((values.foodTags as any) || []).filter((k: any) => k.key !== FoodTagsKeys.hintFlavors),
                  ...(data.value ? [{ key: FoodTagsKeys.hintFlavors, value: data.value }] : [])
                ])
              );
            }}
            options={[
              {
                label: 'None',
                value: undefined
              },
              ...Object.values(HintFlavors).map((hintFlavors) => ({
                value: hintFlavors,
                label: startCase(lowerCase(hintFlavors))
              }))
            ]}
            isDisabled={isDisabled}
            customStyles={foodInformationFormSingleSelectCustomStyles}
          />
        </Box>
        <Box sx={{ width: '20%' }}>
          <SelectMUI
            label="EatingExperience"
            value={eatingExperience}
            data-test="exact-meal-foodTags-eatingExperience"
            onChange={(data: any) => {
              setEatingExperience(data.value);
              setFieldValue(
                'foodTags',
                compact([
                  ...((values.foodTags as any) || []).filter((k: any) => k.key !== FoodTagsKeys.eatingExperience),
                  ...(data.value ? [{ key: FoodTagsKeys.eatingExperience, value: data.value }] : [])
                ])
              );
            }}
            options={[
              {
                label: 'None',
                value: undefined
              },
              ...Object.values(EatingExperience).map((eatingExperience) => ({
                value: eatingExperience,
                label: startCase(lowerCase(eatingExperience))
              }))
            ]}
            isDisabled={isDisabled}
            customStyles={foodInformationFormSingleSelectCustomStyles}
          />
        </Box> */}
      </Box>
      {/* <Box sx={{ width: '100%' }}>
        <SelectMUI
          isMulti
          label="Tags"
          id="foodInfoTagsSelect"
          value={categoryTags}
          data-test="exact-meal-foodTags-category"
          onChange={handleCategoryTagSelect}
          options={Object.values(FoodCategory).map((category) => ({
            value: category,
            label: startCase(category)
          }))}
          isDisabled={isDisabled}
          customStyles={foodInformationFormMultiSelectCustomStyles()}
        />
      </Box> */}
    </Stack>
  );
};
export default MenuLabelSelector;
