import { DeliveryAddress } from '@calo/types';

export const getAddressForDeliveryTicket = (address: DeliveryAddress): Partial<DeliveryAddress> => {
  const {
    id,
    name,
    lat,
    lng,
    kitchen,
    type,
    apartment,
    office,
    building,
    street,
    district,
    city,
    region,
    country,
    customDeliveryTime
  } = address;
  return {
    id,
    name,
    lat,
    lng,
    kitchen,
    type,
    apartment,
    office,
    building,
    street,
    district,
    city,
    region,
    country,
    customDeliveryTime
  } as Partial<DeliveryAddress>;
};
