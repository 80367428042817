import { SupplyChainUserCreateReq, SupplyChainUserUpdateReq } from '@calo/dashboard-types';
import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import mutation from './mutation';

export const createSupplyChainUser = async (values: SupplyChainUserCreateReq) => {
  const { data, status } = await client.post('supply-chain-users', values);
  ToastSuccessMsgs({ status, action: 'Supply-chain-User created successfully' });
  mutation(['supply-chain-users', data.id], data);
  return data;
};

export const updateSupplyChainUser = async (payload: SupplyChainUserUpdateReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`supply-chain-users/${id}`, rest);
  ToastSuccessMsgs({ status, action: 'Supply-chain-User updated successfully' });
  mutation(['supply-chain-users', id], data);
};

export const deleteSupplyChainUser = async (userId: string) => {
  const { status } = await client.delete(`supply-chain-users/${userId}`);
  ToastSuccessMsgs({ status, action: 'Supply-chain-User deleted successfully' });
};

export const resetSupplyChainUserPassword = async (payload: { userId: string; newPassword: string }) => {
  const { data, status } = await client.put(`/supply-chain-users/${payload.userId}/reset-password`, {
    password: payload.newPassword
  });
  ToastSuccessMsgs({ status, action: 'Supply-chain-User password changed successfully' });
  return data;
};
