import { Permission } from '@calo/dashboard-types';
import { LatLng } from '@calo/driver-types';
import { Country, DDeliveryStatus, DeliveryTime, Kitchen } from '@calo/types';
import { GoogleMap, InfoWindow, Marker, Polyline } from '@react-google-maps/api';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { multiPolygon, point } from '@turf/helpers';
import { getRoutePlanPath } from 'actions';
import { MapSidebar } from 'components';
import { getCenteredKitchen } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { Delivery, DeliveryPlan, DriverPinData } from 'lib/interfaces';
import { keyBy } from 'lodash-es';
import { Suspense, useEffect, useMemo, useReducer, useState } from 'react';
import DeliveryRow from '../DeliveryRow';
import DriverTracking from './DriverTracking';
import PolygonContext from './PolygonContext';
import PolygonManager from './PolygonManager';
import polygonReducer from './polygonReducer';

interface MapViewProps {
  deliveries: Delivery[];
  day: string;
  time: DeliveryTime;
  updateDeliveries: (id: string, canceled: boolean) => void;
  deliveryPlans: DeliveryPlan[];
  kitchen: Kitchen;
  country: Country;
}

interface RoutePath {
  waypoints: LatLng[];
}

const renderRoute = (path: RoutePath[], opacity: number, weight: number, color: string) =>
  path.map((wp, i) => (
    <Polyline
      path={wp.waypoints}
      key={i}
      options={{
        strokeColor: color,
        strokeOpacity: opacity,
        strokeWeight: weight
      }}
    />
  ));

const renderMarker = (delivery: Delivery, handleMarkerClick: (delivery: Delivery) => void, mpoly: any) => {
  delivery.kitchen === Kitchen.AE1 && console.log('Rendering marker for delivery:', delivery);

  return (
    <Marker
      key={delivery.id}
      position={{
        lat: delivery.deliveryAddress.lat,
        lng: delivery.deliveryAddress.lng
      }}
      onClick={() => handleMarkerClick(delivery)}
      icon={
        booleanPointInPolygon(point([delivery.deliveryAddress.lng, delivery.deliveryAddress.lat]), mpoly)
          ? 'https://maps.google.com/mapfiles/ms/micons/green.png'
          : 'https://maps.google.com/mapfiles/ms/micons/red.png'
      }
    />
  );
};

const MapView = ({ deliveries, day, time, updateDeliveries, deliveryPlans, country, kitchen }: MapViewProps) => {
  const [map, setMap] = useState<google.maps.Map>();
  const [isEditing, setIsEditing] = useState(false);
  const [isAreaView, setIsAreaView] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedDeliveries, setSelectedDeliveries] = useState<Delivery[]>([]);
  const [sideBarSelectedDeliveries, setSideBarSelectedDeliveries] = useState<null | Delivery[]>(null);
  const [sideBar, SetSideBar] = useState(false);
  const [selectedDeliveryPlan, setSelectedDeliveryPlan] = useState<DeliveryPlan | null>(null);
  const [completeSelectedRoute, setCompleteSelectedRoute] = useState<RoutePath[] | null>([]);
  const [getCenter, setGetCenter] = useState<any>();
  const roles = useUserRoles();

  const [polygonState, dispatch] = useReducer(polygonReducer, { polygons: [] });

  useEffect(() => {
    setIsEditing(false);
    setGetCenter(getCenteredKitchen(kitchen));
  }, [day, time, country, kitchen]);

  const deliveryDetails = useMemo(() => {
    const unassigned: Delivery[] = [];
    const drivers: DriverPinData[] = [];
    const assigned: any = {};

    for (const el of deliveries) {
      if (el.driver !== undefined && el.driver.id !== '') {
        if (assigned[el.driver.id] === undefined) {
          assigned[el.driver.id] = { data: [el] };
          drivers.push(el.driver);
        } else {
          assigned[el.driver.id].data.push(el);
        }
      } else {
        unassigned.push(el);
      }
    }
    return { drivers, assigned, unassigned };
  }, [deliveries]);

  useEffect(() => {
    if (selectedDeliveryPlan) {
      getRoutePlanPath(selectedDeliveryPlan.id)
        .then((response) => {
          setCompleteSelectedRoute(response);
        })
        .catch(() => {
          setCompleteSelectedRoute(null);
        });
    } else {
      setCompleteSelectedRoute(null);
    }
  }, [selectedDeliveryPlan]);

  const handleMarkerClick = (delivery: Delivery) => {
    const closeDeliveries = deliveries.filter(
      (d) => d.deliveryAddress.lat === delivery.deliveryAddress.lat && d.deliveryAddress.lng === delivery.deliveryAddress.lng
    );
    setSelectedIndex(0);
    setSelectedDeliveries(closeDeliveries);
    dispatch!({
      type: 'select',
      payload: null
    });
    // onPolygonSelect(null)
  };

  const handleMapClick = () => {
    setSelectedDeliveries([]);
    dispatch!({
      type: 'select',
      payload: null
    });
    // onPolygonSelect(null)
  };

  const mpoly = multiPolygon(
    polygonState.polygons.map((row) => [
      [
        ...row.polygon
          .getPath()
          .getArray()
          .map((b) => [b.lng(), b.lat()]),
        [row.polygon.getPath().getArray()[0].lng(), row.polygon.getPath().getArray()[0].lat()]
      ]
    ])
  );

  const handleSelectingDeliveryPlan = (deliveryPlan: DeliveryPlan | null) => {
    setSelectedDeliveryPlan(deliveryPlan);
  };

  return (
    <div className="flex flex-1 flex-grow" key={`${kitchen}-${day}-${time}`}>
      <MapSidebar
        country={country}
        isOpen={sideBar}
        handleMarkerClick={handleMarkerClick}
        updateDeliveries={updateDeliveries}
        unassigned={deliveryDetails.unassigned}
        assigned={deliveryDetails.assigned}
        deliveriesLength={deliveries.length}
        deliveryPlans={deliveryPlans}
        setSelectedDeliveryPlan={handleSelectingDeliveryPlan}
        selectedDeliveryPlan={selectedDeliveryPlan}
        day={day}
        time={time}
        setIsAreaView={setIsAreaView}
        isAreaView={isAreaView}
        polygonState={polygonState}
        selectedDeliveries={sideBarSelectedDeliveries}
        setSelectedDeliveries={setSideBarSelectedDeliveries}
      />
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMap
          mapContainerClassName="w-full h-full relative"
          center={getCenter}
          zoom={country === 'SA' ? 10 : 11}
          options={{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
          }}
          onClick={handleMapClick}
          onLoad={setMap}
        >
          {roles.includes(Permission.VIEW_ROUTE_PLAN_LIST) && (
            <div className=" absolute z-10 bg-white">
              <a className="navbar-item" onClick={() => SetSideBar(!sideBar)}>
                <i className="fas fa-bars"></i>
              </a>
            </div>
          )}
          <DriverTracking country={country} map={map} driversData={keyBy(deliveryDetails.drivers, 'id')} />
          {isAreaView
            ? sideBarSelectedDeliveries?.map((delivery) => renderMarker(delivery, handleMarkerClick, mpoly))
            : deliveries
                .filter((d) => d.deliveryStatus !== DDeliveryStatus.delivered)
                .map((delivery) => renderMarker(delivery, handleMarkerClick, mpoly))}
          {!isAreaView && completeSelectedRoute && renderRoute(completeSelectedRoute, 1, 5, 'black')}
          <PolygonContext.Provider value={dispatch}>
            <Suspense fallback={null} key={`${kitchen}-${day}-${time}`}>
              <PolygonManager
                closePopUp={handleMapClick}
                day={day}
                isAreaView={isAreaView}
                time={time}
                polygonState={polygonState}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                country={country}
                kitchen={kitchen}
              />
            </Suspense>
          </PolygonContext.Provider>
          {selectedDeliveries.length > 0 && (
            <InfoWindow
              position={{
                lat: selectedDeliveries[0].deliveryAddress.lat,
                lng: selectedDeliveries[0].deliveryAddress.lng
              }}
              onCloseClick={() => setSelectedDeliveries([])}
            >
              <>
                <div className="flex-row justify-center items-center">
                  <button
                    className="hover:text-black focus:outline-none py-1 px-3 self-start"
                    onClick={() => {
                      setSelectedIndex(selectedIndex === 0 ? selectedDeliveries.length - 1 : selectedIndex - 1);
                    }}
                  >
                    <i className="fas fa-arrow-left fa-lg"></i>
                  </button>
                  <p className="px-3">
                    {' '}
                    {selectedIndex + 1}/{selectedDeliveries.length}{' '}
                  </p>
                  <button
                    className="hover:text-black focus:outline-none py-1 px-3 self-start"
                    onClick={() => {
                      setSelectedIndex(selectedIndex === selectedDeliveries.length - 1 ? 0 : selectedIndex + 1);
                    }}
                  >
                    <i className="fas fa-arrow-right fa-lg"></i>
                  </button>
                </div>
                <DeliveryRow delivery={selectedDeliveries[selectedIndex]} />
              </>
            </InfoWindow>
          )}
          <Suspense fallback={null}></Suspense>
        </GoogleMap>
      </div>
    </div>
  );
};

export default MapView;
