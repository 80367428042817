import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Route, Switch } from 'react-router-dom';
import GiftList from './GiftList';

const Gift = () => {
  const roles = useUserRoles();

  return (
    <Switch>
      {roles.includes(Permission.ADD_FOOD_DELIVERY_MENU) && (
        <Route key={Routes.gifts} exact path={Routes.gifts} component={GiftList} />
      )}
    </Switch>
  );
};
export default Gift;
