import { Macros } from '@calo/types';
import { orderMealsBySize } from 'lib/helpers';
import { Food, FoodComponent } from 'lib/interfaces';
import { upperFirst } from 'lodash-es';

export const displayMessageForMacrosLimits = (
  allSizesFood: Food[],
  food: Food,
  macros: Macros,
  macrosProp: 'carbs' | 'cal' | 'protein' | 'fat'
) => {
  const defaultSizes = ['XS', 'S', 'M', 'L', 'XL'];
  const existingSizes = orderMealsBySize(allSizesFood).map((food) => food.size);
  if (defaultSizes.includes(food.size) && food.macros[macrosProp] && macros && macros[macrosProp]) {
    const index = defaultSizes.indexOf(food.size);
    const existingSizeIndex = existingSizes.indexOf(food.size);
    if (index === -1) return '';
    const hightLimit = allSizesFood.find((food) => food.size === existingSizes[existingSizeIndex + 1])?.macros[macrosProp];
    const lowLimit = allSizesFood.find((food) => food.size === existingSizes[existingSizeIndex - 1])?.macros[macrosProp];
    if (lowLimit && lowLimit > macros[macrosProp]) return `${upperFirst(macrosProp)} amount is less than on a smaller meal`;
    if (hightLimit && hightLimit < macros[macrosProp]) return `${upperFirst(macrosProp)} amount is more than on a larger meal`;
    return '';
  }
  return '';
};

export const selectCompIngredientsAttribtues = (component: FoodComponent | undefined) => {
  return component?.ingredients?.map((r: any) => ({
    id: r.id,
    category: r.category,
    name: r.name,
    slug: r.slug
  }));
};
