import { Button, TableBody, TableRow } from '@mui/material';
import { StyledStatsTableCell } from 'lib/componentStyles';
import { StatsFoodComponent } from 'lib/interfaces';
import { v4 as uuid } from 'uuid';
import {
  calcualteSubComponentCookedWeight,
  calculateRemovedCookedWeight,
  calculateSubComponentRawWeight,
  calculateTotalCookedWeight,
  calculateTotalRawWeight,
  getMeasurementUnit
} from './helpers';
import { styles } from './styles';

interface KitchenComponentStatsTableRowProps {
  switchUnit: boolean;
  showDetailedView: boolean;
  selectedCustomSize?: string;
  setIsDrawerOpen: (value: boolean) => void;
  filteredComponentData: StatsFoodComponent[];
  setDrawerComponent: (value: StatsFoodComponent) => void;
}
const KitchenComponentStatsTableRow = ({
  switchUnit,
  setIsDrawerOpen,
  showDetailedView,
  setDrawerComponent,
  filteredComponentData
}: KitchenComponentStatsTableRowProps) => (
  <TableBody>
    {filteredComponentData.map((row) => (
      <TableRow key={uuid()}>
        <StyledStatsTableCell sx={{ fontWeight: 600, textTransform: 'capitalize' }}>
          <Button
            variant="text"
            sx={styles.button}
            onClick={() => {
              setDrawerComponent(row);
              setIsDrawerOpen(true);
            }}
          >
            {row.name.en}
          </Button>
        </StyledStatsTableCell>
        <StyledStatsTableCell sx={{ fontWeight: 400 }}>{row.kitchen}</StyledStatsTableCell>
        {showDetailedView && (
          <>
            <StyledStatsTableCell sx={{ fontWeight: 400 }}>
              {Math.ceil(row.stats.rawWeight - row.stats.rawSpecialRequestsWeight)}
            </StyledStatsTableCell>
            <StyledStatsTableCell sx={{ fontWeight: 400 }}>
              {switchUnit
                ? row.stats.quantity - row.stats.specialRequestsQuantity
                : Math.ceil(row.stats.cookedWeight - row.stats.cookedSpecialRequestsWeight)}
            </StyledStatsTableCell>
            <StyledStatsTableCell sx={{ fontWeight: 400 }}>{Math.ceil(row.stats.rawSpecialRequestsWeight)}</StyledStatsTableCell>
            <StyledStatsTableCell sx={{ fontWeight: 400 }}>
              {switchUnit ? row.stats.specialRequestsQuantity : Math.ceil(row.stats.cookedSpecialRequestsWeight)}
            </StyledStatsTableCell>
            <StyledStatsTableCell sx={{ fontWeight: 400 }}>
              {calculateSubComponentRawWeight(switchUnit, row)}
            </StyledStatsTableCell>
            <StyledStatsTableCell sx={{ fontWeight: 400 }}>
              {calcualteSubComponentCookedWeight(switchUnit, row)}
            </StyledStatsTableCell>
            <StyledStatsTableCell sx={{ fontWeight: 400 }}>{Math.ceil(row.stats.removedRawWeight)}</StyledStatsTableCell>
            <StyledStatsTableCell sx={{ fontWeight: 400 }}>{calculateRemovedCookedWeight(switchUnit, row)}</StyledStatsTableCell>
          </>
        )}
        <StyledStatsTableCell sx={{ fontWeight: 400 }}>{calculateTotalRawWeight(row)}</StyledStatsTableCell>
        <StyledStatsTableCell sx={{ fontWeight: 400 }}>{calculateTotalCookedWeight(switchUnit, row)}</StyledStatsTableCell>

        <StyledStatsTableCell colSpan={2} sx={{ fontWeight: 400 }}>
          {getMeasurementUnit(switchUnit, row)}
        </StyledStatsTableCell>
      </TableRow>
    ))}
  </TableBody>
);
export default KitchenComponentStatsTableRow;
