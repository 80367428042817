import DateFnsAdapter from '@date-io/date-fns';
import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider, PickersDay, pickersDayClasses } from '@mui/x-date-pickers';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns';
import { Menu } from 'lib/interfaces';

interface DailyMenuDatePickerProps {
  menuList?: Menu[];
  selectedDay: string;
  setSelectedDay: React.Dispatch<React.SetStateAction<string>>;
}

const DailyMenuDatePicker = ({ selectedDay, menuList, setSelectedDay }: DailyMenuDatePickerProps) => {
  const isDateDisabled = (date: any) => {
    const formattedDate = format(date.getTime(), 'yyyy-MM-dd');
    return !menuList?.some((menu) => formattedDate === menu.id);
  };

  const handleRenderDay = (day: Date, DayComponentProps: any) => {
    const calenderDay = format(day, 'yyyy-MM-dd');

    if (!menuList?.map((r) => r.id).includes(calenderDay)) {
      return <PickersDay disabled={true} sx={{ backgroundColor: 'none' }} {...DayComponentProps} />;
    }

    if (!menuList?.map((r) => r.id).includes(calenderDay)) {
      return <PickersDay disabled={true} sx={{ backgroundColor: 'none' }} {...DayComponentProps} />;
    }

    return (
      <PickersDay
        onClick={() => null}
        sx={{
          backgroundColor: caloTheme.palette.primary100,
          color: 'black',
          ':hover': { color: 'black', backgroundColor: caloTheme.palette.neutral100 },
          [`&&.${pickersDayClasses.selected}`]: {
            border: 1,
            color: 'black',
            backgroundColor: caloTheme.palette.neutral100,
            borderColor: caloTheme.palette.primary600
          }
        }}
        {...DayComponentProps}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px' }}>
      <DesktopDatePicker
        label="Date"
        inputFormat="dd-MM-yyyy"
        value={selectedDay}
        shouldDisableDate={isDateDisabled}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              mr: 2,
              width: '20%',
              minWidth: '180px',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                mt: 2,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                borderRadius: '8px'
              }
            }}
          />
        )}
        renderDay={(day, _value, DayComponentProps) => handleRenderDay(new Date(day), DayComponentProps)}
        onChange={(event) => {
          event && setSelectedDay(format(+event, 'yyyy-MM-dd'));
        }}
        minDate={format(Date.now(), 'yyyy-MM-dd')}
      />
    </LocalizationProvider>
  );
};

export default DailyMenuDatePicker;
