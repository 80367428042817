import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import localForage from 'localforage';

import reducers from '../reducers';
import middleware from '../middleware';

const persistConfig = {
  key: 'calo-dashboard',
  storage: localForage,
  whitelist: ['ui']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, middleware);
export const persistor = persistStore(store);
