import { Permission } from '@calo/dashboard-types';
import { Box, Stack, Typography } from '@mui/material';

import { Icon as Iconify } from '@iconify/react';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import { Routes } from 'lib/enums';
import { getFoodAggregatedRatingsForPeriod } from 'lib/helpers';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import { Food } from 'lib/interfaces';

interface RatingsProps {
  selectedFood: Food;
  foodWithAllSizes: Food[];
}

const Ratings = ({ selectedFood, foodWithAllSizes }: RatingsProps) => {
  const roles = useUserRoles();
  const aggregatedRatings = getFoodAggregatedRatingsForPeriod(selectedFood, 90);
  return (
    <div>
      {(!!selectedFood.totalRating || !!selectedFood.numberOfRatings) && (
        <Box flexDirection={'row'} display={'flex'}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0.5}
            sx={{
              mr: 1,
              cursor:
                roles.includes(Permission.VIEW_RATING_LIST) && roles.includes(Permission.LIST_CUSTOMER_COMPLAINT)
                  ? 'pointer'
                  : 'default'
            }}
            onClick={() =>
              history.push(Routes.foodFeedback.replace(':slug', selectedFood!.slug), {
                ids: foodWithAllSizes.map((f) => f.id),
                kitchen: selectedFood!.kitchen,
                country: selectedFood!.country,
                name: selectedFood!.name.en
              })
            }
          >
            <Icon name="star" size={6} />
            {!!selectedFood?.totalRating && !!selectedFood?.numberOfRatings
              ? (selectedFood?.totalRating / selectedFood?.numberOfRatings).toFixed(2)
              : 0}{' '}
            ({selectedFood?.numberOfRatings ? selectedFood?.numberOfRatings : 0})
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0.5}
            sx={{
              cursor:
                roles.includes(Permission.VIEW_RATING_LIST) && roles.includes(Permission.LIST_CUSTOMER_COMPLAINT)
                  ? 'pointer'
                  : 'default'
            }}
            onClick={() =>
              history.push(Routes.foodFeedback.replace(':slug', selectedFood!.slug), {
                ids: foodWithAllSizes.map((f) => f.id),
                kitchen: selectedFood!.kitchen,
                country: selectedFood!.country,
                name: selectedFood!.name.en
              })
            }
          >
            <Icon name="star" size={6} />
            {aggregatedRatings ? (
              <Typography className="text-black px-1 mt-1">
                {aggregatedRatings.average} ({aggregatedRatings.count})
              </Typography>
            ) : (
              <Typography className="text-black px-1 mt-1">
                {0} ({0})
              </Typography>
            )}
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0.5} sx={{ ml: 1 }}>
            <Iconify icon="mdi:heart" style={{ fontSize: 20, color: caloTheme.palette.secondaryRed500 }} />
            <Typography className="text-black px-1 mt-1">{selectedFood?.totalNumberOfFavorites ?? 0}</Typography>
          </Stack>
        </Box>
      )}
    </div>
  );
};

export default Ratings;
