import { Affiliate, Permission } from '@calo/dashboard-types';

import { useUserRoles } from 'lib/hooks';
import AffiliatesListComponent from './AffiliatesList';
import CreateAffiliateProfileComponent from './CreateAffiliate';
import ComponentHeaderCard from './HeaderCard';

interface AffiliateProgramHomePageProps {
  affiliatesLoading: boolean;
  affiliates: Affiliate[];
  refetchAffiliates: () => void;
}

const AffiliateProgramHomePage = ({ affiliatesLoading, affiliates, refetchAffiliates }: AffiliateProgramHomePageProps) => {
  const roles = useUserRoles();

  return (
    <>
      <ComponentHeaderCard />

      {roles.includes(Permission.CREATE_AFFILIATE_PROFILE) && (
        <CreateAffiliateProfileComponent refetchAffiliates={() => refetchAffiliates()} />
      )}

      <AffiliatesListComponent affiliatesLoading={affiliatesLoading} affiliates={affiliates} />
    </>
  );
};
export default AffiliateProgramHomePage;
