import { DeliveryArea } from '@calo/dashboard-types';

export const checkIfPointIsInsidePolygons = (
  lat: number,
  lng: number,
  polygons: PolygonState['polygons']
): PolygonRowState | undefined => {
  for (const polygon of polygons) {
    if (google.maps.geometry.poly.containsLocation(new google.maps.LatLng(lat, lng), polygon.polygon)) {
      return polygon;
    }
  }
  return undefined;
};

export const isPointInPolygon = (lat: number, lng: number, polygonBounds: any) => {
  let inside = false;
  for (let i = 0, j = polygonBounds.length - 1; i < polygonBounds.length; j = i++) {
    const xi = polygonBounds[i].lng,
      yi = polygonBounds[i].lat;
    const xj = polygonBounds[j].lng,
      yj = polygonBounds[j].lat;
    const intersect = yi > lat !== yj > lat && lng < ((xj - xi) * (lat - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};

export const findPolygon = (lat: number, lng: number, kitchenPolygons: DeliveryArea[]) => {
  for (const polygon of kitchenPolygons) {
    if (isPointInPolygon(lat, lng, polygon.bounds)) {
      return true;
    }
  }
  return null;
};
