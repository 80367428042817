import { MacrosPreferencePriority, Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { FoodPreference } from '@calo/types';
import { useFormik } from 'formik';

export type FormData = UpdateSubscriptionReq & { macrosPreferencePriority?: MacrosPreferencePriority };

export default (subscription: Subscription & { foodPreference: FoodPreference }, onSubmit: (values: FormData) => any) => {
  const macros = subscription.macros;
  const macrosType = subscription.macrosType;
  const macrosPreferences = subscription.macrosPreferences;
  const macrosPreferencePriority = subscription?.foodPreference?.macrosPreferencePriority ?? {
    cal: false,
    carbs: false,
    protein: false,
    fat: false
  };
  return useFormik<FormData>({
    enableReinitialize: true,
    initialValues: {
      macros,
      macrosType,
      macrosPreferences,
      macrosPreferencePriority
    },

    validate: (_: FormData) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
};
