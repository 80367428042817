import ExcelJS, { Column, Style } from 'exceljs';
import saveAs from 'file-saver';
import { startCase } from 'lodash-es';

import { IngredientStatsReq } from '@calo/dashboard-types';

import { IngredientComponentStats, IngredientStats } from 'lib/interfaces';
import { getQuantity, getQuantityAfterPrep, getSwitchedValue, getTotalCookedWeight, getTotalRawWeight } from './helpers';

const columnsStyle: Partial<Style> = {
  border: {
    top: { style: 'thin', color: { argb: 'D3D3D3' } },
    left: { style: 'thin', color: { argb: 'D3D3D3' } },
    bottom: { style: 'thin', color: { argb: 'D3D3D3' } },
    right: { style: 'thin', color: { argb: 'D3D3D3' } }
  },
  alignment: {
    vertical: 'middle',
    horizontal: 'left'
  }
};

const columns: Array<Partial<Column>> = [
  { width: 30, key: 'componentName', style: columnsStyle },
  { width: 30, key: 'rawWeight', style: columnsStyle },
  { width: 20, key: 'cookedWeight', style: columnsStyle },
  { width: 10, key: 'quantity', style: columnsStyle },
  { width: 20, key: 'quantityAfterPrep', style: columnsStyle },
  { width: 20, key: 'removedQuantity', style: columnsStyle }
];

interface ComponentsDrawerProps {
  switchUnit: boolean;
  ingredient: IngredientStats;
  filters: IngredientStatsReq;
  ingredientComponentStats: IngredientComponentStats[];
}

const onExport = async ({ ingredient, ingredientComponentStats, filters, switchUnit }: ComponentsDrawerProps) => {
  const ingredientWeight = switchUnit && ingredient?.weight ? ingredient?.weight : 1;
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('ingredient-components-stats', {
    pageSetup: { fitToPage: true, orientation: 'portrait' }
  });
  worksheet.columns = columns;
  worksheet.mergeCells('A1', 'F1');
  worksheet.getCell('A1').value = `${filters.day.gte} - ${filters.day.lte}`;
  worksheet.getCell('A1').font = { color: { argb: '0000' }, bold: true, size: 18 };
  worksheet.getCell('A1').alignment = {
    vertical: 'middle',
    horizontal: 'center'
  };
  worksheet.getRow(2).values = [
    'Name',
    'Internal Name',
    'Measurement Unit',
    'Quantity',
    'Quantity after Prep',
    'Removed Quantity'
  ];
  worksheet.getRow(2).font = { color: { argb: '0000' }, bold: true };
  worksheet.getRow(3).values = [
    ingredient?.name.en,
    ingredient?.internalName,
    switchUnit && ingredient?.measurementUnit ? startCase(ingredient?.measurementUnit) : 'Gram',
    (ingredient?.quantity || 0) / ingredientWeight,
    (ingredient?.quantity / ingredient.wastage || 0) / ingredientWeight,
    (ingredient?.removedQuantity || 0) / ingredientWeight
  ];
  worksheet.getRow(4);
  worksheet.getRow(5).values = [
    'Component Name',
    'Raw Weight',
    'Cooked Weight',
    'Quantity',
    'Quantity after Prep',
    'Removed Quantity'
  ];
  worksheet.getRow(5).font = { color: { argb: '0000' }, bold: true };

  for (const row of ingredientComponentStats) {
    if (row.neededIngredientFromParent > 0) {
      worksheet.addRow(
        {
          componentName: row.name.en,
          rawWeight: getTotalRawWeight(row),
          cookedWeight: getTotalCookedWeight(row),
          quantity: getQuantity(row, switchUnit, ingredient),
          quantityAfterPrep: getQuantityAfterPrep(row, switchUnit, ingredient),
          removedQuantity: getSwitchedValue(row.removedNeededIngredient, switchUnit, ingredient)
        },
        ''
      );
    }
    if (row.neededIngredientFromChild > 0) {
      worksheet.addRow(
        {
          componentName: row.name.en + ' (Child)',
          rawWeight: getTotalRawWeight(row, true, row.neededIngredientFromParent === 0),
          cookedWeight: getTotalCookedWeight(row, true, row.neededIngredientFromParent === 0),
          quantity: getQuantity(row, switchUnit, ingredient, true, row.neededIngredientFromParent === 0),
          quantityAfterPrep: getQuantityAfterPrep(row, switchUnit, ingredient, true, row.neededIngredientFromParent === 0),
          removedQuantity: getSwitchedValue(row.removedNeededIngredient, switchUnit, ingredient)
        },
        ''
      );
    }
  }
  const buffer = await workbook.xlsx.writeBuffer();
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const fileExtension = '.xlsx';
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, 'ingredient-components-stats' + fileExtension);
};

export default onExport;
