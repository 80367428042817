import React from 'react';
import { Card, CardMedia, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import { caloTheme } from 'assets/images/theme/calo';

interface PhoneCardComponentProps {
  id: string;
  name: string;
  tags: string[];
  macros: {
    cal: number;
    carbs: number;
    fat: number;
    protein: number;
    fiber: number;
  };
}

interface MacroIndicatorProps {
  color: string;
}

const StyledCard = styled(Card)({
  display: 'flex',
  width: '100%',
  height: '143px',
  border: `1px solid ${caloTheme.palette.neutral300}`,
  backgroundColor: '##E0E0E0',
  borderRadius: '8px',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  padding: '12px',
  gap: '8px'
});

const CardDetails = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'space-between',
  gap: '8px'
});

const MacroBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '16px'
});

const MacroText = styled(Typography)({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '19px',
  display: 'flex',
  alignItems: 'center',
  color: '#757575'
});

const MacroIndicator = styled(Box)<MacroIndicatorProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 4px;
`;

const TopContainer = styled(Box)({
  gap: 1
});

const BottomContainer = styled(Box)({
  gap: 1,
  marginTop: '20px'
});

const FoodName = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700,
  color: '#212121'
});

const CalorieText = styled(Typography)({
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 700,
  color: '#212121'
});

const PhoneCardComponent = ({ id, name, macros }: PhoneCardComponentProps) => {
  return (
    <StyledCard>
      <CardMedia
        component="img"
        sx={{ minWidth: 120, maxWidth: 120, height: 120, objectFit: 'cover', objectPosition: 'center', borderRadius: '4px' }}
        image={`${process.env.REACT_APP_BUCKET_URL}/food/${id}/square@1x.jpg`}
        alt={name}
      />
      <CardDetails>
        <TopContainer>
          <FoodName>{name}</FoodName>
        </TopContainer>
        <BottomContainer>
          <CalorieText>{macros.cal} kcal</CalorieText>
          <MacroBox>
            <MacroText>
              <MacroIndicator color="#24A170" />
              {macros.protein}g Pro
            </MacroText>
            <MacroText>
              <MacroIndicator color="#FAA800" />
              {macros.carbs}g Carbs
            </MacroText>
            <MacroText>
              <MacroIndicator color="#E64646" />
              {macros.fat}g Fat
            </MacroText>
          </MacroBox>
        </BottomContainer>
      </CardDetails>
    </StyledCard>
  );
};

export default PhoneCardComponent;
