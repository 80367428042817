import { LoyaltyTransaction } from 'lib/interfaces';

interface PointsTransactionRowMobileProps {
  row: LoyaltyTransaction;
}

const PointsTransactionRowMobile = (_: PointsTransactionRowMobileProps) => {
  return <></>;
};

export default PointsTransactionRowMobile;
