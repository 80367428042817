import { CustomMealCategory } from '@calo/types';

export interface CustomMealConfig {
  category: CustomMealCategory;
  emoji: '🍲' | '🍛' | '🥗' | '🥪' | '🏋';
  label: 'Meal' | 'Rice Bowl' | 'Salad' | 'Sandwich' | 'Athlete';
}

export const createCustomMealConfigs: CustomMealConfig[] = [
  { category: CustomMealCategory.meal, emoji: '🍲', label: 'Meal' },
  { category: CustomMealCategory.rice, emoji: '🍛', label: 'Rice Bowl' },
  { category: CustomMealCategory.salad, emoji: '🥗', label: 'Salad' },
  { category: CustomMealCategory.sandwich, emoji: '🥪', label: 'Sandwich' },
  { category: CustomMealCategory.athlete, emoji: '🏋', label: 'Athlete' }
];
