import client from 'lib/client';
import { toast } from 'react-toastify';

export const updateTicket = async (payload: { id: string; ticketStatus: string }) => {
  const { id, ticketStatus } = payload;
  const { data, status } = await client.put(`tickets/${id}`, {
    status: ticketStatus
  });
  if (status >= 200 && status <= 299) {
    if (ticketStatus === 'approved') {
      toast('Updating location confirmed', { type: 'success', autoClose: 2000 });
    } else {
      toast('Updating location rejected', { type: 'error', autoClose: 2000 });
    }
  }
  return data;
};
