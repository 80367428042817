import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Route, Switch } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';

import ExactKitchen from './ExactKitchen';
import WaitingListMap from './ExactKitchen/WaitingListMap';
import KitchenFoodStats from './KitchenFoodComponentStats';
import KitchenIngredientStats from './KitchenIngredientStats';
import KitchenList from './KitchenList';
import KitchenPortionStats from './KitchenPortionStats';
import PackagingStats from './PackagingStats';

const Kitchen = () => {
  const roles = useUserRoles();

  return (
    <Switch>
      {roles.includes(Permission.VIEW_KITCHEN_LIST) && <Route exact path={Routes.kitchenList} component={KitchenList} />}
      {roles.includes(Permission.VIEW_KITCHEN) && <Route exact path={Routes.kitchen} component={ExactKitchen} />}
      {roles.includes(Permission.VIEW_KITCHEN) && roles.includes(Permission.VIEW_WAITING_LIST) && (
        <Route exact path={Routes.waitingListMap} component={WaitingListMap} />
      )}

      {roles.includes(Permission.VIEW_FOOD_STATS) && [
        <Route key="food-stats" exact path={Routes.kitchenFoodStats} component={KitchenFoodStats} />
      ]}

      {roles.includes(Permission.VIEW_PORTION_STATS) && (
        <Route key="portion-stats" exact path={Routes.kitchenPortionStats} component={KitchenPortionStats} />
      )}

      {roles.includes(Permission.VIEW_INGREDIENT_STATS) && (
        <Route key="ingredient-stats" exact path={Routes.kitchenIngredientStats} component={KitchenIngredientStats} />
      )}

      {roles.includes(Permission.VIEW_PACKAGE_ELEMENTS_STATS) && (
        <Route key="packaging-stats" exact path={Routes.packagingStats} component={PackagingStats} />
      )}
    </Switch>
  );
};

export default Kitchen;
