import { CreateFoodComponentReq, FoodComponentMethodStep, UpdateFoodComponentReq } from '@calo/dashboard-types';

export interface ComponentMethodRowProps {
  image: string;
  isEdit: boolean;
  disabled: boolean;
  uploadImages: boolean;
  step: FoodComponentMethodStep & { index: number };
  setSelected: (value: any) => void;
  selected: FoodComponentMethodStep & { index: number };
  values: UpdateFoodComponentReq | CreateFoodComponentReq;
  setActiveSelected: (step: FoodComponentMethodStep, index: number) => void;
  setValues: (values: UpdateFoodComponentReq | CreateFoodComponentReq) => void;
  uploadLink: () => Promise<{ url: string; fields: Record<string, string | Blob> }>;
  updateMethodStep: (description: string, index: number, attachment?: string, station?: string) => void;
}

export const MAX_IMAGE_SIZE = 62914560;
