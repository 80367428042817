import { MenuFood } from 'lib/interfaces';
import RSelect, { Props } from 'react-select';

export interface SelectOption {
  label: string;
  value: string | number | boolean | MenuFood | undefined | null;
}

export interface SelectProps extends Omit<Props<SelectOption>, 'value'> {
  label?: string;
  value?: any;
  hidden?: boolean;
  error?: boolean;
  areDuplicatesAllowed?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  customStyles?: any;
}

const Select = ({
  error,
  label,
  value,
  options = [],
  hidden,
  isMulti,
  customStyles,
  areDuplicatesAllowed,
  isDisabled = false,
  ...rest
}: SelectProps) => {
  const mapValueToSelectOption = () => {
    if (Array.isArray(value)) {
      return value.map(
        (currentValue) => options.find((option) => !('options' in option) && option.value === currentValue) as SelectOption
      );
    }
    return options.find((option) => !('options' in option) && option.value === value) || null;
  };

  return (
    <div className="field" hidden={hidden}>
      {label && <label className="label">{label}</label>}
      <div className="control is-clearfix">
        <RSelect
          {...rest}
          isDisabled={isDisabled}
          value={areDuplicatesAllowed ? value : mapValueToSelectOption()}
          options={options}
          isMulti={isMulti}
          blurInputOnSelect={false}
          styles={customStyles}
          classNames={{
            control: (_state) => (error ? 'border border-red-500' : '')
          }}
        />
      </div>
    </div>
  );
};

export default Select;
