import { caloTheme } from 'assets/images/theme/calo';
import { FoodPackageElement } from 'lib/interfaces';
import { isEmpty } from 'lodash';

import { Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import PackageElementRow from './PackageElementRow';

const PackageElementListCard = ({
  isLoading,
  packagingElements
}: {
  isLoading: boolean;
  packagingElements: FoodPackageElement[];
}) => {
  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        border: 'none',
        padding: 2,
        paddingX: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '16px',
        gap: '8px'
      }}
    >
      <Box overflow="auto" width="100%" sx={{ py: 1 }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            lineHeight: '23px'
          }}
        >
          Packaging Elements
        </Typography>
      </Box>
      <TableContainer>
        <Table sx={{ width: '100%' }}>
          <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, border: 0 }}>
            <TableRow>
              <TableCell
                sx={{
                  borderRadius: '8px 0 0 8px',
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  minWidth: '50%'
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  width: '25%'
                }}
              >
                Cost
              </TableCell>
              <TableCell
                sx={{
                  borderRadius: '0 8px 8px 0',
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  width: '25%'
                }}
              >
                Size
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packagingElements?.map((ingredient) => <PackageElementRow key={ingredient.id} element={ingredient} />)}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && isEmpty(packagingElements) && <Typography sx={{ textAlign: 'center' }}> No Elements Found</Typography>}
    </Card>
  );
};

export default PackageElementListCard;
