import { useEffect, useRef, useState } from 'react';

import { getWeek, getYear, parseISO } from 'date-fns';
import { chunk, orderBy, uniq, uniqBy } from 'lodash';
import { useMutation, useQuery } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import { Brand, Country, Kitchen, Subscription } from '@calo/types';

import { caloTheme } from 'assets/images/theme/calo';
import { Button, CaloLoader, ModalRef } from 'components';
import { getAccessibleCountries } from 'lib/helpers';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { Menu, MenuFood } from 'lib/interfaces';
import {
  applyGiftRecord,
  autoApplyGiftRecord,
  createGiftRecord,
  createGiftRecordWithAutoSelectSubscriptions,
  getListWithParams,
  getRecordWithParams,
  validateSubscribers
} from '../../../actions';
import GiftHistoryList from '../GiftHistoryList';
import { AddDateModal, AddGiftModal, ValidateSubscribersModal } from '../GiftModal';
import AddAddonModal from '../GiftModal/AddAddonModal';
import AddLastDeliveryDateModal from '../GiftModal/AddLastDeliveryDateModal';
import AddMealModal from '../GiftModal/AddMealModal';
import AddSubscriberModal from '../GiftModal/AddSubscriberModal';
import ReadyForRelease from '../ReadyForRelease';
import Settings from './Settings';

const GiftList = () => {
  const roles = useUserRoles();
  const [page, setPage] = useState(0);
  const [pageList, setPageList] = useState(0);
  const [noMenu, setNoMenu] = useState<boolean>(false);
  const [noAddonMenu, setNoAddonMenu] = useState<boolean>(false);
  const [pageListHistory, setPageListHistory] = useState(0);
  const [subButton, setSubButton] = useState<boolean>(false);
  const [menuButton, setMenuButton] = useState<boolean>(false);
  const [addonMenuButton, setAddonMenuButton] = useState<boolean>(false);
  const [checkLoading, setCheckLoading] = useState<boolean>(false);
  const [filterName, setFilterName] = useState<string>();
  const [proceed, setProceed] = useState<any>(undefined);
  const [checkCursor, setCheckCursor] = useState(undefined);
  const [giftListData, setGiftListData] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  const [selectedLastDeliveryDate, setSelectedLastDeliveryDate] = useState<string | undefined>();
  const [selectedMeals, setSelectedMeals] = useState<MenuFood[] | undefined>();
  const [selectedAddons, setSelectedAddons] = useState<any[] | undefined>();
  const [selectedSubscribers, setSelectedSubscribers] = useState<Subscription[] | undefined>();
  const [removedUsers, setRemovedUsers] = useState<Subscription[] | undefined>();
  const [autoSelectSubscribers, setAutoSelectSubscribers] = useState(true);
  const userKitchens = useUserKitchens();

  const [filters, setFilters] = useState<any>({
    country: getAccessibleCountries(userKitchens)[0] || Country.BH,
    brand: Brand.CALO
  });

  const { mutateAsync: applyReleasedMutation } = useMutation(applyGiftRecord);
  const { mutateAsync: autoApplyReleasedMutation } = useMutation(autoApplyGiftRecord);
  const { mutateAsync: validateMutation } = useMutation(validateSubscribers);
  const { mutateAsync: createMutation } = useMutation(createGiftRecord);
  const { mutateAsync: createGiftWithAutoSelectedSubscriptionsMutation } = useMutation(
    createGiftRecordWithAutoSelectSubscriptions
  );

  const [selectedCountryBrand, setSelectedCountryBrand] = useState<{
    country: Country | undefined;
    brand: Brand;
    kitchen: Kitchen | undefined;
  }>({
    brand: Brand.CALO,
    country: undefined,
    kitchen: undefined
  });

  const { isLoading, refetch } = useQuery<any, Error, any>(
    ['group-gifts', { filters, cursor: checkCursor, limit: 100 }],
    getListWithParams,
    {
      suspense: false,
      onSuccess: (dataList) => {
        setGiftListData((old: any) => uniqBy([...old, ...dataList.data], 'id'));
        if (dataList.meta.cursor) {
          setCheckCursor(dataList.meta.cursor);
        }
      }
    }
  );

  let filteredData;
  const handleDraftData = (status: string) => {
    filteredData =
      status === 'draft'
        ? orderBy(
            giftListData.filter(
              (r) =>
                (r.status === 'draft' || r.status === 'pending') && filters.country === r.country && filters.brand === r.brand
            ),
            'date',
            'desc'
          )
        : orderBy(
            giftListData.filter((r) => r.status === 'released' && filters.country === r.country && filters.brand === r.brand),
            'date',
            'desc'
          );
    const giftingListArray = chunk(filteredData, 10);
    return giftingListArray[status === 'draft' ? pageList : pageListHistory] || [];
  };

  const addGiftModalRef = useRef<ModalRef>();
  const addDateModalRef = useRef<ModalRef>();
  const addLastDeliveryDateModalRef = useRef<ModalRef>();
  const addMealModalRef = useRef<ModalRef>();
  const addAddonModalRef = useRef<ModalRef>();
  const showDataModalRef = useRef<ModalRef>();
  const addSubscribersModalRef = useRef<ModalRef>();
  const validateSubscribersModalRef = useRef<ModalRef>();

  const handleClear = () => {
    setProceed(undefined);
    setSelectedDate(undefined);
    setSelectedLastDeliveryDate(undefined);
    setSelectedMeals(undefined);
    setSelectedAddons(undefined);
    setSelectedSubscribers(undefined);
    setSubButton(false);
    setMenuButton(false);
    setSelectedCountryBrand({ country: undefined, brand: Brand.CALO, kitchen: undefined });
  };

  const { data: MenuData, isLoading: LoadingMeals } = useQuery(
    [`menu`, selectedDate, { brand: selectedCountryBrand.brand, kitchen: selectedCountryBrand.kitchen }],
    getRecordWithParams,
    {
      suspense: false,
      enabled: !(proceed === undefined || selectedDate === undefined),
      retry: false,
      onError: () => {
        setNoMenu(true);
      },
      onSuccess: () => {
        setNoMenu(false);
      }
    }
  );
  const menu = MenuData as Menu;

  const { data: addonsMenu, isLoading: LoadingAddonsMeals } = useQuery(
    [
      `/addons-menu/year/${getYear(parseISO(selectedDate!))}/week/${getWeek(parseISO(selectedDate!))}`,
      { brand: selectedCountryBrand.brand, kitchen: selectedCountryBrand.kitchen }
    ],
    getRecordWithParams,
    {
      suspense: false,
      enabled: !(proceed === undefined || selectedDate === undefined),
      retry: false,
      onError: () => {
        setNoAddonMenu(true);
      },
      onSuccess: () => {
        setNoAddonMenu(false);
      }
    }
  );

  useEffect(() => {
    if (proceed) {
      addGiftModalRef.current?.close();
    }
  }, [proceed]);

  useEffect(() => {
    handleClear();
  }, [filters.country, filters.brand]);

  const handleCreateGift = (proceeding: { country: Country; brand: Brand }) => {
    setSelectedDate(undefined);
    setSelectedLastDeliveryDate(undefined);
    setSelectedMeals(undefined);
    setSelectedAddons(undefined);
    setSelectedSubscribers(undefined);
    setProceed(proceeding);
  };

  const handleValidateUsers = async (selectedUsers: Subscription[]) => {
    const selectedIds = selectedUsers.map((r) => r.id);
    setCheckLoading(true);
    const { isLoading } = await validateMutation(
      {
        subscriptionIds: selectedIds,
        date: selectedDate,
        country: selectedCountryBrand.country,
        brand: selectedCountryBrand.brand
      },
      {
        onSuccess: (data) => {
          console.log('passed', data.passed);
          setSelectedSubscribers(uniq(data.passed));
          data.rejected.length > 0 && setRemovedUsers(uniq(data.rejected));
          data.rejected.length > 0 && validateSubscribersModalRef.current?.open();
        }
      }
    );
    if (isLoading) {
      setCheckLoading(isLoading);
    } else {
      setCheckLoading(isLoading);
    }
  };

  const handleSubmit = async () => {
    const subscribersData = selectedSubscribers?.map((sub: Subscription) => ({
      userId: sub.id,
      name: sub.name,
      email: sub.email,
      phoneNumber: sub.phoneNumber
    }));
    const mealsData = selectedMeals?.map((meal: any) => ({
      id: meal.id,
      name: meal.name,
      foodType: meal.type,
      size: meal.size
    }));
    const addonsData = selectedAddons?.map((addon: any) => ({
      id: addon.id,
      name: addon.name,
      size: addon.size,
      foodType: addon.type
    }));

    if (autoSelectSubscribers) {
      await createGiftWithAutoSelectedSubscriptionsMutation(
        {
          food: mealsData,
          addons: addonsData,
          brand: selectedCountryBrand.brand,
          country: selectedCountryBrand.country,
          kitchen: selectedCountryBrand.kitchen,
          date: selectedDate,
          expectedLastDeliveryDate: selectedLastDeliveryDate
        },
        {
          onSuccess: (data) => {
            refetch();
            setGiftListData((old) => orderBy([...old, data], 'date'));
            handleClear();
          }
        }
      );
    } else {
      await createMutation(
        {
          gifts: subscribersData,
          food: mealsData,
          brand: selectedCountryBrand.brand,
          country: selectedCountryBrand.country,
          kitchen: selectedCountryBrand.kitchen,
          date: selectedDate
        },
        {
          onSuccess: (data) => {
            refetch();
            setGiftListData((old) => orderBy([...old, data], 'date'));
            handleClear();
          }
        }
      );
    }
  };

  const handleReleaseGift = async (dataId: string, auto = false) => {
    if (auto) {
      await autoApplyReleasedMutation(dataId, {
        onSuccess: (releasedData) => {
          setGiftListData(giftListData.filter((r) => r.id !== dataId));
          setGiftListData((old) => orderBy([...old, releasedData], 'date'));
          refetch();
        }
      });
    } else {
      await applyReleasedMutation(dataId, {
        onSuccess: (releasedData) => {
          setGiftListData(giftListData.filter((r) => r.id !== dataId));
          setGiftListData((old) => orderBy([...old, releasedData], 'date'));
          refetch();
        }
      });
    }
  };

  useEffect(() => {
    if (selectedDate) {
      setSubButton(false);
    }
  }, [selectedDate]);

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="uppercase" style={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '32px' }}>
                Send a gift
              </p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <p className="uppercase" style={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '32px' }}>
                {filters.country} - {filters.brand}
              </p>
            </div>
          </div>
        </div>
      </section>
      {isLoading ? (
        <CaloLoader />
      ) : (
        <>
          <section className="section">
            {proceed ? (
              <div className="card bg-gray-200 w-full">
                <span className="float-right mx-6 my-2">
                  <i className="fas fa-times ml-2 cursor-pointer" onClick={() => handleClear()} />
                </span>
                <div className="card-content">
                  <div className="flex justify-between w-full">
                    <span className="flex flex-col w-3/12">
                      <label style={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '24px', width: '119px' }}>
                        {' '}
                        Gift Date
                      </label>
                      <Button
                        content={selectedDate ? `📅 ${selectedDate}` : '📅 Date'}
                        className="w-32"
                        onClick={() => addDateModalRef.current?.open()}
                        primary
                      />
                    </span>
                    <div className="flex flex-col justify-between w-3/12">
                      <span className="flex flex-col justify-between w-11/12">
                        <label style={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '24px', width: '119px' }}>
                          Subscribers
                        </label>
                        {selectedSubscribers ? (
                          <div className="flex flex-row">
                            <span className="flex flex-row">{`🧍 * ${selectedSubscribers.length} added `}</span>
                            <div className="flex ml-4">
                              <p
                                className="border-none text-blue-500 cursor-pointer"
                                onClick={() => {
                                  addSubscribersModalRef.current?.open();
                                }}
                              >
                                Edit
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {!autoSelectSubscribers && (
                              <Button
                                primary
                                className="w-32"
                                content={'🧍Subscribers'}
                                onClick={() => (selectedDate ? addSubscribersModalRef.current?.open() : setSubButton(true))}
                              />
                            )}
                            <div className="flex items-center mt-2">
                              <input
                                checked={autoSelectSubscribers ? true : false}
                                id="checked-checkbox"
                                type="checkbox"
                                onChange={() => {
                                  setAutoSelectSubscribers(!autoSelectSubscribers);
                                }}
                                value={autoSelectSubscribers ? 1 : 0}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="checked-checkbox"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                Auto Select Subscribers
                              </label>
                            </div>
                          </div>
                        )}
                        {subButton && !selectedDate && (
                          <p className="text-red-500 text-xs">Choose date to be able to select a subscribers</p>
                        )}
                      </span>
                    </div>
                    {autoSelectSubscribers && (
                      <div className="flex flex-col justify-center w-3/12">
                        <span className="flex flex-col w-full">
                          <label style={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '24px' }}>
                            {' '}
                            Last Delivery Date
                          </label>
                          <Button
                            content={selectedLastDeliveryDate ? `📅 ${selectedLastDeliveryDate}` : '📅 Date'}
                            className="w-32"
                            onClick={() => addLastDeliveryDateModalRef.current?.open()}
                            primary
                          />
                        </span>
                      </div>
                    )}
                    {autoSelectSubscribers ? (
                      <div className="flex flex-col justify-between w-3/12">
                        <span>
                          <p style={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '24px', width: '119px' }}>Meals*</p>
                          {selectedMeals ? (
                            <div className="flex flex-row">
                              <span className="flex flex-row">{`🌮 * ${selectedMeals.length} added `}</span>
                              <div className="flex ml-4">
                                <p
                                  className="border-none text-blue-500 cursor-pointer"
                                  onClick={() => addMealModalRef.current?.open()}
                                >
                                  Edit
                                </p>
                              </div>
                            </div>
                          ) : (
                            <Button
                              primary
                              className="w-32"
                              content={'+ Add Meal'}
                              onClick={() =>
                                !selectedDate ||
                                !selectedLastDeliveryDate ||
                                noMenu ||
                                (!selectedSubscribers && !autoSelectSubscribers) ||
                                checkLoading
                                  ? setMenuButton(true)
                                  : addMealModalRef.current?.open()
                              }
                            />
                          )}
                          {!selectedDate && menuButton && (
                            <p className="text-red-500 text-xs">Choose gift date to be able to select a meal</p>
                          )}
                          {!selectedLastDeliveryDate && menuButton && (
                            <p className="text-red-500 text-xs">Choose last delivery date to be able to select a meal</p>
                          )}
                          {selectedDate && selectedLastDeliveryDate && noMenu && menuButton && (
                            <p className="text-red-500 text-xs">Selected date does not have a menu</p>
                          )}
                        </span>
                      </div>
                    ) : (
                      <div className="flex flex-col justify-between w-3/12">
                        <span>
                          <p style={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '24px', width: '119px' }}>Meals</p>
                          {selectedMeals ? (
                            <div className="flex flex-row">
                              <span className="flex flex-row">{`🌮 * ${selectedMeals.length} added `}</span>
                              <div className="flex ml-4">
                                <p
                                  className="border-none text-blue-500 cursor-pointer"
                                  onClick={() => addMealModalRef.current?.open()}
                                >
                                  Edit
                                </p>
                              </div>
                            </div>
                          ) : (
                            <Button
                              primary
                              className="w-32"
                              content={'+ Add Meal'}
                              onClick={() =>
                                !selectedDate || noMenu || (!selectedSubscribers && !autoSelectSubscribers) || checkLoading
                                  ? setMenuButton(true)
                                  : addMealModalRef.current?.open()
                              }
                            />
                          )}
                          {!selectedDate && menuButton && (
                            <p className="text-red-500 text-xs">Choose date to be able to select a meal</p>
                          )}
                          {selectedDate && noMenu && menuButton && (
                            <p className="text-red-500 text-xs">Selected date does not have a menu</p>
                          )}
                          {!selectedSubscribers && menuButton && (
                            <p className="text-red-500 text-xs">Choose subscribers to be able to select a meal</p>
                          )}
                        </span>
                      </div>
                    )}
                    {autoSelectSubscribers && (
                      <div className="flex flex-col justify-between w-3/12">
                        <span>
                          <p style={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '24px', width: '119px' }}>Addons</p>
                          {selectedAddons ? (
                            <div className="flex flex-row">
                              <span className="flex flex-row">{`🌮 * ${selectedAddons.length} added `}</span>
                              <div className="flex ml-4">
                                <p
                                  className="border-none text-blue-500 cursor-pointer"
                                  onClick={() => addAddonModalRef.current?.open()}
                                >
                                  Edit
                                </p>
                              </div>
                            </div>
                          ) : (
                            <Button
                              primary
                              className="w-32"
                              content={'+ Add Addon'}
                              onClick={() =>
                                !selectedDate || !selectedLastDeliveryDate || noAddonMenu || !autoSelectSubscribers
                                  ? setAddonMenuButton(true)
                                  : addAddonModalRef.current?.open()
                              }
                            />
                          )}
                          {!selectedDate && addonMenuButton && (
                            <p className="text-red-500 text-xs">Choose gift date to be able to select an addon</p>
                          )}
                          {!selectedLastDeliveryDate && addonMenuButton && (
                            <p className="text-red-500 text-xs">Choose last delivery date to be able to select an addon</p>
                          )}
                          {selectedDate && selectedLastDeliveryDate && noAddonMenu && addonMenuButton && (
                            <p className="text-red-500 text-xs">Selected date does not have an addon menu</p>
                          )}
                        </span>
                      </div>
                    )}

                    {(selectedSubscribers || autoSelectSubscribers) && (selectedMeals || selectedAddons) && (
                      <span className="ml-4 w-1/4 m-auto">
                        <Button primary content="Done" onClick={() => handleSubmit()} />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              roles.includes(Permission.CREATE_GROUP_GIFT) && (
                <div className="flex justify-center">
                  <Button primary content="+ Add Gift" onClick={() => addGiftModalRef.current?.open()} />
                </div>
              )
            )}
          </section>
          {giftListData?.filter((r) => r.status === 'draft' || r.status === 'pending').length > 0 && (
            <section className="section">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <p className="uppercase font-roboto text-3xl">Ready for Release</p>
                  </div>
                </div>
              </div>
              <div>
                <ReadyForRelease
                  page={pageList}
                  setPage={setPageList}
                  showDataModalRef={showDataModalRef}
                  giftListData={handleDraftData('draft')}
                  setselectedMeals={(m) => setSelectedMeals(m)}
                  setselectedAddons={(a) => setSelectedAddons(a)}
                  handleReleaseGift={(v, a) => handleReleaseGift(v, a)}
                  total={
                    giftListData?.filter(
                      (r) =>
                        (r.status === 'draft' || r.status === 'pending') &&
                        filters.country === r.country &&
                        filters.brand === r.brand
                    ).length || 0
                  }
                />
              </div>
            </section>
          )}
          <section className="section">
            <GiftHistoryList
              page={pageListHistory}
              setPage={setPageListHistory}
              total={
                giftListData?.filter((r) => r.status === 'released' && filters.country === r.country && filters.brand === r.brand)
                  .length || 0
              }
              giftHistory={handleDraftData('released') || []}
              showDataModalRef={showDataModalRef}
            />

            <AddGiftModal
              addGiftModalRef={addGiftModalRef}
              setProceed={(v) => handleCreateGift(v)}
              selectedCountryBrand={selectedCountryBrand}
              setSelectedCountryBrand={(v) => setSelectedCountryBrand(v)}
            />

            <AddDateModal
              selectedDate={selectedDate}
              selectedMeals={selectedMeals}
              addDateModalRef={addDateModalRef}
              selectedSubscribers={selectedSubscribers}
              setSelectedDate={(v) => setSelectedDate(v)}
              setSelectedMeals={(v) => setSelectedMeals(v)}
              setSelectedAddons={(v) => setSelectedAddons(v)}
              setSelectedSubscribers={setSelectedSubscribers}
            />

            <AddLastDeliveryDateModal
              selectedDate={selectedLastDeliveryDate}
              addLastDeliveryDateModalRef={addLastDeliveryDateModalRef}
              setSelectedDate={(v) => setSelectedLastDeliveryDate(v)}
            />

            <AddSubscriberModal
              page={page}
              proceed={proceed}
              setPage={setPage}
              selectedDate={selectedDate}
              selectedSubscribers={selectedSubscribers || []}
              addSubscribersModalRef={addSubscribersModalRef}
              validateSubscribers={(v) => handleValidateUsers(v)}
              setselectedSubscribers={(v) => setSelectedSubscribers(v)}
              selectedCountryBrand={{
                brand: selectedCountryBrand.brand!,
                country: selectedCountryBrand.country!,
                kitchen: selectedCountryBrand.kitchen!
              }}
            />

            <AddMealModal
              menu={menu}
              page={page}
              setPage={setPage}
              filterName={filterName}
              LoadingMeals={LoadingMeals}
              setFilterName={setFilterName}
              addMealModalRef={addMealModalRef}
              setSelectedMeals={setSelectedMeals}
              selectedMeals={selectedMeals || []}
              selectedSubscribers={selectedSubscribers!}
              setSelectedSubscribers={setSelectedSubscribers}
              selectedCountryBrand={{ country: selectedCountryBrand.country!, brand: selectedCountryBrand.brand! }}
              autoSelectSubscribers={autoSelectSubscribers}
            />

            <AddAddonModal
              addonsMenu={addonsMenu}
              page={page}
              setPage={setPage}
              filterName={filterName}
              LoadingAddonsMeals={LoadingAddonsMeals}
              setFilterName={setFilterName}
              addAddonModalRef={addAddonModalRef}
              setSelectedAddons={setSelectedAddons}
              selectedAddons={selectedAddons || []}
              selectedCountryBrand={{ country: selectedCountryBrand.country!, brand: selectedCountryBrand.brand! }}
              autoSelectSubscribers={autoSelectSubscribers}
            />

            <ValidateSubscribersModal
              removedUsers={removedUsers!}
              selectedSubscribers={selectedSubscribers!}
              setselectedSubscribers={(v) => setSelectedSubscribers(v)}
              validateSubscribersModalRef={validateSubscribersModalRef}
            />
          </section>
        </>
      )}
      <Settings onFilter={setFilters} filters={filters} />
    </>
  );
};

export default GiftList;
