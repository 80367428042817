import { useEffect, useRef, useState } from 'react';

import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import { useUserRoles } from 'lib/hooks';
import { v4 as uuid } from 'uuid';

import { CreateFoodComponentReq, Permission, UpdateFoodComponentReq } from '@calo/dashboard-types';
import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import ComponentStandardsPopup from './ComponentStandardsPopup';
import ComponentStandardsRow from './ComponentStandardsRow';

const defaultStandards = [
  {
    id: uuid(),
    name: 'Color'
  },
  {
    id: uuid(),
    name: 'Taste'
  },
  {
    id: uuid(),
    name: 'Appearance'
  },
  {
    id: uuid(),
    name: 'Texture'
  },
  {
    id: uuid(),
    name: 'Smell'
  }
];

interface ComponentStandardsProps {
  values: UpdateFoodComponentReq | CreateFoodComponentReq;
  setValues: (v: any) => void;
  isDisabled: boolean;
  allowUploadImages?: boolean;
}

const ComponentStandards = ({ values, setValues, isDisabled, allowUploadImages }: ComponentStandardsProps) => {
  const addModalRef = useRef<ModalRef>();
  const userRoles = useUserRoles();
  const [activeStandardId, setActiveStandardId] = useState<string | null>(null);
  const openEditModal = (id: string) => {
    setActiveStandardId(id);
    addModalRef.current?.open();
  };
  const handleDelete = (id: string) => {
    const standards = [...(values.standards ?? [])];
    const filteredStandards = standards.filter((s) => s.id !== id);
    setValues({ ...values, standards: filteredStandards });
  };

  useEffect(() => {
    if (!values.standards || values.standards.length === 0) {
      setValues({ ...values, standards: defaultStandards });
    }
  }, [values]);

  return (
    <Stack direction="column" justifyContent="space-evenly" alignItems="baseline" spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline" spacing={2} width={'100%'}>
        <Typography sx={{ fontSize: '19px', fontWeight: 600 }}>Standards</Typography>
        {values.standards && values.standards.length > 0 && (
          <Button
            data-test="addStepButton"
            variant="text"
            sx={{
              marginBottom: 2,
              lineHeight: '17px',
              fontWeight: 600,
              fontSize: '16px',
              borderRadius: '8px',
              textTransform: 'none',
              paddingY: '10px',
              paddingX: '16px'
            }}
            onClick={() => {
              addModalRef.current?.open();
            }}
            disabled={!userRoles.includes(Permission.UPDATE_FOOD_COMPONENTS) || Boolean(isDisabled)}
          >
            + Add
          </Button>
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-evenly" alignItems="baseline" spacing={2} width={'100%'}>
        {values.standards && values.standards?.length > 0 ? (
          <TableContainer>
            <Table sx={{ width: '100%' }}>
              <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, border: 0 }}>
                <TableRow>
                  <TableCell
                    sx={{
                      borderRadius: '8px 0 0 8px',
                      border: 0,
                      fontWeight: 600,
                      lineHeight: '14px',
                      fontSize: '12px',
                      width: '20%'
                    }}
                  >
                    Standard
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 0,
                      fontWeight: 600,
                      lineHeight: '14px',
                      fontSize: '12px',
                      width: '70%',
                      maxWidth: '70%'
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 0,
                      fontWeight: 600,
                      lineHeight: '14px',
                      fontSize: '12px',
                      width: '10%',
                      borderRadius: '0 8px 8px 0'
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.standards?.map((standard) => (
                  <ComponentStandardsRow
                    key={standard.id}
                    standard={standard}
                    openEditModal={openEditModal}
                    handleDelete={handleDelete}
                    isDisabled={!userRoles.includes(Permission.UPDATE_FOOD_COMPONENTS) || Boolean(isDisabled)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Button
            data-test="addStepButton"
            variant="outlined"
            sx={{
              marginBottom: 2,
              lineHeight: '17px',
              fontWeight: 600,
              fontSize: '16px',
              borderRadius: '8px',
              textTransform: 'none',
              paddingY: '10px',
              paddingX: '16px'
            }}
            onClick={() => {
              addModalRef.current?.open();
            }}
            disabled={!userRoles.includes(Permission.UPDATE_FOOD_COMPONENTS) || Boolean(isDisabled)}
          >
            Add
          </Button>
        )}
        <Popup
          ref={addModalRef}
          maxWidth="md"
          fullWidth={true}
          closeIcon={true}
          onClose={() => {
            addModalRef.current?.close();
            setActiveStandardId(null);
          }}
          title={'Add Standard'}
          titleStyle={{ fontSize: '23px', fontWeight: 600 }}
        >
          <ComponentStandardsPopup
            closePopup={() => addModalRef.current?.close()}
            values={values}
            setValues={(v) => setValues(v)}
            activeStandardId={activeStandardId}
            setActiveStandardId={(id) => setActiveStandardId(id)}
            allowUploadImages={allowUploadImages}
          />
        </Popup>
      </Stack>
    </Stack>
  );
};
export default ComponentStandards;
