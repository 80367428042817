import { useRef, useState } from 'react';

import { deleteKDSUserPermissions } from 'actions/kdsUserPermissions';
import { caloTheme } from 'assets/images/theme/calo';
import Input from 'components/MUI/InputMUI';
import { theme } from 'lib/componentStyles';
import { KDSUserGroup } from 'lib/interfaces';
import { useMutation } from 'react-query';

import MoreVertRounded from '@mui/icons-material/MoreVertRounded';
import { Box, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, TableCell } from '@mui/material';

interface TableHeadCellProps {
  userGroup: KDSUserGroup;
  isLast: boolean;
  setSelectedGroup: React.Dispatch<React.SetStateAction<KDSUserGroup>>;
  selectedGroup: KDSUserGroup | null;
}

const TableHeadCell = ({ userGroup, isLast, setSelectedGroup, selectedGroup }: TableHeadCellProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { mutateAsync: deleteMutation } = useMutation(deleteKDSUserPermissions);

  const handleRemove = async () => {
    await deleteMutation(userGroup.id);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <TableCell
      sx={{
        '&.MuiTableCell-head': {
          borderTopRightRadius: isLast ? 8 : 0,
          backgroundColor: `${caloTheme.palette.neutral100} !important`,
          color: theme.palette.common.black,
          border: 'none',
          fontSize: 14,
          minWidth: 200
        }
      }}
    >
      {!selectedGroup || selectedGroup.id !== userGroup.id ? (
        <Box>
          {userGroup.groupName}
          <IconButton
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <MoreVertRounded />
          </IconButton>
        </Box>
      ) : (
        <Input
          label="Name"
          debounce
          name={userGroup.groupName}
          placeholder="Enter Name"
          value={userGroup.groupName}
          onChange={(data: any) => setSelectedGroup((old) => ({ ...old, groupName: data.target.value }))}
          inputProps={{
            style: {
              backgroundColor: caloTheme.palette.white
            }
          }}
          InputLabelProps={{
            style: {
              color: caloTheme.palette.primary500
            }
          }}
        />
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        sx={{ zIndex: theme.zIndex.modal }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                  <MenuItem
                    onClick={(e) => {
                      setSelectedGroup(userGroup);
                      handleClose(e);
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      handleRemove();
                      handleClose(e);
                    }}
                  >
                    Remove
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </TableCell>
  );
};

export default TableHeadCell;
