import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { caloTheme } from 'assets/images/theme/calo';
import { FeedBackData } from 'lib/interfaces';
import FeedbackRow from './FeedbackRow';

interface FeedbackTableProps {
  data: FeedBackData[];
}

const FeedbackTable = ({ data }: FeedbackTableProps) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  return (
    <Table
      sx={{
        marginY: '4px',
        minHeight: '120px',
        overflow: 'auto',
        width: '100%',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column'
        }
      }}
    >
      <TableHead
        sx={{
          backgroundColor: caloTheme.palette.neutral50,
          color: 'black',
          flexWrap: 0,
          justifyContent: 'space-between',
          width: '100%',
          borderRadius: '8px'
        }}
      >
        <TableRow
          style={{
            backgroundColor: caloTheme.palette.neutral50,
            color: 'black',
            width: '100%',
            borderRadius: '8px',
            justifyContent: 'space-between'
          }}
        >
          <StyledTableCell style={{ width: '23%', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
            Meal
          </StyledTableCell>
          <StyledTableCell style={{ width: '15%' }}>Comment</StyledTableCell>
          <StyledTableCell style={{ width: '15%' }}>Feedback</StyledTableCell>
          <StyledTableCell style={{ width: '10%' }}>Customer</StyledTableCell>
          <StyledTableCell style={{ width: '12%' }}>Date</StyledTableCell>
          <StyledTableCell
            style={{ width: '15%', textAlign: 'center', borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}
          >
            Portion Size
          </StyledTableCell>
        </TableRow>
      </TableHead>
      {data && data.length === 0 ? (
        <span className="absolute w-11/12 text-2xl mt-6 text-center font-bold text-gray-400">NO FEEDBACKS</span>
      ) : (
        <TableBody>{data?.map((user: any) => <FeedbackRow key={user.user} user={user} />)}</TableBody>
      )}
    </Table>
  );
};

export default FeedbackTable;
