import { useFormik } from 'formik';

import { AddressType, Country, NewDeliveryAddress } from '@calo/types';

type DeliveryAddress = { placeId?: string } & NewDeliveryAddress;

export default (onSubmit: (values: DeliveryAddress) => any, deliveryAddress?: NewDeliveryAddress) =>
  useFormik<DeliveryAddress>({
    initialValues: {
      lat: 26.222917,
      lng: 50.580958,
      country: Country.BH,
      notes: '',
      name: '',
      region: '',
      city: '',
      district: '',
      street: '',
      building: '',
      type: AddressType.home,
      ...deliveryAddress
    },

    validate: (values: DeliveryAddress) => {
      const errors: any = {};

      if (!values.country?.trim()) {
        errors.country = true;
      }

      if (!values.street?.trim()) {
        errors.street = true;
      }

      if (!values.building?.trim()) {
        errors.building = true;
      }

      return errors;
    },

    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
