import { ListGiftsFilters, Permission } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import { Box, Card, CardContent } from '@mui/material';
import { format } from 'date-fns/fp';
import useSpecialRequestList from 'hooks/useSpecialRequestList';
import { useUserRoles } from 'lib/hooks';
import { useState } from 'react';
import CreateSpecialRequest from './CreateSpecialRequest';
import SpecialRequestList from './SpecialRequestList';

const SpecialRequest = () => {
  const roles = useUserRoles();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<ListGiftsFilters>({
    date: {
      lte: format('yyyy-MM-dd')(Date.now()),
      gte: format('yyyy-MM-dd')(Date.now())
    },
    kitchen: Kitchen.BH1
  });
  const { data, refetch, requestLoadingList, specialRequest } = useSpecialRequestList({ filters, page });

  return (
    <Box>
      {roles.includes(Permission.CREATE_GIFT) && (
        <Card
          variant="outlined"
          sx={{
            borderRadius: '8px',
            width: 'full',
            overflow: 'visible',
            border: 'none'
          }}
        >
          <CardContent>
            <CreateSpecialRequest refetchRequestList={refetch} />
          </CardContent>
        </Card>
      )}

      <Card variant="outlined" sx={{ border: 'none', my: 3, borderRadius: '8px' }}>
        <CardContent>
          <SpecialRequestList
            data={data}
            page={page}
            filters={filters}
            setPage={setPage}
            setFilters={setFilters}
            refetchSpecialList={refetch}
            specialRequestList={specialRequest || []}
            requestLoadingList={requestLoadingList}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
export default SpecialRequest;
