import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Box, Table, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { StyledStatsTableCell } from 'lib/componentStyles';
import { StatsFoodComponent } from 'lib/interfaces';
import KitchenComponentStatsTableRow from './KitchenComponentStatsTableRow';
import { styles } from './styles';

interface KitchenComponentStatsTableProps {
  switchUnit: boolean;
  showDetailedView: boolean;
  selectedCustomSize?: string;
  setIsDrawerOpen: (value: boolean) => void;
  filteredComponentData: StatsFoodComponent[];
  setDrawerComponent: (value: StatsFoodComponent) => void;
}
const KitchenComponentStatsTable = ({
  switchUnit,
  setIsDrawerOpen,
  showDetailedView,
  setDrawerComponent,
  selectedCustomSize,
  filteredComponentData
}: KitchenComponentStatsTableProps) => (
  <Box sx={styles.root}>
    <Table stickyHeader={true} sx={styles.table}>
      <TableHead sx={styles.tableHead}>
        <TableRow sx={styles.tableRow}>
          <StyledStatsTableCell sx={styles.tableCell}>Name</StyledStatsTableCell>
          <StyledStatsTableCell sx={styles.tableCell}>Kitchen</StyledStatsTableCell>
          {showDetailedView && (
            <>
              <StyledStatsTableCell sx={styles.tableCell}>Raw Weight</StyledStatsTableCell>
              <StyledStatsTableCell sx={styles.tableCell}>Cooked Weight</StyledStatsTableCell>
              <StyledStatsTableCell sx={styles.tableCell}>Special Requests Raw Weight</StyledStatsTableCell>
              <StyledStatsTableCell sx={styles.tableCell}>Special Requests Cooked Weight</StyledStatsTableCell>
              <StyledStatsTableCell sx={styles.tableCell}>Sub-component Raw Weight</StyledStatsTableCell>
              <StyledStatsTableCell sx={styles.tableCell}>Sub-component Cooked Weight</StyledStatsTableCell>
              <StyledStatsTableCell sx={styles.tableCell}>Removed Raw Weight</StyledStatsTableCell>
              <StyledStatsTableCell sx={styles.tableCell}>Removed Cooked Weight</StyledStatsTableCell>
            </>
          )}
          <StyledStatsTableCell sx={styles.tableCell}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'}>
              <Tooltip
                title="Raw Weight - Removed Raw Weight + Sub-component Raw Weight + Special Requests Raw Weight."
                placement="top-end"
                sx={styles.tooltip}
              >
                <InfoOutlined color={'info'} fontSize="medium" />
              </Tooltip>
              <Typography
                sx={{
                  color: 'black',
                  fontSize: '12px',
                  lineHeight: '14px',
                  fontFamily: 'Roboto',
                  fontWeight: 600
                }}
              >
                Total Raw Weight
              </Typography>
            </Box>
          </StyledStatsTableCell>
          <StyledStatsTableCell sx={styles.tableCell}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'}>
              <Tooltip
                title="Cooked Weight - Removed Cooked Weight + Sub-component Cooked Weight + Special Requests Cooked Weight."
                placement="top-end"
                sx={styles.tooltip}
              >
                <InfoOutlined color={'info'} fontSize="medium" />
              </Tooltip>
              <Typography sx={styles.text}>Total Cooked Weight</Typography>
            </Box>
          </StyledStatsTableCell>
          <StyledStatsTableCell sx={styles.measurementUnit}>Measurement Unit</StyledStatsTableCell>
        </TableRow>
      </TableHead>
      {filteredComponentData && filteredComponentData.length === 0 ? (
        <StyledStatsTableCell sx={styles.noStatsCell} colSpan={12}>
          <Typography sx={styles.centeredText}>No Stats</Typography>
        </StyledStatsTableCell>
      ) : (
        <KitchenComponentStatsTableRow
          switchUnit={switchUnit}
          setIsDrawerOpen={setIsDrawerOpen}
          showDetailedView={showDetailedView}
          selectedCustomSize={selectedCustomSize}
          setDrawerComponent={setDrawerComponent}
          filteredComponentData={filteredComponentData}
        />
      )}
    </Table>
  </Box>
);

export default KitchenComponentStatsTable;
