import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Route, Switch } from 'react-router-dom';
import CouponList from './CouponList';
import ExactCoupon from './ExactCoupon';
import NewCoupon from './NewCoupon';

const Coupons = () => {
  const roles = useUserRoles();
  return (
    <Switch>
      {roles.includes(Permission.VIEW_COUPON_LIST) && [
        <Route key={Routes.couponsList} exact path={Routes.couponsList} component={CouponList} />
      ]}
      {roles.includes(Permission.CREATE_COUPON) && [
        <Route key={Routes.newCoupon} exact path={Routes.newCoupon} component={NewCoupon} />
      ]}
      {roles.includes(Permission.VIEW_COUPON) && [
        <Route key={Routes.coupon} exact path={Routes.coupon} component={ExactCoupon} />
      ]}
    </Switch>
  );
};

export default Coupons;
