import { Brand, Country, Kitchen } from '@calo/types';
import { resolveCountryFromKitchen } from 'lib';
import { useHistory, useParams } from 'react-router-dom';
import { MenuListFilters, MenuPathUpdateFunctions } from '../types';

export const useMenuFilters = (): [MenuListFilters, MenuPathUpdateFunctions] => {
  const history = useHistory();
  const { brand, country, kitchen } = useParams<{ brand: string; country: string; kitchen: string }>();

  // Derive filters directly from URL parameters
  const filters: MenuListFilters = {
    country: (country ?? resolveCountryFromKitchen(kitchen as Kitchen)) as Country,
    brand: (brand as Brand) ?? Brand.CALO,
    kitchen: (kitchen as Kitchen) ?? Kitchen.BH1
  };

  // Functions to update URL path parameters
  const setBrand = (newBrand: Brand) => {
    history.push(`/${newBrand}/${filters.country}/${filters.kitchen}/menu`);
  };

  const setKitchen = (newKitchen: Kitchen) => {
    const newCountry = resolveCountryFromKitchen(newKitchen);
    history.push(`/${filters.brand}/${newCountry}/${newKitchen}/menu`);
  };

  return [filters, { setBrand, setKitchen }];
};
