import { FoodMenuLabelsTags } from '@calo/dashboard-types';
import { Food } from 'lib/interfaces';
import { inRange, uniq } from 'lodash-es';

export const getLabelToAdd = (
  menuDay: number,
  menuLabels: {
    label: FoodMenuLabelsTags;
    startDay: string;
    endDay: string;
  }[]
) => {
  return menuLabels.find((label) => {
    const start = new Date(label.startDay).getTime();
    const end = new Date(label.endDay).getTime();
    return inRange(menuDay, start, end + 1);
  });
};

export const updateFoodTags = (food: Food[], currentTags: any, menuDay: number): { foodId: string; value: string[] }[] => {
  return food.map((f) => {
    const foodTags = currentTags?.find((t: any) => t.id === f.id)?.value || [];
    const labelToAdd = getLabelToAdd(menuDay, f.menuLabels ?? []);
    return {
      foodId: f.id,
      value: labelToAdd ? uniq([...foodTags, labelToAdd.label]) : foodTags
    };
  });
};
