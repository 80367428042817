import { Icon } from '@iconify/react';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { getImageUploadLink } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { ImageUploader } from 'components';
import Popup from 'components/Popup';
import { useEffect } from 'react';

interface ImagePopupProps {
  imageUrl: string | undefined;
  mainRef: any;
  displayImage: string[];
  selectedComplaintId: string;
  setImageUrlLink: React.Dispatch<React.SetStateAction<string | undefined>>;
  currentIndex: number;
  setCurrentIndex: (value: number) => void;
  viewType?: string;
  urlFile?: string;
}

const ImagePopup = ({
  urlFile = 'complaints',
  displayImage,
  currentIndex,
  setCurrentIndex,
  selectedComplaintId,
  imageUrl,
  mainRef,
  setImageUrlLink
}: ImagePopupProps) => {
  useEffect(() => {
    setCurrentIndex(displayImage.findIndex((image) => image.includes(imageUrl || '')));
  }, [imageUrl, mainRef, displayImage]);

  const handleChangeImage = (viewNext: string) => {
    if (imageUrl) {
      const currentImageIndex = displayImage.findIndex((image) => image.includes(imageUrl));
      setCurrentIndex(currentImageIndex);
      if (currentImageIndex === displayImage.length) {
        setCurrentIndex(0);
      } else if (viewNext === 'Right' && displayImage.length >= currentImageIndex) {
        setImageUrlLink(displayImage[currentImageIndex + 1]);
        setCurrentIndex(currentImageIndex + 1);
      } else if (viewNext === 'Left' && displayImage.length > currentImageIndex && currentImageIndex >= 0) {
        setImageUrlLink(displayImage[currentImageIndex - 1]);
        setCurrentIndex(currentImageIndex - 1);
      }
    }
  };

  if (imageUrl) {
    return (
      <>
        <Popup
          maxWidth="lg"
          fullWidth
          title="Attachment"
          ref={mainRef}
          onClose={() => {
            mainRef.current?.close();
            setCurrentIndex(0);
          }}
        >
          <Box display={'flex'} justifyContent={'center'}>
            <Stack justifyContent={'center'} display={displayImage.length > 1 ? 'flex' : 'none'}>
              <IconButton
                onClick={() => handleChangeImage('Left')}
                sx={{
                  backgroundColor: caloTheme.palette.primary200,
                  cursor: 'pointer',
                  display: currentIndex <= 0 ? 'none' : 'flex'
                }}
              >
                <Icon icon="formkit:arrowleft" color={caloTheme.palette.primary500} width={32} height={'32px'} />
              </IconButton>
            </Stack>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} key={imageUrl}>
              <Grid container spacing={5}>
                <Grid item key={imageUrl}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box display={'flex'} flexDirection={'column'} justifyContent={'start'}>
                        <Stack key={imageUrl} display={'flex'} flexDirection={'column'} justifyContent={'start'}>
                          <ImageUploader
                            key={imageUrl}
                            uploadLink={() =>
                              getImageUploadLink(
                                imageUrl.includes(urlFile) ? imageUrl : `${urlFile}/${selectedComplaintId}/${imageUrl}`,
                                ' '
                              )
                            }
                            image={
                              imageUrl.includes(urlFile)
                                ? `${process.env.REACT_APP_BUCKET_URL}/${imageUrl}`
                                : `${process.env.REACT_APP_BUCKET_URL}/${urlFile}/${selectedComplaintId}/${imageUrl}`
                            }
                            disabled={false}
                            width={220}
                            maxHeight={220}
                            extra
                          />
                        </Stack>
                        <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ width: '100%' }}>
                          <Typography sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
                            Attachment ( {currentIndex + 1} / {displayImage.length} )
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Stack justifyContent={'center'} display={displayImage.length > 1 ? 'flex' : 'none'}>
              <IconButton
                onClick={() => handleChangeImage('Right')}
                sx={{
                  backgroundColor: caloTheme.palette.primary200,
                  cursor: 'pointer',
                  display: currentIndex + 1 >= displayImage.length ? 'none' : 'flex'
                }}
              >
                <Icon icon="formkit:arrowright" color={caloTheme.palette.primary500} width={32} height={'32px'} />
              </IconButton>
            </Stack>
          </Box>
          <Stack
            sx={{
              justifyContent: 'center',
              flexDirection: 'row',
              display: 'flex',
              width: '100%',
              marginTop: 2
            }}
          >
            <Button
              variant="text"
              sx={{
                width: '156px',
                color: caloTheme.palette.primary500,
                fontFamily: 'Roboto',
                textAlign: 'center',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '23px',
                textTransform: 'capitalize'
              }}
              onClick={() => {
                mainRef.current?.close();
                setCurrentIndex(0);
              }}
            >
              Done
            </Button>
          </Stack>
        </Popup>
      </>
    );
  } else {
    return <></>;
  }
};
export default ImagePopup;
