import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns/fp';
import { StyledTableCell } from 'lib/componentStyles';
import { roundToDecimalPlaces } from 'lib/helpers';
import { Delivery } from 'lib/interfaces';

import { TableRow } from '@mui/material';

import styles from '../styles';
import { handleDeliveryStatusIcon } from './StatusIcon';

interface TableRowProps {
  delivery: Delivery;
  setSelectedDate: (value: Date) => void;
  selectedDate: Date;
  combine: boolean;
}

const TableRowComponent = ({ delivery, setSelectedDate, selectedDate, combine }: TableRowProps) => {
  return (
    <TableRow
      key={delivery.day}
      onClick={() => setSelectedDate(new Date(delivery.day))}
      sx={{
        ...styles.tableRow,
        border: delivery.day === format('yyyy-MM-dd')(selectedDate) ? 1 : 0,
        borderColor: caloTheme.palette.primary500
      }}
    >
      <StyledTableCell sx={styles.styledTableRow}>{format('dd/MM/yy')(new Date(delivery.day))}</StyledTableCell>
      <StyledTableCell sx={styles.styledTableRow}>
        {combine
          ? `${roundToDecimalPlaces(delivery.cost, 2)}/${roundToDecimalPlaces(delivery.paidAmount, 2)}`
          : roundToDecimalPlaces(delivery.cost, 2)}
      </StyledTableCell>
      {!combine && <StyledTableCell sx={styles.styledTableRow}>{roundToDecimalPlaces(delivery.paidAmount, 2)}</StyledTableCell>}
      <StyledTableCell>{handleDeliveryStatusIcon(delivery)}</StyledTableCell>
    </TableRow>
  );
};

export default TableRowComponent;
