import { Food } from '@calo/types';
import { CardContent, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import FoodDietTypeTags from 'components/FoodDietTypeTags';
import { capitalize } from 'lodash-es';

const AdditionalMealInfo = ({ meal }: { meal: Food }) => {
  return (
    <CardContent>
      <Stack display={'flex'} gap={2}>
        {Object.entries(meal.macros).map(([mealMacrosKey, mealMacrosValue], index) => (
          <Stack key={index}>
            <Typography
              variant="subtitle1"
              sx={{
                color:
                  mealMacrosKey === 'cal'
                    ? caloTheme.palette.primary500
                    : mealMacrosKey === 'fat'
                      ? caloTheme.palette.secondaryYellow500
                      : mealMacrosKey === 'carbs'
                        ? caloTheme.palette.secondaryPurple500
                        : caloTheme.palette.secondaryBlue500,
                backgroundColor:
                  mealMacrosKey === 'cal'
                    ? caloTheme.palette.primary100
                    : mealMacrosKey === 'fat'
                      ? caloTheme.palette.secondaryYellow100
                      : mealMacrosKey === 'carbs'
                        ? caloTheme.palette.secondaryPurple100
                        : caloTheme.palette.secondaryBlue100,
                borderRadius: '8px',
                left: '12px',
                top: '8px',
                padding: '10px 16px 10px 18px'
              }}
            >
              {`${mealMacrosKey === 'cal' ? 'Calories' : capitalize(mealMacrosKey)}: ${mealMacrosValue} ${mealMacrosKey === 'cal' ? '' : 'g'}`}
            </Typography>
          </Stack>
        ))}
      </Stack>
      <Stack
        sx={{
          color: caloTheme.palette.neutral900,
          my: 1,
          width: 'full',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Stack
          sx={{
            fontWeight: 600,
            fontFamily: caloTheme.typography.fontFamily,
            color: caloTheme.palette.neutral900,
            my: 1,
            width: 'full',
            flexDirection: 'column',
            ml: 1
          }}
        >
          Type
          <Typography
            variant="h1"
            sx={{
              mt: 1,
              borderRadius: '37px',
              textAlign: 'center',
              width: 'full',
              p: '6px',
              background: caloTheme.palette.primary100,
              color: caloTheme.palette.primary700,
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '17px',
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            {capitalize(meal.type?.join(' & '))}
          </Typography>
        </Stack>
        <Stack
          sx={{
            fontWeight: 600,
            fontFamily: caloTheme.typography.fontFamily,
            color: caloTheme.palette.neutral900,
            my: 1,
            ml: 1,
            width: 'full',
            flexDirection: 'column'
          }}
        >
          Tags
          <Typography>
            <div className="tags capitalize">
              {meal.tags.map((type, i) => (
                <FoodDietTypeTags key={i} tag={type} blueBuerry />
              ))}
            </div>
          </Typography>
        </Stack>
      </Stack>
    </CardContent>
  );
};

export default AdditionalMealInfo;
