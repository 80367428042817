import { Country, Kitchen } from '@calo/types';
import { toggleUISettings } from 'actions';
import { Select2, SideDrawer } from 'components';
import { getAccessibleCountries, getKitchenOptions } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';

interface SettingsProps {
  onFilter: (values: any) => any;
  filters: any;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Country"
          value={filters.country}
          onChange={(e) =>
            onFilter({
              ...filters,
              country: e.target.value as Country,
              kitchen: Object.values(Kitchen).find((k) => k.includes(e.target.value) && !k.includes('000'))
            })
          }
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(e) => onFilter({ ...filters, kitchen: e.target.value as Kitchen })}
          options={getKitchenOptions(userKitchens, filters.country)}
        />
      </section>
    </SideDrawer>
  );
};

export default Settings;
