import { useCallback, useEffect, useState } from 'react';

import { compact, debounce, flatten, isNull, omit, pick, uniq } from 'lodash-es';
import { useQuery } from 'react-query';
import Select, { ActionMeta, InputActionMeta, OnChangeValue } from 'react-select';
import { toast } from 'react-toastify';

import { Map } from '@calo/dashboard-types';
import { AddressType, Country, DeliveryAddress, DeliveryTime, NewDeliveryAddress } from '@calo/types';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { Alert, AlertTitle, Button, Stack, Typography } from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { getRecord } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Input } from 'components';
import { AddressViewMode } from 'lib/enums';
import { findPolygon, getCenteredCountry } from 'lib/helpers';
import { AddressService } from 'services';
import AddressTypeRow from './AddressTypeRow';
import DeliveryInstructions from './DeliveryInstructions';
import useAddressPickerForm from './useAddressPickerForm';

interface AddressPickerProps {
  label: string;
  country: Country;
  onCancel: () => void;
  onDelete?: () => any;
  viewMode?: AddressViewMode;
  onPick: (values: NewDeliveryAddress) => any;
  deliveryAddress?: Partial<DeliveryAddress> | null;
  time: DeliveryTime;
}

const AddressPicker = ({ label, country, viewMode, onPick, deliveryAddress }: AddressPickerProps) => {
  const [linkLocation, setLinkLocation] = useState<string>('');
  const [mode, setMode] = useState(viewMode || AddressViewMode.map);
  const [markerInDeliveryZone, setMarkerInDeliveryZone] = useState<boolean | undefined>(true);
  const [polys, setPolys] = useState<any>([]);
  const [options, setOptions] = useState<any[]>([]);

  const { isLoading } = useQuery([`${country}/map/default/kitchen/all`], getRecord, {
    suspense: false,
    retry: false,
    onError: console.error,
    onSuccess: (data) => {
      const maps = data as Map[];
      const concatenatedData = maps.map((obj) => {
        const morningValue = obj.morning || [];
        const eveningValue = obj.evening || [];
        return compact(uniq([...morningValue, ...eveningValue]));
      });
      setPolys(() => concatenatedData);
    }
  });

  const handleMapClick = async (e: google.maps.MapMouseEvent) => {
    console.log('handling map click');
    const address = await AddressService.getAddress(e.latLng!.lat(), e.latLng!.lng());
    setValues({
      ...(address as NewDeliveryAddress),
      lat: e.latLng!.lat(),
      lng: e.latLng!.lng()
    });
    setMarkerInDeliveryZone(!isNull(findPolygon(e.latLng!.lat(), e.latLng!.lng(), flatten(polys))));
  };

  const onMarkerSet = async () => {
    try {
      if (!values.lat || !values.lng) {
        toast('Latitude or longitude is undefined', { type: 'error', autoClose: 2000 });
        return;
      }
      const updateValuesWithAddress = async (address: Partial<NewDeliveryAddress>) => {
        setValues((prevValues) => ({
          ...pick(prevValues, ['lat', 'lng', 'type']),
          ...(address as NewDeliveryAddress),
          type: values.type as any
        }));
        setMode(AddressViewMode.form);
      };
      const address = await AddressService.getAddress(values.lat, values.lng);
      if (address.country === country) {
        await updateValuesWithAddress(address);
      } else {
        toast(`The location is not in ${country}`, { type: 'error', autoClose: 2000 });
      }
    } catch (error) {
      console.error('Failed to set marker:', error);
      toast('An error occurred while setting the marker', { type: 'error', autoClose: 2000 });
    }
  };

  const { values, handleChange, handleBlur, setValues, submitForm, setFieldValue } = useAddressPickerForm(
    onPick,
    omit(deliveryAddress || {}, ['id', 'createdAt', 'updatedAt']) as NewDeliveryAddress
  );

  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${values.lat},${values.lng}`;

  useEffect(() => {
    if (deliveryAddress && deliveryAddress.lat && deliveryAddress.lng) {
      console.log({ deliveryAddress });
      setValues({
        ...values,
        // @ts-ignore
        type: deliveryAddress.type || AddressType.home,
        // @ts-ignore
        apartment: deliveryAddress?.apartment,
        office: deliveryAddress.office,
        area: `${deliveryAddress.district} ${deliveryAddress.region}` || '',
        building: deliveryAddress.building || '',
        lat: deliveryAddress.lat!,
        lng: deliveryAddress.lng!,
        notes: deliveryAddress.notes || '',
        postalCode: deliveryAddress.postalCode || '',
        postalCodeSuffic: deliveryAddress.postalCodeSuffix || '',
        city: deliveryAddress.city || '',
        street: deliveryAddress.street || ''
      });
    }
  }, [deliveryAddress]);

  useEffect(() => {
    if (linkLocation) {
      const url = linkLocation.split(',');
      const lat = +url[0];
      const lng = +url[1];
      setValues({
        ...values,
        lat: lat,
        lng: lng
      });
      setMarkerInDeliveryZone(!isNull(findPolygon(lat, lng, flatten(polys))));
    }
  }, [linkLocation]);

  useEffect(() => {
    if (values.type === AddressType.home) {
      setValues({
        ...values,
        apartment: values.apartment || ''
      });
    } else {
      setValues({
        ...values,
        office: values.office || ''
      });
    }
  }, [values.type]);

  useEffect(() => {
    if (country) {
      let toUpdate: any = {
        country
      };
      if (values.country !== country || !values.lat || !values.lng) {
        toUpdate = {
          ...toUpdate,
          lat: getCenteredCountry(country).lat,
          lng: getCenteredCountry(country).lng
        };
      }
      setValues({
        ...values,
        ...toUpdate
      });
    }
  }, [country]);

  const copyGoogleMapsLinkToClipboard = () => {
    navigator.clipboard.writeText(googleMapsLink);
  };

  const handleSelect = async ({ value }: OnChangeValue<any, false>, { action }: ActionMeta<any>) => {
    if (action === 'select-option') {
      AddressService.getPlace(value).then((place) => {
        setValues({
          ...values,
          placeId: value,
          lat: place.geometry!.location!.lat(),
          lng: place.geometry!.location!.lng()
        });
      });
    }
  };

  const submitFormHandler = () => {
    if (!values.type) {
      toast('Please select an address type (Home or Office)', { type: 'error', autoClose: 2000 });
      return;
    }
    submitForm();
  };

  const handleSearch = useCallback(
    debounce((text: string, { action }: InputActionMeta) => {
      if (action === 'input-change') {
        AddressService.autocompleteAddress(text, {}).then((data) => setOptions(data));
      }
    }, 600),
    []
  );

  if (mode === AddressViewMode.map) {
    return (
      <>
        <section className="section is-title-bar -mx-5 -mt-8">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <Typography
                  sx={{
                    mb: '-16px',
                    fontSize: '28px',
                    fontWeight: 600,
                    textTransform: 'none'
                  }}
                >
                  {label}
                </Typography>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Typography
                  sx={{
                    mb: '-16px',
                    fontSize: '28px',
                    fontWeight: 600,
                    textTransform: 'none'
                  }}
                >
                  1/2
                </Typography>
              </div>
            </div>
          </div>
        </section>
        <div className="flex-shrink-0 flex items-center mt-4">
          <div className="w-full">
            <Input
              label="Coordinates"
              className="rounded-lg"
              onChange={(data) => setLinkLocation(data.target.value)}
              placeholder="31.9719, 35.8492"
            />
            <Select
              value={{
                value: values.placeId,
                label: AddressService.display(values as NewDeliveryAddress)
              }}
              onChange={handleSelect}
              onInputChange={handleSearch}
              options={options}
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              placeholder="Search"
              isLoading={isLoading}
            />
          </div>
        </div>
        {isLoading ? (
          <CaloLoader />
        ) : (
          <Stack
            sx={{
              border: markerInDeliveryZone ? 0 : 3,
              borderColor: markerInDeliveryZone ? caloTheme.palette.primary500 : caloTheme.palette.red,
              mt: 2,
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {!markerInDeliveryZone && (
              <Alert severity="error" sx={{ mb: '-16px', zIndex: 10 }}>
                <strong>Location Outside Delivery Area</strong>
              </Alert>
            )}
            <GoogleMap
              mapContainerClassName="w-full h-454 rounded-xl"
              center={{ lat: values.lat!, lng: values.lng! }}
              zoom={13}
              options={{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
              }}
              onClick={handleMapClick}
            >
              <Marker position={{ lat: values.lat!, lng: values.lng! }} draggable onDragEnd={handleMapClick} />
            </GoogleMap>
          </Stack>
        )}

        <Button
          data-test="confirmPinLocationButton"
          variant="contained"
          aria-label="addAddressButton"
          sx={{
            width: '100%',
            height: '51px',
            color: 'white',
            mt: 2,
            lineHeight: '17px',
            fontWeight: 600,
            fontSize: '14px',
            borderRadius: '8px',
            borderColor: caloTheme.palette.primary500,
            backgroundColor: caloTheme.palette.primary500,
            '&:hover': {
              backgroundColor: caloTheme.palette.primary600,
              borderColor: caloTheme.palette.primary600
            }
          }}
          onClick={onMarkerSet}
          disabled={!markerInDeliveryZone}
        >
          Confirm Pin Location
        </Button>
      </>
    );
  }

  return (
    <>
      <section className="section is-title-bar -mx-5 -mt-8">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <Typography
                sx={{
                  mb: '-16px',
                  fontSize: '28px',
                  fontWeight: 600,
                  textTransform: 'none'
                }}
              >
                {label}
              </Typography>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <Typography
                sx={{
                  mb: '-16px',
                  fontSize: '28px',
                  fontWeight: 600,
                  textTransform: 'none'
                }}
              >
                2/2
              </Typography>
            </div>
          </div>
        </div>
      </section>
      <div className="py-8 overflow-y-auto">
        <div className="flex-col mb-4">
          <img
            className="w-full mx-auto rounded-lg"
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${values.lat},${values.lng}
            &zoom=11&size=600x150&maptype=roadmap
            &markers=color:green|${values.lat},${values.lng}
            &key=AIzaSyBRXQIB947Dfvs_384Q5K4eR04mt4K-zQQ`}
            alt=""
          />

          <Button
            variant="outlined"
            aria-label="addAddressButton"
            sx={{
              width: '100%',
              height: '51px',
              mt: 2,
              lineHeight: '17px',
              fontWeight: 600,
              fontSize: '20px',
              borderRadius: '8px',
              textTransform: 'none',
              borderColor: caloTheme.palette.primary500,
              color: caloTheme.palette.primary500,
              '&:hover': {
                backgroundColor: caloTheme.palette.primary100,
                borderColor: caloTheme.palette.primary600
              }
            }}
            onClick={() => setMode(AddressViewMode.map)}
          >
            Edit Location Pin
          </Button>
        </div>
        <div className="flex-col">
          <Stack
            sx={{
              marginBottom: '16px',
              backgroundColor: '#F7F7F7',
              paddingBottom: '8px',
              paddingX: '16px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Stack
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}
            >
              <Alert severity="info" sx={{ backgroundColor: '#F7F7F7', paddingLeft: 0 }}>
                <AlertTitle sx={{ fontSize: '14px', paddingTop: '2px' }}>Coordinates</AlertTitle>
              </Alert>
              <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '-15px' }}>
                <a href={googleMapsLink} target="_blank" rel="noreferrer">
                  <Typography sx={{ fontSize: '14px' }}>Google Maps Link</Typography>
                </a>
                <Button onClick={() => copyGoogleMapsLinkToClipboard()}>
                  <CopyAllIcon />
                </Button>
              </Stack>
            </Stack>
            <Typography sx={{ marginLeft: '6px', marginTop: '-8px', fontSize: '14px' }}>
              Lat: {values.lat}, Lng: {values.lng}
            </Typography>
          </Stack>
        </div>
        <div className="flex-col">
          <div className="flex flex-row justify-center mb-4 cursor-pointer">
            <AddressTypeRow
              type={AddressType.home}
              onSelect={(t) => setFieldValue('type', t)}
              selected={values.type === AddressType.home}
              name="Home"
            />
            <span className="w-full ml-2">
              <AddressTypeRow
                type={AddressType.office}
                onSelect={(t) => setFieldValue('type', t)}
                selected={values.type === AddressType.office}
                name="Office"
              />
            </span>
          </div>
          {country === Country.GB ? (
            <>
              <div className="flex flex-row justify-center mb-4">
                <span className="w-full ml-2 rounded-xl">
                  <Input onChange={handleChange} onBlur={handleBlur} value={values.building} name="building" label="Building" />
                </span>
                <span className="w-full ml-2 rounded-xl">
                  <Input onChange={handleChange} onBlur={handleBlur} value={values.street} name="street" label="Street" />
                </span>
              </div>
              <div className="flex flex-row justify-center mb-4">
                <span className="w-full ml-2 rounded-xl">
                  <Input onChange={handleChange} onBlur={handleBlur} value={values.city} name="city" label="City/Town" />
                </span>
                <span className="w-full ml-2 rounded-xl">
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.postalCode}
                    name="postalCode"
                    label="Postcode"
                  />
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-row justify-center mb-4">
                <span className="w-full ml-2 rounded-xl">
                  {values.type === AddressType.home ? (
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.apartment}
                      type="text"
                      name="apartment"
                      label="Apartment"
                    />
                  ) : (
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.office}
                      type="text"
                      name="office"
                      label="Office"
                    />
                  )}
                </span>
                <span className="w-full ml-2 rounded-xl">
                  <Input onChange={handleChange} onBlur={handleBlur} value={values.building} name="building" label="Building" />
                </span>
              </div>
              <div className="flex flex-row justify-center mb-4">
                <span className="w-full ml-2 rounded-xl">
                  <Input onChange={handleChange} onBlur={handleBlur} value={values.street} name="street" label="Street" />
                </span>
                <span className="w-full ml-2 rounded-xl">
                  <Input onChange={handleChange} onBlur={handleBlur} value={values.district} name="district" label="Area" />
                </span>
              </div>
            </>
          )}

          <DeliveryInstructions
            values={values}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <Button
            data-test="saveAddressButton"
            variant="contained"
            aria-label="addAddressButton"
            disabled={
              (values.country === 'GB'
                ? !(values.street && values.building && values.city && values.postalCode && values.type)
                : !(values.type === AddressType.home ? values.apartment : values.office)) || !values.building
            }
            sx={{
              boxShadow: 'none',
              width: '100%',
              height: '51px',
              mt: 2,
              lineHeight: '17px',
              fontWeight: 600,
              fontSize: '14px',
              borderRadius: '8px',
              borderColor: caloTheme.palette.primary500,
              backgroundColor: caloTheme.palette.primary500,
              color: 'white',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: caloTheme.palette.primary600,
                borderColor: caloTheme.palette.primary600
              }
            }}
            onClick={submitFormHandler}
          >
            Save Address
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddressPicker;
