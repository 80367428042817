import { CustomMealCategory, Food, FoodComponentType, Localized, Macros } from '@calo/types';
import { Stack } from '@mui/material';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { ComponentLabelValue, ComponentsByTag, FoodComponent, MenuFood } from 'lib/interfaces';
import { keyBy, times } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import ConfirmationForm from './ConfirmationForm';
import CustomMealButton from './CustomMealButton';
import InitialForm from './InitialForm';

interface CustomMealFormProps {
  customFood?: Food | null;
  isFinished: boolean;
  setIsFinished: React.Dispatch<React.SetStateAction<boolean>>;
  customFoodName: Localized;
  mealDescription: Localized;
  componentsByCategory: FoodComponent[];
  customMealAction: string;
  customMealCategory: CustomMealCategory;
  setCustomFoodName: React.Dispatch<React.SetStateAction<Localized>>;
  setMealDescription: React.Dispatch<React.SetStateAction<Localized>>;
  handleUpdateCustomFood: (
    value: MenuFood,
    customName: Localized,
    mealDescription: Localized,
    customMealCategory: CustomMealCategory
  ) => void;
}

const CustomMealForm = ({
  customFood,
  isFinished,
  customFoodName,
  mealDescription,
  componentsByCategory,
  customMealAction,
  customMealCategory,
  setIsFinished,
  setCustomFoodName,
  setMealDescription,
  handleUpdateCustomFood
}: CustomMealFormProps) => {
  const [selectedComponents, setSelectedComponents] = useState<ComponentsByTag>({
    base: [],
    protein: [],
    side: [],
    topping: [],
    sauce: []
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<FoodComponentType>(FoodComponentType.base);

  const keyedComponents = useMemo(() => keyBy(componentsByCategory, 'id'), [componentsByCategory]);

  const selectedComponentsIds = Object.values(selectedComponents).flatMap((components: ComponentLabelValue[]) =>
    components.map((comp) => comp.value)
  );

  const getTotalMacros = () => {
    return selectedComponentsIds.reduce(
      (macros, id) => {
        const component = keyedComponents[id];
        macros.fat += component.macros.fat;
        macros.protein += component.macros.protein;
        macros.carbs += component.macros.carbs;
        macros.cal += component.macros.cal;
        return macros;
      },
      { fat: 0, protein: 0, carbs: 0, cal: 0 } as Macros
    );
  };

  useEffect(() => {
    if (!customFood) {
      return;
    }

    const selectedComponentsInFood: ComponentsByTag = {
      base: [],
      protein: [],
      side: [],
      topping: [],
      sauce: []
    };

    for (const component of customFood?.components ?? []) {
      const section = component.sections?.find((comp) => comp.category === customMealCategory);
      if (!section) {
        continue;
      }

      const mappedComponent = keyedComponents[component.id];
      selectedComponentsInFood[section.tag] = [
        ...selectedComponentsInFood[section.tag],
        ...times(component?.count ?? 0, () => ({ label: mappedComponent.name.en, value: component.id }))
      ];
    }

    setSelectedComponents(selectedComponentsInFood);
  }, [customFood]);

  useEffect(() => {
    if (customFood?.name) {
      setCustomFoodName({ en: customFood.name.en, ar: customFood.name.en });
    }
    if (customFood?.description) {
      setMealDescription({ en: customFood.description.en, ar: customFood.description.en });
    }
    setIsSubmitting(false);
  }, []);

  const handleNameChange = (data: string) => {
    const customName = data;
    if (customName.length <= 32) {
      setCustomFoodName({ en: data, ar: data });
    }
  };

  const handleDescriptionChange = (description: string) => {
    setMealDescription({ en: description, ar: description });
  };

  const buttonClickHandler = () => {
    if (isFinished) {
      setIsSubmitting(true);
      handleUpdateCustomFood(selectedComponents as any, customFoodName, mealDescription, customMealCategory);
      return;
    }

    const tags = Object.keys(selectedComponents);
    const indexOfCurrentTag = tags.indexOf(selectedTag);

    if (selectedTag !== FoodComponentType.sauce) {
      setSelectedTag(tags[indexOfCurrentTag + 1] as FoodComponentType);
      return;
    }

    setIsFinished(true);
  };

  return (
    <InputThemeProvider>
      <Stack sx={{ width: '520px', position: 'relative', maxHeight: '820px', height: '820px' }}>
        {!isFinished && (
          <InitialForm
            selectedTag={selectedTag}
            keyedComponents={keyedComponents}
            selectedComponents={selectedComponents}
            customFoodName={customFoodName}
            totalMacros={getTotalMacros()}
            customMealCategory={customMealCategory}
            componentsByCategory={componentsByCategory}
            handleNameChange={handleNameChange}
            setSelectedComponents={setSelectedComponents}
            setSelectedTag={setSelectedTag}
          />
        )}

        {isFinished && (
          <ConfirmationForm
            customFoodName={customFoodName}
            mealDescription={mealDescription}
            selectedComponents={selectedComponents}
            customMealCategory={customMealCategory}
            keyedComponents={keyedComponents}
            totalMacros={getTotalMacros()}
            handleNameChange={handleNameChange}
            handleDescriptionChange={handleDescriptionChange}
          />
        )}
        <CustomMealButton
          customFoodName={customFoodName}
          customMealAction={customMealAction}
          selectedComponents={selectedComponents}
          selectedTag={selectedTag}
          isSubmitting={isSubmitting}
          isFinished={isFinished}
          clickHandler={buttonClickHandler}
          customMealCategory={customMealCategory}
        />
      </Stack>
    </InputThemeProvider>
  );
};

export default CustomMealForm;
