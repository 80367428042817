import { Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { Food } from 'lib/interfaces';

interface MealNameProps {
  selectedFood: Food;
}

const MealName = ({ selectedFood }: MealNameProps) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mx: 1 }}>
      <Typography sx={{ fontSize: '20px', fontWeight: 600, lineHeight: '24px', color: caloTheme.palette.black }}>
        {selectedFood.name.en}
      </Typography>
    </Stack>
  );
};

export default MealName;
