import { useFormik } from 'formik';

import { Macros } from '@calo/types';

interface CustomizeFoodMacrosFormProps {
  onSuccess: (newMacros: Macros) => void;
}
const useCustomizeFoodMacrosForm = ({ onSuccess }: CustomizeFoodMacrosFormProps) => {
  const formik = useFormik<Macros>({
    initialValues: {
      carbs: 0,
      cal: 0,
      fat: 0,
      protein: 0
    },

    validate: (_: Macros) => {
      const errors: any = {};
      // Temporary disabled
      // if (values.cal > 850) {
      //   errors.cal = 'Calories cannot be greater than 850';
      // }

      // if (values.protein > 80) {
      //   errors.protein = 'Protein cannot be greater than 80';
      // }

      return errors;
    },
    onSubmit: async (values) => {
      onSuccess(values);
    }
  });

  return formik;
};

export default useCustomizeFoodMacrosForm;
