import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Food, MenuFood } from 'lib/interfaces';
import { renderFoodDetails } from './helpers';

interface ConfirmationPopupProps {
  replaceFood: {
    open: boolean;
    name: string;
    data?: MenuFood;
  };
  selectedFoodReplace?: Food;
  confirmationRef: any;
  handleReplaceFood: (food: Food[]) => void;
  foodListFilters: Food[];
}

const ConfirmationPopup = ({
  foodListFilters,
  confirmationRef,
  handleReplaceFood,
  selectedFoodReplace,
  replaceFood
}: ConfirmationPopupProps) => {
  return (
    <Box>
      <Stack sx={{ flexDirection: 'row', width: '100%', justifyContent: 'center', my: 1 }}>
        <Stack sx={styles.foodStack}>
          <Typography sx={styles.typographyTitle}>{replaceFood.name}</Typography>
          <Typography sx={styles.typographyDetails}>{renderFoodDetails(replaceFood.data)}</Typography>
        </Stack>
        <Typography sx={{ display: 'flex', justifyContent: 'center', my: 'auto', mx: 1 }}>
          {' '}
          <ChevronRightIcon />{' '}
        </Typography>
        <Stack sx={styles.foodStack}>
          <Typography sx={styles.typographyTitle}>{selectedFoodReplace?.name.en || ''}</Typography>
          <Typography sx={styles.typographyDetails}>{renderFoodDetails(selectedFoodReplace)}</Typography>
        </Stack>
      </Stack>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'center', textAlign: 'center', mt: 3 }}>
        <Button
          variant="text"
          sx={{ ...styles.buttonStyle, mr: 2, color: caloTheme.palette.neutral600 }}
          onClick={() => confirmationRef.current?.close()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            ...styles.buttonStyle,
            backgroundColor: caloTheme.palette.primary500,
            color: 'white',
            '&:hover': {
              backgroundColor: caloTheme.palette.primary600,
              boxShadow: 'none'
            }
          }}
          disabled={!selectedFoodReplace}
          onClick={() =>
            handleReplaceFood(
              foodListFilters.filter(
                (food) =>
                  selectedFoodReplace && food.name.en === selectedFoodReplace.name.en && ['XS', 'S', 'M', 'L'].includes(food.size)
              )
            )
          }
        >
          Confirm
        </Button>
      </Stack>
    </Box>
  );
};

export default ConfirmationPopup;

const styles = {
  foodStack: {
    backgroundColor: caloTheme.palette.neutral50,
    padding: '8px 4px 8px 4px',
    minHeight: '68px',
    width: '44%',
    gap: '8px',
    borderRadius: '8px'
  },
  typographyTitle: {
    textTransform: 'capitalize',
    justifyContent: 'center',
    display: 'flex',
    color: caloTheme.palette.neutral900,
    fontWeight: 600,
    fontsize: '20px',
    lineHeight: '24px',
    alignContent: 'center',
    textAlign: 'center'
  },
  typographyDetails: {
    textTransform: 'capitalize',
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    color: caloTheme.palette.neutral900,
    fontWeight: 400,
    fontsize: '16px',
    lineHeight: '20px',
    alignContent: 'center',
    textAlign: 'center',
    width: '100%'
  },
  buttonStyle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    boxShadow: 'none',
    width: '153px',
    height: '44px',
    padding: '10px 20px 10px 20px',
    gap: '10px',
    borderRadius: '8px'
  }
};
