import { Card, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { caloTheme } from 'assets/images/theme/calo';
import { format, parseISO } from 'date-fns/fp';
import { sortBy } from 'lodash';

interface AddonsProps {
  addons: any;
}

const RetailMenuRow = ({ addons }: AddonsProps) => {
  const sortedList = sortBy(addons.food, 'priority');

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  return (
    <Card variant="outlined" sx={{ border: 'none', borderRadius: '8px', paddingBottom: '4px' }}>
      <div className="table-container">
        <Table
          sx={{
            marginY: '4px',
            minHeight: '120px',
            overflow: 'auto',
            width: '100%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
        >
          <TableHead
            style={{ borderRadius: '8px' }}
            sx={{
              backgroundColor: caloTheme.palette.neutral50,
              color: 'black',
              flexWrap: 0,
              justifyContent: 'space-between',
              width: '100%',
              borderRadius: '8px'
            }}
          >
            <TableRow
              sx={{
                backgroundColor: caloTheme.palette.neutral50,
                color: 'black',
                width: '100%',
                flexWrap: 0,
                justifyContent: 'space-between'
              }}
            >
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Food Tags</StyledTableCell>
              <StyledTableCell>Retail Price</StyledTableCell>
              <StyledTableCell>Size</StyledTableCell>
              <StyledTableCell>Start Date</StyledTableCell>
              <StyledTableCell>End Date</StyledTableCell>
              <StyledTableCell>Disabled</StyledTableCell>
              <StyledTableCell>Swap Available</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedList.map((f: any) => (
              <TableRow key={f.id}>
                <StyledTableCell>
                  <div key={f.id} className="mb-4">
                    {f.name.en}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div key={f.id} className="mb-4">
                    {f.retail.retailTagList?.join(', ')}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div key={f.id} className="mb-4">
                    {f.retail.retailPrice}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div key={f.id} className="mb-4">
                    {f.size}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div key={f.id} className="mb-4">
                    {f.retail.startDate ? format('yyyy-MM-dd')(parseISO(f.retail.startDate)) : 'N/A'}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div key={f.id} className="mb-4">
                    {f.retail.endDate ? format('yyyy-MM-dd')(parseISO(f.retail.endDate)) : 'N/A'}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div key={f.id} className="mb-4  w-16 text-center">
                    {f.retail.disabled ? 'Yes' : 'No'}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div key={f.id} className="mb-4  w-16 text-center">
                    {f.retail.swapAvailable ? 'Yes' : 'No'}
                  </div>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
};

export default RetailMenuRow;
