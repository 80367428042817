import { useRef, useState } from 'react';

import { compact, flatten, groupBy, uniq, uniqBy } from 'lodash';
import { useMutation } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import { Subscription } from '@calo/types';

import { validateAllergens } from 'actions';
import { Button, Icon, Modal, ModalRef, Select2 } from 'components';
import { stars, types } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { MenuFood } from 'lib/interfaces';

interface AddMealRowProps {
  food: MenuFood;
  allMenuFood: MenuFood[];
  selectedMeals: MenuFood[];
  selectedUsers: Subscription[];
  autoSelectSubscribers: boolean | undefined;
  onPick: (food: MenuFood[]) => void;
  setSelectedUsers: (values: Subscription[]) => void;
}

const AddMealRow = ({
  setSelectedUsers,
  allMenuFood,
  food,
  onPick,
  selectedMeals,
  selectedUsers,
  autoSelectSubscribers
}: AddMealRowProps) => {
  const [selectedSize, setSelectedSize] = useState<any>({ size: food.size, foodId: food.id, foodName: food.name.en });
  const sameMealSize = flatten(Object.values(groupBy(allMenuFood, 'name.en')));

  const [checkedMeal, setCheckedMeal] = useState<any>({ foodId: '', status: false, rejected: [], passed: [] });
  const [checkedLoading, setCheckedLoading] = useState<boolean>(false);
  const ExactSelectedDataRef = useRef<ModalRef>();

  const { mutateAsync: validateMutation } = useMutation(validateAllergens);

  const roles = useUserRoles();

  const handleValidateFood = async (selectedUsers: Subscription[], foodId: string) => {
    const selectedIds = selectedUsers.map((r) => r.id);
    const { isLoading } = await validateMutation(
      {
        subscriptionIds: selectedIds,
        foodId: foodId
      },
      {
        onSuccess: (data) => {
          setCheckedMeal({ foodId: foodId, status: true, passed: data.passed, rejected: data.rejected });
        }
      }
    );
    if (isLoading) {
      setCheckedLoading(isLoading);
    } else {
      setCheckedLoading(isLoading);
    }
  };
  const handleSizeChange = (size: string, allSizes: MenuFood[]) => {
    const selectedMealData = flatten(compact(allSizes.filter((r) => r.size === size)));
    setSelectedSize({
      size: selectedMealData[0].size,
      foodId: selectedMealData[0].id,
      foodName: selectedMealData[0].name.en
    });
  };

  const handleFoodSize = (foodName: MenuFood) => {
    const allSizes = compact(sameMealSize.map((r) => r.name.en === foodName.name.en && r));

    return (
      <Select2
        value={selectedSize?.size}
        name="size"
        disabled={!!selectedMeals.find((r) => r.id === selectedSize.foodId)}
        onChange={(data: any) => handleSizeChange(data.target.value, allSizes)}
        options={Object.values(allSizes).map((meal) => ({
          value: meal.size,
          label: meal.size
        }))}
      />
    );
  };

  const handleSelectMeal = (food: MenuFood, selectedSize: any) => {
    if (selectedSize.foodName === food.name.en && selectedSize.size !== food.size) {
      const selectedMealSize = allMenuFood.find((meal) => meal.id === selectedSize.foodId);
      onPick(uniqBy([...selectedMeals, selectedMealSize!], 'id'));
    } else {
      onPick(uniqBy([...selectedMeals, food], 'id'));
    }
  };

  const handleRemoveMeal = (food: MenuFood, selectedSize: any) => {
    if (selectedSize.foodName === food.name.en && selectedSize.size !== food.size) {
      const selectedMealSize = allMenuFood.find((meal) => meal.id === selectedSize.foodId);
      onPick(
        uniqBy(
          selectedMeals.filter((r) => r !== selectedMealSize!),
          'id'
        )
      );
    } else {
      onPick(
        uniqBy(
          selectedMeals.filter((meal) => meal !== food),
          'id'
        )
      );
    }
  };

  const handleButton = (food: any) => {
    if (autoSelectSubscribers) {
      if (selectedMeals.includes(food)) {
        return (
          <Button
            className="w-24  border-2 border-red-500 bg-red-50 text-red-500 outline:none hover:text-red-500 hover:border-red-500 focus:border-red-500 focus:text-red-500"
            content="Remove"
            onClick={() => handleRemoveMeal(food, selectedSize)}
          />
        );
      } else {
        return (
          <Button
            className="w-24  border border-green-500 text-green-500 outline:none hover:text-green-500 hover:border-green-500 focus:border-green-500 focus:text-green-500"
            content={'Add'}
            onClick={() => handleSelectMeal(food, selectedSize)}
          />
        );
      }
    } else {
      if (selectedMeals.includes(food)) {
        return (
          <Button
            className="w-24  border-2 border-red-500 bg-red-50 text-red-500 outline:none hover:text-red-500 hover:border-red-500 focus:border-red-500 focus:text-red-500"
            content="Remove"
            onClick={() => handleRemoveMeal(food, selectedSize)}
          />
        );
      } else if (food.id === checkedMeal.foodId && checkedMeal.status && !selectedMeals.includes(food.id)) {
        return (
          <Button
            className="w-24  border border-green-500 text-green-500 outline:none hover:text-green-500 hover:border-green-500 focus:border-green-500 focus:text-green-500"
            content={'Add'}
            onClick={() => handleSelectMeal(food, selectedSize)}
          />
        );
      } else {
        return (
          <Button
            className="border border-green-500 text-green-500 outline:none hover:text-green-500 hover:border-green-500 focus:border-green-500 focus:text-green-500"
            content={'Check Allergies'}
            disabled={checkedLoading}
            onClick={() => handleValidateFood(selectedUsers, food.id)}
          />
        );
      }
    }
  };
  return (
    <>
      <tr key={food.id}>
        <td>
          <span>
            <span>{food.name.en}</span>
            {checkedMeal.rejected.length > 0 && food.id === checkedMeal.foodId && (
              <span className="flex mt-1">
                <p className=" text-red-400 text-xs">Some Customers are allergic to ingresients in this meal</p>
                <Icon name="infoWarning" size={6} className="ml-2" onClick={() => ExactSelectedDataRef.current?.open()} />
              </span>
            )}
          </span>
        </td>
        <td>{stars(food?.totalRating && food?.numberOfRatings ? food.totalRating / food.numberOfRatings : 0)}</td>
        <td>
          <div className="tags capitalize">{food.type?.map((type) => types(type))}</div>
        </td>
        <td>{handleFoodSize(food)}</td>
        <td>{roles.includes(Permission.VALIDATE_GROUP_GIFT) && handleButton(food)}</td>
      </tr>
      <Modal
        ref={ExactSelectedDataRef}
        onClose={() => {
          ExactSelectedDataRef.current?.close();
        }}
      >
        <section className="section is-title-bar label -my-8 ">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <p className="uppercase font-roboto text-2xl">Subscribers allergic to ingredients</p>
              </div>
            </div>
          </div>
        </section>
        <section className="section -m-8">
          <div className="card has-table has-table-container-upper-radius">
            <div className="card-content">
              <div className="table-container overflow-y-auto" style={{ maxHeight: '26rem' }}>
                <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                  <thead>
                    <tr className="bg-black sticky top-0 z-50">
                      <th style={{ color: 'white' }} className=" w-48">
                        Subscriber ID
                      </th>
                      <th style={{ color: 'white' }} className=" w-48">
                        Name
                      </th>
                      <th style={{ color: 'white' }} className=" w-48">
                        Phone
                      </th>
                      <th style={{ color: 'white' }} className=" w-48">
                        Email
                      </th>
                      <th style={{ color: 'white' }} className=" w-48">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkedMeal.rejected &&
                      checkedMeal.rejected.map((subscriber: Subscription) => (
                        <tr key={subscriber.id}>
                          <td>{subscriber.id}</td>
                          <td>{subscriber.name}</td>
                          <td>{subscriber.phoneNumber}</td>
                          <td>{subscriber.email}</td>
                          <td>
                            <Button
                              content="Remove"
                              className="w-24  border-2 border-red-500 bg-red-50 text-red-500 outline:none hover:text-red-500 hover:border-red-500 focus:border-red-500 focus:text-red-500"
                              onClick={() =>
                                setCheckedMeal({
                                  ...checkedMeal,
                                  rejected: checkedMeal.rejected.filter((r: any) => r.id !== subscriber.id)
                                })
                              }
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <section className="section -m-8">
          <div className="level-item">
            <Button
              primary
              content="Done"
              disabled={checkedMeal.length === 0 || checkedLoading}
              onClick={() => {
                setSelectedUsers(uniq(compact([...checkedMeal.passed, ...checkedMeal.rejected])));
                ExactSelectedDataRef.current?.close();
              }}
            />
          </div>
        </section>
      </Modal>
    </>
  );
};
export default AddMealRow;
