import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import { AddPerksEmployeeReq, RemovePerksEmployeeReq } from 'lib/interfaces';

import mutation from './mutation';

export const addPerksEmployee = async (values: AddPerksEmployeeReq) => {
  const id = `${values.country}_${values.year}_${values.month}`;
  const { data, status } = await client.put(`/perks/${id}`, values);
  ToastSuccessMsgs({ status, action: 'Employee added to the perks successfully' });
  return data;
};

export const removePerksEmployee = async (values: RemovePerksEmployeeReq) => {
  const id = `${values.country}#${values.year}#${values.month}`;
  const { data, status } = await client.put('/perks/remove', values);
  ToastSuccessMsgs({ status, action: 'Employee removed from the perks successfully' });
  mutation(['perks', id], data);
  return data;
};
