import { useEffect } from 'react';

import { ModalRef } from 'components';
import FoodComponentMacrosCalculator from 'components/FoodComponentMacrosCalculator';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { FormikErrors } from 'formik';
import { useUserRoles } from 'lib/hooks';
import { BaseOmit, FoodComponent } from 'lib/interfaces';
import { toast } from 'react-toastify';

import { Macros, Micronutrients } from '@calo/types';
import { Card, Stack, TextField } from '@mui/material';
import { FormOperation } from 'lib/enums';
import { calculateCalories } from 'lib/helpers';
import { getComponentPermission } from 'lib/helpers/permissionUtils';

interface MacrosMicrosInputProps {
  operation: FormOperation;
  prototype?: boolean;
  values: Omit<FoodComponent, BaseOmit>;
  errors: FormikErrors<Omit<FoodComponent, BaseOmit>>;
  calculatedMacrosFromIngredients: Macros | undefined;
  calculatedMicronutrientsFromIngredients: Micronutrients | undefined;
  isCalculatedMacrosDifferent: boolean;
  calculatedMacrosRef: React.MutableRefObject<ModalRef | undefined>;
  setValues: (
    values: React.SetStateAction<Omit<FoodComponent, BaseOmit>>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
  handleChange: { (e: React.ChangeEvent<any>): void };
  handleChangeForNumRestrictions: (event: React.ChangeEvent<any>) => void;
  isDisabled?: boolean;
}

const MacrosMicrosInputCard = ({
  prototype,
  operation,
  values,
  errors,
  calculatedMacrosFromIngredients,
  calculatedMicronutrientsFromIngredients,
  isCalculatedMacrosDifferent,
  calculatedMacrosRef,
  setValues,
  setFieldValue,
  handleChange,
  handleBlur,
  handleChangeForNumRestrictions,
  isDisabled
}: MacrosMicrosInputProps) => {
  const userRoles = useUserRoles();

  useEffect(() => {
    setFieldValue('macros.cal', calculateCalories(values.macros));

    if (!values.macros.fiber) {
      setFieldValue('macros.fiber', 0);
    }
  }, [values.macros?.carbs, values.macros?.fat, values.macros?.protein]);

  useEffect(() => {
    const calValue = calculateCalories(values.macros);
    if (values.macros?.cal !== calValue) {
      setFieldValue('macros.cal', calValue);
    }
  }, []);

  const applyCalculatedMacros = () => {
    if (calculatedMacrosFromIngredients && calculatedMicronutrientsFromIngredients) {
      setValues({
        ...values,
        macros: {
          ...values.macros,
          ...calculatedMacrosFromIngredients
        },
        micronutrients: {
          ...values.micronutrients,
          ...calculatedMicronutrientsFromIngredients
        }
      });
    } else {
      toast(`There is no values from ingredients`, { type: 'error', autoClose: 2000 });
    }
  };

  const permission = getComponentPermission(userRoles, operation, prototype);

  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: '16px',
        border: 'none',
        borderRadius: '16px',
        px: '14px',
        paddingTop: '12px',
        paddingBottom: '20px'
      }}
    >
      <FoodComponentMacrosCalculator
        applyCalculatedMacros={applyCalculatedMacros}
        calculatedMacrosRef={calculatedMacrosRef}
        isCalculatedMacrosDifferent={isCalculatedMacrosDifferent}
        isDisabled={isDisabled}
      />
      <InputThemeProvider>
        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2, marginTop: '16px' }}>
          <TextField
            data-test="calInput"
            label="Cal*"
            value={values.macros.cal}
            name="macros.cal"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            sx={{ width: '100%', zIndex: 0 }}
            disabled
            error={!!errors.macros?.cal}
          />
          <TextField
            data-test="proteinInput"
            label="Protein*"
            value={values.macros.protein}
            name="macros.protein"
            onChange={handleChangeForNumRestrictions}
            onBlur={handleBlur}
            type="number"
            sx={{ width: '100%', zIndex: 0 }}
            disabled={!permission || isDisabled}
            error={!!errors.macros?.protein}
          />
          <TextField
            data-test="carbsInput"
            label="Carbs*"
            value={values.macros.carbs}
            name="macros.carbs"
            onChange={handleChangeForNumRestrictions}
            type="number"
            onBlur={handleBlur}
            sx={{ width: '100%', zIndex: 0 }}
            disabled={!permission || isDisabled}
            error={!!errors.macros?.carbs}
          />
          <TextField
            data-test="fatInput"
            label="Fat*"
            value={values.macros.fat}
            name="macros.fat"
            onChange={handleChangeForNumRestrictions}
            onBlur={handleBlur}
            type="number"
            sx={{ width: '100%', zIndex: 0 }}
            disabled={!permission || isDisabled}
            error={!!errors.macros?.fat}
            inputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          />
          <TextField
            data-test="fiberInput"
            label="Fiber"
            value={values.macros.fiber}
            name="macros.fiber"
            onChange={handleChangeForNumRestrictions}
            onBlur={handleBlur}
            type="number"
            sx={{ width: '100%', zIndex: 0 }}
            disabled={!permission || isDisabled}
            inputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          />
        </Stack>
        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2, marginTop: 2 }}>
          <TextField
            data-test="saturatedFatsInput"
            label="Saturated Fats"
            value={values.micronutrients?.saturatedFats}
            name="micronutrients.saturatedFats"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            disabled={!permission || isDisabled}
            sx={{ width: '100%', zIndex: 0 }}
            error={!!errors.micronutrients?.saturatedFats}
          />
          <TextField
            data-test="transFatsInput"
            label="Trans Fats"
            value={values.micronutrients?.transFats}
            name="micronutrients.transFats"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            disabled={!permission || isDisabled}
            sx={{ width: '100%', zIndex: 0 }}
            error={!!errors.micronutrients?.transFats}
          />
          <TextField
            data-test="sodiumInput"
            type="number"
            label="Sodium"
            onBlur={handleBlur}
            onChange={handleChange}
            name="micronutrients.sodium"
            sx={{ width: '100%', zIndex: 0 }}
            value={values.micronutrients?.sodium}
            error={!!errors.micronutrients?.sodium}
            disabled={!permission || isDisabled}
          />
          <TextField
            data-test="cholesterolInput"
            label="Cholesterol"
            value={values.micronutrients?.cholesterol}
            name="micronutrients.cholesterol"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            disabled={!permission || isDisabled}
            sx={{ width: '100%', zIndex: 0 }}
            error={!!errors.micronutrients?.cholesterol}
          />
          <TextField
            data-test="addedSugarInput"
            type="number"
            onBlur={handleBlur}
            label="Added sugar"
            name="micronutrients.addedSugar"
            onChange={handleChange}
            sx={{ width: '100%', zIndex: 0 }}
            value={values.micronutrients?.addedSugar}
            error={!!errors.micronutrients?.addedSugar}
            disabled={!permission || isDisabled}
          />
          <TextField
            data-test="totalSugarInput"
            type="number"
            onBlur={handleBlur}
            label="Total sugar"
            name="micronutrients.totalSugar"
            onChange={handleChange}
            sx={{ width: '100%', zIndex: 0 }}
            value={values.micronutrients?.totalSugar}
            error={!!errors.micronutrients?.totalSugar}
            disabled={!permission || isDisabled}
          />
        </Stack>
      </InputThemeProvider>
    </Card>
  );
};

export default MacrosMicrosInputCard;
