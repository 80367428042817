import React, { useEffect } from 'react';

import { Brand, Country, Kitchen } from '@calo/types';

import { Button, Modal, ModalRef, Select } from 'components';
import { getAccessibleCountries, getKitchenOptions } from 'lib';
import { useUserKitchens } from 'lib/hooks';

interface AddGiftModalProps {
  setProceed: (v: { country: Country; brand: Brand; kitchen: Kitchen }) => void;
  addGiftModalRef: React.MutableRefObject<ModalRef | undefined>;
  selectedCountryBrand: { country: Country | undefined; brand: Brand; kitchen: Kitchen | undefined };
  setSelectedCountryBrand: (v: { country: Country | undefined; brand: Brand; kitchen: Kitchen | undefined }) => void;
}
const AddGiftModal = ({ addGiftModalRef, selectedCountryBrand, setSelectedCountryBrand, setProceed }: AddGiftModalProps) => {
  const userKitchens: string[] = useUserKitchens();

  useEffect(() => {
    if (selectedCountryBrand.country !== undefined) {
      const kitchen = Object.values(Kitchen).find((k) => k.includes(selectedCountryBrand.country!));
      setSelectedCountryBrand({ ...selectedCountryBrand, kitchen: kitchen });
    }
  }, [selectedCountryBrand.country]);

  return (
    <Modal
      ref={addGiftModalRef}
      onClose={() => {
        addGiftModalRef.current?.close();
        setSelectedCountryBrand({ brand: Brand.CALO, country: undefined, kitchen: undefined });
      }}
    >
      <div className="section -m-10">
        <section className="section is-title-bar -my-8 ">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <p className="uppercase font-roboto text-2xl">Choose Subscribers</p>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="flex flex-row w-10/12 mx-auto">
            <span className="w-1/2 mr-8">
              <Select
                label="Country"
                value={selectedCountryBrand?.country}
                onChange={(country: any) => setSelectedCountryBrand({ ...selectedCountryBrand, country: country.value })}
                options={getAccessibleCountries(userKitchens).map((t) => ({
                  value: t,
                  label: Country[t]
                }))}
              />
            </span>
            <span className="w-1/2 ml-8">
              <Select
                label="Kitchen"
                value={selectedCountryBrand?.kitchen}
                onChange={(e: any) => setSelectedCountryBrand({ ...selectedCountryBrand, kitchen: e.value as Kitchen })}
                options={
                  selectedCountryBrand.country
                    ? getKitchenOptions(userKitchens, selectedCountryBrand.country)
                    : userKitchens.map((kitchen) => ({ value: kitchen, label: kitchen }))
                }
                isDisabled={
                  !selectedCountryBrand.country &&
                  Object.values(Kitchen).filter((r) => r.includes(selectedCountryBrand.country!)).length === 0
                }
              />
            </span>
          </div>
        </section>
        <section className="flex justify-center">
          <Button
            primary
            content="Proceed"
            disabled={!selectedCountryBrand.brand || !selectedCountryBrand.country || !selectedCountryBrand.kitchen}
            onClick={() =>
              setProceed({
                country: selectedCountryBrand.country!,
                brand: selectedCountryBrand.brand!,
                kitchen: selectedCountryBrand.kitchen!
              })
            }
          />
        </section>
      </div>
    </Modal>
  );
};
export default AddGiftModal;
