import { AddToWallet, RefundReq, WalletAction, WalletFilters } from '@calo/dashboard-types';
import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import mutation from './mutation';

export const createWallet = async (payload: AddToWallet & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.post('/wallets', rest, {
    params: {
      subscriptionId: id
    }
  });
  if (payload.action === WalletAction.ADD) {
    ToastSuccessMsgs({ status, action: 'Amount added successfuly' });
  } else if (payload.action === WalletAction.REFUND) {
    ToastSuccessMsgs({ status, action: 'Amount refunded successfuly' });
  } else {
    ToastSuccessMsgs({ status, action: 'Amount removed successfuly' });
  }
  mutation(['wallet', data.id], data);
  return data;
};

export const refund = async (id: string, subId: string, values: RefundReq) => {
  const { data } = await client.post(`/wallets/${id}/refund`, values, {
    params: {
      subscriptionId: subId
    }
  });
  return data;

  // dispatch(setWallet(data.data));
};

export const exportWallet = async (filters: WalletFilters) => {
  const { status } = await client.get('wallets/export', { params: { filters } });
  ToastSuccessMsgs({ status, action: 'exported file sent to your email' });
};

export const syncSubscriptionBalanceWithActual = async (subscriptionId: string) => {
  const { status } = await client.post(`/balance/${subscriptionId}/sync`);
  ToastSuccessMsgs({ status, action: 'synced successfully' });
};
