import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

interface TimePickerProps {
  customDeliveryTime?: Date | null;
  updateCustomDeliveryTime: (time: string) => Promise<void>;
}

export default function BasicTimePicker({ customDeliveryTime, updateCustomDeliveryTime }: TimePickerProps) {
  const [value, setValue] = React.useState<Dayjs | null>(customDeliveryTime ? dayjs(customDeliveryTime) : null);

  const handleNewTime = async (newValue: Dayjs | null) => {
    const time = newValue ? `${newValue?.hour()}:${newValue?.minute()}` : null;
    if (time) {
      await updateCustomDeliveryTime(time);
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileTimePicker
        label={value ? `${value?.hour()}:${value?.minute()} - ${value?.hour() + 1}:${value?.minute()}` : '--:--'}
        value={value}
        onAccept={async (newValue) => {
          await handleNewTime(newValue);
        }}
        onChange={(newValue) => setValue(dayjs(newValue))}
        ignoreInvalidInputs={true}
        minutesStep={10}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
