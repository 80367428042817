import { getRecord } from 'actions';
import cx from 'classnames';
import { Icon } from 'components';
import { useQuery } from 'react-query';

const Stats = () => {
  const { data } = useQuery(['stats', 'invitation-codes'], getRecord);
  const stats = data as { generated: number; claimed: number };

  return (
    <div className="level">
      <div className="level-left">
        <div
          className={cx(' flex border-l-8 border-yellow-500 opacity-100 rounded-2xl mr-8 w-52 h-28  hover:border-yellow-500', {})}
        >
          <div className="card tile rounded-2xl">
            <div className="card-content -mt-4">
              <div className="is-widget-label">
                <Icon className="relative ml-32 mt-1" size={8} name="invitation-envelope" />
                <h3 className="subtitle is-spaced -mt-4">Invitations</h3>
                <p className="text-yellow-500 text-4xl -mt-4">{stats?.generated}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={cx(' flex border-l-8 border-blue-500 opacity-100 rounded-2xl mr-8 w-52 h-28  hover:border-blue-500', {})}>
          <div className="card tile rounded-2xl">
            <div className="card-content -mt-4">
              <div className="is-widget-label">
                <Icon className="relative ml-32 text-blue-500" size={8} name="claimed-envelope" />
                <h3 className="subtitle is-spaced -mt-4">Claimed</h3>
                <p className="text-blue-500 text-4xl -mt-4">{stats?.claimed}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Stats;
