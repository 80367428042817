import { useFormik } from 'formik';
import { hasValidCharactersForLanguage } from 'lib/helpers';
import { IngredientFormData, IngredientType } from 'lib/interfaces';
import { isNumber } from 'lodash';

import { Macros, Micronutrient } from '@calo/types';

export default (ingredient: IngredientType, onSubmit: (values: IngredientFormData) => any) => {
  return useFormik<IngredientFormData>({
    initialValues: {
      name: ingredient.name,
      category: ingredient.category,
      measurementUnit: ingredient.measurementUnit,
      internalName: ingredient.internalName,
      macros: ingredient.macros,
      wastage: ingredient.wastage,
      cost: ingredient.cost,
      country: ingredient.country,
      kitchen: ingredient.kitchen,
      brand: ingredient.brand,
      weight: ingredient.weight || 1,
      micronutrients: {
        addedSugar: ingredient.micronutrients?.addedSugar || 0,
        cholesterol: ingredient.micronutrients?.cholesterol || 0,
        saturatedFats: ingredient.micronutrients?.saturatedFats || 0,
        sodium: ingredient.micronutrients?.sodium || 0,
        totalSugar: ingredient.micronutrients?.totalSugar || 0,
        transFats: ingredient.micronutrients?.transFats || 0
      }
    },
    validate: (values: IngredientFormData) => {
      const errors: any = {};

      if (!values.name?.en) {
        errors.name = {
          ...errors.name,
          en: true
        };
      }

      if (!values.name?.ar) {
        errors.name! = {
          ...errors.name,
          ar: true
        };
      }

      if (!hasValidCharactersForLanguage(values.name?.ar || '', 'AR')) {
        errors.name = {
          ...errors.name,
          ar: true
        };
      }

      if (!hasValidCharactersForLanguage(values.name?.en || '', 'EN')) {
        errors.name = {
          ...errors.name,
          en: true
        };
      }

      for (const key in values.macros) {
        if (!isNumber(values.macros[key as keyof Macros])) {
          errors.macros = {
            ...errors.macros,
            [key]: true
          };
        }
      }

      for (const key in values.micronutrients) {
        if (!isNumber(values.micronutrients[key as Micronutrient])) {
          errors.micronutrients = {
            ...errors.micronutrients,
            [key]: true
          };
        }
      }

      if (!values.wastage || values.wastage === 0) {
        errors.wastage = true;
      }

      if (!isNumber(values.cost!)) {
        errors.cost = true;
      }

      if (!values.measurementUnit) {
        errors.measurementUnit = true;
      }

      if (!values.measurementUnit) {
        errors.measurementUnit = true;
      }

      return errors;
    },
    onSubmit: async (values) => {
      console.log(values);
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
};
