import React, { ReactNode } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

interface ModalHeaderProps {
  headerTitle?: string;
  onClose: () => void;
  children?: ReactNode;
}

const StyledHeader = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 16px 6px 16px',
  borderBottom: '1px solid #e0e0e0',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  position: 'relative',
  zIndex: 20,
  flexShrink: 0,
  boxSizing: 'border-box'
});

const HeaderRow = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px'
});

const ModalHeader = ({ headerTitle, onClose, children }: ModalHeaderProps) => {
  return (
    <StyledHeader>
      <HeaderRow>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {headerTitle}
        </Typography>
        <IconButton onClick={onClose} size="small" sx={{ color: '#212121' }}>
          <CloseIcon />
        </IconButton>
      </HeaderRow>
      {children && children}
    </StyledHeader>
  );
};

export default ModalHeader;
