import { useMemo, useState } from 'react';

import { orderBy } from 'lodash-es';
import { useInfiniteQuery } from 'react-query';

import { LogType } from '@calo/dashboard-types';
import { Subscription } from '@calo/types';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Card, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow, Typography } from '@mui/material';
import { Stack, styled } from '@mui/system';

import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { useDocumentMedia } from 'hooks';
import client from 'lib/client';
import { Log } from 'lib/interfaces';
import LogsCardRow from './LogsCardRow';
import LogsCardRowMobile from './LogsCardRowMobile';

interface LogsCardProps {
  subscription: Subscription & { remainingDays: number; expectedLastDeliveryDay: string; ratings: any[] };
}
const LogsCard = ({ subscription }: LogsCardProps) => {
  const { isMobile, isTablet } = useDocumentMedia();
  const [isSwapMealTableOpen, setIsSwapMealTableOpen] = useState<boolean>(false);

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<{ data: Log[]; meta: { cursor?: string } }>(
    `subscriptions/${subscription.id}/logs`,
    async ({ pageParam, queryKey }) => {
      const { data } = await client.get(queryKey.join('/'), {
        params: {
          ...(pageParam && {
            cursor: pageParam
          })
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => {
        return data.meta.cursor;
        // !!data.meta.cursor,
      }
    }
  );

  const list = useMemo(
    () =>
      (data?.pages || []).reduce<Log[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [data]
  );

  const filteredLogs = useMemo(
    () => orderBy(list, ['createdAt'], ['desc']).filter((logs) => logs.type !== LogType.DELIVERY_FOOD_REPLACED),
    [list]
  );
  const swapMealLogs = useMemo(
    () => orderBy(list, ['createdAt'], ['desc']).filter((logs) => logs.type === LogType.DELIVERY_FOOD_REPLACED),
    [list]
  );

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      flexShrink: 0,
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px',
      variant: 'caption',
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    },
    [`&.${tableCellClasses.body}`]: {
      flexShrink: 0,
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      variant: 'caption',
      lineHeight: '14px',
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    }
  }));

  return (
    <>
      <Card variant="outlined" sx={{ width: '100%', border: 'none', borderRadius: '8px' }}>
        <Box
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column'
            }
          }}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '19px',
              lineHeight: '23px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600
            }}
          >
            Logs
          </Typography>
        </Box>
        <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
          {isTablet || isMobile ? (
            <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
              {isLoading ? (
                <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                  <CaloLoader />
                </Stack>
              ) : (
                <>
                  {list.map((row) => (
                    <LogsCardRowMobile key={row.id} row={row} />
                  ))}
                </>
              )}
            </Box>
          ) : (
            <>
              {isLoading ? (
                <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                  <CaloLoader />
                </Stack>
              ) : (
                <>
                  {filteredLogs.length === 0 && swapMealLogs.length === 0 ? (
                    <Typography
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{
                        justifyContent: 'center',
                        color: caloTheme.palette.neutral400,
                        fontSize: '33px',
                        fontWeight: 400,
                        lineHeight: '24px'
                      }}
                    >
                      No Logs
                    </Typography>
                  ) : (
                    <Table
                      sx={{
                        marginY: '4px',
                        minHeight: '120px',
                        tableLayout: 'fixed',
                        overflow: 'auto',
                        width: '100%',
                        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                          flexDirection: 'column'
                        }
                      }}
                    >
                      <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, width: '100%', borderRadius: '8px' }}>
                        <TableRow>
                          <StyledTableCell sx={{ width: '70%' }}>Actions</StyledTableCell>
                          <StyledTableCell sx={{ width: '15%' }}>Actor</StyledTableCell>
                          <StyledTableCell sx={{ width: '15%' }}>Time</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredLogs.map((row) => (
                          <LogsCardRow key={row.id} row={row} />
                        ))}
                      </TableBody>
                    </Table>
                  )}
                  {swapMealLogs.length > 0 && (
                    <Table
                      sx={{
                        marginY: '4px',
                        marginTop: filteredLogs.length === 0 ? '-32px' : '',
                        tableLayout: 'fixed',
                        overflow: 'auto',
                        width: '100%',
                        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                          flexDirection: 'column'
                        }
                      }}
                    >
                      <TableHead
                        sx={{ backgroundColor: caloTheme.palette.secondaryPurple50, width: '100%', borderRadius: '8px' }}
                      >
                        <TableRow onClick={() => setIsSwapMealTableOpen(!isSwapMealTableOpen)}>
                          <StyledTableCell sx={{ width: '70%' }}>Swap Meal</StyledTableCell>
                          <StyledTableCell sx={{ width: '15%' }}></StyledTableCell>
                          <StyledTableCell sx={{ width: '15%' }}>
                            <Typography sx={{ textAlign: 'end' }}>
                              {isSwapMealTableOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
                            </Typography>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ display: isSwapMealTableOpen ? undefined : 'none' }}>
                        {swapMealLogs.map((row) => (
                          <LogsCardRow key={row.id} row={row} />
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </>
              )}
            </>
          )}
        </Box>

        <Box
          sx={{
            width: 'full',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              display: 'flex',
              justifyItems: 'center',
              m: 'auto',
              mb: 2,
              mt: '-4px',
              width: 'auto'
            }
          }}
        >
          {hasNextPage && (
            <Button
              variant="text"
              aria-label="Remove Suspension"
              sx={{
                display: 'flex',
                mx: 'auto',
                height: '45px',
                fontWeight: 600,
                lineHeight: '17px',
                fontSize: '14px',
                borderRadius: '8px',
                padding: '14px 20px 14px 20px',
                color: caloTheme.palette.primary500,
                '&:hover': {
                  color: caloTheme.palette.primary600
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  display: 'flex',
                  justifyItems: 'center',
                  m: 'auto',
                  mb: 2,
                  mt: '-4px',
                  width: 'auto'
                }
              }}
              onClick={() => fetchNextPage()}
            >
              Show More
            </Button>
          )}
        </Box>
      </Card>
    </>
  );
};
export default LogsCard;
