import { useEffect, useState } from 'react';

import ComponentCostInfo from 'components/ComponentCostInfo';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { FormikErrors } from 'formik';
import { useUserRoles } from 'lib/hooks';
import { BaseOmit, FoodComponent, FoodComponentWithQuantity } from 'lib/interfaces';
import { CostInfo, showCostInfoHandler } from 'views/Food/helpers';

import { FoodComponentStation, FoodComponentStatus, MeasurementUnit, Permission } from '@calo/dashboard-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Card, IconButton, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';

interface ComponentInformationProps {
  prototype?: boolean;
  values: Omit<FoodComponent, BaseOmit>;
  errors: FormikErrors<Omit<FoodComponent, BaseOmit>>;
  childComponents: FoodComponent[];
  isDisabled?: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
  handleChange: { (e: React.ChangeEvent<any>): void };
  handleChangeForNumRestrictions: (event: React.ChangeEvent<any>) => void;
}

const ComponentInformationCard = ({
  values,
  errors,
  prototype,
  childComponents,
  isDisabled,
  setFieldValue,
  handleChange,
  handleBlur,
  handleChangeForNumRestrictions
}: ComponentInformationProps) => {
  const roles = useUserRoles();

  const [showCostInfo, setShowCostInfo] = useState(false);
  const [costType, setCostType] = useState(CostInfo.none);

  useEffect(() => {
    if (values.measurementUnit === MeasurementUnit.g) {
      setFieldValue('weight', 1);
    }
  }, [values.measurementUnit]);

  const costInfoText = () => {
    const childCompsWithQuantity: FoodComponentWithQuantity[] = childComponents.map((child) => ({
      ...child,
      quantity: values.childComponents?.find((comp) => comp.id === child.id)?.quantity ?? 0
    }));
    const info = showCostInfoHandler(
      costType,
      [...values.ingredients],
      childCompsWithQuantity,
      values.cookedRawFactor,
      values.weight ?? 1
    );
    return info;
  };

  const permission = prototype
    ? roles.includes(Permission.CREATE_PROTOTYPE_COMPONENT)
    : roles.includes(Permission.CREATE_FOOD_COMPONENTS);

  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: '16px',
        border: 'none',
        borderRadius: '16px',
        px: '18px',
        py: '18px'
      }}
    >
      <Typography sx={{ fontSize: '19px', fontWeight: 600, marginBottom: '10px' }}>Additional Information</Typography>
      <InputThemeProvider>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: 2
            }}
          >
            <TextField
              data-test="costPerUnitInput"
              label="Cost per unit (Recipe Sheet)*"
              value={values.cost}
              name="cost"
              onChange={handleChangeForNumRestrictions}
              onBlur={handleBlur}
              type="number"
              disabled={!permission || isDisabled}
              error={!!errors.cost}
              sx={{ my: 1, zIndex: 0, width: '100%' }}
              inputProps={{ inputProps: { style: { borderRadius: 8, min: 0 } }, style: { borderRadius: 8 } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowCostInfo(true);
                        setCostType(CostInfo.cost);
                      }}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              data-test="costPerUnitPrecoroInput"
              label="Cost per unit (Precoro)"
              value={values.purchasingCost}
              name="purchasingCost"
              onChange={handleChange}
              onBlur={handleBlur}
              type="number"
              disabled
              error={!!errors.purchasingCost}
              sx={{ my: 1, width: '100%' }}
              inputProps={{ inputProps: { style: { borderRadius: 8, min: 0 } }, style: { borderRadius: 8 } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowCostInfo(true);
                        setCostType(CostInfo.purchasingCost);
                      }}
                      disabled={!values.purchasingCost}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              data-test="cookedWeightInput"
              label="Cooked Weight (g)"
              value={values.weight}
              name="weight"
              onChange={handleChange}
              onBlur={handleBlur}
              type="number"
              disabled={!permission || isDisabled || values.measurementUnit === MeasurementUnit.g}
              sx={{ my: 1, width: '100%' }}
              inputProps={{ inputProps: { style: { borderRadius: 8, min: 0 } }, style: { borderRadius: 8 } }}
            />
            <TextField
              data-test="cookedRawFactorInput"
              label="Cooked > Raw Factor"
              value={values.cookedRawFactor}
              name="cookedRawFactor"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!permission || isDisabled}
              sx={{ my: 1, width: '100%' }}
              inputProps={{ inputProps: { style: { borderRadius: 8, min: 0 } }, style: { borderRadius: 8 } }}
            />
          </Stack>
          {showCostInfo && <ComponentCostInfo costInfo={costInfoText()} handleClose={() => setShowCostInfo(false)} />}
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2
            }}
          >
            <TextField
              data-test="measurementUnitSelector"
              select
              label="Measurement Unit"
              value={values.measurementUnit}
              placeholder="Select Unit"
              sx={{ my: 1, width: '100%' }}
              disabled={!permission || isDisabled}
              onChange={(data: any) => setFieldValue('measurementUnit', data.target.value)}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            >
              {Object.values(MeasurementUnit).map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              data-test="stationSelector"
              select
              label="Station"
              value={values.cookingStation}
              SelectProps={{
                renderValue: (selected) => {
                  return (selected as string[]).map((station) => FoodComponentStation[station]).join(', ');
                },
                multiple: true,
                value: values.cookingStation,
                onChange: (e) => setFieldValue('cookingStation', e.target.value)
              }}
              sx={{ my: 1, width: '100%' }}
              disabled={!permission || isDisabled}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            >
              {Object.entries(FoodComponentStation).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              data-test="statusSelector"
              select
              name="status"
              label="Recipe Status"
              value={values.status}
              sx={{ my: 1, width: '100%' }}
              disabled={!permission || isDisabled}
              onChange={(data: any) => setFieldValue('status', data.target.value)}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            >
              {Object.entries(FoodComponentStatus).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Stack>
      </InputThemeProvider>
    </Card>
  );
};

export default ComponentInformationCard;
