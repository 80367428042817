import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, Stack } from '@mui/material';
import { resolveSize } from 'lib/helpers';
import { useRef } from 'react';
import Chips from '../Chips';
import { ModalRef } from '../Modal';
import Popup from '../Popup';
import styles from './styles';

interface SpecialRequestChipsRendererProps {
  items: any[];
  onDelete?: (item: any) => void;
  showType?: 'short' | 'full';
  styleOverrides?: any;
}
const SpecialRequestChipsRenderer = ({
  items,
  onDelete,
  showType = 'full',
  styleOverrides = {}
}: SpecialRequestChipsRendererProps) => {
  const moreRequestRef = useRef<ModalRef>();

  const renderChips = (showAll: boolean) => {
    const maxChipsToShow = showType === 'short' && !showAll ? 2 : Infinity;
    return items?.slice(0, maxChipsToShow).map((item) => {
      const keyProp = item.item ? item.item.id : item.id || item.type;
      const label = item.item ? `${item.item?.name?.en} - ${resolveSize(item.item.size)} (${item.amount})` : item.type;

      const onDeleteHandler = onDelete ? () => onDelete(item) : undefined;

      return (
        <Grid item key={keyProp}>
          <Chips
            keyProp={keyProp}
            label={label}
            more={item.more || false}
            onDelete={onDeleteHandler}
            styleOverrides={{ ...styles.chips }}
            onClick={item.more ? () => item.onClick?.() : undefined}
          />
        </Grid>
      );
    });
  };

  return (
    <>
      <Grid container width={'100%'} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
        {renderChips(false)}
        {showType === 'short' && items.length > 2 && (
          <Grid item xs={4} sx={{ width: '100%' }}>
            <Chips
              keyProp="more"
              label={`${items.length - 2} more`}
              more={true}
              onClick={() => moreRequestRef.current?.open()}
              styleOverrides={{ ...styleOverrides, cursor: 'pointer' }}
            />
          </Grid>
        )}
      </Grid>

      <Popup
        fullWidth
        title="Request Type"
        ref={moreRequestRef}
        onClose={() => moreRequestRef.current?.close()}
        info={
          <>
            <CloseIcon width={24} height={24} onClick={() => moreRequestRef.current?.close()} sx={{ cursor: 'pointer' }} />
          </>
        }
      >
        <Box>
          <Stack flexDirection={'row'} sx={{ flexWrap: 'wrap', mb: 1 }}>
            {renderChips(true)}
          </Stack>
          <Stack>
            <Button variant="contained" sx={{ ...styles.confirmButton }} onClick={() => moreRequestRef.current?.close()}>
              Confirm
            </Button>
          </Stack>
        </Box>
      </Popup>
    </>
  );
};

export default SpecialRequestChipsRenderer;
