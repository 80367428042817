import { Typography } from '@mui/material';

export const getDatesBetween = (startDate: string, endDate: string) => {
  const dates: string[] = [];
  const currentDate = new Date(startDate);
  const end = new Date(endDate);

  while (currentDate <= end) {
    dates.push(currentDate.toISOString().split('T')[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates.reverse();
};

export const renderTypography = (value: number | undefined) => (
  <Typography
    sx={{
      fontSize: '22px',
      fontWeight: 600,
      lineHeight: '24px',
      mt: 1
    }}
  >
    {value === undefined ? '0' : value}
  </Typography>
);
