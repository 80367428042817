import React, { useState } from 'react';

import { parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import DatePicker from 'react-date-picker';

import { Button, Modal, ModalRef } from 'components';

interface AddLastDeliveryDateModalProps {
  selectedDate: string | undefined;
  setSelectedDate: (v: string | undefined) => void;
  addLastDeliveryDateModalRef: React.MutableRefObject<ModalRef | undefined>;
}

const AddDateModal = ({ addLastDeliveryDateModalRef, selectedDate, setSelectedDate }: AddLastDeliveryDateModalProps) => {
  const [chosenDate, setChosenDate] = useState<string>('');

  const handleSetDate = () => {
    setSelectedDate(chosenDate);
    addLastDeliveryDateModalRef.current?.close();
  };

  return (
    <Modal
      ref={addLastDeliveryDateModalRef}
      onClose={() => {
        addLastDeliveryDateModalRef.current?.close();
        setSelectedDate(selectedDate);
      }}
    >
      <div className="section -m-10">
        <section className="section is-title-bar -my-8 ">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <p className="uppercase font-roboto text-2xl">Choose Date</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div>
            <DatePicker
              className="flex w-64 mx-auto my-8"
              onChange={(date: any) => setChosenDate(format('yyyy-MM-dd')(date))}
              value={chosenDate ? parseISO(chosenDate) : undefined}
              clearIcon={null}
            />
          </div>
        </section>
        <section className="flex justify-center">
          <Button primary content="Set Date" disabled={!chosenDate} onClick={() => handleSetDate()} />
        </section>
      </div>
    </Modal>
  );
};
export default AddDateModal;
