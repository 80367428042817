import { useCallback, useRef, useState } from 'react';

import { getImageUploadLink, uploadImage } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components/Modal';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { Stack } from '@mui/material';

import ImagePopup from '../ImageUploaderMUI/ImagePopup';
import ImagesCardPreview from './ImagesCardPreview';
import ImageUploadCard from './ImageUploadCard';
import ImageUploadProgress from './ImageUploadProgress';

interface ImageDropZoneProps {
  initialURL: string;
  uploadLink: () => Promise<{ url: string; fields: Record<string, string | Blob> }>;
  disabled: boolean;
  imageURLs: string[];
  setImageURLs: (files: string[]) => void;
}

const ImageDropZone = ({ initialURL, uploadLink, imageURLs, setImageURLs }: ImageDropZoneProps) => {
  const [displayImage, setDisplayImage] = useState<string | null>(null);
  const [progress, setProgress] = useState(-1);

  const viewImageRef = useRef<ModalRef>();

  const onDrop = useCallback(
    async (files) => {
      if (!files) {
        return;
      }

      const validFiles = files.filter((file) => file.size <= 62914560 && file.type.includes('image'));
      if (validFiles.length === 0) {
        toast('Please upload valid images (size <= 60MB)', { type: 'error', autoClose: 2000 });
        return;
      }

      const uploadedImageURLs: string[] = [];

      for (const file of validFiles) {
        const imageUUID = uuid();
        const { url, fields } = await getImageUploadLink(`${initialURL}/${imageUUID}`);
        const formData = new FormData();

        for (const key of Object.keys(fields)) {
          formData.append(key, fields[key]);
        }

        formData.append('file', file);

        setDisplayImage(URL.createObjectURL(file));

        const imageUpload = await uploadImage(url, formData, {
          onUploadProgress: (progressEvent) =>
            setProgress(Math.min(100, Math.round((progressEvent.loaded * 100) / progressEvent.total)))
        });

        if (imageUpload) {
          const imageURL = `${process.env.REACT_APP_BUCKET_URL}/${initialURL}/${imageUUID}/original`;
          uploadedImageURLs.push(imageURL);
          setProgress(-1);
          setImageURLs([...imageURLs, ...uploadedImageURLs]);
        }
      }
    },
    [uploadLink, imageURLs, initialURL]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop
  });

  const handleRemoveImage = (image: string) => {
    setImageURLs(imageURLs.filter((url) => url !== image));
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        borderRadius: '8px',
        backgroundColor: caloTheme.palette.neutral50,
        width: '100%',
        height: '250px',
        padding: '10px 0',
        textAlign: 'center'
      }}
    >
      {imageURLs.length > 0 || progress > 0 ? (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          width="95%"
          height="100%"
          sx={{ overflowX: 'auto', overflowY: 'hidden', scrollbarWidth: 'thin' }}
        >
          <ImageUploadCard progress={progress} getInputProps={getInputProps} getRootProps={getRootProps} />
          <ImagesCardPreview
            imageURLs={imageURLs}
            handleRemoveFile={handleRemoveImage}
            setDisplayImage={setDisplayImage}
            viewImageRef={viewImageRef}
          />
          <ImageUploadProgress progress={progress} />
        </Stack>
      ) : (
        <ImageUploadCard progress={progress} getInputProps={getInputProps} getRootProps={getRootProps} fullWidth={true} />
      )}
      <ImagePopup imageUrl={displayImage ?? ''} mainRef={viewImageRef} canEdit={false} />
    </Stack>
  );
};

export default ImageDropZone;
