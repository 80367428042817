import { CaloLoader } from 'components';
import { useQuery } from 'react-query';

import { SubscriptionDayProgress } from '@calo/dashboard-types';
import { Box } from '@mui/material';

import { getRecord } from '../../../../../actions';
import styles from './styles';
import TrackedMacros from './TrackedMacros';
import TrackersForm from './TrackersForm';

interface TrackedProgressProps {
  subscriptionId: string;
  date: string;
}

const TrackedProgress = ({ subscriptionId, date }: TrackedProgressProps) => {
  const { data, isLoading } = useQuery<any, Error, SubscriptionDayProgress>(
    [`/progress/${subscriptionId}/date/${date}`],
    getRecord,
    {
      suspense: false,
      retry: false,
      onError: console.error
    }
  );

  const { trackedMacros, trackers } = data
    ? (data as SubscriptionDayProgress)
    : {
        trackedMacros: {
          cal: 0,
          carbs: 0,
          protein: 0,
          fat: 0
        },
        trackers: {
          water: 0,
          weight: 0,
          steps: 0
        }
      };

  return isLoading ? (
    <CaloLoader />
  ) : (
    <Box sx={{ ...styles.mainBox, paddingTop: '0px' }} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
      <TrackedMacros macros={trackedMacros} />
      <TrackersForm subscriptionId={subscriptionId} date={date} trackers={trackers} />
    </Box>
  );
};

export default TrackedProgress;
