import { Stack, Typography } from '@mui/material';
import styles from './styles';

interface DetailItemProps {
  title: string;
  children: React.ReactNode;
}

const DetailItem = ({ title, children }: DetailItemProps) => (
  <Stack width="33%" display={'flex'} flexDirection={'column'}>
    <Typography variant="caption" sx={{ ...styles.titleText }}>
      {title}
    </Typography>
    <Typography variant="h6" sx={{ textAlign: 'left', ...styles.text }}>
      {children}
    </Typography>
  </Stack>
);

export default DetailItem;
