import { RetailBranch } from '@calo/types';
import { toggleUISettings } from 'actions';
import { Select2, SideDrawer } from 'components';
import { AppState } from 'lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { RetailFilter } from '../../types';

interface SettingsProps {
  onFilter: (values: RetailFilter) => any;
  filters: RetailFilter;
}

const retailBranchData = [
  { label: RetailBranch.SEEF, value: RetailBranch.SEEF },
  { label: RetailBranch.BOXAT, value: RetailBranch.BOXAT },
  { label: RetailBranch.HITTEN, value: RetailBranch.HITTEN },
  { label: RetailBranch.ROSHAN, value: RetailBranch.ROSHAN },
  { label: RetailBranch.SEDRA, value: RetailBranch.SEDRA }
];

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Branch"
          value={filters.branch}
          onChange={(data) => onFilter({ ...filters, branch: data.target.value as RetailBranch })}
          options={retailBranchData}
        />
      </section>
    </SideDrawer>
  );
};

export default Settings;
