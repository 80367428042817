import { FoodFilters, Permission, SortingFood } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { getListWithParams } from 'actions/index';
import { caloTheme } from 'assets/images/theme/calo';
import CaloLoader from 'components/CaloLoader';
import { ModalRef } from 'components/Modal';
import { Routes } from 'lib/enums';
import { resolveCountryFromKitchen } from 'lib/helpers';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { Food } from 'lib/interfaces';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import FoodCommentsPopup from '../../Shared/FoodCommentsPopup';
import FoodRow from './FoodRow';
import Settings from './Settings';

interface PaginatedFoodList {
  data: Food[];
  meta: {
    limit: number;
    total: number;
  };
}

const FoodList = () => {
  const history = useHistory();
  const location = useLocation();
  const userKitchen = useUserKitchens();
  const commentsPopupRef = useRef<ModalRef>();
  const searchParams = new URLSearchParams(location.search);

  const roles = useUserRoles();
  const [page, setPage] = useState(0);

  const sort: SortingFood = {
    orderBy: 'name',
    orderMode: 'asc'
  };

  const [selectedFood, setSelectedFood] = useState<Food | undefined>();

  const [foodList, setFoodList] = useState<Food[]>([]);

  const [filters, setFilters] = useState<FoodFilters>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    isDeleted: false,
    status: '',
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data, isLoading } = useQuery<any, Error, PaginatedFoodList>(
    [
      'food/prototype',
      {
        filters: {
          brand: filters.brand,
          country: filters.country,
          kitchen: filters.kitchen,
          foodType: filters.foodType,
          dietType: filters.dietType,
          name: filters.name,
          componentId: filters.componentId ? filters.componentId : undefined,
          ingredientId: filters.ingredientId ? filters.ingredientId : undefined,
          foodTags: filters.foodTags,
          sizes: filters.sizes,
          status: filters.status,
          isDeleted: false,
          prototype: true
        },
        sort,
        page,
        limit: 50
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: roles.includes(Permission.VIEW_PROTOTYPE_FOOD_LIST),
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  useEffect(() => {
    if (!data?.data) {
      return;
    }

    const list = data.data.filter((f) => {
      let res = true;
      if (filters.dietType) {
        res = res && (f.tags || []).includes(filters.dietType);
      }
      if (filters.foodType) {
        res = res && f.type.includes(Array.isArray(filters.foodType) ? filters.foodType[0] : filters.foodType);
      }

      return res;
    });

    setFoodList(list);
  }, [data]);

  useEffect(() => {
    setPage(0);
  }, [filters]);

  const closeCommentsPopup = () => {
    commentsPopupRef.current?.close();
  };

  const commentClickHandler = (food: Food) => {
    setSelectedFood(food);
    commentsPopupRef.current?.open();
  };

  const addCommentSuccessHandler = (data: Food) => {
    setSelectedFood(data);
    setFoodList((prev) => {
      const foodIndex = prev.findIndex((food) => food.id === data.id);
      return [...prev.slice(0, foodIndex), data, ...prev.slice(foodIndex + 1)];
    });
  };

  return (
    <>
      <Stack sx={{ mb: 2, flexDirection: 'row', backgroundColor: 'white', borderRadius: '16px', px: '12px', py: '16px' }}>
        <Typography
          variant="h3"
          sx={{
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column',
              textAlign: 'center'
            },
            textAlign: 'left',
            fontSize: '24px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Chef's Playground
        </Typography>
      </Stack>
      {isLoading ? (
        <CaloLoader />
      ) : (
        <>
          <Stack sx={{ backgroundColor: 'white', borderRadius: '16px', px: '12px', py: '16px' }}>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>Meals</Typography>
              <Button
                onClick={() => history.push(Routes.playgroundNewFood)}
                disabled={!roles.includes(Permission.CREATE_PROTOTYPE_FOOD)}
                startIcon={<AddIcon fontSize="small" />}
                sx={{
                  textTransform: 'none',
                  color: caloTheme.palette.neutral900,
                  fontSize: '16px',
                  ':hover': {
                    backgroundColor: 'transparent'
                  }
                }}
              >
                Add
              </Button>
            </Stack>
            <Paper sx={{ width: '100%', mt: 1, boxShadow: 'none' }}>
              <TableContainer>
                <Table sx={{ width: '100%' }}>
                  <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, border: 0 }}>
                    <TableRow>
                      <TableCell
                        sx={{
                          borderRadius: '8px 0 0 8px',
                          border: 0,
                          fontWeight: 600,
                          lineHeight: '14px',
                          fontSize: '12px',
                          minWidth: '100px',
                          width: '20%'
                        }}
                      >
                        Meal Name
                      </TableCell>
                      <TableCell
                        sx={{
                          border: 0,
                          fontWeight: 600,
                          lineHeight: '14px',
                          fontSize: '12px',
                          minWidth: '80px',
                          width: '15%'
                        }}
                      >
                        Chef Name
                      </TableCell>
                      <TableCell
                        sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '60px', width: '10%' }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        sx={{
                          border: 0,
                          fontWeight: 600,
                          lineHeight: '14px',
                          fontSize: '12px',
                          minWidth: '120px',
                          width: '45%'
                        }}
                      >
                        Comments
                      </TableCell>
                      <TableCell
                        sx={{
                          border: 0,
                          fontWeight: 600,
                          lineHeight: '14px',
                          fontSize: '12px',
                          minWidth: '50px',
                          width: '10%',
                          borderRadius: '0 8px 8px 0'
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {foodList.map((food) => {
                      return <FoodRow key={food.id} food={food} commentClickHandler={commentClickHandler} />;
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Stack>
          <FoodCommentsPopup
            selectedFood={selectedFood}
            ref={commentsPopupRef}
            successHandler={addCommentSuccessHandler}
            closeCommentsPopup={closeCommentsPopup}
          />
        </>
      )}
      <Settings onFilter={setFilters} filters={filters} />
    </>
  );
};

export default FoodList;
