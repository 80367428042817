import { Permission, SupplyCapZoneFilters } from '@calo/dashboard-types';
import { Country, Kitchen } from '@calo/types';
import { Box, Button, Stack, Typography } from '@mui/material';
import { toggleUISettings } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import Icon from 'components/Icon';
import { Routes } from 'lib/enums';
import { getAccessibleCountries, getCountryFromLocalStorage, getKitchenOptions } from 'lib/helpers';
import history from 'lib/history';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import KitchenMapView from './KitchenMapView/KitchenMapView';
import Settings from './Settings';
import styles from './styles';

const SupplyCapZones = () => {
  const userKitchens: Kitchen[] = useUserKitchens();
  const roles = useUserRoles();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState<SupplyCapZoneFilters>({
    country: (getCountryFromLocalStorage() || getAccessibleCountries(userKitchens)[0]) as Country,
    kitchen: userKitchens && (getKitchenOptions(userKitchens, getAccessibleCountries(userKitchens)[0])[0].value as Kitchen)
  });

  useEffect(() => {
    if (filters.country) {
      history.push(Routes.supplyCapZones.replace(':country', filters.country));
    }
    if (filters.kitchen) {
      history.push(Routes.supplyCapZones.replace(':country', filters.country).replace(':kitchen', filters.kitchen));
    }
  }, [filters.country, filters.kitchen]);

  const requestFilters = useMemo(
    () => ({
      ...filters
    }),
    [filters]
  );

  return (
    <>
      <div className="bg-white -mt-8">
        <Box sx={styles.mainBox}>
          <Stack flexDirection={'row'} sx={{ paddingY: 2, my: 'auto' }}>
            <Typography sx={styles.titleText}>Supply Cap Zones</Typography>
            <Typography sx={styles.countryKitchenText}>
              {[requestFilters.country, requestFilters.kitchen].filter(Boolean).join('/')}
            </Typography>
          </Stack>
          <Stack sx={styles.mainButtonStack}>
            <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
              <Button
                aria-label="filter-subscription-list"
                sx={{
                  cursor: 'pointer',
                  my: 'auto',
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    justifyItems: 'end',
                    width: '50%'
                  }
                }}
                onClick={() => dispatch(toggleUISettings())}
              >
                {<Icon name="filter" size={6} className="w-12 h-18" />}
              </Button>
            </Stack>
          </Stack>
        </Box>
        <Settings filters={filters} onFilter={setFilters} />
      </div>
      {roles.includes(Permission.VIEW_SUPPLY_CAP_ZONES) && (
        <div className="flex flex-1 mt-0">
          <KitchenMapView country={requestFilters.country as Country} kitchen={filters.kitchen as Kitchen} />
        </div>
      )}
    </>
  );
};

export default SupplyCapZones;
