export const foodListSelection = {
  meta: {
    limit: true,
    total: true
  },
  data: {
    averageRating: true,
    numberOfRatings: true,
    totalRating: true,
    periodicAggregatedRatings: {
      periodInDays: true,
      total: true,
      count: true,
      average: true
    },
    country: true,
    kitchen: true,
    description: {
      en: true
    },
    id: true,
    name: {
      en: true
    },
    size: true,
    slug: true,
    tags: true,
    type: true,
    metadata: true
  }
};

// const value =
// /* GraphQL */ `
//   query GET_FOOD_LIST_DETAILS($query: GetFoodReqInput) {
//     listFoodDetails(query: $query) {
//       meta {
//         limit
//         total
//       }
//       data {
//         averageRating
//         numberOfRatings
//         totalRating
//         periodicAggregatedRatings {
//           periodInDays
//           total
//           count
//           average
//         }
//         country
//         kitchen
//         description {
//           en
//         }
//         id
//         name {
//           en
//         }
//         size
//         slug
//         tags
//         type
//         metadata
//       }
//     }
//   }
// `;
