import { format, parseISO } from 'date-fns/fp';
import { startCase } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import { ListComplaintsFilters } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { toggleUISettings } from 'actions';
import { Select2, SideDrawer } from 'components';
import { ComplaintReason } from 'lib/enums';
import { complaintsReasonsHelper } from 'lib/helpers/complaintsReasons';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';

interface SettingsProps {
  onFilter: (values: ListComplaintsFilters) => void;
  filters: ListComplaintsFilters;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));
  const dispatch = useDispatch();
  const userKitchens: string[] = useUserKitchens();
  const subComplaintOptions = complaintsReasonsHelper.getSelectableOptions();
  const complaintOptions = Object.entries(ComplaintReason)
    .filter(([_, value]) => value in subComplaintOptions)
    .map(([key, value]) => ({
      value: key,
      label: startCase(value)
    }));

  const categorySelectOptions = [{ label: 'Any', value: '' }, ...complaintOptions];

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(e: any) => onFilter({ ...filters, kitchen: e.target.value as Kitchen })}
          options={userKitchens.map((kitchen) => ({
            value: kitchen,
            label: kitchen
          }))}
        />
        <Select2
          label="Category"
          value={filters.category}
          style={{ marginBottom: 4 }}
          onChange={(data) =>
            onFilter({
              ...filters,
              category: data.target.value === 'Any' ? undefined : (data.target.value as string),
              type: '' as string
            })
          }
          options={categorySelectOptions}
        />
        <Select2
          label="Complaint"
          value={filters.type}
          style={{ marginBottom: 4 }}
          disabled={!filters.category}
          onChange={(data) =>
            onFilter({ ...filters, type: data.target.value === 'Any' ? undefined : (data.target.value as string) })
          }
          options={[
            {
              value: '',
              label: 'Any'
            },
            // @ts-ignore
            ...(filters.category ? subComplaintOptions[ComplaintReason[filters.category]] : [{ label: 'Any', value: '' }])?.map(
              (category: any) => ({
                value: category.value,
                label: startCase(category.label)
              })
            )
          ]}
        />
        <span>
          <label className="label">Complaints Raised Date</label>
          <DateRangePicker
            clearIcon={null}
            calendarType={'US'}
            onChange={(dates: any) =>
              onFilter({
                ...filters,
                date: {
                  gte: format('yyyy-MM-dd')(dates[0]),
                  lte: format('yyyy-MM-dd')(dates[1])
                }
              })
            }
            value={[
              filters?.date?.gte ? parseISO(filters?.date?.gte) : undefined,
              filters?.date?.lte ? parseISO(filters?.date?.lte) : undefined
            ]}
          />
        </span>
      </section>
    </SideDrawer>
  );
};

export default Settings;
