import { getList } from 'actions';
import { KDSUserGroup } from 'lib/interfaces';
import { useQuery } from 'react-query';

const useKDSPermissions = () => {
  const { data } = useQuery<any, Error, { data: any }>('kds-user-permissions', getList, {
    suspense: false
  });
  const kdsUserGroup = (data?.data ?? []) as KDSUserGroup[];

  return kdsUserGroup;
};

export default useKDSPermissions;
