import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Route, Switch } from 'react-router-dom';
import MaterialResourcePlanningReport from './MaterialResourcePlanningReport';
import MovingCostReport from './MovingCostReport';
import SupplyChainReportsList from './SupplyChainReportsList';

const SupplyChainReports = () => {
  const roles = useUserRoles();

  return (
    <Switch>
      <Route key={Routes.supplyChainReportsList} exact path={Routes.supplyChainReportsList} component={SupplyChainReportsList} />
      {roles.includes(Permission.VIEW_MATERIAL_RESOURCE_PLANING_REPORT) && (
        <Route
          key={Routes.supplyChainMRPReport}
          exact
          path={Routes.supplyChainMRPReport}
          component={MaterialResourcePlanningReport}
        />
      )}
      {roles.includes(Permission.VIEW_MOVING_COST_REPORT) && (
        <Route
          key={Routes.supplyChainMovingCostReport}
          exact
          path={Routes.supplyChainMovingCostReport}
          component={MovingCostReport}
        />
      )}
    </Switch>
  );
};

export default SupplyChainReports;
