import { isEqual, set } from 'date-fns';

export function disableDates(...dates: Date[]) {
  const timing = { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 };
  dates = dates.map((date) => set(date, timing));

  return (date: Date) => {
    const matchDate = set(date, timing);

    return dates.some((date) => isEqual(matchDate, date));
  };
}
