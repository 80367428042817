import { DeliveryFilters, Permission } from '@calo/dashboard-types';
import { Currency } from '@calo/types';
import { getListWithParams } from 'actions';
import { useUserRoles } from 'lib/hooks';
import { useQuery } from 'react-query';

interface DeliveryStatsProps {
  filters: DeliveryFilters;
  total: number;
}

const DeliveryStats = ({ filters, total }: DeliveryStatsProps) => {
  const roles = useUserRoles();
  const { data } = useQuery<any, Error, any>(
    [
      'stats/deliveries',
      {
        filters
      }
    ],
    getListWithParams,
    {
      enabled: roles.includes(Permission.VIEW_DELIVERY_STATS)
    }
  );
  const stats = data as { count: number; cost: [{ currency: Currency; total: number }] };

  return (
    <div className="level my-4">
      <div className="level-left">
        {roles.includes(Permission.VIEW_DELIVERY_LIST) && (
          <div className="flex opacity-80 rounded-2xl mr-8 w-48 h-28 border-l-8  border-yellow-500">
            <div className="card tile rounded-2xl">
              <div className="card-content -mt-4">
                <div className="is-widget-label">
                  <h3 className="subtitle is-spaced">Total Deliveries</h3>
                  <p className="text-yellow-500 text-4xl -mt-2">{stats?.count || total}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        {roles.includes(Permission.VIEW_DELIVERY_STATS) && (
          <div className="flex opacity-80 rounded-2xl mr-8 w-auto h-28 border-l-8  border-yellow-500">
            <div className="card tile rounded-2xl">
              <div className="card-content -mt-4">
                <div className="is-widget-label">
                  <h3 className="subtitle ">Total value</h3>
                  <p className="text-yellow-500 text-4xl -mt-2">
                    {filters.country ? (
                      `${stats?.cost[0] ? stats?.cost[0]?.total.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0} ${stats?.cost[0] ? stats?.cost[0]?.currency : ''}`
                    ) : (
                      <p className="text-lg mt-2 text-black">Choose a country</p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default DeliveryStats;
