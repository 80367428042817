import { CreateUserReq, Permission, UserGroup } from '@calo/dashboard-types';
import { getList } from 'actions';
import { createUser } from 'actions/users';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import UserForm from './UserForm';

const NewUser = () => {
  const { mutateAsync: createMutation } = useMutation(createUser);
  const roles = useUserRoles();
  const history = useHistory();

  const { data } = useQuery<any, Error, { data: UserGroup[] }>('user-permissions', getList, {
    suspense: true
  });
  const permissionsAndRoles = data?.data as UserGroup[];

  const handleNewUser = async (values: Omit<CreateUserReq, 'id'>) => {
    const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);
    const user = await createMutation({ ...values, phoneNumber: phoneNumber?.number as string });
    roles.includes(Permission.VIEW_USER) && history.replace(Routes.user.replace(':id', user.id));
  };
  return <UserForm onSubmit={handleNewUser} permissionsAndRoles={permissionsAndRoles} />;
};
export default NewUser;
