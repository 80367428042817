import { caloTheme } from 'assets/images/theme/calo';
import { GlobalStyles } from 'lib/interfaces';

export const styles: GlobalStyles = {
  floatButtonContainer: {
    position: 'fixed',
    bottom: '5%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1000,
    boxShadow: 'none'
  },
  floatButtonStyle: {
    backgroundColor: caloTheme.palette.primary100,
    color: caloTheme.palette.primary.dark,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: caloTheme.palette.primary100
    }
  }
};
