import { caloTheme } from 'assets/images/theme/calo';
import { Delivery } from 'lib/interfaces';

import { Icon } from '@iconify/react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Tooltip } from '@mui/material';

import styles from '../styles';

interface FooterViewProps {
  rowsPerPage: number;
  deliveries: Delivery[];
  handleToday: () => void;
  currentPage: number;
  handlePageChange: (p: number) => void;
  total: number;
  isLoading: boolean;
}

const FooterView = ({
  rowsPerPage,
  deliveries,
  handleToday,
  currentPage,
  handlePageChange,
  total,
  isLoading
}: FooterViewProps) => {
  const totalPages = Math.floor(deliveries.length / rowsPerPage);

  return (
    <Box sx={styles.paginationBox}>
      <Tooltip title="First Page">
        <Button onClick={() => handlePageChange(0)} disabled={currentPage === 0}>
          <Icon
            icon="uil:angle-double-left"
            width="24"
            height="24"
            color={currentPage === 0 ? caloTheme.palette.neutral500 : caloTheme.palette.neutral900}
          />
        </Button>
      </Tooltip>
      <Tooltip title="Previous Page">
        <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>
          <Icon
            icon="uil:angle-left"
            width="24"
            height="24"
            color={currentPage === 0 ? caloTheme.palette.neutral500 : caloTheme.palette.neutral900}
          />
        </Button>
      </Tooltip>
      <Tooltip title="Today">
        <Button onClick={handleToday}>
          <Icon icon="mdi:calendar-today" width="24" height="24" color={caloTheme.palette.primary500} />
        </Button>
      </Tooltip>
      <Tooltip title="Next Page">
        <LoadingButton
          loading={isLoading}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={isLoading || (deliveries.length === total && currentPage === totalPages)}
          endIcon={
            !isLoading && (
              <Icon
                icon="uil:angle-right"
                width="24"
                height="24"
                color={
                  deliveries.length === total && currentPage === totalPages
                    ? caloTheme.palette.neutral500
                    : caloTheme.palette.neutral900
                }
              />
            )
          }
        ></LoadingButton>
      </Tooltip>
      <Tooltip title="Last Page">
        <Button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
          <Icon
            icon="uil:angle-double-right"
            width="24"
            height="24"
            color={currentPage === totalPages ? caloTheme.palette.neutral500 : caloTheme.palette.neutral900}
          />
        </Button>
      </Tooltip>
    </Box>
  );
};

export default FooterView;
