import {
  CreateUserReq,
  DeliveryPlanFilters,
  DriverCreateReq,
  DriverUpdateReq,
  UpdateDriverMetricReq,
  UpdateUserReq
} from '@calo/dashboard-types';

import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import mutation from './mutation';

export const createUser = async (values: CreateUserReq) => {
  const { data } = await client.post('users', values);
  mutation(['users', data.id], data);
  return data;
};

export const updateUser = async (payload: UpdateUserReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data } = await client.put(`users/${id}`, rest);
  mutation(['users', id], data);
};

export const deleteUser = async (userId: string) => {
  const { status } = await client.delete(`users/${userId}`);
  ToastSuccessMsgs({ status, action: 'User deleted successfully' });
};

export const resetPassword = async (payload: { userId: string; newPassword: string }) => {
  const { data, status } = await client.put(`/users/${payload.userId}/reset-password`, {
    password: payload.newPassword
  });
  ToastSuccessMsgs({ status, action: 'User password changed successfully' });
  return data;
};

export const createDriver = async (values: DriverCreateReq) => {
  const { data, status } = await client.post('drivers', values);
  ToastSuccessMsgs({ status, action: 'New driver created' });
  return data;
};

export const updateDriver = async (payload: DriverUpdateReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`drivers/${id}`, rest);
  ToastSuccessMsgs({ status, action: 'driver updated' });
  return data;
};

export const deleteDriver = async (driver: any) => {
  const { status } = await client.delete(`drivers/${driver.id}`);
  ToastSuccessMsgs({ status, action: 'driver deleted' });
};

export const resetDriverPassword = async (payload: { driverId: string; newPassword: string }) => {
  const { data, status } = await client.put(`/drivers/${payload.driverId}/reset-password`, {
    password: payload.newPassword
  });
  ToastSuccessMsgs({ status, action: 'Driver password changed successfully' });
  return data;
};

export const updateDriverMetricReq = async (payload: UpdateDriverMetricReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`/route-plans/${id}`, rest);
  ToastSuccessMsgs({ status, action: 'Updated' });
  return data;
};

export const updateAllDriverMetricReq = async (ids: string[]) => {
  const { data, status } = await client.put(`/route-plans/batch-allow-drivers-to-start-shift`, {
    ids
  });
  ToastSuccessMsgs({ status, action: 'Updated' });
  return data;
};

export const exportDrivers = async (filters: DeliveryPlanFilters, email: string) => {
  const { data, status } = await client.get('route-plans/export', {
    params: {
      day: filters.day,
      country: filters.country,
      kitchen: filters.kitchen,
      email: email
    }
  });
  ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
  return data;
};
