import { forwardRef } from 'react';

import { deletePrototypeFood } from 'actions';
import { Modal, Button as OButton } from 'components';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { Food } from 'lib/interfaces';

interface DeleteSizeModalProps {
  foodWithAllSizes: Food[];
  close: () => void;
}

const DeleteSizeModal = forwardRef(({ foodWithAllSizes, close }: DeleteSizeModalProps, ref) => {
  const handleDeleteMeal = async () => {
    await Promise.all(foodWithAllSizes.map((food) => deletePrototypeFood(food.id)));
    history.push(Routes.playgroundFoodList);
    close();
  };

  return (
    <Modal ref={ref}>
      <div className="flex-row justify-between items-center mb-3">
        <h1 className="text-3xl font-bold">Delete Meal Size</h1>
        <OButton icon="fas fa-times fa-lg" onClick={() => close()} className="ml-4 w-4 h-auto border-none" />
      </div>
      <h2 className="mb-5 font-bold">Are you sure you would like to delete the Meal?</h2>
      <div className="flex flex-row justify-between">
        <OButton
          className="py-2 px-3 border-none outline-none"
          data-test="confirm_delete_meal"
          content="Delete Meal"
          onClick={handleDeleteMeal}
        />
      </div>
    </Modal>
  );
});

export default DeleteSizeModal;
