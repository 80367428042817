import { Box, Card, Stack, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { useMutation } from 'react-query';

import LoadingButton from '@mui/lab/LoadingButton';
import { withdrawBalance } from 'actions/referrals';
import { caloTheme } from 'assets/images/theme/calo';
import CaloLoader from 'components/CaloLoader/CaloLoader';
import Icon from 'components/Icon';
import { format } from 'date-fns/fp';
import { StyledTableCell } from 'lib/componentStyles';
import { toast } from 'react-toastify';
import userReferralSummary from './hooks/useReferralSummary';
import useReferralTransactions from './hooks/useReferralTransactions';

const copyToClipBoard = async (text: string, message: string) => {
  try {
    await navigator.clipboard.writeText(text);
    toast(message, { type: 'success', autoClose: 2000 });
  } catch {
    toast('Failed to copy!', { type: 'error', autoClose: 2000 });
  }
};

const ReferralCard = ({ subscription }: { subscription: { id: string } }) => {
  const { summary } = userReferralSummary({ subscriptionId: subscription.id });
  const { isLoading: isFetchingTransactions, transactionsList } = useReferralTransactions({ subscriptionId: subscription.id });

  const { mutateAsync: withdrawBalanceMutation, isLoading: isLoadingWithdarwel } = useMutation(withdrawBalance);
  const handleWithdrawBalance = async () => {
    await withdrawBalanceMutation({ userId: subscription.id });
  };

  return (
    <Card variant="outlined" sx={{ width: '100%', border: 'none', borderRadius: '8px' }}>
      <Box
        sx={{
          padding: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'auto',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column'
          }
        }}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '19px',
            lineHeight: '23px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Summary
        </Typography>
      </Box>
      <Stack
        sx={{
          padding: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column'
          }
        }}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'row'
            }
          }}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-evenly'}
          width={'50%'}
        >
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'}>
            <Stack
              sx={{
                width: '35%',
                marginRight: 1,
                display: 'flex',
                minWidth: '64px',
                textAlign: 'left',
                borderRadius: '8px',
                flexDirection: 'row',
                padding: '16px 16px 10px 16px',
                justifyContent: 'space-between',
                color: caloTheme.palette.neutral900,
                border: '1px solid ' + caloTheme.palette.neutral100,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto'
                }
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '19px',
                  textAlign: 'left',
                  marginRight: '4px',
                  lineHeight: '23px',
                  letterSpacing: '-0.02em',
                  fontFamily: caloTheme.typography.fontFamily
                }}
              >
                Total Lifetime Accumulated
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '23px',
                  lineHeight: '24px',
                  marginRight: '6px',
                  letterSpacing: '-0.02em',
                  fontFamily: caloTheme.typography.fontFamily
                }}
              >
                {summary?.totalEarned}
              </Typography>
            </Stack>
            <Stack
              sx={{
                width: '35%',
                marginRight: 1,
                display: 'flex',
                minWidth: '64px',
                textAlign: 'left',
                borderRadius: '8px',
                flexDirection: 'row',
                padding: '16px 16px 10px 16px',
                justifyContent: 'space-between',
                color: caloTheme.palette.neutral900,
                border: '1px solid ' + caloTheme.palette.neutral100,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto'
                }
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '19px',
                  textAlign: 'left',
                  marginRight: '4px',
                  lineHeight: '23px',
                  letterSpacing: '-0.02em',
                  fontFamily: caloTheme.typography.fontFamily
                }}
              >
                Total Referred
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '23px',
                  lineHeight: '24px',
                  marginRight: '6px',
                  letterSpacing: '-0.02em',
                  fontFamily: caloTheme.typography.fontFamily
                }}
              >
                {summary?.totalReferred}
              </Typography>
            </Stack>
            <Stack
              sx={{
                width: '35%',
                marginRight: 1,
                display: 'flex',
                minWidth: '64px',
                textAlign: 'left',
                borderRadius: '8px',
                flexDirection: 'row',
                padding: '16px 16px 10px 16px',
                justifyContent: 'space-between',
                color: caloTheme.palette.neutral900,
                border: '1px solid ' + caloTheme.palette.neutral100,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto'
                }
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '19px',
                  textAlign: 'left',
                  marginRight: '4px',
                  lineHeight: '23px',
                  letterSpacing: '-0.02em',
                  fontFamily: caloTheme.typography.fontFamily
                }}
              >
                Current Balance
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '23px',
                  lineHeight: '24px',
                  marginRight: '6px',
                  letterSpacing: '-0.02em',
                  fontFamily: caloTheme.typography.fontFamily
                }}
              >
                {summary?.balance ?? 0}
              </Typography>
            </Stack>
          </Box>
          <LoadingButton
            loading={isLoadingWithdarwel}
            variant="outlined"
            disabled={!((summary?.balance || 0) > 0)}
            color="primary"
            size="large"
            style={{ marginTop: 10 }}
            onClick={() => handleWithdrawBalance()}
          >
            Withdraw
          </LoadingButton>
        </Box>
        <Box
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column'
            }
          }}
          display={'flex'}
          flexDirection={'row'}
        >
          <Typography
            sx={{
              fontSize: '17px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: caloTheme.typography.fontWeightBold
            }}
          >
            <Stack
              sx={{
                width: 'auto',
                marginRight: 1,
                display: 'flex',
                borderRadius: '8px',
                flexDirection: 'row',
                justifyContent: 'space-between',
                fontFamily: caloTheme.typography.fontFamily,
                border: '1px solid' + caloTheme.palette.neutral100,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto',
                  marginRight: 0
                }
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '17px',
                  lineHeight: '28px',
                  letterSpacing: -0.02,
                  padding: '16px 16px 10px 16px',
                  color: caloTheme.palette.neutral900,
                  fontFamily: caloTheme.typography.fontFamily
                }}
              >
                Referral Link {summary?.share.link}
              </Typography>

              <Typography
                sx={{
                  margin: '16px',
                  display: 'flex',
                  fontSize: '23px',
                  cursor: 'pointer',
                  lineHeight: '28px',
                  justifyItems: 'end'
                }}
                onClick={() => copyToClipBoard(summary?.share.link, 'Referral Link Copied!')}
              >
                <Icon name={'copyBlack'} size={7} />
              </Typography>
            </Stack>
          </Typography>
          <Typography
            sx={{
              fontSize: '17px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: caloTheme.typography.fontWeightBold
            }}
          >
            <Stack
              sx={{
                width: 'auto',
                marginRight: 1,
                display: 'flex',
                borderRadius: '8px',
                flexDirection: 'row',
                justifyContent: 'space-between',
                fontFamily: caloTheme.typography.fontFamily,
                border: '1px solid' + caloTheme.palette.neutral100,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto',
                  marginRight: 0
                }
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '17px',
                  lineHeight: '28px',
                  letterSpacing: -0.02,
                  padding: '16px 16px 10px 16px',
                  color: caloTheme.palette.neutral900,
                  fontFamily: caloTheme.typography.fontFamily
                }}
              >
                Referral Code: {summary?.share.code}
              </Typography>

              <Typography
                sx={{
                  margin: '16px',
                  display: 'flex',
                  fontSize: '23px',
                  cursor: 'pointer',
                  lineHeight: '28px',
                  justifyItems: 'end'
                }}
                onClick={() => copyToClipBoard(summary?.share.code, 'Referral Code Copied!')}
              >
                <Icon name={'copyBlack'} size={7} />
              </Typography>
            </Stack>
          </Typography>
        </Box>
      </Stack>
      <Box
        sx={{
          padding: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'auto',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column'
          }
        }}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '19px',
            lineHeight: '23px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          History
        </Typography>
      </Box>
      {isFetchingTransactions ? (
        <Stack sx={{ width: '100%', justifyContent: 'center' }}>
          <CaloLoader />
        </Stack>
      ) : (
        <Table
          sx={{
            marginY: '4px',
            minHeight: '120px',
            tableLayout: 'fixed',
            overflow: 'auto',
            width: '100%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
        >
          <TableHead
            sx={{
              backgroundColor: caloTheme.palette.neutral50,
              width: '100%',
              borderRadius: '8px'
            }}
          >
            <TableRow>
              <StyledTableCell sx={{ width: '10%' }}>Time</StyledTableCell>
              <StyledTableCell sx={{ width: '10%' }}>Description</StyledTableCell>
              <StyledTableCell sx={{ width: '10%' }}>Amount</StyledTableCell>
              <StyledTableCell sx={{ width: '10%' }}>Balance</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {transactionsList.length === 0 && (
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: '25px',
                    lineHeight: '23px',
                    fontFamily: caloTheme.typography.fontFamily,
                    fontWeight: 600
                  }}
                >
                  No Transaction History
                </Typography>
              )}
            </TableRow>
            {transactionsList.map((transaction) => (
              <TableRow key={transaction.id}>
                <StyledTableCell>{format('dd LLL yyy, k:m aaaa')(new Date(transaction.createdAt))}</StyledTableCell>
                <StyledTableCell>{transaction.description}</StyledTableCell>
                <StyledTableCell>{transaction.amount}</StyledTableCell>
                <StyledTableCell>{transaction.type}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Card>
  );
};

export default ReferralCard;
