import { Dispatch } from 'redux';

import { Country } from '@calo/types';
import client from 'lib/client';
import { Action } from 'lib/enums';

export const setUsers = (users: any[]) => ({
  type: Action.USER_LIST_SET,
  payload: {
    users
  }
});

export const getUsers = (country: Country) => async (dispatch: Dispatch<any>) => {
  const { data } = await client.get('/map/drivers', { params: { country } });

  dispatch(setUsers(data.data));
};
