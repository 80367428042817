import { FoodPrototypeAction, PrototypeAction } from '@calo/dashboard-types';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import React from 'react';
import CommentItem from './CommentItem';
interface CommentsPopupProps {
  commentsPopupRef: React.MutableRefObject<ModalRef>;
  commentDisabled: boolean;
  submitDisabled: boolean;
  comment: string;
  commentItems: FoodPrototypeAction[];
  setComment: React.Dispatch<React.SetStateAction<string>>;
  closeHandler: () => void;
  submitCommentHandler: any;
}

const CommentsPopup = ({
  comment,
  setComment,
  commentsPopupRef,
  commentItems,
  closeHandler,
  commentDisabled,
  submitDisabled,
  submitCommentHandler
}: CommentsPopupProps) => {
  return (
    <Popup ref={commentsPopupRef} maxWidth="lg" fullWidth={true} onClose={closeHandler}>
      <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: '20px' }}>
        <Typography variant="h5" sx={{ fontSize: '32px', fontWeight: 600 }}>
          Comments
        </Typography>
        <IconButton onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </Stack>

      {commentItems.length > 0 && (
        <Stack
          sx={{
            px: '10px',
            gap: '20px',
            maxHeight: '600px',
            overflowY: 'auto',
            marginBottom: '35px',
            '&::-webkit-scrollbar': {
              width: '8px',
              height: '8px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: caloTheme.palette.neutral500,
              borderRadius: '4px'
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555'
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1'
            }
          }}
        >
          {commentItems.map((action, index) => {
            if (action.type === PrototypeAction.comment) {
              return <CommentItem key={index} commentInfo={action} isLastItem={index === commentItems.length - 1} />;
            }
          })}
        </Stack>
      )}
      <Stack sx={{ width: '100%', marginBottom: '10px', gap: '6px' }}>
        <Typography sx={{ fontSize: '23px', fontWeight: 600 }}>Add a Comment</Typography>
        <TextField
          label="Comment"
          type="text"
          name="comment"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          disabled={commentDisabled}
          sx={{ width: '100%' }}
          InputProps={{
            style: { borderRadius: 8 },
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="text"
                  disabled={submitDisabled}
                  onClick={submitCommentHandler}
                  sx={{
                    textTransform: 'none',
                    fontSize: '16px',
                    color: caloTheme.palette.primary500,
                    fontWeight: 600,
                    backgroundColor: 'transparent'
                  }}
                >
                  Add comment
                </Button>
              </InputAdornment>
            )
          }}
        />
      </Stack>
    </Popup>
  );
};

export default CommentsPopup;
