import { Currency } from '@calo/types';

export const convertCurrency = (amount: number, from: Currency, to?: Currency) => {
  switch (from) {
    case to:
      return amount;
    case Currency.AED:
    case Currency.QAR:
    case Currency.SAR: {
      if (to === Currency.AED || to === Currency.QAR || to === Currency.SAR) {
        return amount;
      } else if (to === Currency.BHD || to === Currency.OMR || to === Currency.KWD) {
        return amount / 10;
      }

      break;
    }
    case Currency.BHD:
    case Currency.OMR:
    case Currency.KWD: {
      if (to === Currency.BHD || to === Currency.OMR || to === Currency.KWD) {
        return amount;
      } else if (to === Currency.AED || to === Currency.SAR || to === Currency.QAR) {
        return amount * 10;
      }
      break;
    }
    default:
      return 0;
  }

  return 0;
};
