import { Box, Button, Stack, Typography } from '@mui/material';
import { startCase } from 'lodash-es';

import { DietType, FoodType, Kitchen, PlanType, SubscriptionFrequency } from '@calo/types';

import { caloTheme } from 'assets/images/theme/calo';

interface Selection {
  kitchens: Kitchen[];
  setKitchen: (kitchen: Kitchen) => void;
  availableDeliveryDays: number[];
  selectedDeliveryDays: number[];
  handleDeliveryDaySelection: (day: number) => void;
  selectedKitchen: Kitchen;
  frequencies: SubscriptionFrequency[];
  selectedFrequency?: SubscriptionFrequency;
  setSubscriptionFrequency: (frequency: SubscriptionFrequency) => void;
  dietTypes: DietType[];
  selectedDietType?: DietType;
  setDietType: React.Dispatch<React.SetStateAction<DietType | undefined>>;
  availablePlans: any[];
  planTypes: PlanType[];
  selectedPlanType?: PlanType;
  setPlanType: React.Dispatch<React.SetStateAction<PlanType | undefined>>;
  isCustomPlan: boolean;
  handleCustomMealPlan: (meal: FoodType, action: 'add' | 'remove') => void;
  selectedCustomPlanMeals: { [k: string]: number };
}

const getDay = (day: number) => {
  switch (day) {
    case 0:
    default:
      return 'Sun.';
    case 1:
      return 'Mon.';
    case 2:
      return 'Tue.';
    case 3:
      return 'Wed.';
    case 4:
      return 'Thu.';
    case 5:
      return 'Fri.';
    case 6:
      return 'Sat.';
  }
};

export default ({
  availableDeliveryDays,
  selectedDeliveryDays,
  handleDeliveryDaySelection,
  selectedKitchen,
  setKitchen,
  frequencies,
  selectedFrequency,
  setSubscriptionFrequency,
  dietTypes,
  selectedDietType,
  setDietType,
  planTypes,
  selectedPlanType,
  setPlanType,
  isCustomPlan,
  availablePlans,
  selectedCustomPlanMeals,
  handleCustomMealPlan
}: Selection) => {
  const kitchensList = Object.values(Kitchen).filter((k) => !k.includes('000'));
  let planFoodTypes: string[] = [];
  if (availablePlans.length > 0) {
    const selectedPlan = availablePlans.find((p) => p.planId === selectedPlanType);
    if (selectedPlan) {
      planFoodTypes = Object.keys(selectedPlan.foodTypePrices ?? []);
    }
  }

  return (
    <>
      <Box
        sx={{
          padding: 2,
          marginBottom: 1,
          borderRadius: 3,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'row'
          },
          backgroundColor: caloTheme.palette.grey[100]
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: 'left',
            lineHeight: '40px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Mountain Peak
        </Typography>
        <Stack direction="row" spacing={1} flexWrap={'wrap'}>
          {kitchensList.map((kitchen) => (
            <Button
              key={kitchen}
              variant={kitchen === selectedKitchen ? 'contained' : 'outlined'}
              onClick={() => setKitchen(kitchen)}
              sx={{
                color: kitchen === selectedKitchen ? caloTheme.palette.white : caloTheme.palette.primary.main,
                flexGrow: 1
              }}
            >
              {kitchen}
            </Button>
          ))}
        </Stack>
      </Box>
      <Box
        sx={{
          padding: 2,
          marginBottom: 1,
          borderRadius: 3,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'row'
          },
          backgroundColor: caloTheme.palette.grey[100]
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: 'left',
            lineHeight: '40px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Delivery Days
        </Typography>
        <Stack direction="row" spacing={2} flexWrap={'wrap'}>
          {availableDeliveryDays.map((day) => (
            <Button
              key={day}
              variant={selectedDeliveryDays.includes(day) ? 'contained' : 'outlined'}
              onClick={() => handleDeliveryDaySelection(day)}
              sx={{
                color: selectedDeliveryDays.includes(day) ? caloTheme.palette.white : caloTheme.palette.primary.main,
                flexGrow: 1,
                marginLeft: 0,
                marginRight: 0
              }}
            >
              {getDay(day)}
            </Button>
          ))}
        </Stack>
      </Box>
      <Box
        sx={{
          padding: 2,
          marginBottom: 1,
          borderRadius: 3,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'row'
          },
          backgroundColor: caloTheme.palette.grey[100]
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: 'left',
            lineHeight: '40px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Diet
        </Typography>
        <Stack direction="row" spacing={1} flexWrap={'wrap'}>
          {(!dietTypes || dietTypes.length === 0) && <>Loading...</>}
          {dietTypes.map((diet) => (
            <Button
              key={diet}
              variant={diet === selectedDietType ? 'contained' : 'outlined'}
              onClick={() => setDietType(diet)}
              sx={{
                color: diet === selectedDietType ? caloTheme.palette.white : caloTheme.palette.primary.main,
                flexGrow: 1
              }}
            >
              {startCase(diet)}
            </Button>
          ))}
        </Stack>
      </Box>
      <Box
        sx={{
          padding: 2,
          marginBottom: 1,
          borderRadius: 3,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'row'
          },
          backgroundColor: caloTheme.palette.grey[100]
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: 'left',
            lineHeight: '40px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Frequency
        </Typography>
        <Stack direction="row" spacing={1} flexWrap={'wrap'}>
          {(!frequencies || frequencies.length === 0) && <>Selected a diet...</>}
          {frequencies.map((frequency) => (
            <Button
              key={frequency}
              variant={frequency === selectedFrequency ? 'contained' : 'outlined'}
              onClick={() => setSubscriptionFrequency(frequency)}
              sx={{
                color: frequency === selectedFrequency ? caloTheme.palette.white : caloTheme.palette.primary.main,
                flexGrow: 1
              }}
            >
              {startCase(frequency)}
            </Button>
          ))}
        </Stack>
      </Box>
      <Box
        sx={{
          padding: 2,
          marginBottom: 1,
          borderRadius: 3,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'row'
          },
          backgroundColor: caloTheme.palette.grey[100]
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: 'left',
            lineHeight: '40px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Package
        </Typography>
        <Stack direction="row" spacing={2} justifyContent={'flex-start'} flexWrap={'wrap'} sx={{ gap: 0.5 }}>
          {(!planTypes || planTypes.length === 0) && <>Select a frequency...</>}
          {planTypes.map((plan) => (
            <Button
              key={plan}
              variant={plan === selectedPlanType ? 'contained' : 'outlined'}
              onClick={() => setPlanType(plan)}
              sx={{
                color: plan === selectedPlanType ? caloTheme.palette.white : caloTheme.palette.primary.main,
                marginLeft: 0,
                marginRight: 0
              }}
            >
              {startCase(plan)}
            </Button>
          ))}
        </Stack>
      </Box>
      {isCustomPlan && (
        <Box
          sx={{
            padding: 2,
            marginBottom: 1,
            borderRadius: 3,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'row'
            },
            backgroundColor: caloTheme.palette.grey[100]
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textAlign: 'left',
              lineHeight: '40px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600
            }}
          >
            Meals
          </Typography>
          <Stack direction="row" spacing={2} flexWrap={'wrap'} justifyContent={'left'}>
            {planFoodTypes.map((fdt: string) => (
              <Box
                key={`${fdt}-box`}
                sx={{
                  padding: 3,
                  marginBottom: 1,
                  borderRadius: 3,
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    flexDirection: 'row'
                  },
                  backgroundColor: caloTheme.palette.white
                }}
              >
                <Button
                  key={`${fdt}-add`}
                  variant={'outlined'}
                  size="small"
                  onClick={() => handleCustomMealPlan(fdt as FoodType, 'add')}
                >
                  +
                </Button>
                <Typography
                  key={`${fdt}-add-title`}
                  variant="subtitle2"
                  sx={{
                    textAlign: 'center',
                    fontFamily: caloTheme.typography.fontFamily,
                    fontWeight: 600
                  }}
                >
                  {startCase(fdt)} x{selectedCustomPlanMeals[fdt]}
                </Typography>
                <Button
                  key={`${fdt}-remove`}
                  variant={'outlined'}
                  size="small"
                  onClick={() => handleCustomMealPlan(fdt as FoodType, 'remove')}
                >
                  -
                </Button>
              </Box>
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
};
