import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  editButton: {
    mr: 2,
    height: '45px',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    boxShadow: 'none',
    borderRadius: '8px',
    padding: '14px 20px 14px 20px',
    backgroundColor: caloTheme.palette.neutral50,
    '&:hover': {
      backgroundColor: caloTheme.palette.neutral50,
      boxShadow: 'none'
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      justifyItems: 'center',
      margin: 'auto',
      width: 'auto'
    }
  },
  swapButton: {
    ml: 'auto',
    height: '45px',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    boxShadow: 'none',
    borderRadius: '8px',
    padding: '14px 20px 14px 20px',
    backgroundColor: caloTheme.palette.neutral50,
    '&:hover': {
      backgroundColor: caloTheme.palette.neutral50,
      boxShadow: 'none'
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      justifyItems: 'center',
      margin: 'auto',
      width: 'auto'
    }
  },
  cancelButton: {
    textAlign: 'center',
    height: '45px',
    mx: 2,
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
    color: caloTheme.palette.neutral600,
    '&:hover': {
      backgroundColor: caloTheme.palette.neutral100,
      borderColor: caloTheme.palette.neutral100
    }
  },
  saveButton: {
    textAlign: 'center',
    height: '45px',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
    boxShadow: 'none',
    padding: '14px 20px 14px 20px',
    backgroundColor: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    color: 'white',
    '&:hover': {
      color: 'white',
      backgroundColor: caloTheme.palette.primary600,
      borderColor: caloTheme.palette.primary600
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      marginTop: 2,
      width: '75%',
      mx: 1
    }
  },
  favMeal: {
    zIndex: 1,
    left: 3,
    top: 4,
    cursor: 'pointer'
  },
  cardSubTitle: { fontSize: '12px', fontWeight: 600, lineHeight: '14px' },
  ingredientsText: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    whiteSpace: 'nowrap'
  },
  enLargedIcon: { minWidth: '592px', minHeight: '592px', margin: 'auto', borderRadius: 8 },
  favButtonEnlarged: {
    zIndex: 1,
    left: 33,
    top: 86,
    cursor: 'pointer'
  },
  selectSize: {
    width: '100%',
    minWidth: '35%',
    height: '45px',
    marginRight: '12px'
  }
};

export default styles;
