import { useEffect, useRef, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import { Permission, ProcessingStage, PrototypeAction } from '@calo/dashboard-types';
import { Button } from '@mui/material';
import {
  addPrototypeIngredientAction,
  approvePrototypeIngredient,
  deletePrototypeIngredient,
  getListWithParams,
  getRecord,
  updatePrototypeIngredient
} from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { DeleteConfirmationPopup } from 'components';
import Disclaimer from 'components/Disclaimer';
import { AllergenCategoryCard, CostCard, IngredientHeaderCard, MeasurementGuideAndMacrosCard } from 'components/IngredientShared';
import { ModalRef } from 'components/Modal';
import { FormOperation, Routes } from 'lib/enums';
import { calculateCalories } from 'lib/helpers';
import { getUsedOnMenuButtonColor } from 'lib/helpers/usedOnMenu';
import { useUserRoles } from 'lib/hooks';
import { FoodComponentQuery, IngredientFormData, IngredientType } from 'lib/interfaces';
import { omit } from 'lodash';
import { useHistory } from 'react-router-dom';
import ActivityLogCard from 'views/ChefPlayground/Shared/ActivityLogCard';
import IngredientCommentsPopup from 'views/ChefPlayground/Shared/IngredientCommentsPopup';
import StatusBox from 'views/ChefPlayground/Shared/StatusBox';
import IngredientInformationCard from './IngredientInformationCard';
import useIngredientForm from './useIngredientForm';

const ExactIngredient = () => {
  const isDisabled = false;
  const roles = useUserRoles();
  const history = useHistory();
  const deletionRef = useRef<ModalRef>(null);

  const { id } = useParams<{ id: string }>();
  const commentsPopupRef = useRef<ModalRef>();

  const [usedOnMenuButtonColor, setUsedOnMenuButtonColor] = useState<string[]>([]);

  const { data: components, isLoading: loadingComponents } = useQuery<any, Error, FoodComponentQuery>(
    [
      'food-components/prototype',
      {
        page: 0,
        filters: {
          ingredientId: id
        },
        limit: 50
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: roles.includes(Permission.DELETE_INGREDIENT)
    }
  );

  const { mutateAsync: updateMutation } = useMutation(updatePrototypeIngredient);

  const { data, refetch } = useQuery(['ingredients/prototype', id], getRecord, {
    suspense: true
  });
  const ingredient = data as IngredientType;

  const handleUpdateIngredient = async (values: IngredientFormData) => {
    await updateMutation({ id, ...omit(values, ['order', 'prototype']) });
    ingredient.name.en = values.name.en;
  };

  const { values, errors, isValid, isSubmitting, dirty, handleSubmit, handleChange, handleBlur, setFieldValue } =
    useIngredientForm(ingredient, handleUpdateIngredient);

  useEffect(() => {
    setFieldValue('macros.cal', calculateCalories(values.macros));
  }, [values.macros.carbs, values.macros.fat, values.macros.protein]);

  useEffect(() => {
    const calValue = calculateCalories(values.macros);
    if (values.macros.cal !== calValue) {
      setFieldValue('macros.cal', calValue);
    }
  }, []);

  useEffect(() => {
    if (ingredient?.usedOnMenu) {
      for (const data of ingredient.usedOnMenu) {
        setUsedOnMenuButtonColor((old) => [...old, getUsedOnMenuButtonColor(data)]);
      }
    }
  }, [ingredient]);

  const isApproved = ingredient?.stage === ProcessingStage.approved;
  const isWaitingApproval = ingredient?.stage === ProcessingStage.pending;

  const { mutate: createAction, isLoading: actionLoading } = useMutation(addPrototypeIngredientAction, {
    onSuccess: () => {
      refetch();
    }
  });

  const { mutate: approveIngredient, isLoading: approveLoading } = useMutation(approvePrototypeIngredient, {
    onSuccess: () => {
      refetch();
    }
  });

  const { mutate: deleteIngredient, isLoading: deleteLoading } = useMutation(deletePrototypeIngredient, {
    onSuccess: () => {
      history.push(Routes.playgroundIngredientList);
    }
  });

  const addCommentSuccessHandler = (data: IngredientType) => {
    setFieldValue('prototypeActions', data.prototypeActions);
    refetch();
  };

  const handleCloseDeletePopup = () => {
    deletionRef.current?.close();
  };

  return (
    <>
      <IngredientHeaderCard
        operation={FormOperation.update}
        ingredient={ingredient}
        route={Routes.playgroundIngredientList}
        disabled={!roles.includes(Permission.UPDATE_PROTOTYPE_INGREDIENT) || !dirty || !isValid || isSubmitting}
        handleSubmit={handleSubmit}
        usedOnMenuButtonColor={usedOnMenuButtonColor}
      >
        <Button
          variant="outlined"
          disabled={
            !ingredient ||
            actionLoading ||
            deleteLoading ||
            loadingComponents ||
            !roles.includes(Permission.DELETE_PROTOTYPE_INGREDIENT)
          }
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'capitalize',
            borderRadius: '8px',
            boxShadow: 'none',
            color: caloTheme.palette.neutral900,
            borderColor: caloTheme.palette.neutral900,
            backgroundColor: 'transparent',
            '&:hover': {
              borderColor: caloTheme.palette.neutral900,
              backgroundColor: caloTheme.palette.white
            }
          }}
          onClick={() => deletionRef.current?.open()}
        >
          {loadingComponents ? 'loading' : 'Delete'}
        </Button>
        {!isApproved && !isWaitingApproval && (
          <Button
            variant="outlined"
            disabled={
              !ingredient || actionLoading || isApproved || !roles.includes(Permission.CREATE_ACTION_PROTOTYPE_INGREDIENT)
            }
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              textTransform: 'capitalize',
              borderRadius: '8px',
              boxShadow: 'none',
              color: caloTheme.palette.neutral900,
              borderColor: caloTheme.palette.neutral900,
              backgroundColor: 'transparent',
              '&:hover': {
                borderColor: caloTheme.palette.neutral900,
                backgroundColor: caloTheme.palette.white
              }
            }}
            onClick={() => createAction({ action: PrototypeAction.pend, id: ingredient.id })}
          >
            Send for Approval
          </Button>
        )}
        {isWaitingApproval && !isApproved && (
          <Button
            variant="outlined"
            disabled={
              !ingredient ||
              approveLoading ||
              actionLoading ||
              isApproved ||
              !roles.includes(Permission.APPROVE_PROTOTYPE_COMPONENT)
            }
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              textTransform: 'capitalize',
              borderRadius: '8px',
              boxShadow: 'none',
              color: caloTheme.palette.white,
              borderColor: caloTheme.palette.primary500,
              backgroundColor: caloTheme.palette.primary500,
              '&:hover': {
                borderColor: caloTheme.palette.primary500,
                backgroundColor: caloTheme.palette.primary700
              }
            }}
            onClick={() => approveIngredient(ingredient.id)}
          >
            Approve
          </Button>
        )}

        {isApproved && <StatusBox status={ProcessingStage.approved} />}
      </IngredientHeaderCard>
      <Disclaimer type="ingredient" sx={{ marginTop: '20px', marginBottom: 0 }} />
      <IngredientInformationCard
        id={ingredient.id}
        values={values}
        errors={errors}
        showImageUploader={true}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        isDisabled={isDisabled}
      />
      <AllergenCategoryCard values={values} setFieldValue={setFieldValue} isDisabled={isDisabled} />
      <MeasurementGuideAndMacrosCard
        values={values}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        disabled={isDisabled}
      />
      <CostCard
        operation={FormOperation.update}
        values={values}
        errors={errors}
        ingredient={ingredient}
        handleChange={handleChange}
      />
      <ActivityLogCard
        actionLogs={ingredient?.prototypeActions ?? []}
        type="ingredient"
        addCommentClickHandler={() => commentsPopupRef.current?.open()}
      />
      <IngredientCommentsPopup
        selectedIngredient={ingredient}
        ref={commentsPopupRef}
        closeCommentsPopup={() => commentsPopupRef.current?.close()}
        successHandler={addCommentSuccessHandler}
      />
      <DeleteConfirmationPopup
        type="ingredient"
        deletionRef={deletionRef}
        typeUsage={
          components?.data?.map((component) => ({
            name: component.name.en,
            id: component.id
          })) || []
        }
        isLoading={loadingComponents}
        onClose={handleCloseDeletePopup}
        onDelete={() => deleteIngredient(id)}
      />
    </>
  );
};

export default ExactIngredient;
