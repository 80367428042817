import { LatLng } from '@calo/driver-types';
import { Suspense, useReducer, useEffect } from 'react';
import SinglePolygonManager from './PolygonManager';
import SinglePolygonContext from './SinglePolygonContext';
import singlePolygonReducer from './singlePolygonReducer';
import { multiPolygon } from '@turf/helpers';

interface SingleDrawingManagerProps {
  bounds?: LatLng[];
  isEditing: boolean;
  setIsEditing: (e: boolean) => void;
  handleUpdate: (data: LatLng[]) => Promise<any>;
  setMpoly: (value: any) => void;
  setPolygonSt: (value: any) => void;
}

const SingleDrawingManager = ({
  setPolygonSt,
  setMpoly,
  bounds,
  isEditing,
  setIsEditing,
  handleUpdate
}: SingleDrawingManagerProps) => {
  const [polygonState, dispatch] = useReducer(singlePolygonReducer, { polygon: undefined });

  const mpoly =
    polygonState.polygon !== null &&
    polygonState.polygon !== undefined &&
    multiPolygon([
      [
        polygonState.polygon
          ?.getPath()
          ?.getArray()
          .map((r) => [r.lng(), r.lat()]),
        [[polygonState.polygon?.getPath()?.getArray()[0].lng(), polygonState.polygon?.getPath()?.getArray()[0].lat()]]
      ]
    ]);

  useEffect(() => {
    mpoly && setMpoly(mpoly);
    setPolygonSt(polygonState);
  }, [polygonState.polygon]);

  return (
    <SinglePolygonContext.Provider value={dispatch}>
      <Suspense fallback={null}>
        <SinglePolygonManager
          bounds={bounds}
          polygonState={polygonState}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          handleUpdate={handleUpdate}
        />
      </Suspense>
    </SinglePolygonContext.Provider>
  );
};

export default SingleDrawingManager;
