import { Permission } from '@calo/dashboard-types';
import { createCodes, getRecord } from 'actions';
import mutation from 'actions/mutation';
import cx from 'classnames';
import { Button, Select2 } from 'components';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { InvitationUser } from 'lib/interfaces';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';

interface InvitationStatsRowProps {
  user: InvitationUser;
}

const InvitationStatsRow = ({ user }: InvitationStatsRowProps) => {
  const { data } = useQuery(['stats', 'invitation-codes'], getRecord);
  const stats = data as { generated: number; claimed: number };

  const roles = useUserRoles();
  const [val, setVal] = useState(0);
  const { mutateAsync: createCodesMutation } = useMutation(createCodes);
  const options: string[] = [];

  for (let i = 0; i <= 25; i++) {
    options.push(`${i}`);
  }

  const handleNewCodes = async (values: InvitationUser) => {
    const userID = values.user.id;
    const count = +val;
    await createCodesMutation(
      { userId: userID, count },
      {
        onSuccess: () => {
          mutation(['stats/user-invitation-codes', userID], {
            values: [values, (values.generated = +(values.generated + count))]
          });
          mutation(['stats', 'invitation-codes'], { generated: stats.generated + count, claimed: stats.claimed });
        },
        onSettled: () => {
          setVal(0);
        }
      }
    );
  };

  return (
    <tr>
      <td>
        {roles.includes(Permission.VIEW_SUBSCRIPTION) ? (
          <Link to={Routes.subscription.replace(':id', user.user.id)}>{user.user.name}</Link>
        ) : (
          `${user.user.name}`
        )}
      </td>
      <td>{user.generated}</td>
      <td>{user.claimed}</td>
      <td>{user.generated - user.claimed || '--'}</td>
      <td>{user.country || '--'}</td>
      <td>{JSON.stringify(user.kitchen) || '--'}</td>
      <td>{user.user.phoneNumber || '--'}</td>
      <td>{'--'}</td>
      <td>
        {roles.includes(Permission.CREATE_INVITATION_CODE) && (
          <div className="flex flex-row  ">
            <Select2
              options={options.map((type) => ({
                value: +type,
                label: type
              }))}
              className="w-auto"
              onChange={(e: any) => setVal(e.target.value)}
              value={val}
            />
            <Button
              content="Send"
              disabled={val === 0}
              primary
              onClick={() => handleNewCodes(user)}
              className={cx(
                'hover:text-white hover:shadow-md focus:text-white ml-4  text-white border-green-500 hover:border-green-500 ',
                {}
              )}
            />
          </div>
        )}
      </td>
    </tr>
  );
};

export default InvitationStatsRow;
