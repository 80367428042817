import { getRecord, updateFoodPackageElement } from 'actions';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import PackageElementHeaderCard from './PackageElementHeaderCard';
import PackageElementInformation from './PackageElementInformationCard';
import usePackageElementForm from './usePackageElementForm';

const ExactPackageElement = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useQuery(['food-package-elements', id], getRecord, {
    suspense: true
  });
  const element = data as any;
  const { mutateAsync: updateMutation } = useMutation(updateFoodPackageElement);

  const handleUpdateElement = async (values: any) => {
    await updateMutation({ id, ...values });
  };

  const { handleSubmit, values, handleBlur, isSubmitting, isValid, dirty, setFieldValue, errors, touched } =
    usePackageElementForm(element, handleUpdateElement);

  return (
    <>
      <PackageElementHeaderCard
        dirty={dirty}
        isValid={isValid}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        packageElement={element}
      />
      <PackageElementInformation
        errors={errors}
        values={values}
        touched={touched}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      />
    </>
  );
};

export default ExactPackageElement;
