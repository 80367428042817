import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { styles } from './styles';

interface ConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  selectedWeek: number;
  startWeek: string;
  endWeek: string;
}

const ConfirmDialog = ({ isOpen, onClose, onConfirm, selectedWeek, startWeek, endWeek }: ConfirmDialogProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Confirm Duplication</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`You are duplicating the menu of week ${selectedWeek} dates from ${startWeek} to ${endWeek}. Are you sure you want to proceed?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={styles.popupContainer}>
        <Button onClick={onConfirm} variant="contained" sx={styles.buttons}>
          Duplicate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
