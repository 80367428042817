import { debounce } from 'lodash-es';

interface OnSearchProps {
  text: string;
  action: any;
  name: (values: string) => void;
}

export const handleSearch = debounce(({ text, action, name }: OnSearchProps) => {
  if (text.length > 0 || action === 'input-change') {
    return name(text);
  }
}, 600);
