import { Brand, Country, Kitchen } from '@calo/types';
import { History } from 'history';
import { MenuPresentationType } from 'lib/enums';
import { Food, Menu } from 'lib/interfaces';

export type MenuListFilters = {
  country: Country;
  brand: Brand;
  kitchen: Kitchen;
};

export type MenuPathUpdateFunctions = {
  setBrand: (newBrand: Brand) => void;
  setKitchen: (newKitchen: Kitchen) => void;
};

export interface WeekMenuListProps {
  foodList: Food[];
  foodIds: string[];
  searchTerm: string;
  isLoading: boolean;
  isEditable: boolean;
  selectedWeek: number;
  selectedYear: number;
  filters: MenuListFilters;
  isAddMealsPopupOpen: boolean;
  openAddMealsPopup: () => void;
  weeklyMenuList: Menu[] | undefined;
  onMenuUpdate: (updatedMenu: Menu) => void;
  setWeeklyAverageCost: React.Dispatch<React.SetStateAction<number>>;
  setIsAddMealsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface DayMenuProps {
  filters: MenuListFilters;
  history: History<unknown>;
  isLoading: boolean;
  selectedMenu: Menu | undefined;
}

export type MenuActionsCardProps = {
  filters: MenuListFilters;
  history: History<unknown>;
  isDuplicated: boolean;
  setIsDuplicated: (value: boolean) => void;
  setSelectedYear: (v: number) => void;
  menuList: Menu[] | undefined;
  updateWeeklyMenuList: (newMenuList: Menu[]) => void;
  weeklyMenuList: Menu[] | undefined;
  foodList: Food[];
  foodIds: string[];
  isLoading: boolean;
  isFoodListLoading: boolean;
  weeklyAverageCost: number;
  setWeeklyAverageCost: (v: number) => void;
  isWeeklyMenuChanged: boolean;
} & (
  | {
      menuPresentation: MenuPresentationType.daily;
      selectedDay: string;
      setSelectedDay: React.Dispatch<React.SetStateAction<string>>;
    }
  | {
      menuPresentation: MenuPresentationType.weekly;
      selectedWeek: number;
      setSelectedWeek: React.Dispatch<React.SetStateAction<number>>;
    }
);

export interface MenuHeaderCardProps {
  selectedDay: string;
  filters: MenuListFilters;
  isWeeklyMenuChanged: boolean;
  menuPresentation: MenuPresentationType;
  setMenuPresentation: React.Dispatch<React.SetStateAction<MenuPresentationType>>;
}

export interface menuPresentationOption {
  label: MenuPresentationType.daily | MenuPresentationType.weekly;
  value: MenuPresentationType.daily | MenuPresentationType.weekly;
}

export interface WeeklyMenusDatePickerProps {
  selectedWeek: number;
  filters: MenuListFilters;
  isWeeklyMenuChanged: boolean;
  setSelectedYear: (v: number) => void;
  setSelectedWeek: React.Dispatch<React.SetStateAction<number>>;
}

export const menuPresentationOptions: menuPresentationOption[] = [
  {
    label: MenuPresentationType.daily,
    value: MenuPresentationType.daily
  },
  {
    label: MenuPresentationType.weekly,
    value: MenuPresentationType.weekly
  }
];
