import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  card: {
    mb: 2,
    border: 'none',
    padding: 2,
    paddingX: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '16px'
  },
  button: {
    borderColor: caloTheme.palette.neutral900,
    borderRadius: '8px',
    color: caloTheme.palette.neutral900,
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 600,
    ':hover': {
      borderColor: caloTheme.palette.neutral900,
      color: caloTheme.palette.neutral900
    }
  },
  text: { fontSize: '32px', fontWeight: 600 },
  stack: { display: 'flex', flexDirection: 'row', gap: '10px' }
};
