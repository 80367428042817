import { caloTheme } from 'assets/images/theme/calo';
import SelectMUI from 'components/MUI/SelectMUI';
import { foodInformationFormMultiSelectCustomStyles } from 'lib/componentStyles';
import { FoodPackageElement } from 'lib/interfaces';
import { round } from 'lodash-es';

import { FoodPackageElementReq } from '@calo/dashboard-types';
import { Dictionary } from '@calo/types';
import { Icon as Iconify } from '@iconify/react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';

import { InputMUI } from '../';

interface PackageElementsPickerProps {
  onChange: (rows: any[]) => void;
  value: FoodPackageElement[];
  keyed: Dictionary<FoodPackageElementReq>;
  list: FoodPackageElement[];
  isDisabled?: boolean;
}

const PackageElementsPicker = ({ value, onChange, keyed, list, isDisabled }: PackageElementsPickerProps) => {
  const handleChange = (id: string, quantity: number) => {
    const i = value.findIndex((r) => r.id === id);
    onChange([...value.slice(0, i), { ...value[i], quantity }, ...value.slice(i + 1)]);
  };

  const modifyElements = (data: any) => {
    let newList: any[] = [...value];
    const existing = newList.map((row) => row.id);
    const newValues = (data || []).map((row) => row.value);

    if (newValues.length > newList.length!) {
      // add
      newList.push(
        ...data
          .filter((row) => !existing.includes(row.value))
          .map((row) => ({
            id: row.value,
            quantity: 1
          }))
      );
    } else {
      // remove
      newList = newList.filter((row) => newValues.includes(row.id));
    }
    onChange(newList);
  };
  const handleRemoveElement = (removedId: string) => {
    const newList = [...value];
    const elements = newList.filter((r) => r.id !== removedId);
    onChange(elements);
  };

  return (
    <Paper sx={{ width: '98%', m: 2, mt: 0, boxShadow: 'none' }}>
      <TableContainer>
        <Table sx={{ width: '100%' }}>
          <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, border: 0 }}>
            <TableRow>
              <TableCell
                colSpan={2}
                sx={{
                  borderRadius: '8px 0 0 8px',
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  width: '50%'
                }}
              >
                Element
              </TableCell>
              <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', width: '20%' }}>
                Quantity
              </TableCell>
              <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', width: '10%' }}>Cost</TableCell>
              <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', width: '10%' }}>Size</TableCell>
              <TableCell
                sx={{
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  width: '10%',
                  borderRadius: '0 8px 8px 0'
                }}
              >
                Total Cost
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map((element, index) => (
              <TableRow key={element.id + index}>
                <TableCell sx={{ p: 0, width: '0' }}>
                  <IconButton disabled={isDisabled} onClick={() => handleRemoveElement(element.id)}>
                    <Iconify
                      color={isDisabled ? caloTheme.palette.neutral600 : caloTheme.palette.neutral900}
                      width={24}
                      icon="uil:trash"
                    />
                  </IconButton>
                </TableCell>
                <TableCell sx={{ pl: 0, fontSize: '16px' }}>{element.name || keyed[element.id]?.name}</TableCell>
                <TableCell>
                  <InputMUI
                    type="number"
                    value={element.quantity}
                    onChange={(e) => handleChange(element.id, parseFloat(e.target.value))}
                    min={0}
                    step="any"
                    debounce
                    error={element.quantity === 0}
                    disabled={isDisabled}
                    inputProps={{ style: { borderRadius: 8 } }}
                  />
                </TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{keyed[element.id]?.cost}</TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{keyed[element.id]?.size}</TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{round(keyed[element.id]?.cost * element.quantity, 6)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={5} sx={{ borderBottom: 0, pb: 1, borderTop: '1px solid ' + caloTheme.palette.neutral100 }}>
                <SelectMUI
                  label="Packaging Element*"
                  placeholder="Select Packaging Element"
                  value={value.map((row) => row.id)}
                  isMulti
                  options={list.map((fk) => ({
                    value: fk.id,
                    label: typeof fk.name === 'string' ? fk.name : ''
                  }))}
                  onChange={modifyElements}
                  isDisabled={isDisabled}
                  customStyles={foodInformationFormMultiSelectCustomStyles()}
                />
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: 0,
                  pb: 1,
                  fontSize: '16px',
                  borderTop: '1px solid ' + caloTheme.palette.neutral100,
                  color: caloTheme.palette.black
                }}
              >
                {round(
                  value.map((fk) => keyed[fk.id]?.cost * fk.quantity).reduce((a, b) => a + b, 0),
                  6
                )}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PackageElementsPicker;
