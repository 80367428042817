import { Currency } from '@calo/types';
import cx from 'classnames';
import { Icon } from 'components';

interface PaymentStatsProps {
  transaction: number;
  totalPayment: number;
  currency: Currency;
}
const Stats = ({ totalPayment, transaction, currency }: PaymentStatsProps) => {
  console.log(totalPayment);

  return (
    <div className="level">
      <div className="level-left">
        <div className={cx(' flex opacity-80 rounded-2xl mr-8 w-auto h-28 border-l-8  border-yellow-500', {})}>
          <div className="card tile rounded-2xl">
            <div className="card-content -mt-4">
              <div className="is-widget-label">
                <Icon className="relative ml-32 mt-1" size={6} name="visa" />
                <h3 className="subtitle is-spaced -mt-4">Total Payment</h3>
                <p className="text-yellow-500 text-4xl -mt-4">
                  {totalPayment.toLocaleString(undefined, { maximumFractionDigits: currency === Currency.BHD ? 3 : 2 })}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={cx(' flex opacity-80 rounded-2xl mr-8 w-52 h-28 border-l-8  border-blue-500', {})}>
          <div className="card tile rounded-2xl">
            <div className="card-content -mt-4">
              <div className="is-widget-label">
                <Icon className="relative ml-32 text-blue-500" size={6} name="transaction" />
                <h3 className="subtitle is-spaced -mt-4">Transaction</h3>
                <p className="text-blue-500 text-4xl -mt-4">{transaction}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Stats;
