import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

interface ComponentCostInfoProps {
  costInfo: string;
  handleClose: () => void;
  marginTop?: string;
  marginBottom?: string;
  marginHorizontal?: string;
}

const ComponentCostInfo = ({ costInfo, handleClose, marginTop, marginBottom, marginHorizontal }: ComponentCostInfoProps) => {
  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: caloTheme.palette.neutral50,
        borderRadius: '8px',
        paddingY: '8px',
        paddingX: '12px',
        marginTop: marginTop || '-4px',
        marginBottom: marginBottom || '10px',
        marginHorizontal: marginHorizontal || '0px'
      }}
    >
      <Stack>
        {costInfo.split(',').map((info, index) => (
          <Typography key={index} sx={{ fontSize: '14px' }}>
            {info}
          </Typography>
        ))}
      </Stack>
      <IconButton
        onClick={handleClose}
        sx={{
          alignSelf: 'flex-start'
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};

export default ComponentCostInfo;
