import { useState } from 'react';
import { useQuery } from 'react-query';

import { Affiliate, GetAffiliatesRes, Permission } from '@calo/dashboard-types';

import { getListWithParams } from 'actions';
import { useUserRoles } from 'lib/hooks';

const useAffiliates = () => {
  const roles = useUserRoles();

  const [affiliates, setAffiliates] = useState<Affiliate[]>();

  const { refetch, isLoading } = useQuery<any, Error, GetAffiliatesRes>(['/v1/affiliates'], getListWithParams, {
    suspense: false,
    keepPreviousData: true,
    enabled: !!roles.includes(Permission.VIEW_AFFILIATE_PROFILE),
    onSuccess: (data) => {
      if (data) {
        setAffiliates(data.data);
      }
    }
  });

  const orderedAffiliates = affiliates
    ? affiliates.sort((a, b) => {
        return new Date(a.dateJoined).getTime() - new Date(b.dateJoined).getTime();
      })
    : [];

  return {
    affiliatesLoading: isLoading,
    affiliates: orderedAffiliates,
    refetchAffiliates: refetch
  };
};

export default useAffiliates;
