import { Card, SxProps, Theme } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

const Container = ({ children, sx = {} }: { children: React.ReactNode; sx?: SxProps<Theme> | undefined }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        border: 'none',
        padding: 2,
        paddingX: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: '-35px',
        marginRight: '-35px',
        ...sx,
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%'
        }
      }}
    >
      {children}
    </Card>
  );
};

export default Container;
