import { getFoodCustomMacros } from 'actions/calculateFoodCustomMacros';
import Popup from 'components/Popup';
import { Food, FoodWithPosition } from 'lib/interfaces';
import { useEffect, useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query';

import { CustomMealCategory, Macros, Subscription } from '@calo/types';
import { Box } from '@mui/material';

import { CustomizeFoodMacrosResultCard } from './CustomizeFoodMacrosResultsCard';
import { CustomMacrosGoalsCard } from './CustomMacrosGoalsCard';

interface CustomizeMacrosPopUpProps {
  onClose: () => void;
  customizeMacrosModalRef: any;
  food: FoodWithPosition;
  deliveryId: string;
  subscription: Subscription;

  refetchDelivery: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
}

const isAthleteFood = (food) => {
  return food.category === CustomMealCategory.athlete;
};

const CustomizeMacrosPopUp = ({
  food,
  customizeMacrosModalRef,
  onClose,
  deliveryId,
  subscription,
  refetchDelivery
}: CustomizeMacrosPopUpProps) => {
  const [newMacros, setNewMacros] = useState<Macros>();
  const [foodTargetMacros, setFoodTargetMacros] = useState<Macros>();
  const [calculatedFood, setCalculatedFood] = useState<FoodWithPosition>();
  const { isLoading, refetch, isFetching } = useQuery<any, Error, any>(
    ['athlete/custom', { subscriptionId: subscription.id, deliveryId, macros: newMacros }],
    () => getFoodCustomMacros(food.id, deliveryId, newMacros!),
    {
      enabled: !!newMacros,
      onSuccess: (data) => {
        setCalculatedFood(data);
      }
    }
  );

  const [openResult, setOpenResults] = useState(false);

  useEffect(() => {
    if (food?.category === CustomMealCategory.athlete && food?.metadata) {
      // @ts-ignore
      const foodMetadata = JSON.parse(food?.metadata);
      setFoodTargetMacros(foodMetadata.targetMacros);
      setOpenResults(true);
    }
  }, [food.id]);

  useEffect(() => {
    if (newMacros) {
      refetch();
    }
  }, [newMacros]);

  const onSubmit = (macros: Macros) => {
    setNewMacros((prev) => ({ ...prev, ...macros }));
    setOpenResults(true);
  };

  return (
    <Popup
      fullWidth
      ref={customizeMacrosModalRef}
      maxWidth={'xl'}
      title="Customize Macros Calculator"
      onClose={() => {
        onClose();
        setOpenResults(false);
        setCalculatedFood(undefined);
      }}
    >
      <CustomMacrosGoalsCard isLoading={isLoading || isFetching} targetMacros={foodTargetMacros} onSubmit={onSubmit} />
      {openResult && (calculatedFood || isAthleteFood(food)) && !isLoading && !isFetching && (
        <Box sx={{ mt: 4 }}>
          <CustomizeFoodMacrosResultCard
            newMacros={newMacros}
            food={(calculatedFood ?? food) as unknown as Food}
            deliveryId={deliveryId}
            subscription={subscription}
            refetchDelivery={refetchDelivery}
            positionIndex={food.positionIndex}
          />
        </Box>
      )}
    </Popup>
  );
};

export default CustomizeMacrosPopUp;
