import { CreateGiftReq, ListGiftsFilters, UpdateGiftReq } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';

import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';

export const createSpecialReq = async (values: CreateGiftReq) => {
  const { data, status } = await client.post('/gifts', values);
  ToastSuccessMsgs({ status, action: 'Created successfully' });
  return data;
};

export const updateSpecialRequest = async (payload: UpdateGiftReq & { id: string; kitchen: Kitchen }) => {
  const { id, kitchen, ...rest } = payload;
  const { data, status } = await client.put(`gifts/${kitchen}/id/${id}`, rest);
  ToastSuccessMsgs({ status, action: 'Updated successfully' });
  return { data, status };
};

export const deleteSpecialRequest = async (payload: any & { id: string; kitchen: Kitchen }) => {
  const { id: _id, kitchen, ...rest } = payload;
  const selectedKitchen = kitchen || Kitchen.BH1;
  const deliveryId = `${rest.date}_${rest.deliveryId}`;
  const { data, status } = await client.delete(`gifts/${selectedKitchen}/id/${deliveryId}`);
  ToastSuccessMsgs({ status, action: 'Gift Deleted successfully' });
  return data;
};
export const exportSpecialRequest = async (filters: ListGiftsFilters) => {
  const { data, status } = await client.get('gifts/export', {
    params: filters
  });
  ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
  return data;
};
