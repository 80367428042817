import { Permission } from '@calo/dashboard-types';
import { FormOperation } from 'lib/enums';

export const getComponentPermission = (roles: any, operation: FormOperation, prototype?: boolean) => {
  if (operation === FormOperation.update) {
    return prototype ? roles.includes(Permission.UPDATE_PROTOTYPE_COMPONENT) : roles.includes(Permission.UPDATE_FOOD_COMPONENTS);
  } else {
    return prototype ? roles.includes(Permission.CREATE_PROTOTYPE_COMPONENT) : roles.includes(Permission.CREATE_FOOD_COMPONENTS);
  }
};
