import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { createCustomMealConfigs } from 'lib/helpers';
import { CustomComponentsByCategory, Food, FoodComponent, MenuFood } from 'lib/interfaces';
import { useState } from 'react';

import { CustomMealCategory, Localized } from '@calo/types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack, Typography } from '@mui/material';

import CustomMealForm from './CustomMealForm';

interface FoodPickerProps {
  customFood?: Food | null;
  isLoading: boolean;
  customMealAction: string;
  customFoodComps: CustomComponentsByCategory;
  closeHandler: () => void;
  handleUpdateCustomFood: (
    value: MenuFood,
    customName: Localized,
    mealDescription: Localized,
    customMealCategory: CustomMealCategory
  ) => void;
}

const CustomFoodPopup = ({
  customMealAction,
  isLoading,
  customFood,
  customFoodComps,
  closeHandler,
  handleUpdateCustomFood
}: FoodPickerProps) => {
  const [customFoodName, setCustomFoodName] = useState<Localized>({ en: '', ar: '' });
  const [mealDescription, setMealDescription] = useState<Localized>({ en: '', ar: '' });
  const [customMealCategory, setCustomMealCategory] = useState<CustomMealCategory | undefined>(customFood?.category);
  const [isFinished, setIsFinished] = useState(false);
  const getCreateCustomMealPopupTitle = () => {
    let title = 'Build your own';
    if (!customMealCategory) {
      return title;
    }
    const config = createCustomMealConfigs.find((config) => config.category === customMealCategory);

    if (config) {
      title += ` ${config.label.toLowerCase()} ${config.emoji}`;
    }

    return title;
  };

  if (isLoading) {
    return (
      <Stack>
        <CaloLoader />
      </Stack>
    );
  }

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Stack sx={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
          {isFinished && (
            <Button
              variant="text"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => setIsFinished(false)}
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 600,
                color: caloTheme.palette.neutral900,
                ':hover': {
                  backgroundColor: 'transparent'
                },
                '.MuiButton-startIcon': {
                  marginRight: '4px'
                },
                '.MuiSvgIcon-root': {
                  fontSize: '12px'
                }
              }}
            >
              Back
            </Button>
          )}
          <Typography sx={{ fontSize: '24px', fontWeight: 500 }}>
            {customMealAction === 'update' ? 'Update Custom Meal' : getCreateCustomMealPopupTitle()}
          </Typography>
        </Stack>

        <IconButton onClick={closeHandler}>
          <CloseIcon sx={{ color: caloTheme.palette.secondaryBlue700 }} />
        </IconButton>
      </Stack>
      {!customMealCategory && (
        <Stack sx={{ flexDirection: 'row', gap: 7, marginX: '40px' }}>
          {createCustomMealConfigs.map((config) => (
            <Stack key={config.category} sx={{ alignItems: 'center', justifyContent: 'center' }}>
              <Button
                sx={{
                  fontSize: '52px',
                  borderRadius: '50px',
                  padding: 0,
                  width: '90px',
                  ':hover': {
                    backgroundColor: 'transparent'
                  }
                }}
                onClick={() => setCustomMealCategory(config.category)}
              >
                {config.emoji}
              </Button>
              <Typography sx={{ fontSize: '12px', fontWeight: 700, marginTop: '-12px' }}>{config.label}</Typography>
            </Stack>
          ))}
        </Stack>
      )}
      {customMealCategory && (
        <CustomMealForm
          customFood={customFood}
          isFinished={isFinished}
          customFoodName={customFoodName}
          mealDescription={mealDescription}
          customMealAction={customMealAction}
          customMealCategory={customMealCategory}
          setIsFinished={setIsFinished}
          setCustomFoodName={setCustomFoodName}
          setMealDescription={setMealDescription}
          handleUpdateCustomFood={handleUpdateCustomFood}
          componentsByCategory={customFoodComps[customMealCategory]?.filter((comp: FoodComponent) =>
            (comp.sections ?? []).find((section) => section.availability)
          )}
        />
      )}
    </>
  );
};
export default CustomFoodPopup;
