import { useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { InvitationCodeUserStatsFilters, Permission } from '@calo/dashboard-types';

import { Brand } from '@calo/types';
import { createCodesAllUsers, getListWithParams, getRecord } from 'actions';
import mutation from 'actions/mutation';
import { Button, Pagination, Select2 } from 'components';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import { InvitationUser } from 'lib/interfaces';
import Settings from '../Settings';
import Stats from '../Stats';
import InvitationStatsRow from './InvitationStatsRow';

interface InvitationStatsListProps {
  data: InvitationUser[];
  meta: {
    limit: number;
    total: number;
  };
}

const InvitationStatsList = () => {
  const options: string[] = [];
  const location = useLocation();
  const [val, setVal] = useState(0);
  const [page, setPage] = useState(0);
  const searchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState<InvitationCodeUserStatsFilters>({
    ...JSON.parse(searchParams.get('filters') || `{}`),
    brand: Brand.CALO
  });
  const { mutateAsync: createCodesAllUsersMutation } = useMutation(createCodesAllUsers);
  for (let i = 0; i <= 25; i++) {
    options.push(`${i}`);
  }

  const { data } = useQuery<any, Error, InvitationStatsListProps>(
    ['stats/user-invitation-codes', { page, filters, limit: 50 }],
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  const InvitationsCodeData = data?.data as InvitationUser[];

  const { data: stats } = useQuery(['stats', 'invitation-codes'], getRecord);
  const InvitationStats = stats as { generated: number; claimed: number };
  const roles = useUserRoles();

  const handleNewCodes = async (values: number) => {
    await createCodesAllUsersMutation(values, {
      onSuccess: async (data) => {
        mutation(['stats', 'invitation-codes'], {
          generated: InvitationStats.generated + data.total,
          claimed: InvitationStats.claimed
        });
        for (const row of InvitationsCodeData || []) {
          mutation(['stats/user-invitation-codes', row.user.id], {
            row: [row, (row.generated = +(row.generated + +values))]
          });
        }
      },
      onSettled: () => {
        setVal(0);
      }
    });
  };

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Invitation code stats</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="my-6">{roles.includes(Permission.VIEW_INVITATION_CODE_STATS) && <Stats />}</div>
      <section>
        <div className="level -mt-16">
          <div className="level-right"></div>
          <div className="flex flex-row">
            {roles.includes(Permission.CREATE_BATCH_INVITATION_CODE) && (
              <>
                <Select2
                  options={options.map((type) => ({
                    value: +type,
                    label: type
                  }))}
                  className="w-auto"
                  onChange={(e: any) => setVal(e.target.value)}
                  value={val}
                />
                <Button
                  content="Send to everyone"
                  onClick={() => handleNewCodes(val)}
                  className="hover:text-white hover:shadow-md focus:text-white ml-4 bg-yellow-400 text-white border-yellow-400 hover:border-yellow-400"
                />
              </>
            )}
          </div>
        </div>
      </section>
      <section>
        {roles.includes(Permission.VIEW_INVITATION_CODE_USER_STATS) && (
          <div className="card has-table has-table-container-upper-radius">
            <div className="card-content">
              <div className="table-container">
                <table className="table is-fullwidth  is-hoverable is-sortable">
                  <thead>
                    <tr className="bg-black">
                      <th style={{ color: 'white' }}>User</th>
                      <th style={{ color: 'white' }}>Invitations</th>
                      <th style={{ color: 'white' }}>Claimed</th>
                      <th style={{ color: 'white' }}>Not used</th>
                      <th style={{ color: 'white' }}>Country</th>
                      <th style={{ color: 'white' }}>Kitchen</th>
                      <th style={{ color: 'white' }}>Phone number</th>
                      <th style={{ color: 'white' }}>History</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{InvitationsCodeData?.map((r) => <InvitationStatsRow key={r.user.id} user={r} />)}</tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <Settings onFilter={setFilters} filters={filters} />
        {data && (
          <Pagination
            isHidden={data.data.length === 0 || data.meta.total < 50}
            limit={50}
            total={data.meta.total}
            page={page}
            onChange={setPage}
          />
        )}
      </section>
    </>
  );
};

export default InvitationStatsList;
