import { useEffect, useRef, useState } from 'react';

import { getRecord } from 'actions';
import { deleteKDSUser, resetKDSPassword, updateKDSUser } from 'actions/kdsUsers';
import { Button, ConfirmationModal, Input, Modal, ModalRef } from 'components';
import { useKDSPermissions } from 'hooks';
import { Routes } from 'lib/enums';
import { generatePassword } from 'lib/helpers';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import { KDSUser } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { KDSUserUpdateReq, Permission } from '@calo/dashboard-types';

import UserForm from './UserForm';

const ExactKDSUser = () => {
  const { id } = useParams<{ id: string }>();
  const roles = useUserRoles();
  const confirmModalRef = useRef<ModalRef>();
  const resetModalRef = useRef<ModalRef>();
  const [newPassword, setNewPassword] = useState<string>('');
  const kdsUserGroup = useKDSPermissions();

  const { data } = useQuery(['kitchen-users', id], getRecord, {
    suspense: true
  });
  const user = data as KDSUser;

  const { mutateAsync: updateMutation } = useMutation(updateKDSUser);
  const { mutateAsync: deleteMutation } = useMutation(deleteKDSUser);

  const handleUpdateUser = async (values: KDSUserUpdateReq) => {
    const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);
    await updateMutation({ id, ...values, phoneNumber: phoneNumber?.number as string });
  };

  const handleDelete = async (userId: string) => {
    await deleteMutation(userId, {
      onSuccess: () => {
        queryClient.removeQueries(['kitchen-users', id]);
        history.push(Routes.kdsUsersList);
      }
    });
  };

  const handleResetPassword = async (userId: string, newPassword: string) => {
    await navigator.clipboard.writeText(newPassword);
    await resetKDSPassword({ userId, newPassword });
    resetModalRef.current?.close();
  };

  useEffect(() => {
    const tempPass = generatePassword();
    setNewPassword(tempPass);
  }, []);

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  <Link to={Routes.kdsUsersList}>Kitchen Users</Link>
                </li>
                <li>{user!.name}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="card">
        <header className="card-header bg-black">
          <p className="card-header-title text-white">
            Kitchen User Info
            {roles.includes(Permission.DELETE_KDS_USER) && (
              <Button icon="fas fa-trash" onClick={() => confirmModalRef.current?.open()} className="ml-4 w-4 h-auto" danger />
            )}
          </p>
          <span>
            {roles.includes(Permission.RESET_KDS_USER_PASSWORD) && (
              <Button
                content="Reset password"
                onClick={() => resetModalRef.current?.open()}
                className="m-4 h-auto text-white"
                warning
              />
            )}
          </span>
        </header>
        <div className="card-content overflow-visible">
          <UserForm user={user!} onSubmit={handleUpdateUser} kdsUserGroup={kdsUserGroup} />
        </div>
      </div>
      <ConfirmationModal ref={confirmModalRef} values={user} action={(user) => handleDelete(user.id)}>
        <div className="mt-4 mb-4 ml-4">
          <span className="flex">
            <label className="label">Deleting user: </label>
            <p className="ml-2">{user.name}</p>
          </span>
        </div>
      </ConfirmationModal>
      <Modal ref={resetModalRef}>
        <div className="flex flex-col">
          <Input disabled label="Temp password" value={newPassword} onChange={() => setNewPassword(newPassword)} />
          <Button warning content="Copy" onClick={() => handleResetPassword(user.id, newPassword)} />
        </div>
      </Modal>
    </>
  );
};

export default ExactKDSUser;
