import DateFnsAdapter from '@date-io/date-fns';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Box, Button, MenuItem, Stack, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { caloTheme } from 'assets/images/theme/calo';
import { InputMUI, ModalRef } from 'components';
import Popup from 'components/Popup';
import { format } from 'date-fns/fp';
import { ComplaintReason } from 'lib/enums';
import { complaintsReasonsHelper } from 'lib/helpers/complaintsReasons';
import { keyBy, startCase } from 'lodash-es';
import { useMemo } from 'react';

interface EditComplaintPopupPrpos {
  editComplaintRef: React.MutableRefObject<ModalRef | undefined>;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedComplaint: any;
  setSelectedComplaint: React.Dispatch<any>;
  availableMeals: any[];
  componentMethodImageRef: React.MutableRefObject<ModalRef | undefined>;
  handleUpdateData: (data: any) => Promise<void>;
}
const EditComplaintPopup = ({
  handleUpdateData,
  componentMethodImageRef,
  availableMeals,
  editComplaintRef,
  setSelectedComplaint,
  selectedComplaint,
  handleFileChange
}: EditComplaintPopupPrpos) => {
  const subComplaintOptions = complaintsReasonsHelper.getSelectableOptions();
  const complaintOptions = Object.entries(ComplaintReason)
    .filter(([_, value]) => value in subComplaintOptions)
    .map(([key, value]) => ({
      value: key,
      label: startCase(value)
    }));

  const availableMealsOptions = useMemo(() => {
    return availableMeals.map((meal) => ({
      value: meal.id,
      label: meal.name.en
    }));
  }, [availableMeals]);

  const handleOpenAttachmentFile = () => {
    if (selectedComplaint?.attachment) {
      componentMethodImageRef.current?.open();
    }
  };

  const formatSelectedMeal = (id: string) => {
    const keyedMeals = keyBy(availableMeals, 'id');
    let meal;
    if (keyedMeals[id]) {
      meal = {
        id: keyedMeals[id].id,
        name: keyedMeals[id].name?.en || '',
        size: keyedMeals[id].size || '',
        slug: keyedMeals[id].slug || ''
      };
    }
    return meal;
  };

  return (
    <Popup
      maxWidth="lg"
      fullWidth
      title="Edit"
      ref={editComplaintRef}
      onClose={() => {
        editComplaintRef.current?.close();
        setSelectedComplaint(undefined);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          padding: 2,
          zIndex: 0
        }}
      >
        <InputMUI
          debounce
          label="User"
          sx={{ zIndex: 0, width: '90%' }}
          name="User"
          value={selectedComplaint?.user?.name}
          disabled
          onChange={(data: any) =>
            setSelectedComplaint({ ...selectedComplaint, user: { ...selectedComplaint.user, name: data.target.value } })
          }
          inputProps={{
            inputProps: { style: { borderRadius: 8, zIndex: 0 } },
            style: { borderRadius: 8, height: '51px', zIndex: 0 }
          }}
        />
        <Stack sx={{ mx: 2, width: 'full', maxHeight: '48px', justifyContent: 'end' }}>
          <LocalizationProvider dateAdapter={DateFnsAdapter}>
            <DesktopDatePicker
              inputFormat="dd/MM/yyyy"
              label="Select Date"
              disabled
              value={selectedComplaint?.date || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    style: {
                      height: '51px'
                    }
                  }}
                />
              )}
              onChange={(endDate: any) =>
                setSelectedComplaint({
                  ...selectedComplaint,
                  date: endDate === null ? null : format('yyyy-MM-dd')(endDate as Date)
                })
              }
              maxDate={Date.now()}
              InputProps={{ style: { height: '51px', borderRadius: '8px' } }}
            />
          </LocalizationProvider>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          padding: 2,
          zIndex: 0
        }}
      >
        <Stack width={'33%'} sx={{ mr: 2 }}>
          <TextField
            select
            name="ComplaintType"
            sx={{ width: '100%', zIndex: 0 }}
            label="Complaint Type"
            value={selectedComplaint?.category || null}
            onChange={(data: any) => {
              setSelectedComplaint({ ...selectedComplaint, category: data.target.value });
            }}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {complaintOptions.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                {startCase(category.label)}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack width={'33%'} sx={{ mr: 2 }}>
          <TextField
            select
            name="Complaint"
            sx={{ width: '100%', zIndex: 0 }}
            label="Complaint"
            value={selectedComplaint?.type || null}
            onChange={(data: any) => {
              setSelectedComplaint({ ...selectedComplaint, type: data.target.value });
            }}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {/* @ts-ignore */}
            {((selectedComplaint?.type && subComplaintOptions[ComplaintReason[selectedComplaint.category]]) || []).map(
              (complaint) => (
                <MenuItem key={complaint.value} value={complaint.value}>
                  {startCase(complaint.label)}
                </MenuItem>
              )
            )}
          </TextField>
        </Stack>
        <Stack width={'34%'} sx={{ mr: 2 }}>
          <TextField
            select
            id={selectedComplaint?.meal?.id}
            name="Meal"
            sx={{ width: '100%', zIndex: 0 }}
            label="Meal"
            value={selectedComplaint?.meal?.id || ''}
            onChange={(data: any) => {
              setSelectedComplaint({ ...selectedComplaint, meal: formatSelectedMeal(data.target.value) });
            }}
            disabled={availableMeals.length === 0}
            InputProps={{
              inputProps: { style: { borderRadius: 8 } },
              style: { borderRadius: 8 }
            }}
          >
            {/* @ts-ignore */}
            {((availableMealsOptions && availableMealsOptions) || []).map((meal) => (
              <MenuItem key={meal.value} value={meal.value}>
                {startCase(meal.label)}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', padding: 2 }}>
        <Stack width={'65%'}>
          <TextField
            label="Comment"
            sx={{ zIndex: 0 }}
            name="comment"
            value={selectedComplaint?.comments ? selectedComplaint?.comments?.[0]?.text || '' : ''}
            placeholder="Add Comment"
            onChange={(data: any) =>
              setSelectedComplaint({
                ...selectedComplaint,
                comments: [{ ...(selectedComplaint.comments?.[0] || ''), text: data.target.value }]
              })
            }
            inputProps={{
              inputProps: { style: { borderRadius: 8, zIndex: 0 } },
              style: { borderRadius: 8, zIndex: 0 }
            }}
          />
        </Stack>
        <Button
          variant="outlined"
          sx={{
            display: 'flex',
            height: '51px',
            fontWeight: 600,
            lineHeight: '23px',
            fontSize: '14px',
            borderRadius: '8px',
            fontFamily: 'Roboto',
            textTransform: 'none',
            boxShadow: 'none',
            color: caloTheme.palette.primary500,
            borderColor: caloTheme.palette.primary500,
            '&:hover': {
              boxShadow: 'none',
              color: caloTheme.palette.primary600,
              borderColor: caloTheme.palette.primary600
            },
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              display: 'flex',
              justifyItems: 'center',
              m: 'auto',
              mb: 2,
              mt: '-4px',
              width: 'auto'
            }
          }}
          onClick={() => (selectedComplaint?.attachments.length > 0 ? handleOpenAttachmentFile() : '')}
          startIcon={
            selectedComplaint?.attachments.length > 0 ? <></> : <AttachFileIcon style={{ transform: 'rotate(45deg)' }} />
          }
        >
          {selectedComplaint?.attachments.length === 0 ? (
            <>
              <input
                accept="image/*"
                type="file"
                size={2}
                multiple
                className="file-input fas fa-paperclip mt-2 cursor-pointer float-right "
                onChange={(e) => {
                  handleFileChange(e);
                  handleOpenAttachmentFile();
                }}
              />
              Attach File
            </>
          ) : (
            'View Attached Files'
          )}
        </Button>
      </Box>

      <Box>
        <Stack
          sx={{
            justifyContent: 'center',
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
            marginTop: 2
          }}
        >
          <Button
            variant="text"
            sx={{
              width: '156px',
              color: caloTheme.palette.neutral400,
              fontFamily: 'Roboto',
              textAlign: 'center',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '23px',
              textTransform: 'capitalize'
            }}
            onClick={() => {
              editComplaintRef.current?.close();
            }}
          >
            Close
          </Button>
          <Button
            variant="text"
            sx={{
              width: '156px',
              color: caloTheme.palette.primary500,
              fontFamily: 'Roboto',
              textAlign: 'center',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '23px',
              textTransform: 'capitalize'
            }}
            onClick={() => {
              handleUpdateData(selectedComplaint);
              editComplaintRef.current?.close();
            }}
          >
            Update
          </Button>
        </Stack>
      </Box>
    </Popup>
  );
};

export default EditComplaintPopup;
