import { useEffect } from 'react';

import { createFoodPackage } from 'actions';
import { Routes } from 'lib/enums';
import { CreateFoodPackageReq } from 'lib/interfaces';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import PackageHeaderCard from './PackageHeaderCard';
import PackageInformationCard from './PackageInformationCard';
import usePackageForm from './usePackageForm';

const NewFoodPackages = () => {
  const { mutateAsync: createMutation } = useMutation(createFoodPackage);
  const history = useHistory();

  const handleNewPackages = async (values: CreateFoodPackageReq) => {
    const packages = await createMutation(values);
    history.replace(Routes.package.replace(':id', packages.id));
  };
  const { handleSubmit, values, handleBlur, isSubmitting, isValid, dirty, setFieldValue, setValues, errors, touched } =
    usePackageForm(handleNewPackages);

  useEffect(() => {
    setFieldValue('elements', []);
  }, [values.country, values.brand, values.kitchen]);

  return (
    <>
      <PackageHeaderCard dirty={dirty} isValid={isValid} isSubmitting={isSubmitting} handleSubmit={handleSubmit} />
      <PackageInformationCard
        errors={errors}
        values={values}
        touched={touched}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        setValues={setValues}
      />
    </>
  );
};

export default NewFoodPackages;
