import { CreateFoodComponentReq, FoodComponentMethodStep, UpdateFoodComponentReq } from '@calo/dashboard-types';
import { Button } from '@mui/material';
import { getImageUploadLink } from 'actions';
import { useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router';
import ComponentMethodRow from './ComponentMethodRow';
import { styles } from './styles';

interface ComponentMethodProps {
  values: UpdateFoodComponentReq | CreateFoodComponentReq;
  isEdit: boolean;
  disabled: boolean;
  uploadImages: boolean;
  setValues: (v: any) => void;
  setIsEdit: (value: boolean) => void;
  setFieldValue: (value: any) => void;
}

const ComponentMethod = ({
  isEdit,
  setIsEdit,
  setFieldValue,
  disabled,
  uploadImages,
  values,
  setValues
}: ComponentMethodProps) => {
  const { id } = useParams<{ id: string }>();
  const [selected, setSelected] = useState<FoodComponentMethodStep & { index: number }>({
    description: '',
    attachment: '',
    station: '',
    index: -1
  });

  const updateMethodStep = (description: string, index: number, attachment?: string, station?: string) => {
    const tempData = [...(values.method ?? [])];
    const step = tempData[index];
    if (step) {
      tempData[index] = {
        ...step,
        description: description,
        attachment: attachment,
        station: station
      };
      setValues({
        ...values,
        method: tempData
      });
      setSelected({ ...tempData[index], index });
      setIsEdit(true);
    } else {
      console.error('Step not found at index:', index);
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const reorderedItems = [...(values.method ?? [])];
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setValues({
      ...values,
      method: reorderedItems
    });
    setFieldValue(reorderedItems);

    if (selected.index === result.source.index) {
      setSelected({ ...movedItem, index: result.destination.index });
    }
  };

  const handleChangeMethod = (data: FoodComponentMethodStep) => {
    const currentMethod = values.method || [];
    const newMethod = [
      ...currentMethod,
      {
        description: data.description,
        attachment: data.attachment,
        station: data.station
      }
    ];
    setValues({ ...values, method: newMethod });
    setIsEdit(true);
  };

  const setActiveSelected = (r: FoodComponentMethodStep, index: number) => {
    const newSelected = {
      description: r.description,
      attachment: r.attachment,
      station: r.station,
      index: index
    };
    setSelected(newSelected);
    setIsEdit(true);
  };

  const handleEditMethod = () => {
    const tempData = [...(values.method ?? [])];
    setValues({
      ...values,
      method: tempData
    });
    setIsEdit(false);
  };

  return (
    <div className="card has-table">
      <div className="card-content">
        <DragDropContext onDragEnd={handleDragEnd}>
          <table className="table is-fullwidth is-striped is-hoverable is-sortable">
            <Droppable droppableId="droppable">
              {(provider) => (
                <tbody className="text-capitalize" ref={provider.innerRef} {...provider.droppableProps}>
                  {values.method?.map((step: FoodComponentMethodStep, index) => (
                    <ComponentMethodRow
                      isEdit={isEdit}
                      values={values}
                      disabled={disabled}
                      selected={selected}
                      setValues={setValues}
                      updateMethodStep={updateMethodStep}
                      setSelected={setSelected}
                      step={{ ...step, index }}
                      uploadImages={uploadImages}
                      setActiveSelected={setActiveSelected}
                      uploadLink={() => getImageUploadLink(`food-component/${id}/method/step/${index}`)}
                      image={`${process.env.REACT_APP_BUCKET_URL}/food-component/${id}/method/step/${index}/square@1x.jpg`}
                    />
                  ))}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      </div>

      {!disabled && (
        <div className="mt-4 text-center">
          <Button
            data-test="addStepButton"
            variant="outlined"
            sx={styles.add}
            onClick={() => handleChangeMethod({ description: '', attachment: '', station: '' })}
          >
            Add Step
          </Button>
          {isEdit && (
            <Button data-test="doneStepButton" variant="contained" sx={styles.done} onClick={handleEditMethod}>
              Done
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default ComponentMethod;
