import { Delivery } from 'lib/interfaces';
import { isEmpty, some } from 'lodash-es';
import { checkNonEmpty } from '.';

export const hasGifts = (delivery: Delivery, giftType?: string): boolean => {
  if (isEmpty(delivery.giftedItems)) {
    return false;
  }

  if (giftType) {
    const item = delivery.giftedItems[giftType];
    return item ? checkNonEmpty(item) : false;
  }

  return some(delivery.giftedItems, (item) => checkNonEmpty(item));
};
