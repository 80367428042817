import { SxProps } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

export const styles: Record<string, SxProps> = {
  root: {
    height: '70vh',
    overflow: 'auto'
  },
  table: {
    minHeight: '120px',
    overflowY: 'auto',
    width: '100%',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  tableHead: {
    backgroundColor: caloTheme.palette.neutral50,
    color: 'black',
    flexWrap: 0,
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '8px'
  },
  tableRow: {
    backgroundColor: caloTheme.palette.neutral50,
    color: 'black',
    width: '100%',
    flexWrap: 0,
    justifyContent: 'space-between'
  },
  tableCell: {
    width: '20%',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px'
  },
  noStatsCell: {
    border: 0
  },
  tooltip: {
    borderRadius: 10,
    padding: 0.2,
    ':hover': { cursor: 'pointer' }
  },
  button: {
    color: caloTheme.palette.hyperlink,
    textTransform: 'capitalize',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px',
    paddingLeft: 0,
    textAlign: 'left'
  },
  centeredText: {
    mt: 3,
    textAlign: 'center',
    width: 'full',
    fontSize: '24px',
    color: caloTheme.palette.neutral400
  },
  text: {
    color: 'black',
    fontSize: '12px',
    lineHeight: '14px',
    fontFamily: 'Roboto',
    fontWeight: 600
  },
  measurementUnitText: { width: '10%', borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }
};
