import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { updateAiPrompts } from 'actions/aiDashboard';
import { ToastSuccessMsgs } from 'lib/helpers';
import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import { sanitizePromptText } from 'views/AiDashboard/utils';

const EditDialogBox = ({
  currentValue,
  setCurrentValue,
  currentKey,
  isOpen,
  setIsOpen,
  updatePrompts
}: {
  currentValue: string;
  setCurrentValue: (value: React.SetStateAction<string>) => void;
  currentKey: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  updatePrompts: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleSave = async () => {
    try {
      setIsLoading(true);
      await updateAiPrompts({ [currentKey]: sanitizePromptText(currentValue) });
      ToastSuccessMsgs({ status: 200, action: 'Prompt updated' });
    } catch {
      toast(`Prompt update failed`, { type: 'error', autoClose: 2000 });
    } finally {
      setIsLoading(false);
      handleClose();
      updatePrompts();
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{currentKey}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          multiline
          rows={10}
          value={currentValue}
          onChange={(e) => setCurrentValue(e.target.value)}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <ReactLoading type="bubbles" color="#57AE7F" width={80} height={80} />
        ) : (
          <div className="flex flew-row justify-center">
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSave} variant="contained">
              Save
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditDialogBox;
