import { Typography } from '@mui/material';
import { Menu } from 'lib/interfaces';
import { styles } from './styles';

interface MealSizeProps {
  foodName: string;
  menu: Menu;
}

const MealSize = ({ foodName, menu }: MealSizeProps): JSX.Element[] =>
  menu.food
    .filter((food) => food.name.en === foodName)
    .map((food, index) => (
      <Typography key={index} sx={styles.mealSizeText}>
        {`${food.size}${index === menu.food.length - 1 ? '' : '-'}`}
      </Typography>
    ));

export default MealSize;
