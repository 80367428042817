import {
  addDays,
  addWeeks,
  endOfWeek as endOfWeekfns,
  isWithinInterval,
  setISOWeek,
  startOfWeek as startOfWeekfns,
  subWeeks
} from 'date-fns';
import { endOfWeek, format, startOfWeek } from 'date-fns/fp';
interface DateOfWeekProps {
  week: number;
  year: number;
}

export const dateOfWeek = ({ week, year }: DateOfWeekProps) => {
  const date = new Date(year, 0, 1 + week * 7);
  const weekDate = date;
  weekDate.setDate(date.getDate() - 7);
  return `${format('yyyy-MM-dd')(startOfWeek(weekDate))} - ${format('yyyy-MM-dd')(endOfWeek(weekDate))}`;
};

export const handleWeekFormat = (week: number) => {
  const weekNumber = week.toString().split('#');
  const date = new Date(+weekNumber[0], 0, 1 + +weekNumber[1] * 7);
  const weekDate = date;
  weekDate.setDate(date.getDate() - 7);
  return `${format('eee dd/MM/yyyy')(startOfWeek(weekDate))} - ${format('eee dd/MM/yyyy')(endOfWeek(weekDate))}`;
};

export const findStartAndEndDateForWeek = (weekNumber: number, year?: number) => {
  const currentYear = year ?? new Date().getFullYear();
  const startDate = startOfWeekfns(new Date(currentYear, 0, 1), { weekStartsOn: 0 });
  const targetDate = addWeeks(startDate, weekNumber - 1);
  const startOfWeekDate = startOfWeekfns(targetDate, { weekStartsOn: 0 });
  const endOfWeekDate = endOfWeekfns(targetDate, { weekStartsOn: 0 });
  return { startOfWeekDate, endOfWeekDate };
};
export const isWithinWeeks = (date: Date, weeks: number) => {
  const now = new Date();
  const twoWeeksAgo = subWeeks(now, weeks);

  return isWithinInterval(date, { start: twoWeeksAgo, end: now });
};

export const isDateInSelectedWeek = (date: Date, selectedWeek?: number) => {
  if (!date || !selectedWeek) {
    return false;
  }

  const year = new Date().getFullYear();
  const start = startOfWeekfns(setISOWeek(new Date(year, 0, 1), selectedWeek), { weekStartsOn: 0 });
  const end = endOfWeekfns(setISOWeek(new Date(year, 0, 1), selectedWeek), { weekStartsOn: 0 });
  return date >= start && date <= end;
};

export const getWeekDates = (weekNumber: number, year: number) => {
  const startOfYear = new Date(year, 0, 1);
  const startOfWeekDate = addDays(startOfYear, (weekNumber - 1) * 7);
  const weekStart = startOfWeekfns(startOfWeekDate, { weekStartsOn: 0 });
  return Array.from({ length: 7 }, (_, i) => addDays(weekStart, i));
};
