import { MeasurementUnit } from '@calo/dashboard-types';
import { Style } from 'exceljs';
import { IngredientComponentStats, IngredientStats } from 'lib/interfaces';
import { Dictionary, keyBy, sortBy, sum } from 'lodash';

interface GroupedIngredientData {
  [key: string]: IngredientStats;
}

export const grouppedAllIngredients = (selectedStatsData: IngredientStats[]) => {
  const groupedData: GroupedIngredientData = {};
  for (const item of selectedStatsData) {
    if (groupedData[item.id]) {
      groupedData[item.id] = {
        ...item,
        keyedComponents: groupKeyedComponents(item, groupedData[item.id]),
        quantity:
          groupedData[item.id].quantity + Math.ceil(item.quantity * item.wastage - getChildQuantity(item.keyedComponents)),
        specialRequestsQuantity:
          groupedData[item.id].specialRequestsQuantity + Math.ceil(item.specialRequestsQuantity * item.wastage),
        removedQuantity: groupedData[item.id].removedQuantity + Math.ceil(item.removedQuantity * item.wastage),
        childQuantityBeforePrep: groupedData[item.id].childQuantityBeforePrep + getChildQuantity(item.keyedComponents)
      };
    } else {
      groupedData[item.id] = {
        ...item,
        quantity: Math.ceil(item.quantity * item.wastage - getChildQuantity(item.keyedComponents)),
        removedQuantity: Math.ceil(item.removedQuantity * item.wastage),
        childQuantityBeforePrep: getChildQuantity(item.keyedComponents)
      };
    }
  }
  return groupedData;
};

const getChildQuantity = (keyedComponents: Dictionary<IngredientComponentStats>) => {
  return sum(Object.values(keyedComponents).map((comp) => comp.neededIngredientFromChild ?? 0));
};

const groupKeyedComponents = (ingStats: IngredientStats, groupedIng: IngredientStats) => {
  const newKeyedComponents: Dictionary<IngredientComponentStats> = { ...groupedIng.keyedComponents };
  for (const [compId, ingredientCompStats] of Object.entries(ingStats.keyedComponents)) {
    if (!newKeyedComponents[compId]) {
      newKeyedComponents[compId] = {
        id: ingredientCompStats.id,
        name: ingredientCompStats.name,
        cookedRawFactor: ingredientCompStats.cookedRawFactor,
        childComponentRawWeight: 0,
        neededIngredientFromChild: 0,
        neededIngredientFromParent: 0,
        parentComponentRawWeight: 0,
        removedComponentRawWeight: 0,
        removedNeededIngredient: 0,
        parentComponentSpecialRequestsRawWeight: 0
      };
    }

    const current = newKeyedComponents[compId];
    newKeyedComponents[compId] = {
      ...newKeyedComponents[compId],
      childComponentRawWeight: current.childComponentRawWeight + ingredientCompStats.childComponentRawWeight,
      neededIngredientFromChild: current.neededIngredientFromChild + ingredientCompStats.neededIngredientFromChild,
      neededIngredientFromParent: current.neededIngredientFromParent + ingredientCompStats.neededIngredientFromParent,
      parentComponentRawWeight: current.parentComponentRawWeight + ingredientCompStats.parentComponentRawWeight,
      parentComponentSpecialRequestsRawWeight:
        current.parentComponentSpecialRequestsRawWeight + ingredientCompStats.parentComponentSpecialRequestsRawWeight,
      removedComponentRawWeight: current.removedComponentRawWeight + ingredientCompStats.removedComponentRawWeight,
      removedNeededIngredient: current.removedNeededIngredient + ingredientCompStats.removedNeededIngredient
    };
  }

  const keyedComponentsList = Object.values(newKeyedComponents);
  const sortedList = sortBy(keyedComponentsList, ['name.en']);

  return keyBy(sortedList, 'id');
};

const border: Partial<Style> = {
  border: {
    top: { style: 'thin', color: { argb: 'D3D3D3' } },
    left: { style: 'thin', color: { argb: 'D3D3D3' } },
    bottom: { style: 'thin', color: { argb: 'D3D3D3' } },
    right: { style: 'thin', color: { argb: 'D3D3D3' } }
  }
};

export const columns = (isDetailedView: boolean) => {
  if (isDetailedView) {
    return [
      { width: 30, key: 'name', style: border },
      { width: 30, key: 'internalName', style: border },
      { width: 10, key: 'quantity', style: border },
      { width: 10, key: 'quantityAfterPrep', style: border },
      { width: 10, key: 'specialRequestsQuantity', style: border },
      { width: 10, key: 'specialRequestsQuantityAfterPrep', style: border },
      { width: 10, key: 'subComponentQuantity', style: border },
      { width: 10, key: 'subComponentQuantityAfterPrep', style: border },
      { width: 10, key: 'removedQuantity', style: border },
      { width: 10, key: 'removedQuantityAferPrep', style: border },
      { width: 10, key: 'totalQuantity', style: border },
      { width: 10, key: 'totalQuantityAfterPrep', style: border },
      { width: 4 },
      { width: 30, key: 'name2', style: border },
      { width: 30, key: 'internalName2', style: border },
      { width: 10, key: 'quantity2', style: border },
      { width: 10, key: 'quantityAfterPrep2', style: border },
      { width: 10, key: 'specialRequestsQuantity2', style: border },
      { width: 10, key: 'specialRequestsQuantityAfterPrep2', style: border },
      { width: 10, key: 'subComponentQuantity2', style: border },
      { width: 10, key: 'subComponentQuantityAfterPrep2', style: border },
      { width: 10, key: 'removedQuantity2', style: border },
      { width: 10, key: 'removedQuantityAferPrep2', style: border },
      { width: 10, key: 'totalQuantity2', style: border },
      { width: 10, key: 'totalQuantityAfterPrep2', style: border }
    ];
  }
  return [
    { width: 30, key: 'name', style: border },
    { width: 30, key: 'internalName', style: border },
    { width: 10, key: 'totalQuantity', style: border },
    { width: 10, key: 'totalQuantityAfterPrep', style: border },
    { width: 4 },
    { width: 30, key: 'name2', style: border },
    { width: 30, key: 'internalName2', style: border },
    { width: 10, key: 'totalQuantity2', style: border },
    { width: 10, key: 'totalQuantityAfterPrep2', style: border }
  ];
};

const calculateRow = (quantity: number, ingredient: IngredientStats, afterPrep?: boolean) => {
  const calcualtedQuantity = Math.ceil(quantity / (afterPrep ? ingredient.wastage : 1));
  if (ingredient.measurementUnit === MeasurementUnit.g) {
    return calcualtedQuantity;
  }
  return calcualtedQuantity === 0
    ? 0
    : `${calcualtedQuantity} (${Math.ceil(calcualtedQuantity / (ingredient.weight || 1))} ${ingredient.measurementUnit})`;
};

export const findDisplayWeights = (ingredient: IngredientStats | undefined) => {
  if (!ingredient) {
    return;
  }
  const { quantity, childQuantityBeforePrep, removedQuantity, specialRequestsQuantity } = ingredient;

  return {
    quantity: calculateRow(quantity - specialRequestsQuantity, ingredient),
    quantityAfterPrep: calculateRow(quantity - specialRequestsQuantity, ingredient, true),
    specialRequestsQuantity: calculateRow(specialRequestsQuantity, ingredient),
    specialRequestsQuantityAfterPrep: calculateRow(specialRequestsQuantity, ingredient, true),
    subComponentQuantity: calculateRow(childQuantityBeforePrep, ingredient),
    subComponentQuantityAfterPrep: calculateRow(childQuantityBeforePrep, ingredient, true),
    removedQuantity: calculateRow(removedQuantity, ingredient),
    removedQuantityAferPrep: calculateRow(removedQuantity, ingredient, true),
    totalQuantity: calculateRow(quantity + childQuantityBeforePrep - removedQuantity, ingredient),
    totalQuantityAfterPrep: calculateRow(quantity + childQuantityBeforePrep - removedQuantity, ingredient, true)
  };
};
