import { Modal } from 'components';
import AddAddonTable from '../AddAddonTable';

export interface AddAddonModalProps {
  addonsMenu: any;
  page: number;
  addAddonModalRef: any;
  LoadingAddonsMeals: boolean;
  selectedAddons: any[];
  selectedCountryBrand: any;
  filterName: string | undefined;
  autoSelectSubscribers: boolean | undefined;
  setPage: (value: number) => void;
  setFilterName: (value: string) => void;
  setSelectedAddons: (value: any[]) => void;
}

const AddAddonModal = ({
  page,
  addAddonModalRef,
  addonsMenu,
  selectedCountryBrand,
  setPage,
  filterName,
  LoadingAddonsMeals,
  setSelectedAddons,
  selectedAddons,
  autoSelectSubscribers,
  setFilterName
}: AddAddonModalProps) => (
  <Modal
    ref={addAddonModalRef}
    onClose={() => {
      addAddonModalRef.current?.close();
    }}
  >
    <>
      <section className="section is-title-bar -my-8">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="uppercase font-roboto text-2xl">Choose Addon</p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <p className="uppercase font-roboto text-2xl">
                {selectedCountryBrand.country}-{selectedCountryBrand.brand}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <AddAddonTable
          addonsMenu={addonsMenu}
          page={page}
          setPage={setPage}
          filterName={filterName}
          loadingAddonsMeals={LoadingAddonsMeals}
          setFilterName={setFilterName}
          addAddonModalRef={addAddonModalRef}
          setSelectedAddons={setSelectedAddons}
          selectedAddons={selectedAddons || []}
          selectedCountryBrand={{ country: selectedCountryBrand.country!, brand: selectedCountryBrand.brand! }}
          autoSelectSubscribers={autoSelectSubscribers}
        />
      </section>
    </>
  </Modal>
);
export default AddAddonModal;
