import { Permission } from '@calo/dashboard-types';
import { Localized } from '@calo/types';
import { Stack, TextField, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { useUserRoles } from 'lib/hooks';

interface MealNameCardProps {
  customFoodName: Localized;
  handleNameChange: (data: string) => void;
}

const MealNameCard = ({ customFoodName, handleNameChange }: MealNameCardProps) => {
  const userRoles = useUserRoles();

  return (
    <Stack sx={{ marginTop: '10px' }} className="mb-4">
      <TextField
        label="Meal Name"
        name={customFoodName.en}
        id="name.en"
        placeholder="Enter Meal Name"
        value={customFoodName.en}
        sx={{ width: '100%', mr: 2 }}
        disabled={!userRoles.includes(Permission.UPDATE_DELIVERY_CUSTOM_FOOD)}
        onChange={(data: any) => handleNameChange(data.target.value)}
      />
      {customFoodName.en.length >= 32 && (
        <Typography sx={{ fontSize: '14px', color: caloTheme.palette.secondaryRed200 }}>Maximum characters is 32</Typography>
      )}
    </Stack>
  );
};

export default MealNameCard;
