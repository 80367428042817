import { quickSearch } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Autocomplete } from 'components/index';
import { useUserRoles } from 'lib/hooks';
import { EmployeePerksInfo, EmployeePerksInfoReq } from 'lib/interfaces';
import { startCase } from 'lodash-es';
import { useState } from 'react';

import { Permission } from '@calo/dashboard-types';
import { Country } from '@calo/types';
import { Button, Stack } from '@mui/material';

interface AddEmployeeModalProps {
  handleAddingEmployee: (employees: EmployeePerksInfoReq[]) => void;
  country: Country;
  employeePerksInfo: EmployeePerksInfo[];
}

const AddEmployeeModal = ({ handleAddingEmployee, country, employeePerksInfo }: AddEmployeeModalProps) => {
  const userRoles = useUserRoles();
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const employeePerksIds = employeePerksInfo.map((employee) => employee.id);
  const customStyles2 = {
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999
    }),
    control: (defaultStyle: any) => ({
      ...defaultStyle,
      boxShadow: 'none',
      borderRadius: '8px',
      borderColor: caloTheme.palette.neutral700,
      height: '50px'
    }),
    placeholder: (defaultStyle: any) => ({
      ...defaultStyle,
      maxHeight: '24px',
      color: caloTheme.palette.neutral500
    }),
    indicatorsContainer: () => ({}),
    dropdownIndicator: () => ({
      color: 'white'
    })
  };
  const transformData = (data: any[]) => {
    return data
      .map((row: any) => {
        if (row.type === 'subscription' && row.country === country && !employeePerksIds.includes(row.id)) {
          return {
            value: row.id,
            type: row.type,
            data: row,
            country: row.country,
            kitchen: row.kitchen,
            currency: row.currency,
            phoneNumber: row.phoneNumber,
            email: row.email,
            name: row.name,
            label: `${startCase(row.name)}, ${row.phoneNumber}, ${row.email}, ${row.country}`
          };
        }

        return null;
      })
      .filter(Boolean);
  };
  return (
    <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={2}>
      {userRoles.includes(Permission.VIEW_SEARCH_BAR) && (
        <Stack direction={'column'} justifyContent={'center'} alignItems={'stretch'} spacing={2} sx={{ width: '100%' }}>
          <Autocomplete
            label="Select user"
            onSearch={(text) =>
              quickSearch({
                limit: 10,
                query: text,
                page: 1
              })
            }
            transformer={transformData}
            customStyles={customStyles2}
            placeHolder={true}
            placeHolderValue={selectedCustomer ? selectedCustomer.label : 'Search'}
            onPick={(data) => (data === undefined ? setSelectedCustomer(undefined) : setSelectedCustomer(data))}
            menuPortalTarget={document.body}
          />
        </Stack>
      )}
      <Button
        variant="contained"
        onClick={() =>
          handleAddingEmployee([
            {
              id: selectedCustomer?.value,
              name: selectedCustomer?.name,
              email: selectedCustomer?.email,
              kitchen: selectedCustomer?.kitchen,
              currency: selectedCustomer?.currency
            }
          ])
        }
        sx={{
          width: 135,
          height: 40,
          fontSize: '16px',
          fontWeight: 600,
          textTransform: 'none',
          backgroundColor: caloTheme.palette.primary500,
          boxShadow: 'none',
          borderRadius: '8px',
          color: 'white',
          '&:hover': {
            backgroundColor: caloTheme.palette.primary600,
            boxShadow: 'none'
          }
        }}
      >
        Add Employee
      </Button>
    </Stack>
  );
};

export default AddEmployeeModal;
