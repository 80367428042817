import { exportFood } from 'actions/food';
import { exportFoodComponent } from 'actions/foodComponent';
import { exportIngredient } from 'actions/ingredient';
import { Routes } from 'lib/enums';

export const handleExport = (listType: string, filters: any) => {
  switch (listType) {
    case 'food':
      exportFood(
        {
          orderBy: 'name',
          orderMode: 'asc'
        },
        filters
      );
      break;
    case 'Components':
      exportFoodComponent(filters);
      break;
    case 'Ingredients':
      exportIngredient(filters);
      break;
    default:
      break;
  }
};

export const getAddRoute = (listType: string): string => {
  switch (listType) {
    case 'Ingredients':
      return Routes.newIngredient;
    case 'Components':
      return Routes.newFoodComponent;
    case 'food':
      return Routes.newFood;
    default:
      return '';
  }
};
