import { caloTheme } from 'assets/images/theme/calo';
import { Delivery } from 'lib/interfaces';

import { DeliveryStatus } from '@calo/types';
import { Icon } from '@iconify/react';
import { IconButton } from '@mui/material';

import styles from '../styles';

interface TableRowProps {
  icon: string;
  bgColor: string;
  color: string;
}

export const handleDeliveryStatusIcon = (delivery: Delivery) => {
  if (delivery.skipped) {
    return (
      <StatusIcon icon="lucide:chevrons-right" bgColor={caloTheme.palette.neutral100} color={caloTheme.palette.neutral900} />
    );
  }
  if (delivery.deliveredAt) {
    return <StatusIcon icon="ic:baseline-check" bgColor={caloTheme.palette.primary100} color={caloTheme.palette.primary700} />;
  }
  if (delivery.status === DeliveryStatus.cancelled) {
    return <StatusIcon icon="ph:x" bgColor={caloTheme.palette.secondaryRed100} color={caloTheme.palette.secondaryRed500} />;
  }
  if (delivery.status === DeliveryStatus.paused) {
    return (
      <StatusIcon
        icon="mingcute:pause-line"
        bgColor={caloTheme.palette.secondaryPurple100}
        color={caloTheme.palette.secondaryPurple800}
      />
    );
  }
  if (!delivery.deliveredAt) {
    return (
      <StatusIcon icon="ep:loading" bgColor={caloTheme.palette.secondaryYellow100} color={caloTheme.palette.secondaryYellow800} />
    );
  }
  return null;
};

const StatusIcon = ({ icon, bgColor, color }: TableRowProps) => {
  return (
    <IconButton style={{ ...styles.IconButton, backgroundColor: bgColor }}>
      <Icon icon={icon} style={{ color }} />
    </IconButton>
  );
};

export default StatusIcon;
