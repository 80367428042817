import { Button, Input, Select } from 'components';
import { KDSUser, KDSUserGroup } from 'lib/interfaces';
import { toLower } from 'lodash';
import { uniq } from 'lodash-es';

import { Country, Kitchen } from '@calo/types';

import useUserForm from './useUserForm';

interface UserFormProps {
  onSubmit: (value: Omit<KDSUser, 'id'>) => Promise<void>;
  user: KDSUser;
  kdsUserGroup: KDSUserGroup[];
}

const UserForm = ({ onSubmit, user, kdsUserGroup }: UserFormProps) => {
  const { handleSubmit, values, handleChange, handleBlur, isSubmitting, isValid, dirty, setFieldValue } = useUserForm(
    user,
    onSubmit
  );

  return (
    <form onSubmit={handleSubmit}>
      <Input label="Name" value={values.name} name="name" onChange={handleChange} onBlur={handleBlur} />
      <Input label="Phone number" value={values.phoneNumber} name="phoneNumber" onChange={handleChange} onBlur={handleBlur} />
      <Input
        label="Email"
        value={values.email}
        name="email"
        onChange={(data: any) => setFieldValue('email', toLower(data.target.value))}
        onBlur={handleBlur}
      />
      <Select
        label="Country"
        value={values.country}
        onChange={(data: any) => {
          setFieldValue('country', data.value);
          setFieldValue(
            'kitchen',
            uniq([...values.kitchen, Object.values(Kitchen).find((r) => r.includes(data.value) && !r.includes('000'))])
          );
        }}
        options={Object.values(Country).map((c) => ({
          value: c,
          label: Country[c]
        }))}
      />
      <Select
        label="Kitchen"
        value={values.kitchen}
        onChange={(data: any) =>
          setFieldValue(
            'kitchen',
            data.map((row) => row.value)
          )
        }
        options={Object.values(Kitchen)
          .filter((r) => !r.includes('000'))
          .map((c) => ({
            value: c,
            label: c
          }))}
        isMulti
      />
      <Select
        label="group"
        value={values.group}
        name="group"
        onChange={(data: any) => setFieldValue('group', data.value)}
        options={kdsUserGroup.map((type) => ({
          value: type.id,
          label: type.groupName
        }))}
        onBlur={handleBlur}
      />
      <div>
        <Button type="submit" primary loading={isSubmitting} disabled={!dirty || !isValid || isSubmitting} content="Save" />
      </div>
    </form>
  );
};
export default UserForm;
