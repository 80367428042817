import { ceil, round, startCase } from 'lodash-es';

import { Stack, styled, TableCell, tableCellClasses, TableHead, TableRow, Typography } from '@mui/material';

import { MeasurementUnit } from '@calo/dashboard-types';
import { caloTheme } from 'assets/images/theme/calo';
import { IOSSwitch } from 'components/MUI';
import { StatsFoodComponent } from 'lib/interfaces';
import { getTotalChildComponentQuantity } from '../helpers';

interface TableHeaderProps {
  component: StatsFoodComponent;
  switchUnitDrawer: boolean;
  setSwitchUnitDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TableHeader({ component, switchUnitDrawer, setSwitchUnitDrawer }: TableHeaderProps) {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '19px',
      textTransform: 'capitalize'
    }
  }));
  const HeaderTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      paddingBottom: 4
    }
  }));
  const BodyTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '20px',
      textTransform: 'capitalize',
      fontWeight: 600,
      paddingTop: 4,
      paddingBottom: 8
    }
  }));

  let totalCookedWeight = 0;
  for (const foodItem of Object.values(component.keyedFood)) {
    const weightOrQuantity = switchUnitDrawer ? foodItem.quantity || 0 : foodItem.weight;
    if (!foodItem.components) {
      totalCookedWeight += weightOrQuantity;
    }
  }

  const removedQuantity = Object.values(component.keyedFood).reduce((sum, item) => {
    return sum + (switchUnitDrawer ? item.removedQuantity || 0 : item.removedWeight || 0);
  }, 0);

  return (
    <TableHead
      style={{ borderRadius: '8px' }}
      sx={{
        backgroundColor: caloTheme.palette.neutral50,
        color: 'black',
        flexWrap: 0,
        justifyContent: 'space-between',
        width: '100%',
        borderRadius: '8px'
      }}
    >
      <TableRow
        sx={{
          backgroundColor: caloTheme.palette.white,
          color: 'black',
          width: '100%',
          flexWrap: 0,
          justifyContent: 'space-between'
        }}
      >
        <HeaderTableCell colSpan={2} sx={{ pl: 0.5 }}>
          {switchUnitDrawer ? startCase(component.measurementUnit) : 'Gram'}
        </HeaderTableCell>
        <HeaderTableCell colSpan={1}>Total Cooked Weight</HeaderTableCell>
        <HeaderTableCell colSpan={2}>Total Child Weight</HeaderTableCell>
      </TableRow>
      <TableRow
        sx={{
          backgroundColor: caloTheme.palette.white,
          color: 'black',
          width: '100%',
          flexWrap: 0,
          justifyContent: 'space-between'
        }}
      >
        <BodyTableCell colSpan={2} sx={{ pl: 0 }}>
          <IOSSwitch
            onChange={() => {
              setSwitchUnitDrawer((prev) => !prev);
            }}
            sx={{ color: 'white' }}
            checked={switchUnitDrawer}
            disabled={component.measurementUnit === MeasurementUnit.g}
          />
        </BodyTableCell>
        <BodyTableCell colSpan={1}>
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 600 }}>{ceil(totalCookedWeight - removedQuantity)} </Typography>
            <Typography sx={{ textTransform: 'none', fontWeight: 600 }}>g</Typography>
          </Stack>
        </BodyTableCell>
        <BodyTableCell colSpan={2}>
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 600 }}>
              {round(switchUnitDrawer ? Math.round(getTotalChildComponentQuantity(component)) : component.stats.childWeight, 2)}{' '}
            </Typography>
            <Typography sx={{ textTransform: 'none', fontWeight: 600 }}>
              {switchUnitDrawer ? `${component.measurementUnit}s` : `g`}
            </Typography>
          </Stack>
        </BodyTableCell>
      </TableRow>
      <TableRow
        sx={{
          backgroundColor: caloTheme.palette.neutral50,
          color: 'black',
          width: '100%',
          flexWrap: 0,
          justifyContent: 'space-between'
        }}
      >
        <StyledTableCell sx={{ width: '30%', minWidth: '130px', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
          Meal Name
        </StyledTableCell>
        <StyledTableCell sx={{ width: '10%', minWidth: '120px' }}>Size</StyledTableCell>
        <StyledTableCell sx={{ width: '20%', minWidth: '130px', borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}>
          No. of Meals
        </StyledTableCell>
        <StyledTableCell sx={{ width: '20%', minWidth: '130px', borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}>
          No. of Special Request Meals
        </StyledTableCell>
        <StyledTableCell sx={{ width: '20%', minWidth: '120px' }}>Raw Weight</StyledTableCell>
        <StyledTableCell sx={{ width: '20%', minWidth: '120px' }}>Cooked Weight</StyledTableCell>
        <StyledTableCell sx={{ width: '20%', minWidth: '120px' }}>Removed Weight</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}
