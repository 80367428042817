import { useState } from 'react';

import { startCase } from 'lodash-es';
import { useMutation } from 'react-query';

import { Permission, Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { ActivityLevel, Goal } from '@calo/types';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';

import { updateSubscription } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import { useUserRoles } from 'lib/hooks';
import useBiometricsForm from './useBiometricsForm';

interface BiometricsCardProps {
  subscription: Subscription;
}
const BiometricsCard = ({ subscription }: BiometricsCardProps) => {
  const roles = useUserRoles();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { mutateAsync: updateMutation } = useMutation(updateSubscription);

  const onSubmit = async (data: UpdateSubscriptionReq) => {
    await updateMutation({
      id: subscription.id,
      ...data
    });
  };

  const { values, handleBlur, setFieldValue, setValues, initialValues } = useBiometricsForm(subscription, onSubmit);

  const handleEditing = (value: boolean) => {
    if (values.macrosData !== subscription.macrosData) {
      onSubmit({ ...values });
    }
    setIsEditing(value);
  };

  const handleReset = () => {
    setValues(initialValues);
    setIsEditing(false);
  };

  return (
    <>
      <Box
        sx={{
          margin: 2,
          padding: 2,
          backgroundColor: caloTheme.palette.neutral50,
          borderRadius: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'auto',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column'
          }
        }}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '19px',
            lineHeight: '23px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Biometrics
        </Typography>
        {isEditing ? (
          <Stack sx={{ justifyContent: 'end', flexDirection: 'row' }}>
            <CheckIcon sx={{ marginRight: 2 }} style={{ cursor: 'pointer' }} onClick={() => handleEditing(!isEditing)} />
            <ClearIcon style={{ cursor: 'pointer' }} onClick={handleReset} />
          </Stack>
        ) : (
          <Icon
            onClick={() => handleEditing(!isEditing)}
            name="edit2"
            size={6}
            style={{ cursor: 'pointer', width: '26px', height: '26px' }}
          />
        )}
      </Box>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          width: 'full',
          mx: 2,
          '& .MuiTextField-root': { my: 2, mx: 2 },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'full',
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTextField-root': { my: 2, mx: 1, width: '100%', justifyContent: 'space-between' }
          }
        }}
      >
        <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <TextField
            name="height"
            label="Height"
            placeholder="Enter Height"
            id="exact-subscription-height"
            value={values.macrosData?.height}
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
            onChange={(data) => setFieldValue('macrosData.height', data.target.value)}
            inputProps={{
              inputProps: { style: { borderRadius: 8 } },
              style: { borderRadius: 8 },
              endAdornment: <InputAdornment position="end">cm</InputAdornment>
            }}
          />
          <TextField
            label="Weight"
            name="macrosData.weight"
            placeholder="Enter Weight"
            id="exact-subscription-weight"
            value={values.macrosData?.weight}
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
            onChange={(data) => setFieldValue('macrosData.weight', data.target.value)}
            inputProps={{
              inputProps: { style: { borderRadius: 8 }, min: 100, max: 300 },
              style: { borderRadius: 8 },
              endAdornment: <InputAdornment position="end">kg</InputAdornment>
            }}
          />
        </Stack>
        <Stack display={'flex'} flexDirection={'column'} justifyContent={'space-between'} sx={{ width: '100%' }}>
          <TextField
            label="Target weight"
            name="macrosData.targetWeight"
            placeholder="Enter Target weight"
            id="exact-subscription-targetWeight"
            value={values.macrosData?.targetWeight}
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
            onChange={(data) => setFieldValue('macrosData.targetWeight', data.target.value)}
            inputProps={{
              inputProps: { style: { borderRadius: 8 }, min: 100, max: 300 },
              style: { borderRadius: 8 },
              endAdornment: <InputAdornment position="end">kg</InputAdornment>
            }}
          />

          <TextField
            select
            label="Goal"
            onBlur={handleBlur}
            name="macrosData.goal"
            id="exact-subscription-goal"
            value={values.macrosData?.goal}
            onChange={(data) => {
              setFieldValue('macrosData.goal', data.target.value);
            }}
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {Object.values(Goal).map((goal) => (
              <MenuItem key={goal} value={goal}>
                {startCase(goal)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            type="string"
            onBlur={handleBlur}
            name="activityLevel"
            label="Activity level"
            id="exact-subscription-activityLevel"
            value={values.macrosData?.activityLevel}
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
            onChange={(data) => setFieldValue('macrosData[activityLevel]', data.target.value)}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {Object.values([
              { value: ActivityLevel.level1, label: `Little or no exercise` },
              { value: ActivityLevel.level2, label: `Light: 1-3 workouts/week` },
              { value: ActivityLevel.level3, label: `Moderate: 3-5 workouts/week` },
              { value: ActivityLevel.level4, label: `Active: 6-7 workouts/week` },
              { value: ActivityLevel.level5, label: `Very Active: 6-7 intense workouts/week` }
            ]).map((activityLevel) => (
              <MenuItem key={activityLevel.value} value={activityLevel.value}>
                {activityLevel.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </Box>
    </>
  );
};
export default BiometricsCard;
