import { format } from 'date-fns/fp';
import ExcelJS, { Style } from 'exceljs';
import { chunk, startCase } from 'lodash';

import { FoodStatsFilters, PortionStatsFilters } from '@calo/dashboard-types';
import { CustomMealCategory, FoodType } from '@calo/types';

import { FoodStatsComponent } from './KitchenPortionStats';

export interface Filters extends PortionStatsFilters {
  type: FoodType;
  size: string[];
  onlySandwiches: boolean;
}

export const getTitle = (title: string, filters: Filters) => {
  let fullTitle = title + ': ';
  if (filters.day) {
    fullTitle += `${filters.day.gte}`;
    if (filters.day.gte !== filters.day.lte) {
      fullTitle += ` / ${filters.day.lte}`;
    }
    fullTitle += ' | ';
  }
  if (filters.kitchen) {
    fullTitle += `For: ${filters.kitchen} | `;
  }
  if (filters.time) {
    fullTitle += `Delivery Time: ${startCase(filters.time)} | `;
  }
  if (filters.size.length > 0) {
    fullTitle += `Sizes: ${filters.size.join(', ')} | `;
  }
  if (filters.type) {
    fullTitle += `Type: ${filters.type} | `;
  }
  if (filters.onlySandwiches) {
    fullTitle += 'Only-Sandwiches | ';
  }

  fullTitle += `Generated on: ${format('dd/MM/yyyy hh:mm a')(new Date())}`;

  return fullTitle;
};
const border: Partial<Style> = {
  font: { size: 12 },
  border: {
    top: { style: 'thin', color: { argb: 'D3D3D3' } },
    left: { style: 'thin', color: { argb: 'D3D3D3' } },
    bottom: { style: 'thin', color: { argb: 'D3D3D3' } },
    right: { style: 'thin', color: { argb: 'D3D3D3' } }
  }
};

const detailedViewHeaderColumns = [
  { header: 'Quantity', width: 10, key: 'quantity', style: border },
  { header: 'Modified Quantity', width: 15, key: 'modifiedQuantity', style: border },
  { header: 'Special Requests Quantity', width: 20, key: 'specialRequestsQuantity', style: border }
];

const detailedViewHeader2Columns = [
  { header: 'Quantity', width: 10, key: 'quantity2', style: border },
  { header: 'Modified Quantity', width: 15, key: 'modifiedQuantity2', style: border },
  { header: 'Special Requests Quantity', width: 20, key: 'specialRequestsQuantity2', style: border }
];

const totalViewHeaderColumns = [{ header: 'Total Quantity', width: 15, key: 'quantity', style: border }];

const totalViewHeader2Columns = [{ header: 'Total Quantity', width: 15, key: 'quantity2', style: border }];

export const columns = (isDetailedView: boolean) => [
  { header: 'Name', width: 30, key: 'name', style: border },
  { header: 'Meal Type', width: 10, key: 'type', style: border },
  { header: 'Size', width: 10, key: 'size', style: border },
  ...(isDetailedView ? detailedViewHeaderColumns : totalViewHeaderColumns),
  { width: 4 },
  { header: 'Name', width: 30, key: 'name2', style: border },
  { header: 'Meal Type', width: 10, key: 'type2', style: border },
  { header: 'Size', width: 10, key: 'size2', style: border },
  ...(isDetailedView ? detailedViewHeader2Columns : totalViewHeader2Columns)
];

const defaultColumns = ['Name', 'Meal Type', 'Size'];
const totalViewColumns = ['Total Quantity'];

const detailedViewColumns = ['Quantity', 'Modified Quantity', 'Special Requests Quantity'];

const handleMealSize = (row: FoodStatsComponent) => {
  const sizeMappings = {
    [CustomMealCategory.salad]: 'CD',
    [CustomMealCategory.sandwich]: 'CS',
    [CustomMealCategory.rice]: 'CR'
  };

  if (row.size === 'C') {
    return sizeMappings[row.category] || 'C';
  }
  return row.size;
};

export const prepareForExport = (
  workbook: ExcelJS.Workbook,
  summedFoodData: FoodStatsComponent[],
  filters: FoodStatsFilters,
  isDetailedView: boolean
) => {
  const worksheet = workbook.addWorksheet('meal-stats', {
    pageSetup: { fitToPage: true, orientation: 'portrait' }
  });
  worksheet.mergeCells('A1', isDetailedView ? 'M1' : 'K1');
  worksheet.mergeCells('A2', isDetailedView ? 'M2' : 'K2');
  worksheet.getRow(3).values = [
    ...defaultColumns,
    ...(isDetailedView ? detailedViewColumns : totalViewColumns),
    '',
    ...defaultColumns,
    ...(isDetailedView ? detailedViewColumns : totalViewColumns)
  ];

  worksheet.columns = columns(isDetailedView);
  worksheet.getCell('A1').value = `production date: ${filters.day.gte} - ${filters.day.lte}`;
  worksheet.getCell('A2').value = `export day: ${format('dd-MM-yyyy')(Date.now())} - ${format('hh:mm aa')(Date.now())}`;
  worksheet.getCell('A1').font = { color: { argb: '0000' }, bold: true, size: 18 };
  worksheet.getCell('A1').alignment = {
    vertical: 'middle',
    horizontal: 'center'
  };
  worksheet.getCell('A2').font = { color: { argb: '0000' }, bold: true, size: 18 };
  worksheet.getCell('A2').alignment = {
    vertical: 'middle',
    horizontal: 'center'
  };
  for (const row of chunk(summedFoodData, 2)) {
    worksheet.addRow(
      {
        name: row[0].name.en,
        type: (row[0]?.type || []).join(', '),
        size: handleMealSize(row[0]),
        quantity: isDetailedView ? row[0].quantity - row[0].specialRequestsQuantity - row[0].modifiedQuantity : row[0].quantity,
        modifiedQuantity: row[0].modifiedQuantity,
        specialRequestsQuantity: row[0].specialRequestsQuantity,
        name2: row[1] ? row[1].name.en : '',
        type2: row[1] ? (row[1]?.type || []).join(', ') : '',
        size2: row[1] ? handleMealSize(row[1]) : '',
        quantity2: row[1]
          ? isDetailedView
            ? row[1].quantity - row[1].specialRequestsQuantity - row[1].modifiedQuantity
            : row[1].quantity
          : '',
        modifiedQuantity2: row[1] ? row[1].modifiedQuantity : '',
        specialRequestsQuantity2: row[1] ? row[1].specialRequestsQuantity : ''
      },
      ''
    );
  }
};
