import { useMemo, useState } from 'react';

import { orderBy } from 'lodash-es';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { GetUsersReq, Permission, User } from '@calo/dashboard-types';

import { Button, CaloLoader } from 'components';
import client from 'lib/client';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { UserListFilters } from 'lib/interfaces';
import Settings from './Settings';
import UserRow from './UserRow';

const UserList = () => {
  const location = useLocation();
  const history = useHistory();
  const roles = useUserRoles();
  const searchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState<UserListFilters>({
    group: '',
    country: '',
    kitchen: '',
    ...JSON.parse(searchParams.get('filters') || '{}')
  });
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery<{
    data: User[];
    meta: GetUsersReq;
  }>(
    ['/users', filters],
    async ({ pageParam, queryKey }) => {
      const { data } = await client.get(queryKey[0] as string, {
        params: {
          ...(pageParam && {
            cursor: pageParam
          }),
          roleFilter: filters.group,
          country: filters.country,
          kitchen: filters.kitchen,
          email: filters.email
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => data.meta?.cursor,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  const users = useMemo(
    () =>
      (data?.pages || []).reduce<User[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [data]
  );

  return (
    <InfiniteScroll
      dataLength={users.length || 0}
      next={fetchNextPage}
      hasMore={!!hasNextPage}
      loader={null}
      scrollableTarget="scrollable"
    >
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Users</li>
              </ul>
            </div>
          </div>
          {roles.includes(Permission.CREATE_USER) && (
            <div className="level-right">
              <div className="level-item">
                <Button icon="fas fa-plus" onClick={() => history.push(Routes.newUser)} className="mr-4" />
              </div>
            </div>
          )}
        </div>
      </section>
      <section>
        {isLoading ? (
          <CaloLoader />
        ) : users && users.length === 0 ? (
          <span className="absolute w-full text-4xl mt-4 text-center font-bold text-gray-400">NO USERS</span>
        ) : (
          <>
            <div className="card has-table has-table-container-upper-radius">
              <div className="card-content">
                <div className="table-container overflow-y-auto">
                  <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                    <thead>
                      <tr className="bg-black">
                        <th style={{ color: 'white' }}>Name</th>
                        <th style={{ color: 'white' }}>Group</th>
                        <th style={{ color: 'white' }}>Phone Number</th>
                        <th style={{ color: 'white' }}>Email</th>
                        <th style={{ color: 'white' }}>Country</th>
                        <th style={{ color: 'white' }}>Kitchen</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderBy(users, 'name').map((user, index) => (
                        <UserRow key={`${user}-${index}`} user={user} />
                      ))}
                    </tbody>
                    {users.length >= 25 && (
                      <tfoot>
                        <th>Name</th>
                        <th>Group</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Country</th>
                        <th>Kitchen</th>
                      </tfoot>
                    )}
                  </table>
                  {!!hasNextPage && (
                    <div className="flex justify-center pb-3">
                      <Button onClick={() => fetchNextPage()} content="Load more" loading={isFetchingNextPage} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <Settings onFilter={setFilters} filters={filters} />
      </section>
    </InfiniteScroll>
  );
};

export default UserList;
