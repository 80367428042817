import { useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import { lowerCase, sortBy, startCase } from 'lodash-es';
import DatePicker from 'react-date-picker';

import { AddonsSubscriptionMenuFoodItem, FoodTagsKeys } from '@calo/dashboard-types';
import { AddonCategory, FoodType } from '@calo/types';
import CloseIcon from '@mui/icons-material/Close';

import { handleSearch } from 'lib/helpers';
import { MenuFood } from 'lib/interfaces';
import Button from '../Button';
import Modal from '../Modal';
import Select from '../Select';

interface AddonsPickerProps {
  addonsRef: any;
  addonsList: MenuFood[];
  addonsItem: AddonsSubscriptionMenuFoodItem;
  existAddons: AddonsSubscriptionMenuFoodItem[];
  setFilterName?: (value: string) => void;
  handleSelectedAddons?: (id: string) => void;
  AddToTable: (value: AddonsSubscriptionMenuFoodItem) => void;
  isLoading: boolean;
}

const AddonsSubscriptionPicker = ({
  AddToTable,
  addonsList,
  addonsRef,
  addonsItem,
  existAddons,
  setFilterName,
  handleSelectedAddons,
  isLoading
}: AddonsPickerProps) => {
  const [FoodTypes, setFoodTypes] = useState<FoodType>(FoodType.breakfast);
  const [addonCategory, setAddonCategory] = useState<AddonCategory>(AddonCategory.COFFEE_AND_TEA);
  const [addonsItem1, setAddonsItem1] = useState<AddonsSubscriptionMenuFoodItem | undefined>(addonsItem);
  const [alwaysAvailable, setAlwaysAvailable] = useState<boolean>(true);

  const checkExistence = (id: string) => existAddons.find((existAddon) => existAddon.id === id);
  const checkFoodTags = (food: MenuFood) => {
    if (food.foodTags && food.foodTags.length) {
      return food.foodTags.some(({ key, value }) => {
        return key === FoodTagsKeys.addonCategory && value.includes(addonCategory);
      });
    }
  };
  const type = addonsList.filter((r) => r.type.includes(FoodTypes) && !checkExistence(r.id) && checkFoodTags(r));

  const options = useMemo(
    () =>
      sortBy(type, (f) => `${f.name.en}-${f.size}`).map((food) => ({
        name: food.name.en,
        value: food.id,
        type: food.type,
        label: `${food.name.en} (${food.size})`,
        foodTags: food.foodTags
      })),
    [addonsList, FoodTypes, addonCategory, existAddons]
  );

  const handleDataChange = () => {
    if (addonsItem1 && addonsItem1.priority && addonsItem1.id) {
      addonsRef.current?.close();
      AddToTable(addonsItem1);
    }
  };

  useEffect(() => {
    if (alwaysAvailable) {
      setAddonsItem1({ ...addonsItem, startDate: undefined, endDate: undefined });
    }
  }, [alwaysAvailable]);

  useEffect(() => {
    setAddonsItem1(addonsItem);
    // setAlwaysAvailable(!(addonsItem.startDate || addonsItem.endDate))
  }, [addonsItem]);

  useEffect(() => {
    setAddonsItem1(undefined);
  }, [FoodTypes]);

  useEffect(() => {
    setAddonsItem1(undefined);
  }, [addonCategory]);

  return (
    <div>
      <Modal ref={addonsRef}>
        <div className="flex flex-col">
          <Select
            label="Food type"
            value={FoodTypes}
            name="FoodTypes"
            onChange={(data: any) => setFoodTypes(data.value)}
            options={Object.values(FoodType).map((t) => ({
              value: FoodType[t],
              label: t
            }))}
          />
          <Select
            label="Addon Category"
            value={addonCategory}
            name="AddonCategory"
            onChange={(data: any) => setAddonCategory(data.value)}
            options={Object.values(AddonCategory).map((t) => ({
              value: AddonCategory[t],
              label: startCase(lowerCase(t))
            }))}
          />
          <Select
            label="Addons"
            onChange={(data: any) => {
              setAddonsItem1({ ...addonsItem, id: data.value, priority: +existAddons.length + 1 });
              handleSelectedAddons && handleSelectedAddons(data.value);
            }}
            onInputChange={(data: string, action: any) =>
              setFilterName && handleSearch({ text: data, action, name: setFilterName })
            }
            placeholder={'Search...'}
            data-test="addon-picker-addon-select"
            options={options}
            isLoading={isLoading}
            value={addonsItem1?.id}
          />
          <div className="flex flex-row mb-3">
            <div className="field mr-5">
              <label className="label">Always available</label>
              <label
                className={cx(' cursor-pointer inline-block w-12 p-1 rounded-full', {
                  'toggle-left bg-green-500': alwaysAvailable,
                  'toggle-right bg-red-500': !alwaysAvailable
                })}
              >
                <input
                  type="checkbox"
                  className="hidden"
                  checked={alwaysAvailable}
                  onChange={() => setAlwaysAvailable(alwaysAvailable === true ? false : true)}
                />
                <div
                  className={cx('h-5 w-5 rounded-full bg-white transition-all right-0', {
                    'ml-5': alwaysAvailable
                  })}
                ></div>
              </label>
            </div>

            {!alwaysAvailable && (
              <div className="field mr-5">
                <label className="label">Start Date</label>
                <DatePicker
                  className="w-64 mx-auto"
                  onChange={(date: any) =>
                    setAddonsItem1({
                      ...addonsItem,
                      endDate: addonsItem1?.endDate,
                      startDate: date ? format('yyyy-MM-dd')(date) : undefined
                    })
                  }
                  value={addonsItem1?.startDate ? parseISO(addonsItem1.startDate) : undefined}
                  clearIcon={<CloseIcon />}
                />
              </div>
            )}

            {!alwaysAvailable && (
              <div className="field">
                <label className="label">End Date</label>
                <DatePicker
                  className="w-64 mx-auto"
                  onChange={(date: any) =>
                    setAddonsItem1({
                      ...addonsItem,
                      startDate: addonsItem1?.startDate,
                      endDate: date ? format('yyyy-MM-dd')(date) : undefined
                    })
                  }
                  value={addonsItem1?.endDate ? parseISO(addonsItem1.endDate) : undefined}
                  clearIcon={<CloseIcon />}
                />
              </div>
            )}
          </div>
          <Button
            warning
            content="Confirm"
            disabled={!addonsItem1?.id}
            onClick={() => handleDataChange()}
            data-test="addon-picker-confirm-button"
          />
        </div>
      </Modal>
    </div>
  );
};

export default AddonsSubscriptionPicker;
