import { useState } from 'react';

import { FoodComponentQualityStandard } from '@calo/dashboard-types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Menu, MenuItem, TableCell, TableRow } from '@mui/material';

interface ComponentMethodRowProps {
  standard: FoodComponentQualityStandard;
  openEditModal: (id: string) => void;
  handleDelete: (id: string) => void;
  isDisabled: boolean;
}
const ComponentStandardsRow = ({ standard, openEditModal, handleDelete, isDisabled }: ComponentMethodRowProps) => {
  const [deliveryActionMenuAnchorEl, setDeliveryActionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isExportMenuOpened = Boolean(deliveryActionMenuAnchorEl);

  return (
    <TableRow>
      <TableCell sx={{ fontSize: '16px', pxy: 0 }}>{standard.name}</TableCell>
      <TableCell sx={{ fontSize: '16px', py: 0 }}>
        {standard.description ? standard.description?.slice(0, 100) : '---'}
        {(standard.description ?? []).length >= 100 && '...'}
      </TableCell>
      <TableCell sx={{ fontSize: '16px', py: 0 }}>
        <IconButton
          disabled={isDisabled}
          onClick={(event) => {
            !deliveryActionMenuAnchorEl && setDeliveryActionMenuAnchorEl(event.currentTarget);
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          MenuListProps={{
            'aria-labelledby': 'export-button'
          }}
          anchorEl={deliveryActionMenuAnchorEl}
          open={isExportMenuOpened}
          onClose={() => setDeliveryActionMenuAnchorEl(null)}
        >
          <MenuItem
            sx={{ fontWeight: 600, py: 1 }}
            disabled={isDisabled}
            onClick={() => {
              openEditModal(standard.id);
              setDeliveryActionMenuAnchorEl(null);
            }}
          >
            <Box>Edit</Box>
          </MenuItem>
          <MenuItem
            sx={{ fontWeight: 600, py: 1 }}
            disabled={isDisabled}
            onClick={() => {
              handleDelete(standard.id);
              setDeliveryActionMenuAnchorEl(null);
            }}
          >
            <Box>Delete</Box>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};
export default ComponentStandardsRow;
