import cx from 'classnames';

interface ButtonProps {
  text: string;
  method: () => void;
  disabled?: boolean;
  className?: string;
}

interface Props {
  visable: boolean;
  buttonsDetails: ButtonProps[];
  className?: string;
  onBlur?: () => void;
}

const OptionsDropdown = ({ visable, buttonsDetails, className, onBlur }: Props) => {
  if (visable) {
    return (
      <div
        onBlur={onBlur}
        className={cx({ 'bg-white absolute min-h-full top-5 left-5 border right-5 z-10 rounded py-2': !className }, className)}
      >
        {buttonsDetails.map((buttonDetails) => (
          <button
            key={buttonDetails.text}
            onClick={buttonDetails.method}
            disabled={buttonDetails.disabled}
            className={cx(
              { 'w-full p-1 px-3 focus:outline-none hover:bg-gray-300 text-left': !buttonDetails.className },
              buttonDetails.className
            )}
          >
            {buttonDetails.text}
          </button>
        ))}
      </div>
    );
  } else return null;
};

export default OptionsDropdown;
