import { MovingCostReportInterval } from '@calo/dashboard-types';
import { exportMovingCostReport } from 'actions/supplyChainReports';
import { Button, Select2 } from 'components';
import { format, parseISO } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import { useState } from 'react';
import DatePicker from 'react-date-picker';
import { Link, useParams } from 'react-router-dom';

const MovingCostReport = () => {
  const { kitchen } = useParams<{ kitchen: string }>();
  const [startingDate, setStartingDate] = useState('');
  const [interval, setInterval] = useState(MovingCostReportInterval.monthly);

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  <Link to={Routes.supplyChainReportsList}>Moving Cost Report</Link>
                </li>
                <li>{kitchen}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="w-64 ml-16">
          <Select2
            label="Interval"
            value={interval}
            onChange={(data) => setInterval(data.target.value as MovingCostReportInterval)}
            options={Object.values(MovingCostReportInterval).map((type) => ({
              value: type,
              label: type
            }))}
          />
        </div>
        <div className="mt-1 ml-16">
          <label className="label">Starting date:</label>
          <DatePicker
            className="flex w-64 my-8"
            onChange={(date: Date) => setStartingDate(format('yyyy-MM-dd')(date))}
            value={startingDate ? parseISO(startingDate) : undefined}
            clearIcon={null}
          />
        </div>
        <div className=" ml-16 mt-10">
          <Button
            primary
            content="Request report"
            disabled={!startingDate}
            onClick={() =>
              exportMovingCostReport(kitchen, {
                interval,
                startingDate
              })
            }
          />
        </div>
      </section>
    </>
  );
};

export default MovingCostReport;
