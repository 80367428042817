import { KDSCreateReq, KDSUserUpdateReq } from '@calo/dashboard-types';
import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import mutation from './mutation';

export const createKDSUser = async (values: KDSCreateReq) => {
  const { data, status } = await client.post('kitchen-users', values);
  ToastSuccessMsgs({ status, action: 'kitchen-User created successfully' });
  mutation(['kitchen-users', data.id], data);
  return data;
};

export const updateKDSUser = async (payload: KDSUserUpdateReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`kitchen-users/${id}`, rest);
  ToastSuccessMsgs({ status, action: 'kitchen-User updated successfully' });
  mutation(['kitchen-users', id], data);
};

export const deleteKDSUser = async (userId: string) => {
  const { status } = await client.delete(`kitchen-users/${userId}`);
  ToastSuccessMsgs({ status, action: 'kitchen-User deleted successfully' });
};

export const resetKDSPassword = async (payload: { userId: string; newPassword: string }) => {
  const { data, status } = await client.put(`/kitchen-users/${payload.userId}/reset-password`, {
    password: payload.newPassword
  });
  ToastSuccessMsgs({ status, action: 'kitchen-User password changed successfully' });
  return data;
};
