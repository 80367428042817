import { RefObject, useEffect, useMemo, useRef, useState } from 'react';

import { keyBy, round, sumBy, uniqBy } from 'lodash-es';

import { MenuFoodComponent, Permission, UpdateFoodReq } from '@calo/dashboard-types';
import { Dictionary } from '@calo/types';
import { Stack } from '@mui/material';

import CostInformationCard from 'components/MealCostInformationCard/CostInformationCard';
import {
  Components,
  Information,
  Ingredients,
  MealInformation,
  NutritionalInformation,
  PackagingInformation,
  PortioningNotes
} from 'components/Sections';
import { useUserRoles } from 'lib/hooks';
import { BaseOmit, Food, FoodComponent } from 'lib/interfaces';

import { findAutoCalculatedMacros, findAutoCalculatedMicros } from 'views/Food/helpers';
import { displayMessageForMacrosLimits } from '../helpers';
import StickyHeader from './StickyHeader';
import useFoodForm from './useFoodForm';

interface FoodFormProps {
  name: string;
  selectedFood: Food;
  allSizesFood: Food[];
  filteredFoodUsed: any;
  foodWithAllSizes: Food[];
  usedOnMenuButtonColor: string[];
  foodComponentsFromSearch: FoodComponent[];
  setName: (value: string) => void;
  setSelectedFood: (food: Food) => void;
  setFoodWithAllSizes: (food: Food[]) => void;
  onSubmit: (value: Omit<UpdateFoodReq, BaseOmit>) => Promise<void>;
}

const FoodForm = ({
  name,
  selectedFood,
  allSizesFood,
  filteredFoodUsed,
  foodWithAllSizes,
  foodComponentsFromSearch,
  usedOnMenuButtonColor,
  setName,
  onSubmit,
  setSelectedFood,
  setFoodWithAllSizes
}: FoodFormProps) => {
  const roles = useUserRoles();
  const isDisabled = !roles.includes(Permission.UPDATE_FOOD);

  const [packageCost, setPackageCost] = useState(0);

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
    dirty,
    setFieldValue,
    setValues,
    resetForm,
    errors
  } = useFoodForm(selectedFood, onSubmit);

  const keyedComponents = keyBy(values.components, 'id') as any as Dictionary<FoodComponent>;
  const childComponents = (selectedFood.components ?? []).flatMap(
    (comp: any) => comp.childComponents ?? []
  ) as any as FoodComponent[];
  const keyedChildComponents = useMemo(() => keyBy(childComponents, 'id'), [childComponents]);

  const sectionRefs: RefObject<HTMLDivElement>[] = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null)
  ];

  useEffect(() => resetForm, [selectedFood]);

  useEffect(() => {
    const ingredients = (values.components ?? []).flatMap((comp) => comp.ingredients ?? []) ?? [];
    const childIngredients = (values.components ?? []).flatMap((comp) =>
      (comp.childComponents ?? []).flatMap((childComp: any) => childComp.ingredients)
    );
    const allIngredients = [...ingredients, ...childIngredients];
    setFieldValue('ingredients', uniqBy(allIngredients, 'id'));
  }, [values.components]);

  const handleComponentsChange = async (componentRows: MenuFoodComponent[]) => {
    const newComponentRows = componentRows.map((r) => (isNaN(r.quantity) ? { ...r, id: r.id, quantity: 0 } : r));

    setValues({
      ...values,
      components: newComponentRows,
      macros: values.withManualMacros ? values.macros : findAutoCalculatedMacros(newComponentRows),
      micronutrients: values.withManualMacros ? values.micronutrients : findAutoCalculatedMicros(newComponentRows)
    });
  };

  const getTotalCost = () => {
    const componentCosts = values.components?.map(
      (r) => ((values.components ?? []).find((k) => k.id === r.id)?.cost || 0) * r.quantity
    );
    const cC = round(
      sumBy(componentCosts, (fk) => fk || 0),
      3
    );
    return (packageCost + cC).toFixed(3);
  };

  const handleAutoCalculate = (value: boolean) => {
    if (value) {
      setValues({
        ...values,
        withManualMacros: value
      });
    } else {
      setValues({
        ...values,
        macros: findAutoCalculatedMacros(values.components ?? []),
        micronutrients: findAutoCalculatedMicros(values.components ?? []),
        withManualMacros: value
      });
    }
  };

  return (
    <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={2}>
      <StickyHeader
        roles={roles}
        dirty={dirty}
        values={values}
        isValid={isValid}
        setValues={setValues}
        isDisabled={isDisabled}
        sectionRefs={sectionRefs}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        selectedFood={selectedFood}
        setSelectedFood={setSelectedFood}
        filteredFoodUsed={filteredFoodUsed}
        foodWithAllSizes={foodWithAllSizes}
        setFoodWithAllSizes={setFoodWithAllSizes}
        usedOnMenuButtonColor={usedOnMenuButtonColor}
        components={values.components ?? ([] as any)}
        childComponents={childComponents}
      />
      <MealInformation
        values={values}
        errors={errors}
        food={selectedFood}
        isDisabled={isDisabled}
        roles={roles}
        setFieldValue={setFieldValue}
        ref={sectionRefs[0]}
      />
      <Components
        values={values}
        isDisabled={isDisabled}
        foodComponents={foodComponentsFromSearch}
        isLoading={false}
        setFieldValue={setFieldValue}
        handleComponentsChange={handleComponentsChange}
        ref={sectionRefs[1]}
        setName={setName}
        name={name}
      />
      <NutritionalInformation
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        microNutrientsAuto={values.withManualMacros || false}
        handleAutoCalculate={handleAutoCalculate}
        displayMessageForMacrosLimits={(macrosProp: 'carbs' | 'cal' | 'protein' | 'fat') =>
          displayMessageForMacrosLimits(allSizesFood, selectedFood, values.macros!, macrosProp)
        }
        ref={sectionRefs[2]}
      />
      <Information
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        food={selectedFood}
        isDisabled={isDisabled}
        allSizesFood={allSizesFood}
      />
      <Ingredients
        isLoading={false}
        ref={sectionRefs[3]}
        ingredients={values.ingredients ?? []}
        components={(values.components as any) ?? []}
      />
      <CostInformationCard
        values={values}
        setFieldValue={setFieldValue}
        getTotalCost={getTotalCost}
        packagingCost={packageCost}
        allComponents={keyedComponents}
        foodWithAllSizes={foodWithAllSizes}
        childComponent={keyedChildComponents}
        errors={errors}
      />
      <PortioningNotes values={values} errors={errors} handleChange={handleChange} isDisabled={isDisabled} ref={sectionRefs[5]} />
      <PackagingInformation
        values={values}
        setFieldValue={setFieldValue}
        setPackageCost={(r) => setPackageCost(r)}
        food={selectedFood}
        isDisabled={isDisabled}
        ref={sectionRefs[4]}
        roles={roles}
      />
    </Stack>
  );
};

export default FoodForm;
