import { Permission } from '@calo/dashboard-types';
import { getList } from 'actions';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { Kitchen } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import { useQuery } from 'react-query';
import KitchenRow from './KitchenRow';

const KitchenList = () => {
  const roles = useUserRoles();
  const userKitchens = useUserKitchens();

  const { data: kitchenList } = useQuery<string, Error, { data: Kitchen[] }>('kitchens', getList, {
    suspense: true,
    onSuccess: (data) => {
      for (const row of data?.data || []) {
        queryClient.setQueryData(['kitchens', row.id], row);
      }
    }
  });

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Pre-Launch Kitchens</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div>
        <div className="card has-table has-table-container-upper-radius">
          <div className="card-content">
            <div className="table-container">
              <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                <thead>
                  <tr className="bg-black">
                    <th style={{ color: 'white' }}>ID</th>
                    <th style={{ color: 'white' }}>Capacity</th>
                    {roles.includes(Permission.VIEW_KITCHEN_STATS) && (
                      <>
                        <th style={{ color: 'white' }}>Waiting List</th>
                        <th style={{ color: 'white' }}>Allowed</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {kitchenList?.data
                    .filter((kitchen) => kitchen.id.includes('000'))
                    .map((kitchen) => <KitchenRow key={kitchen.id} kitchen={kitchen} />)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Operating Kitchens</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div>
        <div className="card has-table has-table-container-upper-radius">
          <div className="card-content">
            <div className="table-container">
              <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                <thead>
                  <tr className="bg-black">
                    <th style={{ color: 'white' }}>ID</th>
                    <th style={{ color: 'white' }}>Capacity</th>
                    {roles.includes(Permission.VIEW_KITCHEN_STATS) && (
                      <>
                        <th style={{ color: 'white' }}>Waiting List</th>
                        <th style={{ color: 'white' }}>Allowed</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {kitchenList?.data
                    .filter((kitchen) => !kitchen.id.includes('000') && userKitchens.includes(kitchen.id))
                    .map((kitchen) => <KitchenRow key={kitchen.id} kitchen={kitchen} />)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KitchenList;
