import { Country, Kitchen } from '@calo/types';
import { toggleUISettings } from 'actions';
import { Select2, SideDrawer } from 'components';
import { getAccessibleCountries, getKitchenOptions } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';

interface SettingsProps {
  onFilter: (values: any) => void;
  filters: any;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Country"
          value={filters.country}
          onChange={(data) =>
            onFilter({
              ...filters,
              country: data.target.value as Country,
              kitchen: Object.values(Kitchen).find(
                (k) => k.includes(data.target.value) && !k.includes(`${data.target.value}${'000'}`)
              ) as Kitchen
            })
          }
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
          data-test="dashboard-country-select"
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(data) => onFilter({ ...filters, kitchen: data.target.value as Kitchen })}
          options={getKitchenOptions(userKitchens, filters.country)}
          disabled={Object.values(Kitchen).filter((r) => r.includes(filters.country!)).length === 0}
          data-test="dashboard-kitchen-select"
        />
      </section>
    </SideDrawer>
  );
};

export default Settings;
