import { Permission } from '@calo/dashboard-types';
import { Box, Divider, Stack, Switch, ThemeProvider, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import Button from 'components/Button';
import { theme } from 'lib/componentStyles';
import { useUserRoles } from 'lib/hooks';
import { useEffect, useState } from 'react';
import { KitchenPolygonRowState } from '../Polygon/types';
import ColorSelector from './ColorSelector';
import styles from './styles';

interface CityAreaFormProps {
  polygonRow: any;
  onChange: (polygonRow: KitchenPolygonRowState) => void;
  onDelete: (polygonRow: KitchenPolygonRowState) => void;
}

const CityAreaForm = ({ polygonRow, onDelete, onChange }: CityAreaFormProps) => {
  const [selectedZone, setSelectedZone] = useState<KitchenPolygonRowState>({
    polygon: polygonRow.polygon,
    color: '',
    bounds: [],
    supplyCapped: false
  });
  const roles = useUserRoles();
  const [selectedColor, setSelectedColor] = useState<string>(polygonRow.color);
  const [supplyCapped, setSupplyCapped] = useState<boolean>(polygonRow.supplyCapped);

  useEffect(() => {
    if (polygonRow) {
      setSelectedZone({
        polygon: polygonRow.polygon,
        color: polygonRow.color,
        bounds: polygonRow.bounds,
        supplyCapped: polygonRow.supplyCapped
      });
    }
  }, [polygonRow]);

  return (
    <div>
      {roles.includes(Permission.UPDATE_SUPPLY_CAP_ZONE) && (
        <>
          <Box style={{ flexDirection: 'column' }}>
            <Box sx={{ mx: '5px' }}>
              <Typography sx={{ fontWeight: 600, lineHeight: '16px', fontSize: '14px' }}>Supply Cap Zone</Typography>
              <ColorSelector
                onSelectColor={(color) => {
                  setSelectedColor(color);
                  setSelectedZone((prevZone) => ({ ...prevZone, color }));
                }}
                selectedColor={selectedColor}
              />
            </Box>

            <Divider sx={styles.divider} />
            <Box>
              <Stack
                direction="row"
                alignItems="start"
                sx={{ backgroundColor: caloTheme.palette.neutral50, borderRadius: '12px', p: '4px', width: '240px' }}
              >
                <ThemeProvider theme={theme}>
                  <Box sx={{ marginX: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Switch
                      onChange={() => {
                        setSupplyCapped(!supplyCapped);
                        setSelectedZone((prevZone) => ({ ...prevZone, supplyCapped: !supplyCapped }));
                      }}
                      sx={{ color: 'white' }}
                      checked={selectedZone.supplyCapped}
                    />
                    <Typography>{`${supplyCapped ? 'Capped' : 'Uncapped'}`}</Typography>
                  </Box>
                </ThemeProvider>
              </Stack>
            </Box>
          </Box>

          <Box className="flex flex-row justify-between pt-4">
            {roles.includes(Permission.DELETE_SUPPLY_CAP_ZONE) && (
              <Button type="button" danger className="w-24" content="Delete" onClick={() => onDelete(polygonRow)} />
            )}
            <Button
              type="button"
              primary
              className="w-24"
              content="Apply"
              onClick={() =>
                onChange({
                  color: selectedZone.color,
                  bounds: selectedZone.bounds,
                  polygon: selectedZone.polygon,
                  supplyCapped: selectedZone.supplyCapped
                })
              }
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default CityAreaForm;
