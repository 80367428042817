import { Permission, PrototypeAction } from '@calo/dashboard-types';
import { addPrototypeIngredientAction } from 'actions/ingredient';
import { useUserRoles } from 'lib/hooks';
import { IngredientType } from 'lib/interfaces';
import { forwardRef, useState } from 'react';
import { useMutation } from 'react-query';
import CommentsPopup from 'views/ChefPlayground/Shared/CommentsPopup';

interface CommentsPopupProps {
  selectedIngredient?: IngredientType;
  closeCommentsPopup: () => void;
  successHandler: (data: IngredientType) => void;
}

const IngredientCommentsPopup = forwardRef(
  ({ selectedIngredient, closeCommentsPopup, successHandler }: CommentsPopupProps, ref) => {
    const userRoles = useUserRoles();

    const commentItems = selectedIngredient?.prototypeActions?.filter((action) => action.type === PrototypeAction.comment) ?? [];

    const [comment, setComment] = useState('');

    const { mutate: addPrototypeAction, isLoading } = useMutation(addPrototypeIngredientAction, {
      onSuccess: (data: IngredientType) => {
        if (!data) {
          return;
        }
        setComment('');
        successHandler(data);
        closeCommentsPopup();
      }
    });

    const submitCommentHandler = () => {
      if (!selectedIngredient?.id || !comment) {
        return;
      }
      addPrototypeAction({ id: selectedIngredient.id, action: PrototypeAction.comment, message: comment });
    };

    return (
      <CommentsPopup
        comment={comment}
        commentsPopupRef={ref as any}
        commentItems={commentItems}
        commentDisabled={isLoading || !selectedIngredient?.id || !userRoles.includes(Permission.CREATE_ACTION_PROTOTYPE_FOOD)}
        submitDisabled={isLoading || !comment || !userRoles.includes(Permission.CREATE_ACTION_PROTOTYPE_FOOD)}
        setComment={setComment}
        closeHandler={closeCommentsPopup}
        submitCommentHandler={submitCommentHandler}
      />
    );
  }
);

export default IngredientCommentsPopup;
