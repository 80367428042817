import { CouponCategory, CouponFilters } from '@calo/dashboard-types';
import { CouponType, Currency } from '@calo/types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { toggleUISettings } from 'actions';
import { Input, Select2, SideDrawer } from 'components';
import { format, parseISO } from 'date-fns/fp';
import { AppState } from 'lib/interfaces';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface SettingsProps {
  onFilter: (values: CouponFilters) => any;
  filters: CouponFilters;
  selected: CouponCategory;
}

const Settings = ({ filters, onFilter, selected }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));
  const day = format('yyyy-MM-dd')(Date.now());
  const dispatch = useDispatch();

  useEffect(() => {
    if (filters.status === 'active') {
      onFilter({ ...filters, expiresAt: { gte: day } });
    } else if (filters.status === 'expired') {
      onFilter({ ...filters, expiresAt: { lte: day } });
    } else {
      onFilter({ ...filters, expiresAt: undefined });
    }
  }, [filters.status]);

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Input
          label="Code"
          value={filters.code}
          onChange={(e) => onFilter({ ...filters, code: e.target.value.length > 0 ? e.target.value : undefined })}
          debounce
        />
        <Select2
          label="Type"
          value={filters.type}
          onChange={(data) =>
            onFilter({ ...filters, type: data.target.value === 'Any' ? undefined : (data.target.value as CouponType) })
          }
          options={[
            {
              value: 'Any',
              label: 'Any'
            },
            ...Object.values(CouponType).map((type) => ({
              value: type,
              label: type
            }))
          ]}
        />
        <Select2
          label="Currency"
          value={filters.currency}
          onChange={(data) =>
            onFilter({ ...filters, currency: data.target.value === 'Any' ? undefined : (data.target.value as Currency) })
          }
          options={[
            {
              value: 'Any',
              label: 'Any'
            },
            ...Object.values(Currency).map((m) => ({
              value: m,
              label: Currency[m]
            }))
          ]}
        />
        {selected === CouponCategory.promo && (
          <Select2
            label="Status"
            value={filters.status}
            onChange={(data: any) => onFilter({ ...filters, status: data.target.value })}
            options={[
              {
                value: '',
                label: 'Any'
              },
              ...['active', 'expired'].map((m) => ({
                value: m,
                label: m
              }))
            ]}
          />
        )}

        <div className="field">
          <label className="label">Expires At</label>
          <div className="control is-clearfix flex flex-col ">
            <DateRangePicker
              onChange={(dates: any) =>
                onFilter({
                  ...filters,
                  expiresAt: dates
                    ? {
                        gte: format('yyyy-MM-dd')(dates[0]),
                        lte: format('yyyy-MM-dd')(dates[1])
                      }
                    : undefined
                })
              }
              minDate={filters.status === 'active' && new Date()}
              maxDate={filters.status === 'expired' && new Date()}
              value={
                filters.expiresAt
                  ? [
                      filters.expiresAt!.gte ? parseISO(filters.expiresAt!.gte) : undefined,
                      filters.expiresAt!.lte ? parseISO(filters.expiresAt!.lte) : undefined
                    ]
                  : undefined
              }
            />
          </div>
        </div>
        <label className="font-bold ">Used Times</label>
        <div className="tabs">
          <Input
            label="Min"
            onChange={(e) =>
              onFilter({
                ...filters,
                usedTimes: {
                  max: filters?.usedTimes?.max || 0,
                  min: +e.target.value
                }
              })
            }
            value={filters?.usedTimes?.min || 0}
            type="number"
          />
          <Input
            label="Max"
            onChange={(e) =>
              onFilter({
                ...filters,
                usedTimes: {
                  min: filters?.usedTimes?.min || 0,
                  max: +e.target.value
                }
              })
            }
            value={filters?.usedTimes?.max || 0}
            type="number"
          />
        </div>
      </section>
    </SideDrawer>
  );
};

export default Settings;
