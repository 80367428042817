import { format } from 'date-fns/fp';
import { useFormik } from 'formik';

import { CreateDeliveryReq } from '@calo/dashboard-types';
import { PermissionService } from '@calo/services';
import { Subscription } from '@calo/types';

export default (
  subscription: Subscription & { remainingDays: number; expectedLastDeliveryDay: string; ratings: any[] },
  onSubmit: (values: CreateDeliveryReq) => any
) => {
  return useFormik<CreateDeliveryReq>({
    initialValues: {
      date: format('yyyy-MM-dd')(PermissionService.getMinActionDate(subscription.deliveryDays, Date.now()))
    },

    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
};
