import { Reducer } from 'react';

const reducer: Reducer<KitchenPolygonState, PolygonAction> = (state = { polygons: [] }, action) => {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        polygons: [...action.payload]
      };
    case 'update': {
      const i = state.polygons.findIndex((p) => p.polygon === action.payload.polygon);
      return {
        ...state,
        polygons: [...state.polygons.slice(0, i), ...state.polygons.slice(i + 1), { ...action.payload }],
        ...(state.selectedPolygon &&
          state.selectedPolygon.polygon === action.payload.polygon && {
            selectedPolygon: action.payload
          })
      };
    }
    case 'add':
      return {
        ...state,
        polygons: [...state.polygons, action.payload]
      };
    case 'delete': {
      const { polygon } = action.payload;
      polygon.unbindAll();
      polygon.setMap(null);
      return {
        ...state,
        polygons: state.polygons.filter((p) => p.polygon !== action.payload.polygon),
        selectedPolygon: null
      };
    }
    case 'reset':
      for (const row of state.polygons) {
        row.polygon.unbindAll();
        row.polygon.setMap(null);
      }
      return { polygons: [] };
    case 'select':
      return {
        ...state,
        selectedPolygon: action.payload ? state.polygons.find((p) => p.polygon === action.payload.polygon) : action.payload
      };
    default:
      return state;
  }
};

export default reducer;
