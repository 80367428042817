import { useMemo, useRef, useState } from 'react';

import { addMonths, format, parseISO } from 'date-fns/fp';
import { startCase } from 'lodash-es';
import { useInfiniteQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { CodeChannelCategory } from '@calo/dashboard-types';
import { PaymentTransaction, TransactionHistorySource, TransactionHistoryType, UserAction, WalletReason } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import {
  Box,
  Button,
  Card,
  MenuItem,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
  tableCellClasses
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, ModalRef } from 'components';
import Popup from 'components/Popup';
import { useDocumentMedia } from 'hooks';
import client from 'lib/client';
import { refundReasonsHelper } from 'lib/helpers/refundReasonsHelper';
import history from 'lib/history';
import SubscriptionBalanceSheet from '../../../SubscriptionBalanceSheet';
import TransactionCardRow from './TransactionsCardRow';
import TransactionCardRowMobile from './TransactionsCardRowMobile';

const transactionPaymentActions = Object.values(UserAction).map((type) => ({
  value: type,
  label: type.replaceAll('_', ' ')
}));

const transactionManualAdditionActions = [
  { value: WalletReason.CALO_TEAM_PERKS, label: 'Calo Team Perks' },
  { value: WalletReason.CASH_OR_BENEFIT_PAYMENT, label: 'Cash or Benefit Payment' },
  { value: WalletReason.COMPENSATION, label: 'Compensation' },
  { value: WalletReason.MANUAL_DISCOUNT, label: 'Manual Discount' },
  { value: WalletReason.CX_GIFT, label: 'CX Gift' },
  { value: WalletReason.MARKETING_GIFT_AND_EXPERIMENTS, label: 'Marketing Gift and Experiments' },
  { value: WalletReason.CALO_BUSINESS, label: 'Calo Business' },
  { value: WalletReason.TRANSFERRED_CREDITS, label: 'Transferred Credits' },
  { value: WalletReason.CUSTOMERS_INFLUENCERS, label: 'Customers Influencers' },
  { value: WalletReason.CUSTOMERS_CALO_EXPERIMENTS, label: 'Customers Calo Experiments' },
  { value: WalletReason.COMPLEMENTARY_CARD, label: 'Complementary Card' },
  { value: CodeChannelCategory.CX_INFLUENCER, label: 'CX Influencer' },
  { value: WalletReason.OTHER, label: 'Other' }
];

const transactionManualDeductionActions = [
  { value: WalletReason.INVAILID_ENTRY, label: 'Invalid Entry' },
  { value: WalletReason.DOUBLE_ENTRY, label: 'Double Entry' },
  { value: WalletReason.REFUNDED_MANUALLY, label: 'Refunded Manually' },
  { value: WalletReason.FIXING_BALANCE, label: 'Fixing Balance' },
  { value: WalletReason.CREDIT_TRANSFER, label: 'Credit Transfer' },
  { value: WalletReason.REMOVING_GIFTS, label: 'Removing Gifts' },
  { value: WalletReason.REMOVING_DISCOUNT_CODE_CREDITS, label: 'Removing Discount Code Credits' },
  { value: WalletReason.DELIVERY_DEDUCTION, label: 'Delivery Deduction' },
  { value: WalletReason.OTHER, label: 'Other' }
];

const transactionRefundActions = refundReasonsHelper.getOptions();

interface TransactionsCardProps {
  subscription: any;
}

type PageFilters = {
  dateFrom: Date;
  dateTo: Date;
  sort: 'desc' | 'asc';
  type: string;
  action: string | undefined;
  source: string;
};

const TransactionsCard = ({ subscription }: TransactionsCardProps) => {
  const { isMobile, isTablet } = useDocumentMedia();
  const transactionHistoryModalRef = useRef<ModalRef>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState<PageFilters>({
    dateFrom: addMonths(-1)(new Date()),
    dateTo: new Date(),
    sort: 'desc',
    type: 'All',
    action: 'All',
    source: 'All',
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<{ data: PaymentTransaction[]; meta: any }>(
    [`/subscriptions/${subscription.id}/transactions`, filters],
    async ({ pageParam, queryKey }) => {
      searchParams.set('filters', JSON.stringify(filters));
      history.push({
        pathname: location.pathname,
        search: searchParams.toString()
      });
      const { data } = await client.get(queryKey[0] as string, {
        params: {
          ...(pageParam && {
            cursor: pageParam
          }),
          type: filters.type === 'All' ? undefined : filters.type,
          dateFrom: filters.dateFrom,
          dateTo: filters.dateTo,
          source: filters.source === 'All' ? undefined : filters.source,
          action: filters.action === 'All' ? undefined : filters.action,
          sort: filters.sort
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => data.meta?.cursor
    }
  );

  const list = useMemo(
    () =>
      (data?.pages || []).reduce<PaymentTransaction[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [data]
  );

  const transactionSourceOptions = useMemo(
    () =>
      (filters.type === 'All'
        ? [
            'All',
            TransactionHistorySource.PAYMENT,
            TransactionHistorySource.MANUAL_ADDITION,
            TransactionHistorySource.MANUAL_DEDUCTION,
            TransactionHistorySource.REFUND
          ]
        : filters.type === TransactionHistoryType.CREDIT
          ? ['All', TransactionHistorySource.PAYMENT, TransactionHistorySource.MANUAL_ADDITION]
          : ['All', TransactionHistorySource.MANUAL_DEDUCTION, TransactionHistorySource.REFUND]
      ).map((row) => ({
        value: row,
        label: row
      })),
    [filters.type]
  );

  const transactionActionOptions = useMemo(
    () =>
      filters.source === 'All'
        ? [{ value: 'All', label: 'All' }]
        : filters.source === TransactionHistorySource.PAYMENT
          ? transactionPaymentActions
          : filters.source === TransactionHistorySource.REFUND
            ? transactionRefundActions
            : filters.source === TransactionHistorySource.MANUAL_ADDITION
              ? transactionManualAdditionActions
              : transactionManualDeductionActions,
    [filters.source]
  );

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      flexShrink: 0,
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px',
      variant: 'caption',
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    },
    [`&.${tableCellClasses.body}`]: {
      flexShrink: 0,
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      variant: 'caption',
      lineHeight: '14px',
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    }
  }));

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          colorPrimary: {
            '&.Mui-checked': {
              color: caloTheme.palette.white
            }
          },
          track: {
            backgroundColor: caloTheme.palette.neutral400,
            '.Mui-checked.Mui-checked + &': {
              opacity: 0.7,
              backgroundColor: caloTheme.palette.primary500
            }
          }
        }
      }
    }
  });

  const exportTrx = () => {
    const rows = list.map((e) => [
      format('PPpp')(parseISO(e.createdAt)).replaceAll(',', ' '),
      e.type,
      e.source,
      e.action,
      e.amount,
      e.balance,
      e.currency
    ]);

    rows.unshift(['Time', 'Type', 'Soruce', 'Action', 'Amount', 'Balance', 'Currency']);

    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    const fileName = `${subscription.name}-${subscription.currency}-trx.csv`;
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv"
  };

  return (
    <>
      <Card variant="outlined" sx={{ width: '100%', border: 'none', borderRadius: '8px' }}>
        <Box
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column'
            }
          }}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '19px',
              lineHeight: '23px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600
            }}
          >
            Filters
          </Typography>
        </Box>
        <Box
          sx={{
            width: 'full',
            mx: 2,
            '& .MuiTextField-root': { my: 2, mx: 2, width: '29%', justifyContent: 'space-between' },
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'full',
              display: 'flex',
              flexDirection: 'column',
              '& .MuiTextField-root': { my: 2, mx: 1, width: '100%', justifyContent: 'space-between' }
            }
          }}
        >
          <TextField
            select
            name="transactionType"
            sx={{ width: '100%', mr: 2, mb: 2 }}
            label="Transaction Type"
            value={filters.type}
            id="exact-subscription-transactions-type"
            onChange={(data: any) => {
              setFilters({
                ...filters,
                type: data.target.value,
                source: 'All',
                action: undefined
              });
            }}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {[
              { value: 'All', label: 'All' },
              { value: TransactionHistoryType.CREDIT, label: 'Credit' },
              { value: TransactionHistoryType.DEBIT, label: 'Debit' }
            ].map((p) => (
              <MenuItem key={p.value} value={p.value}>
                {p.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            name="transactionSource"
            sx={{ width: '100%', mr: 2, mb: 2 }}
            label="Transaction Source"
            value={filters.source}
            id="exact-subscription-transactions-source"
            onChange={(data: any) => {
              setFilters({
                ...filters,
                source: data.target.value,
                action: undefined
              });
            }}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {transactionSourceOptions.map((p) => (
              <MenuItem key={p.value} value={p.value}>
                {startCase(p.label)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            name="transactionAction"
            sx={{ width: '100%', mr: 2, mb: 2 }}
            label="Transaction Action"
            value={filters.action}
            id="exact-subscription-transactions-action"
            onChange={(data: any) => {
              setFilters({
                ...filters,
                action: data.target.value
              });
            }}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {transactionActionOptions.map((p) => (
              <MenuItem key={p.value} value={p.value}>
                {startCase(p.label)}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box
          display={'flex'}
          flexDirection={'row'}
          width="auto"
          sx={{
            width: 'full',
            mx: 2,
            '& .MuiTextField-root': { my: 2, mx: 2, width: '29%', justifyContent: 'space-between' },
            '& .sort-switch': { my: 2, mx: 2, width: '29%', justifyContent: 'space-between' },
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'full',
              display: 'flex',
              flexDirection: 'column',
              '& .MuiTextField-root': { my: 2, mx: 1, width: '100%', justifyContent: 'space-between' }
            }
          }}
        >
          <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px' }}>
            <DesktopDatePicker
              disableFuture
              label="Date From"
              inputFormat="dd-MM-yyyy"
              value={filters.dateFrom || null}
              renderInput={(params) => <TextField {...params} />}
              onChange={(date: any) => {
                setFilters({
                  ...filters,
                  dateFrom: date ? new Date(date).toISOString() : (undefined as any)
                });
              }}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px' }}>
            <DesktopDatePicker
              disableFuture
              label="Date To"
              inputFormat="dd-MM-yyyy"
              value={filters.dateTo || null}
              renderInput={(params) => <TextField {...params} />}
              onChange={(date: any) => {
                setFilters({
                  ...filters,
                  dateTo: date ? new Date(date).toISOString() : (undefined as any)
                });
              }}
            />
          </LocalizationProvider>

          <Stack className="sort-switch" sx={{ marginX: 2 }}>
            <ThemeProvider theme={theme}>
              <Typography
                sx={{
                  textAlign: 'left',
                  color: caloTheme.palette.neutral900,
                  fontSize: '12px',
                  lineHeight: '14px',
                  width: 'auto',
                  mb: '4px',
                  fontFamily: caloTheme.typography.fontFamily,
                  fontWeight: 600
                }}
              >
                Sort By Latest
              </Typography>
              <Switch
                onChange={() => {
                  setFilters({
                    ...filters,
                    sort: filters.sort === 'asc' ? 'desc' : 'asc'
                  });
                }}
                checked={filters.sort === 'desc'}
              />
            </ThemeProvider>
          </Stack>

          <Stack sx={{ margin: 2, alignItems: 'end' }}>
            <Button
              variant="outlined"
              aria-label="Transaction History"
              sx={{
                width: 'auto',
                height: '45px',
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                borderRadius: '8px',
                padding: '14px 20px 14px 20px',
                color: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary500,
                '&:hover': {
                  color: caloTheme.palette.primary600,
                  borderColor: caloTheme.palette.primary600
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  justifyItems: 'center',
                  margin: 'auto',
                  marginTop: 4,
                  width: 'auto'
                }
              }}
              onClick={() => transactionHistoryModalRef.current?.open()}
            >
              Balance Sheet
            </Button>
          </Stack>

          <Stack sx={{ margin: 2, alignItems: 'end' }}>
            <Button
              variant="outlined"
              aria-label="Export Transactions"
              sx={{
                width: 'auto',
                height: '45px',
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                borderRadius: '8px',
                padding: '14px 20px 14px 20px',
                color: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary500,
                '&:hover': {
                  color: caloTheme.palette.primary600,
                  borderColor: caloTheme.palette.primary600
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  justifyItems: 'center',
                  margin: 'auto',
                  marginTop: 4,
                  width: 'auto'
                }
              }}
              onClick={() => exportTrx()}
            >
              Export
            </Button>
          </Stack>
        </Box>

        <Box
          component="form"
          autoComplete="off"
          sx={{
            width: 'full',
            mx: 2,
            '& .MuiTextField-root': { my: 2, mx: 2, width: '29%', justifyContent: 'space-between' },
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'full',
              display: 'flex',
              flexDirection: 'column',
              '& .MuiTextField-root': { my: 2, mx: 1, width: '100%', justifyContent: 'space-between' }
            }
          }}
        ></Box>

        <Box
          component="form"
          autoComplete="off"
          sx={{
            width: 'full',
            mx: 2,
            '& .MuiTextField-root': { my: 2, mx: 2, width: '29%', justifyContent: 'space-between' },
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'full',
              display: 'flex',
              flexDirection: 'column',
              '& .MuiTextField-root': { my: 2, mx: 1, width: '100%', justifyContent: 'space-between' }
            }
          }}
        ></Box>
      </Card>

      <Card variant="outlined" sx={{ width: '100%', border: 'none', borderRadius: '8px', marginTop: '14px' }}>
        <Box
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column'
            }
          }}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '19px',
              lineHeight: '23px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600
            }}
          >
            Transactions
          </Typography>
        </Box>
        <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
          {isTablet || isMobile ? (
            <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
              {isLoading ? (
                <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                  <CaloLoader />
                </Stack>
              ) : (
                <>
                  {list.map((row) => (
                    <TransactionCardRowMobile key={row.id} row={row} />
                  ))}
                </>
              )}
            </Box>
          ) : (
            <>
              {isLoading ? (
                <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                  <CaloLoader />
                </Stack>
              ) : (
                <>
                  {list.length === 0 ? (
                    <Typography
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{
                        justifyContent: 'center',
                        color: caloTheme.palette.neutral400,
                        fontSize: '33px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        mb: 5
                      }}
                    >
                      No Transactions Data
                    </Typography>
                  ) : (
                    <Table
                      sx={{
                        marginY: '4px',
                        minHeight: '120px',
                        tableLayout: 'fixed',
                        overflow: 'auto',
                        width: '100%',
                        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                          flexDirection: 'column'
                        }
                      }}
                    >
                      <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, width: '100%', borderRadius: '8px' }}>
                        <TableRow>
                          <StyledTableCell sx={{ width: '10%' }}>Time</StyledTableCell>
                          <StyledTableCell sx={{ width: '12%' }}>Source</StyledTableCell>
                          <StyledTableCell sx={{ width: '14%' }}>Action</StyledTableCell>
                          <StyledTableCell sx={{ width: '8%' }}>Notes</StyledTableCell>
                          <StyledTableCell sx={{ width: '9%' }}>Provider</StyledTableCell>
                          <StyledTableCell sx={{ width: '10%' }}>Status</StyledTableCell>
                          <StyledTableCell sx={{ width: '10%' }}>
                            Amount <sup>VAT EXCL.</sup>{' '}
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: '7%' }}>VAT</StyledTableCell>
                          <StyledTableCell sx={{ width: '9%' }}>Balance</StyledTableCell>
                          <StyledTableCell sx={{ width: '7%' }}>Actor</StyledTableCell>
                          <StyledTableCell sx={{ width: '9%' }}>Delivery</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {list.map((row) => (
                          <>
                            <TransactionCardRow row={row} key={row.id} />
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </>
              )}
            </>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              mb: 2,
              mt: '-4px',
              width: '100%',
              display: 'flex',
              justifyItems: 'center'
            }
          }}
        >
          {hasNextPage && (
            <Button
              variant="text"
              aria-label="Remove Suspension"
              sx={{
                display: 'flex',
                mb: 1,
                mx: 'auto',
                height: '45px',
                fontWeight: 600,
                lineHeight: '17px',
                fontSize: '14px',
                borderRadius: '8px',
                padding: '14px 20px 14px 20px',
                color: caloTheme.palette.primary500,
                '&:hover': {
                  color: caloTheme.palette.primary600
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  display: 'flex',
                  justifyItems: 'center',
                  m: 'auto',
                  mb: 2,
                  mt: '-4px',
                  width: 'auto'
                }
              }}
              onClick={() => fetchNextPage()}
            >
              Show More
            </Button>
          )}
        </Box>
      </Card>

      <Popup
        title={'Balance Sheet'}
        fullWidth={true}
        maxWidth="xl"
        ref={transactionHistoryModalRef}
        onClose={() => transactionHistoryModalRef.current?.close()}
      >
        <SubscriptionBalanceSheet userId={subscription.id} balance={subscription.balance[subscription.currency]} />
      </Popup>
    </>
  );
};
export default TransactionsCard;
