import parsePhoneNumberFromString from 'libphonenumber-js';
import { omit } from 'lodash-es';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Subscription } from '@calo/dashboard-types';
import { Box, Card, Stack, Typography } from '@mui/material';

import { createBusinessAccountSubscription } from 'actions/businessAccount';
import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import AccountForm from './AccountForm';

const NewAccount = (subscription: Subscription) => {
  const history = useHistory();
  const { mutateAsync: createMutation } = useMutation(createBusinessAccountSubscription);

  const handleCreateBusinessAccount = async (values: any) => {
    try {
      const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);
      const newValues = {
        ...omit(values, ['country', 'kitchen', 'deliveryAddress']),
        phoneNumber: phoneNumber!.number as string,
        deliveryAddresses: [{ ...values.deliveryAddress, kitchen: values.kitchen, country: values.country, isDefault: true }],
        fullName: values.fullName,
        email: values.email,
        deliveryTime: values.deliveryTime,
        brand: values.brand,
        language: 'en'
      };
      const ns = await createMutation({ ...newValues });
      history.replace(Routes.accountUser.replace(':id', ns.id));
    } catch (error: any) {
      if (error.message.includes('409')) {
        toast('Account already exists', { type: 'error', autoClose: 2000 });
      } else {
        toast('error', { type: 'error', autoClose: 2000 });
      }
    }
  };
  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          border: 'none',
          mb: 2,
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          display={'flex'}
          flexDirection="row"
          justifyContent={'space-between'}
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
          width="100%"
        >
          <Stack>
            <Typography
              variant="h6"
              sx={{
                mt: 'auto',
                fontWeight: 600,
                fontSize: '33px',
                lineHeight: '40px',
                color: caloTheme.palette.neutral900,
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              Accounts
            </Typography>
          </Stack>
        </Box>
      </Card>
      <Card
        variant="outlined"
        sx={{
          mb: 2,
          width: 'full',
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Stack padding={2}>
          <Typography
            sx={{
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '19px',
              lineHeight: '23px',
              color: caloTheme.palette.neutral900
            }}
          >
            Create Account
          </Typography>
        </Stack>
        <Stack>
          <AccountForm onSubmit={handleCreateBusinessAccount} subscription={subscription} />
        </Stack>
      </Card>
    </>
  );
};
export default NewAccount;
