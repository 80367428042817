import { selectedFoodData } from 'actions';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

const RedirctFoodID = () => {
  const { id } = useParams<{ id: string }>();

  const handleSlugFood = async () => {
    try {
      const selectedData = await selectedFoodData(id);
      return history.push(Routes.foodSlug.replace(':slug', selectedData.slug));
    } catch (error: any) {
      if (error.response && error.config.url?.includes('food') && error.response.status === 404) {
        toast('Meal does not exist', {
          type: 'error'
        });
        return history.push(Routes.foodList);
      }
    }
  };

  useEffect(() => {
    handleSlugFood();
  }, []);

  return (
    <div>
      <p className="mt-16 font-bold text-center text-3xl text-gray-400 uppercase"> Redirct to meal page</p>
    </div>
  );
};
export default RedirctFoodID;
