import { useCallback, useState } from 'react';

import cx from 'classnames';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { ImageUpdateLogReq, Permission } from '@calo/dashboard-types';

import { uploadImage } from 'actions';
import client from 'lib/client';
import { useUserRoles } from 'lib/hooks';

interface ImageUploaderProps {
  uploadLink: () => Promise<{ url: string; fields: Record<string, string | Blob> }>;
  image: string;
  disabled: boolean;
  values?: ImageUpdateLogReq;
  width?: number;
  maxHeight?: number;
  extra?: boolean;
}

export default ({ extra, image, uploadLink, disabled, values, width = 120, maxHeight }: ImageUploaderProps) => {
  const [progress, setProgress] = useState(0);
  const [displayImage, setDisplayImage] = useState<string>(image);

  const roles = useUserRoles();

  const onDrop = useCallback(
    async (files) => {
      if (!files) {
        return;
      }
      if (files && files[0].size > 62914560) {
        toast('Image size is too big', { type: 'error', autoClose: 2000 });
        return;
      }

      if (files && !files[0].type.includes('image')) {
        toast('Image only', { type: 'error', autoClose: 2000 });
        return;
      }

      const file = files[0];

      const { url, fields } = await uploadLink();

      const formData = new FormData();

      for (const key of Object.keys(fields)) {
        formData.append(key, fields[key]);
      }

      formData.append('file', file);

      setDisplayImage(URL.createObjectURL(file));

      const imageUpload = await uploadImage(url, formData, {
        onUploadProgress: (progressEvent) =>
          setProgress(Math.min(100, Math.round((progressEvent.loaded * 100) / progressEvent.total)))
      });

      if (imageUpload && values && roles.includes(Permission.CREATE_LOGS_ON_UPDATE_IMAGE)) {
        await client.post('image-change-log', values);
      }
    },
    [uploadLink]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="card-content">
      <figure className="image">
        <img
          alt="placeholder"
          className={cx('object-cover rounded-s-md', {
            'max-h-44': maxHeight && !extra,
            'width-120': width && !extra,
            'max-h-600px': maxHeight && extra,
            'width-auto': width && extra
          })}
          width={width}
          onError={(e: any) => (e.target.src = 'https://via.placeholder.com/120')}
          src={displayImage}
        />
      </figure>
      {disabled && (
        <div className="file is-boxed is-fullwidth max-h-32 w-auto" {...getRootProps()}>
          <div className="file-label">
            <input className="file-input" {...getInputProps()} accept="image/*" />
            <div className="flex-col items-center bg-gray-100 border border-gray-200 rounded">
              <span className="flex justify-center my-3">
                <i className="fas fa-upload"></i>
              </span>
              <p className="text-xs text-center mb-3">Browse or drag and drop your file…</p>
            </div>
          </div>
        </div>
      )}
      {progress > 0 && progress < 100 && (
        <>
          <br />
          <progress className="progress" value={progress} max="100">
            {progress}%
          </progress>
        </>
      )}
    </div>
  );
};
