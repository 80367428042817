import { GetFoodPreferenceReq } from '@calo/dashboard-types';
import { useFormik } from 'formik';

export default (foodSlugs: any[], onSubmit: (values: GetFoodPreferenceReq) => any) =>
  useFormik<GetFoodPreferenceReq>({
    enableReinitialize: true,
    initialValues: {
      blockMealList: foodSlugs
    },
    validate: (_: GetFoodPreferenceReq) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
