import { Icon as Iconify } from '@iconify/react';
import { Box, Grid, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

interface FoodRestrictionsItemProps {
  keyProp: string;
  text: string;
  onClick: any;
}

const FoodRestrictionItem = ({ keyProp, text, onClick }: FoodRestrictionsItemProps) => (
  <Box
    key={keyProp}
    sx={{
      margin: '3px',
      flexDirection: 'row',
      borderRadius: '37px',
      flexWrap: 'wrap',
      background: caloTheme.palette.secondaryPink100,
      color: caloTheme.palette.red,
      padding: ' 8px 12px'
    }}
  >
    <Grid key={keyProp}>
      <Typography sx={{ display: 'flex', flexDirection: 'row', mr: '2px' }}>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '18px',
            marginX: 1
          }}
        >
          {text}
        </Typography>
        <Iconify
          icon="ph:x-bold"
          color={caloTheme.palette.red}
          style={{ cursor: 'pointer', margin: 'auto', marginRight: 2 }}
          onClick={onClick}
        />
      </Typography>
    </Grid>
  </Box>
);

export default FoodRestrictionItem;
