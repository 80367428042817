import { SxProps, Theme } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

const styles: { [key: string]: SxProps<Theme> | undefined } = {
  mainStack: {
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      width: '100%',
      justifyContent: 'center'
    }
  },
  indexCard: {
    ml: 4,
    borderWidth: 0,
    width: '100%',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      ml: 3,
      width: '94%',
      justifyContent: 'center'
    }
  },
  indexText: {
    ml: '6px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: caloTheme.typography.fontFamily
  },
  infoStack: {
    width: '100%',
    height: '85px',
    marginX: 2,
    gridColumn: 4,
    marginY: 1,
    backgroundColor: caloTheme.palette.primary50,
    borderRadius: '8px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      width: '90%',
      mx: 2
    }
  },
  infoText: {
    m: 1,
    mb: 1,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    fontFamily: caloTheme.typography.fontFamily
  },
  actionButton: {
    width: 'auto',
    height: '25px',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
    padding: '14px 20px 14px 20px',
    marginLeft: '10px',
    color: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    '&:hover': {
      color: caloTheme.palette.primary600,
      borderColor: caloTheme.palette.primary600
    }
  },
  resultText: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '19px',
    lineHeight: '23px',
    fontFamily: caloTheme.typography.fontFamily,
    color: '#000000'
  }
};

export default styles;
