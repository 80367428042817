import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  stack: {
    width: '98%',
    m: 2,
    mt: 0
  },
  typographyLabels: {
    color: caloTheme.palette.neutral900,
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '23px'
  },
  boxWithWidth: {
    width: '30%'
  },
  boxHalfWidth: {
    width: '50%'
  },
  stackRow: {
    pb: 3,
    borderBottom: `1px solid ${caloTheme.palette.neutral100}`
  },
  marginTop20: {
    color: caloTheme.palette.neutral900,
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '23px',
    marginTop: '20px'
  },
  menuLabelTypography: {
    paddingY: '8px',
    paddingLeft: '12px',
    fontWeight: 600,
    color: caloTheme.palette.neutral700
  },
  menuLabelBox: {
    justifyContent: 'justify-between',
    gap: '8px',
    display: 'flex',
    borderRadius: 10,
    alignItems: 'center',
    backgroundColor: caloTheme.palette.neutral50
  },
  removeMenuTags: { cursor: 'pointer', color: caloTheme.palette.neutral900, mr: '8px' },
  fullWidthBox: {
    width: '100%'
  },
  heatingInstructionsBox: {
    width: '32%'
  },
  heatingInstructionInput: {
    marginTop: '18px',
    width: '100%'
  },
  spicySnackBox: {
    width: '20%'
  },
  spicySnackFullWidthBox: {
    width: '50%'
  }
};
