import { DeliveryFood } from '@calo/types';

export const extractMealNutrition = (meal: DeliveryFood) => {
  return {
    cal: meal.macros?.cal || 0,
    fat: meal.macros?.fat || 0,
    protein: meal.macros?.protein || 0,
    carbs: meal.macros?.carbs || 0,
    fiber: meal.macros?.fiber || 0,
    cholesterol: meal.micronutrients?.cholesterol || 0,
    totalSugar: meal.micronutrients?.totalSugar || 0,
    addedSugar: meal.micronutrients?.addedSugar || 0,
    transFats: meal.micronutrients?.transFats || 0,
    sodium: meal.micronutrients?.sodium || 0,
    saturatedFats: meal.micronutrients?.saturatedFats || 0
  };
};
