import { getImageUploadLink, uploadImage } from 'actions';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

interface handleImageProps {
  files: FileList | null;
  uuIDCreate: string;
  setLoadingImage: (val: boolean) => void;
  urlFile: string;
  setProgress?: (val: number) => void;
  setDisplayImage: React.Dispatch<React.SetStateAction<string[]>>;
  disabled: boolean;
}

export const handleUploadImage = async ({
  disabled,
  setDisplayImage,
  setLoadingImage,
  urlFile,
  uuIDCreate,
  setProgress,
  files
}: handleImageProps) => {
  if (!files || disabled) {
    return;
  }
  const imageFiles = [...files];

  for (const file of imageFiles) {
    if (!file.type.includes('image')) {
      toast('Image only', { type: 'error', autoClose: 2000 });
      continue;
    }

    setLoadingImage(true);
    const uuId = uuid();
    const imageExtension = getFileExtension(file);
    const { url, fields } = await getImageUploadLink(`${urlFile}/${uuIDCreate}/${uuId}.${imageExtension}`, ' ');

    const formData = new FormData();
    for (const key of Object.keys(fields)) {
      formData.append(key, fields[key]);
    }
    formData.append('file', file);

    let imageUpload;
    if (setProgress) {
      imageUpload = await uploadImage(url, formData, {
        onUploadProgress: (progressEvent) =>
          setProgress(Math.min(100, Math.round((progressEvent.loaded * 100) / progressEvent.total)))
      }).finally(() => toast('uploaded', { type: 'success', autoClose: 2000 }));
    } else {
      imageUpload = await uploadImage(url, formData, {}).finally(() => toast('uploaded', { type: 'success', autoClose: 2000 }));
    }

    if (imageUpload) {
      setDisplayImage((prevImages) => [...prevImages, fields['key']]);
      setLoadingImage(false);
    }
  }
};

export const getFileExtension = (file: File) => {
  const fileName = file.name;
  const extension = fileName.slice(fileName.lastIndexOf('.') + 1);
  return extension;
};
