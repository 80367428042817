import React from 'react';
import { toast } from 'react-toastify';
import { Icon, Modal, ModalRef } from '../';
import { MAX_IMAGE_SIZE } from './types';

interface ComponentMethodImageModalProps {
  step: any;
  index: number;
  selected: any;
  progress: number;
  setSelected: (value: any) => void;
  handleUploadImage: (file: any) => void;
  handleRemoveImage: (stepDesc: any) => void;
  componentMethodImageRef: React.MutableRefObject<ModalRef | undefined>;
}

const ComponentMethodImageModal: React.FC<ComponentMethodImageModalProps> = ({
  componentMethodImageRef,
  selected,
  setSelected,
  handleUploadImage,
  progress,
  index,
  handleRemoveImage,
  step
}) => {
  const handleEditImage = () => {
    setSelected({
      description: step.description || selected.description,
      station: step.station || selected.station,
      index: index,
      attachment: step.attachment || selected.attachment
    });
  };

  const handleOnChangeUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0].size > MAX_IMAGE_SIZE) {
      toast('Image size is too big', { type: 'error', autoClose: 2000 });
    } else {
      const file = e.target.files && e.target.files[0];
      handleUploadImage(file);
    }
  };
  return (
    <Modal ref={componentMethodImageRef} onClose={() => componentMethodImageRef.current?.close()}>
      <section className="section is-title-bar label -my-8 ">
        <div className="level -mb-4">
          <div className="level-left">
            <div className="level-item">
              <div className="file is-boxed is-fullwidth">
                <div className="file-label">
                  <input
                    className="file-input cursor-pointer fas fa-pen"
                    accept="image/*"
                    type="file"
                    onClick={handleEditImage}
                    onChange={handleOnChangeUploadImage}
                  />
                  <Icon size={6} name="edit" onClick={handleEditImage} className="cursor-pointer mr-4" />
                </div>
              </div>
              <Icon
                name="trashCan"
                size={16}
                onClick={() => handleRemoveImage({ description: step.description, index: index })}
                className="-mb-10 cursor-pointer"
              />
            </div>
          </div>
          <div className="level-right">
            <div className="level-right">
              <i
                className="fas fa-times ml-2 cursor-pointer float-right"
                onClick={() => {
                  componentMethodImageRef.current?.close();
                  setSelected('');
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mx-auto">
        <div className="-mb-4 mt-4">
          <figure className="-m-4">
            <img
              alt="placeholder"
              className="object-cover rounded-md"
              onError={(e: any) => (e.target.src = 'https://via.placeholder.com/120')}
              src={selected.attachment}
            />
          </figure>
        </div>
        <div>
          {progress > 0 && progress < 100 && (
            <>
              <br />
              <progress className="progress" value={progress} max="100">
                {progress}%
              </progress>
            </>
          )}
        </div>
      </section>
    </Modal>
  );
};

export default ComponentMethodImageModal;
