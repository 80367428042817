import { SyncFoodComponentReq } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { getKitchenShortCut } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { useState } from 'react';

interface KitchensDropdownProps {
  values: SyncFoodComponentReq;
  currentKitchen: Kitchen;
  selectKitchen: (kitchen: Kitchen) => void;
}

const KitchensDropdown = ({ values, currentKitchen, selectKitchen }: KitchensDropdownProps) => {
  const userKitchens: Kitchen[] = useUserKitchens();
  const [selectedKitchen, setSelectedKitchen] = useState('');

  const kitchenOptions = userKitchens.filter((kitchen) => kitchen !== Kitchen.OM1 && kitchen !== currentKitchen);

  return (
    <Stack>
      <Typography sx={{ my: '10px' }}>Select the kitchen to sync component with:</Typography>
      <TextField
        select
        name="kitchens"
        label="Choose MP"
        value={selectedKitchen}
        onChange={(event) => setSelectedKitchen(event.target.value)}
        InputProps={{ style: { borderRadius: 8, position: 'relative' } }}
      >
        {kitchenOptions
          .filter((kitchen) => !values.kitchens.includes(kitchen))
          .map((kitchen) => (
            <MenuItem key={kitchen} value={kitchen} onClick={() => selectKitchen(kitchen)}>
              <Box>{getKitchenShortCut(kitchen)}</Box>
            </MenuItem>
          ))}
      </TextField>
    </Stack>
  );
};

export default KitchensDropdown;
