import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  card: {
    width: 'full',
    mb: '14px',
    border: 'none',
    borderRadius: '8px',
    paddingBottom: '4px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  tableTitle: {
    py: 2,
    textAlign: 'start',
    fontFamily: caloTheme.typography.fontFamily,
    fontWeight: 600,
    lineHeight: '23px',
    color: caloTheme.palette.neutral900,
    fontSize: '19px'
  },
  mainTable: {
    marginY: '4px',
    minHeight: '120px',
    overflow: 'auto',
    width: '100%',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  tableHead: {
    backgroundColor: caloTheme.palette.neutral50,
    color: 'black',
    flexWrap: 0,
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '8px'
  },
  tableRow: {
    backgroundColor: caloTheme.palette.neutral50,
    color: 'black',
    width: '100%',
    flexWrap: 0,
    justifyContent: 'space-between'
  },
  noStatsText: {
    mt: 3,
    textAlign: 'center',
    width: 'full',
    fontSize: '24px',
    color: caloTheme.palette.neutral400
  }
};

export default styles;
