import { useRef } from 'react';

import { Link } from 'react-router-dom';

import { FoodStatus, Permission, PrototypeAction } from '@calo/dashboard-types';
import { Country } from '@calo/types';
import { Icon as Iconify } from '@iconify/react';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';

import { addPrototypeFoodAction, approvePrototypeFood } from 'actions/food';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef, UsedOnMenuIndicator } from 'components';
import { Routes } from 'lib/enums';
import { resolveCountry } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { Food } from 'lib/interfaces';
import { isEmpty } from 'lodash';
import { useMutation } from 'react-query';
import StatusBox from 'views/ChefPlayground/Shared/StatusBox';
import DeleteSizeModal from './DeleteSizeModal';
import MealName from './MealName';
import NewSizeModal from './NewSizeModal';
import Ratings from './Ratings';

interface HeaderProps {
  selectedFood: Food;
  foodWithAllSizes: Food[];
  setSelectedFood: React.Dispatch<React.SetStateAction<Food | undefined>>;
  setFoodWithAllSizes: (food: Food[]) => void;
  filteredFoodUsed: {
    type: 'mainMenu' | 'addonsMenu';
    date?: string | undefined;
    week?: number | undefined;
  }[];
  usedOnMenuButtonColor: string[];
  isDisabled?: boolean;
  refetch: any;
}

const Header = ({
  selectedFood,
  foodWithAllSizes,
  setSelectedFood,
  setFoodWithAllSizes,
  filteredFoodUsed,
  usedOnMenuButtonColor,
  isDisabled,
  refetch
}: HeaderProps) => {
  const roles = useUserRoles();

  const addMealsModalRef = useRef<ModalRef>();
  const deleteMealModalRef = useRef<ModalRef>();

  const { mutate: createAction, isLoading: actionLoading } = useMutation(addPrototypeFoodAction, {
    onSuccess: () => {
      refetch();
    }
  });

  const { mutate: approveFood, isLoading: approveLoading } = useMutation(approvePrototypeFood, {
    onSuccess: () => {
      refetch();
    }
  });

  const isApproved = selectedFood.status === FoodStatus.approved;
  const isWaitingApproval = selectedFood.status === FoodStatus.pending;

  return (
    <Stack direction="column" justifyContent="space-between" spacing={2} sx={{ backgroundColor: caloTheme.palette.white }}>
      <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ mx: 2, zIndex: 0 }}>
        <Link to={Routes.playgroundFoodList}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Iconify icon="fluent:chevron-left-24-filled" color={caloTheme.palette.neutral900} style={{ fontSize: '16px' }} />
            <Typography sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '20px', color: caloTheme.palette.black }}>
              Back
            </Typography>
          </Stack>
        </Link>
        <Typography sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '20px', color: caloTheme.palette.black, mr: 1 }}>
          {resolveCountry(selectedFood.country || Country.BH)}
        </Typography>
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <MealName selectedFood={selectedFood} />
          <Typography variant="h6" className="text-center">
            {selectedFood.kitchen}
          </Typography>
          <Ratings selectedFood={selectedFood} foodWithAllSizes={foodWithAllSizes} />
          <UsedOnMenuIndicator filteredFoodUsed={filteredFoodUsed} usedOnMenuButtonColor={usedOnMenuButtonColor} type="food" />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          {roles.includes(Permission.DELETE_PROTOTYPE_FOOD) && (
            <IconButton disabled={isDisabled} onClick={() => deleteMealModalRef.current?.open()}>
              <Iconify color={isDisabled ? caloTheme.palette.neutral500 : caloTheme.palette.red} width={30} icon="uil:trash" />
            </IconButton>
          )}
          {!isApproved && !isWaitingApproval && (
            <Button
              variant="outlined"
              disabled={!selectedFood || isEmpty(foodWithAllSizes) || actionLoading || isApproved}
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                textTransform: 'capitalize',
                borderRadius: '8px',
                boxShadow: 'none',
                color: caloTheme.palette.neutral900,
                borderColor: caloTheme.palette.neutral900,
                backgroundColor: 'transparent',
                '&:hover': {
                  borderColor: caloTheme.palette.neutral900,
                  backgroundColor: caloTheme.palette.white
                }
              }}
              onClick={() => createAction({ action: PrototypeAction.pend, id: selectedFood.id })}
            >
              Send for Approval
            </Button>
          )}

          {isWaitingApproval && !isApproved && (
            <Button
              variant="outlined"
              disabled={
                !selectedFood ||
                isEmpty(foodWithAllSizes) ||
                approveLoading ||
                actionLoading ||
                isApproved ||
                !roles.includes(Permission.APPROVE_PROTOTYPE_FOOD)
              }
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                textTransform: 'capitalize',
                borderRadius: '8px',
                boxShadow: 'none',
                color: caloTheme.palette.white,
                borderColor: caloTheme.palette.primary500,
                backgroundColor: caloTheme.palette.primary500,
                '&:hover': {
                  borderColor: caloTheme.palette.primary500,
                  backgroundColor: caloTheme.palette.primary700
                }
              }}
              onClick={() => approveFood(selectedFood.id)}
            >
              Approve
            </Button>
          )}

          {isApproved && <StatusBox status={FoodStatus.approved} />}
        </Stack>
        <NewSizeModal
          selectedFood={selectedFood}
          foodWithAllSizes={foodWithAllSizes}
          setSelectedFood={setSelectedFood}
          setFoodWithAllSizes={setFoodWithAllSizes}
          ref={addMealsModalRef}
          close={() => addMealsModalRef.current?.close()}
        />
        <DeleteSizeModal
          foodWithAllSizes={foodWithAllSizes}
          ref={deleteMealModalRef}
          close={() => deleteMealModalRef.current?.close()}
        />
      </Stack>
    </Stack>
  );
};

export default Header;
