import { v4 as uuid } from 'uuid';

import { Stack, styled, TableCell, tableCellClasses, TableRow, Tooltip, Typography } from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ComponentFoodSubStats } from 'lib/interfaces';
import { round } from 'lodash-es';
interface ComponentRowProps {
  meal: ComponentFoodSubStats;
  switchUnit: boolean;
  cookedRawFactor: number;
}

export default function ComponentRow({ meal, cookedRawFactor, switchUnit }: ComponentRowProps) {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '19px',
      textTransform: 'capitalize'
    }
  }));

  return (
    <TableRow key={uuid()}>
      <StyledTableCell sx={{ fontWeight: 600, textTransform: 'capitalize' }}>
        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography>{meal.name.en}</Typography>
          {meal.components && (
            <Tooltip title={`Child Component in: ${meal.components.map((comp) => comp.name.en).join(' - ')}`}>
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          )}
        </Stack>
      </StyledTableCell>
      <StyledTableCell sx={{ fontWeight: 400 }}>{meal.size}</StyledTableCell>
      <StyledTableCell sx={{ fontWeight: 400 }}>{meal.mealCount - meal.specialRequestsQuantity}</StyledTableCell>
      <StyledTableCell sx={{ fontWeight: 400 }}>{meal.specialRequestsQuantity}</StyledTableCell>
      <StyledTableCell sx={{ fontWeight: 400 }}>
        {Math.ceil(cookedRawFactor * (meal.weight - (meal.removedWeight || 0)))}
      </StyledTableCell>
      <StyledTableCell sx={{ fontWeight: 400 }}>
        {(switchUnit ? round(meal.quantity, 1) : round(meal.weight, 1)) - (meal.removedWeight || 0)}
      </StyledTableCell>
      <StyledTableCell sx={{ fontWeight: 400 }}>{(switchUnit ? meal.removedQuantity : meal.removedWeight) ?? 0}</StyledTableCell>
    </TableRow>
  );
}
