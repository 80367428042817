import { Permission } from '@calo/dashboard-types';
import { Brand, RetailBranch } from '@calo/types';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { getListWithParams } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { generatePath, RouteComponentProps, useLocation } from 'react-router';
import { RetailFilter } from '../types';
import RetailMenuRow from './RetailMenuRow';
import Settings from './Settings';

type AddonsListProps = RouteComponentProps;

const retailBranchValues = Object.values(RetailBranch);

const RetailMenu = ({ history }: AddonsListProps) => {
  const roles = useUserRoles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState<RetailFilter>({
    brand: Brand.CALO,
    branch: retailBranchValues.includes(location.pathname.split('/')[2] as RetailBranch)
      ? location.pathname.split('/')[2]
      : RetailBranch.SEEF,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  useEffect(() => {
    const path = generatePath(Routes.retailMenu, { brand: filters.brand, branch: filters.branch });
    history.push(path);
    searchParams.set('filters', JSON.stringify(filters));

    history.push({
      pathname: path,
      search: searchParams.toString()
    });
  }, [filters.branch]);

  const { data: retailMenu, isLoading } = useQuery<any, Error, any>(
    ['retail-menu', { brand: filters.brand, branch: filters.branch }],
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          display={'flex'}
          flexDirection="row"
          justifyContent={'space-between'}
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
          width="100%"
        >
          <Stack width={'50%'} display={'flex'} flexDirection={'row'} alignItems="start" justifyContent={'space-between'}>
            <Typography
              variant="h3"
              sx={{
                mt: 'auto',
                fontWeight: 600,
                fontSize: '33px',
                lineHeight: '40px',
                color: caloTheme.palette.neutral900,
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              Retail Menu / {filters.branch}
            </Typography>
          </Stack>
          {roles.includes(Permission.UPDATE_RETAIL_MENU_ITEMS) && (
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                startIcon={<i className="fas fa-pencil" />}
                onClick={() => {
                  history.push(Routes.retailNewMenu.replace(':brand', filters.brand).replace(':branch', filters.branch));
                }}
              >
                Edit
              </Button>
            </Stack>
          )}
        </Box>
        <Settings onFilter={setFilters} filters={filters} />
        <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
          {isLoading ? (
            <Stack sx={{ width: '100%', justifyContent: 'center' }}>
              <CaloLoader />
            </Stack>
          ) : retailMenu ? (
            <RetailMenuRow addons={retailMenu} />
          ) : (
            <Typography variant="h6" sx={{ textAlign: 'center', marginY: 2 }}>
              No Retail Menu added
            </Typography>
          )}
        </Box>
      </Card>
    </>
  );
};

export default RetailMenu;
