import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

interface WarningTextProps {
  message: string;
}
const WarningText = ({ message }: WarningTextProps) => {
  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: caloTheme.palette.warning.main,
        fontSize: '16px',
        textAlign: 'center',
        width: '100%'
      }}
    >
      <Icon icon="ph:warning" width="1.2em" height="1.2em" style={{ color: '#FF5F15', marginRight: '0.5em' }} />
      {message}
    </Typography>
  );
};

export default WarningText;
