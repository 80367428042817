import { caloTheme } from 'assets/images/theme/calo';
import { theme } from 'lib/componentStyles';
import { KDSUserGroup } from 'lib/interfaces';

import { TableCell, TableRow } from '@mui/material';

import CheckboxCell from './CheckboxCell';

interface TableHeadCellProps {
  setSelectedGroup: React.Dispatch<React.SetStateAction<KDSUserGroup>>;
  selectedGroup: KDSUserGroup | null;
  kdsUserGroup: KDSUserGroup[];
  permission: string;
}

const TableBodyCell = ({ kdsUserGroup, setSelectedGroup, selectedGroup, permission }: TableHeadCellProps) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          fontSize: 14,
          position: 'sticky',
          left: 0,
          backgroundColor: caloTheme.palette.white,
          zIndex: theme.zIndex.modal + 1
        }}
      >
        {permission}
      </TableCell>
      {kdsUserGroup.length > 0 &&
        kdsUserGroup.map((role, index) => (
          <CheckboxCell
            key={index}
            setSelectedRole={setSelectedGroup}
            selectedRole={selectedGroup}
            permission={permission}
            role={role}
          />
        ))}
    </TableRow>
  );
};

export default TableBodyCell;
