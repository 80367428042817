import { Brand, Country, Kitchen } from '@calo/types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { useDispatch, useSelector } from 'react-redux';

import { toggleUISettings } from 'actions';
import { Select2, SideDrawer } from 'components';
import { parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import { getAccessibleCountries, getKitchenOptions } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';

interface AddonsFilter {
  country: Country;
  date: {
    gte: string;
    lte?: string;
  };
  brand: Brand;
  kitchen: Kitchen;
}

interface SettingsProps {
  onFilter: (values: AddonsFilter) => any;
  filters: AddonsFilter;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Country"
          value={filters.country}
          onChange={(data) =>
            onFilter({
              ...filters,
              country: data.target.value as Country,
              kitchen: userKitchens.find((k) => k.includes(data.target.value)) as Kitchen
            })
          }
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(data) => onFilter({ ...filters, kitchen: data.target.value as Kitchen })}
          options={getKitchenOptions(userKitchens, filters.country)}
          disabled={Object.values(Kitchen).filter((r) => r.includes(filters.country!)).length === 0}
        />
        <div className="field">
          <label className="label">date</label>
          <div className="control is-clearfix">
            <DateRangePicker
              clearIcon={filters.date.lte !== undefined && undefined}
              onChange={(dates: any) => {
                onFilter({
                  ...filters,
                  date: {
                    gte: dates ? format('yyyy-MM-dd')(dates[0]) : format('yyyy-MM-dd')(Date.now()),
                    lte: dates && dates[1] ? format('yyyy-MM-dd')(dates[1]) : undefined
                  }
                });
              }}
              value={[parseISO(filters.date.gte), filters.date.lte ? parseISO(filters.date.lte) : undefined]}
            />
          </div>
        </div>
      </section>
    </SideDrawer>
  );
};

export default Settings;
