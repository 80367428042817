import { Link } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Kitchen } from 'lib/interfaces';
import Stats from './Stats';

interface KitchenRowProps {
  kitchen: Kitchen;
}

const KitchenRow = ({ kitchen }: KitchenRowProps) => {
  const roles = useUserRoles();
  return (
    <tr>
      <td>
        {roles.includes(Permission.VIEW_KITCHEN) ? (
          <Link to={Routes.kitchen.replace(':id', kitchen.id)}>{kitchen.id}</Link>
        ) : (
          kitchen.id
        )}
      </td>
      <td>{kitchen.capacity}</td>
      {roles.includes(Permission.VIEW_KITCHEN_STATS) && <Stats kitchen={kitchen} />}
    </tr>
  );
};

export default KitchenRow;
