import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Route, Switch } from 'react-router-dom';
import PaymentList from './PaymentList';

const Payment = () => {
  const roles = useUserRoles();

  return (
    <Switch>
      {roles.includes(Permission.VIEW_WALLET_LIST) && (
        <Route key={Routes.payment} exact path={Routes.payment} component={PaymentList} />
      )}
    </Switch>
  );
};

export default Payment;
