import { Food, MenuFood } from '../interfaces';

export const getFoodAggregatedRatingsForPeriod = (food: Food | MenuFood, period: number) => {
  // based on the periodicAggregatedRatings property in food, if it has an object with periodInDays equals to period, return it, otherwise return false
  const aggregatedRatings = food.periodicAggregatedRatings;

  if (!aggregatedRatings || aggregatedRatings.length === 0) {
    return;
  }

  const aggregatedRatingsForPeriod = aggregatedRatings.find((rating) => rating.periodInDays === period);

  if (aggregatedRatingsForPeriod) {
    return aggregatedRatingsForPeriod;
  }
};
