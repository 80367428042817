import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { Card } from '@mui/material';

import { getRecord } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { AccountPages } from 'lib/enums';
import { BusinessAccount } from 'lib/interfaces';
import AccountCard from './AccountCard';
import AccountDeliveriesCard from './AccountDeliveriesCard';
import AccountInformationCard from './AccountInformationCard';
import AccountTabs from './AccountTabs';

const ExactAccount = () => {
  const { id } = useParams<{ id: string }>();
  const [selectedPage, setSelectedPage] = useState<AccountPages>(AccountPages.userInfo);

  const { data, refetch } = useQuery(['business/users', id], getRecord, {
    suspense: true
  });
  const account = data as BusinessAccount;

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: '14px',
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <AccountCard key={`${account.id}-accCard`} account={account} />
        <AccountTabs
          key={`${account.id}-accTab`}
          selectedPage={selectedPage}
          setSelectedPage={(value) => setSelectedPage(value)}
        />
      </Card>

      {selectedPage === AccountPages.userInfo && (
        <AccountInformationCard key={`${account.id}-userInfoCard`} account={account} refetch={refetch} />
      )}
      {selectedPage === AccountPages.deliveries && (
        <AccountDeliveriesCard key={`${account.id}-userDelCard`} account={account} refetch={refetch} />
      )}
    </>
  );
};
export default ExactAccount;
