import { addAddonFood } from 'actions/delivery';
import mutation from 'actions/mutation';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import { endOfWeek, getWeek, getYear } from 'date-fns';
import { Delivery, Menu, MenuFood } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import { useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation } from 'react-query';
import MenuFoodPicker from 'views/Deliveries/ExactDelivery/MenuTab/MenuFoodPicker';

import { AddonAddedAsType } from '@calo/dashboard-types';
import { FoodType } from '@calo/types';
import { Box, Stack, Typography } from '@mui/material';

import AddonsPickerPopup from '../../DeliveryAction/AddonsPickerPopup';

interface AddonPopupProps {
  menu: Menu;
  selectedDelivery: Delivery;
  selectedAddType: AddonAddedAsType;
  addonModalRef: React.MutableRefObject<ModalRef | undefined>;
  refetchDelivery: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  menuLoading?: boolean;
}

const AddonPopup = ({
  menu,
  addonModalRef,
  selectedDelivery,
  selectedAddType,
  refetchDelivery,
  menuLoading
}: AddonPopupProps) => {
  const [isGifted, setIsGifted] = useState<boolean>(false);
  const [addonType, setAddonType] = useState(FoodType.coffee);
  const [selectedAddon, setSelectedAddon] = useState<MenuFood | null>(null);
  const { mutateAsync: addAddonMutation } = useMutation(addAddonFood);

  const week = getWeek(new Date(selectedDelivery.day));
  const year = getYear(endOfWeek(new Date(selectedDelivery.day)));

  const handleAddAddonRequest = async (mealAdded?: MenuFood | null) => {
    if (!mealAdded) return;
    await addAddonMutation(
      { foodType: addonType, foodId: mealAdded.id, id: selectedDelivery.id, isGifted: isGifted, addedAs: selectedAddType },
      {
        onSuccess: (data) => {
          const query = queryClient.getQueryData(['deliveries', selectedDelivery.id]) as any;
          const deliveryUpdated = { ...query, addons: data.addons, cost: data.cost };
          mutation(['deliveries', selectedDelivery.id], deliveryUpdated);
          setSelectedAddon(null);
          setIsGifted(false);
          addonModalRef.current?.close();
          refetchDelivery();
        }
      }
    );
  };

  return (
    <Popup
      ref={addonModalRef}
      maxWidth={'lg'}
      onClose={() => {
        addonModalRef.current?.close();
        setSelectedAddon(null);
      }}
    >
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ mb: 2 }}>
        <Stack>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '19px',
              fontFamily: caloTheme.typography.fontFamily,
              textTransform: 'uppercase',
              mb: '-4px'
            }}
          >
            {selectedAddType === AddonAddedAsType.addon ? 'Add new addon' : 'Add new meal'}
          </Typography>
        </Stack>
      </Box>
      {selectedAddType === AddonAddedAsType.addon ? (
        <AddonsPickerPopup
          year={year}
          week={week}
          isGifted={isGifted}
          addonType={addonType}
          setIsGifted={setIsGifted}
          delivery={selectedDelivery}
          setAddonType={setAddonType}
          addonModalRef={addonModalRef}
          selectedAddon={selectedAddon}
          country={selectedDelivery.country}
          setSelectedAddon={setSelectedAddon}
          handleAddAddonRequest={handleAddAddonRequest}
        />
      ) : (
        <MenuFoodPicker
          menu={menu}
          gifted={true}
          isGifted={isGifted}
          setIsGifted={setIsGifted}
          setMealType={setAddonType}
          delivery={selectedDelivery}
          menuModalRef={addonModalRef}
          refetchDelivery={refetchDelivery}
          deliveryBalance={selectedDelivery.balance}
          handleAddMealRequest={(v) => handleAddAddonRequest(v)}
          menuLoading={menuLoading}
        />
      )}
    </Popup>
  );
};

export default AddonPopup;
