import { FoodService } from '@calo/services';
import { getCaloFoodTypePrice } from '@calo/services/dist/constants';
import { DietType, FoodType, Kitchen, SubscriptionTier } from '@calo/types';
import { sumBy } from 'lodash-es';
import { toast } from 'react-toastify';
import { MenuFood } from '../interfaces';
import { checkCountryCurrency, resolveCountryFromKitchen } from './countryUtils';

export const formatNumber = (num: number) => {
  const roundedNumber = num % 1 === 0 ? num.toFixed(0) : num.toFixed(2).replace(/\.?0*$/, '');
  return roundedNumber;
};

export enum KitchenWeighted {
  BH1 = 'BH1',
  SA1 = 'SA1',
  SA2 = 'SA2',
  AE1 = 'AE1',
  KW1 = 'KW1',
  GB1 = 'GB1'
}

const weightedAverages: {
  [key in KitchenWeighted]: { [size: string]: number };
} = {
  [KitchenWeighted.BH1]: { L: 0.32, M: 0.48, S: 0.2 },
  [KitchenWeighted.SA1]: { L: 0.16, M: 0.49, S: 0.34 },
  [KitchenWeighted.SA2]: { L: 0.17, M: 0.52, S: 0.32 },
  [KitchenWeighted.AE1]: { L: 0.22, M: 0.5, S: 0.28 },
  [KitchenWeighted.KW1]: { L: 0.21, M: 0.45, S: 0.33 },
  [KitchenWeighted.GB1]: { L: 0, M: 0, S: 0 } // TODO: Add GB1 weighted averages
};

export const getWeightedAverage = (kitchen: Kitchen | KitchenWeighted, size: string): number => {
  const kitchenKey = (Object.values(KitchenWeighted) as string[]).includes(kitchen as string)
    ? (kitchen as KitchenWeighted)
    : KitchenWeighted.BH1;
  const kitchenAverages = weightedAverages[kitchenKey];

  return kitchenAverages && kitchenAverages[size] ? kitchenAverages[size] : 0;
};

export const calculateTotalCost = (meal: any): number => {
  let totalCost = 0;
  if (meal.components) {
    for (const component of meal.components) {
      const { quantity, cost } = component;
      const componentCost = cost || 0;
      totalCost += componentCost * quantity;
    }
    return totalCost;
  }

  return 0;
};

export const handleMealCost = (mealName: string, sortedList: MenuFood[], kitchen: Kitchen) => {
  const mealSizes = ['L', 'M', 'S'];
  const singleMealSizeVariants = sortedList
    .filter((food) => food.name.en === mealName && mealSizes.includes(food.size))
    .map((f) => ({ ...f, kitchen }));
  if (singleMealSizeVariants.length === 0) {
    return 0;
  }

  const weightedAverageCost =
    singleMealSizeVariants.length < 3
      ? sumBy(singleMealSizeVariants, calculateTotalCost) / singleMealSizeVariants.length
      : sumBy(singleMealSizeVariants, (meal) => calculateTotalCost(meal) * getWeightedAverage(kitchen || Kitchen.BH1, meal.size));

  const selectedDietType = FoodService.getDiet(singleMealSizeVariants[0] as any);
  try {
    const sellingPrice = getCaloFoodTypePrice({
      tier: SubscriptionTier.personalized,
      currency: checkCountryCurrency(resolveCountryFromKitchen(kitchen || Kitchen.BH1)),
      dietType: selectedDietType,
      foodType: singleMealSizeVariants[0].type[0]
    });
    const percentageCost = +formatNumber((weightedAverageCost * 100) / +sellingPrice);

    return percentageCost;
  } catch (error) {
    toast(`For meal: ${mealName} error: ${error}`, { type: 'error', autoClose: 2000 });
    return 0;
  }
};

export const findDietType = (tags: DietType[], mealType: FoodType[]) => {
  if (tags.includes(DietType.balanced)) {
    return DietType.balanced;
  }
  if (tags.includes(DietType.weightLoss) && !mealType.includes(FoodType.snack)) {
    return DietType.weightLoss;
  }

  if (tags.includes(DietType.vegetarian) && !mealType.includes(FoodType.snack)) {
    return DietType.vegetarian;
  }

  if (mealType.includes(FoodType.snack) && tags.length === 1 && tags[0] === DietType.weightLoss) {
    return DietType.weightLoss;
  }

  if (tags.includes(DietType.vegetarian)) {
    return DietType.vegetarian;
  }

  if (tags.includes(DietType.lowCarb)) {
    return DietType.lowCarb;
  }

  if (tags.includes(DietType.highProtein)) {
    return DietType.highProtein;
  }

  if (tags.includes('gourmet' as DietType) || tags.includes(DietType.chefPicks)) {
    return DietType.chefPicks;
  }

  const tag = tags.find((tag) => tag !== DietType.keto);

  if ((tag as string) === 'gourmet') {
    return DietType.chefPicks;
  }

  return tag;
};

export const handleMealSellingPrice = (kitchen: Kitchen, type: any, tags: string[], foodType: FoodType) => {
  try {
    const sellingPrice = getCaloFoodTypePrice({
      tier: SubscriptionTier.personalized,
      currency: checkCountryCurrency(resolveCountryFromKitchen(kitchen)),
      dietType: findDietType(tags as DietType[], type)!,
      foodType: foodType
    });
    return sellingPrice || 0;
  } catch (error) {
    console.error(error);
    return 0;
  }
};
