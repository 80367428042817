import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  card: {
    width: '100%',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: caloTheme.spacing(2),
    padding: caloTheme.spacing(2),
    borderRadius: '8px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  stack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column',
      marginTop: caloTheme.spacing(2)
    }
  },
  typography: {
    fontWeight: 600,
    fontSize: '20px',
    marginRight: caloTheme.spacing(2)
  },
  selectStack: {
    flexDirection: 'row'
  },
  filterButton: { borderWidth: 1, borderColor: '#343434', borderRadius: '8px', boxShadow: 'none', height: '46px', width: '56px' },
  datePickerStyles: {
    boxShadow: 'none',
    borderRadius: '8px',
    height: '46px',
    padding: '6px 0px',
    width: '110px',
    marginRight: '8px',
    borderWidth: 1,
    borderColor: '#343434'
  }
};
