import React, { DetailedHTMLProps, ButtonHTMLAttributes, MouseEvent, useState } from 'react';
import cx from 'classnames';

interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  content?: string;
  icon?: string;
  onClick?: (event: MouseEvent) => any;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  primary?: boolean;
  outlined?: boolean;
  warning?: boolean;
  fluid?: boolean;
  danger?: boolean;
}

const Button = ({
  content,
  onClick,
  disabled,
  loading,
  primary,
  outlined,
  warning,
  fluid,
  danger,
  className,
  icon,
  ...rest
}: ButtonProps) => {
  const [localLoading, setLocalLoading] = useState(false);

  const handleLocalClick = (e: MouseEvent) => {
    if (disabled) {
      return;
    }
    if (onClick) {
      const result = onClick(e);

      if (result instanceof Promise) {
        setLocalLoading(true);
        result.finally(() => {
          setLocalLoading(false);
        });
      }
    }
  };

  return (
    <button
      {...rest}
      onClick={handleLocalClick}
      className={cx(
        {
          button: true,
          'is-loading': loading || localLoading,
          'is-primary': primary,
          'is-outlined': outlined,
          'is-warning': warning,
          'is-fullwidth': fluid,
          'is-danger': danger
        },
        className
      )}
      disabled={disabled || localLoading || loading}
    >
      {content}
      {icon && <i className={icon}></i>}
    </button>
  );
};

export default Button;
