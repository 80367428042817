import { useState } from 'react';

import { format } from 'date-fns/fp';

import { CreateDeliveryReq } from '@calo/dashboard-types';
import { Subscription } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import { Box, Button, Stack, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { caloTheme } from 'assets/images/theme/calo';
import useNewDeliveryForm from './useNewDeliveryForm';

interface DeliveriesPanelProps {
  onSubmit: (value: CreateDeliveryReq) => Promise<void>;
  selectedDate: Date;
  subscription: Subscription & { remainingDays: number; expectedLastDeliveryDay: string; ratings: any[] };
}

const NewDeliveryForm = ({ subscription, onSubmit, selectedDate }: DeliveriesPanelProps) => {
  const [selectedNewDeliveryDay, setSelectedNewDeliveryDay] = useState<string>(format('yyyy-MM-dd')(selectedDate));

  const { isSubmitting, isValid } = useNewDeliveryForm(subscription, onSubmit);

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} m={2}>
        <Stack mb={2}>
          <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px' }}>
            <DesktopDatePicker
              label="Delivery Date"
              inputFormat="dd-MM-yyyy"
              value={selectedNewDeliveryDay}
              renderInput={(params) => <TextField {...params} />}
              onChange={(date) => setSelectedNewDeliveryDay(format('yyyy-MM-dd')(+date!))}
              minDate={format('yyyy-MM-dd')(Date.now())}
            />
          </LocalizationProvider>
        </Stack>
      </Box>
      <Box width={'full'} display={'flex'} flexDirection={'row'}>
        <Button
          fullWidth
          variant="contained"
          disabled={!isValid || isSubmitting || !selectedNewDeliveryDay}
          aria-label="create-new-delivery"
          sx={{
            mx: 2,
            height: '51px',
            lineHeight: '17px',
            fontWeight: 600,
            fontSize: '14px',
            borderRadius: '8px',
            borderColor: caloTheme.palette.primary500,
            backgroundColor: caloTheme.palette.primary500,
            color: 'White',
            '&:hover': {
              backgroundColor: caloTheme.palette.primary600,
              borderColor: caloTheme.palette.primary600
            }
          }}
          onClick={() => onSubmit({ date: selectedNewDeliveryDay! })}
        >
          Confirm
        </Button>
      </Box>
    </>
  );
};

export default NewDeliveryForm;
