import { useFormik } from 'formik';
import { SupplyChainUser } from 'lib/interfaces';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { omit } from 'lodash-es';

export default (user: SupplyChainUser, onSubmit: (values: Omit<SupplyChainUser, 'id'>) => any) =>
  useFormik<Omit<SupplyChainUser, 'id'>>({
    initialValues: {
      ...(omit(user, ['id', 'createdAt', 'updatedAt']) as SupplyChainUser)
    },
    validate: (values: Omit<SupplyChainUser, 'id'>) => {
      const errors: any = {};

      if (!values.name) {
        errors.name = true;
      }
      if (!values.country) {
        errors.country = true;
      }
      if (!values.kitchen || values.kitchen.length === 0) {
        errors.kitchen = true;
      }

      const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);
      if (!phoneNumber || (phoneNumber && !phoneNumber.isValid())) {
        errors.phoneNumber = true;
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
