import { Card } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import React from 'react';

const Container = ({ children }: { children: React.ReactNode }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        width: '100%',
        border: 'none',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          width: '100%',
          flexDirection: 'column'
        }
      }}
    >
      {children}
    </Card>
  );
};

export default Container;
