import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  card: {
    width: 'full',
    border: 'none',
    marginBottom: '16px',
    borderRadius: '8px',
    paddingBottom: '4px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    width: '100%'
  },
  stack: {
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  typography: {
    marginTop: 'auto',
    fontWeight: 600,
    fontSize: '23px',
    lineHeight: '28px',
    border: 'none',
    fontFamily: caloTheme.typography.fontFamily,
    '&:hover': {
      border: 'none',
      fontFamily: caloTheme.typography.fontFamily
    }
  },
  actionStack: {
    width: '70%',
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'row',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: '16px'
    }
  },
  outlinedButton: {
    width: 'full',
    height: '51px',
    lineHeight: '17px',
    fontWeight: 600,
    marginRight: '16px',
    fontSize: '14px',
    borderRadius: '8px',
    boxShadow: 'none',
    borderColor: caloTheme.palette.primary500,
    color: caloTheme.palette.primary500,
    '&:hover': {
      boxShadow: 'none',
      borderColor: caloTheme.palette.primary600
    }
  },
  tooltipButton: {
    height: '51px',
    lineHeight: '17px',
    fontWeight: 600,
    marginRight: '16px',
    fontSize: '14px',
    borderRadius: '8px',
    boxShadow: 'none',
    borderColor: caloTheme.palette.primary500,
    color: caloTheme.palette.primary500,
    '&:hover': {
      boxShadow: 'none',
      borderColor: caloTheme.palette.primary600
    }
  },
  generateButton: {
    textDecoration: 'none',
    height: '51px',
    lineHeight: '17px',
    fontWeight: 600,
    marginRight: '16px',
    fontSize: '14px',
    borderRadius: '8px',
    boxShadow: 'none',
    backgroundColor: caloTheme.palette.secondaryYellow200,
    borderColor: caloTheme.palette.secondaryYellow200,
    color: caloTheme.palette.secondaryYellow900,
    '&:hover': {
      boxShadow: 'none',
      color: caloTheme.palette.secondaryYellow900,
      borderColor: caloTheme.palette.secondaryYellow300,
      backgroundColor: caloTheme.palette.secondaryYellow300
    }
  },
  saveButton: {
    width: '120px',
    height: '51px',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
    backgroundColor: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    boxShadow: 'none',
    color: 'white',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: caloTheme.palette.primary600,
      borderColor: caloTheme.palette.primary600
    }
  },
  formBox: {
    width: 'full',
    marginTop: '16px',
    '& .MuiTextField-root': { margin: '16px', width: '20%', justifyContent: 'space-between' },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'row',
      width: '96%',
      '& .MuiTextField-root': { marginTop: '16px', marginLeft: '8px', marginRight: '8px', width: '96%' }
    }
  },
  formCard: {
    marginBottom: '16px',
    width: 'full',
    overflow: 'visible',
    border: 'none',
    borderRadius: '8px',
    paddingBottom: '4px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  headerStack: {
    marginTop: '16px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  headerTypography: {
    margin: '16px',
    width: '50%',
    fontWeight: 600,
    fontSize: '23px',
    lineHeight: '28px',
    border: 'none',
    fontFamily: caloTheme.typography.fontFamily,
    '&:hover': {
      border: 'none',
      fontFamily: caloTheme.typography.fontFamily
    }
  },
  tableBox: {
    marginLeft: '16px',
    marginTop: '32px',
    minHeight: '20rem'
  }
};
