import { Route, Switch } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';

import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import AccountList from './Accounts/AccountList';
import ExactAccount from './Accounts/ExactAccount';
import NewAccount from './Accounts/NewAccount';
import OrdersList from './Orders/OrdersList/OrdersList';

const Bussiness = () => {
  const roles = useUserRoles();

  return (
    <Switch>
      {roles.includes(Permission.VIEW_BUSINESS_USER_LIST) && (
        <Route key={Routes.accountUsersList} exact path={Routes.accountUsersList} component={AccountList} />
      )}
      {roles.includes(Permission.CREATE_BUSINESS_USER) && [
        <Route key={Routes.newAccounts} exact path={Routes.newAccounts} component={NewAccount} />
      ]}
      {roles.includes(Permission.VIEW_BUSINESS_USER) && [
        <Route key={Routes.accountUser} path={Routes.accountUser} component={ExactAccount} />
      ]}
      {roles.includes(Permission.VIEW_BUSINESS_DELIVERY_LIST) && [
        <Route key={Routes.orders} path={Routes.orders} component={OrdersList} />
      ]}
    </Switch>
  );
};

export default Bussiness;
