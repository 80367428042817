import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    primary50: string;
    primary100: string;
    primary200: string;
    primary300: string;
    primary400: string;
    primary500: string;
    primary600: string;
    primary700: string;
    primary800: string;
    primary900: string;

    neutral50: string;
    neutral100: string;
    neutral200: string;
    neutral300: string;
    neutral400: string;
    neutral500: string;
    neutral600: string;
    neutral700: string;
    neutral800: string;
    neutral900: string;
    secondaryPurple50: string;
    secondaryPurple100: string;
    secondaryPurple200: string;
    secondaryPurple300: string;
    secondaryPurple400: string;
    secondaryPurple500: string;
    secondaryPurple600: string;
    secondaryPurple700: string;
    secondaryPurple800: string;
    secondaryPurple900: string;
    secondaryBlue50: string;
    secondaryBlue100: string;
    secondaryBlue200: string;
    secondaryBlue300: string;
    secondaryBlue400: string;
    secondaryBlue500: string;
    secondaryBlue600: string;
    secondaryBlue700: string;
    secondaryBlue800: string;
    secondaryBlue900: string;
    secondaryYellow50: string;
    secondaryYellow100: string;
    secondaryYellow200: string;
    secondaryYellow300: string;
    secondaryYellow400: string;
    secondaryYellow500: string;
    secondaryYellow600: string;
    secondaryYellow700: string;
    secondaryYellow800: string;
    secondaryYellow900: string;
    secondaryPink50: string;
    secondaryPink100: string;
    secondaryPink200: string;
    secondaryPink300: string;
    secondaryPink400: string;
    secondaryPink500: string;
    secondaryPink600: string;
    secondaryPink700: string;
    secondaryPink800: string;
    secondaryPink900: string;
    secondaryOrange50: string;
    secondaryOrange100: string;
    secondaryOrange200: string;
    secondaryOrange300: string;
    secondaryOrange400: string;
    secondaryOrange500: string;
    secondaryOrange600: string;
    secondaryOrange700: string;
    secondaryOrange800: string;
    secondaryOrange900: string;
    secondaryRed50: string;
    secondaryRed100: string;
    secondaryRed200: string;
    secondaryRed300: string;
    secondaryRed400: string;
    secondaryRed500: string;
    secondaryRed600: string;
    secondaryRed700: string;
    secondaryRed800: string;
    secondaryRed900: string;
    secondaryTeal50: string;
    secondaryTeal100: string;
    secondaryTeal200: string;
    secondaryTeal300: string;
    secondaryTeal400: string;
    secondaryTeal500: string;
    secondaryTeal600: string;
    secondaryTeal700: string;
    secondaryTeal800: string;
    secondaryTeal900: string;
    red500: string;
    alert50: string;
    black: string;
    white: string;
    red: string;
    mealo: string;
    hyperlink: string;
  }

  interface PaletteOptions {
    primary50: string;
    primary100: string;
    primary200: string;
    primary300: string;
    primary400: string;
    primary500: string;
    primary600: string;
    primary700: string;
    primary800: string;
    primary900: string;
    neutral50: string;
    neutral100: string;
    neutral200: string;
    neutral300: string;
    neutral400: string;
    neutral500: string;
    neutral600: string;
    neutral700: string;
    neutral800: string;
    neutral900: string;
    secondaryPurple50: string;
    secondaryPurple100: string;
    secondaryPurple200: string;
    secondaryPurple300: string;
    secondaryPurple400: string;
    secondaryPurple500: string;
    secondaryPurple600: string;
    secondaryPurple700: string;
    secondaryPurple800: string;
    secondaryPurple900: string;
    secondaryBlue50: string;
    secondaryBlue100: string;
    secondaryBlue200: string;
    secondaryBlue300: string;
    secondaryBlue400: string;
    secondaryBlue500: string;
    secondaryBlue600: string;
    secondaryBlue700: string;
    secondaryBlue800: string;
    secondaryBlue900: string;
    secondaryYellow50: string;
    secondaryYellow100: string;
    secondaryYellow200: string;
    secondaryYellow300: string;
    secondaryYellow400: string;
    secondaryYellow500: string;
    secondaryYellow600: string;
    secondaryYellow700: string;
    secondaryYellow800: string;
    secondaryYellow900: string;
    secondaryPink50: string;
    secondaryPink100: string;
    secondaryPink200: string;
    secondaryPink300: string;
    secondaryPink400: string;
    secondaryPink500: string;
    secondaryPink600: string;
    secondaryPink700: string;
    secondaryPink800: string;
    secondaryPink900: string;
    secondaryOrange50: string;
    secondaryOrange100: string;
    secondaryOrange200: string;
    secondaryOrange300: string;
    secondaryOrange400: string;
    secondaryOrange500: string;
    secondaryOrange600: string;
    secondaryOrange700: string;
    secondaryOrange800: string;
    secondaryOrange900: string;
    secondaryRed50: string;
    secondaryRed100: string;
    secondaryRed200: string;
    secondaryRed300: string;
    secondaryRed400: string;
    secondaryRed500: string;
    secondaryRed600: string;
    secondaryRed700: string;
    secondaryRed800: string;
    secondaryRed900: string;
    secondaryTeal50: string;
    secondaryTeal100: string;
    secondaryTeal200: string;
    secondaryTeal300: string;
    secondaryTeal400: string;
    secondaryTeal500: string;
    secondaryTeal600: string;
    secondaryTeal700: string;
    secondaryTeal800: string;
    secondaryTeal900: string;
    red500: string;
    alert50: string;
    black: string;
    white: string;
    red: string;
    mealo: string;
    hyperlink: string;
  }
}
export const caloThemeFont = createTheme({
  typography: {
    fontFamily: 'Roboto'
  }
});

export const caloTheme = createTheme({
  typography: {
    fontFamily: 'Roboto',
    fontSize: 14
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1026,
      xl: 1280,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200
    }
  },
  palette: {
    primary: {
      main: '#57AE7F'
    },
    primary50: '#F7FBF9',
    primary100: '#E6F3EC',
    primary200: '#CDE7D9',
    primary300: '#B3DBC5',
    primary400: '#89C6A5',
    primary500: '#57AE7F',
    primary600: '#47936A',
    primary700: '#397555',
    primary800: '#234935',
    primary900: '#152C20',
    neutral50: '#F7F7F7',
    neutral100: '#EBEBEB',
    neutral200: '#DFDFDF',
    neutral300: '#D2D2D2',
    neutral400: '#C2C2C2',
    neutral500: '#AEAEAE',
    neutral600: '#9D9D9D',
    neutral700: '#7A7A7A',
    neutral800: '#575757',
    neutral900: '#343434',
    secondaryPurple50: '#F8F7FA',
    secondaryPurple100: '#E3E0EC',
    secondaryPurple200: '#CEC9DE',
    secondaryPurple300: '#BAB3D1',
    secondaryPurple400: '#9E94BE',
    secondaryPurple500: '#7466A2',
    secondaryPurple600: '#62558C',
    secondaryPurple700: '#504673',
    secondaryPurple800: '#393252',
    secondaryPurple900: '#282339',
    secondaryBlue50: '#F6F9FB',
    secondaryBlue100: '#DBE8EE',
    secondaryBlue200: '#B7D0DC',
    secondaryBlue300: '#9cbfcf',
    secondaryBlue400: '#78a8bd',
    secondaryBlue500: '#4F89A3',
    secondaryBlue600: '#43748b',
    secondaryBlue700: '#33596a',
    secondaryBlue800: '#284552',
    secondaryBlue900: '#1c3039',
    secondaryYellow50: '#FEFCF7',
    secondaryYellow100: '#FAF3DE',
    secondaryYellow200: '#F4E6BD',
    secondaryYellow300: '#efda9b',
    secondaryYellow400: '#e9cd7a',
    secondaryYellow500: '#E4C159',
    secondaryYellow600: '#ddb230',
    secondaryYellow700: '#b18c1d',
    secondaryYellow800: '#7b6114',
    secondaryYellow900: '#44360b',
    secondaryPink50: '#FDF6F6',
    secondaryPink100: '#faeeee',
    secondaryPink200: '#f6dcdd',
    secondaryPink300: '#f2cfd0',
    secondaryPink400: '#eebebf',
    secondaryPink500: '#E8A8AA',
    secondaryPink600: '#dc7b7e',
    secondaryPink700: '#ca373c',
    secondaryPink800: '#711e20',
    secondaryPink900: '#2d0c0d',
    secondaryOrange50: '#fdf0e1',
    secondaryOrange100: '#fce6cd',
    secondaryOrange200: '#fad7af',
    secondaryOrange300: '#f8c992',
    secondaryOrange400: '#f5b56a',
    secondaryOrange500: '#F29C38',
    secondaryOrange600: '#EF8810',
    secondaryOrange700: '#b4660c',
    secondaryOrange800: '#784408',
    secondaryOrange900: '#3c2204',
    secondaryRed50: '#fae7e7',
    secondaryRed100: '#f1c3c3',
    secondaryRed200: '#df7070',
    secondaryRed300: '#d64c4c',
    secondaryRed400: '#c92d2d',
    secondaryRed500: '#B12828',
    secondaryRed600: '#9b2323',
    secondaryRed700: '#6f1919',
    secondaryRed800: '#591414',
    secondaryRed900: '#2c0a0a',
    secondaryTeal50: '#D9FFFF',
    secondaryTeal100: '#B3FFFF',
    secondaryTeal200: '#79FFFF',
    secondaryTeal300: '#00f3f3',
    secondaryTeal400: '#00b9b9',
    secondaryTeal500: '#008080',
    secondaryTeal600: '#006060',
    secondaryTeal700: '#004d4d',
    secondaryTeal800: '#003a3a',
    secondaryTeal900: '#002020',
    red500: '#FD2020',
    alert50: '#FDF2F2',
    black: '#000000',
    white: '#ffffff',
    red: '#DC2626',
    mealo: '#FF9900',
    hyperlink: '#367BF5'
  }
});
