import { ComponentService } from '@calo/services';
import { DeliveryFood, FoodActionType, Macros, Micronutrients } from '@calo/types';
import { Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { FoodComponent } from 'lib/interfaces';
import { compact, round } from 'lodash-es';
import { useEffect, useState } from 'react';
import { extractMealNutrition } from './helpers';

interface ModifyMealTotalInformationSectionProps {
  allFoodComponentsData: FoodComponent[];
  selectedRemovedComponentIdList: string[];
  meal: DeliveryFood & { positionIndex: number };
  selectedSwappedComponentIdList: { oldId: string; newId: string }[];
}

const initialMacroMicroData: MacroMicroData = {
  cal: 0,
  fat: 0,
  transFats: 0,
  saturatedFats: 0,
  protein: 0,
  sodium: 0,
  cholesterol: 0,
  carbs: 0,
  fiber: 0,
  totalSugar: 0,
  addedSugar: 0
};

const calculateStatsValue = (key: keyof Macros | keyof Micronutrients, component: any, originalComponent: any) => {
  if (!component || !originalComponent) return 0;
  const macrosValue = originalComponent?.macros ? originalComponent.macros[key as keyof Macros] : 0;
  const micronutrientsValue = originalComponent?.micronutrients
    ? originalComponent.micronutrients[key as keyof Micronutrients]
    : 0;
  const weight = ComponentService.calculateComponentWeight(
    component.cups,
    component.measurementUnit,
    component.weight,
    originalComponent?.quantity || 0
  );
  return (macrosValue || micronutrientsValue) * weight;
};
export interface MacroMicroData {
  protein: number;
  sodium: number;
  fiber: number;
  totalSugar: number;
  cholesterol: number;
  carbs: number;
  cal: number;
  fat: number;
  addedSugar: number;
  transFats: number;
  saturatedFats: number;
}
const ModifyMealTotalInformationSection = ({
  meal,
  allFoodComponentsData,
  selectedSwappedComponentIdList,
  selectedRemovedComponentIdList
}: ModifyMealTotalInformationSectionProps) => {
  const [mealMacroMicrosData, setMealMacroMicroData] = useState<MacroMicroData>(initialMacroMicroData);

  const mealRemovedActions = meal.actions
    ?.filter((action) => action.type === FoodActionType.remove)
    .flatMap((component) => component.componentId); // check the saved removed action the meal has

  const mealSwappedActions = meal.actions
    ?.filter((action) => action.type === FoodActionType.swap)
    .flatMap((component) => component.swappedComponents); // check the saved swapped actions the meal has

  const addListIds = compact(
    meal.actions
      ?.filter((action) => action.type === FoodActionType.remove) //undo removed actions
      .flatMap((component) => component.componentId)
      ?.filter((addedComponent) => addedComponent && !selectedRemovedComponentIdList.includes(addedComponent))
  );

  const mealRemovedComponentsMatch = selectedRemovedComponentIdList.every((id) => mealRemovedActions?.includes(id)); //new remove action has been added

  const mealSwappedComponentsMatch = selectedSwappedComponentIdList.every((swap) =>
    mealSwappedActions?.some((action) => action === swap)
  ); //new swap action has been added

  const calculateModificationsMacrosAndMicros = (updatedNutritionData: MacroMicroData) => {
    if (!mealRemovedComponentsMatch) {
      // remove components
      for (const removedId of selectedRemovedComponentIdList.filter((compId) => !mealRemovedActions?.includes(compId))) {
        const component = allFoodComponentsData.find((comp) => comp.id === removedId);
        const originalComponent = meal.components?.find((comp) => comp.id === removedId);
        for (const key of Object.keys(updatedNutritionData)) {
          const nutrientType = key as keyof Macros | keyof Micronutrients;
          updatedNutritionData[key as keyof Macros | keyof Micronutrients] -= calculateStatsValue(
            nutrientType,
            component,
            originalComponent
          );
        }
      }
    }
    const difference = mealSwappedActions?.filter(
      (mealAction) =>
        !selectedSwappedComponentIdList.some(
          (modifiedAction) => modifiedAction.oldId === mealAction?.oldId && modifiedAction.newId === mealAction?.newId
        )
    );
    if (difference && difference.length > 0) {
      //undo swapping
      for (const addedComponent of difference) {
        const component = allFoodComponentsData.find((comp) => comp.id === addedComponent?.oldId);
        const originalComponent = meal.components?.find((comp) => comp.id === addedComponent?.oldId);
        const removedComponent = allFoodComponentsData.find((comp) => comp.id === addedComponent?.newId);
        for (const key of Object.keys(updatedNutritionData)) {
          const macrosOriginalComponent = removedComponent?.macros ? removedComponent.macros[key as keyof Macros] : 0;
          const micronutrientsOriginalComponent = removedComponent?.micronutrients
            ? removedComponent.micronutrients[key as keyof Micronutrients]
            : 0;
          const value = macrosOriginalComponent || micronutrientsOriginalComponent || 0 || 0;
          updatedNutritionData[key as keyof Macros | keyof Micronutrients] -= value;
        }
        for (const key of Object.keys(updatedNutritionData)) {
          const macrosOriginalComponent = originalComponent?.macros ? originalComponent.macros[key as keyof Macros] : 0;
          const micronutrientsOriginalComponent = originalComponent?.micronutrients
            ? originalComponent.micronutrients[key as keyof Micronutrients]
            : 0;
          const componentWeight = ComponentService.calculateComponentWeight(
            component?.cups,
            component?.measurementUnit!,
            component?.weight ?? 0,
            originalComponent?.quantity || 0
          );
          const value = (macrosOriginalComponent || micronutrientsOriginalComponent || 0) * componentWeight;
          updatedNutritionData[key as keyof Macros | keyof Micronutrients] += value;
        }
      }
    }
    if (addListIds && addListIds.length > 0) {
      //re-add removed a component
      for (const addedComponent of addListIds) {
        const component = allFoodComponentsData.find((comp) => comp.id === addedComponent);
        const originalComponent = meal.components?.find((comp) => comp.id === addedComponent);
        if (component) {
          for (const key of Object.keys(updatedNutritionData)) {
            const nutrientType = key as keyof Macros | keyof Micronutrients;
            updatedNutritionData[key as keyof Macros | keyof Micronutrients] += calculateStatsValue(
              nutrientType,
              component,
              originalComponent
            );
          }
        }
      }
    }
    if (!mealSwappedComponentsMatch) {
      // swap components
      for (const { oldId, newId } of selectedSwappedComponentIdList) {
        const oldComponent = allFoodComponentsData.find((comp) => comp.id === oldId);
        const newComponent = allFoodComponentsData.find((comp) => comp.id === newId);
        const originalComponent = meal.components?.find((comp) => comp.id === oldId);
        const swapExists = mealSwappedActions?.some((swap) => swap?.oldId === oldId && swap.newId === newId);
        const swapSwapped = mealSwappedActions?.some((swap) => swap?.oldId === oldId && swap.newId !== newId);

        if (oldComponent && newComponent && !swapExists && !swapSwapped) {
          for (const key of Object.keys(updatedNutritionData)) {
            const macrosOriginalComponent = originalComponent?.macros ? originalComponent.macros[key as keyof Macros] : 0;
            const micronutrientsOriginalComponent = originalComponent?.micronutrients
              ? originalComponent.micronutrients[key as keyof Micronutrients]
              : 0;
            const componentWeight = ComponentService.calculateComponentWeight(
              oldComponent.cups,
              oldComponent.measurementUnit,
              oldComponent.weight ?? 0,
              originalComponent?.quantity || 0
            );
            const value = (macrosOriginalComponent || micronutrientsOriginalComponent || 0) * componentWeight;
            updatedNutritionData[key as keyof Macros | keyof Micronutrients] -= value;
          }
          for (const key of Object.keys(updatedNutritionData)) {
            const macrosValueNewComponent = newComponent?.macros ? newComponent.macros[key as keyof Macros] : 0;
            const micronutrientsValueNewComponent = newComponent?.micronutrients
              ? newComponent.micronutrients[key as keyof Micronutrients]
              : 0;
            const value = macrosValueNewComponent || micronutrientsValueNewComponent || 0 || 0;
            updatedNutritionData[key as keyof Macros | keyof Micronutrients] += value;
          }
        } else if (swapSwapped) {
          const oldSwapping = mealSwappedActions?.find((action) => action?.oldId === oldId);
          const oldComponent = allFoodComponentsData.find((comp) => comp.id === oldSwapping?.newId);
          const newComponent = allFoodComponentsData.find((comp) => comp.id === newId);
          const originalComponent = meal.components?.find((comp) => comp.id === oldId);
          for (const key of Object.keys(updatedNutritionData)) {
            const macrosOriginalComponent = originalComponent?.macros ? originalComponent.macros[key as keyof Macros] : 0;
            const micronutrientsOriginalComponent = originalComponent?.micronutrients
              ? originalComponent.micronutrients[key as keyof Micronutrients]
              : 0;
            const componentWeight = ComponentService.calculateComponentWeight(
              oldComponent?.cups,
              oldComponent?.measurementUnit!,
              oldComponent?.weight ?? 0,
              originalComponent?.quantity || 0
            );
            const value = (macrosOriginalComponent || micronutrientsOriginalComponent || 0) * componentWeight;
            updatedNutritionData[key as keyof Macros | keyof Micronutrients] -= value;
          }
          for (const key of Object.keys(updatedNutritionData)) {
            const macrosValueNewComponent = newComponent?.macros ? newComponent.macros[key as keyof Macros] : 0;
            const micronutrientsValueNewComponent = newComponent?.micronutrients
              ? newComponent.micronutrients[key as keyof Micronutrients]
              : 0;
            const value = macrosValueNewComponent || micronutrientsValueNewComponent || 0 || 0;
            updatedNutritionData[key as keyof Macros | keyof Micronutrients] += value;
          }
        }
      }
    }
    return updatedNutritionData;
  };

  useEffect(() => {
    let updatedNutritionData = { ...initialMacroMicroData, ...extractMealNutrition(meal) };
    updatedNutritionData = calculateModificationsMacrosAndMicros(updatedNutritionData);
    setMealMacroMicroData(updatedNutritionData);
  }, [meal, selectedRemovedComponentIdList, selectedSwappedComponentIdList]);

  const handleInfoStack = (label: string, value: number, unit: string) => (
    <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <Typography sx={{ fontSize: '12px', fontStyle: 'normal', fontWeight: 400, lineHeight: '12px' }}>{label}</Typography>
      <Typography
        sx={{ fontSize: '12px', fontStyle: 'normal', fontWeight: 600, lineHeight: '12px' }}
      >{`${round(value, 2)} ${unit}`}</Typography>
    </Stack>
  );

  return (
    <>
      <Stack sx={{ flexDirection: 'row', margin: 1 }}>
        <Typography sx={{ fontSize: '16px', fontStyle: 'normal', fontWeight: 600, lineHeight: '14px' }}>
          {round(mealMacroMicrosData.cal, 2)}
        </Typography>
        <Typography sx={{ fontSize: '10px', fontStyle: 'normal', fontWeight: 400, lineHeight: '12px', marginLeft: '4px' }}>
          Kcal
        </Typography>
      </Stack>
      <Stack sx={{ justifyContent: 'space-between', flexDirection: 'row', marginRight: 1 }}>
        <Stack
          sx={{
            borderLeftWidth: 2,
            borderLeftColor: caloTheme.palette.neutral100,
            borderRightWidth: 2,
            borderRightColor: caloTheme.palette.neutral100,
            width: '100%',
            paddingX: 1
          }}
        >
          {handleInfoStack('Total Fats', mealMacroMicrosData.fat, 'g')}
          {handleInfoStack('Trans Fats', mealMacroMicrosData.transFats, 'g')}
          {handleInfoStack('Sat Fats', mealMacroMicrosData.saturatedFats, 'g')}
        </Stack>
        <Stack sx={{ borderRightWidth: 2, borderRightColor: caloTheme.palette.neutral100, width: '100%', paddingX: 1 }}>
          {handleInfoStack('Pro', mealMacroMicrosData.protein, 'g')}
          {handleInfoStack('Sodium', mealMacroMicrosData.sodium * 1000, 'mg')}
          {handleInfoStack('Cholesterol', mealMacroMicrosData.cholesterol * 1000, 'mg')}
        </Stack>
        <Stack sx={{ borderRightWidth: 2, borderRightColor: caloTheme.palette.neutral100, width: '100%', paddingX: 1 }}>
          {handleInfoStack('Carbs', mealMacroMicrosData.carbs, 'g')}
          {handleInfoStack('Fiber', mealMacroMicrosData.fiber, 'g')}
          {handleInfoStack('Total Sugar', mealMacroMicrosData.totalSugar, 'g')}
          {handleInfoStack('Added Sugar', mealMacroMicrosData.addedSugar, 'g')}
        </Stack>
      </Stack>
    </>
  );
};

export default ModifyMealTotalInformationSection;
