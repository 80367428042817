import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import queryClient from 'lib/queryClient';

import { CreateKDSUserGroupReq, UpdateKDSUserGroupReq } from '@calo/dashboard-types';

import mutation from './mutation';

export const createKDSUserPermission = async (values: CreateKDSUserGroupReq) => {
  const { data, status } = await client.post('kds-user-permissions', values);
  mutation('kds-user-permissions', data);
  ToastSuccessMsgs({ status, action: 'new group created' });
  return data;
};

export const updateKDSUserPermissions = async (payload: UpdateKDSUserGroupReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`kds-user-permissions/${id}`, rest);
  mutation('kds-user-permissions', data);
  ToastSuccessMsgs({ status, action: `group ${data.groupName} updated` });
  return data;
};

export const deleteKDSUserPermissions = async (id: string) => {
  const { data: _data, status } = await client.delete(`kds-user-permissions/${id}`);
  ToastSuccessMsgs({ status, action: 'group deleted' });
  const query = queryClient.getQueryData('kds-user-permissions') as any;
  const index = query.data.findIndex((i: any) => i.id === id);
  query.data.splice(index, 1);
};
