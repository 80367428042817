import { Route, Switch } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';

import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import ComplaintList from './ComplaintList';

const Complaints = () => {
  const roles = useUserRoles();
  return (
    <Switch>
      {roles.includes(Permission.LIST_CUSTOMER_COMPLAINT) && [
        <Route key={Routes.complaints} exact path={Routes.complaints} component={ComplaintList} />
      ]}
    </Switch>
  );
};

export default Complaints;
