import { Subscription } from '@calo/types';
import Rewards from './Rewards';
import TransactionsLogs from './TransactionsLogs';

interface LoyaltyCardProps {
  subscription: Subscription & {
    remainingDays: number;
    expectedLastDeliveryDay: string;
    ratings: any[];
    magicPrice?: boolean;
  };
}

const LoyaltyCard = ({ subscription }: LoyaltyCardProps) => {
  return (
    <>
      <TransactionsLogs subscription={subscription} />
      <Rewards subscription={subscription} />
    </>
  );
};

export default LoyaltyCard;
