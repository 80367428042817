import { NewDeliveryAddress } from '@calo/types';
import { updateTicket } from 'actions';
import mutation from 'actions/mutation';
import { Button, ModalRef } from 'components';
import client from 'lib/client';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import queryClient from 'lib/queryClient';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery, useMutation } from 'react-query';
import { AddressService } from 'services';
import TicketModal from './TicketModal';
import TicketsRow from './TicketsRow';

const findUpdatedDriver = (query: any, updatedTicket: any) => {
  const listLength = query.pages.length;
  let foundedList = 0;
  for (let i = 0; i < listLength; i++) {
    const index = query.pages[i].data.findIndex((id: any) => id.id === updatedTicket.id);
    if (index >= 0) {
      foundedList = i;
    }
  }
  return foundedList;
};

const TicketsList = () => {
  const [selectedTicket, setSelectedTicket] = useState<any>();
  const [oldLocAddress, setOldLocAddress] = useState<string>();
  const [newLocAddress, setNewLocAddress] = useState<string>();

  const ticketModalRef = useRef<ModalRef>();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery<{ data: any[]; meta: any }>(
    ['tickets'],
    async ({ pageParam, queryKey }) => {
      const { data } = await client.get(queryKey[0] as string, {
        params: {
          ...(pageParam && {
            cursor: pageParam
          })
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => data.meta?.cursor
    }
  );

  const ticketsRecords = useMemo(
    () =>
      (data?.pages || []).reduce<any[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [data]
  );

  const { mutateAsync: updateMutation } = useMutation(updateTicket);

  const handleUpdateTicket = async (values: any) => {
    await updateMutation(
      {
        id: values.id,
        ticketStatus: values.status
      },
      {
        onSuccess: (returnedData) => {
          const query = queryClient.getQueryData(['tickets']) as any;
          const indexes = findUpdatedDriver(query, values);
          const index = query.pages[indexes].data.findIndex((id: any) => id.id === values.id);
          query.pages[indexes].data.splice(index, 1, returnedData);
          queryClient.setQueryData(['tickets'], query.pages);
          mutation(['tickets'], query);
          ticketModalRef.current?.close();
          history.push(Routes.tickets);
        }
      }
    );
  };

  const addressText = useCallback(
    async (lat: number, lng: number, text: string) => {
      if (selectedTicket) {
        const address = await AddressService.getAddress(+lat, +lng);
        const addressText = AddressService.display(address as NewDeliveryAddress);
        if (text === 'new') setNewLocAddress(addressText);
        if (text === 'old') setOldLocAddress(addressText);
      }
    },
    [selectedTicket]
  );

  useEffect(() => {
    if (selectedTicket) {
      addressText(selectedTicket?.data.newLocation.lat, selectedTicket?.data.newLocation.lat, 'new');
      addressText(selectedTicket?.data.oldLocation.lat, selectedTicket?.data.oldLocation.lng, 'old');
    }
  }, [selectedTicket]);

  return (
    <InfiniteScroll
      dataLength={ticketsRecords.length || 0}
      next={fetchNextPage}
      hasMore={!!hasNextPage}
      loader={null}
      scrollableTarget="scrollable"
    >
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Updates</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="card has-table has-table-container-upper-radius">
          <div className="card-content">
            <div className="table-container overflow-y-auto">
              <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                <thead>
                  <tr className="bg-black h-12">
                    <th style={{ color: 'white' }}>Date</th>
                    <th style={{ color: 'white' }}>Customer</th>
                    <th style={{ color: 'white' }}>Customer number </th>
                    <th style={{ color: 'white' }}>Driver</th>
                    <th style={{ color: 'white' }}>Driver number </th>
                    <th style={{ color: 'white' }}>Action type</th>
                    <th style={{ color: 'white' }}>Country</th>
                    <th style={{ color: 'white' }}>Address</th>
                    <th style={{ color: 'white' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {ticketsRecords.map((ticket: any) => (
                    <TicketsRow
                      key={ticket.id}
                      ticket={ticket}
                      setSelectedTicket={setSelectedTicket}
                      ticketModalRef={ticketModalRef}
                    />
                  ))}
                </tbody>
                {ticketsRecords.length >= 25 && (
                  <tfoot>
                    <tr className="bg-black">
                      <th style={{ color: 'white' }}>Date</th>
                      <th style={{ color: 'white' }}>Customer</th>
                      <th style={{ color: 'white' }}>Customer number </th>
                      <th style={{ color: 'white' }}>Driver</th>
                      <th style={{ color: 'white' }}>Driver number </th>
                      <th style={{ color: 'white' }}>Action type</th>
                      <th style={{ color: 'white' }}>Country</th>
                      <th style={{ color: 'white' }}>Address</th>
                      <th style={{ color: 'white' }}></th>
                    </tr>
                  </tfoot>
                )}
              </table>
              {!!hasNextPage && (
                <div className="flex justify-center pb-3">
                  <Button onClick={() => fetchNextPage()} content="Load more" loading={isFetchingNextPage} />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <TicketModal
        handleUpdateTicket={handleUpdateTicket}
        newLocAddress={newLocAddress}
        oldLocAddress={oldLocAddress}
        selectedTicket={selectedTicket}
        ticketModalRef={ticketModalRef}
      />
    </InfiniteScroll>
  );
};

export default TicketsList;
