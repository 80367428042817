import { Box, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { useDocumentMedia } from 'hooks';
import { AccountPages } from 'lib/enums';

interface AccountTabsProps {
  selectedPage: AccountPages;
  setSelectedPage: (value: AccountPages) => void;
}

const AccountTabs = ({ selectedPage, setSelectedPage }: AccountTabsProps) => {
  const { isMobile, isTablet } = useDocumentMedia();
  return (
    <Box
      sx={{
        width: '100%',
        height: isMobile || isTablet ? '30vh' : '6vh',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888',
          borderRadius: '5px'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: 'full',
          mb: '4px',
          borderBottom: 1,
          borderColor: caloTheme.palette.neutral50,
          justifyContent: 'space-around',
          mx: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        {Object.values(AccountPages).map((page) => (
          <Stack
            key={page}
            data-test={`${page}Page`}
            onClick={() => setSelectedPage(page)}
            sx={{
              height: '39px',
              width: '180px',
              left: '0px',
              flexShrink: 0,
              top: '10px',
              padding: '10px 24px 10px 24px',
              borderBottom: selectedPage === page ? 2 : 0,
              color: selectedPage === page ? caloTheme.palette.primary500 : caloTheme.palette.neutral600,
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: 'auto'
              }
            }}
          >
            <Typography
              sx={{
                cursor: 'pointer',
                letterSpacing: -0.02,
                textAlign: 'center',
                fontSize: '16px',
                lineHeight: '19px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                color: selectedPage === page ? caloTheme.palette.primary500 : caloTheme.palette.neutral600
              }}
            >
              {page}
            </Typography>
          </Stack>
        ))}
      </Box>
    </Box>
  );
};

export default AccountTabs;
