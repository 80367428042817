import { Autocomplete, Box, Button, Card, MenuItem, Stack, TextField, Typography, capitalize } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import { useMutation } from 'react-query';

import { AffiliateCommissionPercentage, AffiliateDiscountCodeAmount, CompensationScheme } from '@calo/dashboard-types/dist/enums';

import { createAffiliateProfile } from 'actions/affiliates';
import { caloTheme } from 'assets/images/theme/calo';
import { removeAllWhiteSpacesAndDashes } from 'lib';
import { countries, countryCodes } from 'lib/countries';
import { getObjectFromEnum } from 'lib/helpers';
import styles from './styles';
import useCreateAffiliateForm from './useCreateAffiliateForm';

interface CreateAffiliateProfileProps {
  refetchAffiliates: any;
}

const CreateAffiliateProfile = ({ refetchAffiliates }: CreateAffiliateProfileProps) => {
  const allowedDiscountAmounts = getObjectFromEnum(AffiliateDiscountCodeAmount);
  const allowedCommissionPercentages = getObjectFromEnum(AffiliateCommissionPercentage);
  const allowedCompensationSchemes = getObjectFromEnum(CompensationScheme);

  const { mutateAsync: createAffiliate } = useMutation(createAffiliateProfile);

  const { handleSubmit, values, handleChange, setFieldValue, errors, touched } = useCreateAffiliateForm({
    refetchAffiliates,
    createAffiliate
  });

  return (
    <>
      <Card variant="outlined">
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
          width="100%"
        >
          <Typography variant="h6" sx={styles.sectionHeader}>
            Create Affiliate
          </Typography>
        </Box>
        <Box sx={styles.rowContainer}>
          <Typography variant="h6" sx={styles.subHeader}>
            1. Enter affiliate information
          </Typography>
        </Box>
        <Box sx={styles.rowContainer}>
          <Stack width={'34%'} sx={{ mr: 2 }}>
            <TextField
              id="name"
              name="name"
              sx={{ width: '100%', zIndex: 0 }}
              label="Affiliate Name"
              value={values.name}
              onChange={handleChange}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            ></TextField>
          </Stack>
          <Stack width={'33%'} sx={{ mr: 2 }}>
            <TextField
              id="email"
              name="email"
              sx={{ width: '100%', zIndex: 0 }}
              label="Affiliate's Email"
              value={values.email}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            ></TextField>
          </Stack>
          <Stack sx={{ mr: 2 }}>
            <Autocomplete
              id="country"
              sx={{ width: 150 }}
              options={countries}
              autoHighlight
              getOptionLabel={(option) => option.code}
              onChange={(e, country) => {
                setFieldValue('country', country.code);
              }}
              disableClearable={true}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt=""
                  />
                  {option.label} ({option.code}) {option.phone}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                  {...params}
                  label="Country"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password' // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Stack>
          <Stack width={'33%'} sx={{ mr: 2 }}>
            <MuiTelInput
              id="phoneNumber"
              name="phoneNumber"
              sx={{ width: '100%', zIndex: 0 }}
              label="Phone Number"
              value={values.phoneNumber}
              onChange={(e) => {
                handleChange('phoneNumber')(removeAllWhiteSpacesAndDashes(e));
              }}
              error={touched.phoneNumber && Boolean(errors.phoneNumber)}
              helperText={touched.phoneNumber && errors.phoneNumber}
              placeholder="39767579"
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
              onlyCountries={countryCodes}
              defaultCountry="BH"
            />
          </Stack>
        </Box>
        <Box sx={styles.rowContainer}>
          <Typography variant="h6" sx={styles.subHeader}>
            2. Create compensation scheme{' '}
            <Typography component={'span'} sx={styles.headerNote}>
              (Affiliate compensation details once the code is used)
            </Typography>
          </Typography>
        </Box>
        <Box sx={styles.rowContainer}>
          <Stack width={'33%'} sx={{ mr: 2 }}>
            <TextField
              id="affiliateCode"
              name="affiliateCode"
              sx={{ width: '100%', zIndex: 0 }}
              label="Discount Code Name"
              value={values.affiliateCode}
              onChange={handleChange}
              error={touched.affiliateCode && Boolean(errors.affiliateCode)}
              helperText={touched.affiliateCode && errors.affiliateCode}
              InputProps={{
                inputProps: { style: { borderRadius: 8, textTransform: 'uppercase' } },
                style: { borderRadius: 8 }
              }}
            ></TextField>
          </Stack>
          <Stack width={'33%'} sx={{ mr: 2 }}>
            <TextField
              select
              id="compensationScheme"
              name="compensationScheme"
              sx={{ width: '100%', zIndex: 0 }}
              label="Compensation Scheme"
              value={values.compensationScheme}
              onChange={handleChange}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            >
              {allowedCompensationSchemes.map((allowedCompensationScheme) => (
                <MenuItem key={allowedCompensationScheme.key} value={allowedCompensationScheme.key}>
                  {capitalize(allowedCompensationScheme.value.toString())}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack width={'33%'} sx={{ mr: 2 }}>
            <TextField
              select
              id="commissionAmount"
              name="commissionAmount"
              sx={{ width: '100%', zIndex: 0 }}
              label="Discount Code Commission"
              value={values.commissionAmount}
              onChange={handleChange}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            >
              {allowedCommissionPercentages.map((allowedCommissionPercentage) => (
                <MenuItem key={allowedCommissionPercentage.key} value={allowedCommissionPercentage.value}>
                  {capitalize(allowedCommissionPercentage.value.toString())}%
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Box>
        <Box sx={styles.rowContainer}>
          <Typography variant="h6" sx={styles.subHeader}>
            3. Discount given to subscribers{' '}
            <Typography component={'span'} sx={styles.headerNote}>
              (New subscribers who use the affiliate code)
            </Typography>
          </Typography>
        </Box>
        <Box sx={styles.rowContainer}>
          <Stack width={'33%'} sx={{ mr: 2 }}>
            <TextField
              select
              id="discountAmount"
              name="discountAmount"
              sx={{ width: '100%', zIndex: 0 }}
              label="Discount Amount"
              value={values.discountAmount}
              onChange={handleChange}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            >
              {allowedDiscountAmounts.map((allowedDiscountAmount) => (
                <MenuItem key={allowedDiscountAmount.key} value={allowedDiscountAmount.value}>
                  {capitalize(allowedDiscountAmount.value.toString())}%
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Box>
        <Box sx={styles.rowContainer}>
          <Button variant="contained" sx={styles.createAffiliateContainer} onClick={() => handleSubmit()}>
            Create Affiliate
          </Button>
        </Box>
      </Card>
    </>
  );
};
export default CreateAffiliateProfile;
