import { InputHTMLAttributes, DetailedHTMLProps, useState, ChangeEvent } from 'react';
import cx from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  labelTag?: string;
  error?: boolean;
  debounce?: boolean;
  hidden?: boolean;
  className?: string;
}

const Input = ({ label, labelTag, debounce, error, hidden, className, onChange, value, ...rest }: InputProps) => {
  const [localValue, setLocalValue] = useState(value);

  const debounced = useDebouncedCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  }, 600);

  const handleOnchange = (e: ChangeEvent<HTMLInputElement>) => {
    const callback = debounce ? debounced : onChange;
    setLocalValue(e.target.value);
    if (callback) {
      callback(e);
    }
  };

  return (
    <div className={`field ${className}`} hidden={hidden}>
      {label && (
        <label className="label">
          {label}
          {labelTag && <span className="text-xs text-gray-400 font-normal ml-2">{labelTag}</span>}
        </label>
      )}
      <div className="control">
        <input
          className={cx(
            'input',
            {
              'is-danger': error
            },
            className
          )}
          value={debounce ? localValue : value}
          onChange={handleOnchange}
          {...rest}
        />
      </div>
    </div>
  );
};

export default Input;
