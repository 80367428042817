import { FoodDietType, FoodType, MenuTag, MenuTagValue } from '@calo/types';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Button, Stack, Typography } from '@mui/material';

import { Icon } from '@iconify/react';
import { Radio } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { MenuDraft, MenuFood } from 'lib/interfaces';
import { startCase } from 'lodash-es';
import { useState } from 'react';

interface MenuTableHeaderProps {
  groupByName: MenuFood[];
  value: FoodType;
  draftOptions: MenuDraft | undefined;
  foodLabel: MenuTag[] | undefined;
  onButtonClick: (key: string, value: FoodType, k: string) => void;
  onFilterClose: (key: string, value: FoodType, k: string) => void;
  setHederOpen: (value: FoodType | undefined) => void;
}
const MenuTableHeader = ({ groupByName, value, draftOptions, foodLabel, onButtonClick, setHederOpen }: MenuTableHeaderProps) => {
  const handleClick = (key: any, value: FoodType, k?: any) => {
    onButtonClick(key, value, k);
  };

  const [selectedValue, setSelectedValue] = useState('');

  const handleOptionCounts = (key: string, value: FoodType, k?: string) => {
    const mealsByType = groupByName.filter((f) =>
      value === FoodType.lunch
        ? (f.type.includes(FoodType.lunch) || f.type.includes(FoodType.dinner)) && f
        : value === FoodType.juice
          ? (f.type.includes(FoodType.coffee) || f.type.includes(FoodType.juice)) && f
          : f.type.includes(FoodType[value]) && f
    );
    let mealLength = 0;
    if (k) {
      if (k === 'fixed') {
        mealLength = mealsByType.filter(
          (meal) =>
            foodLabel?.find((key) => key.foodId === meal.id && key.value.includes(MenuTagValue.TOP_RATED)) &&
            meal.tags.includes(key === 'lowCarb' ? FoodDietType.lowCarb : key === 'highProtein' ? FoodDietType.highProtein : key)
        ).length;
      } else if (k === 'rotational') {
        mealLength = mealsByType.filter(
          (meal) =>
            !foodLabel?.find((key) => key.foodId === meal.id && key.value.includes(MenuTagValue.TOP_RATED)) &&
            meal.tags.includes(key === 'lowCarb' ? FoodDietType.lowCarb : key === 'highProtein' ? FoodDietType.highProtein : key)
        ).length;
      }
    } else {
      switch (key) {
        case 'vegetarian': {
          mealLength = mealsByType.filter((meal) => meal.tags.includes(FoodDietType.vegetarian) && meal.tags.length === 1).length;

          break;
        }
        case 'gourmet': {
          mealLength = mealsByType.filter((meal) => meal.tags.includes(FoodDietType.gourmet)).length;

          break;
        }
        case 'highProtein': {
          mealLength = mealsByType.filter((meal) => meal.tags.includes(FoodDietType.highProtein)).length;

          break;
        }
        default: {
          mealLength = mealsByType.filter((meal) => meal.tags.includes(key)).length;
        }
      }
    }
    return mealLength;
  };
  return (
    <Stack
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      sx={{ width: '100%', backgroundColor: '#F7F7F7' }}
    >
      {draftOptions &&
        (value === FoodType.breakfast || value === FoodType.lunch || value === FoodType.snack) &&
        Object.entries(draftOptions[value]).map(([key, v1]) => {
          if (typeof v1 === 'number') {
            return (
              <Stack display={'flex'} flexDirection={'row'} width={'100%'} justifyContent={'space-between'} sx={{ marginY: 2 }}>
                <Typography
                  sx={{
                    color: '#000000',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '18px'
                  }}
                >
                  <Box display="flex" alignItems="center" justifyContent={'space-between'}>
                    <Box>{`${startCase(key)} (${handleOptionCounts(key, value)}/${v1})`}</Box>
                    <Box marginLeft={'5px'}>
                      <Stack
                        style={{
                          width: '20%',
                          marginLeft: 1
                        }}
                      >
                        {v1 === handleOptionCounts(key, value) ? (
                          <Typography
                            sx={{
                              display: 'flex',
                              height: '32px',
                              width: '32px',
                              backgroundColor: caloTheme.palette.neutral50,
                              borderRadius: '32px',
                              alignItems: 'center',
                              justifyContent: 'center',
                              margin: 'auto'
                            }}
                          >
                            <Icon icon="lucide:check-circle" width="20" color={caloTheme.palette.primary600} />
                          </Typography>
                        ) : v1 < handleOptionCounts(key, value) ? (
                          <Typography
                            sx={{
                              alignItems: 'center',
                              justifyContent: 'center',
                              display: 'flex',
                              height: '32px',
                              width: '32px',
                              backgroundColor: caloTheme.palette.neutral50,
                              borderRadius: '32px',
                              margin: 'auto'
                            }}
                          >
                            <Icon icon="ph:warning-circle-thin" width="20" color={caloTheme.palette.secondaryOrange400} />
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              alignItems: 'center',
                              justifyContent: 'center',
                              display: 'flex',
                              height: '32px',
                              width: '32px',
                              backgroundColor: caloTheme.palette.neutral50,
                              borderRadius: '32px',
                              margin: 'auto'
                            }}
                          >
                            <Icon icon="ph:x-circle-thin" width="20" color={caloTheme.palette.secondaryRed300} />
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </Typography>
                <Box>
                  <Radio
                    checked={selectedValue === `${key}-${value}`}
                    onChange={() => {
                      setSelectedValue(`${key}-${value}`);
                      handleClick(key, value);
                    }}
                    value={`${key}-${value}`}
                    sx={{
                      '&.Mui-checked': {
                        color: caloTheme.palette.primary600
                      }
                    }}
                  />
                </Box>
              </Stack>
            );
          } else {
            return Object.entries(v1).map(([k, v2]) => (
              <Box
                display={'flex'}
                flexDirection={'row'}
                width={'100%'}
                sx={{ marginY: 2, width: '100%' }}
                justifyContent={'space-between'}
              >
                <Stack display={'flex'} flexDirection={'row'} width={'85%'} justifyContent={'space-between'}>
                  <Typography
                    sx={{
                      color: '#000000',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '18px'
                    }}
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Box>{`${startCase(key)} ${startCase(k)} (${handleOptionCounts(key, value, k)}/${v2})`}</Box>
                      <Box marginLeft={'5px'}>
                        <Stack
                          style={{
                            justifyContent: 'right',
                            width: '20%',
                            marginLeft: 1
                          }}
                        >
                          {handleOptionCounts(key, value, k) === (v2 as number) ? (
                            <Typography
                              sx={{
                                display: 'flex',
                                height: '32px',
                                width: '32px',
                                backgroundColor: caloTheme.palette.neutral50,
                                borderRadius: '32px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: 'auto'
                              }}
                            >
                              <Icon icon="lucide:check-circle" width="20" color={caloTheme.palette.primary600} />
                            </Typography>
                          ) : (v2 as number) < handleOptionCounts(key, value, k) ? (
                            <Typography
                              sx={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                height: '32px',
                                width: '32px',
                                backgroundColor: caloTheme.palette.neutral50,
                                borderRadius: '32px',
                                margin: 'auto'
                              }}
                            >
                              <Icon icon="mdi:warning-circle-outline" width="22" color={caloTheme.palette.secondaryOrange400} />
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                height: '32px',
                                width: '32px',
                                backgroundColor: caloTheme.palette.neutral50,
                                borderRadius: '32px',
                                margin: 'auto'
                              }}
                            >
                              <Icon icon="ph:x-circle-bold" width="20" color={caloTheme.palette.secondaryRed300} />
                            </Typography>
                          )}
                        </Stack>
                      </Box>
                    </Box>
                  </Typography>
                </Stack>
                <Box>
                  <Radio
                    checked={selectedValue === `${key}-${value}-${k}`}
                    onChange={() => {
                      setSelectedValue(`${key}-${value}-${k}`);
                      handleClick(key, value, k);
                    }}
                    value={`${key}-${value}-${k}`}
                    sx={{
                      '&.Mui-checked': {
                        color: caloTheme.palette.primary600
                      }
                    }}
                  />
                </Box>
              </Box>
            ));
          }
        })}
      <Button
        onClick={() => {
          setSelectedValue('');
          handleClick('', value, '');
          setHederOpen(undefined);
        }}
        sx={{
          marginTop: 2,
          color: caloTheme.palette.primary900,
          fontWeight: 600,
          fontFamily: 'Roboto',
          fontSize: '16px',
          lineHeight: '20px',
          letterSpacing: '0em',
          textAlign: 'center',
          '&:hover': {
            backgroundColor: caloTheme.palette.primary50
          },
          '& .MuiTouchRipple-root': {
            color: caloTheme.palette.primary50
          }
        }}
      >
        Clear Filter
        <CloseOutlinedIcon sx={{ width: '20px', height: '20px', marginLeft: '10px' }} />
      </Button>
    </Stack>
  );
};
export default MenuTableHeader;
