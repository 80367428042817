import { Country, DDeliveryStatus } from '@calo/types';
import { getRecord } from 'actions';
import cx from 'classnames';
import { Delivery, DeliveryPlan } from 'lib/interfaces';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { OptionsDropdown } from '../index';

interface Props {
  driverId: string;
  driverName: string;
  numOfDeliveries: number;
  setSelectedD: (deliveries: Delivery[]) => void;
  data: Delivery[];
  generateRouteManually: (deliveryPlanId: string, country: Country) => Promise<void>;
  deliveryPlans: DeliveryPlan[];
  setSelectedDeliveryPlan: (deliveryPlan: DeliveryPlan) => void;
  optionsId: string | null;
  setOptionsId: (id: string | null) => void;
}

const SidebarDriverOptions = ({
  driverId,
  driverName,
  numOfDeliveries,
  data,
  setSelectedD,
  generateRouteManually,
  setSelectedDeliveryPlan,
  deliveryPlans,
  optionsId,
  setOptionsId
}: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const deliveryPlan = deliveryPlans[deliveryPlans.findIndex((d) => d.driver.id === driverId)];
  const generateRoute = async () => {
    if (deliveryPlan) {
      await generateRouteManually(deliveryPlan.id, deliveryPlan.country);
      setOptionsId(null);
      return;
    }
  };

  useQuery([`/drivers/${driverId}`], getRecord, {
    enabled: !driverName,
    retry: false,
    onError: console.error,
    onSuccess(data: any) {
      if (data) {
        toast(`The driver ${data.name} (${data.phoneNumber}) from ${data.country} assigned to some delivery areas`, {
          type: 'error',
          autoClose: 5000
        });
        setError(true);
        setErrorMessage(data.name);
      }
    }
  });

  const getSelectedDeliveryPlan = () => {
    setSelectedDeliveryPlan(deliveryPlan);
  };

  return (
    <li className={cx('flex flex-row items-start h-16  text-gray-600  relative w-full border border-gray-600 rounded my-1')}>
      <button
        className="focus:outline-none absolute hover:text-gray-100 bottom-5 right-3"
        onClick={() => setOptionsId(optionsId === driverId ? null : driverId)}
      >
        <i className="fas fa-ellipsis-v"></i>
      </button>
      <button
        className="flex flex-column pl-3 focus:outline-none hover:text-gray-100  w-full h-full justify-center "
        onClick={() => {
          setSelectedD(data);
          getSelectedDeliveryPlan();
        }}
      >
        <p className={error ? 'text-red-500 font-bold' : ''}>{error ? errorMessage : driverName}</p>
        <p>
          {data.filter((d) => d.deliveryStatus === DDeliveryStatus.delivered).length || 0}/{numOfDeliveries}
        </p>
      </button>
      <OptionsDropdown visable={optionsId === driverId} buttonsDetails={[{ text: 'Generate route', method: generateRoute }]} />
    </li>
  );
};

export default SidebarDriverOptions;
