import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Link } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { TableCell, TableRow } from '@mui/material';

interface PackageElementRowProps {
  element: any;
}

const PackageElementRow = ({ element }: PackageElementRowProps) => {
  const roles = useUserRoles();

  return (
    <TableRow key={element.id} sx={{ borderBottom: '1px solid' + caloTheme.palette.neutral100 }}>
      <TableCell sx={{ fontSize: '14px' }}>
        {roles.includes(Permission.VIEW_FOOD_PACKAGE_ELEMENT) ? (
          <Link to={Routes.packageElement.replace(':id', element.id)} style={{ color: '#367BF5' }}>
            {element.name}
          </Link>
        ) : (
          element.name
        )}
      </TableCell>
      <TableCell sx={{ fontSize: '14px' }}>{element.cost}</TableCell>
      <TableCell sx={{ fontSize: '14px' }}>{element.size}</TableCell>
    </TableRow>
  );
};

export default PackageElementRow;
