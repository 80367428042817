import { format, parseISO } from 'date-fns/fp';
import { startCase } from 'lodash-es';

import { PaymentSource, Permission, WalletAction } from '@calo/dashboard-types';
import { PaymentMethod } from '@calo/types';
import { Button, Card, CardActions, CardContent, CardHeader, Stack, Typography, styled, typographyClasses } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import { Wallet } from 'lib/interfaces';

interface WalletCardRowMobileProps {
  row: Wallet;
  onRefund: (wallet: Wallet) => Promise<void> | void;
}
const WalletCardRowMobile = ({ row, onRefund }: WalletCardRowMobileProps) => {
  const roles = useUserRoles();

  const StyledTypo = styled(Typography)(() => ({
    [`&.${typographyClasses.root}`]: {
      px: 4,
      fontWeight: 400,
      fontSize: '12px',
      fontHeight: '14px',
      variant: 'caption',
      fontFamily: caloTheme.typography.fontFamily
    }
  }));
  return (
    <Card key={row.id} sx={{ width: 'full', mb: 2 }}>
      <CardHeader
        title={
          <Stack sx={{ width: 'full', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
            <StyledTypo>
              {row.amount}{' '}
              {row.action === WalletAction.ADD && row.source !== PaymentSource.credits && <span>(-{row.vat} VAT)</span>}{' '}
              {row.currency}
            </StyledTypo>

            <StyledTypo>{format('Pp')(parseISO(row.createdAt))}</StyledTypo>
          </Stack>
        }
      />
      <CardContent>
        <Stack sx={{ color: caloTheme.palette.neutral900, my: 1, width: 'full', display: 'flex', flexDirection: 'column' }}>
          <StyledTypo sx={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
            Actor:
            <StyledTypo sx={{ textAlign: 'right', width: '100%' }}>{row.actor?.name || '---'}</StyledTypo>
          </StyledTypo>

          <StyledTypo sx={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
            Source:
            <StyledTypo sx={{ textAlign: 'right', width: '100%' }}>{row.source || '---'}</StyledTypo>
          </StyledTypo>

          <StyledTypo sx={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
            Action:
            <StyledTypo sx={{ textAlign: 'right', width: '100%' }}>{row.action || '---'}</StyledTypo>
          </StyledTypo>

          {row.reason && (
            <StyledTypo sx={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
              Reason:
              <StyledTypo sx={{ textAlign: 'right', width: '100%' }}>{startCase(row.reason)}</StyledTypo>
            </StyledTypo>
          )}

          {row.notes && (
            <StyledTypo sx={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
              Note:
              <StyledTypo sx={{ textAlign: 'right', width: '100%' }}>{startCase(row.notes || '---')}</StyledTypo>
            </StyledTypo>
          )}
        </Stack>
      </CardContent>

      <CardActions sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        {row.deliveryId && (
          <Button
            variant="text"
            aria-label="ViewDelivery"
            sx={{
              width: '50%',
              height: '45px',
              fontWeight: 600,
              lineHeight: '17px',
              fontSize: '14px',
              borderRadius: '8px',
              padding: '14px 20px 14px 20px',
              color: caloTheme.palette.primary500,
              '&:hover': {
                color: caloTheme.palette.primary500
              }
            }}
            onClick={() => history.push(Routes.delivery.replace(':id', row.deliveryId!))}
          >
            View Delivery
          </Button>
        )}

        {[PaymentMethod.cc, PaymentMethod.benefit].includes(row.paymentMethod) &&
          row.action === WalletAction.ADD &&
          row.source === PaymentSource.online &&
          roles.includes(Permission.CREATE_REFUND) && (
            <Button
              variant="text"
              aria-label="Refund"
              sx={{
                width: '50%',
                height: '45px',
                fontWeight: 600,
                lineHeight: '17px',
                fontSize: '14px',
                borderRadius: '8px',
                padding: '14px 20px 14px 20px',
                color: caloTheme.palette.primary500,
                '&:hover': {
                  color: caloTheme.palette.primary500
                }
              }}
              onClick={() => onRefund(row)}
            >
              Refund
            </Button>
          )}
      </CardActions>
    </Card>
  );
};
export default WalletCardRowMobile;
