import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { format, isToday, isTomorrow, isYesterday, parseISO } from 'date-fns';

interface PhoneDatePickerProps {
  dates: string[];
  onDateSelect: (selectedDate: string) => void;
}

const DatePickerContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '44px',
  padding: '8px 0px 8px 0px',
  marginTop: '10px'
});

const DateItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ isSelected: boolean }>(({ isSelected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '8px',
  paddingRight: '8px',
  flexShrink: 0,
  height: '28px',
  cursor: 'pointer',
  borderBottom: isSelected ? `2px solid #28B17B` : '2px solid #D2D2D2',
  color: isSelected ? '#28B17B' : '#B1B1B1'
}));

const DateLabel = styled(Typography)({
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,
  textTransform: 'uppercase'
});

const formatDateLabel = (dateString: string) => {
  const date = parseISO(dateString);
  if (isToday(date)) {
    return 'Today';
  }

  if (isYesterday(date)) {
    return 'Yesterday';
  }

  if (isTomorrow(date)) {
    return 'Tomorrow';
  }
  return format(date, 'dd MMM').toUpperCase();
};

const PhoneDatePicker = ({ dates, onDateSelect }: PhoneDatePickerProps) => {
  const todayDate = dates.find((date) => isToday(parseISO(date))) || dates[0];

  const [selectedDate, setSelectedDate] = useState<string>(todayDate);

  const handleDateClick = (date: string) => {
    setSelectedDate(date);
    onDateSelect(date);
  };

  return (
    <DatePickerContainer>
      {dates.map((date) => (
        <DateItem key={date} isSelected={date === selectedDate} onClick={() => handleDateClick(date)}>
          <DateLabel>{formatDateLabel(date)}</DateLabel>
        </DateItem>
      ))}
    </DatePickerContainer>
  );
};

export default PhoneDatePicker;
