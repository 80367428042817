import { createCustomFood, getListWithParams, updateCustomFood } from 'actions/index';
import mutation from 'actions/mutation';
import { caloTheme } from 'assets/images/theme/calo';
import CaloLoader from 'components/CaloLoader';
import CustomFoodPopup from 'components/CustomFoodPopup';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import { useUserRoles } from 'lib/hooks';
import { CustomComponentsByCategory, Delivery, Food, FoodWithPosition, MenuFood } from 'lib/interfaces';
import { flatten } from 'lodash';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import { CustomMealCategory, Localized } from '@calo/types';
import { Box, Button, Stack, Typography } from '@mui/material';

import CustomMealListItem from './CustomMealListItem';

interface CustomMealsListProps {
  delivery: Delivery;
  customFood: Food | null;
  selectedMeal: FoodWithPosition;
  customFoodComponents: CustomComponentsByCategory;
  isCustomComponentsLoading: boolean;
  customFoodModalRef: React.MutableRefObject<ModalRef | undefined>;
  handleReplace: (value: MenuFood) => Promise<void>;
  setCustomFood: React.Dispatch<React.SetStateAction<Food | null>>;
}

const CustomMealsList = ({
  delivery,
  selectedMeal,
  customFood,
  isCustomComponentsLoading,
  customFoodComponents,
  customFoodModalRef,
  setCustomFood,
  handleReplace
}: CustomMealsListProps) => {
  const userRoles = useUserRoles();
  const [customMealAction, setCustomMealAction] = useState<string>('');
  const [userCustomMeals, setUserCustomMeals] = useState<any[]>([]);
  const { mutateAsync: updateCustomFoodMutation } = useMutation(updateCustomFood);
  const { mutateAsync: createCustomFoodMutation } = useMutation(createCustomFood);

  useEffect(() => {
    if (customFood && customMealAction === 'update') {
      customFoodModalRef.current?.open();
    }
  }, [customFood]);

  const handleUpdateCustomFood = async (x: any, customName: Localized, mealDescription: Localized) => {
    const customComponents = flatten(Object.values(x)).map((r: any) => ({ id: r.value }));
    if (!customFood) {
      return;
    }
    await updateCustomFoodMutation(
      {
        deliveryId: delivery.id,
        foodId: customFood.id,
        name: customName,
        description: mealDescription,
        components: customComponents.map((r) => r.id)
      },
      {
        onSuccess: () => {
          customFoodModalRef.current?.close();
          setCustomFood(null);
          refetch();
        }
      }
    );
  };

  const handleCreateCustomFood = async (
    components: any,
    customName: Localized,
    mealDescription: Localized,
    customMealCategory: CustomMealCategory
  ) => {
    const customComponents = flatten(Object.values(components)).map((r: any) => ({ id: r.value }));
    await createCustomFoodMutation(
      {
        subscriptionId: delivery.userId,
        deliveryId: delivery.id,
        name: customName,
        description: mealDescription,
        foodComponentIds: customComponents.map((r) => r.id),
        category: customMealCategory ?? CustomMealCategory.meal
      },
      {
        onSuccess: (data) => {
          customFoodModalRef.current?.close();
          handleReplace(data);
          mutation(['food/custom', { subscriptionId: delivery.userId, deliveryId: delivery.id }], data);
          refetch();
        }
      }
    );
  };

  const handleCustomMeal = (
    componentData: any,
    customName: Localized,
    mealDescription: Localized,
    customMealCategory: CustomMealCategory
  ) => {
    if (customMealAction === 'update') {
      handleUpdateCustomFood(componentData, customName, mealDescription);
    } else if (customMealAction === 'create') {
      handleCreateCustomFood(componentData, customName, mealDescription, customMealCategory);
    }
  };

  const {
    data: customMeals,
    isLoading,
    refetch
  } = useQuery<any, Error, { data: any[] }>(
    ['food/custom', { subscriptionId: delivery.userId, deliveryId: delivery.id }],
    getListWithParams,
    {
      enabled: userRoles.includes(Permission.VIEW_FOOD_COMPONENTS_CUSTOM_FOOD),
      onSuccess: (data) => {
        if (data?.data) {
          setUserCustomMeals(data.data);
        }
      }
    }
  );

  useEffect(() => {
    if (customMeals?.data) {
      setUserCustomMeals(customMeals.data);
    }
  }, [customMeals]);

  const removeFoodHandler = (id: string) => {
    setUserCustomMeals((prev) => prev.filter((meal) => meal.id !== id));
  };

  const closeCustomMealPopupHandler = () => {
    customFoodModalRef.current?.close();
    setCustomFood(null);
    setCustomMealAction('');
  };

  return (
    <Box sx={{ my: 2, borderWidth: '1px', borderColor: caloTheme.palette.neutral100, borderRadius: '8px', padding: '8px' }}>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
        <Typography
          sx={{
            fontWeight: 600,
            textTransform: 'none',
            fontSize: '20px',
            my: 'auto'
          }}
        >
          Custom Meals
        </Typography>
        {userRoles.includes(Permission.CREATE_USER_CUSTOM_FOOD) && (
          <Button
            variant="contained"
            aria-label="show-meal-macros"
            sx={{
              ml: '-16px',
              height: '45px',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '17px',
              borderRadius: '8px',
              textTransform: 'none',
              color: caloTheme.palette.white,
              backgroundColor: caloTheme.palette.primary500,
              '&:hover': {
                backgroundColor: caloTheme.palette.primary600,
                color: caloTheme.palette.white
              },
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                justifyItems: 'center',
                margin: 'auto',
                marginTop: 4,
                width: 'auto'
              }
            }}
            onClick={() => {
              setCustomMealAction('create');
              setCustomFood(null);
              customFoodModalRef.current?.open();
            }}
          >
            Create Custom Meal
          </Button>
        )}
      </Stack>
      <Box
        maxHeight={'16rem'}
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            width: '2px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1'
          }
        }}
      >
        {isLoading && <CaloLoader />}
        {userCustomMeals.length === 0 && !isLoading && (
          <Typography sx={{ marginBottom: '16px', textAlign: 'center' }}> No custom meal available</Typography>
        )}
        {!isCustomComponentsLoading &&
          userCustomMeals.map((meal: Food, index: number) => (
            <CustomMealListItem
              key={meal.id}
              isLastItem={index === userCustomMeals.length - 1}
              delivery={delivery}
              customMeal={meal}
              selectedMeal={selectedMeal!}
              isMealInDelivery={!!delivery.food.find((food) => food.id === meal.id)}
              onPick={handleReplace}
              setCustomFood={setCustomFood}
              removeMealFromList={removeFoodHandler}
              setCustomMealAction={setCustomMealAction}
            />
          ))}
      </Box>
      {userRoles.includes(Permission.VIEW_FOOD_COMPONENTS_CUSTOM_FOOD) && (
        <Popup ref={customFoodModalRef} onClose={closeCustomMealPopupHandler} maxWidth="xl">
          <CustomFoodPopup
            isLoading={isCustomComponentsLoading}
            customMealAction={customMealAction}
            customFood={customFood}
            closeHandler={closeCustomMealPopupHandler}
            handleUpdateCustomFood={handleCustomMeal}
            customFoodComps={customFoodComponents}
          />
        </Popup>
      )}
    </Box>
  );
};

export default CustomMealsList;
