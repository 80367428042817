import { useCallback, useEffect, useState } from 'react';

import { caloTheme } from 'assets/images/theme/calo';

type UseResizeProps = {
  minWidth?: number;
  initialWidth?: number;
};

type UseResizeReturn = {
  width: number;
  enableResize: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const useResize = ({
  minWidth = 800,
  initialWidth = Math.max(window.innerWidth * 0.6, 800)
}: UseResizeProps): UseResizeReturn => {
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(initialWidth);

  const enableResize = useCallback(() => {
    setIsResizing(true);
    document.body.classList.add('no-select');
  }, []);

  const disableResize = useCallback(() => {
    setIsResizing(false);
    document.body.classList.remove('no-select');
    const draggerElement = document.getElementById('dragger');
    if (draggerElement) {
      draggerElement.style.backgroundColor = '';
      document.body.style.cursor = '';
    }
  }, []);

  const resize = useCallback(
    (e) => {
      if (isResizing) {
        requestAnimationFrame(() => {
          const screenWidth = window.innerWidth;
          const newWidth = Math.min(Math.max(minWidth, screenWidth - e.clientX), screenWidth - 10);
          setWidth(newWidth);
          if (newWidth === minWidth) {
            const draggerElement = document.getElementById('dragger');
            if (draggerElement) {
              document.body.style.cursor = 'not-allowed';
              draggerElement.style.backgroundColor = caloTheme.palette.secondaryRed100;
              setTimeout(() => {
                draggerElement.style.backgroundColor = '';
                document.body.style.cursor = '';
              }, 500);
            }
          }
        });
      }
    },
    [isResizing, minWidth]
  );

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => resize(e);
    const handleMouseUp = () => disableResize();

    if (isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, resize, disableResize]);

  return { width, enableResize };
};

export default useResize;
