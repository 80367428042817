export const convertTimeToRangeWithFormatting = (time: string): [string, string] => {
  const startTime = new Date();
  const endTime = new Date();

  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':').map((val) => parseInt(val, 10));
  const isPM = time.toUpperCase().includes('PM');
  const isAM = time.toUpperCase().includes('AM');
  if (isPM && hours < 12) hours += 12;
  if (isAM && hours === 12) hours = 0;
  startTime.setHours(hours, minutes, 0, 0);
  endTime.setHours(hours + 1, minutes, 0, 0);
  const formatTime = (date: Date) => date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
  const formattedStartTime = formatTime(startTime);
  const formattedEndTime = formatTime(endTime);

  return [formattedStartTime, formattedEndTime];
};
