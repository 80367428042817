export const createAutoDownloadLinkFromUrl = (url: string, extension?: string, exportFileName?: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.target = '_blank';
  if (extension && exportFileName) {
    a.download = `${exportFileName}.${extension}`;
  }
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};
