import { SxProps, Theme } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

const styles: { [key: string]: SxProps<Theme> | undefined } = {
  tableTitle: {
    py: 2,
    textAlign: 'start',
    fontFamily: caloTheme.typography.fontFamily,
    fontWeight: 600,
    lineHeight: '23px',
    color: caloTheme.palette.neutral900,
    fontSize: '19px'
  },
  filterTypeButton: {
    textTransform: 'capitalize',
    borderRadius: '8px',
    fontSize: '16px',
    width: '141px',
    color: caloTheme.palette.neutral900,
    height: '44px',
    borderColor: caloTheme.palette.neutral900,
    marginLeft: '10px'
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '16px',
    width: '70px'
  },
  selectorNumber: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '2%',
    textAlign: 'center',
    color: 'white',
    width: '20px',
    height: '20px',
    borderRadius: '120px',
    backgroundColor: caloTheme.palette.primary500
  }
};

export default styles;
