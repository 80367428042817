import { Brand, Country, Kitchen } from '@calo/types';
import { useFormik } from 'formik';
import { CreateFoodPackageElementReq } from 'lib/interfaces';

export default (onSubmit: (values: CreateFoodPackageElementReq) => Promise<void>) =>
  useFormik<CreateFoodPackageElementReq>({
    initialValues: {
      name: '',
      size: '',
      cost: 0,
      country: Country.BH,
      brand: Brand.CALO,
      kitchen: Kitchen.BH1
    },
    validate: (values: CreateFoodPackageElementReq) => {
      const errors: any = {};
      if (!values.name) {
        errors.name = {
          ...errors.name
        };
      }

      if (!values.kitchen) {
        errors.kitchen = true;
      }

      return errors;
    },
    onSubmit: async (values) => {
      console.log(values);
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
