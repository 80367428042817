import { Subscription } from '@calo/dashboard-types';
import { Brand, FoodActionType, FoodType, Kitchen } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { getListWithParams, updateFoodPreference } from 'actions';
import { CaloLoader, MealTotalInformationSection } from 'components';
import { format, isSameMonth } from 'date-fns/fp';
import {
  CategorizedDeliveryFood,
  CustomComponentsByCategory,
  Delivery,
  FoodComponent,
  MealPlanFoodState,
  Menu,
  PaginatedDeliveries
} from 'lib/interfaces';
import handleRenderDay, { DeliveryDayStatusProps } from 'lib/renderDayStyle';
import { chunk, compact, flattenDeep, uniq, uniqBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation, useQuery } from 'react-query';
import DeliveryMealCard from './DeliveryMealCard';

interface DeliveriesCardProps {
  menu: Menu;
  foodPref: any;
  foodPrefRef: any;
  favMealIds: string[];
  menuLoading: boolean;
  deliveryLoading: boolean;
  selectedDeliveryFood: any;
  selectedDate: Date | null;
  setSelectedDeliveryFood: any;
  selectedCustomer: Subscription;
  setFavMealIds: (val: string[]) => void;
  selectedDelivery: Delivery | undefined;
  subscriptionDeliveries: PaginatedDeliveries;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setSelectedDelivery: React.Dispatch<React.SetStateAction<Delivery | undefined>>;
  delRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<Delivery, unknown>>;
}

const DeliveriesCard = ({
  selectedDeliveryFood,
  setSelectedDeliveryFood,
  delRefetch,
  favMealIds,
  setFavMealIds,
  menuLoading,
  deliveryLoading,
  selectedCustomer,
  setSelectedDelivery,
  foodPref,
  foodPrefRef,
  setSelectedDate,
  selectedDate,
  selectedDelivery,
  menu,
  subscriptionDeliveries
}: DeliveriesCardProps) => {
  const today = new Date(Date.now());
  const [newDate, setNewDate] = useState<Date>(today);
  const { mutateAsync: updateFavMeal } = useMutation(updateFoodPreference);
  const [chunkIndex, setChunkIndex] = useState<number>(0);
  const [foodComponentData, setFoodComponentsData] = useState<any[]>([]);
  const [childFoodComponentsData, setChildFoodComponentsData] = useState<any[]>([]);
  const [customFoodComponents, setCustomFoodComponents] = useState<FoodComponent[]>([]);
  const [allCustomFoodComponents, setAllCustomFoodComponents] = useState<CustomComponentsByCategory>({
    rice: [],
    meal: [],
    salad: [],
    sandwich: []
  });
  const [childComponentIds, setChildComponentsIds] = useState<string[]>([]);

  const chunkedData = useMemo(
    () =>
      chunk(
        uniq(selectedDelivery?.food?.flatMap((item) => uniq(item.components?.map((component) => component.id)))).filter(
          (compId) => !foodComponentData.some((compData) => compData.id === compId)
        ) || [],
        100
      ),
    [selectedDelivery?.food]
  );

  const chunkedData2 = useMemo(
    () =>
      chunk(
        uniq(menu?.food?.flatMap((item) => uniq(item.components?.map((component) => component.id)))).filter(
          (compId) => !foodComponentData.some((compData) => compData.id === compId)
        ) || [],
        100
      ),
    [menu]
  );

  const giftedMealComponentIdsChunked = useMemo(() => {
    const componentIds = selectedDelivery?.giftedItems?.meal
      ? selectedDelivery.giftedItems.meal.flatMap(
          (meal: any) => meal.components?.map((component: FoodComponent) => component.id) ?? []
        )
      : [];
    const chunkedComponentIds = chunk(componentIds, 100);

    return chunkedComponentIds;
  }, [selectedDelivery?.giftedItems]);

  const chunkedData3 = useMemo(
    () =>
      chunk(
        uniq(
          foodPref?.mealsCustomization
            ?.filter((customizeAction: any) => customizeAction.action === FoodActionType.swap)
            .map((swapAction: any) => swapAction.swappedWithId)
        ).filter((compId) => !foodComponentData.some((compData) => compData.id === compId)) || [],
        100
      ),
    [foodPref]
  );
  const chunkedAllData = [...chunkedData, ...chunkedData2, ...chunkedData3, ...giftedMealComponentIdsChunked];

  const [deliveryDayStatus, setDeliveryDayStatus] = useState<DeliveryDayStatusProps>({
    skipped: uniq([]),
    delivered: uniq([]),
    cancelled: uniq([]),
    pending: uniq([]),
    paused: uniq([])
  });

  useEffect(() => {
    subscriptionDeliveries?.data.filter(
      (del) => del.skipped && setDeliveryDayStatus((prevState) => ({ ...prevState, skipped: [...prevState.skipped, del.day] }))
    );
    subscriptionDeliveries?.data.filter(
      (del) =>
        del.deliveredAt && setDeliveryDayStatus((prevState) => ({ ...prevState, delivered: [...prevState.delivered, del.day] }))
    );
    subscriptionDeliveries?.data.filter(
      (del) =>
        del.status === 'cancelled' &&
        setDeliveryDayStatus((prevState) => ({ ...prevState, cancelled: [...prevState.cancelled, del.day] }))
    );
    subscriptionDeliveries?.data.filter(
      (del) =>
        !del.deliveredAt && setDeliveryDayStatus((prevState) => ({ ...prevState, pending: [...prevState.pending, del.day] }))
    );
    subscriptionDeliveries?.data.filter(
      (del) =>
        del.status === 'paused' && setDeliveryDayStatus((prevState) => ({ ...prevState, paused: [...prevState.paused, del.day] }))
    );
  }, [subscriptionDeliveries?.data]);

  const { isLoading: LoadingComponents } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        page: 0,
        filters: {
          kitchen: selectedDelivery?.kitchen || Kitchen.BH1,
          brand: Brand.CALO,
          ids: chunkedAllData[chunkIndex]
        },
        limit: 100
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled:
        chunkedAllData.length > 0 &&
        !(
          selectedDelivery?.food
            .map((item) => item.components?.map((component) => component.id))
            .filter((compId) => !foodComponentData.some((compData) => compData.id === compId)).length === 0
        ) &&
        chunkIndex <= chunkedAllData.length - 1,
      keepPreviousData: false,
      onSuccess: (data) => {
        if (data && data.data) {
          const childComponentsIds = flattenDeep(
            data.data.map((comp: any) => comp.childComponents?.map((childData: any) => childData.id))
          );
          setChildComponentsIds(compact(uniq(childComponentsIds)) || []);
          setFoodComponentsData((prev) => uniqBy([...prev, ...data.data], 'id'));
          if (chunkIndex < chunkedAllData.length) {
            setChunkIndex(chunkIndex + 1);
          }
        }
      }
    }
  );

  const { isLoading: loadingCustomComponents } = useQuery<any, Error, any>(
    [
      'food-components/custom-food',
      {
        filters: {
          kitchen: selectedDelivery?.kitchen || Kitchen.BH1,
          brand: Brand.CALO
        }
      }
    ],
    getListWithParams,
    {
      retry: false,
      onSuccess: (data) => {
        if (data) {
          setAllCustomFoodComponents((prev) => {
            return {
              ...prev,
              meal: data.mealComponents.data,
              rice: data.riceComponents.data,
              salad: data.saladComponents.data,
              sandwich: data.sandwichComponents.data
            };
          });
          if (data && data.data) {
            setCustomFoodComponents([...data.data] || []);
          }
        }
      }
    }
  );

  const { data: caloKidsComponents, isLoading: loadingCustomCaloKidsComponents } = useQuery<
    any,
    Error,
    { data: FoodComponent[] }
  >(
    [
      'food-components/custom-food',
      {
        filters: {
          country: selectedDelivery?.country,
          brand: Brand.CALO,
          kitchen: selectedDelivery?.kitchen || Kitchen.BH1,
          foodType: FoodType.caloKids
        },
        limit: 100
      }
    ],
    getListWithParams,
    {
      suspense: false
    }
  );

  const { isLoading: LoadingChildComponents } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        page: 0,
        filters: {
          kitchen: selectedDelivery?.kitchen || Kitchen.BH1,
          brand: Brand.CALO,
          ids: childComponentIds
        },
        limit: 200
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: childComponentIds.length > 0,
      keepPreviousData: false,
      onSuccess: (data) => {
        if (data && data.data) {
          setChildFoodComponentsData((prev) => uniqBy([...prev, ...data.data], 'id'));
        }
      }
    }
  );

  useEffect(() => {
    setSelectedDelivery(undefined);
    setSelectedDeliveryFood({
      breakfast: [],
      lunch: [],
      snack: [],
      caloKids: [],
      dessert: [],
      salad: [],
      juice: [],
      coffee: [],
      gifts: []
    });
    const formattedDate = selectedDate ? format('yyyy-MM-dd')(selectedDate) : null;

    const allDeliveryData = selectedDate && subscriptionDeliveries?.data.find((del) => del.day === formattedDate);
    if (allDeliveryData) {
      setSelectedDelivery(allDeliveryData);
      const initialFoodState: MealPlanFoodState = {
        breakfast: [],
        lunch: [],
        snack: [],
        juice: [],
        dessert: [],
        caloKids: [],
        salad: [],
        coffee: [],
        gifts: []
      };
      const giftedMeals =
        allDeliveryData.giftedItems && allDeliveryData.giftedItems.meal
          ? allDeliveryData.giftedItems.meal.map((meal) => ({ ...meal, isGiftedItem: true }))
          : [];

      const combinedMeals = [...(allDeliveryData.addons ?? []), ...(allDeliveryData.food ?? [])];

      const newFoodState = combinedMeals.reduce((acc: MealPlanFoodState, meal, index) => {
        const categorizedMeal: CategorizedDeliveryFood = { ...meal, positionIndex: index };
        if (meal.type)
          for (const type of meal.type) {
            if (type === FoodType.dinner) {
              continue;
            } else if (type === FoodType.lunch) {
              acc.lunch.push(categorizedMeal);
            } else if (Object.values(FoodType).includes(type)) {
              const category = FoodType[type];
              if (typeof category === 'string') {
                acc[category] = acc[category] || [];
                acc[category].push(categorizedMeal);
              }
            }
          }
        return acc;
      }, initialFoodState);
      for (const [index, meal] of giftedMeals.entries()) {
        newFoodState.gifts.push({ ...meal, isGiftedItem: meal.isGiftedItem, positionIndex: index } as any);
      }
      setSelectedDeliveryFood(newFoodState);
    }
    if (selectedDate && !isSameMonth(selectedDate, newDate)) {
      setNewDate(selectedDate);
      setSelectedDate(selectedDate);
    }
  }, [subscriptionDeliveries, selectedDate, selectedCustomer, newDate, selectedDelivery?.food]);

  useEffect(() => {
    setChunkIndex(0);
    setFoodComponentsData([]);
    setChildFoodComponentsData([]);
  }, [selectedCustomer, selectedDate, menu]);

  const handleUpdateSubscriptionFavoriteMeal = async (mealId: string) => {
    let updatedFavIds: string[] = [];
    updatedFavIds = favMealIds.includes(mealId) ? favMealIds.filter((id) => id !== mealId) : [...favMealIds, mealId];

    await updateFavMeal(
      {
        subscriptionId: selectedCustomer.id,
        allergicIng: foodPref?.ingredientsToAvoid || [],
        healthConditions: foodPref?.healthConditions.healthConditions || [],
        notes: foodPref?.healthConditions?.notes || [],
        favorite: updatedFavIds
      },
      {
        onSuccess: (updatedData) => {
          if (updatedData) {
            setFavMealIds(updatedData.foodPreference.favouriteFood);
            delRefetch();
          }
        }
      }
    );
  };

  return (
    <>
      <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
        <Stack style={{ padding: 1, justifyContent: 'center' }}>
          <Typography
            style={{
              fontFamily: 'Roboto',
              fontSize: '19px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '23px',
              letterSpacing: '-0.38px'
            }}
          >
            Delivery Day
          </Typography>
        </Stack>
        <Stack style={{ padding: '8px' }}>
          <LocalizationProvider dateAdapter={DateFnsAdapter}>
            <DesktopDatePicker
              inputFormat="dd/MM/yyyy"
              value={selectedDate || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    style: {
                      borderRadius: '8px',
                      borderColor: 'black',
                      textAlign: 'center'
                    },
                    endAdornment: (
                      <IconButton size="small" sx={{ padding: 1 }}>
                        {params.InputProps?.endAdornment}
                      </IconButton>
                    )
                  }}
                  label="Select Date"
                  InputLabelProps={{
                    style: {
                      alignContent: 'center'
                    }
                  }}
                />
              )}
              renderDay={(day, _value, DayComponentProps) => handleRenderDay(day, DayComponentProps, deliveryDayStatus)}
              onChange={(endDate: any) => endDate && setSelectedDate(endDate as Date)}
              onMonthChange={(newDate) => setSelectedDate(newDate as Date)}
            />
          </LocalizationProvider>
        </Stack>
      </Box>
      {deliveryLoading ? (
        <CaloLoader />
      ) : (
        <>
          <Box sx={{ flexDirection: 'row', width: '100%', marginY: '1px', marginX: '4px' }}>
            <Stack>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '20px'
                }}
              >
                Total Information:
              </Typography>
            </Stack>
            <MealTotalInformationSection meals={selectedDelivery?.food || []} />
          </Box>
          {selectedDelivery ? (
            <Box style={{ flexDirection: 'row', width: '100%', margin: 8 }}>
              {['breakfast', 'lunch', 'snack', 'dessert', 'caloKids', 'salad', 'juice', 'coffee', 'gifts'].map((mealType) => (
                <Stack key={mealType} display={selectedDeliveryFood[mealType]?.length > 0 ? 'flex' : 'none'}>
                  <Typography sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '20px', textTransform: 'capitalize' }}>
                    {mealType === 'lunch' ? 'lunch ' : mealType === 'caloKids' ? 'Kids' : mealType}
                  </Typography>
                  <Stack>
                    {selectedDeliveryFood[mealType].map((meal: any) => (
                      <DeliveryMealCard
                        key={meal.id}
                        meal={meal}
                        menu={menu}
                        foodPref={foodPref}
                        foodPrefRef={foodPrefRef}
                        refetch={delRefetch}
                        selectedDelivery={selectedDelivery}
                        customFoodComponents={customFoodComponents}
                        isCustomCompsLoading={loadingCustomComponents}
                        allCustomFoodComponents={allCustomFoodComponents}
                        setCustomFoodComponents={setCustomFoodComponents}
                        isFavorite={favMealIds?.includes(meal.id) || false}
                        caloKidsComponents={caloKidsComponents?.data || []}
                        setAllCustomFoodComponent={setAllCustomFoodComponents}
                        handleUpdateSubscriptionFavoriteMeal={handleUpdateSubscriptionFavoriteMeal}
                        allFoodComponentsData={uniq(compact([...(foodComponentData || []), ...(childFoodComponentsData || [])]))}
                        menuLoading={
                          menuLoading ||
                          LoadingComponents ||
                          loadingCustomComponents ||
                          LoadingChildComponents ||
                          loadingCustomCaloKidsComponents
                        }
                      />
                    ))}
                  </Stack>
                </Stack>
              ))}
            </Box>
          ) : (
            <Stack style={{ textAlign: 'center' }}>
              <Typography sx={{ paddingY: 2 }}> No Delivery</Typography>
            </Stack>
          )}
        </>
      )}
    </>
  );
};
export default DeliveriesCard;
