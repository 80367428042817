import { FoodPrototypeAction, PrototypeAction } from '@calo/dashboard-types';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns';

interface ActivityLogItemProps {
  type: 'meal' | 'component' | 'ingredient';
  action: FoodPrototypeAction;
}

const actionHeaders = {
  [PrototypeAction.create]: {
    meal: 'Meal Created',
    component: 'Component Created',
    ingredient: 'Ingredient Created'
  },
  [PrototypeAction.comment]: {
    meal: 'Comment Added to Meal',
    component: 'Comment Added to Component',
    ingredient: 'Comment Added to Ingredient'
  },
  [PrototypeAction.pend]: {
    meal: 'Meal Ready to be Reviewed',
    component: 'Component Ready to be Reviewed',
    ingredient: 'Ingredient Ready to be Reviewed'
  },
  [PrototypeAction.approve]: {
    meal: 'Meal Approved',
    component: 'Component Approved',
    ingredient: 'Ingredient Approved'
  }
};

const ActivityLogItem = ({ type, action }: ActivityLogItemProps) => {
  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'flex-start', gap: '20px' }}>
      <Stack sx={{ backgroundColor: caloTheme.palette.primary100, borderRadius: '28px', padding: '14px' }}>
        <AccessTimeIcon color="primary" fontSize="medium" />
      </Stack>
      <Stack sx={{ gap: '6px', width: '70%', alignSelf: 'center' }}>
        <Typography sx={{ fontWeight: 600 }}>{actionHeaders[action.type][type]}</Typography>
        {action.type === PrototypeAction.comment && <Typography>{action.message}</Typography>}
      </Stack>
      <Stack sx={{ marginLeft: 'auto' }}>
        <Typography>{format(new Date(action.createdAt), 'dd/MM/yyyy hh:mm a')}</Typography>
        <Typography>Added by {action.actor.name}</Typography>
      </Stack>
    </Stack>
  );
};

export default ActivityLogItem;
