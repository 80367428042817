import { Permission } from '@calo/dashboard-types';
import { CustomMealCategory, Dictionary, Localized, Macros } from '@calo/types';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Stack, TextField, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { getIngredientsAllergens } from 'lib/helpers/ingredientAllergens';
import { useUserRoles } from 'lib/hooks';
import { ComponentLabelValue, ComponentsByTag, FoodComponent } from 'lib/interfaces';
import { MacrosCard, MealNameCard, SelectedComponentCard } from '../Shared';
import IngredientList from '../Shared/IngredientList';
interface ConfirmationFormProps {
  mealDescription: Localized;
  customFoodName: Localized;
  totalMacros: Macros;
  customMealCategory: CustomMealCategory;
  selectedComponents: ComponentsByTag;
  keyedComponents: Dictionary<FoodComponent>;
  handleNameChange: (data: string) => void;
  handleDescriptionChange: (description: string) => void;
}

const FinalForm = ({
  mealDescription,
  customFoodName,
  totalMacros,
  selectedComponents,
  keyedComponents,
  customMealCategory,
  handleNameChange,
  handleDescriptionChange
}: ConfirmationFormProps) => {
  const userRoles = useUserRoles();

  const ingredients = Object.values(selectedComponents)
    .flat()
    .flatMap((comp: ComponentLabelValue) => keyedComponents[comp.value]?.ingredients ?? []);

  const allAllergens = getIngredientsAllergens(ingredients);

  return (
    <Stack sx={{ marginBottom: '80px', width: '100%' }}>
      <MealNameCard customFoodName={customFoodName} handleNameChange={handleNameChange} />
      <Stack sx={{ marginTop: '10px' }} className="mb-4">
        <TextField
          label="Meal Description (optional)"
          name={mealDescription.en}
          id="description"
          placeholder="Enter Meal Description"
          value={mealDescription.en}
          sx={{ width: '100%', mr: 2 }}
          disabled={!userRoles.includes(Permission.UPDATE_DELIVERY_CUSTOM_FOOD)}
          onChange={(data: any) => handleDescriptionChange(data.target.value)}
        />
      </Stack>
      <MacrosCard macros={totalMacros} />
      <Stack
        id="selected-components"
        sx={{
          flexDirection: 'row',
          gap: '20px',
          marginTop: '4px',
          marginBottom: '6px',
          overflowX: 'scroll',
          scrollbarWidth: 'none', // For Firefox
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        {Object.values(selectedComponents)
          .flat()
          .map((component: ComponentLabelValue, index) => (
            <SelectedComponentCard
              key={`${component.value}#${index}`}
              component={keyedComponents[component.value]}
              customMealCategory={customMealCategory}
            />
          ))}
      </Stack>
      <Stack id="ingredients" sx={{ gap: '4px' }}>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Ingredients</Typography>
        <IngredientList ingredients={ingredients} />
      </Stack>
      {allAllergens.length > 0 && (
        <Stack sx={{ flexDirection: 'row', gap: '8px', marginTop: '4px' }}>
          <WarningAmberRoundedIcon sx={{ color: caloTheme.palette.red500 }} fontSize="small" />
          <Typography sx={{ color: caloTheme.palette.red500, fontSize: '14px' }}>Contains {allAllergens.join(', ')}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default FinalForm;
