import { caloTheme } from 'assets/images/theme/calo';
import { EmployeePerksStatus } from 'lib/enums';

import { Icon } from '@iconify/react';

interface PerksIconProps {
  status: EmployeePerksStatus;
}

const PerksIcon = ({ status }: PerksIconProps) => {
  const getIconProps = () => {
    switch (status) {
      case EmployeePerksStatus.success:
        return {
          icon: 'uim:check',
          style: {
            color: caloTheme.palette.primary900,
            backgroundColor: caloTheme.palette.primary100
          }
        };
      case EmployeePerksStatus.pending:
        return {
          icon: 'ri:loader-2-line', // or lucide:loader
          style: {
            color: caloTheme.palette.neutral500,
            backgroundColor: caloTheme.palette.neutral100
          }
        };
      case EmployeePerksStatus.failed:
        return {
          icon: 'gg:close',
          style: {
            color: caloTheme.palette.secondaryRed200,
            backgroundColor: caloTheme.palette.secondaryRed50
          }
        };
      default:
        return {
          icon: 'lucide:loader',
          style: {
            color: caloTheme.palette.neutral500,
            backgroundColor: caloTheme.palette.neutral100
          }
        };
    }
  };

  const { icon, style } = getIconProps();

  return (
    <Icon
      icon={icon}
      width={40}
      height={40}
      style={{
        ...style,
        padding: 4,
        borderRadius: '50%'
      }}
    />
  );
};

export default PerksIcon;
