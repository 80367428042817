import { omit } from 'lodash-es';
import { ComplaintReason } from '../enums';

export const complaintsReasonsHelper = {
  getOptions: () => ({
    [ComplaintReason.HEALTH_RELATED_CONCERNS_COMPLAINTS]: [
      { value: 'FOOD_POISONING', label: 'Food poisoning' },
      { value: 'STOMACH_ACHE', label: 'Stomach ache' },
      { value: 'FEELING_SICK', label: 'Feeling sick' },
      { value: 'OTHER', label: 'Other' }
    ],
    [ComplaintReason.COLD_SECTION_RELATED_ERRORS]: [
      { value: 'WRONG_MEAL', label: 'Wrong meal' },
      { value: 'PRESENTATION_OF_THE_MEAL', label: 'Presentation of the meal' },
      { value: 'MISSING_COMPONENT', label: 'Missing component' },
      { value: 'WRONG_PORTION', label: 'Wrong portion' },
      { value: 'WRONG_MEAL_PREPARED', label: 'Wrong meal prepared' },
      { value: 'WRONG_COMPONENT', label: 'Wrong component' },
      { value: 'TORN_TORTILLA', label: 'Torn tortilla' },
      { value: 'TORN_WRAPPING_PAPER', label: 'Torn wrapping paper' },
      {
        value: 'WRAPPING_PAPER_INSIDE_THE_SANDWICH',
        label: 'Wrapping paper inside the sandwich'
      },
      { value: 'OTHER', label: 'Other' }
    ],
    [ComplaintReason.SPILLED_LIQUIDS_OR_FOOD_COMPLAINTS]: [
      { value: 'SPILLED_SOUP', label: 'Spilled Soup' },
      { value: 'SPILLED_FOOD', label: 'Spilled Food' },
      { value: 'SPILLED_SAUCE', label: 'Spilled Sauce' },
      { value: 'SPILLED_COFFEE', label: 'Spilled coffee' },
      { value: 'OTHER', label: 'Other' }
    ],
    [ComplaintReason.DISPATCH_RELATED_ERRORS]: [
      { value: 'MISSING_SAUCE', label: 'Missing sauce' },
      { value: 'WRONG_PORTION', label: 'Wrong portion' },
      { value: 'WRONG_MEAL_SNACK', label: 'Wrong meal/snack' },
      { value: 'MISSING_LABEL', label: 'Missing label' },
      { value: 'SPILLED_FOOD', label: 'Spilled food' },
      { value: 'MISSING_MEAL_SNACK', label: 'Missing meal/snack' },
      { value: 'MISSING_COMPONENTS', label: 'Missing components' },
      { value: 'OPENED_CONTAINER', label: 'Opened container' },
      { value: 'MISSING_CUTLERY', label: 'Missing cutlery' },
      { value: 'CANCELLED', label: 'Cancelled' },
      { value: 'WRONG_ADD-ON', label: 'Wrong add-on' },
      { value: 'MISSING_ADD-ON', label: 'Missing add-on' },
      { value: 'WRONG_LABEL', label: 'Wrong label' },
      { value: 'EXTRA_MEAL_SNACK', label: 'Extra meal/snack' },
      { value: 'PRESENTATION_OF_THE_MEAL', label: 'Presentation of the meal' },
      { value: 'WRONG_SAUCE', label: 'Wrong sauce' },
      { value: 'WRONG_COMPONENT', label: 'Wrong component' },
      { value: 'OPENED_CUTLERY_SET', label: 'Opened cutlery set' },
      { value: 'WRONG_CARD', label: 'Wrong card' },
      { value: 'WRONG_SAUCE_PORTION', label: 'Wrong sauce portion' },
      { value: 'DAMAGED_CONTAINER', label: 'Damaged container' },
      { value: 'PRINTING_ERROR', label: 'Printing error' },
      { value: 'OPENED_BAG', label: 'Opened bag' },
      { value: 'EMPTY_LABEL', label: 'Empty label' },
      {
        value: 'COMPONENT_WAS_NOT_REMOVED',
        label: 'Component was not removed'
      },
      {
        value: 'WRONG_COMPONENT_WAS_REMOVED',
        label: 'Wrong component was removed'
      },
      { value: 'OTHER', label: 'Other' }
    ],
    [ComplaintReason.QUALITY_RELATED_COMPLAINTS]: [
      { value: 'SMELL', label: 'Smell' },
      { value: 'TEXTURE', label: 'Texture' },
      { value: 'UNDERCOOKED', label: 'Undercooked' },
      { value: 'OVERCOOKED', label: 'Overcooked' },
      { value: 'SALTY', label: 'Salty' },
      { value: 'NOT_CLEAN', label: 'Not clean' },
      { value: 'COLOR', label: 'Color' },
      { value: 'ROTTEN', label: 'Rotten' },
      { value: 'WILTED', label: 'Wilted' },
      { value: 'TASTE', label: 'Taste' },
      { value: 'QUALITY', label: 'Quality' },
      { value: 'MOLD', label: 'Mold' },
      { value: 'RANCID', label: 'Rancid' },
      { value: 'TORN_TORTILLA', label: 'Torn tortilla' },
      { value: 'SMELL_OF_THE_BAG', label: 'Smell of the bag' },
      { value: 'WATERY', label: 'Watery' },
      { value: 'MEAL_LUMPS', label: 'Meal lumps' },
      { value: 'FUNGUS', label: 'Fungus' },
      { value: 'PRESENTATION_OF_THE_MEAL', label: 'Presentation of the meal' },
      {
        value: 'CHEMICAL_SMELL_AND_INSECTICIDE_TASTE',
        label: 'Chemical smell & Insecticide taste'
      },
      { value: 'SMELL_OF_THE_CHICKEN', label: 'Smell of the chicken' },
      { value: 'CONTAINER_LAMINATION', label: 'Container lamination' },
      { value: 'DRY', label: 'Dry' },
      { value: 'UNCLEANED_SHRIMP', label: 'Uncleaned shrimp' },
      { value: 'GAS_TASTE_AND_SMELL', label: 'Gas taste & smell' },
      { value: 'UNCUT_COMPONENT', label: 'Uncut component' },
      { value: 'OTHER', label: 'Other' }
    ],
    [ComplaintReason.FOREIGN_OBJECTS_FOUND]: [
      { value: 'HAIR', label: 'Hair' },
      { value: 'PLASTIC', label: 'Plastic' },
      { value: 'SEEDS', label: 'Seeds' },
      { value: 'PAPER', label: 'Paper' },
      { value: 'BONE', label: 'Bone' },
      { value: 'STONE', label: 'Stone' },
      { value: 'WOOD', label: 'Wood' },
      { value: 'HARD_OBJECT', label: 'Hard object' },
      { value: 'BUG', label: 'Bug' },
      { value: 'SHELLS', label: 'Shells' },
      { value: 'STAPLER', label: 'Stapler' },
      { value: 'WORM', label: 'Worm' },
      { value: 'GLASS', label: 'Glass' },
      { value: 'VEGGIES', label: 'Veggies' },
      { value: 'STICK', label: 'Stick' },
      { value: 'METAL', label: 'Metal' },
      { value: 'HAIR-LIKE_OBJECT', label: 'Hair-like object' },
      { value: 'ALUMINUM', label: 'Aluminum' },
      { value: 'PEELS', label: 'Peels' },
      { value: 'NAIL', label: 'Nail' },
      { value: 'THREAD', label: 'Thread' },
      { value: 'FLY', label: 'Fly' },
      { value: 'CUTLERY_FOREIGN_OBJECT', label: 'Cutlery foreign object' },
      { value: 'OBJECT', label: 'Object' },
      { value: 'WHEAT', label: 'Wheat' },
      { value: 'ANT', label: 'Ant' },
      { value: 'STEM', label: 'Stem' },
      { value: 'TAPE', label: 'Tape' },
      { value: 'SPLINTERS', label: 'Splinters' },
      { value: 'NYLON', label: 'Nylon' },
      { value: 'PAPER_STICKER', label: 'Paper Sticker' },
      { value: 'INSECT', label: 'Insect' },
      { value: 'SCREW', label: 'Screw' },
      { value: 'RUBBER-LIKE_OBJECT', label: 'Rubber-like object' },
      { value: 'SAND', label: 'Sand' },
      { value: 'BAKING_PAPER', label: 'Baking paper' },
      { value: 'GLOVE_PIECE', label: 'Glove piece' },
      { value: 'OLIVE_PIT', label: 'Olive pit' },
      { value: 'RICE_SACK_THREAD', label: 'Rice sack thread' },
      { value: 'CLUSTER', label: 'Cluster' },
      { value: 'STYROFOAM', label: 'Styrofoam' },
      { value: 'OTHER', label: 'Other' }
    ],
    [ComplaintReason.FOOD_RELATED_FOREIGN_OBJECTS_FOUND]: [
      { value: 'VEGGIES', label: 'Veggies' },
      { value: 'PEELS', label: 'Peels' },
      { value: 'STEM', label: 'Stem' },
      { value: 'OTHER', label: 'Other' }
    ],
    [ComplaintReason.NON_FOOD_RELATED_FOREIGN_OBJECTS_FOUND]: [
      { value: 'HAIR', label: 'Hair' },
      { value: 'PLASTIC', label: 'Plastic' },
      { value: 'SEEDS', label: 'Seeds' },
      { value: 'PAPER', label: 'Paper' },
      { value: 'BONE', label: 'Bone' },
      { value: 'STONE', label: 'Stone' },
      { value: 'WOOD', label: 'Wood' },
      { value: 'SHELLS', label: 'Shells' },
      { value: 'GLASS', label: 'Glass' },
      { value: 'METAL', label: 'Metal' },
      { value: 'ALUMINUM', label: 'Aluminum' },
      { value: 'NAIL', label: 'Nail' },
      { value: 'CUTLERY_FOREIGN_OBJECT', label: 'Cutlery foreign object' },
      { value: 'TAPE', label: 'Tape' },
      { value: 'NYLON', label: 'Nylon' },
      { value: 'INSECT', label: 'Insect' },
      { value: 'SAND', label: 'Sand' },
      { value: 'GLOVE_PIECE', label: 'Glove piece' },
      { value: 'OLIVE_PIT', label: 'Olive pit' },
      { value: 'RICE_SACK_THREAD', label: 'Rice sack thread' },
      { value: 'STYROFOAM', label: 'Styrofoam' },
      { value: 'OTHER', label: 'Other' }
    ]
  }),
  getSelectableOptions: () => {
    const options = complaintsReasonsHelper.getOptions();
    const filteredOptions = omit(options, [ComplaintReason.FOREIGN_OBJECTS_FOUND]);

    return filteredOptions;
  }
};
