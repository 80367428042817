import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';

import { FoodStatsFilters } from '@calo/dashboard-types';
import { Icon as Iconify } from '@iconify/react';
import { caloTheme } from 'assets/images/theme/calo';
import { StatsFoodComponent } from 'lib/interfaces';
import MainTable from './MainTable';
import onExport from './onExport';
import useResize from './useResize';

interface ComponentsDrawerProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  component?: StatsFoodComponent;
  filters: FoodStatsFilters;
}

export default function ComponentsDrawer({ isOpen, setIsOpen, component, filters }: ComponentsDrawerProps) {
  const { width, enableResize } = useResize({});
  const componentFoodStats = component?.keyedFood ? Object.values(component.keyedFood) : [];

  const handleExport = async () => {
    if (!component || !componentFoodStats.length) {
      return;
    }
    await onExport({ component, componentFoodStats, filters });
  };

  return (
    <React.Fragment>
      <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        variant="persistent"
        sx={{
          zIndex: 49,
          border: 0,
          '& .MuiDrawer-paper': {
            border: 0,
            boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.10)'
          }
        }}
        PaperProps={{ style: { width } }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
          sx={{ overflow: 'auto', paddingTop: '100px', mx: 2 }}
        >
          <Box
            id="dragger"
            onMouseDown={(e) => enableResize(e)}
            sx={{
              width: '5px',
              cursor: 'ew-resize',
              padding: '4px 0 0',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              zIndex: '100'
            }}
          />
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
            <Typography
              sx={{
                textTransform: 'capitalize',
                textAlign: 'left',
                fontSize: '20px',
                lineHeight: '24px',
                fontWeight: 600,
                color: caloTheme.palette.neutral900
              }}
            >
              {component?.name.en}
            </Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <IconButton>
                <Iconify
                  icon="lucide:download"
                  width="16"
                  height="16"
                  color={caloTheme.palette.neutral900}
                  onClick={() => handleExport()}
                />
              </IconButton>
              <IconButton aria-label="close" onClick={() => setIsOpen(false)} sx={{ color: caloTheme.palette.neutral900 }}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
          {component && <MainTable component={component} />}
        </Stack>
      </Drawer>
    </React.Fragment>
  );
}
