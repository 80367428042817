import DateFnsAdapter from '@date-io/date-fns';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, TextFieldProps } from '@mui/material';
import { LocalizationProvider, PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { format, getYear, startOfWeek } from 'date-fns';
import { findStartAndEndDateForWeek } from 'lib';
import PaperContent from './PaperContent';
import { styles } from './styles';

interface CalenderDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectedDate: Date;
  selectedWeek: number;
  setSelectedDate: (date: Date) => void;
  setSelectedWeek: React.Dispatch<React.SetStateAction<number>>;
  isWeeklyMenuChanged: boolean;
  onDone: (weekNumber: number, year: number) => void;
}

const CalenderDialog = ({
  isOpen,
  onClose,
  selectedDate,
  selectedWeek,
  setSelectedDate,
  setSelectedWeek,
  isWeeklyMenuChanged,
  onDone
}: CalenderDialogProps) => {
  const handleRenderDateInput = (params: TextFieldProps) => {
    const { inputProps, ...rest } = params;
    const { startOfWeekDate, endOfWeekDate } = findStartAndEndDateForWeek(selectedWeek);
    const updatedInputProps = {
      ...inputProps,
      value: `Week ${selectedWeek} (${format(startOfWeekDate, 'dd/MM')} - ${format(endOfWeekDate, 'dd/MM')})`
    };

    return (
      <InputThemeProvider>
        <TextField {...rest} inputProps={updatedInputProps} sx={styles.dateInputStyle} error={false} />
      </InputThemeProvider>
    );
  };

  const handleRenderDay = (day: Date, DayComponentProps: any) => {
    const { startOfWeekDate, endOfWeekDate } = findStartAndEndDateForWeek(selectedWeek);
    if (
      format(day, 'yyyy-MM-dd') < format(startOfWeekDate, 'yyyy-MM-dd') ||
      format(day, 'yyyy-MM-dd') > format(endOfWeekDate, 'yyyy-MM-dd')
    ) {
      return <PickersDay disabled sx={{ backgroundColor: 'none' }} {...DayComponentProps} />;
    }
    return <PickersDay {...DayComponentProps} onClick={() => null} sx={styles.pickerStyle} />;
  };

  const handleDoneClick = () => {
    const year = getYear(startOfWeek(new Date(selectedDate), { weekStartsOn: 0 }));
    onDone(selectedWeek, year);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        Select Week
        <IconButton aria-label="close" onClick={onClose} sx={styles.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
          <StaticDatePicker
            components={{
              // eslint-disable-next-line react/no-multi-comp
              PaperContent: (props) => (
                <PaperContent
                  {...props}
                  selectedDate={selectedDate}
                  selectedWeek={selectedWeek}
                  setSelectedWeek={setSelectedWeek}
                />
              )
            }}
            label="Week"
            onChange={() => {}}
            value={selectedDate}
            disabled={isWeeklyMenuChanged}
            displayStaticWrapperAs="desktop"
            renderInput={handleRenderDateInput}
            onMonthChange={(event) => setSelectedDate(new Date(event))}
            renderDay={(day, _value, DayComponentProps) => handleRenderDay(new Date(day), DayComponentProps)}
          />
        </LocalizationProvider>
      </DialogContent>

      <DialogActions style={styles.popupContainer}>
        <Button onClick={handleDoneClick} color="primary" variant="contained" sx={styles.buttons}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CalenderDialog;
