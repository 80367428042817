import { CustomMealCategory, FoodComponentType, Localized } from '@calo/types';
import { Button } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { handleTagBarText } from 'components/FoodComponentShared/CustomSectionCard/helpers';
import { ComponentsByTag } from 'lib/interfaces';
import { flatten } from 'lodash-es';

interface CustomMealButtonProps {
  isSubmitting: boolean;
  isFinished: boolean;
  customMealAction: string;
  customFoodName: Localized;
  selectedTag: FoodComponentType;
  selectedComponents: ComponentsByTag;
  clickHandler: () => void;
  customMealCategory: CustomMealCategory;
}

const CustomMealButton = ({
  isSubmitting,
  customMealAction,
  customFoodName,
  selectedTag,
  selectedComponents,
  isFinished,
  clickHandler,
  customMealCategory
}: CustomMealButtonProps) => {
  const findNextTag = () => {
    const tags = Object.keys(selectedComponents);
    const indexOfCurrentTag = tags.indexOf(selectedTag);

    if (indexOfCurrentTag + 1 !== tags.length) {
      return `Next, ${handleTagBarText(tags[indexOfCurrentTag + 1], customMealCategory)}`;
    }
    if (!isFinished) {
      return 'Done';
    }
    return customMealAction === 'create' ? 'Create Custom Meal' : 'Update Custom Meal';
  };

  const isButtonDisabled = () => {
    const categoriesWithSelectedComponents = Object.keys(selectedComponents).filter(
      (category) => selectedComponents[category as FoodComponentType].length > 0
    ).length;
    if (categoriesWithSelectedComponents < 2 || !customFoodName.en) {
      return true;
    }
    return false;
  };

  return (
    <Button
      variant="contained"
      disabled={
        isSubmitting ||
        isButtonDisabled() ||
        flatten(Object.values(selectedComponents)).map((r: any) => ({ id: r.value })).length === 0
      }
      sx={{
        position: 'sticky',
        width: '50%',
        marginTop: 'auto',
        marginX: 'auto',
        bottom: '10px',
        right: '10px',
        fontWeight: 600,
        py: '10px',
        fontSize: '14px',
        borderRadius: '8px',
        textTransform: 'none',
        color: caloTheme.palette.white,
        backgroundColor: caloTheme.palette.primary500,
        '&:hover': {
          backgroundColor: caloTheme.palette.primary600,
          color: caloTheme.palette.white
        },
        ':disabled': {
          backgroundColor: caloTheme.palette.neutral300
        },
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          justifyItems: 'center',
          margin: 'auto',
          marginTop: 4,
          width: 'auto'
        }
      }}
      onClick={clickHandler}
    >
      {findNextTag()}
    </Button>
  );
};

export default CustomMealButton;
