import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '20vh',
    width: 'auto',
    flex: 1,
    '&::-webkit-scrollbar': { height: '0.4em' },
    '&::-webkit-scrollbar-thumb': { backgroundColor: '#888' },
    overflowX: 'auto',
    overflowY: 'hidden'
  },
  customComponentContainer: {
    overflow: 'auto',
    '&::-webkit-scrollbar': { height: '0.4em' },
    '&::-webkit-scrollbar-thumb': { backgroundColor: '#888' }
  },
  mainGrid: {
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingY: 2
  },
  componentCard: {
    border: 2,
    marginRight: 3,
    width: '120px',
    borderRadius: '8px',
    height: '164px',
    minHeight: '164px',
    boxShadow: 'none'
  },
  swappedText: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '12px',
    m: 1,
    fontStyle: 'normal',
    letterSpacing: '0.02em',
    color: caloTheme.palette.primary500,
    '&:hover': {
      color: caloTheme.palette.primary500
    }
  },
  removedText: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '12px',
    m: 1,
    fontStyle: 'normal',
    letterSpacing: '0.02em',
    color: caloTheme.palette.red,
    '&:hover': {
      color: caloTheme.palette.red
    }
  },
  imageContainer: {
    my: 2,
    alignItems: 'center',
    zIndex: 0
  },
  imageBox: {
    width: '80px',
    height: '70px',
    justifyContent: 'center',
    my: 2,
    borderRadius: 4
  },
  componentNameText: {
    mt: '4px',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '17px',
    textAlign: 'center',
    color: caloTheme.palette.neutral900,
    width: '100px'
  },
  removeComponentBox: {
    position: 'relative',
    alignItems: 'start',
    mr: '2px',
    mb: '-28px',
    zIndex: 10
  },
  swapComponentBox: {
    position: 'relative',
    alignItems: 'start',
    mr: '2px',
    mb: '-28px',
    zIndex: 10
  },
  changeText: {
    marginTop: 2,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    textAlign: 'start',
    color: caloTheme.palette.neutral900
  },
  removeComponent: {
    borderWidth: 1,
    borderColor: caloTheme.palette.white,
    backgroundColor: caloTheme.palette.neutral100,
    height: 110,
    marginTop: 6,
    marginX: 8,
    borderRadius: 8
  },
  removeIcon: {
    display: 'relative',
    width: 48,
    height: 48,
    alignSelf: 'center',
    margin: 'auto'
  },
  removeComponentStack: {
    position: 'relative',
    borderWidth: 1,
    borderColor: caloTheme.palette.white,
    flex: 1,
    alignSelf: 'center'
  },
  originalBox: {
    position: 'relative',
    justifyContent: 'start',
    width: '100%',
    mr: '2px',
    marginBottom: '-10px',
    zIndex: 10
  },
  originalText: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '12px',
    mx: '2px',
    fontStyle: 'normal',
    letterSpacing: '0.02em',
    color: caloTheme.palette.primary500,
    '&:hover': {
      color: caloTheme.palette.primary600
    }
  },
  formControlBox: {
    flexDirection: 'row',
    justifyContent: 'right',
    width: '100%',
    mb: '-10px'
  },
  radioButton: {
    mt: '-16px',
    marginRight: '-10px',
    justifyContent: 'right'
  }
};

export default styles;
