import { CodeChannel, CouponCategory, CouponMode, CreateCouponReq } from '@calo/dashboard-types';
import { Brand, CouponType, Currency } from '@calo/types';
import { useFormik } from 'formik';

export default (onSubmit: (values: Omit<CreateCouponReq, 'id'>) => any) =>
  useFormik<Omit<CreateCouponReq, 'id'>>({
    initialValues: {
      code: '',
      amount: 0,
      expiresAt: '',
      rules: [],
      currency: Currency.BHD,
      category: CouponCategory.promo,
      type: CouponType.PERCENTAGE,
      channel: CodeChannel.CALO_PROMOS,
      channelCategory: undefined,
      assignee: '',
      brand: Brand.CALO,
      mode: CouponMode.DISCOUNT
    },
    validate: (values: Omit<CreateCouponReq, 'id'>) => {
      const errors: any = {};

      if (!values.code) {
        errors.code = true;
      }
      if (!values.rules) {
        errors.rule = true;
      }
      return errors;
    },

    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
