import { DeliveryPlanFilters } from '@calo/dashboard-types';
import { Country, DeliveryTime, Kitchen } from '@calo/types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { toggleUISettings } from 'actions';
import { Select, Select2, SideDrawer } from 'components';
import { format, parseISO } from 'date-fns/fp';
import { getAccessibleCountries, setCountryToLocalStorage } from 'lib/helpers';
import { getKitchenOptions } from 'lib/helpers/kitchenUtils';
import { useUserKitchens } from 'lib/hooks';
import { AppState, Driver } from 'lib/interfaces';
import { omit, sortBy } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

interface SettingsProps {
  onFilter: (values: DeliveryPlanFilters) => any;
  filters: DeliveryPlanFilters;
  drivers: Driver[];
}

const Settings = ({ filters, onFilter, drivers }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  const changeCountry = (data: any) => {
    setCountryToLocalStorage(data.target.value);
    onFilter({
      ...filters,
      country: data.target.value as Country,
      kitchen: Object.values(Kitchen).find((k) => k.includes(data.target.value) && !k.includes('000')) as Kitchen,
      driverId: 'ANY'
    });
  };

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Country"
          value={filters.country}
          onChange={(data: any) => changeCountry(data)}
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(data) => onFilter({ ...filters, kitchen: data.target.value as Kitchen })}
          options={getKitchenOptions(userKitchens, filters.country)}
          disabled={Object.values(Kitchen).filter((r) => r.includes(filters.country!)).length === 0}
        />

        <Select
          label="Delivery Time"
          value={filters.time}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['deliveryTime']),
              ...(v.value &&
                ({
                  time: v.value
                } as any))
            })
          }
          options={[
            {
              value: DeliveryTime.morning,
              label: 'Morning'
            },
            {
              value: DeliveryTime.evening,
              label: 'Evening'
            }
          ]}
        />

        <Select2
          label="Driver"
          value={filters.driverId}
          onChange={(e) => onFilter({ ...filters, driverId: e.target.value })}
          options={[
            {
              value: 'ANY',
              label: 'All'
            },
            ...sortBy(drivers, 'name').map((d) => ({
              value: d.id,
              label: `${d.name} (${d.phoneNumber})`
            }))
          ]}
        />

        <div className="field">
          <label className="label">Day</label>
          <div className="control is-clearfix">
            <DateRangePicker
              clearIcon={null}
              onChange={(dates: any) => {
                onFilter({
                  ...filters,
                  day: {
                    gte: format('yyyy-MM-dd')(dates[0]),
                    lte: format('yyyy-MM-dd')(dates[1])
                  }
                });
              }}
              value={[parseISO(filters.day.gte), parseISO(filters.day.lte)]}
            />
          </div>
        </div>
      </section>
    </SideDrawer>
  );
};

export default Settings;
