import { Button, Stack } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { orderMealsBySize, resolveSize } from 'lib/helpers';
import { Food } from 'lib/interfaces';

interface SizesSwitchProps {
  selectedFood: Food;
  foodWithAllSizes: Food[];
  setSelectedFood: (food: Food) => void;
}

const SizesSwitch = ({ selectedFood, foodWithAllSizes, setSelectedFood }: SizesSwitchProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ backgroundColor: caloTheme.palette.neutral50, borderRadius: '12px', p: '4px', gap: '28px' }}
    >
      {orderMealsBySize(foodWithAllSizes!).map((food) => (
        <Button
          key={food.id}
          variant={'contained'}
          sx={{
            color: food.id === selectedFood.id ? caloTheme.palette.white : caloTheme.palette.neutral600,
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'capitalize',
            gap: '10px',
            borderColor: caloTheme.palette.neutral900,
            borderRadius: '8px',
            backgroundColor: food.id === selectedFood.id ? caloTheme.palette.primary500 : caloTheme.palette.neutral50,
            boxShadow: 'none',
            '&:hover': {
              borderColor: caloTheme.palette.primary500,
              backgroundColor: caloTheme.palette.primary500,
              color: caloTheme.palette.white,
              boxShadow: 'none'
            }
          }}
          onClick={() => {
            setSelectedFood(food);
          }}
        >
          {resolveSize(food.size)}
        </Button>
      ))}
    </Stack>
  );
};

export default SizesSwitch;
