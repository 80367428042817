import { caloTheme } from 'assets/images/theme/calo';
import { GlobalStyles } from 'lib/interfaces';

export const styles: GlobalStyles = {
  mealListBox: {
    width: '18%',
    border: `1px solid ${caloTheme.palette.neutral100}`,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 6px'
  },
  dateTypography: {
    fontSize: '14px',
    fontWeight: 600,
    alignSelf: 'center',
    marginBottom: '8px'
  },
  mealStack: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  mealBox: {
    padding: '10px 6px',
    minHeight: '96px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '4px',
    alignContent: 'center'
  },
  recurringBackground: {
    backgroundColor: '#FAEEEE'
  },
  defaultBackground: {
    backgroundColor: 'white'
  },
  mealNameTypography: {
    fontSize: '14px',
    marginBottom: '6px'
  },
  iconButton: {
    cursor: 'pointer',
    display: 'flex',
    width: '32px',
    height: '32px',
    borderRadius: '16px',
    textAlign: 'center',
    padding: '4px',
    marginTop: '-4px'
  },
  costTypography: {
    marginTop: 1,
    marginBottom: 1,
    fontSize: '16px',
    display: 'flex'
  },
  infoIcon: {
    cursor: 'pointer',
    width: '24px',
    height: '24px'
  },
  separator: {
    width: '100%',
    height: '1px',
    backgroundColor: caloTheme.palette.neutral100
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: '2px',
    mb: '-2px'
  },
  warningContainer: {
    height: '26px',
    width: '26px',
    backgroundColor: '#FAE7E7',
    display: 'flex',
    padding: 2,
    alignItems: 'flex-start',
    gap: 4,
    borderRadius: '280px'
  },
  warningIcon: { width: '18px', alignSelf: 'center' },
  text: { fontSize: '14px', marginBottom: '6px', width: '90%' },
  row: { flexDirection: 'row', justifyContent: 'space-between' },
  mealSizeText: { fontWeight: 600, fontSize: '14px', lineHeight: '17px', mt: '3px' }
};
