import { useContext, useEffect, useRef, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { LatLng } from '@calo/driver-types';
import { Country } from '@calo/types';
import { DrawingManager, InfoWindow } from '@react-google-maps/api';

import { Permission } from '@calo/dashboard-types';
import { getList, updateKitchen } from 'actions';
import { useUserRoles } from 'lib/hooks';
import { Kitchen } from 'lib/interfaces';
import { flatten } from 'lodash-es';
import { CityAreaForm } from '../CityAreaForm';
import PolygonContext from '../Polygon/PolygonContext';
import { KitchenPolygonRowState } from '../Polygon/types';
interface KitchenPolygonManagerProps {
  polygonState: KitchenPolygonState;
  closePopUp: () => void;
  country: Country;
  kitchenID: string;
  addressView: boolean;
}

const polygonOptionsEdit: google.maps.PolygonOptions = {
  fillOpacity: 1,
  editable: false,
  clickable: true,
  strokeWeight: 1,
  zIndex: 50
};

const getPolygonCenter = (polygon: google.maps.Polygon | any) => {
  if (polygon.polygon) {
    const bounds = new google.maps.LatLngBounds();
    const polygonPath = polygon.polygon.getPath();
    for (const path of polygonPath.getArray()) {
      bounds.extend(path);
    }
    const centerLng = bounds.getCenter().lng();
    const lat = bounds.getNorthEast().lat();
    return { lat, lng: centerLng };
  } else {
    const bounds = polygon.polygon ? polygon.polygon : polygon.bounds;
    const centerLng = bounds.lng;
    const lat = bounds.lat;
    return { lat, lng: centerLng };
  }
};

const KitchenPolygonManager = ({ kitchenID, polygonState, closePopUp, country }: KitchenPolygonManagerProps) => {
  const dispatch = useContext(PolygonContext);
  const { mutateAsync: updateMutation } = useMutation(updateKitchen);
  const { data: kitchenList, refetch } = useQuery<string, Error, { data: Kitchen[] }>('kitchens', getList, {
    suspense: true
  });

  const [countryKitchen, setCountryKitchen] = useState<boolean>();
  const kitchen = kitchenList?.data.find((r) => r.country === country && r.id === kitchenID);
  const roles = useUserRoles();
  const { selectedPolygon } = polygonState;
  const dmRef = useRef(null);

  let row: any;

  useEffect(() => {
    for (row of polygonState.polygons) {
      row.polygon.setEditable(true);
      const options: google.maps.PolygonOptions = {
        ...polygonOptionsEdit,
        fillColor: row.color || 'rgba(0, 0, 0, 0.5)',
        fillOpacity: 10,
        strokeColor: row.supplyCapped ? 'red' : (row.color || 'rgba(0, 0, 0, 0.5)').replace('0.5', '1')
      };
      row.polygon.setOptions(options);
    }
  }, [polygonState.polygons, country]);

  useEffect(() => {
    if (kitchenList?.data.find((r) => r.country === country && r.id === kitchenID)) {
      setCountryKitchen(true);
    } else {
      toast("Kitchen doesn't exist for this country", { type: 'error', autoClose: 2000 });
      setCountryKitchen(false);
    }
  }, [country]);

  useEffect(() => {
    if (selectedPolygon != null) {
      if (selectedPolygon.polygon.getEditable()) {
        selectedPolygon.polygon.setOptions({ editable: false });
        closePopUp();
      } else {
        for (const p of polygonState.polygons) {
          if (p.polygon?.getEditable()) {
            p.polygon?.setOptions({ editable: false });
          }
        }
        selectedPolygon.polygon?.setOptions({ editable: true });
      }
    }
  }, [selectedPolygon]);

  const onKitchenDelete = (row: KitchenPolygonRowState) => {
    dispatch!({
      type: 'delete',
      payload: row
    });
  };

  const onSaveKitchenArea = async (row: KitchenPolygonRowState) => {
    dispatch!({
      type: 'update',
      payload: row
    });
    closePopUp();
  };

  const saveMap = async () => {
    const x = polygonState.polygons.map((r: any) => [
      {
        color: r.color,
        bounds: r.polygon
          .getPath()
          .getArray()
          .map((row: any) => row.toJSON()) as LatLng[],
        supplyCapped: r.supplyCapped
      }
    ]);

    await updateMutation({
      supplyCapZones: flatten(x).filter((p) => p.bounds.length > 3),
      country,
      id: kitchen!.id
    });
    refetch();

    dmRef.current && (dmRef.current as any).setDrawingMode(null);
  };

  const handlePolygonClick = (polygon: google.maps.Polygon) => {
    dispatch!({
      type: 'select',
      payload: { polygon }
    });
  };

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    if (polygon.getPath().getArray().length < 4) {
      toast(`Polygon needs to have at least 4 points, please edit this one or it will not be included on save`, {
        type: 'error',
        autoClose: 5000
      });
    }
    dispatch!({
      type: 'select',
      payload: null
    });
    const newPoly = new google.maps.Polygon({
      paths: polygon.getPaths(),
      ...polygonOptionsEdit
    });
    newPoly.setMap(polygon.getMap());
    newPoly.addListener('click', () => handlePolygonClick(newPoly));
    dispatch!({
      type: 'add',
      payload: {
        polygon: newPoly,
        color: '',
        supplyCapped: false,
        bounds: polygon
          .getPath()
          .getArray()
          .map((row) => row.toJSON()) as LatLng[]
      }
    });
    handlePolygonClick(newPoly);
    polygon.setMap(null);
  };

  const onLoaded = (dm: google.maps.drawing.DrawingManager) => {
    dm.setDrawingMode(null);
    dmRef.current = dm as any;

    dispatch!({
      type: 'set',
      payload: (kitchen?.supplyCapZones || []).map((zone) => {
        const polygon = new google.maps.Polygon({
          paths: zone.bounds,
          ...polygonOptionsEdit
        });
        polygon.setMap(dm.getMap());
        polygon.addListener('click', () =>
          dispatch!({
            type: 'select',
            payload: { polygon: polygon, bounds: zone.bounds, color: zone.color, supplyCapped: zone.supplyCapped }
          })
        );

        return { polygon: polygon, bounds: zone.bounds, color: zone.color, supplyCapped: zone.supplyCapped };
      })
    });
  };
  const onUnloaded = (dm: google.maps.drawing.DrawingManager) => {
    dm.setDrawingMode(null);
    dispatch!({
      type: 'reset'
    });
  };

  return (
    <>
      {roles.includes(Permission.UPDATE_SUPPLY_CAP_ZONE) && (
        <div className="absolute top-10 right-2 bg-white p-2 flex flex-row">
          <span className="has-tooltip-left mx-2" data-tooltip="Save">
            <i className="far fa-save" onClick={() => saveMap()} />
          </span>
        </div>
      )}
      <DrawingManager
        key={`${country}-${kitchen?.id}`}
        onLoad={onLoaded}
        onUnmount={onUnloaded}
        onPolygonComplete={onPolygonComplete}
        options={{
          drawingControl: roles.includes(Permission.CREATE_SUPPLY_CAP_ZONE) && countryKitchen,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON]
          },
          polygonOptions: polygonOptionsEdit
        }}
      />
      {selectedPolygon && (
        <InfoWindow position={getPolygonCenter(selectedPolygon)} onCloseClick={closePopUp}>
          <CityAreaForm polygonRow={selectedPolygon} onChange={onSaveKitchenArea} onDelete={onKitchenDelete} />
        </InfoWindow>
      )}
    </>
  );
};

export default KitchenPolygonManager;
