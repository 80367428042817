import { Brand, Country, Kitchen } from '@calo/types';
import { Box, Card, Stack } from '@mui/material';
import { postRequest } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { FoodPicker } from 'components';
import { Food } from 'lib/interfaces';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

interface FoodPickerCardProps {
  country: Country;
  brand: Brand;
  kitchen: Kitchen;
  showPopulateDate?: boolean;
  populateSearchDate: string;
  handleFoodIds: () => string[] | undefined;
  handleFoodChanges: (food: Food[], tags: any) => void;
  setPopulateSearchDate: React.Dispatch<React.SetStateAction<string>>;
}

const FoodPickerCard = ({
  country,
  brand,
  kitchen,
  showPopulateDate = false,
  populateSearchDate,
  setPopulateSearchDate,
  handleFoodIds,
  handleFoodChanges
}: FoodPickerCardProps) => {
  const [filterName, setFilterName] = useState<string>();

  useEffect(() => {
    setFilterName('');
  }, [country, brand, kitchen]);

  const { data: foodList, isLoading: foodLoading } = useQuery<any, Error, { data: Food[] }>(
    [
      'food/list',
      {
        filters: { name: filterName ? filterName : undefined, country, brand, kitchen, isDeleted: false },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit: 20,
        calculatePurchasingCost: true
      }
    ],
    postRequest,
    {
      suspense: false,
      enabled: !!filterName
    }
  );

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        overflow: 'visible',
        width: 'full',
        border: 'none',
        borderRadius: '8px',
        paddingBottom: '4px',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column'
        }
      }}
    >
      <Stack>
        {country && brand && (
          <Box
            sx={{
              m: 2
            }}
          >
            <label className="label mb-4">Meal</label>
            <FoodPicker
              createMenu={true}
              disabled={false}
              foodLoading={foodLoading}
              value={handleFoodIds() || []}
              setFilterName={setFilterName}
              showPopulateDate={showPopulateDate}
              populateSearchDate={populateSearchDate}
              setPopulateSearchDate={(v) => setPopulateSearchDate(v)}
              foodList={(foodList?.data || []).filter((f) => !f.deletedAt)}
              onChange={(food, tags) => handleFoodChanges(food as Food[], tags)}
            />
          </Box>
        )}
      </Stack>
    </Card>
  );
};

export default FoodPickerCard;
