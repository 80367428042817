import { useEffect, useRef, useState } from 'react';

import { format as formatDate } from 'date-fns';

import { Permission } from '@calo/dashboard-types';
import { DeliveryAddress, Subscription } from '@calo/types';
import CheckIcon from '@mui/icons-material/Check';
import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { updateSubscriptionCustomDeliveryTime } from 'actions';
import mutation from 'actions/mutation';
import { caloTheme } from 'assets/images/theme/calo';
import { CustomTimePicker, Icon, ModalRef } from 'components';
import Popup from 'components/Popup';
import { getCustomDeliveryRange } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';

interface CustomDeliveryTimeProps {
  subscription: Subscription;
  refetchAllDeliveries: any;
}
const CustomDeliveryTime = ({ subscription, refetchAllDeliveries }: CustomDeliveryTimeProps) => {
  const roles = useUserRoles();
  const customDeliveryTimeRef = useRef<ModalRef>();
  const [selectedAddress, setSelectedAddress] = useState<DeliveryAddress>();
  const [selectedAddressCustomDeliveryTime, setSelectedAddressCustomDeliveryTime] = useState<string>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    const defaultAddress = subscription.deliveryAddresses.find((address) => address.default);
    if (defaultAddress) {
      setSelectedAddress(defaultAddress);
    }
  }, [subscription.deliveryAddresses]);

  useEffect(() => {
    const address = subscription.deliveryAddresses.find((add) => add.default) as DeliveryAddress & { customDeliveryTime: string };
    if (address && address.customDeliveryTime) {
      const list = address.customDeliveryTime.split(':');
      const time = new Date();
      time.setHours(parseInt(list[0]));
      time.setMinutes(parseInt(list[1]));
      const formattedTime = formatDate(time, 'h:mm a');
      setSelectedAddressCustomDeliveryTime(formattedTime);
    }
  }, [selectedAddress, subscription]);

  const handleUpdateAddress = async (id: string, customTime: string, action: string) => {
    await updateSubscriptionCustomDeliveryTime(subscription.id, customTime, id);
    const index = subscription.deliveryAddresses.findIndex((address) => address.id === id);
    if (index >= 0) {
      const removedAddress = [
        ...(subscription.deliveryAddresses as any).filter((address: any) => address.id !== id),
        {
          ...subscription.deliveryAddresses[index],
          customDeliveryTime: customTime
        }
      ];
      mutation(['subscriptions', subscription.id], {
        ...subscription,
        deliveryAddresses: removedAddress
      });
    }
    refetchAllDeliveries();
    if (action === 'delete') {
      setSelectedAddressCustomDeliveryTime(undefined);
    }
    customDeliveryTimeRef.current?.close();
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 'full',
          justifyContent: 'space-between',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
            width: '100%',
            mr: 0,
            mb: '14px',
            flexDirection: 'column'
          }
        }}
      >
        <Stack
          sx={{
            margin: 2,
            padding: 2,
            backgroundColor: caloTheme.palette.neutral50,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'row',
            width: 'full',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '19px',
              lineHeight: '23px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600
            }}
          >
            Custom Delivery Time
          </Typography>
          {isEditing ? (
            <CheckIcon style={{ cursor: 'pointer', padding: 1 }} onClick={() => setIsEditing(!isEditing)} />
          ) : (
            <Icon
              onClick={() => setIsEditing(!isEditing)}
              name="edit2"
              size={6}
              style={{ cursor: 'pointer', width: '26px', height: '26px' }}
            />
          )}
        </Stack>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            paddingY: 1,
            justifyContent: 'space-between',
            flex: 1,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
              width: 'full',
              mb: '14px',
              flexDirection: 'row'
            }
          }}
        >
          {selectedAddressCustomDeliveryTime ? (
            <Box
              display={'flex'}
              flexDirection={'row'}
              width={'100%'}
              sx={{
                flex: 1,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
                  width: '100%',
                  mb: '14px',
                  flexDirection: 'row'
                }
              }}
            >
              <Stack
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
                justifyContent={'start'}
                sx={{
                  textAlign: 'center',
                  mb: 2,
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
                    width: '100%',
                    mb: '14px',
                    flexDirection: 'row'
                  }
                }}
              >
                {selectedAddressCustomDeliveryTime && (
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '18px',
                      lineHeight: '19px'
                    }}
                  >
                    {`${getCustomDeliveryRange(selectedAddressCustomDeliveryTime)[0]} - ${getCustomDeliveryRange(selectedAddressCustomDeliveryTime)[1]}`}
                  </Typography>
                )}
              </Stack>
              {isEditing && (
                <Stack
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'end'}
                  width={'100%'}
                  sx={{
                    textAlign: 'end',
                    mt: '-10px',
                    mr: 2,
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
                      width: '100%',
                      mb: '14px',
                      flexDirection: 'row'
                    }
                  }}
                >
                  <Button
                    variant="text"
                    onClick={() => handleUpdateAddress(selectedAddress?.id ?? '', '', 'delete')}
                    sx={{
                      color: caloTheme.palette.primary500,
                      mr: 1,
                      mt: '2px',
                      fontWeight: 600,
                      fontSize: '19px',
                      lineHeight: '23px',
                      fontFamily: 'Roboto'
                    }}
                  >
                    <Icon name={'deleteTrash'} size={6} />
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      border: 2,
                      height: '39px',
                      borderRadius: '8px',
                      textTransform: 'none',
                      borderColor: caloTheme.palette.primary500,
                      color: caloTheme.palette.primary500,
                      fontWeight: 600,
                      fontSize: '19px',
                      fontFamily: 'Roboto',
                      '&:hover': {
                        borderColor: caloTheme.palette.primary600,
                        color: caloTheme.palette.primary600,
                        border: 2
                      }
                    }}
                    startIcon={<Icon name={'editPen3'} size={5} />}
                    onClick={() => customDeliveryTimeRef.current?.open()}
                  >
                    Edit
                  </Button>
                </Stack>
              )}
            </Box>
          ) : (
            <Button
              variant="outlined"
              aria-label="Custom Delivery Time"
              sx={{
                width: '100%',
                height: '45px',
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                boxShadow: 'none',
                borderRadius: '8px',
                mb: 1,
                mx: 2,
                borderColor: caloTheme.palette.primary500,
                color: caloTheme.palette.primary500,
                '&:hover': {
                  borderColor: caloTheme.palette.primary600,
                  color: caloTheme.palette.primary600,
                  boxShadow: 'none'
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  justifyItems: 'center',
                  margin: 'auto',
                  marginTop: 4,
                  width: 'auto'
                }
              }}
              onClick={() => customDeliveryTimeRef.current?.open()}
              disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
            >
              Add Custom Delivery Time
            </Button>
          )}
        </Stack>
      </Box>
      <Popup fullWidth title={'Enter Time'} ref={customDeliveryTimeRef} onClose={() => customDeliveryTimeRef.current?.close()}>
        <CustomTimePicker
          defaultAddress={selectedAddress}
          addresses={subscription.deliveryAddresses}
          customTime={selectedAddress?.customDeliveryTime}
          closePopup={() => customDeliveryTimeRef.current?.close()}
          onSubmit={(id, customTime) => handleUpdateAddress(id, customTime, 'update')}
        />
      </Popup>
    </>
  );
};
export default CustomDeliveryTime;
