import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Route, Switch } from 'react-router-dom';
import AddressChangePage from './AddressChangePage/AddressChangePage';

const AddressChange = () => {
  const roles = useUserRoles();

  return (
    <Switch>
      {roles.includes(Permission.LIST_DELIVERY_TICKET) && (
        <Route key="delivery-change" exact path={Routes.logisticChange} component={AddressChangePage} />
      )}
    </Switch>
  );
};

export default AddressChange;
