import { useCallback, useState } from 'react';

import { getListWithParams } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { format } from 'date-fns/fp';
import ExcelJS, { Column, Style } from 'exceljs';
import { saveAs } from 'file-saver';
import { resolveCountryFromKitchen } from 'lib';
import { StyledStatsTableCell } from 'lib/componentStyles';
import history from 'lib/history';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { chunk } from 'lodash-es';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import { Box, Card, Stack, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';

import SharedStatsHeader from '../SharedStatsHeader';
import Settings from './Settings';

const border: Partial<Style> = {
  border: {
    top: { style: 'thin', color: { argb: 'D3D3D3' } },
    left: { style: 'thin', color: { argb: 'D3D3D3' } },
    bottom: { style: 'thin', color: { argb: 'D3D3D3' } },
    right: { style: 'thin', color: { argb: 'D3D3D3' } }
  }
};

const columns: Array<Partial<Column>> = [
  { header: 'Name', width: 30, key: 'name', style: border },
  { header: 'Quantity', width: 10, key: 'quantity', style: border },
  { header: 'Cost', width: 10, key: 'cost', style: border },
  { width: 4 },
  { header: 'Name', width: 30, key: 'name2', style: border },
  { header: 'Quantity', width: 10, key: 'quantity2', style: border },
  { header: 'Cost', width: 10, key: 'cost2', style: border }
];

const PackagingStats = () => {
  const roles = useUserRoles();
  const userKitchen = useUserKitchens();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState<any>({
    day: {
      gte: format('yyyy-MM-dd')(Date.now()),
      lte: format('yyyy-MM-dd')(Date.now())
    },
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data: statsData, isLoading } = useQuery<any, Error, { data: any[] }>(
    ['stats/food-package-elements', filters],
    getListWithParams,
    {
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );
  const { data: statsCutlery } = useQuery<any, Error, { data: any }>(
    ['stats/cutleries_and_cooler_bags', filters],
    getListWithParams,
    {
      enabled: !!roles.includes(Permission.VIEW_CUTLERY_AND_COOLER_BAG_STATS)
    }
  );
  const statsNumbers = statsCutlery?.data as { nbCutlery: number; nbCoolerBag: number };

  const onExport = useCallback(async () => {
    if (!statsData) {
      return;
    }
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('packaging-stats', {
      pageSetup: { fitToPage: true, orientation: 'portrait' }
    });

    worksheet.columns = columns;

    for (const row of chunk(statsData.data, 2)) {
      worksheet.addRow(
        {
          name: row[0].name,
          quantity: row[0].count,
          cost: Math.round(row[0].count * row[0].cost * 100) / 100,
          name2: row[1] ? row[1].name : '',
          quantity2: row[1] ? row[1].count : '',
          cost2: row[1] ? Math.round(row[1].count * row[1].cost * 100) / 100 : ''
        },
        ''
      );
    }
    if (statsNumbers?.nbCutlery) {
      worksheet.addRow({
        name: 'Cutlery',
        quantity: statsNumbers.nbCutlery
      });
    }
    if (statsNumbers?.nbCoolerBag) {
      worksheet.addRow({
        name: 'Cooler Bags',
        quantity: statsNumbers.nbCoolerBag
      });
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, 'packaging-stats' + fileExtension);
  }, [statsData]);

  return (
    <>
      <SharedStatsHeader title={'Packaging Stats'} isLoading={isLoading} onExport={onExport} isDisabled={false} />
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: '14px',
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ paddingRight: 2 }}>
            <Typography
              variant="h6"
              sx={{
                py: 2,
                textAlign: 'start',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px',
                color: caloTheme.palette.neutral900,
                fontSize: '19px'
              }}
            >
              Packaging
            </Typography>
          </Stack>

          {isLoading ? (
            <Stack sx={{ width: '100%', justifyContent: 'center' }}>
              <CaloLoader />
            </Stack>
          ) : (
            <>
              <Table
                sx={{
                  marginY: '4px',
                  minHeight: '120px',
                  overflow: 'auto',
                  width: '100%',
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    flexDirection: 'column'
                  }
                }}
              >
                <TableHead
                  style={{ borderRadius: '8px' }}
                  sx={{
                    backgroundColor: caloTheme.palette.neutral50,
                    color: 'black',
                    flexWrap: 0,
                    justifyContent: 'space-between',
                    width: '100%',
                    borderRadius: '8px'
                  }}
                >
                  <TableRow
                    sx={{
                      backgroundColor: caloTheme.palette.neutral50,
                      color: 'black',
                      width: '100%',
                      flexWrap: 0,
                      justifyContent: 'space-between'
                    }}
                  >
                    <StyledStatsTableCell style={{ width: '34%', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
                      Name
                    </StyledStatsTableCell>
                    <StyledStatsTableCell style={{ width: '33%' }}>Quantity</StyledStatsTableCell>
                    <StyledStatsTableCell style={{ width: '33%', borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}>
                      Total Cost
                    </StyledStatsTableCell>
                  </TableRow>
                </TableHead>
                {statsData && statsData.data.length === 0 && !statsNumbers?.nbCutlery && !!statsNumbers?.nbCoolerBag ? (
                  <StyledStatsTableCell style={{ border: 0 }} colSpan={6}>
                    <Typography
                      sx={{
                        mt: 3,
                        textAlign: 'center',
                        width: 'full',
                        fontSize: '24px',
                        color: caloTheme.palette.neutral400
                      }}
                    >
                      No Stats
                    </Typography>
                  </StyledStatsTableCell>
                ) : (
                  <TableBody>
                    <TableRow>
                      <StyledStatsTableCell style={{ fontWeight: 600 }}>Cutlery</StyledStatsTableCell>
                      <StyledStatsTableCell>{statsNumbers?.nbCutlery || 0}</StyledStatsTableCell>
                      <StyledStatsTableCell>--</StyledStatsTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledStatsTableCell style={{ fontWeight: 600 }}>Cooler Bag</StyledStatsTableCell>
                      <StyledStatsTableCell>{statsNumbers?.nbCoolerBag || 0}</StyledStatsTableCell>
                      <StyledStatsTableCell>--</StyledStatsTableCell>
                    </TableRow>
                    {statsData &&
                      statsData.data.map((row) => (
                        <TableRow key={row.id}>
                          <StyledStatsTableCell>{row.name}</StyledStatsTableCell>
                          <StyledStatsTableCell>{row.count}</StyledStatsTableCell>
                          <StyledStatsTableCell>{Math.round(row.count * row.cost * 100) / 100}</StyledStatsTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                )}
              </Table>
            </>
          )}
        </Box>
      </Card>
      <Settings onFilter={setFilters} filters={filters} />
    </>
  );
};

export default PackagingStats;
