import { CreateFoodReq, UpdateFoodReq } from '@calo/dashboard-types';
import { FoodType, IngredientCategory } from '@calo/types';
import { caloTheme } from 'assets/images/theme/calo';
import { isAfter, isBefore, isWithinInterval, parseISO } from 'date-fns';
import { FoodTagKeys } from 'lib/enums';
import { BaseOmit } from 'lib/interfaces';

const categoryToTagMap = {
  [IngredientCategory.dairy]: FoodTagKeys.dairyFree,
  [IngredientCategory.gluten]: FoodTagKeys.glutenFree
};

export const getExcludedFoodTags = (
  values: { ingredients?: { category: string[] }[] },
  categoriesToCheck: string[]
): FoodTagKeys[] => {
  const excludedTags: FoodTagKeys[] = [];

  if (!values.ingredients || values.ingredients.length === 0) {
    return excludedTags;
  }

  for (const category of categoriesToCheck) {
    const isExcluded = !values.ingredients.flatMap((ing) => ing.category).includes(category);

    if (isExcluded) {
      const correspondingTag = categoryToTagMap[category];
      if (correspondingTag) {
        excludedTags.push(correspondingTag);
      }
    }
  }

  return excludedTags;
};

export const getLabelStyles = (startDay: string, endDay: string) => {
  const today = new Date();
  const startDate = parseISO(startDay);
  const endDate = parseISO(endDay);

  let backgroundColor = caloTheme.palette.neutral100;
  let color = caloTheme.palette.neutral100;

  if (isBefore(today, startDate)) {
    backgroundColor = caloTheme.palette.neutral50;
    color = caloTheme.palette.neutral700;
  } else if (isAfter(today, endDate)) {
    backgroundColor = caloTheme.palette.secondaryRed50;
    color = caloTheme.palette.red500;
  } else if (isWithinInterval(today, { start: startDate, end: endDate })) {
    backgroundColor = caloTheme.palette.primary100;
    color = caloTheme.palette.primary700;
  }

  return { backgroundColor, color };
};

export const isSnack = (food: Omit<CreateFoodReq, 'id'> | Omit<UpdateFoodReq, BaseOmit>) => {
  return food.type?.includes(FoodType.snack);
};
