import { Brand, Country, Kitchen } from '@calo/types';
import { selectedMenuFilters } from 'components/AddMealsByFilterPopup/AddMealsInterfaces';
import client from 'lib/client';
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

interface useFoodListByFiltersProps {
  selectedMenuFilters: selectedMenuFilters;
  country: Country;
  kitchen: Kitchen;
  brand: Brand;
  filtersOn: boolean;
}
const DEFAULT_LIMIT = 75;
const useFoodListByFilters = ({ selectedMenuFilters, country, kitchen, brand, filtersOn }: useFoodListByFiltersProps) => {
  const {
    data: food,
    fetchNextPage,
    hasNextPage,
    isLoading,
    remove,
    isFetchingNextPage
  } = useInfiniteQuery<{ data: any[]; meta: any }>(
    ['food/list', selectedMenuFilters],
    async ({ pageParam = 0, queryKey }) => {
      const { data } = await client.post(queryKey[0] as string, {
        page: pageParam,
        limit: DEFAULT_LIMIT,
        filters: {
          isDeleted: false,
          country: country,
          brand: brand!,
          kitchen: kitchen,
          name: selectedMenuFilters.mealName || undefined,
          lastUsedOnMenu: selectedMenuFilters.lastUsed.length > 0 ? selectedMenuFilters.lastUsed : undefined,
          foodTags: {
            protein: selectedMenuFilters.protein || undefined,
            taste: selectedMenuFilters.taste || undefined,
            base: selectedMenuFilters.base || undefined,
            category: selectedMenuFilters.category || undefined
          },
          dietType: selectedMenuFilters.plan || undefined,
          foodType: selectedMenuFilters.type || undefined,
          assemblyRequired: selectedMenuFilters.sandwich
        },
        kitchen: kitchen,
        country: country,
        brand: brand,
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        }
      });
      return data;
    },
    {
      enabled: filtersOn,
      keepPreviousData: false,
      suspense: false,
      getNextPageParam: (lastPage, allPages) => {
        if (filtersOn) {
          const nextPage = allPages.length;
          const maxPages = Math.ceil(lastPage.meta.total / lastPage.meta.limit);
          return nextPage <= maxPages ? nextPage : undefined;
        }
      }
    }
  );

  const foodData = useMemo(
    () =>
      (food?.pages || []).reduce<any[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [food, food?.pages[0].data]
  );

  return {
    foodFiltersLoading: isLoading,
    foodListFiltersData: foodData,
    hasNextPage: hasNextPage,
    fetchNextPage: fetchNextPage,
    remove: remove,
    isLoadingFetchNextPage: isFetchingNextPage
  };
};

export default useFoodListByFilters;
