import { FoodPrototypeAction, PrototypeAction } from '@calo/dashboard-types';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns';

interface CommentItemProps {
  isLastItem: boolean;
  commentInfo: Extract<FoodPrototypeAction, { type: PrototypeAction.comment }>;
}

const CommentItem = ({ isLastItem, commentInfo }: CommentItemProps) => {
  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'flex-start', gap: '20px' }}>
        <Stack sx={{ backgroundColor: caloTheme.palette.primary100, borderRadius: '28px', padding: '14px' }}>
          <AccessTimeIcon color="primary" fontSize="medium" />
        </Stack>
        <Stack sx={{ gap: '6px' }}>
          <Typography sx={{ fontWeight: 600 }}>
            Added by {commentInfo.actor.name} on {format(new Date(commentInfo.createdAt), 'dd/MM/yyyy hh:mm a')}
          </Typography>
          <Typography>{commentInfo.message}</Typography>
        </Stack>
      </Stack>
      {!isLastItem && <Stack sx={{ width: '100%', height: '1px', backgroundColor: caloTheme.palette.neutral100 }} />}
    </>
  );
};

export default CommentItem;
