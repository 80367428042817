import { DeliveryFood } from '@calo/types';
import { Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { useEffect, useState } from 'react';

interface MealTotalInformationSectionProps {
  meals: DeliveryFood[];
  swappedComponentList?: {
    oldId: string;
    newId: string;
  }[];
  removedComponentIds?: string[];
  componentsData?: any[];
}

export interface MacroMicroData {
  cal: number;
  fat: number;
  totalFat: number;
  transFats: number;
  saturatedFats: number;
  protein: number;
  sodium: number;
  cholesterol: number;
  carbs: number;
  fiber: number;
  totalSugar: number;
  addedSugar: number;
}
const MealTotalInformationSection = ({ meals }: MealTotalInformationSectionProps) => {
  const [mealMacroMicrosData, setMealMacroMicroData] = useState<MacroMicroData>({
    cal: 0,
    totalFat: 0,
    transFats: 0,
    saturatedFats: 0,
    protein: 0,
    sodium: 0,
    cholesterol: 0,
    carbs: 0,
    fiber: 0,
    totalSugar: 0,
    addedSugar: 0,
    fat: 0
  });

  const calculateMealMacrosMicros = () => {
    const mealDataSum = meals.reduce(
      (acc: MacroMicroData, food: DeliveryFood) => {
        acc.cal += food.macros?.cal || 0;
        acc.fiber += food.macros?.fiber || 0;
        acc.carbs += food.macros?.carbs || 0;
        acc.protein += food.macros?.protein || 0;
        acc.fat += food.macros?.fat || 0;
        acc.saturatedFats += food.micronutrients?.saturatedFats || 0;
        acc.cholesterol += food.micronutrients?.cholesterol || 0;
        acc.totalSugar += food.micronutrients?.totalSugar || 0;
        acc.addedSugar += food.micronutrients?.addedSugar || 0;
        acc.transFats += food.micronutrients?.transFats || 0;
        acc.totalFat += food.macros?.fat || 0;
        acc.sodium += food.micronutrients?.sodium || 0;
        return acc;
      },
      {
        cal: 0,
        totalFat: 0,
        transFats: 0,
        saturatedFats: 0,
        protein: 0,
        sodium: 0,
        cholesterol: 0,
        carbs: 0,
        fiber: 0,
        totalSugar: 0,
        addedSugar: 0,
        fat: 0
      }
    );
    setMealMacroMicroData(mealDataSum);
  };

  useEffect(() => {
    calculateMealMacrosMicros();
  }, [meals]);

  const handleInfoStack = (label: string, value: number, unit: string) => (
    <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <Typography sx={{ fontSize: '12px', fontStyle: 'normal', fontWeight: 400, lineHeight: '12px' }}>{label}</Typography>
      <Typography
        sx={{
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '12px'
        }}
      >{`${value} ${unit}`}</Typography>
    </Stack>
  );

  return (
    <>
      <Stack sx={{ flexDirection: 'row', margin: 1 }}>
        <Typography sx={{ fontSize: '16px', fontStyle: 'normal', fontWeight: 600, lineHeight: '14px' }}>
          {mealMacroMicrosData.cal || 0}
        </Typography>
        <Typography sx={{ fontSize: '10px', fontStyle: 'normal', fontWeight: 400, lineHeight: '12px', marginLeft: '4px' }}>
          Kcal
        </Typography>
      </Stack>

      <Stack sx={{ justifyContent: 'space-between', flexDirection: 'row', marginRight: 1 }}>
        <Stack
          sx={{
            borderLeftWidth: 2,
            borderLeftColor: caloTheme.palette.neutral100,
            borderRightWidth: 2,
            borderRightColor: caloTheme.palette.neutral100,
            width: '100%',
            paddingX: 1
          }}
        >
          {handleInfoStack('Total Fats', mealMacroMicrosData.totalFat, 'g')}
          {handleInfoStack('Trans Fats', mealMacroMicrosData.transFats, 'g')}
          {handleInfoStack('Sat Fats', mealMacroMicrosData.saturatedFats, 'g')}
        </Stack>

        <Stack sx={{ borderRightWidth: 2, borderRightColor: caloTheme.palette.neutral100, width: '100%', paddingX: 1 }}>
          {handleInfoStack('Pro', mealMacroMicrosData.protein, 'g')}
          {handleInfoStack('Sodium', mealMacroMicrosData.sodium * 1000, 'mg')}
          {handleInfoStack('Cholesterol', mealMacroMicrosData.cholesterol * 1000, 'mg')}
        </Stack>

        <Stack sx={{ borderRightWidth: 2, borderRightColor: caloTheme.palette.neutral100, width: '100%', paddingX: 1 }}>
          {handleInfoStack('Carbs', mealMacroMicrosData.carbs, 'g')}
          {handleInfoStack('Fiber', mealMacroMicrosData.fiber, 'g')}
          {handleInfoStack('Total Sugar', mealMacroMicrosData.totalSugar, 'g')}
          {handleInfoStack('Added Sugar', mealMacroMicrosData.addedSugar, 'g')}
        </Stack>
      </Stack>
    </>
  );
};

export default MealTotalInformationSection;
