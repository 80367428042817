import { CouponRuleType } from '@calo/dashboard-types';
import cx from 'classnames';
import { Button, FileUploader } from 'components';
import { flatten } from 'lodash';

interface ExclusiveListProps {
  exList: any;
  addExclusiveMemberRef: any;
  setExList: (value: any) => void;
  handleRemoveUser: (value: any) => void;
}

const ExclusiveList = ({ exList, addExclusiveMemberRef, setExList, handleRemoveUser }: ExclusiveListProps) => (
  <>
    <div className="flex mb-4">
      <label className="b-checkbox checkbox mr-4">
        <input
          type="checkbox"
          value="lorem"
          checked={exList.type === CouponRuleType.PHONE_EXISTS}
          onChange={() =>
            setExList(
              exList.type === CouponRuleType.PHONE_EXISTS
                ? { type: undefined, value: [] }
                : { type: CouponRuleType.PHONE_EXISTS, value: [] }
            )
          }
        />
        <span className="check is-primary"> Phone list </span>
      </label>
      <label className="b-checkbox checkbox">
        <input
          type="checkbox"
          value="lorem"
          checked={exList.type === CouponRuleType.EMAIL_EXISTS}
          onChange={() =>
            setExList(
              exList.type === CouponRuleType.EMAIL_EXISTS
                ? { type: undefined, value: [] }
                : { type: CouponRuleType.EMAIL_EXISTS, value: [] }
            )
          }
        />
        <span className="check is-primary"> Email list </span>
      </label>
    </div>
    <label> Exclusive Coupon for:</label>
    <div className="overflow-y-auto h-64 w-auto mb-4 border-2">
      {flatten(exList.value).map((f: any, index) => (
        <div className={cx('right-0 my-3 mx-2 border border-gray-200 bg-gray-200 shadow-lg rounded-md')} key={index}>
          <span>
            <i className="fas fa-times ml-2 cursor-pointer" onClick={() => handleRemoveUser(f)} />
          </span>
          <span className="text-black mx-2" key={index}>
            {f}
          </span>
        </div>
      ))}
    </div>

    <div className="flex mb-4">
      <Button
        className="mx-2"
        type="submit"
        primary
        content="Add"
        disabled={!(exList.type === CouponRuleType.PHONE_EXISTS || exList.type === CouponRuleType.EMAIL_EXISTS)}
        onClick={() => addExclusiveMemberRef.current?.open()}
      />

      <FileUploader setExList={setExList} exList={exList} isDisabled={!exList.type} />
    </div>
  </>
);
export default ExclusiveList;
