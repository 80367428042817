import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { IngredientStatsReq } from '@calo/dashboard-types';
import { Icon as Iconify } from '@iconify/react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';

import { caloTheme } from 'assets/images/theme/calo';
import { IngredientComponentStats, IngredientStats } from 'lib/interfaces';
import MainTable from './MainTable/MainTable';
import onExport from './onExport';
import useResize from './useResize';

interface ComponentsDrawerProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  ingredient?: IngredientStats;
  filters: IngredientStatsReq;
  showAllUnits: boolean;
}

export default function ComponentsDrawer({ isOpen, setIsOpen, ingredient, filters, showAllUnits }: ComponentsDrawerProps) {
  const [switchUnit, setSwitchUnit] = useState(showAllUnits);
  const { width, enableResize } = useResize({});
  const ingredientComponentStats: IngredientComponentStats[] = ingredient?.keyedComponents
    ? Object.values(ingredient?.keyedComponents)
    : [];

  const handleExport = useCallback(async () => {
    if (!ingredient || !ingredientComponentStats.length) {
      return;
    }
    await onExport({ ingredient, ingredientComponentStats, filters, switchUnit });
  }, [ingredient, ingredientComponentStats, filters, switchUnit]);

  useEffect(() => {
    setSwitchUnit(showAllUnits);
  }, [showAllUnits]);

  return (
    <React.Fragment>
      <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        variant="persistent"
        sx={{
          zIndex: 49,
          border: 0,
          '& .MuiDrawer-paper': {
            border: 0,
            boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.10)'
          }
        }}
        PaperProps={{ style: { width } }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
          sx={{ overflow: 'auto', paddingTop: '100px', mx: 2 }}
        >
          <Box
            id="dragger"
            onMouseDown={(e) => enableResize(e)}
            sx={{
              width: '5px',
              cursor: 'ew-resize',
              padding: '4px 0 0',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              zIndex: '100'
            }}
          />
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
            <Typography
              sx={{
                textTransform: 'capitalize',
                textAlign: 'left',
                fontSize: '20px',
                lineHeight: '24px',
                fontWeight: 600,
                color: caloTheme.palette.neutral900
              }}
            >
              {ingredient?.name.en}
            </Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <IconButton>
                <Iconify
                  icon="lucide:download"
                  width="16"
                  height="16"
                  color={caloTheme.palette.neutral900}
                  onClick={() => handleExport()}
                />
              </IconButton>
              <IconButton aria-label="close" onClick={() => setIsOpen(false)} sx={{ color: caloTheme.palette.neutral900 }}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
          {ingredient && <MainTable ingredient={ingredient} switchUnit={switchUnit} setSwitchUnit={setSwitchUnit} />}
        </Stack>
      </Drawer>
    </React.Fragment>
  );
}
