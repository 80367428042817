import { differenceInDays, format, parseISO } from 'date-fns';
import { addonsWithinThisWeek } from './addonsWithinThisWeek';

export const getUsedOnMenuButtonColor = (data) => {
  if (data.type === 'mainMenu') {
    const daysDifference = differenceInDays(parseISO(data.date), parseISO(format(new Date(), 'yyyy-MM-dd')));
    return daysDifference <= 1 && daysDifference >= 0 ? 'red' : 'yellow';
  } else {
    return addonsWithinThisWeek(data.week) ? 'red' : 'addons';
  }
};
