import { useFormik } from 'formik';

import { UpdateSubscriptionTrackersReq } from '@calo/dashboard-types';

type UpdateTrackersFormProps = {
  water: number;
  weight: number;
  steps: number;
};

export default (props: UpdateTrackersFormProps, onSubmit: (values: UpdateSubscriptionTrackersReq) => Promise<void>) =>
  useFormik<UpdateSubscriptionTrackersReq>({
    enableReinitialize: true,
    initialValues: {
      ...props
    },
    validate: (values: UpdateTrackersFormProps) => {
      const errors: Partial<Record<keyof UpdateTrackersFormProps, boolean>> = {};

      if ((values.water as unknown) === '' || values.water < 0) {
        errors.water = true;
      }

      if ((values.weight as unknown) === '' || values.weight < 0) {
        errors.weight = true;
      }

      if ((values.steps as unknown) === '' || values.steps < 0) {
        errors.steps = true;
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
