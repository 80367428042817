import { KitchenLogType, Permission, PrototypeAction } from '@calo/dashboard-types';
import { IngredientCategory } from '@calo/types';
import { Card, Chip, InputBaseComponentProps, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { getImageUploadLink } from 'actions';
import { ImageUploaderMUI } from 'components';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { format } from 'date-fns';
import { FormikErrors } from 'formik';
import { ImageType } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { BaseOmit, FoodComponent } from 'lib/interfaces';
import { startCase, uniq, uniqBy } from 'lodash-es';
import { styles } from './styles';

interface ComponentNameAndImageProps {
  foodComponent: FoodComponent;
  values: Omit<FoodComponent, BaseOmit>;
  errors: FormikErrors<Omit<FoodComponent, BaseOmit>>;
  isLoading: boolean;
  isDisabled?: boolean;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
}

const ComponentNameAndImageCard = ({ foodComponent, values, errors, handleChange, isDisabled }: ComponentNameAndImageProps) => {
  const userRoles = useUserRoles();

  const uniqIngredients = uniqBy(foodComponent?.ingredients, 'id') || [];
  const ingredientsWithAllergens = uniqIngredients.filter(
    (ing) => ing.category.length > 0 && !ing.category.includes(IngredientCategory.other)
  );
  const sortedIngredientsByCategory = ingredientsWithAllergens.sort((a, b) => a.name.en.localeCompare(b.name.en));
  const uniqAllergens = uniq(sortedIngredientsByCategory.flatMap((i) => i.category));

  const createInfo = foodComponent.prototypeActions?.find((action) => action.type === PrototypeAction.create);

  return (
    <Card variant="outlined" sx={styles.card}>
      <Stack sx={styles.createInfoStack}>
        <Stack sx={styles.createInfoInnerStack}>
          <Typography sx={styles.componentInfoText}>Component Information</Typography>
          {createInfo && (
            <Typography sx={styles.createdDateText}>
              {`Created on ${format(new Date(createInfo?.createdAt), 'dd/MM/yyyy')}`}
            </Typography>
          )}
        </Stack>
        {createInfo && <Typography sx={styles.createdByText}>Created by: {createInfo?.actor.name}</Typography>}
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack display="flex" flexDirection="column" justifyContent="center">
          <ImageUploaderMUI
            key={foodComponent.id}
            values={{ name: foodComponent.name.en, id: foodComponent.id, type: KitchenLogType.foodComponent }}
            uploadLink={() => getImageUploadLink(`food-component/${foodComponent.id}`)}
            image={`${process.env.REACT_APP_BUCKET_URL}/food-component/${foodComponent.id}/square@1x.jpg`}
            disabled={userRoles.includes(Permission.UPLOAD_IMAGE)}
            id={foodComponent.id}
            imageType={ImageType.COMPONENT}
          />
        </Stack>

        <Stack sx={styles.textFieldStack}>
          <InputThemeProvider>
            <Stack sx={styles.nameInputStack}>
              <TextField
                label="Name EN*"
                name="name.en"
                value={values.name.en}
                sx={styles.textField}
                disabled={isDisabled}
                onChange={handleChange}
                error={!!errors.name?.en}
              />
              <TextField
                label="Name AR*"
                name="name.ar"
                value={values.name.ar}
                sx={styles.textField}
                disabled={isDisabled}
                onChange={handleChange}
                error={!!errors.name?.ar}
                inputProps={styles.textFieldInputProps as InputBaseComponentProps}
              />
            </Stack>
            <TextField
              label="Internal Name*"
              name="internalName"
              value={values.internalName}
              sx={styles.internalName}
              disabled={isDisabled}
              onChange={handleChange}
              error={!!errors.internalName}
            />
          </InputThemeProvider>

          {uniqAllergens.length > 0 && <Typography>Allergens</Typography>}
          <Stack sx={styles.allergensStack}>
            <Stack direction="row" flexWrap="wrap" gap={2} sx={styles.ingredientChips}>
              {sortedIngredientsByCategory?.map((ing) => (
                <Tooltip title={ing.category.sort().join(', ')} placement="top" arrow>
                  <Chip key={ing.id} label={ing.internalName ?? ing.name.en} sx={styles.ingredientChip} />
                </Tooltip>
              ))}
            </Stack>

            {uniqAllergens.length > 0 && (
              <Typography>Warning: Includes {uniqAllergens.map(startCase).sort().join(', ')}.</Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ComponentNameAndImageCard;
