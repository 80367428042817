import { Subscription } from '@calo/types';
import {
  Box,
  Button,
  Card,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { useDocumentMedia } from 'hooks';
import { loyaltyClient } from 'lib/client';
import { PaginatedLoyaltyRewards, Reward } from 'lib/interfaces';
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import RewardRow from './RewardRow';
import RewardRowMobile from './RewardRowMobile';

interface RewardsProps {
  subscription: Subscription & {
    remainingDays: number;
    expectedLastDeliveryDay: string;
    ratings: any[];
    magicPrice?: boolean;
  };
}
const Rewards = ({ subscription }: RewardsProps) => {
  const { isMobile, isTablet } = useDocumentMedia();
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<PaginatedLoyaltyRewards>(
    `dashboard/rewards/${subscription.id}`,
    async ({ queryKey, pageParam }) => {
      const { data } = await loyaltyClient.get(queryKey.join('/'), {
        params: {
          limit: 5,
          ...(pageParam && {
            cursor: pageParam
          })
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => data.meta.cursor
    }
  );

  const list = useMemo(
    () =>
      (data?.pages || []).reduce<Reward[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [data]
  );

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      flexShrink: 0,
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px',
      variant: 'caption',
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    },
    [`&.${tableCellClasses.body}`]: {
      flexShrink: 0,
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      variant: 'caption',
      lineHeight: '14px',
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    }
  }));
  return (
    <>
      <Card variant="outlined" sx={{ width: '100%', border: 'none', borderRadius: '8px', marginTop: '14px' }}>
        <Box
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column'
            }
          }}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '19px',
              lineHeight: '23px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600
            }}
          >
            Rewards
          </Typography>
        </Box>
        <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
          {isTablet || isMobile ? (
            <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
              {isLoading ? (
                <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                  <CaloLoader />
                </Stack>
              ) : (
                <>
                  {list.map((row) => (
                    <RewardRowMobile key={row.id} row={row} />
                  ))}
                </>
              )}
            </Box>
          ) : (
            <>
              {isLoading ? (
                <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                  <CaloLoader />
                </Stack>
              ) : (
                <>
                  {list.length === 0 ? (
                    <Typography
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{
                        justifyContent: 'center',
                        color: caloTheme.palette.neutral400,
                        fontSize: '33px',
                        fontWeight: 400,
                        lineHeight: '24px'
                      }}
                    >
                      No Rewards Data
                    </Typography>
                  ) : (
                    <Table
                      sx={{
                        marginY: '4px',
                        minHeight: '120px',
                        tableLayout: 'fixed',
                        overflow: 'auto',
                        width: '100%',
                        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                          flexDirection: 'column'
                        }
                      }}
                    >
                      <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, width: '100%', borderRadius: '8px' }}>
                        <TableRow>
                          <StyledTableCell>Type</StyledTableCell>
                          <StyledTableCell>Value</StyledTableCell>
                          <StyledTableCell>Worth</StyledTableCell>
                          <StyledTableCell>Pending</StyledTableCell>
                          <StyledTableCell>Status</StyledTableCell>
                          <StyledTableCell>Date</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {list.map((row) => (
                          <>
                            <RewardRow row={row} key={row.id} />
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </>
              )}
            </>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              mb: 2,
              mt: '-4px',
              width: '100%',
              display: 'flex',
              justifyItems: 'center'
            }
          }}
        >
          {hasNextPage && (
            <Button
              variant="text"
              aria-label="Remove Suspension"
              sx={{
                display: 'flex',
                mb: 1,
                mx: 'auto',
                height: '45px',
                fontWeight: 600,
                lineHeight: '17px',
                fontSize: '14px',
                borderRadius: '8px',
                padding: '14px 20px 14px 20px',
                color: caloTheme.palette.primary500,
                '&:hover': {
                  color: caloTheme.palette.primary600
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  display: 'flex',
                  justifyItems: 'center',
                  m: 'auto',
                  mb: 2,
                  mt: '-4px',
                  width: 'auto'
                }
              }}
              onClick={() => fetchNextPage()}
            >
              Show More
            </Button>
          )}
        </Box>
      </Card>
    </>
  );
};

export default Rewards;
