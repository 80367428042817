import { Brand } from '@calo/types';
import { Box, Button, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import Icon from '../Icon';
import { ModalRef } from '../Modal';
import MenuInfoTypes from './MenuInfoTypes';
export interface MenuMealInfoProps {
  brand: Brand;
  selectedMeal: any;
  setSelectedMeal: React.Dispatch<any>;
  handleCalculateLastTime: (food: any) => JSX.Element | undefined;
  handleFoodSizes: (foodName: string) => JSX.Element[];
  infoMealRef: React.MutableRefObject<ModalRef | undefined>;
}
const MenuMealInfo = ({
  selectedMeal,
  handleFoodSizes,
  infoMealRef,
  setSelectedMeal,
  handleCalculateLastTime
}: MenuMealInfoProps) => (
  <Box display={'flex'} flexDirection={'column'} sx={{ minWidth: '832px' }}>
    <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
      <Typography
        variant="h6"
        sx={{
          fontFamily: caloTheme.typography.fontFamily,
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '19px',
          color: '#000000',
          textTransform: 'capitalize',
          mt: '2px'
        }}
      >{`${selectedMeal?.name.en}`}</Typography>
      <Typography display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'}>
        {handleFoodSizes(selectedMeal?.name.en)}
      </Typography>
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'}>
        <Typography display={'flex'} flexDirection={'row'} sx={{ mt: '1px', mr: 2 }}>
          <Icon name="star" size={5} />
          <Typography sx={{ ml: '6px' }}>
            {!!selectedMeal?.totalRating && !!selectedMeal?.numberOfRatings
              ? (selectedMeal?.totalRating / selectedMeal?.numberOfRatings).toFixed(2)
              : 0}
            ({selectedMeal?.numberOfRatings ? selectedMeal?.numberOfRatings : 0})
          </Typography>
        </Typography>
        {selectedMeal && selectedMeal?.lastUsedOnMenu && handleCalculateLastTime(selectedMeal)}
      </Stack>
    </Stack>

    <MenuInfoTypes selectedMeal={selectedMeal} />
    <Stack display={'flex'} flexDirection={'row'} m={2} justifyContent={'center'}>
      <Button
        variant="contained"
        data-test="menu-new-modal-confirm-button"
        sx={{
          width: '123px',
          height: '51px',
          lineHeight: '17px',
          fontWeight: 600,
          fontSize: '14px',
          borderRadius: '8px',
          backgroundColor: caloTheme.palette.primary500,
          borderColor: caloTheme.palette.primary500,
          color: 'white',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: caloTheme.palette.primary500,
            borderColor: caloTheme.palette.primary600,
            boxShadow: 'none'
          }
        }}
        onClick={() => {
          infoMealRef.current?.close();
          setSelectedMeal(undefined);
        }}
      >
        Done
      </Button>
    </Stack>
  </Box>
);
export default MenuMealInfo;
