import { Country, Dictionary } from '@calo/types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { InfoWindow, Marker } from '@react-google-maps/api';
import { getDriversPositions } from 'actions';
import { DriverPinData } from 'lib/interfaces';

let interval: NodeJS.Timer;
interface PositionsData {
  id: string;
  lat: number;
  lng: number;
  color?: string;
}

interface DriverTrackingProps {
  country: Country;
  driversData: Dictionary<DriverPinData>;
  map?: google.maps.Map;
}

const DriverTracking = ({ country, map, driversData }: DriverTrackingProps) => {
  const [selectedDriver, setSelectedDriver] = useState<PositionsData | null>(null);
  const [points, setPoints] = useState<PositionsData[]>([]);

  const getDriversLiveTracking = async () => {
    const { data }: { data: { data: PositionsData[] } } = await getDriversPositions(Object.keys(driversData), country);
    const updatedPoints: PositionsData[] = [];

    for (const row of data.data) {
      const index = points.findIndex((i) => i.id === row.id);
      if (index === -1) {
        updatedPoints.push({
          ...row,
          color: `#${Math.floor(Math.random() * 16777215).toString(16)}`
        });
      } else {
        updatedPoints.push({
          ...row,
          color: points[index].color
        });
      }
    }
    setPoints(updatedPoints);
  };
  useEffect(() => {
    if (!map || !country || Object.keys(driversData).length === 0) {
      setPoints([]);
      return;
    }
    if (!interval) {
      toast('Connected to live tracking service', { type: 'success', autoClose: 2000 });
    }
    getDriversLiveTracking();
    interval = setInterval(async () => {
      getDriversLiveTracking();
    }, 15000);

    return () => {
      interval && clearInterval(interval);
    };
  }, [map, driversData]);
  return (
    <>
      {points.map((point) => (
        <Marker
          key={point.id}
          position={{
            lat: point.lat,
            lng: point.lng
          }}
          onClick={() => setSelectedDriver(point)}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            scale: 7,
            fillColor: point.color,
            strokeColor: point.color,
            labelOrigin: new google.maps.Point(0, -3)
          }}
        />
      ))}
      {selectedDriver && (
        <InfoWindow
          position={{
            lat: selectedDriver.lat,
            lng: selectedDriver.lng
          }}
          onCloseClick={() => setSelectedDriver(null)}
        >
          <>
            <p>{driversData[selectedDriver.id]?.name}</p>
            <p>{driversData[selectedDriver.id]?.phoneNumber}</p>
          </>
        </InfoWindow>
      )}
    </>
  );
};

export default DriverTracking;
