import { Currency } from '@calo/types';
import { Delivery, FoodWithPosition, MenuFood } from 'lib/interfaces';
import FoodPicker from '../FoodPicker';

interface RegularMealTableProps {
  menuFood: MenuFood[];
  selected: FoodWithPosition;
  currency: Currency;
  handleReplace: (food: MenuFood) => Promise<void>;
  delivery: Delivery;
  allMenuFood: MenuFood[];
}

const RegularMealTable = ({ menuFood, selected, currency, handleReplace, delivery, allMenuFood }: RegularMealTableProps) => (
  <div className="table-container max-h-96 overflow-y-auto">
    <table className="is-fullwidth border-separate" style={{ borderSpacing: '0px 0.5em' }}>
      <thead className="sticky top-0 z-10 h-8">
        <tr className="bg-white">
          <th>Name</th>
          <th>Ingredients</th>
          <th className="w-32">Tags</th>
          <th className="w-32">Sizes</th>
          <th className="w-24"></th>
        </tr>
      </thead>
      <tbody>
        {menuFood.map((r) => (
          <FoodPicker
            key={r.id}
            foodList={r}
            onPick={handleReplace}
            food={selected!}
            currency={currency}
            delivery={delivery}
            allMenuFood={allMenuFood || []}
          />
        ))}
      </tbody>
    </table>
  </div>
);
export default RegularMealTable;
