import { parseISO } from 'date-fns';
import { differenceInDays, endOfWeek, format, startOfWeek } from 'date-fns/fp';

export const addonsWithinThisWeek = (week: number) => {
  const today = new Date(Date.now());
  const [year, weekNumber] = week.toString().split('#');
  const date = new Date(+year, 0, 1 + (+weekNumber - 1) * 7);
  const weekDate = date;
  weekDate.setDate(date.getDate());
  const startWeek = startOfWeek(weekDate);
  const endWeek = endOfWeek(weekDate);
  return !(
    differenceInDays(parseISO(format('yyyy-MM-dd')(startWeek)), parseISO(format('yyyy-MM-dd')(today))) <= 2 ||
    differenceInDays(parseISO(format('yyyy-MM-dd')(endWeek)), parseISO(format('yyyy-MM-dd')(today))) >= 0
  );
};
