import { AddressService } from '@calo/services';
import { DeliveryStatus } from '@calo/types';
import { updateDelivery } from 'actions';
import cx from 'classnames';
import { format } from 'date-fns/fp';
import { Delivery } from 'lib/interfaces';
import { Draggable } from 'react-beautiful-dnd';
import OptionsDropdown from '../OptionsDropdown';
interface Props {
  editMode: boolean;
  item: Delivery;
  index: number;
  handleMarkerClick: (item: Delivery) => void;
  setSelectedDelivery: (address: Delivery | null) => void;
  updateDeliveries: (id: string, canceled: boolean) => void;
  removeFromList: (deliveryID: string) => void;
  generateRouteManually: () => Promise<void>;
  eta: string;
  estimatedPosition: string;
  actualPosition: (id: string) => string;
  optionsId: string | null;
  selectedColor: string | undefined;
  setOptionsId: (id: string | null) => void;
}

const SidebarDragComp = ({
  editMode,
  item,
  index,
  handleMarkerClick,
  setSelectedDelivery,
  updateDeliveries,
  removeFromList,
  generateRouteManually,
  eta,
  optionsId,
  setOptionsId,
  actualPosition,
  selectedColor,
  estimatedPosition
}: Props) => {
  const cancelDelivery = async () => {
    await updateDelivery({ status: DeliveryStatus.cancelled, id: item.id });
    setOptionsId(null);
    removeFromList(item.id);
    updateDeliveries(item.id, true);
    if (item.driver) {
      generateRouteManually();
    }
  };

  return (
    <Draggable isDragDisabled={!editMode} draggableId={item.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="flex flex-column my-2 w-full px-3 relative rounded-lg text-gray-600 focus:outline-none relative"
        >
          <div className="px-1 h-8 flex flex-column bg-blue-500 rounded-t text-white items-start text-center	text-xs">
            <p className="mr-3">
              ETA ~ {eta} - Est. Ord. {estimatedPosition}
            </p>
            <p>
              TD: {item.deliveredAt ? format('hh:mm a')(new Date(item.deliveredAt)) : '--:--'} - Actual Ord.{' '}
              {actualPosition(item.id)}
            </p>
          </div>
          <button
            disabled={!editMode}
            className={cx('focus:outline-none absolute  top right-5 cursor-default text-white', {
              'hover:text-gray-500': editMode,
              'cursor-pointer': editMode,
              'text-white': optionsId === item.id
            })}
            onClick={() => setOptionsId(optionsId === item.id ? null : item.id)}
          >
            <i className="fas fa-ellipsis-h"></i>
          </button>
          <div
            className={cx('focus:outline-none w-full border rounded-b p-2 border-gray-600 text-left ', {
              'hover:text-gray-100': editMode
            })}
            onClick={() => handleMarkerClick(item)}
            style={{
              backgroundColor: selectedColor || 'white',
              borderColor: selectedColor ? selectedColor.replace('0.5', '1') : 'gray'
            }}
          >
            <div className="flex flex-row justify-between">
              <p className="font-bold cursor-pointer hover:text-gray-100">{item.name}</p>
              <span className={cx('text-sm text-yellow-500 ml-5', { 'text-green-500': !!item.deliveredAt })}>
                {item.deliveredAt ? 'Delivered' : 'Pending'}
              </span>
            </div>
            <p className="text-xs">
              {item.shortId || ''} ({estimatedPosition})
            </p>
            <p className="text-xs">{AddressService.display(item.deliveryAddress)}</p>
            <p className="text-sm">{item.phoneNumber}</p>
          </div>
          <OptionsDropdown
            visable={optionsId === item.id}
            buttonsDetails={[
              { text: 'Cancel delivery', method: cancelDelivery },
              { text: 'Update address', method: () => setSelectedDelivery(item) }
            ]}
          />
        </div>
      )}
    </Draggable>
  );
};

export default SidebarDragComp;
