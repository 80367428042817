import { caloTheme } from 'assets/images/theme/calo';
import CaloLoader from 'components/CaloLoader';
import { format } from 'date-fns/fp';
import { Delivery } from 'lib/interfaces';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import FooterView from './FooterView';
import styles from './styles';
import TableRowView from './TableRowView';

interface DeliveriesListViewProps {
  selectedDate: Date;
  allDeliveries: Delivery[];
  setSelectedDate: (value: Date) => void;
  refetchSubscription: () => void;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  isLoading: boolean;
}

const DeliveriesListView = ({
  selectedDate,
  allDeliveries,
  setSelectedDate,
  page,
  setPage,
  total,
  isLoading
}: DeliveriesListViewProps) => {
  const [currentPage, setCurrentPage] = useState(page);
  const ref = useRef(null);
  const rowsPerPage = 8;
  const sortedDeliveries = useMemo(
    () => allDeliveries.sort((a, b) => new Date(b.day).getTime() - new Date(a.day).getTime()),
    [allDeliveries]
  );
  const totalPages = Math.floor(sortedDeliveries.length / rowsPerPage);
  const deliveriesBatch = sortedDeliveries.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);

  const handlePageChange = (p: number) => {
    if (p <= 0) {
      setCurrentPage(p);
    } else if (p >= totalPages - 1) {
      setPage((old) => old + 1);
      setCurrentPage(p);
    } else {
      setCurrentPage(p);
    }
  };

  const handleToday = () => {
    const today = new Date();
    setSelectedDate(today);
    const todayIndex = sortedDeliveries.findIndex(
      (delivery) => format('yyyy-MM-dd')(new Date(delivery.day)) === format('yyyy-MM-dd')(today)
    );
    if (todayIndex === -1) {
      setPage((old) => old + 1);
    } else {
      setCurrentPage(Math.floor(todayIndex / rowsPerPage));
    }
  };

  useEffect(() => {
    const selectedDateIndex = sortedDeliveries.findIndex(
      (delivery) => format('yyyy-MM-dd')(new Date(delivery.day)) === format('yyyy-MM-dd')(new Date(selectedDate))
    );
    if (selectedDateIndex !== -1) {
      setCurrentPage(Math.floor(selectedDateIndex / rowsPerPage));
    }
  }, []);

  return (
    <Box>
      {sortedDeliveries.length === 0 ? (
        <Typography variant="h6" align="center" sx={{ marginY: 2 }}>
          No deliveries generated
        </Typography>
      ) : (
        <TableContainer sx={{ marginX: 1 }}>
          <Table sx={styles.table}>
            <TableHead ref={ref} sx={{ backgroundColor: caloTheme.palette.neutral50 }}>
              <TableRow>
                <TableCell sx={{ width: '25%', textAlign: 'center !important', borderRadius: '8px 0 0 8px', border: 0 }}>
                  Day
                </TableCell>
                <TableCell sx={{ width: '25%', textAlign: 'center !important', border: 0 }}>
                  {(ref.current as any)?.clientWidth <= 250 ? 'Cost/Paid' : 'Cost'}
                </TableCell>
                {(ref.current as any)?.clientWidth > 250 && (
                  <TableCell sx={{ width: '25%', textAlign: 'center !important', border: 0 }}>Paid</TableCell>
                )}
                <TableCell sx={{ width: '25%', textAlign: 'center !important', borderRadius: '0 8px 8px 0', border: 0 }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deliveriesBatch.length > 0 ? (
                deliveriesBatch.map((delivery) => (
                  <TableRowView
                    delivery={delivery}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    combine={(ref.current as any)?.clientWidth <= 250}
                  />
                ))
              ) : isLoading ? (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                    <CaloLoader />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                    No deliveries found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <FooterView
        rowsPerPage={rowsPerPage}
        deliveries={sortedDeliveries}
        handleToday={handleToday}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        total={total}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default DeliveriesListView;
