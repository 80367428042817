import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Route, Switch } from 'react-router-dom';
import InvitationStatsList from './InvitationStatsList';

const InvitationStats = () => {
  const roles = useUserRoles();

  return (
    <Switch>
      {(roles.includes(Permission.VIEW_INVITATION_CODE_STATS) || roles.includes(Permission.VIEW_INVITATION_CODE_USER_STATS)) && (
        <Route key={Routes.invitationStats} exact path={Routes.invitationStats} component={InvitationStatsList} />
      )}
    </Switch>
  );
};
export default InvitationStats;
