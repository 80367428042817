import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  chips: {
    color: caloTheme.palette.primary700,
    backgroundColor: caloTheme.palette.primary100,
    padding: '8px 6px 8px 6px',
    borderWidth: 0,
    mr: 1,
    my: 1,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    textTransform: 'capitalize'
  },
  confirmButton: {
    textTransform: 'none',
    height: '45px',
    mx: 'auto',
    width: '113px',
    lineHeight: '24px',
    fontWeight: 600,
    fontSize: '20px',
    borderRadius: '8px',
    padding: '14px 20px 14px 20px',
    color: caloTheme.palette.white,
    backgroundColor: caloTheme.palette.primary500,
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      justifyItems: 'center',
      margin: 'auto',
      marginTop: 4,
      width: 'auto'
    }
  }
};

export default styles;
