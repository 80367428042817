import { forwardRef, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { Kitchen } from '@calo/types';
import { syncFoodComponent } from 'actions';
import Popup from 'components/Popup';
import SelectSyncOptions from './SelectSyncOptions';
import SuccessBox from './SuccessBox';
import ValidationResult from './ValidationResult';
import useSyncComponentForm from './useSyncComponentForm';

interface SyncComponentPopupProps {
  id: string;
  componentName: string;
  currentKitchen: Kitchen;
  closePopup: () => void;
}

const SyncComponentPopup = forwardRef(({ id, componentName, currentKitchen, closePopup }: SyncComponentPopupProps, ref) => {
  const [showValidation, setShowValidation] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { mutateAsync: syncComponents, data: syncComponentResult, isLoading } = useMutation(syncFoodComponent);

  const { handleSubmit, values, setFieldValue, setValues } = useSyncComponentForm((data) => syncComponents({ id, ...data }));

  useEffect(() => {
    if (syncComponentResult?.isSuccess) {
      setIsSuccess(true);
      toast('Component Updated Successfully', { type: 'success', delay: 3 });
    }
    if (syncComponentResult) {
      setShowValidation(true);
    }
    if (syncComponentResult?.data.length === 0) {
      setFieldValue('validate', false);
    }
  }, [syncComponentResult]);

  const handleClosePopup = () => {
    setValues({ validate: true, kitchens: [], attributes: [] });
    closePopup();
    setShowValidation(false);
    setIsSuccess(false);
  };

  return (
    <Popup ref={ref} maxWidth="md" fullWidth={true} onClose={handleClosePopup}>
      {isSuccess ? (
        <SuccessBox componentName={componentName} kitchens={values.kitchens} handleClosePopup={handleClosePopup} />
      ) : showValidation ? (
        <ValidationResult
          handleClosePopup={handleClosePopup}
          validationData={syncComponentResult?.data ?? []}
          formValues={values}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
      ) : (
        <SelectSyncOptions
          values={values}
          isLoading={isLoading}
          setFieldValue={setFieldValue}
          closePopup={closePopup}
          handleSubmit={handleSubmit}
          currentKitchen={currentKitchen}
        />
      )}
    </Popup>
  );
});

export default SyncComponentPopup;
