import { FoodDietType } from '@calo/types';
import { Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';

interface TagProps {
  tag: string;
}

const MenuFoodDietTags = ({ tag }: TagProps) => {
  switch (tag) {
    case FoodDietType.balanced:
      return (
        <Typography
          sx={{
            width: '24px',
            height: '24px',
            borderRadius: '240px',
            textAlign: 'center',
            backgroundColor: caloTheme.palette.primary500,
            color: 'white',
            mr: '3px'
          }}
        >
          {' '}
          {'B'}
        </Typography>
      );
    case FoodDietType.lowCarb:
      return (
        <Typography
          sx={{
            width: '24px',
            height: '24px',
            borderRadius: '240px',
            textAlign: 'center',
            backgroundColor: caloTheme.palette.secondaryBlue500,
            color: 'white',
            mr: '3px'
          }}
        >
          {' '}
          {'L'}
        </Typography>
      );
    case FoodDietType.vegetarian:
      return (
        <Typography
          sx={{
            width: '24px',
            height: '24px',
            borderRadius: '240px',
            textAlign: 'center',
            backgroundColor: caloTheme.palette.secondaryPurple500,
            color: 'white',
            mr: '3px'
          }}
        >
          {' '}
          {'V'}
        </Typography>
      );
    case FoodDietType.gourmet:
      return (
        <Typography
          sx={{
            width: '24px',
            height: '24px',
            borderRadius: '240px',
            textAlign: 'center',
            backgroundColor: caloTheme.palette.secondaryOrange100,
            color: 'black',
            mr: '3px'
          }}
        >
          {' '}
          {'G'}
        </Typography>
      );
    case FoodDietType.preBuiltCustom:
      return (
        <Typography
          sx={{
            width: '24px',
            height: '24px',
            borderRadius: '240px',
            textAlign: 'center',
            backgroundColor: caloTheme.palette.black,
            color: 'white',
            mr: '3px'
          }}
        >
          {' '}
          {'P'}
        </Typography>
      );
    case FoodDietType.highProtein:
      return (
        <Typography
          sx={{
            width: '24px',
            height: '24px',
            borderRadius: '240px',
            textAlign: 'center',
            backgroundColor: caloTheme.palette.secondaryBlue100,
            color: 'black',
            mr: '3px'
          }}
        >
          {' '}
          {'H'}
        </Typography>
      );
    case FoodDietType.weightLoss:
      return (
        <Typography
          sx={{
            width: '24px',
            height: '24px',
            borderRadius: '240px',
            textAlign: 'center',
            backgroundColor: caloTheme.palette.secondaryYellow300,
            color: 'black',
            mr: '3px'
          }}
        >
          {' '}
          {'W'}
        </Typography>
      );
    case FoodDietType.customMacros:
      return (
        <Typography
          sx={{
            width: '24px',
            height: '24px',
            borderRadius: '240px',
            textAlign: 'center',
            backgroundColor: caloTheme.palette.secondaryTeal500,
            color: 'black',
            mr: '3px'
          }}
        >
          {' '}
          {'C'}
        </Typography>
      );
    default:
      return (
        <Typography
          sx={{
            width: 'auto',
            height: '24px',
            borderRadius: '8px',
            textAlign: 'center',
            textTransform: 'capitalize',
            backgroundColor: caloTheme.palette.secondaryPink100,
            color: 'white',
            mr: '3px',
            px: '4px'
          }}
        >
          {' '}
          {tag}
        </Typography>
      );
  }
};
export default MenuFoodDietTags;
