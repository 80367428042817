import { SupplyCapZoneFilters } from '@calo/dashboard-types';
import { Country, Kitchen } from '@calo/types';
import { toggleUISettings } from 'actions';
import { Select, SideDrawer } from 'components';
import { getAccessibleCountries, setCountryToLocalStorage } from 'lib/helpers';
import { getKitchenOptions } from 'lib/helpers/kitchenUtils';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface SettingsProps {
  onFilter: (values: SupplyCapZoneFilters) => any;
  filters: SupplyCapZoneFilters;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  const changeCountry = (data: any) => {
    setCountryToLocalStorage(data.value);
    onFilter({
      ...filters,
      country: data.value,
      kitchen: userKitchens.find((k) => k.includes(data.value)) as Kitchen
    });
  };

  useEffect(() => {
    onFilter({
      ...filters,
      kitchen: userKitchens.find((k) => k.includes(filters.country!)) as Kitchen
    });
  }, []);

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select
          label="Country"
          value={filters.country}
          onChange={(data: any) => changeCountry(data)}
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
          data-test="dashboard-country-select"
        />
        <Select
          label="Kitchen"
          value={filters.kitchen}
          onChange={(data: any) => {
            onFilter({ ...filters, kitchen: data.value as Kitchen });
          }}
          options={getKitchenOptions(userKitchens, filters.country!)}
          isDisabled={userKitchens.filter((r) => r.includes(filters.country!))?.length === 0}
          data-test="supplyCapZones-kitchen-select"
        />
      </section>
    </SideDrawer>
  );
};

export default Settings;
