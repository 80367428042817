import { Kitchen } from '@calo/types';

export const mountainPeeksAverageCost: Partial<Record<Kitchen, Record<string, number>>> = {
  [Kitchen.SA1]: {
    s: 0.34,
    m: 0.49,
    l: 0.16
  },
  [Kitchen.SA2]: {
    s: 0.32,
    m: 0.52,
    l: 0.17
  },
  [Kitchen.BH1]: {
    s: 0.2,
    m: 0.48,
    l: 0.32
  },
  [Kitchen.QA1]: {
    s: 0.2,
    m: 0.48,
    l: 0.32
  },
  [Kitchen.KW1]: {
    s: 0.33,
    m: 0.45,
    l: 0.21
  },
  [Kitchen.AE1]: {
    s: 0.28,
    m: 0.5,
    l: 0.22
  },
  [Kitchen.GB1]: {
    s: 0.33,
    m: 0.33,
    l: 0.34
  }
};

export const macrosSortOptions = Object.freeze({
  any: 'any',
  highestToLowestProtein: 'highest to lowest protein',
  highestToLowestCarb: 'highest to Lowest carb',
  lowestToHighestFat: 'lowest to highest fat'
});
