import client from 'lib/client';

interface WithdrawBalanceProps {
  userId: string;
}

export const withdrawBalance = async (payload: WithdrawBalanceProps) => {
  const { userId } = payload;
  const { data } = await client.post(`referrals/${userId}/withdraw`);
  return data;
};
