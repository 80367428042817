import { Permission } from '@calo/dashboard-types';
import { PhoneNumber } from 'components';
import { format, parseISO } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Link } from 'react-router-dom';
import PricingService from 'services/PricingService';

interface PaymentRowProps {
  user: any;
}

const PaymentRow = ({ user }: PaymentRowProps) => {
  const roles = useUserRoles();

  return (
    <tr>
      <td>
        {roles.includes(Permission.VIEW_SUBSCRIPTION) ? (
          <Link to={Routes.subscription.replace(':id', user.userId)}>{user.name}</Link>
        ) : (
          `${user.name}`
        )}
      </td>
      <td>
        <PhoneNumber>{user.phoneNumber}</PhoneNumber>
      </td>
      <td>{user.createdAt && format('MMM dd-yyyy')(parseISO(user.createdAt))}</td>
      <td>{user.paymentMethod}</td>
      <td>{+PricingService.roundAmount(user.amount, user.currency)}</td>
      <td>{user.currency}</td>
      <td>{user.type ? user.type : '--'}</td>
      <td>{user.reason ? user.reason : '--'}</td>
      <td>{user.notes}</td>
    </tr>
  );
};

export default PaymentRow;
