import { CreateFoodReq } from '@calo/dashboard-types';
import { Country, Kitchen } from '@calo/types';
import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { InputMUI } from 'components';
import SelectMUI from 'components/MUI/SelectMUI';
import { FormikErrors } from 'formik';
import { foodInformationFormSingleSelectCustomStyles } from 'lib/componentStyles';
import { hasValidCharactersForLanguage, selectCountry } from 'lib/helpers';
import { getKitchenOptions } from 'lib/helpers/kitchenUtils';
import { useUserKitchens } from 'lib/hooks';
import { useState } from 'react';

interface MealCreationInformationProps {
  values: Omit<CreateFoodReq, 'id'>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<Omit<CreateFoodReq, 'id'>>>;
}

const MealCreationInformation = ({ values, setFieldValue }: MealCreationInformationProps) => {
  const userKitchens: Kitchen[] = useUserKitchens();
  const [langAlert, setLangAlert] = useState({ AR: false, EN: false, value: '' });

  const handleInformationFieldChange = (field: string, data: string, lang: 'AR' | 'EN') => {
    const allowSpecialChar = field.includes('description');
    if (hasValidCharactersForLanguage(data, lang, allowSpecialChar) || data === '') {
      setFieldValue(field, data);
      setLangAlert({ ...langAlert, [`${lang}`]: false, value: field });
    } else {
      setFieldValue(field, '');
      setLangAlert({ ...langAlert, [`${lang}`]: true, value: field });
    }
  };
  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        spacing={2}
        sx={{ backgroundColor: caloTheme.palette.white, borderRadius: '16px', px: 1 }}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ backgroundColor: caloTheme.palette.white, borderRadius: '16px' }}
        >
          <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Typography
              sx={{
                my: 2,
                mx: '8px',
                mb: 3,
                color: caloTheme.palette.neutral900,
                textTransform: 'capitalize',
                fontSize: '19px',
                fontWeight: 600,
                lineHeight: '23px'
              }}
            >
              Meal Information
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
            <Stack direction="column" justifyContent="space-between" alignItems="center" sx={{ width: '50%', mr: 1, ml: '4px' }}>
              <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
                <InputMUI
                  label="Meal Name (English)"
                  name="name.en"
                  placeholder="Enter Meal Name"
                  value={values.name?.en}
                  debounce
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(data) => {
                    handleInformationFieldChange('name.en', data.target.value, 'EN');
                  }}
                  error={langAlert.EN && langAlert.value === 'name.en'}
                  sx={{
                    width: '100%',
                    mb:
                      (langAlert.EN && langAlert.value === 'name.en') ||
                      (values.name?.en && values.name.en.length > 0 && values.name.en.length < 3)
                        ? 0
                        : '20px'
                  }}
                  inputProps={{ style: { borderRadius: 8 }, dataTest: 'foodEnNameTextField' }}
                />
                <FormHelperText id="name.en-error" sx={{ color: caloTheme.palette.red }}>
                  {langAlert.EN && langAlert.value === 'name.en' && 'letters should be in English only'}
                  {values.name?.en && values.name.en.length > 0 && values.name.en.length < 3 && 'Minimum 3 characters'}
                </FormHelperText>
              </Stack>
              <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
                <InputMUI
                  label="Description (English - Sticker)"
                  name="description.en"
                  placeholder="Enter Description"
                  value={values.description!.en}
                  debounce
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(data) => handleInformationFieldChange('description.en', data.target.value, 'EN')}
                  error={langAlert.EN && langAlert.value === 'description.en'}
                  sx={{ width: '100%', mb: langAlert.EN && langAlert.value === 'description.en' ? 0 : '20px' }}
                  inputProps={{ style: { borderRadius: 8 }, dataTest: 'foodEnDescriptionTextField' }}
                />
                <FormHelperText id="description.en-error" sx={{ color: caloTheme.palette.red }}>
                  {langAlert.EN && langAlert.value === 'description.en' && 'Letters should be in English only'}
                </FormHelperText>
              </Stack>
              <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
                <InputMUI
                  label="Long Description (English)"
                  name="longDescription.en"
                  placeholder="Enter Long Description"
                  value={values.longDescription?.en}
                  debounce
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(data) => setFieldValue('longDescription.en', data.target.value)}
                  error={langAlert.EN && langAlert.value === 'longDescription.en'}
                  sx={{ width: '100%', mb: langAlert.EN && langAlert.value === 'longDescription.en' ? 0 : '20px' }}
                  inputProps={{ style: { borderRadius: 8 }, dataTest: 'foodEnLongDescriptionTextField' }}
                  rows={4}
                  multiline
                />
                <FormHelperText id="longDescription.en-error" sx={{ color: caloTheme.palette.red }}>
                  {langAlert.EN && langAlert.value === 'longDescription.en' && 'Letters should be in English only'}
                </FormHelperText>
              </Stack>
            </Stack>
            <Stack direction="column" justifyContent="space-between" alignItems="center" sx={{ width: '50%', mx: 1 }}>
              <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
                <InputMUI
                  label="Meal Name (Arabic)"
                  name="name.ar"
                  placeholder="Enter Meal Name"
                  value={values.name?.ar}
                  debounce
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(data) => handleInformationFieldChange('name.ar', data.target.value, 'AR')}
                  error={langAlert.AR && langAlert.value === 'name.ar'}
                  sx={{
                    width: '100%',
                    mb:
                      (langAlert.AR && langAlert.value === 'name.ar') ||
                      (values.name?.ar && values.name.ar.length > 0 && values.name.ar.length < 3)
                        ? 0
                        : '20px'
                  }}
                  inputProps={{ style: { borderRadius: 8 }, dataTest: 'foodArNameTextField' }}
                />
                <FormHelperText id="name.ar-error" sx={{ color: caloTheme.palette.red }}>
                  {langAlert.AR && langAlert.value === 'name.ar' && 'Letters should be in Arabic only'}
                  {values.name?.ar && values.name.ar.length > 0 && values.name.ar.length < 3 && 'Minimum 3 characters'}
                </FormHelperText>
              </Stack>
              <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
                <InputMUI
                  label="Description (Arabic - Sticker)"
                  name="description.ar"
                  placeholder="Enter Description"
                  value={values.description!.ar}
                  debounce
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(data) => handleInformationFieldChange('description.ar', data.target.value, 'AR')}
                  error={langAlert.AR && langAlert.value === 'description.ar'}
                  sx={{ width: '100%', mb: langAlert.AR && langAlert.value === 'description.ar' ? 0 : '20px' }}
                  inputProps={{ style: { borderRadius: 8 }, dataTest: 'foodArDescriptionTextField' }}
                />
                <FormHelperText id="description.ar-error" sx={{ color: caloTheme.palette.red }}>
                  {langAlert.AR && langAlert.value === 'description.ar' && 'Letters should be in Arabic only'}
                </FormHelperText>
              </Stack>
              <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
                <InputMUI
                  label="Long Description (Arabic)"
                  name="longDescription.ar"
                  placeholder="Enter Long Description"
                  value={values.longDescription?.ar}
                  debounce
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(data) => setFieldValue('longDescription.ar', data.target.value)}
                  error={langAlert.AR && langAlert.value === 'longDescription.ar'}
                  sx={{ width: '100%', mb: langAlert.AR && langAlert.value === 'longDescription.ar' ? 0 : '20px' }}
                  inputProps={{ style: { borderRadius: 8 }, dataTest: 'foodArLongDescriptionTextField' }}
                  rows={4}
                  multiline
                />
                <FormHelperText id="longDescription.ar-error" sx={{ color: caloTheme.palette.red }}>
                  {langAlert.AR && langAlert.value === 'longDescription.ar' && 'Letters should be in Arabic only'}
                </FormHelperText>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Box flexDirection="column" justifyContent="space-between">
        <Stack
          direction="column"
          justifyContent="space-between"
          spacing={2}
          sx={{ backgroundColor: caloTheme.palette.white, px: 1, borderRadius: '16px' }}
        >
          <Typography
            sx={{
              mt: 2,
              mx: '8px',
              color: caloTheme.palette.neutral900,
              textTransform: 'capitalize',
              fontSize: '19px',
              fontWeight: 600,
              lineHeight: '23px'
            }}
          >
            Meal Properties
          </Typography>
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
            <Stack direction="column" justifyContent="space-between" sx={{ width: '100%', mt: '-12px', mx: 1, mb: 2 }}>
              <SelectMUI
                label="Country*"
                value={values.country || Country.BH}
                id="newMealCountrySelector"
                data-test="new-meal-country"
                onChange={(data: any) => {
                  selectCountry({
                    value: data.value,
                    kitchens: userKitchens,
                    setFieldValue
                  });
                }}
                options={Object.values(Country).map((country) => ({
                  value: country,
                  label: country
                }))}
                customStyles={foodInformationFormSingleSelectCustomStyles}
              />
            </Stack>
            <Stack direction="column" justifyContent="space-between" sx={{ width: '100%', mt: '-12px', mx: 1, mb: 2 }}>
              <SelectMUI
                label="Kitchen*"
                data-test="new-meal-kitchen"
                id="newMealKitchenSelector"
                value={values.kitchen || Kitchen.BH1}
                options={getKitchenOptions(userKitchens, values.country)}
                onChange={(data: any) => setFieldValue('kitchen', data.value)}
                customStyles={foodInformationFormSingleSelectCustomStyles}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
export default MealCreationInformation;
