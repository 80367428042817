import { caloTheme } from 'assets/images/theme/calo';
import React from 'react';

import { Icon } from '@iconify/react';
import { Box, Stack } from '@mui/material';

interface Props {
  selectedDeliveryView: string;
  setSelectedDeliveryView: React.Dispatch<React.SetStateAction<string>>;
}

const TabsView = ({ selectedDeliveryView, setSelectedDeliveryView }: Props) => {
  return (
    <Box
      sx={{
        flexDirection: 'row',
        display: 'flex',
        padding: ' 4px',
        gap: ' 8px',
        borderRadius: '8px',
        border: '1px solid ' + caloTheme.palette.neutral100
      }}
    >
      <Stack
        sx={{
          backgroundColor: selectedDeliveryView === 'list' ? caloTheme.palette.primary100 : caloTheme.palette.white,
          px: '10px',
          py: '6px',
          borderRadius: '8px'
        }}
      >
        <Icon
          icon="lucide:list"
          width="24"
          height="24"
          onClick={() => setSelectedDeliveryView('list')}
          style={{
            cursor: 'pointer',
            color: selectedDeliveryView === 'list' ? caloTheme.palette.primary500 : caloTheme.palette.neutral600
          }}
        />
      </Stack>
      <Stack
        sx={{
          backgroundColor: selectedDeliveryView === 'calender' ? caloTheme.palette.primary100 : caloTheme.palette.white,
          px: '10px',
          py: '6px',
          borderRadius: '8px'
        }}
      >
        <Icon
          icon="uit:calender"
          width="24"
          height="24"
          style={{
            cursor: 'pointer',
            color: selectedDeliveryView === 'calender' ? caloTheme.palette.primary500 : caloTheme.palette.neutral600
          }}
          onClick={() => setSelectedDeliveryView('calender')}
        />
      </Stack>
    </Box>
  );
};

export default TabsView;
