import { AddressType, DeliveryAddress, DeliveryInstruction, NewDeliveryAddress } from '@calo/types';
import AddIcon from '@mui/icons-material/Add';
import CallIcon from '@mui/icons-material/Call';
import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { FormikErrors } from 'formik';
import { DeliveryInstructionsLabels } from 'lib/enums';
import { useState } from 'react';
import TextArea from '../../TextArea';

interface DeliveryInstructionsProps {
  values: NewDeliveryAddress;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<DeliveryAddress>> | Promise<void>;
  handleChange: { (e: React.ChangeEvent<any>): void };
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
}

const DeliveryInstructions = ({ values, setFieldValue, handleChange, handleBlur }: DeliveryInstructionsProps) => {
  const [showInstructions, setShowInstructions] = useState(
    (values.deliveryInstructions || []).length > 0 || Boolean(values.notes)
  );

  const { deliveryInstructions } = values;

  const icons = {
    [DeliveryInstruction.CALL_ME_WHEN_YOU_REACH]: <CallIcon fontSize="small" color="action" />,
    [DeliveryInstruction.LEAVE_AT_RECEPTION]: '🛎️',
    [DeliveryInstruction.LEAVE_AT_THE_DOOR]: '🚪',
    [DeliveryInstruction.RING_MY_DOORBELL]: '🛎️'
  };

  const toggleDeliveryInstructionSelected = (instruction: DeliveryInstruction) => {
    const newDeliveryInstructions = deliveryInstructions?.includes(instruction)
      ? deliveryInstructions.filter((inst) => inst !== instruction)
      : [...(deliveryInstructions || []), instruction];

    setFieldValue('deliveryInstructions', newDeliveryInstructions);
  };

  const officeInstructions = [DeliveryInstruction.CALL_ME_WHEN_YOU_REACH, DeliveryInstruction.LEAVE_AT_RECEPTION];
  const homeInstructions = [
    DeliveryInstruction.CALL_ME_WHEN_YOU_REACH,
    DeliveryInstruction.LEAVE_AT_THE_DOOR,
    DeliveryInstruction.RING_MY_DOORBELL
  ];

  const instructionsToShow = values.type === AddressType.office ? officeInstructions : homeInstructions;

  return (
    <Stack sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '8px' }}>
        <Button
          disableRipple
          onClick={() => setShowInstructions((prev) => !prev)}
          startIcon={showInstructions ? null : <AddIcon />}
          sx={{
            fontSize: '16px',
            fontWeight: 900,
            textTransform: 'none',
            color: showInstructions ? '#2B2B2B' : caloTheme.palette.primary500,
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
        >
          Add Delivery Instructions
        </Button>
        <Typography sx={{ marginLeft: '-2px', marginTop: '1px', fontSize: '12px', fontWeight: 900, color: '#B1B1B1' }}>
          (Optional)
        </Typography>
      </Stack>
      {showInstructions && (
        <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
          {instructionsToShow.map((instruction) => (
            <Stack
              key={instruction}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Stack sx={{ display: 'flex', flexDirection: 'row', marginLeft: 1, gap: 1 }}>
                {icons[instruction]}
                <Typography sx={{ fontSize: '14px', fontWeight: 400, marginTop: '2px' }}>
                  {DeliveryInstructionsLabels[instruction]}
                </Typography>
              </Stack>
              <FormControlLabel
                sx={{ marginRight: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
                control={
                  <Checkbox
                    sx={{ padding: 0 }}
                    checked={deliveryInstructions?.includes(instruction)}
                    onChange={() => toggleDeliveryInstructionSelected(instruction)}
                  />
                }
                label=""
              />
            </Stack>
          ))}
          <div className="flex flex-row justify-center mb-4 mt-1 ml-2">
            <span className="w-full">
              <TextArea
                rows={1}
                label="Additional Delivery Request"
                placeholder="Additional Delivery Request"
                name="notes"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.notes}
              />
            </span>
          </div>
        </Stack>
      )}
    </Stack>
  );
};

export default DeliveryInstructions;
