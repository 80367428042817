import { Reducer } from 'react';

const reducer: Reducer<SinglePolygonState, SinglePolygonAction> = (state = { polygon: undefined }, action) => {
  switch (action.type) {
    case 'set':
      return {
        polygon: action.payload
      };
    case 'add':
      return {
        polygon: action.payload.polygon
      };

    case 'delete': {
      const polygon = action.payload;
      polygon?.unbindAll();
      polygon?.setMap(null);
      return {
        ...state,
        polygon: undefined
      };
    }

    case 'reset':
      state.polygon?.unbindAll();
      state.polygon?.setMap(null);
      return { polygon: undefined };

    default:
      return state;
  }
};

export default reducer;
