import { alpha, styled, TableCell, tableCellClasses, TableRow } from '@mui/material';

import { Currency } from '@calo/types';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns/fp';
import {
  resolveLoyaltyTransactionLogReason,
  resolveLoyaltyTransactionLogSource,
  resolveLoyaltyTransactionLogStatus
} from 'lib/helpers';
import { LoyaltyTransaction } from 'lib/interfaces';

interface PointsTransactionRowProps {
  row: LoyaltyTransaction;
  currency: Currency;
}

const PointsTransactionRow = ({ row }: PointsTransactionRowProps) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      width: '100%',
      px: 4,
      component: 'th',
      scope: 'row',
      color: caloTheme.palette.neutral900,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      variant: 'caption'
    },
    [`&.${tableCellClasses.body}`]: {
      px: 4,
      fontWeight: 400,
      fontSize: '12px',
      fontHeight: '14px',
      variant: 'caption',
      fontFamily: caloTheme.typography.fontFamily
    }
  }));

  const isAddition = ['added', 'added_by_calo'].includes(row.type);

  return (
    <>
      <TableRow key={row.id}>
        <StyledTableCell>{row.time && format('dd MMM yyy k:mm aaaa')(new Date(row.time))}</StyledTableCell>
        <StyledTableCell>{resolveLoyaltyTransactionLogSource(row.type)}</StyledTableCell>
        <StyledTableCell>{resolveLoyaltyTransactionLogReason(row)}</StyledTableCell>
        <StyledTableCell>{resolveLoyaltyTransactionLogStatus(row.type)}</StyledTableCell>
        <StyledTableCell
          style={{
            backgroundColor:
              (isAddition ? alpha(caloTheme.palette.primary100, 0.7) : alpha(caloTheme.palette.secondaryRed100, 0.2)) || 'inherit'
          }}
        >
          {`${isAddition ? `+ ${row.points}` : `- ${row.points}`}`}
        </StyledTableCell>
        <StyledTableCell>{row.balance}</StyledTableCell>
        <StyledTableCell>{row.actor || '-'}</StyledTableCell>
      </TableRow>
    </>
  );
};

export default PointsTransactionRow;
