import { useCallback } from 'react';

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { chunk, sortBy } from 'lodash-es';

import { IngredientStatsReq } from '@calo/dashboard-types';

import { IngredientStats } from 'lib/interfaces';
import SharedStatsHeader from 'views/Kitchen/SharedStatsHeader';
import { printPDF } from 'views/Kitchen/utils';
import { columns, findDisplayWeights } from '../helpers';

interface IngredientStatsHeaderCardProps {
  isLoading: boolean;
  filters: IngredientStatsReq;
  summedComponentData: IngredientStats[];
  filtersCheck: { subscriptions: boolean; businesses: boolean; charity: boolean };
  showDetailedView: boolean;
}

const IngredientStatsHeaderCard = ({
  summedComponentData,
  isLoading,
  filters,
  filtersCheck,
  showDetailedView
}: IngredientStatsHeaderCardProps) => {
  const onExport = useCallback(async () => {
    if (!summedComponentData) {
      return;
    }
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('ingredient-stats', {
      pageSetup: { fitToPage: true, orientation: 'portrait' }
    });
    worksheet.mergeCells('A1', 'G1');
    worksheet.getCell('A1').value = `${filters.day.gte} - ${filters.day.lte}`;
    worksheet.getCell('A1').font = { color: { argb: '0000' }, bold: true, size: 18 };
    worksheet.getCell('A1').alignment = {
      vertical: 'middle',
      horizontal: 'center'
    };

    const defaultColumns = ['Name', 'Internal Name'];

    const totalColumns = ['Total Quantity (in grams)', 'Total Quantity after Prep (in grams)'];
    const detailedColumns = [
      'Quantity',
      'Quantity after Prep',
      'Special Requests Quantity',
      'Special Requests Quantity after Prep',
      'Sub-component Quantity',
      'Sub-component Quantity after prep',
      'Removed Quantity',
      'Removed Quantity after prep'
    ];
    worksheet.getRow(2).values = [
      ...defaultColumns,
      ...(showDetailedView ? detailedColumns : []),
      ...totalColumns,
      ' ',
      ...defaultColumns,
      ...(showDetailedView ? detailedColumns : []),
      ...totalColumns
    ];
    worksheet.columns = columns(showDetailedView);

    for (const row of chunk(sortBy(summedComponentData, 'name.en'), 2)) {
      const firstIngredient = findDisplayWeights(row[0]);
      const secondIngredient = findDisplayWeights(row[1]);
      const baseColumns = {
        name: row[0].name.en,
        internalName: row[0].internalName,
        totalQuantity: firstIngredient?.totalQuantity,
        totalQuantityAfterPrep: firstIngredient?.totalQuantityAfterPrep,
        name2: row[1] ? row[1].name.en : '',
        internalName2: row[1] ? row[1].internalName : '',
        totalQuantity2: secondIngredient?.totalQuantity,
        totalQuantityAfterPrep2: secondIngredient?.totalQuantityAfterPrep
      };

      const detailedViewColumns = {
        quantity: firstIngredient?.quantity,
        quantityAfterPrep: firstIngredient?.quantityAfterPrep,
        specialRequestsQuantity: firstIngredient?.specialRequestsQuantity,
        specialRequestsQuantityAfterPrep: firstIngredient?.specialRequestsQuantityAfterPrep,
        subComponentQuantity: firstIngredient?.subComponentQuantity,
        subComponentQuantityAfterPrep: firstIngredient?.subComponentQuantityAfterPrep,
        removedQuantity: firstIngredient?.removedQuantity,
        removedQuantityAferPrep: firstIngredient?.removedQuantityAferPrep,
        quantity2: secondIngredient?.quantity,
        quantityAfterPrep2: secondIngredient?.quantityAfterPrep,
        specialRequestsQuantity2: secondIngredient?.specialRequestsQuantity,
        specialRequestsQuantityAfterPrep2: secondIngredient?.specialRequestsQuantityAfterPrep,
        subComponentQuantity2: secondIngredient?.subComponentQuantity,
        subComponentQuantityAfterPrep2: secondIngredient?.subComponentQuantityAfterPrep,
        removedQuantity2: secondIngredient?.removedQuantity,
        removedQuantityAferPrep2: secondIngredient?.removedQuantityAferPrep
      };

      worksheet.addRow(
        {
          ...baseColumns,
          ...(showDetailedView ? detailedViewColumns : {})
        },
        ''
      );
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, 'ingredient-stats' + fileExtension);
  }, [summedComponentData, filtersCheck]);

  const handleOnPrint = () => {
    const records = sortBy(summedComponentData, 'name.en').map((row) => {
      const ingredient = findDisplayWeights(row);
      const baseSection = {
        name: row.name.en,
        internalName: row.internalName,
        totalQuantity: ingredient?.totalQuantity,
        totalQuantityAfterPrep: ingredient?.totalQuantityAfterPrep
      };
      const detailedSection = {
        quantity: ingredient?.quantity,
        quantityAfterPrep: ingredient?.quantityAfterPrep,
        specialRequestsQuantity: ingredient?.specialRequestsQuantity,
        specialRequestsQuantityAfterPrep: ingredient?.specialRequestsQuantityAfterPrep,
        subComponentQuantity: ingredient?.subComponentQuantity,
        subComponentQuantityAfterPrep: ingredient?.subComponentQuantityAfterPrep,
        removedQuantity: ingredient?.removedQuantity,
        removedQuantityAferPrep: ingredient?.removedQuantityAferPrep
      };

      return {
        ...baseSection,
        ...(showDetailedView ? detailedSection : {})
      };
    });
    printPDF(`Ingredient stats ${filters.day.gte}-${filters.day.lte}`, records);
  };

  return (
    <SharedStatsHeader
      title={'Ingredient Stats'}
      isLoading={isLoading}
      onExport={onExport}
      isDisabled={summedComponentData.length === 0 || isLoading}
      onPrint={handleOnPrint}
    />
  );
};

export default IngredientStatsHeaderCard;
