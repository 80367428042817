import { Affiliate } from '@calo/dashboard-types';
import { Box, Card, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Button } from 'components';
import AffiliateProfileRow from './AffiliateRow';
import { exportAffiliatesReport } from 'actions/affiliates';
import { useState } from 'react';
import { toast } from 'react-toastify';

interface AffiliatesListComponentProps {
  affiliatesLoading: boolean;
  affiliates: Affiliate[] | undefined;
}

const AffiliatesList = ({ affiliatesLoading, affiliates }: AffiliatesListComponentProps) => {
  const [isExportPressed, setExportPressed] = useState(false);
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  const handleExportAffiliates = () => {
    if (isExportPressed) {
      toast('Please wait 10 seconds', { type: 'warning', autoClose: 2000 });
    } else {
      setExportPressed(true);
      exportAffiliatesReport();
      setTimeout(() => {
        setExportPressed(false);
      }, 10000);
    }
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <>
          <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px'
              }}
            >
              Affiliates
            </Typography>
            <Button onClick={handleExportAffiliates} icon="fas fa-file-export" />

            {affiliatesLoading ? (
              <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                <CaloLoader />
              </Stack>
            ) : (
              <>
                <Table
                  sx={{
                    marginY: '4px',
                    minHeight: '120px',
                    overflow: 'auto',
                    width: '100%',
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      flexDirection: 'column'
                    }
                  }}
                >
                  <TableHead
                    style={{ borderRadius: '8px' }}
                    sx={{
                      backgroundColor: caloTheme.palette.neutral50,
                      color: 'black',
                      flexWrap: 0,
                      justifyContent: 'space-between',
                      width: '100%',
                      borderRadius: '8px'
                    }}
                  >
                    <TableRow
                      sx={{
                        backgroundColor: caloTheme.palette.neutral50,
                        color: 'black',
                        width: '100%',
                        flexWrap: 0,
                        justifyContent: 'space-between'
                      }}
                    >
                      <StyledTableCell style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
                        Name
                      </StyledTableCell>
                      <StyledTableCell>Date of Joining</StyledTableCell>
                      <StyledTableCell>Coupon</StyledTableCell>
                      <StyledTableCell>Created By</StyledTableCell>
                      <StyledTableCell>Country</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {affiliates && affiliates.length === 0 ? (
                    <span className="absolute w-11/12 text-2xl mt-6 text-center font-bold text-gray-400 ">NO Affiliates!</span>
                  ) : (
                    <TableBody>
                      {affiliates &&
                        affiliates.map((affiliateProfile) => (
                          <AffiliateProfileRow affiliateProfile={affiliateProfile} key={affiliateProfile.id} />
                        ))}
                    </TableBody>
                  )}
                </Table>
              </>
            )}
          </Box>
        </>
      </Card>
    </>
  );
};
export default AffiliatesList;
