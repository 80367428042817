import { DeliveryStatus, PaymentMethod } from '@calo/types';

import { Delivery } from 'lib/interfaces';
import { Icon } from '../';

interface DeliveryPaymentIconProps {
  delivery: Delivery;
}

const DeliveryPaymentIcon = ({ delivery }: DeliveryPaymentIconProps) => (
  <div>
    <span
      className="fa-stack mt-2 mr-1"
      title={
        delivery.status === DeliveryStatus.upcoming ? `Paid by ${delivery.paymentMethod}` : `Not paid (${delivery.paymentMethod})`
      }
    >
      {delivery.paymentMethod === PaymentMethod.mada && <i>mada</i>}
      {delivery.paymentMethod === PaymentMethod.cash && <i className="fas fa-money-bill fa-stack-1x"></i>}
      {(delivery.paymentMethod === PaymentMethod.cc || delivery.paymentMethod === PaymentMethod.benefit) && (
        <i className="far fa-credit-card fa-stack-1x"></i>
      )}
      {delivery.paymentMethod === PaymentMethod.applePay && <i className="fab fa-apple-pay fa-stack-2x"></i>}
      {delivery.status !== DeliveryStatus.upcoming && <i className="fas fa-slash fa-stack-2x" style={{ color: 'red' }} />}
    </span>
    <span className="fa-stack mt-2">
      {delivery.status === DeliveryStatus.cancelled && <i className="fas fa-ban mr-1" title="cancelled"></i>}
      {delivery.status === DeliveryStatus.paused && <i className="fas fa-pause mr-1" title="paused"></i>}
      {delivery.status === DeliveryStatus.suspended && <i className="fas fa-times-circle mr-1" title="suspended"></i>}
      {delivery.skipped && <i className="fas fa-forward mr-1" title="skipped"></i>}
    </span>
    <span className="fa-stack mt-2">{delivery.withCutlery && <Icon name="cutlery" size={6} />}</span>
    <span className="fa-stack mt-2">
      {delivery.withCoolerBag && <Icon name="coolerBag" size={28} className="ml-1 w-24 h-16 -mb-24 -mt-2" />}
    </span>
  </div>
);
export default DeliveryPaymentIcon;
