import { Brand, Country, FoodType } from '@calo/types';
import { getRecordWithParams } from 'actions';
import { Button, Checkbox, ModalRef, Select } from 'components';
import { selectStyles } from 'lib/componentStyles';
import { Delivery, Menu } from 'lib/interfaces';
import { flatten, uniq } from 'lodash';
import { useQuery } from 'react-query';

interface AddonsPickerModalProps {
  year: number;
  week: number;
  country: Country;
  isGifted: boolean;
  delivery: Delivery;
  selectedAddon: any;
  addonType: FoodType;
  setIsGifted: (value: boolean) => void;
  setAddonType: (v: any) => void;
  setSelectedAddon: (v: any) => void;
  handleAddAddonRequest: (v: any) => void;
  addonModalRef: React.MutableRefObject<ModalRef | undefined>;
}

const AddonsPickerModal = ({
  setIsGifted,
  isGifted,
  addonType,
  delivery,
  year,
  week,
  setAddonType,
  selectedAddon,
  handleAddAddonRequest,
  addonModalRef,
  setSelectedAddon
}: AddonsPickerModalProps) => {
  const { data: addons } = useQuery(
    [
      `addons-menu/year/${year}/week/${week}`,
      {
        brand: delivery.brand || Brand.CALO,
        kitchen: delivery.kitchen
      }
    ],
    getRecordWithParams,
    {
      retry: false
    }
  );

  const addonsMenu = addons as Menu;
  const addonsTypes = uniq(flatten(addonsMenu?.food?.map((r: any) => r.type)));

  return (
    <>
      <Select
        label="Type"
        value={addonType}
        options={addonsTypes
          .filter((r) => r !== FoodType.dinner)
          .map((t) =>
            t === FoodType.lunch
              ? {
                  value: t,
                  label: 'Lunch & Dinner'
                }
              : {
                  value: t,
                  label: t
                }
          )}
        customStyles={selectStyles}
        onChange={(data: any) => setAddonType(data.value)}
      />
      <Select
        label="Addon"
        value={selectedAddon}
        customStyles={selectStyles}
        options={
          addonsMenu ? addonsMenu.food.filter((f) => f.type.includes(addonType)).map((f) => ({ value: f, label: f.name.en })) : []
        }
        onChange={(data: any) => setSelectedAddon(data.value)}
      />
      <div className="flex">
        <span className="flex flex-row mt-2">
          <Checkbox
            className="mx-2 mt-1.5"
            customStyles={{ accentColor: 'green' }}
            checked={isGifted}
            onChange={() => setIsGifted(!isGifted)}
            onClick={() => setIsGifted(!isGifted)}
          />
          <label className=""> Add meal as a gift - For free</label>
        </span>
        <div className="flex flex-row mx-auto">
          <Button
            primary
            disabled={!selectedAddon}
            className="w-40 mx-auto"
            content="ADD"
            onClick={() => handleAddAddonRequest(selectedAddon)}
          />
          <Button
            className="w-40 ml-4 bg-gray-200 text-gray-400"
            content="cancel"
            onClick={() => {
              addonModalRef.current?.close();
              setSelectedAddon(null);
            }}
          />
        </div>
      </div>
    </>
  );
};
export default AddonsPickerModal;
