import { RetailMenuFoodItem } from '@calo/dashboard-types';
import { FoodComponent } from '@calo/types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getListWithParams } from 'actions';
import { handleSearch } from 'lib/helpers';
import { uniqBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import Button from '../Button';
import SelectMUI from '../MUI/SelectMUI';
import Select from '../Select';

interface FoodModifiersProps {
  currentRetailItem: RetailMenuFoodItem;
  setCurrentRetailItem: React.Dispatch<React.SetStateAction<RetailMenuFoodItem | undefined>>;
  currentFood: any;
}

const FoodModifiers: React.FC<FoodModifiersProps> = ({ currentRetailItem, setCurrentRetailItem, currentFood }) => {
  const [currentComponentName, setCurrentComponentName] = useState<string>('salad');
  const foodComponentSample = {
    name: 'salad',
    country: 'BH',
    brand: 'CALO',
    kitchen: 'BH001'
  };

  const { data: foodComponentData, isLoading: isLoadingFoodComponents } = useQuery<any, Error>(
    [
      'food-components',
      {
        filters: {
          name: currentComponentName,
          country: foodComponentSample?.country,
          brand: foodComponentSample?.brand,
          kitchen: foodComponentSample?.kitchen
        },
        limit: 50
      }
    ],
    getListWithParams,
    {
      enabled: !!foodComponentSample?.name,
      keepPreviousData: true
    }
  );

  const componentOptions = useMemo(
    () =>
      foodComponentData?.data
        ? foodComponentData.data.map((component) => ({
            value: component.id,
            label: component.name.en
          }))
        : [],
    [foodComponentData]
  );

  const { data: foodComponentList } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        filters: {
          ids: currentFood?.components.map((comp) => comp.id) || []
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!currentFood?.components,
      keepPreviousData: true
    }
  );

  const handleAddModifier = () => {
    // @ts-ignore
    setCurrentRetailItem((prevItem) => {
      if (prevItem) {
        const newModifier = {
          name: { en: '', ar: '' },
          type: '',
          componentOptions: [],
          otherOptions: []
        };
        const updatedModifiers = [...(prevItem.foodModifiers || []), newModifier];
        return { ...prevItem, foodModifiers: updatedModifiers };
      }
      return prevItem;
    });
  };

  const handleRemoveModifier = (index: number) => {
    setCurrentRetailItem((prevItem) => {
      if (prevItem) {
        const updatedModifiers = prevItem.foodModifiers?.filter((_, modIndex) => modIndex !== index) || [];
        return { ...prevItem, foodModifiers: updatedModifiers };
      }
      return prevItem;
    });
  };

  const handleComponentOptionsChange = (selectedOptions, modifier, action) => {
    const restModifiers = currentRetailItem?.foodModifiers?.filter((mod) => mod.name.en !== modifier.name.en) || [];
    switch (action.action) {
      case 'select-option': {
        const newOptions = selectedOptions.map((option) => ({
          id: option.value,
          name: { en: option.label },
          quantity: 1 // Default quantity to 1
        }));
        const componentOptionsSelect = uniqBy([...modifier.componentOptions, ...newOptions], 'id');
        setCurrentRetailItem({
          ...currentRetailItem,
          foodModifiers: [
            ...restModifiers,
            {
              ...modifier,
              componentOptions: componentOptionsSelect
            }
          ]
        });
        break;
      }
      case 'remove-value': {
        const componentOptionsRemove = modifier.componentOptions.filter((option) => option.id !== action.removedValue.value);
        setCurrentRetailItem({
          ...currentRetailItem,
          foodModifiers: [
            ...restModifiers,
            {
              ...modifier,
              componentOptions: componentOptionsRemove
            }
          ]
        });
        break;
      }
      case 'clear': {
        setCurrentRetailItem({
          ...currentRetailItem,
          foodModifiers: [
            ...restModifiers,
            {
              ...modifier,
              componentOptions: []
            }
          ]
        });
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className="mb-3">
      <label className="label">Food Modifiers</label>
      {currentRetailItem?.foodModifiers && currentRetailItem.foodModifiers.length > 0 ? (
        currentRetailItem.foodModifiers.map((modifier, index) => (
          <div key={modifier.id || index} className="mb-3 pb-3 border-b border-gray-300">
            <div className="flex flex-row space-x-4">
              {/* Name EN Field */}
              <div className="flex-1">
                <label className="label">Name EN</label>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={modifier.name?.en || ''}
                  onChange={(e) => {
                    const updatedModifiers =
                      currentRetailItem?.foodModifiers?.map((mod, modIndex) =>
                        modIndex === index ? { ...mod, name: { ...mod.name, en: e.target.value } } : mod
                      ) || [];
                    setCurrentRetailItem({ ...currentRetailItem, foodModifiers: updatedModifiers });
                  }}
                />
              </div>
              {/* Name AR Field */}
              <div className="flex-1">
                <label className="label">Name AR</label>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={modifier.name?.ar || ''}
                  onChange={(e) => {
                    const updatedModifiers =
                      currentRetailItem?.foodModifiers?.map((mod, modIndex) =>
                        modIndex === index ? { ...mod, name: { ...mod.name, ar: e.target.value } } : mod
                      ) || [];
                    setCurrentRetailItem({ ...currentRetailItem, foodModifiers: updatedModifiers });
                  }}
                />
              </div>
              {/* Type Selector */}
              <div className="flex-1">
                <label className="label">Type</label>
                <Select
                  value={modifier.type || ''}
                  onChange={(data: any) => {
                    const updatedModifiers =
                      currentRetailItem?.foodModifiers?.map((mod, modIndex) =>
                        modIndex === index ? { ...mod, type: data.value } : mod
                      ) || [];
                    setCurrentRetailItem({ ...currentRetailItem, foodModifiers: updatedModifiers });
                  }}
                  options={[
                    { value: 'ADD', label: 'Add' },
                    { value: 'REPLACE', label: 'Replace' },
                    { value: 'OPTION', label: 'Option' }
                  ]}
                  styles={{
                    container: (provided: any) => ({
                      ...provided,
                      width: '100%'
                    })
                  }}
                />
              </div>
              {/* Remove Modifier Button */}
              <Button danger icon="fas fa-trash" onClick={() => handleRemoveModifier(index)} data-test="remove-modifier-button" />
            </div>
            {/* Conditional Rendering Based on Modifier Type */}
            {modifier.type === 'REPLACE' && !!currentFood && (
              <div className="field mt-3">
                <label className="label">Source Component ID</label>
                <Select
                  options={
                    foodComponentList?.data?.map((comp) => ({
                      value: comp.id,
                      label: comp.name?.en || ''
                    })) || []
                  }
                  value={modifier.sourceComponentID || null}
                  onChange={(selectedOption) => {
                    const updatedModifiers =
                      currentRetailItem?.foodModifiers?.map((mod, modIndex) =>
                        //@ts-ignore
                        modIndex === index ? { ...mod, sourceComponentID: selectedOption?.value || '' } : mod
                      ) || [];
                    setCurrentRetailItem({ ...currentRetailItem, foodModifiers: updatedModifiers });
                  }}
                  placeholder="Select source component"
                  isLoading={isLoadingFoodComponents}
                  isMulti={false}
                  styles={{
                    container: (provided: any) => ({
                      ...provided,
                      width: '80%',
                      maxHeight: '50px',
                      overflowY: 'auto'
                    })
                  }}
                />
              </div>
            )}
            {modifier.type === 'OPTION' && (
              <div className="field mt-3">
                <label className="label">Other Options</label>
                <Select
                  isMulti
                  options={[
                    { value: 'hot', label: 'Hot' },
                    { value: 'cold', label: 'Cold' }
                  ]}
                  value={modifier.otherOptions?.map((option) => ({ value: option, label: option })) || []}
                  onChange={(selectedOptions) => {
                    const updatedModifiers =
                      currentRetailItem?.foodModifiers?.map((mod, modIndex) =>
                        modIndex === index
                          ? {
                              ...mod,
                              //@ts-ignore
                              otherOptions: selectedOptions ? selectedOptions.map((option) => option.value) : []
                            }
                          : mod
                      ) || [];
                    setCurrentRetailItem({ ...currentRetailItem, foodModifiers: updatedModifiers });
                  }}
                />
              </div>
            )}
            {(modifier.type === 'ADD' || modifier.type === 'REPLACE') && (
              <>
                {/* Move componentInputSelector above the table */}
                <div className="field mt-3">
                  <label className="label">Select Component</label>
                  <SelectMUI
                    isMulti
                    data-test="selectComponentSelector"
                    placeholder="Select Component"
                    id="componentInputSelector"
                    options={componentOptions}
                    value={
                      modifier?.componentOptions
                        ?.filter((option): option is FoodComponent => typeof option !== 'string')
                        .map((option) => ({
                          value: option.id,
                          label: option.name?.en || ''
                        })) || []
                    }
                    isLoading={isLoadingFoodComponents}
                    onChange={(selectedOptions, action) => {
                      handleComponentOptionsChange(selectedOptions, modifier, action);
                    }}
                    onInputChange={(data: any, action: any) => {
                      handleSearch({
                        text: data,
                        action,
                        name: setCurrentComponentName
                      });
                    }}
                    isDisabled={false}
                  />
                </div>
                {/* Updated table with Component Name and Quantity */}
                <TableContainer component={Paper} className="mb-3">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Component Name</TableCell>
                        <TableCell>Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {modifier?.componentOptions
                        ?.filter((option): option is FoodComponent => typeof option !== 'string')
                        .map((option) => (
                          <TableRow key={option.id}>
                            <TableCell>{option.name?.en || ''}</TableCell>
                            <TableCell>
                              <TextField
                                type="number"
                                value={option.quantity || 1}
                                onChange={(e) => {
                                  const newQuantity = parseInt(e.target.value, 10) || 1;
                                  const updatedComponentOptions = (modifier.componentOptions ?? [])
                                    .filter((compOption): compOption is FoodComponent => typeof compOption !== 'string')
                                    .map((compOption) =>
                                      compOption.id === option.id ? { ...compOption, quantity: newQuantity } : compOption
                                    );
                                  const updatedModifiers =
                                    currentRetailItem?.foodModifiers?.map((mod) =>
                                      mod.name.en === modifier.name.en
                                        ? { ...mod, componentOptions: updatedComponentOptions }
                                        : mod
                                    ) || [];
                                  setCurrentRetailItem({
                                    ...currentRetailItem,
                                    foodModifiers: updatedModifiers
                                  });
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </div>
        ))
      ) : (
        <p>No modifiers added yet.</p>
      )}
      <Button primary icon="fas fa-plus" onClick={handleAddModifier} />
    </div>
  );
};

export default FoodModifiers;
