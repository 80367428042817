import { Permission } from '@calo/dashboard-types';
import { PhoneNumber } from 'components';
import { parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Link } from 'react-router-dom';

interface CouponUsageRowProps {
  couponUsage: any;
}

const CouponUsageRow = ({ couponUsage }: CouponUsageRowProps) => {
  const roles = useUserRoles();

  return (
    <tr key={couponUsage.userId}>
      <td>
        {roles.includes(Permission.VIEW_SUBSCRIPTION) ? (
          <Link to={Routes.subscription.replace(':id', couponUsage.userId)}>{couponUsage.name}</Link>
        ) : (
          couponUsage.name
        )}
      </td>
      <td>
        <PhoneNumber>{couponUsage.phoneNumber}</PhoneNumber>
      </td>
      <td>{couponUsage.email}</td>
      <td>{couponUsage.action}</td>
      <td>{couponUsage.usedAt && format('MMM dd-yyyy')(parseISO(couponUsage.usedAt))}</td>
      <td>{couponUsage.paymentAmount}</td>
    </tr>
  );
};

export default CouponUsageRow;
