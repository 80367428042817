import { ThemeProvider, createTheme } from '@mui/material';

const InputThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: '8px !important'
          }
        }
      }
    }
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default InputThemeProvider;
