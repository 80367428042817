import { FoodFilters, FoodStatus } from '@calo/dashboard-types';
import { AddonCategory, Brand, Country, FoodDietType, FoodType, Kitchen } from '@calo/types';

import { getListWithParams, toggleUISettings } from 'actions';
import { Input, Select, Select2, SideDrawer } from 'components';
import { getAccessibleCountries, getKitchenOptions, handleSearch } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { AppState, FoodComponent, Ingredient, Options } from 'lib/interfaces';
import { capitalize, isNull, sortBy } from 'lodash';
import { lowerCase, omit, startCase } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
interface SettingsProps {
  onFilter: (values: FoodFilters) => any;
  filters: FoodFilters;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const [allIngredients, setAllIngredients] = useState<Ingredient[]>([]);
  const [filterIngredients, setFilterIngredients] = useState<string>();
  const [allComponents, setAllComponents] = useState<FoodComponent[]>([]);
  const [filterComponents, setFilterComponents] = useState<string>();
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  const { isLoading: ingLoading } = useQuery<any, Error, { data: Ingredient[] }>(
    [
      'ingredients',
      {
        filters: {
          country: filters.country,
          brand: Brand.CALO,
          name: filterIngredients || undefined,
          kitchen: filters.kitchen || Kitchen.BH1,
          ids: !filterIngredients && filters.ingredientId ? filters.ingredientId : undefined
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!filterIngredients || !!filters.ingredientId,
      onSuccess: (allIngredientList) => {
        setAllIngredients(allIngredientList?.data);
      }
    }
  );

  const { isLoading: compLoading } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        filters: {
          country: filters.country,
          brand: filters.brand || Brand.CALO,
          kitchen: filters.kitchen || Kitchen.BH1,
          name: filterComponents || undefined,
          ids: !filterComponents && filters.componentId ? filters.componentId : undefined
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!filterComponents || !!filters.componentId,
      onSuccess: (allComponentList) => {
        setAllComponents((prev) => [...prev, ...(allComponentList?.data ?? [])]);
      }
    }
  );

  const { isLoading: prototypeComponentsLoading } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components/prototype',
      {
        filters: {
          country: filters.country,
          brand: filters.brand || Brand.CALO,
          kitchen: filters.kitchen || Kitchen.BH1,
          name: filterComponents || undefined,
          ids: !filterComponents && filters.componentId ? filters.componentId : undefined
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!filterComponents || !!filters.componentId,
      onSuccess: (allComponentList) => {
        setAllComponents((prev) => [...prev, ...(allComponentList?.data ?? [])]);
      }
    }
  );

  const ingredientOptions: Options<Ingredient>[] = useMemo(
    () =>
      sortBy(allIngredients, (ingredient) => `${ingredient.name.en}`).map((ingredient) => ({
        value: ingredient.id,
        data: { ...ingredient },
        label: `${ingredient.name.en} (${ingredient.internalName || ''})`
      })),
    [allIngredients]
  );

  const componentOptions: Options<FoodComponent>[] = useMemo(
    () =>
      sortBy(allComponents, (component) => `${component.name.en}`).map((component) => ({
        value: component.id,
        data: { ...component },
        label: component.name.en
      })),
    [allComponents]
  );

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Country"
          value={filters.country}
          onChange={(data) =>
            onFilter({
              ...filters,
              country: data.target.value === 'all' ? undefined : (data.target.value as Country),
              kitchen:
                data.target.value === 'all' ? undefined : (userKitchens.find((k) => k.includes(data.target.value)) as Kitchen)
            })
          }
          options={[
            { value: 'all', label: 'All' },
            ...getAccessibleCountries(userKitchens).map((c) => ({
              value: c,
              label: Country[c]
            }))
          ]}
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(data) => onFilter({ ...filters, kitchen: data.target.value as Kitchen })}
          options={getKitchenOptions(userKitchens, filters.country!)}
          disabled={Object.values(Kitchen).filter((r) => r.includes(filters.country!)).length === 0}
        />
        <Input
          label="Name"
          value={filters.name}
          data-test="nameFoodFilterInput"
          onChange={(e) => onFilter({ ...omit(filters, 'name'), ...(e.target.value && { name: e.target.value }) })}
          debounce
        />
        <Select
          label="Size"
          isMulti
          value={filters.sizes}
          onChange={(data: any) => onFilter({ ...filters, sizes: data.map((row) => row.value) })}
          options={Object.values(['xs', 's', 'm', 'l', 'xl', 'r', 'r2']).map((ft) => ({
            value: ft.toUpperCase(),
            label: ft.toUpperCase()
          }))}
        />
        <Select
          label="Ingredient"
          isClearable
          isLoading={ingLoading}
          value={filters.ingredientId}
          options={ingredientOptions}
          onChange={(ingredient: any) => {
            if (isNull(ingredient)) {
              onFilter({ ...filters, ingredientId: undefined });
            } else {
              onFilter({ ...filters, ingredientId: ingredient.data.id });
            }
          }}
          onInputChange={(data: string, action: any) => handleSearch({ text: data, action, name: setFilterIngredients })}
        />
        <Select
          label="Component"
          isClearable
          isLoading={compLoading || prototypeComponentsLoading}
          value={filters.componentId}
          options={componentOptions}
          onChange={(component: any) => {
            if (isNull(component)) {
              onFilter({ ...filters, componentId: undefined });
            } else {
              onFilter({ ...filters, componentId: component.data.id });
            }
          }}
          onInputChange={(data: string, action: any) => handleSearch({ text: data, action, name: setFilterComponents })}
        />
        <Select
          label="Diet"
          value={filters.dietType}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['dietType']),
              ...(v.value && {
                dietType: v.value
              })
            })
          }
          options={[
            {
              value: '',
              label: 'Any'
            },
            ...Object.values(FoodDietType).map((foodDietType) => ({
              value: foodDietType,
              label: startCase(lowerCase(foodDietType))
            }))
          ]}
        />

        <Select
          label="Type"
          value={filters.foodType}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['foodType']),
              ...(v.value && {
                foodType: v.value
              })
            })
          }
          options={[
            {
              value: '',
              label: 'Any'
            },
            ...Object.values(FoodType).map((ft) => ({
              value: ft,
              label: capitalize(ft)
            }))
          ]}
        />

        <Select
          label="Category"
          value={filters.foodTags?.addonCategory}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['foodTags']),
              ...(v.value && {
                foodTags: {
                  ...filters.foodTags,
                  addonCategory: v.value
                }
              })
            })
          }
          options={[
            {
              label: 'Any',
              value: null
            },
            ...Object.values(AddonCategory).map((addonCategory) => ({
              value: addonCategory,
              label: startCase(lowerCase(addonCategory))
            }))
          ]}
        />
        <Select
          label="Status"
          value={filters.status}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['status']),
              ...(v.value && {
                status: v.value
              })
            })
          }
          options={[
            {
              value: '',
              label: 'Any'
            },
            ...Object.values(FoodStatus).map((ft) => ({
              value: ft,
              label: capitalize(ft)
            }))
          ]}
        />
      </section>
    </SideDrawer>
  );
};

export default Settings;
