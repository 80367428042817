import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import { formatKitchenText, resolveCountry } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { FoodPackageElement } from 'lib/interfaces';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Stack, Typography } from '@mui/material';

import Container from './Container';

type PackageElementHeaderCardProps = {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  dirty: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  packageElement: FoodPackageElement;
};

const PackageElementHeaderCard = ({ dirty, isValid, isSubmitting, handleSubmit, ...props }: PackageElementHeaderCardProps) => {
  const userRoles = useUserRoles();

  return (
    <>
      <Container>
        <Stack
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                width: '100%'
              }
            }}
          >
            <Stack sx={{ marginLeft: '30px' }}>
              <Link to={Routes.packageElementList} style={{ color: 'black' }}>
                <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                  <ArrowBackIosIcon
                    style={{
                      fontSize: '14px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 'auto',
                      marginBottom: 'auto'
                    }}
                  />
                  <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>Back</Typography>
                </Stack>
              </Link>
            </Stack>
          </Stack>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              border: 'none',
              color: '#000000',
              textTransform: 'none',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                width: '100%',
                mt: 1
              }
            }}
          >
            {capitalize(props.packageElement.brand)} - {capitalize(resolveCountry(props.packageElement.country))} -{' '}
            {formatKitchenText(props.packageElement.kitchen)}
          </Typography>
        </Stack>
        <Stack
          sx={{
            marginX: 'auto',
            marginTop: '20px',
            marginBottom: '0px',
            width: '100%',
            height: '1px',
            backgroundColor: caloTheme.palette.neutral100
          }}
        />
      </Container>
      <Container sx={{ marginTop: '-5px', paddingTop: '5px', position: 'sticky', top: 0, zIndex: 500 }}>
        <Stack
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>{props.packageElement.name}</Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
            <Button
              variant="contained"
              disabled={!dirty || !isValid || isSubmitting || !userRoles.includes(Permission.UPDATE_FOOD_PACKAGE_ELEMENT)}
              sx={{
                textTransform: 'none',
                fontWeight: 600,
                fontSize: '16px',
                borderRadius: '8px',
                backgroundColor: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary500,
                boxShadow: 'none',
                color: 'white',
                '&:hover': {
                  boxShadow: 'none',
                  backgroundColor: caloTheme.palette.primary600,
                  borderColor: caloTheme.palette.primary600
                }
              }}
              onClick={() => handleSubmit()}
            >
              Save Changes
            </Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default PackageElementHeaderCard;
