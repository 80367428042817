import { GetWaitingListReqFilters, SortingWaitingList } from '@calo/dashboard-types';
import { WaitingListType } from '@calo/types';
import { getListWithParams, removeUserFromWaitingList, sendInvite } from 'actions';
import mutation from 'actions/mutation';
import { Icon, Pagination } from 'components';
import { Kitchen } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import WaitingRow from './WaitingRow';

interface WaitingListProps {
  kitchen: Kitchen;
  selected: WaitingListType;
  isAllowed: boolean;
  filters: GetWaitingListReqFilters;
}

interface PaginatedWaitingList {
  data: any[];
  meta: {
    limit: number;
    total: number;
  };
}

const WaitingList = ({ kitchen, selected, isAllowed, filters }: WaitingListProps) => {
  const [page, setPage] = useState(0);
  const { mutateAsync: deleteMutation } = useMutation(removeUserFromWaitingList);
  const { mutateAsync: sendInviteMutation } = useMutation(sendInvite);

  const [sort, setSort] = useState<SortingWaitingList>({
    orderBy: 'joinedAt',
    orderMode: 'desc'
  });

  const { data: waitingList, isLoading } = useQuery<any, Error, PaginatedWaitingList>(
    ['/waiting-list', { kitchenId: kitchen.id, displayType: 'list', type: selected, filters, sort, page, limit: 25 }],
    getListWithParams,
    {
      keepPreviousData: true,
      suspense: false
    }
  );

  const getFilteredList = () => {
    let waitingListArr;
    if (selected === WaitingListType.SUPPLY_CAP) {
      waitingListArr = waitingList?.data.filter((data) =>
        data.type === selected && isAllowed ? data.isAllowed : !data.isAllowed
      );
    } else {
      waitingListArr = waitingList?.data.filter((data) => data.type === selected);
    }
    return waitingListArr || [];
  };

  useEffect(() => {
    setPage(0);
    setSort({ ...sort, orderBy: sort.orderBy, orderMode: sort.orderMode });
  }, [selected]);

  const handleDeleteUser = async (id: string) => {
    await deleteMutation(
      { id },
      {
        onSuccess: () => {
          const result = queryClient.getQueryData([
            '/waiting-list',
            { kitchenId: kitchen.id, displayType: 'list', type: WaitingListType.PRE_LAUNCH, sort, filters, page, limit: 25 }
          ]) as any;
          const index = result.data.findIndex((i: any) => i.id === id);
          result.data.splice(index, 1);
          queryClient.setQueryData(
            [
              '/waiting-list',
              { kitchenId: kitchen.id, displayType: 'list', type: WaitingListType.PRE_LAUNCH, sort, filters, page, limit: 25 }
            ],
            result
          );
          const stats = queryClient.getQueryData(['stats', 'kitchens', kitchen.id]) as any;
          mutation(['stats', 'kitchens', kitchen.id], { ...stats, preLaunchCount: stats.preLaunchCount - 1 });
        }
      }
    );
  };

  const handleInviteUser = async (userId: string) => {
    await sendInviteMutation(
      { id: userId, sendSms: { sendSms: true } },
      {
        onSuccess: () => {
          const result = queryClient.getQueryData([
            '/waiting-list',
            { kitchenId: kitchen.id, displayType: 'list', type: WaitingListType.PRE_LAUNCH, sort, filters, page, limit: 25 }
          ]) as any;
          const index = result.data.findIndex((i: any) => i.id === userId);
          result.data.splice(index, 1);
          queryClient.setQueryData(
            [
              '/waiting-list',
              { kitchenId: kitchen.id, displayType: 'list', type: WaitingListType.PRE_LAUNCH, sort, filters, page, limit: 25 }
            ],
            result
          );
          mutation(['/waiting-list', { kitchenId: kitchen.id, displayType: 'list', type: WaitingListType.PRE_LAUNCH }], result);
          const stats = queryClient.getQueryData(['stats', 'kitchens', kitchen.id]) as any;
          mutation(['stats', 'kitchens', kitchen.id], { ...stats, preLaunchCount: stats.preLaunchCount - 1 });
        }
      }
    );
  };
  return (
    <>
      <section>
        <div className="card has-table has-table-container-upper-radius">
          <div className="card-content">
            <div className="table-container overflow-y-auto">
              <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                <thead>
                  <tr className="items-center justify-between bg-black">
                    <th style={{ color: 'white' }}>Name</th>
                    <th style={{ color: 'white' }}>Phone</th>
                    <th style={{ color: 'white' }}>Email</th>
                    <th style={{ color: 'white' }}>
                      <span className="flex">
                        <p>Joined At</p>
                        <Icon
                          name="sort"
                          size={4}
                          onClick={() =>
                            setSort({ ...sort, orderBy: 'joinedAt', orderMode: sort.orderMode === 'asc' ? 'desc' : 'asc' })
                          }
                          className="ml-1 mt-1 cursor-pointer"
                        />
                      </span>
                    </th>
                    {selected === WaitingListType.SOFT_LAUNCH && <th></th>}
                    {selected === WaitingListType.SUPPLY_CAP && !isAllowed && <th style={{ color: 'white' }}>Action</th>}
                    {(selected === WaitingListType.SUPPLY_CAP || isAllowed) && <th style={{ color: 'white' }}>Country</th>}

                    {selected === WaitingListType.SUPPLY_CAP && !isAllowed ? (
                      <>
                        <th></th>
                        <th></th>
                      </>
                    ) : (
                      <th></th>
                    )}
                  </tr>
                </thead>
                {getFilteredList() && getFilteredList().length === 0 && !isLoading ? (
                  <span className="absolute w-full text-3xl mt-4 text-center font-bold text-gray-400 ">NO DATA</span>
                ) : (
                  <tbody>
                    {(getFilteredList() || []).map((user, index) => (
                      <WaitingRow
                        key={`${user.id}-${index}`}
                        user={user}
                        kitchenId={kitchen.id}
                        selectedType={selected}
                        isAllowed={isAllowed}
                        handleDeleteUser={(value) => handleDeleteUser(value)}
                        handleInviteUser={(value) => handleInviteUser(value)}
                      />
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        {waitingList && <Pagination limit={25} total={waitingList.meta.total} page={page} onChange={setPage} />}
      </section>
    </>
  );
};

export default WaitingList;
