import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { Timeslot as TimeSlotType } from '../../types';

interface TimeSlotProps {
  day: string;
  timeslot: TimeSlotType;
  timeSlotIndex: number;
  onTimeSlotChange: (day: string, index: number, field: 'start' | 'end', value: string) => void;
  onManagerChange: (day: string, timeSlotIndex: number, managerIndex: number, value: string) => void;
  onRemoveTimeSlot: (day: string, index: number) => void;
  onAddManager: (day: string, timeSlotIndex: number) => void;
  onRemoveManager: (day: string, timeSlotIndex: number, managerIndex: number) => void;
}

const TimeSlot = ({
  day,
  timeslot,
  timeSlotIndex,
  onTimeSlotChange,
  onManagerChange,
  onRemoveTimeSlot,
  onAddManager,
  onRemoveManager
}: TimeSlotProps) => (
  <Box>
    <Box display="flex" alignItems="center">
      <TextField
        label="Start"
        type="time"
        value={timeslot.start}
        onChange={(e) => onTimeSlotChange(day, timeSlotIndex, 'start', e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: 8, marginTop: 16 }}
      />
      <TextField
        label="End"
        type="time"
        value={timeslot.end}
        onChange={(e) => onTimeSlotChange(day, timeSlotIndex, 'end', e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: 8, marginTop: 16 }}
      />
      <IconButton onClick={() => onRemoveTimeSlot(day, timeSlotIndex)}>
        <DeleteIcon />
      </IconButton>
    </Box>
    <Box>
      {timeslot.managers.map((manager, managerIndex) => (
        <Box key={managerIndex} display="flex" alignItems="center" marginTop={1}>
          <TextField
            label={`Manager ${managerIndex + 1}`}
            value={manager}
            onChange={(e) => onManagerChange(day, timeSlotIndex, managerIndex, e.target.value)}
            fullWidth
            style={{ marginRight: 8, marginTop: 16 }}
          />
          <IconButton onClick={() => onRemoveManager(day, timeSlotIndex, managerIndex)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button startIcon={<AddIcon />} onClick={() => onAddManager(day, timeSlotIndex)} style={{ marginTop: 8 }}>
        Add Manager
      </Button>
    </Box>
  </Box>
);

export default TimeSlot;
