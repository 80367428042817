import { useState } from 'react';

import { getListWithParams } from 'actions';
import { CaloLoader } from 'components';
import { resolveCountryFromKitchen } from 'lib';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { DeliveryFilters, Permission } from '@calo/dashboard-types';
import { DeliveryTime, Kitchen } from '@calo/types';
import { format } from 'date-fns/fp';
import CoolerBagsHeaderCard from './CoolerBagsHeaderCard/CoolerBagsHeaderCard';
import CoolerBagsListCard from './CoolerBagsListCard/CoolerBagsListCard';
import CoolerBagsTotalsCard from './CoolerBagsTotalsCard/CoolerBagsTotalsCard';
import Settings from './Settings/Settings';

export interface DriverCoolerBagStats {
  driverId: string;
  driverName: string;
  deliveriesWithCoolerBag: number;
  totalCoolerBagsReturned: number;
  damagedCoolerBags: number;
  routePlanId?: string;
}

export interface CoolerBagStats {
  data: {
    [driverId: string]: DriverCoolerBagStats;
  };
}

const CoolerBags = () => {
  const roles = useUserRoles();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userKitchen = useUserKitchens();

  const [filters, setFilters] = useState<DeliveryFilters>({
    driverId: '',
    day: {
      lte: format('yyyy-MM-dd')(Date.now()),
      gte: format('yyyy-MM-dd')(Date.now())
    },
    country: resolveCountryFromKitchen((userKitchen && userKitchen && userKitchen[0]) || Kitchen.BH1),
    deliveryTime: DeliveryTime.morning,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const [selectedDate, setSelectedDate] = useState<string>(filters?.day.gte);

  const {
    data: coolerBagTotals,
    isLoading: coolerBagTotalsLoading,
    refetch
  } = useQuery<any, Error, { data: any }>(
    [
      'stats/cutleries_and_cooler_bags',
      {
        day: filters.day,
        country: filters.country,
        kitchen: filters.kitchen,
        deliveryTime: filters.deliveryTime,
        driverId: filters.driverId,
        coolerBagsStats: true
      }
    ],
    getListWithParams,
    {
      enabled: !!roles.includes(Permission.VIEW_CUTLERY_AND_COOLER_BAG_STATS)
    }
  );

  const { data: driversList, isLoading: driversLoading } = useQuery(
    ['/map/drivers', { country: filters.country }],
    getListWithParams,
    {
      retry: false,
      onError: console.error
    }
  );

  const { data, isLoading } = useQuery<any, Error, CoolerBagStats>(
    ['drivers/cooler-bags', { ...filters, day: { gte: selectedDate, lte: selectedDate } }],
    getListWithParams,
    {
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  const isDisabled = false;
  return (
    <>
      <CoolerBagsHeaderCard filters={filters} isDisabled={isDisabled} isLoading={isLoading} coolerBagsStats={data} />
      {coolerBagTotalsLoading ? (
        <CaloLoader />
      ) : (
        <CoolerBagsTotalsCard
          coolerBagTotals={coolerBagTotals?.data}
          isLoading={coolerBagTotalsLoading}
          filters={filters}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )}
      {isLoading ? (
        <CaloLoader />
      ) : (
        <CoolerBagsListCard coolerBagsStats={data} isLoading={isLoading} selectedDate={selectedDate} refetchTotals={refetch} />
      )}
      <Settings
        onFilter={setFilters}
        filters={filters}
        //@ts-ignore
        drivers={driversLoading ? [] : driversList?.data || []}
      />
    </>
  );
};

export default CoolerBags;
