import { WalletReason } from '@calo/types';

export const walletSubReasonsHelper = {
  getOptions: () => ({
    [WalletReason.COMPENSATION]: [
      { value: 'COMPENSATION_LOGISTIC_ERROR', label: 'Logistic error' },
      { value: 'COMPENSATION_FORIGN_OBJECT', label: 'Foreign object' },
      { value: 'COMPENSATION_MEAL_QUALITY', label: 'Quality of the meal' },
      { value: 'COMPENSATION_SNACK_QUALITY', label: 'Quality of the snack' },
      { value: 'COMPENSATION_PACKAGING', label: 'Packaging' },
      { value: 'COMPENSATION_DISPATCH_ERROR', label: 'Dispatch error' },
      { value: 'COMPENSATION_SPILLED_LIQUIDS', label: 'Spilled liquids' },
      { value: 'COMPENSATION_AGENT_MISTAKE', label: 'Agent mistake' },
      { value: 'Other', label: 'Other' }
    ],
    [WalletReason.CX_GIFT]: [
      {
        value: 'COMPENSATION_PERSONAL_PREFERENCE',
        label: 'Personal preference'
      },
      {
        value: 'COMPENSATION_MEALS_NOT_SELECTED',
        label: 'Did not select the meals'
      },
      { value: 'COMPENSATION_MEALS_NOT_FOUND', label: 'Meals not found' },
      { value: 'COMPENSATION_CUSTOME_ERROR', label: "Customer's error" },
      { value: 'COMPENSATION_GIFT', label: 'Gift' },
      { value: 'Other', label: 'Other' }
    ]
  }),
  getObject: () => ({
    [WalletReason.COMPENSATION]: {
      COMPENSATION_LOGISTIC_ERROR: 'Logistic error',
      COMPENSATION_FORIGN_OBJECT: 'Foreign object',
      COMPENSATION_MEAL_QUALITY: 'Quality of the meal',
      COMPENSATION_SNACK_QUALITY: 'Quality of the snack',
      COMPENSATION_PACKAGING: 'Packaging',
      COMPENSATION_DISPATCH_ERROR: 'Dispatch error',
      COMPENSATION_SPILLED_LIQUIDS: 'Spilled liquids',
      COMPENSATION_AGENT_MISTAKE: 'Agent mistake',
      Other: 'Other'
    },
    [WalletReason.CX_GIFT]: {
      COMPENSATION_PERSONAL_PREFERENCE: 'Personal preference',
      COMPENSATION_MEALS_NOT_SELECTED: 'Did not select the meals',
      COMPENSATION_MEALS_NOT_FOUND: 'Meals not found',
      COMPENSATION_CUSTOME_ERROR: "Customer's error",
      COMPENSATION_GIFT: 'Gift',
      Other: 'Other'
    }
  })
};
