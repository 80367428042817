import { loyaltyTransactionLogStatus } from '../enums';
import { LoyaltyTransaction } from '../interfaces';

export const resolveLoyaltyTransactionLogStatus = (type: string) => {
  switch (type) {
    case 'added':
      return loyaltyTransactionLogStatus.added;
    case 'expired':
      return loyaltyTransactionLogStatus.expired;
    case 'added_by_calo':
      return loyaltyTransactionLogStatus.added_by_calo;
    case 'redeemed':
      return loyaltyTransactionLogStatus.redeemed;
  }
};

export const resolveLoyaltyTransactionLogSource = (type: string) => {
  switch (type) {
    case 'added':
    case 'expired':
    case 'redeemed':
      return 'online';
    case 'added_by_calo':
      return 'offline';
  }
};

export const resolveLoyaltyTransactionLogReason = (row: LoyaltyTransaction) => {
  switch (row.type) {
    case 'added':
      return 'Payment made';
    case 'expired':
      return '-';
    case 'added_by_calo':
      return 'Manual points added';
    case 'redeemed':
      return row.reward && row.reward.description ? row.reward.description : 'Claimed';
  }
};
