import { ListComplaintsFilters, Permission } from '@calo/dashboard-types';
import { getListWithParams } from 'actions';
import { useUserRoles } from 'lib/hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';

interface PaginatedComplaints {
  data: any[];
  meta: {
    limit: number;
    total: number;
  };
}

interface useComplaintsProps {
  filters: ListComplaintsFilters;
  page: number;
  setPage: (value: number) => void;
}
const useComplaints = ({ filters, page }: useComplaintsProps) => {
  const roles = useUserRoles();

  const [complaints, setComplaints] = useState<PaginatedComplaints>();

  const { data, refetch, isLoading } = useQuery<any, Error, PaginatedComplaints>(
    [
      'complaints',
      {
        page,
        limit: 15,
        filters: {
          ...filters,
          category: filters.category || filters.category !== 'Any' ? filters.category : undefined,
          type: filters.type || filters.type !== 'Any' ? filters.type : undefined
        }
      }
    ],
    getListWithParams,
    {
      suspense: false,
      keepPreviousData: false,
      enabled: !!roles.includes(Permission.VIEW_RATING_LIST),
      onSuccess: (data) => {
        if (data) {
          setComplaints(data);
        }
      }
    }
  );

  return {
    complaintsLoading: isLoading,
    complaints,
    data,
    refetchComplaints: refetch
  };
};

export default useComplaints;
