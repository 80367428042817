import ExcelJS from 'exceljs';
import { Food, FoodComponent, Ingredient } from 'lib/interfaces';
import { uniqBy } from 'lodash';
import {
  addCellBorderToFilledCells,
  addEmptyRow,
  addLabelValueRow,
  addTableHeaderRow,
  clearRows,
  removeRowBorders
} from './excelUtils';

const createIngredientsWorksheet = (workbook: ExcelJS.Workbook, selectedFood: Food, selectedFoodComponents: FoodComponent[]) => {
  const worksheet = workbook.addWorksheet('ingredients');

  worksheet.getColumn(2).width = 15;
  worksheet.getColumn(3).width = 10;
  worksheet.getColumn(4).width = 40;
  worksheet.getColumn(5).width = 15;

  const colCount = 6;
  let rowIndex = 1;

  rowIndex = clearRows(worksheet, 1, 4, colCount);

  addLabelValueRow(worksheet, rowIndex, colCount, 'KITCHEN', selectedFood.kitchen);
  rowIndex++;
  addLabelValueRow(worksheet, rowIndex, colCount, 'MEAL NAME', selectedFood.name.en);
  rowIndex++;
  addEmptyRow(worksheet, rowIndex, colCount);
  rowIndex++;
  addTableHeaderRow(worksheet, rowIndex, ['', '', 'BASE UNIT', 'INGREDIENT', 'UNIT COST', '']);
  rowIndex++;
  const ingredients = uniqBy(
    selectedFoodComponents.flatMap((comp) => comp.ingredients),
    'name.en'
  );
  addIngredientsRows(worksheet, rowIndex, colCount, ingredients);
};

const addIngredientsRows = (worksheet: ExcelJS.Worksheet, rowIndex: number, colCount: number, ingredients: Ingredient[]) => {
  for (const ingredient of ingredients) {
    const ingredientRow = worksheet.getRow(rowIndex);
    ingredientRow.height = 15;
    ingredientRow.values = ['', '', ingredient.measurementUnit, ingredient.name.en, ingredient.cost, ''];
    ingredientRow.getCell(3).alignment = { horizontal: 'center' };
    ingredientRow.getCell(5).alignment = { horizontal: 'left' };

    removeRowBorders(ingredientRow);
    addCellBorderToFilledCells(ingredientRow, colCount);
    rowIndex++;
  }
};

export default createIngredientsWorksheet;
