import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import styles from './styles';

interface ChipComponentProps {
  label: string;
  more: boolean;
  keyProp: string;
  styleOverrides: any;
  onDelete?: () => void;
  onClick?: () => void;
}

const ChipComponent = ({ keyProp, label, onDelete, onClick, more, styleOverrides }: ChipComponentProps) => {
  const handleClick = () => {
    if (more && onClick) {
      onClick();
    }
  };
  const deleteIcon = onDelete && onDelete !== undefined ? <CloseIcon /> : undefined;
  const sx = {
    ...styles.chips,
    cursor: more ? 'pointer' : 'default',
    ...styleOverrides
  };

  return (
    <Chip
      sx={sx}
      key={keyProp}
      label={label}
      size="medium"
      variant="outlined"
      deleteIcon={deleteIcon}
      onDelete={onDelete}
      onClick={handleClick}
    />
  );
};

export default ChipComponent;
