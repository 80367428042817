import { Ingredient, IngredientCategory } from '@calo/types';
import { isEmpty, omit, startCase, uniq } from 'lodash-es';

export const getIngredientsAllergens = (ingredients: Ingredient[]) => {
  return uniq(ingredients.flatMap((ing) => ing.category ?? []).filter((cat) => cat !== IngredientCategory.other)).map((cat) =>
    startCase(cat)
  );
};

export const hasAllergen = (ingredient: Ingredient) => {
  if (
    !isEmpty(ingredient.category) &&
    ingredient.category.some((cat) => Object.values(omit(IngredientCategory, IngredientCategory.other)).includes(cat))
  ) {
    return true;
  }
  return false;
};
