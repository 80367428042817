import { CouponCategory } from '@calo/dashboard-types';
import cx from 'classnames';
import { Icon } from 'components';

interface CouponStatsProps {
  selected: CouponCategory;
  setSelected: (value: CouponCategory) => void;
  promo: number;
  referrals: number;
}

const CouponStats = ({ selected, setSelected, promo, referrals }: CouponStatsProps) => {
  console.log(selected);
  // const { data } = useQuery(['stats', 'coupons'], getRecord);

  return (
    <div className="level mt-6">
      <div className="level-left">
        <div
          className={cx(
            ' cursor-pointer flex opacity-80 rounded-2xl mr-8 w-52 h-28 border-l-8 border-white hover:border-yellow-500',
            {
              'border-l-8 border-yellow-500 opacity-100': selected === CouponCategory.promo
            }
          )}
        >
          <div
            className="card tile rounded-2xl"
            onClick={() => {
              setSelected(CouponCategory.promo);
            }}
          >
            <div className="card-content -mt-4">
              <div className="is-widget-label">
                <Icon className="relative ml-32 mt-1" size={8} name="promo" />
                <p className="text-black text-xl -mt-7 mb-2">Promo</p>
                <p className="text-yellow-500 text-4xl ">{promo}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={cx(
            ' cursor-pointer flex opacity-80 rounded-2xl mr-8 w-52 h-28 border-l-8 border-white hover:border-blue-500',
            {
              'border-l-8 border-blue-500 opacity-100': selected === CouponCategory.referral
            }
          )}
        >
          <div className="card tile rounded-2xl" onClick={() => setSelected(CouponCategory.referral)}>
            <div className="card-content -mt-4">
              <div className="is-widget-label">
                <Icon className="relative ml-32 text-blue-500" size={8} name="referrals" />
                <p className="text-black text-xl -mt-6 mb-2">Referrals</p>
                <p className="text-blue-500 text-4xl">{referrals}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CouponStats;
