import { useEffect, useRef, useState } from 'react';

import { differenceInWeeks, parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import { ceil, intersection, sortBy, uniqBy } from 'lodash-es';

import { FoodTagsKeys } from '@calo/dashboard-types';
import { Brand, FoodMenuLabelsTags, FoodType, Kitchen } from '@calo/types';
import { Icon } from '@iconify/react';
import { Box, Card, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { formatNumber, getLabelToAdd, handleMealCost, mapDietTypeToTag } from 'lib/helpers';
import { MenuDraft, MenuFood, UsedOnMenuReq } from 'lib/interfaces';
import CaloLoader from '../CaloLoader';
import MenuMealInfo from '../MenuMealInfo';
import { ModalRef } from '../Modal';
import Popup from '../Popup';
import MenuTableCard from './MenuTableCard';
import MenuTableHeader from './MenuTableHeader';

interface MenuTableProps {
  brand: Brand;
  selectedDate: string;
  foodLabel?: any;
  kitchen: Kitchen;
  foodList: MenuFood[];
  isEditable?: boolean;
  removeFood?: (ids: string[]) => void;
  setReplaceFood: (value: { open: boolean; name: string }) => void;
  draftTrack: MenuDraft | undefined;
  isMealsLoading?: boolean;
}

export interface MenuOptionsData {
  type: string;
  number: number;
}

export interface MenuOptions {
  tag: FoodTagsKeys;
  children: MenuOptionsData[];
}

export const handleCalculateLastTime = (food: any) => {
  if (!food.lastUsedOnMenu.date) return;
  if (format('yyyy-MM-dd')(parseISO(food.lastUsedOnMenu.date)) > format('yyyy-MM-dd')(+Date.now())) {
    return (
      <Typography
        variant="h4"
        sx={{
          mt: '4px',
          fontFamily: caloTheme.typography.fontFamily,
          fontSize: '14px',
          lineHeight: '17px',
          color: caloTheme.palette.neutral900
        }}
      >
        Last used {food.lastUsedOnMenu.date}
      </Typography>
    );
  } else {
    return (
      <Typography
        variant="h4"
        sx={{
          mt: '4px',
          fontFamily: caloTheme.typography.fontFamily,
          fontSize: '14px',
          lineHeight: '17px',
          color: caloTheme.palette.neutral900
        }}
      >
        Last used {differenceInWeeks(Date.now(), parseISO(food.lastUsedOnMenu))} weeks ago
      </Typography>
    );
  }
};

const isWithinRange = (date: Date, start: Date, end: Date) => date >= start && date <= end;
const isMealUsedInWeekRange = (lastUsedDate?: UsedOnMenuReq): boolean => {
  if (!lastUsedDate?.date) {
    return false;
  }
  const lastUsedMenuDate = new Date(lastUsedDate.date);
  const currentDate = new Date();

  const currentWeekStart = new Date(currentDate);
  currentWeekStart.setDate(currentDate.getDate() - currentDate.getDay());

  const lastWeekStart = new Date(currentWeekStart);
  lastWeekStart.setDate(currentWeekStart.getDate() - 7);

  const nextWeekStart = new Date(currentWeekStart);
  nextWeekStart.setDate(currentWeekStart.getDate() + 7);

  const weekAfterNextStart = new Date(currentWeekStart);
  weekAfterNextStart.setDate(currentWeekStart.getDate() + 14);

  return (
    isWithinRange(lastUsedMenuDate, lastWeekStart, currentWeekStart) ||
    isWithinRange(lastUsedMenuDate, currentWeekStart, nextWeekStart) ||
    isWithinRange(lastUsedMenuDate, nextWeekStart, weekAfterNextStart)
  );
};

const checkIfTopRatedFood = (food: MenuFood, date: string) => {
  const menuDay = new Date(date).getTime();
  return (
    food.menuLabels &&
    food.menuLabels.some(
      (tag: any) => tag.label === FoodMenuLabelsTags.TOP_RATED && getLabelToAdd(menuDay, food.menuLabels ?? [])
    )
  );
};

const MenuTable = ({
  draftTrack,
  isMealsLoading,
  kitchen,
  foodList,
  isEditable = true,
  removeFood,
  foodLabel,
  brand,
  selectedDate,
  setReplaceFood
}: MenuTableProps) => {
  const [filterKey, setFilterKey] = useState<any | null>(null);
  const [isFixedOrRotational, setIsFixedOrRotational] = useState<any | null>(null);
  const [foodType, setFoodType] = useState<any | null>(null);

  const handleButtonClick = (key: any, value: FoodType, mealRatingType: any) => {
    setFilterKey(key);
    setIsFixedOrRotational(mealRatingType);
    setFoodType(value);
  };

  const infoMealRef = useRef<ModalRef>();
  const [menuCost, setMenuCost] = useState<number>(0);
  const [headerOpen, setHederOpen] = useState<FoodType | undefined>();
  const [selectedMeal, setSelectedMeal] = useState<any | undefined>();
  const filteredList = foodList.filter((food) => ['XS', 'S', 'M', 'L'].includes(food.size));
  const sortedList = sortBy(filteredList, (f) => `${f.name.en}-${f.size}`);
  const groupByName = uniqBy(sortedList, 'name.en');

  const [purchasingCostFood, setPurchasingCostFood] = useState<number>(0);

  const handleSelectMealInfo = (food: any) => {
    setSelectedMeal(food);
    infoMealRef.current?.open();
  };

  const handleFoodSizes = (foodName: string) => {
    const mealSize = sortedList.filter((food) => food.name.en === foodName);
    return mealSize.map((food, index) => (
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '17px',
          mt: '3px'
        }}
      >
        {`${food.size} ${index === mealSize.length - 1 ? '' : '-'} `}
      </Typography>
    ));
  };

  const handleRemoveAllSizes = (food: MenuFood) => {
    const removedFood = sortedList.filter((foodList) => foodList.name.en === food.name.en);
    removeFood!(removedFood.map((r) => r.id));
  };

  const calculateMenuCost = () => {
    let cost = 0;
    let numberOfMeals = 0;
    for (const food of groupByName) {
      if (
        food.type.includes(FoodType.breakfast) ||
        food.type.includes(FoodType.lunch) ||
        food.type.includes(FoodType.dinner) ||
        food.type.includes(FoodType.snack)
      ) {
        const foodCost = handleMealCost(food.name.en, sortedList, kitchen);
        cost += foodCost;
        numberOfMeals += 1;
      }
    }
    setMenuCost(ceil(+formatNumber(cost) / numberOfMeals));
  };

  const foodData = (food: MenuFood) => {
    const foodCost = isEditable ? handleMealCost(food.name.en, sortedList, kitchen) : 0;
    const MenuTableCardProps = createMenuTableCardProps(food, foodCost);
    const isTopRatedFood = checkIfTopRatedFood(food, selectedDate);

    if (!filterKey) {
      return <MenuTableCard {...MenuTableCardProps} />;
    }

    if (!food.type.includes(foodType)) {
      return <MenuTableCard {...MenuTableCardProps} />;
    }

    const dietTag = mapDietTypeToTag(filterKey);
    const hasDietTag = food.tags?.includes(dietTag);
    const isVegetarianConditionMet = filterKey === 'vegetarian' && food.tags?.length === 1 && hasDietTag;

    if (!isFixedOrRotational && (isVegetarianConditionMet || (hasDietTag && filterKey !== 'vegetarian'))) {
      return <MenuTableCard {...MenuTableCardProps} />;
    }

    if (
      isFixedOrRotational === 'fixed' &&
      hasDietTag &&
      (filterKey === 'balanced' || filterKey === 'lowCarb') &&
      isTopRatedFood
    ) {
      return <MenuTableCard {...MenuTableCardProps} />;
    }

    if (
      isFixedOrRotational !== 'fixed' &&
      hasDietTag &&
      (filterKey === 'balanced' || filterKey === 'lowCarb') &&
      !isTopRatedFood
    ) {
      return <MenuTableCard {...MenuTableCardProps} />;
    }

    return null;
  };

  function createMenuTableCardProps(food: MenuFood, foodCost: any) {
    return {
      food,
      brand,
      key: food.id,
      foodCost,
      foodLabel,
      removeFood,
      isEditable,
      setReplaceFood,
      handleRemoveAllSizes,
      handleSelectMealInfo,
      isMealUsedInWeekRange
    };
  }

  const calculateMenuPurchasingCost = () => {
    let cost = 0;
    let numberOfMeals = 0;
    for (const food of sortedList) {
      const foodCost = food.purchasingCost || 0;
      cost += foodCost;
      if (intersection(food.type, [FoodType.breakfast, FoodType.lunch, FoodType.dinner, FoodType.snack]).length > 0) {
        numberOfMeals++;
      }
    }

    setPurchasingCostFood(+formatNumber(cost / numberOfMeals));
  };

  useEffect(() => {
    if (isEditable) {
      calculateMenuCost();
      calculateMenuPurchasingCost();
    }
  }, [groupByName]);

  function handleFilterClose(key: string, value: FoodType, k: string): void {
    setFilterKey(key);
    setIsFixedOrRotational(k);
    setFoodType(value);
  }

  return (
    <>
      {sortedList && isEditable && (
        <Stack direction={'row'} justifyContent={'start'}>
          <Typography
            sx={{
              mr: 2,
              mt: '2px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '24px',
              color: caloTheme.palette.secondaryPurple900
            }}
            variant={'h1'}
          >
            {`${uniqBy(sortedList, 'name.en').length} Meals`}
          </Typography>
          <Typography
            sx={{
              backgroundColor: caloTheme.palette.neutral50,
              borderRadius: '37px',
              padding: '8px 12px 8px 12px',
              mr: 1,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '17px',
              color: caloTheme.palette.neutral700
            }}
            variant={'h1'}
          >
            {`Menu Cost: ${menuCost || 0}%`}
          </Typography>
          <Typography
            sx={{
              backgroundColor: caloTheme.palette.neutral50,
              borderRadius: '37px',
              padding: '8px 12px 8px 12px',
              mr: 1,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '17px',
              color: caloTheme.palette.neutral700
            }}
            variant={'h1'}
          >
            {`Menu Purchasing Cost: ${purchasingCostFood}%`}
          </Typography>
        </Stack>
      )}
      {isMealsLoading ? (
        <CaloLoader />
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            mt: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
        >
          {Object.entries(FoodType)
            .filter(([_, v]) => v !== FoodType.coffee && v !== FoodType.juice)
            .map(
              ([key, value]) =>
                value !== FoodType.dinner &&
                value !== FoodType.coffee && (
                  <Box
                    key={`${key}-${value}`}
                    sx={{
                      width: '25%',
                      mx: 'auto',
                      mr: 4,
                      [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                        flexDirection: 'column',
                        width: '96%'
                        // mb: 2,
                      }
                    }}
                  >
                    <header
                      key={`${key}-${value}`}
                      className="card-header-title  justify-between rounded-md"
                      style={{
                        height: value === headerOpen ? 'auto' : '',
                        backgroundColor: caloTheme.palette.neutral50,
                        color: caloTheme.palette.primary900,
                        cursor: 'pointer'
                      }}
                      onClick={() =>
                        headerOpen === value ? (setHederOpen(undefined), handleFilterClose('', value, '')) : setHederOpen(value)
                      }
                    >
                      <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                        {`${value === FoodType.lunch ? 'LUNCH & DINNER' : value.toUpperCase()} (${
                          groupByName.filter((f) => f.type.includes(FoodType[value])).length
                        })`}
                        {headerOpen === value ? (
                          <Icon icon="octicon:filter-24" width="30" height="30" />
                        ) : (
                          <Icon icon="octicon:filter-24" width="30" height="30" />
                        )}
                      </Stack>
                    </header>
                    <Card
                      variant="outlined"
                      sx={{
                        padding: 1,
                        marginTop: -1,
                        borderRadius: '0 0 8px 8px',
                        width: '100%',
                        display:
                          headerOpen === value &&
                          draftTrack &&
                          (value === FoodType.breakfast || value === FoodType.lunch || value === FoodType.snack) &&
                          draftTrack[value]
                            ? 'flex'
                            : 'none',
                        backgroundColor: '#F7F7F7',
                        borderTop: 0,
                        border: 0
                      }}
                    >
                      {headerOpen === value && (
                        <MenuTableHeader
                          key={`${key}-${value}`}
                          value={value}
                          groupByName={groupByName}
                          draftOptions={draftTrack}
                          foodLabel={foodLabel}
                          onButtonClick={handleButtonClick}
                          onFilterClose={handleFilterClose}
                          setHederOpen={setHederOpen}
                        />
                      )}
                    </Card>
                    <Stack key={key} sx={{ width: '100%', mx: 'auto', mr: 4 }}>
                      {groupByName
                        .filter((meal) => !meal.type.includes(FoodType.coffee) || !meal.type.includes(FoodType.juice))
                        .map((meal) =>
                          value === FoodType.lunch
                            ? (meal.type.includes(FoodType.lunch) || meal.type.includes(FoodType.dinner)) && foodData(meal)
                            : meal.type.includes(FoodType[value]) && foodData(meal)
                        )}
                    </Stack>
                  </Box>
                )
            )}
        </Box>
      )}

      <Popup
        maxWidth="lg"
        title="Info"
        ref={infoMealRef}
        onClose={() => {
          infoMealRef.current?.close();
          setSelectedMeal(undefined);
        }}
      >
        <MenuMealInfo
          key={'key'}
          brand={brand}
          infoMealRef={infoMealRef}
          selectedMeal={selectedMeal}
          setSelectedMeal={setSelectedMeal}
          handleFoodSizes={handleFoodSizes}
          handleCalculateLastTime={handleCalculateLastTime}
        />
      </Popup>
    </>
  );
};
export default MenuTable;
