import React, { ReactNode } from 'react';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import ModalHeader from './ModalHeaderCard';
import ModalContentCard from './ModalContentCard';

const StyledContainer = styled(Paper)(() => ({
  width: '393px',
  height: '90vh',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  outline: 'none'
}));

interface ModalContainerProps {
  header: ReactNode;
  headerTitle?: string;
  body: ReactNode;
  onClose: () => void;
}

const ModalContainer = ({ header, body, headerTitle, onClose }: ModalContainerProps) => {
  return (
    <StyledContainer>
      <ModalHeader headerTitle={headerTitle} onClose={onClose}>
        {header}
      </ModalHeader>
      <ModalContentCard>{body}</ModalContentCard>
    </StyledContainer>
  );
};

export default ModalContainer;
