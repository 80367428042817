import { FoodType } from '@calo/types';
import { MenuDraft } from '../interfaces';

export const mealTracking: MenuDraft = {
  [FoodType.breakfast]: {
    balanced: {
      fixed: 3,
      rotational: 4
    },
    lowCarb: {
      fixed: 3,
      rotational: 2
    },
    highProtein: 2
  },
  [FoodType.lunch]: {
    balanced: {
      fixed: 6,
      rotational: 8
    },
    lowCarb: {
      fixed: 3,
      rotational: 3
    },
    highProtein: 3,
    vegetarian: 3,
    gourmet: 2
  },
  [FoodType.snack]: {
    balanced: {
      fixed: 2,
      rotational: 10
    },
    lowCarb: {
      fixed: 1,
      rotational: 2
    },
    highProtein: 2
  }
};
