import { keyBy } from 'lodash-es';
import { AnyAction } from 'redux';

import { Action } from 'lib/enums';
import { UserState } from 'lib/interfaces';

export default (
  state: UserState = {
    keyedList: null
  },
  action: AnyAction
) => {
  switch (action.type) {
    case Action.USER_LIST_SET:
      return {
        ...state,
        keyedList: keyBy(action.payload.users, 'id')
      };
    case Action.USER_SET:
      return {
        ...state,
        keyedList: {
          ...state.keyedList,
          [action.payload.user.id]: {
            ...(state.keyedList && state.keyedList[action.payload.user.id]),
            ...action.payload.user
          }
        }
      };
    default:
      return state;
  }
};
