import { CompensationScheme, CreateAffiliateReq } from '@calo/dashboard-types';
import { useFormik } from 'formik';
import * as yup from 'yup';

interface useCreateAffiliateFormProps {
  refetchAffiliates: any;
  createAffiliate: any;
}

const validationSchema = yup.object({
  name: yup.string().required('Affiliate name is required'),
  country: yup.string().required('Affiliate country is required'),
  affiliateCode: yup.string().required('Affiliate Code Name is required'),
  phoneNumber: yup.string().required('Affiliate phone number is required'),
  email: yup.string().email().required('Affiliate email is required'),
  discountAmount: yup.number(),
  commissionAmount: yup.number()
});

const useCreateAffiliateForm = ({ refetchAffiliates, createAffiliate }: useCreateAffiliateFormProps) =>
  useFormik<CreateAffiliateReq>({
    initialValues: {
      country: '',
      name: '',
      affiliateCode: '',
      phoneNumber: '',
      email: '',
      discountAmount: 5,
      commissionAmount: 5,
      compensationScheme: 'payout' as CompensationScheme
    },
    validationSchema,
    onSubmit: async (values) => {
      const capitalizedCodeName = values.affiliateCode.toUpperCase();
      values.affiliateCode = capitalizedCodeName;

      await createAffiliate(values);
      await refetchAffiliates();
    }
  });

export default useCreateAffiliateForm;
