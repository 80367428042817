import { GetWaitingListReqFilters } from '@calo/dashboard-types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { toggleUISettings } from 'actions';
import { Input, SideDrawer } from 'components';
import { format } from 'date-fns/fp';
import { AppState } from 'lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';
interface SettingsProps {
  onFilter: (values: any) => any;
  filters?: GetWaitingListReqFilters;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Input
          label="Name"
          value={filters?.name}
          onChange={(e) => onFilter({ ...filters, name: e.target.value.length > 0 ? e.target.value : undefined })}
          debounce
        />

        <Input
          label="Phone Number"
          value={filters?.phoneNumber}
          onChange={(e) => onFilter({ ...filters, phoneNumber: e.target.value.length > 0 ? e.target.value : undefined })}
          debounce
        />
        <div className="field">
          <label className="label">Day</label>
          <div className="control is-clearfix">
            <DateRangePicker
              calendarType={'US'}
              onChange={(dates: any) =>
                onFilter({
                  ...filters,
                  date: dates
                    ? {
                        gte: format('yyyy-MM-dd')(dates[0]),
                        lte: format('yyyy-MM-dd')(dates[1])
                      }
                    : undefined
                })
              }
              value={[filters!.date?.gte, filters!.date?.lte]}
            />
          </div>
        </div>
      </section>
    </SideDrawer>
  );
};

export default Settings;
