import { caloTheme } from 'assets/images/theme/calo';

import { CircularProgress, Stack } from '@mui/material';

interface ImageUploadProgressProps {
  progress: number;
  fontSize?: number;
  firstFontColor?: string;
  secondFontColor?: string;
}

const ImageUploadProgress = ({ progress }: ImageUploadProgressProps) => (
  <>
    {progress > 0 && progress < 100 && (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        width={'100%'}
        sx={{
          position: 'relative',
          height: 160,
          width: 160,
          minWidth: 160,
          border: '1px solid ' + caloTheme.palette.neutral400,
          borderRadius: '8px',
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress variant="determinate" sx={{ color: caloTheme.palette.primary500 }} value={progress} />
      </Stack>
    )}{' '}
  </>
);

export default ImageUploadProgress;
