import { styled, TableCell, tableCellClasses, TableRow } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { format, parseISO } from 'date-fns/fp';
import { Log } from 'lib/interfaces';
import React from 'react';

interface LogsCardRowProps {
  row: Log;
}

const LogsCardRow = ({ row }: LogsCardRowProps) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      width: '100%',
      px: 4,
      component: 'th',
      scope: 'row',
      color: caloTheme.palette.neutral900,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      variant: 'caption'
    },
    [`&.${tableCellClasses.body}`]: {
      px: 4,
      fontWeight: 400,
      fontSize: '12px',
      fontHeight: '14px',
      variant: 'caption',
      fontFamily: caloTheme.typography.fontFamily
    }
  }));

  return (
    <>
      <TableRow key={row.id}>
        <StyledTableCell>
          {row.message.split('\n').map((line, index) => (
            <React.Fragment key={`${index}-${row.id}`}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </StyledTableCell>
        <StyledTableCell>{row.actor}</StyledTableCell>
        <StyledTableCell>{format('dd/MM/yyyy hh:mm a')(parseISO(row.createdAt))}</StyledTableCell>
      </TableRow>
    </>
  );
};
export default LogsCardRow;
