import { Brand } from '@calo/types';
import { getListWithParams } from 'actions/index';
import { OperationType } from 'lib/enums';
import { useQuery } from 'react-query';

const useExtraMeals = ({ selectedDate, selectedCustomer, kitchen, selectedOperation, selectedDelivery }) => {
  const {
    data: extraMeals,
    refetch: refetchExtraMeals,
    isLoading: loadingExtraMeals
  } = useQuery<any, Error, any[]>(
    [
      `kitchens/${kitchen}/extra-meals`,
      {
        date: selectedDate,
        brand: selectedCustomer?.data.brand || Brand.CALO,
        userId: selectedCustomer?.value
      },
      {}
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: !!selectedDate && !!selectedCustomer && (selectedOperation === OperationType.mealSwap ? !!selectedDelivery : true),
      refetchInterval: 25000
    }
  );
  return {
    extraMeals,
    refetchExtraMeals,
    loadingExtraMeals
  };
};

export default useExtraMeals;
