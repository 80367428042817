import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  cardTitle: { fontWeight: 600, fontSize: '19px', lineHeight: '23px', textTransform: 'capitalize', my: 'auto' },
  chips: {
    color: caloTheme.palette.primary700,
    backgroundColor: caloTheme.palette.primary100,
    padding: '8px 6px 8px 6px',
    borderWidth: 0,
    mr: 1,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    textTransform: 'capitalize'
  },
  attachFileButton: {
    display: 'flex',
    height: '42px',
    fontWeight: 600,
    lineHeight: '23px',
    fontSize: '14px',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    boxShadow: 'none',
    mr: 2,
    width: 'auto',
    color: caloTheme.palette.black,
    borderColor: caloTheme.palette.black,
    '&:hover': {
      boxShadow: 'none',
      color: caloTheme.palette.black,
      borderColor: caloTheme.palette.black
    }
  },
  autoComplete: {
    width: '50%',
    borderRadius: 5,
    mr: 1,
    '& .MuiInputBase-input': {
      height: '16px',
      borderRadius: '12px'
    }
  },
  addButton: {
    textTransform: 'none',
    height: '48px',
    minWidth: '120px',
    lineHeight: '24px',
    fontWeight: 600,
    fontSize: '20px',
    borderRadius: '8px',
    padding: '10px 20px',
    boxShadow: 'none',
    color: caloTheme.palette.black,
    backgroundColor: caloTheme.palette.white,
    borderColor: caloTheme.palette.black
  },
  confirmButton: {
    textTransform: 'none',
    height: '45px',
    width: '113px',
    lineHeight: '24px',
    fontWeight: 600,
    fontSize: '20px',
    borderRadius: '8px',
    padding: '14px 20px 14px 20px',
    boxShadow: 'none',
    color: caloTheme.palette.white,
    backgroundColor: caloTheme.palette.primary500
  },
  cancelButton: {
    textTransform: 'none',
    height: '42px',
    width: '113px',
    lineHeight: '24px',
    fontWeight: 600,
    fontSize: '20px',
    borderRadius: '8px',
    boxShadow: 'none',
    padding: '14px 20px 14px 20px',
    color: caloTheme.palette.neutral600
  },
  createRequestButton: {
    display: 'flex',
    height: '42px',
    fontWeight: 600,
    lineHeight: '23px',
    fontSize: '14px',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    boxShadow: 'none',
    color: caloTheme.palette.white,
    backgroundColor: caloTheme.palette.primary500,
    '&:hover': {
      boxShadow: 'none',
      color: caloTheme.palette.white,
      backgroundColor: caloTheme.palette.primary600
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      display: 'flex',
      justifyItems: 'center',
      m: 'auto',
      width: 'auto'
    }
  }
};

export default styles;
