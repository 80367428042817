import { TableCell, createTheme, styled, tableCellClasses } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { getFoodTypeColor, getTagColor } from './helpers';

export const selectStyles = {
  // Green border, Green indicator
  control: (defaultStyle: any) => ({
    ...defaultStyle,
    boxShadow: 'none',
    borderRadius: '8px',
    '&:focus': {
      border: '2px solid #57AE7F !important'
    },
    border: '2px solid #57AE7F !important'
  }),
  placeholder: (defaultStyle: any) => ({
    ...defaultStyle
  }),
  singleValue: (defaultStyle: any) => ({
    ...defaultStyle,
    color: caloTheme.palette.primary500
  }),
  indicatorsContainer: () => ({}),
  dropdownIndicator: () => ({
    color: caloTheme.palette.primary500,
    marginRight: '8px'
  })
};

export const logisticChangeSelectStyles = {
  control: (defaultStyle: any) => ({
    ...defaultStyle,
    boxShadow: 'none',
    borderRadius: '8px',
    '&:focus': {
      border: '2px solid #57AE7F !important'
    },
    border: '2px solid #57AE7F !important',
    width: '140px'
  }),
  placeholder: (defaultStyle: any) => ({
    ...defaultStyle
  }),
  singleValue: (defaultStyle: any) => ({
    ...defaultStyle,
    color: caloTheme.palette.primary500
  }),
  indicatorsContainer: () => ({}),
  dropdownIndicator: () => ({
    color: caloTheme.palette.primary500,
    marginRight: '8px'
  })
};

export const customFoodTypeStyles = {
  multiValue: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: `${getFoodTypeColor(state.data.value)}1A`,
    borderRadius: '5px'
  }),
  multiValueLabel: (styles: any, state: any) => ({
    ...styles,
    color: getFoodTypeColor(state.data.value),
    fontWeight: 'bold'
  }),
  multiValueRemove: (styles: any, state: any) => ({
    ...styles,
    color: getFoodTypeColor(state.data.value),
    ':hover': {
      color: 'black',
      cursor: 'pointer'
    }
  })
};

export const customTagStyles = {
  multiValue: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: getTagColor(state.data.value),
    borderRadius: '5px'
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: 'white'
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: 'white',
    ':hover': {
      color: 'black',
      cursor: 'pointer'
    }
  })
};

export const customStyles2 = {
  //No colors, no Indicator
  control: (defaultStyle: any) => ({
    ...defaultStyle,
    boxShadow: 'none',
    borderRadius: '12px'
  }),
  placeholder: (defaultStyle: any) => ({
    ...defaultStyle,
    maxHeight: '24px'
  }),
  multiValue: (styles: any) => ({
    ...styles,
    borderRadius: '5px'
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontWeight: 'bold'
  }),
  indicatorsContainer: () => ({}),
  dropdownIndicator: () => ({
    color: 'white'
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    ':hover': {
      color: 'black',
      cursor: 'pointer'
    }
  })
};

export const customStyleDatePicker = {
  //green text, border and icon date picker
  control: (defaultStyle: any) => ({
    ...defaultStyle,
    boxShadow: 'none',
    borderRadius: '6px',
    '&:focus': {
      border: '2px solid #E5E7EB !important'
    },
    border: '2px solid #E5E7EB !important'
  }),
  placeholder: (defaultStyle: any) => ({
    ...defaultStyle
  }),
  singleValue: (defaultStyle: any) => ({
    ...defaultStyle
  }),
  indicatorsContainer: () => ({}),
  dropdownIndicator: () => ({
    marginRight: '8px'
  })
};

export const customStyleKitchen = {
  //No border green indicator and green text
  control: (defaultStyle: any) => ({
    ...defaultStyle,
    boxShadow: 'none',
    borderRadius: 'none',
    width: '6rem',
    '&:focus': {
      border: '2px solid #F7F7F7 !important'
    },
    border: 'none',
    background: '#F7F7F7'
  }),
  placeholder: (defaultStyle: any) => ({
    ...defaultStyle
  }),
  singleValue: (defaultStyle: any) => ({
    ...defaultStyle,
    color: caloTheme.palette.primary500
  }),
  indicatorsContainer: () => ({}),
  dropdownIndicator: () => ({
    color: caloTheme.palette.primary500,
    marginRight: '8px'
  })
};

export const customStyles4 = {
  //rounded isMulti green background and text
  control: (defaultStyle: any) => ({
    ...defaultStyle,
    boxShadow: 'none',
    borderRadius: '8px'
  }),
  placeholder: (defaultStyle: any) => ({
    ...defaultStyle,
    maxHeight: '24px'
  }),
  multiValue: (styles: any) => ({
    ...styles,
    borderRadius: '12px',
    backgroundColor: '#E6F3EC'
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    margin: '6px',
    fontWeight: 'bold',
    color: '#397555'
  }),
  indicatorsContainer: () => ({}),
  dropdownIndicator: () => ({
    color: 'white'
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    border: '3px solid #397555',
    fontWeight: 'bold',
    padding: '2px',
    marginTop: '8px',
    marginBottom: '8px',
    marginRight: '8px',
    borderRadius: '12px',
    ':hover': {
      cursor: 'pointer'
    }
  }),
  clearIndicator: (styles: any) => ({
    ...styles,
    margin: 'auto'
  })
};

//switch toggle
export const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          '&.Mui-checked': {
            color: caloTheme.palette.white
          }
        },
        track: {
          backgroundColor: caloTheme.palette.neutral400,
          '.Mui-checked.Mui-checked + &': {
            opacity: 0.7,
            backgroundColor: caloTheme.palette.primary500
          }
        }
      }
    }
  }
});

export const customMenuStyles = {
  base: (styles: any) => ({
    ...styles,
    zIndex: 9999
  }),
  multiValue: (styles: any, state: any) => ({
    ...styles,
    zIndex: 9999,
    backgroundColor: `${getFoodTypeColor(state.data.value)}1A`,
    borderRadius: '5px'
  }),
  multiValueLabel: (styles: any, state: any) => ({
    ...styles,
    zIndex: 9999,
    color: getFoodTypeColor(state.data.value),
    fontWeight: 'bold'
  }),
  multiValueRemove: (styles: any, state: any) => ({
    ...styles,
    zIndex: 9999,
    color: getFoodTypeColor(state.data.value),
    ':hover': {
      color: 'black',
      cursor: 'pointer'
    }
  })
};

export const customStylesComplaints = {
  //No colors, no Indicator
  control: (defaultStyle: any) => ({
    ...defaultStyle,
    boxShadow: 'none',
    borderRadius: '8px',
    height: '51px'
  }),
  placeholder: (defaultStyle: any) => ({
    ...defaultStyle,
    maxHeight: '24px'
  }),
  multiValue: (styles: any) => ({
    ...styles,
    borderRadius: '5px'
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontWeight: 'bold'
  }),
  indicatorsContainer: () => ({}),
  dropdownIndicator: () => ({
    color: 'white'
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: 'black',
    ':hover': {
      color: 'black',
      cursor: 'pointer'
    }
  }),
  clearIndicator: (styles: any) => ({
    ...styles,
    color: 'white'
  })
};

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    border: 'none',
    justifyContent: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    border: 'none',
    justifyContent: 'center'
  }
}));
export const foodInformationFormSingleSelectCustomStyles = {
  control: (defaultStyle: any) => ({
    ...defaultStyle,
    boxShadow: 'none',
    borderRadius: '8px',
    height: '56px',
    padding: '6px 0px'
  }),
  placeholder: (defaultStyle: any) => ({
    ...defaultStyle,
    fontSize: '16px',
    maxHeight: '24px'
  }),
  input: (defaultStyle: any) => ({
    ...defaultStyle,
    fontSize: '16px'
  }),
  multiValue: (styles: any) => ({
    ...styles,
    borderRadius: '5px'
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontWeight: 'bold'
  }),
  indicatorsContainer: () => ({}),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: 'black',
    ':hover': {
      color: 'black',
      cursor: 'pointer'
    }
  })
};

export const foodInformationFormMultiSelectCustomStyles = (
  getBackgroundColor?: (value: any) => string,
  getTextColor?: (value: any) => string
) => {
  return {
    control: (defaultStyle: any) => ({
      ...defaultStyle,
      boxShadow: 'none',
      borderRadius: '8px',
      minHeight: '56px',
      padding: '6px 0px'
    }),
    placeholder: (defaultStyle: any) => ({
      ...defaultStyle,
      maxHeight: '24px'
    }),
    multiValue: (styles: any, state: any) => ({
      ...styles,
      height: '30px',
      fontSize: '18px',
      paddingLeft: '6px',
      paddingRight: '6px',
      backgroundColor: getBackgroundColor ? getBackgroundColor(state.data.value) : caloTheme.palette.primary100,
      borderRadius: '8px'
    }),
    multiValueLabel: (styles: any, state: any) => ({
      ...styles,
      color: getTextColor ? getTextColor(state.data.value) : caloTheme.palette.primary700,
      fontWeight: '600'
    }),
    multiValueRemove: (styles: any, state: any) => ({
      ...styles,
      color: getTextColor ? getTextColor(state.data.value) : caloTheme.palette.primary700,
      ':hover': {
        color: 'black',
        cursor: 'pointer'
      }
    })
  };
};

export const StyledStatsTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    border: 'none',
    fontFamily: 'Roboto',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    backgroundColor: caloTheme.palette.neutral50
  },
  [`&.${tableCellClasses.body}`]: {
    border: 'none',
    justifyContent: 'space-between',
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none'
  }
}));
