import { ComponentService } from '@calo/services';
import { Food, FoodActionType, IngredientCategory } from '@calo/types';
import { compact, flattenDeep, intersection, orderBy, round } from 'lodash-es';
import { toast } from 'react-toastify';
import { Delivery, FoodComponent, Ingredient } from '../interfaces';
import { calculateFractionWeight } from './calculateComponentWeight';

export const renderIngredients = (
  meal: any,
  delivery: any,
  allComponentsData?: FoodComponent[],
  removedIds?: string[],
  swappedIds?: any[]
) => {
  const ingredients = getIngredientsToAvoid(meal, delivery, allComponentsData || [], removedIds, swappedIds);
  const allergicIngredients = orderBy(
    ingredients.filter((ing: any) => ing.isAlergen),
    ['weightedIng'],
    ['desc']
  );
  const nonAllergicIngredients = orderBy(
    ingredients.filter((ing: any) => !ing.isAlergen),
    ['weightedIng'],
    ['desc']
  );
  return [...allergicIngredients, ...nonAllergicIngredients];
};

export const getIngredientsToAvoid = (
  food: any,
  delivery: Delivery,
  allFoodComponentsData?: FoodComponent[],
  removedIds?: string[],
  swappedIds?: any[]
) => {
  let selectedMealComp = [];
  let singleComponent: any = [];
  let selectedMealIng: any[] = [];
  const mergedIngredientData: any = {};
  let ingredientsWeightedData: any[] = [];
  const mergedSingleIngredientData: any = {};
  let singleIngredientsWeightedData: any[] = [];

  if (!!allFoodComponentsData && allFoodComponentsData.length > 0 && food.components) {
    const removedComponent = food.actions
      ?.filter((action: any) => action.type === FoodActionType.remove)
      .flatMap((component: any) => component.componentId);
    const swappedComponent = food.actions
      ?.filter((action: any) => action.type === FoodActionType.swap)
      .flatMap((component: any) => component.swappedComponents);
    const removedComponentList = removedComponent
      ? food.components?.filter((comp: any) => !removedComponent?.includes(comp.id))
      : food.components;
    const removedComponentList2 = removedIds
      ? food.components?.filter((comp: any) => !removedIds?.includes(comp.id))
      : removedComponentList;
    const swappedComponentList = swappedComponent
      ? removedComponentList2.map((component: any) => {
          if (swappedComponent.some((swapped: any) => swapped.oldId === component.id)) {
            const swappedData = swappedComponent.find((swap: any) => swap.oldId === component.id);
            return allFoodComponentsData?.find((comp) => comp.id === swappedData.newId);
          } else {
            return component;
          }
        })
      : removedComponentList2;
    const swappedComponentList2 = swappedIds
      ? swappedComponentList.map((component: any) => {
          if (swappedIds.some((swapped: any) => swapped.oldId === component.id)) {
            const swappedData = swappedIds.find((swap) => swap.oldId === component?.id);
            return allFoodComponentsData?.find((comp) => comp.id === swappedData.newId);
          } else {
            return component;
          }
        })
      : swappedComponentList;

    selectedMealComp = compact(
      (swappedComponentList2 ?? []).map((component: any) => {
        const comp = allFoodComponentsData?.find((comp) => comp.id === component?.id);
        if (!comp) {
          toast.error('component not found');
          return;
        }
        return {
          ...allFoodComponentsData?.find((comp) => comp?.id === component?.id),
          ingredients: allFoodComponentsData?.find((comp) => comp?.id === component?.id)?.ingredients || [],

          calculatedComponentWeight: ComponentService.calculateComponentWeight(
            comp.cups,
            comp.measurementUnit,
            comp.weight ?? 0,
            component?.quantity ?? 0,
            2
          ),
          childComponents: allFoodComponentsData
            ?.find((comp) => comp.id === component?.id)
            ?.childComponents?.map((child) => {
              const compData: any = allFoodComponentsData?.find((comp) => comp?.id === child?.id);
              if (compData) {
                return {
                  ...compData,
                  calculatedComponentWeight: ComponentService.calculateComponentWeight(
                    compData.cups,
                    compData.measurementUnit,
                    compData.weight,
                    child?.quantity ?? 0,
                    2
                  )
                };
              } else {
                return [];
              }
            })
        };
      })
    );
  }

  if (food?.components === undefined) {
    const singleComponentChild = food?.childComponents?.map((child: any) => {
      const childData = allFoodComponentsData?.find((comp) => comp.id === child.id);
      if (childData) {
        return {
          ...childData,
          calculatedWeight: ComponentService.calculateComponentWeight(
            childData.cups,
            childData.measurementUnit,
            childData.weight ?? 0,
            child?.quantity ?? 0,
            2
          )
        };
      } else {
        return [];
      }
    });
    singleComponent = singleComponentChild;
  }

  if (!!allFoodComponentsData && singleComponent.length > 0) {
    selectedMealIng = [food, ...flattenDeep(compact(singleComponent))];
    singleIngredientsWeightedData = selectedMealIng.map((comp) =>
      comp.ingredients?.map((ing: any) => {
        return {
          ...ing,
          calculatedComponentWeight: comp.calculatedComponentWeight,
          calculatedIngredientWeight: calculateFractionWeight(comp, ing.quantity, comp.calculatedComponentWeight)
        };
      })
    );
  }

  for (const item of flattenDeep(compact(singleIngredientsWeightedData))) {
    const id = item.id;
    if (mergedSingleIngredientData[id]) {
      mergedSingleIngredientData[id].calculatedComponentWeight += item.calculatedComponentWeight;
    } else {
      mergedSingleIngredientData[id] = { ...item };
    }
  }

  if (!!allFoodComponentsData && selectedMealComp.length > 0) {
    const childComponentsData = selectedMealComp.map((comp: any) => comp.childComponents);
    selectedMealIng = [...selectedMealComp, ...flattenDeep(compact(childComponentsData))];
    ingredientsWeightedData = selectedMealIng.map((comp) =>
      comp.ingredients?.map((ing: any) => {
        return {
          ...ing,
          calculatedComponentWeight: comp.calculatedComponentWeight,
          calculatedIngredientWeight: calculateFractionWeight(comp, ing.quantity, comp.calculatedComponentWeight)
        };
      })
    );
  }

  for (const item of flattenDeep(compact(ingredientsWeightedData))) {
    const id = item.id;
    if (mergedIngredientData[id]) {
      mergedIngredientData[id].calculatedComponentWeight += item.calculatedComponentWeight;
    } else {
      mergedIngredientData[id] = { ...item };
    }
  }

  return (
    Object.values(mergedIngredientData).length > 0
      ? Object.values(mergedIngredientData)
      : Object.values(mergedSingleIngredientData).length > 0
        ? Object.values(mergedSingleIngredientData)
        : (removedIds ? [] : food?.ingredients) || []
  ).map((ing: any) => {
    const weightedIng = round(ing.calculatedIngredientWeight || 0, 4);
    let isAlergen = isIngredientInIngredientsToAvoid(delivery?.ingredientsToAvoid || [], ing.name.en);
    if (!isAlergen) {
      isAlergen = isIngredientInAllergicFood(ing.category, delivery);
    }
    return {
      name: ing?.name?.en,
      isAlergen,
      weightedIng
    };
  });
};

const isIngredientInIngredientsToAvoid = (ingredientsToAvoid: Ingredient[], name: string) => {
  return !!ingredientsToAvoid?.find((i) => i.name.en === name);
};

const isIngredientInAllergicFood = (category: IngredientCategory[], delivery?: Delivery) => {
  return (
    intersection(delivery?.macrosData?.allergicFood?.filter((v) => v !== IngredientCategory.other) || [], category || []).length >
    0
  );
};

export const checkIsRemovedByDefault = (
  foodPref: { mealsCustomization: any[] } | undefined,
  selectedMealToRemoveComponents: Food | undefined
): boolean => {
  if (!foodPref || !foodPref.mealsCustomization || foodPref.mealsCustomization.length === 0 || !selectedMealToRemoveComponents) {
    return false;
  }

  return !!foodPref.mealsCustomization.find(
    (mealCustomization) =>
      mealCustomization.mealId === selectedMealToRemoveComponents.id &&
      selectedMealToRemoveComponents.components?.find((component) => component.id === mealCustomization.componentId)
  );
};
