import { RatingFilters } from '@calo/dashboard-types';
import { Country, DietType, Kitchen, MenuTagValue, PortionSize, RatingFactor } from '@calo/types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { toggleUISettings } from 'actions';
import { Input, Select, Select2, SideDrawer } from 'components';
import { format } from 'date-fns/fp';
import { getAccessibleCountries, getKitchenOptions } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { capitalize, startCase } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

interface SettingsProps {
  onFilter: (values: RatingFilters) => any;
  filters: RatingFilters;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Country"
          value={filters.country || getAccessibleCountries(userKitchens)[0]}
          onChange={(data) =>
            onFilter({
              ...filters,
              country: data.target.value as Country,
              kitchen: data.target.value ? (userKitchens.find((k) => k.includes(data.target.value)) as Kitchen) : ('' as Kitchen)
            })
          }
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(data) => onFilter({ ...filters, kitchen: data.target.value as Kitchen })}
          options={getKitchenOptions(userKitchens, filters.country || getAccessibleCountries(userKitchens)[0])}
          disabled={
            userKitchens.filter((r) => r.includes(filters.country || getAccessibleCountries(userKitchens)[0])).length === 0
          }
          data-test="dashboard-kitchen-select"
        />
        <Input
          label="Meal"
          value={filters.foodName}
          onChange={(e) => onFilter({ ...filters, foodName: e.target.value })}
          debounce
        />
        <Select2
          label="Portion"
          value={filters.portionSize}
          onChange={(e) => onFilter({ ...filters, portionSize: e.target.value === 'All' ? (undefined as any) : e.target.value })}
          options={[
            {
              value: 'All',
              label: 'All'
            },
            ...Object.values(PortionSize).map((c) => ({
              value: c,
              label: PortionSize[c]
            }))
          ]}
        />
        <Select2
          label="Rating"
          value={filters.rating}
          onChange={(e) => onFilter({ ...filters, rating: +e.target.value })}
          options={[
            {
              value: '',
              label: 'All'
            },
            ...['1', '2', '3', '4', '5'].map((c) => ({
              value: +c,
              label: c
            }))
          ]}
        />

        <Select
          label="Menu Tag"
          value={filters.menuTags}
          onChange={(data: any) =>
            onFilter({
              ...filters,
              menuTags: (data || []).map((row) => row.value)
            })
          }
          isMulti
          options={Object.values(MenuTagValue).map((tag) => ({
            label: capitalize(startCase(tag)),
            value: tag
          }))}
        />

        <Select
          label="Meal Plan"
          value={filters.foodDietTypes}
          onChange={(data: any) =>
            onFilter({
              ...filters,
              foodDietTypes: (data || []).map((row) => row.value)
            })
          }
          isMulti
          options={Object.values(DietType).map((type) => ({
            label: capitalize(startCase(type)),
            value: type
          }))}
        />

        <Select
          label="Feedback"
          value={filters.ratingFactors}
          onChange={(data: any) =>
            onFilter({
              ...filters,
              ratingFactors: (data || []).map((row) => row.value)
            })
          }
          isMulti
          options={Object.values(RatingFactor).map((factor) => ({
            label: capitalize(startCase(factor)),
            value: factor
          }))}
        />

        <div className="field">
          <label className="label">Date range</label>
          <div className="control is-clearfix">
            <DateRangePicker
              onChange={(dates: any) => {
                onFilter({
                  ...filters,
                  createdAt: dates
                    ? {
                        gte: format('yyyy-MM-dd')(dates[0]),
                        lte: format('yyyy-MM-dd')(dates[1])
                      }
                    : undefined
                });
              }}
              value={[filters!.createdAt?.gte, filters!.createdAt?.lte]}
            />
          </div>
        </div>
      </section>
    </SideDrawer>
  );
};

export default Settings;
