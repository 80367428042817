import { styled, Table, TableCell, tableCellClasses, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { IngredientComponentStats, IngredientStats } from 'lib/interfaces';
import ComponentsBody from './ComponentsBody';
import TableHeader from './TableHeader';

interface MainTableProps {
  ingredient: IngredientStats;
  switchUnit: boolean;
  setSwitchUnit: (switchUnit: boolean) => void;
}

export default function MainTable({ ingredient, switchUnit, setSwitchUnit }: MainTableProps) {
  const ingredientComponentStats: IngredientComponentStats[] = ingredient?.keyedComponents
    ? Object.values(ingredient?.keyedComponents)
    : [];
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '19px',
      textTransform: 'capitalize'
    }
  }));

  return (
    <Table sx={{ marginY: '4px', minHeight: '120px', overflow: 'auto', width: '100%' }}>
      <TableHeader ingredient={ingredient} switchUnit={switchUnit} setSwitchUnit={setSwitchUnit} />
      {ingredientComponentStats && ingredientComponentStats.length === 0 ? (
        <StyledTableCell style={{ border: 0 }} colSpan={12}>
          <Typography
            sx={{
              mt: 3,
              textAlign: 'center',
              width: 'full',
              fontSize: '24px',
              color: caloTheme.palette.neutral400
            }}
          >
            No Components
          </Typography>
        </StyledTableCell>
      ) : (
        <ComponentsBody ingredient={ingredient} switchUnit={switchUnit} />
      )}
    </Table>
  );
}
