import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Route, Switch } from 'react-router-dom';
import DriversMetricsList from './DriversMetricsList';
import ExactDriverMetric from './ExactDriverMetric';

const DriverMetrics = () => {
  const roles = useUserRoles();
  return (
    <Switch>
      {roles.includes(Permission.VIEW_DRIVER_METRIC_LIST) && [
        <Route key={Routes.driversMetric} exact path={Routes.driversMetric} component={DriversMetricsList} />
      ]}
      {roles.includes(Permission.VIEW_DRIVER_METRIC) && [
        <Route key={Routes.driverMetric} exact path={Routes.driverMetric} component={ExactDriverMetric} />
      ]}
    </Switch>
  );
};

export default DriverMetrics;
