import { Box, Button, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { PopulateMenuReq } from 'lib/interfaces';

interface ConfirmPopupMenuProps {
  setFieldValue: any;
  confirmModalRef: any;
  infoChanges: { field: string; value: any };
  setPopulateMenuData: (value: PopulateMenuReq[]) => void;
}

const ConfirmPopupMenu = ({ setPopulateMenuData, setFieldValue, confirmModalRef, infoChanges }: ConfirmPopupMenuProps) => (
  <>
    <Box display={'flex'} flexDirection={'column'} m={2}>
      <Stack mb={2}>
        <Typography
          sx={{
            display: 'flex',
            flexDirection: 'row',
            m: 2,
            color: caloTheme.palette.red
          }}
        >
          <i className=" fa fa-exclamation-triangle mr-2 mt-0.5 text-red-400"></i>
          This change will remove the meals you have added
        </Typography>
      </Stack>
    </Box>
    <Box display={'flex'} flexDirection={'row'} m={2}>
      <Button
        data-test="menu-new-modal-cancel-button"
        sx={{
          width: '262px',
          height: '51px',
          lineHeight: '17px',
          fontWeight: 600,
          fontSize: '14px',
          borderRadius: '8px',
          color: caloTheme.palette.neutral600,
          '&:hover': {
            backgroundColor: caloTheme.palette.neutral100,
            borderColor: caloTheme.palette.neutral100
          }
        }}
        onClick={() => confirmModalRef.current?.close()}
      >
        Cancel
      </Button>

      <Button
        variant="contained"
        data-test="menu-new-modal-confirm-button"
        onClick={() => {
          setFieldValue(infoChanges.field, infoChanges.value);
          setFieldValue('food', []);
          setPopulateMenuData([]);
          confirmModalRef.current?.close();
        }}
        sx={{
          width: '262px',
          height: '51px',
          lineHeight: '17px',
          fontWeight: 600,
          fontSize: '14px',
          borderRadius: '8px',
          backgroundColor: caloTheme.palette.primary500,
          borderColor: caloTheme.palette.primary500,
          color: 'white',
          '&:hover': {
            backgroundColor: caloTheme.palette.primary500,
            borderColor: caloTheme.palette.primary600
          }
        }}
      >
        Confirm
      </Button>
    </Box>
  </>
);
export default ConfirmPopupMenu;
