import { useState } from 'react';

import { getRecordWithParams } from 'actions';
import { CaloLoader } from 'components';
import { resolveCountryFromKitchen } from 'lib';
import { PerksStatus } from 'lib/enums';
import { useUserKitchens } from 'lib/hooks';
import { EmployeePerks, EmployeePerksResponse, PerksFilters } from 'lib/interfaces';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { Kitchen } from '@calo/types';

import EmployeePerksHeaderCard from './EmployeePerksHeaderCard';
import EmployeePerksInfoListCard from './EmployeePerksInfoListCard';
import Settings from './Settings';

const PackageElementList = () => {
  const history = useHistory();
  const userKitchen = useUserKitchens();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState<PerksFilters>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 2,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });
  const initialEmployeePerks: EmployeePerks = {
    id: '',
    country: filters.country,
    year: filters.year,
    month: filters.month,
    status: PerksStatus.pending,
    employees: []
  };
  const [employeePerks, setEmployeePerks] = useState<EmployeePerks>(initialEmployeePerks);
  const { isLoading } = useQuery<any, Error, EmployeePerksResponse>(
    ['/perks', { country: filters.country, month: filters.month, year: filters.year }],
    getRecordWithParams,
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        setEmployeePerks(data.data);
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      },
      onError: () => {
        setEmployeePerks(initialEmployeePerks);
      }
    }
  );
  const isDisabled = false;

  return (
    <>
      <EmployeePerksHeaderCard
        filters={filters}
        employeePerks={employeePerks}
        isDisabled={isDisabled}
        setEmployeePerks={setEmployeePerks}
        isLoading={isLoading}
      />
      {isLoading ? (
        <CaloLoader />
      ) : (
        <EmployeePerksInfoListCard
          employeePerks={employeePerks}
          isLoading={isLoading}
          filters={filters}
          setEmployeePerks={setEmployeePerks}
        />
      )}
      <Settings onFilter={setFilters} filters={filters} />
    </>
  );
};

export default PackageElementList;
