import { addMenuFood } from 'actions';
import mutation from 'actions/mutation';
import { Button, CaloLoader, ModalRef, Select } from 'components';
import FoodDietTypeTags from 'components/FoodDietTypeTags';
import { selectStyles } from 'lib/componentStyles';
import { getIngredientsToAvoid } from 'lib/helpers';
import { Delivery, Menu, MenuFood } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import { flatten, uniq } from 'lodash-es';
import { useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation } from 'react-query';

import { FoodType, MultiCurrencyAmount } from '@calo/types';
import { Typography } from '@mui/material';

interface MenuFoodPickerProps {
  menu: Menu;
  gifted: boolean;
  delivery: Delivery;
  isGifted: boolean;
  setIsGifted: (v: boolean) => void;
  setMealType: (v: FoodType) => void;
  deliveryBalance: MultiCurrencyAmount;
  handleAddMealRequest?: (v: any) => any;
  menuModalRef: React.MutableRefObject<ModalRef | undefined>;
  refetchDelivery: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  menuLoading?: boolean;
}

const MenuFoodPicker = ({
  deliveryBalance,
  setMealType,
  menu,
  delivery,
  menuModalRef,
  refetchDelivery,
  menuLoading
}: MenuFoodPickerProps) => {
  const { mutateAsync: addFoodMenuMutation } = useMutation(addMenuFood);
  const [menuType, setMenuType] = useState(FoodType.breakfast);
  const menuTypes = uniq(flatten(menu?.food.map((r) => r.type)));
  const menus = menuTypes.filter((r) => r !== FoodType.dinner);
  const food = menu?.food?.filter((f) => f.type.includes(menuType));

  const handleAddMenuFoodRequest = async (food: MenuFood) => {
    await addFoodMenuMutation(
      { foodId: food.id, deliveryId: delivery.id },
      {
        onSuccess: (data) => {
          const query = queryClient.getQueryData(['deliveries', delivery.id]) as any;
          const deliveryUpdated = { ...query, food: data.food, cost: data.cost };
          mutation(['deliveries', delivery.id], deliveryUpdated);
          refetchDelivery();
        }
      }
    );
    menuModalRef.current?.close();
  };

  return (
    <div>
      <section className="flex flex-row w-full mb-4">
        <div className="w-8/12 z-50">
          <Select
            label="Type"
            className="z-50"
            value={menuType}
            options={menus.map((t) =>
              t === FoodType.lunch
                ? {
                    value: t,
                    label: 'Lunch & Dinner'
                  }
                : {
                    value: t,
                    label: t
                  }
            )}
            customStyles={selectStyles}
            onChange={(data: any) => {
              setMenuType(data.value);
              setMealType(data.value);
            }}
          />
        </div>
        <div className="float-right mt-8 ml-8">
          <span className="text-black">
            {' '}
            Balance: {deliveryBalance && deliveryBalance[delivery?.currency]}
            {delivery.currency}
          </span>
        </div>
      </section>
      <section>
        <div className="table-container overflow-y-auto h-96">
          <table className=" table is-sortable is-fullwidth">
            <thead>
              <tr className="bg-white sticky top-0 z-20">
                <th>Name</th>
                <th>Size</th>
                <th>Ingredients</th>
                <th>Tags</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {menuLoading ? (
                <tr>
                  <td colSpan={6}>
                    <CaloLoader />
                  </td>
                </tr>
              ) : !food || food.length === 0 ? (
                <Typography>No Food to add</Typography>
              ) : (
                food?.map((r) => (
                  <tr key={r.id}>
                    <td className="w-64">{r.name.en}</td>
                    <td className="w-8">
                      <p className="font-bold">{r.size}</p>
                    </td>
                    <td className="pb-2 px-1">
                      {getIngredientsToAvoid(r, delivery).map((i: any) =>
                        i.isAlergen ? <span className="text-red-500"> {i.name}, </span> : <span> {i.name}, </span>
                      )}
                    </td>
                    <td>
                      {r.tags?.length > 0 && (
                        <div className="tags capitalize">
                          {r.tags.map((type) => (
                            <FoodDietTypeTags key={type} tag={type} />
                          ))}
                        </div>
                      )}
                    </td>
                    <td className="px-1">{r.price}</td>
                    <td>
                      <Button
                        content="Add"
                        onClick={() => handleAddMenuFoodRequest(r)}
                        className="mr-4 border-blue-500 text-blue-500 w-24"
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </section>
      {/* {gifted && (
        <section className="flex mt-4">
          <span className="flex flex-row mt-2">
            <input
              type="checkbox"
              className="mx-2 mt-1.5 bg-green-500 text-green-500"
              checked={isGifted}
              style={{ accentColor: 'green' }}
              onClick={() => setIsGifted(!isGifted)}
            />
            <label className="mt-1.5"> Add meal as a gift - For free</label>
          </span>
          <div className="flex flex-row mx-auto">
            <Button className="w-40 bg-gray-200 text-gray-400" content="cancel" onClick={() => menuModalRef.current?.close()} />
          </div>
        </section>
      )} */}
    </div>
  );
};
export default MenuFoodPicker;
