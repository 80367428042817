import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Route, Switch } from 'react-router-dom';
import ExactSupplyChainUser from './ExactSupplyChainUser';
import NewSupplyChainUser from './NewSupplyChainUser';
import SupplyChainUsersList from './SupplyChainUsersList';

const SupplyChainusers = () => {
  const roles = useUserRoles();

  return (
    <Switch>
      {roles.includes(Permission.VIEW_SUPPLY_CHAIN_USER) && (
        <Route key={Routes.supplyChainUser} exact path={Routes.supplyChainUser} component={ExactSupplyChainUser} />
      )}
      {roles.includes(Permission.CREATE_SUPPLY_CHAIN_USER) && (
        <Route key={Routes.newSupplyChainUser} exact path={Routes.newSupplyChainUser} component={NewSupplyChainUser} />
      )}

      {roles.includes(Permission.VIEW_SUPPLY_CHAIN_USER_LIST) && (
        <Route key={Routes.supplyChainUsersList} exact path={Routes.supplyChainUsersList} component={SupplyChainUsersList} />
      )}
    </Switch>
  );
};

export default SupplyChainusers;
