import { useFormik } from 'formik';

import { UpdateAccountSubscriptionReq } from 'lib/interfaces';

export default (accountSubscription: UpdateAccountSubscriptionReq, onSubmit: (values: UpdateAccountSubscriptionReq) => any) =>
  useFormik<UpdateAccountSubscriptionReq>({
    enableReinitialize: true,
    initialValues: {
      fullName: accountSubscription.fullName,
      phoneNumber: accountSubscription.phoneNumber,
      deliveryTime: accountSubscription.deliveryTime
    },
    validate: (values: UpdateAccountSubscriptionReq) => {
      const errors: any = {};

      if (!values.fullName) {
        errors.fullName = true;
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
