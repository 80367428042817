import { Country, Kitchen } from '@calo/types';
import { GoogleMap } from '@react-google-maps/api';
import { getCenteredKitchen } from 'lib/helpers';
import { Suspense, useEffect, useReducer, useState } from 'react';
import KitchenPolygonManager from './KitchenPolygonManager';
import KitchenPolygonReducer from './kitchenPolygonReducer';
import PolygonContext from './Polygon/PolygonContext';

interface KitchenMapViewProps {
  country: Country;
  kitchen: Kitchen;
}

const KitchenMapView = ({ country, kitchen }: KitchenMapViewProps) => {
  const [getCenter, setGetCenter] = useState<any>();
  const [polygonState, dispatch] = useReducer(KitchenPolygonReducer, { polygons: [] });

  useEffect(() => {
    setGetCenter(getCenteredKitchen(kitchen));
  }, [kitchen]);

  const handleMapClick = () => {
    dispatch!({
      type: 'select',
      payload: null
    });
  };

  return (
    <div className="flex flex-1 flex-grow">
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMap
          mapContainerClassName="w-full h-full relative"
          center={getCenter}
          zoom={country === 'SA' ? 10 : 11}
          options={{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
          }}
          onClick={handleMapClick}
        >
          <PolygonContext.Provider value={dispatch}>
            <Suspense fallback={null}>
              <KitchenPolygonManager
                kitchenID={kitchen}
                addressView={false}
                closePopUp={handleMapClick}
                polygonState={polygonState}
                country={country}
              />
            </Suspense>
          </PolygonContext.Provider>
          <Suspense fallback={null}></Suspense>
        </GoogleMap>
      </div>
    </div>
  );
};

export default KitchenMapView;
