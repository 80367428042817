import { TableCell, TableRow } from '@mui/material';
import { startCase } from 'lodash-es';

const TableHeaderRow = ({ type }: { type: 'ingredient' | 'component' }) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          borderRadius: '8px 0 0 8px',
          border: 0,
          fontWeight: 600,
          lineHeight: '14px',
          fontSize: '12px',
          minWidth: '100px',
          width: '20%'
        }}
      >
        {startCase(type)} Name
      </TableCell>
      <TableCell
        sx={{
          border: 0,
          fontWeight: 600,
          lineHeight: '14px',
          fontSize: '12px',
          minWidth: '80px',
          width: '15%'
        }}
      >
        Chef Name
      </TableCell>
      <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '60px', width: '10%' }}>
        Status
      </TableCell>
      <TableCell
        sx={{
          border: 0,
          fontWeight: 600,
          lineHeight: '14px',
          fontSize: '12px',
          minWidth: '120px',
          width: '45%'
        }}
      >
        Comments
      </TableCell>
      <TableCell
        sx={{
          border: 0,
          fontWeight: 600,
          lineHeight: '14px',
          fontSize: '12px',
          minWidth: '50px',
          width: '10%',
          borderRadius: '0 8px 8px 0'
        }}
      >
        Actions
      </TableCell>
    </TableRow>
  );
};

export default TableHeaderRow;
