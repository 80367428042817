import { GetWaitingListReqFilters } from '@calo/dashboard-types';
import { Kitchen as CKitchen, WaitingListType } from '@calo/types';
import { getRecord, updateKitchen } from 'actions';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Kitchen } from 'lib/interfaces';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import KitchenForm from './KitchenForm';
import WaitingList from './WaitingList';
import Settings from './WaitingList/Settings';
import Stats from './WaitingList/Stats';

const ExactKitchen = () => {
  const [selected, setSelected] = useState(WaitingListType.SUPPLY_CAP);
  const [isAllowed, setIsAllowed] = useState<boolean>(false);
  const [filters, setFilters] = useState<GetWaitingListReqFilters>({
    isAllowed: false
  });

  const { id } = useParams<{ id: CKitchen }>();
  const { mutateAsync: updateMutation } = useMutation(updateKitchen);

  const roles = useUserRoles();

  const { data } = useQuery(['kitchens', id], getRecord, {
    suspense: true
  });
  const kitchen = data as Kitchen;

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  <Link to={Routes.kitchenList}>Kitchen List</Link>
                </li>
                <li>{kitchen.id}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div>
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <p className="text-black py-4 text-xl">Current capacity: {kitchen.capacity} </p>
          </div>
        </div>
        <div>
          <Stats
            kitchen={kitchen}
            selected={selected}
            setSelected={(v) => setSelected(v)}
            isAllowed={isAllowed}
            setIsAllowed={(v) => setIsAllowed(v)}
            filters={filters}
            setFilters={setFilters}
          />
          <div>
            <WaitingList kitchen={kitchen} selected={selected} isAllowed={isAllowed} filters={filters} />
          </div>
        </div>
      </div>
      <KitchenForm kitchen={kitchen} onSubmit={(data) => updateMutation({ id: kitchen.id, ...data })} roles={roles} />
      <Settings onFilter={setFilters} filters={filters} />
    </>
  );
};

export default ExactKitchen;
