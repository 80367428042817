import { Link } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { TableCell, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { BusinessAccount } from 'lib/interfaces';

interface AccountListRowProps {
  accountUser: BusinessAccount;
}

const AccountListRow = ({ accountUser }: AccountListRowProps) => {
  const roles = useUserRoles();

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      color: caloTheme.palette.neutral900,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none'
    }
  }));

  return (
    <TableRow key={accountUser.id} hover>
      <StyledTableCell
        component="th"
        scope="row"
        sx={{
          textOverflow: 'ellipsis'
        }}
      >
        <Typography
          component="span"
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'inline-block',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            fontFamily: caloTheme.typography.fontFamily
          }}
        >
          {roles.includes(Permission.VIEW_BUSINESS_USER) ? (
            <Link to={Routes.accountUser.replace(':id', accountUser.id)}>{accountUser.fullName}</Link>
          ) : (
            accountUser.fullName
          )}
        </Typography>
      </StyledTableCell>

      <StyledTableCell>{accountUser.phoneNumber}</StyledTableCell>

      <StyledTableCell>
        <Typography
          sx={{
            width: '239px',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            fontFamily: caloTheme.typography.fontFamily
          }}
        >
          {accountUser.email}
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: 400,
            color: caloTheme.palette.neutral900
          }}
        >
          {accountUser.deliveryAddresses[0].kitchen || '---'}
        </Typography>
      </StyledTableCell>
    </TableRow>
  );
};
export default AccountListRow;
