import { CreateDeliveryTicketReq } from '@calo/dashboard-types';
import client from 'lib/client';
export const createDeliveryTicket = async (payload: CreateDeliveryTicketReq) => {
  const { data } = await client.post('delivery-tickets', {
    date: payload.date,
    data: payload.data,
    brand: payload.brand,
    country: payload.country,
    createdBy: payload.createdBy,
    kitchen: payload.kitchen
  });
  return data;
};
