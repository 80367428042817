import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { FoodPackage } from 'lib/interfaces';
import { round } from 'lodash-es';
import { Link } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { TableCell, TableRow } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';

interface PackagesRowProps {
  foodPackage: FoodPackage;
}

const PackagesRow = ({ foodPackage }: PackagesRowProps) => {
  const roles = useUserRoles();

  return (
    <TableRow key={foodPackage.id} sx={{ borderBottom: '1px solid' + caloTheme.palette.neutral100 }}>
      <TableCell sx={{ p: 0, pl: 1 }}>
        <CardMedia
          component="img"
          image={`${process.env.REACT_APP_BUCKET_URL}/food-package/${foodPackage.id}/square@1x.jpg`}
          alt="placeholder"
          sx={{ width: 40, height: 40, objectFit: 'cover', borderRadius: '4px', pr: 0, mr: 0 }}
          onError={(e: any) => {
            e.target.src = noImagePlaceholder;
          }}
        />
      </TableCell>
      <TableCell sx={{ fontSize: '14px', pl: 0, textAlign: 'start' }}>
        {roles.includes(Permission.VIEW_FOOD_PACKAGE) ? (
          <Link to={Routes.package.replace(':id', foodPackage.id)} style={{ color: '#367BF5' }}>
            {foodPackage.name}
          </Link>
        ) : (
          foodPackage.name
        )}
      </TableCell>
      <TableCell sx={{ fontSize: '14px' }}>{foodPackage.type}</TableCell>
      <TableCell sx={{ fontSize: '14px' }}>{foodPackage.elements.map((p) => p.name).join(', ')}</TableCell>
      <TableCell sx={{ fontSize: '14px' }}>{round(foodPackage.cost, 3)}</TableCell>
    </TableRow>
  );
};

export default PackagesRow;
