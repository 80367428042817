import DateFnsAdapter from '@date-io/date-fns';
import { TextField, TextFieldProps } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider, PickersDay, pickersDayClasses } from '@mui/x-date-pickers';
import { caloTheme } from 'assets/images/theme/calo';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { format, getYear, parse } from 'date-fns';
import { findStartAndEndDateForWeek } from 'lib';
import { useState } from 'react';
import { WeeklyMenusDatePickerProps } from '../../types';
import PaperContent from '../PaperContent';

const WeeklyMenusDatePicker = ({
  isWeeklyMenuChanged,
  selectedWeek,
  setSelectedWeek,
  setSelectedYear
}: WeeklyMenusDatePickerProps) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleRenderDateInput = (params: TextFieldProps) => {
    const { inputProps, ...rest } = params;
    const dateValue = inputProps?.value;
    let year = getYear(selectedDate);
    if (dateValue) {
      const parsedDate = parse(dateValue, 'dd-MM-yyyy', new Date());
      year = getYear(parsedDate);
      setSelectedYear(year);
    }
    const { startOfWeekDate, endOfWeekDate } = findStartAndEndDateForWeek(selectedWeek, year);

    const updatedInputProps = {
      ...inputProps,
      value: `Week ${selectedWeek} (${format(startOfWeekDate, 'dd/MM')} - ${format(endOfWeekDate, 'dd/MM')})`
    };

    return (
      <InputThemeProvider>
        <TextField
          {...rest}
          inputProps={updatedInputProps}
          sx={{
            mr: 2,
            width: '20%',
            minWidth: '240px',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              mt: 6,
              width: '100%',
              display: 'flex',
              borderRadius: '8px',
              paddingLeft: '30px',
              flexDirection: 'column',
              justifyContent: 'center'
            }
          }}
          error={false}
        />
      </InputThemeProvider>
    );
  };

  const handleRenderDay = (day: Date, DayComponentProps: any) => {
    const { startOfWeekDate, endOfWeekDate } = findStartAndEndDateForWeek(selectedWeek);

    if (
      format(day, 'yyyy-MM-dd') < format(startOfWeekDate, 'yyyy-MM-dd') ||
      format(day, 'yyyy-MM-dd') > format(endOfWeekDate, 'yyyy-MM-dd')
    ) {
      return <PickersDay disabled={true} sx={{ backgroundColor: 'none' }} {...DayComponentProps} />;
    }

    return (
      <PickersDay
        {...DayComponentProps}
        onClick={() => null}
        sx={{
          color: 'black',
          backgroundColor: caloTheme.palette.primary100,
          ':hover': { color: 'black', backgroundColor: caloTheme.palette.neutral100 },
          [`&&.${pickersDayClasses.selected}`]: {
            border: 1,
            color: 'black',
            backgroundColor: caloTheme.palette.neutral100,
            borderColor: caloTheme.palette.primary600
          }
        }}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px' }}>
      <DesktopDatePicker
        components={{
          // eslint-disable-next-line react/no-multi-comp
          PaperContent: (props) => (
            <PaperContent {...props} selectedDate={selectedDate} selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} />
          )
        }}
        showDaysOutsideCurrentMonth
        label="Week"
        inputFormat="dd-MM-yyyy"
        value={selectedDate}
        disabled={isWeeklyMenuChanged}
        shouldDisableDate={() => true}
        renderInput={handleRenderDateInput}
        renderDay={(day, _value, DayComponentProps) => handleRenderDay(new Date(day), DayComponentProps)}
        onMonthChange={(event) => setSelectedDate(new Date(event))}
        onYearChange={(event) => setSelectedDate(new Date(event))}
        onChange={() => {}}
        minDate={format(Date.now(), 'yyyy-MM-dd')}
      />
    </LocalizationProvider>
  );
};

export default WeeklyMenusDatePicker;
