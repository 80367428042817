import { GetWaitingListReqFilters } from '@calo/dashboard-types';
import { Country, WaitingListType } from '@calo/types';
import { getRecord } from 'actions';
import cx from 'classnames';
import { Button, Icon } from 'components';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { Kitchen } from 'lib/interfaces';
import { useQuery } from 'react-query';
import { generatePath } from 'react-router-dom';

interface StatsProps {
  kitchen: Kitchen;
  selected: WaitingListType;
  setSelected: (value: any) => void;
  isAllowed: boolean;
  setIsAllowed: (value: any) => void;
  filters: GetWaitingListReqFilters;
  setFilters: (val: any) => void;
}

const Stats = ({ kitchen, selected, setSelected, isAllowed, setIsAllowed, filters, setFilters }: StatsProps) => {
  const { data } = useQuery(['stats', 'kitchens', kitchen.id], getRecord);
  const stats = data as { allowedCount: number; preLaunchCount: number; softLaunchCount: number; supplyCapCount: number };
  const path = generatePath(Routes.waitingListMap, {
    id: kitchen.id,
    waitingListType: selected,
    isAllowed: isAllowed.toString()
  });

  return (
    <div className="level">
      <div className="level-left">
        <div
          className={cx(
            ' cursor-pointer flex opacity-80 rounded-2xl mr-8 w-52 h-28 border-l-8 border-white hover:border-red-500',
            {
              'border-l-8 border-red-500 opacity-100': selected === WaitingListType.SUPPLY_CAP && !isAllowed
            }
          )}
        >
          <div
            className="card tile rounded-2xl"
            onClick={() => {
              setSelected(WaitingListType.SUPPLY_CAP);
              setIsAllowed(false);
              setFilters({ ...filters, isAllowed: false });
            }}
          >
            <div className="card-content -mt-4">
              <div className="is-widget-label">
                <Icon className="relative ml-32 mt-1" size={8} name="waitingArea" />
                <h3 className="subtitle is-spaced -mt-4">Supply Caps</h3>
                <p className="text-red-500 text-4xl -mt-4">{stats?.supplyCapCount}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={cx(
            ' cursor-pointer flex opacity-80 rounded-2xl mr-8 w-52 h-28 border-l-8 border-white hover:border-green-500',
            {
              'border-l-8 border-green-500 opacity-100': selected === WaitingListType.SUPPLY_CAP && isAllowed
            }
          )}
        >
          <div
            className="card tile rounded-2xl"
            onClick={() => {
              setIsAllowed(true);
              setFilters({ ...filters, isAllowed: true });
              setSelected(WaitingListType.SUPPLY_CAP);
            }}
          >
            <div className="card-content -mt-4">
              <div className="is-widget-label">
                <Icon className="relative ml-32 text-green-500" size={8} name="bag" />
                <h3 className="subtitle is-spaced -mt-4">Allowed</h3>
                <p className="text-green-500 text-4xl -mt-4">{stats?.allowedCount}</p>
              </div>
            </div>
          </div>
        </div>
        {kitchen.country !== Country.BH && (
          <>
            <div
              className={cx(
                ' cursor-pointer  flex opacity-80 rounded-2xl mr-8 w-52 h-28 border-l-8 border-white hover:border-blue-500',
                {
                  'border-l-8 border-blue-500 opacity-100': selected === WaitingListType.SOFT_LAUNCH
                }
              )}
            >
              <div
                className="card tile rounded-2xl w-24"
                onClick={() => {
                  setIsAllowed(false);
                  setFilters({ ...filters, isAllowed: undefined });
                  setSelected(WaitingListType.SOFT_LAUNCH);
                }}
              >
                <div className="card-content -mt-4">
                  <div className="is-widget-label">
                    <Icon className="relative ml-32" size={8} name="grommet" />
                    <h3 className="subtitle is-spaced -mt-4">Soft-Launch</h3>
                    <p className="text-blue-500 text-4xl -mt-4">{stats?.softLaunchCount}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={cx(
                ' cursor-pointer  flex opacity-80 rounded-2xl mr-8 w-52 h-28 border-l-8 border-white hover:border-yellow-500',
                {
                  'border-l-8 border-yellow-500 opacity-100': selected === WaitingListType.PRE_LAUNCH
                }
              )}
            >
              <div
                className="card tile rounded-2xl"
                onClick={() => {
                  setSelected(WaitingListType.PRE_LAUNCH);
                  setIsAllowed(false);
                  setFilters({ ...filters, isAllowed: undefined });
                }}
              >
                <div className="card-content -mt-4">
                  <div className="is-widget-label">
                    <Icon className="relative ml-32 mt-1" size={8} name="rocket" />
                    <h3 className="subtitle is-spaced -mt-4">Pre-Launch</h3>
                    <p className="text-yellow-500 text-4xl -mt-4">{stats?.preLaunchCount}</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="level-right">
        <div className="flex mt-16 -mb-8">
          <Button content="Map View" onClick={() => history.push(path)} primary />
        </div>
      </div>
    </div>
  );
};
export default Stats;
