import { FoodDietType } from '@calo/types';

import Tag from '../Tag';

interface TagProps {
  tag: string;
  fullName?: boolean;
  blueBuerry?: boolean;
}

const FoodDietTypeTags = ({ tag, blueBuerry, fullName }: TagProps) => {
  switch (tag) {
    case FoodDietType.balanced:
      return <Tag green> {fullName ? 'Balanced' : 'B'}</Tag>;
    case FoodDietType.lowCarb:
      return blueBuerry ? <Tag blueBerry> {fullName ? 'Low-Carb' : 'L'}</Tag> : <Tag blue> {fullName ? 'Low-Carb' : 'L'}</Tag>;
    case FoodDietType.highProtein:
      return <Tag orange> {fullName ? 'High-Protein' : 'H'}</Tag>;
    case FoodDietType.vegetarian:
      return <Tag purple> {fullName ? 'Vegetarian' : 'V'}</Tag>;
    case FoodDietType.gourmet:
      return <Tag yellow> {fullName ? 'Gourmet' : 'G'}</Tag>;
    case FoodDietType.preBuiltCustom:
      return <Tag black> {fullName ? 'Pre-Built Custom' : 'P'}</Tag>;
    case FoodDietType.weightLoss:
      return <Tag orange> {fullName ? 'Weight-Loss' : 'W'}</Tag>;
    case FoodDietType.customMacros:
      return <Tag lightGreen> {fullName ? 'Custom-Macros' : 'C'}</Tag>;
    default:
      return <Tag blue>{tag}</Tag>;
  }
};
export default FoodDietTypeTags;
