import { AnyAction } from 'redux';

import { Action, ViewMode } from 'lib/enums';
import { UIState } from 'lib/interfaces';

export default (
  state: UIState = {
    viewMode: ViewMode.map,
    settings: false,
    kitchenSettings: false
  },
  action: AnyAction
) => {
  switch (action.type) {
    case Action.UI_SET_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload.viewMode
      };
    case Action.UI_SET_SETTINGS_VISILE:
      return {
        ...state,
        settings: action.payload.settings,
        couponSettings: false,
        kitchenSettings: false
      };
    case Action.UI_SET_KITCHEN_SETTINGS_VISILE:
      return {
        ...state,
        kitchenSettings: action.payload.kitchenSettings,
        couponSettings: false,
        settings: false
      };
    case Action.UI_SET_COUPON_SETTINGS_VISILE:
      return {
        ...state,
        couponSettings: action.payload.couponSettings,
        kitchenSettings: false,
        settings: false
      };
    default:
      return state;
  }
};
