import { LinkedComponent } from '@calo/dashboard-types';
import { ComponentService } from '@calo/services';
import { Dictionary } from '@calo/types';
import { round } from 'lodash-es';
import { FoodComponent } from '../interfaces';

export const calculateFractionWeight = (fc: any, quantity: number, newCookedWeight: number) => {
  const totalWeight = ComponentService.calculateTotalComponentAbsoluteWeight(fc) || 1;
  const fraction = quantity / totalWeight;
  const ingQuantity = newCookedWeight * fc.cookedRawFactor * fraction;
  return ingQuantity;
};

export const getComponentWeight = (allComponents: Dictionary<FoodComponent>, component: LinkedComponent) => {
  const comp = allComponents[component.id];
  return comp
    ? round(
        ComponentService.calculateComponentWeight(comp?.cups!, comp.measurementUnit, comp?.weight ?? 0, component.quantity),
        6
      )
    : 0;
};
