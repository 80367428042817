import { Brand, Localized } from '@calo/types';

export const makeFoodSlug = (name: Localized, brand: Brand, kitchen: string, isCustom?: boolean, id?: string): string => {
  const slug = isCustom ? slugify(['custom', name.en, id, kitchen, brand]) : slugify([name.en, kitchen, brand]);
  return slug;
};

export const slugify = (arr: any[]) => {
  let str = arr
    .join('-')
    .replace(/^\s+|\s+$/g, '')
    .toLowerCase();
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  str = str
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
  return str;
};
