import { Currency, PaymentMethod } from '@calo/types';

export const subscriberPaymentOptions = (currency: Currency) => {
  switch (currency) {
    case Currency.AED:
      return [PaymentMethod.cash, PaymentMethod.cc];
    case Currency.BHD:
      return [PaymentMethod.cash, PaymentMethod.cc, PaymentMethod.benefit];
    case Currency.SAR:
      return [PaymentMethod.cash, PaymentMethod.cc, PaymentMethod.applePay, PaymentMethod.mada, PaymentMethod.bnpl];
    case Currency.KWD:
      return [PaymentMethod.cash, PaymentMethod.cc, PaymentMethod.knet];
    case Currency.QAR:
      return [PaymentMethod.cash, PaymentMethod.cc, PaymentMethod.naps];
    default:
      return [PaymentMethod.cash, PaymentMethod.cc];
  }
};
