import {
  CodeChannel,
  Coupon,
  CouponRule,
  CouponRuleType,
  CouponUsedBy,
  CouponUsedByFilters,
  Permission
} from '@calo/dashboard-types';
import { PlanType } from '@calo/types';
import { deleteCoupon, getListWithParams, getRecord } from 'actions';
import { updateCoupon } from 'actions/coupons';
import { Button, CaloLoader, ConfirmationModal, ModalRef, Pagination } from 'components';
import { parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import ExcelJS, { Style } from 'exceljs';
import { saveAs } from 'file-saver';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { sumBy } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import CouponForm from './CouponForm';
import CouponUsageRow from './CouponUsageRow';
import Settings from './Settings';

const ExactCoupon = () => {
  const history = useHistory();
  const roles = useUserRoles();
  const { id } = useParams<{ id: string }>();
  const [channel, setChannel] = useState<CodeChannel>(CodeChannel.CALO_PROMOS);
  const [frequency, setFrequency] = useState<CouponRule>({
    type: CouponRuleType.SUBSCRIPTION_FREQUENCY,
    value: '' as any
  });
  const [planType, setPlanType] = useState<CouponRule>({ type: CouponRuleType.SUBSCRIPTION_PLAN, value: undefined! });

  const [emailDomain, setEmailDomain] = useState<CouponRule>({ type: CouponRuleType.EMAIL_DOMAIN, value: '' as any });
  const [creditCardH, setCreditCardH] = useState<CouponRule>({
    type: CouponRuleType.CREDIT_CARD_PREFIX,
    value: [] as any
  });
  const [page, setPage] = useState(0);

  const [filters, setFilters] = useState<CouponUsedByFilters>({
    phoneNumber: undefined
  });

  interface PaginatedCouponUsage {
    data: CouponUsedBy[];
    meta: {
      limit: number;
      total: number;
    };
  }

  const [exList, setExList] = useState<any>({ type: undefined!, value: [] as string[] });
  const [exclusiveMember, setExclusiveMember] = useState<boolean>(false);
  const [exType, setExType] = useState<string>('Add List');

  const { mutateAsync: deleteMutation } = useMutation(deleteCoupon);

  const confirmModalRef = useRef<ModalRef>();

  const { data } = useQuery(['coupons', id], getRecord, {
    suspense: true
  });
  const coupon = data as Coupon;

  const { data: usageData, isLoading } = useQuery<any, Error, PaginatedCouponUsage>(
    [
      `coupons/usage/${id}`,
      {
        page: page,
        filters: {
          phoneNumber: filters.phoneNumber
        }
      }
    ],
    getListWithParams,
    {
      suspense: false
    }
  );

  useEffect(() => {
    setPage(0);
    setFilters({ ...filters });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [filters]);

  const usageList = usageData?.data;

  const { mutateAsync: updateMutation } = useMutation(updateCoupon);

  const handleUpdateCoupon = async (values: any) => {
    const iPlan = values.rules.findIndex((r: any) => r.type === planType.type);
    const iFreq = values.rules.findIndex((r: any) => r.type === frequency.type);

    const iList = values.rules.findIndex((r: any) => r.type === exList.type);
    const iEmail = values.rules.findIndex((r: any) => r.type === emailDomain.type);
    const iCard = values.rules.findIndex((r: any) => r.type === creditCardH.type);

    if (iPlan > -1) {
      values.rules.splice(iPlan, 1);
    }
    if (iFreq > -1) {
      values.rules.splice(iFreq, 1);
    }

    if (iList > -1) {
      values.rules.splice(iList, 1);
    }

    if (iEmail > -1) {
      values.rules.splice(iEmail, 1);
    }
    if (iCard > -1) {
      values.rules.splice(iCard, 1);
    }

    if ((frequency.value as string) !== '') {
      values.rules.push(frequency);
    }

    if (exType === 'Add List') {
      if (exList.type && exList.value.length > 0) {
        values.rules.push(exList);
      }
    } else if (exType === 'Email domain') {
      if (emailDomain.type && emailDomain.value !== undefined) {
        values.rules.push(emailDomain);
      }
    } else {
      if (creditCardH.type && creditCardH.value !== undefined) {
        values.rules.push(creditCardH);
      }
    }

    if (Object.values(planType.value).length !== 5) {
      values.rules.push(planType);
    }
    const { channel, assignee, amount } = values;
    const allValues = { ...values, assignee: assignee?.id, amount: amount, channel: channel };

    await updateMutation({ id: id, ...allValues });
  };

  useEffect(() => {
    const iList = coupon.rules.findIndex((r) => r.type === CouponRuleType.EMAIL_EXISTS || r.type === CouponRuleType.PHONE_EXISTS);
    const iEmail = coupon.rules.findIndex((r) => r.type === CouponRuleType.EMAIL_DOMAIN);
    const iCard = coupon.rules.findIndex((r) => r.type === CouponRuleType.CREDIT_CARD_PREFIX);

    if (iList > -1) {
      setExList(coupon.rules[iList]);
      coupon.rules.splice(iList, 1);
      setExclusiveMember(true);
    }

    if (iEmail > -1) {
      setEmailDomain(coupon.rules[iEmail]);
      coupon.rules.splice(iEmail, 1);
      setExclusiveMember(true);
    }

    if (iCard > -1) {
      setCreditCardH(coupon.rules[iCard]);
      coupon.rules.splice(iCard, 1);
      setExclusiveMember(true);
    }
  }, []);

  useEffect(() => {
    setChannel(coupon.channel);
    const iMeal = coupon.rules.findIndex((r) => r.type === CouponRuleType.SUBSCRIPTION_PLAN);
    if (iMeal > -1) {
      if (Object.values(coupon.rules[iMeal].value).length === 0) {
        setPlanType({
          type: CouponRuleType.SUBSCRIPTION_PLAN,
          value: Object.values([
            PlanType.full,
            PlanType.skipBreakfast,
            PlanType.skipDinner,
            PlanType.custom,
            PlanType.businessLunch
          ]) as any
        });
      } else {
        setPlanType(coupon.rules[iMeal]);
      }
      coupon.rules.splice(iMeal, 1);
    } else {
      setPlanType({
        type: CouponRuleType.SUBSCRIPTION_PLAN,
        value: Object.values([
          PlanType.full,
          PlanType.skipBreakfast,
          PlanType.skipDinner,
          PlanType.custom,
          PlanType.businessLunch
        ]) as any
      });
    }

    const iFreq = coupon.rules.findIndex((r) => r.type === CouponRuleType.SUBSCRIPTION_FREQUENCY);
    if (iFreq > -1) {
      setFrequency(coupon.rules[iFreq]);
      coupon.rules.splice(iFreq, 1);
    }

    const iCard = coupon.rules.findIndex((r) => r.type === CouponRuleType.CREDIT_CARD_PREFIX);
    if (iCard > -1) {
      setCreditCardH(coupon.rules[iCard]);
      coupon.rules.splice(iCard, 1);
      setExclusiveMember(true);
    }
  }, []);

  const onExport = useCallback(async () => {
    if (!coupon) {
      return;
    }
    const border: Partial<Style> = {
      border: {
        top: { style: 'medium', color: { argb: 'D3D3D3' } },
        left: { style: 'medium', color: { argb: 'D3D3D3' } },
        bottom: { style: 'medium', color: { argb: 'D3D3D3' } },
        right: { style: 'medium', color: { argb: 'D3D3D3' } }
      }
    };

    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('coupon-analysis', {
      pageSetup: { fitToPage: true, orientation: 'portrait' }
    });

    worksheet.columns = [
      { header: 'Channel', width: 24, key: 'channel', style: border },
      { header: 'Assigned to', width: 24, key: 'name', style: border },
      { header: 'Phone number', width: 24, key: 'aPhoneNumber', style: border },
      { header: 'Email', width: 24, key: 'aEmail', style: border },
      { header: 'Used time', width: 10, key: 'usedTime', style: border },
      { header: 'Total value', width: 10, key: 'totalValue', style: border },
      { header: 'Currency', width: 12, key: 'currency', style: border },
      { header: 'Used by', width: 24, key: 'usedBy', style: border },
      { header: 'Phone number', width: 24, key: 'uPhoneNumber', style: border },
      { header: 'Email', width: 24, key: 'uEmail', style: border },
      { header: 'Action type', width: 24, key: 'actionType', style: border },
      { header: 'Used on', width: 24, key: 'usedOn', style: border },
      { header: 'Amount', width: 10, key: 'amount', style: border }
    ];

    const couponUsedBy = coupon.usedBy && coupon.usedBy[0];

    worksheet.addRow({
      name: coupon.assignee && typeof coupon.assignee === 'object' ? coupon.assignee.name : 'By Calo',
      channel: coupon.channel ?? '',
      aPhoneNumber: coupon.assignee && typeof coupon.assignee === 'object' ? coupon.assignee.phone : '',
      aEmail: coupon.assignee && typeof coupon.assignee === 'object' ? coupon.assignee.email : '',
      usedTime: coupon.usedTimes ? coupon.usedTimes : 0,
      totalValue: coupon.usedBy ? sumBy(coupon.usedBy, 'paymentAmount') : 0,
      currency: coupon.currency,
      usedBy: couponUsedBy?.user?.name,
      uPhoneNumber: couponUsedBy?.user?.phone,
      uEmail: couponUsedBy?.user?.email,
      actionType: couponUsedBy?.action,
      usedOn: couponUsedBy?.usedAt && format('MMM dd-yyyy')(parseISO(coupon.usedBy[0]?.usedAt)),
      amount: couponUsedBy?.paymentAmount
    });

    for (let i = 1; i < coupon.usedBy?.length; i++) {
      worksheet.addRow(
        {
          usedBy: coupon.usedBy[i]?.user?.name || '',
          uPhoneNumber: coupon.usedBy[i]?.user?.phone || '',
          uEmail: coupon.usedBy[i]?.user?.email || '',
          actionType: coupon.usedBy[i]?.action || '',
          usedOn: (coupon.usedBy[i]?.usedAt && format('MMM dd-yyyy')(parseISO(coupon.usedBy[i]?.usedAt))) || '',
          amount: coupon.usedBy[i]?.paymentAmount || 0
        },
        ''
      );
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, 'coupon-Analysis' + fileExtension);
  }, [data]);

  const handleDelete = async () => {
    await deleteMutation(id, {
      onSuccess: () => {
        history.push(Routes.couponsList);
      }
    });
  };

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  <Link to={Routes.couponsList}>Coupons</Link>
                </li>
                <li>{coupon!.code}</li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              {roles.includes(Permission.DELETE_COUPON) && (
                <Button
                  onClick={() => confirmModalRef.current?.open()}
                  icon="fas fa-trash"
                  className="mr-2 has-tooltip-danger"
                  danger
                  disabled={coupon.usedTimes > 0}
                  data-tooltip={coupon.usedTimes > 0 ? 'Coupon was used by users' : 'Delete coupon'}
                />
              )}
              <Button onClick={onExport} icon="fas fa-file-export" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="overflow-visible">
          <CouponForm
            coupon={coupon!}
            onSubmit={handleUpdateCoupon}
            frequency={frequency}
            channel={channel}
            setChannel={setChannel}
            setFrequency={setFrequency}
            planType={planType}
            setPlanType={setPlanType}
            exList={exList}
            setExList={setExList}
            exclusiveMember={exclusiveMember}
            setExclusiveMember={setExclusiveMember}
            emailDomain={emailDomain}
            setEmailDomain={setEmailDomain}
            creditCardH={creditCardH}
            setCreditCardH={setCreditCardH}
            exType={exType}
            setExType={setExType}
          />
        </div>

        <ConfirmationModal ref={confirmModalRef} values={coupon} action={handleDelete}>
          <div className="mt-4 mb-4 ml-4">
            <span className="flex">
              <label className="label">Deleting {coupon.code} will remove the valuable information related to it </label>
            </span>
          </div>
        </ConfirmationModal>
        {/* {coupon.category === CouponCategory.promo && (
          <div className="overflow-visible">
            <CouponData
              coupon={coupon}
            />
          </div>
        )} */}
        <div className="card has-table has-table-container-upper-radius mt-3">
          <div className="card-content">
            <div className="table-container overflow-y-auto">
              <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                <thead>
                  <tr className="bg-black">
                    <th style={{ color: 'white' }} className=" w-48">
                      Assigned to
                    </th>
                    {coupon.assignee && (
                      <>
                        <th style={{ color: 'white' }} className=" w-48">
                          Phone number
                        </th>
                        <th style={{ color: 'white' }} className=" w-48">
                          Email
                        </th>
                      </>
                    )}
                    <th style={{ color: 'white' }} className=" w-48">
                      Used time
                    </th>
                    <th style={{ color: 'white' }} className=" w-48">
                      Total value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <td>{!!coupon.assignee && typeof coupon.assignee === 'object' ? coupon.assignee.name : 'By Calo'}</td>
                  {!!coupon.assignee && typeof coupon.assignee === 'object' && (
                    <>
                      <td>{coupon.assignee.phone}</td>
                      <td>{coupon.assignee.email}</td>
                    </>
                  )}
                  <td>{coupon.usedTimes}</td>
                  <td>{typeof coupon.totalUsedValue === 'number' ? coupon.totalUsedValue.toFixed(3) : '???'}</td>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {roles.includes(Permission.VIEW_COUPON_USAGE_LIST) && (
          <div>
            {isLoading ? (
              <CaloLoader />
            ) : (
              <div className="card has-table has-table-container-upper-radius">
                <div className="card-content">
                  <div className="table-container overflow-y-auto">
                    <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                      <thead>
                        <tr className="bg-black">
                          <th style={{ color: 'white' }} className=" w-48">
                            Used by
                          </th>
                          <th style={{ color: 'white' }} className=" w-48">
                            Phone number
                          </th>
                          <th style={{ color: 'white' }} className=" w-48">
                            Email
                          </th>
                          <th style={{ color: 'white' }} className=" w-48">
                            Action type
                          </th>
                          <th style={{ color: 'white' }} className=" w-48">
                            Used on
                          </th>
                          <th style={{ color: 'white' }} className=" w-48">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {usageList?.map((couponUsage, index) => <CouponUsageRow key={`${index}`} couponUsage={couponUsage} />)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {usageData && (
          <Pagination
            isHidden={usageData.data.length === 0 || usageData.meta.total < 50}
            limit={usageData.meta.limit}
            total={usageData.meta.total}
            page={page}
            onChange={setPage}
          />
        )}
      </section>
      <Settings onFilter={setFilters} filters={filters} />
    </>
  );
};

export default ExactCoupon;
