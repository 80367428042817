import { getAddonSubscriptionMenu } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, ModalRef, Select2 } from 'components';
import FoodDietTypeTags from 'components/FoodDietTypeTags';
import Popup from 'components/Popup';
import { parseISO } from 'date-fns';
import { isBefore } from 'date-fns/fp';
import { getIngredientsToAvoid, sortFoodByMacros } from 'lib/index';
import { CustomComponentsByCategory, Delivery, Food, FoodWithPosition, Menu, MenuFood } from 'lib/interfaces';
import { intersection } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { Brand, DietType, FoodType, Kitchen } from '@calo/types';
import { Box, Stack, Typography } from '@mui/material';

import CustomMealsList from './CustomMealsList';
import FoodListItem from './FoodListItem';

interface SwapMealPopUpProps {
  delivery: Delivery;
  customFoodComponents: CustomComponentsByCategory;
  customFoodModalRef: React.MutableRefObject<ModalRef | undefined>;
  selectedMeal: FoodWithPosition;
  foodModalRef: React.MutableRefObject<ModalRef | undefined>;
  menu: Menu;
  menuLoading: boolean;
  isCustomComponentsLoading: boolean;
  setSelectedMeal: React.Dispatch<React.SetStateAction<FoodWithPosition | null | undefined>>;
  handleClose: () => void;
  handleReplace: (value: MenuFood) => Promise<void>;
  handleReplaceAddonSubscriptionFood: (value: MenuFood) => Promise<void>;
}

const SwapMealPopUp = ({
  customFoodModalRef,
  selectedMeal,
  delivery,
  menu,
  menuLoading,
  foodModalRef,
  customFoodComponents,
  isCustomComponentsLoading,
  handleClose,
  setSelectedMeal,
  handleReplace,
  handleReplaceAddonSubscriptionFood
}: SwapMealPopUpProps) => {
  const [customFood, setCustomFood] = useState<Food | null>(null);
  const [allMenuFood, setAllMenufood] = useState<MenuFood[]>();
  const sortOptions = Object.freeze({
    any: 'any',
    highestToLowestProtein: 'highest to lowest protein',
    highestToLowestCarb: 'highest to Lowest carb',
    lowestToHighestFat: 'lowest to highest fat'
  });
  const [sortFilter, setSortFilter] = useState<string>('');

  const menuFood = useMemo(() => {
    if (!selectedMeal) {
      return [];
    }

    let food = (menu?.food || []).filter((f) => intersection(f.type, selectedMeal.type).length > 0);

    if (isBefore(Date.now())(parseISO(delivery.day))) {
      food = food.filter((f) => (f.tags || []).includes(delivery.dietType || DietType.balanced));
    }
    setAllMenufood(food);

    return sortFoodByMacros(sortFilter, food);
  }, [
    menu,
    delivery,
    selectedMeal,
    sortFilter,
    sortOptions.highestToLowestProtein,
    sortOptions.highestToLowestCarb,
    sortOptions.lowestToHighestFat
  ]);

  const isAddonSubscriptionFood = Boolean(selectedMeal?.addedAs && selectedMeal?.addedAs === 'addonSubscription');

  const { data: addonSubscriptionData } = useQuery(
    [`addonSubscription/${delivery.kitchen}`, delivery.kitchen],
    () => getAddonSubscriptionMenu(delivery.kitchen || Kitchen.BH1),
    { enabled: isAddonSubscriptionFood }
  );

  const shouldRenderAddonSubscriptionMenu =
    isAddonSubscriptionFood &&
    addonSubscriptionData &&
    addonSubscriptionData?.addonSubscriptionMenu &&
    addonSubscriptionData?.addonSubscriptionMenu?.length > 0;
  const filteredAddonSubscriptionMenu = shouldRenderAddonSubscriptionMenu
    ? addonSubscriptionData?.addonSubscriptionMenu
        ?.filter((data: any) => data?.addonCategory?.includes(selectedMeal?.belongsTo))
        ?.filter((r: any) => r?.id !== selectedMeal?.id)
    : [];

  return (
    <Popup
      ref={foodModalRef}
      title={'Replace Meal'}
      maxWidth={'lg'}
      onClose={() => {
        setSelectedMeal(null);
        setCustomFood(null);
        handleClose();
      }}
    >
      <Box sx={{ ml: 2, mb: 2 }}>
        <Select2
          label="Sort by"
          value={sortFilter}
          name="sort"
          onChange={(data) => setSortFilter(data.target.value)}
          options={Object.values(sortOptions).map((option) => ({
            value: option,
            label: option
          }))}
        />
      </Box>

      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '16px', paddingX: '16px' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{selectedMeal?.name.en}</Typography>
        <Typography sx={{ fontSize: '12px', width: '50%' }}>
          {getIngredientsToAvoid(selectedMeal, delivery).map((i: any, index: number) =>
            i.isAlergen ? (
              <span key={index} className="text-red-500">
                {i.name},{' '}
              </span>
            ) : (
              <span key={index}> {i.name}, </span>
            )
          )}
        </Typography>
        <Typography
          sx={{ display: 'flex', flexDirection: 'row', gap: '2px', alignSelf: 'flex-start', width: '15%', flexWrap: 'wrap' }}
        >
          {selectedMeal?.tags?.map((r) => <FoodDietTypeTags key={r} tag={r} />)}
        </Typography>
        <Typography>{selectedMeal?.size}</Typography>
      </Stack>
      {menuLoading && <CaloLoader />}
      {menuFood.length === 0 && !menuLoading && (
        <Typography display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ my: 2 }}>
          No meals to swap with
        </Typography>
      )}
      <Box
        sx={{
          borderWidth: '1px',
          padding: '10px',
          borderRadius: '8px',
          borderColor: caloTheme.palette.neutral100
        }}
      >
        <Stack
          sx={{
            maxHeight: '22rem',
            overflowY: 'scroll',
            padding: '10px',
            '&::-webkit-scrollbar': {
              width: '2px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '10px'
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555'
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1'
            }
          }}
        >
          {shouldRenderAddonSubscriptionMenu &&
            filteredAddonSubscriptionMenu?.map((r: any, index: number) => (
              <FoodListItem
                key={r.id}
                food={r}
                handleClick={handleReplaceAddonSubscriptionFood}
                selectedFood={selectedMeal!}
                allMenuFood={allMenuFood || []}
                isLastItem={index === filteredAddonSubscriptionMenu.length - 1}
              />
            ))}
          {!isAddonSubscriptionFood &&
            menuFood?.map((food, index) => (
              <FoodListItem
                key={food.id}
                food={food}
                isLastItem={index === menuFood.length - 1}
                selectedFood={selectedMeal}
                allMenuFood={allMenuFood ?? []}
                handleClick={handleReplace}
              />
            ))}
        </Stack>
      </Box>
      {delivery.brand !== Brand.MEALO &&
        (selectedMeal?.type?.includes(FoodType.lunch) || selectedMeal?.type?.includes(FoodType.dinner)) &&
        !shouldRenderAddonSubscriptionMenu && (
          <CustomMealsList
            customFood={customFood}
            delivery={delivery}
            selectedMeal={selectedMeal}
            isCustomComponentsLoading={isCustomComponentsLoading}
            setCustomFood={setCustomFood}
            handleReplace={handleReplace}
            customFoodComponents={customFoodComponents}
            customFoodModalRef={customFoodModalRef}
          />
        )}
    </Popup>
  );
};
export default SwapMealPopUp;
