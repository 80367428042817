export const groupedAllMeals = (selectedStatsData: any[]) => {
  const groupedData: any = {};
  for (const item of selectedStatsData) {
    if (groupedData[item.key]) {
      groupedData[item.key] = {
        ...item,
        name: {
          en: item.name
        },
        quantity: (groupedData[item.key].quantity ?? 0) + item.quantity,
        modifiedQuantity: (groupedData[item.key].modifiedQuantity ?? 0) + item.modifiedQuantity,
        specialRequestsQuantity: (groupedData[item.key].specialRequestsQuantity ?? 0) + item.specialRequestsQuantity
      };
    } else {
      groupedData[item.key] = {
        ...item,
        name: {
          en: item.name
        }
      };
    }
  }
  return groupedData;
};
