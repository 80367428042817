import LoadingButton from '@mui/lab/LoadingButton';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import styles from './styles';

interface ExportButtonProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: (event: any) => void;
}

const ExportButton = ({ onClick, isDisabled, isLoading }: ExportButtonProps) => {
  return (
    <LoadingButton
      variant="outlined"
      disabled={isDisabled || isLoading}
      aria-label="Download-subscription-list"
      sx={styles.exportButton}
      onClick={onClick}
      startIcon={
        <Icon
          name="importFile"
          size={5}
          style={{
            stroke: isDisabled || isLoading ? caloTheme.palette.neutral300 : caloTheme.palette.neutral900
          }}
        />
      }
    >
      Download
    </LoadingButton>
  );
};

export default ExportButton;
