import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  titleText: { fontWeight: 600, fontSize: '20px', lineHeight: '24px', mb: '8px' },
  datePickerStack: { justifyContent: 'start', ml: 1, mt: '8px' },
  typeStack: { my: 1, width: '100%', mx: 'auto' },
  typeText: { fontWeight: 600, fontSize: '16px', fontStyle: 'normal', lineHeight: '20px' },
  divider: { color: caloTheme.palette.neutral50, borderWidth: 1, mx: 2, my: '-8px', display: 'flex' },
  leftTextType: { float: 'left', width: '75%' },
  rightTextType: { float: 'right', width: '40%' },
  applyFilterButton: {
    textTransform: 'none',
    height: '45px',
    mx: 'auto',
    my: 1,
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
    padding: '14px 20px 14px 20px',
    color: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    '&:hover': {
      backgroundColor: caloTheme.palette.primary100,
      borderColor: caloTheme.palette.primary500
    }
  }
};

export default styles;
