import { Menu, MenuItem, TextField } from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';

interface Option {
  slug: string;
}

interface Values {
  blockMealList: string[];
}

interface SearchableDropdownProps {
  options: Option[];
  setFoodList: React.Dispatch<React.SetStateAction<{ slug: string }[]>>;
  setFieldValue: (field: string, value: any) => void;
  disabled: boolean;
  setSearchQuery: (query: string) => void;
  values: Values;
}

function extractName(slug: string) {
  const slugParts = slug.split('-');
  const nameParts = slugParts.slice(0, -2);
  return nameParts.join(' ');
}

export const SearchableDropdown = ({
  options,
  setFoodList,
  setFieldValue,
  disabled,
  setSearchQuery,
  values
}: SearchableDropdownProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const anchorRef = useRef<HTMLInputElement | null>(null);

  const debouncedSetSearchQuery = useCallback(
    debounce((query: string) => {
      if (query.length <= 0) {
        setFoodList([]);
      }
      setSearchQuery(query);
      setShowDropdown(query.length > 0);
    }, 500),
    []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchQuery(e.target.value);
  };

  const handleOptionClick = (option: Option) => {
    if (!selectedOptions.some((selected) => selected.slug === option.slug)) {
      const newSelectedOptions = [...selectedOptions, option];
      const updatedBlockMealList = [...values.blockMealList, option.slug];
      setSelectedOptions(newSelectedOptions);
      setFieldValue('blockMealList', updatedBlockMealList);
    }
    setSearchQuery('');
    setShowDropdown(false);
  };

  return (
    <>
      <TextField
        id="search-field"
        ref={anchorRef}
        onChange={handleInputChange}
        InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
        placeholder="Search..."
        type="text"
        fullWidth
        inputProps={{ style: { width: '100%' } }}
        disabled={disabled}
      />
      <Menu
        anchorEl={anchorRef.current}
        open={showDropdown && options.length > 0}
        onClose={() => setShowDropdown(false)}
        PaperProps={{ style: { maxHeight: 200, width: anchorRef.current?.clientWidth } }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleOptionClick(option)}>
            {extractName(option.slug)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
