import { Box, Button, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import Popup from 'components/Popup';

interface CommentViewPopupProps {
  commentRef: any;
  selectedComplaint: any;
  setSelectedComplaint: (value: undefined) => void;
}

const CommentViewPopup = ({ commentRef, selectedComplaint, setSelectedComplaint }: CommentViewPopupProps) => (
  <Popup
    maxWidth="lg"
    fullWidth
    title="Comment"
    ref={commentRef}
    onClose={() => {
      commentRef.current?.close();
    }}
  >
    <Box>
      <Stack
        sx={{
          flexDirection: 'row',
          display: 'flex',
          width: '100%',
          marginX: 2
        }}
      >
        <Typography
          sx={{
            color: caloTheme.palette.neutral900,
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '20px'
          }}
        >
          {selectedComplaint && selectedComplaint.comments
            ? selectedComplaint.comments[selectedComplaint?.comments.length - 1].text
            : ''}
        </Typography>
      </Stack>
      <Stack
        sx={{
          justifyContent: 'center',
          flexDirection: 'row',
          display: 'flex',
          width: '100%',
          marginTop: 2
        }}
      >
        <Button
          variant="text"
          sx={{
            width: '156px',
            color: caloTheme.palette.primary500,
            fontFamily: 'Roboto',
            textAlign: 'center',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '23px',
            textTransform: 'capitalize'
          }}
          onClick={() => {
            commentRef.current?.close();
            setSelectedComplaint(undefined);
          }}
        >
          Done
        </Button>
      </Stack>
    </Box>
  </Popup>
);
export default CommentViewPopup;
