import { Permission } from '@calo/dashboard-types';
import { Add as AddIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  InputLabel,
  List,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { getLogisticsSchedule, updateLogisticsSchedule } from 'actions/aiDashboard';
import { caloTheme } from 'assets/images/theme/calo';
import CaloLoader from 'components/CaloLoader';
import { Province } from 'lib/enums';
import { ToastSuccessMsgs } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ProvinceSchedule } from '../types';
import TimeSlot from './components/TimeSlot';

const LogisticsSchedule = () => {
  const [currentProvince, setCurrentProvince] = useState<Province>(Province.AE);
  const [schedule, setSchedule] = useState<ProvinceSchedule | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const roles = useUserRoles();
  const isUserAllowedEdit = roles.includes(Permission.UPDATE_AI_LOGISTICS);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const fetchedSchedule = await getLogisticsSchedule(currentProvince);
    setSchedule(fetchedSchedule);
    setLoading(false);
  }, [currentProvince]);

  useEffect(() => {
    fetchData();
  }, [currentProvince, fetchData]);

  const handleSave = async () => {
    if (!schedule) return;
    try {
      setLoading(true);
      await updateLogisticsSchedule(currentProvince, schedule);
      ToastSuccessMsgs({ status: 200, action: 'Schedule updated successfully' });
    } catch {
      toast(`Unable to update schedule`, { type: 'error', autoClose: 2000 });
    } finally {
      setLoading(false);
    }
  };

  const updateSchedule = (updater: (prevSchedule: ProvinceSchedule) => ProvinceSchedule) => {
    setSchedule((prevSchedule) => (prevSchedule ? updater(prevSchedule) : null));
  };

  const handleTimeSlotChange = (day: string, index: number, field: 'start' | 'end', value: string) => {
    updateSchedule((prevSchedule) => {
      const newSchedule = { ...prevSchedule };
      newSchedule.days[day].timeslots[index][field] = value;
      return newSchedule;
    });
  };

  const handleManagerChange = (day: string, timeSlotIndex: number, managerIndex: number, value: string) => {
    updateSchedule((prevSchedule) => {
      const newSchedule = { ...prevSchedule };
      newSchedule.days[day].timeslots[timeSlotIndex].managers[managerIndex] = value;
      return newSchedule;
    });
  };

  const addTimeSlot = (day: string) => {
    updateSchedule((prevSchedule) => {
      const newSchedule = { ...prevSchedule };
      newSchedule.days[day].timeslots.push({ start: '00:00', end: '00:00', managers: [''] });
      return newSchedule;
    });
  };

  const removeTimeSlot = (day: string, index: number) => {
    updateSchedule((prevSchedule) => {
      const newSchedule = { ...prevSchedule };
      newSchedule.days[day].timeslots.splice(index, 1);
      return newSchedule;
    });
  };

  const addManager = (day: string, timeSlotIndex: number) => {
    updateSchedule((prevSchedule) => {
      const newSchedule = { ...prevSchedule };
      newSchedule.days[day].timeslots[timeSlotIndex].managers.push('');
      return newSchedule;
    });
  };

  const removeManager = (day: string, timeSlotIndex: number, managerIndex: number) => {
    updateSchedule((prevSchedule) => {
      const newSchedule = { ...prevSchedule };
      newSchedule.days[day].timeslots[timeSlotIndex].managers.splice(managerIndex, 1);
      return newSchedule;
    });
  };

  if (!schedule || loading) {
    return <CaloLoader />;
  }

  return (
    <Box style={{ marginLeft: 16 }}>
      <Typography variant="h4" gutterBottom>
        Schedule Editor
      </Typography>
      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <InputLabel>Province</InputLabel>
        <Select value={currentProvince} onChange={(e) => setCurrentProvince(e.target.value as Province)} label="Province">
          {Object.keys(Province).map((province) => (
            <MenuItem key={province} value={province}>
              {province}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography variant="h5" gutterBottom>
        {schedule.country} - Kitchen {schedule.kitchen}
      </Typography>
      {Object.entries(schedule.days).map(([day, daySchedule]) => (
        <Accordion key={day}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{day.charAt(0).toUpperCase() + day.slice(1)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {daySchedule.timeslots.map((timeslot, timeSlotIndex) => (
                <TimeSlot
                  key={timeSlotIndex}
                  day={day}
                  timeslot={timeslot}
                  timeSlotIndex={timeSlotIndex}
                  onTimeSlotChange={handleTimeSlotChange}
                  onManagerChange={handleManagerChange}
                  onRemoveTimeSlot={removeTimeSlot}
                  onAddManager={addManager}
                  onRemoveManager={removeManager}
                />
              ))}
            </List>
            <Button startIcon={<AddIcon />} onClick={() => addTimeSlot(day)} style={{ marginTop: 16 }}>
              Add Time Slot
            </Button>
          </AccordionDetails>
        </Accordion>
      ))}
      <Button
        variant="contained"
        aria-label="save-changes"
        onClick={handleSave}
        disabled={!isUserAllowedEdit}
        sx={{
          width: '200px',
          height: '50px',
          lineHeight: '17px',
          fontWeight: 600,
          fontSize: '14px',
          borderRadius: '8px',
          marginTop: '16px',
          backgroundColor: caloTheme.palette.primary500,
          borderColor: caloTheme.palette.primary500,
          color: 'white',
          '&:hover': {
            backgroundColor: caloTheme.palette.primary500,
            borderColor: caloTheme.palette.primary600
          }
        }}
      >
        Save Changes
      </Button>
    </Box>
  );
};

export default LogisticsSchedule;
