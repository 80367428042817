import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { Brand, Country, Kitchen } from '@calo/types';
import { Box, Button, Card, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

import { getListWithParams, toggleUISettings } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Icon, Pagination } from 'components';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { BusinessAccountFilters, PaginatedBusinessAccount } from 'lib/interfaces';
import AccountListRow from './AccountListRow';
import Settings from './Settings';

interface AccountListProps {
  history: any;
}
const AccountList = ({ history }: AccountListProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const roles = useUserRoles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<BusinessAccountFilters>({
    kitchen: Kitchen.BH1,
    brand: Brand.CALO,
    country: Country.BH,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data, isLoading } = useQuery<any, Error, PaginatedBusinessAccount>(
    [
      'business/users',
      {
        page,
        filters: {
          brand: filters.brand,
          fullName: filters.fullName ? filters.fullName : undefined,
          phoneNumber: filters.phoneNumber ? filters.phoneNumber : undefined,
          email: filters.email ? filters.email : undefined
        },
        limit: 50
      }
    ],
    getListWithParams,
    {
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  useEffect(() => {
    setPage(0);
  }, [filters]);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: 2,
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          display={'flex'}
          flexDirection="row"
          justifyContent={'space-between'}
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
          width="100%"
        >
          <Stack width={'50%'} display={'flex'} flexDirection={'row'} alignItems="start" justifyContent={'space-between'}>
            <Typography
              variant="h3"
              sx={{
                mt: 'auto',
                fontWeight: 600,
                fontSize: '33px',
                lineHeight: '40px',
                color: caloTheme.palette.neutral900,
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              Accounts
            </Typography>
          </Stack>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems="end"
            sx={{
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
                flexDirection: 'column'
              }
            }}
          >
            <Button
              aria-label="filter-subscription-list"
              sx={{
                cursor: 'pointer',
                my: 'auto',
                '&:hover': {
                  backgroundColor: 'white',
                  borderColor: 'none'
                },
                color: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary500,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  justifyItems: 'center',
                  margin: 'auto',
                  marginTop: 4,
                  width: 'auto'
                }
              }}
              onClick={() => dispatch(toggleUISettings())}
            >
              {<Icon name="filter" size={6} className="w-10 h-18" />}
            </Button>
            {/* <Button
              variant="outlined"
              aria-label="Download-subscription-list"
              sx={{
                textTransform: 'none',
                height: '45px',
                ml: 2,
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                borderRadius: '8px',
                padding: '14px 20px 14px 20px',
                color: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary500,
                '&:hover': {
                  backgroundColor: caloTheme.palette.primary100,
                  borderColor: caloTheme.palette.primary500,
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  justifyItems: 'center',
                  margin: 'auto',
                  marginTop: 4,
                  width: 'auto'
                }
              }}
              disabled
              onClick={() => exportAccounts(filters)}
              startIcon={<Icon name='importFile' size={6} style={{ stroke: (data && data.data.length === 0 || isLoading) ? caloTheme.palette.neutral300 : caloTheme.palette.primary500 }} />}
            >
              Download
            </Button> */}
            {roles.includes(Permission.CREATE_BUSINESS_USER) && (
              <Button
                variant="outlined"
                aria-label="create-subscription-list"
                sx={{
                  textTransform: 'none',
                  height: '45px',
                  ml: 2,
                  lineHeight: '17px',
                  fontWeight: 600,
                  fontSize: '14px',
                  borderRadius: '8px',
                  padding: '14px 20px 14px 20px',
                  color: caloTheme.palette.primary500,
                  borderColor: caloTheme.palette.primary500,
                  '&:hover': {
                    backgroundColor: caloTheme.palette.primary100,
                    borderColor: caloTheme.palette.primary500
                  },
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    justifyItems: 'center',
                    margin: 'auto',
                    marginTop: 4,
                    width: 'auto'
                  }
                }}
                onClick={() => history.push(Routes.newAccounts)}
                startIcon={'+'}
              >
                Create Business Account
              </Button>
            )}
          </Stack>
        </Box>
      </Card>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <>
          <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px'
              }}
            >
              Business Accounts List
            </Typography>

            {isLoading ? (
              <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                <CaloLoader />
              </Stack>
            ) : (
              <>
                <Table
                  sx={{
                    marginY: '4px',
                    minHeight: '120px',
                    overflow: 'auto',
                    width: '100%',
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      flexDirection: 'column'
                    }
                  }}
                >
                  <TableHead
                    style={{ borderRadius: '8px' }}
                    sx={{
                      backgroundColor: caloTheme.palette.neutral50,
                      color: 'black',
                      flexWrap: 0,
                      justifyContent: 'space-between',
                      width: '100%',
                      borderRadius: '8px'
                    }}
                  >
                    <TableRow
                      sx={{
                        backgroundColor: caloTheme.palette.neutral50,
                        color: 'black',
                        width: '100%',
                        flexWrap: 0,
                        justifyContent: 'space-between'
                      }}
                    >
                      <StyledTableCell style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
                        Business Name
                      </StyledTableCell>
                      <StyledTableCell>Phone</StyledTableCell>
                      <StyledTableCell sx={{ maxWidth: '239px', minWidth: '239px' }}>Email</StyledTableCell>
                      <StyledTableCell style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}>
                        Kitchen
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {!data || (data && data.data.length === 0) ? (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell colSpan={5}>
                          <Typography
                            sx={{
                              textAlign: 'center',
                              fontSize: '24px',
                              color: caloTheme.palette.neutral400
                            }}
                          >
                            No Accounts
                          </Typography>
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {data && data.data.map((accountUser) => <AccountListRow key={accountUser.id} accountUser={accountUser} />)}
                    </TableBody>
                  )}
                </Table>
              </>
            )}
            {data && (
              <Pagination
                isHidden={data.meta.total === 0}
                limit={data.meta.limit}
                total={data.meta.total || 0}
                page={page}
                onChange={setPage}
              />
            )}
          </Box>
          <Settings onFilter={setFilters} filters={filters} />
        </>
      </Card>
    </>
  );
};
export default AccountList;
