import { CreateMapReq, UpdateMapReq } from '@calo/dashboard-types';
import { Country, Kitchen } from '@calo/types';

import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import mutation from './mutation';

export const updateMap = async (payload: UpdateMapReq & { id: string; country: Country; kitchen: Kitchen }) => {
  const { id, country, kitchen, ...rest } = payload;
  const { data, status } = await client.put(`${country}/map/${id}/kitchen/${kitchen}`, rest);
  mutation([`${country}/map`, id], data);
  ToastSuccessMsgs({ status, action: 'Map Updated' });
};

export const createMap = async (payload: CreateMapReq) => {
  const { data, status } = await client.post(`${payload.country}/map`, payload);
  mutation([`${payload.country}/map`, data.id], data);
  ToastSuccessMsgs({ status, action: 'Map Created' });
};

export const getDriversPositions = async (driverIds: string[], country: Country) => {
  const { data } = await client.get(`/tracking/${country}/positions`, { params: { driverIds } });
  return data;
};
