import { Card, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { format, parseISO } from 'date-fns/fp';
import { memo } from 'react';

interface AddonSubscriptionCardProps {
  name: string;
  startDate: string;
  endDate: string;
  isSelected: boolean;
  isDisabled: boolean;
  onPressRemove: ({ category, startDate, endDate }: { category: string; endDate: string; startDate: string }) => void;
}

export const AddonSubscriptionCard = memo(
  ({ isSelected, name, startDate, endDate, isDisabled, onPressRemove }: AddonSubscriptionCardProps) => {
    return (
      <Card
        sx={{
          backgroundColor: isSelected ? caloTheme.palette.primary100 : caloTheme.palette.neutral100,
          width: '49%',
          padding: '10px'
        }}
      >
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Typography
            variant="h6"
            sx={{
              fontSize: '19px',
              lineHeight: '23px',
              my: 'auto',
              mx: 2,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600
            }}
          >
            {name}
          </Typography>
          <span
            style={{ display: isDisabled ? 'none' : 'inline' }}
            onClick={() => onPressRemove({ category: name, startDate, endDate })}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '16px',
                lineHeight: '23px',
                my: 'auto',
                mx: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                color: caloTheme.palette.primary500,
                cursor: 'pointer'
              }}
            >
              Remove
            </Typography>
          </span>
        </Stack>
        <Stack marginTop={'18px'} flexDirection={'row'} justifyContent={'space-between'}>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '23px',
              my: 'auto',
              mx: 2,
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            Start Date : {format('dd-MM-yyyy')(parseISO(startDate))}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '23px',
              my: 'auto',
              mx: 1.2,
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            End Date : {format('dd-MM-yyyy')(parseISO(endDate))}
          </Typography>
        </Stack>
      </Card>
    );
  }
);
