import { FoodFilters } from '@calo/dashboard-types';
import { Country } from '@calo/types';
import { toggleUISettings } from 'actions';
import { Select, SideDrawer } from 'components';
import { getAccessibleCountries } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';

interface SettingsProps {
  onFilter: (values: FoodFilters) => any;
  filters: FoodFilters;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select
          label="Country"
          value={filters.country}
          onChange={(country: any) =>
            onFilter({
              ...filters,
              ...(country && {
                country: country.value
              })
            })
          }
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
      </section>
    </SideDrawer>
  );
};

export default Settings;
