import React, { DetailedHTMLProps, TextareaHTMLAttributes } from 'react';
import cx from 'classnames';
interface TextAreaProps extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label?: string;
  error?: boolean;
}

const TextArea = ({ label, error, ...rest }: TextAreaProps) => (
  <div className="field">
    {label && <label className="label">{label}</label>}
    <div className="control">
      <textarea
        className={cx('textarea', {
          'border-red-500': error
        })}
        {...rest}
      />
    </div>
  </div>
);

export default TextArea;
