import cx from 'classnames';

import { Permission } from '@calo/dashboard-types';
import { PermissionService } from '@calo/services';
import { Food } from '@calo/types';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { skipMeal, unSkipMeal } from 'actions';
import { Button } from 'components';
import FoodDietTypeTags from 'components/FoodDietTypeTags';
import { useUserRoles } from 'lib/hooks';
import { Delivery, FoodWithPosition } from 'lib/interfaces';
import { useState } from 'react';

interface FoodRowProps {
  item: FoodWithPosition;
  delivery: Delivery;
  removeFromList: (food: Food) => void;
  selectRow: (food: FoodWithPosition) => void;
  disableEditButton?: boolean;
  setCustomMeal?: (value: Food) => void;
  setSwapMeal: (value: boolean) => void;
}

const FoodRow = ({ delivery, setSwapMeal, item, selectRow, removeFromList, disableEditButton, setCustomMeal }: FoodRowProps) => {
  const [open, setOpen] = useState(false);

  const handleEditMeal = (item: FoodWithPosition) => {
    if (item.isCustom) {
      setCustomMeal!(item);
      selectRow(item);
    } else {
      selectRow(item);
    }
  };

  const roles = useUserRoles();

  const onSkip = async () => {
    await (item.skipped
      ? unSkipMeal({ deliveryId: delivery.id, foodId: item.id })
      : skipMeal({ deliveryId: delivery.id, foodId: item.id }));
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {item.skipped && (
            <b
              className={cx('', {
                'text-cRed': item.skipped
              })}
            >
              {' '}
              SKIPPED
            </b>
          )}{' '}
          {`${item.isGiftedItem ? `(Gift) ${item.name?.en}` : `${item.name?.en}`}`}{' '}
        </TableCell>
        <TableCell>{item.size}</TableCell>
        <TableCell>{item.type?.join(', ')}</TableCell>
        <TableCell>{item.macros?.cal}</TableCell>
        <TableCell>{item.macros?.protein}</TableCell>
        <TableCell>{item.macros?.carbs}</TableCell>
        <TableCell>{item.macros?.fat}</TableCell>
        <TableCell>
          {item.tags?.length > 0 && (
            <div className="tags capitalize">
              {item.tags.map((type, i) => (
                <FoodDietTypeTags key={i} tag={type} />
              ))}
            </div>
          )}
        </TableCell>
        <TableCell>
          {roles.includes(Permission.REPLACE_FOOD_DELIVERY_MENU) && !item.skipped && (
            <Button
              icon="fas fa-edit"
              onClick={() => {
                setSwapMeal(true);
                handleEditMeal(item);
              }}
              className="mr-4"
              disabled={disableEditButton}
            />
          )}
          {roles.includes(Permission.DELETE_FOOD_DELIVERY_MENU) && !item.skipped && (
            <Button
              icon="fas fa-trash"
              disabled={(!PermissionService.deliveryCanBeEdited(delivery) && !disableEditButton) || item.isGiftedItem}
              onClick={() => removeFromList(item)}
              className="has-tooltip-danger mr-4"
              data-tooltip={
                !PermissionService.deliveryCanBeEdited(delivery) && !disableEditButton
                  ? 'You cannot delete after the lock-up time'
                  : undefined
              }
            />
          )}
          {roles.includes(Permission.DELETE_FOOD_DELIVERY_MENU) && (
            <Button
              content={item.skipped ? 'Un-Skip' : 'Skip'}
              disabled={(!PermissionService.deliveryCanBeEdited(delivery) && !disableEditButton) || item.isGiftedItem}
              onClick={onSkip}
              className="has-tooltip-danger font-bold"
              data-tooltip={
                !PermissionService.deliveryCanBeEdited(delivery) && !disableEditButton
                  ? 'You cannot skip a meal after the lock-up time'
                  : undefined
              }
            />
          )}
        </TableCell>
      </TableRow>
      {(item.components ?? []).length > 0 && (
        <TableRow>
          <TableCell></TableCell>
          <TableCell style={{ padding: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Component</TableCell>
                    <TableCell style={{ width: '5rem' }}>Qty</TableCell>
                    <TableCell style={{ width: '5rem' }}>Cal</TableCell>
                    <TableCell style={{ width: '5rem' }}>Protein</TableCell>
                    <TableCell style={{ width: '5rem' }}>Carbs</TableCell>
                    <TableCell style={{ width: '5rem' }}>Fat</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(item.components ?? []).map((component) => (
                    <TableRow key={component.id}>
                      <TableCell>{component.name?.en}</TableCell>
                      <TableCell>{component.quantity}</TableCell>
                      <TableCell>{Math.round(component.quantity * (component.macros?.cal ?? 0))}</TableCell>
                      <TableCell>{Math.round(component.quantity * (component.macros?.protein ?? 0))}</TableCell>
                      <TableCell>{Math.round(component.quantity * (component.macros?.carbs ?? 0))}</TableCell>
                      <TableCell>{Math.round(component.quantity * (component.macros?.fat ?? 0))}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default FoodRow;
