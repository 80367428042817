import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Button } from '@mui/material';
import { Icon, ModalRef } from 'components';
import { format, parseISO } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

interface TicketsRowProps {
  ticket: any;
  setSelectedTicket: (values: any) => void;
  ticketModalRef: React.MutableRefObject<ModalRef | undefined>;
}

const TicketsRow = ({ ticketModalRef, ticket, setSelectedTicket }: TicketsRowProps) => {
  const location = useLocation();
  const params = location.search.split('ticketId=');

  useEffect(() => {
    if (ticket.id === params[1]) {
      setSelectedTicket(ticket);
      ticketModalRef.current?.open();
    }
  }, []);

  const confirmClickHandler = () => {
    ticketModalRef.current?.open();
    history.push(`${Routes.tickets}?ticketId=${ticket.id}`);
    setSelectedTicket(ticket);
  };

  return (
    <tr>
      <td>
        <p className="text-gray-300 text-sm">{format('dd/MM/yyyy')(parseISO(ticket.createdAt))}</p>
      </td>
      <td>
        <p className="capitalize">{ticket.data.customer.name}</p>
      </td>
      <td>{ticket.data.customer.phoneNumber}</td>
      <td>{ticket.createdBy.name}</td>
      <td>{ticket.createdBy.phoneNumber}</td>
      <td>{ticket.type}</td>
      <td>{ticket.data.customer.country}</td>
      <td>
        <span className="flex flex-col">
          <a
            onClick={() =>
              window.open(`https://maps.google.com/?q=${ticket.data.oldLocation.lat}, ${ticket.data.oldLocation.lng}`)
            }
          >
            {' '}
            Old Marker{' '}
          </a>
          <a
            onClick={() =>
              window.open(`https://maps.google.com/?q=${ticket.data.newLocation.lat}, ${ticket.data.newLocation.lng}`)
            }
          >
            {' '}
            New marker{' '}
          </a>
        </span>
      </td>
      <td>
        {ticket.status === 'approved' ? (
          <p className="text-green-500">Confirmed</p>
        ) : ticket.status === 'pending' ? (
          <div className="flex flex-row gap-3.5">
            <Button
              onClick={confirmClickHandler}
              sx={{
                backgroundColor: '#57AE7F',
                borderRadius: '8px',
                width: '32px',
                minWidth: '32px',
                padding: 0,
                '&:hover': {
                  backgroundColor: '#57AE7F'
                }
              }}
            >
              <CheckRoundedIcon sx={{ color: 'white' }} strokeWidth="30px" />
            </Button>
            <Icon
              name="reject"
              className="mr-4 border-2 bg-red-500 border-red-500 rounded-lg cursor-pointer"
              onClick={() => {
                ticketModalRef.current?.open();
                history.push(`${Routes.tickets}?ticketId=${ticket.id}`);
                setSelectedTicket(ticket);
              }}
              size={8}
            />
          </div>
        ) : (
          <p className="text-red-500">Rejected</p>
        )}
      </td>
    </tr>
  );
};

export default TicketsRow;
