import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  mainContainer: { width: '100%', border: 'none', borderRadius: '8px', marginTop: '8px' },
  mainBox: {
    padding: 2,
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      width: 'auto',
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'column'
    }
  },
  selectedDateText: {
    mr: 2,
    fontSize: '19px',
    lineHeight: '23px',
    fontFamily: caloTheme.typography.fontFamily,
    fontWeight: 600
  },
  deliveryStatusText: {
    mt: '3px',
    fontWeight: 600,
    color: caloTheme.palette.red,
    fontSize: '14px',
    lineHeight: '17px',
    fontFamily: caloTheme.typography.fontFamily
  },
  deliveryDeliveredAtText: {
    mt: '3px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    fontFamily: caloTheme.typography.fontFamily
  },
  titleText: {
    ml: 2,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    fontFamily: caloTheme.typography.fontFamily
  },
  text: {
    ml: 2,
    mt: 2,
    letterSpacing: '-0.02em',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    fontFamily: caloTheme.typography.fontFamily
  }
};

export default styles;
