import { useEffect, useState } from 'react';

import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { caloTheme } from 'assets/images/theme/calo';
import CaloLoader from 'components/CaloLoader';
import Popup from 'components/Popup';

import { Button, Stack } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';

import WarningText from './WarningText';

type ImagePopupProps = {
  imageUrl: string | undefined;
  mainRef: any;
} & (
  | {
      canEdit: true;
      originalImage: string;
      sizeWarning: string;
      dimensionsWarning: string;
      setSizeWarning: React.Dispatch<React.SetStateAction<string>>;
      setDimensionsWarning: React.Dispatch<React.SetStateAction<string>>;
    }
  | {
      canEdit: false;
    }
);

const ImagePopup = ({ imageUrl, mainRef, ...props }: ImagePopupProps) => {
  const [hasImageLoaded, setHasImageLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const loadImageDetails = async () => {
      if (props.canEdit) {
        try {
          const response = await fetch(props.originalImage);
          const blob = await response.blob();
          const image = new Image();
          image.src = URL.createObjectURL(blob);
          image.addEventListener('load', () => {
            const { width, height } = image;
            if (width < 999 || height < 999) {
              props.setDimensionsWarning(
                'Warning: Image dimensions are too small, minimum image dimensions are 999 x 999 pixels'
              );
            }
            if (blob.size < 10 * 1024) {
              props.setSizeWarning('Warning: Image size is too small, minimum image size is 10Kb');
            }
          });
        } catch (error) {
          console.error('Error loading original image', error);
        }
      }
    };

    loadImageDetails();
  }, [props.canEdit, props.canEdit && props.originalImage]);

  if (imageUrl && props.canEdit) {
    return (
      <Popup
        maxWidth="md"
        title="Preview"
        closeIcon
        ref={mainRef}
        onClose={() => {
          mainRef.current?.close();
        }}
      >
        <Stack display="flex" flexDirection="column" gap={2} alignItems="center">
          <CardMedia
            component="img"
            image={imageUrl}
            alt="placeholder"
            sx={{ objectFit: 'cover', borderRadius: '8px', width: 500, height: 500 }}
            onLoad={() => !hasError && setHasImageLoaded(true)}
            onError={(e: any) => {
              setHasError(true);
              e.target.src = noImagePlaceholder;
            }}
          />
          {!hasImageLoaded && !hasError && <CaloLoader />}
          {props.dimensionsWarning && <WarningText message={props.dimensionsWarning} />}
          {props.sizeWarning && <WarningText message={props.sizeWarning} />}
          <Button
            data-test="imagePopupDoneButton"
            variant="text"
            sx={{
              color: caloTheme.palette.neutral900,
              textTransform: 'capitalize',
              fontSize: '20px',
              fontWeight: 600,
              lineHeight: '24px',
              px: 6,
              py: 1,
              '&:hover': {
                color: caloTheme.palette.white,
                backgroundColor: caloTheme.palette.neutral900
              }
            }}
            onClick={() => mainRef.current?.close()}
          >
            Done
          </Button>
        </Stack>
      </Popup>
    );
  } else {
    return <></>;
  }
};
export default ImagePopup;
