import { CustomMealCategory, FoodComponentType } from '@calo/types';
import { CardMedia, Stack, Typography } from '@mui/material';
import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { RemoveButton } from 'components/MUI';
import { FoodComponent } from 'lib/interfaces';

interface SelectedComponentCardProps {
  component: FoodComponent;
  customMealCategory: CustomMealCategory;
  removeComponent?: (id: string, tag: FoodComponentType) => void;
}

const SelectedComponentCard = ({ component, customMealCategory, removeComponent }: SelectedComponentCardProps) => {
  const displayImage = `${process.env.REACT_APP_BUCKET_URL}/food-component/${component.id}/square@1x.jpg`;

  const tag = (component.sections ?? []).find((section) => section.category === customMealCategory)?.tag;

  return (
    <Stack sx={{ gap: 2, alignItems: 'center', px: '4px', py: '8px', position: 'relative', width: '100px', minWidth: '60px' }}>
      <CardMedia
        component="img"
        image={displayImage}
        alt="placeholder"
        sx={{ width: 60, maxHeight: 60, height: 60, objectFit: 'cover', borderRadius: '8px', marginTop: '12px' }}
        onError={(e: any) => {
          e.target.src = noImagePlaceholder;
        }}
      />
      <Typography sx={{ fontSize: '12px', textAlign: 'center' }}>{component.name.en}</Typography>
      {removeComponent && <RemoveButton handleClick={() => removeComponent(component.id, tag!)} />}
    </Stack>
  );
};

export default SelectedComponentCard;
