import { theme } from 'lib/componentStyles';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Button, Stack, Typography } from '@mui/material';

interface TableHeadCellProps {
  AddGroup: () => void;
}

const Header = ({ AddGroup }: TableHeadCellProps) => {
  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Typography sx={{ fontSize: '16px', letterSpacing: '0.15px', lineHeight: '150%', color: theme.palette.text.secondary }}>
          User Access
        </Typography>
        ,
        <Typography sx={{ fontSize: '16px', letterSpacing: '0.15px', lineHeight: '150%', color: theme.palette.text.secondary }}>
          KDS Roles & Permissions
        </Typography>
        ,
      </Breadcrumbs>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} width={'100%'}>
        <Typography sx={{ fontSize: '34px', letterSpacing: '0.25px', lineHeight: '123.5%', color: theme.palette.text.primary }}>
          KDS Roles & Permissions
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddRoundedIcon sx={{ width: '24px', height: '24px' }} />}
          onClick={AddGroup}
          sx={{
            color: theme.palette.primary.contrastText,
            fontSize: '15px',
            letterSpacing: '0.46px',
            lineHeight: '26px',
            textTransform: 'uppercase',
            fontWeight: 600,
            borderRadius: '4px',
            boxShadow:
              '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
          }}
        >
          Add role
        </Button>
      </Stack>
    </>
  );
};

export default Header;
