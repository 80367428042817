import { useMemo } from 'react';

import { flatten, keyBy, sortBy, uniq } from 'lodash-es';
import { ActionMeta } from 'react-select';

import { handleSearch } from 'lib/helpers';
import { MenuFood } from 'lib/interfaces';
import Select from '../Select';

interface FoodSelectProps {
  onChange: (foodIds: any) => void;
  value: any;
  foodList: MenuFood[];
  filterName: string;
  setFilterName: (value: string) => void;
  label: string;
  isLoading: boolean;
}

const FoodSelect = ({ isLoading, setFilterName, onChange, value, foodList, label }: FoodSelectProps) => {
  const keyedFoodList = useMemo(() => keyBy(foodList, 'id'), [foodList]);

  const options = useMemo(
    () =>
      sortBy(foodList as any, (f) => `${f.name.en}-${f.size}`).map((food) => ({
        name: food.name.en,
        value: food.id,
        label: `${food.name.en} ${label === 'ingredient' ? `(${food.internalName})` : ''}  ${label === 'Meal' || label === 'food' ? `(${food.size})` : ''}`
      })),
    [foodList]
  );

  const touched = useMemo(() => uniq(value.map((id: any) => keyedFoodList[id]?.name.en)), [value]);

  const handleFoodChange = (data: any, { action }: ActionMeta<any>) => {
    switch (action) {
      case 'select-option': {
        const filteredData: { name: string; value: any }[] = data.filter(
          (r: { name: string; value: any }) => !value.includes(r.value)
        );
        const res: MenuFood[] = flatten(
          filteredData.map((r) => {
            if (touched.includes(r.name)) {
              return keyedFoodList[r.value];
            }
            return foodList.filter((f) => f.name.en === r.name);
          })
        );
        onChange(res.map((f) => f.id));

        break;
      }
      case 'remove-value': {
        onChange((data || []).map((f) => f.value));

        break;
      }
      case 'clear': {
        onChange([]);

        break;
      }
      // No default
    }
  };

  return (
    <Select
      isMulti
      isLoading={isLoading}
      label={label}
      value={value}
      options={options}
      onChange={handleFoodChange}
      onInputChange={(data: any, action: any) => handleSearch({ text: data, action, name: setFilterName })}
    />
  );
};

export default FoodSelect;
