import { Stack } from '@mui/material';
import { AreaColors } from 'lib/enums';

interface ColorSelectorProps {
  selectedColor: string;
  onSelectColor: (color: string) => void;
}
const ColorSelector = ({ selectedColor, onSelectColor }: ColorSelectorProps) => (
  <Stack sx={{ flexDirection: 'row', mt: 1, width: '100%', justifyContent: 'space-around' }}>
    {Object.values(AreaColors).map((color, index) => (
      <div
        key={index}
        onClick={() => onSelectColor(color)}
        className="flex w-6 h-6 rounded-full mr-2 cursor-pointer justify-center items-center"
        style={{
          backgroundColor: color,
          borderWidth: 0.5,
          borderColor: selectedColor === color ? 'green' : color.replace('0.5', '1')
        }}
      >
        {selectedColor === color && <i className="fas fa-check text-green-600"></i>}
      </div>
    ))}
  </Stack>
);

export default ColorSelector;
