import { v4 as uuid } from 'uuid';

import { styled, TableCell, tableCellClasses, TableRow, Typography } from '@mui/material';

import { IngredientComponentStats, IngredientStats } from 'lib/interfaces';
import { getQuantity, getQuantityAfterPrep, getTotalCookedWeight, getTotalRawWeight } from '../helpers';

interface ComponentRowProps {
  component: IngredientComponentStats;
  switchUnit: boolean;
  ingredient: IngredientStats;
  isChild?: boolean;
  onlyChild?: boolean;
}

export default function ComponentRow({
  component,
  switchUnit,
  ingredient,
  isChild = false,
  onlyChild = false
}: ComponentRowProps) {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '19px',
      textTransform: 'capitalize'
    }
  }));

  return (
    <TableRow key={uuid()}>
      <StyledTableCell sx={{ fontWeight: 600, textTransform: 'capitalize' }}>
        {component.name.en}
        {isChild && (
          <Typography component={'span'} sx={{ fontSize: '14px', fontWeight: 600 }}>
            {' '}
            (Child Comp.)
          </Typography>
        )}
      </StyledTableCell>
      <StyledTableCell sx={{ fontWeight: 400 }}>{Math.ceil(getTotalRawWeight(component, isChild, onlyChild))}</StyledTableCell>
      <StyledTableCell sx={{ fontWeight: 400 }}>{Math.ceil(getTotalCookedWeight(component, isChild, onlyChild))}</StyledTableCell>
      <StyledTableCell sx={{ fontWeight: 400 }}>
        {Math.ceil(getQuantity(component, switchUnit, ingredient, isChild, onlyChild))}
      </StyledTableCell>
      <StyledTableCell sx={{ fontWeight: 400 }}>
        {Math.ceil(getQuantityAfterPrep(component, switchUnit, ingredient, isChild, onlyChild))}
      </StyledTableCell>
    </TableRow>
  );
}
