import { Button, Input, Select } from 'components';
import { useUserKitchens } from 'lib/hooks';
import { KDSUserGroup } from 'lib/interfaces';
import { toLower } from 'lodash';

import { KDSCreateReq } from '@calo/dashboard-types';
import { Country, Kitchen } from '@calo/types';

import useUserForm from './useUserForm';

interface UserFormProps {
  onSubmit: (value: Omit<KDSCreateReq, 'id'>) => Promise<void>;
  kdsUserGroup: KDSUserGroup[];
}

const UserForm = ({ onSubmit, kdsUserGroup }: UserFormProps) => {
  const { handleSubmit, values, handleChange, handleBlur, isSubmitting, isValid, dirty, setFieldValue } = useUserForm(onSubmit);

  const userKitchens: Kitchen[] = useUserKitchens();
  return (
    <div className=" p-6 pt-12">
      <form onSubmit={handleSubmit}>
        <Input label="Name" value={values.name} name="name" onChange={handleChange} onBlur={handleBlur} />
        <Input label="Phone number" value={values.phoneNumber} name="phoneNumber" onChange={handleChange} onBlur={handleBlur} />
        <Select
          label="Country"
          value={values.country}
          onChange={(data: any) => {
            setFieldValue('country', data.value);
            setFieldValue('kitchen', [userKitchens.find((k) => k.includes(data.value))]);
          }}
          options={Object.values(Country).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select
          label="Kitchen"
          value={values.kitchen}
          onChange={(data: any) =>
            setFieldValue(
              'kitchen',
              data.map((row) => row.value)
            )
          }
          options={userKitchens.map((k) => ({
            value: k,
            label: k
          }))}
          isMulti
        />
        <Select
          label="group"
          value={values.group}
          name="group"
          onChange={(data: any) => setFieldValue('group', data.value)}
          options={kdsUserGroup.map((type) => ({
            value: type.id,
            label: type.groupName
          }))}
          onBlur={handleBlur}
        />
        <Input
          label="Email"
          value={values.email}
          name="email"
          onChange={(data: any) => setFieldValue('email', toLower(data.target.value))}
          onBlur={handleBlur}
        />
        <Input label="Password" value={values.password} name="password" onChange={handleChange} onBlur={handleBlur} />
        {values.password.length < 8 && (
          <p className="text-red-500 text-xs mb-2 -mt-2">password should be at least 8 characters</p>
        )}

        <Button type="submit" primary loading={isSubmitting} disabled={!dirty || !isValid || isSubmitting} content="Save" />
      </form>
    </div>
  );
};
export default UserForm;
