import { DietType, FoodType } from '@calo/types';

export const getPackageMaxMin = (diet, food) => {
  const minAndMaxCaloriesPerMealDietType = {
    [DietType.chefPicks]: {
      [FoodType.lunch]: {
        min: 300,
        max: 650
      },
      [FoodType.breakfast]: {
        min: 300,
        max: 650
      },
      [FoodType.dinner]: {
        min: 300,
        max: 650
      },
      [FoodType.snack]: {
        min: 100,
        max: 250
      }
    },
    [DietType.balanced]: {
      [FoodType.lunch]: {
        min: 300,
        max: 650
      },
      [FoodType.breakfast]: {
        min: 300,
        max: 650
      },
      [FoodType.dinner]: {
        min: 300,
        max: 650
      },
      [FoodType.snack]: {
        min: 100,
        max: 250
      }
    },
    [DietType.vegetarian]: {
      [FoodType.lunch]: {
        min: 300,
        max: 650
      },
      [FoodType.breakfast]: {
        min: 300,
        max: 650
      },
      [FoodType.dinner]: {
        min: 300,
        max: 650
      },
      [FoodType.snack]: {
        min: 100,
        max: 250
      }
    },
    [DietType.lowCarb]: {
      [FoodType.lunch]: {
        min: 300,
        max: 480
      },
      [FoodType.dinner]: {
        min: 300,
        max: 480
      },
      [FoodType.breakfast]: {
        min: 300,
        max: 480
      },
      [FoodType.snack]: {
        min: 100,
        max: 250
      }
    },
    [DietType.highProtein]: {
      [FoodType.lunch]: {
        min: 450,
        max: 650
      },
      [FoodType.dinner]: {
        min: 450,
        max: 650
      },
      [FoodType.breakfast]: {
        min: 450,
        max: 650
      },
      [FoodType.snack]: {
        min: 100,
        max: 250
      }
    }
  };
  let packageMax = 0;
  let packageMin = 0;

  const validFoodTypes = [FoodType.breakfast, FoodType.snack, FoodType.lunch, FoodType.dinner];
  const validDietTypes = [DietType.lowCarb, DietType.highProtein, DietType.vegetarian, DietType.balanced, DietType.chefPicks];

  for (const f of food || []) {
    validFoodTypes.includes(f) &&
      validDietTypes.includes(diet) &&
      ((packageMax += minAndMaxCaloriesPerMealDietType[diet][f].max),
      (packageMin += minAndMaxCaloriesPerMealDietType[diet][f].min));
  }

  return { packageMin, packageMax };
};
