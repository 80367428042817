import { useEffect, useMemo, useState } from 'react';

import { difference, intersection, isEqual, lowerCase, sortBy, startCase, unionBy } from 'lodash-es';
import { useMutation, useQuery } from 'react-query';

import { Permission, Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { Brand, FoodPreference, IngredientCategory } from '@calo/types';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Grid, InputLabel, MenuItem, Stack, TextField, Typography } from '@mui/material';

import { getList, getListWithParams, updateFoodPreference, updateSubscription } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon, Select as ReactSelect } from 'components';
import { handleSearch } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { Ingredient, Options } from 'lib/interfaces';
import { toast } from 'react-toastify';
import useAllergicIngredientsForm from './useAllergicIngredientsForm';

interface WarningIngredientsProps {
  subscription: Subscription & { foodPreference: FoodPreference };
  refetchSubscription: () => void;
}

const WarningIngredients = ({ subscription, refetchSubscription }: WarningIngredientsProps) => {
  const roles = useUserRoles();
  const [filterName, setFilterName] = useState<string>();
  const [allergicIngredients, setAllergicIngredients] = useState<Ingredient[]>([]);
  const [dislikedIngredientsCategory, setDislikedIngredientsCategory] = useState<IngredientCategory[]>([]);
  const [dislikedIngredients, setDislikedIngredients] = useState<Ingredient[]>([]);
  const [allIngredients, setAllIngredients] = useState<Ingredient[]>([]);
  const { mutateAsync: updateMutation } = useMutation(updateSubscription);
  const { mutateAsync: updateAllergicIngMutation } = useMutation(updateFoodPreference);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const onSubmit = async (data: UpdateSubscriptionReq) => {
    await updateMutation({
      id: subscription.id,
      ...data
    });
  };
  const { values, setFieldValue, setValues, initialValues } = useAllergicIngredientsForm(subscription, onSubmit);

  useEffect(() => {
    setDislikedIngredientsCategory(subscription?.foodPreference?.dislikedIngredientsCategory || []);
  }, [subscription]);

  const { data: foodPref } = useQuery<any, Error, any>([`food-preferences/${subscription.id}`], getList, {
    enabled: roles.includes(Permission.VIEW_INGREDIENT_LIST),
    onSuccess: (data) => {
      setAllergicIngredients(data?.ingredientsToAvoid ?? []);
      setDislikedIngredients(data?.dislikedIngredients ?? []);
    }
  });

  const { isLoading } = useQuery<any, Error, { data: Ingredient[] }>(
    [
      'ingredients',
      {
        filters: {
          country: subscription.country,
          brand: subscription.brand || Brand.CALO,
          name: filterName || undefined
        }
      }
    ],
    getListWithParams,
    {
      enabled: roles.includes(Permission.VIEW_INGREDIENT_LIST) && !!filterName,
      onSuccess: (allIngredientList) => {
        setAllIngredients(allIngredientList?.data);
      }
    }
  );

  const options: Options<Ingredient>[] = useMemo(
    () =>
      sortBy(
        unionBy(allIngredients, allergicIngredients, dislikedIngredients, 'id'),
        (ingredient) => `${ingredient.name.en}`
      ).map((ingredient) => ({
        value: ingredient.id,
        data: { ...ingredient },
        label: `${ingredient.name.en} (${ingredient.internalName || ''})`
      })),
    [allIngredients, allergicIngredients, dislikedIngredients]
  );

  useEffect(() => {
    !dislikedIngredientsCategory.includes(IngredientCategory.other) && setDislikedIngredients([]);
  }, [dislikedIngredientsCategory]);

  const handleFoodPref = async (data: UpdateSubscriptionReq) => {
    const isOtherAlreadySelected = subscription.macrosData?.allergicFood?.includes(IngredientCategory.other);
    const isOtherSelected = data.macrosData?.allergicFood?.includes(IngredientCategory.other);

    if (isOtherAlreadySelected && !isOtherSelected) {
      await updateAllergicIngMutation({
        subscriptionId: subscription.id,
        allergicIng: [],
        dislikedIng: dislikedIngredients ?? [],
        dislikedIngCategory: dislikedIngredientsCategory ?? [],
        healthConditions: foodPref?.healthConditions?.healthConditions,
        notes: foodPref?.healthConditions?.notes,
        favorite: foodPref?.favouriteFood || []
      });
      setAllergicIngredients([]);
    } else if (
      isOtherSelected &&
      (difference(foodPref?.ingredientsToAvoid, allergicIngredients).length > 0 ||
        difference(allergicIngredients, foodPref?.ingredientsToAvoid || []).length > 0)
    ) {
      await updateAllergicIngMutation({
        subscriptionId: subscription.id,
        allergicIng: allergicIngredients ?? [],
        dislikedIng: dislikedIngredients ?? [],
        dislikedIngCategory: dislikedIngredientsCategory ?? [],
        healthConditions: foodPref?.healthConditions?.healthConditions,
        notes: foodPref?.healthConditions?.notes,
        favorite: foodPref?.favouriteFood || []
      });
    }
  };

  const handleEditing = async (edit: boolean) => {
    if (!edit && !isEqual(values.macrosData, subscription.macrosData)) {
      onSubmit({
        ...values
      });
    }
    handleFoodPref(values);
    if (!edit) {
      await updateAllergicIngMutation({
        subscriptionId: subscription.id,
        allergicIng: allergicIngredients ?? [],
        dislikedIng: dislikedIngredients,
        dislikedIngCategory: dislikedIngredientsCategory ?? [],
        healthConditions: foodPref?.healthConditions?.healthConditions,
        notes: foodPref?.healthConditions?.notes,
        favorite: foodPref?.favouriteFood || []
      });
      refetchSubscription();
    }
    setIsEditing(edit);
  };

  const handleIngredientsChange = (data) => {
    const newValue = data.target.value;
    const filteredValue = newValue.filter((value) => value !== IngredientCategory.other);
    const isSame = intersection(filteredValue, dislikedIngredients).length > 0;
    if (isSame) {
      toast(`${newValue[newValue.length - 1]} is already added as a dislike. Remove it to be able to add it as an allergen`, {
        type: 'error',
        autoClose: 2000
      });
      return;
    }
    setFieldValue('macrosData[allergicFood]', newValue);
  };

  const handleDislikedIngredientsChange = (data) => {
    const newValue = data.target.value;
    const filteredValue = newValue.filter((value) => value !== IngredientCategory.other);
    const isSame = intersection(filteredValue, values.macrosData!.allergicFood).length > 0;
    if (isSame) {
      toast(
        `${filteredValue[filteredValue.length - 1]} is already added as an allergen. Remove it to be able to add it as a dislike ingredient`,
        {
          type: 'error',
          autoClose: 2000
        }
      );
      return;
    }
    setDislikedIngredientsCategory(data.target.value);
  };

  const handleReset = () => {
    setValues(initialValues);
    setAllergicIngredients(foodPref?.ingredientsToAvoid || []);
    setIsEditing(false);
  };

  return (
    <>
      <Box
        sx={{
          margin: 2,
          padding: 2,
          backgroundColor: caloTheme.palette.neutral50,
          borderRadius: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'auto',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column'
          }
        }}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '19px',
            lineHeight: '23px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Warning Ingredients
        </Typography>
        {isEditing ? (
          <Stack sx={{ justifyContent: 'end', flexDirection: 'row' }}>
            <CheckIcon sx={{ marginRight: 2 }} style={{ cursor: 'pointer' }} onClick={() => handleEditing(!isEditing)} />
            <ClearIcon style={{ cursor: 'pointer' }} onClick={handleReset} />
          </Stack>
        ) : (
          <Icon
            data-test="edit-warning-ingredients-icon"
            onClick={() => handleEditing(!isEditing)}
            name="edit2"
            size={6}
            style={{ cursor: 'pointer', width: '26px', height: '26px' }}
          />
        )}
      </Box>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          overflow: 'visible',
          width: 'full',
          mx: 2,
          height: 'full',
          '& .MuiTextField-root': { my: 2, mx: 2, width: 'full' },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'full',
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTextField-root': { my: 2, mx: 1, justifyContent: 'space-between' }
          }
        }}
      >
        <Stack display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
          <TextField
            data-test="allergic-ingredients-dropdown"
            select
            placeholder={'Search Ingredients'}
            id="exact-subscription-allergicIngredients"
            label="Allergic Ingredients"
            value={values.macrosData!.allergicFood}
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
            SelectProps={{
              multiple: true,
              value: values.macrosData!.allergicFood || [],
              onChange: (data) => {
                handleIngredientsChange(data);
              }
            }}
            InputProps={{ inputProps: { style: { borderRadius: 8, width: 'full' } }, style: { borderRadius: 8 } }}
          >
            {Object.values(IngredientCategory)
              .filter((r) => (values.macrosData!.allergicFood ? !values.macrosData!.allergicFood.includes(r) : r))
              .map((row) => (
                <MenuItem key={row} value={row} data-test={`allergic-food-${lowerCase(row)}-menu-item`}>
                  {startCase(row)}
                </MenuItem>
              ))}
          </TextField>
          {(values.macrosData!.allergicFood?.includes(IngredientCategory.other) || allergicIngredients.length > 0) && (
            <>
              <InputLabel id="OtherIngredients" sx={{ ml: 2, mb: 1 }}>
                Other Ingredients
              </InputLabel>
              <ReactSelect
                isMulti
                className="mx-4"
                isLoading={isLoading}
                options={options}
                isDisabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
                value={allergicIngredients && allergicIngredients.map((ingredient) => ingredient.id)}
                onChange={(data: any) => {
                  const newValue = data.map((ingredient) => ingredient.data);
                  const namesSet = new Set(dislikedIngredients.map((item) => item.name.en));
                  for (const item of newValue) {
                    if (namesSet.has(item.name.en)) {
                      toast(
                        `${item.name.en} is already added as a dislike ingredient. Remove it to be able to add it as a allergen`,
                        {
                          type: 'error',
                          autoClose: 2000
                        }
                      );
                      return;
                    }
                  }
                  setAllergicIngredients(() => data.map((ingredient) => ingredient.data));
                }}
                onInputChange={(data: string, action: any) => handleSearch({ text: data, action, name: setFilterName })}
              />
            </>
          )}
        </Stack>
      </Box>
      <Box sx={{ padding: '8px' }}>
        <Stack
          sx={{
            border: 1,
            borderColor: caloTheme.palette.neutral100,
            borderRadius: '8px',
            padding: '8px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginX: 3,
            marginY: 2
          }}
        >
          {values.macrosData!.allergicFood && values.macrosData!.allergicFood.length > 0 ? (
            values.macrosData!.allergicFood.map((allergicIng: any) => (
              <Box
                key={allergicIng}
                sx={{
                  margin: '4px',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  borderRadius: '8px',
                  textAlign: 'center',
                  background: caloTheme.palette.primary100,
                  color: caloTheme.palette.primary700
                }}
              >
                <Grid key={allergicIng}>
                  <Typography sx={{ display: 'flex', flexDirection: 'row', mr: '2px' }}>
                    <p className="mx-2">{startCase(allergicIng)}</p>
                    {isEditing && (
                      <Icon
                        key={allergicIng}
                        name="removeX"
                        className="m-auto cursor-pointer"
                        onClick={() =>
                          setFieldValue(
                            'macrosData[allergicFood]',
                            values.macrosData!.allergicFood?.filter((row: any) => row !== allergicIng)
                          )
                        }
                      />
                    )}
                  </Typography>
                </Grid>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                margin: '4px',
                flexDirection: 'row',
                borderRadius: '8px',
                textAlign: 'center'
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: caloTheme.typography.fontFamily,
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: caloTheme.palette.neutral500
                }}
              >
                {startCase('No Warning Ingredients')}
              </Typography>
            </Box>
          )}
        </Stack>
      </Box>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          overflow: 'visible',
          width: 'full',
          mx: 2,
          height: 'full',
          '& .MuiTextField-root': { my: 2, mx: 2, width: 'full' },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'full',
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTextField-root': { my: 2, mx: 1, justifyContent: 'space-between' }
          }
        }}
      >
        <Stack display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
          <TextField
            data-test="disliked-ingredients-dropdown"
            select
            placeholder={'Search Ingredients'}
            id="exact-subscription-dislikedIngredients"
            label="Disliked Ingredients"
            value={dislikedIngredientsCategory}
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
            SelectProps={{
              multiple: true,
              value: dislikedIngredientsCategory || [],
              onChange: (data) => {
                handleDislikedIngredientsChange(data);
              }
            }}
            InputProps={{ inputProps: { style: { borderRadius: 8, width: 'full' } }, style: { borderRadius: 8 } }}
          >
            {Object.values(IngredientCategory)
              .filter((row: IngredientCategory) => !dislikedIngredientsCategory.includes(row))
              .map((row) => (
                <MenuItem key={row} value={row} data-test={`disliked-food-${lowerCase(row)}-menu-item`}>
                  {startCase(row)}
                </MenuItem>
              ))}
          </TextField>
          {dislikedIngredientsCategory?.includes(IngredientCategory.other) && (
            <>
              <InputLabel id="OtherIngredients" sx={{ ml: 2, mb: 1 }}>
                Other Ingredients
              </InputLabel>
              <ReactSelect
                isMulti
                className="mx-4"
                isLoading={isLoading}
                options={options}
                isDisabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
                value={dislikedIngredients && dislikedIngredients.map((ingredient) => ingredient.id)}
                onChange={(data: any) => {
                  const newValue = data.map((ingredient) => ingredient.data);
                  const namesSet = new Set(allergicIngredients.map((item) => item.name.en));
                  for (const item of newValue) {
                    if (namesSet.has(item.name.en)) {
                      toast(
                        `${item.name.en} is already added as an allergen. Remove it to be able to add it as a dislike ingredient`,
                        {
                          type: 'error',
                          autoClose: 2000
                        }
                      );
                      return;
                    }
                  }
                  setDislikedIngredients(() => data.map((ingredient) => ingredient.data));
                }}
                onInputChange={(data: string, action: any) => handleSearch({ text: data, action, name: setFilterName })}
              />
            </>
          )}
        </Stack>
      </Box>
      <Box sx={{ padding: '8px' }}>
        <Stack
          sx={{
            border: 1,
            borderColor: caloTheme.palette.neutral100,
            borderRadius: '8px',
            padding: '8px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginX: 3,
            marginY: 2
          }}
        >
          {dislikedIngredientsCategory && dislikedIngredientsCategory.length > 0 ? (
            dislikedIngredientsCategory.map((dislikeIng: IngredientCategory) => (
              <Box
                key={dislikeIng}
                sx={{
                  margin: '4px',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  borderRadius: '8px',
                  textAlign: 'center',
                  background: caloTheme.palette.primary100,
                  color: caloTheme.palette.primary700
                }}
              >
                <Grid key={dislikeIng}>
                  <Typography sx={{ display: 'flex', flexDirection: 'row', mr: '2px' }}>
                    <p className="mx-2">{startCase(dislikeIng)}</p>
                    {isEditing && (
                      <Icon
                        key={dislikeIng}
                        name="removeX"
                        className="m-auto cursor-pointer"
                        onClick={() =>
                          setDislikedIngredientsCategory(dislikedIngredientsCategory.filter((item) => item !== dislikeIng))
                        }
                      />
                    )}
                  </Typography>
                </Grid>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                margin: '4px',
                flexDirection: 'row',
                borderRadius: '8px',
                textAlign: 'center'
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: caloTheme.typography.fontFamily,
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: caloTheme.palette.neutral500
                }}
              >
                {startCase('No Disliked Ingredients')}
              </Typography>
            </Box>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default WarningIngredients;
