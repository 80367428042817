import { InputValueRestrictionType, InputValueRestrictions } from 'lib/enums';

export const isNumberInputValid = (value: number, type: InputValueRestrictionType) => {
  const digits = value.toString().split('.');

  if (digits.length === 0) {
    return true;
  }
  if (digits[0].length > InputValueRestrictions[type].before) {
    return false;
  }

  if (digits.length > 1 && digits[1].length > InputValueRestrictions[type].after) {
    return false;
  }

  return true;
};
