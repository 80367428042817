import { Box, Button, Stack, Typography } from '@mui/material';
import customMealImage from 'assets/images/customMealImage.png';
import { caloTheme } from 'assets/images/theme/calo';
import IngredientList from 'components/CustomFoodPopup/CustomMealForm/Shared/IngredientList';
import { FoodWithPosition } from 'lib/interfaces';

interface CustomMealListItemProps {
  isLastItem: boolean;
  customMeal: any;
  selectedMeal: FoodWithPosition;
  isLoading: boolean;
  handleReplace: (newMeal: any, oldMeal: any) => Promise<void>;
}

const CustomMealListItem = ({ isLastItem, customMeal, selectedMeal, isLoading, handleReplace }: CustomMealListItemProps) => {
  const isSelected = selectedMeal.id === customMeal.id;

  const selectMealHandler = () => {
    if (isSelected) {
      return;
    }
    handleReplace(customMeal, selectedMeal);
  };

  return (
    <Box
      flexDirection={'row'}
      sx={{
        marginBottom: 2,
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '38vh',
        borderBottom: isLastItem ? 0 : 1,
        borderColor: caloTheme.palette.neutral100
      }}
    >
      <Stack flexDirection={'row'} sx={{ paddingY: 1 }}>
        <Stack sx={{ marginRight: 1 }}>
          <img
            className="object-cover"
            width={60}
            style={{ maxHeight: '60px', maxWidth: '60px', borderRadius: '8px' }}
            height={60}
            alt={customMeal.name.en}
            onError={(e: any) => (e.target.src = 'https://via.placeholder.com/120')}
            src={customMealImage}
          />
        </Stack>
        <Stack flexDirection={'column'} width={'100%'}>
          <Stack justifyContent={'start'} flexDirection={'row'}>
            <Typography sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '20px' }}>{customMeal?.name?.en}</Typography>
          </Stack>
          <Stack sx={{ marginY: '8px' }}>
            <Stack display={'flex'} flexDirection={'row'} width={'full'} flexWrap={'wrap'}>
              <IngredientList ingredients={customMeal.ingredients} sx={{ fontSize: '12px', fontWeight: 400 }} />
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ width: '15%', mr: 1, my: 1 }}>
          <Button
            variant="outlined"
            disabled={isSelected || isLoading}
            sx={{
              height: '45px',
              lineHeight: '17px',
              fontWeight: 600,
              fontSize: '14px',
              boxShadow: 'none',
              borderRadius: '8px',
              textTransform: 'none',
              borderColor: isSelected ? caloTheme.palette.primary500 : caloTheme.palette.neutral900,
              color: isSelected ? caloTheme.palette.white : caloTheme.palette.neutral900,
              backgroundColor: isSelected ? caloTheme.palette.primary500 : 'transparent',
              '&:hover': {
                backgroundColor: caloTheme.palette.neutral50,
                boxShadow: 'none',
                color: caloTheme.palette.neutral900,
                borderColor: caloTheme.palette.neutral900
              },
              ':disabled': {
                color: isSelected ? 'white' : caloTheme.palette.neutral600
              },
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                justifyItems: 'center',
                margin: 'auto',
                width: 'auto'
              }
            }}
            onClick={selectMealHandler}
          >
            {isSelected ? 'Selected' : 'Select Meal'}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CustomMealListItem;
