import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  softLaunchButton: {
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    gap: '10px',
    mr: '2px',
    borderColor: caloTheme.palette.neutral50,
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: caloTheme.palette.primary500,
      backgroundColor: caloTheme.palette.primary500,
      color: caloTheme.palette.white,
      boxShadow: 'none'
    }
  },
  fullLaunchButton: {
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    gap: '10px',
    marginLeft: '-4px',
    borderWidth: '0px',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: caloTheme.palette.primary500,
      backgroundColor: caloTheme.palette.primary500,
      color: caloTheme.palette.white,
      boxShadow: 'none'
    }
  },
  deleteButton: {
    gap: '10px',
    height: '40px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    padding: '10px 16px 10px 16px',
    width: '50%',
    color: caloTheme.palette.neutral900,
    '&:hover': {
      backgroundColor: caloTheme.palette.neutral100,
      boxShadow: 'none'
    }
  },
  applyButton: {
    gap: '10px',
    height: '40px',
    color: 'white',
    fontWeight: 600,
    width: '50%',
    fontSize: '16px',
    boxShadow: 'none',
    lineHeight: '20px',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    padding: '10px 16px 10px 16px',
    backgroundColor: caloTheme.palette.primary500,
    '&:hover': {
      backgroundColor: caloTheme.palette.primary600,
      boxShadow: 'none'
    }
  },
  divider: {
    color: caloTheme.palette.neutral100,
    width: '100%',
    height: '1px',
    marginTop: '10px',
    marginBottom: '10px',
    borderRadius: '8px'
  },
  warningText: { mx: 1, color: caloTheme.palette.red, fontSize: '12px', fontWeight: 400 },
  shortDayText: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textTransform: 'none'
  },
  toggleStyle: {
    borderRadius: '130px',
    marginRight: 8,
    width: '40px',
    borderColor: caloTheme.palette.neutral900,
    height: '40px',
    borderWidth: '1px'
  },
  cancelButtonPopup: {
    gap: '10px',
    height: '40px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    padding: '10px 16px 10px 16px',
    marginHorizontal: 'auto',
    color: caloTheme.palette.neutral600,
    '&:hover': {
      backgroundColor: caloTheme.palette.neutral100,
      boxShadow: 'none'
    }
  },
  confirmButtonPopup: {
    gap: '10px',
    height: '40px',
    color: 'white',
    fontWeight: 600,
    marginHorizontal: 'auto',
    fontSize: '16px',
    boxShadow: 'none',
    lineHeight: '20px',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    padding: '10px 16px 10px 16px',
    backgroundColor: caloTheme.palette.primary500,
    '&:hover': {
      backgroundColor: caloTheme.palette.primary600,
      boxShadow: 'none'
    }
  },
  ///////////Confirmation popup component
  titleText: {
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '40px',
    color: caloTheme.palette.neutral900
  },
  contentText: {
    fontWeight: 400,
    marginY: '24px',
    paddingX: '4px',
    fontSize: '16px',
    lineHeight: '20px',
    color: caloTheme.palette.neutral900
  },
  rawStack: { alignSelf: 'center', flexDirection: 'row', display: 'flex' },
  container: { flexDirection: 'column', display: 'flex' },
  headerStack: { flexDirection: 'row', justifyContent: 'space-between' },
  summaryText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px'
  }
};

export default styles;
