import BigNumber from 'bignumber.js';

import { Currency } from '@calo/types';

class PricingService {
  /**
   * Get the VAT for the NET amount. The VAT that will be used to add
   * to the payment amount to charge the user
   *
   * example: If the plan price is 100 BHD, the method will return 5 BHD, which is the
   * amount that needs to be added on top of the plan price to charge the user for
   * @param  {number} amount
   * @param  {Currency} currency
   * @param  {boolean} advanceRounding
   * @returns number
   */
  public static getVATForNetAmount(netAmount: number, currency: Currency, advanceRounding?: boolean): number {
    switch (currency) {
      case Currency.BHD:
        return this.roundAmount(netAmount * 0.1, currency, advanceRounding);
      case Currency.SAR:
        return this.roundAmount(netAmount * 0.15, currency, advanceRounding);
      case Currency.AED:
        return this.roundAmount(netAmount * 0.05, currency, advanceRounding);
      case Currency.KWD:
      case Currency.QAR:
      case Currency.GBP:
        return 0;
      case Currency.OMR:
        return this.roundAmount(netAmount * 0.05, currency, advanceRounding);
      default:
        return this.roundAmount(netAmount * 0.1, currency, advanceRounding);
    }
  }

  /**
   * Gets the VAT from the gross amount
   * To be used for any incoming payment from outside of our system
   *
   * example: The payment provider returns an amount that has been charged
   * to the users card of 110 BHD. The NET amount of the goods purchased is
   * 100 BHD, and the VAT that has been added to the charge is 5 BHD. In this case
   * this method will return 5 BHD which is the VAT.
   *
   * @param  {number} grossAmount
   * @param  {Currency} currency
   * @returns number
   */
  public static getVATFromGrossAmount(grossAmount: number, currency: Currency): number {
    switch (currency) {
      case Currency.BHD:
        return this.roundAmount((grossAmount * 10) / 110, currency);
      case Currency.SAR:
        return this.roundAmount((grossAmount * 15) / 115, currency);
      case Currency.AED:
        return this.roundAmount((grossAmount * 5) / 105, currency);
      case Currency.KWD:
      case Currency.QAR:
      case Currency.GBP:
        return 0;
      case Currency.OMR:
        return this.roundAmount((grossAmount * 5) / 105, currency);
      default:
        return this.roundAmount((grossAmount * 10) / 110, currency);
    }
  }

  /**
   * Returns mountly package discount compared to weekly
   * @param  {Currency} currency
   * @returns number
   */
  public static getMonthlyDiscount(currency: Currency) {
    switch (currency) {
      case Currency.BHD:
        return 20;
      case Currency.SAR:
        return 200;
      case Currency.AED:
        return 200;
      case Currency.KWD:
        return 20;
      case Currency.QAR:
        return 200;
      case Currency.OMR:
        return 20;
      default:
        return 20;
    }
  }

  /**
   * Returns the gross amount
   *
   * example: A subscription plan costs 100 BHD, and the user needs to be charged
   * incl. the VAT. This method will add the VAT to the price of the subscription price
   * which will be 110 BHD.
   * @param  {number} totalAmount
   * @param  {Currency} currency
   * @param  {boolean} advanceRounding
   * @returns number
   */
  public static getGrossAmount(netAmount: number, currency: Currency, advanceRounding?: boolean): number {
    switch (currency) {
      case Currency.BHD:
        return this.roundAmount(netAmount * 1.1, currency, advanceRounding);
      case Currency.SAR:
        return this.roundAmount(netAmount * 1.15, currency, advanceRounding);
      case Currency.AED:
        return this.roundAmount(netAmount * 1.05, currency, advanceRounding);
      case Currency.KWD:
      case Currency.QAR:
      case Currency.GBP:
        return this.roundAmount(netAmount * 1, currency, advanceRounding);
      case Currency.OMR:
        return this.roundAmount(netAmount * 1.05, currency, advanceRounding);
      default:
        return this.roundAmount(netAmount * 1.1, currency, advanceRounding);
    }
  }

  /**
   * Returns the NET amount (excl. VAT) from the gross amount (incl. VAT)
   * @param  {number} grossAmount
   * @param  {Currency} currency
   * @param  {boolean} round
   * @returns number
   */
  public static getNetAmount(grossAmount: number, currency: Currency, round = true): number {
    const bigNumber = new BigNumber(grossAmount);
    let netAmount;
    switch (currency) {
      case Currency.BHD:
        netAmount = bigNumber.dividedBy(1.1).toNumber();
        break;
      case Currency.SAR:
        netAmount = bigNumber.dividedBy(1.15).toNumber();
        break;
      case Currency.AED:
        netAmount = bigNumber.dividedBy(1.05).toNumber();
        break;
      case Currency.KWD:
      case Currency.QAR:
      case Currency.GBP:
        netAmount = grossAmount;
        break;
      case Currency.OMR:
        netAmount = bigNumber.dividedBy(1.05).toNumber();
        break;
      default:
        netAmount = bigNumber.dividedBy(1.1).toNumber();
    }
    return round ? this.roundAmount(netAmount, currency) : netAmount;
  }

  /**
   * Returns the gross (incl. VAT) with the discount amount removed from the original amount
   * @param  {number} netAmount
   * @param  {number} discount
   * @param  {Currency} currency
   * @returns number
   */
  public static getGrossAmountWithDiscount(netAmount: number, discount: number, currency: Currency): number {
    return this.getGrossAmount(netAmount - discount, currency);
  }

  /**
   * Returns the amount with the correct decimal points depending on the given currency
   *
   * @param  {number} amount
   * @param  {Currency} currency
   * @param  {boolean} advanceRounding
   * @returns number
   */
  public static roundAmount(amount: number, currency: Currency, advanceRounding?: boolean): number {
    const bigNumber = new BigNumber(amount);
    let roundDown = false;
    let fixedDecmials = 3;

    switch (currency) {
      case Currency.BHD:
      case Currency.KWD:
      case Currency.OMR:
        fixedDecmials = 3;
        break;
      case Currency.SAR:
      case Currency.AED:
      case Currency.QAR:
      case Currency.GBP:
        fixedDecmials = 2;
        break;
    }

    if (advanceRounding) {
      // Separate the integer and decimal parts
      const integerPart = bigNumber.integerValue(BigNumber.ROUND_DOWN); // round down, to get the integer part as is
      const decimalPart = bigNumber.minus(integerPart);

      // This is to round the 3rd decimal point only
      // Example: 300.001 => 300.00, 300.009 => 300.00
      if (decimalPart.isLessThan(0.01)) {
        roundDown = true;
      }
    }

    // round down/up will only affect the 3rd decimal point
    // Example:
    // 300.001 => 300.00 (round down)
    // 309.997 => 310.00 (round up)
    return roundDown ? +bigNumber.toFixed(fixedDecmials, BigNumber.ROUND_DOWN) : +bigNumber.toFixed(fixedDecmials);
  }
}

export default PricingService;
