export const styles = {
  card: {
    mb: 2,
    border: 'none',
    padding: 2,
    paddingX: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '16px',
    gap: '8px'
  },
  tableCell: {
    borderRadius: '8px 0 0 8px',
    border: 0,
    fontWeight: 600,
    lineHeight: '14px',
    fontSize: '12px',
    minWidth: '50%'
  }
};
