import React, { useRef, useState, useEffect } from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/system';

interface ScrollableSectionProps {
  children: React.ReactNode;
  xScrollable?: boolean;
  yScrollable?: boolean;
  scrollSpeed: number;
}

interface ScrollableContainerProps extends BoxProps {
  xScrollable?: boolean;
  yScrollable?: boolean;
}

const ScrollContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'xScrollable' && prop !== 'yScrollable'
})<ScrollableContainerProps>(({ xScrollable, yScrollable }) => ({
  overflowX: xScrollable ? 'auto' : 'hidden',
  overflowY: yScrollable ? 'auto' : 'hidden',
  whiteSpace: xScrollable ? 'nowrap' : 'normal',
  cursor: 'grab',
  '&:active': {
    cursor: 'grabbing'
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  userSelect: 'none'
}));

const ScrollableSection = ({ children, xScrollable = false, yScrollable = false, scrollSpeed }: ScrollableSectionProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleMouseUp = () => {
      setIsDragging(false);
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.pageX - (scrollRef.current?.offsetLeft || 0));
    setStartY(e.pageY - (scrollRef.current?.offsetTop || 0));
    setScrollLeft(scrollRef.current?.scrollLeft || 0);
    setScrollTop(scrollRef.current?.scrollTop || 0);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
    const y = e.pageY - (scrollRef.current?.offsetTop || 0);
    const walkX = (x - startX) * scrollSpeed;
    const walkY = (y - startY) * scrollSpeed;
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = scrollLeft - walkX;
      scrollRef.current.scrollTop = scrollTop - walkY;
    }
  };

  const handleWheel = (e: React.WheelEvent) => {
    if (!scrollRef.current) {
      return;
    }

    if (xScrollable) {
      scrollRef.current.scrollLeft += e.deltaX;
    } else if (yScrollable) {
      scrollRef.current.scrollTop += e.deltaY;
    }
  };

  return (
    <ScrollContainer
      ref={scrollRef}
      xScrollable={xScrollable}
      yScrollable={yScrollable}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseLeave={() => setIsDragging(false)}
      onMouseUp={() => setIsDragging(false)}
      onWheel={handleWheel}
    >
      {children}
    </ScrollContainer>
  );
};

export default ScrollableSection;
