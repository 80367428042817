import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import mutation from './mutation';

export const generateRoute = async (id: string, country: string) => {
  const { data } = await client.post(`/${country}/route-plans/${id}/regenerate`);
  mutation(['routes', id], data);
};

export const getRoutePlanPath = async (id: string) => {
  const { data } = await client.get(`/route-plans/${id}/path`);
  return data;
};

export const updateSubscriptionCustomDeliveryTime = async (id: string, time: string, addressId: string) => {
  const { status } = await client.put(`/route-plans/subscription/${id}/custom-delivery-time`, { time, addressId });
  ToastSuccessMsgs({ status, action: 'Custom Time added successfully' });
  return time;
};
