import ExcelJS, { Column, Style } from 'exceljs';
import { saveAs } from 'file-saver';
import { flatten, keyBy } from 'lodash-es';

import { AddressService } from '@calo/services';

const border: Partial<Style> = {
  border: {
    top: { style: 'thin', color: { argb: 'D3D3D3' } },
    left: { style: 'thin', color: { argb: 'D3D3D3' } },
    bottom: { style: 'thin', color: { argb: 'D3D3D3' } },
    right: { style: 'thin', color: { argb: 'D3D3D3' } }
  }
};

const columns: Array<Partial<Column>> = [
  { header: 'Meal Name', width: 35, key: 'name', style: border },
  { header: 'Meal Size', width: 10, key: 'size', style: border },
  { header: 'Number of Meals', width: 15, key: 'quantity', style: border },
  { header: 'Comment', width: 30, style: border }
];

export const onExport = async (order: any, selectedMealsData: any[]) => {
  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet(order.user.fullName, {
    pageSetup: { fitToPage: true, orientation: 'portrait' }
  });
  worksheet.getRow(6).values = ['Meal Name', 'Meal Size', 'Number of Meals', 'Comment'];
  worksheet.columns = columns;
  worksheet.getCell('A1').value = 'Business Name';
  worksheet.getCell('A2').value = 'Address';
  worksheet.getCell('A3').value = 'Deliver Date';
  worksheet.getCell('A4').value = 'Delivery Time';

  worksheet.getCell('A1').style = {
    font: { bold: true },
    alignment: { horizontal: 'left' },
    border: border.border,
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'CDE7D9' } }
  };
  worksheet.getCell('A2').style = {
    font: { bold: true },
    alignment: { horizontal: 'left' },
    border: border.border,
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'CDE7D9' } }
  };
  worksheet.getCell('A3').style = {
    font: { bold: true },
    alignment: { horizontal: 'left' },
    border: border.border,
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'CDE7D9' } }
  };
  worksheet.getCell('A4').style = {
    font: { bold: true },
    alignment: { horizontal: 'left' },
    border: border.border,
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'CDE7D9' } }
  };

  worksheet.getCell('A6').style = {
    font: { bold: true },
    alignment: { horizontal: 'left' },
    border: border.border,
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'B7D0DC' } }
  };
  worksheet.getCell('B6').style = {
    font: { bold: true },
    alignment: { horizontal: 'left' },
    border: border.border,
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'B7D0DC' } }
  };
  worksheet.getCell('C6').style = {
    font: { bold: true },
    alignment: { horizontal: 'left' },
    border: border.border,
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'B7D0DC' } }
  };
  worksheet.getCell('D6').style = {
    font: { bold: true },
    alignment: { horizontal: 'left' },
    border: border.border,
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'B7D0DC' } }
  };

  worksheet.mergeCells('B1', 'E1');
  worksheet.mergeCells('B2', 'E2');
  worksheet.mergeCells('B3', 'E3');
  worksheet.mergeCells('B4', 'E4');

  worksheet.getCell('B1').value = order.user.fullName;
  worksheet.getCell('B2').value = AddressService.display(order.deliveryAddress);
  worksheet.getCell('B3').value = order.date;
  worksheet.getCell('B4').value = order.time;

  const allOrders: any[] = flatten(order.orders.map((order: any) => order.items));
  const keyedMeals = keyBy(selectedMealsData, 'id');

  for (const row of allOrders) {
    worksheet.addRow({
      name: keyedMeals[row.itemId]?.name.en || keyedMeals[row.itemId]?.name || '',
      size: keyedMeals[row.itemId]?.size || '',
      quantity: row.count
    });
  }

  const buffer = await workbook.xlsx.writeBuffer();
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const fileExtension = '.xlsx';
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, 'B2B Delivery Download' + fileExtension);
};
