import React, { useCallback } from 'react';

import ExcelJS, { Style } from 'exceljs';
import { saveAs } from 'file-saver';

import { Subscription } from '@calo/types';

import { Button, Modal, ModalRef } from 'components';

interface ValidateSubscribersModalProps {
  removedUsers: Subscription[];
  selectedSubscribers: Subscription[];
  setselectedSubscribers: (value: Subscription[]) => void;
  validateSubscribersModalRef: React.MutableRefObject<ModalRef | undefined>;
}
const ValidateSubscribersModal = ({
  setselectedSubscribers,
  removedUsers,
  selectedSubscribers,
  validateSubscribersModalRef
}: ValidateSubscribersModalProps) => {
  const onExport = useCallback(async () => {
    if (!removedUsers) {
      return;
    }
    const border: Partial<Style> = {
      border: {
        top: { style: 'medium', color: { argb: 'D3D3D3' } },
        left: { style: 'medium', color: { argb: 'D3D3D3' } },
        bottom: { style: 'medium', color: { argb: 'D3D3D3' } },
        right: { style: 'medium', color: { argb: 'D3D3D3' } }
      }
    };

    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('gift-skipped-users', {
      pageSetup: { fitToPage: true, orientation: 'portrait' }
    });

    worksheet.columns = [
      { header: 'Subscriber ID', width: 64, key: 'id', style: border },
      { header: 'Name', width: 32, key: 'name', style: border },
      { header: 'Phone number', width: 32, key: 'phoneNumber', style: border },
      { header: 'Email', width: 64, key: 'email', style: border }
    ];

    for (const row of removedUsers) {
      worksheet.addRow(
        {
          id: row.id,
          name: row.name,
          phoneNumber: row.phoneNumber,
          email: row.email
        },
        ''
      );
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, 'gift-skipped-users' + fileExtension);
  }, [removedUsers]);

  return (
    <Modal
      ref={validateSubscribersModalRef}
      onClose={() => {
        setselectedSubscribers(selectedSubscribers);
        validateSubscribersModalRef.current?.close();
      }}
    >
      <div className="section -m-10">
        <section className="section is-title-bar -my-8 ">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <p className="uppercase font-roboto text-2xl">Warning ⚠️</p>
              </div>
            </div>
          </div>
        </section>
        <section className="section -m-8">
          <>
            <span>
              <p className="flex text-2xl justify-center font-roboto text-2xl">
                The following subscribers Skipped their delivery
              </p>
              <p className="flex text-md text-red-500 justify-center font-roboto text-2xl">
                these users will not be added to the gifting experiment
              </p>
            </span>
            <section className="section -m-8">
              <div className="card has-table has-table-container-upper-radius">
                <div className="card-content">
                  <div className="table-container overflow-y-auto" style={{ maxHeight: '26rem' }}>
                    <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                      <thead>
                        <tr className="bg-black sticky top-0 z-50">
                          <th style={{ color: 'white' }} className=" w-48">
                            Subscriber ID
                          </th>
                          <th style={{ color: 'white' }} className=" w-48">
                            Subscriber Name
                          </th>
                          <th style={{ color: 'white' }} className=" w-48">
                            Subscriber Phone
                          </th>
                          <th style={{ color: 'white' }} className=" w-48">
                            Subscriber Email
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {removedUsers &&
                          removedUsers?.map((subscriber: Subscription) => (
                            <tr key={subscriber.id}>
                              <td>{subscriber.id}</td>
                              <td>{subscriber.name}</td>
                              <td>{subscriber.phoneNumber}</td>
                              <td>{subscriber.email}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </>
        </section>
        <section className=" section -m-8 flex justify-center">
          <div className="flex justify-between">
            <Button primary content="Export" className="mr-8" disabled={!removedUsers} onClick={() => onExport()} />
            <Button
              primary
              content="Continue"
              onClick={() => {
                setselectedSubscribers(selectedSubscribers);
                validateSubscribersModalRef.current?.close();
              }}
            />
          </div>
        </section>
      </div>
    </Modal>
  );
};
export default ValidateSubscribersModal;
