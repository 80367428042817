import { useRef, useState } from 'react';

import { format } from 'date-fns/fp';
import { startCase } from 'lodash-es';

import { Box, Button, Grid, Stack, styled, TableCell, tableCellClasses, TableRow, Typography } from '@mui/material';

import { getImageUploadLink } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { ImageUploader, ModalRef } from 'components';
import Popup from 'components/Popup';
import { ComplaintData } from 'lib/interfaces';

interface ComplaintUserTypeRowProps {
  row: ComplaintData;
}

const ComplaintUserTypeRow = ({ row }: ComplaintUserTypeRowProps) => {
  const attachmentRef = useRef<ModalRef>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [displayImage, setDisplayImage] = useState<string[]>([]);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      width: '100%',
      px: 4,
      component: 'th',
      scope: 'row',
      color: caloTheme.palette.neutral900,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '17px',
      variant: 'caption'
    },
    [`&.${tableCellClasses.body}`]: {
      px: 4,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '17px',
      variant: 'caption',
      fontFamily: caloTheme.typography.fontFamily
    }
  }));

  return (
    <>
      <TableRow key={row.id}>
        <StyledTableCell>{row.meal?.name ?? '---'}</StyledTableCell>

        <StyledTableCell>{startCase(row.category)}</StyledTableCell>

        <StyledTableCell>{startCase(row.type)}</StyledTableCell>

        <StyledTableCell style={{ textAlign: 'center' }}>
          <Button
            variant="text"
            aria-label="Notes"
            sx={{
              color: caloTheme.palette.primary500,
              fontFamily: 'Roboto',
              textAlign: 'center',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '19px',
              textTransform: 'capitalize'
            }}
            disabled={!row.comments}
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? 'Hide Comment' : 'View Comment'}
          </Button>
        </StyledTableCell>

        <StyledTableCell style={{ textAlign: 'center' }}>
          <Button
            variant="text"
            disabled={!row.attachments || row.attachments.length === 0}
            sx={{
              color: caloTheme.palette.primary500,
              fontFamily: 'Roboto',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '19px',
              textTransform: 'capitalize'
            }}
            onClick={() => {
              setDisplayImage(row?.attachments ?? []);
              attachmentRef.current?.open();
            }}
          >
            View Attachments
          </Button>
        </StyledTableCell>

        <StyledTableCell style={{ textAlign: 'center' }}>
          <Typography>{format('dd/MM/yyyy')(new Date(row.date))}</Typography>
        </StyledTableCell>
      </TableRow>

      {isOpen && (
        <TableRow>
          <StyledTableCell
            colSpan={6}
            sx={{
              familyFont: caloTheme.typography.fontFamily,
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '14px',
              color: caloTheme.palette.neutral900,
              backgroundColor: caloTheme.palette.secondaryBlue50
            }}
          >
            {row.comments && row.comments[row.comments.length - 1].text}
          </StyledTableCell>
        </TableRow>
      )}

      <Popup
        maxWidth="lg"
        fullWidth
        title="Attachment"
        ref={attachmentRef}
        onClose={() => {
          attachmentRef.current?.close();
        }}
      >
        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} key={row?.id}>
          <Grid container spacing={5}>
            {displayImage?.map((image: string, index) => (
              <Grid item key={image}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'start'}>
                      <Stack key={image} display={'flex'} flexDirection={'column'} justifyContent={'start'}>
                        <ImageUploader
                          key={row?.id}
                          uploadLink={() => getImageUploadLink(`complaints/${row?.id.split('#')[1]}/${image}`, ' ')}
                          image={`${process.env.REACT_APP_BUCKET_URL}/complaints/${row?.id.split('#')[1]}/${image}`}
                          disabled={false}
                          width={120}
                          maxHeight={44}
                        />
                      </Stack>
                      <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ width: '100%' }}>
                        <Typography sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
                          Attachment {index + 1}
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Stack
          sx={{
            justifyContent: 'center',
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
            marginTop: 2
          }}
        >
          <Button
            variant="text"
            sx={{
              width: '156px',
              color: caloTheme.palette.primary500,
              fontFamily: 'Roboto',
              textAlign: 'center',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '23px',
              textTransform: 'capitalize'
            }}
            onClick={() => {
              attachmentRef.current?.close();
            }}
          >
            Done
          </Button>
        </Stack>
      </Popup>
    </>
  );
};
export default ComplaintUserTypeRow;
