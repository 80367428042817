import { Permission } from '@calo/dashboard-types';
import { WaitingListType } from '@calo/types';
import { allowWaiting, sendCode } from 'actions';
import cx from 'classnames';
import { Button, ConfirmationModal, ModalRef, OptionsDropdown, PhoneNumber } from 'components';
import { format, parseISO } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { generatePath } from 'react-router-dom';

interface WaitingRowProps {
  user: any;
  kitchenId: string;
  selectedType: any;
  isAllowed: boolean;
  handleDeleteUser: (value: any) => void;
  handleInviteUser: (value: any) => void;
}

const WaitingRow = ({ handleInviteUser, handleDeleteUser, user, kitchenId, selectedType, isAllowed }: WaitingRowProps) => {
  const [optionId, setOptionId] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const confirmModalRef = useRef<ModalRef>();
  const roles = useUserRoles();
  const [optionAction, setOptionAction] = useState('');
  const path = generatePath(Routes.waitingListMap, {
    id: kitchenId,
    waitingListType: selectedType,
    ...(selectedType === WaitingListType.SUPPLY_CAP && { isAllowed: isAllowed.toString() })
  });

  const { mutateAsync: updateMutation } = useMutation(allowWaiting);
  const { mutateAsync: sendCodeMutation } = useMutation(sendCode);

  const handleSendCode = async (id: string, userName: string) => {
    await sendCodeMutation({ id, userName });
  };

  return (
    <tr>
      <td>{user.name}</td>
      <td>
        <PhoneNumber>{user.phoneNumber}</PhoneNumber>
      </td>
      <td>{user.email}</td>
      <td>{user.joinedAt && format('dd-MM-yyy')(parseISO(user.joinedAt))}</td>
      {selectedType === WaitingListType.SOFT_LAUNCH && roles.includes(Permission.SEND_INVITATION_CODE_WAITING_LIST) && (
        <td>
          <span>
            <Button
              content={user.codeLastSent ? 'Resend code' : 'Send code'}
              className="text-white bg-yellow-300 rounded hover:text-white hover:shadow-md focus:text-white border-yellow-300 hover:border-yellow-300"
              onClick={() => handleSendCode(user.id, user.name)}
            />
          </span>
          {user.codeLastSent && (
            <p className="text-gray-300 text-xs">{`Last sent: ${format('dd-MM-yyyy hh:mm a')(parseISO(user.codeLastSent))}`}</p>
          )}
        </td>
      )}
      {selectedType === WaitingListType.SUPPLY_CAP && !isAllowed && <td>{user.action}</td>}
      {(selectedType === WaitingListType.SUPPLY_CAP || isAllowed) && <td>{user.country}</td>}
      {selectedType === WaitingListType.SUPPLY_CAP && roles.includes(Permission.ALLOW_WAITING_LIST) && !user.isAllowed && (
        <td>
          <span>
            <Button
              content="Allow"
              className="text-white bg-yellow-300 rounded hover:text-white hover:shadow-md focus:text-white border-yellow-300 hover:border-yellow-300"
              onClick={() => updateMutation(user.id)}
            />
          </span>
        </td>
      )}
      <td>
        <span className="float-right -mt-1">
          <i
            className="fa fa-map-marker-alt text-3xl text-green-600 cursor-pointer"
            onClick={() => history.push(path, { user: user })}
          />
          {selectedType === WaitingListType.PRE_LAUNCH && roles.includes(Permission.DELETE_WAITING_LIST) && (
            <span>
              <i
                className={cx('cursor-pointer fas fa-ellipsis-v text-xl ml-2')}
                onClick={() => {
                  setOptionId(user.id);
                  setIsOpen(!isOpen);
                }}
              />
              <OptionsDropdown
                visable={isOpen && user.id === optionId}
                className="absolute border-1 bg-gray-50 rounded -ml-8 -mt-1 z-50 shadow-lg"
                buttonsDetails={[
                  {
                    text: 'Send invite',
                    className: 'flex flex-row m-auto shadow-xl  z-50 bg-gray-100',
                    method: () => {
                      setOptionAction('Invite');
                      confirmModalRef.current?.open();
                    },
                    disabled: false
                  },
                  {
                    text: 'Remove',
                    className: 'flex flex-row m-auto shadow-xl z-50',
                    method: () => {
                      setOptionAction('remove');
                      confirmModalRef.current?.open();
                    },
                    disabled: false
                  }
                ]}
              />
            </span>
          )}
        </span>
        <ConfirmationModal
          ref={confirmModalRef}
          values={user}
          action={optionAction === 'Invite' ? () => handleInviteUser(user.id) : () => handleDeleteUser(user.id)}
        >
          {optionAction === 'Invite' ? (
            <div className="mt-4 mb-4 ml-4">
              <span>
                <label className="label">Are you sure want to invite the selected user ? </label>
                <p>
                  This will:
                  <li className="text-sm">Remove them permanently from the pre-launch list.</li>
                  <li className="text-sm">Send an SMS to their phone number that we can deliver to their area now.</li>
                </p>
              </span>
            </div>
          ) : (
            <div>Are you sure want to remove the selected user ?</div>
          )}
        </ConfirmationModal>
      </td>
    </tr>
  );
};

export default WaitingRow;
