import { caloTheme } from 'assets/images/theme/calo';

import { Icon as Iconify } from '@iconify/react';
import { Typography } from '@mui/material';

interface ClickToUploadCardProps {
  icon?: string;
  iconColor?: string;
  fontSize?: number;
  firstFontColor?: string;
  secondFontColor?: string;
  px?: number;
}

const ClickToUploadCard = ({
  icon = 'octicon:image-24',
  iconColor = caloTheme.palette.neutral900,
  fontSize = 18,
  firstFontColor = caloTheme.palette.neutral900,
  secondFontColor = caloTheme.palette.primary500,
  px
}: ClickToUploadCardProps) => (
  <>
    <Iconify icon={icon} width={48} height={48} style={{ color: iconColor }} />
    <Typography fontSize={fontSize} fontWeight={600} color={firstFontColor} px={px}>
      <Typography fontSize={fontSize} fontWeight={600} component="span" color={secondFontColor}>
        Click to Upload{' '}
      </Typography>
      or drag and drop
    </Typography>
  </>
);

export default ClickToUploadCard;
