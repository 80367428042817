import { Country, DeliveryPreferences, DeliveryTime, Subscription } from '@calo/types';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack, Typography } from '@mui/material';
import { updateSubscriptionDeliveryPreferences } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { validateDeliveryPreferences } from '../../DeliveriesCard/DeliveriesMeals/DeliveriesMealTable/shared/DeliveryMealAction/SwapMealPopUp/helpers';
import DeliveryPreferencesSection from './components/DeliveryPreferencesSection';
import SelectMultiPreference from './components/SelectMultiPreference';

type DeliveryPreferencesProps = {
  subscription: Subscription;
};

const DeliveryPreferencesCard = ({ subscription }: DeliveryPreferencesProps) => {
  const [selectedDeliveryPreferencesTemp, setSelectedDeliveryPreferencesTemp] = useState<DeliveryPreferences>({});
  const [selectedDeliveryPreferences, setSelectedDeliveryPreferences] = useState<DeliveryPreferences>({});
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isMultiPreference, setIsMultiPreference] = useState<boolean>(false);
  const [isMultiPreferenceTemp, setIsMultiPreferenceTemp] = useState<boolean>(false);
  const { mutateAsync: updateDeliveryPreferencesMutation } = useMutation(updateSubscriptionDeliveryPreferences);

  let deliveryDaysArray = ['0', '1', '2', '3', '4', '5', '6'];
  if (subscription.country === Country.QA) {
    deliveryDaysArray = deliveryDaysArray.filter((d) => d !== '5');
  }

  useEffect(() => {
    initializeDeliveryDays();
  }, []);

  const initializeDeliveryDays = () => {
    let deliveryDays: DeliveryPreferences = {};
    if (subscription.deliveryPreferences) {
      deliveryDays = subscription.deliveryPreferences;
    } else {
      const defaultDeliveryAddressId = subscription.deliveryAddresses?.find((data) => data.default)?.id ?? '';
      for (const day of deliveryDaysArray) {
        deliveryDays[day as keyof DeliveryPreferences] = {
          deliveryTime: subscription.deliveryTime || DeliveryTime.morning,
          deliveryAddressId: defaultDeliveryAddressId
        };
      }
    }
    setSelectedDeliveryPreferences(deliveryDays);
    setSelectedDeliveryPreferencesTemp(deliveryDays);
    initializeMultiSelectFlag(deliveryDays);
  };

  const setSingleDeliveryAddress = () => {
    const newDeliveryPreferences: DeliveryPreferences = {};
    const defaultDeliveryAddressId = subscription.deliveryAddresses?.find((data) => data.default)?.id ?? '';

    for (const day of deliveryDaysArray) {
      newDeliveryPreferences[day as keyof DeliveryPreferences] = {
        deliveryTime: subscription.deliveryTime || DeliveryTime.morning,
        deliveryAddressId: defaultDeliveryAddressId
      };
    }
    setSelectedDeliveryPreferences(newDeliveryPreferences);
  };

  const initializeMultiSelectFlag = (selectedDeliveryPreferences: DeliveryPreferences) => {
    const deliveryPreferencesValues = Object.values(selectedDeliveryPreferences);
    if (deliveryPreferencesValues.length) {
      const deliveryPreference = deliveryPreferencesValues[0];
      const isAllDaysUsingSameAddress = deliveryPreferencesValues.every(
        (deliveryPreferenceItem) => deliveryPreferenceItem.deliveryAddressId === deliveryPreference.deliveryAddressId
      );
      const isAllDaysUsingSameTime = deliveryPreferencesValues.every(
        (deliveryPreferenceItem) => deliveryPreferenceItem.deliveryTime === deliveryPreference.deliveryTime
      );
      if (isAllDaysUsingSameAddress && isAllDaysUsingSameTime) {
        setIsMultiPreference(false);
        setIsMultiPreferenceTemp(false);
      } else {
        setIsMultiPreference(true);
        setIsMultiPreferenceTemp(true);
      }
    }
  };

  const changeDeliveryAddress = (applyForAllDays: boolean, addressId: string, deliveryDay?: string) => {
    if (applyForAllDays) {
      const newDeliveryPreferences: DeliveryPreferences = {};
      for (const day of deliveryDaysArray) {
        newDeliveryPreferences[day as keyof DeliveryPreferences] = {
          deliveryAddressId: addressId,
          deliveryTime: selectedDeliveryPreferences[day as keyof DeliveryPreferences]?.deliveryTime || DeliveryTime.morning
        };
      }
      setSelectedDeliveryPreferences(newDeliveryPreferences);
    } else if (deliveryDay) {
      setSelectedDeliveryPreferences({
        ...selectedDeliveryPreferences,
        [deliveryDay]: {
          deliveryAddressId: addressId,
          deliveryTime:
            selectedDeliveryPreferences[deliveryDay as keyof DeliveryPreferences]?.deliveryTime || DeliveryTime.morning
        }
      });
    }
  };

  const changeDeliveryTime = (applyForAllDays: boolean, deliveryTime: DeliveryTime, deliveryDay?: string) => {
    if (applyForAllDays) {
      const newDeliveryPreferences: DeliveryPreferences = {};
      for (const day of deliveryDaysArray) {
        newDeliveryPreferences[day as keyof DeliveryPreferences] = {
          deliveryAddressId: selectedDeliveryPreferences[day as keyof DeliveryPreferences]?.deliveryAddressId || '',
          deliveryTime: deliveryTime
        };
      }

      setSelectedDeliveryPreferences(newDeliveryPreferences);
    } else if (deliveryDay) {
      setSelectedDeliveryPreferences({
        ...selectedDeliveryPreferences,
        [deliveryDay]: {
          deliveryAddressId: selectedDeliveryPreferences[deliveryDay as keyof DeliveryPreferences]?.deliveryAddressId,
          deliveryTime
        }
      });
    }
  };

  const checkIfAllDaysSelected = () => {
    let isAllDaysSelected = true;
    for (const day of deliveryDaysArray) {
      const selectedDeliveryDayPreferences = selectedDeliveryPreferences[day as keyof DeliveryPreferences];
      const validDeliveryAddress = subscription.deliveryAddresses.find(
        (deliveryAddress) => deliveryAddress.id === selectedDeliveryDayPreferences?.deliveryAddressId
      );
      if (
        !selectedDeliveryDayPreferences ||
        !selectedDeliveryDayPreferences.deliveryAddressId ||
        !selectedDeliveryDayPreferences.deliveryTime ||
        !validDeliveryAddress
      ) {
        isAllDaysSelected = false;
      }
    }
    return isAllDaysSelected;
  };

  const saveEditing = async () => {
    if (!checkIfAllDaysSelected()) {
      toast(`You should select delivery preferences for all days`, {
        type: 'error',
        autoClose: 3000
      });
      return;
    }

    if (validateDeliveryPreferences(selectedDeliveryPreferences) && isMultiPreference) {
      toast(`Please ensure delivery preferences vary for different days.`, {
        type: 'error',
        autoClose: 3000
      });
      return;
    }

    await updateDeliveryPreferencesMutation({
      deliveryPreferences: selectedDeliveryPreferences,
      id: subscription.id
    });
    setSelectedDeliveryPreferencesTemp(selectedDeliveryPreferences);
    setIsMultiPreferenceTemp(isMultiPreference);
    setIsEditing(!isEditing);
  };

  const cancelEditing = () => {
    setSelectedDeliveryPreferences(selectedDeliveryPreferencesTemp);
    setIsMultiPreference(isMultiPreferenceTemp);
    setIsEditing(false);
  };

  return (
    <div style={{ padding: '15px' }}>
      <Box
        sx={{
          display: 'flex',
          direction: 'row',
          width: 'full',
          justifyContent: 'space-between',
          padding: '12px',
          backgroundColor: caloTheme.palette.neutral50,
          borderRadius: 2
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: '19px',
            lineHeight: '23px',
            my: 'auto',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Address & Time
        </Typography>
        {isEditing ? (
          <Stack flexDirection={'row'} gap={1}>
            <CheckIcon style={{ cursor: 'pointer', padding: 1 }} onClick={() => saveEditing()} />
            <CloseIcon style={{ cursor: 'pointer', padding: 1 }} onClick={() => cancelEditing()} />
          </Stack>
        ) : (
          <Icon
            onClick={() => setIsEditing(!isEditing)}
            name="edit2"
            size={6}
            style={{ cursor: 'pointer', width: '26px', height: '26px' }}
          />
        )}
      </Box>
      <SelectMultiPreference
        isEditing={isEditing}
        isMultiPreference={isMultiPreference}
        setIsMultiPreference={setIsMultiPreference}
        setSingleDeliveryAddress={setSingleDeliveryAddress}
      />
      <DeliveryPreferencesSection
        isEditing={isEditing}
        isApplyForAllDays={!isMultiPreference}
        subscription={subscription}
        selectedDeliveryPreferences={selectedDeliveryPreferences}
        changeDeliveryAddress={changeDeliveryAddress}
        changeDeliveryTime={changeDeliveryTime}
        deliveryDays={deliveryDaysArray}
      />
    </div>
  );
};

export default DeliveryPreferencesCard;
