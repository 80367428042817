import { Brand, Country, Subscription } from '@calo/types';
import { Button, CaloLoader, Input, ModalRef } from 'components';
import { Menu, MenuFood } from 'lib/interfaces';
import { groupBy } from 'lodash';
import React, { useMemo } from 'react';
import AddMealRow from './AddMealRow';

interface AddMealModalProps {
  menu: Menu;
  page: number;
  loadingMeals: boolean;
  selectedMeals: MenuFood[];
  setPage: (v: number) => void;
  filterName: string | undefined;
  autoSelectSubscribers: boolean | undefined;
  selectedSubscribers: Subscription[];
  setFilterName: (value: string) => void;
  setselectedSubscribers: (v: any) => void;
  setSelectedMeals: (value: MenuFood[]) => void;
  selectedCountryBrand: { brand: Brand; country: Country };
  addMealModalRef: React.MutableRefObject<ModalRef | undefined>;
}
const AddMealTable = ({
  menu,
  loadingMeals,
  selectedSubscribers,
  addMealModalRef,
  setselectedSubscribers,
  filterName,
  setFilterName,
  selectedMeals,
  setSelectedMeals,
  autoSelectSubscribers
}: AddMealModalProps) => {
  const menuFood = useMemo(() => {
    if (!menu) {
      return [];
    }
    return Object.values(groupBy(menu?.food, 'name.en')).map((r) => r[0]);
  }, [menu]);

  const filterByName = filterName
    ? menuFood.filter((meal) => meal.name.en.toLowerCase().includes(filterName.toLowerCase()))
    : menuFood;

  return (
    <section className="section">
      <div className="mb-4">
        <Input label="Name" value={filterName} onChange={(e) => setFilterName(e.target.value)} debounce />
      </div>
      <div>
        {loadingMeals ? (
          <CaloLoader />
        ) : (
          <div className="card has-table has-table-container-upper-radius">
            <div className="card-content">
              <div className="table-container overflow-y-auto" style={{ maxHeight: '26rem' }}>
                <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                  <thead>
                    <tr className="bg-black sticky top-0 z-50">
                      <th style={{ color: 'white' }} className=" w-64">
                        Name
                      </th>
                      <th style={{ color: 'white' }} className=" w-48">
                        Rating
                      </th>
                      <th style={{ color: 'white' }} className=" w-48">
                        Type
                      </th>
                      <th style={{ color: 'white' }} className=" w-48">
                        Size
                      </th>
                      <th style={{ color: 'white' }} className=" w-48">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterByName &&
                      filterByName?.map((food: MenuFood) => (
                        <AddMealRow
                          key={food.id}
                          food={food}
                          allMenuFood={menu.food}
                          selectedMeals={selectedMeals}
                          selectedUsers={selectedSubscribers}
                          onPick={(v) => setSelectedMeals(v)}
                          setSelectedUsers={setselectedSubscribers}
                          autoSelectSubscribers={autoSelectSubscribers}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center">
        <Button
          primary
          content="Done"
          className="-mb-6 mt-6"
          disabled={selectedMeals.length === 0}
          onClick={() => addMealModalRef.current?.close()}
        />
      </div>
    </section>
  );
};

export default AddMealTable;
