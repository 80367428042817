import { Permission } from '@calo/dashboard-types';
import { Icon } from '@iconify/react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, Card, Stack, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { getList } from 'actions';
import { exportMrpReport } from 'actions/supplyChainReports';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { StyledTableCell } from 'lib/componentStyles';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { MaterialResourcePlanningItem } from 'lib/interfaces';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

const MaterialResourcePlanningReport = () => {
  const { kitchen } = useParams<{ kitchen: string; day: string }>();
  const { data, isLoading } = useQuery<string, Error, { data: MaterialResourcePlanningItem[] }>(
    `inventory/${kitchen}/mrp-report`,
    getList,
    {}
  );
  const roles = useUserRoles();

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: 2,
          border: 'none',
          borderRadius: '16px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          display={'flex'}
          flexDirection="row"
          justifyContent={'space-between'}
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
          width="100%"
        >
          <Stack width={'100%'} display={'flex'} flexDirection={'row'} alignItems="start" justifyContent={'space-between'}>
            <Stack display={'flex'} flexDirection={'row'} alignItems="center">
              <Link
                to={Routes.supplyChainReportsList}
                style={{ color: 'black', display: 'flex', flexDirection: 'row', gap: '8px', height: '100%' }}
              >
                <ArrowBackIosIcon
                  style={{
                    fontSize: '14px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 'auto',
                    marginBottom: 'auto'
                  }}
                />
                <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>Back</Typography>
              </Link>
              <Typography
                variant="h3"
                sx={{
                  ml: 3,
                  mt: 'auto',
                  fontWeight: 600,
                  fontSize: '33px',
                  lineHeight: '40px',
                  color: caloTheme.palette.neutral900,
                  fontFamily: caloTheme.typography.fontFamily
                }}
              >
                {`${kitchen.slice(0, 2)} ${kitchen.slice(2)}`}
              </Typography>
            </Stack>
            {roles.includes(Permission.EXPORT_MATERIAL_RESOURCE_PLANING_REPORT) && (
              <Button
                onClick={() => exportMrpReport(kitchen)}
                startIcon={<Icon icon="material-symbols-light:download-sharp" width="32" height="32" />}
                sx={{ color: 'black', fontWeight: 'bold' }}
              ></Button>
            )}
          </Stack>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems="end"
            sx={{
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
                flexDirection: 'column'
              }
            }}
          ></Stack>
        </Box>
      </Card>
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: 2,
          border: 'none',
          borderRadius: '16px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
          {isLoading ? (
            <CaloLoader />
          ) : (
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: caloTheme.palette.neutral50,
                    color: 'black',
                    width: '100%',
                    flexWrap: 0,
                    justifyContent: 'flex-start'
                  }}
                >
                  <StyledTableCell
                    style={{ borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px', width: '20%', fontWeight: 'bold' }}
                  >
                    Category
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '20%', fontWeight: 'bold' }}>Recipe Ref</StyledTableCell>
                  <StyledTableCell style={{ width: '8%', fontWeight: 'bold' }}>Conv. Unit</StyledTableCell>
                  <StyledTableCell style={{ width: '8%', fontWeight: 'bold' }}>Stock</StyledTableCell>
                  <StyledTableCell style={{ width: '8%', fontWeight: 'bold' }}>Weekly Usage</StyledTableCell>
                  <StyledTableCell style={{ width: '8%', fontWeight: 'bold' }}>Balance</StyledTableCell>
                  <StyledTableCell style={{ width: '8%', fontWeight: 'bold' }}>Run Out Days</StyledTableCell>
                  <StyledTableCell style={{ width: '8%', fontWeight: 'bold' }}>Open POS</StyledTableCell>
                  <StyledTableCell style={{ width: '8%', fontWeight: 'bold' }}>Balance Including PO</StyledTableCell>
                  <StyledTableCell
                    style={{ borderTopRightRadius: '16px', borderBottomRightRadius: '16px', width: '10%', fontWeight: 'bold' }}
                  >
                    Status
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.data.map((row) => (
                    <TableRow key={row.recipeRef} sx={{ borderBottom: '1px solid #e0e0e0' }}>
                      <StyledTableCell>{row.category}</StyledTableCell>
                      <StyledTableCell>{row.recipeRef}</StyledTableCell>
                      <StyledTableCell>{row.convertedUnit}</StyledTableCell>
                      <StyledTableCell>{row.stock}</StyledTableCell>
                      <StyledTableCell>{row.weeklyUsage}</StyledTableCell>
                      <StyledTableCell>{row.balance}</StyledTableCell>
                      <StyledTableCell>{row.runOutDays}</StyledTableCell>
                      <StyledTableCell>{row.openPOs}</StyledTableCell>
                      <StyledTableCell>{row.balanceIncludingPO}</StyledTableCell>
                      <StyledTableCell>{row.status}</StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </Card>
    </>
  );
};

export default MaterialResourcePlanningReport;
