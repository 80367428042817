import { useEffect, useState } from 'react';

import { differenceInDays, endOfWeek, parseISO, startOfWeek } from 'date-fns';
import { format } from 'date-fns/fp';
import { isEmpty, keyBy, orderBy, uniqBy } from 'lodash-es';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { BatchUpdateFoodListReq, BatchUpdateFoodReq, MenuFoodComponent, Permission } from '@calo/dashboard-types';
import { Stack } from '@mui/material';

import { batchUpdatePrototypeFood, getList, getListWithParams } from 'actions';
import { InputValueRestrictionType, Routes } from 'lib/enums';
import { isNumberInputValid } from 'lib/helpers';
import { getUsedOnMenuButtonColor } from 'lib/helpers/usedOnMenu';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import { Food, FoodComponent } from 'lib/interfaces';
import FoodForm from './FoodForm';

const formatComponents = (components?: MenuFoodComponent[]) => {
  return components?.map((comp) => ({
    id: comp.id,
    quantity: comp.quantity
  }));
};

const ExactFood = () => {
  const roles = useUserRoles();
  const { slug } = useParams<{ slug: string }>();

  const { mutateAsync: updateMutation } = useMutation(batchUpdatePrototypeFood);

  const [name, setName] = useState('');

  const [usedOnMenuButtonColor, setUsedOnMenuButtonColor] = useState<string[]>([]);

  const [foodWithAllSizes, setFoodWithAllSizes] = useState<Food[]>([]);
  const [selectedFood, setSelectedFood] = useState<Food | undefined>();

  const { refetch, data } = useQuery<string, Error, Food[]>([`food/prototype/slug/${slug}`], getList, {
    suspense: true
  });

  useEffect(() => {
    if (data && data.length > 0) {
      const prioritySizes = ['M', 'S', 'L'];
      const foodWithPreferredSize = data.find((food) => prioritySizes.includes(food.size.toUpperCase()));
      const selectedFood = foodWithPreferredSize ?? foodWithAllSizes[0];
      setFoodWithAllSizes(data);
      setSelectedFood(selectedFood);
      if (selectedFood) {
        setSelectedFood(selectedFood);
      } else {
        setSelectedFood(data[0]);
      }
    }
  }, [data]);

  const { data: foodComponentSearchResult } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        limit: 50,
        filters: {
          withChildComponents: true,
          name,
          country: selectedFood?.country,
          brand: selectedFood?.brand,
          kitchen: selectedFood?.kitchen
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!name && roles.includes(Permission.VIEW_FOOD_COMPONENTS_LIST),
      keepPreviousData: true
    }
  );

  const { data: prototypeComponentSearchResult } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components/prototype',
      {
        limit: 50,
        filters: {
          withChildComponents: true,
          name,
          country: selectedFood?.country,
          brand: selectedFood?.brand,
          kitchen: selectedFood?.kitchen
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!name && roles.includes(Permission.VIEW_PROTOTYPE_COMPONENT_LIST),
      keepPreviousData: true
    }
  );

  useEffect(() => {
    if (!selectedFood?.usedOnMenu) return;
    for (const data of selectedFood.usedOnMenu) {
      setUsedOnMenuButtonColor((old) => [...old, getUsedOnMenuButtonColor(data)]);
    }
  }, [selectedFood]);

  const filteredFoodUsed = uniqBy(
    orderBy(selectedFood?.usedOnMenu, ['date', 'week'], ['asc'])?.filter((r) => {
      if (r.date) {
        return differenceInDays(parseISO(r.date!), parseISO(format('yyyy-MM-dd')(+Date.now()))) >= 0;
      } else {
        const weekNumber = r.week!.toString().split('#');
        const date = new Date(+weekNumber[0], 0, 1 + +weekNumber[1] * 7);
        const weekDate = date;
        weekDate.setDate(date.getDate() - 7);
        return (
          differenceInDays(parseISO(format('yyyy-MM-dd')(startOfWeek(weekDate))), parseISO(format('yyyy-MM-dd')(+Date.now()))) &&
          differenceInDays(+endOfWeek(weekDate), Date.now()) >= 0
        );
      }
    }),
    'date'
  );

  const handleUpdateFood = async ({
    averageRating: _averageRating,
    numberOfRatings: _numberOfRatings,
    totalRating: _totalRating,
    country: _country,
    brand: _brand,
    ...values
  }: Partial<Food>) => {
    //not common changes
    const {
      size,
      components,
      packagingIds,
      macros,
      withManualMacros,
      micronutrients,
      price,
      metadata,
      b2bPrice: _,
      ...commonChanges
    } = values;
    if (selectedFood) {
      const updatedFoods: BatchUpdateFoodReq = [];

      const macrosExceedingLimit = Object.fromEntries(
        Object.entries(macros ?? {}).filter(([_, value]) => !isNumberInputValid(value, InputValueRestrictionType.macros))
      );

      if (!isEmpty(macrosExceedingLimit)) {
        toast(`${Object.keys(macrosExceedingLimit).join(', ')} Exceed max allowed limit`, { type: 'error' });
        return;
      }

      updatedFoods.push(
        ...foodWithAllSizes.map((food) => {
          if (food.id === selectedFood.id) {
            // update selected size with all changes
            return {
              id: selectedFood.id,
              size,
              components: formatComponents(components) as any,
              packagingIds,
              macros,
              price,
              micronutrients,
              withManualMacros,
              metadata
            };
          } else {
            //update all other sizes with common changes and reorder/add/remove
            let comps = food.components;
            if (components) {
              const rightCompOrder = components.map((c) => c.id);
              const keyedComp = keyBy(food.components, 'id');
              comps = rightCompOrder.map((id) => {
                if (keyedComp[id]) return keyedComp[id];
                else
                  return {
                    id,
                    quantity: 0
                  };
              });
              return { id: food.id, components: formatComponents(comps) };
            }
            return { id: food.id };
          }
        })
      );
      const req: BatchUpdateFoodListReq = {
        updatedCommonAttributes: commonChanges,
        updatedFoods
      };
      const response = await updateMutation(req, {
        onSuccess: () => {
          if (filteredFoodUsed.length > 0) {
            toast('The change will take effect on the next new generated menu', { type: 'success', autoClose: 2000 });
          }
          refetch(); //useMutation once the BE is fixed
        }
      });
      setFoodWithAllSizes(response);
      if (values.name?.en !== selectedFood.name.en) {
        history.push(Routes.playgroundFoodSlug.replace(':slug', response[0].slug));
      }
    }
  };

  if (selectedFood) {
    return (
      <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={0}>
        <FoodForm
          name={name}
          refetch={refetch}
          setName={setName}
          food={selectedFood}
          onSubmit={handleUpdateFood}
          selectedFood={selectedFood}
          setSelectedFood={setSelectedFood}
          foodWithAllSizes={foodWithAllSizes}
          filteredFoodUsed={filteredFoodUsed}
          allSizesFood={foodWithAllSizes}
          setFoodWithAllSizes={setFoodWithAllSizes}
          usedOnMenuButtonColor={usedOnMenuButtonColor}
          foodComponentsFromSearch={[...(foodComponentSearchResult?.data ?? []), ...(prototypeComponentSearchResult?.data ?? [])]}
        />
      </Stack>
    );
  } else {
    return <div />;
  }
};

export default ExactFood;
