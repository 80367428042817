import { useFormik } from 'formik';

import { UpdateSubscriptionReq } from '@calo/dashboard-types';

export default (subscription: UpdateSubscriptionReq, onSubmit: (values: UpdateSubscriptionReq) => any) =>
  useFormik<UpdateSubscriptionReq>({
    enableReinitialize: true,
    initialValues: {
      dayAllowance: subscription.dayAllowance,
      autoRenew: subscription.autoRenew,
      paymentMethod: subscription.paymentMethod
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    validate: (_: UpdateSubscriptionReq) => {},
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
