import React, { useState } from 'react';

import { chunk, uniqBy } from 'lodash';
import { useMutation, useQuery } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import { Brand, Country, Subscription } from '@calo/types';

import { deleteSubscriberItem, getListWithParams } from 'actions';
import mutation from 'actions/mutation';
import { Button, CaloLoader, Input, Modal, ModalRef, Pagination } from 'components';
import { resolveSize, types } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { MenuFood } from 'lib/interfaces';

interface ShowDataModalProps {
  giftID: string;
  dataType: string;
  listType: string;
  meals: MenuFood[];
  addons: any[];
  selectedDate: string | undefined;
  totalRecords: number;
  setGiftID: (value: string) => void;
  selectedCountryBrand: { brand: Brand; country: Country };
  showDataModalRef: React.MutableRefObject<ModalRef | undefined>;
}
const ShowDataModal = ({
  listType,
  setGiftID,
  meals,
  addons,
  dataType,
  giftID,
  totalRecords,
  selectedDate,
  showDataModalRef,
  selectedCountryBrand
}: ShowDataModalProps) => {
  const [page, setPage] = useState(0);
  const [checkCursor, setCheckCursor] = useState(undefined);
  const [giftListItems, setGiftListItems] = useState<any[]>([]);
  const [filterName, setFilterName] = useState<string>();
  const { mutateAsync: deleteMutation } = useMutation(deleteSubscriberItem);

  const [date, recordID] = giftID.split('#');
  const roles = useUserRoles();

  const { data, isLoading } = useQuery<any, Error, any>(
    [`/group-gifts/${date}/${recordID}/items`, { limit: 8, cursor: checkCursor }],
    getListWithParams,
    {
      suspense: false,
      keepPreviousData: false,
      enabled: roles.includes(Permission.LIST_GROUP_GIFT_ITEM) && giftID.length > 0,
      onSuccess: (dataList) => {
        setGiftListItems((old: any) => uniqBy([...old, ...dataList.data], 'id'));
      }
    }
  );

  let filteredData;
  const handleDraftData = (dataType: string) => {
    if (dataType === 'subscribers') {
      filteredData = filterName
        ? giftListItems.filter((r) => r.name.toLowerCase().includes(filterName.toLowerCase()))
        : giftListItems;
    } else if (dataType === 'meals') {
      filteredData = filterName ? meals.filter((r) => r.name.en.toLowerCase().includes(filterName.toLowerCase())) : meals;
    } else {
      filteredData = filterName ? addons.filter((r) => r.name.en.toLowerCase().includes(filterName.toLowerCase())) : addons;
    }
    const giftingListArray = chunk(filteredData, 8);
    return giftingListArray[page] || [];
  };

  const handleDeleteItem = async (giftID: string, subID: string) => {
    await deleteMutation(
      { id: giftID, userId: subID },
      {
        onSuccess: () => {
          setGiftListItems(giftListItems.filter((r) => r.id !== subID));
          mutation(
            ['group-gifts', { filters: { country: selectedCountryBrand.country, brand: selectedCountryBrand.brand } }],
            ''
          );
        }
      }
    );
  };

  const handleNewPage = () => {
    if (data.meta.cursor) {
      setCheckCursor(data.meta.cursor);
    }
    setPage(page + 1);
  };

  return (
    <Modal
      ref={showDataModalRef}
      onClose={() => {
        setPage(0);
        setGiftID('');
        setGiftListItems([]);
        setCheckCursor(undefined);
        showDataModalRef.current?.close();
      }}
    >
      <>
        <section className="section is-title-bar -my-4">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <p className="uppercase font-roboto text-2xl">{dataType}</p>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <p className="uppercase font-roboto text-2xl">
                  {selectedCountryBrand.country}-{selectedCountryBrand.brand}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className=" flex flex-row mx-4 mt-6">
          <div className="w-full">
            <Input
              label="Name"
              className="w-full"
              value={filterName}
              onChange={(e) => {
                setPage(0);
                setFilterName(e.target.value);
              }}
              debounce
            />
          </div>
        </section>
        <section className="section -m-8">
          {isLoading ? (
            <CaloLoader />
          ) : (
            <>
              <div className="card has-table has-table-container-upper-radius">
                <div className="card-content">
                  <div className="table-container overflow-y-auto" style={{ maxHeight: '26rem' }}>
                    <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth" key={dataType}>
                      <thead>
                        {dataType === 'subscribers' ? (
                          <tr className="bg-black sticky top-0 z-50">
                            <th style={{ color: 'white' }} className=" w-48">
                              Subscriber ID
                            </th>
                            <th style={{ color: 'white' }} className=" w-48">
                              Subscriber Name
                            </th>
                            <th style={{ color: 'white' }} className=" w-48">
                              Subscriber Phone
                            </th>
                            <th style={{ color: 'white' }} className=" w-48">
                              Delivery Date
                            </th>
                            {listType === 'released' && (
                              <th style={{ color: 'white' }} className=" w-48">
                                Status
                              </th>
                            )}
                            <th style={{ color: 'white' }} className=" w-48">
                              Subscriber Email
                            </th>
                            {listType === 'draft' && roles.includes(Permission.DELETE_GROUP_GIFT_ITEM) && (
                              <th style={{ color: 'white' }} className=" w-48"></th>
                            )}
                          </tr>
                        ) : (
                          <tr className="bg-black sticky top-0 z-50">
                            <th style={{ color: 'white' }} className=" w-48">
                              Meal Name
                            </th>
                            <th style={{ color: 'white' }} className=" w-48">
                              Type
                            </th>
                            <th style={{ color: 'white' }} className=" w-48">
                              Size
                            </th>
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {dataType === 'subscribers'
                          ? handleDraftData(dataType)?.map((subscriber: Subscription | any) => (
                              <tr key={subscriber.id}>
                                <td>{subscriber.id}</td>
                                <td>{subscriber.name}</td>
                                <td>{subscriber.phoneNumber}</td>
                                <td>{selectedDate}</td>
                                {listType === 'released' && <td className="capitalize">{subscriber.status}</td>}
                                <td>{subscriber.email}</td>
                                {listType === 'draft' && roles.includes(Permission.DELETE_GROUP_GIFT_ITEM) && (
                                  <td>
                                    <Button
                                      content="Remove"
                                      className="w-24 border-2 border-red-500 bg-red-50 text-red-500 outline:none hover:text-red-500 hover:border-red-500 focus:border-red-500 focus:text-red-500"
                                      onClick={() => handleDeleteItem(giftID, subscriber.id)}
                                    />
                                  </td>
                                )}
                              </tr>
                            ))
                          : handleDraftData(dataType)?.map((meal: MenuFood | any) => (
                              <tr key={meal.id}>
                                <td>{meal.name.en}</td>
                                <td>
                                  <span className="flex">{meal.foodType?.map((type: any) => types(type))}</span>
                                </td>
                                <td className="font-bold">{resolveSize(meal.size)}</td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
          {dataType === 'meals' || dataType === 'addons' ? (
            <Pagination
              limit={8}
              total={
                dataType === 'meals' && meals?.length
                  ? meals?.length
                  : dataType === 'addons' && addons?.length
                    ? addons?.length
                    : 0
              }
              page={page}
              onChange={setPage}
            />
          ) : (
            <>
              {totalRecords >= 8 && (
                <span className="flex flex-row">
                  <Button content="prev" onClick={() => setPage(page - 1)} disabled={page === 0} primary />
                  <p className="mx-4 mt-2">{page + 1}</p>
                  <Button
                    content="next"
                    onClick={() => handleNewPage()}
                    disabled={page === Math.ceil(totalRecords / 8) - 1}
                    primary
                  />
                </span>
              )}
              <span className={totalRecords >= 8 ? 'flex float-right  -mt-10' : 'flex float-right'}>Total: {totalRecords}</span>
            </>
          )}
        </section>
      </>
    </Modal>
  );
};
export default ShowDataModal;
