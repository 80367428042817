import { useMemo, useState } from 'react';

import { format } from 'date-fns/fp';
import { compact, flatten, keyBy, sortBy, unionBy, uniq, uniqBy } from 'lodash-es';
import { ActionMeta } from 'react-select';

import { Permission } from '@calo/dashboard-types';
import { FoodDietType, MenuTagValue } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import { Button, Stack, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { caloTheme } from 'assets/images/theme/calo';
import { handleSearch } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { Food, MenuFood, Options } from 'lib/interfaces';
import Select from '../Select';

interface FoodPickerProps {
  value: string[];
  disabled: boolean;
  createMenu: boolean;
  foodList: MenuFood[];
  foodLoading: boolean;
  showPopulateDate: boolean;
  populateSearchDate: string;
  setFilterName?: (value: string) => void;
  setPopulateSearchDate: (value: string) => void;
  onChange: (value: MenuFood[], tags?: any) => void;
}

const FoodPicker = ({
  disabled,
  createMenu,
  foodLoading,
  onChange,
  value,
  foodList,
  setFilterName,
  populateSearchDate,
  setPopulateSearchDate,
  showPopulateDate
}: FoodPickerProps) => {
  const roles = useUserRoles();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [foodTags, setFoodTags] = useState<MenuTagValue | string>();
  const [selectedFoods, setSelectedFoods] = useState<Food[]>([]);
  const allFoodList = useMemo(() => unionBy(foodList, selectedFoods, 'id'), [foodList, selectedFoods]);
  const keyedFoodList = useMemo(() => keyBy(allFoodList, 'id'), [allFoodList]);

  const checkExistence = (id: string) => value.find((existFood) => existFood === id);
  const food = allFoodList.filter((r) => !checkExistence(r.id));
  const sortedFoodList = sortBy(food, (f) => `${f.name.en}-${f.size}`).filter((f) => ['XS', 'S', 'M', 'L'].includes(f.size));
  const options = useMemo<Options<MenuFood>[]>(
    () =>
      sortedFoodList.map((food) => ({
        name: food.name.en,
        value: food.id,
        data: food,
        label: `${food.name.en} ${food.size && `(${food.size})`} ${food.tags.length ? `(${food.tags.map((e) => e)})` : ''}`
      })),
    [allFoodList, food, selectedFoods, foodList]
  );

  const touched = useMemo(() => uniq(value.map((id) => keyedFoodList[id]?.name.en)), [value]);

  const handleFoodChange = (data: Food[], action: string) => {
    if (action === 'select-option') {
      const allFood = data.filter((food) => ['XS', 'S', 'M', 'L'].includes(food.size));
      const tags = allFood.map((f) => {
        const isPreBuiltFood = f.tags.includes(FoodDietType.preBuiltCustom);
        const value: (MenuTagValue | string)[] = [];
        if (isPreBuiltFood) {
          value.push(MenuTagValue.CUSTOMIZABLE);
        }
        if (foodTags) {
          value.push(foodTags);
        }
        return [{ foodId: f.id, value }];
      });
      flatten(compact(tags)).length > 0 ? onChange(allFood, flatten(compact(tags))) : onChange(allFood);
      setSelectedIds([]);
      setFoodTags('');
    }
  };

  const handleSelectFood = (data: any, { action }: ActionMeta<any>) => {
    switch (action) {
      case 'select-option': {
        const res: any = uniqBy(
          flatten(
            data.map((r) => {
              if (r.name && touched.includes(r.name)) {
                return keyedFoodList[r.value];
              }
              return allFoodList.filter((f) => f.name.en === r.name);
            })
          ),
          'id'
        );
        setSelectedFoods(res as Food[]);
        setSelectedIds(res.map((f) => f.id));

        break;
      }
      case 'remove-value': {
        setSelectedFoods(data.map((f) => f.data) as Food[]);
        setSelectedIds((data || []).map((f) => f.value));

        break;
      }
      case 'clear': {
        setSelectedFoods([]);
        setSelectedIds([]);

        break;
      }
      // No default
    }
  };

  return (
    <>
      <div className="field">
        <div className="flex flex-row">
          <div className="field-body">
            <Select
              isMulti
              onChange={handleSelectFood}
              className="mr-4 "
              isLoading={foodLoading}
              options={options}
              value={selectedIds}
              isDisabled={!roles.includes(Permission.UPDATE_MENU) || !roles.includes(Permission.CREATE_MENU) || disabled}
              onInputChange={(data: string, action: any) =>
                setFilterName && handleSearch({ text: data, action, name: setFilterName })
              }
              placeholder={'Search...'}
              data-test="menu-foodpicker-food-select"
            />
          </div>
          {createMenu && showPopulateDate && (
            <Stack sx={{ mr: 1, mt: '-16px' }}>
              <LocalizationProvider dateAdapter={DateFnsAdapter}>
                <DesktopDatePicker
                  label="Date"
                  inputFormat="dd-MM-yyyy"
                  value={populateSearchDate}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(date) => setPopulateSearchDate(format('yyyy-MM-dd')(+date!))}
                  minDate={format('yyyy-MM-dd')(Date.now())}
                />
              </LocalizationProvider>
            </Stack>
          )}
          <div className="flex flex-row">
            <Button
              variant="contained"
              data-test="menu-foodpicker-add-button"
              sx={{
                width: '120px',
                height: '40px',
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                borderRadius: '8px',
                backgroundColor: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary500,
                boxShadow: 'none',
                color: 'white',
                '&:hover': {
                  boxShadow: 'none',
                  backgroundColor: caloTheme.palette.primary600,
                  borderColor: caloTheme.palette.primary600
                }
              }}
              disabled={selectedIds.length === 0}
              onClick={() => handleFoodChange(selectedFoods, 'select-option')}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default FoodPicker;
