import { NewDeliveryAddress } from '@calo/types';
import { AddressServiceClass } from '@calo/services';
import { merge } from 'lodash-es';

class AddressService extends AddressServiceClass {
  private autocompleteService = new google.maps.places.AutocompleteService();
  private autocomplete = new google.maps.places.PlacesService(document.createElement('div'));
  private geocode = new google.maps.Geocoder();

  autocompleteAddress(address: string, config: Omit<google.maps.places.AutocompletionRequest, 'input'>): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.autocompleteService.getPlacePredictions(
        merge(
          {},
          {
            input: address
          },
          config
        ),
        (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
            resolve([]);
          } else if (status !== google.maps.places.PlacesServiceStatus.OK) {
            reject(status);
          }
          console.log(results, 'results');

          resolve(
            (results || []).map((result) => ({
              label: result.description,
              value: result.place_id
            }))
          );
        }
      );
    });
  }

  getPlace(id: string): Promise<google.maps.places.PlaceResult> {
    return new Promise((resolve, reject) => {
      this.autocomplete.getDetails(
        {
          placeId: id
        },
        (result, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            reject(status);
          }
          console.log(result);
          resolve(result!);
        }
      );
    });
  }

  getAddress(lat: number, lng: number): Promise<Partial<NewDeliveryAddress>> {
    return new Promise((resolve, reject) => {
      this.geocode.geocode(
        {
          location: {
            lat,
            lng
          }
        },
        (result, status) => {
          if (status !== google.maps.GeocoderStatus.OK) {
            reject(status);
          }
          resolve(this.parseAddress(result!));
        }
      );
    });
  }
}

export default new AddressService();
