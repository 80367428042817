import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, LinearProgress, Button as MUIButton, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { flatten, unionBy } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { FoodDietType, Kitchen, MenuTagValue } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import { caloTheme } from 'assets/images/theme/calo';
import { getYear } from 'date-fns';
import { MenuPresentationType } from 'lib/enums';
import { findStartAndEndDateForWeek, handleMealCost, isDateInSelectedWeek } from 'lib/helpers';
import { Food } from 'lib/interfaces';
import { Search, SearchIconWrapper, StyledInputBase, styles } from 'views/Menu/MainMenu/MenuList/WeeklyInfoCard/styles';
import Icon from '../Icon';
import { ModalRef } from '../Modal';
import Popup from '../Popup';
import { AddMealsByFiltersProps } from './AddMealsInterfaces';
import ConfirmationPopup from './ConfirmationPopup';
import FilterSection from './FilterSection';
import FoodItemCard from './FoodItemCard';

const AddMealsByFilters = ({
  confirmation = false,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  foodIds,
  handleReplaceFood,
  handleFoodChanges,
  handleClosePopup,
  foodListFilters,
  isLoading,
  filtersOn,
  setFiltersOn,
  values,
  replaceFood,
  setSelectedMenuFilters,
  selectedMenuFilters,
  selectedWeek,
  onDateSelected,
  selectedYear = getYear(new Date()),
  menuPresentation = MenuPresentationType.daily
}: AddMealsByFiltersProps) => {
  const confirmationRef = useRef<ModalRef>();
  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);
  const lastFoodElementRef = useRef<HTMLDivElement | null>(null);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [showFilterSection, setShowFilterSection] = useState<boolean>(false);
  const [foodTags, setFoodTags] = useState<MenuTagValue | string>();
  const [selectedFoodReplace, setSelectedFoodReplace] = useState<Food | undefined>(undefined);

  const handleDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }

    setSelectedDate(date);

    if (onDateSelected) {
      onDateSelected(date);
    }
  };

  useEffect(() => {
    if (selectedWeek) {
      setSelectedDate(findStartAndEndDateForWeek(selectedWeek, selectedYear).startOfWeekDate);
    }
  }, [selectedWeek]);

  const allFoodList = useMemo(() => {
    const validSizes = ['XS', 'S', 'M', 'L'];
    const unifiedFoodList = unionBy(foodListFilters, 'name.en');
    return unifiedFoodList.filter((food) => validSizes.includes(food.size) && food.name.en.includes(searchTerm));
  }, [foodListFilters, searchTerm]);

  const replaceFoodList =
    replaceFood.open && replaceFood.name.length > 0
      ? allFoodList.filter((food) => food.name.en !== replaceFood.name)
      : allFoodList;

  const handleAddingMeal = (food: Food) => {
    const allFood = foodListFilters.filter(
      (allFood) => food.name.en === allFood.name.en && ['XS', 'S', 'M', 'L'].includes(allFood.size)
    );
    const tags = allFood.map((f) => {
      const isPreBuiltFood = f.tags.includes(FoodDietType.preBuiltCustom);
      const value: string[] = [];
      if (isPreBuiltFood) {
        value.push(MenuTagValue.CUSTOMIZABLE);
      }
      if (foodTags) {
        value.push(foodTags);
      }
      return [{ foodId: f.id, value }];
    });
    handleFoodChanges(allFood as any, flatten(tags), selectedDate);
    setFoodTags('');
  };

  const handleCalculateMealCost = (meal: any) => handleMealCost(meal.name.en, foodListFilters, values.kitchen || Kitchen.BH1);

  const handleObserver = (entities: any) => {
    const target = entities[0];
    if (target?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(handleObserver, {
      threshold: 1.0
    });
    if (lastFoodElementRef.current) {
      observer.current.observe(lastFoodElementRef.current);
    }
    return () => {
      if (lastFoodElementRef.current) {
        observer.current?.unobserve(lastFoodElementRef.current);
      }
    };
  }, [replaceFoodList.length]);

  const handleAction = (food: Food) => {
    if (replaceFood.open) {
      if (confirmation) {
        setSelectedFoodReplace(food);
        confirmationRef.current?.open();
      } else {
        handleReplaceFood(
          foodListFilters.filter((allFood) => food.name.en === allFood.name.en && ['XS', 'S', 'M', 'L'].includes(allFood.size))
        );
      }
    } else {
      handleAddingMeal(food);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedMenuFilters({ ...selectedMenuFilters, mealName: value });
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      setSelectedMenuFilters({ ...selectedMenuFilters, mealName: value });
    }, 500);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" p={2} borderBottom="1px solid #e0e0e0">
        {menuPresentation === MenuPresentationType.weekly && (
          <Box display="flex" alignItems="center" gap={2} style={{ width: '100%' }}>
            <Stack style={{ width: '80%' }}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  value={selectedMenuFilters.mealName}
                  sx={{ border: 1, borderColor: caloTheme.palette.grey[700], borderRadius: '8px', width: '100%', height: '44px' }}
                  placeholder="Search meals"
                  onChange={handleSearchChange}
                  inputProps={{ 'aria-label': 'search', style: { width: '100%' } }}
                />
              </Search>
            </Stack>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              {!replaceFood.data && (
                <LocalizationProvider dateAdapter={DateFnsAdapter}>
                  <DesktopDatePicker
                    label="Day"
                    inputFormat="dd/MM/yyyy"
                    value={selectedDate}
                    shouldDisableDate={(date) => !isDateInSelectedWeek(date as Date, selectedWeek)}
                    InputProps={{ style: { maxHeight: '44px', borderRadius: '8px', alignContent: 'center', marginRight: 4 } }}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} sx={{ marginTop: '-2px' }} />}
                  />
                </LocalizationProvider>
              )}

              <IconButton onClick={() => setShowFilterSection(!showFilterSection)} sx={{ marginLeft: '8px' }}>
                <FilterListIcon />
              </IconButton>
            </Stack>
          </Box>
        )}
      </Box>

      {((menuPresentation === MenuPresentationType.weekly && showFilterSection) ||
        menuPresentation === MenuPresentationType.daily) && (
        <FilterSection
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedMenuFilters={selectedMenuFilters}
          setSelectedMenuFilters={setSelectedMenuFilters}
        />
      )}
      <InfiniteScroll
        next={fetchNextPage}
        hasMore={hasNextPage}
        scrollableTarget="scrollable"
        loader={isFetchingNextPage || isLoading}
        dataLength={replaceFoodList.filter((food) => !foodIds?.includes(food.id)).length}
      >
        {isLoading ? (
          <Box display="flex" flexDirection="row" my={3} justifyContent="center">
            <Stack>
              <Typography display="flex" flexDirection="column" sx={{ width: '120px', height: '48px' }}>
                <Icon name="calo" size={12} className="w-64 -ml-24 " />
              </Typography>
              <LinearProgress
                color="inherit"
                value={80}
                sx={{ mt: 2 }}
                style={{ color: caloTheme.palette.primary500, backgroundColor: caloTheme.palette.primary50 }}
              />
            </Stack>
          </Box>
        ) : replaceFoodList && replaceFoodList.length > 0 ? (
          <Box display="flex" flexDirection="column" sx={styles.replaceContainer}>
            {replaceFoodList
              .filter((food) => !foodIds?.includes(food.id))
              .map((food, index) => (
                <FoodItemCard
                  food={food}
                  key={food.id}
                  isLoading={isLoading}
                  replaceFood={replaceFood}
                  handleAction={handleAction}
                  lastFoodElementRef={lastFoodElementRef}
                  handleCalculateMealCost={handleCalculateMealCost}
                  isLast={index === replaceFoodList.filter((food) => !foodIds?.includes(food.id)).length - 1}
                />
              ))}
          </Box>
        ) : replaceFoodList.length === 0 && filtersOn ? (
          <Box display="flex" flexDirection="row" my={3} justifyContent="center">
            <Typography variant="h5" sx={styles.noMealResultStyle}>
              No Meals found for the selected filters
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </InfiniteScroll>

      <Box display="flex" flexDirection="row" m={2} justifyContent="center">
        <MUIButton
          variant="contained"
          data-test="menu-new-modal-confirm-button"
          sx={styles.filterButtonStyle}
          disabled={
            isFetchingNextPage ||
            (!values.brand && !values.country && !values.day && !values.kitchen && selectedMenuFilters.type.length === 0)
          }
          onClick={() => (filtersOn ? handleClosePopup() : setFiltersOn(true))}
        >
          {filtersOn ? (isFetchingNextPage ? 'Loading...' : 'Done') : 'Filter Meals'}
        </MUIButton>
      </Box>

      <Popup maxWidth="sm" title="Confirm Changes" ref={confirmationRef} onClose={() => handleClosePopup()} fullWidth>
        <ConfirmationPopup
          replaceFood={replaceFood}
          confirmationRef={confirmationRef}
          foodListFilters={foodListFilters}
          handleReplaceFood={handleReplaceFood}
          selectedFoodReplace={selectedFoodReplace}
        />
      </Popup>
    </Box>
  );
};
export default AddMealsByFilters;
