import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  portionSizeTypography: {
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600
  },
  reviewTypography: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    color: caloTheme.palette.neutral900
  },
  infoStack: {
    flexDirection: 'column',
    marginLeft: '12px',
    alignSelf: 'center'
  },
  foodImage: {
    objectFit: 'cover',
    height: '140px',
    borderRadius: '8px'
  },
  rowStack: {
    flexDirection: 'row'
  },
  foodName: {
    alignSelf: 'stretch',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px'
  }
};

export default styles;
