import { SupplyChainUserCreateReq } from '@calo/dashboard-types';
import { Country } from '@calo/types';
import { useFormik } from 'formik';
import { generatePassword } from 'lib/helpers';
import parsePhoneNumberFromString from 'libphonenumber-js';

export default (onSubmit: (values: Omit<SupplyChainUserCreateReq, 'id'>) => any) =>
  useFormik<Omit<SupplyChainUserCreateReq, 'id'>>({
    initialValues: {
      name: '',
      phoneNumber: '',
      email: '',
      country: Country.BH,
      kitchen: [],
      password: generatePassword()
    },
    validate: (values: Omit<SupplyChainUserCreateReq, 'id'>) => {
      const errors: any = {};

      if (!values.name) {
        errors.name = true;
      }
      if (!values.country) {
        errors.country = true;
      }
      if (!values.kitchen || values.kitchen.length === 0) {
        errors.kitchen = true;
      }
      if (!values.email) {
        errors.email = true;
      }

      const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);
      if (!phoneNumber || (phoneNumber && !phoneNumber.isValid())) {
        errors.phoneNumber = true;
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
