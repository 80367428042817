import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { RouteComponentProps, useLocation } from 'react-router';

import { IngredientsFilters, Permission } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';

import { getListWithParams } from 'actions';
import { CaloLoader, Pagination } from 'components';
import SharedListHeaderCard from 'components/SharedListHeaderCard';
import { resolveCountryFromKitchen } from 'lib/helpers';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { Ingredient, IngredientsQuery } from 'lib/interfaces';
import IngredientListCard from './IngredientListCard';
import Settings from './Settings';

type IngredientsProps = RouteComponentProps;

const Ingredients = ({ history }: IngredientsProps) => {
  const location = useLocation();
  const userRoles = useUserRoles();
  const userKitchen = useUserKitchens();
  const searchParams = new URLSearchParams(location.search);
  const [page, setPage] = useState<number>(0);
  const [filters, setFilters] = useState<IngredientsFilters>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data, isLoading } = useQuery<any, Error, IngredientsQuery>(
    ['ingredients', { page, filters, limit: 50 }],
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );
  const isDisabled = !userRoles.includes(Permission.CREATE_INGREDIENT);

  const ingredients = data?.data as Ingredient[] | undefined;

  useEffect(() => {
    setPage(0);
  }, [filters]);

  return (
    <>
      <SharedListHeaderCard
        filters={filters}
        history={history}
        listType="Ingredients"
        list={ingredients ?? []}
        isDisabled={isDisabled}
      />
      {isLoading ? <CaloLoader /> : <IngredientListCard ingredients={ingredients} isLoading={isLoading} />}
      <Settings onFilter={setFilters} filters={filters} />
      {data && (
        <Pagination
          isHidden={data.data.length === 0 || data.meta.total < 50}
          limit={50}
          total={data.meta.total}
          page={page}
          onChange={setPage}
        />
      )}
    </>
  );
};

export default Ingredients;
