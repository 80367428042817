import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { caloTheme } from 'assets/images/theme/calo';

import { Icon as Iconify } from '@iconify/react';
import { Box, CardMedia, IconButton } from '@mui/material';

interface ImagesCardPreviewProps {
  imageURLs: string[];
  handleRemoveFile: (file: string) => void;
  setDisplayImage: (url: string) => void;
  viewImageRef: React.RefObject<any>;
}

const ImagesCardPreview = ({ imageURLs, handleRemoveFile, setDisplayImage, viewImageRef }: ImagesCardPreviewProps) => (
  <>
    {imageURLs.map((imageURL, index) => (
      <Box
        key={index}
        sx={{
          position: 'relative',
          height: 160,
          width: 160,
          minWidth: 160,
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CardMedia
          component="img"
          image={imageURL.replace('/original', '/square@1x.jpg')}
          alt="placeholder"
          sx={{ width: 160, height: 160, objectFit: 'cover', borderRadius: '8px' }}
          onError={(e: any) => {
            e.target.src = e.target.src.includes('/square@1x.jpg') ? imageURL : noImagePlaceholder;
          }}
          onClick={() => {
            setDisplayImage(imageURL.replace('/original', '/square@3x.jpg'));
            viewImageRef.current?.open();
          }}
        />
        <IconButton onClick={() => handleRemoveFile(imageURL)} sx={{ position: 'absolute', top: 0, right: 0 }}>
          <Iconify
            icon="flowbite:x-circle-outline"
            width={32}
            height={32}
            style={{
              background: 'radial-gradient(circle, white 50%, transparent 65%)',
              color: caloTheme.palette.neutral900
            }}
          />
        </IconButton>
      </Box>
    ))}
  </>
);

export default ImagesCardPreview;
