import { Permission } from '@calo/dashboard-types';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { getAiPrompts, PromptData } from 'actions/aiDashboard';
import CaloLoader from 'components/CaloLoader';
import { useUserRoles } from 'lib/hooks';
import { useEffect, useState } from 'react';
import EditDialogBox from './components/EditDialogBox';

const capitalizeFirstWord = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).replace(/_/g, ' ');
};

const PromptTuning = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [promptData, setPromptData] = useState<PromptData>();
  const [currentKey, setCurrentKey] = useState('');
  const [currentValue, setCurrentValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const roles = useUserRoles();
  const isUserAllowedEdit = roles.includes(Permission.UPDATE_AI_LOGISTICS);

  const getPrompts = async () => {
    setIsLoading(true);
    const data = await getAiPrompts();
    setPromptData(data);
    setIsLoading(false);
  };
  const updatePrompts = async () => {
    const data = await getAiPrompts();
    setPromptData(data);
  };
  useEffect(() => {
    getPrompts();
  }, []);

  const handleEdit = (key, value) => {
    setCurrentKey(key);
    setCurrentValue(value);
    setIsOpen(true);
  };

  if (isLoading || !promptData) {
    return <CaloLoader />;
  }
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography sx={{ fontSize: '32px', fontWeight: 600, marginBottom: '16px' }}>Prompts Dashboard</Typography>
      <Grid container spacing={3}>
        {Object.entries(promptData).map(([key, value]) => (
          <Grid item xs={12} key={key}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                {capitalizeFirstWord(key)}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2, whiteSpace: 'pre-wrap' }}>
                {value.length > 100 ? `${value.slice(0, 100)}...` : value}
              </Typography>
              <Button disabled={!isUserAllowedEdit} variant="outlined" onClick={() => handleEdit(key, value)}>
                Edit
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <EditDialogBox {...{ isOpen, setIsOpen, currentKey: currentKey, currentValue, setCurrentValue, updatePrompts }} />
    </Box>
  );
};

export default PromptTuning;
