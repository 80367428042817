import { useState } from 'react';

import { caloTheme } from 'assets/images/theme/calo';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { FormikErrors, FormikTouched } from 'formik';
import { getAccessibleCountries, getKitchenOptions, hasValidCharactersForLanguage } from 'lib/helpers';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { CreateFoodPackageElementReq } from 'lib/interfaces';

import { Permission } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import { Card, FormHelperText, MenuItem, Stack, TextField, Typography } from '@mui/material';

interface PackageElementInformationProps {
  values: CreateFoodPackageElementReq;
  errors: FormikErrors<CreateFoodPackageElementReq>;
  touched: FormikTouched<CreateFoodPackageElementReq>;
  handleChange: { (e: React.ChangeEvent<any>): void };
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<CreateFoodPackageElementReq>>;
}

const PackageElementInformation = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue
}: PackageElementInformationProps) => {
  const [langAlert, setLangAlert] = useState({ AR: false, EN: false, value: '' });

  const userRoles = useUserRoles();
  const userKitchens: Kitchen[] = useUserKitchens();

  const handleNameFieldChange = (field: string, data: string, lang: 'AR' | 'EN') => {
    setFieldValue(field, data);
    const hasValidCharacters = hasValidCharactersForLanguage(data, lang, false) || data === '';
    setLangAlert({ ...langAlert, [`${lang}`]: !hasValidCharacters, value: field });
  };

  return (
    <InputThemeProvider>
      <Card
        variant="outlined"
        sx={{
          marginTop: '16px',
          border: 'none',
          borderRadius: '16px',
          py: '20px',
          px: '12px',
          zIndex: 100,
          display: 'flex',
          flexDirection: 'column',
          gap: '20px'
        }}
      >
        <Typography variant="h5" sx={{ fontSize: '19px', fontWeight: 600 }}>
          Information
        </Typography>
        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '13px', width: '100%' }}>
          <Stack sx={{ width: '100%' }}>
            <TextField
              label="Name"
              name="name"
              id="name"
              placeholder="Enter Name"
              value={values.name}
              sx={{ width: '100%' }}
              disabled={!userRoles.includes(Permission.CREATE_FOOD_PACKAGE_ELEMENT)}
              onChange={(data) => handleNameFieldChange('name', data.target.value, 'EN')}
              onBlur={handleBlur}
              error={touched.name && !!errors.name}
            />
            <FormHelperText id="name.en-error" sx={{ color: caloTheme.palette.red }}>
              {langAlert.EN && langAlert.value === 'name' && 'letters should be in English only'}
            </FormHelperText>
          </Stack>
        </Stack>
        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '13px', width: '100%' }}>
          <TextField
            select
            label="Country*"
            value={values.country}
            name="country"
            placeholder="Select Country"
            sx={{ width: '50%' }}
            disabled={!userRoles.includes(Permission.CREATE_FOOD_PACKAGE_ELEMENT)}
            onChange={(data) => {
              setFieldValue('country', data.target.value);
              setFieldValue('kitchen', '');
            }}
            error={!!errors.country}
          >
            {getAccessibleCountries(userKitchens).map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Kitchen*"
            name="kitchen"
            value={values.kitchen}
            placeholder="Select Kitchen"
            sx={{ width: '50%' }}
            disabled={!userRoles.includes(Permission.CREATE_FOOD_PACKAGE_ELEMENT)}
            onChange={handleChange}
            error={!!errors.kitchen}
          >
            {getKitchenOptions(userKitchens, values.country)
              .filter((option) => option.label !== 'Any')
              .map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        </Stack>
        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '13px', width: '100%' }}>
          <TextField
            label="Size*"
            value={values.size}
            name="size"
            placeholder="Enter Size"
            sx={{ width: '50%' }}
            disabled={!userRoles.includes(Permission.CREATE_FOOD_PACKAGE_ELEMENT)}
            onChange={(data) => setFieldValue('size', data.target.value)}
            onBlur={handleBlur}
            error={touched.size && !!errors.size}
          />
          <TextField
            label="Cost per Unit"
            name="cost"
            value={values.cost}
            placeholder="Enter Cost"
            sx={{ width: '50%' }}
            disabled={true}
            onChange={(data) => setFieldValue('cost', data.target.value)}
            onBlur={handleBlur}
            error={touched.cost && !!errors.cost}
          />
        </Stack>
      </Card>
    </InputThemeProvider>
  );
};

export default PackageElementInformation;
