import { useState } from 'react';

import { caloTheme } from 'assets/images/theme/calo';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { FormikErrors, FormikTouched } from 'formik';
import { hasValidCharactersForLanguage } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { UpdateFoodPackageElementReq } from 'lib/interfaces';

import { Permission } from '@calo/dashboard-types';
import { Card, FormHelperText, Stack, TextField, Typography } from '@mui/material';

interface PackageElementInformationProps {
  values: UpdateFoodPackageElementReq;
  errors: FormikErrors<UpdateFoodPackageElementReq>;
  touched: FormikTouched<UpdateFoodPackageElementReq>;
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<UpdateFoodPackageElementReq>>;
}

const PackageElementInformation = ({ values, errors, touched, handleBlur, setFieldValue }: PackageElementInformationProps) => {
  const [langAlert, setLangAlert] = useState({ AR: false, EN: false, value: '' });

  const userRoles = useUserRoles();

  const handleNameFieldChange = (field: string, data: string, lang: 'AR' | 'EN') => {
    setFieldValue(field, data);
    const hasValidCharacters = hasValidCharactersForLanguage(data, lang, false) || data === '';
    setLangAlert({ ...langAlert, [`${lang}`]: !hasValidCharacters, value: field });
  };

  return (
    <InputThemeProvider>
      <Card
        variant="outlined"
        sx={{
          marginTop: '16px',
          border: 'none',
          borderRadius: '16px',
          py: '20px',
          px: '12px',
          zIndex: 100,
          display: 'flex',
          flexDirection: 'column',
          gap: '20px'
        }}
      >
        <Typography variant="h5" sx={{ fontSize: '19px', fontWeight: 600 }}>
          Information
        </Typography>
        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '13px', width: '100%' }}>
          <Stack sx={{ width: '100%' }}>
            <TextField
              label="Name"
              name="name"
              id="name"
              placeholder="Enter Name"
              value={values.name}
              sx={{ width: '100%' }}
              disabled={!userRoles.includes(Permission.UPDATE_FOOD_PACKAGE_ELEMENT)}
              onChange={(data) => handleNameFieldChange('name', data.target.value, 'EN')}
              onBlur={handleBlur}
              error={touched.name && !!errors.name}
            />
            <FormHelperText id="name.en-error" sx={{ color: caloTheme.palette.red }}>
              {langAlert.EN && langAlert.value === 'name' && 'letters should be in English only'}
            </FormHelperText>
          </Stack>
        </Stack>
        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '13px', width: '100%' }}>
          <TextField
            label="Size*"
            value={values.size}
            name="size"
            placeholder="Enter Size"
            sx={{ width: '50%' }}
            disabled={!userRoles.includes(Permission.UPDATE_FOOD_PACKAGE_ELEMENT)}
            onChange={(data) => setFieldValue('size', data.target.value)}
            onBlur={handleBlur}
            error={touched.size && !!errors.size}
          />
          <TextField
            label="Cost per Unit"
            name="cost"
            value={values.cost}
            placeholder="Enter Cost"
            sx={{ width: '50%' }}
            disabled={true}
            onChange={(data) => setFieldValue('cost', data.target.value)}
            onBlur={handleBlur}
            error={touched.cost && !!errors.cost}
          />
        </Stack>
      </Card>
    </InputThemeProvider>
  );
};

export default PackageElementInformation;
