import { useCallback, useEffect, useRef, useState } from 'react';

import { useFormik } from 'formik';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { debounce } from 'lodash-es';

import { Permission } from '@calo/dashboard-types';
import { styled, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow, TextField } from '@mui/material';

import { getSubscriptionCognitoUser, updateSubscriptionCognitoUser } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Button, Input, Modal, ModalRef } from 'components';
import { useUserRoles } from 'lib/hooks';

interface CognitoUser {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
}

const SubscriptionUsersList = () => {
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [user, setUser] = useState<CognitoUser | undefined>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const modalRef = useRef<ModalRef>();

  const roles = useUserRoles();

  const handleSearch = (searchValue: string) => {
    if (!searchValue || searchValue.length === 0) {
      setSearchValue('');
    } else {
      setSearchValue(searchValue);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      email: '',
      phoneNumber: ''
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSubmit: (_) => {},
    validate: (values) => {
      const errors: { name?: string; email?: string; phoneNumber?: string } = {};
      if (!values.name) {
        errors.name = 'Name is required';
      }
      if (!values.email) {
        errors.email = 'Email is required';
      }
      if (!values.phoneNumber || !values.phoneNumber.includes('+') || isValidPhoneNumber(values.phoneNumber)) {
        errors.phoneNumber = 'Phone number must not be empty & includes +';
      }
      return errors;
    }
  });

  const getUserDetails = useCallback(
    debounce(async (value: string) => {
      setLoading(true);
      const data = await getSubscriptionCognitoUser(value!).catch(() => setUser(undefined));
      setUser(data);
      setLoading(false);
    }, 1200),
    []
  );

  const handleOnSave = async () => {
    await updateSubscriptionCognitoUser(formik.values.id, {
      name: formik.values.name,
      email: formik.values.email,
      phoneNumber: formik.values.phoneNumber
    });
    getUserDetails(searchValue!);
  };

  useEffect(() => {
    if (searchValue && searchValue.length > 0) {
      getUserDetails(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (user) {
      formik.setValues({
        id: user.id || '',
        name: user.name || '',
        email: user.email || '',
        phoneNumber: user.phoneNumber || ''
      });
    }
  }, [user]);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="uppercase" style={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '33px' }}>
                Users
              </p>
            </div>
          </div>
        </div>
      </section>

      <TextField
        id="search-users"
        label="Search..."
        placeholder="Search by email or phone number (+XXXXXXXX)"
        variant="outlined"
        onChange={(event) => handleSearch(event.target.value)}
      />

      <Table
        sx={{
          marginY: '4px',
          minHeight: '120px',
          overflow: 'auto',
          width: '100%',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Phone Number</StyledTableCell>
            <StyledTableCell>Edit</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {user && !isLoading ? (
            <tr>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.phoneNumber}</td>
              <td>
                {roles.includes(Permission.UPDATE_APP_COGNITO_USERS) && (
                  <Button primary outlined content="Edit" onClick={() => modalRef?.current?.open()} />
                )}
              </td>
            </tr>
          ) : isLoading ? (
            'Loading...'
          ) : (
            ''
          )}
        </TableBody>
      </Table>

      <Modal isNarrow ref={modalRef}>
        <div className="flex flex-col">
          <div className="section">
            <div className="field">
              <div className="control">
                <Input disabled label="ID" name="id" value={formik.values.id} onChange={formik.handleChange} />
                <Input label="Name" name="name" value={formik.values.name} onChange={formik.handleChange} />
                <Input label="Email" name="email" value={formik.values.email} onChange={formik.handleChange} />
                <Input label="Phone Number" name="phoneNumber" value={formik.values.phoneNumber} onChange={formik.handleChange} />
                <Button fluid primary content="Save" onClick={() => handleOnSave()} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SubscriptionUsersList;
