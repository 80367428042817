import { Button, CircularProgress, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Modal, ModalRef } from 'components';
import { useEffect, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

export const AddonSubscriptionModal = ({
  packages,
  isOpen,
  selectedAddonSubscription,
  isLoading,
  handleSave,
  setSelectedAddonSubscription,
  onClose
}: {
  packages: Array<Record<string, any>>;
  isOpen: boolean;
  selectedAddonSubscription: string;
  isLoading: boolean;
  handleSave: () => void;
  onClose: () => void;
  setSelectedAddonSubscription: (value: string) => void;
}) => {
  const modalRef = useRef<ModalRef>(null);

  useEffect(() => {
    if (isOpen) {
      modalRef.current?.open();
    } else {
      modalRef.current?.close();
    }
  }, [isOpen]);

  return (
    <Modal isNarrow ref={modalRef} onClose={onClose}>
      <Typography
        variant="h6"
        sx={{
          fontSize: '19px',
          lineHeight: '23px',
          fontFamily: caloTheme.typography.fontFamily,
          fontWeight: 600
        }}
      >
        Add Add-on Subscriptions
      </Typography>
      <FormControl sx={{ marginY: '15px', width: '100%' }}>
        <Typography>Subscription name</Typography>
        <Select
          labelId="add-on-subscription-label-select"
          id="add-on-subscription-selector"
          value={selectedAddonSubscription}
          onChange={(e) => setSelectedAddonSubscription(e.target.value)}
        >
          {packages &&
            packages?.length > 0 &&
            packages?.map((data, index) => (
              <MenuItem key={`${data?.id}-${index}`} value={data?.id}>
                {data?.id}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {isLoading ? (
        <Stack flexDirection="row" justifyContent="center">
          <CircularProgress color="success" />
        </Stack>
      ) : (
        <Button
          onClick={handleSave}
          variant="outlined"
          aria-label="add-address-button"
          sx={{
            float: 'right',
            margin: '4px',
            lineHeight: '17px',
            fontWeight: 600,
            fontSize: '14px',
            borderRadius: '8px',
            padding: '12px 20px 12px 20px',
            backgroundColor: caloTheme.palette.primary500,
            borderColor: caloTheme.palette.primary500,
            color: caloTheme.palette.white,
            '&:hover': {
              borderColor: caloTheme.palette.primary600,
              color: caloTheme.palette.primary500
            }
          }}
        >
          Save
        </Button>
      )}
    </Modal>
  );
};
