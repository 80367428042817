import { useState } from 'react';

import { DeliveryPlanFilters, Permission } from '@calo/dashboard-types';
import { DriverMetrics } from '@calo/driver-types';
import { DeliveryTime, Dictionary, Kitchen } from '@calo/types';
import { Button as ButtonMUI, Stack, Tooltip } from '@mui/material';

import { exportDrivers, getListWithParams, updateAllDriverMetricReq, updateDriverMetricReq } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Button, CaloLoader } from 'components';
import { parseISO } from 'date-fns';
import { differenceInDays } from 'date-fns/esm';
import { format, subDays } from 'date-fns/fp';
import { resolveCountryFromKitchen } from 'lib/helpers';
import history from 'lib/history';
import { useCurrentUser, useUserKitchens, useUserRoles } from 'lib/hooks';
import { Driver } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import { selectUserEmail } from 'lib/selectors';
import { flatten, groupBy, sortBy } from 'lodash-es';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import DriverMetricRow from '../DriversMetricRow';
import Settings from '../Settings';
import { getShiftDurationMillisec } from '../utils';

const DriversMetricList = () => {
  const location = useLocation();
  const user = useCurrentUser();
  const searchParams = new URLSearchParams(location.search);
  const { mutateAsync: updateMutation } = useMutation(updateDriverMetricReq);
  const { mutateAsync: updateAllShiftMutation } = useMutation(updateAllDriverMetricReq);
  const userKitchen = useUserKitchens();

  const roles = useUserRoles();
  const [filters, setFilters] = useState<DeliveryPlanFilters>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    time: DeliveryTime.morning,
    driverId: 'ANY',
    day: {
      lte: format('yyyy-MM-dd')(Date.now()),
      gte: format('yyyy-MM-dd')(subDays(1)(Date.now()))
    },
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });
  const [drivers, setDrivers] = useState<Driver[]>([]);

  useQuery(['/map/drivers', { country: filters.country }], getListWithParams, {
    retry: false,
    onError: console.error,
    onSuccess(data: any) {
      if (data && data.data) {
        setDrivers(data.data);
      }
    }
  });

  const [driverMetrics, setDriverMetrics] = useState<Dictionary<DriverMetrics[]> | undefined>();
  const { isLoading } = useQuery<any, Error, { data: DriverMetrics[] }>(
    [
      `${filters.country}/route-plans`,
      {
        day: filters.day,
        time: filters.time,
        country: filters.country,
        kitchen: filters.kitchen,
        driverId: filters.driverId
      }
    ],
    getListWithParams,
    {
      onSuccess: (data) => {
        if (data.data) {
          const groupedData = groupBy(sortBy(data.data, 'day'), 'day');
          setDriverMetrics(groupedData);
          for (const day of Object.keys(groupedData)) {
            queryClient.setQueryData(['delivery-metrics', day], day);
          }
        }
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  const handleStartShift = async (row: DriverMetrics) => {
    await updateMutation(
      {
        id: row.id,
        canStartShift: true
      },
      {
        onSuccess: () => {
          const query = queryClient.getQueryData([
            `${filters.country}/route-plans`,
            {
              day: filters.day,
              time: filters.time,
              country: filters.country,
              kitchen: filters.kitchen,
              driverId: filters.driverId
            }
          ]) as any;
          const index = query?.data.findIndex((i: any) => i.id === row.id);
          if (index >= 0) {
            const req = { ...query.data[index], canStartShift: true };
            query?.data?.splice(index, 1, req);
            const groupedData = groupBy(sortBy(query.data, 'day'), 'day');
            setDriverMetrics(groupedData);
            for (const day of Object.keys(groupedData)) {
              queryClient.setQueryData(['delivery-metrics', day], day);
            }
          }
        }
      }
    );
  };

  const handleStartAllShift = async () => {
    if (driverMetrics) {
      const driverIds = Object.entries(driverMetrics).map(([_, value]) =>
        value.filter((driverMet) => !driverMet.canStartShift).map((ddid) => ddid.id)
      );
      await updateAllShiftMutation(flatten(driverIds), {
        onSuccess: (data) => {
          const query = queryClient.getQueryData([
            `${filters.country}/route-plans`,
            {
              day: filters.day,
              time: filters.time,
              country: filters.country,
              kitchen: filters.kitchen,
              driverId: filters.driverId
            }
          ]) as any;

          for (let i = 0; i < data.length; i++) {
            const req = { ...query.data[i], canStartShift: true };
            query?.data?.splice(i, 1, req);
          }
          const groupedData = groupBy(sortBy(query.data, 'day'), 'day');
          setDriverMetrics(groupedData);
          for (const day of Object.keys(groupedData)) {
            queryClient.setQueryData(['delivery-metrics', day], day);
          }
        }
      });
    }
  };

  const getTotalDeliveries = () => {
    let totalDeliveries = 0;
    for (const d of Object.values(driverMetrics || [])) {
      for (const shift of d) {
        totalDeliveries += shift.deliveredDeliveries || 0;
      }
    }
    return totalDeliveries;
  };

  const getTotalAverageDeliveryTime = () => {
    let count = 0;
    let totalms = 0;
    for (const d of Object.values(driverMetrics || [])) {
      for (const shift of d) {
        const millisec = getShiftDurationMillisec(shift);
        if (millisec && shift.deliveredDeliveries) {
          count++;
          totalms += millisec / shift.deliveredDeliveries;
        }
      }
    }
    const avg = totalms / count;
    return Math.floor(avg / 100 / 60) / 10;
  };

  const isAllowAllDisabled = driverMetrics
    ? flatten(Object.values(driverMetrics).map((value) => value.filter((driverMet) => !driverMet.canStartShift).map((d) => d.id)))
        .length === 0
    : false;

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className=" w-full flex-row justify-between w-full items-center">
              <ul>
                <li>Drivers Metrics</li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <span className="mr-4">
              <Button onClick={() => exportDrivers(filters, selectUserEmail(user))} icon="fas fa-file-export" />
            </span>
          </div>
        </div>
      </section>
      <div className="p-5">
        <div className="flex-row mb-7 ">
          <p className="w-1/3 text-xl font-medium">
            From {new Date(Date.parse(filters.day.gte)).toLocaleString().split(',')[0]} to{' '}
            {new Date(Date.parse(filters.day.lte)).toLocaleString().split(',')[0]}
          </p>
          <p className="w-1/3 text-xl font-medium">
            Shift hours: {filters.time === DeliveryTime.morning ? '07:00am ' : '05:00pm '}
            {filters.time === DeliveryTime.morning ? '11:00am ' : '09:00pm '}
          </p>
        </div>
        <div className="flex-row">
          <div className="w-60 h-32 text-white p-5 rounded shadow relative mr-16" style={{ backgroundColor: '#28B17B' }}>
            <p className="text-2xl mb-5">Avg Delivery Time</p>
            <p className="text-3xl">{getTotalAverageDeliveryTime() || '--'} min</p>
            <i className="far fa-clock absolute -bottom-4 -right-4 fa-5x " style={{ color: '#303030' }}></i>
          </div>
          <div className="w-60 h-32 text-white p-5 rounded shadow relative" style={{ backgroundColor: '#28B17B' }}>
            <p className="text-2xl mb-5">Total delivered </p>
            <p className="text-3xl">{getTotalDeliveries()}</p>
            <i className="fas fa-truck absolute -bottom-4 -right-6 fa-5x" style={{ color: '#303030' }}></i>
          </div>
        </div>
        {roles.includes(Permission.BATCH_ALLOW_DRIVERS_TO_START_SHIFT) && (
          <div className="flex-row justify-end">
            <>
              <Tooltip
                className=" justify-end"
                disableHoverListener={false}
                title={
                  isAllowAllDisabled
                    ? 'All Drivers allowed'
                    : differenceInDays(parseISO(filters.day.lte), parseISO(filters.day.gte)) === 0
                      ? ''
                      : 'Can not allow for more than one day'
                }
                placement="top"
                arrow
              >
                <Stack>
                  <ButtonMUI
                    fullWidth
                    variant="contained"
                    sx={{
                      width: 'auto',
                      height: '36px',
                      lineHeight: '17px',
                      fontWeight: 600,
                      fontSize: '14px',
                      borderRadius: '8px',
                      boxShadow: 'none',
                      borderColor: caloTheme.palette.primary500,
                      backgroundColor: caloTheme.palette.primary500,
                      color: 'White',
                      '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: caloTheme.palette.primary600,
                        borderColor: caloTheme.palette.primary600
                      }
                    }}
                    onClick={() => handleStartAllShift()}
                    disabled={differenceInDays(parseISO(filters.day.lte), parseISO(filters.day.gte)) !== 0 || isAllowAllDisabled}
                  >
                    Allow All
                  </ButtonMUI>
                </Stack>
              </Tooltip>
            </>
          </div>
        )}

        <div>
          {isLoading ? (
            <CaloLoader />
          ) : (
            <table className="w-full h-full mt-7 border-separate">
              <thead className="">
                <tr className="sticky top-0 z-10" style={{ backgroundColor: '#303030' }}>
                  <th className="text-white rounded-l py-4 pl-5 ">Name</th>
                  <th className="text-white py-4">Deliveries</th>
                  <th className="text-white py-4">Start time</th>
                  <th className="text-white py-4">Departure</th>
                  <th className="text-white py-4">End time</th>
                  <th className="text-white py-4">Duration</th>
                  <th className="text-white py-4">Delivered</th>
                  <th className="text-white py-4">Not delivered</th>
                  <th className="text-white py-4">Avg delivery</th>
                  <th className="text-white py-4">Allow Start Shift</th>
                </tr>
              </thead>
              {driverMetrics && Object.entries(driverMetrics).reverse().length === 0 && !isLoading ? (
                <span className="absolute w-full text-4xl mt-4 text-center font-bold text-gray-400">NO DRIVERS METRICS</span>
              ) : (
                <tbody>
                  {driverMetrics &&
                    Object.entries(driverMetrics)
                      .reverse()
                      .map(([key, value]) => (
                        <DriverMetricRow
                          key={key}
                          day={key}
                          rows={value}
                          handleStartShift={handleStartShift}
                          getShiftDurationMillisec={getShiftDurationMillisec}
                          totalAvgDeliveryTime={getTotalAverageDeliveryTime()}
                        />
                      ))}
                </tbody>
              )}
            </table>
          )}
        </div>
        <Settings onFilter={setFilters} filters={filters} drivers={drivers} />
      </div>
    </>
  );
};

export default DriversMetricList;
