import { useHistory, useParams } from 'react-router-dom';

import { CreateFoodReq, MenuFoodComponent } from '@calo/dashboard-types';

import { createFood } from 'actions/food';
import { Routes } from 'lib/enums';
import FoodForm from './FoodForm';

const formatComponents = (components?: MenuFoodComponent[]) => {
  return components?.map((comp) => ({
    id: comp.id,
    quantity: comp.quantity
  }));
};

const NewFood = () => {
  const history = useHistory();
  const { type } = useParams<{ type: string }>();

  const handleNewFood = async (values: Omit<CreateFoodReq, 'id'>) => {
    const { ingredients: _, ...rest } = values;
    const formattedFood = {
      ...rest,
      components: formatComponents(rest.components)
    };
    const food = await createFood(formattedFood);
    history.replace(Routes.foodSlug.replace(':slug', food.slug));
  };

  return <FoodForm onSubmit={handleNewFood} isPreBuildCustom={type === 'custom'} />;
};

export default NewFood;
