import { Box, Grid, Stack, Typography } from '@mui/material';
import { getImageUploadLink } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { useRef, useState } from 'react';
import { ImagePopup } from '../../views/Complaints/ComplaintsPopUps';
import Icon from '../Icon';
import ImageUploader from '../ImageUploader';
import { ModalRef } from '../Modal';

interface AttachFileProps {
  viewType: string;
  urlFile: string;
  displayImage: string[];
  uuIDCreate: string;
  setDisplayImage: React.Dispatch<React.SetStateAction<string[]>>;
}

const AttachFile = ({ displayImage, setDisplayImage, uuIDCreate, urlFile, viewType }: AttachFileProps) => {
  const viewImageRef = useRef<ModalRef>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageUrlLink, setImageUrlLink] = useState<string | undefined>();

  const handleEnlargeImage = (image: string, imageIndex: number) => {
    setImageUrlLink(image);
    setCurrentIndex(imageIndex);
    viewImageRef.current?.open();
  };

  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ width: '100%' }}>
      <Grid container spacing={5}>
        {displayImage.map((image, index) => (
          <Grid item key={image}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'start'}>
                  <Stack key={image} display={'flex'} flexDirection={'column'} justifyContent={'start'}>
                    <Stack onClick={() => handleEnlargeImage(image, index)}>
                      <ImageUploader
                        key={`${urlFile}/${uuIDCreate}/${image}`}
                        uploadLink={() =>
                          getImageUploadLink(image.includes(urlFile) ? image : `${urlFile}/${uuIDCreate}/${image}`, ' ')
                        }
                        image={
                          image.includes(urlFile)
                            ? `${process.env.REACT_APP_BUCKET_URL}/${image}`
                            : `${process.env.REACT_APP_BUCKET_URL}/${urlFile}/${uuIDCreate}/${image}`
                        }
                        disabled={false}
                        width={120}
                        maxHeight={80}
                      />
                    </Stack>
                    <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ width: '100%' }}>
                      <Typography sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
                        Attachment {index + 1}
                      </Typography>
                    </Stack>
                    <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ width: '100%', mt: 1 }}>
                      <Icon
                        name="trash"
                        size={6}
                        style={{ color: caloTheme.palette.neutral100 }}
                        onClick={() => {
                          setDisplayImage(displayImage.filter((imageId) => imageId !== image));
                        }}
                        className="mx-4 cursor-pointer"
                      />
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <ImagePopup
        viewType={viewType}
        urlFile={urlFile}
        mainRef={viewImageRef}
        imageUrl={imageUrlLink}
        selectedComplaintId={viewType === 'create' ? '' : uuIDCreate}
        displayImage={displayImage}
        currentIndex={currentIndex}
        setImageUrlLink={setImageUrlLink}
        setCurrentIndex={setCurrentIndex}
      />
    </Box>
  );
};
export default AttachFile;
