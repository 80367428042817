import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  trackedCaloriesText: {
    mr: 2,
    fontSize: '13px',
    lineHeight: '23px',
    fontFamily: caloTheme.typography.fontFamily,
    fontWeight: 600
  }
};

export default styles;
