import { format } from 'date-fns/fp';
import { useDispatch, useSelector } from 'react-redux';

import { DietType, MenuTagValue, PortionSize, RatingFactor } from '@calo/types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { RatingFilters } from '@calo/dashboard-types';
import { toggleUISettings } from 'actions';
import { Select, Select2, SideDrawer } from 'components';
import { FoodFeedbackPages } from 'lib/enums';
import { AppState } from 'lib/interfaces';
import { capitalize, startCase } from 'lodash-es';

interface SettingsProps {
  onFilter: (values: RatingFilters) => any;
  filters: RatingFilters;
  page: FoodFeedbackPages;
}

const Settings = ({ filters, onFilter, page }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        {page === FoodFeedbackPages.feedback && (
          <>
            <Select2
              label="Portion"
              value={filters.portionSize}
              onChange={(e) =>
                onFilter({ ...filters, portionSize: e.target.value === 'All' ? (undefined as any) : e.target.value })
              }
              options={[
                {
                  value: 'All',
                  label: 'All'
                },
                ...Object.values(PortionSize).map((c) => ({
                  value: c,
                  label: PortionSize[c]
                }))
              ]}
            />
            <Select2
              label="Rating"
              value={filters.rating}
              onChange={(e) => onFilter({ ...filters, rating: +e.target.value })}
              options={[
                {
                  value: '',
                  label: 'All'
                },
                ...['1', '2', '3', '4', '5'].map((c) => ({
                  value: +c,
                  label: c
                }))
              ]}
            />
            <Select
              label="Menu Tag"
              value={filters.menuTags}
              onChange={(data: any) =>
                onFilter({
                  ...filters,
                  menuTags: (data || []).map((row) => row.value)
                })
              }
              isMulti
              options={Object.values(MenuTagValue).map((tag) => ({
                label: capitalize(startCase(tag)),
                value: tag
              }))}
            />

            <Select
              label="Meal Plan"
              value={filters.foodDietTypes}
              onChange={(data: any) =>
                onFilter({
                  ...filters,
                  foodDietTypes: (data || []).map((row) => row.value)
                })
              }
              isMulti
              options={Object.values(DietType).map((type) => ({
                label: capitalize(startCase(type)),
                value: type
              }))}
            />

            <Select
              label="Feedback"
              value={filters.ratingFactors}
              onChange={(data: any) =>
                onFilter({
                  ...filters,
                  ratingFactors: (data || []).map((row) => row.value)
                })
              }
              isMulti
              options={Object.values(RatingFactor).map((factor) => ({
                label: capitalize(startCase(factor)),
                value: factor
              }))}
            />
          </>
        )}

        <div className="field">
          <label className="label">Date range</label>
          <div className="control is-clearfix">
            <DateRangePicker
              onChange={(dates: any) => {
                onFilter({
                  ...filters,
                  createdAt: dates
                    ? {
                        gte: format('yyyy-MM-dd')(dates[0]),
                        lte: format('yyyy-MM-dd')(dates[1])
                      }
                    : undefined
                });
              }}
              value={[filters!.createdAt?.gte, filters!.createdAt?.lte]}
            />
          </div>
        </div>
      </section>
    </SideDrawer>
  );
};

export default Settings;
