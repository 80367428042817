import { FoodComponentType } from '@calo/types';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { CardMedia, IconButton, Stack, Typography } from '@mui/material';
import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { caloTheme } from 'assets/images/theme/calo';
import { hasAllergen } from 'lib/helpers';
import { FoodComponent } from 'lib/interfaces';

interface ComponentCardProps {
  component: FoodComponent;
  selectedCount: number;
  selectedTag: FoodComponentType;
  maxAllowedCompsForTag: number;
  numOfSelectedComponentsByTag: number;
  setSelectedComponentInfo: React.Dispatch<React.SetStateAction<string | null>>;
  selectComponent: (id: string, tag: FoodComponentType) => void;
}

const ComponentCard = ({
  component,
  selectedCount,
  selectedTag,
  maxAllowedCompsForTag,
  numOfSelectedComponentsByTag,
  selectComponent,
  setSelectedComponentInfo
}: ComponentCardProps) => {
  const displayImage = `${process.env.REACT_APP_BUCKET_URL}/food-component/${component.id}/square@1x.jpg`;

  const componentClickHandler = () => {
    if (numOfSelectedComponentsByTag === maxAllowedCompsForTag) {
      return;
    }
    selectComponent(component.id, selectedTag);
  };

  const containsAllergen = component.ingredients.some((ing) => hasAllergen(ing));

  return (
    <Stack
      onClick={componentClickHandler}
      sx={{
        position: 'relative',
        borderRadius: '16px',
        borderWidth: '2px',
        gap: 2,
        borderColor: selectedCount > 0 ? caloTheme.palette.primary500 : 'transparent',
        alignItems: 'center',
        px: '4px',
        py: '8px',
        width: '33%',
        height: '220px',
        marginBottom: '8px'
      }}
    >
      <CardMedia
        component="img"
        image={displayImage}
        alt="placeholder"
        sx={{ width: 140, maxHeight: 140, height: 140, objectFit: 'cover', borderRadius: '8px' }}
        onError={(e: any) => {
          e.target.src = noImagePlaceholder;
        }}
      />
      <Typography sx={{ fontSize: '16px', textAlign: 'center' }}>{component.name.en}</Typography>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          setSelectedComponentInfo(component.id);
        }}
        sx={{
          position: 'absolute',
          left: '1px',
          top: '1px'
        }}
      >
        <InfoIcon fontSize="small" sx={containsAllergen ? { color: caloTheme.palette.red500 } : {}} />
      </IconButton>
      {selectedCount !== 0 && (
        <Typography
          sx={{
            position: 'absolute',
            right: '5px',
            top: '5px',
            fontSize: '14px',
            fontWeight: 600,
            backgroundColor: caloTheme.palette.primary100,
            color: caloTheme.palette.primary500,
            width: '24px',
            height: '24px',
            borderRadius: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {selectedCount}x
        </Typography>
      )}
    </Stack>
  );
};

export default ComponentCard;
