import { User, UserGroup } from '@calo/dashboard-types';
import { Country, Kitchen } from '@calo/types';
import { Button, Input, Select } from 'components';
import useUserForm from './useUserForm';
interface UserFormProps {
  onSubmit: (value: Omit<User, 'id'>) => Promise<void>;
  user: User;
  permissionsAndRoles: UserGroup[];
}

const UserForm = ({ onSubmit, user, permissionsAndRoles }: UserFormProps) => {
  const { handleSubmit, values, handleChange, handleBlur, isSubmitting, isValid, dirty, setFieldValue } = useUserForm(
    user,
    onSubmit
  );

  return (
    <form onSubmit={handleSubmit}>
      <Input label="Name" value={values.name} name="name" onChange={handleChange} onBlur={handleBlur} />
      <Input label="Phone number" value={values.phoneNumber} name="phoneNumber" onChange={handleChange} onBlur={handleBlur} />
      <Input label="Email" value={values.email} name="email" onChange={handleChange} onBlur={handleBlur} />
      <Select
        label="Country"
        value={values.country}
        onChange={(data: any) => setFieldValue('country', data.value)}
        options={Object.values(Country).map((c) => ({
          value: c,
          label: Country[c]
        }))}
      />
      <Select
        label="Kitchen"
        value={values.kitchen}
        onChange={(data: any) =>
          setFieldValue(
            'kitchen',
            data.map((row) => row.value)
          )
        }
        options={Object.values(Kitchen)
          .filter((kitchen) => !kitchen.includes('000'))
          .map((c) => ({
            value: c,
            label: c
          }))}
        isMulti
        isDisabled={Object.values(Kitchen).filter((r) => r.includes(values.country!)).length === 0}
      />
      <Select
        label="group"
        value={values.group}
        name="group"
        onChange={(data: any) => setFieldValue('group', data.value)}
        options={permissionsAndRoles.map((type) => ({
          value: type.id,
          label: type.groupName
        }))}
        onBlur={handleBlur}
      />
      <div>
        <Button type="submit" primary loading={isSubmitting} disabled={!dirty || !isValid || isSubmitting} content="Save" />
      </div>
    </form>
  );
};
export default UserForm;
