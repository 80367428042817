import { getRecord } from 'actions';
import { Kitchen } from 'lib/interfaces';
import { useQuery } from 'react-query';

interface StatsProps {
  kitchen: Kitchen;
}

const Stats = ({ kitchen }: StatsProps) => {
  const { data } = useQuery(['stats', 'kitchens', kitchen.id], getRecord);
  const stats = data as { allowedCount: number; preLaunchCount: number; softLaunchCount: number; supplyCapCount: number };

  return (
    <>
      <td>{stats ? stats?.supplyCapCount + stats?.preLaunchCount + stats?.softLaunchCount : '---'}</td>
      <td>{stats ? stats?.allowedCount : '---'}</td>
    </>
  );
};

export default Stats;
