import { Route, Switch } from 'react-router-dom';

import { Routes } from 'lib/enums';

import Calculator from './Calculator';

const Plans = () => {
  return (
    <Switch>
      <Route key={Routes.plansCalculator} exact path={Routes.plansCalculator} component={Calculator} />
    </Switch>
  );
};

export default Plans;
