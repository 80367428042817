import { caloTheme } from 'assets/images/theme/calo';

import { CircularProgress, Stack } from '@mui/material';

import ClickToUploadCard from './ClickToUploadCard';

interface ImageUploadCardProps {
  progress: number;
  getInputProps: any;
  getRootProps: any;
  fullWidth?: boolean;
}

const ImageUploadCard = ({ progress, getInputProps, getRootProps, fullWidth }: ImageUploadCardProps) => {
  if (fullWidth) {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} width="100%" {...getRootProps()}>
        <input disabled={progress > 0 && progress < 100} {...getInputProps()} />
        {progress > 0 && progress < 100 ? (
          <CircularProgress variant="determinate" sx={{ color: caloTheme.palette.primary500 }} value={progress} />
        ) : (
          <ClickToUploadCard />
        )}
      </Stack>
    );
  } else {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        width={'100%'}
        {...getRootProps()}
        sx={{
          position: 'relative',
          height: 160,
          width: 160,
          minWidth: 160,
          border: '1px solid ' + caloTheme.palette.neutral400,
          borderRadius: '8px',
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <input disabled={progress > 0 && progress < 100} {...getInputProps()} />
        <ClickToUploadCard
          icon="ic:round-plus"
          iconColor={caloTheme.palette.neutral500}
          fontSize={14}
          firstFontColor={caloTheme.palette.neutral500}
          px={2}
        />
      </Stack>
    );
  }
};
export default ImageUploadCard;
