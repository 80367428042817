import { DDeliveryStatus, DeliveryStatus, PaymentMethod } from '@calo/types';
import { Icon as Iconify } from '@iconify/react';
import { Box, Typography } from '@mui/material';
import { Icon } from 'components';
import { hasGifts } from 'lib/index';
import { Delivery } from 'lib/interfaces';
import { useMemo } from 'react';

interface DeliveryPaymentIconProps {
  delivery: Delivery;
}

const DeliveryPaymentIcon = ({ delivery }: DeliveryPaymentIconProps) => {
  const handlePaymentMethodIcon = () => {
    if (delivery?.status === DeliveryStatus.upcoming) {
      switch (delivery?.paymentMethod) {
        case PaymentMethod.mada:
          return <i className="w-12 ml-2">MADA</i>;
        case PaymentMethod.cash:
          return <Icon name="paidCash" size={5} className="w-12" />;
        case PaymentMethod.cc || PaymentMethod.benefit:
          return <Icon name="paidCC" size={5} className="w-12" />;
        case PaymentMethod.applePay:
          return <Icon name="applePay" size={7} className="w-24 -mt-1" />;
      }
    } else {
      switch (delivery?.paymentMethod) {
        case PaymentMethod.mada:
          return (
            <i className="w-12 ml-2" style={{ color: 'red' }}>
              MADA
            </i>
          );
        case PaymentMethod.cash:
          return <Icon name="notPaidCash" size={5} className="w-12" />;
        case PaymentMethod.cc || PaymentMethod.benefit:
          return <Icon name="notPaidCC" size={5} className="w-12" />;
        case PaymentMethod.applePay:
          return <Icon name="applePay" size={7} className="w-24 -mt-1" />;
      }
    }
  };

  const hasGiftedItems = useMemo(() => hasGifts(delivery), [delivery.id]);

  return (
    <Box display={'flex'} flexDirection={'row'} sx={{ mt: '1px' }}>
      {delivery.deliveryStatus === DDeliveryStatus.delivered && <Icon name="deliveredTruck" size={5} className="ml-1 w-8" />}
      {handlePaymentMethodIcon()}
      {delivery.withCutlery && <Icon name="cutlery" size={6} className="ml-2 mt-1" />}
      {delivery.withCutlery && Boolean(delivery.numberOfCutlery) && (
        <Typography sx={{ mr: 2 }}>x {delivery.numberOfCutlery}</Typography>
      )}
      {delivery.withCoolerBag && <Iconify icon="charm:shopping-bag" width="24px" height="24px" style={{ marginRight: '12px' }} />}
      {hasGiftedItems && <Iconify icon="ph:gift" width="26px" height="26px" />}
    </Box>
  );
};
export default DeliveryPaymentIcon;
