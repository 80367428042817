import { alpha, InputBase, styled } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { GlobalStyles } from 'lib/interfaces';

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto'
  }
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  }
}));

export const styles: GlobalStyles = {
  searchBar: {
    border: 1,
    gap: '10px',
    padding: '10px 8px',
    borderColor: caloTheme.palette.grey[700],
    borderRadius: '8px',
    height: '40px',
    width: '400px'
  },
  cardContainer: {
    width: 'full',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '24px',
    mb: 2,
    padding: 2,
    borderRadius: '8px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  costRow: { flexDirection: 'row', width: '60%' },
  editButton: {
    borderWidth: 1,
    borderColor: caloTheme.palette.grey[700],
    borderRadius: '8px',
    boxShadow: 'none',
    height: '40px',
    '&:hover': {
      borderColor: caloTheme.palette.grey[700],
      borderRadius: '8px',
      boxShadow: 'none'
    }
  },

  addMealButton: {
    marginX: 2,
    backgroundColor: caloTheme.palette.primary500,
    width: '87px',
    gap: '10px',
    borderRadius: '8px',
    color: 'white',
    padding: '10px 16px 10px 16px',
    boxShadow: 'none',
    fontSize: '16px',
    height: '40px',
    fontWeight: 600,
    lineHeight: '20px',
    '&:hover': {
      backgroundColor: caloTheme.palette.primary600,
      color: 'white',
      borderRadius: '8px',
      boxShadow: 'none'
    }
  },
  replaceContainer: {
    maxHeight: '64vh',
    padding: '4px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
  },
  noMealResultStyle: {
    color: '#757575',
    textAlign: 'center',
    padding: '16px'
  },
  filterButtonStyle: {
    width: '200px',
    height: '48px',
    borderRadius: '8px',
    backgroundColor: caloTheme.palette.primary.main,
    color: '#fff',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: caloTheme.palette.primary.main
    }
  },
  previewMenu: {
    color: 'black',
    boxShadow: 'none',
    marginRight: '20px',
    height: '40px',
    '&:hover': {
      borderRadius: '8px',
      boxShadow: 'none'
    }
  },
  text: { fontWeight: 600, mr: 2 }
};
