import { getRecord } from 'actions';
import cx from 'classnames';
import { Delivery } from 'lib/interfaces';
import { useState } from 'react';
import { useQuery } from 'react-query';

interface Props {
  driverName: string;
  setSelectedD: (deliveries: Delivery[]) => void;
  data: Delivery[];
  color?: string;
  setSelectedColor: (color: string | undefined) => void;
  driverId: string;
}

const SidebarAreaOptions = ({ driverName, data, setSelectedD, color, setSelectedColor, driverId }: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  useQuery([`/drivers/${driverId}`], getRecord, {
    enabled: !driverName,
    retry: false,
    onError: console.error,
    onSuccess(data: any) {
      if (data) {
        setError(true);
        setErrorMessage(data.name);
      }
    }
  });

  return (
    <li
      className={cx('flex flex-row items-start h-16  text-gray-600  relative w-full border rounded my-1')}
      style={{ backgroundColor: color || 'white', borderColor: color ? color.replace('0.5', '1') : 'gray' }}
    >
      <button
        className="flex flex-column pl-3 focus:outline-none hover:text-gray-100  w-full h-full justify-center "
        onClick={() => {
          setSelectedD(data);
          setSelectedColor(color);
        }}
      >
        <p className={error ? 'text-red-500 font-bold' : ''}>{error ? errorMessage : driverName}</p>
      </button>
    </li>
  );
};

export default SidebarAreaOptions;
