import { Permission } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import { Box, Card, Stack, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns/fp';
import { StyledTableCell } from 'lib/componentStyles';
import { Routes } from 'lib/enums';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { Link } from 'react-router-dom';

const SupplyChainReportsList = () => {
  const roles = useUserRoles();
  const userKitchens = useUserKitchens();
  const day = format('yyyy-MM-dd')(Date.now());
  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: 2,
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          display={'flex'}
          flexDirection="row"
          justifyContent={'space-between'}
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
          width="100%"
        >
          <Stack width={'50%'} display={'flex'} flexDirection={'row'} alignItems="start" justifyContent={'space-between'}>
            <Typography
              variant="h3"
              sx={{
                mt: 'auto',
                fontWeight: 600,
                fontSize: '33px',
                lineHeight: '40px',
                color: caloTheme.palette.neutral900,
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              Supply Chain Reports
            </Typography>
          </Stack>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems="end"
            sx={{
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
                flexDirection: 'column'
              }
            }}
          ></Stack>
        </Box>
      </Card>
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: 2,
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
          <Typography variant="h6">{day}</Typography>
          {userKitchens ? (
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: caloTheme.palette.neutral50,
                    color: 'black',
                    width: '100%',
                    flexWrap: 0,
                    justifyContent: 'flex-start'
                  }}
                >
                  <StyledTableCell
                    style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', width: '40%', fontWeight: 'bold' }}
                  >
                    Kitchen
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '30%', fontWeight: 'bold' }}></StyledTableCell>
                  <StyledTableCell
                    style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px', width: '30%', fontWeight: 'bold' }}
                  ></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userKitchens.sort().map((kitchen: Kitchen) => (
                  <TableRow key={`mrp-${kitchen}`} sx={{ borderBottom: '1px solid #e0e0e0' }}>
                    <StyledTableCell>{kitchen}</StyledTableCell>
                    <StyledTableCell>
                      {roles.includes(Permission.VIEW_MATERIAL_RESOURCE_PLANING_REPORT) ? (
                        <Link to={Routes.supplyChainMRPReport.replace(':kitchen', kitchen).replace(':day', day)}>
                          Material Resource Planning Report
                        </Link>
                      ) : (
                        'Material Resource Planning Report'
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {roles.includes(Permission.VIEW_MOVING_COST_REPORT) ? (
                        <Link to={Routes.supplyChainMovingCostReport.replace(':kitchen', kitchen)}>Moving Cost Report</Link>
                      ) : (
                        'Moving Cost Report'
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography variant="h6">You are not assigned to any kitchen</Typography>
          )}
        </Box>
      </Card>
    </>
  );
};

export default SupplyChainReportsList;
