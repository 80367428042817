import { useState } from 'react';

import { format, setDay } from 'date-fns/fp';
import { range } from 'lodash-es';
import { useMutation } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';

import { Subscription } from '@calo/types';
import { updateSubscription2 } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import { useUserRoles } from 'lib/hooks';
import usePlanForm from '../usePlanForm';

interface DeliveryDaysCardProps {
  subscription: Subscription & { remainingDays: number; expectedLastDeliveryDay: string };
}
const DeliveryDaysCard = ({ subscription }: DeliveryDaysCardProps) => {
  const roles = useUserRoles();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutateAsync: updateSubscription } = useMutation(updateSubscription2);
  const onSubmit = async ({ deliveryDays }: { deliveryDays: number[] }): Promise<void> => {
    await updateSubscription({
      subId: subscription.id,
      data: {
        deliveryDays: deliveryDays
      }
    });
  };

  const { values, setFieldValue, initialValues, setValues } = usePlanForm(subscription, onSubmit);

  const handleEditing = (edit: boolean) => {
    if (subscription.deliveryDays !== values.deliveryDays) {
      onSubmit(values);
    }
    setIsEditing(edit);
  };

  const handleReset = () => {
    setValues(initialValues);
    setIsEditing(false);
  };

  return (
    <>
      <Box
        sx={{
          margin: 2,
          padding: '12px',
          backgroundColor: caloTheme.palette.neutral50,
          borderRadius: 2,
          display: 'flex',
          direction: 'row',
          width: 'full',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '19px',
            lineHeight: '23px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Delivery Days
        </Typography>
        {isEditing ? (
          <Stack sx={{ justifyContent: 'end', flexDirection: 'row' }}>
            <CheckIcon sx={{ marginRight: 2 }} style={{ cursor: 'pointer' }} onClick={() => handleEditing(!isEditing)} />
            <ClearIcon style={{ cursor: 'pointer' }} onClick={handleReset} />
          </Stack>
        ) : (
          <Icon
            onClick={() => handleEditing(!isEditing)}
            name="edit2"
            size={6}
            style={{ cursor: 'pointer', width: '26px', height: '26px' }}
          />
        )}
      </Box>
      <Stack sx={{ display: 'flex', direction: 'row', width: 'full', justifyContent: 'space-between', padding: '12px' }}>
        <TextField
          select
          label="Days"
          name="deliveryDays"
          value={values.deliveryDays}
          id="new-subscription-deliveryDays"
          placeholder={'Select Delivery Days'}
          SelectProps={{
            multiple: true,
            value: values.deliveryDays,
            onChange: (e) => setFieldValue('deliveryDays', e.target.value)
          }}
          disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
          InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
        >
          {range(0, 7)
            .filter((r) => values.deliveryDays && !values.deliveryDays.includes(r))
            .map((range) => (
              <MenuItem value={range}>{format('EEE')(setDay(range)(Date.now()))}</MenuItem>
            ))}
        </TextField>
      </Stack>

      <Box sx={{ padding: '8px' }}>
        <Stack
          sx={{
            border: 1,
            borderColor: caloTheme.palette.neutral100,
            borderRadius: '8px',
            padding: '8px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: '2px'
          }}
        >
          {values.deliveryDays.map((day) => (
            <Box
              sx={{
                margin: '4px',
                flexDirection: 'row',
                flexWrap: 'wrap',
                borderRadius: '8px',
                textAlign: 'center',
                background: caloTheme.palette.primary100,
                color: caloTheme.palette.primary700,
                width: isEditing ? '76px' : '52px',
                height: '33px'
              }}
            >
              <Grid sx={{ m: 'auto', mt: '4px' }}>
                <Typography sx={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
                  <p className="mx-2">{format('EEE')(setDay(day)(Date.now()))}</p>
                  {roles.includes(Permission.UPDATE_SUBSCRIPTION) && isEditing && (
                    <Icon
                      name="removeX"
                      className="m-auto"
                      onClick={() =>
                        setFieldValue(
                          'deliveryDays',
                          values.deliveryDays.filter((row) => row !== day)
                        )
                      }
                    />
                  )}
                </Typography>
              </Grid>
            </Box>
          ))}
        </Stack>
      </Box>
    </>
  );
};
export default DeliveryDaysCard;
