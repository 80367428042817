import React from 'react';

import cx from 'classnames';

import { AddressType } from '@calo/types';

interface AddressTypeRowProps {
  name: string;
  type: AddressType;
  onSelect: (type: AddressType) => void;
  selected: boolean;
}

const AddressTypeRow = ({ type, onSelect, selected, name }: AddressTypeRowProps) => (
  <div
    className={cx('flex p-4 w-full justify-between rounded-lg bg-gray-100', {
      'border-2 border border-green-500 text-green-500 bg-green-100': selected,
      'border-gray-500': !selected
    })}
    onClick={() => onSelect(type)}
  >
    <p className=" mx-auto label font-bold uppercase">{name}</p>
  </div>
);

export default AddressTypeRow;
