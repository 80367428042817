import { IngredientComponentStats, IngredientStats } from 'lib/interfaces';

export const getRemovedValue = (oldValue: number, isChild = false, onlyChild = false) =>
  !isChild || (isChild && onlyChild) ? oldValue : 0;
export const getTotalRawWeight = (component: IngredientComponentStats, isChild = false, onlyChild = false) =>
  Math.ceil(
    (isChild ? component.childComponentRawWeight : component.parentComponentRawWeight) -
      getRemovedValue(component.removedComponentRawWeight, isChild, onlyChild)
  );

export const getTotalCookedWeight = (component: IngredientComponentStats, isChild = false, onlyChild = false) =>
  Math.ceil(getTotalRawWeight(component, isChild, onlyChild) / (component.cookedRawFactor || 1));

export const getSwitchedValue = (oldValue: number, switchUnit: boolean, ingredient?: IngredientStats) =>
  Math.ceil(oldValue / (switchUnit && ingredient?.weight ? ingredient?.weight : 1));

export const getQuantityAfterPrep = (
  component: IngredientComponentStats,
  switchUnit: boolean,
  ingredient?: IngredientStats,
  isChild = false,
  onlyChild = false
) =>
  getSwitchedValue(
    (isChild ? component.neededIngredientFromChild : component.neededIngredientFromParent) -
      getRemovedValue(component.removedNeededIngredient, isChild, onlyChild),
    switchUnit,
    ingredient
  );

export const getQuantity = (
  component: IngredientComponentStats,
  switchUnit: boolean,
  ingredient?: IngredientStats,
  isChild = false,
  onlyChild = false
) => getQuantityAfterPrep(component, switchUnit, ingredient, isChild, onlyChild) * (ingredient?.wastage || 1);
