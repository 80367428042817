import { FoodStatus, ProcessingStage } from '@calo/dashboard-types';
import { Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { startCase } from 'lodash-es';

const StatusBox = ({ status }: { status?: ProcessingStage | FoodStatus }) => {
  const backgroundColor =
    status === 'approved'
      ? caloTheme.palette.primary100
      : status === 'pending'
        ? caloTheme.palette.secondaryYellow100
        : caloTheme.palette.neutral50;
  const color =
    status === 'approved'
      ? caloTheme.palette.primary700
      : status === 'pending'
        ? caloTheme.palette.secondaryYellow700
        : caloTheme.palette.neutral700;

  return (
    <Typography
      sx={{
        fontSize: '14px',
        fontWeight: 600,
        borderRadius: '18px',
        px: '14px',
        py: '8px',
        backgroundColor: backgroundColor,
        color: color
      }}
    >
      {startCase(status)}
    </Typography>
  );
};

export default StatusBox;
