import { useState } from 'react';

import { format, parseISO } from 'date-fns/fp';

import { Permission } from '@calo/dashboard-types';
import { Button, styled, TableCell, tableCellClasses, TableRow, Typography } from '@mui/material';

import { selectedFoodData } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';

interface FeedbackCardRowProps {
  row: any;
}

const handleChangeURL = async (foodID: string) => {
  const selectedFeedBackFood = await selectedFoodData(foodID);
  return history.replace(Routes.foodSlug.replace(':slug', selectedFeedBackFood.slug));
};

const FeedbackCardRow = ({ row }: FeedbackCardRowProps) => {
  const roles = useUserRoles();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      width: '100%',
      px: 4,
      component: 'th',
      scope: 'row',
      color: caloTheme.palette.neutral900,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      variant: 'caption'
    },
    [`&.${tableCellClasses.body}`]: {
      px: 4,
      fontWeight: 400,
      fontSize: '12px',
      fontHeight: '14px',
      variant: 'caption',
      fontFamily: caloTheme.typography.fontFamily
    }
  }));

  return (
    <>
      <TableRow key={row.id}>
        <StyledTableCell>
          {roles.includes(Permission.VIEW_FOOD_LIST_BY_SLUG) ? (
            <a onClick={() => handleChangeURL(row.food.id)}>{row.food.name.en}</a>
          ) : (
            `${row.food.name.en}`
          )}
        </StyledTableCell>
        <StyledTableCell>{row.rating}</StyledTableCell>
        <StyledTableCell>
          {row.review ? (
            <Button
              variant="text"
              aria-label="Notes"
              sx={{
                height: '32px',
                fontWeight: 400,
                lineHeight: '14px',
                fontSize: '12px',
                borderRadius: '8px',
                fontFamily: caloTheme.typography.fontFamily,
                color: isOpen ? 'white' : caloTheme.palette.secondaryBlue900,
                backgroundColor: isOpen ? caloTheme.palette.secondaryBlue300 : caloTheme.palette.secondaryBlue50,
                '&:hover': {
                  color: isOpen ? 'white' : caloTheme.palette.secondaryBlue900,
                  backgroundColor: isOpen ? caloTheme.palette.secondaryBlue400 : caloTheme.palette.secondaryBlue100
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  display: 'flex',
                  justifyItems: 'center',
                  m: 'auto',
                  mb: 2,
                  mt: '-4px',
                  width: 'auto'
                }
              }}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? 'Hide Review' : 'View Review'}
            </Button>
          ) : (
            <Typography display={'flex'} flexDirection={'row'}>
              ---
            </Typography>
          )}
        </StyledTableCell>
        <StyledTableCell>{row.createdAt && format('dd-MM-yyyy')(parseISO(row.createdAt))}</StyledTableCell>
      </TableRow>
      {isOpen && (
        <TableRow>
          <StyledTableCell
            colSpan={10}
            sx={{
              familyFont: caloTheme.typography.fontFamily,
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '14px',
              color: caloTheme.palette.neutral900,
              backgroundColor: caloTheme.palette.secondaryBlue50
            }}
          >
            {row.review}
          </StyledTableCell>
        </TableRow>
      )}
    </>
  );
};
export default FeedbackCardRow;
