import { Permission } from '@calo/dashboard-types';
import { format, parseISO } from 'date-fns/fp';
import { useUserRoles } from 'lib/hooks';
import { FoodLogInterface } from 'lib/interfaces';
import { differenceWith, flatten, isEqual, omit } from 'lodash';
import { linkTo } from './helpers';

interface FoodLogRowProps {
  log: FoodLogInterface;
}

const FoodLogRow = ({ log }: FoodLogRowProps) => {
  const roles = useUserRoles();

  const handleDiffValues = (log: FoodLogInterface, newVal: boolean, field: string) => {
    let oldData;
    let newData;

    if (field === 'ingredients') {
      oldData = differenceWith(
        flatten(log.data.oldValue).map((r: any) => omit(r, ['createdAt', 'updatedAt', 'key', 'slug', 'brand', 'country', 'id'])),
        flatten(log.data.newValue).map((r: any) => omit(r, ['createdAt', 'updatedAt', 'key', 'slug', 'brand', 'country', 'id'])),
        isEqual
      );
      newData = differenceWith(
        flatten(log.data.newValue).map((r: any) => omit(r, ['createdAt', 'updatedAt', 'key', 'slug', 'brand', 'country', 'id'])),
        flatten(log.data.oldValue).map((r: any) => omit(r, ['createdAt', 'updatedAt', 'key', 'slug', 'brand', 'country', 'id'])),
        isEqual
      );
    } else {
      oldData = JSON.stringify(log.data.oldValue);
      newData = JSON.stringify(log.data.newValue);
    }

    const oldValues = JSON.stringify(oldData)
      ?.replace(/[&\\#+() $~%'"*?[<>{}]/g, '')
      .replace(/]/g, '')
      ?.split(',');
    const newValues = JSON.stringify(newData)
      ?.replace(/[&\\#+() $~%'"*?[<>{}]/g, '')
      .replace(/]/g, '')
      ?.split(',');

    const diff = newVal ? newValues : oldValues;
    return (
      <table className="w-full table">
        <tbody>
          {diff?.map((r, index) => (
            <tr key={`old${r}-${index}`}>
              <td key={`new${r}-${index}`}>
                <p className="text-sm break-words w-auto max-w-md">{r.replace(/:/g, ': ').toString()}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <tr>
      <td>{format('dd/MM/yyyy hh:mm aa')(parseISO(log.createdAt))}</td>
      <td>{log.country}</td>
      <td>{JSON.stringify(log.kitchen)}</td>
      <td>{log.changesBy.name}</td>
      <td>{log.action}</td>
      <td>{log.data.field}</td>
      <td>
        {log.data.oldValue && JSON.stringify(log.data.oldValue).split(',').length > 1 ? (
          handleDiffValues(log, false, log.data.field)
        ) : (
          <p>{JSON.stringify(log.data.oldValue)}</p>
        )}
      </td>

      <td>
        {log.data.newValue && JSON.stringify(log.data.newValue).split(',').length > 1 ? (
          handleDiffValues(log, true, log.data.field)
        ) : (
          <p>{JSON.stringify(log.data.newValue)}</p>
        )}
      </td>

      <td>
        {roles.includes(Permission.VIEW_FOOD_COMPONENTS) && log.action !== 'delete' ? (
          <a onClick={() => linkTo(log)}>{log.data.name}</a>
        ) : (
          `${log.data.name}`
        )}
      </td>
    </tr>
  );
};

export default FoodLogRow;
