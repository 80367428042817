import { useState } from 'react';

import { FormikErrors } from 'formik';
import { camelCase } from 'lodash';

import { Permission } from '@calo/dashboard-types';
import { Card, FormHelperText, Stack, TextField, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { hasValidCharactersForLanguage } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { IngredientFormData } from 'lib/interfaces';

interface IngredientInformationProps {
  values: IngredientFormData;
  errors: FormikErrors<IngredientFormData>;
  handleChange: { (e: React.ChangeEvent<any>): void };
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IngredientFormData>>;
  disabled?: boolean;
}

const IngredientInformation = ({
  values,
  errors,
  disabled,
  handleChange,
  handleBlur,
  setFieldValue
}: IngredientInformationProps) => {
  const userRoles = useUserRoles();

  const [langAlert, setLangAlert] = useState({ AR: false, EN: false, value: '' });

  const handleNameFieldChange = (field: string, data: string, lang: 'AR' | 'EN') => {
    const allowSpecialChar = field.includes('description');

    setFieldValue(field, data);

    const hasValidCharacters = hasValidCharactersForLanguage(data, lang, allowSpecialChar) || data === '';
    setLangAlert({ ...langAlert, [`${lang}`]: !hasValidCharacters, value: field });

    setFieldValue('key', camelCase(data));
  };

  return (
    <InputThemeProvider>
      <Card
        variant="outlined"
        sx={{
          marginTop: '16px',
          border: 'none',
          borderRadius: '16px',
          py: '20px',
          px: '12px',
          zIndex: 100,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography variant="h5" sx={{ fontSize: '19px', fontWeight: 600, marginBottom: '20px' }}>
          Ingredient Information
        </Typography>
        <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '13px', width: '100%', marginBottom: '28px' }}>
          <Stack sx={{ width: '50%' }}>
            <TextField
              label="Name EN*"
              name="name.en"
              id="name.en"
              placeholder="Enter EN-Name"
              value={values.name.en}
              sx={{ width: '100%' }}
              disabled={!userRoles.includes(Permission.UPDATE_INGREDIENT) || Boolean(disabled)}
              onChange={(data) => handleNameFieldChange('name.en', data.target.value, 'EN')}
              onBlur={handleBlur}
              error={!!errors.name?.en}
            />
            <FormHelperText id="name.en-error" sx={{ color: caloTheme.palette.red }}>
              {langAlert.EN && langAlert.value === 'name.en' && 'letters should be in English only'}
            </FormHelperText>
          </Stack>
          <Stack sx={{ width: '50%' }}>
            <TextField
              label="Name AR*"
              name="name.ar"
              id="name.ar"
              placeholder="Enter AR-Name"
              value={values.name.ar}
              sx={{ width: '100%' }}
              onBlur={handleBlur}
              error={!!errors.name?.ar}
              disabled={!userRoles.includes(Permission.UPDATE_INGREDIENT) || Boolean(disabled)}
              onChange={(data) => handleNameFieldChange('name.ar', data.target.value, 'AR')}
            />
            <FormHelperText id="name.en-error" sx={{ color: caloTheme.palette.red }}>
              {langAlert.AR && langAlert.value === 'name.ar' && 'letters should be in Arabic only'}
            </FormHelperText>
          </Stack>
        </Stack>
        <TextField
          label="Internal name"
          value={values.internalName}
          name="internalName"
          sx={{ width: '100%' }}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.internalName}
          disabled={!userRoles.includes(Permission.UPDATE_INGREDIENT) || Boolean(disabled)}
        />
      </Card>
    </InputThemeProvider>
  );
};

export default IngredientInformation;
