import { useEffect, useState } from 'react';

import { FormikErrors } from 'formik';
import { isNumber } from 'lodash';

import { Permission } from '@calo/dashboard-types';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { FormControl, IconButton, InputLabel, MenuItem, Radio, Select, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { useUserRoles } from 'lib/hooks';
import { SubscriptionPlan2Values } from 'lib/interfaces';

interface SelectCutleryProps {
  values: SubscriptionPlan2Values;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<SubscriptionPlan2Values>> | Promise<void>;
  isSubmitting: boolean;
  isEditing: boolean;
}

const SelectCutlery = ({ values, setFieldValue, isSubmitting, isEditing }: SelectCutleryProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [numberOfCutlery, setNumberOfCutlery] = useState<number>(values.numberOfCutlery || 0);
  const [selectedValue, setSelectedValue] = useState(
    values.numberOfCutlery ? values.numberOfCutlery.toString() : values.withCutlery ? 'on' : 'off'
  );
  const roles = useUserRoles();

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, value: string) => {
    event.stopPropagation();
    setSelectedValue(value);

    const numberValue = Number(value);
    console.log('numberValue', numberValue);
    if (isNumber(numberValue) && !isNaN(numberValue)) {
      setNumberOfCutlery(1);
      setFieldValue('numberOfCutlery', numberValue);
      toggleMenu();
    } else if (value === 'off' || value === 'on') {
      setNumberOfCutlery(0);
      setFieldValue('withCutlery', value === 'off' ? false : true);
      setFieldValue('numberOfCutlery', 0);
    }
  };

  const renderSelectValue = () => {
    if (selectedValue === 'off') {
      return 'Off';
    } else if (selectedValue === 'on') {
      return 'On';
    } else {
      return `Custom (${numberOfCutlery})`;
    }
  };

  useEffect(() => {
    setFieldValue('numberOfCutlery', numberOfCutlery);
  }, [numberOfCutlery, setFieldValue]);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);
  const disabled = !roles.includes(Permission.UPDATE_SUBSCRIPTION) || isSubmitting || !isEditing;
  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: '18%',
        display: 'flex',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          width: 'full'
        }
      }}
    >
      <InputLabel id="exact-subscription-cutlery-label">Cutlery</InputLabel>
      <Select
        labelId="exact-subscription-cutlery"
        value={values.numberOfCutlery ? values.numberOfCutlery.toString() : values.withCutlery ? 'on' : 'off'}
        label="Cutlery"
        onOpen={toggleMenu}
        onClose={toggleMenu}
        open={disabled ? false : isMenuOpen}
        renderValue={renderSelectValue}
        sx={{ borderRadius: '8px' }}
        onChange={(event) => {
          const value = event.target.value;
          const numberValue = Number(value);
          if (isNumber(numberValue) && !isNaN(numberValue)) {
            setFieldValue('numberOfCutlery', numberValue);
          } else {
            setFieldValue('numberOfCutlery', 0);
            setFieldValue('withCutlery', value === 'off' ? false : true);
          }
        }}
        disabled={disabled}
      >
        <MenuItem value="off" onClick={(event) => handleMenuItemClick(event, 'off')}>
          <Radio checked={selectedValue === 'off'} color="primary" />
          Off
        </MenuItem>
        <MenuItem value="on" onClick={(event) => handleMenuItemClick(event, 'on')}>
          <Radio checked={selectedValue === 'on'} color="primary" />
          On
        </MenuItem>
        <MenuItem
          sx={{ flexDirection: 'column' }}
          value={numberOfCutlery.toString()}
          onClick={(event) => handleMenuItemClick(event, numberOfCutlery.toString())}
        >
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
            <Radio checked={isNumber(selectedValue)} color="primary" />
            Custom Cutlery
          </Stack>
        </MenuItem>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            width: '85%',
            mx: 'auto',
            justifyContent: 'space-between'
          }}
        >
          <IconButton
            disableRipple
            disabled={numberOfCutlery <= 1}
            onClick={(event) => {
              event.stopPropagation();
              setNumberOfCutlery((prev) => prev - 1);
              setFieldValue('numberOfCutlery', numberOfCutlery - 1);
            }}
          >
            <RemoveIcon
              sx={{
                color: numberOfCutlery <= 1 ? caloTheme.palette.grey['400'] : caloTheme.palette.primary500,
                backgroundColor: numberOfCutlery <= 1 ? caloTheme.palette.grey['100'] : caloTheme.palette.primary50,
                borderRadius: '40px',
                p: '8px',
                fontSize: '50px'
              }}
            />
          </IconButton>
          <Typography sx={{ fontSize: '20px' }}>{numberOfCutlery}</Typography>
          <IconButton
            disableRipple
            disabled={numberOfCutlery < 1}
            onClick={(event) => {
              event.stopPropagation();
              setNumberOfCutlery((prev) => prev + 1);
              setFieldValue('numberOfCutlery', numberOfCutlery + 1);
            }}
          >
            <AddIcon
              sx={{
                color: numberOfCutlery < 1 ? caloTheme.palette.grey['400'] : caloTheme.palette.primary500,
                backgroundColor: numberOfCutlery < 1 ? caloTheme.palette.grey['100'] : caloTheme.palette.primary50,
                borderRadius: '40px',
                p: '8px',
                fontSize: '50px'
              }}
            />
          </IconButton>
        </Stack>
      </Select>
    </FormControl>
  );
};

export default SelectCutlery;
