import cx from 'classnames';
import { Icon } from 'components';
import { CustomComponentsByCategory, Delivery, FoodComponent, FoodWithPosition, Menu } from 'lib/interfaces';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';

import { Subscription } from '@calo/types';
import { Box, Card, CardHeader, Stack, Tooltip, Typography } from '@mui/material';

import DeliveryMealAction from '../shared/DeliveryMealAction';
import AdditionalMealInfo from './AdditionalMealInfo';

interface DeliveriesMealTableRowMobileProps {
  mealType: string;
  delivery: Delivery;
  meal: FoodWithPosition;
  menu: Menu;
  menuLoading: boolean;
  components: FoodComponent[];
  subscription: Subscription;
  customFoodComponents: FoodComponent[];
  allCustomFoodComponents: CustomComponentsByCategory;
  caloKidsComponents: FoodComponent[];
  isCustomComponentsLoading: boolean;
  setCustomFoodComponents: React.Dispatch<React.SetStateAction<FoodComponent[]>>;
  refetchDelivery: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  setFetchMenu: (value: boolean) => void;
  setFetchAllComponents: (value: boolean) => void;
}

const DeliveriesMealTableRowMobile = ({
  meal,
  components,
  mealType,
  menu,
  menuLoading,
  delivery,
  subscription,
  caloKidsComponents,
  customFoodComponents,
  allCustomFoodComponents,
  isCustomComponentsLoading,
  setCustomFoodComponents,
  refetchDelivery,
  setFetchMenu,
  setFetchAllComponents
}: DeliveriesMealTableRowMobileProps) => {
  return (
    <Card key={meal.id} sx={{ width: 'full', mb: 3 }}>
      <CardHeader
        title={
          <Box display={'flex'} flexDirection={'row'} width={'full'} justifyContent="space-between">
            <Stack>
              <Typography
                display={'flex'}
                flexDirection={'row'}
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '19px',
                  mt: 2
                }}
              >
                <Typography display={'flex'} flexDirection={'column'} sx={{ my: 'auto' }}>
                  {meal.name
                    ? meal.skipped && (
                        <b
                          className={cx('', {
                            'text-cRed': meal.skipped
                          })}
                        >
                          {' '}
                          SKIPPED
                        </b>
                      )
                    : 'Unkown meal'}
                </Typography>
                {meal.name?.en}{' '}
                {meal.actions?.[0]?.componentId && meal.actions?.[0]?.componentId?.length > 0 && (
                  <Tooltip title="This meal has some removed components" placement="top" arrow>
                    <span>
                      <Icon name="removeIcon" size={6} className="w-12" />
                    </span>
                  </Tooltip>
                )}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 600, mt: '-8px' }}>
                <DeliveryMealAction
                  subscription={subscription}
                  foodComponents={components}
                  meal={meal}
                  menu={menu}
                  menuLoading={menuLoading}
                  mealType={mealType}
                  delivery={delivery}
                  isCustomComponentsLoading={isCustomComponentsLoading}
                  customFoodComponents={customFoodComponents}
                  allCustomFoodComponents={allCustomFoodComponents}
                  caloKidsComponents={caloKidsComponents}
                  setCustomFoodComponents={setCustomFoodComponents}
                  refetchDelivery={refetchDelivery}
                  setFetchMenu={setFetchMenu}
                  setFetchAllComponents={setFetchAllComponents}
                />
              </Typography>
            </Stack>
          </Box>
        }
      />
      <AdditionalMealInfo meal={meal} />
    </Card>
  );
};
export default DeliveriesMealTableRowMobile;
