import { useFormik } from 'formik';
import { KDSUser } from 'lib/interfaces';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { omit } from 'lodash-es';

export default (user: KDSUser, onSubmit: (values: Omit<KDSUser, 'id'>) => any) =>
  useFormik<Omit<KDSUser, 'id'>>({
    initialValues: {
      ...(omit(user, ['id', 'createdAt', 'updatedAt']) as KDSUser)
    },
    validate: (values: Omit<KDSUser, 'id'>) => {
      const errors: any = {};

      if (!values.name) {
        errors.name = true;
      }
      if (!values.country) {
        errors.country = true;
      }
      if (!values.kitchen) {
        errors.kitchen = true;
      }

      const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);
      if (!phoneNumber || (phoneNumber && !phoneNumber.isValid())) {
        errors.phoneNumber = true;
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
