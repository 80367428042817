import { useState } from 'react';

import { useMutation } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material';

import { MealSizePreferences, Subscription } from '@calo/types';
import { updateSubscription } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import { useUserRoles } from 'lib/hooks';
import { capitalize, cloneDeep } from 'lodash-es';

interface MealSizePreferenceCardProps {
  subscription: Subscription;
}
const MealSizePreferenceCard = ({ subscription }: MealSizePreferenceCardProps) => {
  const roles = useUserRoles();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [mealSizePref, setMealSizePref] = useState(subscription.mealSizePreferences);
  const { mutateAsync: updateMutation } = useMutation(updateSubscription);

  const onSubmit = async () => {
    await updateMutation({
      id: subscription.id,
      mealSizePreferences: mealSizePref
    });
  };

  const handleEditing = (edit: boolean) => {
    if (!edit) {
      onSubmit();
    }
    setIsEditing(edit);
  };

  const handleReset = () => {
    setMealSizePref(subscription.mealSizePreferences);
    setIsEditing(false);
  };

  const updateSubsctiptionMealSizePref = (id: number, value: unknown) => {
    const updatedPref = cloneDeep(mealSizePref);
    const newUpdatedPrefrence = (updatedPref ?? []).map((pref) => {
      if (pref.id === id) {
        pref.mealSizePreference = value as MealSizePreferences;
      }
      return pref;
    });
    setMealSizePref(newUpdatedPrefrence);
  };

  return (
    <>
      <Box
        sx={{
          margin: 2,
          padding: '12px',
          backgroundColor: caloTheme.palette.neutral50,
          borderRadius: 2,
          display: 'flex',
          direction: 'row',
          width: 'full',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '19px',
            lineHeight: '23px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Meal size Prefrences
        </Typography>
        {isEditing ? (
          <Stack sx={{ justifyContent: 'end', flexDirection: 'row' }}>
            <CheckIcon sx={{ marginRight: 2 }} style={{ cursor: 'pointer' }} onClick={() => handleEditing(!isEditing)} />
            <ClearIcon style={{ cursor: 'pointer' }} onClick={handleReset} />
          </Stack>
        ) : (
          <Icon
            onClick={() => handleEditing(!isEditing)}
            name="edit2"
            size={6}
            style={{ cursor: 'pointer', width: '26px', height: '26px' }}
          />
        )}
      </Box>
      <Stack
        sx={{
          display: 'flex',
          direction: 'row',
          width: 'full',
          justifyContent: 'space-between',
          padding: '12px',
          paddingTop: '0px',
          gap: '12px'
        }}
      >
        {mealSizePref &&
          mealSizePref.map((mealSizePref) => (
            <Box key={mealSizePref.id}>
              <Typography>{capitalize(mealSizePref.foodDietType)}</Typography>
              <TextField
                select
                value={mealSizePref.mealSizePreference}
                name={`mealSizePrefrence-${mealSizePref.mealSizePreference}`}
                id={`subscriptio-mealSizePrefrence-${mealSizePref.mealSizePreference}`}
                placeholder={'Select meal size prefrence'}
                SelectProps={{
                  onChange: (e) => updateSubsctiptionMealSizePref(mealSizePref.id, e.target.value)
                }}
                disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
                InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
                style={{ width: '100%' }}
              >
                {Object.values(MealSizePreferences).map((item) => (
                  <MenuItem value={item}>{capitalize(item.toLocaleLowerCase())}</MenuItem>
                ))}
              </TextField>
            </Box>
          ))}
      </Stack>
    </>
  );
};

export default MealSizePreferenceCard;
