import { Subscription } from '@calo/types';
import { Icon } from 'components';
import { uniq } from 'lodash';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

interface ImportSubscribersProps {
  isDisabled: boolean;
  selected: Subscription[];
  validateSubscribers: (value: Subscription[]) => void;
}

const readCsv = async (buffer) => {
  const raw = Papa.parse(buffer);
  const ids = raw.data.slice(1).map((d) => d[1]);
  return ids;
};

const ImportSubscribers = ({ selected, isDisabled, validateSubscribers }: ImportSubscribersProps) => {
  const accept = ['.csv'];
  const { getRootProps, getInputProps } = useDropzone({ accept });
  const readExcel = (file: any) => {
    const fileReader = new FileReader();
    let ids = [];
    fileReader.readAsText(file);
    fileReader.addEventListener('load', async () => {
      const buffer = fileReader.result! as any;
      try {
        ids = await readCsv(buffer);
      } catch {
        toast('Error while reading file', { type: 'error', autoClose: 2000 });
      }
      const allIds = ids && uniq([...selected.map((r) => r.id), ...ids].map((r) => ({ id: r })));
      if (allIds.length >= 1000) {
        toast('Limit is 1000 subscribers', { type: 'error', autoClose: 2000 });
      } else {
        allIds.length > 0 && validateSubscribers(allIds as any);
      }
    });
  };

  return (
    <div className="file " {...getRootProps()}>
      <div className="file-label">
        <input
          className="file-input"
          disabled={isDisabled}
          {...getInputProps()}
          onChange={(e) => {
            const file = e.target.files && e.target.files[0];
            readExcel(file);
          }}
        />
        <span className="flex mt-1">
          <Icon name="importFile" size={8} />
          <p className="text-green-500 text-lg">Import</p>
        </span>
      </div>
    </div>
  );
};
export default ImportSubscribers;
