import { useEffect, useState } from 'react';

import { FormikBag, FormikProps, withFormik } from 'formik';
import BigNumber from 'bignumber.js';

import { Refund, Wallet } from '@calo/dashboard-types';
import { Currency, RefundReason } from '@calo/types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, createTheme, MenuItem, Stack, Switch, TextField, ThemeProvider, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { refundReasonsHelper } from 'lib/helpers/refundReasonsHelper';
import PricingService from 'services/PricingService';

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          '&.Mui-checked': {
            color: caloTheme.palette.white
          }
        },
        track: {
          backgroundColor: caloTheme.palette.neutral400,
          '.Mui-checked.Mui-checked + &': {
            opacity: 0.7,
            backgroundColor: caloTheme.palette.primary500
          }
        }
      }
    }
  }
});

type FormValues = Refund & { fullRefund?: boolean };

interface RefundFormProps {
  wallet: Wallet;
  onSubmit: (values: FormValues) => any;
  max: number;
  currency: Currency;
}

const refundReasons = refundReasonsHelper.getOptions();

const refundSubReasons = refundReasonsHelper.getSubReasonsOptions();

const getNetAmount = (grossAmount: number, currency: Currency) => {
  const data = new BigNumber(grossAmount);
  switch (currency) {
    case Currency.BHD:
      return data.dividedBy(1.1).toNumber();
    case Currency.SAR:
      return data.dividedBy(1.15).toNumber();
    case Currency.AED:
      return data.dividedBy(1.05).toNumber();
    case Currency.KWD:
    case Currency.QAR:
    case Currency.GBP:
      return grossAmount;
    case Currency.OMR:
      return data.dividedBy(1.05).toNumber();
    default:
      return data.dividedBy(1.1).toNumber();
  }
};

const NewWalletForm = ({
  wallet,
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSubmit,
  max,
  isSubmitting
}: RefundFormProps & FormikProps<FormValues>) => {
  const [isNoteOpen, setNoteIsOpen] = useState<boolean>(false);
  const [isDescriptionOpen, setDescriptionIsOpen] = useState<boolean>(false);

  const setNoteOnChange = (data: string) => {
    if (data === 'Other') {
      setNoteIsOpen(true);
    } else {
      setNoteIsOpen(false);
    }
    setFieldValue('note', data);
  };

  const setDescriptionOnChange = (data: any) => {
    if (data === 'Other') {
      setDescriptionIsOpen(true);
    } else {
      setDescriptionIsOpen(false);
    }
    setFieldValue('description', data);
  };

  // Listen for changes in fullRefund checkbox
  useEffect(() => {
    if (values.fullRefund) {
      const trxAmount = wallet.paidAmount || wallet.amount;
      if (trxAmount) {
        //remove VAT from the amount
        const amountVatExcl = getNetAmount(trxAmount, wallet.currency);
        setFieldValue('amount', amountVatExcl);
      }
    }
  }, [values.fullRefund]);

  return (
    <Box component="form" autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', mt: 2, width: '401px', mx: 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <TextField
          key={values.fullRefund ? 'fullRefund' : 'amount'}
          type="number"
          name="amount"
          label="Amount (EXCL. VAT)"
          onBlur={handleBlur}
          value={values.amount}
          onChange={handleChange}
          placeholder="Enter Amount"
          disabled={values.fullRefund}
          sx={{
            width: '70%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              mx: 1
            }
          }}
          id="exact-subscription-amount"
          InputProps={{
            inputProps: { style: { borderRadius: 8 }, min: 0.001, max: max, step: 0.001 },
            style: { borderRadius: 8, width: '100%' }
          }}
        />

        <Stack sx={{ width: '30%', alignItems: 'end' }}>
          <ThemeProvider theme={theme}>
            <Typography
              sx={{
                textAlign: 'left',
                color: caloTheme.palette.neutral900,
                fontSize: '12px',
                lineHeight: '14px',
                width: 'auto',
                mb: '4px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600
              }}
            >
              Full Refund
            </Typography>
            <Switch onChange={() => setFieldValue('fullRefund', !values.fullRefund)} checked={values.fullRefund} />
          </ThemeProvider>
        </Stack>
      </Box>

      {errors.amount && <div className="text-red-500 my-1 text-sm">{errors.amount}</div>}

      <TextField
        select
        label="Internal"
        name="note"
        sx={{
          width: '100%',
          mr: 2,
          my: 1,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            marginTop: 2,
            width: '75%',
            mx: 1
          }
        }}
        value={values.note}
        id="exact-subscription-refund-note"
        onChange={(data: any) => setNoteOnChange(data.target.value)}
        InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
      >
        {refundReasons.map((internal) => (
          <MenuItem key={internal.value} value={internal.value}>
            {internal.label}
          </MenuItem>
        ))}
      </TextField>

      {isNoteOpen && (
        <TextField
          multiline
          rows={2}
          name="InternalNote"
          label="Internal Note"
          sx={{
            mb: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              marginTop: 2,
              width: '75%',
              mx: 1
            }
          }}
          onChange={(e) => setFieldValue('description', e.target.value)}
          onBlur={handleBlur}
          aria-label="note area"
          placeholder="Add Note"
        />
      )}
      {errors.note && <div className="text-red-500 my-1 text-sm">{errors.note}</div>}

      <TextField
        select
        label="User Reason"
        name="userReason"
        value={values.description}
        sx={{
          width: '100%',
          mr: 2,
          my: 1,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            marginTop: 2,
            width: '75%',
            mx: 1
          }
        }}
        id="exact-subscription-refund-description"
        onChange={(data: any) => setDescriptionOnChange(data.target.value)}
        InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
      >
        {(refundSubReasons[values.note as RefundReason] || [{ value: '', label: 'Select' }]).map((p) => (
          <MenuItem key={p.value} value={p.value}>
            {p.label}
          </MenuItem>
        ))}
      </TextField>

      {isDescriptionOpen && (
        <TextField
          multiline
          rows={2}
          name="descriptionNote"
          label="Description Note"
          sx={{
            mb: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              marginTop: 2,
              width: '75%',
              mx: 1
            }
          }}
          onChange={(e) => setFieldValue('description', e.target.value)}
          onBlur={handleBlur}
          aria-label="note area"
          placeholder="Add Note"
        />
      )}
      {errors.description && <div className="text-red-500 my-1 text-sm">{errors.description}</div>}

      <LoadingButton
        variant="contained"
        aria-label="Refund"
        sx={{
          width: 'auto',
          height: '45px',
          lineHeight: '17px',
          fontWeight: 600,
          fontSize: '14px',
          borderRadius: '8px',
          padding: '14px 20px 14px 20px',
          backgroundColor: caloTheme.palette.primary500,
          borderColor: caloTheme.palette.primary500,
          color: 'white',
          '&:hover': {
            color: 'white',
            backgroundColor: caloTheme.palette.primary600,
            borderColor: caloTheme.palette.primary600
          },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: '70%',
            marginTop: 2,
            mx: 2,
            justifyContent: 'center'
          }
        }}
        loading={isSubmitting}
        disabled={
          !values.amount ||
          (PricingService.roundAmount(values.amount, values.currency) > max && !values.fullRefund) ||
          isSubmitting
        }
        onClick={() => handleSubmit()}
      >
        Refund
      </LoadingButton>
    </Box>
  );
};

export default withFormik({
  // mapPropsToValues: () => ({
  // }),
  validate: (values: FormValues, props: RefundFormProps) => {
    const errors: any = {};

    if (PricingService.roundAmount(values.amount, values.currency) > props.max && !values.fullRefund) {
      errors.amount = `Refund amount should not be greater than (${props.max} ${values.currency})`;
    }
    if (!values.amount) {
      errors.amount = 'Amount Feild should not be blank';
    }
    if (!values.description) {
      errors.description = 'User reason Feild should not be blank';
    }
    if (!values.note) {
      errors.note = 'Internal Feild should not be blank';
    }
    return errors;
  },
  handleSubmit: async (values, { setSubmitting, props, resetForm }: FormikBag<RefundFormProps, FormValues>) => {
    setSubmitting(true);
    try {
      await props.onSubmit(values);
      resetForm();
    } finally {
      setSubmitting(false);
    }
  }
})(NewWalletForm);
