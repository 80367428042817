import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { getImageUploadLink } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { ImageUploader, ModalRef } from 'components';
import Popup from 'components/Popup';
import { useRef, useState } from 'react';
import ImagePopup from './ImagePopup';

interface AttacmentViewPopupProps {
  viewType: string;
  selectedComplaint: any;
  attachmentRef: React.MutableRefObject<ModalRef | undefined>;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setSelectedComplaint: React.Dispatch<any>;
  displayImage: string[];
  urlFile?: string;
}
const AttachmentViewPopup = ({
  displayImage,
  attachmentRef,
  viewType,
  setSelectedComplaint,
  selectedComplaint,
  urlFile = 'complaints',
  handleFileChange
}: AttacmentViewPopupProps) => {
  const viewImageRef = useRef<ModalRef>();
  const [imageUrlLink, setImageUrlLink] = useState<string | undefined>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const id = urlFile === 'complaints' ? selectedComplaint?.id.split('#')[1] : selectedComplaint?.deliveryId;
  const handleEnlargeImage = (image: string, imageIndex: number) => {
    setImageUrlLink(image);
    setCurrentIndex(imageIndex);
    viewImageRef.current?.open();
  };

  return (
    <>
      <Popup
        maxWidth="lg"
        fullWidth
        title="Attachment"
        ref={attachmentRef}
        onClose={() => attachmentRef.current?.close()}
        subTitle={
          <>
            <Stack display={'flex'} flexDirection={'row'} justifyContent={'end'}>
              <div className="file-label">
                <input
                  accept="image/*"
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                  className="file-input cursor-pointer fas fa-pen"
                />
              </div>
            </Stack>
          </>
        }
      >
        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} key={selectedComplaint?.id}>
          <Grid container spacing={5}>
            {displayImage?.map((image: string, index) => (
              <Grid item key={image}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'start'}>
                      <Stack
                        key={image}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'start'}
                        onClick={() => handleEnlargeImage(image, index)}
                      >
                        <ImageUploader
                          width={120}
                          maxHeight={44}
                          disabled={false}
                          key={selectedComplaint?.id}
                          uploadLink={() => getImageUploadLink(`${urlFile}/${id}/${image}`, ' ')}
                          image={`${process.env.REACT_APP_BUCKET_URL}/${urlFile}/${id}/${image}`}
                        />
                      </Stack>
                      <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ width: '100%' }}>
                        <Typography sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}>
                          Attachment {index + 1}
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Stack
          sx={{
            justifyContent: 'center',
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
            marginTop: 2
          }}
        >
          <Button
            variant="text"
            sx={{
              width: '156px',
              color: caloTheme.palette.primary500,
              fontFamily: 'Roboto',
              textAlign: 'center',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '23px',
              textTransform: 'capitalize'
            }}
            onClick={() => {
              attachmentRef.current?.close();
              setSelectedComplaint(undefined);
            }}
          >
            Done
          </Button>
        </Stack>
      </Popup>
      <ImagePopup
        viewType={viewType}
        urlFile={urlFile}
        imageUrl={imageUrlLink}
        mainRef={viewImageRef}
        displayImage={selectedComplaint?.attachments || []}
        selectedComplaintId={id}
        setImageUrlLink={setImageUrlLink}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </>
  );
};
export default AttachmentViewPopup;
