export const styles = {
  card: {
    marginTop: '16px',
    border: 'none',
    borderRadius: '16px',
    px: '18px',
    py: '18px'
  },
  title: {
    fontSize: '19px',
    fontWeight: 600,
    marginBottom: '10px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 2
  },
  textField: {
    my: 1,
    width: '100%'
  },
  textFieldContainer: {
    width: '100%'
  },
  inputLabel: {
    mb: 1,
    fontSize: '12px',
    fontWeight: 400,
    ml: 1,
    mt: '-4px'
  },
  ingredientChip: {
    color: 'red',
    backgroundColor: 'lightpink',
    fontSize: '14px',
    mb: '15px'
  },
  inputProps: {
    style: { borderRadius: 8 }
  },
  inputStyle: {
    borderRadius: 8
  },
  ingredientChips: {
    fontWeight: 600
  }
};
