import { useEffect, useState } from 'react';

import { format, parseISO } from 'date-fns/fp';

import { Brand, Country } from '@calo/types';

import { ModalRef, Pagination } from 'components';
import { ShowDataModal } from '../GiftModal';

export interface GiftHistoryListProps {
  giftHistory: any[] | undefined;
  page: number;
  total: number;
  setPage: (value: number) => void;
  showDataModalRef: React.MutableRefObject<ModalRef | undefined>;
}

const GiftHistoryList = ({ page, total, setPage, giftHistory, showDataModalRef }: GiftHistoryListProps) => {
  const [dataType, setDataType] = useState<string>('');
  const [giftID, setGiftID] = useState<string>('');
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [selectedDataRecord, setSelectedDataRecord] = useState({
    selectedDate: '',
    selectedCountry: Country.BH,
    selectedBrand: Brand.CALO,
    selectedMeals: [],
    selectedAddons: [],
    giftID: '',
    dataType: ''
  });

  useEffect(() => {
    if (selectedDataRecord.giftID.length > 0) {
      showDataModalRef.current?.open();
    } else {
      showDataModalRef.current?.close();
    }
  }, [selectedDataRecord]);

  return (
    <>
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <p className="uppercase font-roboto text-3xl">Gifting History</p>
          </div>
        </div>
      </div>
      <div className="card has-table has-table-container-upper-radius mt-3">
        <div className="card-content">
          <div className="table-container overflow-y-auto">
            <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
              <thead>
                <tr className="bg-black">
                  <th style={{ color: 'white' }} className=" w-48">
                    Subscribers
                  </th>
                  <th style={{ color: 'white' }} className=" w-48">
                    Meals
                  </th>
                  <th style={{ color: 'white' }} className=" w-48">
                    Addons
                  </th>
                  <th style={{ color: 'white' }} className=" w-48">
                    Date
                  </th>
                  <th style={{ color: 'white' }} className=" w-48">
                    Cost (per subscriber)
                  </th>
                  <th style={{ color: 'white' }} className=" w-48">
                    Total Cost
                  </th>
                </tr>
              </thead>
              <tbody>
                {giftHistory &&
                  giftHistory.map((giftedRec: any) => (
                    <tr key={giftedRec.id}>
                      <td>
                        <span className="flex flex-row">
                          {`🧍 * ${giftedRec.stats.count}`}
                          <span className="ml-2 cursor-pointer w-32">
                            <p
                              className="border-none text-blue-500"
                              onClick={() => {
                                setDataType('subscribers');
                                setSelectedDataRecord({
                                  selectedDate: giftedRec.date,
                                  selectedCountry: giftedRec.country,
                                  selectedBrand: giftedRec.brand,
                                  selectedMeals: giftedRec.food,
                                  selectedAddons: giftedRec.food,
                                  giftID: giftedRec.id,
                                  dataType: 'subscribers'
                                });
                                setGiftID(giftedRec.id);
                                setTotalRecords(giftedRec.stats.count);
                              }}
                            >
                              See More
                            </p>
                          </span>
                        </span>
                      </td>
                      <td>
                        <span className="flex flex-row">
                          {`🌮 * ${giftedRec.food?.length ? giftedRec.food.length : '0'}`}
                          <span className="ml-2 cursor-pointer w-32">
                            <p
                              className="border-none text-blue-500"
                              onClick={() => {
                                setDataType('meals');
                                setSelectedDataRecord({
                                  selectedDate: giftedRec.date,
                                  selectedCountry: giftedRec.country,
                                  selectedBrand: giftedRec.brand,
                                  selectedMeals: giftedRec.food,
                                  selectedAddons: giftedRec.food,
                                  giftID: giftedRec.id,
                                  dataType: 'meals'
                                });
                                setGiftID(giftedRec.id);
                              }}
                            >
                              See More
                            </p>
                          </span>
                        </span>
                      </td>
                      <td>
                        <span className="flex flex-row">
                          <span>{`🌮 * ${giftedRec.addons ? giftedRec.addons.length : '0'}`}</span>
                          <span className="ml-2 cursor-pointer">
                            <p
                              className="border-none text-blue-500"
                              onClick={() => {
                                setDataType('addons');
                                setSelectedDataRecord({
                                  selectedDate: giftedRec.date,
                                  selectedCountry: giftedRec.country,
                                  selectedBrand: giftedRec.brand,
                                  selectedMeals: giftedRec.food,
                                  selectedAddons: giftedRec.addons,
                                  giftID: giftedRec.id,
                                  dataType: 'addons'
                                });
                              }}
                            >
                              See More
                            </p>
                          </span>
                        </span>
                      </td>
                      <td>{format('dd/MM/yyyy')(parseISO(giftedRec.date))}</td>
                      <td>{giftedRec.cost ? giftedRec.cost.toFixed(3) : '--'}</td>
                      <td>{giftedRec.cost ? (giftedRec.cost * giftedRec.stats.count).toFixed(3) : '--'}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination limit={10} total={total} page={page} onChange={setPage} />
      </div>
      <ShowDataModal
        giftID={giftID}
        dataType={dataType}
        listType={'released'}
        setGiftID={setGiftID}
        totalRecords={totalRecords}
        showDataModalRef={showDataModalRef}
        selectedDate={selectedDataRecord.selectedDate}
        meals={selectedDataRecord.selectedMeals || []}
        addons={selectedDataRecord.selectedAddons || []}
        selectedCountryBrand={{
          brand: selectedDataRecord.selectedBrand!,
          country: selectedDataRecord.selectedCountry!
        }}
      />
    </>
  );
};
export default GiftHistoryList;
