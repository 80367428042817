import { Permission } from '@calo/dashboard-types';
import { DeliveryTime, WaitingListType } from '@calo/types';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { multiPolygon, point } from '@turf/helpers';
import { allowWaiting, bulkAllow, bulkInvite, getRecord, updateKitchen } from 'actions';
import mutation from 'actions/mutation';
import markerPinBlack from 'assets/images/markerPinBlack.png';
import markerPinGreen from 'assets/images/markerPinGreen.png';
import cx from 'classnames';
import { Button, ConfirmationModal, ModalRef } from 'components';
import SingleDrawingManager from 'components/SingleDrawingManager';
import ExcelJS, { Column, Style } from 'exceljs';
import { saveAs } from 'file-saver';
import { getCenteredCountry } from 'lib';
import client from 'lib/client';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import { Kitchen } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import { orderBy } from 'lodash-es';
import { Suspense, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import PolygonContext from '../../../DeliveryPlanner/MapView/PolygonContext';
import PolygonManager from '../../../DeliveryPlanner/MapView/PolygonManager';
import polygonReducer from '../../../DeliveryPlanner/MapView/polygonReducer';

const border: Partial<Style> = {
  border: {
    top: { style: 'thin', color: { argb: 'D3D3D3' } },
    left: { style: 'thin', color: { argb: 'D3D3D3' } },
    bottom: { style: 'thin', color: { argb: 'D3D3D3' } },
    right: { style: 'thin', color: { argb: 'D3D3D3' } }
  }
};

const columns: Array<Partial<Column>> = [
  { header: 'Name', width: 25, key: 'name', style: border },
  { header: 'Email', width: 25, key: 'email', style: border },
  { header: 'Phone Number', width: 15, key: 'phoneNumber', style: border },
  { header: 'Country', width: 10, key: 'country', style: border },
  { header: 'Location', width: 30, key: 'location', style: border },
  { header: 'Kitchen', width: 10, key: 'kitchen', style: border }
];

const WaitingListMap = () => {
  const roles = useUserRoles();
  const { mutateAsync: updateMutation } = useMutation(updateKitchen);
  const { mutateAsync: allowMutation } = useMutation(allowWaiting);
  const { mutateAsync: bulkInviteMutation } = useMutation(bulkInvite);
  const { mutateAsync: bulkAllowMutation } = useMutation(bulkAllow);

  const scroll = useRef<HTMLDivElement>(null);
  const confirmModalRef = useRef<ModalRef>();
  const [sideBar, SetSideBar] = useState(true);
  const [deliveryMapToggle, setDeliveryMapToggle] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>();

  const [hoverPin, setHoverPin] = useState<any>();
  const [center, setCenter] = useState<any>();
  const location = useLocation() as any;
  const [polygonState, dispatch] = useReducer(polygonReducer, { polygons: [] });
  const [selectedDelivery, setSelectedDelivery] = useState<any | null>();

  const { id, waitingListType, isAllowed } = useParams<{ id: any; waitingListType: WaitingListType; isAllowed?: string }>();

  const { data: kitchenData } = useQuery(['kitchens', id], getRecord, {
    suspense: true
  });
  const kitchen = kitchenData as Kitchen;
  const exportedFileName = waitingListType === WaitingListType.PRE_LAUNCH ? 'pre-launch users' : 'supply-cap users';

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery<{ data: any[]; meta: any }>(
    'waiting-list',
    async ({ pageParam, queryKey }) => {
      const { data } = await client.get(queryKey[0] as string, {
        params: {
          ...(pageParam && {
            cursor: pageParam
          }),
          ...(waitingListType === WaitingListType.SUPPLY_CAP && isAllowed && { filters: { isAllowed } }),
          kitchenId: id,
          displayType: 'list',
          type: waitingListType,
          limit: 10000
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => data.meta?.cursor
    }
  );

  const waitingList = useMemo(
    () =>
      (data?.pages || []).reduce<any[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [data]
  );

  const [mpoly, setMpoly] = useState<any>(undefined);
  const [polygonSt, setPolygonSt] = useState<any>({ polygon: undefined });

  const getSortedPinList = () => orderBy(waitingList, ['position']);

  const getSortedList = () => {
    if (waitingListType === WaitingListType.PRE_LAUNCH && polygonSt.polygon?.getPath()) {
      return orderBy(waitingList, ['position']).filter(
        (r) => r.location && booleanPointInPolygon(point([r.location.lng, r.location.lat]), mpoly) && r
      );
    } else if (waitingListType === WaitingListType.PRE_LAUNCH && polygonState.selectedPolygon?.polygon) {
      const re = multiPolygon([
        [
          polygonState.selectedPolygon.polygon
            .getPath()
            ?.getArray()
            .map((r) => [r.lng(), r.lat()]),
          [
            [
              polygonState.selectedPolygon.polygon.getPath()?.getArray()[0].lng(),
              polygonState.selectedPolygon.polygon.getPath()?.getArray()[0].lat()
            ]
          ]
        ]
      ]);
      return orderBy(waitingList, ['position']).filter(
        (r) => r.location && booleanPointInPolygon(point([+r.location.lng, +r.location.lat]), re) && r
      );
    } else if (
      waitingListType === WaitingListType.SUPPLY_CAP &&
      isAllowed &&
      isAllowed === 'false' &&
      polygonSt.polygon?.getPath()
    ) {
      return orderBy(waitingList, ['position']).filter(
        (r) => r.location && booleanPointInPolygon(point([r.location.lng, r.location.lat]), mpoly) && r
      );
    } else if (
      waitingListType === WaitingListType.SUPPLY_CAP &&
      isAllowed &&
      isAllowed === 'false' &&
      polygonState.selectedPolygon?.polygon
    ) {
      const re = multiPolygon([
        [
          polygonState.selectedPolygon.polygon
            .getPath()
            ?.getArray()
            .map((r) => [r.lng(), r.lat()]),
          [
            [
              polygonState.selectedPolygon.polygon.getPath()?.getArray()[0].lng(),
              polygonState.selectedPolygon.polygon.getPath()?.getArray()[0].lat()
            ]
          ]
        ]
      ]);
      return orderBy(waitingList, ['position']).filter(
        (r) => r.location && booleanPointInPolygon(point([+r.location.lng, +r.location.lat]), re) && r
      );
    } else {
      return orderBy(waitingList, ['position']);
    }
  };

  useEffect(() => {
    if (location.state && location?.state.user) {
      setSelectedAddress(location.state?.user);
      const state = { ...location.state };
      delete state.user;
      history.replace(location.pathname);
    }
  }, []);

  const pin = useMemo(() => {
    if (hoverPin) {
      return hoverPin;
    } else if (selectedAddress) {
      return selectedAddress;
    } else {
      return null;
    }
  }, [hoverPin, selectedAddress]);

  const scrolltoS = (i: number) => {
    scroll.current?.scrollTo({
      top: i * 75,
      left: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    setCenter(getCenteredCountry(kitchen.country));
  }, [kitchen.id]);

  const onExport = useCallback(async () => {
    if (!getSortedList()) {
      return;
    }
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet(exportedFileName, {
      pageSetup: { fitToPage: true, orientation: 'portrait' }
    });
    worksheet.mergeCells('A1', 'F1');
    worksheet.getRow(2).values = ['Name', 'Email', 'Phone Number', 'Country', 'Location', 'Kitchen'];
    worksheet.columns = columns;
    worksheet.getCell('A1').value = `Selected users ${getSortedList().length}`;
    worksheet.getCell('A1').font = { color: { argb: '0000' }, bold: true, size: 18 };
    worksheet.getCell('A1').alignment = {
      vertical: 'middle',
      horizontal: 'center'
    };
    for (const row of getSortedList()) {
      worksheet.addRow(
        {
          name: row.name,
          email: row.email,
          phoneNumber: row.phoneNumber,
          country: row.country,
          location: row.location,
          kitchen: row.kitchen
        },
        ''
      );
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, exportedFileName + fileExtension);
  }, [getSortedList()]);

  const handleBulkInvite = async () => {
    const selectedUsersIDs = getSortedList().map((r) => r.id);
    if (selectedUsersIDs.length >= 500) {
      selectedUsersIDs.splice(500);
    }
    await bulkInviteMutation(
      { ids: selectedUsersIDs },
      {
        onSuccess: (data) => {
          const queryData = queryClient.getQueryData('waiting-list') as any;
          const result = queryData.pages[0].data.filter((r: any) => !data.ids.includes(r.id));
          const filteredQuery = queryClient.setQueryData('waiting-list', {
            ...queryData,
            pages: [{ data: result, meta: queryData.pages[0].meta }]
          });
          mutation('waiting-list', filteredQuery);
        }
      }
    );
  };

  const handleBulkAllow = async () => {
    const selectedUsersIDs = getSortedList().map((r) => r.id);
    if (selectedUsersIDs.length >= 500) {
      selectedUsersIDs.splice(500);
    }
    await bulkAllowMutation(
      { ids: selectedUsersIDs },
      {
        onSuccess: (data) => {
          const queryData = queryClient.getQueryData('waiting-list') as any;
          const result = queryData.pages[0].data.filter((r: any) => !data.ids.includes(r.id));
          const filteredQuery = queryClient.setQueryData('waiting-list', {
            ...queryData,
            pages: [{ data: result, meta: queryData.pages[0].meta }]
          });
          mutation('waiting-list', filteredQuery);
        }
      }
    );
  };

  const handleBulkAction = async () => {
    if (waitingListType === WaitingListType.PRE_LAUNCH) {
      await handleBulkInvite();
    } else if (waitingListType === WaitingListType.SUPPLY_CAP && isAllowed && isAllowed === 'false') {
      await handleBulkAllow();
    }
  };

  return (
    <>
      <div style={{ height: '100vh', width: '100%' }}>
        <InfiniteScroll
          dataLength={waitingList.length || 0}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={null}
          scrollableTarget="scrollable"
        >
          <div
            className={cx('absolute bg-gray-900 rounded-xl', {
              'w-0 hidden': !sideBar,
              'w-96 z-50': sideBar
            })}
          >
            <div className="card has-table has-table-container-upper-radius">
              <div className="card-content">
                <div ref={scroll} className=" flex table-container overflow-y-auto" style={{ height: '93vh' }}>
                  <table className="table is-fullwidth is-sortable">
                    <thead>
                      <tr className="bg-black sticky top-0 z-100">
                        <th></th>
                        <th style={{ color: 'white' }}>Info</th>
                      </tr>
                    </thead>
                    {getSortedList().length > 0 ? (
                      <tbody>
                        {getSortedList()?.map((user) => (
                          <tr
                            key={user.id}
                            className={cx('h-24 cursor-pointer', {
                              'bg-gray-200': selectedAddress?.id === user.id || hoverPin?.id === user.id,
                              'hover:bg-gray-300': selectedAddress?.id !== user.id
                            })}
                            onMouseEnter={() => setHoverPin(user)}
                            onMouseLeave={() => setHoverPin(null)}
                            onClick={() => setSelectedAddress(user)}
                          >
                            <td>
                              <span className="flex flex-col">
                                <div
                                  className={cx('flex border px-2 text-xs text-black rounded-xl w-24 mt-3 -mb-1 ', {
                                    'bg-blue-100': user.type === WaitingListType.SOFT_LAUNCH,
                                    'bg-yellow-100': user.type === WaitingListType.PRE_LAUNCH,
                                    [`bg-${isAllowed === 'true' ? 'green' : 'red'}-100`]: user.type === WaitingListType.SUPPLY_CAP
                                  })}
                                >
                                  {user.type}
                                </div>
                              </span>
                            </td>
                            <td>
                              <span className="flex flex-col">
                                <p>{user.name}</p>
                                {kitchen.id === 'NA000' && <p>{user.country}</p>}
                                <p>{user.phoneNumber}</p>
                                <p>{user.email}</p>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td></td>
                          <td>
                            <p className="text-center ">No selected users</p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                    {!!hasNextPage && <Button onClick={() => fetchNextPage()} content="Load more" loading={isFetchingNextPage} />}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </InfiniteScroll>
        <GoogleMap
          mapContainerClassName="w-full h-full relative border-none focus:none z-10"
          center={center}
          zoom={kitchen.id === 'NA000' ? 2 : 12}
          options={{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
          }}
        >
          <div
            className={cx(' absolute top-6 z-50', {
              ' w-96 ml-9': sideBar
            })}
          >
            <a
              className={cx('navbar-item', {
                'float-right': sideBar
              })}
              onClick={() => SetSideBar(!sideBar)}
            >
              <i className="fas fa-bars"></i>
            </a>
          </div>
          {(waitingListType === WaitingListType.PRE_LAUNCH ||
            (waitingListType === WaitingListType.SUPPLY_CAP && isAllowed && isAllowed === 'false')) && (
            <div className="absolute z-50 p-2 bg-black mr- 4 top-8 right-16 rounded-lg">
              <span>
                {(polygonSt.polygon?.getPath() || polygonState.selectedPolygon) && !!getSortedList().length && (
                  <Button onClick={onExport} icon="fas fa-file-export" className="py-2 float-right w-4 h-6 mx-1 mt-1" />
                )}
                {((waitingListType === WaitingListType.PRE_LAUNCH &&
                  roles.includes(Permission.BATCH_REMOVE_FROM_PRE_LAUNCH_WAITING_LIST)) ||
                  (waitingListType === WaitingListType.SUPPLY_CAP &&
                    isAllowed &&
                    isAllowed === 'false' &&
                    roles.includes('a:bawl'))) &&
                  (polygonSt.polygon?.getPath() || polygonState.selectedPolygon) &&
                  !!getSortedList().length && (
                    <Button
                      onClick={() => confirmModalRef.current?.open()}
                      content={waitingListType === WaitingListType.PRE_LAUNCH ? 'Invite' : 'Allow'}
                      primary
                      className="py-2 float-right w-auto h-6 mx-1  mt-1 rounded-md"
                    />
                  )}
                {(waitingListType === WaitingListType.PRE_LAUNCH ||
                  (waitingListType === WaitingListType.SUPPLY_CAP && isAllowed && isAllowed === 'false')) && (
                  <p className="float-left mr-2 mt-1 text-white">Count {getSortedList().length}</p>
                )}
                <label
                  className={cx(' -mb-2.5 cursor-pointer inline-block w-12 p-1 rounded-full', {
                    'toggle-left bg-green-500': deliveryMapToggle,
                    'toggle-right bg-red-500': !deliveryMapToggle
                  })}
                >
                  <input
                    type="checkbox"
                    className="hidden"
                    checked={deliveryMapToggle}
                    onChange={() => setDeliveryMapToggle(!deliveryMapToggle)}
                  />
                  <div
                    className={cx('h-4 w-4 rounded-full bg-white transition-all right-0', {
                      'ml-5': deliveryMapToggle
                    })}
                  ></div>
                </label>
              </span>
            </div>
          )}

          {getSortedPinList().map((user, index) => (
            <Marker
              key={user.id}
              position={{
                lat: +user.location?.lat,
                lng: +user.location?.lng
              }}
              onMouseOver={() => {
                setHoverPin(user);
                scrolltoS(index);
              }}
              onMouseOut={() => setHoverPin(null)}
              onClick={() => {
                setSelectedAddress(user);
                scrolltoS(index);
              }}
              icon={pin && user.id === pin.id ? markerPinGreen : markerPinBlack}
            >
              {pin && pin.location && pin.id === user.id && (
                <InfoWindow
                  position={{
                    lat: +pin.location?.lat,
                    lng: +pin.location?.lng
                  }}
                  onCloseClick={() => setSelectedAddress(null)}
                >
                  <div className="card">
                    <header className="card-header">
                      <div className="card-header-icon">
                        <span className="flex flex-col">
                          <p className="font-bold text-black mb-2">{pin.name}</p>
                          <p className="font-bold text-black mb-2">{pin.type}</p>
                          <p className="font-bold text-black mb-2">{pin.phoneNumber}</p>
                          <p className="font-bold text-black mb-2">{pin.email}</p>
                          {waitingListType === WaitingListType.SUPPLY_CAP && user.isAllowed === false && (
                            <Button
                              content="Allow"
                              className="text-white bg-yellow-300 rounded hover:text-white hover:shadow-md focus:text-white border-yellow-300 hover:border-yellow-300"
                              onClick={() => allowMutation(user.id)}
                            />
                          )}
                          {kitchen.id === 'NA000' && <p className="font-bold text-black mb-2">{pin.country}</p>}
                        </span>
                      </div>
                    </header>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}
          {(waitingListType === WaitingListType.PRE_LAUNCH ||
            (waitingListType === WaitingListType.SUPPLY_CAP && isAllowed && isAllowed === 'false')) &&
            !deliveryMapToggle && (
              <SingleDrawingManager
                setMpoly={setMpoly}
                isEditing={isEditing}
                bounds={kitchen.bounds}
                setPolygonSt={setPolygonSt}
                setIsEditing={setIsEditing}
                handleUpdate={async (data) => updateMutation({ id: kitchen.id, bounds: data })}
              />
            )}
          {(waitingListType === WaitingListType.PRE_LAUNCH ||
            (waitingListType === WaitingListType.SUPPLY_CAP && isAllowed && isAllowed === 'false')) &&
            deliveryMapToggle && (
              <>
                <PolygonContext.Provider value={dispatch}>
                  <Suspense fallback={null}>
                    <PolygonManager
                      closePopUp={() => null}
                      day={'default'}
                      isAreaView={deliveryMapToggle}
                      time={DeliveryTime.morning}
                      polygonState={polygonState}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      country={kitchen.country}
                      kitchen={kitchen.id}
                      deliveryPlannerMap={deliveryMapToggle}
                    />
                  </Suspense>
                </PolygonContext.Provider>
                {selectedDelivery && (
                  <InfoWindow
                    position={{
                      lat: selectedDelivery.deliveryAddress.lat,
                      lng: selectedDelivery.deliveryAddress.lng
                    }}
                    onCloseClick={() => setSelectedDelivery(null)}
                  ></InfoWindow>
                )}
                <Suspense fallback={null}></Suspense>
              </>
            )}
        </GoogleMap>
        <ConfirmationModal ref={confirmModalRef} values={getSortedList()!} action={handleBulkAction}>
          <div className="mt-4 mb-4 ml-4">
            <span className="flex flex-col">
              <p className="label">
                {waitingListType === WaitingListType.PRE_LAUNCH ? (
                  <p>CAUTION: MAKE SURE THE SELECTED USERS ARE WITHIN AVAILABLE DELIVERY ZONES BEFORE CONFIRMING.</p>
                ) : null}
              </p>
              <p>
                Are you sure want to {waitingListType === WaitingListType.PRE_LAUNCH ? 'invite' : 'allow'} the selected{' '}
                {`${getSortedList().length}`} users ?
              </p>
              {waitingListType === WaitingListType.PRE_LAUNCH && (
                <>
                  <p>This will : </p>
                  <li className="text-sm">Export a CSV of users' information to your email</li>
                  <li className="text-sm">Remove them permanently from the pre-launch list</li>
                  <li className="text-sm">
                    Send an SMS to their phone numbers that{' '}
                    {waitingListType === WaitingListType.PRE_LAUNCH
                      ? 'they we can deliver to their areas now'
                      : 'they can complete the registration process and start using the app'}
                  </li>
                </>
              )}
              {getSortedList().length > 500 && (
                <p className="text-red-300 text-xs">
                  {' '}
                  You can only {waitingListType === WaitingListType.PRE_LAUNCH ? 'invite' : 'allow'} {getSortedList().length} at a
                  time, if the selected area covers more users, this will{' '}
                  {waitingListType === WaitingListType.PRE_LAUNCH ? 'invite' : 'allow'} 500 random users within the selected area{' '}
                </p>
              )}
            </span>
          </div>
        </ConfirmationModal>
      </div>
    </>
  );
};
export default WaitingListMap;
