import { useMemo } from 'react';

import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns/fp';
import { EmployeePerks, PerksFilters } from 'lib/interfaces';
import { isEmpty } from 'lodash';
import { orderBy } from 'lodash-es';

import { Box, Card, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import PackageElementRow from './EmployeePerksRow';

interface EmployeePerksInfoListCardProps {
  isLoading: boolean;
  employeePerks: EmployeePerks;
  filters: PerksFilters;
  setEmployeePerks: (employeePerks: EmployeePerks) => void;
}

const EmployeePerksInfoListCard = ({ isLoading, employeePerks, filters, setEmployeePerks }: EmployeePerksInfoListCardProps) => {
  const monthName = useMemo(() => format('MMMM')(new Date(`${filters.year}-${filters.month}-01`)), [filters.year, filters.month]);
  const today = new Date();
  const lastDayOfThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  lastDayOfThisMonth.setDate(lastDayOfThisMonth.getDate() - 1);

  return (
    <Card
      variant="outlined"
      sx={{
        border: 'none',
        padding: 2,
        paddingBottom: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '16px',
        gap: '8px'
      }}
    >
      <Box overflow="auto" width="100%" sx={{ py: 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                lineHeight: '24px'
              }}
            >
              Perks
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                lineHeight: '14px'
              }}
            >
              (Next Credit Addition on {format('dd/MM/yyyy')(lastDayOfThisMonth)})
            </Typography>
          </Stack>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '20px'
            }}
          >
            {filters.country} - {monthName} - {filters.year}
          </Typography>
        </Stack>
      </Box>
      <TableContainer>
        <Table sx={{ width: '100%' }}>
          <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, border: 0 }}>
            <TableRow>
              <TableCell
                sx={{
                  borderRadius: '8px 0 0 8px',
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  minWidth: '30%'
                }}
              >
                Employee
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  width: '18%'
                }}
              >
                Amount
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  width: '18%'
                }}
              >
                Currency
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  width: '18%'
                }}
              >
                Used Balance
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  width: '11%'
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  borderRadius: '0 8px 8px 0',
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  width: '5%'
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderBy(employeePerks.employees, (employee) => employee.name.toLowerCase(), 'asc')?.map((employee) => (
              <PackageElementRow
                key={employee.id}
                employee={employee}
                filters={filters}
                setEmployeePerks={setEmployeePerks}
                status={employeePerks.status}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && isEmpty(employeePerks.employees) && <Typography sx={{ textAlign: 'center' }}>No Perks Found</Typography>}
    </Card>
  );
};

export default EmployeePerksInfoListCard;
