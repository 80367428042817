import { Box, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { capitalize } from 'lodash-es';

interface MacrosChipProps {
  macrosKey: string;
  macrosValue: number;
}

const MacrosChip = ({ macrosKey, macrosValue }: MacrosChipProps) => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        color:
          macrosKey === 'cal'
            ? caloTheme.palette.primary500
            : macrosKey === 'fat'
              ? caloTheme.palette.secondaryYellow500
              : macrosKey === 'carbs'
                ? caloTheme.palette.secondaryPurple500
                : caloTheme.palette.secondaryBlue500,
        backgroundColor:
          macrosKey === 'cal'
            ? caloTheme.palette.primary100
            : macrosKey === 'fat'
              ? caloTheme.palette.secondaryYellow100
              : macrosKey === 'carbs'
                ? caloTheme.palette.secondaryPurple100
                : caloTheme.palette.secondaryBlue100,

        borderRadius: '8px',
        padding: '4px',
        width: '110px'
      }}
    >
      <Typography sx={{ fontSize: '14px', fontFamily: 600 }}>
        {`${macrosKey === 'cal' ? 'Calories' : capitalize(macrosKey)}: ${macrosValue} ${macrosKey === 'cal' ? '' : 'g'}`}
      </Typography>
    </Box>
  );
};

export default MacrosChip;
