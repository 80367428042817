import React, { useRef, useState } from 'react';

import { parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import DatePicker from 'react-date-picker';

import { Subscription } from '@calo/types';

import { Button, ConfirmationModal, Modal, ModalRef } from 'components';
import { MenuFood } from 'lib/interfaces';

interface AddDateModalProps {
  selectedDate: string | undefined;
  selectedMeals: MenuFood[] | undefined;
  selectedSubscribers: Subscription[] | undefined;
  setSelectedDate: (v: string | undefined) => void;
  setSelectedMeals: (v: MenuFood[] | undefined) => void;
  setSelectedAddons: (v: any[] | undefined) => void;
  addDateModalRef: React.MutableRefObject<ModalRef | undefined>;
  setSelectedSubscribers: (v: Subscription[] | undefined) => void;
}
const AddDateModal = ({
  selectedSubscribers,
  addDateModalRef,
  selectedDate,
  setSelectedDate,
  setSelectedSubscribers,
  selectedMeals,
  setSelectedMeals,
  setSelectedAddons
}: AddDateModalProps) => {
  const confirmModalRef = useRef<ModalRef>();
  const [chosenDate, setChosenDate] = useState<string>(selectedDate || '');

  const handleSetDate = () => {
    if (selectedSubscribers || selectedMeals) {
      confirmModalRef.current?.open();
    } else {
      setSelectedDate(chosenDate);
      addDateModalRef.current?.close();
    }
  };

  const handleConfirm = () => {
    setSelectedSubscribers(undefined);
    setSelectedMeals(undefined);
    setSelectedAddons(undefined);
    setSelectedDate(chosenDate);
    addDateModalRef.current?.close();
  };
  return (
    <Modal
      ref={addDateModalRef}
      onClose={() => {
        addDateModalRef.current?.close();
        setSelectedDate(selectedDate);
      }}
    >
      <div className="section -m-10">
        <section className="section is-title-bar -my-8 ">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <p className="uppercase font-roboto text-2xl">Choose Date</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div>
            <DatePicker
              className="flex w-64 mx-auto my-8"
              onChange={(date: any) => setChosenDate(format('yyyy-MM-dd')(date))}
              value={chosenDate ? parseISO(chosenDate) : undefined}
              clearIcon={null}
            />
          </div>
        </section>
        <section className="flex justify-center">
          <Button primary content="Set Date" disabled={!chosenDate} onClick={() => handleSetDate()} />
        </section>
      </div>
      <ConfirmationModal ref={confirmModalRef} values={selectedSubscribers || selectedMeals} action={handleConfirm}>
        <div className="mt-4 mb-4 ml-4">
          <span className="flex">
            <label className="label">Changing Date will remove all selected subscribers and meals</label>
          </span>
        </div>
      </ConfirmationModal>
    </Modal>
  );
};
export default AddDateModal;
