import { Kitchen } from '@calo/types';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { getKitchenShortCut } from 'lib/helpers';

interface SuccessBoxProps {
  componentName: string;
  kitchens: Kitchen[];
  handleClosePopup: () => void;
}

const SuccessBox = ({ componentName, kitchens, handleClosePopup }: SuccessBoxProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: '10px' }}>
        <Typography variant="h5" sx={{ fontSize: '28px', fontWeight: 600 }}>
          Component Status
        </Typography>
        <IconButton onClick={handleClosePopup}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Typography sx={{ textAlign: 'center' }}>
        The component " {componentName} " has been synced to{' '}
        {kitchens.map((kitchen) => `"${getKitchenShortCut(kitchen)}"`).join(' and ')}, successfully.
      </Typography>
      <Button
        variant="outlined"
        onClick={handleClosePopup}
        sx={{
          textTransform: 'none',
          color: 'white',
          fontWeight: 600,
          borderRadius: '8px',
          width: '20%',
          fontSize: '20px',
          alignSelf: 'center',
          marginTop: '36px',
          backgroundColor: caloTheme.palette.primary500,
          '&:hover': {
            color: 'white',
            backgroundColor: caloTheme.palette.primary500
          },
          '&:disabled': {
            color: 'white',
            backgroundColor: caloTheme.palette.primary300
          }
        }}
      >
        Okay
      </Button>
    </Box>
  );
};

export default SuccessBox;
