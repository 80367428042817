import ReactLoading from 'react-loading';

import { Icon } from '../';

const CaloLoader = () => (
  <div className="mt-8 w-full h-454 mx-auto">
    <p className="flex mx-auto w-1/2">
      <Icon name="calo" size={14} className="w-full -mb-4" />
    </p>
    <p className="flex justify-center -mr-16 -mb-2">
      <ReactLoading type="bubbles" color="#57AE7F" width={100} height={75} />
    </p>
  </div>
);

export default CaloLoader;
