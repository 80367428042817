import cx from 'classnames';
import { range } from 'lodash-es';

interface PaginationProps {
  total: number;
  limit: number;
  page: number;
  isHidden?: boolean;
  onChange: (page: number) => void;
  notification?: boolean;
}

const getPager = (page: number, totalPages: number) => {
  // default page size is 10
  let startPage: number, endPage: number;
  if (totalPages === 0) {
    startPage = 0;
    endPage = 0;
  } else if (totalPages <= 10) {
    // less than 10 total pages so show all
    startPage = 0;
    endPage = totalPages;
  } else {
    // more than 10 total pages so calculate start and end pages
    if (page <= 6) {
      startPage = 0;
      endPage = 10;
    } else if (page + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = page - 5;
      endPage = page + 4;
    }
  }

  // calculate start and end item indexes

  // create an array of pages to ng-repeat in the pager control

  // return object with all pager properties required by the view
  return range(startPage, endPage);
};

const Pagination = ({ total, limit, page, onChange, isHidden, notification }: PaginationProps) => {
  const totalPages = Math.ceil(total / limit) || 1;
  return (
    <div
      className={cx('z-10', {
        notification: !notification,
        '': notification
      })}
      hidden={isHidden}
    >
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <div className="buttons has-addons">
              <button className="button" disabled={page === 0} onClick={() => onChange(0)}>
                First
              </button>
              <button className="button" disabled={page === 0} onClick={() => onChange(page - 1)}>
                <i className="fas fa-chevron-left"></i>
              </button>

              {getPager(page, totalPages).map((i) => (
                <button
                  key={i}
                  className={cx('button', {
                    'is-active': i === page
                  })}
                  onClick={() => onChange(i)}
                >
                  {i + 1}
                </button>
              ))}
              <button className="button" disabled={page + 1 === totalPages} onClick={() => onChange(page + 1)}>
                <i className="fas fa-chevron-right"></i>
              </button>
              <button className="button" disabled={page + 1 === totalPages} onClick={() => onChange(totalPages - 1)}>
                Last
              </button>
            </div>
          </div>
        </div>
        {!notification && (
          <div className="level-right">
            <div className="level-item">
              <span className="has-text-grey" data-test={'totalRows'}>
                Total: {total}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pagination;
