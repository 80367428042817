import { CreateUserGroupReq, UpdateUserGroupReq } from '@calo/dashboard-types';
import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import queryClient from 'lib/queryClient';
import mutation from './mutation';

export const createUserPermission = async (values: CreateUserGroupReq) => {
  const { data, status } = await client.post('user-permissions', values);
  mutation('user-permissions', data);
  ToastSuccessMsgs({ status, action: 'new group created' });
  return data;
};

export const updateUserPermissions = async (payload: UpdateUserGroupReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`user-permissions/${id}`, rest);
  mutation('user-permissions', data);
  ToastSuccessMsgs({ status, action: `group ${data.groupName} updated` });
  return data;
};

export const deleteUserPermissions = async (id: string) => {
  const { data: _data, status } = await client.delete(`user-permissions/${id}`);
  ToastSuccessMsgs({ status, action: 'group deleted' });
  const query = queryClient.getQueryData('user-permissions') as any;
  const index = query.data.findIndex((i: any) => i.id === id);
  query.data.splice(index, 1);
};
