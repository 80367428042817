import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  IconButton: {
    borderRadius: '50%',
    padding: 4
  },
  table: {
    marginY: 2,
    minHeight: '110px',
    tableLayout: 'fixed',
    width: '100%',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  tableRow: {
    marginTop: 1,
    cursor: 'pointer',
    borderRadius: '12px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: caloTheme.palette.neutral50
    }
  },
  styledTableRow: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px'
  },
  paginationBox: { display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 1 }
};

export default styles;
