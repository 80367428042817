/* eslint-disable @typescript-eslint/no-unused-vars */
import { AddressService } from '@calo/services';
import { AddressType, DeliveryAddress, DeliveryPreferences, NewDeliveryAddress } from '@calo/types';
import { Box, FormControl, Grid, MenuItem, Select, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { useEffect } from 'react';
type DeliveryLocationPicker = {
  isEditing: boolean;
  isApplyForAllDays: boolean;
  deliveryAddresses: DeliveryAddress[];
  deliveryDay?: string;
  selectedDeliveryPreferences: DeliveryPreferences;
  changeDeliveryAddress: (isApplyForAllDays: boolean, addressId: string, day?: string) => void;
};

export const DeliveryLocationPicker = ({
  isEditing,
  deliveryAddresses,
  deliveryDay,
  selectedDeliveryPreferences,
  changeDeliveryAddress,
  isApplyForAllDays = false
}: DeliveryLocationPicker) => {
  const getSelectedDeliveryAddress = (
    isApplyForAllDays: boolean,
    selectedDeliveryPreferences: DeliveryPreferences,
    deliveryAddresses: DeliveryAddress[],
    deliveryDay?: string
  ) => {
    const deliveryAddressId = isApplyForAllDays
      ? selectedDeliveryPreferences[0]?.deliveryAddressId
      : selectedDeliveryPreferences[deliveryDay as keyof DeliveryPreferences]?.deliveryAddressId;
    const deliveryAddress = deliveryAddresses.find((deliveryAddress) => deliveryAddress.id === deliveryAddressId);
    return deliveryAddress;
  };

  let selectedDeliveryAddress: DeliveryAddress | undefined = getSelectedDeliveryAddress(
    isApplyForAllDays,
    selectedDeliveryPreferences,
    deliveryAddresses,
    deliveryDay
  );
  let selectedDeliveryAddressId: string | undefined = selectedDeliveryAddress?.id;

  useEffect(() => {
    selectedDeliveryAddress = getSelectedDeliveryAddress(
      isApplyForAllDays,
      selectedDeliveryPreferences,
      deliveryAddresses,
      deliveryDay
    );
    selectedDeliveryAddressId = selectedDeliveryAddress?.id;
  }, [selectedDeliveryPreferences, isApplyForAllDays]);

  return (
    <Grid item xs={8}>
      {isEditing ? (
        <FormControl sx={{ width: '100%' }}>
          <Select
            value={selectedDeliveryAddress?.id}
            onChange={(e) => {
              changeDeliveryAddress(isApplyForAllDays, e.target.value ?? '', deliveryDay);
            }}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label', sx: { padding: '7px 16px' } }}
          >
            {deliveryAddresses?.map((data, index) => (
              <MenuItem
                style={{ marginTop: index === 0 ? '0px' : '10px' }}
                value={data.id}
                selected={data.id === selectedDeliveryAddress?.id}
              >
                <Stack flexDirection={'column'}>
                  <Typography sx={{ fontWeight: '600' }}>{data?.type === AddressType.home ? '🏠 Home' : '💼 Office'}</Typography>
                  <Typography
                    sx={{ fontSize: '12px', color: caloTheme.palette.neutral700, overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {AddressService.display(data)}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Box flexDirection={'column'} sx={{ padding: '7px 16px' }}>
          <Typography sx={{ fontWeight: '600' }}>
            {selectedDeliveryAddress?.type === AddressType.home ? '🏠 Home' : '💼 Office'}
          </Typography>
          <Typography sx={{ fontSize: '12px', color: caloTheme.palette.neutral700 }}>
            {selectedDeliveryAddress ? AddressService.display(selectedDeliveryAddress as NewDeliveryAddress) : 'Not Selected'}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};
