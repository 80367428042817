import { CouponRuleType, CreateCouponReq, UpdateCouponReq } from '@calo/dashboard-types';
import { Country, SubscriptionTier, UserAction } from '@calo/types';
import { Button, Input, Modal, Select } from 'components';
import { useEffect } from 'react';

interface AddrulesModalProps {
  addRulesRef: any;
  rules: any;
  setRules: (value: any) => void;
  setFieldValue: any;
  values: CreateCouponReq | UpdateCouponReq;
  setActionRules: (value: boolean) => void;
}

const AddRulesModal = ({ addRulesRef, rules, setRules, setFieldValue, setActionRules, values }: AddrulesModalProps) => {
  const createRules = () => {
    if (rules.value && rules.type) {
      setFieldValue('rules', [rules, ...(values.rules && values.rules)]);
      addRulesRef.current?.close();
      setRules({ type: undefined!, value: undefined! });
    }
  };

  const typesFiltered = Object.values(values.rules).map((t) => t.type);

  const filteredTypes = [
    CouponRuleType.USAGE_LIMIT,
    CouponRuleType.USAGE_PER_USER,
    CouponRuleType.USER_ACTION,
    CouponRuleType.USER_COUNTRY,
    CouponRuleType.SUBSCRIPTION_TIER
  ].filter((type) => !typesFiltered.includes(type));

  const allValues = Object.values(values.rules).map((t) => t.value);
  const filteredAction = Object.values([
    UserAction.CREATE_SUBSCRIPTION,
    UserAction.RESUME_SUBSCRIPTION,
    UserAction.PURCHASE_ADDON_SUBSCRIPTION,
    UserAction.RENEW_ADDON_SUBSCRIPTION,
    UserAction.CHANGE_PLAN
  ]).filter((val) => !allValues.includes(val));

  if (!filteredTypes.includes(CouponRuleType.USER_ACTION) && filteredAction.length > 0) {
    filteredTypes.push(CouponRuleType.USER_ACTION);
  }

  useEffect(() => {
    if (typesFiltered.includes(CouponRuleType.USER_ACTION)) {
      setActionRules(true);
    } else {
      setActionRules(false);
    }
  }, [typesFiltered]);

  return (
    <div>
      <Modal ref={addRulesRef}>
        <div className="flex flex-col">
          <Select
            label="Rule Type"
            value={rules.type}
            name="rules.type"
            onChange={(data: any) => setRules({ ...rules, type: data.value })}
            options={Object.values(filteredTypes).map((t) => ({
              value: t,
              label: t
            }))}
          />
          {rules.type === CouponRuleType.USER_ACTION ? (
            <Select
              label="Rule value"
              value={rules.value}
              name="rules.value"
              onChange={(data: any) => setRules({ ...rules, value: data.value })}
              options={Object.values(filteredAction).map((t) => ({
                value: t,
                label: t
              }))}
            />
          ) : rules.type === CouponRuleType.USER_COUNTRY ? (
            <Select
              label="Rule value"
              value={rules.value}
              name="rules.value"
              onChange={(data: any) => setRules({ ...rules, value: data.value })}
              options={Object.values(Country).map((t) => ({
                value: t,
                label: t
              }))}
            />
          ) : rules.type === CouponRuleType.SUBSCRIPTION_TIER ? (
            <Select
              label="Rule value"
              value={rules.value}
              name="rules.value"
              isMulti={true}
              onChange={(data: any) => {
                console.log(data);
                setRules({ ...rules, value: data.map((e: any) => e.value) });
              }}
              options={Object.values(SubscriptionTier).map((t) => ({
                value: t,
                label: t
              }))}
            />
          ) : (
            <Input
              label="Number of use"
              value={rules.value}
              name="number"
              type="number"
              onChange={(e) => setRules({ ...rules, value: +e.target.value })}
            />
          )}
          <Button warning content="Confirm" onClick={() => createRules()} />
        </div>
      </Modal>
    </div>
  );
};

export default AddRulesModal;
