import { useEffect, useState } from 'react';

import { Permission } from '@calo/dashboard-types';
import {
  DeliveryTime,
  DietType,
  FoodType,
  Kitchen,
  PlanType,
  Subscription,
  SubscriptionFrequency,
  SubscriptionTier
} from '@calo/types';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { isEqual, startCase } from 'lodash-es';
import { useMutation } from 'react-query';

import { updateSubscription2, updateSubscriptionPlan } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import IOSSwitch from 'components/MUI/IOSSwitch';
import { useUserRoles } from 'lib/hooks';
import { validateDeliveryPreferences } from '../../DeliveriesCard/DeliveriesMeals/DeliveriesMealTable/shared/DeliveryMealAction/SwapMealPopUp/helpers';
import usePlanForm from '../usePlanForm';
import SelectCutlery from './SelectCutlery';

interface SubscriptionPlanDetailsProps {
  subscription: Subscription & { remainingDays: number; expectedLastDeliveryDay: string; numberOfCutlery?: number };
}

const mapKeyedToFoodType = (values: any) =>
  Object.keys(values).reduce((result: FoodType[], ft) => {
    const length = values[ft as FoodType];
    if (length > 0) {
      result = [...result, ...new Array(length).fill(ft)];
    }
    return result;
  }, []);
const SubscriptionPlanDetails = ({ subscription }: SubscriptionPlanDetailsProps) => {
  const roles = useUserRoles();
  const { mutateAsync: updatePlanMutation } = useMutation(updateSubscriptionPlan);
  const { mutateAsync: updateSubscriptionMutation } = useMutation(updateSubscription2);

  const onSubmit = async ({
    id,
    foodType,
    withCutlery,
    withCoolerBag,
    deliveryTime,
    numberOfCutlery,
    deliveryDays,
    dietType,
    frequency,
    tier
  }: {
    id: PlanType;
    foodType?: { [index in FoodType]: number };
    withCutlery?: boolean;
    withCoolerBag?: boolean;
    deliveryTime?: DeliveryTime;
    numberOfCutlery?: number;
    deliveryDays: number[];
    dietType?: DietType;
    frequency?: SubscriptionFrequency;
    tier?: SubscriptionTier;
  }): Promise<void> => {
    if (
      !isEqual(deliveryDays, subscription.deliveryDays) ||
      !isEqual(deliveryTime, subscription.deliveryTime) ||
      !isEqual(numberOfCutlery, subscription.numberOfCutlery) ||
      !isEqual(withCutlery, subscription.withCutlery) ||
      !isEqual(withCoolerBag, subscription.withCoolerBag) ||
      !isEqual(deliveryDays, subscription.deliveryDays)
    ) {
      await updateSubscriptionMutation({
        data: {
          ...(!isEqual(deliveryTime, subscription.deliveryTime) && { deliveryTime }),
          ...(!isEqual(numberOfCutlery, subscription.numberOfCutlery) && { numberOfCutlery }),
          ...(!isEqual(deliveryDays, subscription.deliveryDays) && { deliveryDays }),
          ...(!isEqual(withCutlery, subscription.withCutlery) && { withCutlery }),
          ...(!isEqual(withCoolerBag, subscription.withCoolerBag) && { withCoolerBag })
        },
        subId: subscription.id
      });
    }

    if (
      !isEqual(id, subscription.plan.id) ||
      !isEqual(mapKeyedToFoodType(foodType), subscription.plan.foodType) ||
      !isEqual(dietType, subscription.plan.dietType) ||
      !isEqual(frequency, subscription.plan.frequency) ||
      !isEqual(tier, subscription.tier)
    ) {
      await updatePlanMutation({
        id: subscription.id,
        planType: id,
        dietType: dietType,
        frequency: frequency,
        tier: tier,
        ...(id === PlanType.custom && {
          foodType: mapKeyedToFoodType(foodType)
        })
      });
    }
  };

  const { values, setValues, isSubmitting, setFieldValue, initialValues } = usePlanForm(subscription, onSubmit);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const handleEditing = (edit: boolean) => {
    if (!edit) {
      onSubmit(values);
    }
    setIsEditing(edit);
  };

  useEffect(() => {
    if (values.id === PlanType.custom && !values.foodType) {
      setValues({
        ...values,
        foodType: {
          breakfast: 1,
          dinner: 1,
          lunch: 1,
          snack: 1,
          dessert: 0,
          caloKids: 0,
          coffee: 0,
          juice: 0,
          salad: 0
        }
      });
    }
  }, [values.id]);

  const handleReset = () => {
    setValues(initialValues);
    setIsEditing(false);
  };
  return (
    <>
      <Box
        sx={{
          margin: 2,
          padding: 2,
          backgroundColor: caloTheme.palette.neutral50,
          borderRadius: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'auto',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column'
          }
        }}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '19px',
            lineHeight: '23px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Subscription Details
        </Typography>
        {isEditing ? (
          <Stack sx={{ justifyContent: 'end', flexDirection: 'row' }}>
            <CheckIcon sx={{ marginRight: 2 }} style={{ cursor: 'pointer' }} onClick={() => handleEditing(!isEditing)} />
            <ClearIcon style={{ cursor: 'pointer' }} onClick={handleReset} />
          </Stack>
        ) : (
          <Icon
            onClick={() => handleEditing(!isEditing)}
            name="edit2"
            size={6}
            style={{ cursor: 'pointer', width: '26px', height: '26px' }}
          />
        )}
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          mx: 1,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column',
            width: 'full'
          }
        }}
      >
        <FormControl
          sx={{
            m: 1,
            minWidth: '18%',
            display: 'flex',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'full'
            }
          }}
        >
          <InputLabel id="exact-subscription-package">Package</InputLabel>
          <Select
            label="Package"
            value={values.id}
            sx={{
              borderRadius: '8px',
              width: 'auto'
            }}
            onChange={(data: any) => setFieldValue('id', data.target.value)}
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || isSubmitting || !isEditing}
          >
            {Object.values(PlanType).map((p) => (
              <MenuItem value={p}>{startCase(p)}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            m: 1,
            minWidth: '18%',
            display: 'flex',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'full'
            }
          }}
        >
          <InputLabel id="exact-subscription-dietType">Diet</InputLabel>
          <Select
            label="Diet"
            value={values.dietType}
            labelId="exact-subscription-dietType"
            sx={{
              borderRadius: '8px'
            }}
            onChange={(data: any) => setFieldValue('dietType', data.target.value)}
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || isSubmitting || !isEditing}
          >
            {Object.values(DietType).map((dietType) => (
              <MenuItem value={dietType}>{startCase(dietType)}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            m: 1,
            minWidth: '18%',
            display: 'flex',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'full'
            }
          }}
        >
          <InputLabel id="exact-subscription-frequency">Frequency</InputLabel>
          <Select
            labelId="exact-subscription-frequency"
            value={values.frequency}
            label="frequency"
            sx={{ borderRadius: '8px' }}
            onChange={(data: any) => setFieldValue('frequency', data.target.value)}
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || isSubmitting || !isEditing}
          >
            {Object.values(SubscriptionFrequency).map((frequency) => (
              <MenuItem value={frequency}>{startCase(frequency)}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            m: 1,
            minWidth: '18%',
            display: 'flex',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'full'
            }
          }}
        >
          <InputLabel id="exact-subscription-deliveryTime">Delivery Time</InputLabel>
          <Select
            labelId="exact-subscription-deliveryTime"
            value={values.deliveryTime}
            label="deliveryTime"
            sx={{
              borderRadius: '8px'
            }}
            onChange={(data: any) => setFieldValue('deliveryTime', data.target.value)}
            disabled={
              !validateDeliveryPreferences(subscription.deliveryPreferences) ||
              !roles.includes(Permission.UPDATE_SUBSCRIPTION) ||
              isSubmitting ||
              !isEditing
            }
          >
            {Object.values(DeliveryTime).map((deliveryTime) => (
              <MenuItem value={deliveryTime}>
                {deliveryTime === DeliveryTime.evening
                  ? `${startCase(deliveryTime)} (6-10 PM) `
                  : subscription.kitchen === Kitchen.GB1
                    ? `${startCase(deliveryTime)} (2-6 AM)`
                    : `${startCase(deliveryTime)} (7-11 AM)`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <SelectCutlery isEditing={isEditing} isSubmitting={isSubmitting} setFieldValue={setFieldValue} values={values} />
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1} sx={{ minWidth: '3%' }}>
          <Typography
            sx={{
              color: caloTheme.palette.neutral900,
              fontSize: '12px',
              lineHeight: '14px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600
            }}
          >
            Cooler Bag
          </Typography>
          <IOSSwitch
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
            onChange={() => setFieldValue('withCoolerBag', !values.withCoolerBag)}
            sx={{ color: 'white' }}
            checked={values.withCoolerBag}
          />
        </Stack>
      </Box>
      {values.id === PlanType.custom && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column',
              width: 'full'
            }
          }}
        >
          <Stack display={'flex'} flexDirection={'column'} width={'100%'}>
            <>
              <Typography
                sx={{
                  fontFamily: caloTheme.typography.fontFamily,
                  marginLeft: 2,
                  marginY: 1,
                  fontSize: '16px',
                  lineHeight: '19px',
                  fontWeight: '400px',
                  color: caloTheme.palette.neutral900
                }}
              >
                Custom Plan
              </Typography>
              <Stack
                display={'flex'}
                flexDirection={'row'}
                width={'100%'}
                sx={{
                  marginLeft: 1,
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    flexDirection: 'column',
                    margin: 'auto',
                    marginTop: 2,
                    width: 'full'
                  }
                }}
              >
                <FormControl
                  sx={{
                    m: 1,
                    width: '25%',
                    display: 'flex',
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      width: '95%'
                    }
                  }}
                >
                  <TextField
                    type="number"
                    label="Breakfast"
                    onChange={(data) => setFieldValue('foodType.breakfast', +data.target.value)}
                    disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
                    value={values.foodType![FoodType.breakfast]}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    m: 1,
                    width: '25%',
                    display: 'flex',
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      width: '95%'
                    }
                  }}
                >
                  <TextField
                    type="number"
                    label="Lunch"
                    onChange={(data) => setFieldValue('foodType[lunch]', data.target.value)}
                    disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
                    value={values.foodType![FoodType.lunch]}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    m: 1,
                    width: '25%',
                    display: 'flex',
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      width: '95%'
                    }
                  }}
                >
                  <TextField
                    type="number"
                    label="Dinner"
                    disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
                    onChange={(data) => setFieldValue('foodType[dinner]', +data.target.value)}
                    value={values.foodType![FoodType.dinner]}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    m: 1,
                    width: '25%',
                    display: 'flex',
                    marginRight: 4,
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      width: '95%'
                    }
                  }}
                >
                  <TextField
                    type="number"
                    label="Snack"
                    disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
                    value={values.foodType![FoodType.snack]}
                    onChange={(data) => setFieldValue('foodType[snack]', +data.target.value)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    m: 1,
                    width: '25%',
                    display: 'flex',
                    marginRight: 4,
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      width: '95%'
                    }
                  }}
                >
                  <TextField
                    type="number"
                    label="Dessert"
                    disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
                    value={values.foodType![FoodType.dessert]}
                    onChange={(data) => setFieldValue('foodType[dessert]', +data.target.value)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    m: 1,
                    width: '25%',
                    display: 'flex',
                    marginRight: 4,
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      width: '95%'
                    }
                  }}
                >
                  <TextField
                    type="number"
                    label="Calo Kids"
                    disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
                    value={values.foodType![FoodType.caloKids]}
                    onChange={(data) => setFieldValue('foodType[caloKids]', +data.target.value)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
              </Stack>
            </>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default SubscriptionPlanDetails;
