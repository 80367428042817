import { caloTheme } from 'assets/images/theme/calo';
import { theme } from 'lib/componentStyles';
import { useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  ThemeProvider,
  Typography
} from '@mui/material';

import styles from './styles';

interface FilterCheckState {
  subscriptions: boolean;
  businesses: boolean;
  charity: boolean;
}

interface TableStatsHeaderSectionProps {
  title: string;
  filtersCheck: FilterCheckState;
  setFiltersCheck: React.Dispatch<React.SetStateAction<FilterCheckState>>;
  switchUnit?: boolean;
  setSwitchUnit?: React.Dispatch<React.SetStateAction<boolean>>;
  showToggleSwitch?: boolean;
  showDetailedViewSwitch?: boolean;
  showDetailedView?: boolean;
  setShowDetailedView?: React.Dispatch<React.SetStateAction<boolean>>;
}

const TableStatsHeaderSection = ({
  filtersCheck,
  setFiltersCheck,
  title,
  setSwitchUnit,
  switchUnit,
  showToggleSwitch,
  showDetailedViewSwitch,
  showDetailedView,
  setShowDetailedView
}: TableStatsHeaderSectionProps) => {
  const [requestTypeFiltersMenuAnchorEl, setRequestTypeFiltersMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isRequestTypeFilterMenuOpened = Boolean(requestTypeFiltersMenuAnchorEl);
  const activeFiltersCount = Object.values(filtersCheck).filter(Boolean).length;

  const handleCheckboxChange = (filterName: keyof FilterCheckState) => {
    setFiltersCheck((prevState) => ({ ...prevState, [filterName]: !prevState[filterName] }));
  };

  return (
    <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ paddingRight: 2 }}>
      <Typography variant="h6" sx={styles.tableTitle}>
        {title}
      </Typography>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
        {showToggleSwitch && (
          <ThemeProvider theme={theme}>
            <Box display={'flex'} flexDirection={'row'}>
              <Typography
                sx={{
                  color: caloTheme.palette.neutral900,
                  fontSize: '12px',
                  lineHeight: '14px',
                  mx: '6px',
                  mt: '12px',
                  fontFamily: 'Roboto',
                  fontWeight: 600
                }}
              >
                {switchUnit ? 'All Units' : 'gram/ml'}
              </Typography>
              <Switch
                onChange={() => {
                  setSwitchUnit?.(!switchUnit);
                }}
                sx={{ color: 'white' }}
                checked={switchUnit}
              />
            </Box>
          </ThemeProvider>
        )}
        {(showToggleSwitch || showDetailedViewSwitch) && (
          <ThemeProvider theme={theme}>
            <Box display={'flex'} flexDirection={'row'}>
              <Typography
                sx={{
                  color: caloTheme.palette.neutral900,
                  fontSize: '12px',
                  lineHeight: '14px',
                  mx: '6px',
                  mt: '12px',
                  fontFamily: 'Roboto',
                  fontWeight: 600
                }}
              >
                {showDetailedView ? 'Detailed' : 'Total'}
              </Typography>
              <Switch
                onChange={() => {
                  setShowDetailedView?.(!showDetailedView);
                }}
                sx={{ color: 'white' }}
                checked={showDetailedView}
              />
            </Box>
          </ThemeProvider>
        )}
        <Button
          onClick={(event) => {
            !requestTypeFiltersMenuAnchorEl && setRequestTypeFiltersMenuAnchorEl(event.currentTarget);
          }}
          variant="outlined"
          startIcon={
            <>
              <Typography style={{ textAlign: 'start' }} sx={styles.text}>
                Types
              </Typography>
              <Typography style={{ fontSize: '12px', margin: 'auto' }} sx={styles.selectorNumber}>
                {activeFiltersCount}
              </Typography>
            </>
          }
          endIcon={<ArrowDropDownIcon />}
          sx={styles.filterTypeButton}
        >
          <Select
            id="filter-select"
            open={isRequestTypeFilterMenuOpened}
            onClose={() => setRequestTypeFiltersMenuAnchorEl(null)}
            sx={{ visibility: 'hidden', width: 0, height: 0 }}
          >
            {Object.entries(filtersCheck).map(([key, value]) => (
              <MenuItem key={key} sx={{ fontWeight: 600 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      onChange={() => handleCheckboxChange(key as keyof FilterCheckState)}
                      disabled={value && activeFiltersCount === 1}
                    />
                  }
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                />
              </MenuItem>
            ))}
          </Select>
        </Button>
      </Box>
    </Stack>
  );
};

export default TableStatsHeaderSection;
