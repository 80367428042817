import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  mainBox: {
    paddingY: 2,
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  updateButton: {
    display: 'flex',
    height: '44px',
    fontWeight: 600,
    lineHeight: '23px',
    fontSize: '14px',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    boxShadow: 'none',
    color: caloTheme.palette.white,
    backgroundColor: caloTheme.palette.primary500,
    '&:hover': {
      boxShadow: 'none',
      color: caloTheme.palette.white,
      backgroundColor: caloTheme.palette.primary600
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      display: 'flex',
      justifyItems: 'center',
      m: 'auto',
      mb: 2,
      mt: '-4px',
      width: 'auto'
    }
  },
  attachButton: {
    display: 'flex',
    height: '44px',
    fontWeight: 600,
    lineHeight: '23px',
    fontSize: '14px',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    boxShadow: 'none',
    mr: 2,
    color: caloTheme.palette.black,
    borderColor: caloTheme.palette.black,
    '&:hover': {
      boxShadow: 'none',
      color: caloTheme.palette.black,
      borderColor: caloTheme.palette.black
    }
  },
  chip: {
    color: caloTheme.palette.primary700,
    backgroundColor: caloTheme.palette.primary100,
    padding: '8px 6px 8px 6px',
    borderWidth: 0,
    mr: 1,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    textTransform: 'capitalize'
  }
};

export default styles;
