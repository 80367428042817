import { FoodTagsKeys } from '@calo/dashboard-types';
import { Brand, FoodDietType } from '@calo/types';
import FoodDietTypeTags from 'components/FoodDietTypeTags';
import { sortBy } from 'lodash';
interface AddonsProps {
  addons: any;
}

const AddonsSubscriptionRow = ({ addons }: AddonsProps) => {
  const sortedList = sortBy(addons.food, 'priority');

  return (
    <div className="card has-table has-table-container-upper-radius ">
      <header className="card-header bg-black w-full">
        <>
          <p className="card-header-title text-white">Addons Subscription menu</p>
          {addons.brand === Brand.MEALO ? (
            <p className="text-bold text-yellow-500 float-right mr-3 my-4 w-12">mealo</p>
          ) : (
            <p className=" text-bold text-green-500 float-right mr-3 my-4 w-12">CALO</p>
          )}
        </>
      </header>
      <div className="table-container">
        <table className="table mt-4 is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>Availability</th>
              <th>Food Type</th>
              <th>Size</th>
              <th>DietType</th>
              <th>Category</th>
            </tr>
          </thead>
          <tbody>
            {sortedList.map((f: any) => (
              <tr key={f.id}>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.name.en}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.startDate || f.endDate ? `${f.startDate || '*'} -> ${f.endDate || '*'}` : 'Always Available'}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.type.join(', ')}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.size}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-2 w-15 flex">
                    {f.tags?.map((r: FoodDietType) => <FoodDietTypeTags key={r} tag={r} fullName />)}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.foodTags?.map((r: any) => (r.key === FoodTagsKeys.addonCategory ? r.value.join(', ') : null))}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddonsSubscriptionRow;
