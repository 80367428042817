import { getListWithParams } from 'actions';
import { addDays, endOfWeek, format as formatDate, startOfWeek } from 'date-fns';
import { format } from 'date-fns/fp';
import { Menu } from 'lib/interfaces';
import { omit } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

const useDuplicateMenu = (weekDates: Date[], filters: any, updateWeeklyMenuList: (newMenuList: Menu[]) => void) => {
  const [duplicateMenuDate, setDuplicateMenuDate] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { refetch: refetchWeeklyMenuList } = useQuery<any, Error, Menu[]>(
    [
      'menu',
      {
        brand: filters.brand,
        kitchen: filters.kitchen || undefined,
        date: {
          gte: duplicateMenuDate ? formatDate(startOfWeek(duplicateMenuDate, { weekStartsOn: 0 }), 'yyyy-MM-dd') : undefined,
          lte: duplicateMenuDate ? formatDate(endOfWeek(duplicateMenuDate, { weekStartsOn: 0 }), 'yyyy-MM-dd') : undefined
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!duplicateMenuDate,
      onSuccess: (data) => {
        let updateMenu: any[] = [];
        for (const [i, menu] of data.entries()) {
          const newDay = format('yyyy-MM-dd')(weekDates[i % 7]);
          const updateDays = {
            ...omit(menu, 'uuid'),
            day: newDay,
            id: newDay
          };
          updateMenu = [...updateMenu, updateDays];
        }
        updateWeeklyMenuList(updateMenu || []);
        setIsLoading(false);
      },
      onError: (e) => {
        console.error('Failed to fetch weekly menu list:', e);
        setIsLoading(false);
      }
    }
  );

  const duplicateMenu = (date: Date) => {
    setIsLoading(true);
    setDuplicateMenuDate(date);
  };

  useEffect(() => {
    if (duplicateMenuDate) {
      refetchWeeklyMenuList();
    }
  }, [duplicateMenuDate, refetchWeeklyMenuList]);

  const duplicateWeeklyMenu = (weekNumber: number, year: number) => {
    const startOfYear = new Date(year, 0, 1);
    const startOfWeekDate = addDays(startOfYear, (weekNumber - 1) * 7);
    const startOfWeekAdjusted = startOfWeek(startOfWeekDate, { weekStartsOn: 0 });
    const weekDates = Array.from({ length: 7 }, (_, i) => addDays(startOfWeekAdjusted, i));
    duplicateMenu(weekDates[0]);
  };

  return { duplicateWeeklyMenu, isLoading, setIsLoading };
};

export default useDuplicateMenu;
