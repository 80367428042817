import { TableBody } from '@mui/material';

import { StatsFoodComponent } from 'lib/interfaces';
import ComponentRow from './ComponentRow';

interface MealsBodyProps {
  component: StatsFoodComponent;
  switchUnit: boolean;
}

export default function MealsBody({ component, switchUnit }: MealsBodyProps) {
  const ingredientComponentStats = Object.values(component.keyedFood);

  return (
    <TableBody>
      {ingredientComponentStats.map((meal) => (
        <ComponentRow key={meal.id} meal={meal} switchUnit={switchUnit} cookedRawFactor={component.cookedRawFactor || 1} />
      ))}
    </TableBody>
  );
}
