import { useEffect, useState } from 'react';

import { format, parseISO } from 'date-fns/fp';
import { omit } from 'lodash-es';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { DeliveryFilters, Permission } from '@calo/dashboard-types';
import { Country, DDeliveryStatus, DeliveryStatus, DeliveryTime, DietType, Kitchen, PlanType } from '@calo/types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { postRequest, toggleUISettings } from 'actions';
import { FoodSelect, Input, Select, Select2, SideDrawer } from 'components';
import { getAccessibleCountries, getKitchenOptions } from 'lib/helpers';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { AppState } from 'lib/interfaces';

interface SettingsProps {
  onFilter: (values: DeliveryFilters) => void;
  filters: DeliveryFilters;
  page: number;
  drivers: any;
}

const Settings = ({ page, filters, onFilter, drivers }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));
  const dispatch = useDispatch();
  const roles = useUserRoles();
  const userKitchens: string[] = useUserKitchens();

  const handleFoodChanges = (foodIds: string[]) => {
    onFilter({ ...filters, foodIds: foodIds });
  };

  const [filterName, setFilterName] = useState<string>();
  const { data: foodList, isLoading } = useQuery<any, Error, any>(
    [
      'food/list',
      {
        filters: {
          name: filterName ? filterName : undefined,
          country: filters.country,
          brand: filters.brand,
          kitchen: filters.kitchen,
          ids: !filters.name && filters.foodIds ? filters.foodIds : undefined
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page,
        limit: 50
      }
    ],
    postRequest,
    {
      suspense: false,
      enabled: !!filterName || !!filters.foodIds
    }
  );

  useEffect(() => {
    if (filterName) {
      setFilterName('');
    }
    onFilter({ ...filters });
  }, [filters.country, filters.brand]);

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Country"
          value={filters.country || getAccessibleCountries(userKitchens)[0]}
          onChange={(e) =>
            onFilter({
              ...filters,
              country: e.target.value as Country,
              kitchen: userKitchens.find((k) => k.includes(e.target.value)) as Kitchen
            })
          }
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(e) => onFilter({ ...filters, kitchen: e.target.value as Kitchen })}
          options={getKitchenOptions(userKitchens, filters.country!)}
          disabled={Object.values(Kitchen).filter((r) => r.includes(filters.country!)).length === 0}
        />

        <Input label="Name" value={filters.name} onChange={(e) => onFilter({ ...filters, name: e.target.value })} debounce />

        <Input
          label="Phone Number"
          value={filters.phoneNumber}
          onChange={(e) => onFilter({ ...filters, phoneNumber: e.target.value })}
          debounce
        />

        {roles.includes(Permission.VIEW_FOOD_LIST) && (
          <FoodSelect
            onChange={(foodIds) => handleFoodChanges(foodIds)}
            isLoading={isLoading}
            value={filters.foodIds || []}
            foodList={foodList?.data || []}
            setFilterName={setFilterName}
            filterName={filterName || ''}
            label="Meals"
          />
        )}

        <Select
          label="Delivery Time"
          value={filters.deliveryTime}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['deliveryTime']),
              ...(v.value && {
                deliveryTime: v.value
              })
            })
          }
          options={[
            {
              value: '',
              label: 'Any'
            },
            {
              value: DeliveryTime.morning,
              label: 'Morning'
            },
            {
              value: DeliveryTime.evening,
              label: 'Evening'
            }
          ]}
        />

        <Select
          isMulti
          label="Plan"
          value={filters.planType}
          onChange={(data: any) => onFilter({ ...filters, planType: data.map((r) => r.value) })}
          options={Object.values(PlanType).map((plan) => ({
            value: plan,
            label: plan
          }))}
        />

        <Select
          isMulti
          label="Diet Type"
          value={filters.dietType}
          onChange={(data: any) => onFilter({ ...filters, dietType: data.map((Dtype) => Dtype.value) })}
          options={Object.values(DietType).map((dType) => ({
            value: dType,
            label: dType
          }))}
        />

        <Select
          label="Skipped"
          value={filters.skipped}
          onChange={(skip: any) => onFilter({ ...filters, skipped: skip.value })}
          options={[
            {
              value: true,
              label: 'True'
            },
            {
              value: false,
              label: 'False'
            }
          ]}
        />

        <Select
          label="Delivery Status"
          value={filters.deliveryStatus}
          onChange={(v: any) => onFilter({ ...filters, deliveryStatus: v.value })}
          options={[
            {
              value: '',
              label: 'Any'
            },
            {
              value: 'notDelivered',
              label: 'Not delivered'
            },
            {
              value: DDeliveryStatus.delivering,
              label: 'Delivering'
            },
            {
              value: DDeliveryStatus.delivered,
              label: 'Delivered'
            }
          ]}
        />

        <Select
          label="Status"
          value={filters.status}
          onChange={(data: any) =>
            onFilter({
              ...filters,
              status: (data || []).map((row) => row.value)
            })
          }
          isMulti
          options={[
            {
              value: DeliveryStatus.upcoming,
              label: 'Upcoming'
            },
            {
              value: DeliveryStatus.suspended,
              label: 'Suspended'
            },
            {
              value: DeliveryStatus.paymentRequired,
              label: 'Not Paid'
            },
            {
              value: DeliveryStatus.paused,
              label: 'Paused'
            },
            {
              value: DeliveryStatus.cancelled,
              label: 'Cancelled'
            }
          ]}
        />

        <Select
          label="With Cutlery"
          value={filters.withCutlery}
          onChange={(v: any) => onFilter({ ...filters, withCutlery: v.value })}
          options={[
            {
              value: undefined,
              label: 'Any'
            },
            {
              value: true,
              label: 'With'
            },
            {
              value: false,
              label: 'Without'
            }
          ]}
        />

        <Select
          label="With Cooler Bag"
          value={filters.withCoolerBag}
          onChange={(v: any) => onFilter({ ...filters, withCoolerBag: v.value })}
          options={[
            {
              value: undefined,
              label: 'Any'
            },
            {
              value: true,
              label: 'With'
            },
            {
              value: false,
              label: 'Without'
            }
          ]}
        />

        <Select
          label="Driver"
          value={filters.driverId}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['driverId']),
              ...(v.value && {
                driverId: v.value
              })
            })
          }
          options={[
            {
              value: '',
              label: 'Any'
            },
            ...drivers.map((d: any) => ({
              value: d.id,
              label: `${d.name} (${d.phoneNumber})`
            }))
          ]}
        />

        <div className="field">
          <label className="label">Day</label>
          <div className="control is-clearfix">
            <DateRangePicker
              clearIcon={null}
              calendarType={'US'}
              onChange={(dates: any) =>
                onFilter({
                  ...filters,
                  day: {
                    gte: format('yyyy-MM-dd')(dates[0]),
                    lte: format('yyyy-MM-dd')(dates[1])
                  }
                })
              }
              value={[parseISO(filters.day.gte), parseISO(filters.day.lte)]}
            />
          </div>
        </div>
      </section>
    </SideDrawer>
  );
};

export default Settings;
