import { DeliveryPreferenceObject, DeliveryPreferences, Nutrition } from '@calo/types';
import { caloTheme } from 'assets/images/theme/calo';

interface MacrosConfigValue {
  color: string;
  label: 'Carb' | 'Fat' | 'Pro';
}

export const macrosConfigs: { [key in Exclude<Nutrition, Nutrition.fiber>]: MacrosConfigValue } = {
  [Nutrition.protein]: {
    color: caloTheme.palette.primary500,
    label: 'Pro'
  },
  [Nutrition.carbs]: {
    color: caloTheme.palette.secondaryYellow500,
    label: 'Carb'
  },
  [Nutrition.fat]: {
    color: caloTheme.palette.red500,
    label: 'Fat'
  }
};

export const validateDeliveryPreferences = (deliveryPreferences?: DeliveryPreferences) => {
  if (!deliveryPreferences) {
    return true;
  }

  const days: (keyof DeliveryPreferences)[] = ['0', '1', '2', '3', '4', '5', '6'];
  const deliveryPreferencesArray = days.map((day) => deliveryPreferences[day]).filter(Boolean) as DeliveryPreferenceObject[];

  if (deliveryPreferencesArray.length !== 7) {
    return false;
  }

  const firstPreference = deliveryPreferencesArray[0];
  const allSame = deliveryPreferencesArray.every(
    (preference) =>
      preference.deliveryTime === firstPreference.deliveryTime &&
      preference.deliveryAddressId === firstPreference.deliveryAddressId
  );

  return allSame;
};
