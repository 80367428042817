import { PermissionService } from '@calo/services';
import { MenuItem, Select, Tooltip } from '@mui/material';
import { replaceFood } from 'actions/delivery';
import { Delivery, FoodWithPosition, Menu, MenuFood } from 'lib/interfaces';
import { compact, flatten, groupBy, startCase, uniqBy } from 'lodash-es';
import { useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation } from 'react-query';

interface FoodSizeTooltipProps {
  meal: FoodWithPosition;
  delivery: Delivery;
  menu: Menu;
  refetchDelivery: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
}

const FoodSizeTooltip = ({ meal, delivery, menu, refetchDelivery }: FoodSizeTooltipProps) => {
  const [selectedSize, setSelectedSize] = useState<any>({ size: meal?.size, foodId: meal?.id, foodName: meal.name?.en });
  const { mutateAsync: replaceMutation } = useMutation(replaceFood);

  const sameMealSize = flatten(Object.values(groupBy(menu?.food, 'name.en')));
  const allSizes = uniqBy(compact(sameMealSize.map((r) => r.name?.en === meal.name?.en && r)), 'size');

  const handleSizeChange = (size: string, allSizes: MenuFood[]) => {
    const selectedMealData = uniqBy(flatten(compact(allSizes.filter((r) => r.size === size))), 'id');
    setSelectedSize({ size: selectedMealData[0].size, foodId: selectedMealData[0].id, foodName: selectedMealData[0].name?.en });
    handleReplace(selectedMealData[0]);
  };

  const handleReplace = async (food: any) => {
    await replaceMutation(
      {
        id: delivery.id,
        sourceId: meal.id,
        targetId: food.size === 'C' ? (food.id === 'food' ? food.sk : food.id) : food.id || food.foodId,
        positionIndex: meal.positionIndex
      },
      {
        onSuccess: () => {
          refetchDelivery();
        }
      }
    );
  };

  return (
    <Tooltip
      title={`${allSizes.length === 0 ? 'No Sizes' : allSizes.length <= 1 ? 'The only size for this meal is already Selected' : PermissionService.deliveryCanBeEdited(delivery) ? '' : 'Can not change size during lockup time'}`}
      placement="top"
      arrow
    >
      <Select
        value={selectedSize.size}
        name="size"
        style={{
          width: '100%'
        }}
        disabled={!(allSizes.length > 1) || !PermissionService.deliveryCanBeEdited(delivery)}
        onChange={(data: any) => handleSizeChange(data.target.value, allSizes)}
      >
        {(allSizes.length === 0 ? [{ size: meal.size }] : Object.values(allSizes)).map((type) => (
          <MenuItem key={type.size} value={type.size}>
            {startCase(type.size)}
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  );
};

export default FoodSizeTooltip;
