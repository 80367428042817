import { Subscription } from '@calo/types';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import { addAddonSubscription, getAddonSubscriptionPackages, removeAddonSubscription } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Popover } from 'components';
import { useUserRoles } from 'lib/hooks';
import { toast } from 'react-toastify';
import { AddonSubscriptionCard } from '../AddonSubscriptionCard';
import { AddonSubscriptionModal } from '../AddonSubscriptionModal';

interface AddonSubscriptionProps {
  subscription: Subscription;
  refetchSubscription?: () => void;
}
export const AddonSubscription = ({ subscription, refetchSubscription = () => ({}) }: AddonSubscriptionProps) => {
  const roles = useUserRoles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAddonSubscription, setSelectedAddonSubscription] = useState('');
  const [removeAddonData, setRemoveAddonData] = useState<{
    category: string;
    startDate: string;
    endDate: string;
  } | null>(null);

  const removePopupRef = useRef<any>();

  const { data: addonPackages } = useQuery(
    [`addonSubscription/${subscription.id}/packages`, subscription.id],
    () => getAddonSubscriptionPackages(subscription.id),
    { suspense: false }
  );

  const {
    mutateAsync: addAddonSubscriptionMutation,
    isSuccess: isAddonSubscriptionAddedSuccessfully,
    isLoading: isAddAddonSubscriptionLoading
  } = useMutation(addAddonSubscription);

  const { mutateAsync: removeAddonSubscriptionMutation, isSuccess: isAddonRemovedSuccessfully } =
    useMutation(removeAddonSubscription);

  const handleRemoveAddonSubscription = async () => {
    removePopupRef.current.close();
    await removeAddonSubscriptionMutation({
      subscriptionId: subscription.id,
      category: removeAddonData?.category || '',
      endDate: removeAddonData?.endDate || '',
      startDate: removeAddonData?.startDate || ''
    });
  };

  const handleSaveAddonSubscription = async () => {
    if (!selectedAddonSubscription) {
      toast('Select the add-on subscription', {
        type: 'info',
        autoClose: 2000
      });
      return;
    }
    await addAddonSubscriptionMutation({
      subscriptionId: subscription.id,
      addonSubscriptionId: selectedAddonSubscription
    });
  };

  useEffect(() => {
    if (isAddonSubscriptionAddedSuccessfully) {
      refetchSubscription();
      setIsModalOpen(false);
      setSelectedAddonSubscription('');
    }
  }, [isAddonSubscriptionAddedSuccessfully]);

  useEffect(() => {
    if (isAddonRemovedSuccessfully) {
      refetchSubscription();
    }
  }, [isAddonRemovedSuccessfully]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          direction: 'row',
          width: 'full',
          justifyContent: 'space-between',
          padding: '8px'
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: '19px',
            lineHeight: '23px',
            my: 'auto',
            mx: 2,
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Add-ons
        </Typography>

        <Button
          variant="outlined"
          aria-label="add-address-button"
          disabled={!roles.includes(Permission.ADD_ADDON_SUBSCRIPTION)}
          sx={{
            float: 'right',
            margin: '4px',
            lineHeight: '17px',
            fontWeight: 600,
            fontSize: '14px',
            borderRadius: '8px',
            padding: '12px 20px 12px 20px',
            borderColor: caloTheme.palette.primary100,
            color: caloTheme.palette.primary500,
            '&:hover': {
              borderColor: caloTheme.palette.primary600
            }
          }}
          startIcon={'+'}
          onClick={() => setIsModalOpen(true)}
        >
          Add Subscription
        </Button>
      </Box>
      <AddonSubscriptionModal
        isLoading={isAddAddonSubscriptionLoading}
        packages={addonPackages?.addonSubscription}
        isOpen={isModalOpen}
        selectedAddonSubscription={selectedAddonSubscription}
        handleSave={handleSaveAddonSubscription}
        onClose={() => setIsModalOpen(false)}
        setSelectedAddonSubscription={setSelectedAddonSubscription}
      />
      <Popover subTitle="It will remove the add-on subscriptions" ref={removePopupRef} onClose={() => setRemoveAddonData(null)}>
        <Typography
          component="div"
          sx={{
            width: 'full',
            fontSize: '24px',
            marginBottom: '10px',
            fontWeight: 'bold'
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          component="div"
          sx={{
            width: 'full',
            fontSize: '20px',
            fontWeight: '400',
            marginBottom: '15px'
          }}
        >
          It will remove the add-on subscriptions
        </Typography>
        <Stack flexDirection={'row'} justifyContent={'space-around'}>
          <Button
            variant="outlined"
            sx={{
              width: '48%',
              lineHeight: '17px',
              marginX: '5px',
              fontWeight: 600,
              fontSize: '14px',
              borderRadius: '8px',
              paddingLeft: '15px',
              padding: '12px 20px 12px 20px',
              borderColor: caloTheme.palette.red,
              color: caloTheme.palette.red,
              '&:hover': {
                borderColor: caloTheme.palette.secondaryRed300
              }
            }}
            onClick={() => removePopupRef.current.close()}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRemoveAddonSubscription}
            variant="outlined"
            sx={{
              width: '48%',
              marginX: '5px',
              lineHeight: '17px',
              fontWeight: 600,
              fontSize: '14px',
              borderRadius: '8px',
              paddingRight: '15px',
              padding: '12px 20px 12px 20px',
              borderColor: caloTheme.palette.primary500,
              color: caloTheme.palette.primary500,
              '&:hover': {
                borderColor: caloTheme.palette.primary600
              }
            }}
          >
            Confirm
          </Button>
        </Stack>
      </Popover>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          direction: 'row',
          rowGap: 2,
          width: 'full',
          justifyContent: 'space-between',
          padding: '8px'
        }}
      >
        {subscription?.addonSubscription &&
          subscription?.addonSubscription?.length > 0 &&
          subscription?.addonSubscription?.map((data) => (
            <AddonSubscriptionCard
              isSelected
              name={data.category}
              startDate={data.startDate}
              endDate={data.endDate}
              onPressRemove={({ category, endDate, startDate }) => {
                setRemoveAddonData({ category, endDate, startDate });
                removePopupRef.current.open();
              }}
              isDisabled={!roles.includes(Permission.REMOVE_ADDON_SUBSCRIPTION)}
            />
          ))}
      </Box>
    </>
  );
};
