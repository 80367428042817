import { useRef } from 'react';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, IconButton } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components';
import MealUsedModal from 'views/Food/ExactFood/Header/MealUsedModal';

interface UsedOnMenuIndicatorProps {
  filteredFoodUsed: {
    type: 'mainMenu' | 'addonsMenu';
    date?: string | undefined;
    week?: number | undefined;
  }[];
  usedOnMenuButtonColor?: string[];
  type: 'ingredient' | 'food' | 'component';
}

const UsedOnMenuIndicator = ({ filteredFoodUsed, usedOnMenuButtonColor, type }: UsedOnMenuIndicatorProps) => {
  const foodUsedOnMenuRef = useRef<ModalRef>();
  const getColor = (type: string) => {
    switch (type) {
      case 'backgroundColor':
        return usedOnMenuButtonColor?.includes('red')
          ? caloTheme.palette.secondaryRed100
          : usedOnMenuButtonColor?.includes('yellow')
            ? caloTheme.palette.secondaryYellow100
            : caloTheme.palette.white;
      case 'color':
        return usedOnMenuButtonColor?.includes('red')
          ? caloTheme.palette.red
          : usedOnMenuButtonColor?.includes('yellow')
            ? caloTheme.palette.secondaryYellow600
            : caloTheme.palette.white;
      default:
        return caloTheme.palette.secondaryBlue100;
    }
  };
  return (
    <Box>
      {filteredFoodUsed && filteredFoodUsed.length > 0 && (
        <IconButton
          size="small"
          sx={{
            backgroundColor: getColor('backgroundColor'),
            '&:hover': { backgroundColor: getColor('backgroundColor') },
            cursor: 'pointer'
          }}
          onClick={() => foodUsedOnMenuRef.current?.open()}
        >
          <WarningAmberIcon style={{ color: getColor('color') }} />
        </IconButton>
      )}
      <MealUsedModal
        selectedFoodType={type}
        filteredFoodUsed={filteredFoodUsed}
        ref={foodUsedOnMenuRef}
        close={() => foodUsedOnMenuRef.current?.close()}
      />
    </Box>
  );
};

export default UsedOnMenuIndicator;
