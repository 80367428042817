import { FoodTagsKeys } from '@calo/dashboard-types';
import { FoodType } from '@calo/types';
import { Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { MenuFoodDietTags } from '..';

const MealInfoTypes = ({ selectedMeal }: { selectedMeal: any }) => {
  const getMealType = () => {
    if (Array.isArray(selectedMeal?.type)) {
      return selectedMeal.type
        .map((r: FoodType) => {
          if (r === FoodType.dinner) {
            return null;
          }
          return r.includes(FoodType.lunch) ? 'Lunch & Dinner' : r;
        })
        .filter(Boolean)
        .join(', ');
    }
    return '';
  };

  const getFoodTagValue = (key: FoodTagsKeys) => {
    const tag = selectedMeal?.foodTags?.find((tag: any) => tag.key === key);
    return Array.isArray(tag?.value) ? tag.value.join(', ') : tag?.value || '--';
  };

  return (
    <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ my: 3 }}>
      <Typography
        variant="h6"
        sx={{
          fontFamily: caloTheme.typography.fontFamily,
          fontWeight: 600,
          fontSize: '19px',
          lineHeight: '23px',
          textTransform: 'capitalize'
        }}
      >
        {getMealType()}
      </Typography>
      <Typography
        variant="h1"
        sx={{
          mt: '2px',
          fontFamily: caloTheme.typography.fontFamily,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '23px',
          textTransform: 'capitalize'
        }}
      >
        Protein: {getFoodTagValue(FoodTagsKeys.protein)}
      </Typography>
      <Typography
        variant="h1"
        sx={{
          mt: '2px',
          fontFamily: caloTheme.typography.fontFamily,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '23px',
          textTransform: 'capitalize'
        }}
      >
        Taste: {getFoodTagValue(FoodTagsKeys.taste)}
      </Typography>
      <Typography
        variant="h1"
        sx={{
          mt: '2px',
          fontFamily: caloTheme.typography.fontFamily,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '23px',
          textTransform: 'capitalize'
        }}
      >
        Base: {getFoodTagValue(FoodTagsKeys.base)}
      </Typography>
      <Typography
        variant="h1"
        sx={{
          mt: '2px',
          fontFamily: caloTheme.typography.fontFamily,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '23px',
          textTransform: 'capitalize'
        }}
      >
        Sandwich: {selectedMeal?.assemblyRequired ? 'Yes' : 'No'}
      </Typography>
      <Stack display="flex" flexDirection="row" justifyContent="space-evenly">
        {Array.isArray(selectedMeal?.tags) ? selectedMeal.tags.map((r: any) => <MenuFoodDietTags key={r} tag={r} />) : null}
      </Stack>
    </Stack>
  );
};

export default MealInfoTypes;
