import { LatLng } from '@calo/driver-types';
import { DrawingManager } from '@react-google-maps/api';
import cx from 'classnames';
import { useContext, useEffect } from 'react';
import PolygonContext from '../SinglePolygonContext';
interface PolygonManagerProps {
  bounds?: LatLng[];
  polygonState: SinglePolygonState;
  isEditing: boolean;
  setIsEditing: (e: boolean) => void;
  handleUpdate: (data: any) => Promise<any>;
}

const polygonOptions: google.maps.PolygonOptions = {
  clickable: false,
  editable: false,
  fillOpacity: 0,
  strokeWeight: 0.3
};

const polygonOptionsEdit: google.maps.PolygonOptions = {
  fillOpacity: 0.3,
  editable: true,
  clickable: true,
  strokeWeight: 1
};

const SinglePolygonManager = ({ bounds, polygonState, isEditing, setIsEditing }: PolygonManagerProps) => {
  const dispatch = useContext(PolygonContext);
  const { polygon: currentPolygon } = polygonState;

  useEffect(() => {
    if (currentPolygon) {
      currentPolygon.setEditable(isEditing);
      currentPolygon.setOptions(isEditing ? polygonOptionsEdit : polygonOptions);
    }
  }, [isEditing]);

  const onPolygonDeleted = () => {
    dispatch!({
      type: 'delete',
      payload: currentPolygon
    });
  };

  // const saveMap = async () => {
  //   const polygon=currentPolygon?.getPath().getArray().map(row => row.toJSON()) as LatLng[];
  //   await handleUpdate(polygon);
  //   dispatch!({
  //     type: 'select',
  //     payload: undefined
  //   });
  //   setIsEditing(false);
  // };

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    dispatch!({
      type: 'select',
      payload: undefined
    });

    const newPoly = new google.maps.Polygon({
      paths: polygon.getPaths(),
      ...polygonOptionsEdit
    });
    newPoly.setMap(polygon.getMap());
    if (currentPolygon) {
      dispatch!({
        type: 'delete',
        payload: currentPolygon
      });
    }

    dispatch!({
      type: 'add',
      payload: { polygon: newPoly }
    });
    polygon.setMap(null);
  };

  const onLoaded = (dm: google.maps.drawing.DrawingManager) => {
    dm.setDrawingMode(null);
    let poly: google.maps.Polygon | null = null;
    if (bounds) {
      poly = new google.maps.Polygon({
        paths: bounds,
        ...polygonOptions
      });
      poly.setMap(dm.getMap());
    }
    dispatch!({
      type: 'set',
      payload: poly
    });
  };
  const onUnloaded = (dm: google.maps.drawing.DrawingManager) => {
    dm.setDrawingMode(null);
    dispatch!({
      type: 'reset'
    });
  };

  return (
    <>
      <div
        className={cx('absolute top-10 right-2 p-2 flex flex-row', {
          'bg-white': (isEditing && polygonState.polygon?.getPath()) || !isEditing
        })}
      >
        {isEditing ? (
          polygonState.polygon?.getPath() && (
            <span className="has-tooltip-left" data-tooltip="Remove area">
              <i className="fas fa-trash " onClick={onPolygonDeleted} />
            </span>
          )
        ) : (
          <i className="fas fa-edit" onClick={() => setIsEditing(true)} />
        )}
      </div>
      <DrawingManager
        key={'drawingManager'}
        onLoad={onLoaded}
        onUnmount={onUnloaded}
        onPolygonComplete={onPolygonComplete}
        options={{
          drawingControl: isEditing,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON]
          },
          polygonOptions: polygonOptionsEdit
        }}
      />
    </>
  );
};

export default SinglePolygonManager;
