import { useEffect, useState } from 'react';

import { format } from 'date-fns/fp';
import { useQuery } from 'react-query';
import { generatePath, RouteComponentProps, useLocation } from 'react-router';

import { Permission } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';

import { getListWithParams } from 'actions';
import { Button, CaloLoader } from 'components';
import { resolveCountryFromKitchen } from 'lib';
import { Routes } from 'lib/enums';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import AddonsRow from './AddonsRow';
import Settings from './Settings';

type AddonsListProps = RouteComponentProps;

const AddonsList = ({ history }: AddonsListProps) => {
  const roles = useUserRoles();
  const userKitchen = useUserKitchens();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    date: {
      gte: format('yyyy-MM-dd')(Date.now())
    },
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  useEffect(() => {
    const path = generatePath(Routes.addonsList, { brand: filters.brand, country: filters.country });
    history.push(path);
  }, [filters.country]);

  const { data: addonsList, isLoading } = useQuery<any, Error, { data: any[] }>(
    ['addons-menu', { date: filters.date, brand: filters.brand, kitchen: filters.kitchen }],
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  Add-ons / {filters.country} / {filters.kitchen}
                </li>
              </ul>
            </div>
          </div>
          {roles.includes(Permission.CREATE_ADDONS) && (
            <div className="level-right">
              <div className="level-item">
                <Button
                  icon="fas fa-plus"
                  onClick={() =>
                    history.push(Routes.newAddons, {
                      country: filters.country,
                      brand: filters.brand,
                      kitchen: filters.kitchen
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </section>
      <Settings onFilter={setFilters} filters={filters} />
      {isLoading ? (
        <CaloLoader />
      ) : addonsList && addonsList.data.length > 0 ? (
        <div className="card has-table has-table-container-upper-radius">
          <div className="card-content">
            {addonsList &&
              addonsList.data.length > 0 &&
              addonsList.data.map((addons, index) => <AddonsRow key={`${addons.id}-${index}`} addons={addons} />)}
          </div>
        </div>
      ) : (
        <span className="flex w-full text-3xl mt-4 justify-center font-bold text-gray-400">NO ADDONS</span>
      )}
    </>
  );
};

export default AddonsList;
