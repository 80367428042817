import { forwardRef, ReactNode, useImperativeHandle, useState } from 'react';

import { caloTheme } from 'assets/images/theme/calo';

import CloseIcon from '@mui/icons-material/Close';
import {
  Breakpoint,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';

interface PopupProps {
  title?: string;
  onClose?: () => void;
  children: ReactNode;
  divider?: boolean;
  fullWidth?: boolean;
  maxWidth?: Breakpoint;
  subTitle?: ReactNode;
  info?: any;
  closeIcon?: boolean;
  titleStyle?: SxProps<Theme>;
}

const Popup = forwardRef(
  ({ info, fullWidth, subTitle, maxWidth, divider, title, children, onClose, closeIcon, titleStyle }: PopupProps, ref) => {
    const [openDialog, setOpenDialog] = useState(false);
    const fullScreen = useMediaQuery(caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg));

    useImperativeHandle(ref, () => ({
      open: () => {
        setOpenDialog(true);
      },
      close: () => {
        setOpenDialog(false);
      }
    }));

    const handleClose = () => {
      setOpenDialog(false);
      onClose && onClose();
    };

    return (
      <Dialog
        maxWidth={maxWidth}
        open={openDialog}
        fullWidth={fullWidth}
        onClose={handleClose}
        sx={{
          borderRadius: '8px',
          '& .MuiDialog-paper': {
            borderRadius: '16px'
          }
        }}
      >
        {title && (
          <DialogTitle>
            <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Typography
                component="div"
                sx={{ width: 'full', fontSize: '26px', lineHeight: '28px', fontWeight: 400, ...titleStyle }}
              >
                {title}
              </Typography>
              {info && (
                <Typography component="div" sx={{ width: 'full', fontSize: '26px', lineHeight: '28px', fontWeight: 400 }}>
                  {info}
                </Typography>
              )}
            </Stack>
            {subTitle && (
              <Typography component="div" sx={{ width: 'full', fontSize: '26px', lineHeight: '28px', fontWeight: 400 }}>
                {subTitle}
              </Typography>
            )}
            {closeIcon && (
              <IconButton
                sx={{ position: 'absolute', top: 16, right: 8, color: caloTheme.palette.neutral900 }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>
        )}
        <DialogContent dividers={divider} sx={{ width: 'full', height: fullScreen ? '400px' : 'auto', overflow: 'auto' }}>
          <Stack sx={{ height: '100%' }}>{children}</Stack>
        </DialogContent>
      </Dialog>
    );
  }
);
export default Popup;
