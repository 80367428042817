import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingRigth: 1,
    paddingLeft: 1
  },
  container: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    color: caloTheme.palette.neutral700,
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
    paddingBottom: 1
  },
  optionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 1
  },
  optionTitle: {
    width: '50%',
    margin: 'auto'
  },
  optionValue: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '50%',
    justifyContent: 'flex-end'
  },
  IconStyle: {
    color: caloTheme.palette.primary500,
    backgroundColor: caloTheme.palette.primary50,
    borderRadius: '40px',
    fontSize: '32px'
  },
  divider: {
    borderWidth: 1,
    borderColor: caloTheme.palette.neutral100,
    height: 'auto',
    marginRight: 1
  }
};

export default styles;
