import { Permission } from '@calo/dashboard-types';
import { DeliveryTime } from '@calo/types';
import cx from 'classnames';
import { DeliveryPaymentIcon, PhoneNumber } from 'components';
import { format } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Delivery } from 'lib/interfaces';
import { Link } from 'react-router-dom';
import { AddressService } from 'services';

interface DeliveryRowProps {
  delivery: Delivery;
  onToggle: (delivery: Delivery) => void;
  selected?: boolean;
}

const DeliveryRow = ({ delivery }: DeliveryRowProps) => {
  const roles = useUserRoles();

  return (
    <tr>
      <td data-label="User">
        {roles.includes(Permission.VIEW_DELIVERY) ? (
          <Link to={Routes.delivery.replace(':id', delivery.id)}>{delivery.name}</Link>
        ) : (
          delivery.name
        )}
      </td>
      <td data-label="Phone">
        <PhoneNumber>{delivery.phoneNumber}</PhoneNumber>
      </td>
      <td data-label="Day">{delivery.day}</td>
      <td data-label="Pending Amount">
        {delivery.pendingAmount &&
          (delivery.pendingAmount[delivery.currency] ? delivery.pendingAmount[delivery.currency] : delivery.pendingAmount['BHD'])}
      </td>
      <td data-label="Menu">
        <div className="tags">
          {delivery.food && delivery.food.length > 0
            ? delivery.food.map((food, i) => (
                <span className="tag is-info" key={i}>
                  {food.name?.en}({food.size})
                </span>
              ))
            : 'Not generated'}
        </div>
      </td>
      <td data-label="Address">{AddressService.display(delivery.deliveryAddress)}</td>
      <td data-label="Notes">{delivery.deliveryAddress.notes}</td>
      <td data-label="Country">{delivery.deliveryAddress.country}</td>
      <td data-label="Kitchen">{delivery.kitchen}</td>
      <td data-label="Currency">{delivery.currency}</td>
      <td data-label="Driver">{delivery.driver?.name}</td>
      <td data-label="Status" style={{ whiteSpace: 'nowrap' }}>
        <DeliveryPaymentIcon delivery={delivery} />
      </td>
      <td>
        <span
          className={cx('tag mt-4', {
            'is-dark': delivery.time === DeliveryTime.evening,
            'is-light': delivery.time !== DeliveryTime.evening
          })}
        >
          {delivery.time || DeliveryTime.morning}
        </span>
      </td>
      <td className="pt-10">
        {delivery.deliveredAt ? (
          <div>
            <p className="text-green-500">Delivered </p>
            <p className="text-sm">({format('hh:mm a')(new Date(Date.parse(delivery.deliveredAt)))})</p>
          </div>
        ) : (
          <div>
            <p className="text-yellow-500">Pending</p>
          </div>
        )}
      </td>
    </tr>
  );
};
export default DeliveryRow;
