import { forwardRef } from 'react';

import { Permission, UpdateFoodReq } from '@calo/dashboard-types';
import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { Food } from 'lib/interfaces';
import AllPackagesMUI from '../MUI/AllPackagesMUI';

interface PackagingInformationProps {
  roles: any;
  values: UpdateFoodReq;
  isDisabled: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setPackageCost: (r: number) => void;
  food: Food;
}

const PackagingInformation = forwardRef<HTMLDivElement, PackagingInformationProps>(
  ({ values, roles, isDisabled, food, setFieldValue, setPackageCost }: PackagingInformationProps, ref) => {
    return (
      <>
        {roles.includes(Permission.VIEW_FOOD_PACKAGE_LIST) && (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            ref={ref}
            sx={{ backgroundColor: caloTheme.palette.white, borderRadius: '16px' }}
          >
            <Typography
              sx={{
                m: 2,
                color: caloTheme.palette.neutral900,
                textTransform: 'capitalize',
                fontSize: '19px',
                fontWeight: 600,
                lineHeight: '23px'
              }}
            >
              Packaging Information
            </Typography>
            <AllPackagesMUI
              values={values}
              setFieldValue={setFieldValue}
              setPackageCost={(r) => setPackageCost(r)}
              country={food.country}
              brand={food.brand}
              isDisabled={isDisabled}
              kitchen={food.kitchen}
            />
            <Typography
              sx={{
                m: 2,
                color: caloTheme.palette.neutral900,
                textTransform: 'capitalize',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '23px'
              }}
            >
              Exclude from printing
            </Typography>

            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.metadata?.excludeFrontLabel}
                    onChange={(c, v) => setFieldValue('metadata.excludeFrontLabel', v)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Front Label"
                labelPlacement="top"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.metadata?.excludeBackLabel}
                    onChange={(c, v) => setFieldValue('metadata.excludeBackLabel', v)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Back Label"
                labelPlacement="top"
              />
            </FormGroup>
          </Stack>
        )}
      </>
    );
  }
);

export default PackagingInformation;
