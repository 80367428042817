import { TableCell, styled, tableCellClasses } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    flexShrink: 0,
    border: 'none',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    variant: 'caption',
    color: caloTheme.palette.neutral900,
    fontFamily: caloTheme.typography.fontFamily
  },
  [`&.${tableCellClasses.body}`]: {
    flexShrink: 0,
    border: 'none',
    fontWeight: 600,
    fontSize: '12px',
    variant: 'caption',
    lineHeight: '14px',
    color: caloTheme.palette.neutral900,
    fontFamily: caloTheme.typography.fontFamily
  }
}));
