import { CustomMealCategory } from '@calo/types';
import { startCase } from 'lodash-es';

export const handleCategoryText = (category: CustomMealCategory) => {
  return category === CustomMealCategory.rice ? 'Rice Bowls' : category;
};

export const handleTagBarText = (tag: string, customMealCategory: CustomMealCategory) => {
  if (customMealCategory === CustomMealCategory.sandwich && tag === 'topping') {
    return 'Fillings';
  }
  return startCase(tag);
};
