import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

const Disclaimer = ({ type, sx }: { type: 'meal' | 'component' | 'ingredient'; sx?: SxProps<Theme> }) => {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        px: '12px',
        py: '20px',
        gap: '8px',
        position: 'sticky',
        top: '70px',
        marginBottom: '20px',
        zIndex: 1000,
        alignItems: 'center',
        backgroundColor: caloTheme.palette.primary50,
        borderWidth: '1px',
        borderColor: caloTheme.palette.primary300,
        borderRadius: '16px',
        ...sx
      }}
    >
      <InfoOutlinedIcon fontSize="small" color="primary" sx={{ alignSelf: 'flex-start', marginTop: '4px' }} />
      <Typography sx={{ fontWeight: 400, display: 'inline' }}>
        This section is a playground.
        <Typography sx={{ fontWeight: 700, display: 'inline' }}>
          {' '}
          Any {type} you create here do not get created on the actual food database.{' '}
        </Typography>
        You can use this area to play around & experiment with different recipes & combinations. Once you’re done, you can send
        your creations for approval to publish them on the menu.
      </Typography>
    </Stack>
  );
};

export default Disclaimer;
