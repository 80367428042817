import { RefundFilters } from '@calo/dashboard-types';
import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';

export const getRefunds = async (cursor?: string) => {
  const { data } = await client.get('/refunds', { params: { cursor } });
  return data;
};

export const exportRefunds = async (filters: RefundFilters) => {
  const { data, status } = await client.get('refunds/export', { params: { filters } });
  ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
  return data;
};

export const exportRefundsV2 = async (filters: RefundFilters) => {
  const { data, status } = await client.get('v2/refunds/export', { params: { filters } });
  ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
  return data;
};
