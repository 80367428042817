import { useState } from 'react';

import cx from 'classnames';
import { addWeeks, eachDayOfInterval, format, isSameDay, parseISO } from 'date-fns/fp';
import { useLocation } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';

import { resendDayDocs } from 'actions';
import { Button } from 'components';
import { resolveCountryFromKitchen } from 'lib/helpers';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import Settings from './Settings';

const Dashboard = () => {
  const [day, setDay] = useState(format('yyyy-MM-dd')(Date.now()));
  const roles = useUserRoles();
  const userKitchen = useUserKitchens();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState<any>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Dashboard</li>
              </ul>
            </div>
          </div>
          {roles.includes(Permission.SEND_KITCHEN_EMAIL) && (
            <div className="level-right">
              <div className="level-item">
                <Button warning content="Send Emails" onClick={() => resendDayDocs(day, filters.country, filters.kitchen)} />
              </div>
            </div>
          )}
        </div>
      </section>
      <section>
        <div className="tabs">
          <ul>
            {eachDayOfInterval({
              start: Date.now(),
              end: addWeeks(2)(Date.now())
            }).map((interval) => (
              <li className={cx({ 'is-active': isSameDay(parseISO(day))(interval) })} key={interval.toISOString()}>
                <a onClick={() => setDay(format('yyyy-MM-dd')(interval))}>{format('P')(interval)}</a>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section>
        <div>
          <p className="text-center mt-4 text-xl">No data available for the selected date</p>
        </div>
      </section>
      <Settings onFilter={(r) => setFilters(r)} filters={filters} />
    </>
  );
};

export default Dashboard;
