import { DeliveryFilters } from '@calo/dashboard-types';
import { Icon } from '@iconify/react';
import { Box, Card, Stack, Tab, Tabs, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { CoolerBagTotals } from 'lib/interfaces';
import { startCase } from 'lodash-es';
import { getDatesBetween, renderTypography } from '../helpers';

interface CoolerBagsTotalsCardProps {
  isLoading: boolean;
  coolerBagTotals: CoolerBagTotals;
  filters: DeliveryFilters;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
}

const CoolerBagsTotalsCard = ({
  isLoading,
  coolerBagTotals,
  filters,
  selectedDate,
  setSelectedDate
}: CoolerBagsTotalsCardProps) => {
  const dateTabs = getDatesBetween(filters?.day.gte, filters?.day.lte);
  if (!dateTabs.includes(selectedDate)) {
    setSelectedDate(dateTabs[0] || '');
  }
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedDate(dateTabs[newValue]);
  };
  return (
    <Card
      variant="outlined"
      sx={{
        border: 'none',
        padding: 2,
        paddingBottom: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTopRightRadius: '16px',
        borderTopLeftRadius: '16px',
        gap: '8px'
      }}
    >
      <Box overflow="auto" width="100%" sx={{ py: 1 }}>
        <Stack direction="column" justifyContent="space-between" sx={{ width: '100%' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap={1} mb={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1} ml={3}>
              <Icon icon="bi:calendar-fill" width="1.2em" height="1.2em" />
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '20px'
                }}
              >
                From:
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 'normal',
                  lineHeight: '20px'
                }}
              >
                {new Date(filters?.day.gte).toLocaleDateString('en-GB')} to{' '}
                {new Date(filters?.day.lte).toLocaleDateString('en-GB')}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1} mr={3}>
              <Icon icon="ph:clock-fill" width="1.4em" height="1.4em" />
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '20px'
                }}
              >
                Delivery time:
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 'normal',
                  lineHeight: '20px'
                }}
              >
                {startCase(filters?.deliveryTime)}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-evenly" alignItems="center" gap={1}>
            <Box
              sx={{
                backgroundColor: caloTheme.palette.neutral50,
                height: '80px',
                border: 'none',
                borderRadius: '16px',
                width: '23%'
              }}
            >
              {isLoading ? (
                <CaloLoader />
              ) : (
                <Stack direction="column" justifyContent="space-between" alignItems="center" gap={1}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      mt: 1,
                      lineHeight: '20px'
                    }}
                  >
                    Sent
                  </Typography>
                  {renderTypography(coolerBagTotals.nbCoolerBag)}
                </Stack>
              )}
            </Box>
            <Box
              sx={{
                backgroundColor: caloTheme.palette.neutral50,
                height: '80px',
                border: 'none',
                borderRadius: '16px',
                width: '23%'
              }}
            >
              {isLoading ? (
                <CaloLoader />
              ) : (
                <Stack direction="column" justifyContent="space-between" alignItems="center" gap={1}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      mt: 1,
                      lineHeight: '20px'
                    }}
                  >
                    Received
                  </Typography>
                  {renderTypography(coolerBagTotals.nbCoolerBagsReturned)}
                </Stack>
              )}
            </Box>
            <Box
              sx={{
                backgroundColor: caloTheme.palette.neutral50,
                height: '80px',
                border: 'none',
                borderRadius: '16px',
                width: '23%'
              }}
            >
              {isLoading ? (
                <CaloLoader />
              ) : (
                <Stack direction="column" justifyContent="space-between" alignItems="center" gap={1}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      mt: 1,
                      lineHeight: '20px'
                    }}
                  >
                    Unreturned
                  </Typography>
                  {renderTypography((coolerBagTotals.nbCoolerBag || 0) - (coolerBagTotals.nbCoolerBagsReturned || 0))}
                </Stack>
              )}
            </Box>
            <Box
              sx={{
                backgroundColor: caloTheme.palette.neutral50,
                height: '80px',
                border: 'none',
                borderRadius: '16px',
                width: '23%'
              }}
            >
              {isLoading ? (
                <CaloLoader />
              ) : (
                <Stack direction="column" justifyContent="space-between" alignItems="center" gap={1}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      mt: 1,
                      lineHeight: '20px'
                    }}
                  >
                    Damaged
                  </Typography>
                  {renderTypography(coolerBagTotals.nbDamagedCoolerBags)}
                </Stack>
              )}
            </Box>
          </Stack>
          <Box
            sx={{
              width: '100%',
              overflowX: 'auto',
              mt: 3
            }}
          >
            <Tabs
              value={dateTabs.indexOf(selectedDate)}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs"
              TabIndicatorProps={{
                sx: {
                  display: 'none'
                }
              }}
              sx={{
                '& .MuiTab-root': {
                  backgroundColor: caloTheme.palette.neutral50,
                  color: caloTheme.palette.common.black,
                  borderRadius: 0,
                  minWidth: 'auto',
                  padding: '8px 16px',
                  '&.Mui-selected': {
                    backgroundColor: caloTheme.palette.primary500,
                    color: caloTheme.palette.common.white
                  },
                  '&:first-of-type': {
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px'
                  },
                  '&:last-of-type': {
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px'
                  }
                }
              }}
            >
              {dateTabs.map((tabLabel, index) => {
                const formattedDate = new Date(tabLabel).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric'
                });

                return <Tab key={index} label={formattedDate} sx={{ width: '18%' }} />;
              })}
            </Tabs>
          </Box>
        </Stack>
      </Box>
    </Card>
  );
};
export default CoolerBagsTotalsCard;
