import { Country, Kitchen, RetailBranch } from '@calo/types';

export const getCountryFromBranch = (branch: RetailBranch) => {
  switch (branch) {
    case RetailBranch.BOXAT:
      return Country.SA;
    case RetailBranch.HITTEN:
      return Country.SA;
    case RetailBranch.ROSHAN:
      return Country.SA;
    case RetailBranch.SEDRA:
      return Country.SA;
    case RetailBranch.SEEF:
      return Country.BH;
    default:
      return Country.SA;
  }
};

export const getKitchenFromBranch = (branch: RetailBranch) => {
  switch (branch) {
    case RetailBranch.BOXAT:
      return Kitchen.SA1;
    case RetailBranch.HITTEN:
      return Kitchen.SA1;
    case RetailBranch.ROSHAN:
      return Kitchen.SA1;
    case RetailBranch.SEDRA:
      return Kitchen.SA1;
    case RetailBranch.SEEF:
      return Kitchen.BH1;
    default:
      return Kitchen.SA1;
  }
};
