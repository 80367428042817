import ExcelJS from 'exceljs';
import { fill, range } from 'lodash';

export const colors = {
  white: 'ffffffff',
  gray: 'ff949494'
};

export const topBorderStylesForCell: Partial<ExcelJS.Borders> = {
  top: { color: { argb: colors.gray }, style: 'thin' },
  right: { color: { argb: colors.white }, style: 'thin' },
  bottom: { color: { argb: colors.white }, style: 'thin' },
  left: { color: { argb: colors.white }, style: 'thin' }
};

export const leftBorderStylesForCell: Partial<ExcelJS.Borders> = {
  top: { color: { argb: colors.white }, style: 'thin' },
  right: { color: { argb: colors.white }, style: 'thin' },
  bottom: { color: { argb: colors.white }, style: 'thin' },
  left: { color: { argb: colors.gray }, style: 'thin' }
};

export const addTableHeaderRow = (worksheet: ExcelJS.Worksheet, rowIndex: number, values: string[]) => {
  const headerRow = worksheet.getRow(rowIndex);
  headerRow.values = values;
  headerRow.height = 40;
  headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  removeRowBorders(headerRow);
  addCellBorderToFilledCells(headerRow, values.length);

  headerRow.eachCell((cell) => {
    cell.font = { bold: true, size: 12 };
  });
};

export const addCellBorders = (cell: ExcelJS.Cell) => {
  cell.border = {
    top: { color: { argb: colors.gray }, style: 'thin' },
    right: { color: { argb: colors.gray }, style: 'thin' },
    bottom: { color: { argb: colors.gray }, style: 'thin' },
    left: { color: { argb: colors.gray }, style: 'thin' }
  };
};

export const removeRowBorders = (row: ExcelJS.Row) => {
  row.eachCell((cell) => {
    cell.border = {
      top: { color: { argb: colors.white }, style: 'thin' },
      right: { color: { argb: colors.white }, style: 'thin' },
      bottom: { color: { argb: colors.white }, style: 'thin' },
      left: { color: { argb: colors.white }, style: 'thin' }
    };
  });
};

export const addLabelValueRow = (
  worksheet: ExcelJS.Worksheet,
  rowNumber: number,
  colCount: number,
  label: string,
  value: string,
  fontSize?: number
) => {
  const row = worksheet.getRow(rowNumber);
  row.values = ['', label, value, ...fill(Array.from({ length: colCount - 3 }), '')];
  removeRowBorders(row);

  row.getCell(2).font = { bold: true };
  row.getCell(3).font = { size: fontSize ?? 16 };

  row.height = 25;
  row.alignment = { vertical: 'middle' };

  row.eachCell((cell, colNumber) => {
    if (range(3, 3 + colCount - 3).includes(colNumber)) {
      addCellBorders(cell);
    }

    if (colNumber === colCount) {
      cell.border = leftBorderStylesForCell;
    }
  });

  worksheet.mergeCells(row.number, 3, row.number, colCount - 1);
};

export const addCellBorderToFilledCells = (row: ExcelJS.Row, colCount: number) => {
  row.eachCell((cell, colNumber) => {
    if (range(3, 3 + colCount - 3).includes(colNumber)) {
      addCellBorders(cell);
    }
    if (colNumber === colCount) {
      cell.border = leftBorderStylesForCell;
    }
  });
};

export const addEmptyRow = (worksheet: ExcelJS.Worksheet, rowNumber: number, colCount: number) => {
  const emptyRow = worksheet.getRow(rowNumber);
  emptyRow.values = fill(new Array(colCount), '');
  removeRowBorders(emptyRow);
  emptyRow.eachCell((cell, colNumber) => {
    if (range(3, 3 + colCount - 3).includes(colNumber)) {
      cell.border = {
        top: { color: { argb: colors.gray }, style: 'thin' },
        right: { color: { argb: colors.white }, style: 'thin' },
        bottom: { color: { argb: colors.white }, style: 'thin' },
        left: { color: { argb: colors.white }, style: 'thin' }
      };
    }
  });
};

export const clearRows = (worksheet: ExcelJS.Worksheet, rowIndex: number, rowCount: number, colCount: number) => {
  for (let i = 0; i < rowCount; i++) {
    const row = worksheet.getRow(rowIndex);
    row.values = fill(new Array(colCount), '');
    row.eachCell((cell) => {
      cell.border = {
        top: { color: { argb: colors.white }, style: 'thin' },
        right: { color: { argb: colors.white }, style: 'thin' },
        left: { color: { argb: colors.white }, style: 'thin' }
      };
    });
    rowIndex++;
  }

  return rowIndex;
};
