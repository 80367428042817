import { Kitchen } from '@calo/types';
import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';

export const addAddonSubscription = async ({
  subscriptionId,
  addonSubscriptionId
}: {
  subscriptionId: string;
  addonSubscriptionId: string;
}) => {
  const { status } = await client.post(`subscriptions/${subscriptionId}/addonSubscription`, { addonSubscriptionId });
  ToastSuccessMsgs({
    status,
    action: 'Add-on subscription added successfully'
  });
};

export const removeAddonSubscription = async ({
  subscriptionId,
  category,
  endDate,
  startDate
}: {
  subscriptionId: string;
  category: string;
  startDate: string;
  endDate: string;
}) => {
  const { status } = await client.delete(`subscriptions/${subscriptionId}/addonSubscription`, {
    data: {
      addonSubscription: {
        category,
        endDate,
        startDate
      }
    }
  });
  ToastSuccessMsgs({
    status,
    action: 'Add-on subscription removed successfully'
  });
};

export const getAddonSubscriptionMenu = async (kitchen: Kitchen) => {
  const { data } = await client.get(`addonSubscription/menu/${kitchen}`);
  return data;
};

export const replaceAddonSubscriptionFood = async ({
  subscriptionId,
  deliveryId,
  sourceId,
  targetId
}: {
  subscriptionId: string;
  deliveryId: string;
  sourceId: string;
  targetId: string;
}) => {
  const { status } = await client.post('addonSubscription/replace', {
    subscriptionId,
    deliveryId,
    sourceId,
    targetId
  });
  ToastSuccessMsgs({
    status,
    action: 'Add-on subscription food replaced successfully'
  });
};

export const getAddonSubscriptionPackages = async (subscriptionId: string) => {
  const { data } = await client.get(`subscriptions/${subscriptionId}/addonSubscription`);
  return data;
};
