import { combineReducers } from 'redux';

import { AppState } from 'lib/interfaces';
import ui from './ui';
import user from './user';

export default combineReducers<AppState>({
  user,
  ui
});
