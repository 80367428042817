import { InvitationCodeReq } from '@calo/dashboard-types';
import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';

export const createCodes = async (values: InvitationCodeReq) => {
  const { data, status } = await client.post('invitation-codes', values);
  ToastSuccessMsgs({ status, action: 'Invitation code successfully sent' });
  return data;
};

export const createCodesAllUsers = async (values: number) => {
  const { data, status } = await client.post('invitation-codes/batch', {
    count: values
  });
  ToastSuccessMsgs({ status, action: 'Invitation code successfully sent to all users' });
  return data;
};
