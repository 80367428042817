import { createKDSUser } from 'actions/kdsUsers';
import { useKDSPermissions } from 'hooks';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { KDSCreateReq, Permission } from '@calo/dashboard-types';

import UserForm from './UserForm';

const NewKDSUser = () => {
  const { mutateAsync: createMutation } = useMutation(createKDSUser);
  const roles = useUserRoles();
  const history = useHistory();
  const kdsUserGroup = useKDSPermissions();

  const handleNewKDSUser = async (values: Omit<KDSCreateReq, 'id'>) => {
    const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);
    const kdsUser = await createMutation({ ...values, phoneNumber: phoneNumber?.number as string });
    roles.includes(Permission.VIEW_KDS_USER) && history.replace(Routes.kdsUser.replace(':id', kdsUser.id));
  };
  return <UserForm onSubmit={handleNewKDSUser} kdsUserGroup={kdsUserGroup} />;
};
export default NewKDSUser;
