import { Box, Grid, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { FoodComponent } from 'lib/interfaces';
import ComponentCard from '../ComponentCard';
import styles from '../styles';

interface ComponentItemProps {
  component: FoodComponent;
  selectedComponent?: FoodComponent;
  index: number;
  foodComponents: FoodComponent[];
  selectedComponentIndex: number;
  selectedRemovedComponentIdList: string[];
  setSelectedComponent: (value: FoodComponent | undefined) => void;
  setSelectedComponentIndex: React.Dispatch<React.SetStateAction<number>>;
}

const ComponentItem = ({
  component,
  selectedComponent,
  foodComponents,
  index,
  selectedComponentIndex,
  selectedRemovedComponentIdList,
  setSelectedComponent,
  setSelectedComponentIndex
}: ComponentItemProps) => {
  const isRemoved = selectedRemovedComponentIdList.includes(component?.id) && foodComponents[index]?.id === component.id;
  const isSwapped = foodComponents[index]?.id !== component.id;
  const isSelected = selectedComponent?.id === component?.id && index === selectedComponentIndex;
  const borderColor =
    isSelected && !isRemoved
      ? caloTheme.palette.primary500
      : isRemoved && isSelected
        ? caloTheme.palette.red
        : caloTheme.palette.neutral100;
  return (
    <Grid item key={index}>
      <Box
        key={component.id}
        onClick={() => {
          setSelectedComponent(component);
          setSelectedComponentIndex(index);
        }}
        sx={{ ...styles.componentCard, borderColor }}
      >
        {isSwapped && !isRemoved && (
          <Stack sx={{ ...styles.removeComponentBox }}>
            <Typography sx={{ ...styles.swappedText }}>Swapped</Typography>
          </Stack>
        )}
        {selectedRemovedComponentIdList?.includes(component.id) && foodComponents[index].id === component.id && (
          <Stack sx={{ ...styles.removeComponentBox }}>
            <Typography sx={{ ...styles.removedText }}>Removed</Typography>
          </Stack>
        )}
        <ComponentCard
          component={component}
          selectedComponent={selectedComponent}
          radioCheck={false}
          foodComponents={foodComponents}
          selectedComponentIndex={selectedComponentIndex}
          selectedRemovedComponentIdList={selectedRemovedComponentIdList}
        />
      </Box>
    </Grid>
  );
};

export default ComponentItem;
