import Axios, { AxiosRequestConfig } from 'axios';
import { merge } from 'lodash-es';

import { SearchQuery } from '@calo/dashboard-types';

import client from 'lib/client';
import { Action, ViewMode } from 'lib/enums';

export const toggleViewMode = () => ({
  type: Action.UI_TOGGLE_VIEW_MODE
});

export const setViewMode = (viewMode: ViewMode) => ({
  type: Action.UI_SET_VIEW_MODE,
  payload: {
    viewMode
  }
});

export const toggleUISettings = () => ({
  type: Action.UI_TOGGLE_SETTINGS
});

export const setUISettingsVisible = (settings: boolean) => ({
  type: Action.UI_SET_SETTINGS_VISILE,
  payload: {
    settings
  }
});

export const toggleUIKitchenSettings = () => ({
  type: Action.UI_TOGGLE_KITCHEN_SETTINGS
});

export const toggleUICouponSettings = () => ({
  type: Action.UI_TOGGLE_COUPON_SETTINGS
});

export const setUIKitchenSettingsVisible = (kitchenSettings: boolean) => ({
  type: Action.UI_SET_KITCHEN_SETTINGS_VISILE,
  payload: {
    kitchenSettings
  }
});

export const setUICouponSettingsVisible = (couponSettings: boolean) => ({
  type: Action.UI_SET_COUPON_SETTINGS_VISILE,
  payload: {
    couponSettings
  }
});

export const quickSearch = async (query: SearchQuery) => {
  const { data } = await client.get('/search', { params: query });

  return data.data;
};

export const getImageUploadLink = async (path: string, extendedPath = '/original') => {
  const { data } = await client.get('/image-link', {
    params: {
      path,
      extendedPath
    }
  });
  return data;
};

export const uploadImage = (url: string, formData: FormData, config: AxiosRequestConfig) =>
  Axios.post(
    url,
    formData,
    merge(config, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  );
