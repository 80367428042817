import { DeliveryTime, Kitchen } from '@calo/types';
import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';

type DeliveryTimePickerProps = {
  isEditing: boolean;
  isApplyForAllDays: boolean;
  deliveryTime: DeliveryTime;
  deliveryDay?: string;
  changeDeliveryTime: (applyForAllDays: boolean, deliveryTime: DeliveryTime, day?: string) => void;
  kitchen?: Kitchen;
};

export const DeliveryTimePicker = ({
  isEditing,
  deliveryDay,
  deliveryTime,
  changeDeliveryTime,
  kitchen,
  isApplyForAllDays = false
}: DeliveryTimePickerProps) => {
  const deliveryTimeValues = {
    [DeliveryTime.morning]: kitchen === Kitchen.GB1 ? '☀️ Early Morning (2AM to 6AM)' : '☀️ Early Morning (7AM to 11AM)',
    [DeliveryTime.evening]: '🌙 Night Before (6PM to 10PM)'
  };
  return (
    <Grid item xs={4}>
      {isEditing ? (
        <FormControl sx={{ width: '100%' }}>
          <Select
            value={deliveryTime}
            onChange={(e) => changeDeliveryTime(isApplyForAllDays, e.target.value as DeliveryTime, deliveryDay)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {Object.values(DeliveryTime).map((deliveryTime) => (
              <MenuItem value={deliveryTime}>{deliveryTimeValues[deliveryTime]}</MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Typography sx={{ padding: '15px 16.5px 16px 14px' }}>{deliveryTimeValues[deliveryTime]}</Typography>
      )}
    </Grid>
  );
};
