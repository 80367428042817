import {
  addAddonFood,
  cancelDelivery,
  getInvoiceForDelivery,
  regenerateDeliveryMenu,
  resizeDeliveryFoodAlgorithm,
  skipDelivery,
  uncancelDelivery,
  unskipDelivery,
  updateDelivery
} from 'actions';
import mutation from 'actions/mutation';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components';
import Popup from 'components/Popup';
import { getWeek, parseISO } from 'date-fns';
import { endOfWeek, getYear, isBefore } from 'date-fns/fp';
import { createAutoDownloadLinkFromUrl } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { Delivery, Menu as FoodMenu, MenuFood } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import { updateDeliveryPayment } from 'mutations';
import { Fragment, useRef, useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation } from 'react-query';
import MenuFoodPicker from 'views/Deliveries/ExactDelivery/MenuTab/MenuFoodPicker';

import { AddonAddedAsType, Permission } from '@calo/dashboard-types';
import { PermissionService } from '@calo/services';
import { DeliveryStatus, DietType, FoodType } from '@calo/types';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material';

import AddonsPickerPopup from './AddonsPickerPopup';
import DeliveryPayment from './DeliveryPayment';

interface DeliveryActionProps {
  menu: FoodMenu;
  subscription: any;
  delivery: Delivery;
  refetchAllDeliveries: () => void;
  refetchDelivery: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  setFetchMenu: (value: boolean) => void;
  menuLoading: boolean;
}

const getInvoiceAsPDF = async (deliveryId: string) => {
  const response = await getInvoiceForDelivery(deliveryId);
  if (response.status === 200 && response.data.data && response.data.data.length > 0) {
    createAutoDownloadLinkFromUrl(response.data.data);
  }
};

const DeliveryAction = ({ menu, delivery, subscription, refetchDelivery, setFetchMenu, menuLoading }: DeliveryActionProps) => {
  const roles = useUserRoles();
  const addonModalRef = useRef<ModalRef>();
  const paymentDeliveryRef = useRef<ModalRef>();

  const [isGifted, setIsGifted] = useState<boolean>(false);
  const [addonType, setAddonType] = useState(FoodType.coffee);
  const [selectedAddon, setSelectedAddon] = useState<MenuFood | null>(null);
  const [selectedAddType, setSelectedAddType] = useState<AddonAddedAsType>(AddonAddedAsType.addon);

  const week = getWeek(new Date(delivery.day));
  const year = getYear(endOfWeek(new Date(delivery.day)));

  const updateMutation = updateDeliveryPayment();
  const { mutateAsync: skipMutation } = useMutation(skipDelivery);
  const { mutateAsync: unskipMutation } = useMutation(unskipDelivery);
  const { mutateAsync: cancelMutation } = useMutation(cancelDelivery);
  const { mutateAsync: uncancelMutation } = useMutation(uncancelDelivery);
  const { mutateAsync: regenerateMutation } = useMutation(regenerateDeliveryMenu);
  const { mutateAsync: resizeAlgorithmMutation } = useMutation(resizeDeliveryFoodAlgorithm);
  const { mutateAsync: addAddonMutation } = useMutation(addAddonFood);

  const [deliveryActionMenuAnchorEl, setDeliveryActionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isExportMenuOpened = Boolean(deliveryActionMenuAnchorEl);

  const handleAddAddonRequest = async (mealAdded: MenuFood | null) => {
    if (!mealAdded) return;
    await addAddonMutation(
      { foodType: addonType, foodId: mealAdded.id, id: delivery.id, isGifted: isGifted, addedAs: selectedAddType },
      {
        onSuccess: (data) => {
          const query = queryClient.getQueryData(['deliveries', delivery.id]) as any;
          const deliveryUpdated = { ...query, addons: data.addons, cost: data.cost };
          mutation(['deliveries', delivery.id], deliveryUpdated);
          setSelectedAddon(null);
          setIsGifted(false);
          addonModalRef.current?.close();
          refetchDelivery();
        }
      }
    );
  };

  const handleRegenerateDeliveryMenu = async (deliveryId: string) => {
    await regenerateMutation(
      { id: deliveryId, isCustomMacros: false },
      {
        onSuccess: (data) => {
          const query = queryClient.getQueryData(['deliveries', delivery.id]) as any;
          const deliveryUpdated = { ...query, ...data };
          mutation(['deliveries', delivery.id], deliveryUpdated);
          refetchDelivery();
        }
      }
    );
  };

  const handleResizeAlgorithm = async (deliveryId: string) => {
    await resizeAlgorithmMutation(
      { id: deliveryId },
      {
        onSuccess: () => refetchDelivery()
      }
    );
  };

  const handleApplyPayment = async () => {
    await updateMutation(delivery, subscription);
    paymentDeliveryRef.current?.close();
  };

  return (
    <div>
      <Fragment>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <LoadingButton
            loading={false}
            id="delivery-action-button"
            aria-haspopup="true"
            disableElevation
            onClick={(event) => {
              !deliveryActionMenuAnchorEl && setDeliveryActionMenuAnchorEl(event.currentTarget);
            }}
            variant="outlined"
            aria-label="meal-actions"
            sx={{
              height: '45px',
              mt: '-8px',
              width: 'auto',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '17px',
              textTransform: 'none',
              color: caloTheme.palette.neutral900,
              border: 'none',
              '&:hover': {
                backgroundColor: caloTheme.palette.neutral100,
                color: caloTheme.palette.neutral900,
                border: 'none'
              },
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                justifyItems: 'center',
                margin: 'auto',
                my: 4,
                width: 'auto'
              }
            }}
            endIcon={<ArrowDownIcon />}
          >
            Delivery Action
          </LoadingButton>
          <Menu
            MenuListProps={{
              'aria-labelledby': 'export-button'
            }}
            anchorEl={deliveryActionMenuAnchorEl}
            open={isExportMenuOpened}
            onClose={() => setDeliveryActionMenuAnchorEl(null)}
          >
            {roles.includes(Permission.REGENERATE_DELIVERY_MENU) && (
              <MenuItem
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                onClick={() => {
                  handleRegenerateDeliveryMenu(delivery.id);
                  setDeliveryActionMenuAnchorEl(null);
                }}
              >
                <Box>Regenerate Menu</Box>
              </MenuItem>
            )}
            {subscription.plan.dietType === DietType.customMacros &&
              (roles.includes(Permission.REGENERATE_DELIVERY_MENU) || roles.includes(Permission.REPLACE_FOOD_DELIVERY_MENU)) && (
                <MenuItem
                  sx={{ fontWeight: 600, py: 1, mr: 2 }}
                  onClick={() => {
                    handleResizeAlgorithm(delivery.id);
                    setDeliveryActionMenuAnchorEl(null);
                  }}
                >
                  <Box>Resize Algorithm</Box>
                </MenuItem>
              )}
            {delivery.status !== DeliveryStatus.cancelled && roles.includes(Permission.CANCEL_DELIVERY) && (
              <MenuItem
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                onClick={() => {
                  cancelMutation(delivery.id, {
                    onSuccess: async () => {
                      refetchDelivery();
                    }
                  });
                  setDeliveryActionMenuAnchorEl(null);
                }}
              >
                <Box>Cancel Delivery</Box>
              </MenuItem>
            )}
            {delivery.status !== DeliveryStatus.cancelled &&
              delivery.skipped &&
              PermissionService.deliveryCanBeEdited(delivery) &&
              roles.includes(Permission.UNSKIP_DELIVERY) && (
                <MenuItem
                  sx={{ fontWeight: 600, py: 1, mr: 2 }}
                  onClick={() => {
                    unskipMutation(delivery.id, {
                      onSuccess: () => {
                        refetchDelivery();
                      }
                    });
                    setDeliveryActionMenuAnchorEl(null);
                  }}
                >
                  <Box>UnSkip Delivery</Box>
                </MenuItem>
              )}
            {delivery.status !== DeliveryStatus.cancelled &&
              !delivery.skipped &&
              PermissionService.deliveryCanBeEdited(delivery) &&
              roles.includes(Permission.SKIP_DELIVERY) && (
                <MenuItem
                  sx={{ fontWeight: 600, py: 1, mr: 2 }}
                  onClick={() => {
                    !delivery!.skipped &&
                      skipMutation(delivery.id, {
                        onSuccess: () => {
                          refetchDelivery();
                        }
                      });
                    setDeliveryActionMenuAnchorEl(null);
                  }}
                >
                  <Box>Skip Delivery</Box>
                </MenuItem>
              )}
            {delivery.status === DeliveryStatus.paused && roles.includes(Permission.UPDATE_DELIVERY) && (
              <MenuItem
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                onClick={() => {
                  updateDelivery({ status: DeliveryStatus.paymentRequired, id: delivery.id });
                  refetchDelivery();
                  setDeliveryActionMenuAnchorEl(null);
                }}
              >
                <Box>Resume Delivery</Box>
              </MenuItem>
            )}
            {delivery.status === DeliveryStatus.cancelled && roles.includes(Permission.UNCANCEL_DELIVERY) && (
              <MenuItem
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                onClick={() => {
                  uncancelMutation(delivery.id, {
                    onSuccess: () => {
                      refetchDelivery();
                    }
                  });
                  setDeliveryActionMenuAnchorEl(null);
                }}
              >
                <Box>Uncancel Delivery</Box>
              </MenuItem>
            )}
            {delivery.status !== DeliveryStatus.cancelled && roles.includes(Permission.GET_DELIVERY_INVOICE) && (
              <MenuItem
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                onClick={() => {
                  getInvoiceAsPDF(delivery.id);
                  setDeliveryActionMenuAnchorEl(null);
                }}
              >
                <Box>Get Invoice</Box>
              </MenuItem>
            )}
            {roles.includes(Permission.CREATE_ADDONS_DELIVERY_MENU) && (
              <MenuItem
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                onClick={() => {
                  setSelectedAddType(AddonAddedAsType.meal);
                  addonModalRef.current?.open();
                  setFetchMenu(true);
                  setDeliveryActionMenuAnchorEl(null);
                }}
              >
                <Box>Add Meal</Box>
              </MenuItem>
            )}
            {roles.includes(Permission.CREATE_ADDONS_DELIVERY_MENU) && (
              <MenuItem
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                onClick={() => {
                  setSelectedAddType(AddonAddedAsType.addon);
                  addonModalRef.current?.open();
                  setFetchMenu(true);
                  setDeliveryActionMenuAnchorEl(null);
                }}
              >
                <Box>Add Addon</Box>
              </MenuItem>
            )}
            {[DeliveryStatus.upcoming, DeliveryStatus.paymentRequired].includes(delivery.status) &&
              delivery.cost !== delivery.paidAmount &&
              subscription.balance[subscription.currency] - delivery.cost >= 0 &&
              isBefore(PermissionService.getMinActionDate(subscription.deliveryDays, Date.now()))(parseISO(delivery.day)) && (
                <MenuItem
                  sx={{ fontWeight: 600, py: 1, mr: 2 }}
                  onClick={() => {
                    paymentDeliveryRef.current?.open();
                    setDeliveryActionMenuAnchorEl(null);
                  }}
                >
                  <Box>Pay Cost</Box>
                </MenuItem>
              )}
            {/* {isBefore(PermissionService.getMinActionDate(subscription.deliveryDays, Date.now()))(parseISO(delivery.day)) && (
              <MenuItem
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                onClick={() => {
                  customDeliveryTimeRef.current?.open();
                  setDeliveryActionMenuAnchorEl(null);
                }}
              >
                <Box>Change Delivery Time</Box>
              </MenuItem>
            )} */}
          </Menu>
        </Grid>
      </Fragment>

      <Popup
        ref={paymentDeliveryRef}
        title={'Delivery Payment'}
        maxWidth={'sm'}
        onClose={() => paymentDeliveryRef.current?.close()}
      >
        <DeliveryPayment onApplyPayment={handleApplyPayment} subscription={subscription} delivery={delivery} />
      </Popup>

      <Popup
        ref={addonModalRef}
        maxWidth={'lg'}
        onClose={() => {
          addonModalRef.current?.close();
          setSelectedAddon(null);
        }}
      >
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ mb: 2 }}>
          <Stack>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '19px',
                fontFamily: caloTheme.typography.fontFamily,
                textTransform: 'uppercase',
                mb: '-4px'
              }}
            >
              {selectedAddType === AddonAddedAsType.addon ? 'Add new addon' : 'Add new meal'}
            </Typography>
          </Stack>
        </Box>
        {selectedAddType === AddonAddedAsType.addon ? (
          <AddonsPickerPopup
            year={year}
            week={week}
            delivery={delivery}
            isGifted={isGifted}
            addonType={addonType}
            setIsGifted={setIsGifted}
            country={delivery.country}
            setAddonType={setAddonType}
            addonModalRef={addonModalRef}
            selectedAddon={selectedAddon}
            setSelectedAddon={setSelectedAddon}
            handleAddAddonRequest={handleAddAddonRequest}
          />
        ) : (
          <MenuFoodPicker
            menu={menu}
            gifted={true}
            delivery={delivery}
            isGifted={isGifted}
            setIsGifted={setIsGifted}
            setMealType={setAddonType}
            menuModalRef={addonModalRef}
            refetchDelivery={refetchDelivery}
            deliveryBalance={delivery.balance}
            handleAddMealRequest={(v) => handleAddAddonRequest(v)}
            menuLoading={menuLoading}
          />
        )}
      </Popup>

      {/* <Popup
        fullWidth
        title={'Enter Time'}
        ref={customDeliveryTimeRef}
        onClose={() => customDeliveryTimeRef.current?.close()}
      >
        <CustomTimePicker
          customTime={subscription.deliveryAddresses[0].customDeliveryTime}
          addresses={subscription.deliveryAddresses}
          closePopup={() => customDeliveryTimeRef.current?.close()}
          onSubmit={(customDate, allDeliveries) => console.log(customDate, allDeliveries)}
          defaultAddress={delivery.deliveryAddress}
        />
      </Popup> */}
    </div>
  );
};
export default DeliveryAction;
