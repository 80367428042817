import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  deleteButton: {
    gap: '10px',
    height: '40px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    padding: '10px 16px 10px 16px',
    width: '50%',
    color: caloTheme.palette.neutral900,
    '&:hover': {
      backgroundColor: caloTheme.palette.neutral100,
      boxShadow: 'none'
    }
  },
  applyButton: {
    gap: '10px',
    height: '40px',
    color: 'white',
    fontWeight: 600,
    width: '50%',
    fontSize: '16px',
    boxShadow: 'none',
    lineHeight: '20px',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    padding: '10px 16px 10px 16px',
    backgroundColor: caloTheme.palette.primary500,
    '&:hover': {
      backgroundColor: caloTheme.palette.primary600,
      boxShadow: 'none'
    }
  },
  divider: {
    color: caloTheme.palette.neutral100,
    width: '100%',
    height: '1px',
    marginTop: '10px',
    marginBottom: '10px',
    borderRadius: '8px'
  }
};

export default styles;
