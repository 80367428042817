import { FoodTags, FoodTagsKeys } from '@calo/dashboard-types';
import { FoodType } from '@calo/types';
import { Grid } from '@mui/material';
import { Chips } from 'components';

interface FoodTagsChipsProps {
  foodTags?: FoodTags;
  mealType: FoodType[];
}

const FoodTagsChips = ({ foodTags, mealType }: FoodTagsChipsProps) => {
  const relevantTagKey = mealType.includes(FoodType.snack) ? FoodTagsKeys.snacktype : FoodTagsKeys.base;

  const relevantTag = foodTags?.find((tag) => tag.key === relevantTagKey);
  if (!relevantTag || relevantTag.value.length === 0) return null;

  return (
    <Grid item>
      <Chips keyProp={`${relevantTagKey}-0`} label={relevantTag.value[0]} more={false} styleOverrides={{}} onClick={undefined} />
    </Grid>
  );
};

export default FoodTagsChips;
