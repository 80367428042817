import { Macros } from '@calo/types';
import { macrosSortOptions } from 'lib/constants';
import { MenuFood } from 'lib/interfaces';
import { groupBy } from 'lodash-es';

export const calculateCalories = (macros: Macros | undefined) => {
  return macros ? macros.fat * 9 + macros?.carbs * 4 + macros?.protein * 4 : 0;
};

export const sortFoodByMacros = (sortFilter: string, food: MenuFood[]) => {
  switch (sortFilter) {
    case macrosSortOptions.highestToLowestProtein:
      return Object.values(groupBy(food, 'name.en'))
        .map((r) => r[0])
        .sort((a, b) => (b.macros?.protein ?? 0) - (a.macros?.protein ?? 0));
    case macrosSortOptions.highestToLowestCarb:
      return Object.values(groupBy(food, 'name.en'))
        .map((r) => r[0])
        .sort((a, b) => (b.macros?.carbs ?? 0) - (a.macros?.carbs ?? 0));
    case macrosSortOptions.lowestToHighestFat:
      return Object.values(groupBy(food, 'name.en'))
        .map((r) => r[0])
        .sort((a, b) => (a.macros?.fat ?? 0) - (b.macros?.fat ?? 0));
    default:
      return Object.values(groupBy(food, 'name.en')).map((r) => r[0]);
  }
};
