import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { capitalize, chunk, compact, flatten, orderBy, uniq, uniqBy } from 'lodash-es';
import { toast } from 'react-toastify';

import { FoodDietType, FoodType } from '@calo/types';

import cairoBold from 'assets/fonts/cairo-bold';
import cairoRegular from 'assets/fonts/cairo-regular';
import tahomaBold from 'assets/fonts/tahoma-bold';
import tahomaRegular from 'assets/fonts/tahoma-regular';
import { ArabicFoodTags, ArabicFoodType } from 'lib/helpers';

const getMappingList = (extraMealsList: any[], lang: string) => {
  const foodListData = extraMealsList?.map((food) =>
    food.sizes
      .filter((f: any) => f.size !== 'C')
      .map((fs: any) => ({
        id: food.id,
        foodSize: fs.size,
        foodId: fs.id,
        allergic: food.allergic,
        nameEn: food.name.en,
        nameAr: food.name.ar,
        type: food.type,
        tags: food.tags
          .map((tag: string | undefined) => (lang === 'En' ? capitalize(tag) : ArabicFoodTags(tag as FoodDietType)))
          .join(lang === 'En' ? ', ' : ' ، '),
        extraMeals: fs.extraMeals || 0
      }))
  );
  const orderedSizes = uniqBy(
    orderBy(
      flatten(foodListData),
      (f) => f.tags.length === 1 && (f.tags.includes(FoodDietType.lowCarb) || f.tags.includes(FoodDietType.highProtein)),
      ['asc']
    ).filter((m) => m.extraMeals && m.extraMeals !== 0),
    'id'
  );

  const mappingList: { key: string; meals: { name: string; id: string; tags: string }[] }[] = [];
  Object.values(FoodType).map(
    (foodType) =>
      foodType !== FoodType.dinner &&
      mappingList.push({
        key: lang === 'En' ? (foodType === FoodType.lunch ? 'Lunch & Dinner' : capitalize(foodType)) : ArabicFoodType(foodType),
        meals: compact(
          uniq(
            flatten(orderedSizes).map(
              (meal) =>
                meal.type.includes(foodType) && {
                  name: lang === 'En' ? meal.nameEn : meal.nameAr,
                  id: meal.foodId,
                  tags: meal.tags
                }
            )
          )
        )
      })
  );

  if (mappingList.every((m) => m.meals.length === 0)) {
    toast('No Meals available', { type: 'error', autoClose: 2000 });
    return [];
  }

  return mappingList;
};

const getCells = (meals: any, lang: string) => {
  const chunks: { name: string; id: string; tags: string }[][] = chunk(meals, 2);
  const firstRowPadding = { top: 4, left: lang === 'En' ? 0 : 4, right: lang === 'En' ? 4 : 0, bottom: 0 };
  const secondRowPadding = { top: 0, left: lang === 'En' ? 0 : 4, right: lang === 'En' ? 4 : 0, bottom: 3 };
  const imageWidth = 20;
  const cellWidth = 70;
  const mealFontSize = 14;
  const tagsFontSize = 12;

  const cells = chunks.map((chunk) => {
    return lang === 'En'
      ? [
          [
            {
              content: '',
              id: chunk[0].id,
              styles: {
                cellWidth: imageWidth,
                rowSpan: 2,
                cellPadding: firstRowPadding
              }
            },
            {
              content: chunk[0].name,
              id: chunk[0].id,
              styles: {
                cellWidth: cellWidth,
                fontSize: mealFontSize,
                cellPadding: firstRowPadding
              }
            },
            {
              content: '',
              id: chunk[1]?.id || '',
              styles: {
                cellWidth: imageWidth,
                rowSpan: 2,
                cellPadding: firstRowPadding
              }
            },
            {
              content: chunk[1]?.name || '',
              id: chunk[1]?.id || '',
              styles: {
                cellWidth: cellWidth,
                fontSize: mealFontSize,
                cellPadding: firstRowPadding
              }
            }
          ],
          [
            {
              content: '',
              id: '',
              styles: {
                cellWidth: imageWidth,
                cellPadding: secondRowPadding
              }
            },
            {
              content: chunk[0].tags,
              id: chunk[0].id,
              styles: {
                cellWidth: cellWidth,
                fontSize: tagsFontSize,
                cellPadding: secondRowPadding
              }
            },
            {
              content: '',
              id: '',
              styles: {
                cellWidth: imageWidth,
                cellPadding: secondRowPadding
              }
            },
            {
              content: chunk[1]?.tags || '',
              id: chunk[1]?.id || '',
              styles: {
                cellWidth: cellWidth,
                fontSize: tagsFontSize,
                cellPadding: secondRowPadding
              }
            }
          ]
        ]
      : [
          [
            {
              content: chunk[1]?.name || '',
              id: chunk[1]?.id || '',
              styles: {
                cellWidth: cellWidth,
                fontSize: mealFontSize,
                cellPadding: firstRowPadding
              }
            },
            {
              content: '',
              id: chunk[1]?.id || '',
              styles: {
                cellWidth: imageWidth,
                rowSpan: 2,
                cellPadding: firstRowPadding
              }
            },
            {
              content: chunk[0]?.name || '',
              id: chunk[0]?.id || '',
              styles: {
                cellWidth: cellWidth,
                fontSize: mealFontSize,
                cellPadding: firstRowPadding
              }
            },
            {
              content: '',
              id: chunk[0]?.id || '',
              styles: {
                cellWidth: imageWidth,
                rowSpan: 2,
                cellPadding: firstRowPadding
              }
            }
          ],
          [
            {
              content: chunk[1]?.tags || '',
              id: chunk[1]?.id || '',
              styles: {
                cellWidth: cellWidth,
                fontSize: tagsFontSize,
                cellPadding: secondRowPadding
              }
            },
            {
              content: '',
              id: '',
              styles: {
                cellWidth: imageWidth,
                cellPadding: secondRowPadding
              }
            },
            {
              content: chunk[0]?.tags || '',
              id: chunk[0]?.id || '',
              styles: {
                cellWidth: cellWidth,
                fontSize: tagsFontSize,
                cellPadding: secondRowPadding
              }
            },
            {
              content: '',
              id: '',
              styles: {
                cellWidth: imageWidth,
                cellPadding: secondRowPadding
              }
            }
          ]
        ];
  });

  return cells;
};
export const downloadPDF = (extraMealsList: any[], lang: string) => {
  const doc = new jsPDF();
  doc.addFileToVFS('Cairo-Bold.ttf', cairoBold);
  doc.addFont('Cairo-Bold.ttf', 'Cairo', 'bold');
  doc.addFileToVFS('Cairo-Regular.ttf', cairoRegular);
  doc.addFont('Cairo-Regular.ttf', 'Cairo', 'normal');
  doc.addFileToVFS('Tahoma-Regular.ttf', tahomaRegular);
  doc.addFont('Tahoma-Regular.ttf', 'Tahoma', 'normal');
  doc.addFileToVFS('Tahoma-Bold.ttf', tahomaBold);
  doc.addFont('Tahoma-Bold.ttf', 'Tahoma', 'bold');

  const mappingList = getMappingList(extraMealsList, lang);

  doc.setFontSize(23);
  if (lang === 'En') {
    doc.setFont('Cairo', 'bold');
  } else {
    doc.setFont('Tahoma', 'bold');
  }
  doc.text(lang === 'En' ? 'MEALS' : 'الوجبات', lang === 'En' ? 13 : 165, 10);
  for (const foodType of mappingList) {
    if (foodType.meals.length > 0) {
      const cells = getCells(foodType.meals, lang);

      autoTable(doc, {
        head: [
          [
            {
              content: foodType.key,
              colSpan: 4
            }
          ]
        ],
        body: [...(flatten(cells) as RowInput[])],
        headStyles: {
          halign: lang === 'En' ? 'left' : 'right',
          lineWidth: 0,
          fontStyle: 'bold',
          font: lang === 'En' ? 'Cairo' : 'Tahoma',
          fontSize: 16,
          cellPadding: { top: 2, left: lang === 'En' ? 0 : 4, right: lang === 'En' ? 4 : 0, bottom: 0 }
        },
        bodyStyles: { halign: lang === 'En' ? 'left' : 'right', lineWidth: 0, font: 'Tahoma' },
        theme: 'plain',
        didDrawCell: (data) => {
          //@ts-ignore
          if (data.section === 'body' && data.cell.raw.content === '' && data.cell.raw.id !== '') {
            //@ts-ignore
            doc.addImage(
              `${process.env.REACT_APP_BUCKET_URL}/food/${(data.cell.raw as any).id}/square@1x.jpg`,
              'JPEG',
              data.cell.x + (lang === 'En' ? 0 : 5),
              data.cell.y + 4,
              15,
              15
            );
          }
        }
      });
    }
  }

  doc.save(`Extra Meals ${new Date()}.pdf`);
};
