import { KitchenLogType } from '@calo/dashboard-types';
import { selectedFoodData } from 'actions/food';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { FoodLogInterface } from 'lib/interfaces';
import { toast } from 'react-toastify';

export const linkTo = async (log: FoodLogInterface) => {
  let selectedData;
  switch (log.type) {
    case KitchenLogType.food:
      selectedData = await selectedFoodData(log.data.id);
      return selectedData['deletedAt']
        ? toast(`${log.type} ${log.data.name} was deleted`, { type: 'error', autoClose: 2000 })
        : history.push(Routes.foodSlug.replace(':slug', selectedData.slug));
    case KitchenLogType.foodComponent:
      return history.push(Routes.foodComponent.replace(':id', log.data.id));
    case KitchenLogType.ingredient:
      return history.push(Routes.ingredient.replace(':id', log.data.id));
    default:
      selectedData = await selectedFoodData(log.data.id);
      return Routes.foodSlug.replace(':slug', selectedData.slug);
  }
};
