import ExcelJS from 'exceljs';
import { flattenDepth } from 'lodash-es';
import { useDropzone } from 'react-dropzone';
import { Button } from '..';

interface FileUploaderProps {
  setExList: (value: any) => void;
  exList: any;
  isDisabled: boolean;
}

export default ({ setExList, exList, isDisabled }: FileUploaderProps) => {
  const accept = ['.csv', '.xls', '.xlsx'];
  const { getRootProps, getInputProps } = useDropzone({ accept });

  const readExcel = (file: any) => {
    const wb = new ExcelJS.Workbook();
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.addEventListener('load', () => {
      const buffer = fileReader.result! as any;
      wb.xlsx.load(buffer).then((workbook) => {
        const dataRow: any = [];
        workbook.eachSheet((sheet) => {
          sheet.eachRow((row: any) => {
            if (!dataRow.includes(row.values.slice(1).toString())) {
              dataRow.push(row.values.slice(1).toString());
            }
          });
          setExList({ ...exList, value: flattenDepth(dataRow) });
        });
      });
    });
  };

  return (
    <div className="file " {...getRootProps()}>
      <div className="file-label">
        <input
          className="file-input"
          disabled={isDisabled}
          {...getInputProps()}
          onChange={(e) => {
            const file = e.target.files && e.target.files[0];
            readExcel(file);
          }}
        />
        <Button disabled={isDisabled} content="Import" primary>
          <i className="fas fa-upload"></i>
          <span className={'file-icon text-black'}></span>
        </Button>
      </div>
    </div>
  );
};
