import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

interface SelectMultiPreferenceProps {
  isEditing: boolean;
  isMultiPreference: boolean;
  setIsMultiPreference: (isMultiPreference: boolean) => void;
  setSingleDeliveryAddress: () => void;
}

const SelectMultiPreference = ({
  isEditing,
  isMultiPreference,
  setIsMultiPreference,
  setSingleDeliveryAddress
}: SelectMultiPreferenceProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <FormControl sx={{ width: '100%' }}>
        <RadioGroup sx={{ width: '100%' }}>
          <FormControlLabel
            sx={[
              {
                padding: '16px',
                borderRadius: '8px',
                width: '100%',
                margin: '16px 0px',
                border: isMultiPreference ? '1px solid #B1B1B1' : '',
                backgroundColor: isMultiPreference ? '' : '#EAF7F2'
              }
            ]}
            disabled={!isEditing}
            label={
              <>
                <Typography
                  sx={{
                    fontFamily: caloTheme.typography.fontFamily,
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: caloTheme.palette.neutral900
                  }}
                >
                  Similar days and timing
                </Typography>
                <Typography
                  sx={{
                    fontFamily: caloTheme.typography.fontFamily,
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: caloTheme.palette.neutral900
                  }}
                >
                  Get all your deliveries to the selected day and time of the week
                </Typography>
              </>
            }
            control={
              <Radio
                color="default"
                onClick={() => {
                  setIsMultiPreference(false);
                  setSingleDeliveryAddress();
                }}
                sx={{
                  cursor: 'pointer',
                  color: isMultiPreference ? '' : caloTheme.palette.primary500,
                  '&.Mui-disabled': {
                    color: isMultiPreference ? '' : caloTheme.palette.primary500
                  }
                }}
              />
            }
            checked={!isMultiPreference}
          />
          <FormControlLabel
            sx={{
              padding: '16px',
              borderRadius: '8px',
              width: '100%',
              margin: '0px',
              border: isMultiPreference ? '' : '1px solid #B1B1B1',
              backgroundColor: isMultiPreference ? '#EAF7F2' : ''
            }}
            disabled={!isEditing}
            label={
              <>
                <Typography
                  sx={{
                    fontFamily: caloTheme.typography.fontFamily,
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: caloTheme.palette.neutral900
                  }}
                >
                  Different days and timing
                </Typography>
                <Typography
                  sx={{
                    fontFamily: caloTheme.typography.fontFamily,
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: caloTheme.palette.neutral900
                  }}
                >
                  Choose the address and time that suits you for each day of the week
                </Typography>
              </>
            }
            control={
              <Radio
                color="default"
                onClick={() => setIsMultiPreference(true)}
                sx={{
                  cursor: 'pointer',
                  color: isMultiPreference ? caloTheme.palette.primary500 : '',
                  '&.Mui-disabled': {
                    color: isMultiPreference ? caloTheme.palette.primary500 : ''
                  }
                }}
              />
            }
            checked={isMultiPreference}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default SelectMultiPreference;
