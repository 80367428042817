import { useState } from 'react';

import { getListWithParams } from 'actions';
import { CaloLoader } from 'components';
import { resolveCountryFromKitchen } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { FoodPackageElementFilters } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';

import PackagingElementsListHeaderCard from './PackageElementHeaderCard';
import PackageElementListCard from './PackageElementListCard';
import Settings from './Settings';

const PackageElementList = () => {
  const history = useHistory();
  const userKitchen = useUserKitchens();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState<FoodPackageElementFilters>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });
  const { data, isLoading } = useQuery<any, Error, { data: any[] }>(
    [
      'food-package-elements',
      { limit: 1000, filters: { country: filters.country, brand: filters.brand, kitchen: filters.kitchen } }
    ],
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  const elements = data?.data || [];
  const isDisabled = false;

  return (
    <>
      <PackagingElementsListHeaderCard filters={filters} history={history} packagingElements={elements} isDisabled={isDisabled} />
      {isLoading ? <CaloLoader /> : <PackageElementListCard packagingElements={elements} isLoading={isLoading} />}
      <Settings onFilter={setFilters} filters={filters} />
    </>
  );
};

export default PackageElementList;
