import { Box, Card, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';

const HeaderCard = () => {
  return (
    <Card
      variant="outlined"
      sx={{
        width: 'full',
        mb: 2,
        border: 'none',
        borderRadius: '8px',
        paddingBottom: '4px',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column'
        }
      }}
    >
      <Box
        display={'flex'}
        flexDirection="row"
        justifyContent={'space-between'}
        sx={{
          padding: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
        width="100%"
      >
        <Stack width={'50%'} display={'flex'} flexDirection={'row'} alignItems="start" justifyContent={'space-between'}>
          <Typography
            variant="h3"
            sx={{
              mt: 'auto',
              fontWeight: 600,
              fontSize: '32px',
              lineHeight: '40px',
              color: caloTheme.palette.neutral900,
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            Affiliate Program
          </Typography>
        </Stack>
      </Box>
    </Card>
  );
};

export default HeaderCard;
