import { FoodDietType, FoodType } from '@calo/types';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { MenuDraft } from 'lib/interfaces';
import { startCase } from 'lodash-es';
import { useState } from 'react';
import { ModalRef } from '../Modal';
import styles from './styles';

interface TrackMealsPopupProps {
  mealOptions: MenuDraft;
  setOptions: (v: MenuDraft) => void;
  trackMealsRef: React.MutableRefObject<ModalRef | undefined>;
}

const TrackMealsPopup = ({ mealOptions, setOptions, trackMealsRef }: TrackMealsPopupProps) => {
  const [trackOptions, setTrackOptions] = useState(mealOptions);

  const handleIncrement = (
    category: FoodType.breakfast | FoodType.lunch | FoodType.snack,
    mainCategory:
      | FoodDietType.balanced
      | FoodDietType.gourmet
      | FoodDietType.highProtein
      | FoodDietType.lowCarb
      | FoodDietType.vegetarian,
    value: number,
    subCategory?: string
  ) => {
    setTrackOptions((prevOptions) => ({
      ...prevOptions,
      [category]: {
        ...prevOptions[category],
        [mainCategory]: subCategory
          ? {
              // @ts-ignore
              ...prevOptions[category][mainCategory],
              [subCategory]: value + 1
            }
          : value + 1
      }
    }));
  };

  const handleDecrement = (
    category: FoodType.breakfast | FoodType.lunch | FoodType.snack,
    mainCategory:
      | FoodDietType.balanced
      | FoodDietType.gourmet
      | FoodDietType.highProtein
      | FoodDietType.lowCarb
      | FoodDietType.vegetarian,
    value: number,
    subCategory?: string
  ) => {
    setTrackOptions((prevOptions) => ({
      ...prevOptions,
      [category]: {
        ...prevOptions[category],
        [mainCategory]: subCategory
          ? {
              // @ts-ignore
              ...prevOptions[category][mainCategory],
              [subCategory]: value - 1
            }
          : value - 1
      }
    }));
  };

  return (
    <Box flexDirection={'row'} sx={{ width: '100%' }}>
      <Box sx={styles.mainContainer}>
        {Object.entries(trackOptions).map(([key, val], index) => {
          return (
            <>
              <Stack sx={styles.container}>
                <Typography sx={styles.titleContainer}>{key === 'lunch' ? 'Lunch & Dinner' : startCase(key)}</Typography>
                {Object.entries(val).map(([k, value]) => (
                  <>
                    {typeof value === 'number' ? (
                      <Stack sx={styles.optionsContainer}>
                        <Typography sx={styles.optionTitle}>{k === 'gourmet' ? 'Chef Special' : startCase(k)}</Typography>
                        <Stack sx={styles.optionValue}>
                          <IconButton
                            onClick={() => handleDecrement(key as any, k as any, value)}
                            sx={{ padding: 2 }}
                            disabled={value === 0}
                          >
                            <RemoveIcon sx={styles.IconStyle} />
                          </IconButton>
                          <Typography sx={{ fontSize: '20px', width: '32px', textAlign: 'center' }}>{value}</Typography>
                          <IconButton onClick={() => handleIncrement(key as any, k as any, value)} sx={{ padding: 2 }}>
                            <AddIcon sx={styles.IconStyle} />
                          </IconButton>
                        </Stack>
                      </Stack>
                    ) : (
                      <>
                        {Object.entries(value as [string, string | number]).map(([k2, v2]) => (
                          <Stack sx={styles.optionsContainer}>
                            <Typography sx={styles.optionTitle}>{startCase(`${k} ${k2}`)}</Typography>
                            <Stack sx={styles.optionValue}>
                              <IconButton
                                onClick={() => handleDecrement(key as any, k as any, v2 as number, k2)}
                                sx={{ padding: 2 }}
                                disabled={v2 === 0}
                              >
                                <RemoveIcon sx={styles.IconStyle} />
                              </IconButton>
                              <Typography sx={{ fontSize: '20px', width: '32px', textAlign: 'center' }}>{v2}</Typography>
                              <IconButton
                                onClick={() => handleIncrement(key as any, k as any, v2 as number, k2)}
                                sx={{ padding: 2 }}
                              >
                                <AddIcon sx={styles.IconStyle} />
                              </IconButton>
                            </Stack>
                          </Stack>
                        ))}
                      </>
                    )}
                  </>
                ))}
              </Stack>
              <Divider
                sx={[
                  styles.divider,
                  {
                    display: index === Object.entries(trackOptions).length - 1 ? 'none' : 'flex'
                  }
                ]}
              />
            </>
          );
        })}
      </Box>
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'}>
        <Button
          variant="contained"
          aria-label="show-meal-macros"
          sx={{
            mt: 2,
            width: '154px',
            height: '45px',
            fontWeight: 600,
            fontSize: '14px',
            boxShadow: 'none',
            lineHeight: '17px',
            borderRadius: '8px',
            textAlign: 'center',
            fontFamily: caloTheme.typography.fontFamily,
            borderColor: caloTheme.palette.primary500,
            color: caloTheme.palette.white,
            backgroundColor: caloTheme.palette.primary500,
            '&:hover': {
              borderColor: caloTheme.palette.primary500,
              backgroundColor: caloTheme.palette.primary500,
              color: caloTheme.palette.white,
              boxShadow: 'none'
            },
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              justifyItems: 'center',
              margin: 'auto',
              marginTop: 4,
              width: 'auto'
            }
          }}
          onClick={() => {
            setOptions(trackOptions);
            trackMealsRef.current?.close();
          }}
        >
          Track Meals
        </Button>
      </Stack>
    </Box>
  );
};
export default TrackMealsPopup;
