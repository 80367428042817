import { ComponentStandards } from 'components';
import { FormikErrors } from 'formik';
import { BaseOmit, FoodComponent } from 'lib/interfaces';

import { Card } from '@mui/material';

interface ComponentMethodCardProps {
  values: Omit<FoodComponent, BaseOmit>;
  setValues: (
    values: React.SetStateAction<Omit<FoodComponent, BaseOmit>>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
  isDisabled?: boolean;
}

const ComponentStandardsCard = ({ values, isDisabled, setValues }: ComponentMethodCardProps) => {
  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: '16px',
        border: 'none',
        borderRadius: '16px',
        px: '18px',
        py: '18px'
      }}
    >
      <ComponentStandards
        values={values}
        setValues={(v) => setValues(v)}
        isDisabled={Boolean(isDisabled)}
        allowUploadImages={false}
      />
    </Card>
  );
};

export default ComponentStandardsCard;
