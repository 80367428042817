import { RatingFilters } from '@calo/dashboard-types';
import { Brand } from '@calo/types';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { toggleUISettings } from 'actions';
import { Icon } from 'components';
import { useState } from 'react';
import { useLocation } from 'react-router';

import { caloTheme } from 'assets/images/theme/calo';
import { FoodFeedbackPages } from 'lib/enums';
import { useDispatch } from 'react-redux';
import ComplaintsCard from './ComplaintsCard';
import FeedbackCard from './FeedbackCard';
import Settings from './Settings';

const FoodFeedback = () => {
  const location = useLocation() as any;
  const [selectedPage, setSelectedPage] = useState<FoodFeedbackPages>(FoodFeedbackPages.feedback);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState<RatingFilters>({
    foodIds: location.state?.ids,
    brand: Brand.CALO,
    country: location.state?.country,
    kitchen: location.state?.kitchen
  });

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: '14px',
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          display={'flex'}
          flexDirection="row"
          justifyContent={'space-between'}
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
          width="100%"
        >
          <Stack width={'50%'} display={'flex'} flexDirection={'row'} alignItems="start" justifyContent={'space-between'}>
            <Typography
              variant="h3"
              sx={{
                mt: 'auto',
                fontWeight: 600,
                fontSize: '33px',
                lineHeight: '40px',
                color: caloTheme.palette.neutral900,
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              Meal Feedback - {location.state?.name}
            </Typography>
          </Stack>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems="end"
            sx={{
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.md)]: {
                flexDirection: 'column'
              }
            }}
          >
            <span className="mr-4">
              <Button
                aria-label="filter-subscription-list"
                sx={{
                  cursor: 'pointer',
                  my: 'auto',
                  '&:hover': {
                    backgroundColor: 'white',
                    borderColor: 'none'
                  },
                  color: caloTheme.palette.primary500,
                  borderColor: caloTheme.palette.primary500,
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    justifyItems: 'center',
                    margin: 'auto',
                    marginTop: 4,
                    width: 'auto'
                  }
                }}
                onClick={() => dispatch(toggleUISettings())}
              >
                {<Icon name="filter" size={6} className="w-10 h-18" />}
              </Button>
            </span>
          </Stack>
        </Box>
      </Card>

      {selectedPage === FoodFeedbackPages.feedback && (
        <FeedbackCard filters={filters} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
      )}
      {selectedPage === FoodFeedbackPages.complaints && (
        <ComplaintsCard filters={filters} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
      )}
      <Settings onFilter={setFilters} filters={filters} page={selectedPage} />
    </>
  );
};

export default FoodFeedback;
