import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  done: {
    marginBottom: 2,
    width: '120px',
    height: '51px',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
    backgroundColor: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    boxShadow: 'none',
    color: 'white',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: caloTheme.palette.primary600,
      borderColor: caloTheme.palette.primary600
    }
  },
  add: {
    marginBottom: 2,
    width: '120px',
    height: '51px',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    marginRight: 4,
    borderRadius: '8px',
    borderColor: caloTheme.palette.primary500,
    boxShadow: 'none',
    color: caloTheme.palette.primary500,
    '&:hover': {
      boxShadow: 'none',
      borderColor: caloTheme.palette.primary600
    }
  }
};
