import React, { useEffect, useRef, useState } from 'react';

import { compact, uniq, uniqBy } from 'lodash';
import { useQuery } from 'react-query';

import { Brand, Country, Kitchen, Subscription, SubscriptionStatus } from '@calo/types';

import { getListWithParams } from 'actions';
import { Button, CaloLoader, Input, Modal, ModalRef, Pagination } from 'components';
import queryClient from 'lib/queryClient';
import ImportSubscribers from '../ImportSubscribers';

interface PaginatedSubscriptions {
  data: Subscription[];
  meta: {
    limit: number;
    total: number;
  };
}

interface AddSubscriberModalProps {
  page: number;
  proceed: boolean;
  setPage: (v: number) => void;
  selectedDate: string | undefined;
  selectedSubscribers: Subscription[];
  setselectedSubscribers: (v: any) => void;
  validateSubscribers: (value: Subscription[]) => void;
  selectedCountryBrand: { brand: Brand; country: Country; kitchen: Kitchen };
  addSubscribersModalRef: React.MutableRefObject<ModalRef | undefined>;
}

const AddSubscriberModal = ({
  selectedSubscribers,
  addSubscribersModalRef,
  setselectedSubscribers,
  selectedCountryBrand,
  setPage,
  proceed,
  validateSubscribers,
  page
}: AddSubscriberModalProps) => {
  const ExactSelectedDataRef = useRef<ModalRef>();
  const [filterSelectedName, setFilterSelectedName] = useState<string>('');

  const { data: subscriptions, isLoading: loadingSubs } = useQuery<any, Error, PaginatedSubscriptions>(
    [
      'subscriptions',
      {
        page,
        limit: 8,
        filters: {
          status: [SubscriptionStatus.ongoing],
          brand: selectedCountryBrand.brand,
          country: selectedCountryBrand.country,
          name: filterSelectedName || undefined,
          kitchen: selectedCountryBrand.kitchen
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!proceed,
      suspense: false,
      onSuccess: (data) => {
        for (const sub of data?.data || []) {
          queryClient.setQueryData(['subscriptions', sub.id], sub);
        }
      }
    }
  );

  useEffect(() => {
    if (selectedSubscribers.length === 0) {
      ExactSelectedDataRef.current?.close();
    }
  }, [selectedSubscribers]);
  const filteredSelectedList =
    selectedSubscribers && filterSelectedName
      ? selectedSubscribers.filter((r) => r.name.toLowerCase().includes(filterSelectedName.toLowerCase()))
      : selectedSubscribers;

  return (
    <Modal
      ref={addSubscribersModalRef}
      onClose={() => {
        setPage(0);
        addSubscribersModalRef.current?.close();
      }}
    >
      <>
        <section className="section is-title-bar -my-4 ">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <p className="uppercase font-roboto text-2xl">Choose Subscribers</p>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <p className="uppercase font-roboto text-2xl">
                  {selectedCountryBrand.country}-{selectedCountryBrand.brand}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className=" flex flex-row mx-4 mt-6">
          <div className="w-9/12 mr-4">
            <Input
              label="Name"
              className="w-full mr-4"
              value={filterSelectedName}
              onChange={(e) => {
                setPage(0);
                setFilterSelectedName(e.target.value);
              }}
              debounce
            />
          </div>
          <div className="m-auto mt-8 flex w-3/12">
            <span className="flex float-left w-1/2">
              <ImportSubscribers isDisabled={false} selected={selectedSubscribers} validateSubscribers={validateSubscribers} />
            </span>
            <span className="flex w-2/2">
              {selectedSubscribers.length > 0 && (
                <Button primary className="mx-8" content="Edit Selected" onClick={() => ExactSelectedDataRef.current?.open()} />
              )}
            </span>
          </div>
        </section>
        <section className="section -mx-8 -mt-8">
          {loadingSubs ? (
            <CaloLoader />
          ) : (
            <div className="card has-table has-table-container-upper-radius">
              <div className="card-content">
                <div className="table-container overflow-y-auto" style={{ maxHeight: '26rem' }}>
                  <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                    <thead>
                      <tr className="bg-black sticky top-0 z-50">
                        <th style={{ color: 'white' }} className=" w-48">
                          Subscriber ID
                        </th>
                        <th style={{ color: 'white' }} className=" w-48">
                          Name
                        </th>
                        <th style={{ color: 'white' }} className=" w-48">
                          Phone
                        </th>
                        <th style={{ color: 'white' }} className=" w-48">
                          Email
                        </th>
                        <th style={{ color: 'white' }} className=" w-48">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptions &&
                        subscriptions.data.map((subscriber: Subscription) => (
                          <tr key={subscriber.id}>
                            <td>{subscriber.id}</td>
                            <td>{subscriber.name}</td>
                            <td>{subscriber.phoneNumber}</td>
                            <td>{subscriber.email}</td>
                            <td>
                              <Button
                                primary
                                outlined
                                className="w-24"
                                content={selectedSubscribers.find((r) => r.id === subscriber.id) ? 'Added' : 'Add'}
                                disabled={!!selectedSubscribers.find((r) => r.id === subscriber.id)}
                                onClick={() =>
                                  setselectedSubscribers((old: Subscription[]) => uniqBy([...compact(old), subscriber], 'id'))
                                }
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </section>
        <section className="section -m-16">
          <div className="level">
            <div className="level-left">
              <div className="level-item ml-8">
                {subscriptions && (
                  <Pagination
                    isHidden={subscriptions.data.length === 0}
                    limit={subscriptions.meta.limit}
                    total={subscriptions.meta.total}
                    page={page}
                    notification={true}
                    onChange={setPage}
                  />
                )}
              </div>
            </div>
            <div className="level-right mr-8">
              <div className="level-item">
                <Button
                  primary
                  content="Done"
                  onClick={() => {
                    validateSubscribers(selectedSubscribers);
                    addSubscribersModalRef.current?.close();
                    setPage(0);
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <Modal
          ref={ExactSelectedDataRef}
          onClose={() => {
            ExactSelectedDataRef.current?.close();
          }}
        >
          <section className="section is-title-bar -my-10 ">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <p className="uppercase font-roboto text-2xl">Choose Subscribers</p>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <p className="uppercase font-roboto text-2xl">
                    {selectedCountryBrand.country}-{selectedCountryBrand.brand}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className=" section flex flex-row -m-8 mt-2">
            <div className="w-full mr-4">
              <Input label="Name" value={filterSelectedName} onChange={(e) => setFilterSelectedName(e.target.value)} debounce />
            </div>
          </section>
          <section className="section -m-8">
            {loadingSubs ? (
              <CaloLoader />
            ) : (
              <div className="card has-table has-table-container-upper-radius">
                <div className="card-content">
                  <div className="table-container overflow-y-auto" style={{ maxHeight: '26rem' }}>
                    <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                      <thead>
                        <tr className="bg-black sticky top-0 z-50">
                          <th style={{ color: 'white' }} className=" w-48">
                            Subscriber ID
                          </th>
                          <th style={{ color: 'white' }} className=" w-48">
                            Name
                          </th>
                          <th style={{ color: 'white' }} className=" w-48">
                            Phone
                          </th>
                          <th style={{ color: 'white' }} className=" w-48">
                            Email
                          </th>
                          <th style={{ color: 'white' }} className=" w-48">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredSelectedList &&
                          uniqBy(filteredSelectedList, 'id').map((subscriber: Subscription) => (
                            <tr key={subscriber.id}>
                              <td>{subscriber.id}</td>
                              <td>{subscriber.name}</td>
                              <td>{subscriber.phoneNumber}</td>
                              <td>{subscriber.email}</td>
                              <td>
                                <Button
                                  className="w-24  border-2 border-red-500 bg-red-50 text-red-500 outline:none hover:text-red-500 hover:border-red-500 focus:border-red-500 focus:text-red-500"
                                  content="Remove"
                                  onClick={() =>
                                    setselectedSubscribers((old: Subscription[]) =>
                                      uniq(compact(old).filter((r) => r !== subscriber))
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </section>
          <section className="section -m-8">
            <div className="level-item">
              <Button
                primary
                content="Done"
                disabled={selectedSubscribers.length === 0}
                onClick={() => {
                  ExactSelectedDataRef.current?.close();
                  setPage(0);
                }}
              />
            </div>
          </section>
        </Modal>
      </>
    </Modal>
  );
};
export default AddSubscriberModal;
