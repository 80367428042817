import { toast } from 'react-toastify';

import { Brand } from '@calo/types';
import { Box, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import { BusinessAccount } from 'lib/interfaces';

const copyToClipBoard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    toast('Phone number copied!', { type: 'success', autoClose: 2000 });
  } catch {
    toast('Failed to copy!', { type: 'error', autoClose: 2000 });
  }
};
interface AccountInfoCardProps {
  account: BusinessAccount;
}
const AccountInfoCard = ({ account }: AccountInfoCardProps) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Stack
          display="flex"
          width="100%"
          flexDirection={'row'}
          justifyContent={'space-between'}
          sx={{
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column',
              textAlign: 'center'
            }
          }}
        >
          <Stack
            display={'flex'}
            flexDirection={'row'}
            sx={{
              width: '60%',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                flexDirection: 'column',
                textAlign: 'center',
                width: '100%'
              }
            }}
          >
            <Typography
              variant="h3"
              sx={{
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  flexDirection: 'column',
                  textAlign: 'center'
                },
                textAlign: 'left',
                fontSize: '33px',
                lineHeight: '40px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600
              }}
            >
              Account
            </Typography>

            <Typography
              sx={{
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  flexDirection: 'column',
                  textAlign: 'center',
                  alignSelf: 'center'
                },
                lineHeight: '40px'
              }}
            >
              {account.brand === Brand.CALO ? (
                <Icon name="calo" size={6} className="w-24 mr-4 -ml-6 " />
              ) : (
                <Icon name="mealo" size={18} className="ml-8 mt-3 w-24 -mb-16" />
              )}
            </Typography>
          </Stack>
        </Stack>
      </Box>

      <Box
        sx={{
          padding: 2,
          width: '100%',
          display: 'flex',
          marginBottom: '4px',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Stack
          sx={{
            marginRight: 1,
            display: 'flex',
            borderRadius: '8px',
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontFamily: caloTheme.typography.fontFamily,
            border: '1px solid' + caloTheme.palette.neutral100,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              marginRight: 0
            }
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '23px',
              lineHeight: '28px',
              letterSpacing: -0.02,
              padding: '16px 16px 10px 16px',
              color: caloTheme.palette.neutral900,
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            {account.fullName} {account.phoneNumber}
          </Typography>

          <Typography
            sx={{
              margin: '16px',
              display: 'flex',
              fontSize: '23px',
              cursor: 'pointer',
              lineHeight: '28px',
              justifyItems: 'end'
            }}
            onClick={() => copyToClipBoard(account.phoneNumber)}
          >
            <Icon name={'copyBlack'} size={7} />
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: 'flex',
            borderRadius: '8px',
            flexDirection: 'row',
            fontFamily: caloTheme.typography.fontFamily,
            border: '1px solid' + caloTheme.palette.neutral100,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto'
            }
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '19px',
              marginLeft: '2px',
              lineHeight: '23px',
              letterSpacing: -0.02,
              padding: '16px 16px 10px 16px',
              color: caloTheme.palette.neutral900,
              fontFamily: caloTheme.typography.fontFamily,
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: 'auto'
              }
            }}
          >
            {account.deliveryAddresses[0].country} - {account.deliveryAddresses[0].kitchen}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};
export default AccountInfoCard;
