import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  saveButton: {
    width: 'auto',
    height: '45px',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
    boxShadow: 'none',
    padding: '14px 20px 14px 20px',
    backgroundColor: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    color: 'white',
    '&:hover': {
      color: 'white',
      backgroundColor: caloTheme.palette.primary600,
      borderColor: caloTheme.palette.primary600
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      marginTop: 2,
      width: '75%',
      mx: 1
    }
  },
  cancelButton: {
    width: 'auto',
    height: '45px',
    mx: 2,
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
    color: caloTheme.palette.neutral600,
    '&:hover': {
      backgroundColor: caloTheme.palette.neutral100,
      borderColor: caloTheme.palette.neutral100
    }
  },
  actionbutton: {
    width: '124px',
    height: '45px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    borderRadius: '8px',
    textTransform: 'none',
    color: caloTheme.palette.black,
    borderColor: caloTheme.palette.neutral900,
    '&:hover': {
      borderColor: caloTheme.palette.neutral900,
      backgroundColor: caloTheme.palette.neutral100,
      color: caloTheme.palette.black
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      justifyItems: 'center',
      margin: 'auto',
      marginTop: 1,
      width: 'auto'
    }
  }
};
