import { styled, TableCell, tableCellClasses, TableRow } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns/fp';
import { Reward } from 'lib/interfaces';

interface RewardRowrops {
  row: Reward;
}

const RewardRow = ({ row }: RewardRowrops) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      width: '100%',
      px: 4,
      component: 'th',
      scope: 'row',
      color: caloTheme.palette.neutral900,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      variant: 'caption'
    },
    [`&.${tableCellClasses.body}`]: {
      px: 4,
      fontWeight: 400,
      fontSize: '12px',
      fontHeight: '14px',
      variant: 'caption',
      fontFamily: caloTheme.typography.fontFamily
    }
  }));
  return (
    <>
      <TableRow key={row.id}>
        <StyledTableCell>{row.type}</StyledTableCell>
        <StyledTableCell>{row.value}</StyledTableCell>
        <StyledTableCell>{row.worth} pts</StyledTableCell>
        <StyledTableCell>{row.pending ? `${row.pending} pts` : '-'}</StyledTableCell>
        <StyledTableCell>{row.status}</StyledTableCell>
        <StyledTableCell>{row.date && format('Pp')(new Date(row.date))}</StyledTableCell>
      </TableRow>
    </>
  );
};

export default RewardRow;
