import { Icon } from 'components';

export const stars = (rate: number) => {
  const allStars: JSX.Element[] = [];
  for (let i = 1; i <= rate; i++) {
    allStars.push(Icon({ name: 'star', size: 4 }));
  }
  for (let i = 5; i > rate; i--) {
    allStars.push(Icon({ name: 'grayStar', size: 4 }));
  }
  return allStars;
};
