import { Button, TableCell, TableRow, Typography } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns/fp';

import {
  AffiliatePayoutTransactionStatus,
  AffiliateTransaction,
  AffiliateTransactionType,
  Permission
} from '@calo/dashboard-types';

import { Currency } from '@calo/types';
import { caloTheme } from 'assets/images/theme/calo';
import { convertCurrency } from 'lib';

interface AffiliateTransactionRowProps {
  affiliateTransaction: AffiliateTransaction;
  affiliateCurrency: string;
  roles: any[];
  handlePayAffiliate: (transactionId: string) => any;
}

const AffiliateTransactionRow = ({
  affiliateTransaction,
  affiliateCurrency,
  roles,
  handlePayAffiliate
}: AffiliateTransactionRowProps) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      color: caloTheme.palette.neutral900,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none'
    }
  }));

  const transactionDate = format('dd/MM/yyyy HH:MM a')(new Date(affiliateTransaction.date));

  const payoutCurrencies = affiliateTransaction.amountPaidToAffiliate && Object.keys(affiliateTransaction.amountPaidToAffiliate);
  let payoutAmount = 0;

  if (payoutCurrencies) {
    for (const currency of payoutCurrencies) {
      if (affiliateTransaction.amountPaidToAffiliate) {
        payoutAmount += convertCurrency(
          affiliateTransaction.amountPaidToAffiliate[currency as Currency],
          currency as Currency,
          affiliateCurrency as Currency
        );
      }
    }
  }

  return (
    <TableRow key={affiliateTransaction.id} hover>
      <StyledTableCell>{affiliateTransaction.compensationScheme ?? 'N/A'}</StyledTableCell>
      <StyledTableCell
        component="th"
        scope="row"
        sx={{
          textOverflow: 'ellipsis'
        }}
      >
        <Typography
          component="span"
          sx={{
            width: '123px',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'inline-block',
            textOverflow: 'ellipsis',
            fontFamily: caloTheme.typography.fontFamily
          }}
        >
          {affiliateTransaction.type.toUpperCase()}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>{affiliateTransaction.userName}</StyledTableCell>
      <StyledTableCell>{transactionDate}</StyledTableCell>
      <StyledTableCell>
        {affiliateTransaction.commissionInOriginalCurrency
          ? `${affiliateTransaction.commissionInOriginalCurrency} ${affiliateTransaction.originalCurrency}`
          : 'N/A'}
      </StyledTableCell>
      <StyledTableCell>{affiliateTransaction.country ? affiliateTransaction.country : 'N/A'}</StyledTableCell>
      <StyledTableCell>
        {affiliateTransaction.amountPaidToAffiliate ? `${payoutAmount} ${affiliateCurrency}` : 'N/A'}
      </StyledTableCell>
      <StyledTableCell>{affiliateTransaction.status ? affiliateTransaction.status.toUpperCase() : 'N/A'}</StyledTableCell>
      <StyledTableCell>
        {affiliateTransaction.type === AffiliateTransactionType.payout &&
          roles.includes(Permission.PAY_AFFILIATE_COMMISSION) &&
          affiliateTransaction.status === AffiliatePayoutTransactionStatus.pending && (
            <Button onClick={() => handlePayAffiliate(affiliateTransaction.id)}>Pay</Button>
          )}
      </StyledTableCell>
    </TableRow>
  );
};
export default AffiliateTransactionRow;
