import {
  Box,
  Card,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { CoolerBagStats } from '../CoolerBags';
import CoolerBagsListRow from './CoolerBagsListRow/CoolerBagsListRow';

interface CoolerBagsListCardProps {
  isLoading: boolean;
  coolerBagsStats?: CoolerBagStats;
  selectedDate: string;
  refetchTotals: () => void;
}

const CoolerBagsListCard = ({ isLoading, coolerBagsStats, selectedDate, refetchTotals }: CoolerBagsListCardProps) => {
  if (!coolerBagsStats || !coolerBagsStats.data) {
    return <Typography>No data available</Typography>;
  }

  return (
    <Card
      variant="outlined"
      sx={{
        border: 'none',
        padding: 2,
        paddingBottom: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
        gap: '8px',
        mt: -2
      }}
    >
      <Box overflow="auto" width="100%" sx={{ py: 1 }}>
        <Divider />
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%', mt: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                lineHeight: '24px'
              }}
            >
              {new Date(selectedDate).toLocaleDateString('en-GB')}
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <TableContainer>
        <Table sx={{ width: '99%' }}>
          <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, border: 0 }}>
            <TableRow>
              <TableCell
                sx={{
                  borderRadius: '8px 0 0 8px',
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  minWidth: '22%'
                }}
              >
                Driver Name
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  width: '26%'
                }}
              >
                Sent
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  width: '20%'
                }}
              >
                Received
              </TableCell>
              <TableCell
                sx={{
                  borderRadius: '0 8px 8px 0',
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  width: '22%'
                }}
              >
                Damaged
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(coolerBagsStats.data).map((driverStat) => (
              <CoolerBagsListRow key={driverStat.driverId} driverStat={driverStat} refetchTotals={refetchTotals} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && !Object.values(coolerBagsStats.data).length && (
        <Typography sx={{ textAlign: 'center' }}>No data found</Typography>
      )}
    </Card>
  );
};

export default CoolerBagsListCard;
