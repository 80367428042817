import cx from 'classnames';
import { flatten, orderBy } from 'lodash-es';

import { FoodDietType, FoodType, Localized } from '@calo/types';

interface ExtraMealListData {
  allergic: boolean;
  id: string;
  name: Localized;
  price: number;
  sizes: {
    size: string;
    extraMeals: number;
  }[];
  slug: string;
  tags: FoodDietType[];
  type: FoodType[];
}
interface ExtraMealRowProps {
  type: FoodType;
  foodList: ExtraMealListData[];
}

const ExtraMealRow = ({ type, foodList }: ExtraMealRowProps) => {
  const foodListData = foodList.map((food) =>
    food.sizes
      .map((fs) => ({
        id: food.id,
        foodSize: fs.size,
        allergic: food.allergic,
        nameEn: food.name.en,
        tags: food.tags,
        extraMeals: fs.extraMeals || 0
      }))
      .filter((food) => food.foodSize !== 'C')
  );
  const orderedSizes = orderBy(flatten(foodListData), (f) => f.extraMeals, ['desc']);

  return foodList.length > 0 ? (
    <div>
      <table className="table is-fullwidth is-hoverable is-sortable">
        <thead>
          <tr>
            <th colSpan={3} className="w-full bg-gray-300 uppercase">
              {type.includes(FoodType.lunch) ? 'LUNCH & DINNER' : type}
            </th>
          </tr>
        </thead>
        <tbody>
          {orderedSizes.map((food) => (
            <tr key={`${food.id}-${food.foodSize}`}>
              <td
                className={cx('font-bold text-md w-6/12', {
                  'text-red-500': food.allergic,
                  'text-black': !food.allergic
                })}
              >
                {food.nameEn} ({food.foodSize})
              </td>
              <td className="w-5/12 capitalize">
                {food.tags.map((r: any, index: any) => `${r} ${index === food.tags.length - 1 ? '' : ','}`)}
              </td>
              <td>
                <p className="font-bold mr-4">{food.extraMeals || 0}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};
export default ExtraMealRow;
