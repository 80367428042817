import { caloTheme } from 'assets/images/theme/calo';
import { GlobalStyles } from 'lib/interfaces';

export const styles: GlobalStyles = {
  card: {
    width: '100%',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: caloTheme.spacing(2),
    padding: caloTheme.spacing(2),
    borderRadius: '8px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  categoryTypography: {
    backgroundColor: caloTheme.palette.neutral50,
    height: '46px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    fontWeight: 600,
    marginBottom: '10px'
  },
  menuStack: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'flex-start'
  }
};
