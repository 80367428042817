import { useEffect, useRef, useState } from 'react';

import { parseISO, subDays } from 'date-fns';
import { format } from 'date-fns/fp';
import { flatten, unionBy, uniq } from 'lodash-es';
import { useMutation, useQuery } from 'react-query';
import { RouteComponentProps, generatePath, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CreateMenuReq } from '@calo/dashboard-types';
import { Brand, Country, Kitchen } from '@calo/types';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';

import { createMenu, getMenu } from 'actions';
import { MenuTable, ModalRef } from 'components';
import Popup from 'components/Popup';
import { Routes } from 'lib/enums';
import { mealTracking, selectCountry, updateFoodTags } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { Food, Menu, MenuDraft, PopulateMenuReq } from 'lib/interfaces';
import ActionsCard from './ActionsCard';
import FilterCriteriaCard from './FilterCriteriaCard';
import FoodPickerCard from './FoodPickerCard';
import { styles } from './styles';
import useMenuForm from './useMenuForm';

type NewMenuProps = RouteComponentProps;

const NewMenu = ({ history }: NewMenuProps) => {
  const userKitchens: Kitchen[] = useUserKitchens();
  const location = useLocation() as any;
  const deletedFoodRef = useRef<ModalRef>();

  const {
    country: paramCountry,
    kitchen: paramKitchen,
    day: paramDay
  } = useParams<{ country: Country | string; kitchen: Kitchen | string; day: string }>();

  const country: Country = paramCountry === ':country' ? Country.BH : (paramCountry as Country);
  const kitchen: Kitchen = paramKitchen === ':kitchen' ? Kitchen.BH1 : (paramKitchen as Kitchen);
  const day: string = paramDay === ':day' ? format('yyyy-MM-dd', new Date()) : paramDay;

  const [mealDraftOptions, setMealDraftOptions] = useState<MenuDraft>(mealTracking);
  const [cardOpen, setCardOpen] = useState<string>('');
  const [brand, setBrand] = useState<Brand>(Brand.CALO);
  const [deletedFoodNames, setDeletedFoodNames] = useState<string>('');

  const [populateMenuData, setPopulateMenuData] = useState<PopulateMenuReq[]>([]);
  const [replaceFood, setReplaceFood] = useState<{ open: boolean; name: string }>({ open: false, name: '' });
  const [selectedFoods, setSelectedFoods] = useState<Food[]>([]);
  const [duplicateMenuDate, setDuplicateMenuDate] = useState<Date>(subDays(new Date(), 1));
  const {
    data,
    refetch: refetchDuplicateMenu,
    isLoading
  } = useQuery<any, Error, Menu>(
    [`menu/${format('yyyy-MM-dd')(duplicateMenuDate)}`, { brand: brand, kitchen: kitchen || undefined }],
    getMenu,
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (data) {
      const deletedFoods = data.food.filter((foodItem) => foodItem.deletedAt);
      const validFoods = data.food.filter((foodItem) => !foodItem.deletedAt);
      if (deletedFoods.length > 0) {
        const deletedNames = deletedFoods.map((food) => food.name.en).join(', ');
        setDeletedFoodNames(deletedNames);
        deletedFoodRef.current?.open();
      }
      setSelectedFoods(flatten(validFoods) as Food[]);
      setFieldValue('food', uniq(validFoods.map((foodData) => foodData.id)));
      setMealDraftOptions(data.draft || mealDraftOptions);
      setFieldValue('draft', data.draft || mealDraftOptions);
      toast('Menu Duplicated Successfully', { type: 'success' });
    }
  }, [data]);
  const { mutateAsync: createMutation } = useMutation(createMenu);

  const onSubmit = async ({ day, food, country, tags, brand, kitchen }: CreateMenuReq) => {
    await createMutation(
      {
        day,
        food: flatten(Object.values(food)),
        country: country,
        tags: tags,
        brand: brand,
        kitchen: kitchen,
        draft: mealDraftOptions as any
      },
      {
        onSuccess: (data) => {
          const path = generatePath(Routes.menu, { brand: data.brand, kitchen: data.kitchen, id: data.id });
          history.push(path);
        }
      }
    );
  };

  useEffect(() => {
    if (location.state && location?.state.country && location?.state.brand && location?.state.kitchen) {
      setBrand(location.state?.brand);
      const state = { ...location.state };
      setFieldValue('country', state.country!);
      setFieldValue('brand', Brand.CALO);
      setFieldValue('kitchen', userKitchens[0]);
      delete state.country;
      delete state.brand;
      history.replace(location.pathname);
    }
  }, []);

  const { values, setFieldValue } = useMenuForm(onSubmit);

  const [populateSearchDate, setPopulateSearchDate] = useState(populateMenuData[0]?.day);

  useEffect(() => {
    setFieldValue('country', country);
    setFieldValue('kitchen', kitchen);
    setFieldValue('day', day);
  }, []);

  useEffect(() => {
    const path = generatePath(Routes.newMenu, { country: values.country, kitchen: values.kitchen, day: values.day });
    history.replace(path);
  }, [values.country, values.kitchen, values.day]);

  useEffect(() => {
    setCardOpen(populateSearchDate);
  }, [populateSearchDate]);

  useEffect(() => {
    if (populateMenuData.length > 0) {
      setPopulateSearchDate(populateMenuData[0].day);
    }
  }, [populateMenuData]);

  const handleFoodChanges = (food: Food[], tags: any) => {
    const menuDay = new Date(values.day).getTime();
    if (populateMenuData.length > 0) {
      const index = populateMenuData.findIndex((data) => data.day === cardOpen);
      if (index >= 0) {
        const addedFoodMenu = { ...populateMenuData[index], food: unionBy(populateMenuData[index].food, food) };
        const modifiedMenu = populateMenuData.map((menu) => (menu.day === addedFoodMenu.day ? addedFoodMenu : menu));
        setPopulateMenuData(modifiedMenu);
        const updatedTags = updateFoodTags(food, tags, menuDay);
        const allTags = uniq([...updatedTags, ...(values.tags ?? [])]);
        setFieldValue('tags', allTags);
      }
    } else {
      setSelectedFoods((old) => unionBy(old, food, 'id'));
      const updatedTags = updateFoodTags(food, tags, menuDay);
      const allFood = uniq([...food.map((f) => f.id), ...(values.food ?? [])]);
      const allTags = uniq([...updatedTags, ...(values.tags ?? [])]);
      setFieldValue('food', allFood);
      setFieldValue('tags', allTags);
    }
  };

  const handleRemoveFood = (removedIds: string[]) => {
    if (populateMenuData.length > 0) {
      const index = populateMenuData.findIndex((data) => data.day === cardOpen);
      if (index >= 0) {
        const selectedMenuRemoveMeal = {
          ...populateMenuData[index],
          food: populateMenuData[index].food.filter((food) => !removedIds.includes(food.id))
        };
        const modifiedMenu = populateMenuData.map((menu) =>
          menu.day === selectedMenuRemoveMeal.day ? selectedMenuRemoveMeal : menu
        );
        setPopulateMenuData(modifiedMenu);
        const tagList = values.tags?.filter((tag) => !removedIds.includes(tag.foodId));
        setFieldValue('tags', tagList);
      }
    } else {
      setSelectedFoods(selectedFoods.filter((selectedFood) => !removedIds.includes(selectedFood.id)));
      const foodList = values.food?.filter((foodId) => !removedIds.includes(foodId));
      const tagList = values.tags?.filter((tag) => tag !== null && !removedIds.includes(tag.foodId));
      setFieldValue('food', foodList);
      if (values.tags && values.tags.length > 0) {
        setFieldValue('tags', tagList);
      }
    }
  };

  useEffect(() => {
    if (!values.kitchen.includes(values.country)) {
      selectCountry({
        value: values.country,
        kitchens: userKitchens,
        setFieldValue
      });
    }
  }, [values.country]);

  useEffect(() => {
    setFieldValue('food', []);
    setBrand(values.brand);
    setSelectedFoods([]);
  }, [values.brand, values.country, values.kitchen]);

  const findCardIndexByDate = () => populateMenuData.findIndex((menu) => menu.day === populateSearchDate);

  useEffect(() => {
    if (populateMenuData && populateMenuData.length > 0) {
      const cardIndex = findCardIndexByDate();
      if (cardIndex >= 0) {
        const cardElement = document.getElementById(`card-${cardIndex - 1}`);
        setTimeout(() => {
          cardElement?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      } else {
        toast(`No Menu populated for this day ${populateSearchDate}`, { type: 'error', autoClose: 2000 });
      }
    }
  }, [populateSearchDate]);

  const handleFoodIds = () => {
    if (populateMenuData.length > 0) {
      const index = populateMenuData.findIndex((data) => data.day === cardOpen);
      if (index >= 0) {
        const populateMenuFoodIds = populateMenuData[index].food.map((f) => f.id);
        return populateMenuFoodIds as string[];
      }
    } else {
      return values.food;
    }
  };

  useEffect(() => {
    if (values.day) {
      const menuDay = new Date(values.day).getTime();
      const updatedTags = updateFoodTags(selectedFoods, values.tags, menuDay);
      const allFood = uniq([...selectedFoods.map((f) => f.id), ...(values.food ?? [])]);
      const allTags = uniq([...updatedTags, ...(values.tags ?? [])]);
      setFieldValue('food', allFood);
      setFieldValue('tags', allTags);
    }
  }, [values.day, selectedFoods]);

  return (
    <>
      <ActionsCard
        values={values}
        brand={brand}
        kitchen={kitchen}
        country={country}
        cardOpen={cardOpen}
        populateMenuData={populateMenuData}
        replaceFood={replaceFood}
        selectedFoods={selectedFoods}
        mealDraftOptions={mealDraftOptions}
        setMealDraftOptions={setMealDraftOptions}
        onSubmit={onSubmit}
        duplicateMenuDate={duplicateMenuDate}
        setDuplicateMenuDate={setDuplicateMenuDate}
        refetchDuplicateMenu={refetchDuplicateMenu}
        handleFoodIds={handleFoodIds}
        setFieldValue={setFieldValue}
        setReplaceFood={setReplaceFood}
        setSelectedFoods={setSelectedFoods}
        handleFoodChanges={handleFoodChanges}
        setPopulateMenuData={setPopulateMenuData}
        selectedDate={values.day}
      />
      <FilterCriteriaCard
        values={values}
        populateMenuData={populateMenuData}
        setFieldValue={setFieldValue}
        setPopulateMenuData={setPopulateMenuData}
      />
      <FoodPickerCard
        brand={brand}
        country={country}
        kitchen={kitchen}
        populateSearchDate={populateSearchDate}
        handleFoodIds={handleFoodIds}
        handleFoodChanges={handleFoodChanges}
        setPopulateSearchDate={setPopulateSearchDate}
      />
      {
        <Card variant="outlined" sx={styles.card}>
          <Box sx={styles.boxHeader}>
            <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Typography sx={styles.typography}>{`${format('EEEE dd/MM/yyyy')(parseISO(values.day ?? day))}`}</Typography>
            </Stack>
          </Box>
          <Box sx={styles.boxContent}>
            <MenuTable
              brand={values.brand}
              kitchen={values.kitchen}
              foodLabel={values.tags}
              selectedDate={values.day}
              foodList={selectedFoods}
              setReplaceFood={setReplaceFood}
              removeFood={(removedIds) => handleRemoveFood(removedIds)}
              draftTrack={mealDraftOptions}
              isMealsLoading={isLoading}
            />
          </Box>
        </Card>
      }
      <Popup maxWidth="sm" fullWidth closeIcon onClose={() => deletedFoodRef.current?.close()} ref={deletedFoodRef}>
        <DialogTitle sx={styles.dialogTitle}>The following food have been deleted and will be removed from the menu</DialogTitle>
        <DialogContent>
          <Stack sx={styles.stackPadding}>
            <List>
              {deletedFoodNames.split(', ').map((name, index) => (
                <ListItem key={index} sx={styles.listItem}>
                  <ListItemText primary={name} />
                </ListItem>
              ))}
            </List>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button sx={styles.button} onClick={() => deletedFoodRef.current?.close()} disabled={isLoading}>
            Done
          </Button>
        </DialogActions>
      </Popup>
    </>
  );
};

export default NewMenu;
