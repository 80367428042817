import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { Country, Kitchen } from '@calo/types';

import { getList, toggleUISettings } from 'actions';
import { Input, Select2, SideDrawer } from 'components';
import { getAccessibleCountries } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { AppState, UserListFilters } from 'lib/interfaces';
import { sortBy } from 'lodash-es';

interface SettingsProps {
  onFilter: React.Dispatch<React.SetStateAction<UserListFilters>>;
  filters: UserListFilters;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  const { data } = useQuery<string, Error, { data: any[] }>('user-permissions', getList, {
    suspense: true
  });

  const permissionsAndRoles = data?.data || [];

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Role"
          value={filters.group}
          onChange={(data) => onFilter({ ...filters, group: data.target.value })}
          options={[
            {
              label: 'All',
              value: ''
            },
            ...sortBy(permissionsAndRoles, 'groupName').map((type) => ({
              value: type.id,
              label: type.groupName
            }))
          ]}
        />
        <Select2
          label="Country"
          value={filters.country}
          onChange={(e) =>
            onFilter({
              ...filters,
              country: e.target.value as Country,
              kitchen: Object.values(Kitchen).find((k) => k.includes(e.target.value) && !k.includes('000')) ?? Kitchen.BH1
            })
          }
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(e) => onFilter({ ...filters, kitchen: e.target.value as Kitchen })}
          options={userKitchens.map((c) => ({
            value: c,
            label: c
          }))}
          disabled={Object.values(Kitchen).filter((r) => r.includes(filters.country!)).length === 0}
        />
        <Input label="Email" value={filters.email} onChange={(e) => onFilter({ ...filters, email: e.target.value })} debounce />
      </section>
    </SideDrawer>
  );
};

export default Settings;
