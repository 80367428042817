import { CreateRetailMenuReq, RetailMenuFoodItem, UpdateRetailMenuReq } from '@calo/dashboard-types';
import { format } from 'date-fns/fp';
import { MenuFood } from 'lib/interfaces';

// Import MUI components
import { Delete, Edit } from '@mui/icons-material';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface RetailTableProps {
  retailMenu: MenuFood[];
  values: CreateRetailMenuReq | UpdateRetailMenuReq;
  retailValues: RetailMenuFoodItem[];
  editRow: (values: RetailMenuFoodItem) => void;
  setValues: React.Dispatch<React.SetStateAction<CreateRetailMenuReq | UpdateRetailMenuReq>>;
}

const RetailTable = ({ setValues, retailMenu, editRow, values }: RetailTableProps) => {
  const deleteRow = (row: RetailMenuFoodItem) => {
    let restOfFood = values.food.filter((item) => item.id !== row.id);
    restOfFood = restOfFood.map((foodItem) => ({
      ...foodItem,
      foodModifiers: foodItem.foodModifiers?.map((modifier) => ({
        ...modifier,
        componentOptions: modifier?.componentOptions?.map((option: any) => ({ id: option.id, quantity: option.quantity }))
      }))
    }));
    setValues({
      ...values,
      food: restOfFood
    });
  };

  const getUpdatedPrice = (id: string) => {
    return values.food.find((item) => item.id === id)?.retailPrice;
  };

  const getUpdatedTags = (id: string) => {
    return values.food.find((item) => item.id === id)?.retailTagList;
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="retail menu table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Retail Tags</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Disabled</TableCell>
            <TableCell>Swap Available</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {retailMenu.map((f: any, index) => (
            <TableRow key={index}>
              <TableCell>{f.name.en}</TableCell>
              <TableCell>{getUpdatedTags(f.id)?.join(', ')}</TableCell>
              <TableCell>{f.size}</TableCell>
              <TableCell>{getUpdatedPrice(f.id)}</TableCell>
              <TableCell>{f.retail.startDate ? format('yyyy-MM-dd')(new Date(f.retail.startDate)) : 'N/A'}</TableCell>
              <TableCell>{f.retail.endDate ? format('yyyy-MM-dd')(new Date(f.retail.endDate)) : 'N/A'}</TableCell>
              <TableCell>{f.retail.disabled ? 'Yes' : 'No'}</TableCell>
              <TableCell>{f.retail.swapAvailable ? 'Yes' : 'No'}</TableCell>
              <TableCell>
                {f.retail?.retailPrice >= 0 && (
                  <IconButton
                    onClick={() =>
                      editRow({
                        id: f.id,
                        priority: f.retail.priority,
                        retailPrice: f.retail.retailPrice,
                        retailTagList: f.retail.retailTagList,
                        endDate: f.retail.endDate,
                        startDate: f.retail.startDate,
                        foodModifiers: f.retail.foodModifiers,
                        disabled: f.retail.disabled,
                        swapAvailable: f.retail.swapAvailable
                      })
                    }
                    data-test="retail-menu-table-edit-button"
                  >
                    <Edit />
                  </IconButton>
                )}
                <IconButton
                  onClick={() =>
                    deleteRow({
                      id: f.id,
                      priority: +values.food.find((e) => f.id === e.id)!.priority,
                      retailPrice: f.retailPrice
                    })
                  }
                  data-test="retail-menu-table-delete-button"
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RetailTable;
