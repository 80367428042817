import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Route, Switch } from 'react-router-dom';
import ExactUser from './ExactUser';
import NewUser from './NewUser';
import PermissionsPage from './Permissions';
import UserList from './UserList';

const Users = () => {
  const roles = useUserRoles();

  return (
    <Switch>
      {roles.includes(Permission.VIEW_USER) && <Route key={Routes.user} exact path={Routes.user} component={ExactUser} />}
      {roles.includes(Permission.CREATE_USER) && <Route key={Routes.newUser} exact path={Routes.newUser} component={NewUser} />}

      {roles.includes(Permission.VIEW_USER_LIST) && (
        <Route key={Routes.usersList} exact path={Routes.usersList} component={UserList} />
      )}
      {roles.includes(Permission.VIEW_USER_PERMISSIONS_LIST) &&
        roles.includes(Permission.UPDATE_USER_PERMISSIONS) &&
        roles.includes(Permission.CREATE_USER_PERMISSIONS) &&
        roles.includes(Permission.DELETE_USER_PERMISSIONS) && [
          <Route key={Routes.permissions} exact path={Routes.permissions} component={PermissionsPage} />
        ]}
    </Switch>
  );
};

export default Users;
