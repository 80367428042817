import { Permission } from '@calo/dashboard-types';
import { Button, Input } from 'components';
import { useUserRoles } from 'lib/hooks';
import { useEffect, useState } from 'react';

interface CityAreaFormProps {
  polygonRow: any;
  allPolygons: any;
  onChange: (polygonRow: KitchenPolygonRowState) => void;
  onDelete: (polygonRow: KitchenPolygonRowState) => void;
}

const CityAreaForm = ({ allPolygons, polygonRow, onDelete, onChange }: CityAreaFormProps) => {
  const [selectedArea, setSelectedArea] = useState<KitchenPolygonRowState>({ polygon: polygonRow.polygon, name: '', bounds: [] });
  const [nameUsed, setNameUsed] = useState<boolean>(false);
  const roles = useUserRoles();

  useEffect(() => {
    if (polygonRow) {
      setSelectedArea({ polygon: polygonRow.polygon, name: polygonRow.name, bounds: polygonRow.bounds });
    }
  }, [polygonRow]);

  const handleChanges = (areaName: string) => {
    setSelectedArea({ ...selectedArea, name: areaName });
  };

  useEffect(() => {
    const check = allPolygons.find(
      (r: any) => r.name.toUpperCase() === selectedArea.name.toUpperCase() && selectedArea.bounds !== r.bounds
    );
    if (selectedArea.name && check) {
      setNameUsed(true);
    } else {
      setNameUsed(false);
    }
  }, [selectedArea.name]);

  return (
    <div>
      {roles.includes(Permission.GET_DRIVER_USERS) && (
        <>
          <div className="flex flex-row mb-3">
            <Input label="Name" value={selectedArea.name} onChange={(data) => handleChanges(data.target.value)} />
          </div>
          {nameUsed && <p className="text-xs mb-2 text-red-500 "> An area with the same name already exist</p>}

          <div className="flex flex-row justify-between">
            <Button type="button" danger className="w-24" content="Delete" onClick={() => onDelete(polygonRow)} />
            <Button
              type="button"
              primary
              className="w-24"
              content="Apply"
              disabled={nameUsed}
              onClick={() =>
                onChange({
                  name: selectedArea.name,
                  bounds: selectedArea.bounds,
                  polygon: selectedArea.polygon
                })
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CityAreaForm;
