import { useEffect, useState } from 'react';

import { flatten, keyBy, round, sumBy } from 'lodash-es';
import { useQuery } from 'react-query';

import { Permission, UpdateFoodReq } from '@calo/dashboard-types';
import { Brand, Country, Kitchen } from '@calo/types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, InputAdornment, Stack } from '@mui/material';

import { getListWithParams } from 'actions';
import ComponentCostInfo from 'components/ComponentCostInfo';
import { foodInformationFormMultiSelectCustomStyles } from 'lib/componentStyles';
import { useUserRoles } from 'lib/hooks';
import { BaseOmit } from 'lib/interfaces';
import InputMUI from '../InputMUI';
import SelectMUI from '../SelectMUI';

interface AllPackagesProps {
  brand: Brand;
  country: Country;
  setFieldValue: any;
  isDisabled: boolean;
  values: Omit<UpdateFoodReq, BaseOmit>;
  setPackageCost: (val: number) => void;
  kitchen: Kitchen;
}
const AllPackagesMUI = ({ isDisabled, values, setFieldValue, setPackageCost, country, brand, kitchen }: AllPackagesProps) => {
  const roles = useUserRoles();
  const [showCostInfo, setShowCostInfo] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const { data } = useQuery<any, Error, { data: any[] }>(
    ['food-packages', { limit: 1000, filters: { country, brand, kitchen } }],
    getListWithParams
  );

  useEffect(() => {
    if (data?.data) {
      const keyed = keyBy(data.data || [], 'id');
      const preselected = values.packagingIds?.map((id) => ({
        label: keyed[id]?.name || '',
        innerValue: id,
        value: Math.random()
      }));
      setSelectedValues(preselected || []);
    }
  }, [data, values.packagingIds]);

  const packageList = data?.data;
  const getPackageCost = () => {
    if (packageList) {
      const packagingCost = values.packagingIds?.map((r: any) => packageList.find((k) => k.id === r)?.cost);
      const pC = round(
        sumBy(flatten(packagingCost), (fk) => fk),
        3
      );
      const costDisplay = packagingCost?.filter(Boolean).join(' + ') + ` = ${pC}`;
      setPackageCost(pC);
      return { totalCost: pC, costDisplay };
    }
    return { totalCost: 0, costDisplay: '' };
  };

  const handleChanges = (selectedOption: any) => {
    const newSelectedOption = selectedOption.map((opt: any) => ({
      label: opt.label,
      innerValue: opt.innerValue ? opt.innerValue : opt.value,
      value: Math.random() //in order to have duplicates, value needs to be different for each pick but we rely on innerValue
    }));
    setSelectedValues(newSelectedOption);
    setFieldValue(
      'packagingIds',
      newSelectedOption.map((row: any) => row.innerValue)
    );
  };

  const costInfoText = () => {
    let info = `Packaging Cost is fetched from Precoro directly.,`;
    if ((values.packagingIds ?? []).length > 1) {
      info += getPackageCost().costDisplay;
    }
    return info;
  };
  return (
    <Box flexDirection={'column'} sx={{ width: '98%', m: 2, mt: 0 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Box sx={{ width: '80%' }}>
          <SelectMUI
            label="Packages"
            id="packageSearchSelector"
            value={selectedValues}
            isMulti
            onChange={handleChanges}
            options={packageList?.map((row) => ({
              value: row.id,
              label: row.name
            }))}
            areDuplicatesAllowed
            isDisabled={!roles.includes(Permission.UPDATE_FOOD_PACKAGE) || isDisabled}
            customStyles={foodInformationFormMultiSelectCustomStyles()}
          />
        </Box>
        <Box sx={{ width: '10%', pt: selectedValues.length > 0 ? 2 : 3 }}>
          <InputMUI
            label="Package Cost"
            value={getPackageCost().totalCost}
            type="number"
            min={0}
            disabled
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowCostInfo(true)}>
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box sx={{ width: '10%', pt: selectedValues.length > 0 ? 2 : 3 }}>
          <InputMUI
            label="Expiry Days"
            value={values.expiresIn}
            type="number"
            debounce
            min={1}
            onChange={(data) => setFieldValue('expiresIn', data.target.value)}
            inputProps={{ style: { borderRadius: 8 } }}
          />
        </Box>
      </Stack>
      {showCostInfo && (
        <ComponentCostInfo
          costInfo={costInfoText()}
          marginTop="8px"
          marginBottom="8px"
          marginHorizontal="8px"
          handleClose={() => {
            setShowCostInfo(false);
          }}
        />
      )}
    </Box>
  );
};
export default AllPackagesMUI;
