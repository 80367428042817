import { useFormik } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { AddressType, Brand, Country, DeliveryTime, Kitchen } from '@calo/types';

import { CreateAccountSubscriptionReq } from 'lib/interfaces';

const Form = (onSubmit: (values: CreateAccountSubscriptionReq) => any) =>
  useFormik<CreateAccountSubscriptionReq>({
    initialValues: {
      fullName: '',
      email: '',
      phoneNumber: '',
      brand: Brand.CALO,
      deliveryAddress: {
        building: '',
        country: Country.BH,
        lat: 26.21536,
        lng: 50.5832,
        street: '',
        type: AddressType.home,
        apartment: '',
        city: '',
        region: '',
        district: ''
      },
      deliveryTime: DeliveryTime.evening || ([] as any),
      country: Country.BH,
      kitchen: Kitchen.BH1
    },
    validate: (values: CreateAccountSubscriptionReq) => {
      const errors: any = {};
      if (!values.fullName) {
        errors.fullName = true;
      }
      if (!values.email) {
        errors.email = true;
      }
      if (!values.kitchen) {
        errors.kitchen = true;
      }
      const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);
      if (!phoneNumber || (phoneNumber && !phoneNumber.isValid())) {
        errors.phoneNumber = true;
      }
      return errors;
    },

    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });

export default Form;
