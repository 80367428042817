import { LinkedComponent, Permission } from '@calo/dashboard-types';
import { TableCell, TextField } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { useUserRoles } from 'lib/hooks';
import { round } from 'lodash-es';

interface PrebuiltCustomCellsProps {
  component: LinkedComponent;
  quantity: number;
  isDisabled: boolean;
  handleChange: (id: string, quantity: number) => void;
}

const PrebuiltCustomCells = ({ component, quantity, isDisabled, handleChange }: PrebuiltCustomCellsProps) => {
  const roles = useUserRoles();
  return (
    <>
      <TableCell>
        <TextField
          type="number"
          value={round(component.quantity / quantity, 2)}
          onChange={(e) => handleChange(component.id, parseFloat(e.target.value) * quantity)}
          error={round(component.quantity / quantity, 2) === 0}
          disabled={!roles.includes(Permission.UPDATE_FOOD) || !roles.includes(Permission.UPDATE_FOOD_COMPONENTS) || isDisabled}
          inputProps={{
            min: 0.1,
            style: { borderRadius: 8 }
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: caloTheme.palette.primary500
              }
            }
          }}
        />
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>{component.quantity}</TableCell>
    </>
  );
};

export default PrebuiltCustomCells;
