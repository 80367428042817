import { useEffect, useState } from 'react';

import { format } from 'date-fns/fp';
import { toast } from 'react-toastify';

import { AddressService } from '@calo/services';
import { DeliveryAddress } from '@calo/types';
import { Box, Button, MenuItem, Stack, TextField, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';

interface CustomTimePickerProps {
  closePopup: () => void;
  setAllDeliveries?: boolean;
  addresses: DeliveryAddress[];
  customTime: string | undefined;
  defaultAddress: DeliveryAddress | undefined;
  onSubmit: (id: string, value: string) => void;
}

const CustomTimePicker = ({
  customTime,
  addresses,
  defaultAddress,
  onSubmit,
  closePopup,
  setAllDeliveries = false
}: CustomTimePickerProps) => {
  const [selectedTime, setSelectedTime] = useState<string>('AM');
  const [selectedTimeHours, setSelectedTimeHours] = useState<string>('');
  const [selectedTimeMinutes, setSelectedTimeMinutes] = useState<string>('');
  const [selectedAddress, setSelectedAddress] = useState<any>(defaultAddress);
  const [customForAllDeliveries, setCustomForAllDeliveries] = useState<boolean>(false);

  useEffect(() => {
    if (customTime) {
      if (selectedAddress.customDeliveryTime) {
        const [hours, minutes] = selectedAddress?.customDeliveryTime.split(':');
        const time = new Date();
        time.setHours(parseInt(hours));
        time.setMinutes(parseInt(minutes));
        setSelectedTimeHours(format('hh')(time));
        setSelectedTimeMinutes(format('mm')(time));
        setSelectedTime(hours > 12 ? 'PM' : 'AM');
      } else {
        setSelectedTimeHours('00');
        setSelectedTimeMinutes('00');
        setSelectedTime('AM');
      }
    }
  }, [customTime, selectedAddress]);

  const handleSelectAddress = (selectedAddressId: string) => {
    const selectedAddressData = addresses.find((address) => address.id === selectedAddressId);
    setSelectedAddress(selectedAddressData);
  };

  const handleSubmitCustomTime = () => {
    if (selectedTimeHours && +selectedTimeHours > 0 && selectedTimeMinutes) {
      let hours = selectedTimeHours;
      if (selectedTime === 'PM') {
        hours = (+hours + 12).toString();
      }
      onSubmit(selectedAddress.id, `${hours}:${selectedTimeMinutes}`);
    }
  };

  const handleGetCustomDeliveryRange = () => {
    const hours = selectedTimeHours;
    if (selectedTimeHours) {
      if (hours && +hours > 0) {
        return `${`${+hours > 12 ? +hours - 12 : hours}:${selectedTimeMinutes ? selectedTimeMinutes : '00'} ${selectedTime} - 
        ${+hours + 1 > 12 ? +hours - 11 : +hours + 1}:${selectedTimeMinutes ? selectedTimeMinutes : '00'} 
        ${+hours === 11 ? (selectedTime === 'PM' ? 'AM' : 'PM') : selectedTime}`}`;
      } else {
        return `${`00:${selectedTimeMinutes ? selectedTimeMinutes : '00'} ${selectedTime} - 01:${selectedTimeMinutes ? selectedTimeMinutes : '00'} ${selectedTime}`}`;
      }
    }
  };
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', minHeight: '12rem' }}>
        <Stack sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
          <Stack sx={{ mx: 1, display: 'flex', flexDirection: 'row', width: '100%', ml: 2 }}>
            <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                placeholder="00"
                type="text"
                variant="outlined"
                sx={{
                  height: '106px',
                  width: '127px',
                  borderRadius: '5px'
                }}
                value={selectedTimeHours ? (selectedTimeHours.length === 1 ? '0' + selectedTimeHours : selectedTimeHours) : ''}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value) {
                    if (value.length > 2 && value.startsWith('0')) {
                      const hours = value.slice(1);
                      if (/^(0[1-9]|1[0-2])$/.test(hours)) {
                        setSelectedTimeHours(+hours > 9 ? hours : `${hours}`);
                      } else {
                        toast('The time should be in 12 hours format', { type: 'error', autoClose: 2000 });
                      }
                    } else {
                      if (/^([0-9]|0[1-9]|1[0-2])$/.test(value)) {
                        setSelectedTimeHours(+value > 9 ? value : `${value}`);
                      } else {
                        toast('The time should be in 12 hours format', { type: 'error', autoClose: 2000 });
                      }
                    }
                  } else {
                    setSelectedTimeHours('00');
                  }
                }}
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'center', color: caloTheme.palette.primary500, fontSize: '64px' },
                    onFocus: (event) => {
                      event.target.select();
                      event.target.style.borderColor = 'green';
                    },
                    onBlur: (event) => {
                      event.target.style.borderColor = 'gray';
                    }
                  }
                }}
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '21px',
                  opacity: '40%',
                  color: caloTheme.palette.neutral700,
                  mt: 3
                }}
              >
                Hour
              </Typography>
            </Stack>
            <Typography
              sx={{
                mt: 3,
                lineHeight: '74px',
                mx: 2,
                fontSize: '74px',
                fontWeight: 400
              }}
            >
              :
            </Typography>
            <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                placeholder="00"
                sx={{
                  height: '106px',
                  width: '127px',
                  borderRadius: '5px'
                }}
                InputProps={{
                  inputProps: {
                    style: {
                      textAlign: 'center',
                      color: caloTheme.palette.primary500,
                      fontSize: '64px'
                    },
                    onFocus: (event) => {
                      event.target.select();
                      event.target.style.borderColor = 'green';
                    },
                    onBlur: (event) => {
                      event.target.style.borderColor = 'gray';
                    }
                  }
                }}
                type="text"
                variant="outlined"
                value={
                  selectedTimeMinutes ? (selectedTimeMinutes.length === 1 ? '0' + selectedTimeMinutes : selectedTimeMinutes) : ''
                }
                onChange={(event) => {
                  const { value } = event.target;
                  if (value) {
                    if (value.length > 2 && value.startsWith('0')) {
                      const minutes = value.slice(1);
                      if (/^([0-9]|0[0-9]|[1-5][0-9])$/.test(minutes)) {
                        setSelectedTimeMinutes(+minutes > 9 ? minutes : `${minutes}`);
                      } else {
                        toast('The time should be in 12 hours format', { type: 'error', autoClose: 2000 });
                      }
                    } else {
                      if (/^([0-9]|0[0-9]|[1-5][0-9])$/.test(value)) {
                        setSelectedTimeMinutes(+value > 9 ? value : `${value}`);
                      } else {
                        toast('The time should be in 12 hours format', { type: 'error', autoClose: 2000 });
                      }
                    }
                  } else {
                    setSelectedTimeHours('00');
                  }
                }}
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '21px',
                  opacity: '40%',
                  color: caloTheme.palette.neutral700,
                  mt: 3
                }}
              >
                Minute
              </Typography>
            </Stack>
          </Stack>

          <Stack sx={{ mx: 1 }}>
            <Button
              variant="outlined"
              aria-label="Custom Delivery Time"
              sx={{
                height: '64px',
                minWidth: '69px',
                fontWeight: 600,
                fontSize: '14px',
                boxShadow: 'none',
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
                borderColor: selectedTime === 'AM' ? caloTheme.palette.primary500 : caloTheme.palette.neutral500,
                color: selectedTime === 'AM' ? caloTheme.palette.primary500 : caloTheme.palette.neutral500,
                '&:hover': {
                  borderColor: selectedTime === 'AM' ? caloTheme.palette.primary600 : caloTheme.palette.neutral500,
                  color: selectedTime === 'AM' ? caloTheme.palette.primary600 : caloTheme.palette.neutral500,
                  boxShadow: 'none'
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  height: '64px',
                  fontWeight: 600,
                  fontSize: '14px',
                  boxShadow: 'none',
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: '6px',
                  borderColor: selectedTime === 'AM' ? caloTheme.palette.primary500 : caloTheme.palette.neutral500,
                  color: selectedTime === 'AM' ? caloTheme.palette.primary500 : caloTheme.palette.neutral500,
                  '&:hover': {
                    borderColor: selectedTime === 'AM' ? caloTheme.palette.primary600 : caloTheme.palette.neutral500,
                    color: selectedTime === 'AM' ? caloTheme.palette.primary600 : caloTheme.palette.neutral500,
                    boxShadow: 'none'
                  }
                }
              }}
              onClick={() => setSelectedTime('AM')}
            >
              AM
            </Button>
            <Button
              variant="outlined"
              aria-label="Custom Delivery Time"
              sx={{
                height: '64px',
                minWidth: '69px',
                fontWeight: 600,
                fontSize: '14px',
                boxShadow: 'none',
                borderTop: 0,
                borderBottomLeftRadius: '6px',
                borderBottomRightRadius: '6px',
                borderColor: selectedTime === 'PM' ? caloTheme.palette.primary500 : caloTheme.palette.neutral500,
                color: selectedTime === 'PM' ? caloTheme.palette.primary500 : caloTheme.palette.neutral500,
                '&:hover': {
                  borderColor: selectedTime === 'PM' ? caloTheme.palette.primary600 : caloTheme.palette.neutral500,
                  color: selectedTime === 'PM' ? caloTheme.palette.primary600 : caloTheme.palette.neutral500,
                  boxShadow: 'none'
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  height: '64px',
                  fontWeight: 600,
                  fontSize: '14px',
                  boxShadow: 'none',
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: '6px',
                  borderColor: selectedTime === 'AM' ? caloTheme.palette.primary500 : caloTheme.palette.neutral500,
                  color: selectedTime === 'AM' ? caloTheme.palette.primary500 : caloTheme.palette.neutral500,
                  '&:hover': {
                    borderColor: selectedTime === 'AM' ? caloTheme.palette.primary600 : caloTheme.palette.neutral500,
                    color: selectedTime === 'AM' ? caloTheme.palette.primary600 : caloTheme.palette.neutral500,
                    boxShadow: 'none'
                  }
                }
              }}
              onClick={() => setSelectedTime('PM')}
            >
              PM
            </Button>
          </Stack>
        </Stack>
        <Stack sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', mb: 1, mt: '-4px' }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '24px',
              mt: 3
            }}
          >
            {handleGetCustomDeliveryRange() || ''}
          </Typography>
        </Stack>
        <Stack sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between', my: 3, mx: 1 }}>
          <TextField
            select
            type="text"
            name="Delivery Address"
            value={selectedAddress?.id}
            label="Delivery Address"
            sx={{
              width: '100%',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: '100%'
              }
            }}
            placeholder="Select Delivery Time"
            id="exact-subscription-deilveryTime"
            onChange={(data: any) => handleSelectAddress(data.target.value)}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {addresses?.map((deliveryAddress) => (
              <MenuItem key={deliveryAddress.id} value={deliveryAddress.id}>
                {AddressService.display(deliveryAddress)}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between', my: 3, mx: 1 }}>
        {setAllDeliveries && (
          <Stack sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <label className="b-checkbox checkbox ml-2">
              <input
                type="checkbox"
                value="lorem"
                checked={customForAllDeliveries}
                onChange={() => setCustomForAllDeliveries(!customForAllDeliveries)}
              />
              <span className="check is-primary"> Add for All Deliveries </span>
            </label>
          </Stack>
        )}
        <Stack sx={{ display: 'flex', flexDirection: 'row', width: '100%', mt: '-28px', justifyContent: 'end' }}>
          <Button
            variant="text"
            sx={{
              color: caloTheme.palette.primary500,
              fontSize: '19px',
              fontWeight: 600,
              lineHeight: '23px'
            }}
            onClick={() => closePopup()}
          >
            Cancel
          </Button>
          <Button
            variant="text"
            sx={{
              color: caloTheme.palette.primary500,
              fontSize: '19px',
              fontWeight: 600,
              lineHeight: '23px'
            }}
            disabled={selectedTimeHours === undefined || selectedTimeMinutes === undefined || +selectedTimeHours === 0}
            onClick={() => handleSubmitCustomTime()}
          >
            {' '}
            OK
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default CustomTimePicker;
