import { useEffect } from 'react';

import { startCase } from 'lodash-es';

import { MeasurementUnit } from '@calo/dashboard-types';
import { styled, TableCell, tableCellClasses, TableHead, TableRow } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { IOSSwitch } from 'components';
import { IngredientStats } from 'lib/interfaces';

interface TableHeaderProps {
  ingredient?: IngredientStats;
  switchUnit: boolean;
  setSwitchUnit: (switchUnit: boolean) => void;
}

export default function TableHeader({ ingredient, switchUnit, setSwitchUnit }: TableHeaderProps) {
  const ingredientWeight = switchUnit && ingredient?.weight ? ingredient?.weight : 1;
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      fontFamily: 'Roboto',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '19px',
      textTransform: 'capitalize'
    }
  }));
  const HeaderTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      paddingBottom: 4
    }
  }));
  const BodyTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '20px',
      textTransform: 'capitalize',
      fontWeight: 600,
      paddingTop: 4,
      paddingBottom: 8
    }
  }));

  useEffect(() => {
    if (ingredient?.measurementUnit === MeasurementUnit.g) {
      setSwitchUnit(false);
    }
  }, [ingredient?.measurementUnit]);

  return (
    <TableHead
      style={{ borderRadius: '8px' }}
      sx={{
        backgroundColor: caloTheme.palette.neutral50,
        color: 'black',
        flexWrap: 0,
        justifyContent: 'space-between',
        width: '100%',
        borderRadius: '8px'
      }}
    >
      <TableRow
        sx={{
          backgroundColor: caloTheme.palette.white,
          color: 'black',
          width: '100%',
          flexWrap: 0,
          justifyContent: 'space-between'
        }}
      >
        <HeaderTableCell sx={{ pl: 0.5 }}>
          {switchUnit && ingredient?.measurementUnit ? startCase(ingredient?.measurementUnit) : 'Gram'}
        </HeaderTableCell>
        <HeaderTableCell colSpan={2}>Internal Name</HeaderTableCell>
        <HeaderTableCell>Total Quantity</HeaderTableCell>
        <HeaderTableCell>Total Quantity After Prep</HeaderTableCell>
      </TableRow>
      <TableRow
        sx={{
          backgroundColor: caloTheme.palette.white,
          color: 'black',
          width: '100%',
          flexWrap: 0,
          justifyContent: 'space-between'
        }}
      >
        <BodyTableCell sx={{ pl: 0 }}>
          <IOSSwitch
            onChange={() => {
              setSwitchUnit(!switchUnit);
            }}
            sx={{ color: 'white' }}
            checked={switchUnit}
            disabled={ingredient?.measurementUnit === MeasurementUnit.g}
          />
        </BodyTableCell>
        <BodyTableCell colSpan={2}>{ingredient?.internalName}</BodyTableCell>
        <BodyTableCell>
          {Math.ceil(
            ((ingredient?.quantity ?? 0) + (ingredient?.childQuantityBeforePrep ?? 0) - (ingredient?.removedQuantity ?? 0) || 0) /
              ingredientWeight
          )}
        </BodyTableCell>
        <BodyTableCell>
          {Math.ceil(
            (((ingredient?.quantity ?? 0) + (ingredient?.childQuantityBeforePrep ?? 0) - (ingredient?.removedQuantity ?? 0)) /
              (ingredient?.wastage ?? 0) || 0) / ingredientWeight
          )}
        </BodyTableCell>
      </TableRow>
      <TableRow
        sx={{
          backgroundColor: caloTheme.palette.white,
          color: 'black',
          width: '100%',
          flexWrap: 0,
          justifyContent: 'space-between'
        }}
      >
        <StyledTableCell colSpan={6} sx={{ pl: 0.5, fontSize: '14px', lineHeight: '18px' }}>
          Quantity Per Component
        </StyledTableCell>
      </TableRow>
      <TableRow
        sx={{
          backgroundColor: caloTheme.palette.neutral50,
          color: 'black',
          width: '100%',
          flexWrap: 0,
          justifyContent: 'space-between'
        }}
      >
        <StyledTableCell sx={{ width: '30%', minWidth: '130px', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
          Component Name
        </StyledTableCell>
        <StyledTableCell sx={{ width: '15%', minWidth: '120px' }}>Total Raw Weight</StyledTableCell>
        <StyledTableCell sx={{ width: '15%', minWidth: '130px' }}>Total Cooked Weight</StyledTableCell>
        <StyledTableCell sx={{ width: '15%', minWidth: '50px' }}>Total Quantity</StyledTableCell>
        <StyledTableCell sx={{ width: '15%', minWidth: '150px' }}>Total Quantity After Prep</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}
