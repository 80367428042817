import { Brand, Country, Kitchen } from '@calo/types';
import { toggleUISettings } from 'actions';
import { Select2, SideDrawer } from 'components';
import { getAccessibleCountries } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { MenuPathUpdateFunctions } from '../types';

interface MenuFilter {
  country: Country;
  brand: Brand;
  kitchen: Kitchen;
}

interface SettingsProps {
  onFilter: MenuPathUpdateFunctions;
  filters: MenuFilter;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Country"
          value={filters.country}
          onChange={(data) => onFilter.setKitchen(userKitchens.find((k) => k.includes(data.target.value)) as Kitchen)}
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(data) => onFilter.setKitchen(data.target.value as Kitchen)}
          options={userKitchens
            .filter((r) => r.includes(filters.country || Country.BH))
            .map((c) => ({
              value: c,
              label: c
            }))}
          disabled={Object.values(Kitchen).filter((r) => r.includes(filters.country!)).length === 0}
        />
      </section>
    </SideDrawer>
  );
};

export default Settings;
