import { CreateFoodReq, FoodStatus } from '@calo/dashboard-types';
import { Country } from '@calo/types';
import { Icon } from '@iconify/react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { FormikErrors } from 'formik';
import { Routes } from 'lib/enums';
import { resolveCountry } from 'lib/helpers';
import { Link } from 'react-router-dom';

interface MealCreationHeaderProps {
  isSubmitting: boolean;
  handleSubmit: any;
  disableSubmit: boolean; //!dirty || !isValid || isSubmitting
  values: Omit<CreateFoodReq, 'id'>;
  setValues: (
    values: React.SetStateAction<Omit<CreateFoodReq, 'id'>>,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<Omit<CreateFoodReq, 'id'>>>;
}

const MealCreationHeader = ({ isSubmitting, disableSubmit, setValues, values, handleSubmit }: MealCreationHeaderProps) => {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      spacing={2}
      sx={{
        backgroundColor: caloTheme.palette.white,
        px: 2,
        position: 'sticky',
        zIndex: 1100,
        top: 0,
        marginRight: '-35px !important',
        marginTop: '-35px !important'
      }}
    >
      <Stack direction="row" justifyContent="space-between" sx={{ mr: 2, pt: 2, px: '42px' }}>
        <Link to={Routes.playgroundFoodList}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Icon icon="fluent:chevron-left-24-filled" color={caloTheme.palette.neutral900} style={{ fontSize: '16px' }} />
            <Typography sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '20px', color: caloTheme.palette.black }}>
              Back
            </Typography>
          </Stack>
        </Link>
        <Typography sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '20px', color: caloTheme.palette.black, mr: 1 }}>
          {resolveCountry(values.country || Country.BH)}
        </Typography>
      </Stack>
      <Divider sx={{ mx: '12px' }} />
      <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center" sx={{ pb: 1, px: '32px' }}>
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24px',
            color: caloTheme.palette.black,
            mt: '-12px',
            textTransform: 'capitalize'
          }}
        >
          {values.name.en || 'Meal Name'}
        </Typography>
        <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
          <IconButton>
            <Link to={Routes.playgroundFoodList} style={{ color: 'black' }}>
              <Icon icon="lucide:trash-2" width={21} />
            </Link>
          </IconButton>
          <LoadingButton
            loadingPosition={'center'}
            type="submit"
            loading={isSubmitting}
            disabled={disableSubmit}
            onClick={() => {
              setValues({
                ...values,
                status: FoodStatus.draft
              });
              handleSubmit();
            }}
            sx={{
              textTransform: 'capitalize',
              color: caloTheme.palette.white,
              backgroundColor: caloTheme.palette.primary500,
              border: 1,
              borderRadius: '8px',
              borderColor: caloTheme.palette.primary500,
              py: '10px',
              px: '16px',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: 600,
              ':hover': {
                color: caloTheme.palette.primary500,
                backgroundColor: caloTheme.palette.white
              },
              ':disabled': {
                color: caloTheme.palette.white,
                borderColor: caloTheme.palette.grey['400'],
                backgroundColor: caloTheme.palette.grey['400']
              }
            }}
          >
            Create Meal
          </LoadingButton>
          {/* <LoadingButton
            type="submit"
            loading={isSubmitting}
            id="saveNewFoodButton"
            disabled={disableRelease}
            onClick={() => {
              setValues({
                ...values,
                status: FoodStatus.approved
              });
              handleSubmit();
            }}
            sx={{
              textTransform: 'capitalize',
              color: caloTheme.palette.white,
              backgroundColor: caloTheme.palette.primary500,
              border: 1,
              borderRadius: '8px',
              borderColor: caloTheme.palette.primary500,
              py: '10px',
              px: '16px',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: 600,
              ':hover': {
                color: caloTheme.palette.primary500,
                backgroundColor: caloTheme.palette.white
              },
              ':disabled': {
                color: caloTheme.palette.white,
                borderColor: caloTheme.palette.grey['400'],
                backgroundColor: caloTheme.palette.grey['400']
              }
            }}
          >
            Release
          </LoadingButton> */}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MealCreationHeader;
