import cx from 'classnames';
import { Modal, Button as OButton } from 'components';
import { differenceInDays, parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import { addonsWithinThisWeek, handleWeekFormat } from 'lib/helpers';
import { forwardRef, useState } from 'react';

interface MealUsedModalProps {
  filteredFoodUsed: {
    type: 'mainMenu' | 'addonsMenu';
    date?: string | undefined;
    week?: number | undefined;
  }[];
  close: () => void;
  selectedFoodType: string;
}

const getSelectedText = (selectedFoodType: string, type: string): string => {
  switch (selectedFoodType) {
    case 'food':
      return type === 'addonsMenu' ? 'meal size or different size' : 'Components for this meal size or different size';
    case 'component':
      return type === 'addonsMenu' ? 'This food component' : 'Components & ingredients for this component';
    case 'ingredient':
      return type === 'addonsMenu' ? 'This food ingredient' : 'Ingredient';
    default:
      return type === 'addonsMenu' ? 'meal size or different size' : 'Components for this meal size or different size';
  }
};

const MealUsedModal = forwardRef(({ filteredFoodUsed, close, selectedFoodType }: MealUsedModalProps, ref) => {
  const [info, setInfo] = useState<any>();
  const [showMoreData, setShowMoreData] = useState<number>(4);

  const handleInfoText = (date: any, usedOnMenuType: string) => {
    const selected = getSelectedText(selectedFoodType, usedOnMenuType);
    const baseClass = 'bg-gray-100 text-center text-black w-8/12 mx-auto text-sm';
    const messages = {
      addonMenu: `This ${selected} is part of an addon menu, changes will not be applied before the next addon menu generation, please coordinate with the operations team`,
      preppedOrCooked: `${selected} are prepped or cooked already, please communicate directly with the Operations team before doing any changes`,
      purchasedOrPrepped: `${selected} might have been purchased or prepped already, please double check with the Operations team`
    };
    const getMessage = () => {
      if (usedOnMenuType === 'addonsMenu') {
        return messages.addonMenu;
      }
      if (differenceInDays(date, Date.now()) <= 2) {
        return messages.preppedOrCooked;
      }
      return messages.purchasedOrPrepped;
    };
    return <p className={baseClass}>{getMessage()}</p>;
  };

  return (
    <Modal
      ref={ref}
      onClose={() => {
        close();
        setInfo(undefined);
        setShowMoreData(4);
      }}
    >
      <section className="section is-title-bar -mt-8">
        <p className="font-bold uppercase text-center">{selectedFoodType} Is used in the following Days</p>
      </section>
      <section>
        <div
          className={cx('', {
            'h-64 overflow-auto overflow-y-visible': showMoreData === filteredFoodUsed.length,
            'h-auto': showMoreData !== filteredFoodUsed.length
          })}
        >
          {(showMoreData === filteredFoodUsed.length ? filteredFoodUsed : filteredFoodUsed?.slice(0, 5)).map((r, index) => (
            <span key={`${r.date ? r.date : r.week}-${index}`}>
              <p
                className={cx('flex justify-center mt-4', {
                  'text-purple-600': r.type === 'addonsMenu' && !addonsWithinThisWeek(r.week!),
                  'text-red-500':
                    (r.type === 'addonsMenu' && addonsWithinThisWeek(r.week!)) ||
                    (r.type === 'mainMenu' &&
                      differenceInDays(parseISO(r.date!), parseISO(format('yyyy-MM-dd')(+Date.now()))) <= 1),
                  'text-yellow-300':
                    r.type === 'mainMenu' && differenceInDays(parseISO(r.date!), parseISO(format('yyyy-MM-dd')(+Date.now()))) > 1
                })}
              >
                {r.date ? format('eee dd/MM/yyyy')(parseISO(r.date)) : handleWeekFormat(r.week!)}
                {
                  <i
                    key={r.date ? r.date : r.week}
                    className="ml-3 mt-1 fa fa-info-circle cursor-pointer"
                    onClick={() => (info && (info.date === r.date || info.week === r.week) ? setInfo(undefined) : setInfo(r))}
                  />
                }
              </p>
              <span className="flex">
                {r.type === 'mainMenu' &&
                  (differenceInDays(parseISO(r.date!), parseISO(format('yyyy-MM-dd')(+Date.now()))) >= 0 ||
                    differenceInDays(parseISO(r.date!), parseISO(format('yyyy-MM-dd')(+Date.now()))) <= 7) &&
                  info &&
                  info.date === r.date &&
                  handleInfoText(parseISO(r.date!), 'mainMenu')}
                {r.type === 'addonsMenu' && info && info.week === r.week && handleInfoText(r.week!, 'addonsMenu')}
              </span>
            </span>
          ))}
          {filteredFoodUsed.length > 4 && showMoreData === 4 && (
            <div className="flex justify-center mt-4" data-test="show_more_data_used_in_menu">
              {' '}
              <OButton
                onClick={() => setShowMoreData(filteredFoodUsed.length)}
                className="rounded-md"
                primary
                content="show more"
              />{' '}
            </div>
          )}
        </div>
      </section>
    </Modal>
  );
});

export default MealUsedModal;
