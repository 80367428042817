import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { toggleUISettings } from 'actions/ui';
import SelectMUI from 'components/MUI/SelectMUI';
import { format } from 'date-fns';
import { foodInformationFormSingleSelectCustomStyles } from 'lib/componentStyles';
import { MenuPresentationType } from 'lib/enums';
import { useDispatch } from 'react-redux';
import { MenuHeaderCardProps, menuPresentationOptions } from '../types';
import { styles } from './styles';

const MenuHeaderCard = ({
  isWeeklyMenuChanged,
  filters,
  selectedDay,
  menuPresentation,
  setMenuPresentation
}: MenuHeaderCardProps) => {
  const dispatch = useDispatch();

  return (
    <Card variant="outlined" sx={styles.card}>
      <Box sx={styles.box}>
        <Stack sx={styles.stack}>
          <Typography variant="h5" sx={styles.typography}>
            Menu {filters.country}
          </Typography>
          {menuPresentation === MenuPresentationType.weekly && (
            <Typography variant="h5" sx={styles.typography}>
              {format(new Date(selectedDay), 'dd/MM/yy')} - {format(new Date(selectedDay), 'eeee')}
            </Typography>
          )}
        </Stack>
      </Box>
      <Stack sx={styles.selectStack}>
        <SelectMUI
          value={menuPresentation}
          isDisabled={isWeeklyMenuChanged}
          onChange={(data: any) => setMenuPresentation(data.value as MenuPresentationType)}
          options={menuPresentationOptions}
          customStyles={{
            ...foodInformationFormSingleSelectCustomStyles,
            control: (defaultStyle: any) => ({
              ...defaultStyle,
              ...styles.datePickerStyles
            })
          }}
        />
        <Button variant="outlined" onClick={() => dispatch(toggleUISettings())} style={styles.filterButton}>
          <FilterListOutlinedIcon color={'action'} />
        </Button>
      </Stack>
    </Card>
  );
};

export default MenuHeaderCard;
