import { FoodType } from '@calo/types';
import { Food, MenuFood } from 'lib/interfaces';
import { compact, startCase } from 'lodash-es';

export const renderFoodDetails = (food: Food | MenuFood | undefined) => {
  const foodTypes = food?.type
    .filter((type: FoodType) => type !== FoodType.dinner)
    .map((type: FoodType) => (type.includes(FoodType.lunch) ? 'Lunch & Dinner' : type));
  const tags = food?.tags.join(', ');
  return `${compact(foodTypes).join(', ')} ${startCase(tags)}`;
};
