import { Ingredient } from '@calo/types';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { hasAllergen } from 'lib/helpers';
import { uniqBy } from 'lodash-es';

const IngredientList = ({ ingredients, sx }: { ingredients: Ingredient[]; sx?: SxProps<Theme> }) => {
  return (
    <Stack sx={{ flexDirection: 'row', rowGap: 0, columnGap: '4px', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
      {uniqBy(ingredients, 'id').map((ing, index) => {
        return (
          <Typography
            key={ing.id}
            sx={{
              color: hasAllergen(ing) ? caloTheme.palette.red500 : 'black',
              fontSize: '14px',
              ...sx
            }}
          >
            {ing.internalName ?? ing.name.en}
            {index < ingredients.length - 1 && ','}
          </Typography>
        );
      })}
    </Stack>
  );
};

export default IngredientList;
