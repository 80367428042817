import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  chips: {
    color: caloTheme.palette.primary700,
    backgroundColor: caloTheme.palette.primary100,
    padding: '8px 6px 8px 6px',
    borderWidth: 0,
    mr: 1,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    textTransform: 'capitalize'
  }
};

export default styles;
