import { useState } from 'react';

import { format } from 'date-fns/fp';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Kitchen } from '@calo/types';
import { ThemeProvider } from '@emotion/react';
import { Box, Card, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { exportBusinessOrders, getListWithParams, toggleUISettings } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Icon } from 'components';
import ExportButton from 'components/ExportButton';
import { resolveCountryFromKitchen } from 'lib/helpers';
import { BusinessOrderFilters, PaginatedBusinessAccountDeliveries } from 'lib/interfaces';
import { toast } from 'react-toastify';
import Settings from '../Settings';
import OrdersListRow from './OrdersListRow';

interface OrdersListProps {
  history: any;
}
const OrdersList = ({ history }: OrdersListProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [loadingExport, setLoadingExport] = useState(false);
  const searchParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState<BusinessOrderFilters>({
    date: {
      lte: format('yyyy-MM-dd')(Date.now()),
      gte: format('yyyy-MM-dd')(Date.now())
    },
    kitchen: Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data, isLoading } = useQuery<any, Error, PaginatedBusinessAccountDeliveries>(
    [
      'business/deliveries',
      {
        page,
        filters: {
          kitchen: filters.kitchen ? filters.kitchen : undefined,
          date: filters.date,
          userId: filters.userId
        },
        limit: 100
      }
    ],
    getListWithParams,
    {
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none',
      fontSize: '12px',
      fontWeight: 600,
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily,
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  const handleExportOrders = async () => {
    try {
      setLoadingExport(true);
      await exportBusinessOrders(filters);
    } catch (error: any) {
      toast('something went wrong', { type: 'error', autoClose: 2000 });
      console.log(error);
    }
    setLoadingExport(false);
  };

  return (
    <ThemeProvider theme={caloTheme}>
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: 2,
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          display={'flex'}
          flexDirection="row"
          justifyContent={'space-between'}
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
          width="100%"
        >
          <Stack width={'50%'} display={'flex'} flexDirection={'row'} alignItems="start" justifyContent={'space-between'}>
            <Typography
              variant="h5"
              sx={{
                my: 'auto',
                fontWeight: 600,
                fontSize: '28px',
                lineHeight: '34px',
                fontFamily: 'Roboto',
                color: caloTheme.palette.neutral900
              }}
            >
              Orders - {resolveCountryFromKitchen(filters.kitchen)}
            </Typography>
          </Stack>
          <Stack display={'flex'} flexDirection={'row'}>
            <Stack display={'flex'} flexDirection={'row'}>
              <Stack data-test={`filtersButton`} sx={{ m: 'auto', mt: 1.2, cursor: 'pointer' }}>
                <Icon name="filters" size={9} onClick={() => dispatch(toggleUISettings())} />
              </Stack>
              <ExportButton
                isDisabled={(data && data.data.length === 0) || isLoading || loadingExport}
                isLoading={isLoading || loadingExport}
                onClick={handleExportOrders}
              />
            </Stack>
          </Stack>
        </Box>
      </Card>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <>
          <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px'
              }}
            >
              {`${filters.date.gte} - ${filters.date.lte}`}
            </Typography>

            {isLoading ? (
              <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                <CaloLoader />
              </Stack>
            ) : (
              <>
                <Table
                  sx={{
                    marginY: '4px',
                    minHeight: '120px',
                    overflow: 'auto',
                    width: '100%',
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      flexDirection: 'column'
                    }
                  }}
                >
                  <TableHead
                    style={{ borderRadius: '8px' }}
                    sx={{
                      backgroundColor: caloTheme.palette.neutral50,
                      color: 'black',
                      flexWrap: 0,
                      justifyContent: 'space-between',
                      width: '100%',
                      borderRadius: '8px'
                    }}
                  >
                    <TableRow
                      sx={{
                        backgroundColor: caloTheme.palette.neutral50,
                        color: 'black',
                        width: '100%',
                        flexWrap: 0,
                        justifyContent: 'space-between'
                      }}
                    >
                      <StyledTableCell style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
                        Business
                      </StyledTableCell>
                      <StyledTableCell>Meals</StyledTableCell>
                      <StyledTableCell>Phone Number</StyledTableCell>
                      <StyledTableCell sx={{ maxWidth: '239px', minWidth: '239px' }}>Address</StyledTableCell>
                      <StyledTableCell>Delivery Time</StyledTableCell>
                      <StyledTableCell style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}>
                        Status
                      </StyledTableCell>
                      <StyledTableCell style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}>
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {data && data.data.length === 0 ? (
                    <StyledTableCell style={{ border: 0 }} colSpan={6}>
                      <Typography
                        sx={{
                          mt: 3,
                          textAlign: 'center',
                          width: 'full',
                          fontSize: '24px',
                          color: caloTheme.palette.neutral400
                        }}
                      >
                        No Orders for the selected Date
                      </Typography>
                    </StyledTableCell>
                  ) : (
                    <TableBody>{data && data.data.map((order) => <OrdersListRow key={order.id} order={order} />)}</TableBody>
                  )}
                </Table>
              </>
            )}
          </Box>
          <Settings onFilter={setFilters} filters={filters} page={page} setPage={setPage} />
        </>
      </Card>
    </ThemeProvider>
  );
};
export default OrdersList;
