import { getRecord } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components/index';
import { format } from 'date-fns/fp';
import { useUserRoles } from 'lib/hooks';
import { Delivery } from 'lib/interfaces';
import { useQuery } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import { DeliveryAddress, Subscription } from '@calo/types';
import { Box, Stack, Typography } from '@mui/material';

import Container from './Container';
import CreateDeliveryCard from './CreateDeliveryCard';
import DeliveriesCalender from './DeliveriesCalender';
import DeliveriesListView from './DeliveriesListView';
import DeliveriesMeals from './DeliveriesMeals';
import StatisticsCards from './StatisticsCards';
import TabsView from './TabsView';
import TrackedProgress from './TrackedProgress';
import UnskipDeliveryCard from './UnskipDeliveryCard';

interface DeliveriesCardProps {
  selectedDate: Date;
  selectedDelivery: string;
  allDeliveries: Delivery[];
  subscription: Subscription & { remainingDays: number; expectedLastDeliveryDay: string; ratings: any[] };
  setSelectedDate: (value: any) => void;
  refetchAllDeliveries: () => void;
  setSelectedDelivery: React.Dispatch<React.SetStateAction<string | undefined>>;
  refetchSubscription: () => void;
  setSubscriptionDeliveries: React.Dispatch<React.SetStateAction<Delivery[]>>;
  selectedDeliveryView: string;
  setSelectedDeliveryView: React.Dispatch<React.SetStateAction<string>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  isLoading: boolean;
}

const DeliveriesCard = ({
  refetchAllDeliveries,
  allDeliveries,
  selectedDate,
  setSelectedDate,
  subscription,
  selectedDelivery,
  setSelectedDelivery,
  refetchSubscription,
  setSubscriptionDeliveries,
  selectedDeliveryView,
  setSelectedDeliveryView,
  page,
  setPage,
  total,
  isLoading
}: DeliveriesCardProps) => {
  const {
    data: exactDelivery,
    isLoading: isLoadingExactDelivery,
    refetch
  } = useQuery(['deliveries', selectedDelivery], getRecord, {
    suspense: false,
    keepPreviousData: false,
    enabled: !!selectedDelivery,
    onSuccess(data) {
      const delivery = data as Delivery;
      setSubscriptionDeliveries((prev) => {
        if (prev) {
          return prev.map((del) => {
            if (del.id === delivery.id) {
              return delivery;
            }
            return del;
          });
        }
        return prev;
      });
    }
  });
  const initialDelivery = allDeliveries.find(
    (delivery) => format('yyyy-MM-dd')(new Date(delivery.day)) === format('yyyy-MM-dd')(selectedDate)
  );
  const delivery =
    isLoadingExactDelivery || !exactDelivery
      ? initialDelivery
      : exactDelivery && (exactDelivery as Delivery & { deliveryAddresses: DeliveryAddress[] });
  const roles = useUserRoles();

  return (
    <>
      <Container>
        <Box
          display={'flex'}
          flexDirection={'column'}
          width="25%"
          sx={{
            flexDirection: 'column',
            display: 'flex',
            p: 1,
            mr: 4,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              display: 'flex',
              flexDirection: 'column',
              width: '100%'
            }
          }}
        >
          <Box>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', ml: 1, mt: 1 }}>
              <Typography sx={{ my: 'auto', fontWeight: '600', fontSize: '20px', lineHeight: '24px' }}>Deliveries</Typography>
              <TabsView selectedDeliveryView={selectedDeliveryView} setSelectedDeliveryView={setSelectedDeliveryView} />
            </Stack>
            <Stack>
              {selectedDeliveryView === 'calender' ? (
                <DeliveriesCalender selectedDate={selectedDate} allDeliveries={allDeliveries} setSelectedDate={setSelectedDate} />
              ) : (
                <DeliveriesListView
                  selectedDate={selectedDate}
                  allDeliveries={allDeliveries}
                  setSelectedDate={setSelectedDate}
                  refetchSubscription={refetchSubscription}
                  page={page}
                  setPage={setPage}
                  total={total}
                  isLoading={isLoading}
                />
              )}
              <StatisticsCards subscription={subscription} refetchSubscription={refetchSubscription} />
            </Stack>
          </Box>
        </Box>

        <Box
          display="flex"
          width="73%"
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: '100%',
              display: 'flex',
              flexDirection: 'column'
            }
          }}
        >
          {delivery && delivery.skipped ? (
            <UnskipDeliveryCard
              selectedDate={selectedDate}
              subscription={subscription}
              deliveryId={delivery.id}
              refetch={refetch}
            />
          ) : delivery && selectedDelivery ? (
            <Stack sx={{ overflowY: 'hidden' }}>
              <DeliveriesMeals
                refetchDelivery={refetch}
                subscription={subscription}
                selectedDelivery={delivery!}
                refetchAllDeliveries={refetchAllDeliveries}
                selectedDate={format('dd/MM/yyyy')(selectedDate)}
              />
              {roles.includes(Permission.VIEW_SUBSCRIPTION_DAY_PROGRESS) && (
                <TrackedProgress
                  subscriptionId={subscription.id}
                  date={delivery ? delivery.day : format('yyyy-MM-dd')(new Date())}
                />
              )}
            </Stack>
          ) : isLoading ? (
            <CaloLoader />
          ) : (
            <CreateDeliveryCard
              selectedDate={selectedDate}
              subscription={subscription}
              setSelectedDelivery={setSelectedDelivery}
              setSubscriptionDeliveries={setSubscriptionDeliveries}
            />
          )}
        </Box>
      </Container>
    </>
  );
};
export default DeliveriesCard;
