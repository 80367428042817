import { ExportMovingCostReportReq } from '@calo/dashboard-types';
import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';

export const exportMovingCostReport = async (kitchen: string, attr: ExportMovingCostReportReq) => {
  const { status } = await client.post(`inventory/${kitchen}/moving-cost-report/export`, attr);
  ToastSuccessMsgs({ status, action: 'Moving cost report will be sent to your email in few minutes' });
  return;
};
export const exportMrpReport = async (kitchen: string) => {
  const { status } = await client.post(`inventory/${kitchen}/mrp-report/export`);
  ToastSuccessMsgs({ status, action: 'MRP report will be sent to your email shortly' });
  return;
};
